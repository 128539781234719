import React, { useEffect } from 'react';

import { restRequest } from '../../Helpers/RequestHelper';
import Loader from '../../Components/Loader/Loader';
import { checkError } from '../../Helpers/AuthHelper';
import { getObjFromLS, deleteValueFromLS } from '../../Helpers/LocalStorage';

const StripePaymentStatus = ({ history }) => {
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = () => {
    restRequest(
      'get',
      'payment/stripeResponse' +
        window.location.search +
        '&se_token=' +
        getObjFromLS('se_token')
    )
      .then((res) => {
        //console.log('stats --------', res.status)
        //console.log('res --------', res)
        deleteValueFromLS('se_token');
        // success is sent by backend so payment is successfully deducted by paypal or stripe
        history.push('/paymentSuccess');
        /*if (res.status === 200) {
          history.push('/paymentSuccess');
        } else {
          history.push('/paymentFailed');
        }*/
      })
      .catch((error) => {
        //console.log('error --------', error)
        checkError(error);
        deleteValueFromLS('se_token');
        history.push('/paymentFailed');
      });
  };
  return <Loader />;
};

export default StripePaymentStatus;
