import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MdClose } from '../../Common/Icons';
// import GeneralPreference from './General/GeneralPreference'
import SalesOrderPreference from './SalesOrderPreference/SalesOrderPreference';
import PackagePreference from './PackagePreference/PackagePreference';
import ShipmentPreference from './ShipmentPreference/ShipmentPreference';
import PurchaseOrderPreference from './PurchaseOrderPreference/PurchaseOrderPreference';
import InvoicePreference from './InvoicePreference/InvoicePreference';
import BillPreference from './BillPreference/BillPreference';
import SalesReturnPreference from './SalesReturnPreference/SalesReturnPreference';
import ReturnReceivePreference from './ReturnReceivePreference/ReturnReceivePreference';
import CreditNotePreference from './CreditNotePreference/CreditNotePreference';
import ReceivePreference from './ReceivePreference/ReceivePreference';
import Layout, { Header, Body } from '../../Components/Layout/Layout';
import NavLayout from '../../Components/Layout/NavLayout';
import './NumberPreferences.css';

class NumberPreferences extends Component {
  routes = [
    {
      title: 'Sales Order Prefix',
      path: '/numberPreferences',
      component: SalesOrderPreference,
    },
    {
      title: 'Package Prefix',
      path: '/numberPreferences/package',
      component: PackagePreference,
    },
    {
      title: 'Shipment Prefix',
      path: '/numberPreferences/shipment',
      component: ShipmentPreference,
    },
    {
      title: 'Invoice Prefix',
      path: '/numberPreferences/invoice',
      component: InvoicePreference,
    },
    {
      title: 'Purchase Order Prefix',
      path: '/numberPreferences/purchaseorder',
      component: PurchaseOrderPreference,
    },
    {
      title: 'Bill Prefix',
      path: '/numberPreferences/bill',
      component: BillPreference,
    },
    {
      title: 'Sales Return Prefix',
      path: '/numberPreferences/SalesReturnPreference',
      component: SalesReturnPreference,
    },
    {
      title: 'Return Receive Prefix',
      path: '/numberPreferences/ReturnReceivePreference',
      component: ReturnReceivePreference,
    },
    {
      title: 'Credit Note Prefix',
      path: '/numberPreferences/CreditNotePreference',
      component: CreditNotePreference,
    },
    {
      title: 'Receive Prefix',
      path: '/numberPreferences/ReceivePreference',
      component: ReceivePreference,
    },
  ];

  componentDidMount() {
    document.title = 'Prefix Preferences';
  }

  renderPreference() {
    return (
      <div className="preference">
        <NavLayout routes={this.routes} handleToast={this.props.handleToast} />
      </div>
    );
  }

  render() {
    return (
      <Layout>
        <Header title="Prefix Preferences">
          <Link to="/settings">
            <MdClose className="material-icon blue" />
          </Link>
        </Header>
        <Body>{this.renderPreference()}</Body>
      </Layout>
    );
  }
}

export default NumberPreferences;
