import React from 'react';
import Avatar from '../../../../Components/Avatar/Avatar';
import { imagePathS3 } from '../../../../Helpers/RequestHelper';
import './ContactInfo.css';
import {
  MdLocalPhone,
  MdPhoneAndroid,
  MdEmail,
  FaSkype,
} from '../../../../Common/Icons';

const ContactInfo = ({
  // id,
  photo,
  displayName,
  salutation,
  firstName,
  lastName,
  designation,
  department,
  companyName,
  workPhone,
  mobile,
  email,
  skype,
}) => {
  return (
    <div className="contact-info">
      <div className="h-center">
        {photo ? (
          <>
            <img
              src={imagePathS3(photo)}
              className="organization-logo avatar"
              alt="contact-pic"
            />
          </>
        ) : (
          <Avatar>{displayName.charAt(0)}</Avatar>
        )}
      </div>

      <div className="contact-info--details">
        <div className="semi-bold">
          {salutation} {firstName} {lastName}
        </div>
        {designation && <div className="grey-text">{designation}</div>}
        {department && <div className="grey-text">{department}</div>}
        {companyName && <div className="grey-text">{companyName}</div>}
      </div>
      <hr className="section-details--separator" />
      <div className="contact-info--contact">
        <div className="section-details--info-title semi-bold margin-bottom-sm">
          Contact Info
        </div>
        <div className="margin-bottom-sm">
          {email && (
            <div>
              <MdEmail className="details-icon" />
              {email}
            </div>
          )}
        </div>
        <div className="margin-bottom-sm">
          {workPhone && (
            <div>
              <MdLocalPhone className="details-icon" />
              {workPhone}
            </div>
          )}
        </div>
        <div className="margin-bottom-sm">
          {mobile && (
            <div>
              <MdPhoneAndroid className="details-icon" />
              {mobile}
            </div>
          )}
        </div>
        <div>
          {skype && (
            <div>
              <FaSkype className="details-icon" />
              {skype}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
