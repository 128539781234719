import React from 'react'

export default () => (
	<svg  style={{height:'70rem'}} version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
	 x="0px" y="0px" viewBox="0 0 445.6 631.9" xmlSpace="preserve">
<circle fill="#ADB6BC" stroke="#ADB6BC" cx="173.1" cy="603.1" r="2.3"/>
<circle fill="#ADB6BC" stroke="#ADB6BC" cx="172.9" cy="561.2" r="2.3"/>
<circle fill="#ADB6BC" stroke="#ADB6BC" cx="173.1" cy="561" r="2.3"/>
<rect x="172.4" y="562.6" fill="#ADB6BC" width="1" height="23"/>
<path fill="#ADB6BC" stroke="#ADB6BC" d="M175.1,584c-1.4,0.7-3,0.7-4.4,0l2.2,4.2L175.1,584z"/>
<path fill="#ADB6BC" d="M172.4,603.1v4.5h1v-4.5H172.4z"/>
<g>
	<path fill="#ADB6BC" d="M173.4,128.1c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5H173.4z M172.4,141.1c0,0.3,0.2,0.5,0.5,0.5
		c0.3,0,0.5-0.2,0.5-0.5H172.4z M172.4,128.1v13h1v-13H172.4z"/>
	<circle fill="#ADB6BC" stroke="#ADB6BC" cx="172.9" cy="126.1" r="2.3"/>
	<path fill="#ADB6BC" stroke="#ADB6BC" d="M175.1,137.9c-1.4,0.7-3,0.7-4.4,0l2.2,4.2L175.1,137.9z"/>
</g>
<g>
	<path fill="#ADB6BC" d="M173.4,563.2c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5H173.4z M172.4,576.2c0,0.3,0.2,0.5,0.5,0.5
		c0.3,0,0.5-0.2,0.5-0.5H172.4z M172.4,563.2v13h1v-13H172.4z"/>
	<circle fill="#ADB6BC" stroke="#ADB6BC" cx="172.9" cy="561.2" r="2.3"/>
</g>
<g>
	<path fill="#ADB6BC" d="M173.4,406.2c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5H173.4z M172.4,419.2c0,0.3,0.2,0.5,0.5,0.5
		c0.3,0,0.5-0.2,0.5-0.5H172.4z M172.4,406.2v13h1v-13H172.4z"/>
	<circle fill="#ADB6BC" stroke="#ADB6BC" cx="172.9" cy="404.2" r="2.3"/>
	<path fill="#ADB6BC" stroke="#ADB6BC" d="M175.1,416c-1.4,0.7-3,0.7-4.4,0l2.2,4.2L175.1,416z"/>
</g>
<g>
	<path fill="#ADB6BC" d="M173.4,266.2c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5H173.4z M172.4,279.2c0,0.3,0.2,0.5,0.5,0.5
		c0.3,0,0.5-0.2,0.5-0.5H172.4z M172.4,266.2v13h1v-13H172.4z"/>
	<circle fill="#ADB6BC" stroke="#ADB6BC" cx="172.9" cy="264.2" r="2.3"/>
	<path fill="#ADB6BC" stroke="#ADB6BC" d="M175.1,276c-1.4,0.7-3,0.7-4.4,0l2.2,4.2L175.1,276z"/>
</g>
<g>
	
		<image width="217" height="138" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANkAAACKCAYAAAAnpO8vAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAD91JREFUeNrsnd1y08gWRtWyExIC
gaQGasIN9/AavELmeXgfXmJeYC7hPldcZAaYBMiPbens1dlbpy3CUDXnINvK91WpWpIdsLt77b9u
JVUlSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIk
SZIkSZIkSZL0L5TW/QO+fv36m8/49u3b7vz4+FijOHK9efOmO3/58uVtc6QVZP8SLGAKiN69e9d9
1qOjI828O6r3798vXb948aIFwhK+dYMurRtcARZQBUx07OHhYf6sHz58yPeeP39effz4UbPuDung
4KA6OTlhLlQ+F9owuswRgHODvFawpXWF6+rqKgHU/v5+tbe3l87Ozqr79++nz58/55/Z3d3tfv7r
169JU3CcsjHvQLm4uMjtgwcPGPOWufHly5eWuQF4QFcCty6wpXUAzDqkCwfxWPP5PINlbVosFpUd
6fLyMt27d6/a3t6uOKcFxPLfovOlcSiMacjGvr2+vs7n1raPHz9m/NvJZNICX13X+RzgTB1sZrxb
QslVgpZWCZfH1IR9CWCAC6CapgGi1LZthgsPxrkd1XQ6TXgua/O/Yx2rGTlyYWgR82Nra6tNKXFU
tMBlQLYW2WTIAjzmB2CuA2irhqwMDdOTJ0+AJ/3111/JOrMGNq6tI9NsNqut8zJsgEUbkMUgSOMT
44sHo7X5kCFxyFob99aMMW0DbAYhgDV4Oq4JJfFq/MwqQUurAqz0YGZx0qdPn9LDhw9rQkRz/wBV
WwfW1mE1QAGatTXQ5XjA7gGh3e8gMzA1K0ciM6odZDbOORykBS5gw1uZGq69bWh5n0U6jYePzS+/
/NKenJysFLTpOgBGi+cCKAMmw2XtxDqzRtaB+T7gAaEDlwEL0CKsIKSUNluEgjGmfcCwrwFWgMbb
FkyQus6wWehILt8QXv7555+N/7MAlsKzjdqTHR8fJ74sBQ5CRDoEwKxDagOOBIvenQCZdRjX9CTe
rPbXgC5FuOjneVDinjQK0AIsxrX1Mc5hoCs818JBAzJCGq4XZrCBENByS37GcXBwkKuOQ3qzQT1Z
UUnMSSw5GF4KDwZgdm8yualkANjUAcOjTeub3s1erYAsHw6YZuaIVBjO8GQZtIbQ5caj0S7sMsNF
1IMzYz4wNywamvPvkJ9ROPP1VQohg3uzQS1/6cUIEff392tra4u/J5ZrAdTUzgEKwKYOWgcbng3I
vNKYw0b6vLB+8mTjgSyD4KC1fm8pB8Nr2QFMC7ue2/05rc0JrLXZ7PnC5tXCcvzG5lpzenraHB0d
De7NBvNkRS7GAmLOpagiRoEDkDgAzDpqC8DsPVQyMnRAxhEhY+RlvXBRs3NEeRnj6Ucb+Zh7sCY8
mc+buefmKeYAlUe8HyV9yv5eHEvmyQb3ZoOGiyw448VYaGatizzMixrhqToPZh20bZ22RaiIh+Ne
QOb5WYrQgAEJjxYJs7TZikJHEZyQjxEttg5X456LmzmFKIsjvNG8WOuwNWbYa5t3Cwpt7AwpN5mP
BjK+1KtXr/IqPLlYlOB3d3dzJzlAk/BgDhgeDW+2VUCYwQzIXOXgKGQcQdEjxrQHDvebG56ahacO
kaN362fu+XJhhE0NbHCwvIwICsOed4MQVZG6DBEyDgJZGSqy3wzX7dukEkUPL9NHOJi9GYYIwOze
NnBFIcTfV0eo6N5MadkI07IIGysv20e4WFQVa/dk8b4cRuLxvDq9YBMDhtnysgzf2dkZ+2DJy8bn
yRBezKxIDhVjqxTrXmGRvIo48RyMz7blcEWONvEYvA7APHYPS7bSwo70v0P1D3WQWCNrvGRfFwY2
PFgu4TNPyPUjtWADA4vb7H2leI1x983D4yp8RD7GBk7A2tnZ6bZKUXoFNC/Td/lZtEWVcRqFjwgV
byq66RvAVATZzGLH9yCLMNBL+E1sQuAeniuinDDCZYHM5llej6UlZGS9bMjvNfiODzzY1dVVhsD3
IuadHJ6XdWFjkYNN3INNixJ+XRQ9YpvV0iApbNxwt7Y8nm1RYWTsF17sCs+2tHGB9zCf8GC+Zpaj
p729PeoB1enpaTVayFgQ5DkwviyWyPKxvAE4QsY4SktUWicHbOKJbl2GhNrxMS4R1BSwxXlAFfeY
F4uq2BUU2/B4KwWPMMbsyqcOEPtc41GYQb7LUJXF8lcG8GWxLPGYyk2/pFTs5Oi2UZXbqYpKUuRk
UWVcKuvr2Pyj2Er3zXm/jbkQm8gjwon5BFgYc55BxMjzVH3My9F5Mr7c+fl5GRJ0lqZs6axqeR1s
qfN726pU4Lg7hZHaK4h1XJfrZOVWu/LwvCw/8IuochNNDeaVh+yl+J0c5RPN5S766JCys4oqYgfV
LZ1ZVhN1jOO4bUyroqLYFbeKpZzyPd2jUERMHP0n6Yf6TWeDeDK+TP+X3vCl/VGVyiuEuaPivPRg
saOjBK03CCp0jLDYWFSIIydLvuC8ZHiLIklUnNeq+DV4dbHMxYiV/XmhpV3X0WnF/rX+IvM3sKlk
P37oYidI8QhMVS7fhNGOyIjX4xnDeA/zL8LGUYaLP6gmdaBEpbAfFty24CwPNnqyqp4xTb3XuhTi
to3i5dPyq/pFSyuBrPyy0Qn9hzB7Fqzr1F6ZXoTdEdZ+BF7hvVIfxpU7kLUoG90S6mndS/oZ8+rO
QiZJmwrQqCBTYUP6URgpyO5AZ0qSwkVJEmSSJMgkSRJkkiTIJEmQSZIkyCRJkEmSJMgkSZBJkiCT
JEmQSZIgkyRBJkmSIJMkQSZJgkySJEEmSYJMkiRBJkmCTJIEmSRJgkySBJkkCTJJkgSZJAkySZI2
CjL9tQlJkP1s6S9qSptqfGsBJG2iNmnO1GvUaW3Z+h/fVpgobTyIK4Hs8+fP3flsNlvqlPjL9mVI
0PvD7K1ytbsdFvb+IGTLnGDelJNlnQz0Sj3ZZDLJR3iu/IHquvNi5R9kj7b/Fzf1FzhHTte349vN
i/I15ktcl0a6NOKlcR9S06H/w/v377dXV1ffdGQBUNuDqbNURefFi0l53Z0BrO17tBI2jHPfEHOP
6zDcMf/m8/n4IHvz5k316tWrpXuLxSJ3hncQEOVOpEMwSe7u26Kz2+JI/VCiGBDRNsIQsZc+lPOj
DS/mrqz1KCi/j/l0fX1d7e7uVkPDtRJPdnBwUJ2fn1f37t1rI78y2DqY3GN1HVWCVUJ3ywAk5Wmj
h62DqYQo2mKOdKmZe6+WlOTy8jLPu77xHx1kJycn2aLwpekQd+etu/YMnF833lGNXTel5fJ72qly
xyAroMrzIcAK2fxp3EA3ER1FmsHrW1tbOSdj7j19+jR7t1EVPl6+fFm9f/++u97Z2clfmk7AhdsR
8ORODNA493bh4AV0jXd+1/p7Wx3jOGLsY3xLwIqx7+YEgMXcKd8XRpw5tr293Rn7P/74I8/L0Xmy
w8PD6sOHDxWAuRvnoIO6zvHzrgPDmwFaFD884W1vDFxOdFMktiqAjKPgEcWwomoY3qubF6T2zAuf
I4u4jxfj/YA3nU67sPLq6qp99OhRjqZGXV3c29vL1UXzZhUtoHlH0kF0FJ1GhjrxY36Lx82A0Xf/
LTSlH1WnpDVWOX7leeRVZbjIHDGQFjFf7Hzu8yYMdHfuHi5XrB8/flx9+fKltdxsnJC9ePGifffu
Xe7Do6Oj9uLigpAx4usGj3bTp01YJzpwnm56N3kHV27JgK7216qivW3g5No2sJJYjGdbRC4ROi7c
cwHXnDnjXm1eerbI0cj1ba5lT7a/v0/q0o4SMmRwZUvClyVGhg3gsnM6IoPlForOqh2uVOz0aHuA
1QFSAZigGgF0xQaEfkWxCSMMVNbOrJ15O3fDDGDZcHtK0hXOmH89o//zvfRQvXZ8fJwODg7Ss2fP
0pMnT2oDDUBySGgdROC8bZ2xZX2zZfe3ubbXtvyY+hEhZO1H8rBxyYsVYaS0eWFje5s3i3zMX1/E
AVzWzsw4A9m1Gezc2v1r4AM882Lz8/PzBdA9ffq0ub6+bm0utr/99tsgHm0wTxaVHIofJKP2pbOF
wYFZftZ4HpYiPIwQ0Ds4qkgAOXG4andfyRewM1yxyi9trvrb6qqbTQpLhQ+HDK9FuMgx8+uZh5EU
QBpSEqKm3d3dHD2xTkvxrax2jypcxEV//PixopQaoaIBl2azWeLc3XwKD1uUZaOSNOmFi1H8SL2t
WdKGF0GK8SwXmBuHLYobOWS0uQNUhIz53JSLIcwp81qNzbdcAEEGWDtkqFh56DWIfv/999x/ZlEA
Kj148ID8DMAydMAWIUJ4JjxdrIkVsXjjeVvTS3a7eLwMJ3Rs3hFj6VXDeeRafpT5V24JFfmZ+Dlv
cxEtih82z/I6GRVGSvinp6cxJ8flyQgZS2/28OHDFktjlie5Vcor8oBXWKyJgzPxYkj2ZBFSxjpZ
gCmNw5P1HnsqF5Yb3+u6KNZPc7UxSvkcZrQXlvcTKeUcjOoiXox/jFDx9evX7eg8Wd+b/f3333gv
gMpejW0ugMfaWW/zZ+NbZJYWIaMSSTwenqzsZB2be0SeFV6qyL261xhrDs75GVoq1MBFLmbziPMM
mc2vHHVeXFxkIz+kFxvck/W8WQKos7Oz1l37zQeaTgGKjWhYKwCrDUQ6rDaPx2UUO1hpjEJH8j1q
XVVK2vx8jLHlaY0ofPjrUWGkaNb4JoactwNY7BoKwNgUzJIRbeRiQ3qxQUv4pexLJvvClYOW2L9o
nVCbN0vWcQCWdnZ2cpnfvBu9FmtmXUUxQsQAzQcjtmxJG6x40LL3QG+31hVrZhhivFYURGJtjLUw
7lv+1QHm1cR2aMBWBtn3QDNQknVIwmt9+vSJNh/WcdmDEVoWFUUsHVDmMDMgk8YjICONoAWoeHoj
ciyWf2zsc0t53quJLbm+52PVqgFbKWQlaObCU/F5WKzO8JhFqq3Dsre6vLzMcOGpaL30v2TxpPGJ
8eZZMFpAwhjzdDPb8mIDMdekHZzjvQCPPbIU2Fh0jnL9nYQsQHv79i25GnsaY5EwsccMzwZglFzD
y5mHw3Plz+2FkqXvQBFF2mz1fxcH3oh7jDeeis3lvqs+txQ02A8LXPFQMPNoVTnY2kEWoNGGVwvY
Dg8PE6vzAGeWKZm1woqlGITykYWvX79qm8fIhIeKc0AKI2pjne9bpMPc4HEWdnG0MW/KxeZVA7Y2
kH0PNs4L75ahowW858+fk89pJt4R8asreNiSbXno119/zSCV82Pd4FpLyPqwIULJ4+PjMm+rAj7p
bomnmZ89e9ZdAxVtuUVqneBaa8j+CboSvhAQSuNU+ctubvt1AesIlSRJkiRJkiRJkiRJkiRJkiRJ
kiRJkiRJkiRJkiRJkiRJkiRJkiRJkiRJkiRJkiRJkiRJkiRJkiRJkiRJkiRJ0v9F/xFgAJa682y6
tS9yAAAAAElFTkSuQmCC" transform="matrix(1 0 0 1 66.4 140.5879)">
	</image>
	<g>
		<path fill="#FFFFFF" d="M110.9,164h128.6c4.2,0,7.7,3.4,7.7,7.7v61.4c0,4.2-3.4,7.7-7.7,7.7H110.9c-4.2,0-7.7-3.4-7.7-7.7v-61.4
			C103.2,167.5,106.6,164,110.9,164z"/>
	</g>
	<circle fill="#E4F0E6" cx="173.1" cy="190.5" r="12"/>
	<g>
		<path fill="#4B575E" d="M149.9,223.3c-0.4,0.2-1.3,0.4-2.5,0.4c-2.6,0-4.6-1.7-4.6-4.7c0-2.9,2-4.9,4.9-4.9c1.2,0,1.9,0.3,2.2,0.4
			l-0.3,1c-0.5-0.2-1.1-0.4-1.9-0.4c-2.2,0-3.7,1.4-3.7,3.9c0,2.3,1.3,3.8,3.6,3.8c0.7,0,1.5-0.2,2-0.4L149.9,223.3z"/>
		<path fill="#4B575E" d="M157.3,220.2c0,2.5-1.7,3.6-3.4,3.6c-1.8,0-3.2-1.3-3.2-3.5c0-2.3,1.5-3.6,3.3-3.6
			C156,216.8,157.3,218.2,157.3,220.2z M152,220.3c0,1.5,0.8,2.6,2,2.6c1.2,0,2-1.1,2-2.6c0-1.1-0.6-2.6-2-2.6
			C152.6,217.7,152,219,152,220.3z"/>
		<path fill="#4B575E" d="M158.9,218.7c0-0.7,0-1.3-0.1-1.8h1.1l0.1,1.1h0c0.3-0.6,1.1-1.3,2.2-1.3c0.9,0,2.4,0.6,2.4,2.9v4h-1.2
			v-3.9c0-1.1-0.4-2-1.6-2c-0.8,0-1.4,0.6-1.6,1.3c-0.1,0.2-0.1,0.4-0.1,0.6v4h-1.2V218.7z"/>
		<path fill="#4B575E" d="M166.7,223.7v-5.8h-0.9v-0.9h0.9v-0.4c0-1.8,1-2.9,2.9-2.9c0.6,0,1.4,0.2,1.7,0.4L171,215
			c-0.3-0.2-0.8-0.4-1.4-0.4c-1.3,0-1.7,0.9-1.7,2v0.3h3.9v6.7h-1.2v-5.8h-2.7v5.8H166.7z"/>
		<path fill="#4B575E" d="M173.9,219c0-0.8,0-1.5-0.1-2.1h1.1l0,1.3h0.1c0.3-0.9,1-1.5,1.9-1.5c0.1,0,0.2,0,0.3,0v1.2
			c-0.1,0-0.3,0-0.4,0c-0.9,0-1.5,0.7-1.6,1.6c0,0.2-0.1,0.4-0.1,0.6v3.6h-1.2V219z"/>
		<path fill="#4B575E" d="M178.5,218.7c0-0.7,0-1.3-0.1-1.8h1.1l0.1,1.1h0c0.4-0.6,1-1.2,2.1-1.2c0.9,0,1.6,0.6,1.9,1.3h0
			c0.2-0.4,0.5-0.7,0.8-0.9c0.4-0.3,0.8-0.5,1.5-0.5c0.9,0,2.2,0.6,2.2,2.9v4h-1.2v-3.8c0-1.3-0.5-2.1-1.5-2.1
			c-0.7,0-1.2,0.5-1.4,1.1c-0.1,0.2-0.1,0.4-0.1,0.6v4.2h-1.2v-4c0-1.1-0.5-1.8-1.4-1.8c-0.8,0-1.3,0.6-1.5,1.2
			c-0.1,0.2-0.1,0.4-0.1,0.6v4.1h-1.2V218.7z"/>
		<path fill="#4B575E" d="M190.8,220.5c0,1.7,1.1,2.3,2.3,2.3c0.9,0,1.4-0.2,1.9-0.3l0.2,0.9c-0.4,0.2-1.2,0.4-2.2,0.4
			c-2.1,0-3.3-1.4-3.3-3.4c0-2,1.2-3.6,3.2-3.6c2.2,0,2.8,1.9,2.8,3.2c0,0.3,0,0.4,0,0.6H190.8z M194.3,219.6c0-0.8-0.3-2-1.7-2
			c-1.2,0-1.8,1.1-1.9,2H194.3z"/>
		<path fill="#4B575E" d="M202.8,213.8v8.1c0,0.6,0,1.3,0.1,1.7h-1.1l-0.1-1.2h0c-0.4,0.8-1.2,1.3-2.3,1.3c-1.6,0-2.9-1.4-2.9-3.4
			c0-2.2,1.4-3.6,3-3.6c1,0,1.7,0.5,2,1h0v-4H202.8z M201.6,219.7c0-0.2,0-0.4-0.1-0.5c-0.2-0.8-0.8-1.4-1.8-1.4c-1.3,0-2,1.1-2,2.6
			c0,1.4,0.7,2.5,2,2.5c0.8,0,1.6-0.5,1.8-1.5c0-0.2,0.1-0.3,0.1-0.5V219.7z"/>
	</g>
	
		<image width="13" height="13" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAFZJREFUeNpiYKAmUCnQ+49LjpEY
hXcmXGLEqolYDXBNyBrQFWGTYyLHX0zoTgEpxKUYJs5EjC3oTmZCV4iuAGY7shgLsSFGUpBjM4yR
2FRAyHaCACDAAIIMMVXsGFS7AAAAAElFTkSuQmCC" transform="matrix(1 0 0 1 166.4 183.5879)">
	</image>
</g>
<g>
	
		<image width="216" height="137" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANgAAACJCAYAAABO8va/AAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAD61JREFUeNrsnV1u3MYShdmckawf
y/oBEkBGgLxbj9lCtuCsx/vxFrSFPNrIqxHDBvJgy5ItS5oZ8tbXU8W0aDs39yLhjKhzAIYcztjR
NOurU11sWlUlSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIk
SZIkSZIkSZIkSZIkSdLolNb5h3v27NkXP9+LFy+646dPn+oK3hM9f/48709OTr4WJ60A+x+hAqQA
6OXLl93PeXx8rGi7x/r111+rx48fd6+fPHnSeoysJXBpncAKqADqzZs31U8//VS9ffu2Ojo6yj/n
u3fv8md//PHH6v3794q2e6bDw8Pq1atXxEPl8dBG0iVO+rCtA2jTdQHLBicDDzgB1KdPn/K56+vr
amdnJ21vb+c/c3FxUU2nyx/98vIyKfTGK7vuHSR//PFHRQxMJhOue2tgJYuRdnNzM0MHWFHhABux
tWrI0qrhAiwci4ExkNJ8Pq92d3fZp8ViUT148CBZpkq2rxjIq6urxJ7Pln/Xw4cPFY0j08ePH7tj
u/4tr7n2Nzc37cHBATHQGmzt58+fq7qu8zGJN5wNyIBulZClVcNljpWwfstEyQYunZ2dJRs8MlNq
mibZgKW2bdlwrYRjhXuRyaTxa2Njg8Sa95Z425QSW8UesAy81pytffToUQtcARpQUjqa2lVBllYN
V7iWDUZtpR/uhHPVwARgNoBpNpvVOBh7oGLD4djjctJ4xTW2xJv3Fg8ZEgestWtPeQhkDccWM43F
SIvDmau1Vja2q4YsrRouyj/LPhksG8QauBBwmdjXOBigOXgVrx2+DjCymzQuGSwZLK4zcNmW4cKh
eE2YANQyXJrGYGosNhqLEcBqcDvKSCCzKmkl5WIaGi4aGicnJxmy169fZ3fa29urrSTEnZKBMgEk
wLJtAmRsXibWXjLmn90h7P5+Py/dcZXXFLhaqHG4uMzuYNm1bL/gbUvQALWwGGpC+/v7ze+//56d
jNJxFXOywbuIwMUeuCgLLcNkuDimIgAi2wArb5PliGVXC8gAyy9Eho2LsA5NG+kfhSxDAFhc+gCN
Y06z4VzEDJDZ5xZMG+wjAJedj+6zVUfZyfi7/H7qOB0s3Ovnn3/OpSGbTUozYFYvT2wgJnbMYAE9
3YupDeCEzcvGSZSLblvJJecasZNxbcO53MWaKAmbZead23vME27trdLh2EJnAWCUjyspFQd1ML+J
XBlYZCbKw2Rw1WbvtQ1ChguQbFA3/GebUiY6cLlcpGpg7JmTMfilmykkx6N2qahOytIQ0HAuykEg
IunOMTembYQB83I+x1zMphx0F9OHDx8oE/PN6iE1KGBYNDeRca9oamxtbdUGGpUg8yvcCrg2gA3Q
2FMqAprDl2JOFg7s5YOicoTu5de1DRdjvuUl4iLAqpcUzuKz3lnMDQ4gOz8/p+OY4mb0kGXiYIB5
5zDfKKSZ4e35DIsNAt3DqZ3PruVQAddmOBnzMlwM5/KslRy2zsWKuZh0xxVNDS9Mlna2BC13CoGL
xMtlj46yg0PV01IW5nmFTeG5/UPM7e3tUSYO6mKDlFXlig3sGqCABefCpWwcpr7ftH3eOLaBArIo
FyfRBGFQi3lY517uatJIGhxlxVjC5Q429w3nmtn+xmLmxo5v7HOcu7H4mVuJCIh5XgZ0uNmQ3cTB
HCxWxLMMitUYiDkqpSJ7hyfmW2QhfrYN30oXq30ulnpQaRo2smlYH7ACrsbvkSZf0VHFecTcjFgx
uBasDrJ9rnaIPQAbsps46ByM+Zd9wQwZJSLrDOkQxg3laM17m34aYAVk/l7AVXuHKfl+Je4s/TtQ
xTVdXt423ivnX909MsDzOXtuNxNPlITEFHvgItaIPWIw1iqODjDEqvizs7Nqa2uLO/X5y9PgcGDi
Htg07oXZflrANvEuYrTrv+pcanjc7eZGuY9uopeNAVgqrjNwZeh8jt7FEqwxz+dDxNz+/j4JfdDg
GAwwnu9CPG5AWRhNimXCyaNZ+6qN7FLhZH7/q3S2etnPqLsSMYDqXxzpDltZ4WCFu6WiMgngYqVP
VwnFogQ6h9FtJuZYjc/Ke2KxfGjzzgPmN5hzB5Fneli8SZnIWrNYVxjyAakLp4q1iTH3qosysWzT
i6oRacnIn236tihZHK66hKv6s7McK30ilvJTGDxbyOoOHtbl0ajT09PxlYi06eOhSSBz98ptdmpl
Mo47U7TgO6C+cpzUORy1g6WiNPTcm+dadQlYJNuyq1z5OtVIvCTyeF6QGLTkPlyiGOJ/0v/HaWhw
MAfjjjtAxar4Hly3jmMf52OLAdU2vq1/bcuY6J8vz/ExYsqfwMjz/f4DukP9g0kr+ycDyCqUjH5D
8RZE1Z8r5bsysJhXpb/oFMrNxtVNTP3X5Ty7D1rAF3N7YosYI9ZWpcEB4x5YPIlMdvHV0h1Ay2Vm
VVXc3+gGMsrB2+X4FwBKI2gmFp3gtrjmbT8WoiESW6zmIY7Yyls4xB7AjRowRD3MI+CIByXjocmy
G9gfwFix8a1HU9SaHzdwsWzKK55b4ETCDcjifBlbxNyHDx+Gb9as06S2rAFKkHoPVabeyCv8xkvV
FzHSuxVzK1bSnwsX1yYo6jWB6y+h+wuYRNc94OzvJOa/G1P31sH+Xwile9gBuUOxcFcAk1NJdzIm
9ACVJAkwSRJgkiQJMEkSYJIkwCRJEmCSJMAkSYBJkiTAJEmASZIAkyRJgEmSAJMkASZJkgCTJAEm
SQJMQyBJAkySBJgkSQJMkgSYJAkwSZIEmCQJMEkSYJIkCTBJEmCSJMAkSboHgOl3F0kC7N+UfpOl
dBeTbi14pLumuxQv9RoNWlvu/Zdd38pSX/nNhiod75lT9WOgHyPrBuFKAPv48WN3PJvNbg1K/Bb5
22PafnNA9atlR0zWX8Dk73XJuAyUb0G3Ck1X+T+fTCYZJsaFQeK3xbOFe3Ge98vfLM85P88gJpWZ
9wKuLsmWsVDsv3g/kjfx1E/qowZsZ2envb6+/mIwAx5exnExeK0D1zpwJVxtcUFE2UgbGOUUIuKh
cK4uEQdogMXrSNgRe/P5fHwl4vPnz784t1gs8ubO1XoKagvI2hjofrbqlQht78Jou/tb/3pWX0mm
3fvETxwHgAi4bm5uqo2NjftRIh4eHlYXFxfVgwcPovxrDbJyEDuwikzV2I4U1LhDJc5Fclh+JGki
NtIysbi2XRIuXayscDi2hN0y9SB585rjq6urHHP/LenfecBevXpVbW9vx9yrdRtv3dK7zANAyOHq
BtAHlnN1AOfnM2gKyfGolzS7uOC6EwMWL43HQhPAhZsBWZSQnMfBmIMRd99//312tVE52MnJSfX2
7dvu9dbWVv7yDAQ1sX3xzqUCKs9UMYC8t3C3ijqy9gyXosZWo2M8zlXMq9qiHOziAQVknmybsjy0
mMqg8fdYjLWbm5vZ1Ujyb968yTE5hIsN6mBHR0fVu3fvoibOgwBcnnE6wGwDJjw+tlQM/sRftw5U
6oOl1v2dda0vEqXzEs2NLtkSIxY3i4gVh24RsOUPu5OxXV9ft/v7+7mCGnUXcXd3N3cRzcUq9kAW
mcczUgwY7Z6JHc9LgtqwscLBio5jeYFkZ3e4c1h0liORNuFinnTnHiMB2dziZ1GUjY0ncOb51cHB
QfXp06fW5mLjBOzJkyfty5cvc+BTImLb5mQxx+pcy52LgaupAoqWfZSNtUNV9xxMQI1wDvaNEjFi
ZA5YftxVPTG1IGHjXBZn2cEePXrEVKUdJWDo+Pg4Z5HpdNraRDNnl9lsxkAsDLiJDUTORJZxgIdG
RnYnv1nYuKPV3uRI8X64lxodo4Orux3j8+6uRAwXs21m8TG3ZDwvS0c+a7GVq6Jo4xN7vWT/73+X
oQbt6dOn6fDwMD1+/Dh99913tWUUIGI+NbEBmBpgGwbchsG1Yec37fyGb9PYYv5VL1uOqWh6JO9E
KjpHoKJp1c2/HLpcJgIQSTlczLYb+9yMzWJoZjGU94BHGWmgLaw0bHZ2dhoSu8Vh+8svvwziZIM5
GF2baHTgYBcXFy1f2L58np169kkOS/J1iV3zw+vrSZSH/pnO4aLzJI2j2dFblxrNwXCjrhz0Ofqs
KBWZi1m+njdMQSgPmY5cXl7muRhNtrKjPaoSEWt+//59RcsUNvjiNh8jq0SnsCv7vMsY98N4H7hq
n3t17mWDmWIdo0rEUblYufggXgdkTXQQo8HhTY+ZxRTuRoNjcXV1xT67FqAZXO2Q5eHgjQHKRHOy
ZC6WzLKTwVabm1Hx5XIR2WBNfa6V9zaQgDVx14qtg7GYfykqR+hkXpncWrERgHkCjjlXNDrmFluL
z58/L7a3t3NpeH5+3rCSg+309LSlmnr27Nm4SsQoE8PFsOnd3V0yTL7Dzg1nX97SZStGj0zkkOXW
PC6GqUX7MNxLqznG1+QoS0T+s8y/+cZyuaoD0HK56Ps83QBCmhrRqse9IgaHgmtwwPhitnWr4AHL
ykU6io05WYYs2vH2OrLTxMuAtDS63OPI12FZPaZb7iUnG5+DRReQ16xd9cUIzKliuVQGivM0NPgM
ydvm+bTpmx9++CGXhS9evGijFzBKwMq5mH3hxBd//fo19l0DGRnLbL212hn3ivtgTcBF+x4QA67o
GuJ8q1wxLf3zigdxScLxxEU4mZeIuVyMZXY+pydRZ9BoolmZ2LJyg4qJmIskP2iSWMXg4WL2hfni
tO4rgwx4MkDmaMkGKR/b4OXzNtg1d+CjNe8lZd4HYNI4FSvj6TzHI050B1kFxB7QgI+GWTQ09vb2
uLGcqyLmXbTlcbCh4VoZYCVkOFn8LMfHx+ns7CxDBGi4FMcAR6nAnswW+wBMGrcigTKF8Icmuwdv
OQYwluBZ7LQHBwcs7M2vca5VwrVSwPqQscrDsk6y7MPjBAmHYmAvLy8zZDgY7X0rH4Evf7b8ux4+
fKhIHJH6j/jjRDxmwjI79jjY0dFRXgJFosXhfJVQ/ixNtGjJrwqulQMWkPm87BZo3BBk7dj5+Xmy
bMQSqxQrocsV0QCocByvcKw4tjlVl0y5cWwxQYy0FiN5AUMJFo+iDN0xXJsmRw+wmHwmujwOWn5m
B9hs8Lh/gYPRas1/hofmsH+Em0njVfwbGszV4/qTYKlocCyS72+//dY1z05PT/OxAdYO9dTyWjvY
txzNYOPGdD5XzNMydNL9VbnMKTqD4VZlwl4XrXV5Vdwz6wR4oQBQGrdKJ/rafax1g0qSJEmSJEmS
JEmSJEmSJEmSJEmSJEmSJEmSJEmSJEmSJEmSJEmSJEmSJEmSJEmSJEmSJEmSJEmSJEmSJEmSpJHr
PwIMAAn4ceVXxWylAAAAAElFTkSuQmCC" transform="matrix(1 0 0 1 67.4 -0.4121)">
	</image>
	<g>
		<path fill="#FFFFFF" d="M111,25.7h128.3c4.2,0,7.7,3.4,7.7,7.7v61.3c0,4.2-3.4,7.7-7.7,7.7H111c-4.2,0-7.7-3.4-7.7-7.7V33.4
			C103.3,29.2,106.7,25.7,111,25.7z"/>
	</g>
	<circle fill="#EBEFF5" cx="172.9" cy="52" r="12"/>
	<g>
		<path fill="#4B575E" d="M137.3,83.7c0.5,0.3,1.3,0.6,2.2,0.6c1.2,0,2-0.7,2-1.6c0-0.9-0.5-1.4-1.8-1.9c-1.5-0.5-2.5-1.3-2.5-2.7
			c0-1.5,1.2-2.5,3-2.5c1,0,1.7,0.2,2.1,0.5l-0.3,1c-0.3-0.2-0.9-0.4-1.8-0.4c-1.3,0-1.8,0.8-1.8,1.4c0,0.9,0.6,1.3,1.9,1.8
			c1.6,0.6,2.4,1.4,2.4,2.8c0,1.4-1.1,2.7-3.3,2.7c-0.9,0-1.9-0.3-2.4-0.6L137.3,83.7z"/>
		<path fill="#4B575E" d="M148,85.1l-0.1-0.8h0c-0.4,0.5-1.1,1-2.1,1c-1.4,0-2.1-1-2.1-1.9c0-1.6,1.4-2.5,4-2.5v-0.1
			c0-0.6-0.2-1.6-1.5-1.6c-0.6,0-1.3,0.2-1.8,0.5l-0.3-0.8c0.6-0.4,1.4-0.6,2.2-0.6c2.1,0,2.6,1.4,2.6,2.8v2.5c0,0.6,0,1.2,0.1,1.6
			H148z M147.8,81.7c-1.3,0-2.8,0.2-2.8,1.5c0,0.8,0.5,1.2,1.2,1.2c0.9,0,1.4-0.6,1.6-1.1c0-0.1,0.1-0.3,0.1-0.4V81.7z"/>
		<path fill="#4B575E" d="M151,75.3h1.2v9.9H151V75.3z"/>
		<path fill="#4B575E" d="M154.9,82c0,1.7,1.1,2.3,2.3,2.3c0.9,0,1.4-0.2,1.9-0.3l0.2,0.9c-0.4,0.2-1.2,0.4-2.2,0.4
			c-2.1,0-3.3-1.4-3.3-3.4c0-2,1.2-3.6,3.2-3.6c2.2,0,2.8,1.9,2.8,3.2c0,0.3,0,0.4,0,0.6H154.9z M158.5,81.1c0-0.8-0.3-2-1.7-2
			c-1.2,0-1.8,1.1-1.9,2H158.5z"/>
		<path fill="#4B575E" d="M161.1,83.9c0.4,0.2,1,0.5,1.6,0.5c0.9,0,1.3-0.4,1.3-1c0-0.6-0.3-0.9-1.3-1.2c-1.2-0.4-1.8-1.1-1.8-1.9
			c0-1.1,0.9-2,2.3-2c0.7,0,1.3,0.2,1.7,0.4l-0.3,0.9c-0.3-0.2-0.8-0.4-1.4-0.4c-0.7,0-1.1,0.4-1.1,0.9c0,0.6,0.4,0.8,1.3,1.1
			c1.2,0.4,1.8,1,1.8,2c0,1.2-0.9,2-2.5,2c-0.7,0-1.4-0.2-1.9-0.5L161.1,83.9z"/>
		<path fill="#4B575E" d="M177.7,80.4c0,3.2-2,4.9-4.4,4.9c-2.5,0-4.2-1.9-4.2-4.8c0-3,1.8-4.9,4.4-4.9
			C176.1,75.6,177.7,77.6,177.7,80.4z M170.5,80.5c0,2,1.1,3.8,3,3.8c1.9,0,3-1.8,3-3.9c0-1.9-1-3.8-3-3.8
			C171.5,76.6,170.5,78.4,170.5,80.5z"/>
		<path fill="#4B575E" d="M179.3,80.5c0-0.8,0-1.5-0.1-2.1h1.1l0,1.3h0.1c0.3-0.9,1-1.5,1.9-1.5c0.1,0,0.2,0,0.3,0v1.2
			c-0.1,0-0.3,0-0.4,0c-0.9,0-1.5,0.7-1.6,1.6c0,0.2-0.1,0.4-0.1,0.6v3.6h-1.2V80.5z"/>
		<path fill="#4B575E" d="M189.5,75.3v8.1c0,0.6,0,1.3,0.1,1.7h-1.1l-0.1-1.2h0c-0.4,0.8-1.2,1.3-2.3,1.3c-1.6,0-2.9-1.4-2.9-3.4
			c0-2.2,1.4-3.6,3-3.6c1,0,1.7,0.5,2,1h0v-4H189.5z M188.3,81.1c0-0.2,0-0.4-0.1-0.5c-0.2-0.8-0.8-1.4-1.8-1.4c-1.3,0-2,1.1-2,2.6
			c0,1.4,0.7,2.5,2,2.5c0.8,0,1.6-0.5,1.8-1.5c0-0.2,0.1-0.3,0.1-0.5V81.1z"/>
		<path fill="#4B575E" d="M192.2,82c0,1.7,1.1,2.3,2.3,2.3c0.9,0,1.4-0.2,1.9-0.3l0.2,0.9c-0.4,0.2-1.2,0.4-2.2,0.4
			c-2.1,0-3.3-1.4-3.3-3.4c0-2,1.2-3.6,3.2-3.6c2.2,0,2.8,1.9,2.8,3.2c0,0.3,0,0.4,0,0.6H192.2z M195.8,81.1c0-0.8-0.3-2-1.7-2
			c-1.2,0-1.8,1.1-1.9,2H195.8z"/>
		<path fill="#4B575E" d="M198.5,80.5c0-0.8,0-1.5-0.1-2.1h1.1l0,1.3h0.1c0.3-0.9,1-1.5,1.9-1.5c0.1,0,0.2,0,0.3,0v1.2
			c-0.1,0-0.3,0-0.4,0c-0.9,0-1.5,0.7-1.6,1.6c0,0.2-0.1,0.4-0.1,0.6v3.6h-1.2V80.5z"/>
	</g>
	
		<image width="13" height="13" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAExJREFUeNpiYCADMOKTXLttz38Y
O9jLhZGgJmQN6BqZiNWADJhI1YChCZcfcPoJXQM+A5iweRafBpw24dOAM/TwaSBoE0nxRBMAEGAA
4tAo9DlyAvMAAAAASUVORK5CYII=" transform="matrix(1 0 0 1 166.4 45.5879)">
	</image>
</g>
<g>
	
		<image width="217" height="138" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANkAAACKCAYAAAAnpO8vAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAEBlJREFUeNrsnctuG0cWhruauvoi
WwJsQAkQ7+2lXyALvYL8PH4fv4RfIEt7r40VIIidSLIkS2T3nK90TqfY5kyAzEyTbP0f0OgL6USs
Pv+5VTVZVUIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQ
QgjxD0ir/ge+ffv2u7/xw4cP3fHx8bHu4sh59+5dd/zq1atFNtJKZP9QWIgpRPTx48fubz08PJTl
3VNOT0+745cvX7YhwhDfKgourZq4QliIKsTEwB4cHOS/9fPnz/naixcvqi9fvsjq7gn7+/vVyclJ
PjZbqNwW2tJGEN0qCi6tqri+ffuWENTe3l718OHDdHZ2Vj148CBdXFzkf7O7u9v9+8vLyyQzHCd2
zzuhXF1d5f2jR4+45y228fXr1xbbQHiIrigpWsS2CkJLqyAw80BdOkjEmk6nWVi2T7PZrLItXV9f
p+3t7Wpra6vimD1CLP9bDL4YB+FMA7v37c3NTbWzs5P3du/z8WQyYcui43hjY6MTG5HN7GrpUS0t
U1w+EKR9CcEgLgTVNA0iSm3bZnERwTi2rbJBTEQuBhMYYDFucLSAfRDZbN+mlCrb2rqu4xzx5fMQ
G8L0Gq5dptCWZqE///wzIkMsyVPDZOG/tjSwthSgtkGb2ODWJjaO69vb24lfm2xububX2cBEOfHP
om1kG3DPwWylNkFxveYYZ4xTJuPBjiyy5cBhtpLOz89z1vP06VOiYi5H3rx5k96/fz+4rW8sM0Wk
cYHAzOMkE1dCTJzbeGZhITjzTgws14hkDG5E3xzxbKvYwMQntz8STCh3N9kilkWnHLXYiFBELstw
OG+4Zve9sWsNNsJrnJvtNKSU3iijPksR0UafLhYCSyEwTxVrIHr5fuLeKguO64gPz2Uv5/SRwUdo
3IiAlFKsN/37SQqII0VQts/pIAIzW2A/s/c09h5E1pg47fIsi85qdITW0hw5PDxs9/f3c402dNo4
uMiOj48TXoUGBykiEYz0D5GZ4HIqQJqAyEgHOSaCsflriC6LzG9I8htRxTUxCqGFsLivbVzz4ywo
xAW2p2hDaFOucc7rZlczXqc2Q2zslyG0QdPFopOYi9hnz57lFJGUEIGRb0/uOhkIbMMFRs21QXRD
aLy/EFneXGCyzBFROM5oXOR08e6lliiWoxdJj9kHIkNg1GgzajHsy6JaTi+vrq4QXEvqeHp6Onja
OKjIfDlUjmKkiNH0IBUkNURgNjAIir9rw4XGeRabNzvqEBhpI+liCGwubxTrLrIIYJEu5gsutByt
iFIevXKNYa9RyGV7wBSY8qHdb6KjA5ltw9PG7PCHimaDiaxsdliOnGup33//PTc56CBGNwmB2QBt
IjB7z6aLbAORsUXKGHVZL12UdY4Er7e7dPEukcnkNJGazKMXNnNb2gEaRZTUbKSNOHFSRWo5WvqI
bLSRjAlnohgTzcx1UYd5UyMiVRfBbJC2bMA2SRXttXwtROb1WXKvlQMYoo2bI9YbxOXRqI2o5OKK
tHHG5uXENDIbf19+P+mhdxzJkhBcbvezMsQnqMdXk5EqHh0d5TVm1GIMDMKgTU/UdwFNIoK5wIho
RLPNQoRZmCEyp7xBShlH0PSILmO/JotIZud0FadFI6wq6rbcEInmCBuLGyx1TI8fP6YBMmg0G8Qg
y7a9pYrUYvXFxcWEZocNwKaniFsc29u3bb9l+y2/thURzkUY3cZQVxr684hhyrJ+iVbUY4237qfU
YbYnXbyx19i+2fmN2dgN19mo2+x1BDkjup2dnbWx5GqIumzQdJEoZoVnThVjqRQFqTczau8iTrwG
42/bdIFFjRYrAeoQmHu7rthdhhMR/3NRVb372XUWEZnXY6kX+fJ1miAIkE61CY3mSI5g2IilkMkX
m7e+VnZcjY+ox1gxjbB2dnZY/kJHMXcXEZq36bv6LPZFJNuIxkekijEZ3ReYmiBrmSZ+dxyT0VGP
FU2PskxoouMYDTSv9bMDx8ZYasXCB1+xn8oV+6OKZEAEY7kLYxZLpShOvS6rPR0sGyGxNnGjaOHX
RdMjLfB8lbr5ax7W/H4Wk9FRl9UerbpazW2lLur1vGFTvtSKKFb98ccf1ZMnT3Dq450nYzKQ58As
ZFcRxpkri5QxtiJa1bHqIwbQBzS50LqUUCs+xoVHr7lU0tPBNoRXCisaZ5V3n6MjRjSL7iPNDx6h
4bm0T58+VT/88MMwn2WozmL5lQE8vkJdFo+p3M0jplSs5OiWUZXLqWLwYh9pY9Vr62tb/62476l/
/z1V7NtFl91EKVGc50ekmJgGnqp//fr13HfFjCaS8eF4BKFICcqBSOVKjmp+Hmxu8HvLqtTguD+N
kboX3eZso+d4u0Xk7FnVHw/1siCCbGqwqDzkKMV3cpRPNJM2FhPI3Qr73sDNtevL16vvW/jaxrEt
uqf9ptacrRSLxrtHZLAnHvrkWbP+k/RDfdPZIJGMD9P/0htSxVg64x3CPFBxXEawWNGxYF6si2Jq
dIyv2divyf7qaaV+ahmOurMdv5afN2TBcDyftgwG7y6WtRgehkGIdYex73WWqlg6tcC7VQu8mxip
6PqCW2AnVbF+MRz4XOcZ+0N0gzZxVqibVBWrrrsJ5lJAiyacFcFGrqwFCwyKpy7m6vtybi0on5Zf
1hctLUVk5YeNQeg/hNlLD7pB7bXppbD7FcUWCa9MF6ti3nRlbGMlItmiVE/zXuL/YVf3VmRCrKuA
RiUyNTbE36WREtk9GEwhlC4KIZEJIZEJISQyISQyISQyIYREJoREJoSQyISQyISQyIQQEpkQEpkQ
EpkQQiITQiITQiITQkhkQkhkQgiJTAiJTAiJTAghkQkhkQkhkQkhJDIhJDIhxFqJTL82ISSy/zf6
RU2xrs5X6aJYS9bJ6darOmD8oDa/bCtzEusuxKWI7OLioju+vb2NAcmCau9+YXsuJej9MHurWu1+
p4W9H4TEXlqccmksq+SgVyZd9Mg1F8XKH2SPff8XN/ULnCNX1/f3t7OL8jXsJc5LJx1OvO/ch2Rj
6P/hgwcP2m/fvuXjyWSSByQEVg5SfwBLERaeLa1jji7+kcDafkQrxVbX9Xe2wzXO2Uekw/6m0+n4
RPbu3bvq6Oho7tpsNsuD4QOEiPIgcuwpYwxqG+9xV9VW8z9v2xY3REobaYrYKx/m7COiWNiHZ0H5
fQjs5uam2t3drYYW11Ii2f7+fnV+fl5tb2+3fHD3Nl0+zQCF2MpBjIGNgS9Tg15EU+44XrG1C7KW
TkyFzXSRzO2qJWO6vr7Odtd3/qMT2cnJSfYofGi8Ch86vE5/wPoCi/cYjFy9qD4To49ojd33xm2g
CdsI+3DbacpohtC4vrm5mWsybO/58+dVOPnRND5evXpVnZ6educ7OzstuTEpI2Lz6NUUEasbSPYM
rL/eDXI5oHG9L05t67sV97zt3fvS2TY94ZXOeC66mZ21W1tbnbP/5Zdfsl2OLpIdHBxkT+KND7xL
AwyAex0GalaIZmb7mTuDFNHL04W2uJYi5VQDZBwsaHS1hcBmpeN1Rzxze+kcLs57Y2Oji3Rmd+2T
J09yNjXq7uLl5WXu7lg0y2Iz0WWvBcXAzcqN+jXd5QPloHOe/krT0991p8SKU97DXuewFFjngAlQ
hSNuygwoHDVCw3Ro5T99+rT6+vVra2XKOEX28uXL9uPHj9Xh4SGj115dXeVI5h2gGJw8YDYwMYCI
qy4UFIOdr4Xwiv2im6bQtobdxLifnqksSiOndnlq51O3FY5xyOGYG6KYlSSNbV0k29vbo3RpRyky
MIFlT8KHjxTRIlmOYp4CzHw/tUu1vVbfBbisE96zUaSP9V0WkPqRTKIaR1Rr5zXXNb/K9BBx3YbY
2Lv4ZmZjM38ftVhXq2F/pdMf5LMMNWjHx8fp6OgomRdJz549q82r1BbCJ/bhEc6GDcSmeZxNG5gt
i2xUqBRsmxzbHmfA+yYEvhCZK+u7KFakkWKNBVYsVCg7zZEKzkJUdozQbmzPxhKPGzu/9eucT82+
Ziaw5vnz583NzU27v7/fvnnzZpCINlgkiw4jzQ8iGdvZ2RlepjGxsc1MUOgmR6iYxfeGRuMpAELr
BOZiSkX+nmKWX6x302NBCtkUHcNZbAgtBBXRjOtmBzPLlmZkSl6T5YUOzNN+/vx5rts9qnSREP3l
yxdSRtaU5XyZdBAvYwMSgplGZyla+z6YE08FJh6B6xBWWZOp2TGOBki53K6aX5xQNjei8RECu/VI
NsVpIzDeS01GyggmsHbIVHFQkb19+7YlZSSiEc344LC7u9t4ymeXZolJw4hgxfwH+ffErud00VPG
LoqFyKIJIjMdRTRry+5idAmLuix3FO21KYbDHoHRNCOKsSEyUkPLmhqaHjh3+gJDM2gkQ2ARzR4/
fpzb+aSMCI3BZLIQ8bk3YwA3LMIhsok3OiJVnBNZpImKYuOLZtzTcqmd07jAuo60p43oDXHlYxMW
DZAsNHPe+b9BmsiG0x/qs0yGHLj379/n8TNRpR9//LFCbFF7MUnNCpDisZa8JMa7j61PMs58tUfj
nmvmaUIeZK5VvTk2beu5edlQtuhnXi7kc4TEnvf4tYhmeQoIu9nZ2clzZY8ePcrlHPNkP/30U/vb
b7+FLY4vkvWiWTIvUz18+DCH8z///LNbDeJLrohY5NN0IPFIzCzmhgeveUqRoxn/zteoRQNEoWDN
o1hZYxeLyHMYYwHD3e3PzjfSxgZhUYvxgkWvfE6aSB1maWLuKFKLDRnFqmpJc0r2IZMJjUiWbBCS
DUKObojIRJdFhIBqRs6uWajPr1GzFW37vI/niIiCQE0n1p942DIcKEIr1yEW2U17ZyZNs7293Vxf
X+e516IOK59Da4cW2NJEtkhoviq/Pj8/p9tIlMtiswFL5pWIVslrr3xMxOMYcXktJ8scIYiMFfP+
gG/rJUWusbw7nRc28EQHwqJrTQ3GiqKDg4N8nRrsw4cPLVnUvRJZX2g+b5FYdkU7H7Ht7e2ly8vL
FPNmEb1IMxEh3o41kOUj5mJ8hAPFEfuTza03RvLcV6weQlyck05iIyGwiGTLENjSRVYKzXJlBEZN
lug8npycJG+SZHGxqJM9D98R6fBuVtDm95f/Pa6J9af/fRzxoC+PSbEnk+EYQRHl6FSHuKjB+Dfl
fNiyBLYSIguh+aB0YvPVIZ3grFbL7zVPluIGlI8sEPFkmuODyBXHlgJ2jhRRsdiXmuvs7CzPvYa4
YJnp4UqK7N+JjeMQHNd+/fXXfI1lMS9evMhTAOJ+wFdX8LAlICi3gzYml91GWr5SIB7GXAWBrZzI
+mILwUGIDj59+lS9fv1alncPKdccIir2qyislRfZfxJdkQ50x8fHx7K+EVN+4c2irwxYRWEJIYQQ
QgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghxH/NvwQYADs4
aQARmoAbAAAAAElFTkSuQmCC" transform="matrix(1 0 0 1 66.4 277.5879)">
	</image>
	<g>
		<path fill="#FFFFFF" d="M110.9,302.1h128.6c4.2,0,7.7,3.4,7.7,7.7v61.4c0,4.2-3.4,7.7-7.7,7.7H110.9c-4.2,0-7.7-3.4-7.7-7.7v-61.4
			C103.2,305.6,106.6,302.1,110.9,302.1z"/>
	</g>
	<circle fill="#F8E7C9" cx="172.9" cy="328.5" r="12"/>
	<path fill="#6B4E1C" d="M169.4,323.1h7l1.3,3.4v7.4h-9.6v-7.4L169.4,323.1z M170.2,324.3l-0.7,1.7h6.7l-0.7-1.7H170.2z
		 M176.5,327.2h-7.2v5.5h7.2V327.2z"/>
	<path fill="#6B4E1C" d="M172.3,326.6v-2.9h1.2v2.9H172.3z"/>
	<path fill="#6B4E1C" d="M173,331v-1.8l0.8,0.8l0.2-0.2l-1.1-1.2l-1.1,1.2l0.2,0.2l0.8-0.8v1.8H173z"/>
	<path fill="#6B4E1C" d="M173.2,331.1h-0.5v-1.6l-0.7,0.7l-0.4-0.4l1.3-1.3l1.3,1.3l-0.4,0.4l-0.7-0.7V331.1z M173,329.2v1.8h-0.3
		v-1.8l-0.8,0.8l-0.2-0.2l1.1-1.2l1.1,1.2l-0.2,0.2L173,329.2z"/>
	<g>
		<path fill="#4B575E" d="M152.7,352.4c0.6-0.1,1.3-0.2,2.3-0.2c1.2,0,2.1,0.3,2.6,0.8c0.5,0.4,0.8,1.1,0.8,2c0,0.8-0.3,1.5-0.7,2
			c-0.6,0.7-1.7,1-2.9,1c-0.4,0-0.7,0-1-0.1v3.8h-1.2V352.4z M153.9,356.9c0.3,0.1,0.6,0.1,1,0.1c1.5,0,2.3-0.7,2.3-2
			c0-1.2-0.9-1.8-2.2-1.8c-0.5,0-0.9,0-1.1,0.1V356.9z"/>
		<path fill="#4B575E" d="M163.4,361.7l-0.1-0.8h0c-0.4,0.5-1.1,1-2.1,1c-1.4,0-2.1-1-2.1-1.9c0-1.6,1.4-2.5,4-2.5v-0.1
			c0-0.6-0.2-1.6-1.5-1.6c-0.6,0-1.3,0.2-1.8,0.5l-0.3-0.8c0.6-0.4,1.4-0.6,2.2-0.6c2.1,0,2.6,1.4,2.6,2.8v2.5c0,0.6,0,1.2,0.1,1.6
			H163.4z M163.2,358.2c-1.3,0-2.8,0.2-2.8,1.5c0,0.8,0.5,1.2,1.2,1.2c0.9,0,1.4-0.6,1.6-1.1c0-0.1,0.1-0.3,0.1-0.4V358.2z"/>
		<path fill="#4B575E" d="M171.2,361.4c-0.3,0.2-1,0.4-1.9,0.4c-2,0-3.4-1.4-3.4-3.4c0-2.1,1.4-3.6,3.6-3.6c0.7,0,1.4,0.2,1.7,0.3
			l-0.3,0.9c-0.3-0.2-0.8-0.3-1.4-0.3c-1.5,0-2.4,1.1-2.4,2.5c0,1.6,1,2.5,2.3,2.5c0.7,0,1.2-0.2,1.5-0.3L171.2,361.4z"/>
		<path fill="#4B575E" d="M173.8,358L173.8,358c0.2-0.2,0.4-0.5,0.6-0.8l2-2.3h1.5l-2.6,2.8l3,4h-1.5l-2.3-3.2l-0.6,0.7v2.5h-1.2
			v-9.9h1.2V358z"/>
		<path fill="#4B575E" d="M179.8,358.5c0,1.7,1.1,2.3,2.3,2.3c0.9,0,1.4-0.2,1.9-0.3l0.2,0.9c-0.4,0.2-1.2,0.4-2.2,0.4
			c-2.1,0-3.3-1.4-3.3-3.4c0-2,1.2-3.6,3.2-3.6c2.2,0,2.8,1.9,2.8,3.2c0,0.3,0,0.4,0,0.6H179.8z M183.4,357.6c0-0.8-0.3-2-1.7-2
			c-1.2,0-1.8,1.1-1.9,2H183.4z"/>
		<path fill="#4B575E" d="M191.9,351.8v8.1c0,0.6,0,1.3,0.1,1.7h-1.1l-0.1-1.2h0c-0.4,0.8-1.2,1.3-2.3,1.3c-1.6,0-2.9-1.4-2.9-3.4
			c0-2.2,1.4-3.6,3-3.6c1,0,1.7,0.5,2,1h0v-4H191.9z M190.7,357.7c0-0.2,0-0.4-0.1-0.5c-0.2-0.8-0.8-1.4-1.8-1.4c-1.3,0-2,1.1-2,2.6
			c0,1.4,0.7,2.5,2,2.5c0.8,0,1.6-0.5,1.8-1.5c0-0.2,0.1-0.3,0.1-0.5V357.7z"/>
	</g>
</g>
<g>
	
		<image width="217" height="138" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANkAAACKCAYAAAAnpO8vAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAEGtJREFUeNrsXc1u1FgT9bU7PxAg
JBqNhtkgzexgybPwPjwPvMIs5gVYwoJdNBJBw4gEQkhIbN+vTqXKqXZ65vs00me3nXMky253E7qv
69Q5Vfe6uygIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAI
giAIgiAIgiAIgiAIgiD+BdK6v8EXL17ceI9v3rzpjp8/f86rOHO8evWqO3769OmqGMkk2b8kFsjk
JHr79m33Xh89esTIu6U4PDzsjp88eZKdhE6+dSRcWldigVROJgzs/v6+Pv/p0yc99/jx4+Lo6IhR
d0uwt7dXHBwc6LHEQmGxkGOMgHROuHUiW1oXgoFcMjjd+wGpQKgHDx4UOzs76cuXL8Xdu3fT169f
9fk7d+50//7bt2+JYThPyDXvyHJ2dqb7e/fu4ZpnxMbp6WlGbIB4W1tbeR3JlsYml8l+ZwdBrrqu
i6ZpEkglez0+Pz9PMojF5uZmgWPsv3//vvT+MfjEPODJ1AECXVxcFNvb27qXa49YybLPVVUVi8Ui
g3CyXyKbxNXoREtjEwzvAZIPwji52rYFiVLOOcmg6WMcy4bBTFAuDCaAASbmDSRaAPEBZZN9TikV
sulxWZZZ3E4+Pj7W4x9++EEfo5wQmzk60dJYBHN76AQTa5jEAiq5NjY2QLgS50EqGbgkZCovLy+V
bCAW9hh8KJpfBGJ+wLX26wtS4Vjio4WSYS/oiCbx0WJ///59KFzrqjY20UYjWfi/E2ouGQwllgxi
KZYgySCVgAxiaSqGh0o6/COcg8LJue4iyKAzKmcCIUxHMrnOBchjyoVzSi48RoiIfcwSP62UEa0k
6ha2EecfPnyodnJsoqUxCIYaTKQ8iW/uCCYevBQClVAsIUsFsoFgMpAgmZyulGiwkyAbSAaCgWiw
Dg5YSmLa6F9PEAxEA6lALkCOW5BNDluJidYeN0ZAxe7ubvvHH39k1G5jEm0xFsFgBcUq6h4SJRlI
iYXkBUWTMcJ708d4HsdOOksOyQY+2YUorsaejcaZEM2JhUTqpFCFsueUYLI1UjK0onyNxA1ioUE8
QAE/f/6MLnQLO4lONZI6/sbQn2Ux9H/oXUS0X8/OzlB/lSAVIEpWifQvVLZSQkdjIaexdwIqyYxY
bjmTEYyROSOExFkYMdQeYt9e2ZcWPRHZNwDcDWIBTgd1utlKtZroSgtaSepQM032Q6rZYCQLKqYZ
xQZD6zAZBKhXJQMKgimx5HjD1KxyVQPxzD46ubzz6NmPMjYfkmW/rmYXc74+iSIcRGtMuWpLvjXq
cokt7T6iOYIu48nJibsmJPliaDUbXMkwfwEVQwNDVAs1WLL6C7awcoLJIG1A3OxYVQ01mVnI0uuy
nl1kdM4EVm+rXTRiuZpBwVTF5Hkomdoga4QVV5XFFbzdL8kc+4SuI1RtaDUblGSwiphsRh0GgGjW
zNBmB5TMCYa9H+OlUDRsaIK4TcS1wPibb09+cYhpA2Sx65p971YRdZhbRdlq1OnWFPM4wF5fY8m7
xZQQ1Ozjx49akw1dmw1GMreK8MtWi0HJlCjVVUuoMju4CGTbNLIt7HzldVkg2pJLdLIR02569LqM
fSVrbNNutCuYtfUxV7Ywy4hjJHEswcpYKWTL9eZnF311h1tFrDsEGWyAlDQA6jKzhp2a+d7ea2UE
K90qul28vj7k2IzKshskMyVrrOlR+lwpiIYci+cljrSdL0C8QMlarBJC3IllLNwyzk7JvKuIeTF8
YKzeACD1PYK5ai3MJjrB/LF3GVNQM7cXN5IiY3U6pOp3F33plHcYfX7MkmxpOdZb/B35iqtuNOKp
ccdkCxrSwcGBWsUh67JBazJINVZMg2j44KjJkI3QaTSFinXXokeuDXuus4uAT0b3CcYmyCRt4o1j
X+1hBMvW9EjBsrQWJ43FTVdS2LpXrdmwggQKhrY+bpOypD+/xod1fNLx8TFWUxe2DjH5PJmrlHcR
MWiubk4+e6wK5pZxlZLRNk7eK7qSLXUXvSQI6oYkDJuIGKmtO61JGASzJNwpGf7Rhw8f5tn4eP/+
ve7hi32FvS3yjYPQLaOK9VewB56lkg1kZwl9xQfDcx4w9VqyklaPdS7F1K2JcRMStNvJ5BPV5+fn
xe7uLmJuUJszSL8bK+6fPXumMg3gfqC40DfIfwqPyzgvFi1iPF9ct/KXOo7cpr2Fa5w8sfZjYsWW
IslslZC6JazIR3LHfWpYYuVJf3Z2ES18v6MZREOG8TkuRxjY2D1MfSvZa2xQwW6JfbRrn0Nijo2w
Lnb8PkPElt0Wk05PT7uaDLdY/fbbb/MhGb6zI34fByajQTbcTo7sEmsoDGaoteIaxX7dlVaQi2Sb
X7cx9ZpZOdbjcd+Li2RzpmoX0fjwO+cRizs7O/NUsgh8aExM+0p6HxAnV1S4qGx/Ryw2OubXbOzX
ZNc9rdRXt9gIK0K3WefQsFjYknqyxcKDYnCSYY7MpRw3W/psfWzZ9juEK+bAbpCNLfv5k+76Ut+o
451YS51Eu4Nek7nfBNp3WPE7HSfd+OgjfuFNvJs5rKZf6hRGsvXJRQWbObN617fXXbwRH2FRsR7b
FzEtJfmhP8ParKZ1UrkXCC359A+EIsNul4qtTL5+PpJtnRaKl2tCMIYR8W/Vbenui7A4IfWd0lhf
GTiZ+0JIROK/uaB1jZmpkIy2kJgseIcjMVX1IskIgg6HJCMIkowgSDKCIEgygiDJCIIkIwiCJCMI
kowgSDKCIEgygiDJCIIgyQiCJCMIkowgCJKMIEgygiDJCIIgyQiCJCMIgiQjCJKMIEgygiBIMoIg
yQiCJCOIdUUmyQji/4Qp/S4dSUYQJNl0bAExmqrldVa7ckIDyWgirjLu8i+66AP/rfHsv3lsxIu/
Ff3169fbSzISiPhfnUxUrUi2q99jv/4NaScdlWyF5PtI2Q9s5zjAK36TijbyFqqYJ+X4G9H+GD9r
6z/OjuOqqnRz3L17N98KkkXZjnIeB21VxvKfA/4H60DMq9aK1zfHax1iJPvrssHjqGka3V9cXCz9
drTj1atXg3yOxdADh0zy/ft3PUaGwYBEeUf28WzlGWmFnfTRTrScs6+9OhJFtyNxko1Yet7tYnws
8ZXrutY4Oz8/L7a2tkbJyIuxBhCZ5ezsrNjc3ASZfNA6BAvZbZalcrH8C4s5XBAybX412LKEBdLF
2LDzS48RV75HvMFBgXA//vijqttQqIZS/l9++SWJNUxCprRYLJKoWVldmeVSBmEhA7Sw97Mhx5Ul
gMqOS9mXRqJyhWqRXDMnWy/RwvboJsfwhNhqeQq1Ry3naompRh5jqyXeWrGODZQN+Ouvv/K7d++K
X3/9tfj999/npWR7e3vFn3/+qV55Z2cH9Vg2O+gZp7DBg7K1nol8cC1btXALPb9OzLfJkSPJgjVc
RTi8pjU72Vqs6OthGyXu8u7ubnHnzp352cWnT58Wr1+/Ln7++Wf9gLCI8qGXLGEYMB0oECwOoG2l
vw7jaK9NwUJQ0ebR8MixixhLBUFrRHIFa/2cbyCY20SUIWINM3oBaLJ9+/YtS202z5oMBNvf31dP
/Pnz57y9va3ksuJUB8MGRjMQbAAGz/al2cTG614nmJPLGydsgExfvSLBvFtoMZGNSE20ihYjftyR
zlVP6jGNL1fFn376KX/69Gm+jQ9kkocPHxbWYcyuWE4oVy87rs0aYvPZxsoGHo+THa4kF+3kZJRr
5TGun3cR3QYamRAftddi2OR1TVC3TuEQX+KcssSdlinoMr59+7Z48uRJxn52JJPskR89epRANPPJ
UDL30FG5ULhWlplqb2yE7mJpKpa8q9izF/GCUdqm09yItVgkWKzLm9DsuLSGR21kU6K5sqHh4URz
VXvw4EFGPwB48eJFnhXJLHOk09PTLB9eBw0ZBgMpGQbdH3jpxrpDpSlYcrKEWqz1TqOrmNtGdhrn
QbpoFcNcWazROwVDR1HOX9pjHNfuiBAr2NDwQE2Gpht6AoeHh7oNptRD/UcvX75MR0dHSYiV5MOW
8sHL+/fvV1KMLmQgsKF1vyn7TSHhBvaSgTBNj/PYV75Zg8NJeEPF2ACZbsOjr2bF8lxYV1a4ghnB
Lo1gFyCdbBc4L/FTSz1WX1xcoIPf7O3ttUIuX9SYZ6dk7n/R/IB6QcWQYWTfCtFUyUzFkhx35LGG
SGvvtZsz85osqhgeY8UImx/Tha9Z7a3y6EjmnUV3Pa5g2CRuaoshtZNSijRSg7VCtK42k3IFU0mD
1WOD12RebIovRpdR5zIkyyTzzshMOlENkoXWfsxemJzGBJoqmSuW12acO5tHEySqmBPNYqDrLoYm
h1pEEEzi6VLiqgY8bsQetugBgFgnJyfoC6hVHErFRukuIpOgsyib1mZQMxswjCoaHt2SGSt83YNX
3sqPjY9IMrsglLEpF2TXS+riwyUls9rMmxy1xU8NgsEiQsGEZ9r4QIxJWaIqhr8xZFdxlCaBZI/k
/6eoGZZZlSLlpQxIubW1hRRU2VKryuo0XVaF/VUDsuzqMfs70VZSxWakZr44vOitXYX7CYlXO4lY
QuXNEBDM2/xnZ2fNvXv3WnS1hVytkSsPqWKjKJl3GSXDYGkVVuRrIQp1s5l4naH3bhIGDMSTgdRm
CdY+4tjuEUp2QXQy2ohGJZt48yMQrKvJMMflKzlibeYLF0zZWigYwkWSt+6x8n5/fz8fHR0VY6jY
4ErmaiYftkCnUYiVZBB0DzVzgExYzxkmoktr6+sCYzwPklltluw4Wg5G60QVzIGECWLB7qFpgeOr
XNt2y6t8Hkxip4WC4Um8RmqwFgqGkgRTRlKiZG92DK1io5As2karz5Rox8fHqlCibskUq7xaYaWN
DlUwX+FhFlH3uBiA36C36uY8Yjrwm3j9bmZLnko0NMt8UtnWJSrJULuji4gmB0gJ0kHBDg8PlWA2
J5bHINhoJFtFNKySloEpT05OdC4NREOnUQY2oQOJPW6VwXMgmNkH3WPBsZOMmAdAMtzzZS4l2w2+
WkpgLSI2U7hu5RCeQ5Pj48ePahGNaF7XFWORbDHyWELGYR2xaLMQaUcmUlWDH4CqifQrqZDhJFPp
Y9RzMsh+TrMfm4rzAhQM1xZrDeFOQCQkYqzcgHJhLSJIZ3faK+FgCdFFRO2FmMJjX9kxFsFGVbJ+
jYZmiKuaZCNksSQDlDDQIBmaIthj0KFmyHLiu/X18e/hHDFd9L+2DWqEc3ArUKrt7W0lFxpl2OPu
elhCkAvzYK5e3uQYk1xrQ7JgHYtINgyUSL7ayC9fviRf1CmZK/mFiDffQeEYovNB/FYpEMkTKCaW
cSyuB9NAmGv1hedFJNfY6rV2JPs7suE4DF7x4cMHPYdZ+8ePH6vNJOYPlBQHBwe6JA/A/WAgkseG
xczakWstSdYnmxMOcNIB79+/L549e8bou0XwO+tDXGSLixg3azl3MwmLFUnnePPmTXf8/PlzRuEM
Eb8XEV9hsSIuOCFKEARBEARBEARBEARBEARBEARBEARBEARBEARBEARBEARBEARBEARBEARBEARB
EARBEARBEARBEARBEARBEMR88R8BBgDmI6hw9TPpagAAAABJRU5ErkJggg==" transform="matrix(1 0 0 1 246.4 278.5879)">
	</image>
	<g>
		<path fill="#FFFFFF" d="M290.7,302.1h128.3c4.2,0,7.7,3.4,7.7,7.7v61.3c0,4.2-3.4,7.7-7.7,7.7H290.7c-4.2,0-7.7-3.4-7.7-7.7v-61.3
			C283.1,305.5,286.5,302.1,290.7,302.1z"/>
	</g>
	<circle fill="#F8E7C9" cx="354.9" cy="328.5" r="12"/>
	<g>
		<path fill="#4B575E" d="M318.6,360.1c0.5,0.3,1.3,0.6,2.2,0.6c1.2,0,2-0.7,2-1.6c0-0.9-0.5-1.4-1.8-1.9c-1.5-0.5-2.5-1.3-2.5-2.7
			c0-1.5,1.2-2.5,3-2.5c1,0,1.7,0.2,2.1,0.5l-0.3,1c-0.3-0.2-0.9-0.4-1.8-0.4c-1.3,0-1.8,0.8-1.8,1.4c0,0.9,0.6,1.3,1.9,1.8
			c1.6,0.6,2.4,1.4,2.4,2.8c0,1.4-1.1,2.7-3.3,2.7c-0.9,0-1.9-0.3-2.4-0.6L318.6,360.1z"/>
		<path fill="#4B575E" d="M329.3,361.6l-0.1-0.8h0c-0.4,0.5-1.1,1-2.1,1c-1.4,0-2.1-1-2.1-1.9c0-1.6,1.4-2.5,4-2.5v-0.1
			c0-0.6-0.2-1.6-1.5-1.6c-0.6,0-1.3,0.2-1.8,0.5l-0.3-0.8c0.6-0.4,1.4-0.6,2.2-0.6c2.1,0,2.6,1.4,2.6,2.8v2.5c0,0.6,0,1.2,0.1,1.6
			H329.3z M329.2,358.2c-1.3,0-2.8,0.2-2.8,1.5c0,0.8,0.5,1.2,1.2,1.2c0.9,0,1.4-0.6,1.6-1.1c0-0.1,0.1-0.3,0.1-0.4V358.2z"/>
		<path fill="#4B575E" d="M332.3,351.7h1.2v9.9h-1.2V351.7z"/>
		<path fill="#4B575E" d="M336.3,358.4c0,1.7,1.1,2.3,2.3,2.3c0.9,0,1.4-0.2,1.9-0.3l0.2,0.9c-0.4,0.2-1.2,0.4-2.2,0.4
			c-2.1,0-3.3-1.4-3.3-3.4c0-2,1.2-3.6,3.2-3.6c2.2,0,2.8,1.9,2.8,3.2c0,0.3,0,0.4,0,0.6H336.3z M339.9,357.6c0-0.8-0.3-2-1.7-2
			c-1.2,0-1.8,1.1-1.9,2H339.9z"/>
		<path fill="#4B575E" d="M342.4,360.3c0.4,0.2,1,0.5,1.6,0.5c0.9,0,1.3-0.4,1.3-1c0-0.6-0.3-0.9-1.3-1.2c-1.2-0.4-1.8-1.1-1.8-1.9
			c0-1.1,0.9-2,2.3-2c0.7,0,1.3,0.2,1.7,0.4L346,356c-0.3-0.2-0.8-0.4-1.4-0.4c-0.7,0-1.1,0.4-1.1,0.9c0,0.6,0.4,0.8,1.3,1.1
			c1.2,0.4,1.8,1,1.8,2c0,1.2-0.9,2-2.5,2c-0.7,0-1.4-0.2-1.9-0.5L342.4,360.3z"/>
		<path fill="#4B575E" d="M351.1,352.3c0.6-0.1,1.5-0.2,2.3-0.2c1.3,0,2.1,0.2,2.7,0.8c0.5,0.4,0.7,1.1,0.7,1.8
			c0,1.2-0.8,2.1-1.8,2.4v0c0.7,0.3,1.2,0.9,1.4,1.9c0.3,1.3,0.5,2.2,0.7,2.6h-1.3c-0.2-0.3-0.4-1.1-0.6-2.2
			c-0.3-1.3-0.8-1.8-1.9-1.8h-1.1v4.1h-1.2V352.3z M352.3,356.6h1.2c1.3,0,2.1-0.7,2.1-1.8c0-1.2-0.9-1.7-2.2-1.8
			c-0.6,0-1,0.1-1.2,0.1V356.6z"/>
		<path fill="#4B575E" d="M359.2,358.4c0,1.7,1.1,2.3,2.3,2.3c0.9,0,1.4-0.2,1.9-0.3l0.2,0.9c-0.4,0.2-1.2,0.4-2.2,0.4
			c-2.1,0-3.3-1.4-3.3-3.4c0-2,1.2-3.6,3.2-3.6c2.2,0,2.8,1.9,2.8,3.2c0,0.3,0,0.4,0,0.6H359.2z M362.8,357.6c0-0.8-0.3-2-1.7-2
			c-1.2,0-1.8,1.1-1.9,2H362.8z"/>
		<path fill="#4B575E" d="M367,352.9v1.9h1.8v0.9H367v3.6c0,0.8,0.2,1.3,0.9,1.3c0.3,0,0.6,0,0.7-0.1l0.1,0.9
			c-0.2,0.1-0.6,0.2-1.1,0.2c-0.6,0-1-0.2-1.3-0.5c-0.3-0.4-0.5-1-0.5-1.8v-3.7h-1v-0.9h1v-1.6L367,352.9z"/>
		<path fill="#4B575E" d="M375.8,359.8c0,0.7,0,1.3,0.1,1.8h-1.1l-0.1-1.1h0c-0.3,0.5-1,1.3-2.2,1.3c-1.1,0-2.3-0.6-2.3-2.9v-3.9
			h1.2v3.7c0,1.3,0.4,2.1,1.5,2.1c0.8,0,1.4-0.6,1.6-1.1c0.1-0.2,0.1-0.4,0.1-0.6v-4.1h1.2V359.8z"/>
		<path fill="#4B575E" d="M377.8,357c0-0.8,0-1.5-0.1-2.1h1.1l0,1.3h0.1c0.3-0.9,1-1.5,1.9-1.5c0.1,0,0.2,0,0.3,0v1.2
			c-0.1,0-0.3,0-0.4,0c-0.9,0-1.5,0.7-1.6,1.6c0,0.2-0.1,0.4-0.1,0.6v3.6h-1.2V357z"/>
		<path fill="#4B575E" d="M382.4,356.7c0-0.7,0-1.3-0.1-1.8h1.1l0.1,1.1h0c0.3-0.6,1.1-1.3,2.2-1.3c0.9,0,2.4,0.6,2.4,2.9v4h-1.2
			v-3.9c0-1.1-0.4-2-1.6-2c-0.8,0-1.4,0.6-1.6,1.3c-0.1,0.2-0.1,0.4-0.1,0.6v4h-1.2V356.7z"/>
	</g>
	<g>
		<path fill="#604A26" d="M352.5,329.2h2.1c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5h-2.1c-0.3,0-0.5-0.2-0.5-0.5
			C352,329.5,352.2,329.2,352.5,329.2z M355.1,327.7c0,0.3-0.2,0.5-0.5,0.5h-2.1c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5h2.1
			C354.9,327.2,355.1,327.4,355.1,327.7z M349.4,327.2c0-0.6,0.5-1,1-1v1H349.4z M351.5,326.1h3.1c0.6,0,1,0.5,1,1v5.2h-2.1
			c-0.6,0-1,0.5-1,1v1c-0.6,0-1-0.5-1-1V326.1z M353.5,333.9c0-0.3,0.2-0.5,0.5-0.5h3.6c0,0.6-0.5,1-1,1h-3.1V333.9z M348.4,327.2
			v0.5c0,0.3,0.2,0.5,0.5,0.5h1.6v5.2c0,1.1,0.9,2.1,2.1,2.1h4.1c1.1,0,2.1-0.9,2.1-2.1v-0.5c0-0.3-0.2-0.5-0.5-0.5h-1.6v-5.2
			c0-1.1-0.9-2.1-2.1-2.1h-4.1C349.3,325.1,348.4,326,348.4,327.2z"/>
		<g>
			<path fill="#604A26" d="M360.7,328.1c0,0.5-0.4,0.9-0.9,0.9h-1.3c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5h0.9
				c0.2,0,0.3-0.1,0.3-0.3v-4.9c0-0.2-0.1-0.3-0.3-0.3h-4c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5h4.4c0.5,0,0.9,0.4,0.9,0.9
				V328.1z"/>
			<path fill="#604A26" d="M355.8,323.7c-0.6-1.2-0.6-2.4,0-3.6l-3.3,1.8L355.8,323.7z"/>
		</g>
	</g>
</g>
<g>
	
		<image width="217" height="138" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANkAAACKCAYAAAAnpO8vAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAEAhJREFUeNrsnd1u01gXhr3tpC2l
RbSCEcwJ5/SQW+AWuB+uh94Ct8BhOa80KqNWolBKf2Pvb70ra5kVNzPSzHxyEud9JMuOk7TJ9nrX
395ui4IQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBC
/gVpmT/c+/fvH3y+o6Oj9vjdu3e8gmvA4eFhe3xwcDDPTjJF9i+EBTG5iD5//tx+zpcvX9Lq1pgv
X760x69fv84uQhffMgouLZO4XFgQ1cnJSfHmzRsd1P39ff2cX79+1de+evWqOD8/p8WtEXt7e8Xx
8bEeiz0UZg/ZnS7sBKJzwS2T2EbLErlkgFT0EI+L6ufPn3ru9vYWx+nRo0f6nh8/fhSj0fSjX11d
JZrgcNne3s5+zf36V1WF6w6BJbGLfHFxocKD7UBgEJs467QsYkuLFhjEhcgFjyRiSru7u8Xd3V2a
TCaprmsVUc45bW5uFhsbG8XNzU3CHq+NP2tnZ4cWOSAuLy9nHsv1z2IXxdbWlu7heHEsgsMGp5sh
ODhfRDhPJ8W2Fi60tAwC888Cz/Tt2zcVlQgpNU2jxzJw2GMg0/39fbvH4JLhA2cLxPFqZJN9TikV
smVENIgNxyK+XJalCg9igzAlzVShLbJeGy1SYBLedf/HH38gaiFVLCEuiEge6zHOi6BKRDKITgYx
YYCxl23mIpDhAUcKBxujGQQFoY3H4wbnxD5UXGIHzdOnT/W5s7Oz7CmksbBolhYlMEQwF5gMVIkU
EcKSvDtJSlgCERUUVSKaQVR4Hh4Kj01wrchkwGmRAwKZiotMrjWcqkYri2g4ltONnhNn3Ij9NBCZ
2E+D1FFqOBWcpJU5RrRFRLOFiMx/75MnT1qByWCWMngqKhm8SgZKRYbjKDQ71s8OoSGqRdzrkdUl
XlNcTwgMQoOgICykgxCU7Bt5voE/FtvB4xrKw3kgNqSRbtFCG/UtMEsT0XLVVLAjMBRZusnjajpe
FUQFoaEuQ+iC8JJdjGQXwS/IA9GRlRWaCwuRzEWR7Ty8qwrM9rXYQG0lBGoHNM70DdfX141s2n2E
wI6Ojnr3wr2KzFZrJLTo0eRADWaRSQUG5HgkA6X7auqSKnusArPXejRMQWC0zAHhztOua3aBTZ+a
igvIayawIc92YBPivGvU8HgvutU7OzsNohl+EOZhDw4OUp/RrDeRxSiGFvzp6al2EGUwEM2gFBWW
5OL4TCMZNIhthHN4DiKEwFxkIWoly9kLG2QyDJFld5yWLuoJE1obvcxuJti8hLD6HCmlNkFgY5gK
WlQ06zWSodlhE81tax5NDk8REbFMYGNsMkgqNgjNRFaZstpoFgRGyxwQuKaeLkJcEJqhaSL6XfIc
arDJ9OVlsrq9jXx4j6SK+fLyEnUcUkhdIQJn32c0601kSBWxNAY8f/48WXu+dFCDmZhUZPKysZwe
22dsI5oNpKYGHs28RvPUkaw2EBec5jRhafceySAwpIu1RS/vRCdztlMlSuTzJgnMBR3H7e1tXT1k
82bDShdjqoilUmi5Y1AgNMyBWTNDazKkiIhgEJiMVRTbTMpofY9kF8EvDtPFgTQ9Ol3GWJN5swO1
WGXd6GSRb+Y16C5CgGh+QJ9YReTrHT9+/DjMdNFWUKetrS10ObS76C176At7j2b22TRtlPNjO1d5
qmhCK0I7kZ3F4ZVlMyUaLrt3E2E3cn4SshkVl9dr1hip0bW2sgTlCaaNir4bIL2JzOsxhGt4FFsa
VUJs1hnymqyy1FCjmQlsbAIbeRSzTmPRjWbRIdJOV0tU3e6iL53ylNE7i9MSbVoy2HVX8bkAMUWG
poh3HGFjSBXh1B8/fpzE2WcvXQYXyQBW08ObYOB8qZRNKidv5Vta6F1Fj2Btl9FF5t1FTkgPJk18
cOyrPSysebTycsFrMBjQCJHLGmhaUkw7/KU6cyzBQjTD/BmcfZ91Wa8iw/1guF0BLXwXF5of8DRx
HgxRLQiqFZh1Hn0+ZCaSxQvDtHEQuaJHsiIILMeucohgyHDqWLfDPlCCBPtSp452Plr7fX6XXkSG
zuLbt2/bu1rhUXyRr90XFgXWzoN1JqB9eVUZPFnbVeQc2bCw6DWTSlo62KaIJqpYo5W+h50gPURZ
gvJEIpk2R3ALDVaADE5kDu5oxs135ql0sDAQ3hl0CmvLdld3eASLjQ+Ka7CRLAWB2SVXe2js2mcX
U3DEXqel6ITtJk8VnP/8PjuMvYoMLXxELtxw6ZHMF/56Ku6D5EtkQuRqI5hFvOTpBJsc65M+BoGl
aC+O34MYn7eFD1qmgN9++w03BvcXlfv4JfP+qhS8Czab34jepwhRK8VGhq++D3VX6ggscRvM9uCa
hujWjVbt8+aw2zIizp363fR9/32Y3ruLCNvdO5rNQ/mKjRSW0xQhhWzDf2e1fWKzY5jNxm5NFkUX
VnjMRLTosP012HDKV+ZH5x//3NxgRBaxlR+FD4TVZjG6dVOFuHQqddMJMmzRdZogbd0W7cQfu6OG
jcV5VDh5TxsHlS52iX/0Jt7R3Lm9IQS6NK8gLhjB1kBZnevrzrZbRoTFwV1nvHCW5pO4eHw/ry3P
VR1rH8VmSoVu08vLi2hH0Ykv6i+alUsiMJoR+U8Oel7kWha7KldoIGlNZCVZFZExLSQr63R5lyMZ
RK1GkRHCdJEQQpERQpERQigyQigyQigyQghFRghFRghFRgihyAihyAghFBkhFBkhFBkhhCIjhCIj
hCIjhFBkhFBkhFBkhBCKjBCKjBBCkRFCkRFCkRFCKDJCKDJC/k9kimzNBpP0xyr9h9WSg0ooxDUQ
GQVE/lFa0/nfZGI/Gf+I3Y/jniLrDJQNoO7tv9fnmCrO+cdvTCPXrPaK4jF7iM+53bRvvL+/b48v
Ly/XR2Txy8ZB8MGxf7D9QFz+P7f/zquRYUcts40Ze8Heo5n/72iPbFVV6bZIRn3/wu3t7Xx7e9sO
gA1QOygYJB9I90pz0kkf+cSUc20E1mY1QVDZaG3GMyE7fmAXsL/JZDJskTnj8bi4vr4uNjY2IKYc
BiyHgcphcPW8nUtdwflbaJrDSg/na+9XZeFZj9uKCw3ApiBGvMadeOTw8HA46WL3y2xubuabmxuN
ZBCYD0T0VC6sMHi5U9TGrevtuK329nfXs+jaRXC+7qDb10Foo9Eow6nD7nByb29vuJEMX+709LSo
67p4/Pgx6rHs+TQGxLxNY4PTQIAxitmgNnBSng6Qdcgac3SsTRBc45sFr8aFB3F5DQ97gy3h5+D4
69evxcuXL4fV+Dg4OCg+ffpUHB8f62OkiJIXz0Ql80aNRSwVGPZxIOPrTHQ+4I29llFgAFvnus88
NiHN3UxYreCwof6CTSGSbW1ttV75y5cvapeDimS///57sb+/ryni9+/fs33hLI9RiKrnsWjWmNhq
DJLt4QwQvWoTWmkXJFlES2GehL5/xZsdsWFhtVVbZ5ngYBO1i8+EpeeQAbnDhaMW+1LH7U4dfQDY
IaLZYBsfV1dX+enTp4V1GLNHLBdUGDgcTyw1xJYselU22Hic7HCuuJhOrgbx2nWv46/L/iDCTeJm
zrgWe4Ijrj0rgn1hmgiRDH0AKVN6N4peRSbeI0sunCA09y7wNOatYuSayOBUNlgT7xqGAre0KJa8
qzivXWuPGdpWrJPYuZbdutwdcB1FBpsxe1E7QkST+quxVLFB5oT2PdLEnz9/5tevX+fPnz8PS2T2
pRK+ILo9GDSpzTRNxGBgg9DMK5UWwZKLJdRiGLjShBVe8mvKjHa72qLrOMvsEckcrKaG7oDl+F7s
JkY1F5qKDU4cNga7g709efIko+nWa6Tu6xd9+PAhnZ+fJ/mi6fT0tBRRlbu7u5WE8pEMBraxDM6G
7DdEhGPsZWDH8lacx77yDeKyGi3Ni2L2PFm9tHFmiVSY1mnb9BalXEQuLCwbupPn7yA6bCIuPbZM
SF+/ubnZnJ2dNScnJ1lsMR8eHvaSOvYWyTw0o+iEZ4FXEaFh34jQNJJZFEty3IrHGiKNfdbKxOU1
WopRDI9RJLP5sbr4mtU40VzYfJctWmgjGTZEMRdWp06rPWXE+2BrqMdgf96I62syuteazPNgCdno
MuqA3d3dJYlcGDgMFo5VZKG13zZFIDIIDA1IiM0jltdmnDsbRhOks9A3Tjg3JrTao5M5Zq3JZH8v
tqNNELGv+vb2tkFp4u+DY8fP67MeW0h3EZOA6CzKprUZPIwNFkZ0Eld/2FKrxrxW5a382PiIIjOh
MYytckFmq+q8bW+Cy3EyGrjQ6qlHnlhEm4zH41oyo8nNzQ0EhqcxV5b39vbyjx8/tHX/8ePHoq9U
sfcmwfv375P/TolmSQajlEEpZRBKyZcr2VfVdMl0ZXUaIheOq2kDsmzrMfs5Ma1kFBtQNIuRzNch
xrrMMpvG5ssmUJMLD+LC3iJZjXlZvFYcvIoNUUxssTdj6T2SeZdRvrzOWWAgcB7RTYSmgyoppK9V
1EGE8OC8puNYIvaXdvtCsguS/DYHNj2G0fwIt7F4feb4/JdGs2a6XgrC8oynvr6+1ha+OG19Whx6
c3Fxoe17bH0KrHeR4cshmkFo5+fnUViNuazCF3QijbTVH6WlBNrssHSwtCJZI5ktNG4jGSPa6kYw
v3ZwmFhnGNch2ryqOl/YB/bwv6i/ID5voD169KhByx5CwxwZHPrJyUnx9u3bXmuxhaSL3bTR6rMk
Qkvfvn3TCCXRLVnEKqca00aHRjBf4WEpou79Jj1cEICZfbK6+E28fqOl3zdmixeKaVLTtPNmvozK
Wvtw0BCWLnSwdFFX3y8iTVyoyOYJDQMog1FKcapzaRAaOo0yqAkdSOzlAuhzEBhEBeFhjwXHLjIy
DCAyue7t7VB2g68KCxkQIpR3DBHVICrc1SFOukFT7dmzZ/n4+DjHCLYIgS2kJus2k3D7C1LHFy9e
YLkLPI9GNSTfiGpXV1cqKng4SQP0MbyTDLKfU+/HpuKwQATDtcV6Q2QnEBH2vqLexNbeZwixAbwO
JQgEhp+zaIEtNJLFiCY1Giar26i2u7sLL5akSE0YaIgMA4c9Bh3RDF5uZ2dHXx9/Hs6R1aX7x26Q
6uEcshWsP8R1RySDsLAWEREOa2FRq8H52vpYbXAcHR1l3M6ySIEthchC6lhEsWGQ9vf3NY28uLhI
vt5MBjb5hYAAHUQ4muhwgID8GHNbuNZwoGdnZ9qVlqwH00BZbMNvXWnFFSebFy2wpRHZX4kNx2Hg
ij///DP5oL969UrTTDJ8UFL4Db++LMpFBVxYWCblN2Iug7iWUmRdsbnggIsOoJh98+YNrW+NwJ31
WG8Y7EJFtKzCWnqR/Z3oHMm32+N3797RCgdIXMD7V38qYFmFRQghhBBCCCGEEEIIIYQQQgghhBBC
CCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCH/mf8JMADOvK8j0TbjogAAAABJRU5ErkJg
gg==" transform="matrix(1 0 0 1 246.4 140.5879)">
	</image>
	<g>
		<path fill="#FFFFFF" d="M290.3,164.7h128.6c4.2,0,7.7,3.4,7.7,7.7v61.4c0,4.2-3.4,7.7-7.7,7.7H290.3c-4.2,0-7.7-3.4-7.7-7.7v-61.4
			C282.6,168.1,286.1,164.7,290.3,164.7z"/>
	</g>
	<circle fill="#D3DDED" cx="355.7" cy="190.5" r="12"/>
	<g>
		<path fill="#4B575E" d="M312,214.4c0.6-0.1,1.5-0.2,2.3-0.2c1.3,0,2.1,0.2,2.7,0.8c0.5,0.4,0.7,1.1,0.7,1.8c0,1.2-0.8,2.1-1.8,2.4
			v0c0.7,0.3,1.2,0.9,1.4,1.9c0.3,1.3,0.5,2.2,0.7,2.6h-1.3c-0.2-0.3-0.4-1.1-0.6-2.2c-0.3-1.3-0.8-1.8-1.9-1.8h-1.1v4.1H312V214.4z
			 M313.2,218.7h1.2c1.3,0,2.1-0.7,2.1-1.8c0-1.2-0.9-1.7-2.2-1.8c-0.6,0-1,0.1-1.2,0.1V218.7z"/>
		<path fill="#4B575E" d="M320.2,220.5c0,1.7,1.1,2.3,2.3,2.3c0.9,0,1.4-0.2,1.9-0.3l0.2,0.9c-0.4,0.2-1.2,0.4-2.2,0.4
			c-2.1,0-3.3-1.4-3.3-3.4c0-2,1.2-3.6,3.2-3.6c2.2,0,2.8,1.9,2.8,3.2c0,0.3,0,0.4,0,0.6H320.2z M323.8,219.6c0-0.8-0.3-2-1.7-2
			c-1.2,0-1.8,1.1-1.9,2H323.8z"/>
		<path fill="#4B575E" d="M328,215v1.9h1.8v0.9H328v3.6c0,0.8,0.2,1.3,0.9,1.3c0.3,0,0.6,0,0.7-0.1l0.1,0.9
			c-0.2,0.1-0.6,0.2-1.1,0.2c-0.6,0-1-0.2-1.3-0.5c-0.3-0.4-0.5-1-0.5-1.8v-3.7h-1v-0.9h1v-1.6L328,215z"/>
		<path fill="#4B575E" d="M336.7,221.8c0,0.7,0,1.3,0.1,1.8h-1.1l-0.1-1.1h0c-0.3,0.5-1,1.3-2.2,1.3c-1.1,0-2.3-0.6-2.3-2.9v-3.9
			h1.2v3.7c0,1.3,0.4,2.1,1.5,2.1c0.8,0,1.4-0.6,1.6-1.1c0.1-0.2,0.1-0.4,0.1-0.6v-4.1h1.2V221.8z"/>
		<path fill="#4B575E" d="M338.7,219c0-0.8,0-1.5-0.1-2.1h1.1l0,1.3h0.1c0.3-0.9,1-1.5,1.9-1.5c0.1,0,0.2,0,0.3,0v1.2
			c-0.1,0-0.3,0-0.4,0c-0.9,0-1.5,0.7-1.6,1.6c0,0.2-0.1,0.4-0.1,0.6v3.6h-1.2V219z"/>
		<path fill="#4B575E" d="M343.4,218.7c0-0.7,0-1.3-0.1-1.8h1.1l0.1,1.1h0c0.3-0.6,1.1-1.3,2.2-1.3c0.9,0,2.4,0.6,2.4,2.9v4h-1.2
			v-3.9c0-1.1-0.4-2-1.6-2c-0.8,0-1.4,0.6-1.6,1.3c-0.1,0.2-0.1,0.4-0.1,0.6v4h-1.2V218.7z"/>
		<path fill="#4B575E" d="M354.1,214.4c0.6-0.1,1.5-0.2,2.3-0.2c1.3,0,2.1,0.2,2.7,0.8c0.5,0.4,0.7,1.1,0.7,1.8
			c0,1.2-0.8,2.1-1.8,2.4v0c0.7,0.3,1.2,0.9,1.4,1.9c0.3,1.3,0.5,2.2,0.7,2.6h-1.3c-0.2-0.3-0.4-1.1-0.6-2.2
			c-0.3-1.3-0.8-1.8-1.9-1.8h-1.1v4.1h-1.2V214.4z M355.3,218.7h1.2c1.3,0,2.1-0.7,2.1-1.8c0-1.2-0.9-1.7-2.2-1.8
			c-0.6,0-1,0.1-1.2,0.1V218.7z"/>
		<path fill="#4B575E" d="M362.2,220.5c0,1.7,1.1,2.3,2.3,2.3c0.9,0,1.4-0.2,1.9-0.3l0.2,0.9c-0.4,0.2-1.2,0.4-2.2,0.4
			c-2.1,0-3.3-1.4-3.3-3.4c0-2,1.2-3.6,3.2-3.6c2.2,0,2.8,1.9,2.8,3.2c0,0.3,0,0.4,0,0.6H362.2z M365.8,219.6c0-0.8-0.3-2-1.7-2
			c-1.2,0-1.8,1.1-1.9,2H365.8z"/>
		<path fill="#4B575E" d="M373.3,223.4c-0.3,0.2-1,0.4-1.9,0.4c-2,0-3.4-1.4-3.4-3.4c0-2.1,1.4-3.6,3.6-3.6c0.7,0,1.4,0.2,1.7,0.3
			l-0.3,0.9c-0.3-0.2-0.8-0.3-1.4-0.3c-1.5,0-2.4,1.1-2.4,2.5c0,1.6,1,2.5,2.3,2.5c0.7,0,1.2-0.2,1.5-0.3L373.3,223.4z"/>
		<path fill="#4B575E" d="M375.3,220.5c0,1.7,1.1,2.3,2.3,2.3c0.9,0,1.4-0.2,1.9-0.3l0.2,0.9c-0.4,0.2-1.2,0.4-2.2,0.4
			c-2.1,0-3.3-1.4-3.3-3.4c0-2,1.2-3.6,3.2-3.6c2.2,0,2.8,1.9,2.8,3.2c0,0.3,0,0.4,0,0.6H375.3z M378.9,219.6c0-0.8-0.3-2-1.7-2
			c-1.2,0-1.8,1.1-1.9,2H378.9z"/>
		<path fill="#4B575E" d="M383,215c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.7-0.3-0.7-0.8c0-0.4,0.3-0.8,0.8-0.8
			C382.7,214.3,383,214.6,383,215z M381.6,223.7v-6.7h1.2v6.7H381.6z"/>
		<path fill="#4B575E" d="M385.4,216.9l1.3,3.8c0.2,0.6,0.4,1.2,0.5,1.7h0c0.2-0.6,0.3-1.1,0.6-1.7l1.3-3.8h1.3l-2.6,6.7h-1.2
			l-2.6-6.7H385.4z"/>
		<path fill="#4B575E" d="M392.1,220.5c0,1.7,1.1,2.3,2.3,2.3c0.9,0,1.4-0.2,1.9-0.3l0.2,0.9c-0.4,0.2-1.2,0.4-2.2,0.4
			c-2.1,0-3.3-1.4-3.3-3.4c0-2,1.2-3.6,3.2-3.6c2.2,0,2.8,1.9,2.8,3.2c0,0.3,0,0.4,0,0.6H392.1z M395.7,219.6c0-0.8-0.3-2-1.7-2
			c-1.2,0-1.8,1.1-1.9,2H395.7z"/>
	</g>
	<g>
		<path fill="#1D324F" d="M354.7,190.3h2c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5h-2c-0.3,0-0.5-0.2-0.5-0.5
			C354.2,190.5,354.4,190.3,354.7,190.3z M357.1,188.8c0,0.3-0.2,0.5-0.5,0.5h-2c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h2
			C356.9,188.3,357.1,188.5,357.1,188.8z M351.7,188.3c0-0.5,0.4-1,1-1v1H351.7z M353.7,187.3h3c0.5,0,1,0.4,1,1v5h-2
			c-0.5,0-1,0.4-1,1v1c-0.5,0-1-0.4-1-1V187.3z M355.7,194.7c0-0.3,0.2-0.5,0.5-0.5h3.5c0,0.5-0.4,1-1,1h-3V194.7z M350.7,188.3v0.5
			c0,0.3,0.2,0.5,0.5,0.5h1.5v5c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-0.5c0-0.3-0.2-0.5-0.5-0.5h-1.5v-5c0-1.1-0.9-2-2-2h-4
			C351.6,186.3,350.7,187.2,350.7,188.3z"/>
		<g>
			<path fill="#1D324F" d="M363.7,192.6c-0.5-1.1-0.6-2.3,0-3.4l-3.2,1.7L363.7,192.6z"/>
			<path fill="#1D324F" d="M365.5,191.3h-2.4c-0.2,0-0.4-0.2-0.4-0.4v-0.2c0-0.2,0.2-0.4,0.4-0.4h2.4c0.2,0,0.4,0.2,0.4,0.4v0.2
				C365.8,191.2,365.7,191.3,365.5,191.3z"/>
		</g>
		<g>
			<path fill="#1D324F" d="M350.9,190.9l-3.2-1.7c0.5,1.1,0.5,2.3,0,3.4L350.9,190.9z"/>
			<path fill="#1D324F" d="M345.8,191.3h2.4c0.2,0,0.4-0.2,0.4-0.4v-0.2c0-0.2-0.2-0.4-0.4-0.4h-2.4c-0.2,0-0.4,0.2-0.4,0.4v0.2
				C345.5,191.2,345.6,191.3,345.8,191.3z"/>
		</g>
	</g>
</g>
<g>
	
		<image width="216" height="138" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANgAAACKCAYAAADIZoQRAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAD8hJREFUeNrsndtuFNcShnv12MbG
2IAvIkCRuIfLvAKvwPvwPOEV8grcBe4tRRARCQiYgz0z3bu+clVneWy4yc4c2v8ntfow48Osrr9O
a7XdNEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQojR
UTbhl3z27Nml3/Ply5fD8dOnT3UnR8jz58+H48ePH19lF70E9i8F9ejRI9+/evVquPb69evml19+
kQVeI168eNE8ePCgqeyiD7tYa8GVdRQWokpB3b9/v3nz5o1f+/PPP/3au3fvmocPHzbv37+X5V0D
7t692xwfHzdHR0d+fu/evR5hpW2k4FJs6yS0so7CyoGzAS2z2az5+PFj2d/f9/fevHmznJyc+PHe
3t7wPb58+VJkiuPB7vMgkq9fv/r+1q1b3Ge//vnz5+bw8LDf2trC4faVI14roZV1EFctrNPT03Jw
cNCcnZ0VG7CCiPq+Lzdu3PD9t2/fmp2dnWKv+4Dz/vr7cU1sLuk8E7vvPdfsnmMT/e7ublNK6e2+
+x7xmd309nr/6dMnf38ttFWLrKxSWBm1LNUrpAHmlRBO+fDhQ0FM5qEKkYlj24hYfo7Xsq1Mp9OG
AWcvxsf29naDQ2VvmUxPNkNkM1H1Ji4XGOeW4XDe37lzx4Vm2U5P+WA2tfJotrXqCMpAEIX++OMP
vE9rXqgQoebzecv1iFaIqtjAEslccIiqbVuuuXez98siR8RkMvHUkL3d65490QlxmeB6u98uJrMR
XmPrzJZ6y34QV4cTrmr0lUWxlUSwiF5DSohoiFpd17XmfVxcYOfmmAp7TjOS+dfm3q7790yB4e3E
5pLZCIICu/cuECJU7tnseo+JmB1gHJx3iMyyIL9ONEN8mTLytauIYmUV4sq0MMXF3sJ/myAw2yY2
eC0Cyw1RITRe5wYgQD5DHA8/g3RSbB6kfQmOM5wmgiFiITp05AJDWLnZOeKax+uORbAOcaXIMl1c
tsjKKsRFQ4MOYS0uNjvHbbWWEk5s0HzjfHI+ckMkYx8ejgjnQkNU3JSIbGJzRUY0crEhrHCWLrDz
pOY8crHNzxWIyOZsljL6udnUHIHVIqPTuIrGx9JrsJzfomClprKUbhCX7dHSxK4RvbZsc5ER/Ylm
vBZRrK1TxUpcstANJ+8lIsv0MKMY4sEE7Pq8O/e2Liz3uudhjexmTseZLiOYjXXUbJXtjTOCLaaG
bOZhENekPS+kEDvHW0R4G6+tPE6hZcoY+5K/P8eZIpY6zxCbKLA+U8T+H4/JIanhkBIiLNsjrpnd
+5mdz/KYkhwfHqni3GzMI9kqUsWlRzAKzsPDQzpCJRsXNghEL4TjorL9NgJjH7+jiy/SxoxebZUO
lsUcXmxuHYauYuszbSR65T4E5CKqnW1GPt4XNkXHkRqdMoQ00VeFLJOlCixrL6JXRJ3W8uZ2Op22
EbUYFH6nbcTFhuAQW4qsanxkBCvVjRm6imIzSYFUDnPoIBK9UmB2jUjlDbG0BUTE+6jToo1PhuTR
z86xvVy7OL4aLNJDn7Oi9mI7ODhgjstTPgaL3wdRmffxvQ3UdkQzItkkt6i7hoHF0cXEY3o+hbIN
bXBkFpL3NAVTRbCMXuls3anyGmLKKIfzxrYsgnXMrRLFmCOjPltmFFuKIdbdQ1ZnUHvFAHlKaN5l
m82Od+y6bzZAOzZYHLvAeG98jaeJ2eCIm1BWUVeK/6YMWyzJ6ghG3ZURzM6ZNJva/sxs58yOfTPb
OTNBTdmoySLaeS3Gqo9ldhOXFsGye8hEMsudwFJD9yx4ougMTqL2GtLEEJfXZHQTq2ZHic5RU+1X
2sgR/1pQF6JXNDYWmxxt1UXOznFfNz9yLpXpG1JD6jDej+0hsGV2E5dag1F/2Qd0kVGHIa4qGmUD
gzSQyJbpYNZfWzEvNol0cljN8b0Gh9r2m9PY+N55CKsPQXR197g5b993aSfRWUxHTeRqw4H7nCu2
hw0yJzbKJgfwuMmHDx98kS4fPDo8bbTq61a8D1pErOEYcaUogybnwhZvjrqKGxjOqvtYTzSHyFxc
sYKnP++TeXOMtDGnbya5vC5sy40Am7t9+zYRbaled2kC4ylk4PETFvDmukJCeE4ShmjaGKystYYV
HRm94v1t3BAfQK3iGE80i6VQQ/oX17vKRvqwBa/J0laqKRy3KWwrz7E5HnthATG2WD8dvfEC4+9n
PHnyxDuIb9++5bkeTxNZ2BmNiqFtH15qWBKVgsu0MMVVibLJTqIYTRQrVX0Wt9rveYpssImoyS7Z
TrUw3B9t4gFNltfxJDyLzH/77bfxpYi06fMpZEQWK1yaWDVfIgplfVU3Mob9griKGhnXI22MUqCN
1HCwidpWIrtJh5v25I48H8TFBvPp+GWwlFnZxb/6RIODGiwWcOY8xiCUSjxlYc3hpdebyy16bZu/
Xbqf9b1ffGSpfj0inpcM8eSF1/uLT74v6y+RreyBS7wKKWPM3GfhOnilOrLlIC50kOqboIbGyEqx
KuXvL5ZoF1bwlGr1Tp3llEgb3cZW+cT70gXGHFg+TId3YRCqCHbhua4rZvUXPdul7pMYp+AWxXbF
6p1hJX7aEVttO9geglsmK1m4V/9hmvoJ5LpFW3cEa6EtCkuRa9wdxe9FtoW5srJgI5dsa1V/DGlt
VsamoHKkqrZ7+dGAywyvTfS6ygbqFHGwnXWarmnXRFwyIzFKm2o1YELikcCUCoqNtAk9nSiEBCaE
BCaEkMCEkMCEkMCEEBKYEBKYEBKYEEICE0ICE0ICE0JIYEJIYEJIYEIICUwICUwICUxDIIQEJoQE
JoSQwISQwISQwIQQEpgQEpgQEpgQQgITQgITQgITQlwDgel/FwkJ7L9E/ypWbKLTbSUesWlskr20
azRo7pH6+PeF/If4uHbhP8srdbzekWrRBtJu1lWEKxHYycnJcDydTi8NCoNYD9zCf5b/4YCLESnr
B2KK1wZnXDvnfF9tW6tia9k/8ObNm/3p6akfTyYTFw1bDhL/MT4EllHsKm+UA12UZl4LcfULTnZx
f+H1tJvvOfVRCyzZ3t5uvn792uzs7DAgvQmrr6k80bBBXCuLYssvkWmOs4GRUYl92kGaSjpktjzG
Uec+nTfOfTabjS9FfP78+YXzGzdu9N++ffMIhrgYgByoRWHVddiCV6rFd9U1bZu7XbifVTS7kCJm
mljbSw3iOjs7c2d+LVLEu3fvNm/fvm3m83mzv79PjtyH5xkGh5Q6By3Oc+vONVZyYFs1OcafJmIH
YSNd6KaL4y5thOwHR13ZS0atHieOM8ep/8jpb7TAHj9+3Lx48aJ58OBBs7e352mhheoLaWAOThX6
fSBDWDmgbaQHXeXVSnwd30Qp4khY6A4Ozreyjz7to36tdsyRGfVEMGowxPbTTz95VBtdBENcR0dH
/iH//vvvfnd3tw8PQ17sXqgS1JytFlmIal4JKWuxkh5LjY7xRK6spUJITSUut48qiuWWr7nAsCuO
qbmwL5w6mdPx8XHz+vVrd/rLiGJLb3J8+fKlv3PnTvPp0ydyYw/tOVgZrXKz6/MQW6nSQ97fxnmx
ry9XdRrVvt+4iPW947yRGanSJmZpHzhetnTE5qz9vSaoPqPa6elpf/v2bc+gRluDvXv3rr9//35B
ZHxoBsA8TRdRbBAUexufWdRZbXqzSAXbuF7OtTb0aa+6SQppG9o1vCqCVbX4LOzE9+Zk02byuOvO
PW+HEydy4dQ/f/7cWy02ToE9evSof/XqVeFDbm1teQj3mH5enHZ2bW4i80Ejwoew2lKppopebSWu
IkGNT3DVPFdOw3RxzH6GkHDCFq2mKTizKdPS3CMY4iI15Dwj2OHhYfPmzZt+dAJ79uxZ/+uvvxaL
Xt7goJO4v7/fW7E5pINgA5Mhf5oCqmbsed8kRJfC8yiWk9NxIySykTQ3FjKXPptdkQ7OERcis9em
bCamWUYyhGXRqyNLIorxPXDulbMfVwTLD0Sjg4YGLfqYXGYQ5hbBPGLFgKV4sk2bjQ8ENqmiV1sV
xX4nEJvYbHIdauU4h6mayhbmYStkPFP7GqJZlhaIb24OfJiUJjW0EqXBydPcsK0flcBqz0GoJkVk
AAjnbCY4RFbq2fjmn8lDBnQroxc6iug2RLm8GWpujKPhUWUk/UL7vQtxZS2W2zS2jG6diarDxvha
yhKcu6WIS+sgrqSLiAfhQ5Ii2ocmTy6WNmbY965gioX6jCaIsRVdo0nMheX7fF95PaWHYyjA/lkq
NzRAct4rus7eMYySgnpsVkWzGRlR1PU00LqMZB8/fuyXmR4uvSlgtdjQlLAoVixqtZYftyYyG6t2
YgMyscHYmjBZZukgDoDrHFOiReRqsz3fRKu+9nhiXFEsIleTa1VzSgeiqeHNjewoQgiNjiIO3EXG
Sg662C9fvuyXlR6uJILhQd6/f19YUU8Us31H48PEVpgUjBX27qmi+TFx9U0mrY2d12lNNcEcUS9T
REWwkTQ6qicphgiWXeec64pWvDc0su7KJgfRi+t7e3s9i3yPj489evG9lpUeLj2CZRSzD9ogMksV
i4msmHcpJycnLY0OhGRRDdfECLaREhLUcGWIzFPD7B7mRHPdeRKbHbkS7i9zWKkvohi1OyJDPIgI
zRGlopaf5zVeN8ftDpzUkG9C9KL+oqs9WoEtporsU2Skighrd3eXCEfgcpEhpvPxbQuNkBjxoV6L
m7DSVdPi/0c+KEk2E060r59wryNZRDsXWMyBed2e4jJ7aX7//ff+yZMnXnstU1wrSRGT8CbMjfkD
mLRQLZz7wBCS6PrEABWiF5Et0kiOU2ANA5hRK4UmNpucasnHTeJ+99W0jXcF6WUwmZwrNuJ6x6Qy
/Pzzz5QjzarEtbIIVkUx/x3oLNqgeA1GCkhkMqF5GojAOI8ohqBYasX1wcuJ8UJWwuMm7ENMzKHW
j7H08dhTf3Bw0P/1118XnivLruEqxLVSgdUioyZjGRXHR0dHg9CsOKWC9WMbZNJIXwnCMXtEWX+/
W7duySJHwOLj/XQAucY9p+VuJUQTy59yQppVGl45MNfF+5kKWkXNtVYCq4Vmg8EE4PD7IDTSRial
qdGsUOWR75KDX6+KJqLJLMcH3b885s9LpBNlsXhkLz0P8fJkRiwk9znWeq5rleJaG4HV0QyhPX36
1CMaAwYMGoLjGNHBw4cP6UTKCq8JCIlnuYhQcO/ePRdRiqpOB9dBWGsnsB+JDTKFhBSeuF6kkJKc
16pXZqyLsNZaYN9phgwgvCQFKMZJPSlMPXWFfWjiUwghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEII
IYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCLFc/ifAAINDHXfDRgeFAAAAAElFTkSuQmCC" transform="matrix(1 0 0 1 246.4 0.5879)">
	</image>
	<g>
		<path fill="#FFFFFF" d="M290.8,25.7h128.3c4.2,0,7.7,3.4,7.7,7.7v61.3c0,4.2-3.4,7.7-7.7,7.7H290.8c-4.2,0-7.7-3.4-7.7-7.7V33.4
			C283.2,29.2,286.6,25.7,290.8,25.7z"/>
	</g>
	<circle fill="#E4F0E6" cx="354.9" cy="52" r="12"/>
	<g>
		<path fill="#4B575E" d="M331.2,75.8v9.4h-1.2v-9.4H331.2z"/>
		<path fill="#4B575E" d="M333.1,83.9c0.4,0.2,1,0.5,1.6,0.5c0.9,0,1.3-0.4,1.3-1c0-0.6-0.3-0.9-1.3-1.2c-1.2-0.4-1.8-1.1-1.8-1.9
			c0-1.1,0.9-2,2.3-2c0.7,0,1.3,0.2,1.7,0.4l-0.3,0.9c-0.3-0.2-0.8-0.4-1.4-0.4c-0.7,0-1.1,0.4-1.1,0.9c0,0.6,0.4,0.8,1.3,1.1
			c1.2,0.4,1.8,1,1.8,2c0,1.2-0.9,2-2.5,2c-0.7,0-1.4-0.2-1.9-0.5L333.1,83.9z"/>
		<path fill="#4B575E" d="M338.6,83.9c0.4,0.2,1,0.5,1.6,0.5c0.9,0,1.3-0.4,1.3-1c0-0.6-0.3-0.9-1.3-1.2c-1.2-0.4-1.8-1.1-1.8-1.9
			c0-1.1,0.9-2,2.3-2c0.7,0,1.3,0.2,1.7,0.4l-0.3,0.9c-0.3-0.2-0.8-0.4-1.4-0.4c-0.7,0-1.1,0.4-1.1,0.9c0,0.6,0.4,0.8,1.3,1.1
			c1.2,0.4,1.8,1,1.8,2c0,1.2-0.9,2-2.5,2c-0.7,0-1.4-0.2-1.9-0.5L338.6,83.9z"/>
		<path fill="#4B575E" d="M349.9,83.3c0,0.7,0,1.3,0.1,1.8h-1.1l-0.1-1.1h0c-0.3,0.5-1,1.3-2.2,1.3c-1.1,0-2.3-0.6-2.3-2.9v-3.9h1.2
			v3.7c0,1.3,0.4,2.1,1.5,2.1c0.8,0,1.4-0.6,1.6-1.1c0.1-0.2,0.1-0.4,0.1-0.6v-4.1h1.2V83.3z"/>
		<path fill="#4B575E" d="M352.6,82c0,1.7,1.1,2.3,2.3,2.3c0.9,0,1.4-0.2,1.9-0.3l0.2,0.9c-0.4,0.2-1.2,0.4-2.2,0.4
			c-2.1,0-3.3-1.4-3.3-3.4c0-2,1.2-3.6,3.2-3.6c2.2,0,2.8,1.9,2.8,3.2c0,0.3,0,0.4,0,0.6H352.6z M356.2,81.1c0-0.8-0.3-2-1.7-2
			c-1.2,0-1.8,1.1-1.9,2H356.2z"/>
		<path fill="#4B575E" d="M368.4,84.9c-0.4,0.2-1.3,0.4-2.5,0.4c-2.6,0-4.6-1.7-4.6-4.7c0-2.9,2-4.9,4.9-4.9c1.2,0,1.9,0.3,2.2,0.4
			l-0.3,1c-0.5-0.2-1.1-0.4-1.9-0.4c-2.2,0-3.7,1.4-3.7,3.9c0,2.3,1.3,3.8,3.6,3.8c0.7,0,1.5-0.2,2-0.4L368.4,84.9z"/>
		<path fill="#4B575E" d="M369.9,85.2v-9.4h1.3l3,4.7c0.7,1.1,1.2,2.1,1.7,3l0,0c-0.1-1.3-0.1-2.4-0.1-3.9v-3.9h1.1v9.4h-1.2l-3-4.8
			c-0.7-1-1.3-2.1-1.8-3.1l0,0c0.1,1.2,0.1,2.3,0.1,3.9v4H369.9z"/>
	</g>
	<g>
		<path fill="#143A19" d="M360.5,47.5c-0.1-0.9-0.4-1.2-1.4-1.3c-0.8,0-1.6-0.1-2.4-0.1l-1.1,0l-0.2,0v0l-0.9,0
			c-0.8,0-1.6,0-2.4,0.1c-0.7,0-1.1,0.4-1.2,1.1c-0.1,0.6-0.1,1.3-0.1,1.9c-0.1,2.5-0.1,5,0.1,7.6c0.1,0.7,0.5,1.2,1.2,1.2l2.1,0
			c0.8,0,1.6,0,2.5,0c0.2,0,0.5-0.1,0.7-0.2c0.8-0.7,1.6-1.5,2.4-2.2c0.5-0.5,0.8-1.2,0.9-1.9C360.7,51.2,360.7,49.3,360.5,47.5z
			 M357.2,56.6v-0.2c0-0.5,0-1,0-1.5c0-0.1,0.1-0.1,0.2-0.2c0.3,0,0.6,0,0.9,0l0.8,0C358.5,55.4,357.9,56,357.2,56.6z M359.6,53.8
			l-0.1,0c-0.2,0-0.4,0-0.7,0c0,0,0,0,0,0c-0.5,0-1,0-1.5,0.1c-0.6,0-1,0.4-1,1c0,0.4-0.1,0.8-0.1,1.2c0,0.3,0,0.5,0,0.8l0,0.2
			c0,0-4.1,0-4.1,0c-0.3,0-0.3,0-0.4-0.4c-0.2-2.4-0.2-4.9-0.1-7.4c0-0.4,0-0.7,0.1-1.1l0.1-0.8c0-0.2,0.1-0.2,0.2-0.2l0.7,0
			c0.5,0,1-0.1,1.6-0.1v-0.2l0,0.2c1.3,0,2.6,0,4,0l0.9,0c0.4,0,0.4,0,0.4,0.4C359.8,49.6,359.8,51.6,359.6,53.8z"/>
		<path fill="#143A19" d="M357,50.5c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1
			h-1.1v-0.9c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3v0.9H354
			c-0.1,0-0.2,0-0.3,0.1s-0.1,0.2-0.1,0.3v1.3c0,0.1,0,0.2,0.1,0.3s0.2,0.1,0.3,0.1h2.6v0.5H354c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h1.1v0.9c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1
			c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3V53h1.1c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3v-1.3c0-0.1,0-0.2-0.1-0.3
			c-0.1-0.1-0.2-0.1-0.3-0.1h-2.6v-0.5H357z"/>
	</g>
</g>
<g>
	<circle fill="#ADB6BC" stroke="#ADB6BC" cx="173.1" cy="561" r="2.3"/>
	<g>
		<path fill="#ADB6BC" stroke="#ADB6BC" d="M89.5,545.1c1.4-0.7,3-0.7,4.4,0l-2.2-4.2L89.5,545.1z"/>
		<path fill="#ADB6BC" d="M128.7,560.7c0.3,0,0.5,0.2,0.5,0.5s-0.2,0.5-0.5,0.5V560.7z M101.7,561.7v-0.5V561.7z M91.2,551.2h0.5
			H91.2z M128.7,561.7h-27v-1h27V561.7z M101.7,561.7c-5.8,0-10.5-4.7-10.5-10.5h1c0,5.2,4.3,9.5,9.5,9.5V561.7z M115.9,548h-1
			 M92.2,542.2v9h-1v-9H92.2z"/>
		<path fill="#ADB6BC" stroke="#ADB6BC" d="M252.9,545.1c1.4-0.7,3-0.7,4.4,0l-2.2-4.2L252.9,545.1z"/>
		<path fill="#ADB6BC" d="M208.1,561.7c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5V561.7z M245.1,561.2v0.5V561.2z M255.1,551.2h0.5
			H255.1z M208.1,560.7h37v1h-37V560.7z M245.1,560.7c5.2,0,9.5-4.3,9.5-9.5h1c0,5.8-4.7,10.5-10.5,10.5V560.7z M255.6,543.2v8h-1
			v-8H255.6z"/>
		<rect x="125" y="560.7" fill="#ADB6BC" width="90" height="1"/>
	</g>
</g>
<g>
	<circle fill="#ADB6BC" stroke="#ADB6BC" cx="173.1" cy="436.2" r="2.3"/>
	<g>
		<path fill="#ADB6BC" stroke="#ADB6BC" d="M91.7,456.2l2.2-4.2c-1.4,0.7-3,0.7-4.4,0L91.7,456.2z"/>
		<path fill="#ADB6BC" d="M128.7,435.5c0.3,0,0.5,0.2,0.5,0.5s-0.2,0.5-0.5,0.5V435.5z M101.7,436v-0.5V436z M91.7,446h-0.5H91.7z
			 M128.7,436.5h-27v-1h27V436.5z M101.7,436.5c-5.2,0-9.5,4.3-9.5,9.5h-1c0-5.8,4.7-10.5,10.5-10.5V436.5z M114.9,449.2h1
			 M91.2,455v-9h1v9H91.2z"/>
		<path fill="#ADB6BC" stroke="#ADB6BC" d="M255.1,456.2l2.2-4.2c-1.4,0.7-3,0.7-4.4,0L255.1,456.2z"/>
		<path fill="#ADB6BC" d="M208.1,436.5c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5V436.5z M245.1,435.5v0.5V435.5z M255.6,446h-0.5
			H255.6z M208.1,435.5h37v1h-37V435.5z M245.1,435.5c5.8,0,10.5,4.7,10.5,10.5h-1c0-5.2-4.3-9.5-9.5-9.5V435.5z M254.6,454v-8h1v8
			H254.6z"/>
		<rect x="125" y="435.5" fill="#ADB6BC" width="90" height="1"/>
	</g>
</g>
<g>
	
		<image width="216" height="138" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANgAAACKCAYAAADIZoQRAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAEBZJREFUeNrsXc1y00oT1Uh2foBA
kioowoYqlrDkFe4rwPPwPLBlySuwJHs2QGXBTwIJ2Jbm69PuFm3F926+KtlSzqlSjSQ7YPf06dPd
M0qKgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAI
giAIgiAIgiCI0SFt+wd89erVtc/44cOH9vzFixecxZHizZs37fmzZ8/W+UYmwf4PUoFITqDT09P2
s56cnND7bhjev39fPHr0qL1++vRpNr/YasKlbSUVCOVE+vz5c3F8fKyvf/36Ve89fvy4+PbtGz3v
BuDo6Kj4+PEjfECvHz58mEEs+Ad8wwnnZNsmoqVtIReIJWlA+3lAKJDp7t27xe3bt9P5+Xlx69at
9PPnT319f3+//fnLy8tENxwXZK5bklxdXel4584dzLXe//XrF3wjTyYTBN3sZNs2oqVNE8uiT5sC
gliLxaKo6zqBUDLq+e/fv9Pu7m6xs7NT4Bzjnz9/Vj4/JoAYNjyAOmTOM+5hvmezWd7b2ytSSlnm
XkeQT8iV5fV8cXGh749E2zTJ0qbJhc+A6AOyOLGapgGBUs45SYTSa5zLUeAaioXIBVRVRa8cKabT
KYKpjuIbGf4BZRNSZSGXEgzXkt3gOh8eHirRJOPJKB8ktdy4mk02nRI6uUTmk6R9SiwxKMhWSsRS
YpVlmYRI5Xw+T7gHJcN9qJu8BlLSG0cGBE6oE0aZ94wR6gRyiX9kmXMlk/gOXsPRCKnywcEByNUg
AIcafWMqljZFsPB/J9RYYjwllRiulDQgieFKQNSrNPXCpSqaWkzuQdkiwRDpiGFDyLSSmcgc63RD
oXzEIffhEI34QSMjrhuQTGozvQ81A/k8ZcTPbkLF0ibIhZpLokuSL96SS/LsUshTQqmEKBWIBnKJ
MUEwuV0pyZBCgmimbJiAhHShDVU500sHijiPIXCCMFAskA48UoKBWH7INchV2+sK8aUG5HKSebrY
N8nSpsiFtBDEwghpksilpBJjVSAVbAQ741qlS+7hdbunBLNJ0doME+L3iEGTDGqkZAOxLGBmKwca
Vy4c9ZKBIFmNQ1JGvRafqkGwSDJ0GjfR+Oi9BvNuIdrvkk+j3iqRGgJikEpqrInKlRALn09uY6wC
uSLB9DBy0TtHAA+WIJmnh65iIA/qLblfg2lyT4llAThZXV6jRkeXERAfa1CzBd8bp4KtSw2REgq5
Kok8FUZwDMoFUomxpjivlrrfqpmljG3zAyliiH5UsOETLHuKmP9GTZwiNWxTQhBLRpBrIT6wkOuF
n8v7oWQLSxVr8S1Vsk2kir0rGApOqBeaFaJWqLmS1VsgWOXkEuNNQTg7VzVDDQbbL+2/rMM6KSI9
dAR1GObRjuxpI9TLRyOQkgg+YYHVa3J9P7IhtPbRkYaP4RwbF7ArpE/0SjBINBaSUXcBIJk1LrSx
IedOqClGP8dbkSbiQMPDU0MQDXMA+7qSwcjEcOEECU2PtoMI9XKCyT0oVWl1u/oDSIT3oU6zNj4y
JFU/NMew1crWxcZXg1l6qCvyVnvpmhaMZGlgZSngJBBtx4g2sfuV12GBZCuZYUwZieE1OHwuPXA6
YYKCuXpVrl4IqngNZHKVQ4mGDEn8rLm4uFAVwxoZ6rM+VawXZ/T6Cwom6aHWXmYgrblMpaZioB2Q
Sl7blQPk2nE1c4IZuUpPDzvfgeQaQRnWLcmigqHucgWTayyazWWcCclmcq6H+NRMCDXHgZrM1E5r
Mez66LOb2JuCefcQzQ1sdcKuDACRBQUVmhxea7mKWWo4tc/p122rPqhYjHgFCTdYQq2olzU2uk0O
7Tj7koy38WPzA69bXY/6XrMlvN82jec+u4m91mCov7ArHl8U6SFqMFvfSqZMsc6adIg1DWlBaalh
8oXmLrnY8BhWY+Pfro1Y2QjRdLIWXRPzbMg6i6VlOlCu0jYo6JorfM+e0hjvOhh2yH///r3Armh8
cevwaFRydfJuoRnKyVaFazVySBOvKRg79gOVMwuM3YVmI5nOu9XZeRlftcSo3W/CRoXSfEsdAT53
7949KFqvkbc3gn369ElHPMflO+VtYdBb7cnVyRSsrbei8Yq/OznKmAZyJ8d41My2QrXpn91vfHOB
ka0yRau9Nvc1Us9u4Ft+DZ/DYy/YQAxfjE9HD55g2Dn/zz//aAfx7OwMz/VomoiNna5CgWRuoDKk
gytpYVgPa+eFKeGoVCyF+symXH3BSbYSeG3DgXeW2zVS/3dQiuABTWwgxpPweILj3bt340sR0ab3
J5FBMtvh4ht2Pd1rW/CReN30sdPEoHKNPG20pkdpqWEsD1JYbC6tNGg3g2NEIPeHceGDEtx7++y9
rMp2f/MTFppRg9kGTl/HKMIaSIp1VuwUdjqE69r0PIZ/XJvP0DVsfWKNn1g2qfWb+hZ8DL7Wffq9
r99GNtlUVEJUQcpoK/deuLZRKSpbVLQ1apXY1BhfKRaCaV4t0VaWZ1qlCuVD3KeqPubPmG0CvRMM
a2D+MB2iC4zgCgbYDuqVTuCaNa5rRGMNNm7CdcnmPtHZu9hulfPd+NF34HsgXJ/YyMa9+Mtp4lPI
sUUbO4KRaF1iUbnG3VH8N2XrrJWljo9c861N/UKkrdkZGx+gLIqVtnv6L4PTDW+Meq3zgZgiFmFN
dGv8otwSctGNiFH6VEmDESQPCcZUkBikT/DpRIIgwQiCBCMIggQjCBKMIEgwgiBIMIIgwQiCBCMI
ggQjCBKMIEgwgiBIMIIgwQiCBCMIggQjCBKMIEgwmoAgSDCCIMEIgiDBCIIEIwgSjCAIEowgSDCC
IMEIgiDBCIIEIwgSjCCIG0Aw/u0iYpA+MRgF45+KJYboCyUNRpCEN0DBxCAq+dn+fCH+QrzdW/nL
8kwdb3Yq2PEB/PXzDF+JvuO+dGMJ9vPnz/Z8Pp9fizqwUzRS5y/L/5fBiTExa01AjT4Sg3PHR675
VvS5PjHp+z+8detW/vPnj55XVaUGweFRCH8x3gjmKrZO7t26iWnmjSBX7gbWSDT4jN/zn8M9XGN0
34LvLRaLcRPMMZ1Oi6urq2JnZwcGyGKIHBGkvj0Au5e6ZPMfoWuOLy1c5V3rA9lTRMy9+Yxe2z34
VDGbzYr9/f2ib2I5qr5KrCdPniSR7CRfOk0mkyQqVlaQMAk2QrCJGGVin2cq55WRv7LzUsbSCFSu
USsSa9xEc1Ih6DY4UKbLWMuoh5wvbEReWItP4f4C7xW/a3Z3d/EzqNcygvrbt2+L09PTcSnY0dFR
cXZ2VtR1Xdy+fRs5crYUMNuX1/4GzoWIDYwR1csM3SA4xXSAGDfBXJFs7ltfcARfaSxlbJsfeB3Z
EmowxPMHDx6oqo2qyfHs2bPi/fv3xcePH/UaEUQkeyUNdAOaxKvBPFKFY8XQfs+iWRPTSR7DPcK8
O2ninOfoE9FX3Hdiuggfg6/B5wD4IHwRPjmqGuzRo0fF8fGxRpEfP37kvb09NYJco/DUGsxUrDFj
1WInH0tLDWsjWWmGTqZkyQtZNjuG39zwJlfoDuYQUEGgxtLB1kf8fvCjLKVIW7NJSZLv3bun9dio
mxyXl5f58PCwsE5i9ujjhor5NfJqSwdxuLUrMxquk52uJRZTyGEgzl08t6CZY4oIYgmJQKzazrX2
Mv9p4rn5lpYh8Llfv35lqcXGS7CvX7/mk5OTBJLhi0O5RMEay6OjYi3EOFUoXpOHMZP90tQreffQ
o96aiaOkDaxjGOYyh7VPTxdrUyz4xcKaGeonUdG8JpN6HzWYKtjdu3eLz58/51ES7OnTp/n09DQh
ikC6YTDJizU1FCPAEJB37/yUplzJiRLy8MY7iq5eniqyozgOwvlcetISWu8egD27maNraOPCfAfk
ahC4UX7g8Dofvhd8sR917stqr1+/Tt++fUtCqnR2dlYKocqDg4NqPp8jUcaB9vyOjDtCwClGsdO0
WLbtMVZ+gFhWk6V16mWvE8NLFfM6FfP6y15vW/MgloxzCcwg2EwyIh3l/gzEA+lEvRYXFxc1CPfg
wYNmNpvlo6Oj/PLly16UrDcF84iBRgdUC+oF+ZZR1ymgYBaBkpy3xLGitbHP2q6JeQ0W1QvXvoJP
DBO+BzXMYbsJIXQPPdNBiohjbtdzSx2RDTVCrgZlyP7+vpYjQjSUKUgTx1mDuTQjF4Z0wwgSUbDw
jNQPkq+L0CBYaN83oVOEhWcseqiCuVJ5Lca1sXE0PMJc5r+CtuwQegfR00TxIxAKaaKeC7TxAcUS
32oQwFGPAegB9JkebqSLeHJyoh1EFJ2oxaBi1hFKVrQ6SXI0qKWGpTU42iZHJJhNDOVryAXY321y
sQHSDbbuE7WrmDXGtA7DeyQr0kYHNjNAvdA9hO/1HjD6/M9evXqV/P8UFcPWqVJkvBQDlGIAhJ/K
tk9VVpfpVimMy0Zj2dZf9u/EVJLqNSIV843exeoidGM7NOqwVlr7kg4IB0jgriWAY9TyA6ni+fl5
S1bxw94cpXcF826ifHlsl0L7VBc7oGq2RpFRiIZVeRSolRhVGyPYy4jzJQ+XgoWUERNiJKOCDbzR
4eSyDEZ9AtvrUFIg48Fo5/WybGuUaJIOKqmcXDjMx4q+u4cbUTBXMfmiBTqK8sWTkEpHqJgDRJKj
CovMpbXudbMwXgfBrBZLdh7TDHrqQJXLgWC55E/hSznFMs42bcs+pozyWm2vK0Au8S1d/8KIziHI
1ad6bYRgMVW0ekxJ9v37d1UmiTjJlKpc7prSpoYql+/csLRQR38WyCZDH4Mhhgt/SNIylJXdHEV4
HMUecWq3R/naF9a6cP/w8LAll3UNc9/k2hjB1pEMz+uIMcqLiwtdKwPJ0FEUoyV0GjHicRe8BnJZ
yqAjNnI6wYhxAATDrnfLTrI9nKvlA3Zm2BKPjr4jCK8dHBxo2YESZNPk2kgN1m0a4REWSReLhw8f
Yq8YDKNqhhwAanZ5eamEQmTb39/XaxhPjOz3NOqxeTguQLkwt79//9asBCRCEMZTyVdXV7pOBkLZ
E/KqaEgDQbr79+9n+BSu+1zz2ioF69ZkaHy4mkkUgvGSGCfByCAYGiAYYXCoGKLbnTt39P3x38M9
Yrjo/u4MqBDuIUsBofb29grbHa8jnooXv1FiYSHZVcsbGptSrq0hWEgXi0g0GOn4+FhTx/Pz84QH
NAGJWMknIT56AGWje44HUCY/B4k8eGKjOM4l28FSD9ZSfRN5EYllfrXxbtdWOWWXaDgPhiu+fPmi
97Dd5fHjx5paEuMHygg8KIltdoCUE0oi9w3zma0i1lYSrEs0JxvghAM+ffpUPH/+nJ53g4CnkPHQ
bvCLbH4R/Wbr1mcGkVZFwjk+fPjQnr948YIeOEK8efOmPV/3iP82EoogCIIgCIIgCIIgCIIgCIIg
CIIgCIIgCIIgCIIgCIIgCIIgCIIgCIIgCIIgCIIgCIIgCIIgCIIgCIIgCIIgiBuA/wkwAMo46JJ1
DduYAAAAAElFTkSuQmCC" transform="matrix(1 0 0 1 -13.6 437.5879)">
	</image>
	<g>
		<path fill="#FFFFFF" d="M30.9,462.3h128c4.2,0,7.6,3.4,7.6,7.6v61.2c0,4.2-3.4,7.6-7.6,7.6h-128c-4.2,0-7.6-3.4-7.6-7.6V470
			C23.3,465.7,26.7,462.3,30.9,462.3z"/>
	</g>
	<circle fill="#D3DDED" cx="94.9" cy="488.5" r="12"/>
	<path fill="#0E3168" d="M99.1,492c0,0.3-0.3,0.6-0.6,0.6s-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6S99.1,491.7,99.1,492z M91.9,492
		c0,0.3-0.3,0.6-0.6,0.6s-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6S91.9,491.7,91.9,492z M99.7,488.8v1.5h-1.8v-2.9L99.7,488.8z
		 M100.6,488.1l-2.8-2.2v-1.5c0-0.6-0.5-1.2-1.1-1.2H90c-0.6,0-1.1,0.5-1.1,1.2v5.8c0,0.5,0.3,0.9,0.7,1.1c-0.1,0.2-0.1,0.4-0.1,0.7
		c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8c0-0.2,0-0.4-0.1-0.6h1.9h1.9c-0.1,0.2-0.1,0.4-0.1,0.6c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8
		c0-0.2,0-0.4-0.1-0.6h0.1c0.3,0,0.6-0.3,0.6-0.6v-2.3C100.9,488.4,100.8,488.2,100.6,488.1z"/>
	<path fill="#231F20" d="M94.4,486.6l-1.1,1.5c0,0.1-0.1,0.1-0.2,0.1h0c-0.1,0-0.1,0-0.2-0.1l-0.6-0.8c-0.1-0.1-0.1-0.3,0-0.3
		c0.1-0.1,0.3-0.1,0.4,0l0.4,0.5l0.9-1.2c0.1-0.1,0.2-0.1,0.4,0C94.5,486.3,94.5,486.5,94.4,486.6z M93.4,484.7
		c-1.4,0-2.5,1.1-2.5,2.4c0,1.3,1.1,2.4,2.5,2.4c1.4,0,2.5-1.1,2.5-2.4C95.9,485.8,94.7,484.7,93.4,484.7z"/>
	
		<image width="7" height="7" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAC5JREFUeNpiYMADGGGMJx+e/UeW
kBGQYmTEJgEDTPiMJU8SZCeKAMhuZPsBAgwA9v0Pe1QLrigAAAAASUVORK5CYII=" transform="matrix(1 0 0 1 89.4 483.5879)">
	</image>
	<path fill="#4B575E" d="M76.8,519.3c0,0.8-0.3,1.3-0.8,1.8c-0.5,0.4-1.3,0.6-2.2,0.6c-1,0-1.8-0.1-2.3-0.4v-1
		c0.4,0.1,0.7,0.3,1.1,0.4c0.4,0.1,0.8,0.1,1.2,0.1c0.7,0,1.2-0.1,1.5-0.4c0.3-0.3,0.5-0.6,0.5-1.1c0-0.3-0.1-0.5-0.2-0.7
		c-0.1-0.2-0.3-0.4-0.6-0.5c-0.3-0.2-0.7-0.3-1.3-0.5c-0.8-0.3-1.4-0.6-1.7-1c-0.3-0.4-0.5-0.9-0.5-1.5c0-0.7,0.2-1.2,0.7-1.6
		c0.5-0.4,1.2-0.6,2-0.6c0.9,0,1.6,0.2,2.3,0.5l-0.3,0.9c-0.7-0.3-1.4-0.4-2.1-0.4c-0.5,0-0.9,0.1-1.2,0.3c-0.3,0.2-0.4,0.5-0.4,0.9
		c0,0.3,0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.4,0.6,0.5c0.3,0.2,0.7,0.3,1.2,0.5c0.9,0.3,1.5,0.7,1.9,1C76.6,518.2,76.8,518.7,76.8,519.3z
		 M83.2,521.5v-4.2c0-0.5-0.1-0.9-0.4-1.2c-0.2-0.3-0.6-0.4-1.1-0.4c-0.7,0-1.2,0.2-1.5,0.6c-0.3,0.4-0.5,1-0.5,1.8v3.4h-1v-9.1h1
		v2.8c0,0.3,0,0.6,0,0.8h0.1c0.2-0.3,0.5-0.6,0.8-0.7c0.4-0.2,0.8-0.3,1.2-0.3c0.8,0,1.4,0.2,1.8,0.6c0.4,0.4,0.6,1,0.6,1.8v4.2
		H83.2z M87.5,521.5h-1v-6.4h1V521.5z M86.5,513.4c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.2-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2
		c0.1,0.1,0.2,0.3,0.2,0.5s-0.1,0.4-0.2,0.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2C86.5,513.8,86.5,513.6,86.5,513.4z
		 M93,521.7c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.2-0.6-0.4-0.9-0.7h-0.1c0,0.4,0.1,0.7,0.1,1.1v2.6h-1v-9.3h0.8l0.1,0.9h0
		c0.3-0.4,0.5-0.6,0.9-0.8c0.3-0.2,0.7-0.2,1.1-0.2c0.9,0,1.5,0.3,2,0.9c0.5,0.6,0.7,1.4,0.7,2.4c0,1.1-0.2,1.9-0.7,2.5
		C94.5,521.4,93.8,521.7,93,521.7z M92.8,515.8c-0.7,0-1.1,0.2-1.4,0.5c-0.3,0.4-0.4,0.9-0.5,1.7v0.2c0,0.9,0.2,1.5,0.5,1.9
		c0.3,0.4,0.8,0.6,1.4,0.6c0.6,0,1-0.2,1.3-0.7c0.3-0.4,0.5-1.1,0.5-1.9c0-0.8-0.2-1.4-0.5-1.8C93.8,516,93.4,515.8,92.8,515.8z
		 M100.7,521.7c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.2-0.6-0.4-0.9-0.7h-0.1c0,0.4,0.1,0.7,0.1,1.1v2.6h-1v-9.3h0.8l0.1,0.9h0
		c0.3-0.4,0.5-0.6,0.9-0.8c0.3-0.2,0.7-0.2,1.1-0.2c0.9,0,1.5,0.3,2,0.9c0.5,0.6,0.7,1.4,0.7,2.4c0,1.1-0.2,1.9-0.7,2.5
		C102.2,521.4,101.6,521.7,100.7,521.7z M100.6,515.8c-0.7,0-1.1,0.2-1.4,0.5c-0.3,0.4-0.4,0.9-0.5,1.7v0.2c0,0.9,0.2,1.5,0.5,1.9
		c0.3,0.4,0.8,0.6,1.4,0.6c0.6,0,1-0.2,1.3-0.7c0.3-0.4,0.5-1.1,0.5-1.9c0-0.8-0.2-1.4-0.5-1.8C101.6,516,101.2,515.8,100.6,515.8z
		 M108.2,521.7c-0.9,0-1.7-0.3-2.3-0.9c-0.5-0.6-0.8-1.4-0.8-2.4c0-1,0.3-1.9,0.8-2.5c0.5-0.6,1.2-0.9,2.1-0.9
		c0.8,0,1.4,0.3,1.9,0.8c0.5,0.5,0.7,1.2,0.7,2.1v0.6h-4.4c0,0.8,0.2,1.3,0.6,1.7c0.4,0.4,0.9,0.6,1.5,0.6c0.7,0,1.4-0.1,2.1-0.4
		v0.9c-0.3,0.1-0.7,0.3-1,0.3C109,521.6,108.7,521.7,108.2,521.7z M108,515.8c-0.5,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.8-0.5,1.4h3.4
		c0-0.6-0.1-1.1-0.4-1.4C108.9,516,108.5,515.8,108,515.8z M117,520.7L117,520.7c-0.5,0.7-1.2,1-2.1,1c-0.8,0-1.5-0.3-2-0.9
		c-0.5-0.6-0.7-1.4-0.7-2.4c0-1.1,0.2-1.9,0.7-2.5s1.1-0.9,2-0.9c0.9,0,1.5,0.3,2,0.9h0.1l0-0.5l0-0.5v-2.6h1v9.1h-0.8L117,520.7z
		 M115.1,520.8c0.7,0,1.1-0.2,1.4-0.5c0.3-0.4,0.5-0.9,0.5-1.8v-0.2c0-0.9-0.2-1.6-0.5-1.9c-0.3-0.4-0.8-0.6-1.4-0.6
		c-0.6,0-1,0.2-1.3,0.7c-0.3,0.4-0.5,1.1-0.5,1.9c0,0.8,0.2,1.4,0.5,1.9C114,520.6,114.5,520.8,115.1,520.8z"/>
</g>
<g>
	
		<image width="217" height="138" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANkAAACKCAYAAAAnpO8vAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAEB9JREFUeNrsncuO20YThUlKc/cl
YyDB2It4P17mWfw+fh7PK3iRF/DS3nsTAw7g8X1uEvuvr1VFtzhCFn8CSuKcAxBNUhxbKtapU1Xd
lKpKEARBEARBEARBEARBEARBEARBEARBEARBEARBEARBEARBEARBEARBEARBEARBEARBEARBEARB
EARBEARBEATh/0C96W/wxYsXt97jmzdvuv3nz5/rLo4YZ2dn3f6zZ89W+UcSyf4FsSBTkOjt27fd
e338+LG87w7i9evX1ZMnT7rj09PT5L6x0aSrN5VYkCrI9P79++rRo0f59Y8fP+ZzT58+rc7Pz+V5
dwTHx8fVu3fv8IN8fHJykiAXPoJ/BOmCcJtEtnpTCAa5LB3o3g+kglAPHjyojo6O6i9fvlSHh4f1
t2/f8usHBwfd3//48aOWG44Pdr87olxcXOTx3r173O98/vv37wTb9PXrV4JvCsJtGtnqdZPLI1CX
DkKu2WxWzefzGlLZmPcvLy/rvb29and3t2Kf8erqaun9cwOE7UcE0oDd98Q57vn19XXa39+v6rpO
dv/z6ARMQTiuL8m2bqLV6yYY74EIBGGCXG3bQqI6pVRPp9N8zL5tFccol435jyeTibxyxNjZ2SGo
5tH8I+EjEApy2ZZJhuJZppPu37+fzC8gGKTKymZp5tqJVq+LYJEeBsFM7mtLATO5zKAQruE8pGqa
pjYyNTc3N5lsEIsRlSO6MQrjA/fZlCuPFmgTmYzd68Q+5GLf7n8e7ZoWEqJ0kM18pyUQo2rrJtra
SFb83zU1lxkjE8sM1lg6UJuRGmAGbVzFOMyky6HMzqFwdq4jGdFO2H5YMF3KUuw+d+q1uPUJUuEQ
rV2bFm5ijtI0mWxWq+VzlkYmKz/SuolWr4Ng1GDn5+e1yXpHMMu5GyNQg2IZWSaQDbuZYZuFTSeZ
aKSTkA2SQTCIRtoQIKUUthflvSwCKKRBsSBe68dZuQyQa84xr1kp0Zo/ZdI9fPgwX2MBe61Em66L
YKSClirmEYmyVDETiwCGopmNeG/5mNfZD9J5cEDJuCm5VuMSiFfeJGFriUZKmBUMxfLAmVzViKod
wez1uZ3iopmpWmsBeu5krD5//lz5uXRycpKba1amDB6Fp0P/h9FFpDVvck791UAqYEo2sZx6mmWr
rskVpnaaMQiYSebEipSzdoLJO0eCImguckMnGWoGz2KDbDDK/Aai4QtZ9qjFaJB4LUddRru/taCe
CPL0AoZUs8FIVqgYcxk0LXKTA4KZEVAvohEEy8Sy/R1Xs0moGsTz9DHIFZ3HiICSsXGQLBVES+Vp
iMVmu3MbIRUEm3nNPsMFLFDn5hh/b+UIbf3ckYZspItDq9ngSsb8BSpGA8OMQQ1We/1FWjgJgpmx
dhA338+qhiE9hWyiLuuli/LQkdRlrmKVEytubOsEnLtqsWWC2TUzVAs/gGB0HPk7uo3ejSSg57b+
0Go2KMlIFZlspg4DEM2bGbnZgZIFwRhjn0tRNDaaIJEmQjZuBDfF04VqUb4J2wyvtVPRCOk6i060
1sk1j5o9GmAeaNto8e/v72eiWWDnBXwv1jqOL12MVJF5La/FULJMFGowlMzTwWlBtl0n29TPT6Iu
K4i2lCUG2YTtbXrE/YwAyi5qVNZi7gszJ1gXXF21crPD/KtlKghSWk2Wu9ix/OrVq1fDfaYh6zGU
zCIKCta4Kk2pt1ytdkzOdyGWvbZnGwTbDVULkjnBmkgVe59BBBtJWdYr0ULZ2lAysj/SRdu/MYLd
mGpd23ht59iu7Py1XROvZdWzAD83H2up04Zs5w+mZNFVZF6MIpTVG4CaLFess1nXUQw18zRxx99n
HEeXsS7UrIx6lUi31aRaUjGvybrGhyvZ3P1g5qVCcnXLc2YejNnmiznq7C5ZydhYdsWq/lE2PqjH
WE0P0UgVqclQJjqNrlBl3TXtkWvHX+vSRRC5eJ9gaoJsX7Nj1fGir5UijQw1y8G1OB9t/bn7TvYT
n1fNHWxLH1uW7tHexw+HrMsG7y6ysv7Tp08VK6l9HWId82ShUtFFjIjk73NSHIeRI2W8pWTq5m+x
rP2ckqmKzmI0ProGR0G6SRGgo/uc51/xL4jFBDUtfJSMhsiQn2cwkv3111955DmwWGHvi3yjDV+H
SkU0ivoriOcEi8nopkwJY8WHXHQcqhYKVq728FqszFKSnysznNxtjABM55oNn8NHeGQmnk0bCoP0
u1lx/8cff+SnmQErq8uFvpH6hTr5cVPOi5UpYnm++tnKX+o4atvurbjHdQTWvk8UxCrHbnrHF5Tn
eTP6ADyHGD5Jh7H8rpjRpIu08OOJZojmBWks8g2idWQJOPGWUsleY0MKdgdSSC8BmiJt7BNzaYx9
fy4xTx+B3377LfvfqJSs/41STEZTk5EnQzRkvGxe9I1UdhB7ncNVLXxt49hu3dOiHLhFsDiHP5Ul
RDlvGk/SD/3dMNN1RSaeGSKyxEp6N0ZnvFLhSiOuUK1ajY5xlmZl7dUnXTxLWKpX0W1euiaCuD9V
vRT8y6+cGw3JyI3jYTyULGbrgyT+gN5Sh3DFHNgtsqllP37SlYTzBkjd95M49uCdfaz0H/wv0sZR
pYt9lF94Uz7NXBqp7BT2jFj3O1HCuDuN/ePwk3KMh3jjeJPWsG7MOymiUr8lX/8DocSwu6ViSw/m
+lj3miNl13opiK/r28yaDSGY3Ej4VwF6lXJtil81W2RIeZOwldgWkiktFLY26OoJR2EUtZpIJghK
FwVBEMkEQSQTBEEkEwSRTBBEMkEQRDJBEMkEQSQTBEEkEwSRTBAEkUwQRDJBEMkEQRDJBEEkEwSR
TBAEkUwQRDJBEMkEQRDJBEEkEwRBJBMEkUwQRDJBEEQyQRDJBOE/QhLJ7pgxheGwTb+w2siognAH
SCYCCf/SfxI/xL6pftVskqFyXui/7ua/Xp/KVHHFD78pjbxjtRc+0fth9vK1zn/Cn25ubrp/5Nu3
b3eHZOWHLY0Qkcd/YPsWueI3t5esr5+5HTe7evfXfWPJXxhLNeN4lbKtC9Oh/8PDw8N0dXWV9yeT
SRioMwo/sB2GdDVbJfth+Vop550h2K2sxnwlC1dsrmRL+/gYPhX+hf/NZrNxkyyws7NTXVxcVLu7
uxggucE6FJLfbcDP1X3CxZ/INceXIq44H/7SHYevcB5/Kvazb6xStrOzs/Gki/0Ps7e3ly4vL3OU
wSBhiCItTH3jrVCr1I9uvXPatnu7dU+LEiIC8a0gHASLuh6ilX7HeHx8PF4l48N9+PChms/n1dHR
EfVYiny6MFwbBu0Zsl3wrA4yNmp83B1FC3+AR6W/rArIpe/EuUgR8b2PHz9Wjx8/Hlfj49mzZ9Xr
16+rd+/e5WNSRPvQSylh3yhuzNbJ1XJcGLY7HwT0c1KAkWzFPU5+/2/5BPvuF+XYBWVUDXJBLGqx
/f39Lii/f/8+++WolOzJkyfVo0ePcor4+fPn+MDJjjFEzp8LA87ZSqO6QeeuZLUbso46LJokwjga
HtG8KFr2EYDxBXwj+wj7Pna+A7uixp9Op+y3EdTv3btXHRwcZDUbbePjx48f6Zdffqm+fv1K8yPn
0GVEKrcwYJAp5kFsbPy4tr+vV3Ug1drfLpT3r9yPPliRFrZBMA+64SNLZGsXjpHVjGkiGm30AUzV
ktVm463JLHoky4VriIbR+MCmZK2rWVtEJQw483S2KeZGgmCZZAu++QvF/ElxoyRtW9pJLNrw6ect
TqkMvjbiIzP3Fba5qVaQLysYf2MEy3WcZVLJU8V0enqa3r59Oy6S+Yeqv3//joTnNDG6QQx2bm5E
y4az44mTq6kL5hQq1hQEq0Wq8ZGumGjulCxUysuImavZjZFpZgF7xliqHNdCNII5uLq6yqXJkE2P
QZ3y5cuX9fn5eb27u1t/+PChOTo6aq6vryHT1Ay2Q8BhpC/Cvm27dpz3/ZppkC/SxVCzmMB2Iopo
2502pr6aVcud564+R70glgXqaxtZOnTDvr1+bUH8xvzrBpVjMzWbW5rY/v33362RLL169SqdnZ0N
UlMMpmQhzTQ/KEpp33txSqSZm5Jl5cIgoVC9CIZRkb9JoWJNUSjnuwHhhO1GrFstk5hoZBSdxJk3
OSAa5Lqx628ihTT/yimjBfXWyJYsqOeaDv+LzuJQk9GD1mSRBz948IAuY+LDI+dsGMWI1s2DFesU
k0etqaePjatZV5P5EpulCW1hu5sgvZoslk9FS3+pdodoXmbcRMrIRglyeXlJEG8/ffpEbZbJNmQ9
tpbuIvkwkYQPS3vVcmRSyJD/3C0MwlCv0Rgx8D4x5ASSYXe/Lo9F9FOqOIaC7OeyurIp0s2fedew
7ChmYkEw1Iza3vyGrT04OGhptKGE5m/Z9yxVrIZKFQdvFLx48aJrVJia1aZejUWXxoxiNmgmZoSJ
qdt0wmSapYYEAc6zz9RH1GPRuq+8jV+sAhHGp2ZLaxSr5cUJbSgWauakm0EwJ1p7cXHRmq+1h4eH
OW08Pj7OKma+OJjDDK5kSDUNEFbio2Y2kjezxKpmdt5X5re+Bo1xkhk4mTQm+7luq4pJaFe/SBel
ZCNpfhRPYHRKxsqNUDIXPKZ/KOlbTxNz7R4E80npFh/79ddfEyuOhlaxytViMPz5558UnPXvv/+e
nyNDuiEHxDJJz0ZF1pk4LNMDrImxIk2IeZJiTiRSh3LORNsWbtGa555yf+eL/nvUXJCH/TxyjD9A
MK5F0SAU82Lg4cOHBPCshPib+V1upuCHgwaNdUSqMm1kNEWr9/b2ULKsVvv7+ygdAtYQjFCoBc+a
muaI5+1d/UaEA05OYcsRD/IuqoYqHlMp17XmeVZvmKWFmyyWUqFgzMVyzlLFPAHN6vt1pIlrSxcD
b968Sagacxakjqwlo0gl8lBkMWFNVIJUZji2xlNK9oNkFcVs1GNBNmG7EdMwjFZHdY9EUVYgbL6A
IXen8RfOoV5MNNPksBos+w5rFNdNsLUpWaFm+T3QcTSj1L5iOisUk9YQCZJx7GoGqViWxfmlaCeM
E7HmkBES+SMrKebRGFlh/+XLl3T//v28mohlU1yDX62bYGslWUm009NTJqvzvsl7RzYzXo06sW+G
JqXMj8mwzwgxy3+PFdbC9qP/hTeoEee456iXlROZXGRAjDxhD6mePn2aWHjO9dT7MR+2ToKtnWQl
2Sx9zE2ROAfZSCGZuKZms0jFM0F13ABSgQDKJtccH1Co2Hci5UBKSsi+1V5VEMsXn1cludy31j63
szHOGaoG2Z4/f56VLRZyYjhIx348B2TGrc7Pz+WJdwQ8VU8LnmVR4OTkJBMpiOUZ0UaRa+NI9k+E
A5FOgqFXUQubAZ6u5+HfAKRy3yh9Z+NWJWx8mlU0SDpAvkCQUBgnykW8q74uYBNJJQiCIAiCIAiC
IAiCIAiCIAiCIAiCIAiCIAiCIAiCIAiCIAiCIAiCIAiCIAiCIAiCIAiCIAiCIAiCIAiCIAiC8J/g
fwIMAGmisOlGNYfWAAAAAElFTkSuQmCC" transform="matrix(1 0 0 1 148.4 437.5879)">
	</image>
	<g>
		<path fill="#FFFFFF" d="M192.6,462.2h128.5c4.2,0,7.7,3.4,7.7,7.7v61.4c0,4.2-3.4,7.7-7.7,7.7H192.6c-4.2,0-7.7-3.4-7.7-7.7v-61.4
			C185,465.6,188.4,462.2,192.6,462.2z"/>
	</g>
	<circle fill="#EBEFF5" cx="256.9" cy="488.5" r="12"/>
	<g>
		<path fill="#4B575E" d="M234.3,512.7v9.4h-1.2v-9.4H234.3z"/>
		<path fill="#4B575E" d="M236.5,517.1c0-0.7,0-1.3-0.1-1.8h1.1l0.1,1.1h0c0.3-0.6,1.1-1.3,2.2-1.3c0.9,0,2.4,0.6,2.4,2.9v4H241
			v-3.9c0-1.1-0.4-2-1.6-2c-0.8,0-1.4,0.6-1.6,1.3c-0.1,0.2-0.1,0.4-0.1,0.6v4h-1.2V517.1z"/>
		<path fill="#4B575E" d="M244.5,515.3l1.3,3.8c0.2,0.6,0.4,1.2,0.5,1.7h0c0.2-0.6,0.3-1.1,0.6-1.7l1.3-3.8h1.3L247,522h-1.2
			l-2.6-6.7H244.5z"/>
		<path fill="#4B575E" d="M256.7,518.6c0,2.5-1.7,3.6-3.3,3.6c-1.8,0-3.2-1.3-3.2-3.5c0-2.3,1.5-3.6,3.3-3.6
			C255.4,515.2,256.7,516.6,256.7,518.6z M251.4,518.7c0,1.5,0.8,2.6,2,2.6c1.2,0,2-1.1,2-2.6c0-1.1-0.6-2.6-2-2.6
			S251.4,517.4,251.4,518.7z"/>
		<path fill="#4B575E" d="M259.6,513.4c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.7-0.3-0.7-0.8c0-0.4,0.3-0.8,0.8-0.8
			C259.3,512.7,259.6,513,259.6,513.4z M258.2,522v-6.7h1.2v6.7H258.2z"/>
		<path fill="#4B575E" d="M266.3,521.8c-0.3,0.2-1,0.4-1.9,0.4c-2,0-3.3-1.4-3.3-3.4c0-2.1,1.4-3.6,3.6-3.6c0.7,0,1.4,0.2,1.7,0.3
			l-0.3,0.9c-0.3-0.2-0.8-0.3-1.4-0.3c-1.5,0-2.4,1.1-2.4,2.5c0,1.6,1,2.5,2.3,2.5c0.7,0,1.2-0.2,1.5-0.3L266.3,521.8z"/>
		<path fill="#4B575E" d="M268.3,518.9c0,1.7,1.1,2.3,2.3,2.3c0.9,0,1.4-0.2,1.9-0.3l0.2,0.9c-0.4,0.2-1.2,0.4-2.2,0.4
			c-2.1,0-3.3-1.4-3.3-3.4s1.2-3.6,3.2-3.6c2.2,0,2.8,1.9,2.8,3.2c0,0.3,0,0.4,0,0.6H268.3z M271.9,518c0-0.8-0.3-2-1.7-2
			c-1.2,0-1.8,1.1-1.9,2H271.9z"/>
		<path fill="#4B575E" d="M280.4,512.2v8.1c0,0.6,0,1.3,0.1,1.7h-1.1l-0.1-1.2h0c-0.4,0.8-1.2,1.3-2.3,1.3c-1.6,0-2.9-1.4-2.9-3.4
			c0-2.2,1.4-3.6,3-3.6c1,0,1.7,0.5,2,1h0v-4H280.4z M279.2,518.1c0-0.2,0-0.4-0.1-0.5c-0.2-0.8-0.8-1.4-1.8-1.4c-1.3,0-2,1.1-2,2.6
			c0,1.4,0.7,2.5,2,2.5c0.8,0,1.6-0.5,1.8-1.5c0-0.2,0.1-0.3,0.1-0.5V518.1z"/>
	</g>
	<path fill="#2E5F80" d="M258.7,489c0,0.3-0.3,0.6-0.6,0.6h-2.4c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6h2.4
		C258.4,488.4,258.7,488.7,258.7,489z M255.7,486h2.4c0.3,0,0.6,0.3,0.6,0.6s-0.3,0.6-0.6,0.6h-2.4c-0.3,0-0.6-0.3-0.6-0.6
		S255.3,486,255.7,486z M260.5,486v-1.2c0.7,0,1.2,0.5,1.2,1.2H260.5z M259.3,493.2c0,0.7-0.5,1.2-1.2,1.2v-1.2
		c0-0.7-0.5-1.2-1.2-1.2h-2.4v-6c0-0.7,0.5-1.2,1.2-1.2h3.6V493.2z M256.9,494.4h-3.6c-0.7,0-1.2-0.5-1.2-1.2h4.2
		c0.3,0,0.6,0.3,0.6,0.6V494.4z M260.5,483.6h-4.8c-1.3,0-2.4,1.1-2.4,2.4v6h-1.8c-0.3,0-0.6,0.3-0.6,0.6v0.6c0,1.3,1.1,2.4,2.4,2.4
		h4.8c1.3,0,2.4-1.1,2.4-2.4v-6h1.8c0.3,0,0.6-0.3,0.6-0.6V486C262.9,484.7,261.8,483.6,260.5,483.6z"/>
</g>
<g>
	<path fill="#ADB6BC" d="M177.3,508.5h-1.5c-0.3,0-0.5-0.2-0.5-0.5v-11.4c0-0.3,0.2-0.5,0.5-0.5h1.5c0.3,0,0.5,0.2,0.5,0.5V508
		C177.8,508.2,177.5,508.5,177.3,508.5z"/>
	<path fill="#ADB6BC" d="M170.4,503.1v-1.5c0-0.3,0.2-0.5,0.5-0.5h11.4c0.3,0,0.5,0.2,0.5,0.5v1.5c0,0.3-0.2,0.5-0.5,0.5h-11.4
		C170.6,503.5,170.4,503.3,170.4,503.1z"/>
</g>
<path fill="#ADB6BC" d="M182.9,618.1c-5.8,0-10.5-4.7-10.5-10.5h1c0,5.2,4.3,9.5,9.5,9.5V618.1z"/>
<path fill="#ADB6BC" stroke="#ADB6BC" d="M353,407.3c1.4-0.7,3-0.7,4.4,0l-2.2-4.2L353,407.3z"/>
<path fill="#ADB6BC" d="M345.2,617.6v0.5V617.6z M355.2,607.6h0.5H355.2z M182.6,617.1h162.6v1H182.6V617.1z M345.2,617.1
	c5.2,0,9.5-4.3,9.5-9.5h1c0,5.8-4.7,10.5-10.5,10.5V617.1z M355.7,405.6v202h-1v-202H355.7z"/>
<g>
	<path fill="#ADB6BC" d="M354.7,275.9c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5H354.7z M355.7,262.9c0-0.3-0.2-0.5-0.5-0.5
		s-0.5,0.2-0.5,0.5H355.7z M355.7,275.9v-13h-1v13H355.7z"/>
	<circle fill="#ADB6BC" stroke="#ADB6BC" cx="355.2" cy="277.9" r="2.3"/>
	<path fill="#ADB6BC" stroke="#ADB6BC" d="M352.9,266.2c1.4-0.7,3-0.7,4.4,0l-2.2-4.2L352.9,266.2z"/>
</g>
<g>
	<path fill="#ADB6BC" d="M354.7,137.8c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5H354.7z M355.7,124.8c0-0.3-0.2-0.5-0.5-0.5
		s-0.5,0.2-0.5,0.5H355.7z M355.7,137.8v-13h-1v13H355.7z"/>
	<circle fill="#ADB6BC" stroke="#ADB6BC" cx="355.2" cy="139.8" r="2.3"/>
	<path fill="#ADB6BC" stroke="#ADB6BC" d="M352.9,128c1.4-0.7,3-0.7,4.4,0l-2.2-4.2L352.9,128z"/>
</g>
</svg>

)