import React, { useEffect } from 'react';
import { ItemForm } from '../../Components/Form';
import FormLayout from '../../Components/Layout/FormLayout';
import { NewItemIcon } from '../../Assets/Navigation/NavigationIcons';

const AddItem = ({ handleToast }) => {
  useEffect(() => {
    document.title = 'Add Item';
  }, []);

  return (
    <FormLayout
      title="Items"
      Icon={NewItemIcon}
      subtitle="Add New"
      closeUrl="/items"
      iconstyle="general_module_icon"
      layoutBoxClass="uiux-form-redesign"
    >
      <ItemForm type="add" handleToast={handleToast} />
    </FormLayout>
  );
};

export default AddItem;
