import React, { Fragment, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withFormik, FieldArray } from 'formik';
import DeleteIcon from '../../../Assets/General/DeleteIcon';
import { MdEdit, MdDragIndicator } from '../../../Common/Icons';
import {
  getObjFromLS,
  checkError,
  setObjInLS,
  restRequest,
  getItemTotalAmount,
  formatNumber,
  handleExtraDescription,
  getFilteredItem,
  getOverallTaxDetails,
  GetDateString,
} from '../../../Helpers';
import { useLocation } from 'react-router-dom';
import {
  MAX_NUMBER,
  moreThanZero,
  moreThanMinus,
  required,
  ValidateDate,
  ValidateMax100Number,
  ValidateMax10Billion,
  ValidateAmountNumber,
  ValidateMax255,
  ValidateMax50,
  ValidateNumber,
  ValidateEqual0,
  ValidateRequired,
  ValidateMax1000,
} from '../../../Helpers/Validator';
// D:\IMS\ims_frontend\src\Components\SmallLoader\SmallLoader.js
import { validateCreateSalesOrderForm } from '../../../Helpers/FormValidator';
import {
  Field,
  Form,
  TaxForm,
  ItemForm,
  AddressForm,
  BulkAddItems,
  AddItem,
  FormSection,
  FormAction,
  ErrorFocus,
} from '../';
import { formSubmit, getError, validate } from '../FormHelper';
import {
  CustomModal,
  Loader,
  Radio,
  RadioContainer,
  SmallLoader,
} from '../../';
import ListTableImageColumn from '../../ListTable/ListTableImageColumn';
import { getDiscountOptions } from '../../../Constants';
import Switch from './../../Switch/Switch';
import { NewItemIcon } from '../../../Assets/Navigation/NavigationIcons';
import AddContact from '../../../Pages/Contact/AddContact';
import DeliveryMethodForm from '../../Form/DeliveryForm/DeliveryMethodForm';
import Customer from '../../../Pages/Contact/ContactDetails/index';
import {
  NewContactIcon,
  NewInvoiceIcon,
} from '../../../Assets/Navigation/NavigationIcons';
import { Draggable } from 'react-beautiful-dnd';
import TableContant from '../../ListTable/TableContant';
import DragDropItems from '../../../Common/DragDrop';
import useMobileDetection from '../../../Hooks/MobileDetect';
import { ToolTip } from '../../../Common';

let showAddItemButton = false;

let allValls = [];
let settingTheWareId = [];
let flagOnce = 0;
let flagOnceForAdjustement = 0;
let flagOnceForShipping = 0;
let confirm = false;
let totalConfirmCheck = false;

const getYupValidationSchema = (values) => {
  return Yup.object().shape({
    contactId: required(ValidateNumber),
    salesOrderNo: ValidateRequired,
    orderDate: required(ValidateDate),
    expectedShipmentDate: ValidateDate,
    reference: ValidateMax50,
    termsAndConditions: values.hasTermsAndConditions
      ? ValidateMax1000
      : ValidateEqual0,
    customerNotes: ValidateMax255,
    warehouseId: required(ValidateNumber),
    items: Yup.array()
      .of(
        Yup.lazy((item) => {
          return Yup.object().shape({
            name: ValidateRequired,
            quantity: required(
              moreThanZero(ValidateMax10Billion).integer('Invalid Value')
            ),
            unitPrice: required(moreThanMinus(ValidateMax10Billion)),
            discount:
              item.discountType === 'percent'
                ? ValidateMax100Number
                : ValidateAmountNumber(
                  item.discount,
                  item.quantity * item.unitPrice
                ),
          });
        })
      )
      .min(1, 'At least one item is required'),
    adjustment: ValidateMax10Billion,
    shippingCharge: ValidateMax10Billion,
    //transactionDiscount: values.transactionDiscountType === 'amount' ? ValidateAmountNumber(Yup.number().trim()) : ValidateMax100Number
    transactionDiscount:
      values.transactionDiscountType === 'percent'
        ? ValidateMax100Number
        : ValidateMax10Billion,
  });
};

const formEnhancer = withFormik({
  validate: validate(getYupValidationSchema),
  mapPropsToValues: ({ salesOrder }) => ({
    ...salesOrder,
    saveAndSend: false,
    saveAndPrint: false,
  }),
  mapPropsToStatus: (props) => ({
    deliveryMethodsList: props.deliveryMethodsList,
    itemList: props.itemList,
    showContactCreateModal: false,
    showBulkAddItemsModal: false,
    customerAddressList: props.customerAddressList,
    showEditBillingAddressModal: false,
    showEditShippingAddressModal: false,
    showAddBillingAddressModal: false,
    showAddShippingAddressModal: false,
    loadingAddress: false,
    loadingItems: false,
    emptyItem: props.emptyItem,
  }),

  handleSubmit: (values, bag) => {
    sessionStorage.setItem('showPrint', false);
    handleFormSubmit(values, bag);
  },
});

const handleFormSubmit = (
  values,
  {
    setSubmitting,
    props: {
      id,
      submitRequestType,
      submitRequestUrl,
      discountPreference,
      successMessage,
      type,
      moduleDetails,
      history,
      handleToast,
      warehouseFromDB,
      handleTotalCheckTrue,
      handleTotalCheckFalse,
      setPrompt,
    },
  }
) => {
  const total = getOrderTotal(values);
  if (total === 0 && !totalConfirmCheck) {
    handleTotalCheckTrue(true);
    setSubmitting(false);
    return;
  }
  let discountLevel = values.discountLevel;
  if (!discountLevel) {
    if (discountPreference.at_individual_item_level) {
      discountLevel = 'item';
    }
    if (discountPreference.at_transaction_level) {
      discountLevel = 'transaction';
    }
  }
  if (getOrderTotal(values) >= MAX_NUMBER) {
    // un comment when limit remove form the text fields ////////
    // handleToast('Total must be less than Ten Billion.', 'error');
    // setSubmitting(false);
    // return;
  } else if (getOrderTotal(values) === 'MAX_AMOUNT') {
    handleToast('Discount must be less than Total Ordered Amount.', 'error');
    setSubmitting(false);
    return;
  } else if (getOrderTotal(values) === 'MAX_PERCENTAGE') {
    handleToast('Discount must be less than 100 %.', 'error');
    setSubmitting(false);
    return;
  }
  let cloneStatus = 0;
  if (type === 'clone') {
    cloneStatus = 1;
  }
  let inActiveWarehouse = values.items.some((item) => {
    return warehouseFromDB.find(
      (list) => list.is_active === 0 && list.id === item.warehouseId
    );
  });
  if (inActiveWarehouse === true) {
    setSubmitting(false);
    return handleToast('Cannot use inactive warehouse', 'error');
  }
  restRequest(submitRequestType(), submitRequestUrl(), {
    customer_id: values.contactId,
    billing_address_id: values.bilingAdressForSaleOrder,
    shipping_address_id: values.shippingAdressForSaleOrder,
    sales_order_no: values.salesOrderNo,
    order_date:
      values.orderDate && values.orderDate.format('YYYY-MM-DD 00:00:00'),
    expected_shipment_date:
      values.expectedShipmentDate &&
      values.expectedShipmentDate.format('YYYY-MM-DD 23:59:59'),
    sales_person_id: values.salesPersonId,
    reference: values.reference,
    delivery_method_id: values.deliveryMethodId,
    customer_note: values.customerNotes,
    terms_and_condition: values.hasTermsAndConditions
      ? values.termsAndConditions
      : '',
    warehouse_id: values.warehouseId,
    items: values.items.map((item, index) => ({
      sort_order: index,
      warehouseId: allValls[index],
      discount_item_level: item.discount || 0,
      discount_type: item.discountType,
      id: item.id,
      salesOrderItemDetailId: item.salesOrderItemDetailId || null,
      name: item.name,
      quantity: item.quantity || 0,
      rate: item.unitPrice || 0,
      unit: item.unit,
      tax_id: item?.tax?.id,
      tax_rate: item.tax.rate,
      total: item.total,
      extra_description: item.extra_description ? item.extra_description : null,
    })),
    discount_type: values.transactionDiscountType,
    discount_transaction_level: values.transactionDiscount || 0,
    adjustment:
      typeof values.adjustment === 'undefined' || values.adjustment === null
        ? 0
        : values.adjustment,
    shipping_charge:
      typeof values.shippingCharge === 'undefined' ||
        values.shippingCharge === null
        ? 0
        : values.shippingCharge,
    total: getOrderTotal(values),
    clone_status: cloneStatus,
    discount_level: discountLevel,
    confirmed: confirm,
  })
    .then((res) => {
      setPrompt(false);
      successMessage();
      totalConfirmCheck = false;
      handleTotalCheckFalse(false);
      if (values.saveAndSend) {
        history.push(`/salesorders/email/${res.data ? res.data.id : id}`);
      } else if (values.saveAndPrint) {
        sessionStorage.setItem('showPrint', true);
        sessionStorage.setItem('showPrintId', res.data ? res.data.id : id);
        let dataFromLS = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLS,
          moduleName: 'salesorders',
          urlPath: '/salesorders',
          showPrint: true,
          action: type === 'add' ? 'add' : 'justedit',
          queryParam: dataFromLS.queryParam ? dataFromLS.queryParam : '',
        });
        return history.push('/r');
      } else {
        let dataFromLS = getObjFromLS('module');
        if (type === 'add') {
          setObjInLS('module', {
            ...dataFromLS,
            moduleName: 'salesorders',
            urlPath: '/salesorders',
            action: type === 'add' ? 'add' : 'justedit',
            queryParam: '',
          });
        } else if (type === 'clone') {
          setObjInLS('module', {
            ...dataFromLS,
            moduleName: 'salesorders',
            urlPath: '/salesorders',
            action: 'add',
            queryParam: '',
          });
        }
        type === 'edit' &&
          setObjInLS('module', {
            ...dataFromLS,
            moduleName: 'salesorders',
            urlPath: '/salesorders',
            action: type === 'add' ? 'add' : 'justedit',
            queryParam: dataFromLS.queryParam ? dataFromLS.queryParam : '',
          });
        history.push('/r');
        sessionStorage.setItem('fullscreen', false);
      }
    })
    .catch((error) => {
      setSubmitting(false);
      checkError(error);
      handleToast(error, 'error');
    });
};

const SalesOrderForm = ({
  // props from formik
  values,
  status,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
  setStatus,
  setValues,
  isSubmitting,
  errors,
  touched,
  setFieldTouched,

  // custom props
  salesPersonsFromDB,
  is_sales_person,
  sales_person_clearable,
  currency,
  type,
  allData,
  warehouseFromDB,
  organizationCurrency,
  contactIdTransaction,
  discountPreference,
  stockPreference,
  taxFromDB,
  itemsDBList,
  customerList,
  updateCustomerList,
  updateTaxList,
  updateItemList,
  newAddedItem,
  newItem,
  history,
  handleToast,
  emptyItem,
  cofirmState,
  handleTotalCheckTrue,
  handleTotalCheckFalse,
  ...props
}) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [staticVal, setStaticValues] = useState({});
  const [hasMobile] = useMobileDetection();
  const [totalItem, setTotalItem] = useState(0);
  const [page, setPage] = useState(1);
  const [nextPageUrl, setNextPageUrl] = useState(null);

  const [currentCustomer, setCurrentCustomer] = useState({});
  useEffect(() => {
    handleAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, staticVal]);

  useEffect(() => {
    setStaticValues(values);
    let dataFromLS = getObjFromLS('module');
    if (values.contactId) {
      setItemQuantity(values.items.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAlert = () => {
    let { checkout } = validateCreateSalesOrderForm(values, staticVal);
    if (!checkout) props.setPrompt();
    else props.setPrompt(false);
  };

  const handleAddressChange = (addressId, addressType) => {
    if (addressId === 'createNew') {
      addressCreateNewHandler(addressType);
    } else {
      if (addressType === 'billing') {
        setFieldValue('bilingAdressForSaleOrder', addressId);
      } else if (addressType === 'shipping') {
        setFieldValue('shippingAdressForSaleOrder', addressId);
      }
    }
  };

  const addressCreateNewHandler = (addressType) => {
    let addAddressModal = '';
    if (addressType === 'billing') {
      addAddressModal = 'showAddBillingAddressModal';
      handlePrompt(false);
    } else if (addressType === 'shipping') {
      addAddressModal = 'showAddShippingAddressModal';
      handlePrompt(false);
    }
    setStatus({
      ...status,
      [addAddressModal]: true,
    });
  };

  let primaryWarehouse = null;
  warehouseFromDB.forEach(function (warehouse) {
    if (warehouse.is_primary) {
      primaryWarehouse = warehouse.id;
    }
  });
  const addressDetailsView = (addressType = 'billing') => {
    let address = {};
    let editAddressModal = '';
    let addressLabel = '';
    if (addressType === 'billing') {
      address =
        status.customerAddressList.find(
          (address) => address.id === values.bilingAdressForSaleOrder
        ) || {};
      editAddressModal = 'showEditBillingAddressModal';
      addressLabel = 'Bill To';
    } else if (addressType === 'shipping') {
      address =
        status.customerAddressList.find(
          (address) => address.id === values.shippingAdressForSaleOrder
        ) || {};
      editAddressModal = 'showEditShippingAddressModal';
      addressLabel = 'Ship To';
    }

    return (
      <div className="form-field flex-25">
        <label>{addressLabel}</label>
        {Object.keys(address).length !== 0 && (
          <MdEdit
            className="so-customer-address--icon"
            onClick={() => {
              setStatus({
                ...status,
                [editAddressModal]: true,
              });
              handlePrompt(false);
            }}
          />
        )}

        <Field
          // className='form-field flex-25'
          type="dropdown"
          value={address.id}
          options={status.customerAddressList}
          dropdownValue="id"
          disabled={!values.contactId}
          dropdownLabel="attention"
          handleChange={(option) => {
            handleAddressChange(option.value, addressType);
          }}
          searchable={false}
          clearable={false}
          isAddress
          addButton={status.customerAddressList.length === 6 ? false : true}
          onBlur={handleBlur}
          disableOption={
            status.customerAddressList.length === 6 ? (
              <p className="text-limit-set">
                'Cannot add new address - max 6 addresses allowed'
              </p>
            ) : (
              ''
            )
          }
        />

        {Object.keys(address).length !== 0 && (
          <>
            <div className="lower_so_address pt-8">
              <div>{!!address.attention && `${address.attention},`}</div>
              <div>{!!address.street && `${address.street},`}</div>
              <div>
                {!!address.city && `${address.city},`}{' '}
                {!!address.state && `${address.state} `}{' '}
                {!!address.zip_code && `${address.zip_code},`}{' '}
                <p className="so-country">{address.country_name}</p>
              </div>

              {!!address.phone && <div>Phone: {address.phone}</div>}
              {!!address.fax && <div>Fax: {address.fax}</div>}
            </div>
          </>
        )}
      </div>
    );
  };

  const getPriceFunction = async (contactId) => {
    if (values?.items[0]?.id !== null) {
      setLoadingItem(true);
      const ids = values.items.map((item) => {
        return item.id;
      });
      const response = await getPreviousSellingPrice(contactId, ids);
      values.items.map((newItem, index) => {
        let previousRate = response?.find((o) => o.item_id === newItem.id);
        newItem.previousSellingPrice = previousRate?.rate;
        values.items[index] = newItem;
      });
      setLoadingItem(false);
    }
  };

  const updateCustomerAddressList = async (contactId) => {
    status.loadingAddress = true;
    setStatus({ ...status });
    let salesOrderType = (() => {
      switch (type) {
        case 'edit':
          return 'edit';
        case 'clone':
        case 'add':
        default:
          return 'create';
      }
    })();
    restRequest(
      'get',
      `salesorders/${salesOrderType}/contact/${contactId}/contactAddress`
    )
      .then((addressList) => {
        const billingAddress =
          addressList.find((address) => address.address_type === 'billing') ||
          {};
        const shippingAddress =
          addressList.find((address) => address.address_type === 'shipping') ||
          {};
        values.contactId = contactId;
        setValues({
          ...values,
          contactId,
          bilingAdressForSaleOrder:
            !!Object.keys(billingAddress).length !== 0
              ? billingAddress.id
              : null,
          shippingAdressForSaleOrder:
            !!Object.keys(shippingAddress).length !== 0
              ? shippingAddress.id
              : null,
        });
        status.customerAddressList = addressList;
        status.showContactCreateModal = false;
        status.loadingAddress = false;
        setStatus({
          ...status,
          // customerAddressList: addressList,
          // showContactCreateModal: false,
          // loadingAddress: false,
        });
      })
      .catch((error) => checkError(error));
  };
  const handleCustomerAddressChange = (contactId) => {
    setStatus({ ...status, loadingAddress: true });
    restRequest('get', `contacts/${contactId}/addresses`)
      .then((res) => {
        setStatus({
          ...status,
          customerAddressList: res,
          loadingAddress: false,
          showEditBillingAddressModal: false,
          showEditShippingAddressModal: false,
        });
      })
      .catch((error) => checkError(error));
  };

  const handleEditonce = () => {
    try {
      setwareId(settingTheWareId);
    } catch (error) {}
  };
  const handleCustomerChange = async (option) => {
    setwareId(settingTheWareId);
    if (option !== null) {
      // Add New
      if (option.value === 'createNew') {
        setStatus({ ...status, showContactCreateModal: true });
        setValues({
          ...values,
        });
      } else {
        // customer selected
        let contactId = option.value;
        setCustomerName(option.label);
        // let contactId = option.id;    prev
        updateCustomerAddressList(contactId);
        await getPriceFunction(contactId);
      }
    } else {
      setValues({
        ...values,
        contactId: null,
        bilingAdressForSaleOrder: null,
        shippingAdressForSaleOrder: null,
      });
      setStatus({
        ...status,
        customerAddressList: [],
      });
    }
  };
  const handleTermsAndConditions = (event) => {
    let selectedValue = event.target.value;
    setFieldValue(
      'hasTermsAndConditions',
      selectedValue === 'true' ? true : false
    );
  };

  const handleSalesPersonChange = (option) => {
    setFieldValue('salesPersonId', option !== null ? option.value : null);
  };

  const handleDeliveryMethodChange = (option) => {
    if (option === null) {
      setFieldValue('deliveryMethodId', null);
    } else {
      let isNewOption = !status.deliveryMethodsList.find(
        (method) => method.name === option.label
      );
      if (!isNewOption) {
        setFieldValue('deliveryMethodId', option.id);
      } else {
        if (option.value === 'createNew') {
          setFieldValue('showDeliveryMethodModal', true);
        }
      }
    }
  };
  // const fetchWarehouseItems = (warehouseId) => {
  //   setTimeout(() => {
  //     setStatus({
  //       ...status,
  //       loadingItems: true,
  //     });
  //   }, 0);
  //   return restRequest(
  //     'get',
  //     `salesorders/create/warehouseitemlist/${warehouseId}`
  //   )
  //     .then((warehouseItems) => {
  //       return warehouseItems;
  //     })
  //     .catch((error) => checkError(error));
  // };
  let location = useLocation();

  const [wareId, setwareId] = useState([]);
  const [changeme, setChangeme] = useState(false);
  const [, setShowMergeModal] = useState(false);
  const [warehouseSelected, setWarehouseSelected] = useState(false);
  const [warehousedb, setWarehousedb] = useState({});
  const getWarehousesListFromDB = async () => {
    setWarehousedb(status.itemList);
    setWarehouseSelected(true);
  };
  useEffect(() => {
    contactIdTransaction &&
      setFieldValue('contactId', parseInt(contactIdTransaction));
    return () => (flagOnce = 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // allItems = itemsDBList;

    getWarehousesListFromDB();
    return () => {
      allValls = [];
      settingTheWareId = [];
      flagOnce = 0;
      setShowMergeModal(false);
      flagOnceForAdjustement = 0;
      flagOnceForShipping = 0;
      // sendReq = true;
      // flag = false;
      confirm = false;

      // showMergingModal = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleWarehouseChange = async (option, ind) => {
    if (ind === 0) {
      if (wareId.length === 0) setwareId(primaryWarehouse);
      else
        setwareId(
          wareId.map((wareId, index) =>
            index === ind ? (wareId = option.id) : wareId
          )
        );
    } else {
      setwareId(
        wareId.map((wareId, index) =>
          index === ind ? (wareId = option.id) : wareId
        )
      );
    }
    let warehouseId = option ? option.id : null;
    let updatedWarehouseItemsList = [];
    //  if (warehouseId !== null) {
    //    await fetchWarehouseItems(warehouseId).then((list) => {
    //      updatedWarehouseItemsList.push(...list);
    //    });
    //  }
    let updatedItemsList = values.items.reduce((updatedItemsList, item) => {
      let itemFromWarehouse = updatedWarehouseItemsList.find(
        (warehouseItem) => warehouseItem.id === item.id || item.id === null
      );
      if (!!itemFromWarehouse) {
        let updatedItem = {
          ...item,
          physical_quantity: itemFromWarehouse.physical_quantity,
          accounting_quantity: itemFromWarehouse.accounting_quantity,
        };
        updatedItemsList.push(values.items);
      }
      return values.items;
    }, []);
    setValues({
      ...values,
      warehouseId,
      items: values.items,
    });
    setStatus({
      ...status,
      loadingItems: false,
    });
  };

  const getPreviousSellingPrice = async (contactId, item) => {
    // return item
    if (!contactId) {
      return item;
    } else {
      let updatedItem = [];
      setStatus({
        ...status,
        loadingItems: true,
        showContactCreateModal: false,
      });
      let PPURL = 'salesorders/previoussoldprice';
      const payload = {
        customer_id: contactId,
        item_id: item,
        object: 'saleOrder',
        object_id:
          type === 'edit' ? values.salesOrderDetails[0].sales_order_id : null,
      };
      await restRequest('post', PPURL, payload).then((previousPrice) => {
        // updatedItem = {
        //   ...updatedItem,
        //   previousSellingPrice: previousPrice,
        // };
        updatedItem = previousPrice;
        setStatus({
          ...status,
          loadingItems: false,
          showContactCreateModal: false,
        });
      });
      return updatedItem;
    }
  };
  const [, setCurrentItem] = useState(1);
  const [loadingItem, setLoadingItem] = useState(false);
  const [blockEnter, setBlockenter] = useState(false);

  // const inputChange = async (val, index) => {
  //   setBlockenter(val.length >= 7 ? true : false);
  //   var item = getFilteredItem(val, status.itemList);
  //   if (item && item.length === 1) {
  //     handleSelectedItem(item[0], index);
  //   }
  // };
  const handleSelectedItem = async (option, index) => {
    if (option.value === 'createNew') {
      setFieldValue('showItemModal', true);
    } else {
      if (values.items.length === 1) {
        let abb = [];
        abb = wareId;
        abb.push(primaryWarehouse);
        setwareId(abb);
      } else {
        let abb = [];
        abb = wareId;
        wareId[index] = primaryWarehouse;
        setwareId(abb);
      }
      setLoadingItem(true);
      setCurrentItem(option.id);
      const response = await getPreviousSellingPrice(values.contactId, [
        option.id,
      ]);
      let previousRate = response.find((o) => o.item_id === option.id);
      let newItem = {
        id: option.id || option.value,
        dragDropID: GetDateString(),
        name: option.name
          ? option.name
          : option.label.props?.children[1].props?.children[0],
        images: option.images,
        sku: option.sku,
        isbn: option.isbn,
        ean: option.ean,
        mpn: option.mpn,
        upc: option.upc,
        stocks: option.stocks,
        unit: option.unit,
        // dragDropID: GetDateString(),
        quantity: values.items[index].quantity,
        unitPrice: option.sales_unit_price,
        previousSellingPrice: null,
        discountType: values.items[index].discountType, // percent, amount
        discount: values.items[index].discount,
        inventoryType: option.inventory_type === 'noninventory' ? true : false,
        tax: {
          id: option.tax ? option.tax.id : null,
          rate: option.tax ? option.tax.rate : 0,
          name: option.tax ? option.tax.name : '',
        },
        taxAmount: option.tax
          ? getTaxAmount(1, option.sales_unit_price, option.tax.rate)
          : 0,
        total: parseFloat(
          getItemTotalAmount({
            quantity: values.items[index].quantity,
            unitPrice: option.sales_unit_price,
            discountType: values.items[index].discountType,
            discount: values.items[index].discount,
          })
        ).toFixed(2),
        previousSellingPrice: previousRate?.rate || null,
      };
      // let updatedNewItem = await getPreviousSellingPrice(
      //   values.contactId,
      //   newItem
      // );

      setValues({
        ...values,
        items: [
          ...Object.assign([...values.items], {
            [index]: newItem,
          }),
        ],
        showItemModal: false,
      });
      if (option.inventory_type === 'noninventory') {
        setStatus({
          ...status,
        });
      }
      setItemQuantity(itemQuantity + 1);
      setLoadingItem(false);
    }
  };

  const handleItemQuantityChange = (quantityValue, index) => {
    let quantity = quantityValue.floatValue;
    if (quantity > 0) {
      let quantString = quantity.toString();

      // Price_Quantity_Limit

      // price and quantity limit from 5 digits to 10 digits
      if (quantString.length > 10) {
        quantString = quantString.slice(0, 10);
      }
      quantity = parseInt(quantString);
    }
    let itemList = [...values.items];
    let currentItem = itemList[index];
    itemList[index] = {
      ...currentItem,
      quantity,
      taxAmount: !!currentItem?.tax?.id
        ? getTaxAmount(quantity, currentItem.unitPrice, currentItem.tax.rate)
        : 0,
      total: parseFloat(
        getItemTotalAmount({ ...currentItem, quantity })
      ).toFixed(2),
    };
    setFieldValue('items', itemList);
  };

  const handleChangeprevPrice = (prevPrice, index) => {
    let itemList = [...values.items];
    let currentItem = itemList[index];
    itemList[index] = {
      ...currentItem,
      unitPrice: prevPrice,
    };
    setFieldValue('items', itemList);
  };

  const handleItemPriceChange = (unitPriceValue, index) => {
    let unitPrice = unitPriceValue.floatValue;
    if (unitPrice > 0) {
      let unitPriceString = unitPrice.toString();

      // Price_Quantity_Limit

      // price and quantity limit from 5 digits to 10 digits
      if (unitPriceString.length > 10) {
        unitPriceString = unitPriceString.slice(0, 10);
      }
      unitPrice = parseFloat(unitPriceString);
    }
    let itemList = [...values.items];
    let currentItem = itemList[index];
    itemList[index] = {
      ...currentItem,
      unitPrice,
      taxAmount: !!currentItem?.tax?.id
        ? getTaxAmount(currentItem.quantity, unitPrice, currentItem.tax.rate)
        : 0,
      total: parseFloat(
        getItemTotalAmount({ ...currentItem, unitPrice })
      ).toFixed(2),
    };
    setFieldValue('items', itemList);
  };

  const handleAdjustmentChange = (adjustmentValue) => {
    let adjustment = adjustmentValue.floatValue;
    if (adjustment > 0) {
      let adjustmentString = adjustment.toString();
      // Price_Quantity_Limit

      // price and quantity limit from 5 digits to 10 digits
      if (adjustmentString.length > 10) {
        adjustmentString = adjustmentString.slice(0, 10);
      }
      adjustment = parseFloat(adjustmentString);
    }
    setFieldValue('adjustment', adjustment);
  };

  const handleShipChargeChange = (shippingValue) => {
    let shipping = shippingValue.floatValue;
    if (shipping > 0) {
      let shippingString = shipping.toString();

      // Price_Quantity_Limit

      // price and quantity limit from 5 digits to 10 digits

      if (shippingString.length > 10) {
        shippingString = shippingString.slice(0, 10);
      }
      shipping = parseFloat(shippingString);
    }
    setFieldValue('shippingCharge', shipping);
  };

  const handleDiscountTypeChange = (option, index) => {
    let discountType = option.value;
    let itemList = [...values.items];
    itemList[index] = {
      ...itemList[index],
      discountType,
      total: parseFloat(
        getItemTotalAmount({ ...itemList[index], discountType })
      ).toFixed(2),
    };
    setFieldValue('items', itemList);
  };

  const handleItemDiscountChangeT = (value, discountType) => {
    let discount = value.floatValue;
    if (discount > 0) {
      let discountString = discount.toString();
      if (discountType === 'percent') {
        let sliceStrLen = discountString.includes('.') ? 5 : 3;
        if (discountString.length > sliceStrLen) {
          discountString = discountString.slice(0, sliceStrLen);
        }
        discount = parseFloat(discountString);
      } else {
        if (discountString.length > 10) {
          discountString = discountString.slice(0, 10);
        }
        discount = parseFloat(discountString);
      }
    }
    setFieldValue('transactionDiscount', discount);
  };

  const handleItemDiscountChange = (value, index, discountType) => {
    let discount = value.floatValue;
    if (discount > 0) {
      let discountString = discount.toString();
      if (discountType === 'percent') {
        let sliceStrLen = discountString.includes('.') ? 5 : 3;
        if (discountString.length > sliceStrLen) {
          discountString = discountString.slice(0, sliceStrLen);
        }
        discount = parseFloat(discountString);
      } else {
        if (discountString.length > 10) {
          discountString = discountString.slice(0, 10);
        }
        discount = parseFloat(discountString);
      }
    }
    let itemList = [...values.items];
    itemList[index] = {
      ...itemList[index],
      discount,
      total: parseFloat(
        getItemTotalAmount({ ...itemList[index], discount })
      ).toFixed(2),
    };
    setFieldValue('items', itemList);
  };
  const [texIndex, setTexIndex] = useState();
  const handleTaxChange = (option, index) => {
    let itemList = [...values.items];
    let currentItem = itemList[index];
    setTexIndex(index);
    if (option !== null) {
      // Add New
      if (option.value === 'createNew') {
        setFieldValue('showTaxModal', true);
      } else {
        // tax selected
        itemList[index] = {
          ...currentItem,
          tax: {
            id: option.id,
            name: option.name,
            rate: option.rate,
          },
          taxAmount: getTaxAmount(
            currentItem.quantity,
            currentItem.unitPrice,
            option.rate
          ),
        };
        setFieldValue('items', itemList);
      }
    } else {
      itemList[index] = {
        ...currentItem,
        tax: {
          id: null,
          name: '',
          rate: 0,
        },
        taxAmount: 0,
      };
      setFieldValue('items', itemList);
    }
  };

  const taxValueFetchAutomatically = (newTax) => {
    let itemList = [...values.items];
    let currentItem = itemList[texIndex];
    if (itemList) {
      itemList[texIndex] = {
        ...currentItem,
        tax: {
          id: newTax.id,
          name: newTax.name,
          rate: newTax.rate,
        },
        taxAmount: getTaxAmount(
          currentItem.quantity,
          currentItem.unitPrice,
          newTax.rate
        ),
      };
      setFieldValue('items', itemList);
    }
  };

  const newTaxModal = () => {
    return (
      <CustomModal
        showModal={values.showTaxModal}
        title="Add New Tax"
        renderActions={false}
        onClose={closeModal}
        showPrompt={showPrompt}
        classNames="UIUX-addtax-popup invoice-popup-set"
      >
        <TaxForm
          inModal
          type="add"
          onSubmitModal={(newTax) => {
            updateTaxList(newTax);
            setValues({
              ...values,
              tax: newTax.id,
              showTaxModal: false,
            });
          }}
          taxValueFetchAutomatically={taxValueFetchAutomatically}
          closeModal={closeModal}
          modalSource={false}
          handleToast={handleToast}
          handlePrompt={handlePrompt}
        />
      </CustomModal>
    );
  };

  const newItemModal = () => {
    return (
      <CustomModal
        size="large"
        customWidth="item-modal-width"
        renderActions={false}
        showModal={values.showItemModal}
        title="Add New Item"
        onClose={closeModal}
        showPrompt={showPrompt}
      >
        <ItemForm
          inModal
          type="add"
          onSubmitModal={(newItem) => {
            updateItemList(newItem.data);
            let ItemsIndex = values.items.length - 1;
            status.itemList.push(newItem.data);
            setStatus({
              ...status,
              itemList: [...status.itemList],
            });
            handleSelectedItem(newItem.data, ItemsIndex);
            setValues({
              ...values,
              showItemModal: false,
            });
          }}
          modalSource={false}
          closeModal={closeModal}
          handleToast={handleToast}
          handlePrompt={handlePrompt}
        />
      </CustomModal>
    );
  };
  const deliveryMethodModal = () => {
    return (
      <CustomModal
        size="large"
        customWidth="item-modal-width"
        renderActions={false}
        showModal={values.showDeliveryMethodModal}
        title="Add New Delivey Method"
        onClose={closeModal}
        showPrompt={showPrompt}
      >
        <DeliveryMethodForm
          inModal
          type="add"
          onSubmitModal={(newItem) => {
            status.deliveryMethodsList.push(newItem);
            setStatus({
              ...status,
              deliveryMethodsList: [...status.deliveryMethodsList],
            });
            setFieldValue('deliveryMethodId', newItem.id);
            setFieldValue('showDeliveryMethodModal', false);
          }}
          modelSource={false}
          closeModal={closeModal}
          handleToast={handleToast}
          handlePrompt={handlePrompt}
        />
      </CustomModal>
    );
  };
  const handleRemoveItem = (option, removeIndex) => {
    if (values.items.length === 1) {
      values.items.push(emptyItem);
    }
    let updatedSalesOrderItems = values.items.filter(
      (_, index) => index !== removeIndex
    );
    setFieldValue('items', updatedSalesOrderItems);
    if (option.id) setItemQuantity(itemQuantity - 1);
    let updatedWardId = wareId.filter((_, index) => index !== removeIndex);
    setwareId(updatedWardId);
  };

  const handleTransactionDiscountTypeChange = (option) => {
    let transactionDiscountType = option.value;
    setFieldValue('transactionDiscountType', transactionDiscountType);
  };

  const getTaxAmount = (quantity, unitPrice, taxRate) => {
    return parseFloat(((quantity * unitPrice * taxRate) / 100).toFixed(2));
  };

  const renderTotalTax = () => {
    let currencySymbol = organizationCurrency.symbol;
    let overallTax = getOverallTaxDetails(values.items);
    return overallTax.map((tax) => (
      <div className="so-mg-1" key={tax.id}>
        <span>{`${tax.name} (${tax.rate}%)`}</span>
        <span className="semi-bold  float-right">{`${currencySymbol}${formatNumber(
          tax.total
        )}`}</span>
      </div>
    ));
  };

  const renderTermsAndConditions = () => {
    return (
      <div className="form-field flex-50 terms_error">
        <Field
          className="radio-field radio_buttons"
          type="radio"
          labelStyle={true}
          name="Terms & Conditions?"
          id="yes"
        >
          <RadioContainer>
            <Radio
              className="inline-field "
              id="yes"
              value={true}
              name="Yes"
              onChange={handleTermsAndConditions}
              checked={values.hasTermsAndConditions}
            />
            <Radio
              className="inline-field"
              id="no"
              value={false}
              name="No"
              onChange={handleTermsAndConditions}
              checked={!values.hasTermsAndConditions}
            />
          </RadioContainer>
        </Field>

        {values.hasTermsAndConditions && (
          <Field
            size="large"
            type="textarea"
            value={values.termsAndConditions}
            id="termsAndConditions"
            required={true}
            handleChange={(e) => {
              setFieldValue('termsAndConditions', e.target.value);
            }}
            error={touched.termsAndConditions && errors.termsAndConditions}
          />
        )}
      </div>
    );
  };

  const renderTransactionLevelDiscount = () => {
    return (
      <div className="float-left w-100">
        <span className="float-left pr">Discount</span>
        <Field
          className="float-right bold discount_switch_summary_table"
          //size='small'
          type="number"
          value={parseFloat(values.transactionDiscount).toFixed(2)}
          decimalScale={2}
          id="transactionDiscount"
          rightData={
            <Switch
              options={getDiscountOptions(organizationCurrency)}
              value={values.transactionDiscountType}
              handleChange={handleTransactionDiscountTypeChange}
            />
          }
          allowNegative={false}
          thousandSeparator=","
          onValueChange={
            (value) =>
              handleItemDiscountChangeT(value, values.transactionDiscountType)
            // setFieldValue('transactionDiscount', value.floatValue)
          }
          onBlur={handleBlur}
          error={touched.transactionDiscount && errors.transactionDiscount}
          removeValueOnClick
        />
      </div>
    );
  };

  function handleSaveAndSend(event, submitFlag) {
    setFieldValue('saveAndSend', true);
    if (submitFlag === false || submitFlag === undefined) {
      event.preventDefault();
      setShowMergeModal(true);
    } else {
      let dataFromLS = getObjFromLS('module');
      if (type === 'add') {
        setObjInLS('module', {
          ...dataFromLS,
          moduleName: 'salesorders',
          urlPath: '/salesorders',
          action: type === 'add' ? 'add' : 'justedit',
          queryParam: '',
        });
      }
      setTimeout(() => {
        return formSubmit(
          event,
          values.items,
          setFieldValue,
          handleSubmit,
          wareId
        );
      }, 1);
    }
  }
  function handleSaveAndPrint(event, submitFlag) {
    setFieldValue('saveAndPrint', true);
    if (submitFlag === false) {
      event.preventDefault();
    } else {
      setTimeout(() => {
        return formSubmit(
          event,
          values.items,
          setFieldValue,
          handleSubmit,
          wareId
        );
      }, 1);
    }
  }

  function closeModal() {
    setStatus({
      ...status,
      showContactCreateModal: false,
      showEditBillingAddressModal: false,
      showEditShippingAddressModal: false,
      showAddBillingAddressModal: false,
      showAddShippingAddressModal: false,
      showBulkAddItemsModal: false,
    });
    setFieldValue('showTaxModal', false);
    setFieldValue('showItemModal', false);
    setFieldValue('showDeliveryMethodModal', false);
  }

  const editAddressModal = (addressType = 'billing') => {
    let showEditAddressModal = false;
    let addressId = null;
    let addressTitle = null;
    if (addressType === 'billing') {
      showEditAddressModal = status.showEditBillingAddressModal;
      addressId = values.bilingAdressForSaleOrder;
      addressTitle = 'Edit Billing Address';
    }
    if (addressType === 'shipping') {
      showEditAddressModal = status.showEditShippingAddressModal;
      addressId = values.shippingAdressForSaleOrder;
      addressTitle = 'Edit Shipping Address';
    }
    return (
      <CustomModal
        className=""
        showModal={showEditAddressModal}
        title={addressTitle}
        renderActions={false}
        onClose={closeModal}
        showPrompt={showPrompt}
      >
        <AddressForm
          type="edit"
          contactId={values.contactId}
          addressId={addressId}
          onSubmit={() => handleCustomerAddressChange(values.contactId)}
          onCancel={closeModal}
          handleToast={handleToast}
          handlePrompt={handlePrompt}
        />
      </CustomModal>
    );
  };

  const handleContactCreateSubmit = async (newCustomer) => {
    const old_obj = {
      id: newCustomer.id,
      display_name: newCustomer.display_name,
    };
    setCustomerName(newCustomer.display_name);
    const { id: value, display_name: label, ...rest } = old_obj;

    const new_customer = { value, label, ...newCustomer };
    setCurrentCustomer(new_customer);
    updateCustomerList(new_customer);
    updateCustomerAddressList(new_customer.value);
    await getPriceFunction(new_customer.value);
  };

  const handlePrompt = (status = true) => setShowPrompt(status);

  const contactCreateModal = () => {
    return (
      <CustomModal
        size="large"
        showModal={status.showContactCreateModal}
        title="Add Contact"
        customWidth="contact-modal-width"
        renderActions={false}
        onClose={closeModal}
        showPrompt={showPrompt}
      >
        <AddContact
          type="salesOrder"
          inModal
          close={closeModal}
          onSubmit={(res) => handleContactCreateSubmit(res.data)}
          handleToast={handleToast}
          handlePrompt={handlePrompt}
        />
      </CustomModal>
    );
  };

  const GetItemStock = (item) => {
        let itemObj = null;
    itemObj = item?.stocks?.find(
      ({ warehouse_id }) => warehouse_id === item.warehouseId
    );
    if (itemObj && !item.inventoryType) {
      return `Avl.: ${stockPreference == 'physical'
        ? itemObj.physical_quantity
        : itemObj.accounting_quantity
        }`;
    } else if (item.inventoryType) {
      return 'un-tracked';
    } else {
      return `Avl.: 0`
    }
  };

  const handleBulkAddItemsOnSubmit = async (selectedItems) => {
    const ids = selectedItems.map((item) => {
      return item.id;
    });
    const response = await getPreviousSellingPrice(values.contactId, ids);
    // Remove first blank field when adding items in bulk
    if (values.items[0] && values.items[0].id === null) {
      values.items.shift();
    }
    const newItems = [];
    selectedItems.forEach((option) => {
      let previousRate = response.find((o) => o.item_id === option.id);
      const newItem = {
        ...option,
        unitPrice: option.sales_unit_price,
        previousSellingPrice: null,
        discountType: 'percent', // percent, amount
        discount: 0,
        dragDropID: GetDateString(),
        inventoryType: option.inventory_type === 'noninventory' ? true : false,
        tax: {
          id: option.tax ? option.tax.id : null,
          rate: option.tax ? option.tax.rate : 0,
          name: option.tax ? option.tax.name : '',
        },
        taxAmount: option.tax
          ? getTaxAmount(1, option.sales_unit_price, option.tax.rate)
          : 0,
        total: parseFloat(
          getItemTotalAmount({
            quantity: option.quantity,
            unitPrice: option.sales_unit_price,
          })
        ).toFixed(2),
        previousSellingPrice: previousRate?.rate || null,
      };
      // const updatedNewItem = await getPreviousSellingPrice(
      //   values.contactId,
      //   newItem
      // );

      newItems.push(newItem);
    });
    setItemQuantity(itemQuantity + selectedItems.length);

    setValues({
      ...values,
      items: [...values.items, ...newItems],
    });
    setStatus({
      ...status,
      //itemList: status.itemList.filter(item => !newItems.find(newItem => newItem.id === item.id  && item.inventory_type !== "inventory")),
      showBulkAddItemsModal: false,
    });
    document.getElementsByClassName('action-buttons--submit').disabled = false;
    setChangeme(true);
  };
  const [modalState, setModalstate] = useState(false);
  const bulkAddItemsModal = () => {
    return (
      <CustomModal
        size="large bulk"
        showModal={status.showBulkAddItemsModal}
        title="Add Bulk Items"
        modaltitle="modal_BulkItems_title_align"
        // className="saleOrder_title_svg"
        Icon={NewItemIcon}
        renderActions={false}
        onClose={() => {
          setStatus({
            ...status,
            showBulkAddItemsModal: false,
          });
        }}
        handleSubmiting={modalState}
        showPrompt={showPrompt}
      >
        <BulkAddItems
          loadingItem={loadingItem}
          rateKey="sales_unit_price"
          currencySymbol={organizationCurrency.symbol}
          close={closeModal}
          onSubmit={handleBulkAddItemsOnSubmit}
          handleToast={handleToast}
          setModalstate={setModalstate}
          handlePrompt={handlePrompt}
          moduleName="salesorders"
        />
      </CustomModal>
    );
  };
  const handleContactAddressSubmit = (address, addressType) => {
    let addAddressModal = '';
    if (addressType === 'billing') {
      addAddressModal = 'showAddBillingAddressModal';
      setFieldValue('bilingAdressForSaleOrder', address.id);
    }
    if (addressType === 'shipping') {
      addAddressModal = 'showAddShippingAddressModal';
      setFieldValue('shippingAdressForSaleOrder', address.id);
    }
    setStatus({
      ...status,
      customerAddressList: [address, ...status.customerAddressList],
      [addAddressModal]: false,
    });
  };

  const addAddressModal = (addressType = 'billing') => {
    let showAddAddressModal = false;
    if (addressType === 'billing') {
      showAddAddressModal = status.showAddBillingAddressModal;
    }
    if (addressType === 'shipping') {
      showAddAddressModal = status.showAddShippingAddressModal;
    }
    return (
      <CustomModal
        className=" uiux-so-address_popup"
        showModal={showAddAddressModal}
        title="Add New Address"
        renderActions={false}
        onClose={closeModal}
        showPrompt={showPrompt}
      >
        <AddressForm
          type="add"
          contactId={values.contactId}
          addressType="additional"
          onSubmit={(address) =>
            handleContactAddressSubmit(address, addressType)
          }
          onCancel={closeModal}
          handleToast={handleToast}
          handlePrompt={handlePrompt}
          showPrompt={showPrompt}
        />
      </CustomModal>
    );
  };

  const onChangeValueHandler = (val, index) => {
    let newVal = handleExtraDescription(val, 254);
    values.items[index].extra_description = newVal;
    setFieldValue(`values.items[${index}].extra_description`, newVal);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(values.items);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFieldValue('items', items);
  };

  const handleArrowsKey = (e, index, fieldname) => {
    if (e.key === 'ArrowDown' && index + 1 < values?.items?.length) {
      const element = document.getElementById(
        `items[${index + 1}].${fieldname}`
      );
      setTimeout(() => {
        element.select();
        element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
      }, 100);
    }
  };

  const handleArrowUpKey = (e, index, fieldname) => {
    if (e.key === 'ArrowUp' && index - 1 >= 0) {
      const element = document.getElementById(
        `items[${index - 1}].${fieldname}`
      );
      element.select();
      element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
    }
  };

  function renderItemSelector() {
    let currencySymbol = organizationCurrency.symbol;
    if (
      !location.pathname.includes('add') ||
      location.pathname.includes('/salesorders/edit') ||
      location.pathname.includes('/salesorders/clone')
    ) {
      if (flagOnce === 0) {
        flagOnce = 1;
        values.salesOrderDetails.forEach(function (item) {
          if (item.warehouse_id === null) {
            settingTheWareId.push(primaryWarehouse);
          } else {
            settingTheWareId.push(item.warehouse_id);
          }
        });
        handleEditonce();
      }
    }
    if (changeme) {
      for (let i = wareId.length; i < values.items.length; i++) {
        let abb = [];
        abb = wareId;
        //abb.push(values.items[0].wareId)
        abb.push(primaryWarehouse);
        setwareId(abb);
      }
    }

    let physcialQuantityItem = new Array(status.itemList.length);
    return (
      <FieldArray
        name="items"
        render={() => {
          let disableOption = [];
          for (let index = 0; index < status.itemList.length; index++) {
            if (status.itemList[index].inventory_type === 'noninventory') {
              disableOption[index] = true;
            } else {
              disableOption[index] = false;
            }
          }
          return (
            <div className="so-tbletabs">
              <table
                style={{ width: '100%' }}
                className={`form-focus  prev-avl-field   create-so-form list-table form-table border create_sales_order  create-so-table ${
                  values.discountLevel === 'item'
                  ? // values.discountLevel === 'transaction'
                  ' discount-added create-ds-form'
                  : 'horizontal-items-table '
                  }`}

              >
                <thead>
                  <tr>
                    <th>Item Name</th>
                    <th className="left-align">Warehouse</th>
                    <th className="left-align">Qty.</th>
                    <th className="left-align">Price</th>
                    {(values.discountLevel === 'item' ||
                      discountPreference.at_individual_item_level) &&
                      values.discountLevel !== 'transaction' && (
                        <th className="left-align">Discount</th>
                      )}
                    <th className="left-align">Tax</th>
                    <th className="right-align">Amount</th>
                  </tr>
                </thead>
                {values.items.forEach(function (item, index) {
                  if (
                    wareId[index] === primaryWarehouse &&
                    primaryWarehouse !== null
                  ) {
                    item['wareId'] = primaryWarehouse;
                  } else {
                    if (wareId[index]) {
                      item['wareId'] = wareId[index];
                    } else {
                      item['wareId'] = primaryWarehouse;
                    }
                  }
                })}
                <DragDropItems handleOnDragEnd={handleOnDragEnd}>
                  {values.items.map((item, index) => (
                    <Draggable
                      key={item.dragDropID}
                      draggableId={item.dragDropID}
                      index={index}
                    >
                      {(provided) => (
                        <tr
                          key={index}
                          className="table--row"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <td>
                            <div className="__dragDrop">
                              <div
                                {...provided.dragHandleProps}
                                className={
                                  values.items.length >= 2
                                    ? '__dragDrop_iconParent'
                                    : '__dragDrop_iconParent notDisplay'
                                }
                                tabIndex="-1"
                              >
                                <MdDragIndicator />
                              </div>
                              <div className="__drag-righ">
                                {item.id ? (
                                  <ListTableImageColumn
                                    name={item.name}
                                    sku={item.sku}
                                    images={item.images}
                                    showSKU={true}
                                    id={item.id}
                                    showModalUponClick={true}
                                    value={item.extra_description}
                                    index={index}
                                    showExtraDescription={true}
                                    onChangeValue={onChangeValueHandler}
                                  />
                                ) : (
                                  <Field
                                    id="itemId"
                                    addButton={true}
                                    autoFocus={true}
                                    add
                                    // type="dropdown"
                                    type="DynamicPaginationDropdown"
                                    value={item.id}
                                    itemsDropDown={true}
                                    withImage={true}
                                    alphabetSort
                                    api="salesorders/items"
                                    disabled={!values.contactId}
                                    placeholder={
                                      values.contactId
                                        ? 'Select Item'
                                        : 'Please Select Customer'
                                    }
                                    // additionalSearchFilter="sku,upc,mpn,ean,isbn"
                                    searchKeys={[
                                      'name',
                                      'sku',
                                      'upc',
                                      'mpn',
                                      'ean',
                                      'isbn',
                                    ]}
                                    clearable={false}
                                    handleChange={(option) => {
                                      handleSelectedItem(option, index);
                                    }}
                                    error={getError(
                                      errors,
                                      touched,
                                      `items[${index}].name`
                                    )}
                                    onBlur={() =>
                                      setFieldTouched(
                                        `items[${index}].name`,
                                        true
                                      )
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="left-align no-padding">
                            <Field
                              size="large"
                              type="dropdown"
                              id="warehouseId"
                              value={wareId[index]}
                              options={warehouseFromDB}
                              dropdownValue="id"
                              dropdownLabel="name"
                              disabled={item.inventoryType || !item.id}
                              clearable={false}
                              handleChange={(option) => {
                                handleWarehouseChange(option, index);
                              }}
                              placeholder="Select"
                              onBlur={handleBlur}
                              required
                              error={touched.warehouseId && errors.warehouseId}
                            />
                          </td>
                          <td className="right-align no-padding previous_sold">
                            <Field
                              size="small"
                              type="number"
                              value={item.quantity}
                              decimalScale={6}
                              id={`items[${index}].quantity`}
                              allowNegative={false}
                              thousandSeparator=","
                              onValueChange={(value) =>
                                handleItemQuantityChange(value, index)
                              }
                              error={getError(
                                errors,
                                touched,
                                `items[${index}].quantity`
                              )}
                              disabled={
                                type === 'dropship' || type === 'backorder'
                              }
                              onBlur={handleBlur}
                              onKeyDown={(e) =>
                                handleArrowsKey(e, index, 'quantity')
                              }
                              onKeyUp={(e) =>
                                handleArrowUpKey(e, index, 'quantity')
                              }
                            />
                            {
                              <div className="table-extra-data right-align">
                                {GetItemStock(item)}
                              </div>
                            }
                          </td>

                          <td className="right-align no-padding previous_sold">
                            <Field
                              size="small"
                              type="number"
                              value={item.unitPrice}
                              decimalScale={6}
                              id={`items[${index}].unitPrice`}
                              prefix={currencySymbol}
                              disabled={false}
                              allowNegative={false}
                              thousandSeparator=","
                              onValueChange={(values) =>
                                handleItemPriceChange(values, index)
                              }
                              error={getError(
                                errors,
                                touched,
                                `items[${index}].unitPrice`
                              )}
                              onBlur={handleBlur}
                              onKeyDown={(e) =>
                                handleArrowsKey(e, index, 'unitPrice')
                              }
                              onKeyUp={(e) =>
                                handleArrowUpKey(e, index, 'unitPrice')
                              }
                            />
                            {!!item.previousSellingPrice && (
                              <ToolTip content="Click to set this price">
                                <div className="table-extra-data right-align">
                                  <div
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      handleChangeprevPrice(
                                        item?.previousSellingPrice,
                                        index
                                      )
                                    }
                                  >{`Prev Sold: ${currencySymbol}${item.previousSellingPrice}`}</div>
                                </div>
                              </ToolTip>
                            )}
                          </td>

                          {(values.discountLevel === 'item' ||
                            discountPreference.at_individual_item_level) &&
                            values.discountLevel !== 'transaction' && (
                              <td className="right-align item-level-discount no-padding discount_switch">
                                <Field
                                  size="xs"
                                  type="number"
                                  value={parseFloat(item.discount).toFixed(2)}
                                  //value={item.discount}
                                  decimalScale={2}
                                  id={`items[${index}].discount`}
                                  rightData={
                                    <Switch
                                      value={item.discountType}
                                      options={getDiscountOptions(
                                        organizationCurrency
                                      )}
                                      handleChange={(option) =>
                                        handleDiscountTypeChange(option, index)
                                      }
                                    />
                                  }
                                  allowNegative={false}
                                  thousandSeparator=","
                                  onValueChange={(values) => {
                                    handleItemDiscountChange(
                                      values,
                                      index,
                                      item.discountType
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  error={getError(
                                    errors,
                                    touched,
                                    `items[${index}].discount`
                                  )}
                                  onKeyDown={(e) =>
                                    handleArrowsKey(e, index, 'discount')
                                  }
                                  onKeyUp={(e) =>
                                    handleArrowUpKey(e, index, 'discount')
                                  }
                                  removeValueOnClick
                                />
                              </td>
                            )}

                          <td className="no-padding">
                            <Field
                              size="small"
                              type="dropdown"
                              value={item?.tax?.id}
                              options={taxFromDB}
                              dropdownValue="id"
                              dropdownLabel="name"
                              handleChange={(option) =>
                                handleTaxChange(option, index)
                              }
                              disabled={!item.id}
                              placeholder="Select"
                              addButton={true}
                            />
                          </td>

                          <td className="list-table--amount right-align">
                            <span>
                              {currencySymbol}
                              {parseFloat(getItemTotalAmount(item)).toFixed(2)}
                            </span>
                            <button
                              type="button"
                              disabled={
                                values.items.length === 1 &&
                                values.items[0].id === null
                              }
                              className="close-btn button"
                              onClick={(option) =>
                                handleRemoveItem(item, index)
                              }
                            >
                              <DeleteIcon />
                            </button>
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                </DragDropItems>
              </table>
            </div>
          );
        }}
      />
    );
  }

  const renderItemsSummary = () => {
    let currencySymbol = organizationCurrency.symbol;
    var currentLocation = window.location;

    if (currentLocation.pathname.includes('add')) {
      if (flagOnceForAdjustement === 0) {
        values.adjustment = 0;
        flagOnceForAdjustement = 1;
      }
      if (flagOnceForShipping === 0) {
        values.shippingCharge = 0;
        flagOnceForShipping = 1;
      }
    }
    return (
      <div className="float-left w-100 mt_3 summary_table">
        <hr className="margin float-left w-100" />
        <div className="float-left w-60 p_10">
          <div className="float-left w-40 so-mg-1">
            <span className="float-left">Total Items:</span>
            <span className="semi-bold">{itemQuantity}</span>
          </div>
        </div>
        <div className="float-left w-40 p_10 pr-0">
          <div className="float-left w-100 so-mg-1">
            <span className="float-left">Sub Total </span>
            <span className="float-right semi-bold">{`${currencySymbol}${parseFloat(
              getSubtotal(values.items)
            ).toFixed(2)}`}</span>
          </div>

          {/* <hr className='margin float-left w-100' /> */}

          {renderTotalTax()}

          {(values.discountLevel === 'transaction' ||
            discountPreference.at_transaction_level) &&
            values.discountLevel !== 'item' &&
            renderTransactionLevelDiscount()}

          <div className="float-left w-100">
            <span className="float-left pr">Adjustment</span>
            <Field
              className="float-right bold"
              type="number"
              value={parseFloat(values.adjustment).toFixed(2)}
              decimalScale={2}
              allowNegative
              id="adjustment"
              placeholder={`${currencySymbol}0`}
              prefix={currencySymbol}
              thousandSeparator=","
              onValueChange={(value) => handleAdjustmentChange(value)}
              onBlur={handleBlur}
              error={touched.adjustment && errors.adjustment}
              removeValueOnClick
            />
          </div>

          <div className="float-left w-100">
            <span className="float-left pr">Shipping & Handling</span>
            <Field
              className="float-right bold"
              type="number"
              value={parseFloat(values.shippingCharge).toFixed(2)}
              decimalScale={2}
              id="shippingCharge"
              placeholder={`${currencySymbol}0`}
              prefix={currencySymbol}
              thousandSeparator=","
              onValueChange={(value) => handleShipChargeChange(value)}
              onBlur={handleBlur}
              error={touched.shippingCharge && errors.shippingCharge}
              removeValueOnClick
            />
          </div>
        </div>

        <hr className="margin float-left w-100" />
        <div className="float-left w-100">
          <div className="float-left w-60 p_10"></div>
          <div className="baseline float-left w-40 ">
            <span className="semi-bold float-left pr_total">Total</span>
            <span className="total-price--value float-right">{`${currencySymbol}${
              !isNaN(getOrderTotal(values))
              ? parseFloat(getOrderTotal(values)).toFixed(2)
              : 0.0
              }`}</span>
          </div>
        </div>
        <hr className="margin float-left w-100" />
        <hr className="margin float-left w-100" />
      </div>
    );
  };

  const renderModals = () => {
    return (
      <Fragment>
        {contactCreateModal()}
        {bulkAddItemsModal()}
        {newTaxModal()}
        {deliveryMethodModal()}
        {newItemModal()}
        {addAddressModal('billing')}
        {addAddressModal('shipping')}
        {editAddressModal('billing')}
        {editAddressModal('shipping')}
        {showCustomerModal && contactDeatilModal()}
        {pendingIvoicesModal()}
      </Fragment>
    );
  };
  allValls = wareId;
  values.items.forEach((item) => {
    if (item.id === null) {
      showAddItemButton = false;
    } else {
      showAddItemButton = true;
    }
  });
  values.items.forEach((item, index) => {
    item['warehouseId'] = wareId[index];
  });

  const checkTotalValueForZero = () => {
    return (
      <CustomModal
        style={{ marginTop: '5%' }}
        showModal={cofirmState}
        title="Confirm Sales Order"
        renderActions={false}
        onClose={() => handleTotalCheckFalse(false)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <p>
            Your total sales order amount is Zero. Please confirm to continue.
          </p>

          <button
            type="button"
            className="action-buttons--submit next-btn"
            onClick={() => {
              handleTotalCheckFalse(false);
              totalConfirmCheck = true;
              handleSubmit();
            }}
          >
            Confirm
          </button>
        </div>
      </CustomModal>
    );
  };

  let submitFlag = true;
  /*let mergeItemsArray=[]
  values.items.map((item,index)=>{
   flag=mergeItemsArray.some(
     (matchItem) => item.id === matchItem.id && item.warehouseId===matchItem.warehouseId
   )
   if(flag){
     submitFlag=false
     return true
   }else{
     mergeItemsArray.push(item)
     return false
   }
  })*/

  const [stopRequest, setstopRequest] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [itemQuantity, setItemQuantity] = useState(0);
  const handleCustomerModal = (id) => {
    setCustomerId(id);
    setShowCustomerModal(true);
  };
  const handleDetailModal = (id) => {
    setCustomerId(id);
    setShowDetailModal(true);
  };
  const contactDeatilModal = () => {
    return (
      <CustomModal
        size="large"
        className="contacts_modal_view"
        showModal={showCustomerModal}
        title="View Contact"
        // minWidth={120}
        modaltitle="inventory_modal_title"
        Icon={NewContactIcon}
        renderActions={false}
        onClose={() => setShowCustomerModal(false)}
        linkTo={`contacts/${customerId && customerId}`}
      >
        <Customer id={customerId && customerId} inModal={true} />
      </CustomModal>
    );
  };
  const pendingIvoicesModal = () => {
    return (
      <CustomModal
        size="large"
        className="view_pending_popup"
        showModal={showDetailModal}
        title={`View ${customerName} Pending Invoices`}
        // minWidth={120}
        modaltitle="inventory_modal_title"
        Icon={NewInvoiceIcon}
        renderActions={false}
        mainContainerClass="pending-invoice-popup"
        onClose={() => setShowDetailModal(false)}
      >
        <TableContant
          url={`salesorders/unpaid/${customerId}`}
          inModal={true}
          showdetail="Invoices"
          currency={organizationCurrency.symbol}
        />
      </CustomModal>
    );
  };

  return (
    <Fragment>
      {renderModals()}
      {checkTotalValueForZero()}
      <Form
        className="salesorder-form feilds_so so-main-form"
        onSubmit={(event) => {
          if (stopRequest === true) event.preventDefault();
          else if (submitFlag === false) {
            event.preventDefault();
            setShowMergeModal(true);
          } else {
            formSubmit(event, values.items, setFieldValue, handleSubmit);
          }
        }}
      >
        <FormSection
          title={`${
            type && type === 'add' ? 'Add' : type === 'edit' ? 'Edit' : 'Clone'
            } Sales Order`}
        >
          <div className="form-fields-container d-flex flex-wrap  position-relative uiux_so_addform">
            <div className="form-field flex-25">
              <Field
                type="DynamicPaginationDropdown"
                name="Customer Name"
                id="contactId"
                value={values.contactId}
                newCust={currentCustomer}
                editCust={values.contactObj}
                handleChange={(option) => handleCustomerChange(option)}
                required
                addButton
                alphabetSort
                error={touched.contactId && errors.contactId}
                placeholder={'Select'}
                onBlur={() => {
                  setFieldTouched('contactId', true);
                }}
                api="salesorders/contact"
              />

              {values.contactId && (
                <>
                  <div className="details-sec text-left d-flex space-between">
                    <button
                      type="button"
                      className="view-details-btn-1 pt-8"
                      onClick={() => handleDetailModal(values.contactId)}
                    >
                      {' '}
                      Pending Invoices
                    </button>
                    <div className="details-sec text-right">
                      <button
                        type="button"
                        className="view-details-btn-1 pt-8"
                        onClick={() => handleCustomerModal(values.contactId)}
                      >
                        {' '}
                        View Details
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>

            {status.loadingAddress ? (
              // <Loader className='d-inline so-loader-w' />
              <div className="details-loader">
                <SmallLoader />
              </div>
            ) : (
              <>
                {addressDetailsView('billing')}
                {addressDetailsView('shipping')}
              </>
            )}

            <Field
              name="Sales Order No."
              id="salesOrderNo"
              disabled
              placeholder="Sales Order Number"
              value={values.salesOrderNo}
              handleChange={handleChange}
              onBlur={handleBlur}
              required
              error={touched.salesOrderNo && errors.salesOrderNo}
              className="flex-25"
            />

            <Field
              type="date"
              name="Order Date"
              id="orderDate"
              value={values.orderDate}
              handleChange={(date) => {
                if (!date) return;
                setFieldValue('orderDate', date);
                if (values?.expectedShipmentDate < date)
                  setFieldValue('expectedShipmentDate', date);
              }}
              onBlur={() => setFieldTouched('orderDate', true)}
              required
              error={touched.orderDate && errors.orderDate}
              className="flex-25 "
            />

            <Field
              type="date"
              name="Expected Shipment Date"
              id="expectedShipmentDate"
              value={values.expectedShipmentDate}
              minDate={values.orderDate}
              handleChange={(date) => {
                setFieldValue('expectedShipmentDate', date);
              }}
              onBlur={() => setFieldTouched('expectedShipmentDate', true)}
              error={
                touched.expectedShipmentDate && errors.expectedShipmentDate
              }
              className="flex-25"
            />

            {!!salesPersonsFromDB && (
              <Field
                type="dropdown"
                name="Sales Person"
                id="salesPersonId"
                value={values.salesPersonId}
                options={salesPersonsFromDB.map(
                  (salesPerson) => salesPerson.users
                )}
                disabled={is_sales_person}
                dropdownValue="id"
                dropdownLabel="name"
                handleChange={handleSalesPersonChange}
                className="flex-25"
                clearable={sales_person_clearable}
              />
            )}

            <Field
              type="dropdown"
              name="Delivery Method"
              id="deliveryMethodId"
              value={values.deliveryMethodId}
              options={status.deliveryMethodsList}
              dropdownValue="id"
              dropdownLabel="name"
              handleChange={handleDeliveryMethodChange}
              creatable
              addButton={true}
              className="flex-25"
            />
            <Field
              type="textarea"
              name="Customer Notes"
              value={values.customerNotes}
              id="customerNotes"
              handleChange={handleChange}
              onBlur={handleBlur}
              error={touched.customerNotes && errors.customerNotes}
              className="flex-50 w-100"
            />
            {renderTermsAndConditions()}
            <Field
              name="Reference"
              id="reference"
              value={values.reference}
              handleChange={handleChange}
              onBlur={handleBlur}
              error={touched.reference && errors.reference}
              className="flex-25"
            />
          </div>
          <div className="form-table--container mr_0 mt-6">
            <label className="semi-bold float-left w-100 mb_3">
              Select Items
            </label>
            {loadingItem && <Loader />}
            {renderItemSelector()}
            {/* add item */}
            {showAddItemButton && (
              <AddItem
                onClick={() => {
                  if (values.items.length === 1 && wareId.length === 0) {
                    let abb = [];
                    abb = wareId;
                    //abb.push(values.items[0].wareId);
                    abb.push(primaryWarehouse);
                    setwareId(abb);
                  } else {
                    let abb = [];
                    abb = wareId;
                    abb.push(values.items[0].wareId);
                    setwareId(abb);
                  }
                  setValues({
                    ...values,
                    items: [...values.items, status.emptyItem],
                  });
                }}
              />
            )}

            {values.contactId && (
              <AddItem
                label={'Add Bulk Item'}
                onClick={() =>
                  !loadingItem &&
                  setStatus({
                    ...status,
                    showBulkAddItemsModal: true,
                  })
                }
              />
            )}

            {renderItemsSummary()}
          </div>

          <FormAction
            className="So-actions"
            disabled={isSubmitting}
            // marginFromRight={5.5}
            saveBtnFlag={values.status === 'confirmed'}
            onSubmit={handleSubmit}
            onCancel={() => {
              let dataFromLS = getObjFromLS('module');
              let redirectUrl = '';
              if (dataFromLS && dataFromLS.moduleName === 'salesorders') {
                redirectUrl = dataFromLS.queryParam;
              }
              if (dataFromLS.moduleName === 'contacts') {
                history.push('/contacts');
                return;
              }
              if (
                window.location.pathname.includes('add') ||
                window.location.pathname.includes('edit')
              ) {
                history.push('/salesorders' + redirectUrl);
              } else {
                setObjInLS('module', {
                  ...dataFromLS,
                  action: null,
                });
                history.push('/r');
              }
            }}
            isSalesOrder={true}
            isSO
            dropUpSO={
              values.status === 'confirmed'
                ? [
                  {
                    label: 'Save & Print',
                    onClick: (event) => {
                      setstopRequest(true);
                      handleSaveAndPrint(event, submitFlag);
                      handleSubmit();
                    },
                    disabled: isSubmitting,
                  },
                  {
                    label: 'Save & Send',
                    onClick: (event) => {
                      setstopRequest(true);
                      handleSaveAndSend(event, submitFlag);
                      handleSubmit();
                    },
                    disabled: isSubmitting,
                  },
                ].filter((item) =>
                  hasMobile ? item.label !== 'Save & Print' : item
                )
                : [
                  {
                    label: 'Save & Print',
                    onClick: (event) => {
                      setstopRequest(true);
                      handleSaveAndPrint(event, submitFlag);
                      handleSubmit();
                    },
                    disabled: isSubmitting,
                  },
                  {
                    label: 'Save & Send',
                    onClick: (event) => {
                      setstopRequest(true);
                      handleSaveAndSend(event, submitFlag);
                      handleSubmit();
                    },
                    disabled: isSubmitting,
                  },
                  {
                    label: 'Save & Confirm',
                    onClick: (event) => {
                      setstopRequest(true);
                      confirm = true;
                      formSubmit(
                        event,
                        values.items,
                        setFieldValue,
                        handleSubmit
                      );
                    },
                    disabled: isSubmitting,
                  },
                ].filter((item) =>
                  hasMobile ? item.label !== 'Save & Print' : item
                )
            }
            additionalFields={[
              {
                label: 'Save & Send',
                onClick: (event) => {
                  handleSaveAndSend(event, submitFlag);
                  handleSubmit();
                },
                disabled: isSubmitting,
              },
              {
                label: 'Save & Print',
                onClick: (event) => {
                  handleSaveAndPrint(event, submitFlag);
                  handleSubmit();
                },
                disabled: isSubmitting,
              },
            ].filter((item) =>
              hasMobile ? item.label !== 'Save & Print' : item
            )}
          />
        </FormSection>
        <ErrorFocus />
      </Form>
      {/* {allValls = wareId} */}
    </Fragment>
  );
};

const getOrderTotal = (values) => {
  let {
    items,
    adjustment = 0,
    shippingCharge = 0,
    transactionDiscountType,
    transactionDiscount = 0,
  } = values;
  let subTotal = getSubtotal(items);
  let taxTotal = getTotalTax(values);

  if (transactionDiscountType === 'percent') {
    if (transactionDiscount > 100) {
      return 'MAX_PERCENTAGE';
    }
    let totalTransactionDiscount = (subTotal * transactionDiscount) / 100;
    return parseFloat(
      (
        subTotal +
        taxTotal +
        +adjustment +
        shippingCharge -
        totalTransactionDiscount
      ).toFixed(2)
    );
  } else if (transactionDiscountType === 'amount') {
    let OrderAmount = parseFloat((subTotal + taxTotal).toFixed(2));
    if (OrderAmount < transactionDiscount) {
      return 'MAX_AMOUNT';
    }
    return parseFloat(
      (
        subTotal +
        taxTotal +
        +adjustment +
        shippingCharge -
        transactionDiscount
      ).toFixed(2)
    );
  } else
    return parseFloat(
      (subTotal + taxTotal + +adjustment + shippingCharge).toFixed(2)
    );
};

const getSubtotal = (itemList) => {
  return parseFloat(
    itemList
      .reduce((total, item) => total + getItemTotalAmount(item), 0)
      .toFixed(2)
  );
};

const getTotalTax = (values) => {
  let overallTax = getOverallTaxDetails(values.items);
  return overallTax.reduce((total, tax) => {
    return total + tax.total;
  }, 0);
};

export default formEnhancer(SalesOrderForm);
