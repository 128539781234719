import React, { useEffect, useState } from 'react';
import Toaster from '../../Helpers/Toaster/Toaster';

const InternetConnection = () => {
  const [internet, SetInternet] = useState({});

  useEffect(() => {
    let id;
    if (internet?.status) {
      id = setTimeout(() => {
        SetInternet({});
      }, 4000);
    }

    return () => id && clearInterval(id);
  }, [internet?.status]);

  const doesConnectionExist = () => {
    var xhr = new XMLHttpRequest();
    var file = 'https://i.imgur.com/7ofBNix.png';
    var randomNum = Math.round(Math.random() * 10000);
    xhr.open('HEAD', file + '?rand=' + randomNum, true);
    xhr.send();
    xhr.addEventListener('readystatechange', processRequest.bind(this), false);
    function processRequest(e) {
      if (xhr.readyState === 4) {
        console.log('internet settings enabled');
        SetInternet({
          status: xhr.status >= 200 && xhr.status < 304 ? 'success' : 'error',
          message:
            xhr.status >= 200 && xhr.status < 304
              ? 'Internet Connected'
              : 'No Internet Connection',
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener('online', doesConnectionExist);
    window.addEventListener('offline', doesConnectionExist);

    return () => {
      window.removeEventListener('online', doesConnectionExist);
      window.removeEventListener('offline', doesConnectionExist);
    };
  }, []);

  return (
    <Toaster message={internet.message || ''} type={internet.status || ''} />
  );
};

export default InternetConnection;
