import React, { Fragment } from 'react';
import getDate from '../../../../Helpers/Date/GetDate';
import DropshipMultipleLink from '../../../../Helpers/DropshipMultipleLink';
export default ({ purchaseOrder, inModal }) => {
  const {
    order_date: date,
    expected_delivery_date: deliveryDate,
    reference,
    warehouse,
    type,
    dropShipSalesOrderNo,
  } = purchaseOrder;
  return (
    <div className="section-details--info package_details">
      {/*{type && type !== 'dropship' && <hr className="float-left w-100" />}*/}
      {date && (
        <Fragment>
          <div className="float-left w-100 py_5">
            <div className="section-details--info-title semi-bold float-left">
              Date
            </div>
            <div className="float-right">{getDate(date)}</div>
          </div>
          <hr className="float-left w-100" />
        </Fragment>
      )}

      {deliveryDate && (
        <Fragment>
          <div className="float-left w-100 py_5">
            <div className="section-details--info-title semi-bold float-left">
              Delivery Date
            </div>
            <div className="float-right">{getDate(deliveryDate)}</div>
          </div>
          <hr className="float-left w-100" />
        </Fragment>
      )}

      {reference && (
        <Fragment>
          <div className="float-left w-100 py_5 d-flex align-center space-between">
            <div className="section-details--info-title semi-bold float-left">
              Reference
            </div>
            <div className="float-right sale-order-ref">{reference}</div>
          </div>
          <hr className="float-left w-100" />
        </Fragment>
      )}

      {!!warehouse && (
        <Fragment>
          <div className="float-left w-100 py_5">
            <div className="section-details--info-title semi-bold float-left">
              Warehouse
            </div>
            <div className="float-right warehouse-name-desk">
              {warehouse.name}
            </div>
          </div>
          {type && type === 'backorder' && <hr className="float-left w-100" />}
        </Fragment>
      )}
      {type && (type === 'dropship' || type === 'backorder') && (
        <Fragment>
          <div className="float-left w-100 py_5">
            <div className="section-details--info-title semi-bold float-left">
              {`${type === 'dropship' ? 'Dropship ' : 'Backorder'} Reference`}
            </div>
            <div className="float-right">
              <DropshipMultipleLink
                dropShipments={dropShipSalesOrderNo}
                field={'sales_order_no'}
                redirect={'salesorders'}
                inModal={inModal}
                showCustomModal={'salesorder'}
              />
            </div>
          </div>
          {/*<hr className="float-left w-100" />*/}
        </Fragment>
      )}
    </div>
  );
};
