import React, { Component } from 'react';

import { restRequest } from '../../../../../Helpers/RequestHelper';
import EmployeesForm from './../../../../../Components/Form/EmployeesForm/EmployeesForm';
import Loader from '../../../../../Components/Loader/Loader';
import { checkError } from '../../../../../Helpers/AuthHelper';

class CreateUpdateContactEmployees extends Component {
    employee =
        this.props.type === 'edit'
            ? {
                  id: this.props.employee.id,
                  salutation: this.props.employee.salutation,
                  firstName: this.props.employee.first_name,
                  lastName: this.props.employee.last_name,
                  email: this.props.employee.email,
                  workPhone: this.props.employee.work_phone,
                  mobile: this.props.employee.mobile,
                  designation: this.props.employee.designation,
                  department: this.props.employee.department,
                  skype: this.props.employee.skype,
              }
            : {
                  id: null,
                  salutation: '',
                  firstName: '',
                  lastName: '',
                  email: '',
                  workPhone: '',
                  mobile: '',
                  designation: '',
                  department: '',
                  skype: '',
              };

    state = {
        employee: {
            ...this.employee,
        },
        type: this.props.type || 'add',
        loading: true,
        requiredInputs: {},
        isSubmitted: false,
    };

    componentDidMount() {
        this.setState({ loading: false });
    }

    submitRequestType() {
        if (this.state.type === 'edit') {
            return 'put';
        } else {
            return 'post';
        }
    }

    submitRequestUrl() {
        if (this.state.type === 'edit') {
            return `contacts/${this.props.contactId}/persons/${this.state.employee.id}`;
        } else {
            return `contacts/${this.props.contactId}/persons`;
        }
    }

    handleSubmit = (values, setSubmitting) => {
        restRequest(this.submitRequestType(), this.submitRequestUrl(), {
            salutation: values.salutation,
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            work_phone: values.workPhone,
            mobile: values.mobile,
            designation: values.designation,
            department: values.department,
            skype: values.skype,
        })
            .then(() => {
                this.props.onSubmit(); // return the added employee data
                this.renderNotification();
            })
            .catch((error) => {
                checkError(error, this.props.handleToast);
                setSubmitting(false);
            });
    };

    renderNotification() {
        if (this.state.type === 'edit') {
            this.props.handleToast('Employee edited successfully', 'success');
        } else {
            this.props.handleToast('Employee added successfully', 'success');
        }
    }

    headerTitle() {
        let title = this.props.type === 'add' ? 'Add' : 'Edit';
        return `${title} Contact Employees`;
    }

    render() {
        if (this.state.loading) return <Loader />;
        return (
            <>
                <div className="header-title">{this.headerTitle()}</div>
                <EmployeesForm
                    employee={this.state.employee}
                    onCancel={this.props.onCancel}
                    handleSubmit={(values, setSubmitting) =>
                        this.handleSubmit(values, setSubmitting)
                    }
                />
            </>
        );
    }
}

export default CreateUpdateContactEmployees;
