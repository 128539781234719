import React, { Component, Fragment } from 'react';

import { restRequest } from '../../../../Helpers/RequestHelper';
import getDate from '../../../../Helpers/Date/GetDate';

import { NewSalesReturnIcon } from '../../../../Assets/Navigation/NavigationIcons'; //PackageIcon,
import Table from '../../../../Components/Table/Table';
import Loader from '../../../../Components/Loader/Loader';
import OverlayLoader from '../../../../Components/Loader/OverlayLoader';
import PDFViewer from '../../../../Components/PDFViewer/PDFViewer';
import CustomModal from '../../../../Components/CustomModal/CustomModal';
import ShipManuallyIcon from '../../../../Assets/General/ShipManuallyIcon';
import { NoItemsBox } from '../../../../Components/Layout/Box';
import SalesReturn from '../../../../Components/Form/SaleReturn/AddSalesReturn';
import ShipmentCreate from '../../Packages/ShipmentCreate';
// import { LinkStatusColumn } from '../../../../Components/ListTable/ListTableColumnJoin'
import EditIcon from '../../../../Assets/General/EditIcon';
import DeleteIcon from '../../../../Assets/General/DeleteIcon';
import MarkAsConfirmedIcon from '../../../../Assets/General/MarkAsConfirmedIcon';
// import MarkAsVoidIcon from '../../../../Assets/General/MarkAsVoidIcon'
import { MdAdd } from '../../../../Common/Icons';

import ReturnReceiveIcon from '../../../../Assets/General/AddRetrunReceive';
import SalesReturnDetails from '../../../SalesReturn/SalesReturnDetails/index';
import { getObjFromLS, setObjInLS } from '../../../../Helpers/LocalStorage';
import ReturnReceive from '../../../../Components/Form/ReturnReceive/ReturnReceive';
import PdfIcon from '../../../../Assets/General/PdfIcon';
import EmailIcon from '../../../../Assets/General/EmailIcon';
import { checkError } from '../../../../Helpers/AuthHelper';
import './Salesordermodal.css';
export default class Packages extends Component {
  state = {
    loading: true,
    saleReturnData: [],
    showPackageCreateModal: false,
    pdf: false,
    showPDF: false,
    showPackageEditModal: false,
    showSRViewModal: false,
    showPackageDeleteModal: false,
    currentPackageID: null,
    salesReturnID: null,
    showManualShippingModal: false,
    showShipmentDeleteModal: false,
    showReturnReceive: false,
    showPrompt: false,
    processing: false,
  };

  tableRowOptions = [
    {
      label: 'Mark As Confirmed',
      onClick: (id) => this.markPackageAsDelivered(id),
      icon: MarkAsConfirmedIcon,
      disabled: (id) =>
        this.props.hasPermissionCustom('salesreturns', 'Edit') === false
          ? true
          : this.getCurrentPackage(id).sales_return_status === 'receiving' ||
            this.getCurrentPackage(id).sales_return_status === 'void' ||
            this.getCurrentPackage(id).sales_return_status === 'confirmed' ||
            this.getCurrentPackage(id).sales_return_status === 'closed',
    },
    {
      label: 'Add Return Receive',
      onClick: (id) => this.createretrunReceive(id),
      icon: ReturnReceiveIcon,
      disabled: (id) =>
        this.getCurrentPackage(id).sales_return_status === 'void' ||
        this.getCurrentPackage(id).sales_return_status === 'draft'
          ? true
          : this.props.hasPermissionCustom('returnreceive', 'Create') === false
          ? true
          : this.getCurrentPackage(id).sales_return_status === 'closed',
    },
    {
      label: 'Edit',
      onClick: (id) => this.handleEditPackage(id),
      icon: EditIcon,
      disabled: (id) =>
        this.props.hasPermissionCustom('salesreturns', 'Edit') === false
          ? true
          : this.getCurrentPackage(id).sales_return_status === 'receiving' ||
            this.getCurrentPackage(id).sales_return_status === 'void' ||
            this.getCurrentPackage(id).sales_return_status === 'confirmed' ||
            this.getCurrentPackage(id).sales_return_status === 'closed',
    },
    {
      label: 'Print & PDF',
      icon: PdfIcon,
      onClick: (id) => this.openPdf(id),
    },
    {
      label: 'Email',
      icon: EmailIcon,
      onClick: (id) => {
        let dataFromLS = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLS,
          queryParam: `${
            dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
          }salesReturn=true`,
        });
        this.props.history.push(`/salesreturns/email/${id}`);
      },
    },
    {
      label: 'Delete',
      onClick: (id) => this.openPackageDeleteModal(id),
      icon: DeleteIcon,
      disabled: (id) =>
        this.props.hasPermissionCustom('salesreturns', 'Delete') === false
          ? true
          : this.getCurrentPackage(id).sales_return_status === 'closed' ||
            this.getCurrentPackage(id).sales_return_status === 'receiving' ||
            this.getCurrentPackage(id).sales_return_status === 'void',
    } /*,
    {
      label: 'Mark As Void',
      onClick: id => this.markPackageAsUndelivered(id),
      icon: MarkAsVoidIcon,
      disabled: id => this.props.hasPermissionCustom('salesreturns','Edit') === false ? true :   (this.getCurrentPackage(id).sales_return_status === 'receiving' || this.getCurrentPackage(id).sales_return_status === 'void' || this.getCurrentPackage(id).sales_return_status === 'draft' || this.getCurrentPackage(id).sales_return_status === 'closed')
    }*/,
  ];

  componentDidMount() {
    this.fetchData();
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  fetchData() {
    restRequest('get', `salesorders/${this.props.salesOrderID}/salesreturn`)
      .then((list) => {
        this.setState({
          saleReturnData: list,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error);
        this.setState({
          loading: false,
        });
      });
  }

  getCurrentPackage = (id) => {
    return this.state.saleReturnData.find((obj) => obj.id === id);
  };

  handleEditPackage(id) {
    this.getCurrentPackage(id);
    this.openPackageEditModal(id);
  }

  renderManualShippingModal() {
    const currentPackage = this.getCurrentPackage(this.state.currentPackageID);
    const slipID = currentPackage ? currentPackage.package_no : null;
    return (
      <CustomModal
        showModal={this.state.showManualShippingModal}
        Icon={ShipManuallyIcon}
        title="Create Shipment"
        renderActions={false}
        onClose={this.closeAllModals}
      >
        <ShipmentCreate
          packageId={this.state.currentPackageID}
          packageNo={slipID}
          onClose={this.closeAllModals}
          onSubmit={this.handlePackageActionSubmit}
          handleToast={this.props.handleToast}
        />
      </CustomModal>
    );
  }

  handleManualShipping = (packageId) => {
    this.setState({
      showManualShippingModal: true,
      currentPackageID: packageId,
    });
  };

  handleDeleteShipment = () => {
    restRequest('delete', `salesreturns/${this.state.currentPackageID}`)
      .then(() => {
        this.handlePackageActionSubmit();
        this.props.handleToast('Sale Return has been deleted', 'success');
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
      });
  };

  renderShipmentDeleteModal() {
    return (
      <CustomModal
        type="delete"
        showModal={this.state.showShipmentDeleteModal}
        title="Delete Shipment"
        onClose={this.closeAllModals}
        onSubmit={this.handleDeleteShipment}
      >
        Are you sure you want to delete this shipment?
      </CustomModal>
    );
  }

  openShipmentDeleteModal = (packageId) => {
    this.setState({
      showShipmentDeleteModal: true,
      currentPackageID: packageId,
    });
  };

  markPackageAsDelivered = (packageId) => {
    this.setState({ processing: true });
    restRequest('put', `salesreturns/${packageId}/confirmed`)
      .then(() => {
        this.setState({ processing: false });
        this.handlePackageActionSubmit();
        this.props.handleToast(
          'Sales Return has been marked as Confirmed',
          'success'
        );
      })
      .catch((error) => {
        this.setState({ processing: false });
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
      });
  };

  markPackageAsUndelivered = (packageId) => {
    restRequest('put', `salesreturns/${packageId}/void`)
      .then(() => {
        this.handlePackageActionSubmit();
        this.props.handleToast(
          'Sales Return has been marked as Void',
          'success'
        );
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
      });
  };

  openPdf = (id) => {
    this.setState({ showPDF: true });
    restRequest('get', `salesreturns/${id}/pdf`)
      .then((res) => {
        this.setState({ pdf: res });
      })
      .catch((error) => checkError(error));
  };

  closeAllModals = () => {
    this.setState({
      showPackageCreateModal: false,
      showPDF: false,
      showPackageEditModal: false,
      showPackageDeleteModal: false,
      showManualShippingModal: false,
      showShipmentDeleteModal: false,
      showSRViewModal: false,
      showReturnReceive: false,
    });
  };

  handleDeletePackage = () => {
    restRequest('delete', `salesreturns/${this.state.currentPackageID}`)
      .then((message) => {
        let dataFromLS = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLS,
          queryParam: `${
            dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
          }salesReturn=true`,
        });
        this.props.handleToast(message.message, 'success');
        this.handlePackageActionSubmit();
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
      });
  };

  renderPackageDeleteModal() {
    return (
      <CustomModal
        type="delete"
        showModal={this.state.showPackageDeleteModal}
        title="Confirm Sales Return Delete"
        onClose={this.closeAllModals}
        onSubmit={this.handleDeletePackage}
      >
        Are you sure you want to delete this Sales Return?
      </CustomModal>
    );
  }

  openPackageDeleteModal(id) {
    this.setState({
      showPackageDeleteModal: true,
      currentPackageID: id,
    });
  }

  renderPackageEditModal() {
    return (
      <CustomModal
        showModal={this.state.showPackageEditModal}
        title={'Edit Sales Return | ' + this.props.saleOrderNo}
        renderActions={false}
        size="medium"
        customWidth="w-109rem"
        onClose={this.closeAllModals}
        showPrompt={this.state.showPrompt}
        className="so_popup_dragDrop salesreturn_popup"
        mainContainerClass="dragDropPopupContainer"
      >
        <SalesReturn
          inModal
          type="edit"
          id={this.state.currentPackageID}
          close={this.closeAllModals}
          onSubmit={this.handlePackageActionSubmit}
          handleToast={this.props.handleToast}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  openPackageEditModal(id) {
    this.setState({
      showPackageEditModal: true,
      currentPackageID: id,
    });
  }

  handlePackageActionSubmit = () => {
    let dataFromLS = getObjFromLS('module');
    setObjInLS('module', {
      ...dataFromLS,
      queryParam: `${
        dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
      }salesReturn=true`,
    });
    this.props.updateSalesOrderStatus();
    // this.fetchData()
    this.closeAllModals();
  };

  handlePrompt = (status = true) => {
    this.setState({ showPrompt: status });
  };

  renderSalesReturnCreateModal() {
    return (
      <CustomModal
        showModal={this.state.showPackageCreateModal}
        Icon={NewSalesReturnIcon}
        title={'Create Sales Return | ' + this.props.saleOrderNo}
        renderActions={false}
        size="medium"
        // customWidth="w-109rem"
        onClose={this.closeAllModals}
        classNames="salesreturn_popup"
        showPrompt={this.state.showPrompt}
        className="so_popup_dragDrop"
        mainContainerClass="dragDropPopupContainer"
      >
        <SalesReturn
          inModal
          type="add"
          salesOrderId={this.props.salesOrderID}
          close={this.closeAllModals}
          onSubmit={this.handlePackageActionSubmit}
          handleToast={this.props.handleToast}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  async createNewPackage() {
    this.setState({ showPackageCreateModal: true });
  }

  openSalesReturnViewModal(id) {
    this.setState({
      showSRViewModal: true,
      salesReturnID: id,
    });
  }

  viewSRModal() {
    return (
      <CustomModal
        showModal={this.state.showSRViewModal}
        title="View Sales Return"
        renderActions={false}
        size="large"
        Icon={NewSalesReturnIcon}
        onClose={this.closeAllModals}
        linkTo={`salesreturns/${this.state.salesReturnID}`}
        classNames="view-sales-return-So"
        className="so-sales-return-popup"
      >
        <SalesReturnDetails id={this.state.salesReturnID} inModal={true} />
      </CustomModal>
    );
  }

  preparePackageList(packages) {
    return packages.map((packageSlip) => {
      return {
        ...packageSlip,
        packageNo: this.props.inModal ? (
          packageSlip.sales_return_no
        ) : (
          <div onClick={() => this.openSalesReturnViewModal(packageSlip.id)}>
            {packageSlip.sales_return_no}
          </div>
        ),
        // packageNo: <LinkStatusColumn to={`/packages/${packageSlip.id}`} label={packageSlip.package_no} status={packageSlip.status} />,
        packageDate: getDate(packageSlip.sales_return_date),
      };
    });
  }

  isPackageCreatable(saleReturns) {
    if (saleReturns.length === 0 && this.props.shipmentsLength > 0) {
      return true;
    }
    if (saleReturns.length > 0) {
      // var quantityRemaining = true
      var returnItemsQuantity = 0;

      saleReturns.forEach((saleReturn) => {
        saleReturn.sales_return_details.forEach((returnDetail) => {
          if (returnDetail.quantity > 0) {
            returnItemsQuantity = returnItemsQuantity + returnDetail.quantity;
          }
        });
      });
      if (saleReturns[0].quantity_remaining === returnItemsQuantity) {
        return false;
      }
    }

    if (
      saleReturns.length &&
      saleReturns[0].quantity_remaining > 0 &&
      this.props.shipmentsLength > 0
    ) {
      return true;
    } else return false;
  }

  renderPackagesListTable() {
    const { label } = this.props;
    const tableListData = this.preparePackageList(this.state.saleReturnData);
    const tableHeaders = [
      'Sales Return',
      'Date',
      'Customer',
      'Add To Stock',
      'Status',
      /*'Return Qty'*/ 'Return Items',
    ];
    const tableData = [
      'packageNo',
      'packageDate',
      'customer_name',
      'back_stock',
      'status',
      /*'return_quantity'*/ 'return_items',
      this.props.inModal ? '' : 'options',
    ];
    return (
      <Fragment>
        <Table
          list={tableListData}
          tableHeaders={tableHeaders}
          tableData={tableData}
          options={this.props.inModal ? [] : this.tableRowOptions}
          className={`box-table ${label ? label + '_menu' : ''}`}
          inModal={this.props.inModal}
          fromTab={true}
        />
        {/* RowIcon={SalesReturnIcon} */}
        {this.isPackageCreatable(this.state.saleReturnData) &&
          (this.props.inModal ? (
            ''
          ) : (
            <button
              className="layout-box--button btn-width"
              onClick={() => this.createNewPackage()}
            >
              <MdAdd className=" black-fill" />
              {/* Add Sales Return */}
              New Sales Return
            </button>
          ))}
      </Fragment>
    );
  }

  createretrunReceive(id) {
    this.setState({ showReturnReceive: true, salesReturnID: id });
  }

  renderReturnReceiveModal() {
    return (
      <CustomModal
        showModal={this.state.showReturnReceive}
        title="Create Return Receive"
        Icon={ReturnReceiveIcon}
        fill="#000000"
        size="large"
        renderActions={false}
        onClose={this.closeAllModals}
        classNames="address-popup-set cus-popup-style"
        showPrompt={this.state.showPrompt}
      >
        <ReturnReceive
          inModal
          type="add"
          salesReturnId={this.state.salesReturnID}
          salesOrderId={this.props.salesOrderID}
          close={this.closeAllModals}
          onSubmit={this.handlePackageActionSubmit}
          handleToast={this.props.handleToast}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  renderModals() {
    return (
      <Fragment>
        <PDFViewer
          showPDF={this.state.showPDF}
          hidePDFModal={this.closeAllModals}
          pdf={this.state.pdf}
        />
        {this.renderReturnReceiveModal()}
        {this.renderManualShippingModal()}
        {this.renderShipmentDeleteModal()}
        {this.renderSalesReturnCreateModal()}
        {this.renderPackageEditModal()}
        {this.renderPackageDeleteModal()}
        {this.viewSRModal()}
      </Fragment>
    );
  }

  renderPackagesList() {
    return (
      <Fragment>
        {this.renderModals()}
        {!this.state.saleReturnData.length ? (
          <NoItemsBox
            subtitle="No Sales Return created yet."
            buttonText="Create Sales Return"
            showButton={this.isPackageCreatable(this.state.saleReturnData)}
            handleclick={() => this.createNewPackage()}
            inModal={this.props.inModal}
            Icon={NewSalesReturnIcon} //add colors to new svg icons
          />
        ) : (
          this.renderPackagesListTable()
        )}
      </Fragment>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return (
      <>
        {this.state.processing && <OverlayLoader />}
        {this.renderPackagesList()}
      </>
    );
  }
}
