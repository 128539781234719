import React from 'react';
//import { useState } from 'react';
import { MdOutlineMoreVert } from '../../../../../Common/Icons';
//import {  MdCheck } from '../../../../../Common/Icons';
import EditIcon from '../../../../../Assets/General/EditIcon';
import DefaultBilling from '../../../../../Assets/General/DefaultBillingIcon';
import DefaultShipping from '../../../../../Assets/General/DefaultShippingIcon';
import DeleteIcon from '../../../../../Assets/General/DeleteIcon';
import Card from '../../../../../Components/Card/Card';
// import { CustomModal } from '../../../../../Components';
import { hasPermissionCustom } from '../../../../../Helpers/CustomPermission';
const ContactAddress = ({
  contactAddresses,
  handleEdit,
  handleDelete,
  handleDefaultAddress,
  inModal,
  // handleUpdate = () => {},
  // handleUpdateModal = () => {},
  // updateoverAll = false,
  // updateModal = false,
}) => {
  // const [adressType, setAdressType] = useState('');
  function getHeader(type) {
    switch (type) {
      case 'billing':
        return 'Billing Address';
      case 'shipping':
        return 'Shipping Address';
      default:
        return 'Additional Address';
    }
  }

  function renderDropdownOptions(address) {
    let options = [
      {
        label: 'Edit',
        icon: EditIcon,
        onClick: () => handleEdit(address),
        disabled: () => !hasPermissionCustom('contact', 'Edit'),
      },
      {
        label: 'Delete',
        icon: DeleteIcon,
        onClick: () => handleDelete(address),
        disabled: () => !hasPermissionCustom('contact', 'Delete'),
      },
    ];
    if (address.address_type === 'additional') {
      options.push(
        {
          label: 'Make Default Billing',
          icon: DefaultBilling,
          onClick: () => handleDefaultAddress(address.id, 'billing'),
          // onClick: () => {
          //   setAdressType('billing');
          //   handleUpdateModal(true);
          // },
          disabled: () => !hasPermissionCustom('contact', 'Create'),
        },
        {
          label: 'Make Default Shipping',
          icon: DefaultShipping,
          onClick: () => handleDefaultAddress(address.id, 'shipping'),
          // onClick: () => {
          //   setAdressType('shipping');
          //   handleUpdateModal(true);
          // },
          disabled: () => !hasPermissionCustom('contact', 'Create'),
        }
      );
    }
    return options;
  }

  function renderCard(contactAddress) {
    // const checkTotalValueForZero = () => {
    //   return (
    //     <CustomModal
    //       style={{ marginTop: '5%' }}
    //       showModal={updateModal}
    //       title="Confirmation"
    //       renderActions={false}
    //       onClose={() => handleUpdateModal(false)}
    //     >
    //       <label className="checkbox--option-label">
    //         <input
    //           onChange={(event) => handleUpdate(event.target.checked)}
    //           checked={updateoverAll}
    //           type="checkbox"
    //           id="Click_checkbox_SameAsBilling"
    //         />
    //         <span>
    //           <MdCheck />
    //         </span>
    //         If you want to save this information for previous records then tick
    //         the checkbox.
    //       </label>
    //       <br />
    //       <div
    //         style={{
    //           display: 'flex',
    //           justifyContent: 'flex-end',
    //         }}
    //       >
    //         <button
    //           type="button"
    //           className="action-buttons--submit next-btn"
    //           onClick={() => {
    //             handleUpdate(updateoverAll);
    //             handleUpdateModal(false);
    //             handleDefaultAddress(contactAddress.id, adressType);
    //           }}
    //         >
    //           Confirm
    //         </button>
    //       </div>
    //     </CustomModal>
    //   );
    // };
    let headerTitle = getHeader(contactAddress.address_type);
    return (
      <>
        {/* {checkTotalValueForZero()} */}
        <Card
          key={contactAddress.id}
          titleWithDropdown
          title={headerTitle}
          headerIcon={<MdOutlineMoreVert />}
          inModal={inModal}
          dropdownOptions={inModal ? [] : renderDropdownOptions(contactAddress)}
        >
          <div className="semi-bold">{contactAddress.attention}</div>
          {contactAddress.phone && <div>(P) {contactAddress.phone}</div>}
          {contactAddress.fax && <div>(F) {contactAddress.fax}</div>}
          {contactAddress.street && <div>{contactAddress.street}</div>}
          <div>
            {contactAddress.city}
            {contactAddress.city && contactAddress.state && ', '}
            {contactAddress.state} {contactAddress.zip_code}
            {(contactAddress.city || contactAddress.state) &&
              contactAddress.country_name &&
              ', '}
            {contactAddress.country_name}
          </div>
        </Card>
      </>
    );
  }

  return contactAddresses.map((contactAddress) => renderCard(contactAddress));
};

export default ContactAddress;
