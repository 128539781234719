import React from 'react';
import { MdOutlineMoreVert } from '../../../../../Common/Icons';
import EditIcon from '../../../../../Assets/General/EditIcon';
import DeleteIcon from '../../../../../Assets/General/DeleteIcon';
import { hasPermissionCustom } from '../../../../../Helpers/CustomPermission';
import Card from '../../../../../Components/Card/Card';

const ContactAddress = ({
  contactDetails,
  handleEdit,
  handleDelete,
  inModal,
}) => {
  function getHeader(type) {
    switch (type) {
      case 'billing':
        return 'Billing Address';
      case 'shipping':
        return 'Shipping Address';
      default:
        return 'Additional Address';
    }
  }

  function renderDropdownOptions(address) {
    let options = [
      {
        label: 'Edit',
        icon: EditIcon,
        onClick: () => handleEdit(address),
        disabled: () => !hasPermissionCustom('contact', 'Edit'),
      },
      {
        label: 'Delete',
        icon: DeleteIcon,
        onClick: () => handleDelete(address),
        disabled: () => !hasPermissionCustom('contact', 'Delete'),
      },
    ];
    return options;
  }

  function renderCard(contactAddress) {
    let headerTitle = getHeader(contactAddress.address_type);
    return (
      <div key={contactAddress.id} className="contact_person_card">
        <Card
          key={contactAddress.id}
          titleWithDropdown
          title={headerTitle}
          headerIcon={<MdOutlineMoreVert />}
          inModal={inModal}
          dropdownOptions={inModal ? [] : renderDropdownOptions(contactAddress)}
        >
          <div className="card_image float-left w-100 text-center">
            {' '}
            <div className="name_initail">
              <span>{contactAddress.first_name.charAt(0)}.</span>
            </div>{' '}
          </div>
          <div className="card_name float-left w-100 text-center">
            {' '}
            {contactAddress.salutation} {contactAddress.first_name}{' '}
            {contactAddress.last_name}
          </div>
          <div className="card_department float-left w-100 text-center">
            {contactAddress.department}
          </div>
          <div className="card_designation float-left w-100 text-center">
            {contactAddress.designation}
          </div>
          <div className="card_email float-left w-100 text-center">
            <a href={!inModal && `mailto:${contactAddress.email}`}>
              {contactAddress.email}
            </a>
          </div>
          {contactAddress.work_phone && (
            <div className="card_phone float-left w-100 text-center">
              <a href="tel:11223366544">Phone: {contactAddress?.work_phone}</a>
            </div>
          )}
          {contactAddress.mobile && (
            <div className="card_cell float-left w-100 text-center">
              <a href="tel:11223366544">Cell: {contactAddress?.mobile}</a>
            </div>
          )}
        </Card>
      </div>
    );
  }

  return contactDetails.map((contactDetails) => renderCard(contactDetails));
};

export default ContactAddress;
