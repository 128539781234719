import React, { Component } from 'react';
import {
  MdWork,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdDashboard,
  MdShoppingCart,
  MdContentPaste,
} from '../../../Common/Icons';
import PageOne from './PageOne/PageOne';
import PageTwo from './PageTwo/PageTwo';
import PageThree from './PageThree/PageThree';
import PageFour from './PageFour/PageFour';
import './Pages.css';

class Content extends Component {
  constructor() {
    super();
    setInterval(() => this.updateUnread(), 7000);
    this.state = {
      active: 1,
    };
    clearInterval(this.updateUnread());
  }

  updateUnread() {
    var arr = [1, 2, 3, 4, 5, 6]; // slide counts
    var index = this.state.active;
    this.setState({
      active: arr[index++] % arr.length,
    });
    if (this.state.active > 4) {
      this.setState({
        active: 1,
      });
    }
  }

  handleActive(active) {
    this.setState({ active });
  }

  renderPage(active) {
    switch (active) {
      case 1:
        return <PageOne />;
      case 2:
        return <PageTwo />;
      case 3:
        return <PageThree />;
      case 4:
        return <PageFour />;
      default:
        break;
    }
  }

  handlePrevious(active) {
    if (active === 1) this.setState({ active: 4 });
    else this.setState({ active: active - 1 });
  }

  handleNext(active) {
    if (active === 4) this.setState({ active: 1 });
    else this.setState({ active: active + 1 });
  }

  render() {
    return (
      <div className="home-pages" id="features">
        <div className="home-pages-nav">
          <button onClick={() => this.handlePrevious(this.state.active)}>
            <MdKeyboardArrowLeft />
          </button>
          <button
            onClick={() => this.handleActive(1)}
            className={this.state.active === 1 && 'home-pages-nav--active'}
          >
            <MdDashboard />
            <div>Dashboard</div>
          </button>
          <button
            onClick={() => this.handleActive(2)}
            className={this.state.active === 2 && 'home-pages-nav--active'}
          >
            <MdWork />
            <div>Inventory Management</div>
          </button>
          <button
            onClick={() => this.handleActive(3)}
            className={this.state.active === 3 && 'home-pages-nav--active'}
          >
            <MdShoppingCart />
            <div>Sales and Purchases</div>
          </button>
          <button
            onClick={() => this.handleActive(4)}
            className={this.state.active === 4 && 'home-pages-nav--active'}
          >
            <MdContentPaste />
            <div>Invoice and Bills</div>
          </button>
          <button onClick={() => this.handleNext(this.state.active)}>
            <MdKeyboardArrowRight />
          </button>
        </div>

        <div className="home-pages-section">
          {this.renderPage(this.state.active)}
        </div>
      </div>
    );
  }
}

export default Content;
