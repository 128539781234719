import React from 'react'

const CaretDown = (
  {
    className = "icon--caret-down"
  }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/*<g id={className}>*/}
        <path id={className} d="M5.41 6.40991L10 10.9899L14.59 6.40991L16 7.81991L10 13.8199L4 7.81991L5.41 6.40991Z" fill="#ADB6BC" />
      {/*</g>*/}
    </svg>
  )
}

export default CaretDown