import { isEqual } from 'lodash';

export const validateInviteUserForm = (data = {}, defaultValues = {}) => {
  const checkEquality = Boolean(isEqual(data, defaultValues));
  let checkout = true;
  const { name = '', email = '', role = '' } = data;

  if ((name || role || email) && !checkEquality) {
    checkout = false;
  }

  return { checkout };
};
