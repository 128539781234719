import React from 'react';
import { Route } from 'react-router-dom';

import AccessDenied from '../Components/AccessDenied/AccessDenied';
import ErrorBoundary from '../Components/ErrorBoundry';

import withContext from '../Context/UserContextHOC';
import { checkUpdatedPermission } from './AuthHelper';

const AuthenticatedRoute = ({
  component: Component,
  handleToast,
  checkPermission = false,
  context,
  ...rest
}) => {
  const role = context.role;
  let permissions;
  if (role) {
    permissions = role.permissions;
  }

  if (window.location.pathname !== '/contacts' && window.location.pathname !== '/r') {
    localStorage.removeItem("selectedChar")
  }
  // for checked false permission if admin update the permission () regardless of socket
  if (
    window.location.pathname !== '/' &&
    window.location.pathname !== '/dashboard' &&
    permissions &&
    !permissions.superAccess &&
    permissions[checkPermission] === false
  ) {
    checkUpdatedPermission(checkPermission, handleToast);
  }
  if (
    !checkPermission ||
    (permissions && permissions[checkPermission]) ||
    (permissions && permissions.superAccess)
  )
    return (
      <ErrorBoundary key={window.location.pathname}>
        <Route
          {...rest}
          render={(props) => <Component {...props} handleToast={handleToast} />}
        />
      </ErrorBoundary>
    );
  return <Route {...rest} render={(props) => <AccessDenied />} />;
};

export default withContext(AuthenticatedRoute);
