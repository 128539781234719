import React, { useEffect } from 'react';
import SalesOrderForm from '../../Components/Form/SalesOrderForm/SalesOrderForm';
import FormLayout from '../../Components/Layout/FormLayout';
import { SalesOrderIcon } from '../../Assets/Navigation/NavigationIcons';

const CloneSalesOrder = (props) => {
  useEffect(() => {
    document.title = 'Clone Sales Orders';
  }, []);

  return (
    <FormLayout
      title=" Clone Sales Orders"
      Icon={SalesOrderIcon}
      closeUrl="/salesorders"
      iconstyle="general_module_icon"
      layoutBoxClass="uiux-form-redesign"
    >
      <SalesOrderForm
        id={props.match.params.id}
        type="clone"
        title="Clone"
        handleToast={props.handleToast}
      />
    </FormLayout>
  );
};

export default CloneSalesOrder;
