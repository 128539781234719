import React, { Fragment, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { withFormik, FieldArray } from 'formik';
import DeleteIcon from '../../../Assets/General/DeleteIcon';
import AddContact from '../../../Pages/Contact/AddContact';
import { Draggable } from 'react-beautiful-dnd';
import { MdHelpOutline } from '../../../Common/Icons';
import {
  required,
  ValidateDate,
  ValidateMax15,
  ValidateMax50,
  ValidateMax255,
  ValidateMax100Number,
  ValidateMax10Billion,
  moreThanZero,
  moreThanMinus,
  ValidateAmountNumber,
  ValidateRequired,
  MAX_NUMBER,
  // MAX_NUMBER_MESSAGE,
  ValidateNumber,
  ValidateEqual0,
  ValidateMax1000,
} from '../../../Helpers/Validator';
import {
  getOverallTaxDetails,
  getFilteredItem,
  handleExtraDescription,
  checkError,
  getItemTotalAmount,
  getOrganizationDate,
  pageIdentify,
  restRequest,
  getObjFromLS,
  setObjInLS,
  confirmationAlert,
  GetDateString,
} from '../../../Helpers';
import {
  ItemForm,
  Field,
  Form,
  FormAction,
  BulkAddItems,
  ErrorFocus,
  AddItem,
  BulkAddConfirmedSalesOrders,
} from '../';
import { CustomModal, Radio, RadioContainer, Loader } from '../../';
import Switch from './../../Switch/Switch';
import { getError, validate, formSubmit } from '../FormHelper';
import { prepareConfirmedSalesOrdersItems } from '../../../Api/Invoice/Utils';
import InvoiceTerm from '../../../Pages/SalesOrder/Invoices/InvoiceTerms/InvoiceTerm';
import InvoiceTermUpdate from '../../../Pages/SalesOrder/Invoices/InvoiceTerms/InvoiceTermUpdate';
import ListTableImageColumn from '../../ListTable/ListTableImageColumn';
import { getDiscountOptions } from '../../../Constants';
import { ranges, customRanges } from '../../CustomDatePicker/StaticRanges';
import moment from 'moment/moment';
import { addDays } from 'date-fns';
import {
  NewItemIcon,
  NewSalesOrderIcon,
} from '../../../Assets/Navigation/NavigationIcons';
import Customer from '../../../Pages/Contact/ContactDetails/index';
import {
  NewContactIcon,
  NewInvoiceIcon,
} from '../../../Assets/Navigation/NavigationIcons';
import { validateInvoiceForm } from '../../../Helpers/FormValidator';
import { MdDragIndicator } from '../../../Common/Icons';
import DragDropItems from '../../../Common/DragDrop';
import { prepareItemsList } from '../../../Api/Invoice/Utils';
import { ToolTip } from '../../../Common';
import TableContent from '../../ListTable/TableContant';

let totalConfirmCheck = false;
let confirmModal = false;
const getYupValidationSchema = (values, owner_can_edit_in_lock_status) => {
  return Yup.object().shape({
    customerId: required(ValidateNumber),
    invoiceNo: required(ValidateMax50),
    invoiceDate: required(ValidateDate),
    orderNo: ValidateMax15,
    note: ValidateMax255,
    items: Yup.array()
      .of(
        Yup.lazy((item) => {
          return Yup.object().shape({
            name: ValidateRequired,
            quantity: required(
              moreThanZero(ValidateMax10Billion).integer('Invalid Value')
            ),
            unitPrice: required(moreThanMinus(ValidateMax10Billion)),
            discount:
              item.discountType === 'percent'
                ? ValidateMax100Number
                : ValidateAmountNumber(
                    item.discount,
                    item.quantity * item.unitPrice
                  ),
          });
        })
      )
      .min(1, 'At least one item is required'),
    adjustment: ValidateMax10Billion,
    shippingCharge: ValidateMax10Billion,
    termsAndConditions: values.hasTermsAndConditions
      ? ValidateMax1000.nullable()
      : ValidateEqual0.nullable(),
    //transactionDiscount: values.transactionDiscountType === 'amount' ? ValidateMax10Billion : ValidateMax100Number
    transactionDiscount:
      values.transactionDiscountType === 'percent'
        ? ValidateMax100Number
        : ValidateMax10Billion,
    edit_reason:
      owner_can_edit_in_lock_status === true
        ? required(ValidateMax255)
        : ValidateMax255,
  });
};
// let totalItems = 0;
let direcInvItems = [];
const formEnhancer = withFormik({
  validate: validate(getYupValidationSchema),
  mapPropsToValues: ({ invoice }) => ({
    ...invoice,

    orderNo: invoice.orderNo ? invoice.orderNo : '',
  }),
  mapPropsToStatus: (props) => ({
    emptyItem: props.emptyItem,
    itemsList: props.itemsList,
    disableDate: props.disableDate,
    confirmedSalesOrdersList: props.confirmedSalesOrdersList,
    showConfirmedSalesOrdersListModal: false,
    showContactCreateModal: false,
    showItemModal: false,
    showTermsModal: false,
    selectedConfirmedSalesOrderIds: [],
    formTitle: props.title,
    salesOrderId: props.salesOrderId || null,
    showAddItemButton: props.showAddItemButton || true,
  }),
  handleSubmit: (values, bag) => {
    handleFormSubmit(values, bag);
  },
});
const handleFormSubmit = (
  values,
  {
    setSubmitting,
    props: {
      id,
      submitRequestType,
      submitRequestUrl,
      successMessage,
      discountPreference,
      inModal,
      close,
      onSubmit,
      history,
      type,
      warehousesList,
      handleToast,
      handleTotalCheckTrue,
      confirmOnEdit,
      handleReasonOnEdit,
      handleTotalCheckFalse,
      setPrompt,
      owner_can_edit_in_lock_status,
    },
  }
) => {
  const total = getOrderTotal(values, values.overAllTotalTaxInValues);
  if (
    owner_can_edit_in_lock_status &&
    !confirmModal &&
    values?.status !== 'draft'
  ) {
    handleReasonOnEdit(true);
    setSubmitting(false);
    return;
  }

  if (parseInt(total) === 0 && !totalConfirmCheck) {
    handleTotalCheckTrue(true);
    setSubmitting(false);
    return;
  }
  if (getOrderTotal(values, values.overAllTotalTaxInValues) >= MAX_NUMBER) {
    // handleToast(`Total must be ${MAX_NUMBER_MESSAGE}`, 'error');
    // setSubmitting(false);
    // return;
  } else if (
    getOrderTotal(values, values.overAllTotalTaxInValues) === 'MAX_AMOUNT'
  ) {
    handleToast(`Discount must be less than Total Ordered Amount`, 'error');
    setSubmitting(false);
    return;
  } else if (
    getOrderTotal(values, values.overAllTotalTaxInValues) === 'MAX_PERCENTAGE'
  ) {
    handleToast(`Discount must be less than 100 %`, 'error');
    setSubmitting(false);
    return;
  }

  let discountLevel = values.discountLevel;

  if (!discountLevel) {
    if (discountPreference.at_individual_item_level) {
      discountLevel = 'item';
    }
    if (discountPreference.at_transaction_level) {
      discountLevel = 'transaction';
    }
  }

  let inActiveWarehouse = values.items.some((item) => {
    return warehousesList.find(
      (list) => list.is_active === 0 && list.id === item.warehouseId
    );
  });
  if (inActiveWarehouse === true) {
    setSubmitting(false);
    return handleToast('Cannot use inactive warehouse', 'error');
  }
  let payload = {
    invoice_no: values.invoiceNo,
    order_no: values.orderNo,
    customer_id: values.customerId,
    invoice_term_name: values.invoiceTerm.name,
    invoice_term_value: values.invoiceTerm.value,
    edit_reason: values?.edit_reason || null,
    invoice_date: values.invoiceDate
      ? values.invoiceDate.format('YYYY-MM-DD 00:00:00')
      : null,
    due_date: values.dueDate
      ? values.dueDate.format('YYYY-MM-DD 23:59:59')
      : null,
    note: values.note,
    items: values.items.map((item, index) => ({
      id: item.id,
      sort_order: index,
      sales_order_item_detail_id: item.salesOrderItemDetailId,
      invoice_item_detail_id: item.invoiceItemDetailId || null,
      warehouse_id: item.warehouseId,
      name: item.name,
      quantity: item.quantity || 0,
      rate: item.unitPrice || 0,
      unit: item.unit,
      tax_id: item?.tax?.id,
      extra_description: item.extra_description,
      discount_item_level: item.discount || 0,
      discount_type: item.discountType,
    })),
    adjustment: values.adjustment || 0,
    shipping_charge: values.shippingCharge || 0,
    discount_transaction_level: values.transactionDiscount || 0,
    discount_type: values.transactionDiscountType,
    //discount_level: discountLevel,
    discount_level: discountLevel === 'no_discount' ? '' : discountLevel,
    sales_person_id: values.salesPersonId,
    terms_and_condition: values.hasTermsAndConditions
      ? values.termsAndConditions
      : '',
  };
  restRequest(submitRequestType(), submitRequestUrl(), payload)
    .then((res) => {
      setPrompt(false);
      successMessage();
      totalConfirmCheck = false;
      confirmModal = false;
      handleTotalCheckFalse(false);
      let dataFromLS = getObjFromLS('module');
      if (dataFromLS === null) {
        let obj = {
          prevId: null,
          id: null,
          nextId: null,
          moduleName: 'invoices',
          urlPath: '/invoices',
          queryParam: window.location.search,
        };
        setObjInLS('module', obj);
        dataFromLS = getObjFromLS('module');
        return history.push('/r');
      }
      if (values.saveAndSend) {
        // let dataFromLS = getObjFromLS('module')
        // setObjInLS('module',{...dataFromLS ,moduleName: 'invoices', action: type ==='add' ? 'add' : 'justedit' , queryParam :"", urlPath: type ==='add' ? '/invoices' :dataFromLS.urlPath})
        if (type === 'add') {
          if (inModal && window.location.pathname.includes('salesorders')) {
            setObjInLS('module', {
              ...dataFromLS,
              moduleName: 'salesorders',
              urlPath: window.location.pathname,
              queryParam: `${
                dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
              }invoices=true`,
            });
          } else {
            setObjInLS('module', {
              ...dataFromLS,
              moduleName: 'invoices',
              id: res.data.id,
              action: type === 'add' ? 'add' : 'justedit',
              queryParam: '',
            });
          }
          sessionStorage.setItem('fullscreen', false);
        } else if (type === 'clone') {
          setObjInLS('module', {
            ...dataFromLS,
            moduleName: 'invoices',
            id: res.data.id,
            action: 'add',
            queryParam: '',
          });
          sessionStorage.setItem('fullscreen', false);
        } else if (type === 'edit') {
          setObjInLS('module', {
            ...dataFromLS,
            action: type === 'add' ? 'add' : 'justedit',
            queryParam: dataFromLS.queryParam ? dataFromLS.queryParam : '',
          });
        }
        history.push(`/invoices/email/${id ? id : res.data.id}`);
      } else if (inModal && !pageIdentify(window.location.pathname)) {
        // let dataFromLS = getObjFromLS('module')
        setObjInLS('module', {
          ...dataFromLS,
          queryParam: `${
            dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
          }invoices=true`,
        });
        return history.push('/r');
      } else if (inModal) {
        // close()
        // onSubmit()
        // let dataFromLS = getObjFromLS('module')
        setObjInLS('module', {
          ...dataFromLS,
          moduleName: 'invoices',
          queryParam: `${
            dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
          }invoices=true`,
        });
        // return history.push('/r');
        return history.go();
      } else {
        if (type === 'add') {
          setObjInLS('module', {
            ...dataFromLS,
            moduleName: 'invoices',
            urlPath: '/invoices',
            id: res.data.id,
            action: type === 'add' ? 'add' : 'justedit',
            queryParam: '',
          });
        } else if (type === 'clone') {
          setObjInLS('module', {
            ...dataFromLS,
            moduleName: 'invoices',
            urlPath: '/invoices',
            id: res.data.id,
            action: 'add',
            queryParam: '',
          });
        } else if (type === 'edit') {
          setObjInLS('module', {
            ...dataFromLS,
            action: type === 'add' ? 'add' : 'justedit',
            id: values.invoiceId,
            urlPath: '/invoices',
            queryParam: dataFromLS.queryParam ? dataFromLS.queryParam : '',
          });
        }
        history.push('/r');
        sessionStorage.setItem('fullscreen', false);
      }
    })
    .catch((error) => {
      confirmModal = false;
      checkError(error);
      handleToast(error, 'error');
      setSubmitting(false);
    });
};

const InvoiceForm = ({
  // props from formik
  values,
  status,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
  setStatus,
  setValues,
  isSubmitting,
  errors,
  touched,
  setFieldTouched,
  // custom props
  title,
  inModal,
  close,
  emptyItem,
  discountPreference,
  itemLevelIndvidual,
  currency,
  fetchTermsList,
  checkDirectInvoice,
  invoiceTermsList,
  taxList,
  contactIdTransaction,
  customersList,
  type,
  invoice,
  invoiceItemsLength,
  warehousesList,
  salesPersonsFromDB,
  is_sales_person,
  sales_person_clearable,
  handleToast,
  history,
  previousPurchasePrices,
  salesOrderId,
  cofirmState,
  confirmOnEdit,
  handleReasonOnEdit,
  handleTotalCheckTrue,
  handleTotalCheckFalse,
  owner_can_edit_in_lock_status,
  ...props
}) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [staticVal, setStaticValues] = useState({});
  const [itemsList, setItemsList] = useState([]);
  const [terms_condition, setTerms_condition] = useState([]);
  const [CurrentCustomer, setCurrentCustomer] = useState({});
  const [showConfirmRemoveItemModal, setShowConfirmRemoveItemModal] =
    useState(false);

  useEffect(() => {
    handleAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, staticVal]);

  useEffect(() => {
    if (props.itemsList.length) {
      setFieldValue('items', props.itemsList);
      setItemsList(props.itemsList);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let value = { ...values };
    delete value.overAllTotalTaxInValues;
    setStaticValues(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      direcInvItems = [];
    };
  }, []);

  useEffect(() => {
    const showButton = values.items.some((item) => item.id === null);
    setShowAddItemButton(!showButton);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.items?.length]);

  const handleAlert = () => {
    let value = { ...values };
    delete value.overAllTotalTaxInValues;
    let { checkout } = validateInvoiceForm(value, {
      ...staticVal,
      items: [emptyItem],
    });
    if (!checkout && !inModal) props.setPrompt();
    else if (!checkout && inModal) props.handlePrompt();
    else {
      if (inModal) props.handlePrompt(false);
      props.setPrompt(false);
    }
  };

  const handleInvoiceTerms = (option) => {
    if (option === null) {
      setStatus({
        ...status,
        disableDate: true,
      });
      setValues({
        ...values,
        invoiceTerm: {
          name: '',
          value: null,
        },
      });
      // let date = moment(getOrganizationDate());
      let date = values.invoiceDate;
      setFieldValue('dueDate', date);
    } else {
      let value = option.value;
      if (value === 'custom') {
        setStatus({
          ...status,
          disableDate: false,
        });
        setValues({
          ...values,
          invoiceTerm: {
            name: option.name,
            value: value,
          },
        });
      } else if (value === 'createNew') {
        setStatus({
          ...status,
          //disableDate: false,
          showTermsModal: true,
        });
        handlePrompt(false);
      } else {
        setStatus({
          ...status,
          disableDate: true,
        });
        setValues({
          ...values,
          invoiceTerm: {
            name: option.name,
            value: value,
          },
        });
      }
    }
  };

  let primaryWarehouse = null;
  warehousesList.forEach(function (warehouse) {
    if (warehouse.is_primary) {
      primaryWarehouse = warehouse.id;
    }
  });

  const handleDate = (option) => {
    let date = values.invoiceDate;
    if (option && option.value) {
      let value = option.value;
      switch (value) {
        case 'custom':
          break;
        case 'this month':
          date = moment(ranges().endOfMonth);
          break;
        case 'next month':
          date = moment(ranges().endOfNextMonth);
          break;
        case 'receipt':
          date = moment(ranges().endOfToday);
          break;
        default:
          date = moment(addDays(getOrganizationDate(), value));
      }
      setFieldValue('dueDate', date);
    } else {
      date = moment(getOrganizationDate());
      setFieldValue('dueDate', date);
    }
  };
  const getPreviousSellingPrice = async (
    contactId,
    item,
    status,
    setStatus
  ) => {
    // return item
    if (!contactId) {
      return item;
    } else {
      let updatedItem = [];
      setStatus({
        ...status,
        loadingItems: true,
      });

      let PPURL = `invoice/previoussoldprice`;
      const payload = {
        customer_id: contactId,
        item_id: item,
        object: 'invoice',
        object_id: type === 'edit' ? values.invoiceId : null,
      };

      await restRequest('post', PPURL, payload).then((previousPrice) => {
        updatedItem = previousPrice;
      });
      return updatedItem;
    }
  };
  const [loadingItem, setLoadingItem] = useState(false);
  const [confirmedSO, updateconfirmedSO] = useState(0);
  const inputChange = async (
    val,
    index,
    status,
    setStatus,
    invoiceType,
    setShowAddItemButton,
    salesOrderId,
    checkDirectInvoice,
    contactId
  ) => {
    var item = getFilteredItem(val, status.itemsList);
    if (item && item.length === 1) {
      handleSelectedItems(
        item[0],
        index,
        status,
        setStatus,
        invoiceType,
        setShowAddItemButton,
        salesOrderId,
        checkDirectInvoice,
        contactId
      );
    }
  };
  const handleSelectedItems = async (
    option,
    index,
    status,
    setStatus,
    invoiceType,
    setShowAddItemButton,
    salesOrderId,
    checkDirectInvoice,
    contactId
  ) => {
    if (option.value === 'createNew') {
      setStatus({ ...status, showItemModal: true });
    } else {
      const prepareoption = await prepareItemsList(
        [option],
        values?.items[index]?.warehouseId
      );
      const response = await getPreviousSellingPrice(
        contactId,
        [option.id],
        status,
        setStatus
      );
      let previousRate = response.find((o) => o.item_id === option.id);
      setLoadingItem(true);
      const newItem = {
        ...option,
        accountId: option?.sales_account_id,
        dragDropID: GetDateString(),
        quantity: values.items[index].quantity,
        warehouseId: primaryWarehouse,
        unitPrice: option?.sales_unit_price || option?.unitPrice,
        total: getItemTotalAmount(prepareoption[0]),
        discount: 0,
        discountType: 'percent',
        previousSellingPrice: previousRate?.rate || null,
      };
      setShowAddItemButton(true);
      //below code run for so invoice and direct edit invoice, adds selected item to dropdown list and removes duplicated,
      if (inModal || (type === 'edit' && invoice.soId)) {
        direcInvItems = direcInvItems.filter(
          (item) =>
            item.salesOrderItemDetailId !== option.salesOrderItemDetailId
        );
        setStatus({
          ...status,
          itemsList: Array.from(
            new Set(direcInvItems.map((a) => a.salesOrderItemDetailId))
          ).map((salesOrderItemDetailId) => {
            return direcInvItems.find(
              (a) => a.salesOrderItemDetailId === salesOrderItemDetailId
            );
          }),
        });
        setLoadingItem(false);
      }
      setFieldValue(`items[${index}]`, { ...newItem });
      if (invoiceType === 'edit' && checkDirectInvoice !== false) {
        return;
      }
      if (option.inventoryType === 'noninventory' || salesOrderId) {
        /*setStatus({
          ...status,
          itemsList: status.itemsList.filter(item =>  item.id !== option.id )
        })*/
      }
      setLoadingItem(false);
    }
  };

  const getTaxAmount = (quantity, unitPrice, taxRate) => {
    return parseFloat(((quantity * unitPrice * taxRate) / 100).toFixed(2));
  };

  const handleItemQuantityChange = (quantityValue, index) => {
    let quantity = quantityValue.floatValue;
    if (quantity > 0) {
      let quantString = quantity.toString();

      // Price_Quantity_Limit

      // price and quantity limit from 5 digits to 10 digits
      if (quantString.length > 10) {
        quantString = quantString.slice(0, 10);
      }
      quantity = parseFloat(quantString);
    }
    let itemsList = [...values.items];
    let currentItem = itemsList[index];
    itemsList[index] = {
      ...currentItem,
      quantity: quantity ? quantity : null,
      taxAmount: !!currentItem?.tax?.id
        ? getTaxAmount(quantity, currentItem.unitPrice, currentItem.tax.rate)
        : 0,
      total: parseFloat(
        getItemTotalAmount({ ...currentItem, quantity })
      ).toFixed(2),
    };
    setFieldValue('items', itemsList);
  };

  const handleTaxChange = (option, index) => {
    let itemsList = [...values.items];
    let currentItem = itemsList[index];
    if (option === null) {
      itemsList[index] = {
        ...currentItem,
        tax: {
          id: null,
          name: '',
          rate: 0,
        },
        taxAmount: 0,
      };
    } else {
      itemsList[index] = {
        ...currentItem,
        tax: {
          id: option.id,
          name: option.name,
          rate: option.rate,
        },
        taxAmount: getTaxAmount(
          currentItem.quantity,
          currentItem.unitPrice,
          option.rate
        ),
      };
    }
    setFieldValue('items', itemsList);
  };

  const handleChangeprevPrice = (prevPrice, index) => {
    let itemList = [...values.items];
    let currentItem = itemList[index];
    itemList[index] = {
      ...currentItem,
      unitPrice: prevPrice,
    };
    setFieldValue('items', itemList);
  };

  const handleItemPriceChange = (unitPriceValue, index) => {
    let unitPrice = unitPriceValue.floatValue;

    if (unitPrice > 0) {
      let unitPriceString = unitPrice.toString();

      // Price_Quantity_Limit

      // price and quantity limit from 5 digits to 10 digits
      if (unitPriceString.length > 10) {
        unitPriceString = unitPriceString.slice(0, 10);
      }
      unitPrice = parseFloat(unitPriceString);
    }
    let itemsList = [...values.items];
    let currentItem = itemsList[index];
    itemsList[index] = {
      ...currentItem,
      unitPrice,
      taxAmount: !!currentItem?.tax?.id
        ? getTaxAmount(currentItem.quantity, unitPrice, currentItem.tax.rate)
        : 0,
      total: parseFloat(
        getItemTotalAmount({ ...currentItem, unitPrice })
      ).toFixed(2),
    };

    setFieldValue('items', itemsList);
  };

  const handleAdjustmentChange = (adjustmentValue) => {
    let adjustment = adjustmentValue.floatValue;
    if (adjustment > 0) {
      let adjustmentString = adjustment.toString();

      // Price_Quantity_Limit

      // price and quantity limit from 5 digits to 10 digits
      if (adjustmentString.length > 10) {
        adjustmentString = adjustmentString.slice(0, 10);
      }
      adjustment = parseFloat(adjustmentString);
    }
    setFieldValue('adjustment', adjustment);
  };

  const handleShipChargeChange = (shippingValue) => {
    let shipping = shippingValue.floatValue;
    if (shipping > 0) {
      let shippingString = shipping.toString();

      // Price_Quantity_Limit

      // price and quantity limit from 5 digits to 10 digits
      if (shippingString.length > 10) {
        shippingString = shippingString.slice(0, 10);
      }
      shipping = parseFloat(shippingString);
    }
    setFieldValue('shippingCharge', shipping);
  };

  const handleDiscountTypeChange = (option, index) => {
    let discountType = option.value;
    let itemsList = [...values.items];
    itemsList[index] = {
      ...itemsList[index],
      discountType,
      total: parseFloat(
        getItemTotalAmount({ ...itemsList[index], discountType })
      ).toFixed(2),
    };
    setFieldValue('items', itemsList);
  };

  const handleItemDiscountChangeT = (value, discountType) => {
    let discount = value.floatValue;
    if (discount > 0) {
      let discountString = discount.toString();
      if (discountType === 'percent') {
        let sliceStrLen = discountString.includes('.') ? 5 : 3;
        if (discountString.length > sliceStrLen) {
          discountString = discountString.slice(0, sliceStrLen);
        }
        discount = parseFloat(discountString);
      } else {
        if (discountString.length > 10) {
          discountString = discountString.slice(0, 10);
        }
        discount = parseFloat(discountString);
      }
    }
    setFieldValue('transactionDiscount', discount);
  };

  const handleItemDiscountChange = (value, index, discountType) => {
    let discount = value.floatValue;
    if (discount > 0) {
      let discountString = discount.toString();
      if (discountType === 'percent') {
        let sliceStrLen = discountString.includes('.') ? 5 : 3;
        if (discountString.length > sliceStrLen) {
          discountString = discountString.slice(0, sliceStrLen);
        }
        discount = parseFloat(discountString);
      } else {
        if (discountString.length > 10) {
          discountString = discountString.slice(0, 10);
        }
      }
    }
    let itemsList = [...values.items];
    itemsList[index] = {
      ...itemsList[index],
      discount,
      total: parseFloat(
        getItemTotalAmount({ ...itemsList[index], discount })
      ).toFixed(2),
    };
    setFieldValue('items', itemsList);
    setFieldValue('items', itemsList);
  };

  const deleteSalesOrderFromList = (option = {}) => {
    let { currentSalesOrderId = null, id = '' } = option;
    if (currentSalesOrderId) {
      let modifiledSalesOrderList = status.confirmedSalesOrdersList;
      modifiledSalesOrderList = modifiledSalesOrderList.map(
        (singleSalesOrder) => {
          if (singleSalesOrder.id === currentSalesOrderId) {
            let itemsListModified =
              singleSalesOrder.sales_order_item_details.map((singleItem) => {
                if (id === singleItem.item_id) {
                  return {
                    ...singleItem,
                    hasItemAdded: false,
                  };
                } else {
                  return singleItem;
                }
              });
            return {
              ...singleSalesOrder,
              selectedByItem: false,
              sales_order_item_details: itemsListModified,
            };
          } else {
            return singleSalesOrder;
          }
        }
      );

      setStatus({
        ...status,
        confirmedSalesOrdersList: modifiledSalesOrderList,
        selectedConfirmedSalesOrderIds: [],
        selectedConfirmedSalesOrderIds:
          status.selectedConfirmedSalesOrderIds.filter(
            (id) => id !== currentSalesOrderId
          ),
      });
    }
  };

  const handleRemoveItem = (
    removeIndex,
    status,
    setStatus,
    option,
    invoiceType,
    salesOrderId
  ) => {
    deleteSalesOrderFromList(option);
    !inModal && values.items.length === 1 && values.items.push(emptyItem);
    const updatedItems = values.items.filter(
      (_, index) => index !== removeIndex
    );

    const showButton = updatedItems.some((item) => item.id === null);
    setShowAddItemButton(!showButton);
    setFieldValue('items', updatedItems);
    //below code run for so invoice and direct edit invoice, removing duplicates and adding removed item into dropdown list,
    if (inModal || (type === 'edit' && invoice.soId)) {
      option.id !== null && direcInvItems.push(option);
      setStatus({
        ...status,
        itemsList: [
          ...Array.from(
            new Set(direcInvItems.map((a) => a.salesOrderItemDetailId))
          ).map((salesOrderItemDetailId) => {
            return direcInvItems.find(
              (a) => a.salesOrderItemDetailId === salesOrderItemDetailId
            );
          }),
        ],
      });
    }
    if (invoiceType === 'edit') {
      if (status.itemsList.find((item) => item.sku === option.sku)) {
        return;
      } else {
        /*setStatus({
          ...status,
          itemsList: [...status.itemsList, option]
        });*/
        return;
      }
    }
    if (
      (option.id != null && option.inventoryType === 'noninventory') ||
      (option.id !== null && salesOrderId)
    ) {
      if (status.itemsList.find((item) => item.sku === option.sku)) {
        return;
      } else {
        /*setStatus({
          ...status,
          itemsList: [...status.itemsList, option]
        });*/
        return;
      }
    }
  };
  // let location = useLocation();
  // const [myWareId, setMyWareId] = useState({});
  const [flag, setflag] = useState(false);
  const [, setWarehouseList] = useState({});
  const [, setShowMergeModal] = useState(false);
  const [, setWarehouseNames] = useState([]);
  const [showAddItemButton, setShowAddItemButton] = useState(
    type === 'edit' && checkDirectInvoice === false ? true : false
  );

  const [sales_invoice_loading, setSales_invoice_loading] = useState(false);

  React.useEffect(() => {
    contactIdTransaction &&
      setFieldValue('customerId', parseInt(contactIdTransaction));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setWarehouseList(warehousesList);
    setflag(true);
    let tempArray = [];
    values.items.forEach((item) => {
      tempArray.push(item.name);
    });
    setWarehouseNames(tempArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.items]);
  useEffect(() => {
    setFieldValue('overAllTotalTaxInValues', overAllTotalTax);
    return () => {
      setShowMergeModal(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (salesOrderId && !values.items.length) {
      setShowAddItemButton(false);
    } else if (invoiceItemsLength === values?.items?.length) {
      setShowAddItemButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.items]);

  useEffect(() => {
    if (values.items.length === 0) {
      setShowAddItemButton(false);
    }
  }, [values.items]);

  // useEffect(() => {
  //   let editableItems = [...values.items];
  //   editableItems.forEach((item, index) => {
  //     editableItems[index] = {
  //       ...item,
  //       previousSellingPrice: previousPurchasePrices[index]
  //         ? previousPurchasePrices[index]
  //         : 0,
  //     };
  //   });
  //   setFieldValue('items', editableItems);
  //   setItemsList(editableItems);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [previousPurchasePrices]);

  const renderSelectedItemsTable = (invoiceType) => {
    if (values.items.length > 0) {
      values.items.forEach(function (value) {
        if (value.warehouseId === null) {
          value.warehouseId = primaryWarehouse;
        }
      });
    }

    const handleOnDragEnd = (result) => {
      if (!result.destination) return;
      const items = Array.from(values.items);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setFieldValue('items', items);
    };

    const handleArrowsKey = (e, index, fieldname) => {
      if (e.key === 'ArrowDown' && index + 1 < values?.items?.length) {
        const element = document.getElementById(
          `items[${index + 1}].${fieldname}`
        );
        setTimeout(() => {
          element.select();
          element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
        }, 100);
      }
    };

    const handleArrowUpKey = (e, index, fieldname) => {
      if (e.key === 'ArrowUp' && index - 1 >= 0) {
        const element = document.getElementById(
          `items[${index - 1}].${fieldname}`
        );
        element.select();
        element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
      }
    };

    const { items, discountLevel } = values;
    return (
      <div className="so-tbletabs">
        <table
          className={`list-table form-focus form-table border ${
            !inModal
              ? 'create_invoice w-100 uiux_invoice_add'
              : 'create_invoice'
          } uiux_invoice_edit_form
      ${
        discountLevel === 'transaction' || discountLevel === 'no_discount'
          ? 'horizontal-items-table no-discount-table'
          : 'discount-added'
      }
       ${type === 'edit' && itemLevelIndvidual ? 'discount-added' : ''}`}
        >
          <thead>
            <tr className="table--row">
              <th>Items</th>
              <th>Warehouse</th>
              <th className="">Qty.</th>
              <th className="">Price</th>
              {(discountLevel === 'item' ||
                discountPreference.at_individual_item_level) &&
                discountLevel !== 'transaction' && (
                  <th className="uiux_so_discount">Discount</th>
                )}
              <th>Tax</th>

              <th className="">Amount</th>
            </tr>
          </thead>
          {flag ? (
            <DragDropItems handleOnDragEnd={handleOnDragEnd}>
              {items.map((item, index) => {
                let quan;
                return (
                  <Draggable
                    key={item.dragDropID}
                    draggableId={item.dragDropID}
                    index={index}
                  >
                    {(provided) => (
                      <tr
                        key={index}
                        className="table--row"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <td>
                          <div className="__dragDrop">
                            <div
                              {...provided.dragHandleProps}
                              className={
                                values.items.length >= 2
                                  ? '__dragDrop_iconParent'
                                  : '__dragDrop_iconParent notDisplay'
                              }
                              tabIndex="-1"
                            >
                              <MdDragIndicator />
                            </div>
                            <div className="__drag-righ">
                              {item.id || item.value ? (
                                <div className="invoice-form">
                                  <ListTableImageColumn
                                    name={
                                      item.name ||
                                      item.label.props.children[1].props
                                        .children[0]
                                    }
                                    images={item.images}
                                    sku={item.sku}
                                    id={item.id}
                                    showModalUponClick={inModal ? false : true}
                                    value={item.extra_description}
                                    index={index}
                                    showExtraDescription={true}
                                    onChangeValue={onChangeValueHandler}
                                  />
                                </div>
                              ) : (
                                <>
                                  {' '}
                                  {!inModal && !checkDirectInvoice ? (
                                    <Field
                                      id="itemId"
                                      autoFocus={true}
                                      addButton={!checkDirectInvoice}
                                      type="DynamicPaginationDropdown"
                                      value={item.id}
                                      disabled={!values.customerId}
                                      withImage={true}
                                      api="invoices/items"
                                      placeholder={
                                        values.customerId
                                          ? 'Select Item'
                                          : 'Please Select Customer'
                                      }
                                      alphabetSort
                                      searchKeys={[
                                        'name',
                                        'sku',
                                        'upc',
                                        'mpn',
                                        'ean',
                                        'isbn',
                                      ]}
                                      clearable={false}
                                      itemsDropDown={true}
                                      handleChange={(option) => {
                                        handleSelectedItems(
                                          option,
                                          index,
                                          status,
                                          setStatus,
                                          invoiceType,
                                          setShowAddItemButton,
                                          salesOrderId,
                                          checkDirectInvoice,
                                          values.customerId
                                        );
                                      }}
                                      error={getError(
                                        errors,
                                        touched,
                                        `items[${index}].name`
                                      )}
                                      onBlur={() =>
                                        setFieldTouched(
                                          `items[${index}].name`,
                                          true
                                        )
                                      }
                                    />
                                  ) : (
                                    <Field
                                      id="itemId"
                                      autoFocus={true}
                                      addButton={!checkDirectInvoice}
                                      type="PaginationDropdown"
                                      value={item.id}
                                      options={status.itemsList}
                                      dropdownValue="id"
                                      dropdownLabel="name"
                                      disabled={!values.customerId}
                                      withImage={true}
                                      placeholder={
                                        values.customerId
                                          ? 'Select Item'
                                          : 'Please Select Customer'
                                      }
                                      alphabetSort
                                      searchKeys={[
                                        'name',
                                        'sku',
                                        'upc',
                                        'mpn',
                                        'ean',
                                        'isbn',
                                      ]}
                                      clearable={false}
                                      itemsDropDown={true}
                                      onInputChange={(option) => {
                                        inputChange(
                                          option,
                                          index,
                                          status,
                                          setStatus,
                                          invoiceType,
                                          setShowAddItemButton,
                                          salesOrderId,
                                          checkDirectInvoice,
                                          values.customerId
                                        );
                                      }}
                                      handleChange={(option) =>
                                        handleSelectedItems(
                                          option,
                                          index,
                                          status,
                                          setStatus,
                                          invoiceType,
                                          setShowAddItemButton,
                                          salesOrderId,
                                          checkDirectInvoice,
                                          values.customerId
                                        )
                                      }
                                      error={getError(
                                        errors,
                                        touched,
                                        `items[${index}].name`
                                      )}
                                      onBlur={() =>
                                        setFieldTouched(
                                          `items[${index}].name`,
                                          true
                                        )
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="no-padding">
                          <Field
                            id="warehouseId"
                            type="dropdown"
                            disabled={
                              owner_can_edit_in_lock_status ||
                              item.salesOrderItemDetailId ||
                              item.inventoryType === 'noninventory' ||
                              !values.customerId
                            }
                            placeholder="Select "
                            value={
                              item.warehouseId
                                ? item.warehouseId
                                : primaryWarehouse
                            }
                            options={warehousesList}
                            dropdownValue="id"
                            dropdownLabel="name"
                            clearable={false}
                            required
                            handleChange={(option) =>
                              setFieldValue(
                                `items[${index}].warehouseId`,
                                option ? option.value : null
                              )
                            }
                          />
                        </td>

                        <td className="right-align no-padding">
                          <Field
                            size="small"
                            type="number"
                            value={item.quantity}
                            decimalScale={6}
                            id={`items[${index}].quantity`}
                            allowNegative={false}
                            thousandSeparator=","
                            onValueChange={(value) =>
                              handleItemQuantityChange(value, index)
                            }
                            error={getError(
                              errors,
                              touched,
                              `items[${index}].quantity`
                            )}
                            onBlur={handleBlur}
                            onKeyDown={(e) =>
                              handleArrowsKey(e, index, 'quantity')
                            }
                            onKeyUp={(e) =>
                              handleArrowUpKey(e, index, 'quantity')
                            }
                            disabled={owner_can_edit_in_lock_status}
                          />
                          <div className="table-extra-data right-align order-invoice">
                            <div style={{ marginTop: '1.3rem' }}>
                              {item.ordered ? (
                                <>
                                  <p className="mb-0">
                                    {' '}
                                    Ordered: {item.ordered} {item.unit}
                                  </p>
                                  <p className="mb-0">
                                    {' '}
                                    Invoiced: {item.invoiced} {item.unit}
                                  </p>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                            {type === 'add' && !inModal && (
                              <Fragment>
                                {item.stocks &&
                                  item.stocks.forEach((stock) => {
                                    if (
                                      stock.warehouse_id === item.warehouseId
                                    ) {
                                      quan = stock.physical_quantity;
                                    }
                                  })}
                                {item.inventoryType === 'noninventory'
                                  ? 'Untracked'
                                  : item.inventoryType === 'inventory' && (
                                      <>
                                        {quan ? quan : '0'} {item.unit}
                                      </>
                                    )}
                              </Fragment>
                            )}
                          </div>
                        </td>
                        <td className="right-align no-padding previous_sold">
                          <Field
                            size="small"
                            type="number"
                            disabled={false}
                            value={item.unitPrice}
                            decimalScale={6}
                            id={`items[${index}].unitPrice`}
                            prefix={currency.symbol}
                            placeholder="$0"
                            allowNegative={false}
                            thousandSeparator=","
                            onValueChange={(values) =>
                              handleItemPriceChange(values, index)
                            }
                            error={getError(
                              errors,
                              touched,
                              `items[${index}].unitPrice`
                            )}
                            onBlur={handleBlur}
                            onKeyDown={(e) =>
                              handleArrowsKey(e, index, 'unitPrice')
                            }
                            onKeyUp={(e) =>
                              handleArrowUpKey(e, index, 'unitPrice')
                            }
                          />
                          {!!item.previousSellingPrice && (
                            <>
                              <span className="icon_div invoice-tooltip-main">
                                <div
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    handleChangeprevPrice(
                                      item?.previousSellingPrice,
                                      index
                                    )
                                  }
                                >
                                  {`Prev Sold: ${currency.symbol}${item.previousSellingPrice}`}
                                </div>
                                <div className="table-extra-data right-align">
                                  <div className="tooltip_tooltip invoice-tooltip">
                                    <p>Click to set this price</p>
                                    {/* {`Prev Sold: ${currency.symbol}${item.previousSellingPrice}`} */}
                                  </div>
                                </div>
                              </span>
                              {/* <ToolTip content="Click to set this price">
                                <div className="table-extra-data right-align">
                                  <div
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      handleChangeprevPrice(
                                        item?.previousSellingPrice,
                                        index
                                      )
                                    }
                                  >
                                    {`Prev Sold: ${currency.symbol}${item.previousSellingPrice}`}
                                  </div>
                                </div>
                              </ToolTip> */}
                            </>
                          )}
                        </td>
                        {(discountLevel === 'item' ||
                          discountPreference.at_individual_item_level) &&
                          discountLevel !== 'transaction' && (
                            <td className="right-align item-level-discount no-padding discount_switch">
                              <Field
                                size="xs"
                                type="number"
                                //value={item.discount}
                                value={parseFloat(item.discount)?.toFixed(2)}
                                decimalScale={2}
                                id={`items[${index}].discount`}
                                rightData={
                                  <Switch
                                    options={getDiscountOptions(currency)}
                                    value={item.discountType}
                                    handleChange={(option) =>
                                      handleDiscountTypeChange(option, index)
                                    }
                                  />
                                }
                                allowNegative={false}
                                thousandSeparator=","
                                onValueChange={(values) =>
                                  handleItemDiscountChange(
                                    values,
                                    index,
                                    item.discountType
                                  )
                                }
                                error={getError(
                                  errors,
                                  touched,
                                  `items[${index}].discount`
                                )}
                                onBlur={handleBlur}
                                onKeyDown={(e) =>
                                  handleArrowsKey(e, index, 'discount')
                                }
                                onKeyUp={(e) =>
                                  handleArrowUpKey(e, index, 'discount')
                                }
                                removeValueOnClick
                              />
                            </td>
                          )}
                        <td className="no-padding">
                          <Field
                            size="small"
                            type="dropdown"
                            placeholder="Select Tax"
                            value={item?.tax?.id}
                            options={taxList}
                            dropdownValue="id"
                            dropdownLabel="name"
                            handleChange={(option) =>
                              handleTaxChange(option, index)
                            }
                          />
                        </td>
                        <td className="list-table--amount right-align">
                          <span>
                            {currency.symbol}
                            {parseFloat(item.total).toFixed(2)}
                          </span>
                          <button
                            disabled={
                              inModal
                                ? items.length === 1
                                : (values.items.length === 1 &&
                                    values.items[0].id === null) ||
                                  owner_can_edit_in_lock_status
                            }
                            type="button"
                            className="close-btn button"
                            onClick={() =>
                              handleRemoveItem(
                                index,
                                status,
                                setStatus,
                                item,
                                invoiceType,
                                salesOrderId
                              )
                            }
                          >
                            <DeleteIcon className="bin" />
                          </button>
                        </td>
                      </tr>
                    )}
                  </Draggable>
                );
              })}
            </DragDropItems>
          ) : (
            <tbody>
              <tr>
                <td>Loading...</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    );
  };

  let getSubtotal = (itemsList) => {
    return itemsList
      .reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0.0)
      .toFixed(2);
  };

  const renderTotalTax = () => {
    let overallTax = getOverallTaxDetails(values.items);
    return overallTax.map((tax) => (
      <div className="float-left w-100" key={tax.id}>
        <span className="semi-bold float-left">{`${tax.name} (${tax.rate}%)`}</span>
        <span className="float-right">{`${currency.symbol}${parseFloat(
          tax.total
        ).toFixed(2)}`}</span>
      </div>
    ));
  };
  const onChangeValueHandler = (val, index) => {
    let newVal = handleExtraDescription(val, 254);
    values.items[index].extra_description = newVal;
    setFieldValue(`values.items[${index}].extra_description`, newVal);
  };
  const overAllTotalTax = () => {
    let overallTax = getOverallTaxDetails(values.items);
    let TotalTax = 0;
    overallTax.forEach((tax) => {
      TotalTax = TotalTax + tax.total;
    });
    return TotalTax;
  };

  const renderOrderSummary = () => {
    return (
      <div className="float-left w-100 mt_3 summary_table">
        <hr className="margin float-left w-100" />
        <div className="float-left w-60 p_10"></div>
        <div className="float-left w-40 p_10 pr_2">
          <div className="float-left w-100">
            <span className="float-left">Sub Total </span>
            <span className="float-right bold">{`${
              currency.symbol
            }${getSubtotal(values.items)}`}</span>
          </div>

          {renderTotalTax()}

          {(values.discountLevel === 'transaction' ||
            discountPreference.at_transaction_level) &&
            values.discountLevel !== 'item' && (
              <div className="float-left w-100">
                <span className="float-left pr">Discount</span>
                <Field
                  className="float-right bold discount_switch_summary_table"
                  // size='medium'
                  type="number"
                  // value={values.transactionDiscount}
                  value={parseFloat(values.transactionDiscount).toFixed(2)}
                  decimalScale={2}
                  size="xs"
                  id="transactionDiscount"
                  rightData={
                    <Switch
                      options={getDiscountOptions(currency)}
                      value={values.transactionDiscountType}
                      handleChange={(value) => {
                        setFieldValue('transactionDiscountType', value.value);
                      }}
                    />
                  }
                  allowNegative={false}
                  thousandSeparator=","
                  onValueChange={
                    (value) =>
                      handleItemDiscountChangeT(
                        value,
                        values.transactionDiscountType
                      )
                    // setFieldValue('transactionDiscount', value.floatValue)
                  }
                  onBlur={handleBlur}
                  error={
                    touched.transactionDiscount && errors.transactionDiscount
                  }
                  removeValueOnClick
                />
              </div>
            )}

          <div className="float-left w-100">
            <span className="float-left pr">Adjustment</span>
            <Field
              className="float-right adj-shipping-invoice-field bold"
              type="number"
              value={parseFloat(values.adjustment).toFixed(2)}
              decimalScale={2}
              allowNegative
              id="adjustment"
              placeholder={`${currency.symbol}0.00`}
              prefix={currency.symbol}
              thousandSeparator=","
              //onValueChange={value => setFieldValue('adjustment', value.floatValue)}
              onValueChange={(value) => handleAdjustmentChange(value)}
              onBlur={handleBlur}
              error={touched.adjustment && errors.adjustment}
              removeValueOnClick
            />
          </div>

          <div className="float-left w-100">
            <span className="float-left pr">Shipping & Handling</span>
            <Field
              className="float-right adj-shipping-invoice-field bold"
              type="number"
              value={parseFloat(values.shippingCharge).toFixed(2)}
              decimalScale={2}
              id="shippingCharge"
              placeholder={`${currency.symbol}0.00`}
              prefix={currency.symbol}
              thousandSeparator=","
              //onValueChange={value => setFieldValue('shippingCharge', value.floatValue)}
              onValueChange={(value) => handleShipChargeChange(value)}
              onBlur={handleBlur}
              error={touched.shippingCharge && errors.shippingCharge}
              removeValueOnClick
            />
          </div>
        </div>

        <hr className="margin float-left w-100" />
        <div className="float-left w-100">
          <div className="float-left w-60 p_10"></div>
          <div className="baseline float-left w-40 px_10">
            <span className="semi-bold float-left pr_total">Total</span>
            <span className="total-price--value float-right mr-1">{`${
              currency.symbol
            }${
              !isNaN(getOrderTotal(values, overAllTotalTax))
                ? getOrderTotal(values, overAllTotalTax)
                : 0.0
            }`}</span>
          </div>
        </div>
        <hr className="margin float-left w-100" />
        <hr className="margin float-left w-100" />
      </div>
    );
  };

  const renderActionButtons = () => {
    return (
      <FormAction
        disabled={isSubmitting}
        type="button"
        className="UIUX_invoice_actions"
        onSubmit={handleSubmit}
        marginFromRight={0}
        showSaveAndSendButton={true}
        saveAndSendField={{
          label: 'Save & Send',
          onClick: (event) => {
            props.setPrompt(false);
            handleSaveAndSend(event);
            handleSubmit();
          },
        }}
        onCancel={() => {
          let dataFromLS = getObjFromLS('module');
          let redirectUrl = '';
          if (dataFromLS && dataFromLS.moduleName === 'invoices') {
            redirectUrl = dataFromLS.queryParam;
          }
          if (dataFromLS.moduleName === 'contacts') {
            history.push('/contacts');
            return;
          }
          if (inModal) {
            let response;
            let value = { ...values };
            delete value.overAllTotalTaxInValues;
            let { checkout } = validateInvoiceForm(value, {
              ...staticVal,
              items: itemsList,
            });
            if (!checkout) {
              response = confirmationAlert();
            }
            if (response || checkout) {
              close();
            }
          } else if (
            window.location.pathname.includes('add') ||
            window.location.pathname.includes('edit')
          ) {
            history.push('/invoices' + redirectUrl);
          } else {
            setObjInLS('module', { ...dataFromLS, action: null });
            history.push('/r');
          }
        }}
      />
    );
  };
  const handleSaveAndSend = (event) => {
    setFieldValue('saveAndSend', true);
    mergeItems(event);
    // formSubmit(event, values.items, setFieldValue, handleSubmit)
  };
  const invoiceTermModal = () => {
    return (
      <CustomModal
        showModal={status.showTermsModal}
        title="Invoice Terms"
        renderActions={false}
        onClose={() => setStatus({ ...status, showTermsModal: false })}
        showPrompt={showPrompt}
      >
        <InvoiceTermUpdate
          type="modal"
          close={() => {
            setStatus({ ...status, showTermsModal: false });
          }}
          updateTermsList={() => {
            fetchTermsList(values.salesOrderId);
            setStatus({ ...status, showTermsModal: false });
          }}
          invoiceTermsList={invoiceTermsList}
          handleToast={handleToast}
          handlePrompt={handlePrompt}
          showPrompt={showPrompt}
        />
      </CustomModal>
    );
  };

  function closeModal() {
    setStatus({
      ...status,
      showBulkAddItemsModal: false,
      showContactCreateModal: false,
      showItemModal: false,
    });
  }

  const newItemModal = () => {
    return (
      <CustomModal
        size="large"
        customWidth="item-modal-width"
        renderActions={false}
        showModal={status.showItemModal}
        title="Add New Item"
        onClose={closeModal}
        showPrompt={showPrompt}
      >
        <ItemForm
          inModal
          type="add"
          onSubmitModal={(newItem) => {
            let ItemsIndex = values.items.length - 1;
            handleCreatedItem(newItem.data, ItemsIndex);
            setStatus({
              ...status,
              showItemModal: false,
            });
          }}
          modalSource={false}
          closeModal={closeModal}
          handleToast={handleToast}
          handlePrompt={handlePrompt}
        />
      </CustomModal>
    );
  };

  const handleCreatedItem = async (item, index) => {
    setLoadingItem(true);
    var newAddedItem = {
      id: item.id,
      salesOrderItemDetailId: null,
      dragDropID: GetDateString(),
      warehouseId: primaryWarehouse,
      name: item.name,
      images: item.images,
      sku: item.sku,
      inventoryType: item.inventory_type,
      unit: item.unit,
      ordered: 0,
      invoiced: 0,
      quantity: 1,
      unitPrice: item.sales_unit_price,
      discount: 0,
      discountType: 'percent',
      upc: item.upc ? item.upc : null,
      mpn: item.mpn ? item.mpn : null,
      ean: item.ean ? item.ean : null,
      isbn: item.isbn ? item.isbn : null,
      tax: {
        id: item.tax ? item?.tax?.id : null,
        rate: item.tax ? item?.tax?.rate : 0,
        name: item.tax ? item?.tax?.name : '',
      },
      taxAmount: 0,
      stocks: item.stocks,
    };
    status.itemsList.push(newAddedItem);
    const newItem = {
      ...newAddedItem,
      quantity: values.items[index].quantity,
      warehouseId: primaryWarehouse,
      previousSellingPrice: 0,
      total: getItemTotalAmount(newAddedItem),
    };
    setShowAddItemButton(true);
    //let updatedNewItem = await getPreviousSellingPrice(values.contactId, newItem, status, setStatus)
    //below code run for so invoice and direct edit invoice, adds selected item to dropdown list and removes duplicated,
    if (inModal || (type === 'edit' && invoice.soId)) {
      direcInvItems = direcInvItems.filter((item1) => item1.id !== item.id);
      setStatus({
        ...status,
        itemsList: Array.from(new Set(direcInvItems.map((a) => a.id))).map(
          (id) => {
            return direcInvItems.find((a) => a.id === id);
          }
        ),
      });
      setLoadingItem(false);
    }
    setFieldValue(`items[${index}]`, { ...newItem });
    /* if (invoiceType === 'edit' && checkDirectInvoice !== false) {
      setStatus({
        ...status,
        itemsList: status.itemsList.filter(item1 =>  item1.invoiceItemDetailId !== item.invoiceItemDetailId)
      })
      return
    }
    if (item.inventoryType === "noninventory" || salesOrderId) {
      setStatus({
        ...status,
        itemsList: status.itemsList.filter(item =>  item.id !== option.id )
      })
    }*/
    setLoadingItem(false);
  };

  const contactCreateModal = () => {
    // modalflag = true;
    return (
      <CustomModal
        size="large"
        showModal={status.showContactCreateModal}
        title="Add Contact"
        customWidth="contact-modal-width"
        renderActions={false}
        onClose={closeModal}
        showPrompt={showPrompt}
      >
        <AddContact
          type="salesOrder"
          inModal
          close={closeModal}
          onSubmit={(res) => handleContactCreateSubmit(res.data)}
          handleToast={handleToast}
          handlePrompt={handlePrompt}
        />
      </CustomModal>
    );
  };
  const getPriceFunction = async (customerId) => {
    if (values?.items[0]?.id !== null) {
      setLoadingItem(true);
      const ids = values.items.map((item) => {
        return item.id;
      });

      const response = await getPreviousSellingPrice(
        customerId,
        ids,
        status,
        setStatus
      );
      values.items.map((newItem, index) => {
        let previousRate = response?.find((o) => o.item_id === newItem.id);
        newItem.previousSellingPrice = previousRate?.rate;
        values.items[index] = newItem;
      });
      setLoadingItem(false);
    }
  };

  const handleContactCreateSubmit = async (newCustomer) => {
    const old_obj = {
      id: newCustomer.id,
      display_name: newCustomer.display_name,
    };
    setCustomerName(newCustomer.display_name);
    const { id: value, display_name: label, ...rest } = old_obj;

    const new_customer = { value, label, ...newCustomer };

    setValues({
      ...values,
      // items: [emptyItem],
      customerId: new_customer ? new_customer.id : null,
    });

    setCurrentCustomer(new_customer);
    await getPriceFunction(new_customer.value);

    let invoiceTermFilter = new_customer.invoice_term_id
      ? invoiceTermsList.filter(
          (list) => parseInt(list.id) === parseInt(new_customer.invoice_term_id)
        )
      : [];
    invoiceTermFilter.length > 0 &&
      setFieldValue('invoiceTerm', {
        name: invoiceTermFilter[0].name,
        value: invoiceTermFilter[0].value,
      });
    invoiceTermFilter.length > 0 && handleDate(invoiceTermFilter[0]);
    closeModal();
    // const new_customer = {value, label, ...newCustomer};
    // setCurrentCustomer('new customer');
  };

  const handleBulkAddItemsOnSubmit = async (selectedItems) => {
    const ids = selectedItems.map((item) => {
      return item.id;
    });
    // // Remove first blank field when adding items in bulk
    if (values.items[0] && values.items[0].id === null) {
      values.items.shift();
    }
    const response = await getPreviousSellingPrice(
      values.customerId,
      ids,
      status,
      setStatus
    );
    setStatus({
      ...status,
      loadingItems: false,
    });

    let newItems = [];
    selectedItems.forEach((item) => {
      let previousPrice = response.find((a) => a.item_id === item.id);
      const newItem = {
        ...item,
        previousSellingPrice: previousPrice?.rate || null,
        warehouseId: primaryWarehouse,
        discount: 0,
        discountType: 'percent',
        dragDropID: GetDateString(),
        unitPrice: item.sales_unit_price,
        total: getItemTotalAmount({
          quantity: item.quantity,
          unitPrice: item.sales_unit_price,
        }),
      };
      newItems.push(newItem);
    });

    setValues({
      ...values,
      items: [...values.items, ...newItems],
    });
    setStatus({
      ...status,
      //itemsList: status.itemsList.filter(item => !selectedItems.find(newItem => newItem.id === item.id  && item.inventory_type !== "inventory")),
      showBulkAddItemsModal: false,
    });

    // item.warehouseId = primaryWarehouse;
    // item['dragDropID'] = GetDateString();

    // for (let option of selectedItems) {
    //   option['unitPrice'] = option.sales_unit_price;
    //   option['total'] = getItemTotalAmount({
    //     quantity: option.quantity,
    //     unitPrice: option.sales_unit_price,
    //   });
    // }

    // let promises = [];
    // selectedItems.forEach((item) => {
    //   let PPURL = null;
    //   if (values.invoiceId !== null) {
    //     PPURL = `invoice/previoussoldprice?customer_id=${values.customerId}&item_id=${item.id}&object=invoice&object_id=${values.invoiceId}`;
    //   } else {
    //     PPURL = `invoice/previoussoldprice?customer_id=${values.customerId}&item_id=${item.id}`;
    //   }
    //   promises.push(restRequest('get', PPURL));
    // });
    // Promise.all(promises).then((promisRes) => {
    //   selectedItems.forEach((item, index) => {
    //     selectedItems[index] = {
    //       ...item,
    //       previousSellingPrice: promisRes[index] ? promisRes[index] : 0,
    //       discount: 0,
    //       discountType: 'percent',
    //     };
    //   });

    document.getElementsByClassName('action-buttons--submit').disabled = false;
    // });
  };

  const [modalState, setModalstate] = useState(false);
  useEffect(() => {
    if (!status.showBulkAddItemsModal) {
      setModalstate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status.showBulkAddItemsModal]);
  const bulkAddItemsModal = () => {
    return (
      <CustomModal
        size="large bulk"
        showModal={status.showBulkAddItemsModal}
        title="Add Bulk Items"
        modaltitle="modal_BulkItems_title_align"
        Icon={NewItemIcon}
        renderActions={false}
        onClose={closeModal}
        handleSubmiting={modalState}
        showPrompt={showPrompt}
      >
        <BulkAddItems
          // GetAllItem={GetAllItem}
          loadingItem={loadingItem}
          // items={status.itemsList.map((item) => ({
          //   ...item,
          //   quantity: 1,
          // }))}
          rateKey="sales_unit_price"
          currencySymbol={currency.symbol}
          close={closeModal}
          onSubmit={handleBulkAddItemsOnSubmit}
          handleToast={handleToast}
          setModalstate={setModalstate}
          handlePrompt={handlePrompt}
          moduleName={'invoices'}
        />
      </CustomModal>
    );
  };

  const closeConfirmedSalesOrdersListModal = () => {
    setStatus({ ...status, showConfirmedSalesOrdersListModal: false });
    updateconfirmedSO(0);
  };

  const [loader, showLoader] = useState(false);
  const handleConfirmedSalesOrdersListOnSubmit = async (
    selectedConfirmedSalesOrdersList
  ) => {
    showLoader(true);
    // Remove first blank field when adding items in bulk
    if (values.items[0] && values.items[0].id === null) {
      values.items.shift();
    }
    let ModifiedselectedConfirmedSalesOrdersList =
      selectedConfirmedSalesOrdersList.map((salesOrder) => {
        let newSalesOrderList = salesOrder.sales_order_item_details.filter(
          (singleItem) => singleItem.hasItemAdded !== true
        );
        return {
          ...salesOrder,
          sales_order_item_details: newSalesOrderList,
        };
      });
    const { items, selectedSalesOrderIds } = prepareConfirmedSalesOrdersItems(
      // selectedConfirmedSalesOrdersList
      ModifiedselectedConfirmedSalesOrdersList
    );
    let modifiedConfirmSalesOrderList = status.confirmedSalesOrdersList;
    items.forEach((singleItem) => {
      modifiedConfirmSalesOrderList = modifiedConfirmSalesOrderList.map(
        (singleOrder) => {
          if (singleItem.currentSalesOrderId === singleOrder.id) {
            let modifiedItem = singleOrder.sales_order_item_details.map(
              (currentSingleItem) => {
                if (singleItem.id === currentSingleItem.item_id) {
                  return {
                    ...currentSingleItem,
                    hasItemAdded: true,
                  };
                } else {
                  return currentSingleItem;
                }
              }
            );
            return {
              ...singleOrder,
              sales_order_item_details: modifiedItem,
            };
          } else {
            return singleOrder;
          }
        }
      );
    });

    const ids = items.map((item) => {
      return item.id;
    });

    const response = await getPreviousSellingPrice(
      values.customerId,
      ids,
      status,
      setStatus
    );
    setStatus({
      ...status,
      loadingItems: false,
    });
    let newItems = [];
    items.forEach((item) => {
      let previousPrice = response.find((a) => a.item_id === item.id);
      const newItem = {
        ...item,
        previousSellingPrice: previousPrice?.rate || null,
        dragDropID: GetDateString(),
      };
      newItems.push(newItem);
    });
    let modifiedselectedConfirmedSalesOrderIds = [
      ...status.selectedConfirmedSalesOrderIds,
      ...selectedSalesOrderIds,
    ];
    modifiedConfirmSalesOrderList = modifiedConfirmSalesOrderList.map(
      (salesOrder) => ({
        ...salesOrder,
        selectedByItem: modifiedselectedConfirmedSalesOrderIds.includes(
          salesOrder.id
        ),
      })
    );

    setStatus({
      ...status,
      confirmedSalesOrdersList: modifiedConfirmSalesOrderList,
      selectedConfirmedSalesOrderIds: modifiedselectedConfirmedSalesOrderIds,
      showConfirmedSalesOrdersListModal: false,
    });
    showLoader(false);

    setFieldValue('items', [...values.items, ...newItems]);
  };

  const confirmedSalesOrdersListModal = () => {
    return (
      <CustomModal
        showModal={status.showConfirmedSalesOrdersListModal}
        title={
          confirmedSO !== 0
            ? `Confirm Sales Orders (${confirmedSO} selected)`
            : 'Confirm Sales Orders'
        }
        renderActions={false}
        Icon={NewSalesOrderIcon}
        modaltitle="confirm_SO_modal"
        onClose={() => closeConfirmedSalesOrdersListModal()}
      >
        <BulkAddConfirmedSalesOrders
          salesOrdersList={status?.confirmedSalesOrdersList.filter(
            (item) => item?.selectedByItem !== true
          )}
          onSubmit={(selectedConfirmedSalesOrdersList) => {
            handleConfirmedSalesOrdersListOnSubmit(
              selectedConfirmedSalesOrdersList
            );
          }}
          handleToast={handleToast}
          onClose={() => closeConfirmedSalesOrdersListModal()}
          updateconfirmedSO={updateconfirmedSO}
          loader={loader}
        />
      </CustomModal>
    );
  };

  const handleCustomerChange = async (option) => {
    const { selectedConfirmedSalesOrderIds = [] } = status;
    if (selectedConfirmedSalesOrderIds.length > 0) {
      setShowConfirmRemoveItemModal(true);
      setStatus({
        ...status,
        newCustomerId: option?.value,
      });

      return;
    }
    if (option && option.value === 'createNew') {
      setStatus({ ...status, showContactCreateModal: true });
      setValues({
        ...values,
      });
    } else {
      if (option && values.items[0].id !== null) {
        const ids = values.items.map((item) => {
          return item.id;
        });
        setCustomerName(option?.label);
        values.customerId = option?.value;
        // setValues({
        //   ...values,
        // });
        const response = await getPreviousSellingPrice(
          values.customerId,
          ids,
          status,
          setStatus
        );
        status.loadingItems = false;
        // setStatus({
        //   ...status,
        // });
        let newItems = [];
        values.items.forEach((item) => {
          let previousPrice = response.find((a) => a.item_id === item.id);
          const newItem = {
            ...item,
            previousSellingPrice: previousPrice?.rate || null,
            dragDropID: GetDateString(),
            unitPrice: item.sales_unit_price,
            total: getItemTotalAmount({
              quantity: item.quantity,
              unitPrice: item.sales_unit_price,
            }),
          };
          newItems.push(newItem);
        });

        setValues({
          ...values,
          items: newItems,
        });
      } else {
        setCustomerName(option?.label);
        setValues({
          ...values,
          customerId: option ? option?.value : null,
        });
        setStatus({
          ...status,
          confirmedSalesOrdersList: [],
        });
      }
      // when customer has custom invoice term ---> enable due date field
      const { value: invoiceTermValue } = option?.invoice_terms || {};
      if (option?.invoice_terms) setTerms_condition(invoiceTermValue);
      if (option) fetchConfirmedSalesOrders(option?.value, invoiceTermValue);
    }
  };

  const fetchConfirmedSalesOrders = (customerId, invoiceTermValue) => {
    setSales_invoice_loading(true);
    restRequest(
      'get',
      `invoices/create/invoiceable-confirmed-salesorders/from-contacts/${customerId}`
    ).then((res) => {
      setSales_invoice_loading(false);
      setStatus({
        ...status,
        confirmedSalesOrdersList: res.sale_orders,
        selectedConfirmedSalesOrderIds: [],
        disableDate: invoiceTermValue === 'custom' ? false : true,
      });
      setFieldValue('invoiceTerm', {
        name:
          res.customer[0] &&
          res.customer[0].invoice_terms &&
          res.customer[0].invoice_terms.name,
        value:
          res.customer[0] &&
          res.customer[0].invoice_terms &&
          res.customer[0].invoice_terms.value,
      });
      handleDate(
        res.customer[0] &&
          res.customer[0].invoice_terms &&
          res.customer[0].invoice_terms
      );
    });
  };

  const mergeItems = (event) => {
    if (submitFlag === false) {
      event.preventDefault();
      setShowMergeModal(true);
    } else {
      formSubmit(event, values.items, setFieldValue, handleSubmit);
    }
  };

  const renderAddItem = () => {
    return (
      <>
        {showAddItemButton && (
          <AddItem
            onClick={() =>
              setValues({
                ...values,
                items: [...values.items, status.emptyItem],
              })
            }
          />
        )}
        {values.customerId && !checkDirectInvoice && !inModal && (
          <AddItem
            label={'Add Bulk Item'}
            onClick={() =>
              !loadingItem &&
              setStatus({
                ...status,
                showBulkAddItemsModal: true,
              })
            }
          />
        )}
      </>
    );
    // }
  };

  const handleSalesPersonChange = (option) => {
    if (option !== null) {
      setFieldValue('salesPersonId', option.value);
    } else {
      setFieldValue('salesPersonId', null);
    }
  };

  const handleTermsAndConditions = (event) => {
    let selectedValue = event.target.value;
    // FieldValueChanged();
    if (selectedValue === 'true') {
      setFieldValue('hasTermsAndConditions', !!selectedValue);
    } else {
      setFieldValue('hasTermsAndConditions', !!!selectedValue);
    }
  };

  const renderTermsAndConditions = () => {
    return (
      <div className="form-field flex-50">
        <Field
          className="radio-field radio_buttons"
          type="radio"
          labelStyle={true}
          name="Terms & Conditions?"
          id="yes"
        >
          <RadioContainer>
            <Radio
              className="inline-field "
              id="yes"
              value={true}
              name="Yes"
              onChange={handleTermsAndConditions}
              checked={values.hasTermsAndConditions}
            />
            <Radio
              className="inline-field"
              id="no"
              value={false}
              name="No"
              onChange={handleTermsAndConditions}
              checked={!values.hasTermsAndConditions}
            />
          </RadioContainer>
        </Field>
        <div className="invoice-textarea-height">
          {values.hasTermsAndConditions && (
            <Field
              className="textarea-so-terms w-100"
              type="textarea"
              value={
                values.termsAndConditions !== null
                  ? values.termsAndConditions
                  : ''
              }
              id="termsAndConditions"
              required={true}
              handleChange={(e) => {
                setFieldValue('termsAndConditions', e.target.value);
              }}
              error={touched.termsAndConditions && errors.termsAndConditions}
            />
          )}
        </div>
      </div>
    );
  };

  let submitFlag = true;
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showPendInvModal, setShowPendInvModal] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [customerId, setCustomerId] = useState('');
  const handleCustomerModal = (id) => {
    setCustomerId(id);
    setShowCustomerModal(true);
  };

  const handleDetailModal = (id) => {
    setCustomerId(id);
    setShowPendInvModal(true);
  };
  const handlePrompt = (status = true) => setShowPrompt(status);

  const contactDeatilModal = () => {
    return (
      <CustomModal
        size="large"
        className="contacts_modal_view"
        showModal={showCustomerModal}
        title="View Contact"
        // minWidth={120}
        modaltitle="inventory_modal_title"
        Icon={NewContactIcon}
        renderActions={false}
        onClose={() => setShowCustomerModal(false)}
        linkTo={`contacts/${customerId && customerId}`}
      >
        <Customer id={customerId && customerId} inModal />
      </CustomModal>
    );
  };

  const pendingInvoiceModal = () => {
    return (
      <CustomModal
        size="large"
        className="view_pending_popup"
        showModal={showPendInvModal}
        title={`Pending Invoices - ${customerName}`}
        // minWidth={120}
        modaltitle="inventory_modal_title"
        Icon={NewInvoiceIcon}
        renderActions={false}
        onClose={() => setShowPendInvModal(false)}
      >
        <TableContent
          url={`invoices/unpaid/${customerId}`}
          inModal={true}
          showdetail="Invoices"
          currency={currency.symbol}
        />
      </CustomModal>
    );
  };

  const checkTotalValueForZero = () => {
    return (
      <CustomModal
        style={{ marginTop: '5%' }}
        showModal={cofirmState}
        title="Confirm Invoice"
        renderActions={false}
        onClose={() => handleTotalCheckFalse(false)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <p>Your total invoice amount is Zero. Please confirm to continue.</p>

          <button
            type="button"
            className="action-buttons--submit next-btn"
            onClick={() => {
              handleTotalCheckFalse(false);
              totalConfirmCheck = true;
              handleSubmit();
            }}
          >
            Confirm
          </button>
        </div>
      </CustomModal>
    );
  };
  const ReasonOnEdit = () => {
    return (
      <CustomModal
        size="medium"
        showModal={confirmOnEdit}
        //title=""
        title="Reason For Edit"
        renderActions={false}
        onClose={() => handleReasonOnEdit(false)}
      >
        <div className="form-field flex-100">
          <div className="invoice-edit-reason">
            <Field
              type="textarea"
              //autoFocus={true}
              name="Note down the reason for editing an invoice that has already been sent."
              className="invoice-edit-reason-text-area"
              id="edit_reason"
              onBlur={handleBlur}
              value={values.edit_reason}
              handleChange={handleChange}
              error={touched.edit_reason && errors.edit_reason}
            />
          </div>
        </div>
        <div className="btnsRow">
          <button
            type="button"
            className="cancel-btn mr-3"
            onClick={() => {
              handleReasonOnEdit(false);
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="action-buttons--submit next-btn"
            disabled={!values?.edit_reason?.trim()}
            onClick={() => {
              handleReasonOnEdit(false);
              confirmModal = true;
              handleSubmit();
            }}
          >
            Confirm
          </button>
        </div>
      </CustomModal>
    );
  };
  const handleDeleteConfirmSaleOrderItems = () => {
    let { items = [] } = values;

    let { selectedConfirmedSalesOrderIds = [], newCustomerId } = status;

    const itemResult = items.filter(
      (item) =>
        selectedConfirmedSalesOrderIds.indexOf(item.currentSalesOrderId) === -1
    );
    if (newCustomerId) {
      fetchConfirmedSalesOrders(newCustomerId, terms_condition);
    }
    if (!itemResult.length) {
      setStatus({
        ...status,
        confirmedSalesOrdersList: [],
        selectedConfirmedSalesOrderIds: [],
      });
    }

    setFieldValue(
      'items',
      itemResult?.length ? itemResult : [status.emptyItem]
    );
    setFieldValue('customerId', newCustomerId);

    setShowConfirmRemoveItemModal(false);
  };
  const renderConfirmRemoveItemModal = () => {
    const { items = [] } = values;
    const salesOrderitemList = items.filter((item) => item.id);
    return (
      <>
        <CustomModal
          showModal={showConfirmRemoveItemModal}
          title={`Confirm Delete Sale Order Items`}
          onClose={() => {
            setShowConfirmRemoveItemModal(false);
          }}
          onSubmit={handleDeleteConfirmSaleOrderItems}
        >
          <div className="confirm-popup-main">
            <p>
              Are you sure you want to delete the confirm sale orders items?
            </p>
            <>
              <ul className="confirm-popup">
                {salesOrderitemList.map((saleOrderitem) => (
                  <li key={saleOrderitem.id}>{saleOrderitem.name}</li>
                ))}
              </ul>
            </>
          </div>
        </CustomModal>
      </>
    );
  };

  const handleDueDate = (option, invoiceStartDate) => {
    let value = option.value;
    let date = invoiceStartDate;
    switch (value) {
      case 'custom':
        break;
      case 'this month':
        date = moment(customRanges(invoiceStartDate).endOfMonth);
        break;
      case 'next month':
        date = moment(customRanges(invoiceStartDate).endOfNextMonth);
        break;
      case 'receipt':
        date = moment(customRanges(invoiceStartDate).endOfToday);
        break;
      default:
        date = moment(addDays(invoiceStartDate, value));
    }
    setFieldValue('dueDate', date);
  };

  const showAddItemsButton = () =>{
    if(type === 'add'){
      return true
    } else if( type === 'edit'){
      if(values?.status === 'draft'){
        return true
      } else if(!owner_can_edit_in_lock_status){
        return false;
      }
    }
  };
  return (
    <Fragment>
      {contactCreateModal()}
      {newItemModal()}
      {invoiceTermModal()}
      {bulkAddItemsModal()}
      {confirmedSalesOrdersListModal()}
      {contactDeatilModal()}
      {checkTotalValueForZero()}
      {renderConfirmRemoveItemModal()}
      {pendingInvoiceModal()}

      <Form
        className={` invoice_form_main invoice-popup-main  ${
          inModal ? 'invoice_modal invoices-large' : 'uiux-invoice-form-main'
        }`}
        title={title}
        onSubmit={(event) => mergeItems(event)}
      >
        {ReasonOnEdit()}
        <div className="form-fields-container d-flex flex-wrap uiux-invoice-form w-100 ">
          <div className="field-container  position-relative flex-25 form-field">
            <Field
              id="customerId"
              name="Customer Name"
              placeholder="Select Customer"
              autoFocus={true}
              type="DynamicPaginationDropdown"
              newCust={CurrentCustomer}
              value={values.customerId}
              editCust={values.invoiceObj}
              disabled={
                owner_can_edit_in_lock_status ||
                inModal ||
                (type === 'edit' && invoice.soId)
              }
              addButton={true}
              required
              handleChange={(option) => {
                handleCustomerChange(option);
              }}
              error={touched.customerId && errors.customerId}
              alphabetSort={true}
              onBlur={() => {
                setFieldTouched('customerId', true);
              }}
              api="invoices/contact"
            />
            {!inModal && values.customerId && (
              <div className="details-sec text-left d-flex space-between">
                <button
                  type="button"
                  className="view-details-btn-1 pt-8"
                  onClick={() => handleDetailModal(values.customerId)}
                >
                  {' '}
                  Pending Invoices
                </button>

                <div className="details-sec text-right">
                  <button
                    type="button"
                    className="view-details-btn-1 pt-8"
                    onClick={() => handleCustomerModal(values.customerId)}
                  >
                    {' '}
                    View Details
                  </button>
                </div>
              </div>
            )}
          </div>
          <Field
            name="Invoice No."
            id="invoiceNo"
            className="flex-25"
            placeholder="Invoice No"
            value={values.invoiceNo}
            // disabled={ type === 'edit'}
            disabled
            handleChange={handleChange}
            onBlur={handleBlur}
            required
            error={touched.invoiceNo && errors.invoiceNo}
          />
          <Field
            name="Order No."
            id="orderNo"
            className={` flex-25 position-relative ${
              !inModal && values.customerId
                ? 'invoice-form-details'
                : 'invoice-add-form'
            }`}
            // className="flex-25 position-relative invoice-add-form"
            value={values.orderNo === null ? '' : values.orderNo}
            handleChange={handleChange}
            onBlur={handleBlur}
            disabled={inModal}
            error={touched.orderNo && errors.orderNo}
          />

          <Field
            className="flex-25 date-canalder-g"
            type="date"
            name="Invoice Date"
            id="invoiceDate"
            disabled={owner_can_edit_in_lock_status}
            // value={values.invoiceDate}
            value={
              typeof values.invoiceDate == 'string'
                ? moment(values.invoiceDate)
                : values.invoiceDate
            }
            // minDate={values.saleOrderDate}
            minDate={
              typeof values.saleOrderDate == 'string'
                ? moment(values.saleOrderDate)
                : values.saleOrderDate
            }
            handleChange={(date) => {
              if (!date) return;
              setFieldValue('invoiceDate', date);
              if (
                values.invoiceTerm.name === '' ||
                values.invoiceTerm.name === 'custom'
              ) {
                if (values.dueDate < date) {
                  setFieldValue('dueDate', date);
                }
              } else {
                handleDueDate(values.invoiceTerm, date);
              }
            }}
            onBlur={() => setFieldTouched('invoiceDate', true)}
            required
            error={touched.invoiceDate && errors.invoiceDate}
          />

          <InvoiceTerm
            className="flex-25"
            name="Invoice Terms"
            handleInvoiceTerms={(option) => handleInvoiceTerms(option)}
            handleInvoiceDate={(date) => setFieldValue('dueDate', date)}
            invoiceDate={values.invoiceDate}
            invoiceTermsList={invoiceTermsList}
            selectedInvoiceTerm={values.invoiceTerm}
            owner_can_edit_in_lock_status={owner_can_edit_in_lock_status}
          />

          <Field
            type="date"
            name="Due Date"
            className="flex-25"
            id="dueDate"
            minDate={values.invoiceDate}
            value={values.dueDate}
            handleChange={(date) => setFieldValue('dueDate', date)}
            disabled={status.disableDate}
          />
          {!!salesPersonsFromDB && (
            <Field
              type="dropdown"
              id="salesPersonId"
              name="Sales Person"
              className="flex-25"
              value={values.salesPersonId}
              options={salesPersonsFromDB.map(
                (salesPerson) => salesPerson.users
              )}
              disabled={is_sales_person}
              dropdownValue="id"
              dropdownLabel="name"
              handleChange={handleSalesPersonChange}
              clearable={sales_person_clearable}
            />
          )}
          <div className="d-flex w-100 tablet-align flex-wrap invoice-tbl">
            <Field
              type="textarea"
              className="flex-50"
              name="Notes"
              value={values.note}
              id="note"
              handleChange={handleChange}
              onBlur={handleBlur}
              error={touched.note && errors.note}
            />
            {renderTermsAndConditions()}
          </div>
        </div>
        <div className="form-field form-table--container horizontal-items-table invoice-table-set">
          <label className="form-label">Select Items</label>
          {loadingItem && <Loader />}
          <FieldArray
            name="items"
            render={() => renderSelectedItemsTable(type)}
          />
          {showAddItemsButton() && (
            <div className="invoice-form-items-action">
              {type === 'add' && salesOrderId === null
                ? renderAddItem()
                : showAddItemButton && renderAddItem()}

              {type === 'add' && (
                <>
                  <AddItem
                    // visible={!!status.confirmedSalesOrdersList.length}
                    visible={
                      status.confirmedSalesOrdersList.filter(
                        (item) => item?.selectedByItem !== true
                      ).length
                    }
                    onClick={() =>
                      setStatus({
                        ...status,
                        showConfirmedSalesOrdersListModal: true,
                      })
                    }
                    label={
                      sales_invoice_loading
                        ? 'loading...'
                        : `Add ${
                            status.confirmedSalesOrdersList.filter(
                              (item) => item?.selectedByItem !== true
                            ).length
                          } confirmed Sales Orders`
                    }
                    inline={false}
                  />
                </>
              )}
            </div>
          )}
          {renderOrderSummary()}
        </div>

        {renderActionButtons()}
        <ErrorFocus />
      </Form>
    </Fragment>
  );
};

const getOrderTotal = (values, overAllTotalTax) => {
  const {
    items,
    transactionDiscount = 0,
    transactionDiscountType,
    adjustment = 0,
    shippingCharge = 0,
  } = values;

  let overallTotal = items.reduce((total, item) => {
    return parseFloat(item.total) + parseFloat(total);
  }, 0);

  let transactionDiscountTotal = transactionDiscount;
  let TotalTax = overAllTotalTax();
  if (transactionDiscountType === 'percent') {
    if (transactionDiscount > 100) {
      return 'MAX_PERCENTAGE';
    }
    transactionDiscountTotal = (overallTotal * transactionDiscountTotal) / 100;
  } else if (transactionDiscountType === 'amount') {
    let OrderAmount = parseFloat((overallTotal + TotalTax).toFixed(2));
    if (OrderAmount < transactionDiscount) {
      return 'MAX_AMOUNT';
    }
  }
  overallTotal =
    (
      overallTotal +
      +adjustment +
      shippingCharge -
      transactionDiscountTotal +
      TotalTax
    ).toFixed(2) || 0;
  return overallTotal;
};

export default formEnhancer(InvoiceForm);
