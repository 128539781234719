import React, { Component } from 'react';
import { withRouter } from 'react-router';
import CustomModal from '../../../../Components/CustomModal/CustomModal';
import { NoItemsBox } from '../../../../Components/Layout/Box';
import Loader from '../../../../Components/Loader/Loader';
import Table from '../../../../Components/Table/Table';
import getDate from '../../../../Helpers/Date/GetDate';
import { restRequest } from '../../../../Helpers/RequestHelper';
import { MdAdd } from '../../../../Common/Icons';
import DeleteIcon from '../../../../Assets/General/DeleteIcon';
import { getObjFromLS, setObjInLS } from '../../../../Helpers/LocalStorage';
import RecordPaymentForm from '../../../../Components/Form/RecordPaymentForm/RecordPaymentForm';
import './CreditRefund.css';
import RefundIcon from '../../../../Assets/General/RefundIcon';
import ShipmentInfoCard from '../../../SalesOrder/Packages/PackageDetails/Info/ShipmentInfoCard';
import { checkError } from '../../../../Helpers/AuthHelper';
class CreditNoteRefund extends Component {
  state = {
    loading: true,
    creditRefundList: [],
    showDeleteCreditRefundModal: false,
    showPaymentRefundModal: false,
    showCreateCreditRefundModal: false,
    showButton: false,
    selectedCreditNoteRedund: null,
    showPrompt: false,
  };
  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    await restRequest('get', `creditrefund/${this.props.creditNoteId}/list`)
      .then((res) => {
        this.setState({
          showButton: res.showButton,
          creditRefundList: res.creditRefunds,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error);
        this.setState({ loading: false });
      });
  }

  handlePrompt = (status = true) => {
    this.setState({ showPrompt: status });
  };

  closeAllModals = () => {
    localStorage.setItem("alert", false)
    this.setState({
      showDeleteCreditRefundModal: false,
      showCreateCreditRefundModal: false,
      showPaymentRefundModal: false,
    });
  };

  deletePaymentModal() {
    return (
      <CustomModal
        type="delete"
        showModal={this.state.showDeleteCreditRefundModal}
        title="Confirm Delete Credit Refund"
        onClose={this.closeAllModals}
        onSubmit={this.deletePayment}
      >
        Are you sure you want to delete this Credit Refund?
      </CustomModal>
    );
  }

  deletePayment = () => {
    this.closeAllModals();
    restRequest('delete', `creditrefund/${this.state.clickedId}`)
      .then((res) => {
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS === null) {
          let obj = {
            prevId: null,
            id: null,
            nextId: null,
            moduleName: '',
            urlPath: window.location.pathname,
            queryParam: window.location.search,
          };
          setObjInLS('module', obj);
          dataFromLS = getObjFromLS('module');
        }
        setObjInLS('module', {
          ...dataFromLS,
          queryParam: `${dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
            }creditRefunds=true`,
        });
        this.fetchData();
        this.props.updateCreditNote();
        this.props.handleToast(
          'Credits Refund deleted successfully',
          'success'
        );
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  };

  renderModals() {
    return (
      <>
        {this.deletePaymentModal()}
        {this.CreditRefundModal()}
        {this.renderViewCreditRefundModal()}
      </>
    );
  }

  tableRowOptions = [
    {
      label: 'Delete',
      icon: DeleteIcon,
      onClick: (id) => this.openCreditRefundDeleteModal(id),
      disabled: () => !this.props.hasPermissionCustom('creditnotes', 'Delete'),
    },
  ];

  openCreditRefundDeleteModal = (id) => {
    this.setState({
      showDeleteCreditRefundModal: true,
      clickedId: id,
    });
  };

  openCreateRefundModal = () => {
    this.setState({
      showCreateCreditRefundModal: true,
    });
  };

  showRefundModal = (index) => {
    this.setState({
      showPaymentRefundModal: true,
      selectedCreditNoteRedund: index,
    });
  };

  prepareCreditRefundList(data) {
    return data.map((payment, index) => {
      return {
        ...payment,
        paymentDate: getDate(payment.refund_date),
        mode: this.props.inModal ? (
          <div>{payment.refund_mode}</div>
        ) : (
          <div
            className="payment_view"
            onClick={() =>
              this.showRefundModal(this.state.creditRefundList[index])
            }
          >
            {payment.refund_mode}
          </div>
        ),
        amount_credited: `${this.props.currency.symbol}${payment.refund_credits}`,
      };
    });
  }

  renderCreditRefundListData() {
    const tableListData = this.prepareCreditRefundList(
      this.state.creditRefundList
    );
    const tableHeaders = ['Date Refunded', 'Payment Mode', 'Amount Credited'];
    const tableData = [
      'paymentDate',
      'mode',
      'amount_credited',
      !this.props.inModal && 'options',
    ];

    return (
      <>
        {this.state.creditRefundList.length > 0 && (
          <div className="float-left w-100">
            <Table
              list={tableListData}
              tableHeaders={tableHeaders}
              tableData={tableData}
              fromTab={true}
              options={this.props.inModal ? [] : this.tableRowOptions}
              className={`box-table box-table credit-refund-table`}
            />
          </div>
        )}
        {this.state.showButton &&
          this.props.hasPermissionCustom('creditnotes', 'Create') &&
          (this.props.inModal ? (
            ''
          ) : (
            <button
              className="layout-box--button btn-width"
              onClick={this.openCreateRefundModal}
            >
              <MdAdd className=" black-fill" />
              Create Refund
            </button>
          ))}
      </>
    );
  }

  CreditRefundModal() {
    return (
      <CustomModal
        Icon={RefundIcon}
        className="AppplyCredits Refund"
        showModal={this.state.showCreateCreditRefundModal}
        title={`Add Refund | ${this.props.creditNoteNo}`}
        renderActions={false}
        size="large"
        onClose={this.closeAllModals}
        extraClass="apply_credit_notes" //commenting this to remove extra space from modal. Ticket related
        extraTitle={`Total Available Credits : ${this.props.currency.symbol}${this.props.totalAvailableCredits}`}
        showPrompt={this.state.showPrompt}
      >
        <RecordPaymentForm
          type="refund"
          id={this.props.creditNoteId}
          handleToast={this.props.handleToast}
          onSubmit={this.handleRecordPaymentSubmit}
          currency={this.props.currency}
          onClose={this.closeAllModals}
          remainingCredits={this.props.totalAvailableCredits}
          creditNoteDate={this.props.creditNoteDate}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  renderViewCreditRefundModal() {
    return (
      <CustomModal
        showModal={this.state.showPaymentRefundModal}
        title={`Credit Refund Details | ${this.props.creditNoteNo}`}
        Icon={RefundIcon}
        renderActions={false}
        renderShipmentActionMenu={true}
        size="small"
        onClose={this.closeAllModals}
      >
        <ShipmentInfoCard
          type="credit_refund"
          currency={this.props.currency}
          selectedRefund={this.state.selectedCreditNoteRedund}
          closeViewRefundModal={this.closeAllModals}
        />
      </CustomModal>
    );
  }

  handleRecordPaymentSubmit = () => {
    this.fetchData();
    this.props.updateCreditNote();
    this.closeAllModals();
  };

  CreditRefundList() {
    return (
      <>
        {this.renderModals()}
        {!this.state.creditRefundList.length ? (
          <NoItemsBox
            subtitle={
              this.state.showButton ? ' ' : 'Refunds Not Applicable Yet!'
            }
            buttonText="Create Refund"
            showButton={this.state.showButton}
            handleclick={this.openCreateRefundModal}
            Icon={RefundIcon}
            inModal={this.props.inModal}
          />
        ) : (
          this.renderCreditRefundListData()
        )}
      </>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return this.CreditRefundList();
  }
}
export default withRouter(CreditNoteRefund);
