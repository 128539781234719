import React, { useEffect } from 'react';
import { TaxIcon } from '../../Assets/Settings/SettingsIcon';
import TaxForm from '../../Components/Form/TaxForm/TaxForm';
import FormLayout from '../../Components/Layout/FormLayout';
// import useToggleClick from '../../Hooks';
// import { validateTaxForm } from '../../Helpers/FormValidator';

const EditTax = ({ inModal, match, handleToast }) => {
  // const [, handleCrossAlert, handleCrossClick] = useToggleClick();
  useEffect(() => {
    document.title = 'Edit Tax';
  }, []);

  const renderTaxForm = () => (
    <TaxForm
      title="Edit Tax"
      inModal={inModal || false}
      type="edit"
      id={match.params.id}
      handleToast={handleToast}
      // handleCrossAlert={handleCrossAlert}
    />
  );

  return (
    <FormLayout
      title="Taxes"
      Icon={TaxIcon}
      subtitle="Edit"
      closeUrl="/taxes"
      // navigate={false}
      // handleCrossClick={handleCrossClick}
      // validator={validateTaxForm}
    >
      {renderTaxForm()}
    </FormLayout>
  );
};

export default EditTax;
