import React, { useState } from 'react';
import { imagePathS3, restRequest, orgStaticContent } from './index';
import './ChooseOrganization.css'

const OrganizationsList = ({ organizations = [], props }) => {
  organizations = organizations.filter((singleOrg) => singleOrg.status !== 0);

  const [btnLoader, setBtnLoader] = useState(false);

  const organizationSwitchHandler = (id) => {
    setBtnLoader(true);
    restRequest('post', `organizations/set_default/${id}`)
      .then((res) => {
        setBtnLoader(false);
        props.handleToast('Set as default', 'success');
        props.history.push('/');
      })
      .catch((error) => {
        setBtnLoader(false);
        props.handleToast(error, 'error');
      });
  };

  return (
    <>
      {organizations.length !== 0 ? (
        <>
          <div className="___main-organization">
            <h4
              className="semi-bold"
              style={{ marginBottom: '25px', textAlign: 'center' }}
            >
              {orgStaticContent.selectTitle}
            </h4>
            {organizations?.map((org) => (
              <div key={org.id} className={`choose_organization`}>
                <h6> </h6>

                <div
                  className="float-left w-100 ml"
                  style={{ height: '290px' }}
                >
                  <div
                    className="float-left h-100 choose_organiartion_card"
                    style={{ width: '70%', border: '1px solid #F0F2F5' }}
                  >
                    <div
                      className="float-left w-100 h-100 left_Sec"
                      style={{ padding: '10px' }}
                    >
                      <div className="image_orgnaization">
                        <div className="m_auto">
                          <img
                            src={imagePathS3(org.organizations.logo)}
                            alt="organization pic"
                          />
                        </div>
                      </div>
                      <div className="name_organization text-center float-left w-100">
                        {org.organizations.name}
                      </div>
                      <div className="about_organization text-center float-left w-100">
                        {org?.organizations?.organization_type?.label}
                      </div>
                      <div className="organzation_action_buttons float-left w-100">
                        <button
                          className="button--full-width btn-select-set"
                          // style={{ width: '30%' }}
                          disabled={btnLoader ? true : false}
                          onClick={() => {
                            organizationSwitchHandler(org.organizations.id);
                          }}
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : null}
    </>
  );
};
export default OrganizationsList;
