import React from 'react';
import CreatableSelect from 'react-select/creatable';

import './../SearchableDropdown/SearchableDropdown.css';

const MultiSelect = ({
  id,
  value,
  valueKey,
  labelKey,
  options,
  removeSelected = false,
  placeholder = 'Select...',
  handleChange,
  disabled = false,
  searchable = true,
  clearable = true,
  className = '',
  // additionalSearchFilter = '',
  noResultsText = 'No results found',
  // validationCheck = false,
  onBlur,
  size = 'medium',
}) => {
  return (
    <CreatableSelect
      className={`searchable-dropdown ${size} ${className}`}
      classNamePrefix="searchable-dropdown"
      id={id}
      defaultValue={options.filter((option) => option[valueKey] === value)}
      getOptionLabel={(option) => option[labelKey]}
      getOptionValue={(option) => option[valueKey]}
      options={options}
      isMulti={true}
      onChange={handleChange}
      placeholder={placeholder}
      isDisabled={disabled}
      isSearchable={searchable}
      isClearable={clearable}
      hideSelectedOptions={removeSelected}
      onBlur={onBlur}
      noOptionsMessage={() => noResultsText}
    />
  );
};

export default MultiSelect;
