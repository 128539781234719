import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { restRequest } from '../../../Helpers/RequestHelper';
import { getOrganizationDate } from '../../../Helpers/Date/OrganizationDate';

import Loader from '../../Loader/Loader';
import NotFound from '../../NotFound/NotFound';

import ReturnReceiveEnhancer from './ReturnReceiveEnhancer';

import './PackageForm.css';
import { checkError } from '../../../Helpers/AuthHelper';
import { GetDateString } from '../../../Helpers';
let warehouseResult = [];
class PackageForm extends Component {
  type = this.props.type; // "add" or "edit" form
  id = this.props.id || this.props.match.params.id || null;

  emptyItem = {
    id: null,
    salesOrderItemDetailId: null,
    name: '',
    image: '',
    sku: '',
    unit: 'unit',
    ordered: 0,
    packed: 0,
    stocks: 0,
    received_quantity: 0,
    quantity: null,
    dragDropID: GetDateString(),
  };

  state = {
    packageInfo: {
      packageNo: '',
      salesOrderId: null,
      packageDate: moment(getOrganizationDate()).format('YYYY-MM-DD 00:00:00'),
      orderDate: null,
      items: [this.emptyItem],
    },
    // New Functionality
    warehouseList: [],
    genericWarehouseId: null,
    itemsList: [],
    salesOrderList: [],
    currency: {
      currency_code: '',
      name: '',
      symbol: '',
    },
    loading: true,
    notFound: false,
  };

  componentDidMount() {
    this.fetchData();
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  async fetchData() {
    await this.setSalesOrderId();
    switch (this.type) {
      case 'edit':
        await this.fetchPackageEditData();
        break;
      case 'add':
      default:
        await this.fetchPackageCreateData(this.state.packageInfo.salesOrderId);
    }

    this.setState({ loading: false });
  }

  async setSalesOrderId() {
    // if (this.props.inModal) {
    this.setState({
      packageInfo: {
        ...this.state.packageInfo,
        salesOrderId: this.props.salesReturnId,
      },
    });
    return this.props.salesOrderId;

    // const res = await restRequest('get', 'packages/create')
    // if(res.sales_order_list.length) {
    //   const salesOrderList = res.sales_order_list.map(salesorder => ({
    //     id: salesorder.id,
    //     name: salesorder.sales_order_no
    //   }))
    //   this.setState({
    //     salesOrderList: salesOrderList,
    //     packageInfo: {
    //       ...this.state.packageInfo,
    //       salesOrderId: salesOrderList[0].id
    //     }
    //   })
    //   return salesOrderList[0].id
    // }
  }

  prepareEditItemsList(
    itemsArray,
    salesOrderItemDetails,
    returnReceiveQuantity
  ) {
    return itemsArray.map((item, index) => ({
      id: item.item_id,
      dragDropID: GetDateString(),
      salesOrderItemDetailId: item.sales_order_item_detail_id,
      packageItemDetailId: item.id,
      name: item.items.name,
      images: item.items.images,
      sku: item.items.sku,
      unit: item.items.unit,
      inventoryType: item.items.inventory_type,
      warehouse_idd: salesOrderItemDetails[index].warehouse_id,
      warehouse_id: salesOrderItemDetails[index].warehouse_id,
      ordered: salesOrderItemDetails.find(
        (salesorderItem) => item.item_id === salesorderItem.item_id
      ).quantity,
      packed: salesOrderItemDetails.find(
        (salesorderItem) => item.item_id === salesorderItem.item_id
      ).packed,
      // stocks: item.item.stocks.find(stock => stock.warehouse_id === packageSalesOrder.warehouse_id) || {},
      received_quantity: item.received_quantity,
      quantity: returnReceiveQuantity[index]
        ? returnReceiveQuantity[index].quantity
        : 0,
      quantityForErrorCheck: item.received_quantity - item.current_quantity,
      totalQuantity: item.quantity,
    }));
  }

  prepareItemsList(itemsArray, packageSalesOrder) {
    return itemsArray.map((item) => ({
      id: item.item_id,
      salesOrderItemDetailId: item.sales_order_item_detail_id,
      name: item.items.name,
      images: item.items.images,
      sku: item.items.sku,
      unit: item.items.unit,
      ordered: item.quantity,
      inventoryType: item.items.inventory_type,
      packed: item.packed,
      stocks:
        item.items.stocks.find(
          (stock) => stock.warehouse_id === packageSalesOrder.warehouse_id
        ) || {},
      received_quantity: item.received_quantity,
      quantity: item.remaining_quantity,
    }));
  }

  fetchPackageEditData() {
    return restRequest('get', `returnreceives/${this.id}/edit`)
      .then((res) => {
        warehouseResult = res.warehouse_list;
        let arr = [];
        for (let i = 0; i < res.sales_Return.salesReturnDetails.length; i++) {
          arr = this.state.warehouseList;
          arr.push(res.sales_Return.salesReturnDetails[i].warehouse_id);
          this.setState({ warehouseList: arr });
        }
        const packageInfo = {
          ...this.state.package,
          packageNo: res.sales_Return.returnReceives[0].return_receives_no,
          saleReturnData: res.sales_Return.sales_return_date,
          returnReason: res.sales_Return.return_reason,
          orderDate: moment(res.sales_Return.updated_at),
          customerId: res.sales_Return.customer_id,
          saleOrderId: res.sales_Return.sale_order_id,
          items: this.prepareEditItemsList(
            res.sales_Return.salesReturnDetails,
            res.sales_Return.salesReturnDetails,
            res.sales_Return.returnReceives[0].return_receives_details
          ),
        };

        const leftoverItems = [];

        this.setState({
          packageInfo: {
            ...this.state.packageInfo,
            ...packageInfo,
            items: [...packageInfo.items],
          },
          itemsList: leftoverItems,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
        if (this.props.inModal) {
          this.props.close();
        } else {
          this.setState({
            loading: false,
            notFound: true,
          });
        }
      });
  }

  prepareAddItemsList(arrayList, salesOrder) {
    arrayList = arrayList.filter((list) => list.remaining_quantity > 0);
    return arrayList.map((item, index) => ({
      id: item.item_id,
      dragDropID: GetDateString(),
      salesOrderItemDetailId: item.sales_order_item_detail_id,
      name: item.items.name,
      images: item.items.images,
      sku: item.items.sku,
      unit: item.items.unit,
      inventoryType: item.items.inventory_type,
      //warehouse_idd: salesOrder.sales_return_details[index].warehouse_id ,
      warehouse_idd:
        item.target_warehouse_id !== null
          ? item.target_warehouse_id
          : item.warehouse_id,
      warehouse_id: salesOrder.sales_return_details[index].warehouse_id,
      target_warehouse_id: item.target_warehouse_id,
      original_warehouse_id: item.warehouse_id,
      ordered: item.quantity,
      packed: item.packed,
      stocks:
        item.items.stocks.find(
          (stock) => stock.warehouse_id === salesOrder.warehouse_id
        ) || {},
      received_quantity: item.received_quantity,
      quantity: item.remaining_quantity,
      quantityForErrorChec: item.remaining_quantity,
    }));
  }

  prepareAddAllItemsList(arrayList, salesOrder) {
    return arrayList.map((item, index) => ({
      id: item.item_id,
      salesOrderItemDetailId: item.sales_order_item_detail_id,
      name: item.items.name,
      images: item.items.images,
      sku: item.items.sku,
      unit: item.items.unit,
      inventoryType: item.items.inventory_type,
      //warehouse_idd: salesOrder.sales_return_details[index].warehouse_id ,
      warehouse_idd:
        item.target_warehouse_id !== null
          ? item.target_warehouse_id
          : item.warehouse_id,
      warehouse_id: salesOrder.sales_return_details[index].warehouse_id,
      target_warehouse_id: item.target_warehouse_id,
      original_warehouse_id: item.warehouse_id,
      ordered: item.quantity,
      packed: item.packed,
      stocks:
        item.items.stocks.find(
          (stock) => stock.warehouse_id === salesOrder.warehouse_id
        ) || {},
      received_quantity: item.received_quantity,
      quantity: item.remaining_quantity,
      quantityForErrorChec: item.remaining_quantity,
    }));
  }

  fetchPackageCreateData = async (salesOrderId) => {
    return await restRequest(
      'get',
      `returnreceives/create?sales_return_id=${this.props.salesReturnId}`
    )
      .then((res) => {
        //console.log('res', res)
        warehouseResult = res.warehouse_list;
        //console.log('res', res)
        let arr = [];
        for (let i = 0; i < res.sales_return.sales_return_details.length; i++) {
          arr = this.state.warehouseList;
          arr.push(res.sales_return.sales_return_details[i].warehouse_id);
          this.setState({ warehouseList: arr });
        }

        const packageInfo = {
          ...this.state.packageInfo,
          saleReturnData: res.sales_return.sales_return_date,
          packageDate:
            res?.sales_return?.sales_return_date &&
            moment(res.sales_return.sales_return_date) <
              moment(getOrganizationDate())
              ? moment(getOrganizationDate()).format('YYYY-MM-DD 00:00:00')
              : moment(res.sales_return.sales_return_date),
          packageNo: res.return_receive_no,
          returnReason:
            res.sales_return.return_reason !== 'Other'
              ? res.sales_return.return_reason
              : res.sales_return.return_reason_description,
          orderDate: moment(res.sales_return.order_date),
          customerId: res.sales_return.customer_id,
          saleOrderId: res.sales_return.sale_order_id,
          items: this.prepareAddItemsList(
            res.sales_return.sales_return_details,
            res.sales_return
          ),
          allItems: this.prepareAddAllItemsList(
            res.sales_return.sales_return_details,
            res.sales_return
          ),
        };
        //console.log('packageInfo --- ', packageInfo.items)
        this.setState({
          packageInfo: {
            ...packageInfo,
            items:
              packageInfo.items.length > 0
                ? [...packageInfo.items]
                : [this.emptyItem],
          },
          itemsList: [],
        });
      })
      .catch((error) => {
        //this.props.handleToast(error, 'error')
        checkError(error, this.props.handleToast);
        if (this.props.inModal) {
          this.props.close();
        }
      });
  };

  submitRequestType = () => {
    switch (this.type) {
      case 'edit':
        return 'put';
      case 'add':
      default:
        return 'post';
    }
  };

  submitRequestUrl = () => {
    switch (this.type) {
      case 'edit':
        return `returnreceives/${this.id}`;
      case 'add':
      default:
        return 'returnreceives';
    }
  };

  successMessage = () => {
    if (this.type === 'add') {
      this.props.handleToast('Return Receives created successfully', 'success');
    } else if (this.type === 'edit') {
      this.props.handleToast('Return Receives has been updated', 'success');
    }
  };

  renderPackageForm() {
    const { packageInfo, itemsList, salesOrderList, currency } = this.state;

    const {
      title,
      inModal,
      close,
      onSubmit,
      handleToast,
      history,
      handlePrompt = () => null,
    } = this.props;
    let warehouseListProp = this.state.warehouseList;
    let warehouseIdForNullCase = this.state.genericWarehouseId;

    return (
      <ReturnReceiveEnhancer
        warehouseListRecieve={warehouseListProp}
        genericWarehouseId={warehouseIdForNullCase}
        title={title}
        type={this.type}
        warehouseResult={warehouseResult}
        packageInfo={packageInfo}
        inModal={inModal || null}
        salesOrderList={salesOrderList}
        itemsList={itemsList}
        emptyItem={this.emptyItem}
        currency={currency}
        submitRequestType={this.submitRequestType}
        submitRequestUrl={this.submitRequestUrl}
        successMessage={this.successMessage}
        close={close}
        onSubmit={onSubmit}
        salesReturnId={this.props.salesReturnId || this.props.id}
        history={history}
        handleToast={handleToast}
        handlePrompt={handlePrompt}
      />
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    return this.renderPackageForm();
  }
}

export default withRouter(PackageForm);
