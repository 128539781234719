import React from 'react';
import { NewBillIcon } from '../../../../Assets/Navigation/NavigationIcons';
import { BillForm } from '../../../../Components/Form';
import { FormLayout } from '../../../../Components';
import { getObjFromLS } from '../../../../Helpers';
import { useLocation } from 'react-router-dom';

const AddBill = ({ handleToast, history }) => {
  const location = useLocation();

  const handleRedirection = () => {
    const { search = '' } = location;
    let dataFromLS = getObjFromLS('module');
    if (dataFromLS.moduleName === 'contacts' && search) return '/contacts';
    else return '/bills';
  };

  return (
    <FormLayout
      title=" Create New Bills"
      Icon={NewBillIcon}
      closeUrl={handleRedirection()}
      // closeUrl="/bills"
      iconstyle="general_module_icon"
      layoutBoxClass="uiux-form-redesign"
    >
      <BillForm
        type="add"
        onSubmit={() => history.push('/bills')}
        onClose={() => history.push('/bills')}
        history={history}
        handleToast={handleToast}
      />
    </FormLayout>
  );
};

export default AddBill;
