import React from 'react';
import { ItemForm } from '../../Components/Form';
import FormLayout from '../../Components/Layout/FormLayout';
import { NewItemIcon } from '../../Assets/Navigation/NavigationIcons';

const CloneItem = ({ handleToast, ...props }) => {
  document.title = 'Clone Item';

  return (
    <FormLayout
      title="Items"
      Icon={NewItemIcon}
      subtitle="Clone"
      closeUrl="/items"
      iconstyle="general_module_icon"
      layoutBoxClass="uiux-form-redesign"
    >
      <ItemForm
        type="clone"
        id={props.match.params.id}
        handleToast={handleToast}
      />
    </FormLayout>
  );
};

export default CloneItem;
