import React from 'react';
import moment from 'moment';
import { getDateFormat } from '../index';
import {getStoreUrl} from "../../../Helpers";

const OrderInfo = ({
  customerName = '',
  orderNo = 0,
  dateCreated = 0,
  storeName = '',
  storeUrl = '',
  store = [],
}) => {
  return (
    <>
      <div className="float-left w-100 sales_order_info">
        <div className="w-100">
          <div className="float-left w-50">
            <h3
              className="details-header--title"
              style={{
                color: 'rgb(51, 163, 72)',
                fontWeight: ' 700',
                textTransform: 'uppercase',
                fontSize: '18px',
              }}
            >
              Order # {orderNo}
            </h3>
            <h5 className="customer_name">
              <div className="pointer">
                <u>{customerName}</u>
              </div>
            </h5>
            {/* <div className="details-header--info d-inline">
              <b> Order#</b> {orderNo}
            </div> */}
          </div>
          <div className="float-right w-30">
            <div className="section-details--info-details details_column">
              <div className="details_row">
                <div className="section-details--info-title semi-bold text_transform_lower">
                  Order Date
                </div>
                <div>{moment(dateCreated).format(getDateFormat())}</div>
              </div>
              <div className="details_row">
                <div className="section-details--info-title semi-bold text_transform_lower">
                  Store
                </div>
                <div>
                  <a href={getStoreUrl(store)} target="_blank" rel="noopener noreferrer">
                    {storeName || storeUrl}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OrderInfo;
