import React from 'react'

import './TransactionSummary.css'
import { formatNumber } from "../../../../Helpers/FormatNumber"

const TransactionSummary = (
  {
    total_receivable,
    total_to_be_invoiced,
    total_to_be_packed,
    total_to_be_shipped,
    total_payable,
    total_items_to_be_received,
    total_items_ordered,
    currencySymbol 
  }) => {
  if(total_receivable !== undefined){
    total_receivable = parseFloat(parseFloat(total_receivable).toFixed(2))
    return (
      <div className="contact-transaction-summary">
        <div className="section-details--info-title semi-bold margin-bottom-sm">Receivables</div>
        <div className="semi-bold margin-bottom-sm">{currencySymbol}{total_receivable}</div>
        <div className="section-details--info-title semi-bold margin-bottom-md">Items</div>
        <div className="contact-transaction-summary--overview-section">
          <div className="text-grey">Items to be invoiced</div>
          <div className="semi-bold">{formatNumber(total_to_be_invoiced)}</div>
        </div>

        <div className="contact-transaction-summary--overview-section background-transparent">
          <div className="text-grey">Items to be shipped</div>
          <div className="semi-bold">{formatNumber(total_to_be_shipped)}</div>
        </div>

        <div className="contact-transaction-summary--overview-section">
          <div className="text-grey">Items to be packed</div>
          <div className="semi-bold">{formatNumber(total_to_be_packed)}</div>
        </div>
      </div>
    )
  }
  return (
    <div className="contact-transaction-summary">
      <div className="section-details--info-title semi-bold margin-bottom-sm">Payables</div>
      <div className="semi-bold margin-bottom-sm">{currencySymbol}{total_payable}</div>

      <div className="contact-transaction-summary--overview-section">
        <div className="text-grey">Items to be received</div>
        <div className="semi-bold">{total_items_to_be_received}</div>
      </div>

      <div className="contact-transaction-summary--overview-section">
        <div className="text-grey">Items ordered</div>
        <div className="semi-bold">{total_items_ordered}</div>
      </div>
    </div>
  )
}

export default TransactionSummary
