import React from 'react';

export default () => (
  <svg
    className="salesreturn"
    xmlns="http://www.w3.org/2000/svg"
    width="11.135"
    height="13.754"
    viewBox="0 0 11.135 13.754"
  >
    <g transform="translate(-817.914 -531)">
      <g transform="translate(817.914 531)">
        <path
          d="M3.965,13.828V9.489H2.788A.633.633,0,0,1,2.2,8.9V8.386A1.892,1.892,0,0,1,4.186,6.4h3.6A1.892,1.892,0,0,1,9.776,8.386v4.339h1.177a.633.633,0,0,1,.588.588v.441a2.078,2.078,0,0,1-1.986,1.986h-3.6S3.892,15.814,3.965,13.828Zm1.25.074a.716.716,0,0,0,.588.735V13.9a1.162,1.162,0,0,1,1.177-1.177H8.6V8.386a.737.737,0,0,0-.735-.735H5.216Zm4.339.662a.716.716,0,0,0,.735-.588H7.348a.332.332,0,0,0-.221.221v.368ZM3.965,8.239V7.65a.841.841,0,0,0-.515.588Zm1.986,3.016a.588.588,0,1,1,0-1.177H7.716a.588.588,0,0,1,0,1.177Zm0-1.765a.588.588,0,1,1,0-1.177H7.716a.726.726,0,0,1,.735.588.633.633,0,0,1-.588.588Z"
          transform="translate(-2.2 -1.987)"
        />
        <path
          d="M12.469,8.564a.568.568,0,0,1-.588-.515.633.633,0,0,1,.588-.588H13.5V3.121a.158.158,0,0,0-.147-.147H10.189v.147c.074.221.074.441.221.588a.138.138,0,0,1,0,.221C10.336,4,10.336,4,10.263,4h-.074L7.174,2.312c-.074,0-.074-.074-.074-.147a.257.257,0,0,1,.074-.147L10.116.4h.074c.074,0,.074,0,.147.074a.27.27,0,0,1,.074.221,2.4,2.4,0,0,0-.294.956h3.53a1.181,1.181,0,0,1,.809.368.572.572,0,0,1,.147.588v5a.927.927,0,0,1-.956.956Z"
          transform="translate(-3.496 -0.4)"
        />
        <path d="M2.622,5.43v6.9L4,13.465V11.336H7.128V5.6L5.805,4.873Z" />
        <rect
          fill="#fff;"
          width="4"
          height="1"
          rx="0.5"
          transform="translate(3 6.386)"
        />
        <rect
          fill="#fff;"
          width="4"
          height="1"
          rx="0.5"
          transform="translate(3 8.386)"
        />
      </g>
    </g>
  </svg>
);
