import React from 'react';
import { ButtonIcons } from '../index';

const ButtonType = ({ menu }) => {
  const {
    tooTip = '',
    icon = '',
    label = false,
    handleClick = () => {},
    className = '',
    // labelIcon = '',
    mobileDimension = false,
    mobileLable = '',
  } = menu;

  return (
    <>
      <button
        className={`${
          label ? 'checked-menu--button' : 'checked-menu--button--icon-only'
        } ${className}`}
        onClick={handleClick}
        title={tooTip && tooTip}
      >
        {icon && ButtonIcons[icon]()}
        {!mobileDimension && label && (
          <span className="checked-menu--label">{label && label}</span>
        )}
        {mobileDimension && mobileLable && (
          <span className="mobile-label">{mobileLable}</span>
        )}
      </button>
    </>
  );
};
export default ButtonType;
