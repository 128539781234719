import React from 'react';

import { NewContactIcon } from '../../../../../Assets/Navigation/NavigationIcons';

import Email from '../../../../../Components/Email/Email';
import { getObjFromLS, setObjInLS } from '../../../../../Helpers/LocalStorage';

const ContactStatementEmail = (props) => {
    let dataFromLS = getObjFromLS('module');
    if (dataFromLS && dataFromLS.moduleName === 'contacts') {
        setObjInLS('module', {
            ...dataFromLS,
            currentOuterTab: `statement`,
        });
    }
    return (
        <Email
            {...props}
            Icon={NewContactIcon}
            name="Contacts Statement"
            onCancelURL="/contacts"
            emailAPI={`contacts/statement/${props.match.params.id}/mail/${props.match.params.starting_date}/${props.match.params.ending_date}`}
            fetchDataAPI={`contacts/statement/${props.match.params.id}/mail/${props.match.params.starting_date}/${props.match.params.ending_date}`}
        />
    );
};

export default ContactStatementEmail;
