import React from 'react';

import { NewPackageIcon } from '../../../Assets/Navigation/NavigationIcons';

import Email from '../../../Components/Email/Email';

const PackageEmail = (props) => {
  return (
    <Email
      {...props}
      Icon={NewPackageIcon}
      name="Packages"
      onCancelURL="/packages"
      emailAPI={`packages/${props.match.params.id}/mail`}
      fetchDataAPI={`packages/${props.match.params.id}/mail`}
    />
  );
};

export default PackageEmail;
