const SALUTATIONS = [
  {
    value: 'Mr.',
    label: 'Mr.'
  },
  {
    value: 'Mrs.',
    label: 'Mrs.'
  },
  {
    value: 'Ms.',
    label: 'Ms.'
  },
  {
    value: 'Miss',
    label: 'Miss'
  },
  {
    value: 'Dr.',
    label: 'Dr.'
  }
]

export default SALUTATIONS