import React, { Component } from 'react'

import { TaxIcon } from '../../Assets/Settings/SettingsIcon'

import DeliveryMethodForm from '../../Components/Form/DeliveryForm/DeliveryMethodForm'
import FormLayout from '../../Components/Layout/FormLayout'


class AddDeliveryMethod extends Component {
  componentDidMount() {
    document.title = 'Add Delivery Method'
  }

  renderDeliveryMethodForm() {
    return (
      <DeliveryMethodForm
        title="New Delivery Method"
        type="add"
        handleToast={this.props.handleToast}
      />
    )
  }

  render() {
    return (
      <FormLayout
        title="Delivery Methods"
        Icon={TaxIcon}
        subtitle="Add New"
        closeUrl="/deliverymethods"
      >
        { this.renderDeliveryMethodForm() }
      </FormLayout>
    )
  }
}

export default AddDeliveryMethod