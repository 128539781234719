import React from 'react';
import { UserContext } from './UserContext';

const WithContext = (Component) => {
  return function WrapperComponent(props) {
    return (
      <UserContext.Consumer>
        {(state) => <Component {...props} context={state} />}
      </UserContext.Consumer>
    );
  };
};

export default WithContext;
