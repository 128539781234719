import React, { Component, Fragment } from 'react';
import { MdClose } from '../../../../Common/Icons';
import { restRequest } from '../../../../Helpers/RequestHelper';

import Loader from '../../../../Components/Loader/Loader';
import Table from '../../../../Components/Table/Table';
import CustomModal from '../../../../Components/CustomModal/CustomModal';
import AccessDenied from '../../../../Components/AccessDenied/AccessDenied';

import InviteForm from '../../../../Components/Form/InviteForm/InviteForm';
import WithContext from '../../../../Context/UserContextHOC';
import { getObjFromLS } from '../../../../Helpers/LocalStorage';
// import EditIcon from '../../../../Assets/General/EditIcon'
import InviteIcon from '../../../../Assets/General/InviteIcon';
import CancelInviteIcon from '../../../../Assets/General/CancelInviteIcon';
import { checkError } from '../../../../Helpers/AuthHelper';

let hasEditPermission = false;
let hasDeletePermission = false;
class PendingInvitesTab extends Component {
  state = {
    loading: true,
    invites: [],
    editInviteToggle: false,
    inviteToggle: false,
    inviteId: null,
    showDeleteModal: false,
    deleteInviteId: null,
  };
  dataFromLS = getObjFromLS('role');
  tableRowOptions = [
    {
      label: 'Invite Again',
      onClick: (id) => this.inviteAgain(id),
      disabled: (id) => hasEditPermission,
      icon: InviteIcon,
    },
    {
      label: 'Cancel Invitation',
      onClick: (id) => this.openDeleteModal(id),
      disabled: (id) => hasDeletePermission,
      icon: CancelInviteIcon,
    },
  ];

  componentDidMount() {
    hasEditPermission =
      this.dataFromLS.permissions.InviteEdit === false
        ? !this.dataFromLS.permissions.InviteEdit
        : false;
    hasDeletePermission =
      this.dataFromLS.permissions.InviteDelete === false
        ? !this.dataFromLS.permissions.InviteDelete
        : false;
    this.fetchData();
  }

  fetchData() {
    this.setState({
      loading: true,
    });
    restRequest('get', 'invites')
      .then((res) => {
        this.setState({
          invites: res,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error);
      });
  }

  toggleEditInvite = (id = false) => {
    this.setState((state) => {
      return {
        ...state,
        editInviteToggle: !state.editInviteToggle,
        inviteId: id ? id : state.inviteId,
      };
    });
  };

  toggleInvite = () => {
    this.setState((state) => {
      return {
        ...state,
        inviteToggle: !state.inviteToggle,
      };
    });
  };

  onSubmitInvite = () => {
    this.setState(
      {
        editInviteToggle: false,
        inviteToggle: false,
      },
      () => {
        this.fetchData();
      }
    );
  };

  inviteAgain = (id) => {
    restRequest('post', `invites/${id}`)
      .then(() => {
        this.props.handleToast('Invitation sent successfully', 'success');
      })
      .catch((error) => {
        if (error) {
          checkError(error, this.props.handleToast);
        }
      });
  };

  openDeleteModal = (id) => {
    this.setState({
      showDeleteModal: true,
      deleteInviteId: id,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
    });
  };

  deleteInvite = () => {
    this.closeDeleteModal();
    restRequest('delete', `invites/${this.state.deleteInviteId}`)
      .then((response) => {
        this.props.handleToast('Invitation has been Cancelled.', 'success');
        this.closeDeleteModal();
        this.fetchData();
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  };

  renderDeleteModal = () => {
    return (
      <CustomModal
        type="confirm"
        showModal={this.state.showDeleteModal}
        title="Cancel Invitation"
        onClose={this.closeDeleteModal}
        onSubmit={this.deleteInvite}
      >
        Are you sure you want to cancel the invitation?
      </CustomModal>
    );
  };

  prepareInvitesList(invites) {
    return invites.map((user) => {
      return {
        id: user.id,
        name: user.name,
        email: user.email,
        role: user.role.name,
      };
    });
  }

  checkPermissions() {
    const {
      context: {
        role: { permissions },
      },
      handleToast,
    } = this.props;

    if (this.state.inviteToggle) {
      return (
        <div className="padding--regular">
          <button
            className="new-role-form--close-button"
            onClick={this.toggleInvite}
          >
            <MdClose className="material-icon blue" />
          </button>
          <InviteForm
            handleToast={handleToast}
            toggleInvite={this.toggleInvite}
            onSubmitInvite={this.onSubmitInvite}
            setPrompt={this.props.setPrompt}
            handleConfirmFormInviteForm={this.props.handleConfirmForRoleForm}
          />
        </div>
      );
    }

    if (this.state.editInviteToggle) {
      return (
        <div className="padding--regular">
          <button
            className="new-role-form--close-button"
            onClick={this.toggleEditInvite}
          >
            <MdClose className="material-icon blue" />
          </button>
          <InviteForm
            inviteId={this.state.inviteId}
            handleToast={handleToast}
            toggleInvite={this.toggleEditInvite}
            onSubmitInvite={this.onSubmitInvite}
            setPrompt={this.props.setPrompt}
            handleConfirmFormInviteForm={this.props.handleConfirmForRoleForm}
          />
        </div>
      );
    }

    if (!permissions.superAccess && !permissions.InviteView) {
      return <AccessDenied className="small" type="section" />;
    }

    if (this.state.loading) return <Loader />;
    const tableHeaders = ['Name', 'Email', 'Role', ''];
    const tableData = ['name', 'email', 'role', 'options'];
    const tableListData = this.prepareInvitesList(this.state.invites);
    return (
      <Fragment>
        {this.renderDeleteModal()}
        <div className="package-item-info package_details_table">
          <Table
            className="list-table with-actions"
            list={tableListData}
            tableHeaders={tableHeaders}
            tableData={tableData}
            options={this.tableRowOptions}
          />
        </div>
      </Fragment>
    );
  }

  render() {
    return this.checkPermissions();
  }
}

export default WithContext(PendingInvitesTab);
