import React, { Component } from 'react'
import queryString from 'query-string'

import CurrencyIcon from '../../Assets/General/CurrencyIcon'
import { restRequest } from '../../Helpers/RequestHelper'
import {  setValueInLS} from '../../Helpers/LocalStorage'
import { fetchCurrency } from '../../Helpers/Currencies'

import ListTable from '../../Components/ListTable/ListTable'
import HeaderWrapper from '../../Components/HeaderWrapper/HeaderWrapper'
import EditIcon from '../../Assets/General/EditIcon'

import './Currency.css'
import {checkError} from "../../Helpers/AuthHelper";


class Currency extends Component {
  state = {
    loading: true,
    data: [],
    sortFilter: 'created_at',
    orderBy: 'desc',
    totalRecords:0
  }

  sortOptions = [
    {
      id: 'currency_code',
      type: 'link',
      label: 'Currency Code',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('currency_code') })
    },
    {
      id: 'symbol',
      type: 'link',
      label: 'Symbol',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('symbol') })
    },
    {
      id: 'created_at',
      type: 'link',
      label: 'Created Time',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('created_at') })
    },
  ]

  componentDidMount() {
    setValueInLS('fullscreen',true)
    document.title = 'Currency'
    this.fetchDataWithQueryString()
  }

  componentDidUpdate() {
    this.handleOnUpdateQueryString()
  }

  handleOnUpdateQueryString() {
    const { sort, orderBy } = this.parseQueryString()
    if (sort !== this.state.sortFilter) {
      this.setState({
        sortFilter: sort
      }, () => this.fetchData())
    }
    if (orderBy !== this.state.orderBy) {
      this.setState({
        orderBy: orderBy
      }, () => this.fetchData())
    }
  }

  fetchDataWithQueryString() {
    const { sort } = this.parseQueryString()
    this.setState({
      sortFilter: sort,
    }, () => this.fetchData())
  }

  parseQueryString() {
    const query = queryString.parse(this.props.location.search)
    let filter = ''
    let sort = ''
    switch (query.sort) {
      case 'currency_code':
      case 'symbol':
      case 'created_at':
        sort = query.sort
        break
      default:
        sort = 'created_at'
    }

    let orderBy = ''
    switch (query.orderBy) {
      case 'asc':
      case 'desc':
        orderBy = query.orderBy
        break
      default:
        orderBy = 'desc'
    }

    let page = ''
    let view = ''

    return {
      filter,
      page,
      sort,
      orderBy,
      view
    }
  }

  fetchData = async () => {
    this.setState({ loading: true })

    const baseCurrency = await fetchCurrency()

    await restRequest('get', `currencies?sort_by=${this.state.sortFilter}&order_by=${this.state.orderBy}`).then(res => {
      this.setState({
        data: this.prepareDataForTable(res, baseCurrency),
        totalRecords: (res && res.length) ? res.length : 0,
        loading: false
      })
    }).catch(error=>{
      checkError(error,this.props.handleToast)
      this.setState({ loading: false })
    })
  }

  renderBaseCurrencyLabel = (name, code, isBaseCurrency) => {
    return (
      <span className="current-currency">
        {name} <span className="semi-bold"><i>{code}</i></span>
        {
          isBaseCurrency &&
          <span className="current-currency--current">
            BASE CURRENCY
          </span>
        }
      </span>
    )
  }

  prepareDataForTable(data, baseCurrency) {
    return data.map(currency => ({
      'id': currency.id,
      'Currency Code': {
        id: 'currency_code',
        sortable: true,
        getValue: () => this.renderBaseCurrencyLabel(currency.name, currency.currency_code, currency.id === baseCurrency.id) 
      },
      'Symbol': {
        id: 'symbol',
        sortable: true,
        getValue: function () { return currency[this.id] }
      },
      'Exchange Rate': {
        id: 'exchange_rate',
        alignRight: true,
        getValue: function () { return currency[this.id] }
      },
    }))
  }

  prepareRowOptions() {
    return [
      {
        label: 'Edit',
        //type: 'link',
        onClick: id => `currencies/update/${id}`,
        disabled: () => true,
        icon: EditIcon
      },
      /*{
        label: 'Delete',
        onClick: this.openDeleteModal
      },*/
    ]
  }

  renderTable() {
    return (
      <ListTable
        className="system_settings"
        data={this.state.data}
        rowOptions={this.prepareRowOptions()}
        generateRoute={this.generateRoute}
        orderBy={this.state.orderBy}
        sortFilter={this.state.sortFilter}
        loading={this.state.loading}
        hasCheckbox={false}
        totalRecords={this.state.totalRecords}

      />
    )
  }

  render() {
    return this.renderTable()
  }
}


export default HeaderWrapper(
  Currency,
  {
    name: 'Currencies',
    Icon: CurrencyIcon,
    baseUrl: 'currencies',
    redirectUrl: '/currencies',
    //buttonUrl: '/currencies/add',
    //buttonLabel: 'New Currency',
    routeName: 'currencies',
    showNewButton:false,
    pagination: false,
    permissionName:'currency'
  }
)