import timezone from 'moment-timezone';
import moment from 'moment';
// import getDateFormat from "./GetDateFormat";

export function getOrganizationTimeZone() {
  return (
    localStorage.getItem('currentOrganization') !== null &&
    JSON.parse(localStorage.getItem('currentOrganization')).time_zone
  );
}

export function getOrganizationDate() {
  return timezone
    .tz(new Date(), getOrganizationTimeZone())
    .format('YYYY-MM-DD HH:mm:ss');
}

export function getDashboardUpdatesTime(time) {
  return moment(time, 'hh:mm:ss').format('hh:mm A');
}

export function getTimeWithSeconds(time) {
  return moment(time).format('hh:mm:ss A');
}

export function getTimeWithoutSeconds(time) {
  return moment(time).format('hh:mm A');
}

export function getDateWithFullYear(time) {
  return moment(time).format(`MMM DD, YYYY`);
}

export function getTimeinMinutes(time) {
  let convertTime = moment.duration(time).asMinutes();
  convertTime = Math.ceil(convertTime);
  return (convertTime === 1) ? convertTime+' Minute' : convertTime+' Minutes';
}