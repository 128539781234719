import React from 'react';
import {
  UserIcon,
  LocationIcon,
  PhoneIcon,
  CompanyOffice,
} from '../../../../Assets/Contact/OverviewContact';
import { getAddress } from '../../../../Pages/Organization/ChooseOrganization';

export default ({ billingAddress, customer }) => (
  <div className="float-left w-100">
    <div className="invoices_desc invoice-desc-main">
      <div
        className="float-left  invoice_to"
        // style={{
        //     backgroundColor: '#f9f9f9',
        //     paddingLeft: '25px',
        // }}
      >
        <div className="float-left w-100">
          <div className="bill_2 float-left w-100 border_bottom_custom">
            Invoice To
          </div>
          <div className="invoices_desc_left_child float-left w-100">
            <div className="Name float-left w-100 color_custom text inv_billto">
              <div className="icon">
                <UserIcon customWidth="20" customHeight="15" />
              </div>
              <span>{billingAddress.attention}</span>
            </div>
            {customer.company_name && (
              <div className="Name float-left w-100 color_custom text inv_billto">
                <div className="icon">
                  <CompanyOffice customWidth="20" customHeight="15" />
                </div>
                <span>{customer.company_name}</span>
              </div>
            )}
            <div className="country float-left w-100 color_custom">
              <>
                <div className="icon">
                  <LocationIcon customWidth="20" customHeight="15" />
                </div>
                <div
                  className="desc"
                  style={{
                    float: 'left',
                    width: '90%',
                  }}
                >
                  {getAddress(billingAddress.street) && (
                    <span>{getAddress(billingAddress.street)}</span>
                  )}
                  <span className="address_sec">
                    {getAddress(billingAddress.city) &&
                      getAddress(billingAddress.city) + ', '}

                    {getAddress(billingAddress.state) &&
                      getAddress(billingAddress.state) + ', '}
                    {getAddress(billingAddress.zip_code) &&
                      getAddress(billingAddress.zip_code) + ', '}
                    {getAddress(billingAddress.country_name)
                      ? getAddress(billingAddress.country_name)
                      : 'United States'}
                  </span>
                </div>
              </>
            </div>

            <div>
              {billingAddress.phone && (
                <div className="contact float-left w-100 color_custom">
                  <div className="icon">
                    {' '}
                    <PhoneIcon customWidth="20" customHeight="15" />
                  </div>
                  <span
                  //  style={{ paddingLeft: '15px' }}
                  >
                    {' '}
                    {billingAddress.phone ? billingAddress.phone : ''}{' '}
                  </span>{' '}
                  {/*| Cell: +1 202 555 0193*/}{' '}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="float-left w-60"></div> */}
    </div>
  </div>
);
