import React from 'react';
import { MdAdd } from '../../Common/Icons';
const AddItem = ({
  onClick,
  label = 'Add Another Item',
  inline = true,
  disabled = false,
  visible = true,
  itemsId,
  className = ''
}) => {
  if (visible)
    return (
      <button
        type="button"
        id={itemsId}
        className={`button blue margin-top-sm margin-right-md ${className} ${inline ? 'inline' : ''
          }`}
        onClick={() => onClick()}
        disabled={disabled}
      >
        <MdAdd /> {label}
      </button>
    );
  return null;
};

export default AddItem;
