import React from 'react'

import AccessDeniedLogo from './accessdeniedlogo.svg'
import './AccessDenied.css'

export default ({
  className = 'full',
  type = 'page',
}) => {
  return(
    <div className={`access-denied ${className}`}>
      <img className="access-denied-style" src={AccessDeniedLogo} alt="access-denied" />
      <h1>Access Denied!</h1>
      <h4>
        Seems like you are not allowed to access this {type}.
      </h4>
    </div>
  )
}