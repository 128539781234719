import React, { Fragment } from 'react';
import { MdMoveToInbox } from '../../Common/Icons';
import ShipNowIcon from '../../Assets/General/ShipNowIcon';
import AddRetrunReceive from '../../Assets/General/AddRetrunReceive';
import {
  InvoiceIcon,
  PackageIcon,
  EmailedIcon,
  CreditNoteIcon,
  PeopleIcon,
  SalesOrderIcon,
  BillIcon,
  PurchaseOrderIcon,
  PaymentIcon,
  ItemIcon,
} from '../../Assets/Activity/Activity';
import RecordPaymentIcon from '../../Assets/General/RecordPaymentIcon';
import getDate from '../../Helpers/Date/GetDate';
import { getDashboardUpdatesTime } from '../../Helpers/Date/OrganizationDate';
import './TimelineCard.css';
import {
  SalesReturnIcon,
  TimeLineDashboardIcon,
} from '../../Assets/Navigation/NavigationIcons';
import RefundIcon from '../../Assets/General/RefundIcon';
const TimelineCard = ({
  name,
  description,
  date,
  icon,
  time,
  subject,
  fromDashboard,
  type,
  noLine = false,
}) => {
  function getIcon(subject, type) {
    // add dashboard icon and add new case 'dashboard'
    switch (subject) {
      case 'contact':
        return <PeopleIcon customWidth="13" customHeight="13" />;
      case 'invoice':
        return type === 'type' ? (
          <EmailedIcon customWidth="13" customHeight="13" />
        ) : (
          <InvoiceIcon customWidth="13" customHeight="13" />
        );
      case 'package':
        return <PackageIcon customWidth="13" customHeight="13" />;
      case 'sales_order':
        return <SalesOrderIcon customWidth="13" customHeight="13" />;
      case 'item':
        return <ItemIcon customWidth="13" customHeight="13" />;
      case 'bill':
      case 'credit_utilize':
        return <BillIcon />;
      case 'purchase_order':
        return <PurchaseOrderIcon />;
      case 'receive':
        return <MdMoveToInbox />;
      case 'bill payment record':
        // case 'invoice_payment':
        return <PaymentIcon customWidth="13" customHeight="13" />;
      case 'Shipment':
        return <ShipNowIcon />;
      case 'sales_return':
        return <SalesReturnIcon />;
      case 'return_receives':
        return <AddRetrunReceive />;
      case 'credit_notes':
        return <CreditNoteIcon customWidth="13" customHeight="13" />;
      case 'dashboard':
        return <TimeLineDashboardIcon />;
      case 'payment_refund':
      case 'credit_refund':
        return <RefundIcon />;
      case 'advance_payment':
      case 'invoice_payment':
        return <RecordPaymentIcon />;
      // add credit refund icon here
      default:
        break;
    }
  }
  return (
    // <div className={`timeline-card ${!noLine && "with-line"}`}>
    //   <div className={`timeline-card-body `}>
    //     <div className="timeline-date">{getDate(date)} {time}</div>
    //     <div className="timeline-card-content">
    //       {getIcon(subject)}
    //       <div>
    //         <div className="timeline-card-description">{description}</div>
    //         <div className="timeline-card-footer">By {name}</div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    fromDashboard ? (
      <div className="activity_parent float-left w-100">
        <div className="float-left w-100 activity_new">
          <div className="left">
            <div className="date">{getDate(date)}</div>
            <small>{getDashboardUpdatesTime(time)}</small>
          </div>
          <div className="right">
            {description.map((data) => {
              return (
                <Fragment key={data.id}>
                  <div className="desc">{data.title}</div>
                  <div
                    className="float-left w-100"
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  />
                  <div className="border_structure">
                    <div className="icon">{getIcon(icon)}</div>
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    ) : (
      <div className="activity_parent float-left w-100">
        <div className="float-left w-100 activity_new items_activity_set">
          <div className="left">
            <div className="date">{getDate(date)}</div>
            <small>{time}</small>
          </div>
          <div className="border_structure">
            <div className="icon">{getIcon(subject, type)}</div>
          </div>
          <div className="right">
            <div className="desc">{description}</div>
            <small>By {name}</small>
          </div>
        </div>
      </div>
    )
  );

  // return (
  //   <div className={`timeline-card ${!noLine && "with-line"}`}>
  //     <div className={`timeline-card-body `}>
  //       <div className="timeline-date">{getDate(date)} {time}</div>
  //       <div className="timeline-card-content">
  //         {getIcon(subject)}
  //         <div>
  //           <div className="timeline-card-description">{description}</div>
  //           <div className="timeline-card-footer">By {name}</div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )
};

export default TimelineCard;
