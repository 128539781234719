import React from 'react'

const CellIcon = ({ customWidth,customHeight, giveFloat}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 35.867 53.801"
         width={`${customWidth ? customWidth : '48'}`} height={`${customHeight ? customHeight : '48'}`}
         style={{ float: `${ giveFloat? 'left' :''}`,fill:'#8e8e8e' }}
    >
      <path className="a"
            d="M10.725-12A6.463,6.463,0,0,0,6-10,6.463,6.463,0,0,0,4-5.275V35.076A6.463,6.463,0,0,0,6,39.8a6.463,6.463,0,0,0,4.729,2H33.142a6.819,6.819,0,0,0,6.725-6.725V-5.275a6.463,6.463,0,0,0-2-4.729,6.463,6.463,0,0,0-4.729-2Zm0,4.483H33.142a2.154,2.154,0,0,1,1.576.666,2.154,2.154,0,0,1,.666,1.576V35.076a2.273,2.273,0,0,1-2.242,2.242H10.725a2.273,2.273,0,0,1-2.242-2.242V-5.275a2.154,2.154,0,0,1,.666-1.576A2.154,2.154,0,0,1,10.725-7.517ZM21.934,30.593a2.273,2.273,0,1,0,1.576.666A2.154,2.154,0,0,0,21.934,30.593Z"
            transform="translate(-4 12)"/>
    </svg>
  )
}

const UserIcon = ({ customWidth,customHeight, giveFloat}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 44.834 49.318"
         width={`${customWidth ? customWidth : '48'}`} height={`${customHeight ? customHeight : '48'}`}
         style={{ float: `${ giveFloat? 'left' :''}`,fill:'#8e8e8e' }}
    >
      <path className="a" d="M25.417-11.5a15.38,15.38,0,0,0-7.881,2.1,15.57,15.57,0,0,0-5.709,5.709,15.38,15.38,0,0,0-2.1,7.881,15.365,15.365,0,0,0,1.856,7.426,15.618,15.618,0,0,0,5.079,5.6,23.4,23.4,0,0,0-7.11,4.834,21.713,21.713,0,0,0-4.8,7.075A22.039,22.039,0,0,0,3,37.818H7.483a17.4,17.4,0,0,1,2.417-9A18.149,18.149,0,0,1,16.415,22.3a17.4,17.4,0,0,1,9-2.417,17.4,17.4,0,0,1,9,2.417,18.149,18.149,0,0,1,6.515,6.515,17.4,17.4,0,0,1,2.417,9h4.483a22.039,22.039,0,0,0-1.751-8.687,21.713,21.713,0,0,0-4.8-7.075,23.4,23.4,0,0,0-7.11-4.834,15.618,15.618,0,0,0,5.079-5.6,15.365,15.365,0,0,0,1.856-7.426,15.38,15.38,0,0,0-2.1-7.881A15.57,15.57,0,0,0,33.3-9.4,15.38,15.38,0,0,0,25.417-11.5Zm0,4.483A10.907,10.907,0,0,1,31.056-5.51,11.3,11.3,0,0,1,35.12-1.447a10.907,10.907,0,0,1,1.506,5.639A10.907,10.907,0,0,1,35.12,9.831a11.3,11.3,0,0,1-4.063,4.063,11.31,11.31,0,0,1-11.279,0,11.3,11.3,0,0,1-4.063-4.063,10.907,10.907,0,0,1-1.506-5.639,10.907,10.907,0,0,1,1.506-5.639A11.3,11.3,0,0,1,19.778-5.51,10.907,10.907,0,0,1,25.417-7.017Z" transform="translate(-3 11.5)"/></svg>
  )
}

const LocationIcon = ({ customWidth,customHeight, giveFloat}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 40.351 59.966"
         width={`${customWidth ? customWidth : '48'}`} height={`${customHeight ? customHeight : '48'}`}
         style={{ float: `${ giveFloat? 'left' :''}`,fill:'#8e8e8e' }}
    >
      <path className="a" d="M23.675-12.5A19.845,19.845,0,0,0,13.518-9.768,20.929,20.929,0,0,0,6.232-2.482,19.845,19.845,0,0,0,3.5,7.675a18.279,18.279,0,0,0,.841,5.044,46.473,46.473,0,0,0,2.172,5.674,126.11,126.11,0,0,0,6.235,12.049q2.8,4.9,6.235,10.088,1.751,2.662,2.872,4.273l1.821,2.662,4.694-6.935Q31.8,35.347,34.6,30.443a126.111,126.111,0,0,0,6.235-12.049,46.473,46.473,0,0,0,2.172-5.674,18.279,18.279,0,0,0,.841-5.044A19.845,19.845,0,0,0,41.119-2.482a20.929,20.929,0,0,0-7.286-7.286A19.845,19.845,0,0,0,23.675-12.5Zm0,4.483a15.38,15.38,0,0,1,7.881,2.1A15.57,15.57,0,0,1,37.266-.206a15.38,15.38,0,0,1,2.1,7.881,14.56,14.56,0,0,1-.7,3.748,42.756,42.756,0,0,1-1.891,5.149A122.647,122.647,0,0,1,30.681,28.2q-3.433,5.885-6.865,11.068l-.14.14-.14-.14Q20.1,34.086,16.67,28.2a122.644,122.644,0,0,1-6.095-11.629,42.756,42.756,0,0,1-1.891-5.149,14.56,14.56,0,0,1-.7-3.748,15.38,15.38,0,0,1,2.1-7.881,15.57,15.57,0,0,1,5.709-5.709A15.38,15.38,0,0,1,23.675-8.017Zm0,11.209a4.426,4.426,0,0,0-4.483,4.483,4.426,4.426,0,0,0,4.483,4.483,4.426,4.426,0,0,0,4.483-4.483,4.426,4.426,0,0,0-4.483-4.483Z" transform="translate(-3.5 12.5)"/>
    </svg>
  )
}

const PhoneIcon = ({ customWidth,customHeight, giveFloat}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 58.442 58.512"
         width={`${customWidth ? customWidth : '48'}`} height={`${customHeight ? customHeight : '48'}`}
         style={{ float: `${ giveFloat? 'left' :''}`,fill:'#8e8e8e' }}
    >
      <path className="a" d="M45.725-12.471a6.5,6.5,0,0,0-2.172.56v-.07l-.14.07A69.15,69.15,0,0,0,18.054,4.272,73.1,73.1,0,0,0,1.871,29.7v.07a7.668,7.668,0,0,0-.28,4.1,7.926,7.926,0,0,0,1.821,3.678l.07.07v.07l7.005,6.935a4.71,4.71,0,0,0,3.468,1.4,4.71,4.71,0,0,0,3.468-1.4L26.6,35.446a5.079,5.079,0,0,0,0-7.005l-4.413-4.413a36.085,36.085,0,0,1,6.3-9.212A28.118,28.118,0,0,1,37.6,8.545l4.624,4.624.21.14A5.861,5.861,0,0,0,45.97,14.36a4.529,4.529,0,0,0,3.257-1.611l.07.07.14-.21,9.037-8.967a4.752,4.752,0,0,0,1.4-3.5,4.752,4.752,0,0,0-1.4-3.5L51.539-10.3a7.467,7.467,0,0,0-3.713-2.032A8.229,8.229,0,0,0,45.725-12.471Zm.35,4.483a2.822,2.822,0,0,1,2.242.841L55.462-.071q.14.14.14.175t-.14.175L45.935,9.806l-.07.07h-.14a1.9,1.9,0,0,1-.63-.28h-.21l-6.3-6.375-1.331.49-1.962.841a41.947,41.947,0,0,0-4.694,2.662A34.8,34.8,0,0,0,25.2,11.7a40.852,40.852,0,0,0-5.814,7.916,40.643,40.643,0,0,0-2.032,3.923l-.56,1.4,1.051,1.121L23.588,31.8q.14.14.14.175t-.14.175l-9.457,9.457q-.14.14-.175.14t-.175-.14L6.775,34.675l-.07-.14a3.263,3.263,0,0,1-.63-3.222v-.07a68.867,68.867,0,0,1,15.2-23.818A64.646,64.646,0,0,1,34.657-2.8a59.466,59.466,0,0,1,10.438-4.9l.07-.07h.07A1.865,1.865,0,0,1,46.075-7.987Z" transform="translate(-1.434 12.488)"/>
    </svg>
  )
}

const FaxIcon = ({ customWidth,customHeight, giveFloat}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="5 -7 53.801 60.043"
         width={`${customWidth ? customWidth : '48'}`} height={`${customHeight ? customHeight : '48'}`}
         style={{ float: `${ giveFloat? 'left' :''}`,fill:'#8e8e8e' }}
    >
      <path className="a" d="M19.934-11.5V-.291H15.45V-4.775H2V35.576H6.483v2.242a6.463,6.463,0,0,0,2,4.729,6.6,6.6,0,0,0,9.457,0,6.463,6.463,0,0,0,2-4.729V35.576H55.8V-.291H46.834V-11.5Zm4.483,4.483H42.351V4.192H24.417ZM6.483-.291h4.483V31.093H6.483ZM15.45,4.192h4.483V8.675h26.9V4.192h4.483v26.9H15.45Zm6.725,8.967v4.483h4.483V13.159Zm8.967,0v4.483h4.483V13.159Zm8.967,0v4.483h4.483V13.159ZM22.175,22.126v4.483h4.483V22.126Zm8.967,0v4.483h4.483V22.126Zm8.967,0v4.483h4.483V22.126ZM10.967,35.576H15.45v2.242a2.229,2.229,0,0,1-3.818,1.576,2.154,2.154,0,0,1-.666-1.576Z" transform="translate(-2 11.5)"/>
    </svg>
  )
}

const ContactCard = ({ customWidth,customHeight, giveFloat}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="5 -7 53.801 60.043"
         width={`${customWidth ? customWidth : '48'}`} height={`${customHeight ? customHeight : '48'}`}
         style={{ float: `${ giveFloat? 'left' :''}`,fill:'#8e8e8e' }}
    >
      <path d="M8.344-61.187V-5.562H80.656V-61.187Zm5.563,5.563H75.094v44.5H65.62a2.7,2.7,0,0,1-.174-.608,1.2,1.2,0,0,0-.261-.608,5.414,5.414,0,0,0-4-1.564,5.414,5.414,0,0,0-4,1.564,1.2,1.2,0,0,0-.261.608,2.7,2.7,0,0,1-.174.608H32.245a2.694,2.694,0,0,1-.174-.608,1.2,1.2,0,0,0-.261-.608,5.414,5.414,0,0,0-4-1.564,5.414,5.414,0,0,0-4,1.564,1.2,1.2,0,0,0-.261.608,2.7,2.7,0,0,1-.174.608H13.906Zm19.469,5.563a10.562,10.562,0,0,0-5.562,1.521A11.732,11.732,0,0,0,23.771-44.5a10.562,10.562,0,0,0-1.521,5.563,10.429,10.429,0,0,0,.913,4.3,11.949,11.949,0,0,0,2.477,3.607,13.647,13.647,0,0,0-4.52,5,13.7,13.7,0,0,0-1.651,6.562h5.563a8.046,8.046,0,0,1,2.434-5.91,8.046,8.046,0,0,1,5.91-2.434,8.046,8.046,0,0,1,5.91,2.434,8.046,8.046,0,0,1,2.434,5.91h5.563a13.7,13.7,0,0,0-1.651-6.562,13.648,13.648,0,0,0-4.52-5,11.949,11.949,0,0,0,2.477-3.607,10.429,10.429,0,0,0,.913-4.3A10.562,10.562,0,0,0,42.979-44.5a11.732,11.732,0,0,0-4.042-4.042A10.562,10.562,0,0,0,33.375-50.062Zm0,5.563a5.375,5.375,0,0,1,3.955,1.608,5.375,5.375,0,0,1,1.608,3.955,5.375,5.375,0,0,1-1.608,3.955,5.375,5.375,0,0,1-3.955,1.608,5.375,5.375,0,0,1-3.955-1.608,5.375,5.375,0,0,1-1.608-3.955,5.375,5.375,0,0,1,1.608-3.955A5.375,5.375,0,0,1,33.375-44.5Zm19.469,2.781v5.563H69.531v-5.562Zm0,11.125v5.563H69.531v-5.562Z" transform="translate(-8.344 61.188)" fill="#8e8e8e"/>
    </svg>
  )
}

const CompanyOffice = ({ customWidth,customHeight, giveFloat}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 32 32"
         width={`${customWidth ? customWidth : '48'}`} height={`${customHeight ? customHeight : '48'}`}
         style={{ float: `${ giveFloat? 'left' :''}`,fill:'#8e8e8e' }}
    >
      <title>BUSINESS</title>
      <g id="_16.OFFICE" data-name="16.OFFICE">
        <path d="M29,7.57H23.5V3a1,1,0,0,0-1-1H9.5a1,1,0,0,0-1,1V7.57H3a1,1,0,0,0-1,1V29a1,1,0,0,0,1,1H29a1,1,0,0,0,1-1V8.57A1,1,0,0,0,29,7.57Zm-1,2v1.77a1,1,0,0,0-.86-.52H24.36a1,1,0,0,0-.86.52V9.57ZM23.5,21.13a1,1,0,0,0,.86.52h2.79a1,1,0,0,0,.86-.52v2.75a1,1,0,0,0-.86-.52H24.36a1,1,0,0,0-.86.52Zm3.64-1.48H24.36a1,1,0,0,0-.86.52V16.48a1,1,0,0,0,.86.52h2.79a1,1,0,0,0,.86-.52v3.68A1,1,0,0,0,27.15,19.64Zm0-4.64H24.36a1,1,0,0,0-.86.52V12.3a1,1,0,0,0,.86.52h2.79A1,1,0,0,0,28,12.3v3.21A1,1,0,0,0,27.15,15ZM8.5,15.51A1,1,0,0,0,7.64,15H4.86a1,1,0,0,0-.86.52V12.3a1,1,0,0,0,.86.52H7.64a1,1,0,0,0,.86-.52ZM4.86,17H7.64a1,1,0,0,0,.86-.52v3.68a1,1,0,0,0-.86-.52H4.86a1,1,0,0,0-.86.52V16.48A1,1,0,0,0,4.86,17Zm0,4.64H7.64a1,1,0,0,0,.86-.52v2.75a1,1,0,0,0-.86-.52H4.86a1,1,0,0,0-.86.52V21.13A1,1,0,0,0,4.86,21.64ZM8.5,9.57v1.77a1,1,0,0,0-.86-.52H4.86a1,1,0,0,0-.86.52V9.57ZM4,24.84a1,1,0,0,0,.86.52H7.64a1,1,0,0,0,.86-.52V28H4ZM10.5,8.57V4h11V28H18.86V24.36a1,1,0,0,0-1-1H14.14a1,1,0,0,0-1,1V28H10.5ZM15.14,28V25.36h1.71V28Zm8.36,0V24.84a1,1,0,0,0,.86.52h2.79a1,1,0,0,0,.86-.52V28Z"/>
        <path d="M12.29,12.82h7.43a1,1,0,0,0,0-2H12.29a1,1,0,0,0,0,2Z"/>
        <path d="M12.29,17h7.43a1,1,0,0,0,0-2H12.29a1,1,0,0,0,0,2Z"/>
        <path d="M19.72,21.64a1,1,0,0,0,0-2H12.29a1,1,0,0,0,0,2Z"/>
        <path d="M12.29,8.64h7.43a1,1,0,1,0,0-2H12.29a1,1,0,1,0,0,2Z"/>
      </g>
    </svg>
  )
}

const CurrencyIcon = ({ customWidth,customHeight, giveFloat}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 53.801 56.043"
         width={`${customWidth ? customWidth : '48'}`} height={`${customHeight ? customHeight : '48'}`}
         style={{ float: `${ giveFloat? 'left' :''}`,fill:'#8e8e8e' }}
    >
      <path className="a" d="M1-10.5V27.609H63.768V-10.5ZM9.967-6.017H54.8a4.332,4.332,0,0,0,1.3,3.187,4.332,4.332,0,0,0,3.187,1.3V18.642A4.426,4.426,0,0,0,54.8,23.126H9.967a4.426,4.426,0,0,0-4.483-4.483V-1.533a4.332,4.332,0,0,0,3.187-1.3A4.332,4.332,0,0,0,9.967-6.017ZM32.384-1.533A8.028,8.028,0,0,0,27.866-.167a9.569,9.569,0,0,0-3.257,3.678,10.851,10.851,0,0,0-1.191,5.044A10.851,10.851,0,0,0,24.608,13.6a9.569,9.569,0,0,0,3.257,3.678,8.156,8.156,0,0,0,9.037,0A9.569,9.569,0,0,0,40.16,13.6a10.851,10.851,0,0,0,1.191-5.044A10.851,10.851,0,0,0,40.16,3.511,9.569,9.569,0,0,0,36.9-.167,8.028,8.028,0,0,0,32.384-1.533Zm0,4.483A3.954,3.954,0,0,1,35.536,4.6a6.1,6.1,0,0,1,1.331,3.958,6.1,6.1,0,0,1-1.331,3.958,3.841,3.841,0,0,1-6.3,0A6.1,6.1,0,0,1,27.9,8.555,6.1,6.1,0,0,1,29.232,4.6,3.954,3.954,0,0,1,32.384,2.95ZM15.571,5.192a3.349,3.349,0,0,0-3.363,3.363,3.349,3.349,0,0,0,3.363,3.363,3.349,3.349,0,0,0,3.363-3.363,3.349,3.349,0,0,0-3.363-3.363Zm33.626,0a3.349,3.349,0,0,0-3.363,3.363,3.363,3.363,0,1,0,6.725,0A3.349,3.349,0,0,0,49.2,5.192Z" transform="translate(-1 10.5)"/>
    </svg>
  )
}

export { CellIcon, UserIcon, LocationIcon, PhoneIcon, FaxIcon, ContactCard, CompanyOffice, CurrencyIcon }