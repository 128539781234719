import React, { Component } from 'react';
import './CountAndProgress.css';
export default class CountAndProgress extends Component {
  constructor(props) {
    super();
  }
  render() {
    const {
      title,
      count,
      color,
      amount,
      percentage,
      dueFlag,
      currency = '$',
    } = this.props;
    return (
      <div className="count_progress_circle_main">
        {dueFlag ? (
          <p className="colored_font green_color_font">Due</p>
        ) : (
          <p className="colored_font red_color_font">Overdue</p>
        )}
        <div className="circle_main_center float-left w-100 text-center">
          <div className="count_progress_circle">
            <p className="semi-bold">{count}</p>
            <p className="semi-bold">{title}</p>
          </div>
        </div>

        <div
          className="count_progress_circle_amount"
          style={{ fontSize: '18px' }}
        >
          {currency}{amount}
        </div>

        <div className="bar-container mt_20px custom_width">
          <div
            className="bar"
            style={{
              backgroundColor: color,
              borderRadius: '2.7px',
              opacity: '0.8',
              width: `${percentage}%`,
            }}
          ></div>
          <p className="text_align_right progress_percentage">{percentage}%</p>
        </div>
      </div>
    );
  }
}
