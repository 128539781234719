import React, { Component } from 'react';
import './DashboardTopCustomers.css';
import { Link } from 'react-router-dom';
import NoDashboardDataFound from '../../../Components/NotFound/NoDashboardDataFound';
import Slider from 'react-slick';
import { imagePathS3, restRequest } from '../../../Helpers/RequestHelper';
import Loader from '../../../Components/Loader/Loader';
import { getObjFromLS, setObjInLS } from '../../../Helpers/LocalStorage';
import { formatNumber } from '../../../Helpers/FormatNumber';
import AccessDenied from '../../../Components/AccessDenied/AccessDenied';
import DataError from '../../../Components/DashboardDataError/DB_DataError';
import { checkError } from '../../../Helpers/AuthHelper';
import {setDashboardAmount} from "../../../Helpers/setAmount";
const settings = {
  dots: true,
  infinite: true,
  speed: 800,
  slidesToScroll: 1,
  slidesToShow: 1,
  pauseOnHover: true,
  autoplay: true,
  autoplaySpeed: 10000,
};
export default class DashboardTopCustomers extends Component {
  constructor(props) {
    super();
    this.state = {
      data: [],
      loading: true,
      accessDenied: false,
      accessError: false
    };
  }

  componentDidMount() {
    this.fetchData();
    document
      .getElementById('click_nav_arrow_button')
      .addEventListener('click', this.myFunction);

    window.addEventListener('resize', this.myFunction);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.myFunction);
    window.removeEventListener('resize', this.myFunction);
  }

  myFunction = () => {
    this.fetchData(
      this.props.currentDateRange.top_customers,
      // this.props.filter,
      this.props.startData,
      this.props.endDate
    );
  };

  //   shouldComponentUpdate(nextProps) {
  //     return (this.props !== nextProps) ? false : true;
  // }
  dashboard_preferences = JSON.parse(localStorage.getItem("dashboardPreferences"))

  // fetchData(currentDateRange) {
  fetchData(currentDateRange = this.dashboard_preferences.top_customers, startData, endDate) {
    this.setState({ loading: true });
    restRequest(
      'get',
      `dashboard/topSellingCustomers/${currentDateRange === 'custom'
        ? `custom?starting_date=${startData}&ending_date=${endDate}`
        : currentDateRange
          ? currentDateRange
          : this.props.currentDateRange
      }`
    )
      .then((res) => {
        this.setState({ data: res, loading: false });
      })
      .catch((err) => {
        checkError(err);
        if (err.response.status === 403) {
          this.setState({ accessDenied: true, loading: false });
        } else {
          this.setState({ accessError: true, loading: false });
        }
      });
  }

  // componentWillReceiveProps(nextProps) {
  //   this.fetchData(nextProps.currentDateRange)
  // }
  componentWillReceiveProps(nextProps) {
    // nextProps.currentDateRange !== this.props.currentDateRange &&  this.fetchData(nextProps.currentDateRange)
    (nextProps.currentDateRange !== this.props.currentDateRange ||
      nextProps.startData !== this.props.startData ||
      nextProps.endDate !== this.props.endDate) &&
      this.fetchData(
        nextProps.currentDateRange.top_customers,
        nextProps.startData,
        nextProps.endDate
      );
  }
  render() {
    // const settings = {
    //   dots: true,
    //   infinite: true,
    //   speed: 800,
    //   slidesToScroll: 1,
    //   slidesToShow: 1,
    //   pauseOnHover: true,
    //   autoplay: true,
    //   autoplaySpeed: 10000,
    // };
    let datafromLS = getObjFromLS('module');
    if (this.state.loading) return <Loader />;
    if (this.state.accessDenied)
      return <AccessDenied className="small dashboard " type="Top Customers" />;
    if (this.state.accessError)
      return <DataError className="small dashboard " type="Top Customers" />;
    if (this.state.data.customers.length === 0)
      return <NoDashboardDataFound message="No top customers at the moment" />;
    return (
      <div className="slick-slider-wrapper">
        <Slider {...settings}>
          {this.state.data.customers.map((singleData, index) => (
            <Link
              key={index}
              to={`/contacts/${singleData.customer_id}`}
              onClick={() => {
                setObjInLS('module', {
                  ...datafromLS,
                  id: singleData.customer_id,
                  urlPath: '/contacts',
                  moduleName: 'contacts',
                });
              }}
            >
              <div className="slider-widget">
                <div className="image_section">
                  <img
                    className="dashoard_slider_image img-circle"
                    src={imagePathS3(singleData.photo)}
                    alt="dummy Img"
                  />
                </div>
                <div className="slider-widget-right">
                  <h4 className="semi-bold line_clamp">
                    {singleData.display_name}
                  </h4>
                  <p className="green_color_font mb_3px">Customer</p>
                  <div className="company-name-set clearfix mb_3px">
                    <span className="top-cus-left">Company Name </span>
                    <span className="word_break line-clamp top-cus-right">
                      {singleData.company_name}
                    </span>
                  </div>
                  <div className="license-main clearfix mb_3px">
                    <span className="top-cus-left">License No. </span>
                    <span className="word_break top-cus-right">
                      {singleData.license_no !== null &&
                        singleData.license_no !== 'null'
                        ? singleData.license_no
                        : 'None'}
                    </span>
                  </div>
                  <div className="balance-main-set clearfix mb_3px">
                    <span className="top-cus-left">Balance Due </span>
                    <span className="red_font top-cus-right">
                      {this.state.data.currency}
                      {`${setDashboardAmount(singleData.balance_due)}`}
                    </span>
                  </div>
                  <div className="clearfix mb_3px">
                    <span className="top-cus-left sizeandweight">
                      Total Sale{' '}
                    </span>
                    <span className="green_font top-cus-right sizeandweight word_break">
                      {this.state.data.currency}
                      {`${setDashboardAmount(singleData.total_sale)}`}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </Slider>
      </div>
    );
  }
}
