import React, { Component } from 'react'

import { WarehouseIcon } from '../../Assets/Settings/SettingsIcon'

import WarehouseForm from '../../Components/Form/WarehouseForm/WarehouseForm'
import FormLayout from '../../Components/Layout/FormLayout'

class EditWarehouse extends Component {

  componentDidMount() {
    document.title = 'Edit Warehouse'
  }

  renderWarehouseForm() {
    return (
      <WarehouseForm
        type="edit"
        id={this.props.match.params.id}
        handleToast={this.props.handleToast}
      />
    )
  }

  render() {
    return (
      <FormLayout
        title="Warehouses"
        Icon={WarehouseIcon}
        subtitle="Edit"
        closeUrl="/warehouses"
      >
        { this.renderWarehouseForm() }
      </FormLayout>
    )
  }
}

export default EditWarehouse