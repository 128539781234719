import React, { useEffect } from 'react';
import { CreditNoteForm } from '../../Components/Form';
import { FormLayout } from '../../Components';
import { CreditNoteIcon } from '../../Assets/Navigation/NavigationIcons';

const EditCreditNote = (props) => {
  useEffect(() => {
    document.title = 'Edit Credit Note';
  }, []);

  return (
    <FormLayout
      title="Credit Notes"
      Icon={CreditNoteIcon}
      subtitle="Edit"
      closeUrl="/creditnotes"
      iconstyle="credit_general_module_icon"
      layoutBoxClass="uiux-form-redesign"
    >
      <CreditNoteForm
        type="edit"
        id={
          props.idFromHeaderWrapper
            ? props.idFromHeaderWrapper
            : props.match.params.id
        }
        handleToast={props.handleToast}
      />
    </FormLayout>
  );
};

export default EditCreditNote;
