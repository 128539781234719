import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { restRequest } from '../../../Helpers/RequestHelper';
import Loader from '../../Loader/Loader';
import EnhancedForm from './DeliveryMethodFormEnhancer';

import './DeliveryMethodForm.css';
import { checkError } from '../../../Helpers/AuthHelper';
class DeliveryMethodForm extends Component {
  type = this.props.type; // "add" or "edit" form

  id = this.props.id || null;

  state = {
    deliveryMethod: {
      name: '',
    },
    loading: true,
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.fetchData().then(() => this.setState({ loading: false }));
  }

  fetchData = async () => {
    if (this.type === 'edit') {
      await restRequest('get', `deliverymethods/${this.id}/edit`)
        .then((res) => {
          let updatedDeliveryMethod = {
            name: res.name,
          };
          this.setState({ deliveryMethod: updatedDeliveryMethod });
        })
        .catch((error) => {
          checkError(error, this.props.handleToast);
        });
    }
  };

  submitRequestType() {
    if (this.type === 'add') {
      return 'post';
    } else if (this.type === 'edit') {
      return 'put';
    }
  }

  submitRequestUrl() {
    if (this.type === 'add') {
      return 'deliverymethods';
    } else if (this.type === 'edit') {
      return `deliverymethods/${this.id}`;
    }
  }

  successMessage = () => {
    if (this.type === 'add') {
      this.props.handleToast('Delivery method created successfully', 'success');
    } else if (this.type === 'edit') {
      this.props.handleToast('Delivery method has been updated', 'success');
    }
  };

  deliveryMethodContent() {
    const { deliveryMethod } = this.state;
    const {
      title,
      history,
      inModal,
      closeModal,
      onSubmitModal,
      handleToast,
      totalRecords,
      modelSource = '',
      handlePrompt = () => null,
    } = this.props;

    return (
      <EnhancedForm
        title={title}
        deliveryMethod={{ ...deliveryMethod }}
        requestType={this.submitRequestType()}
        requestUrl={this.submitRequestUrl()}
        onSubmitModal={onSubmitModal}
        successMessage={this.successMessage}
        handleToast={handleToast}
        history={history}
        inModal={inModal}
        modelSource={modelSource}
        closeModal={closeModal}
        totalRecords={totalRecords}
        handlePrompt={handlePrompt}
      />
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return this.deliveryMethodContent();
  }
}

export default withRouter(DeliveryMethodForm);
