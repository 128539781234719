import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Toaster from '../Helpers/Toaster/Toaster';
import { validToken } from '../Helpers/AuthHelper';

import ProtectedRoutes from './ProtectedRoutes';
import PublicRoutes from './PublicRoutes';
import Accept from '../Pages/Accept/Accept';
import PaymentStatus from '../Pages/PackagesPlan/PaymentStatus';
import PaymentSuccess from '../Pages/PackagesPlan/PaymentSuccess';
import PaymentFailed from '../Pages/PackagesPlan/PaymentFailed';
import { lookResetCodeInUrl } from '../Pages/MyAccount/resetCodeUrlParser';
import StripePaymentStatus from '../Pages/PackagesPlan/StripePaymentStatus';
import OrganizationChoose from '../Components/OrganizationChoose';
import { LogoutUrl, wordpressLogout } from '../Auth/Logout';
import { ssoMessageEvent, restRequest } from '../Helpers';

class Routes extends Component {
  state = {
    toastMessage: '',
    toastType: '',
  };

  handleToastMessage(message, type) {
    this.setState(
      {
        toastMessage: message, //can use string, array, object
        toastType: type,
      },
      () => {
        setTimeout(() => {
          this.setState({
            toastMessage: '',
            toastType: '',
          });
        }, 6000);
      }
    );
  }

  handleToast = (request, type) => {
    if (navigator.onLine) {
      const response = request.response ? request.response : request;
      if (typeof response === 'string' || Array.isArray(response)) {
        this.handleToastMessage(response, type);
      } else if (!response) {
        this.handleToastMessage(
          'Something went wrong.\nPlease contact your administrator.',
          'error'
        );
      } else if (response && response.data && response.data.errors) {
        this.handleToastMessage(response.data.errors, type);
      } else if (response && response.data && response.data.message) {
        this.handleToastMessage(response.data.message, type);
      } else {
        this.handleToastMessage(response.statusText, type);
      }
    } else {
      this.handleToastMessage(
        'Please check your internet connection.',
        'error'
      );
    }
  };

  withNotification = (Component, props) => {
    return <Component {...props} handleToast={this.handleToast} />;
  };

  render() {
    return (
      <Router
        getUserConfirmation={(message, callback) => {
          // this is the default behavior
          const allowTransition = window.confirm(message);
          callback(allowTransition);
        }}
      >
        <Fragment>
          {this.state.toastMessage !== '' && (
            <Toaster
              message={this.state.toastMessage}
              type={this.state.toastType}
            />
          )}

          <Switch>
            <Route
              exact
              path="/invite/accept/:token"
              render={(props) => this.withNotification(Accept, props)}
            />
            <Route
              exact
              path="/organization/choose"
              render={(props) =>
                this.withNotification(OrganizationChoose, props)
              }
            />

            {/* Package Plans */}
            <Route
              exact
              path="/paymentStatus"
              component={PaymentStatus}
              handleToast={this.handleToast}
            />
            <Route
              exact
              path="/stripeStatus"
              component={StripePaymentStatus}
              handleToast={this.handleToast}
            />
            <Route
              exact
              path="/paymentFailed"
              component={PaymentFailed}
              handleToast={this.handleToast}
            />
            <Route
              exact
              path="/paymentSuccess"
              component={PaymentSuccess}
              handleToast={this.handleToast}
            />
            <Route exact path="/abcd" component={LogoutUrl} />
            {/*<Route exact path="/eventlisten" component={ssoMessageEvent()} />*/}
            <Route exact path="/wp_logout" component={wordpressLogout} />

            <Route
              path="/"
              render={(props) => {
                if (!validToken()) {
                  if (window.location.href.includes('myaccount')) {
                    let resetParams = lookResetCodeInUrl(
                      window.location.search
                    );
                    if (
                      resetParams &&
                      resetParams.isResetUrl &&
                      resetParams.resetCode
                    ) {
                      localStorage.setItem('reset_code', resetParams.resetCode);
                    }
                  }

                  return (
                    <PublicRoutes withNotification={this.withNotification} />
                  );
                }
                return <ProtectedRoutes handleToast={this.handleToast} />;
              }}
            />
          </Switch>
        </Fragment>
      </Router>
    );
  }
}

export default Routes;
