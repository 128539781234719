import React, { Component } from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import moment from 'moment';
import { restRequest, checkError, getOrganizationDate } from '../../../Helpers';
import { Loader } from '../../../Components';
import AdjustmentFormEnhancer from './AdjustmentFormEnhancer';

class AdjustmentForm extends Component {
  state = {
    loading: true,
    adjustments: {
      adjustmentType: 'quantity',
      date: moment(getOrganizationDate()),
      purchaseAccountID: null,
      reason: '',
      description: '',
      warehouseID: null,
      items: [],
    },
    purchaseAccounts: [],
    warehouseFromDB: [],
    primaryWarehouse: {},
    warehouseItemsFromDB: [],
    stockPreference: '',
    showPrompt: false,
    itemId: null,
  };

  emptyItem = {
    id: null,
    name: '',
    images: [],
    sku: '',
    availableQuantity: 0,
    updatedQuantity: 0,
    adjustedQuantity: null,
    unit: 'unit',
  };

  componentDidMount() {
    document.title = 'Create Adjustment';
    this.setState({ loading: true });
    this.fetchData().then(() => this.setState({ loading: false }));
    sessionStorage.setItem('once', false);
    sessionStorage.setItem('fullscreen', false);
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillMount() {
    const { history } = this.props;
    const { location } = history;
    const { state = {} } = location;
    const { itemId = null, warehouseId = null } = state;
    this.setState({
      itemId: itemId && parseInt(itemId),
      adjustments: {
        ...this.state.adjustments,
        warehouseID: warehouseId,
      },
    });
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  fetchData = async () => {
    this.setState({ loading: true });

    await restRequest('get', 'itemadjustments/create')
      .then((res) => {
        let primaryWarehouse = res.warehouses.find(
          (warehouse) => warehouse.is_primary === true
        );
        this.setState((state) => ({
          adjustments: {
            ...state.adjustments,
            purchaseAccountID: res.account_groups[0].accounts[0]?.id,
            items: [this.emptyItem],
          },
          purchaseAccounts: res.account_groups,
          warehouseFromDB: res.warehouses,
          primaryWarehouse,
          stockPreference: res.stock_preference,
        }));
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, 'error')
      });

    this.setState({ loading: false });
  };

  handlePrompt = (status = true) => this.setState({ showPrompt: status });

  renderAdjustmentForm() {
    const { title, handleToast, history } = this.props;
    let {
      adjustments,
      warehouseFromDB,
      purchaseAccounts,
      stockPreference,
      primaryWarehouse,
      itemId,
    } = this.state;
    return (
      <>
        <Prompt
          when={this.state.showPrompt}
          message="Are you sure you want to leave page while changes made?"
        />
        <AdjustmentFormEnhancer
          title={title}
          type={this.props.type}
          adjustments={{ ...adjustments }}
          emptyItem={this.emptyItem}
          warehouseFromDB={warehouseFromDB}
          purchaseAccounts={purchaseAccounts}
          stockPreference={stockPreference}
          primaryWarehouse={primaryWarehouse}
          itemId={itemId}
          history={history}
          handleToast={handleToast}
          setPrompt={this.handlePrompt}
        />
      </>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    else return this.renderAdjustmentForm();
  }
}

export default withRouter(AdjustmentForm);
