import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import getDate from '../../../Helpers/Date/GetDate';
import getStatusColor from '../../../Helpers/GetStatusColor';
import NoDashboardDataFound from '../../../Components/NotFound/NoDashboardDataFound';
import './DashboardOrderDetails.css';
import { getObjFromLS, setObjInLS } from '../../../Helpers';

export default function OrderDetailsTable({ data, tab }) {
  let history = useHistory();

  const POClickHandler = (url) => {
    const dataFromLS = getObjFromLS('module');
    setObjInLS('module', {
      ...dataFromLS,
      moduleName: 'purchaseorders',
      urlPath: url,
    });
    history.push(url);
  };
  //console.log(data);

  switch (tab) {
    case 'salesorder':
      return (
        <div className="orders-body">
          {data.length ? (
            <div className="orders-table">
              <table className="lopi orders-table-body dashboard-table-main">
                <thead>
                  <tr className="orders-table-head">
                    <th>
                      <span>Order Date</span>
                    </th>
                    <th>
                      <span>Sale Order No.</span>
                    </th>
                    <th>
                      <span>Customer</span>
                    </th>
                    <th>
                      <span>Status</span>
                    </th>
                    <th>
                      <span>Amount</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((sales, index) => (
                    <tr className="orders-table-list yes" key={index}>
                      <td>{getDate(sales.order_date)}</td>
                      <td className="check">
                        <Link to={`salesorders/${sales.id}`}>
                          {sales.sales_order_no}
                        </Link>
                      </td>
                      <td className="check">
                        <Link to={`contacts/${sales.customer_id}`}>
                          {sales.so_display_name
                            ? sales.so_display_name
                            : sales.display_name}
                        </Link>
                      </td>
                      <td>
                        <span className={getStatusColor(sales.status)}>
                          {sales.status}
                        </span>
                      </td>
                      <td>
                        {sales.currency.symbol}
                        {parseFloat(sales.total).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="empty-data">
              You do not have any sales order yet.
            </div>
          )}
        </div>
      );
    case 'invoice':
      return (
        <div className="orders-body">
          {data.length ? (
            <div className="orders-table">
              <table className="lopi orders-table-body orders-bills-table-body dashboard-invoice-tb">
                <thead>
                  <tr className="orders-table-head">
                    <th>
                      <span>Invoice Date</span>
                    </th>
                    <th>
                      <span>Invoice No.</span>
                    </th>
                    <th>
                      <span>Customer</span>
                    </th>
                    <th>
                      <span>Status</span>
                    </th>
                    <th>
                      <span>Amount</span>
                    </th>
                    <th>
                      <span>Balance Due</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((invoice, index) => (
                    <tr className="orders-table-list yes" key={index}>
                      <td>{getDate(invoice.invoice_date)}</td>
                      <td className="check">
                        <Link to={`invoices/${invoice.id}`}>
                          {invoice.invoice_no}
                        </Link>
                      </td>
                      <td className="check">
                        <Link to={`contacts/${invoice.customer_id}`}>
                          {invoice.display_name
                            ? invoice.display_name
                            : invoice.customer?.display_name}
                        </Link>
                      </td>
                      <td>
                        <span className={getStatusColor(invoice.status)}>
                          {invoice.status}
                        </span>
                      </td>
                      <td>
                        {invoice.currency.symbol}
                        {parseFloat(invoice.total).toFixed(2)}
                      </td>
                      <td>
                        {invoice.currency.symbol}
                        {parseFloat(invoice.payment_due).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="empty-data">You do not have any invoices yet.</div>
          )}
        </div>
      );

    case 'purchaseorder':
      return (
        <div className="orders-body">
          {data.length ? (
            <div className="orders-table">
              <table className="lopi orders-table-body dashboard-purchase-tb UIUX_dashboard_purchase-table">
                <thead>
                  <tr className="orders-table-head">
                    <th>
                      <span>Order Date</span>
                    </th>
                    <th>
                      <span>Purchase Order No.</span>
                    </th>
                    <th>
                      <span>Vendor</span>
                    </th>
                    <th>
                      <span>Status</span>
                    </th>
                    <th>
                      <span>Amount</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((purchase, index) => (
                    <tr className="orders-table-list yes" key={index}>
                      <td>{getDate(purchase.order_date)}</td>
                      <td className="check">
                        {/*<Link to={`purchaseorders/${purchase.id}`}>
                          {purchase.purchase_order_no}
                        </Link>*/}
                        <a
                          //  to={`purchaseorders/${purchase.id}`}
                          onClick={() => {
                            POClickHandler(`purchaseorders/${purchase.id}`);
                          }}
                        >
                          {purchase.purchase_order_no}
                        </a>
                      </td>
                      <td className="check">
                        <Link to={`contacts/${purchase.vendor_id}`}>
                          {purchase.vendor.display_name}
                        </Link>
                      </td>
                      <td>
                        <span className={getStatusColor(purchase.status)}>
                          {purchase.status}
                        </span>
                      </td>
                      <td>
                        {purchase.currency.symbol}
                        {parseFloat(purchase.total).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="empty-data">You do not have any purchases yet.</div>
          )}
        </div>
      );

    case 'bills':
      return (
        <div className="orders-body">
          {data.length ? (
            <div className="orders-table">
              <table className="lopi orders-table-body orders-bills-table-body">
                <thead>
                  <tr className="orders-table-head">
                    <th>
                      <span>Bill Due Date</span>
                    </th>
                    <th>
                      <span>Bill No.</span>
                    </th>
                    <th>
                      <span>Vendor</span>
                    </th>
                    <th>
                      <span>Status</span>
                    </th>
                    <th>
                      <span>Amount</span>
                    </th>
                    <th>
                      <span>Balance Due</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((bill, index) => (
                    <tr className="orders-table-list yes" key={index}>
                      <td>{getDate(bill.due_date)}</td>
                      <td className="check">
                        <Link to={`bills/${bill.id}`}>{bill.bill_no}</Link>
                      </td>
                      <td className="check">
                        <Link to={`contacts/${bill.vendor_id}`}>
                          {bill.vendor.display_name}
                        </Link>
                      </td>
                      <td>
                        <span className={getStatusColor(bill.status)}>
                          {bill.status}
                        </span>
                      </td>
                      <td>
                        {bill.currency.symbol}
                        {parseFloat(bill.total).toFixed(2)}
                      </td>
                      <td>
                        {bill.currency.symbol}
                        {parseFloat(bill.balance_due).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="empty-data">You do not have any bills yet.</div>
          )}
        </div>
      );
    default:
      return <NoDashboardDataFound message="No data available at the moment" />;
  }
}
