import React, { Fragment, useEffect } from 'react';
import { formatNumber } from '../../../../Helpers/FormatNumber';
import { restRequest } from '../../../../Helpers/RequestHelper';
import { getItemTotalAmount } from '../../../../Helpers/Calculate';

import Table from '../../../../Components/Table/Table';
//import { GridLayout } from '../../../../Components/Layout/Layout';
import ListTableImageColumn from '../../../../Components/ListTable/ListTableImageColumn';
import Quantity from '../../../../Components/Fragments/Quantity/Quantity';
// import { checkError } from '../../../../Helpers/AuthHelper';

const SalesOrderSummary = ({ salesOrder, currency, overallTax, inModal }) => {
  const {
    items,
    adjustment,
    // discount,
    shippingCharge,
    discountLevel,
    discountTransactionLevel,
    discountType,
    total,
    // note,
    //salesPerson,
    id,
  } = salesOrder;

  // const [myWareId, setMyWareId] = React.useState([]);
  // const [flag, setflag] = React.useState(false);
  // const [warehouseList, setWarehouseList] = React.useState({});
  // const [warehouseNames, setWarehouseNames] = React.useState([]);

  const tableHeaders =
    discountLevel === 'item'
      ? ['Items', 'Warehouse', 'Qty.', 'Price', 'Discount', 'Amount']
      : ['Items', 'Warehouse', 'Qty.', 'Price', 'Amount'];
  const tableData =
    discountLevel === 'item'
      ? ['name', 'warehouse', 'quantity', 'unitPrice', 'discount', 'total']
      : ['name', 'warehouse', 'quantity', 'unitPrice', 'total'];

  const rightAlignColumns = discountLevel === 'item' ? [2, 3, 4, 5] : [2, 3, 4];

  let subtotal = 0;
  const orderList = items.map((item, index) => {
    // if (flag === true) {
    subtotal +=
      discountLevel === 'item'
        ? getItemTotalAmount(item)
        : item.unitPrice * item.quantity;
    return {
      name: (
        <ListTableImageColumn
          name={item.name}
          sku={item.sku}
          images={item.images}
          id={item.id}
          showModalUponClick={inModal ? false : true}
          value={item.extra_description}
          showExtraDisableDescription={!!item.extra_description}
        />
      ),
      warehouse: item?.warehouse?.name,
      quantity: <Quantity quantity={item.quantity} unit={item.unit} />,
      taxAmount: parseFloat(item.taxAmount).toFixed(2),
      unitPrice: currency.symbol + `${parseFloat(item.unitPrice).toFixed(2)}`,
      discount:
        item.discount !== null
          ? `${
              item.discountType === 'amount'
                ? currency.symbol + parseFloat(item.discount).toFixed(2)
                : ''
            }${
              item.discountType === 'percent'
                ? parseFloat(item.discount) + '%'
                : ''
            }`
          : 0,
      total: `${
        discountLevel === 'item'
          ? currency.symbol + parseFloat(getItemTotalAmount(item)).toFixed(2)
          : currency.symbol +
            parseFloat(item.unitPrice * item.quantity).toFixed(2)
      }`,
    };
    // }
  });

  let transactionDiscount = 0;
  let DISCOUNT_LABEL = 'Discount';
  let DISCOUNT_AMOUNT = 0;
  if (discountType !== null) {
    if (discountType === 'amount') {
      transactionDiscount += discountTransactionLevel;
      DISCOUNT_AMOUNT =
        currency.symbol + parseFloat(discountTransactionLevel).toFixed(2);
    } else if (discountType === 'percent') {
      transactionDiscount = (discountTransactionLevel * subtotal) / 100;
      DISCOUNT_LABEL = `${DISCOUNT_LABEL} (${formatNumber(
        discountTransactionLevel
      )}%)`;
      DISCOUNT_AMOUNT =
        currency.symbol + parseFloat(transactionDiscount).toFixed(2);
    }
  }

  let ADJUSTMENT_AMPOUNT = parseFloat(adjustment).toFixed(2);

  function renderOverallTax() {
    return overallTax.map((tax) => (
      <div className="single-summary-rec mb-6" key={tax.id}>
        <span className="">{`${tax.name} (${tax.rate}%)`} </span>
        <span className="semi-bold">{`${currency.symbol}${parseFloat(
          tax.total
        ).toFixed(2)}`}</span>
      </div>
    ));
  }

  // useEffect(() => {
  //   let url = `salesorders/${id}`;

  //   restRequest('get', url)
  //     .then((res) => {
  //       setWarehouseList(res.warehouse_list);
  //       setMyWareId(res.sales_order_details);
  //       setflag(true);
  //     })
  //     .catch((error) => {
  //       checkError(error);
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   const warehouses = myWareId.map(
  //     ({ warehouse_id }) =>
  //       warehouseList.find(({ id }) => warehouse_id === id)?.name
  //   );
  //   setWarehouseNames(warehouses);
  // }, [myWareId, warehouseList]);

  return (
    <Fragment>
      {/* {flag && ( */}
      <div
        className="salesordersummary sales-order-summary-main"
        style={{ float: 'left', width: '100%' }}
      >
        <Table
          list={orderList}
          tableHeaders={tableHeaders}
          tableData={tableData}
          rightAlignColumns={rightAlignColumns}
          className="details-table sales-order-summary-main uiux_so_table_summary"
        />
        <div className="layout-padding">
          {/*<div className="colorless">
              {salesPerson && (
                <Fragment>
                  <div className="section-details--info-subtitle margin-top-md margin-bottom-sm sales_representative_title colorless">
                    Sales Representative
                  </div>
                  {salesPerson}
                </Fragment>
              )}
            </div>*/}
          <div className="cust-table-summary">
            <div className="w-70 left-col">
              <div className="single-summary-rec mb-6">
                <span className="">Total Items:</span>
                <span className="semi-bold"> {orderList.length}</span>
              </div>
            </div>
            <div className="w-30 right-col">
              <div className="single-summary-rec mb-6">
                <span className="">Sub Total </span>
                <span className="semi-bold">{`${currency.symbol}${parseFloat(
                  subtotal
                ).toFixed(2)}`}</span>
              </div>
              {discountLevel === 'transaction' && (
                <div className="single-summary-rec mb-6">
                  <span className="">{DISCOUNT_LABEL} </span>
                  {parseFloat(DISCOUNT_AMOUNT.substring(1)) > 0 ? (
                    <span className="semi-bold" style={{ color: 'red' }}>
                      (-) {DISCOUNT_AMOUNT.substring(1)}
                    </span>
                  ) : (
                    <span className="semi-bold">{DISCOUNT_AMOUNT}</span>
                  )}
                </div>
              )}
              {renderOverallTax()}
              <div className="single-summary-rec mb-6">
                <span className="">Adjustment </span>
                {parseFloat(adjustment) >= 0 ? (
                  <span className="semi-bold">
                    {currency.symbol}
                    {ADJUSTMENT_AMPOUNT}
                  </span>
                ) : (
                  <span
                    className="semi-bold"
                    style={{ marginLeft: '-20%', color: 'red' }}
                  >
                    (-) {ADJUSTMENT_AMPOUNT.substring(1)}
                  </span>
                )}
              </div>

              <div className="single-summary-rec mb-6">
                <span className="">Shipping & Handling </span>
                <span className="semi-bold">
                  {' '}
                  {currency.symbol}
                  {parseFloat(shippingCharge).toFixed(2)}{' '}
                </span>
              </div>
            </div>
          </div>
        </div>
        <hr className="float-left w-100 divider" />
        <div className="cust-table-summary">
          <div className="w-70 left-col"></div>
          <div className="w-30 right-col">
            <div className="single-summary-rec mb-0">
              <span className="semi-bold">Total </span>
              <span className="semi-bold">
                {' '}
                {currency.symbol}
                {parseFloat(total).toFixed(2)}{' '}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </Fragment>
  );
};
export default SalesOrderSummary;
