import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { restRequest } from '../../../Helpers/RequestHelper';

import OverlayLoader from '../../../Components/Loader/OverlayLoader';
import Form from '../../../Components/Form/Form';
// import Field from '../../../Components/Form/Field/Field'
import FormAction from '../../../Components/Form/FormAction';
import { getObjFromLS } from '../../../Helpers/LocalStorage'; //, setObjInLS
import { checkError } from '../../../Helpers/AuthHelper';

class PackagePreference extends Component {
  state = {
    loading: true,
    salesPref: {
      pkFormat: '',
    },
    terms: '',
    isSubmitting: false,
  };

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    this.setState({ loading: true });
    await restRequest('get', 'numberPreferences/package')
      .then((res) => {
        this.setState({
          salesPref: {
            pkFormat: res.preferences.pkFormat,
          },
          terms: res.terms,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  }

  togglePackageFormatChange(value) {
    let pkAddedFormat = value.target.value;
    this.setState((state) => ({
      salesPref: {
        ...state.salesPref,
        pkFormat: pkAddedFormat,
      },
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let { pkFormat } = this.state.salesPref;
    this.setState({ isSubmitting: true });
    restRequest('put', 'numberPreferences/package', {
      status: {
        pkFormat: pkFormat,
      },
      terms: this.state.terms,
    })
      .then(() => {
        this.setState({ isSubmitting: false });
        this.props.handleToast(
          'Package Prefix preference updated successfully',
          'success'
        );
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });
        //this.props.handleToast(error, "error")
        checkError(error, this.props.handleToast);
      });
  };
  /*
  salesTermsContent() {
    return (
      <div>
        <Field
          name="Terms & Conditions"
          id="terms"
          type="textarea"
          value={this.state.terms}
          placeholder="Terms & Conditions"
          handleChange={this.handleChange}
        />
      </div>
    )
  }
*/
  renderActionButtons() {
    // return (
    //   <FormAction disabled={this.state.isSubmitting} onCancel={() => this.props.history.push('/settings')} />
    // )
    let permissions = getObjFromLS('role').permissions;
    if (!permissions.superAccess) {
      if (permissions.PreferenceEdit) {
        return (
          <FormAction
            disabled={this.state.isSubmitting}
            onCancel={() => this.props.history.push('/settings')}
          />
        );
      }
    }
    if (permissions.superAccess) {
      return (
        <FormAction
          disabled={this.state.isSubmitting}
          onCancel={() => this.props.history.push('/settings')}
        />
      );
    }
  }

  renderPackagePreference() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <div className="po-pref-component">
          {this.state.loading && <OverlayLoader />}
          <div>You can change Package Prefix according to your need.</div>
          {/*<hr className="pref--hr" />*/}
          <label className="list-table--checkbox-lable">
            <div className="sale-return-preference--label-text semi-bold">
              Package Prefix
            </div>
            <input
              style={{ width: '50px' }}
              name="pkFormat"
              value={this.state.salesPref.pkFormat}
              type="text"
              //pattern='[0-9]{0,5}'
              maxLength={3}
              required
              onChange={(value) => this.togglePackageFormatChange(value)}
            />
          </label>
          {this.renderActionButtons()}
        </div>
      </Form>
    );
  }

  render() {
    return this.renderPackagePreference();
  }
}

export default withRouter(PackagePreference);
