import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { restRequest } from '../../Helpers/RequestHelper';
import { getCurrentOrganization } from '../../Helpers/CurrentSession';
import SettingsPageIcon from '../../Assets/General/SettingPageIcon';
import Loader from '../../Components/Loader/Loader';
import HeaderWrapper from '../../Components/HeaderWrapper/HeaderWrapper';
import OrganizationInfo from './Info/OrganizationInfo';
import OrganizationTabs from './Tabs/OrganizationTabs';
import SwitchIcon from '../../Assets/General/SwitchOrganizationIcon';
import { checkError } from '../../Helpers/AuthHelper';
import { Prompt } from 'react-router-dom';
class Organization extends Component {
  state = {
    loading: true,
    organizationInfo: {},
    organizationLogo: null,
    uploadedOrganizationLogo: '',
    uploadingLogo: false,
    detailsNo: '',
    showLogoRemoveModal: false,
    showPrompt: false,
  };

  componentDidMount() {
    document.title = 'Business Profile';
    this.fetchData();
  }

  setPrompt = (prop) => {
    this.setState({
      showPrompt: prop,
    });
  };

  async fetchData() {
    this.setState({ loading: true });
    await restRequest('get', `organizations/${getCurrentOrganization().id}`)
      .then((res) => {
        this.setState({
          organizationInfo: res,
          detailsNo: res.name,
          organizationLogo: res.logo,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error);
      });
  }

  toggleLogoRemoveModal = () => {
    this.setState((state) => ({
      showLogoRemoveModal: !state.showLogoRemoveModal,
    }));
  };

  onConfirmLogoRemove = () => {
    this.setState({
      uploadingLogo: true,
    });
    this.toggleLogoRemoveModal();
    restRequest(
      'delete',
      `organizations/${this.state.organizationInfo.id}/logo`
    )
      .then(() => {
        this.setState({
          uploadedOrganizationLogo: '',
          organizationLogo: null,
          uploadingLogo: false,
        });
        this.props.handleToast('Logo has been removed', 'success');
      })
      .catch((error) => {
        this.setState({ uploadingLogo: false });
        checkError(error, this.props.handleToast);
      });
  };

  onDropImages = (file, rejectedFile) => {
    const handleToast = this.props.handleToast;

    if (rejectedFile.length) {
      handleToast('Maximum file size exceeded!', 'error');
      return;
    }
    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        uploadedOrganizationLogo: reader.result,
        uploadingLogo: true,
      });
      restRequest(
        'put',
        `organizations/${this.state.organizationInfo.id}/logo`,
        {
          logo: reader.result,
        }
      )
        .then(() => {
          this.setState({
            uploadingLogo: false,
          });
          handleToast('Logo has been updated', 'success');
        })
        .catch((error) => {
          this.setState({ uploadingLogo: false });
          checkError(error, handleToast);
        });
    };
    reader.readAsDataURL(file[0]);
  };

  renderActionMenu() {
    // const hasEditPermission = this.hasPermission('organization', 'Edit');
    return (
      <Fragment>
        <span className="left__ layout--title ellipsis">
          <SettingsPageIcon />
          <h3>Settings - User & Access</h3>
        </span>
        <Link
          className="button--primary margin-right-md organization-btn-set"
          to={`/oc/organization/choose`}
        >
          <SwitchIcon className="switch_icon" />
          Switch Business
        </Link>
      </Fragment>
    );
  }

  renderLayoutView() {
    const {
      organizationInfo,
      organizationLogo,
      uploadedOrganizationLogo,
      uploadingLogo,
      showLogoRemoveModal,
    } = this.state;
    return (
      <div className="float-left w-100 organization_details">
        <OrganizationInfo
          info={organizationInfo}
          onDropImages={this.onDropImages}
          logo={organizationLogo}
          uploadedLogo={uploadedOrganizationLogo}
          uploadingLogo={uploadingLogo}
          toggleLogoRemoveModal={this.toggleLogoRemoveModal}
          showLogoRemoveModal={showLogoRemoveModal}
          onConfirmLogoRemove={this.onConfirmLogoRemove}
        />
      </div>
    );
  }

  renderSidebar() {
    return (
      <OrganizationTabs
        handleToast={this.props.handleToast}
        setPrompt={this.setPrompt}
        {...this.props}
      />
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return (
      <div>
        <Prompt
          when={this.state.showPrompt}
          message="Are you sure you want to leave page while changes made?"
        />
        {this.renderLayoutView()}
        {this.renderSidebar()}
      </div>
    );
  }
}

export default HeaderWrapper(Organization, {
  name: 'Organization',
  onlyMenu: true,
  redirectUrl: '/useraccess',
  permissionName: 'organization',
});
