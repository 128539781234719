import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import getDate from '../../../Helpers/Date/GetDate';
import getStatusColor from '../../../Helpers/GetStatusColor';
import { restRequest } from '../../../Helpers/RequestHelper';
// import {
//   moreThanMinus,
//   moreThanZero,
//   required,
//   ValidateMax255,
//   ValidateMax50,
//   ValidateNumber,
//   ValidateRequired
// } from '../../../Helpers/Validator'
import { getOrganizationDate } from '../../../Helpers/Date/OrganizationDate';

import Loader from '../../Loader/Loader';
import NotFound from '../../NotFound/NotFound';
import Field from '../../Form/Field/Field';
import Form from '../../Form/Form';
import FormAction from '../FormAction';
import ErrorFocus from '../ErrorFocus';

import './UtilizationFromInvoice.css';
import { getObjFromLS, setObjInLS } from '../../../Helpers/LocalStorage';
import { Link, withRouter } from 'react-router-dom';
// import {formatNumber} from "../../../Helpers/FormatNumber";
// import {getError} from "../FormHelper";
import { checkError } from '../../../Helpers/AuthHelper';
import { confirmationAlert } from '../../../Helpers';

let flag;
class UtilizationFromInvoice extends Component {
  type = this.props.type; // "invoice", "bill"

  id = this.props.id || null;
  saveAndSend = false;
  state = {
    loading: true,
    creditNotes: {},
    invoice: {},
    totalCreditAmount: 0,
    totalAppliedCredits: 0,
    paymentDue: 0,
    currency: {},
    disableSaveBtn: true,
    notFound: false,
    credit_utilize_date: moment(getOrganizationDate()),
    alertState: false,
  };

  componentDidMount() {
    localStorage.setItem('alert', false);
    this.setState({ loading: true });
    this.fetchData().then(() => this.setState({ loading: false }));
  }
  componentWillUnmount() {
    flag = undefined;
  }

  formValidator() {
    const { creditNotes } = this.state;
    let checkout = true;
    if (creditNotes.length) {
      const checkEquality = creditNotes.some((credit) => credit.used_credits);
      if (checkEquality) {
        checkout = false;
      }
    }
    return { checkout };
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.alertState !== this.state.alertState) {
      const { checkout } = this.formValidator();
      if (!checkout) localStorage.setItem('alert', true);
      else localStorage.setItem('alert', false);
    }
  }

  handlealertFlag = () => {
    this.setState({
      alertState: !this.state.alertState,
    });
  };

  fetchData = async () => {
    this.setState({ loading: true });

    await this.fetchPayment(this.id);

    this.setState({ loading: false });
  };

  fetchPayment = async (id) => {
    await restRequest('get', `creditutilize/${id}/create/invoice`)
      .then((res) => {
        //console.log('res', res)
        flag = res;
        this.setState({
          creditNotes: res.creditNotes,
          invoice: res.invoice,
          currency: res.currency,
        });
      })
      .catch((error) => {
        // console.log('res ,r' ,error.response.data.message)
        this.props.handleToast && this.props.handleToast(error, 'error');
        if (this.props.inModal) {
          this.props.onClose();
        }
      });
  };

  handleSubmit = (values, setSubmitting) => {
    let payload = {
      invoiceId: this.state.invoice.id,
      creditNotesList: values.filter((cr) => cr.used_credits > 0),
      appliedCreditNotes: this.state.totalAppliedCredits,
      amountToCredit: this.state.totalCreditAmount,
      invoiceBalanceDue: this.state.invoice.payment_due,
      credit_utilize_date: this.state.credit_utilize_date.format(
        'YYYY-MM-DD 00:00:00'
      ),
    };
    var checkCreditNotes = payload.creditNotesList.filter(
      (cr) => cr.used_credits > cr.balance
    );
    if (checkCreditNotes && checkCreditNotes.length > 0) {
      this.setState({ disableSaveBtn: true });
      setSubmitting(false);
      return false;
    }
    restRequest('post', 'creditutilize/store', payload)
      .then((res) => {
        this.props.handleToast(
          'Credits has been applied successfully',
          'success'
        );
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS === null) {
          let obj = {
            prevId: null,
            id: null,
            nextId: null,
            moduleName: '',
            urlPath: window.location.pathname,
            queryParam: window.location.search,
          };
          setObjInLS('module', obj);
          dataFromLS = getObjFromLS('module');
        }
        window.location.pathname.includes('invoices') &&
          setObjInLS('module', {
            ...dataFromLS,
            moduleName: 'invoices',
            urlPath: window.location.pathname,
            // queryParam: `${
            //   dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
            // }payments=true`,
            queryParam: `?payments=true`,
          });
        this.props.history.push('/r');
      })
      .catch((error) => {
        //console.log('error', error)
        checkError(error);
        if (typeof setSubmitting !== undefined) {
          setSubmitting(false);
        }
        this.props.handleToast(
          'Credits applied cannot be greater than balance due.',
          'error'
        );
      });
  };

  renderFooter = (touch) => {
    let { totalCreditAmount, totalAppliedCredits, currency } = this.state;
    return (
      <div
        className="float-left w-100 mt_3 summary_table"
        style={{ paddingRight: '2%' }}
      >
        <hr className="margin float-left w-100"></hr>
        <div className="float-left w-70 p_10">
          <div className="float-left w-100 so-mg-1">
            <span className="float-left" style={{ marginRight: '10px' }}>
              Applied Credit Notes :{' '}
            </span>
            <span className="float-left">{totalAppliedCredits}</span>
          </div>
          <div className="float-left w-100">
            <span className="float-left" style={{ marginRight: '10px' }}>
              <span style={{ color: 'red' }}>*</span> CR. = Credit
            </span>
          </div>
          <div className="float-left w-100">
            <span className="float-left" style={{ marginRight: '10px' }}>
              <span style={{ color: 'red' }}>*</span> CN = Credit Notes
            </span>
          </div>
        </div>
        <div className="float-left w-30 p_10 pr-0">
          <div className="float-left w-100 so-mg-1">
            <span className="float-left">Amount To Credit</span>
            <span className="float-right bold">
              {' '}
              {currency.symbol}
              {parseFloat(totalCreditAmount)?.toFixed(2)}
            </span>
          </div>
          <div className="float-left w-100 so-mg-1">
            <span className="float-left">Invoice Balance Due</span>
            <span className="float-right bold">
              {' '}
              {currency.symbol}
              {(this.state.invoice.payment_due - totalCreditAmount).toFixed(2)}
            </span>
          </div>
        </div>
        <hr className="margin float-left w-100" />
        <hr className="margin float-left w-100" />
      </div>
    );
  };
  getTotalCreditNote = (cnotes) => {
    let applied_Credits = cnotes.filter((el) => el.used_credits > 0);

    let total_Credits = applied_Credits.reduce(function (prev, current) {
      return prev + current.used_credits;
    }, 0);
    return total_Credits;
  };
  handleItemQuantityChange = (quantityValue, index, item) => {
    const cnotes = [...this.state.creditNotes];
    if (quantityValue.value === '') {
      cnotes[index].used_credits = null;
      let total_Credits = this.getTotalCreditNote(cnotes);
      this.setState({
        creditNotes: cnotes,
        totalCreditAmount: total_Credits,
      });
    } else {
      let credits = quantityValue.floatValue;
      // Price_Quantity_Limit
      // price and quantity limit from 5 digits to 10 digits
      if (credits > 0) {
        let quantString = credits.toString();
        if (quantString.length > 10) {
          quantString = quantString.slice(0, 10);
        }
        credits = parseFloat(quantString);
      }
      //console.log(credits);
      cnotes[index].used_credits = credits;
      this.setState({ creditNotes: cnotes });
      let applied_Credits = cnotes.filter((el) => el.used_credits > 0);
      let total_Credits = this.getTotalCreditNote(cnotes);
      this.setState({
        ...this.state,
        totalCreditAmount: total_Credits,
        totalAppliedCredits: applied_Credits.length,
      });
      if (
        total_Credits > this.state.invoice.payment_due ||
        applied_Credits.length <= 0 ||
        item.used_credits > item.balance
      ) {
        this.setState({ disableSaveBtn: true });
      } else {
        this.setState({ disableSaveBtn: false });
      }
      const checkCreditNotes = cnotes.filter(
        (cr) => cr.used_credits > cr.balance
      );
      if (checkCreditNotes && checkCreditNotes.length > 0) {
        this.setState({ disableSaveBtn: true });
      }
    }
    this.handlealertFlag();
  };

  handlCancel = () => {
    const { onClose } = this.props;
    let response;
    const { checkout } = this.formValidator();

    if (!checkout) response = confirmationAlert();
    if (response || checkout) onClose();
  };
  renderRecordPaymentForm() {
    let { creditNotes, disableSaveBtn, currency } = this.state;
    return (
      <Formik
        enableReinitialize
        initialValues={creditNotes}
        validationSchema={Yup.object().shape({
          /*amount: required(moreThanMinus(ValidateNumber)),
            date: ValidateRequired.nullable(),
            mode: required(ValidateMax50),
            reference: ValidateMax50,
            notes: ValidateMax255*/
        })}
        onSubmit={(values, { setSubmitting }) => {
          this.handleSubmit(values, setSubmitting);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          handleBlur,
          submitCount,
          setSubmitting,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="credit_note_utilization">
            <div className="so-tbletabs">
              <table
                className={`list-table form-table border create_invoice horizontal-items-table`}
              >
                <thead>
                  <tr className="table--row">
                    <th className="">Issue Date</th>
                    <th className="">CN No.</th>
                    <th className="">Status</th>
                    <th className="right-align">Issued Cr.</th>
                    <th className="right-align">Available Cr.</th>
                    <th className="">Amount To Credit</th>
                  </tr>
                </thead>
                <tbody>
                  {creditNotes.map((item, index) => {
                    return (
                      <tr key={index} className="table--row">
                        <td className="">{getDate(item.credit_note_date)}</td>
                        <td className="">
                          {item.credit_note_id ? (
                            <Link
                              to={`/creditnotes/${item.credit_note_id}`}
                              target="_blank"
                            >
                              {item.credit_note_no}
                            </Link>
                          ) : (
                            <Link
                              to={`/paymentsmade/${item.credit_note_no}`}
                              target="_blank"
                            >
                              {`Payment # ${item.credit_note_no}`}
                            </Link>
                          )}
                        </td>
                        <td className="">
                          <span className={getStatusColor(item.status)}>
                            {' '}
                            {item.status === 'partially applied'
                              ? 'PRTL Applied'
                              : item.status
                                ? item.status
                                : '-'}
                          </span>
                        </td>
                        <td className="right-align">
                          {currency.symbol}
                          {parseFloat(item.issued_credits)?.toFixed(2)}
                        </td>
                        <td className="right-align">
                          {currency.symbol}
                          {parseFloat(item.balance)?.toFixed(2)}
                        </td>
                        <td className="right-align no-padding">
                          <Field
                            size="small"
                            type="number"
                            value={item.used_credits}
                            decimalScale={2}
                            id={`creditNotes[${index}].used_credits`}
                            allowNegative={false}
                            thousandSeparator=","
                            placeholder="$0.00"
                            onValueChange={(value) => {
                              this.handleItemQuantityChange(value, index, item);
                            }}
                            handleChange={handleChange}
                            error={
                              touched &&
                              touched.creditNotes &&
                              touched.creditNotes[index] &&
                              touched.creditNotes[index].used_credits &&
                              creditNotes[index].used_credits >
                              creditNotes[index].balance
                            }
                            onBlur={handleBlur}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {this.renderFooter()}
            <FormAction
              hasHr={true}
              marginFromRight={4}
              disabled={isSubmitting || disableSaveBtn}
              // onCancel={this.props.onClose}
              onCancel={this.handlCancel}
              showSaveAndSendButton={false}
            />
            <ErrorFocus />
          </Form>
        )}
      />
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    return this.renderRecordPaymentForm();
  }
}

export default withRouter(UtilizationFromInvoice);
