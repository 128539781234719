import React, { Component } from 'react';
import ReceivedPaymentFormEnhancer from './ReceivedPaymentFormEnhancer';
import { Prompt, withRouter } from 'react-router-dom';
import moment from 'moment';
import { restRequest, checkError, getOrganizationDate } from '../../Helpers';
import { Loader } from '../../Components';
import '../../Components/Form/CustomerPaymentForm/CP.css';
import '../../Components/Table/Table.css';

class RecivedPaymentForm extends Component {
  type = this.props.type || 'add';

  id = this.props.id || null;
  // contactId = window.location.search.split('=')[1] || null;
  state = {
    loading: true,
    salesOrder: {
      contactId: null,
      salesOrderNo: '',
      reference: '',
      customerNotes: '',
      termsAndConditions: '',
      items: [],
    },
    paymentDate: moment(getOrganizationDate()),
    selectedPaymentMethod: null,
    customerList: [],
    paymentMethods: [],
    invoiceList: [],
    paymentId: null,
    contactId: null,
    payment_mode: '',
    payment_mode_name: '',
    amount_received: null,
    organizationCurrency: {},
    showPrompt: false,
    advancePaymentId: null,
    customerNotes: '',
    reference: '',
    refund_amount: 0,
    contactObj: {},
  };

  componentDidMount() {
    document.title =
      this.props.type === 'add'
        ? 'Create Customer Payment'
        : 'Update Customer Payment';
    this.fetchData();
    sessionStorage.setItem('once', false);
    sessionStorage.setItem('fullscreen', false);
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  fetchData = async () => {
    this.setState({ loading: true });
    const { type } = this;

    switch (type) {
      case 'edit':
        await this.fetchCreateCustomerPaymentEdit();
        break;
      case 'add':
      default:
        await this.fetchCreateCustomerPayment();
        break;
    }

    this.setState({ loading: false });
  };

  // getCloneEditUrl(id, type) {
  //   return `salesorders/${id}/${type === 'edit' ? 'edit' : 'clone'}`;
  // }

  fetchCreateCustomerPayment() {
    return restRequest('get', `advancepayment/invoice/create`)
      .then((res) => {
        let [paymentMode = {}] = res?.payment_methods || [];
        this.setState({
          organizationCurrency: res.base_currency,
          paymentMethods: res.payment_methods,
          invoiceList: res.invoices,
          paymentId: res.payment_no,
          customerList: res.customer_list,
          selectedPaymentMethod: res.payment_methods[0],
          payment_mode: paymentMode?.id || '',
          payment_mode_name: paymentMode?.name || '',
        });
      })
      .catch((error) => checkError(error));
  }

  fetchCreateCustomerPaymentEdit() {
    return restRequest('get', `advancepayment/${this.id}/edit`)
      .then((res) => {
        let paymentMode = res?.payment_methods.find(
          (pay) => pay.name === res.advance_payment.payment_mode
        );

        // const {
        //   base_currency: organizationCurrency = {},
        //   payment_methods: paymentMethods = [],
        //   invoices: invoiceList = [],
        //   customer_list: customerList = [],
        //   advance_payment: {
        //     customer_id: contactId = null,
        //     payment = '',
        //     id: advancePaymentId = null,
        //   } = {},
        //   payment_no: paymentId = '',
        // } = res;

        // const { id: payment_mode = '', name: payment_mode_name = '' } =
        //   paymentMethods.find(
        //     (pay) => pay.name === res.advance_payment.payment_mode
        //   );

        // let invoicesListArr = res?.invoices?.map((inv) => {
        //   let arr = res?.advance_payment?.invoice_payments.find(
        //     (invPay) => invPay.invoice_id === inv.id
        //   );
        //   let payment = arr?.payment_made || 0;
        //   return {
        //     ...inv,
        //     payment,
        //   };
        // });

        this.setState({
          organizationCurrency: res.base_currency,
          paymentMethods: res.payment_methods,
          invoiceList: res?.invoices,
          paymentId: res.payment_no,
          customerList: res.customer_list,
          contactId: res.advance_payment.customer_id,
          amount_received: res.advance_payment.payment,
          refund_amount: res.advance_payment.refund_amount,
          advancePaymentId: res?.advance_payment.id,
          customerNotes: res?.advance_payment?.note || '',
          reference: res?.advance_payment?.reference || '',
          selectedPaymentMethod: res.payment_methods[0],
          payment_mode: paymentMode?.id,
          payment_mode_name: paymentMode?.name,
          paymentDate: moment(res?.advance_payment?.payment_date),
          contactObj: {
            label: res?.customer_list[0]?.display_name,
            value: res?.customer_list[0]?.id,
          },

          //
          // organizationCurrency,
          // paymentMethods,
          // invoiceList,
          // paymentId,
          // customerList,
          // contactId,
          // amount_received: payment.toString(),
          // advancePaymentId,
          // selectedPaymentMethod: paymentMethods[0],
          // payment_mode,
          // payment_mode_name,
        });
      })
      .catch((error) => checkError(error));
  }

  submitRequestType = () => {
    if (this.type === 'edit') {
      return 'put';
    }
    return 'post';
  };

  submitRequestUrl = () => {
    switch (this.type) {
      case 'edit':
        return `advancepayment/${this.state.advancePaymentId}/update`;
      case 'add':
      default:
        return 'advancepayment/invoice';
    }
  };

  successMessage = () => {
    switch (this.type) {
      case 'edit':
        this.props.handleToast('Payment has been updated', 'success');
        break;
      case 'add':
      default:
        this.props.handleToast('Payment created successfully', 'success');
    }
  };

  updateCustomerList = (newCustomer) => {
    this.setState((state) => ({
      customerList: [newCustomer, ...state.customerList],
    }));
  };

  handlePrompt = (status = true) => this.setState({ showPrompt: status });

  renderSalesOrderForm() {
    const { handleToast, history } = this.props;
    let {
      salesOrder,
      customerList,
      organizationCurrency,
      paymentMethods,
      invoiceList,
      payment_mode,
      payment_mode_name,
      paymentId,
      paymentDate,
      amount_received,
      refund_amount,
      contactId,
      advancePaymentId,
      customerNotes,
      reference,
      contactObj,
    } = this.state;
    return (
      <>
        <Prompt
          when={this.state.showPrompt}
          message="Are you sure you want to leave page while changes made?"
        />
        <ReceivedPaymentFormEnhancer
          type={this.type}
          salesOrder={{ ...salesOrder }}
          customerList={customerList}
          paymentMethods={paymentMethods}
          invoiceList={invoiceList}
          organizationCurrency={organizationCurrency}
          submitRequestType={this.submitRequestType}
          submitRequestUrl={this.submitRequestUrl}
          successMessage={this.successMessage}
          history={history}
          paymentDate={paymentDate}
          handleToast={handleToast}
          setPrompt={this.handlePrompt}
          payment_mode={payment_mode}
          payment_mode_name={payment_mode_name}
          _payment_mode={payment_mode_name}
          paymentId={paymentId}
          amount_received={amount_received}
          refund_amount={refund_amount}
          updateCustomerList={this.updateCustomerList}
          contactId={contactId}
          advancePaymentId={advancePaymentId}
          customerNotes={customerNotes}
          reference={reference}
          contactObj={contactObj}
        />
      </>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return this.renderSalesOrderForm();
  }
}

export default withRouter(RecivedPaymentForm);
