import React, { Fragment } from 'react';
import { NewItemIcon } from '../../Assets/Navigation/NavigationIcons';
import { imagePath } from '../../Helpers/RequestHelper';
import ItemDetails from '../../Pages/Items/ItemDetails/index';
import CustomModal from '../CustomModal/CustomModal';
import { handleExtraDescription } from '../../Helpers/addressFieldsHandler';
import { MdOutlineSyncProblem } from '../../Common/Icons';
//import { SiWordpress } from '../../Common/Icons';
//import { ToolTip } from '../../Common';

export default ({
  images = [],
  name = '',
  sku = '',
  unit = '',
  id = null,
  showSKU = true,
  showModalUponClick = false,
  showUnit = false,
  showExtraDescription = false,
  value = null,
  index = null,
  currentitems = [],
  onChangeValue = () => null,
  showExtraDisableDescription = false,
  unSyncIcon = false,
  unSyncText = '',
  association = [],
}) => {
  const [item, showItem] = React.useState(false);
  function viewItemModal() {
    return (
      <CustomModal
        Icon={NewItemIcon}
        showModal={item}
        title={name ? name : 'View Item'}
        className="view_items_popup"
        modaltitle="inventory_modal_title"
        renderActions={false}
        size="large"
        onClose={() => showItem(false)}
        linkTo={`items/${id}`}
      >
        <ItemDetails id={id} inModal={true} className="so-view-ttems-tabs" />
      </CustomModal>
    );
  }
  return (
    <Fragment>
      {viewItemModal()}
      {showModalUponClick === true ? (
        <span
          className="list-table--item--col"
          style={{ flexDirection: 'column' }}
        >
          <div
            onClick={() => showItem(true)}
            style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
          >
            <img
              onClick={() => showItem(true)}
              src={imagePath(images && images[0])}
              alt={name}
            />
            <span onClick={() => showItem(true)}>
              <span
                onClick={() => showItem(true)}
                className="item_view_click_btn"
              >
                {name}
              </span>
              {showSKU && (
                <span className="small-td-info" onClick={() => showItem(true)}>
                  {sku}
                </span>
              )}
              {showUnit && (
                <span className="small-td-info" onClick={() => showItem(true)}>
                  {unit}
                </span>
              )}
            </span>
          </div>

          {showExtraDescription && (
            <textarea
              style={{
                width: '100%',
                marginTop: '10px',
                resize: 'none',
                scrollbarWidth: 'thin',
              }}
              placeholder="Add a description to your item. Max 255 characters are allowed."
              maxLength="254"
              id="global_textarea"
              className="item_modal_dec global_textarea"
              value={value ? value : ''}
              onChange={(event) =>
                onChangeValue(
                  handleExtraDescription(event.target.value, 254),
                  index,
                  currentitems
                )
              }
            />
          )}
          {showExtraDisableDescription && (
            <span
              className="small-td-info-description"
              onClick={() => showItem(true)}
            >
              {value}
            </span>
          )}
        </span>
      ) : (
        <span
          className="list-table--item--col"
          style={{ flexDirection: 'column' }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <img src={imagePath(images && images[0])} alt={name} />
            <span>
              {name}
              {showSKU && <span className="small-td-info">{sku}</span>}
              {showUnit && <span className="small-td-info">{unit}</span>}
            </span>
            {unSyncIcon && unSyncText && (
              <>
                <span className="icon_div">
                  <MdOutlineSyncProblem style={{ cursor: 'pointer' }}/>
                  <div className="tooltip_tooltip unSynTooltip">
                    {unSyncText}
                  </div>
                </span>
              </>
            )}
          </div>
          {showExtraDescription && (
            <textarea
              style={{
                width: '100%',
                marginTop: '10px',
                resize: 'none',
                scrollbarWidth: 'thin',
              }}
              maxLength="254"
              placeholder="Add a description to your item. Max 255 characters are allowed."
              className="item_modal_dec global_textarea"
              value={value ? value : ''}
              onChange={(event) =>
                onChangeValue(
                  handleExtraDescription(event.target.value, 254),
                  index,
                  currentitems
                )
              }
            />
          )}
          {showExtraDisableDescription && (
            <span className="small-td-info-description in-modal">{value}</span>
          )}
        </span>
      )}
    </Fragment>
  );
};
