import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { restRequest } from '../../../Helpers/RequestHelper'

import NotFound from '../../../Components/NotFound/NotFound'
import Loader from '../../../Components/Loader/Loader'
import CurrencyFormEnhanced from './CurrencyFormEnhancer'

import './CurrencyForm.css'
import {checkError} from "../../../Helpers/AuthHelper";

class CurrencyForm extends Component {
  type = this.props.type || 'add'
  id = this.props.id || this.props.match.params.id || null 

  state = {
    currency: {
      name: '',
      currencyCode: '',
      symbol: '',
      exchangeRate: null,
    },
    currencyId: null,
    currencyListFromDB: [],
    loading: true,
    notFound: false,
  }

  componentDidMount() {
    this.fetchData()
  }

  async fetchData() {
    switch (this.type) {
      case 'edit':
        await this.fetchCurrencyEditData()
        break
      case 'add':
      default:
        await this.fetchCurrencyList()
    }

    this.setState({
      loading: false
    })
  }

  fetchCurrencyList() {
    return restRequest('get', 'currencies/create').then(currencyList => {
      this.setState({
        currencyListFromDB: currencyList,
      })
    }).catch((error) => {
      checkError(error)
      this.setState({currencyListFromDB: []})
    })
  }

  fetchCurrencyEditData() {
    this.setState({loading: true})
    return restRequest('get', `currencies/${this.id}/edit`).then(res => {
      if(!res.name) {
        this.setState({
          notFound: true
        })
        return
      }

      this.setState({
        currency: {
          ...this.state.currency,
          name: res.name,
          currencyCode: res.currency_code,
          symbol: res.symbol,
          exchangeRate: res.exchange_rate,
        }
      })
    }).catch((error) => {
      checkError(error,this.props.handleToast)
    })
  }

  submitRequestType() {
    switch (this.type) {
      case 'edit':
        return 'put'
      case 'add':
      default:
        return 'post'

    }
  }

  submitRequestUrl() {
    switch (this.type) {
      case 'edit':
        return `currencies/${this.id}`
      case 'add':
      default:
        return 'currencies'
    }
  }

  successMessage = () => {
    if (this.type === 'add') {
      this.props.handleToast("Currency created successfully", "success")
    }
    else if (this.type === 'edit') {
      this.props.handleToast("Currency has been updated", "success")
    }
  }

  renderForm() {
    const { currency, currencyListFromDB, currencyId } = this.state
    const { history, handleToast } = this.props
    return(
      <CurrencyFormEnhanced 
        type={this.type}
        currency={currency}
        currencyId={currencyId}
        currencyListFromDB={currencyListFromDB}
        history={history}
        successMessage={this.successMessage}
        requestType={this.submitRequestType()}
        requestUrl={this.submitRequestUrl()}
        handleToast={handleToast}
      />
    )
  }

  render() {
    if(this.state.loading) return <Loader />
    if(this.state.notFound) return <NotFound />
    return this.renderForm()
  }
}

export default withRouter(CurrencyForm)