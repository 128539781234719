import React from 'react'

export default ({
  className = ''
}) => (
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 15.729 16.444">
  <g transform="translate(-975 -238)">
    <g transform="translate(975 238)">
      <path d="M11.615-18,8.04-15.14h7.15Zm-5.362.715-2.5,3.329v12.4H19.479v-12.4l-2.5-3.329H13.4l1.787,1.43h1.072l1.072,1.43H5.895l1.072-1.43H8.04l1.787-1.43ZM5.18-13H18.049V-2.986H5.18Z" transform="translate(-3.75 18)" fill="#222629"/><path d="M3.919,4.9a.3.3,0,0,0-.22-.5H2.9V.3A.3.3,0,0,0,2.6,0H.15A.15.15,0,0,0,.044.256l.7.7A.172.172,0,0,0,.85,1H1.9V4.4H1.1a.3.3,0,0,0-.22.5l1.3,1.4a.3.3,0,0,0,.44,0Z"
            transform="translate(10.834 8.1) rotate(90)" fill="#222629"/>
    </g>
  </g>
</svg>
)
