import React, { Component } from 'react';

import { hasPermissionCustom } from '../../../Helpers/CustomPermission';
import { restRequest } from '../../../Helpers/RequestHelper';

import AccessDenied from '../../AccessDenied/AccessDenied';
import PlanExceeded from '../../PlanExceeded/PlanExceeded';
import WithContext from '../../../Context/UserContextHOC';
import UserFormEnhanced from '../UserForm/UserFormEnhancer';
import './Invite.css';
import Loader from '../../Loader/Loader';
import { checkError } from '../../../Helpers/AuthHelper';

class InviteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        name: '',
        email: '',
        role: '',
      },
      rolesList: [],
      loading: true,
      planExceededFlag: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    const { inviteId, fromUserTab } = this.props;
    const api = fromUserTab
      ? `organizations/users/${inviteId}/edit`
      : `invites/${inviteId}/edit`;
    if (inviteId) {
      // edit form
      if (fromUserTab) {
        await restRequest('get', api)
          .then((res) => {
            this.setState({
              user: {
                email: res.user_info.email,
                name: res.user_info.invite_name,
                role: res.user_info.role_id,
              },
              rolesList: res.roles,
              loading: false,
            });
          })
          .catch((error) => {
            checkError(error);
          });
      } else {
        await restRequest('get', api)
          .then((res) => {
            this.setState({
              user: {
                email: res.invitee_details.email,
                name: res.invitee_details.name,
                role: res.invitee_details.role_id,
              },
              rolesList: res.roles,
              loading: false,
            });
          })
          .catch((error) => {
            checkError(error);
          });
      }
    } else {
      await restRequest('get', 'invites/create')
        .then((roleResponse) => {
          this.setState({
            rolesList: roleResponse.data,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          if (error && error.response.data.status === false) {
            checkError(error, this.props.handleToast);
            //this.props.handleToast(error , 'error')
            this.setState({ planExceededFlag: error.response.data.status });
          }
        });
    }
  }

  checkPermissions() {
    const { inviteId } = this.props;

    if (inviteId && !hasPermissionCustom('invite', 'Edit')) {
      return <AccessDenied className="small" type="section" />;
    }
    if (!hasPermissionCustom('invite', 'Create')) {
      return <AccessDenied className="small" type="section" />;
    }

    return this.renderForm();
  }

  renderForm() {
    let title = this.props.inviteId ? 'Edit Invite Form' : 'Invite Form';
    title = this.props.fromUserTab ? 'Edit User' : title;
    const { user, rolesList, loading, planExceededFlag } = this.state;
    const {
      fromUserTab,
      inviteId,
      toggleInvite,
      onSubmitInvite,
      handleToast,
      setPrompt,
      handleConfirmFormInviteForm,
    } = this.props;
    if (loading) return <Loader />;
    if (planExceededFlag === false) return <PlanExceeded />;
    return (
      <UserFormEnhanced
        title={title}
        user={{ ...user }}
        fromUserTab={fromUserTab}
        inviteId={inviteId}
        rolesList={rolesList}
        toggleInvite={toggleInvite}
        onSubmitInvite={onSubmitInvite}
        handleToast={handleToast}
        handleConfirmFormInviteForm={handleConfirmFormInviteForm}
        setPrompt={setPrompt}
      />
    );
  }

  render() {
    return this.checkPermissions();
  }
}

export default WithContext(InviteForm);
