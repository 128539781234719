import React from 'react'

export default ({
  className = ''
}) => (
<svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34"><defs>
  </defs>
  <g transform="translate(-303.846 -202.846)">
    <circle  fill="#fff" cx="17" cy="17" r="17" transform="translate(303.846 202.846)"/>
    <path fill="#3bb54a" d="M7.472-.369a1.1,1.1,0,0,0,1.556,0l12.65-12.65a1.1,1.1,0,0,0,0-1.556l-1.556-1.556a1.1,1.1,0,0,0-1.556,0L8.25-5.814,3.433-10.631a1.1,1.1,0,0,0-1.556,0L.322-9.075a1.1,1.1,0,0,0,0,1.556Z" transform="translate(309.846 227.846)"/>
    </g>
    </svg>
  )