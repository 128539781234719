import React, { Component } from 'react';

import ContactPersonForm from '../../../../../Components/Form/ContactPersonForm/ContactPersonForm';

import './AddContactPerson.css';

class AddContactAddress extends Component {
    state = {
        disabledAddressForms: [],
        billingAddress: {},
    };

    handleAddressForm = (event) => {
        let stateName = event.target.name;
        let value = event.target.value;
        this.setState({ [stateName]: value });
    };
    render() {
        return (
            <ContactPersonForm
                type="add"
                contactId={this.props.contactId}
                onSubmit={this.props.onSubmit}
                onCancel={this.props.onCancel}
                handleToast={this.props.handleToast}
                billingAddress={this.state.billingAddress}
            />
        );
    }
}

export default AddContactAddress;
