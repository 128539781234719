export const PrintReportsUrls = (from = '', queryString = '') => {
  switch (from) {
    case 'inventry_details':
      return `report/inventory/details/pdfmobile${queryString}`;
    case 'inventory_valuation':
      return `report/inventory/valuation/pdfmobile${queryString}`;
    case 'inventory_fifo':
      return `report/inventory/fifo-cost-lot-tracking/pdfmobile${queryString}`;
    case 'warehouse_report':
      return `report/warehouse/pdfmobile${queryString}`;
    case 'purchase_order_report':
      return `report/purchase/pdfmobile${queryString}`;
    case 'bill_report':
      return `report/bill/pdfmobile${queryString}`;
    case 'payment_made_report':
      return `report/bill/payment/pdfmobile${queryString}`;
    case 'receive_report':
      return `report/receive/pdfmobile${queryString}`;
    case 'sales_report':
      return `report/sales/pdfmobile${queryString}`;
    case 'invoice_report':
      return `report/invoice/pdfmobile${queryString}`;
    case 'payment_receive_report':
      return `report/invoice/payment/pdfmobile${queryString}`;
    case 'refund_report':
      return `report/refund/pdfmobile${queryString}`;
    case 'package_report':
      return `report/package/pdfmobile${queryString}`;
    case 'sale_return_report':
      return `report/saleReturns/pdfmobile${queryString}`;
    case 'customer_report':
      return `report/customer/pdfmobile${queryString}`;
    case 'sale_by_item':
      return `report/salesbyitem/pdfmobile${queryString}`;
    case 'sale_by_sales_person':
      return `report/salesbysalesperson/pdfmobile${queryString}`;
    case 'order_fulfilled_by_items':
      return `report/orderfulfillmentbyitem/pdfmobile${queryString}`;
    default:
      return null;
  }
};

export const DeleteReportsUrls = (from = '', folderName = '') => {
  const array = folderName && folderName.split('_');
  switch (from) {
    case 'inventry_details':
      return `report/inventory/details/pdfmobiledelete/${array[2]}`;
    case 'inventory_valuation':
      return `report/inventory/valuation/pdfmobiledelete/${array[2]}`;
    case 'inventory_fifo':
      return `report/inventory/fifo-cost-lot-tracking/pdfmobiledelete/${array[2]}`;
    case 'warehouse_report':
      return `report/warehouse/pdfmobiledelete/${array[2]}`;
    case 'purchase_order_report':
      return `report/purchase/pdfmobiledelete/${array[2]}`;
    case 'bill_report':
      return `report/bill/pdfmobiledelete/${array[2]}`;
    case 'payment_made_report':
      return `report/bill/payment/pdfmobiledelete/${array[2]}`;
    case 'receive_report':
      return `report/receive/pdfmobiledelete/${array[2]}`;
    case 'sales_report':
      return `report/sales/pdfmobiledelete/${array[2]}`;
    case 'invoice_report':
      return `report/invoice/pdfmobiledelete/${array[2]}`;
    case 'payment_receive_report':
      return `report/invoice/payment/pdfmobiledelete/${array[2]}`;
    case 'refund_report':
      return `report/refund/pdfmobiledelete/${array[2]}`;
    case 'package_report':
      return `report/package/pdfmobiledelete/${array[2]}`;
    case 'sale_return_report':
      return `report/saleReturns/pdfmobiledelete/${array[2]}`;
    default:
      return null;
  }
};

export const getImagesUrl = (from = '', id = null) => {
  switch (from) {
    case 'salesorders':
      return `salesorders/${id}/pdfmobile`;
    case 'packages':
      return `packages/${id}/pdfmobile`;
    case 'invoices':
      return `invoices/${id}/pdfmobile`;
    case 'advancepayment':
      return `advancepayment/${id}/pdfmobile`;
    case 'salesreturns':
      return `salesreturns/${id}/pdfmobile`;
    case 'creditnotes':
      return `creditnotes/${id}/pdfmobile`;
    case 'purchaseorders':
      return `purchaseorders/${id}/pdfmobile`;
    case 'bills':
      return `bills/${id}/pdfmobile`;
    default:
      return null;
  }
};
export const getDeleteImageUrl = (folderName = '', id = null, from = '') => {
  const array = folderName && folderName.split('_');
  switch (from) {
    case 'salesorders':
      return `salesorders/${id}/pdfmobiledelete/${array[2]}`;
    case 'packages':
      return `packages/${id}/pdfmobiledelete/${array[2]}`;
    case 'invoices':
      return `invoices/${id}/pdfmobiledelete/${array[2]}`;
    case 'advancepayment':
      return `advancepayment/${id}/pdfmobiledelete/${array[2]}`;
    case 'salesreturns':
      return `salesreturns/${id}/pdfmobiledelete/${array[2]}`;
    case 'creditnotes':
      return `creditnotes/${id}/pdfmobiledelete/${array[2]}`;
    case 'purchaseorders':
      return `purchaseorders/${id}/pdfmobiledelete/${array[2]}`;
    case 'bills':
      return `bills/${id}/pdfmobiledelete/${array[2]}`;
    default:
      return null;
  }
};
