import Decrypt from './StringManipulation/Decrypt';
import Encrypt from './StringManipulation/Encrypt';
/*export function setValueInLS(key, value) {
  sessionStorage.setItem(key, value)
}
export function setObjInLS(key, value) {
  key === 'role' ? localStorage.setItem(key,JSON.stringify(value)) : sessionStorage.setItem(key,JSON.stringify(value))
}
export function getObjFromLS(key) {
  const value = key === 'role' ? JSON.parse(localStorage.getItem(key)) :JSON.parse(sessionStorage.getItem(key))
  return value
}
export function getValueLS(key) {
  return sessionStorage.getItem(key)
}
export function getStringValueFromLS(key) {
  const value = sessionStorage.getItem(key)
  return value
}
export function removeValueFromLS(key) {
  const value = sessionStorage.removeItem(key)
}
export function removeObjFromLS(key) {
  sessionStorage.removeItem(key)
}
export function getIntValueFromLS(key) {
  const value = parseInt(sessionStorage.getItem(key))
  return value
}*/

export function setValueInLS(key, value) {
  sessionStorage.setItem(key, value);
}
export function setObjInLS(key, value) {
  key === 'role' || key === 'se_token'
    ? localStorage.setItem(Encrypt(key), Encrypt(JSON.stringify(value)))
    : sessionStorage.setItem(Encrypt(key), Encrypt(JSON.stringify(value)));
}
export function getObjFromLS(key) {
  const value =
    key === 'role' || key === 'se_token'
      ? Decrypt(localStorage.getItem(Decrypt(key)))
      : Decrypt(sessionStorage.getItem(Decrypt(key)));
  return value && JSON.parse(value);
}
export function deleteValueFromLS(key) {
  return Decrypt(localStorage.removeItem(Decrypt(key)));
}
export function getValueLS(key) {
  return Decrypt(sessionStorage.getItem(Decrypt(key)));
}
export function getStringValueFromLS(key) {
  const value = sessionStorage.getItem(key);
  return value;
}
export function removeValueFromLS(key) {
  return sessionStorage.removeItem(key);
}
export function removeObjFromLS(key) {
  Decrypt(sessionStorage.removeItem(Decrypt(key)));
}
export function getIntValueFromLS(key) {
  const value = parseInt(sessionStorage.getItem(key));
  return value;
}

export function setObjInSS(key, value) {
  sessionStorage.setItem(key, JSON.stringify(value));
}

export function getObjFromSS(key) {
  const value = JSON.parse(sessionStorage.getItem(key));
  return value;
}

export function removeObjFromSS(key) {
  return sessionStorage.removeItem(key);
}
