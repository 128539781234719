import React from 'react';

import { AuthContainer, logout, Loader, orgStaticContent } from './index';

const CenterContent = ({
  children = '',
  user = {},
  props,
  loading = false,
}) => {
  const logoutHandler = () => {
    logout();
    props.history.push('/');
  };

  const AuthContainerLogout = () => {
    return (
      <>
        <p className="auth--logout">
          Welcome <span className="semi-bold">{user?.name}</span> |
          <button
            className="button--no-background link"
            onClick={logoutHandler}
          >
            Logout
          </button>
        </p>
        <h6>{orgStaticContent.noAccessTitle}</h6>
      </>
    );
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <AuthContainer
          // title={'Select Organization'}
          logout={AuthContainerLogout()}
        >
          {children}
        </AuthContainer>
      )}
    </>
  );
};

export default CenterContent;
