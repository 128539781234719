import React from 'react';
import './Footer.css';

class Footer extends React.Component {
  render() {
    return (
      <div className="footer-component">
        <div className="clearfix"></div>
        <h5 className="text-center">Copyright Rapid-IMS @2016</h5>
      </div>
    );
  }
}

export default Footer;
