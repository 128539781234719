import React from 'react'

export default ({
  className = '',
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="21.548" height="26.672" viewBox="0 0 21.548 26.672">
    <g transform="translate(-1645.786 -1155.634)">
      <g transform="translate(1646.286 1155.634)">
        <path fill="#33a348"
              d="M5.862,21.813v-9H3.421A1.313,1.313,0,0,1,2.2,11.589V10.52A3.925,3.925,0,0,1,6.32,6.4H13.8a3.925,3.925,0,0,1,4.12,4.12v9H20.36a1.313,1.313,0,0,1,1.221,1.221v.916a4.312,4.312,0,0,1-4.12,4.12H9.983S5.71,25.933,5.862,21.813Zm2.594.153a1.486,1.486,0,0,0,1.221,1.526V21.966a2.41,2.41,0,0,1,2.442-2.442h3.357v-9A1.529,1.529,0,0,0,13.95,8.994H8.457Zm9,1.373a1.486,1.486,0,0,0,1.526-1.221h-6.1a.688.688,0,0,0-.458.458v.763ZM5.862,10.215V8.994a1.745,1.745,0,0,0-1.068,1.221Zm4.12,6.257a1.313,1.313,0,0,1-1.221-1.221A1.313,1.313,0,0,1,9.983,14.03h3.662a1.313,1.313,0,0,1,1.221,1.221,1.313,1.313,0,0,1-1.221,1.221Zm0-3.662a1.313,1.313,0,0,1-1.221-1.221,1.313,1.313,0,0,1,1.221-1.221h3.662a1.507,1.507,0,0,1,1.526,1.221,1.313,1.313,0,0,1-1.221,1.221Z"
              transform="translate(-2.2 0.388)"/>
        <path fill="#306ab4"
              d="M15.586,13.3a.9.9,0,0,1-.93-.814,1,1,0,0,1,.93-.93h1.627V4.7a.25.25,0,0,0-.232-.232h-5V4.7c.116.349.116.7.349.93a.218.218,0,0,1,0,.349c-.116.116-.116.116-.233.116h-.116L7.216,3.422c-.116,0-.116-.116-.116-.232a.406.406,0,0,1,.116-.232L11.866.4h.116c.116,0,.116,0,.232.116a.427.427,0,0,1,.116.349,3.788,3.788,0,0,0-.465,1.511h5.58a1.867,1.867,0,0,1,1.279.581.9.9,0,0,1,.232.93v7.9A1.465,1.465,0,0,1,17.446,13.3Z"
              transform="translate(2.045 -0.4)"/>
      </g>
    </g>
  </svg>
)
