import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withFormik, FieldArray } from 'formik';
import moment from 'moment';
import DeleteIcon from '../../../Assets/General/DeleteIcon';
import { restRequest } from '../../../Helpers/RequestHelper';
import {
  moreThanZero,
  required,
  ValidateDate,
  ValidateRequired,
  ValidateMax50,
  ValidateMax10Billion,
} from '../../../Helpers/Validator';

import Field from '../Field/Field';
import Form from '../Form';
import { getError, validate, formSubmit } from '../FormHelper';
import ListTableImageColumn from '../../ListTable/ListTableImageColumn';
import FormAction from '../FormAction';
import AddItem from '../AddItem';
import Quantity from '../../Fragments/Quantity/Quantity';
import ErrorFocus from '../ErrorFocus';
import Loader from '../../Loader/Loader';
import { getObjFromLS, setObjInLS } from '../../../Helpers/LocalStorage';
import { checkError } from '../../../Helpers/AuthHelper';
import { confirmationAlert } from '../../../Helpers';
import { validateSalesReturnForm } from '../../../Helpers/FormValidator';
import DragDropItems from '../../../Common/DragDrop';
import { Draggable } from 'react-beautiful-dnd';
import { MdDragIndicator } from '../../../Common/Icons';

let restRequestStop = true;
const getYupValidationSchema = () => {
  return Yup.object().shape({
    packageNo: required(ValidateMax50),
    // salesOrderId: required(ValidateNumber),
    packageDate: required(ValidateDate),
    items: Yup.array()
      .of(
        Yup.object().shape({
          name: ValidateRequired,
          quantity: required(
            moreThanZero(ValidateMax10Billion).integer('Invalid Value')
          ),
        })
      )
      .min(1, 'At least one item is required'),
  });
};

const formEnhancer = withFormik({
  validate: validate(getYupValidationSchema),
  mapPropsToValues: ({ packageInfo }) => ({
    ...packageInfo,
  }),
  mapPropsToStatus: (props) => ({
    itemsList: props.itemsList,
    emptyItem: props.emptyItem,
  }),
  handleSubmit: (values, bag) => {
    handleFormSubmit(values, bag);
  },
});

const handleFormSubmit = (
  values,
  {
    setSubmitting,
    props: {
      submitRequestType,
      submitRequestUrl,
      successMessage,
      inModal,
      close,
      onSubmit,
      history,
      handleToast,
      salesReturnId,
    },
  }
) => {
  if (restRequestStop) {
    setSubmitting(true);
    return false;
  }
  if (submitRequestType() === 'post') {
    values.allItems.forEach((item) => {
      var rem_item = values.items.filter(
        (list) =>
          list.id === item.id &&
          list.salesOrderItemDetailId === item.salesOrderItemDetailId
      );
      item.quantity = rem_item.length > 0 ? rem_item[0].quantity : 0;
    });
    values.items = values.allItems;
  }
  if (!restRequestStop) {
    /*if (inModal) {
      close();
    }*/
    let payload = {
      sales_return_id: parseInt(salesReturnId, 10),
      return_receives_no: values.packageNo,
      customer_id: values.customerId,
      sale_order_id: values.saleOrderId,
      return_receive_date: values.packageDate ? values.packageDate : null,
      items: values.items.map((item, index) => ({
        item_id: item.id,
        /*warehouse_id:
          item.warehouse_idd !== null ? item.warehouse_idd : item.warehouse_id,*/
        target_warehouse_id: item.target_warehouse_id,
        warehouse_id: item.original_warehouse_id,
        sales_order_item_detail_id: item.salesOrderItemDetailId,
        damaged_reason: null,
        package_item_detail_id: item.packageItemDetailId,
        quantity: item.quantity || 0,
      })),
    };
    // console.log('payload-----', payload)
    // setSubmitting(false);
    // return ;
    restRequest(submitRequestType(), submitRequestUrl(), payload)
      .then(() => {
        successMessage();
        if (inModal) {
          let dataFromLS = getObjFromLS('module');
          window.location.pathname.includes('/salesorders')
            ? setObjInLS('module', {
              ...dataFromLS,
              queryParam: `${dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
                }salesReturn=true`,
            })
            : setObjInLS('module', {
              ...dataFromLS,
              queryParam: `${dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
                }returnReceive=true`,
            });
          return history.push('/r');
        } else {
          history.push('/salesreturns');
        }
      })
      .catch((error) => {
        checkError(error, handleToast);
        setSubmitting(false);
      });
  } else {
    setSubmitting(false);
  }
};

const PackageForm = ({
  // props from formik
  values,
  status,
  handleSubmit,
  setSubmitting,
  handleChange,
  handleBlur,
  setFieldValue,
  setStatus,
  setValues,
  isSubmitting,
  submitCount,
  errors,
  touched,
  setFieldTouched,

  // custom props
  title,
  inModal,
  warehouseListRecieve,
  genericWarehouseId,
  warehouseResult,
  type,
  salesOrderList,
  history,
  close,
  ...props
}) => {
  const { itemsList, emptyItem } = status;

  var packageFakeItem = false;

  const [staticVal, setStaticValues] = useState({});
  useEffect(() => {
    setStaticValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, staticVal]);

  const handleAlert = () => {
    let { checkout } = validateSalesReturnForm(values, staticVal);
    if (!checkout) props.handlePrompt();
    else props.handlePrompt(false);
  };

  const handleSelectedItem = (option, index) => {
    const updatedItems = [
      ...Object.assign([...values.items], { [index]: option }),
    ];
    setFieldValue('items', updatedItems);
    setStatus({
      ...status,
      itemsList: status.itemsList.filter((item) => item.salesOrderItemDetailId !== option.salesOrderItemDetailId),
    });
  };

  const renderAddItem = () => {
    const { items } = values;
    items.forEach((item) => {
      if (item.id == null) {
        packageFakeItem = true;
      }
    });
    if (packageFakeItem) {
      return '';
    } else {
      return (
        <AddItem
          onClick={() => setFieldValue('items', [...values.items, emptyItem])}
        />
      );
    }
  };

  const handleItemQuantityChange = (quantityValue, index) => {
    // Price_Quantity_Limit
    // price and quantity limit from 5 digits to 10 digits
    let quantity = quantityValue.floatValue;
    if (quantity > 0) {
      let quantString = quantity.toString();
      if (quantString.length > 10) {
        quantString = quantString.slice(0, 10);
      }
      quantity = parseInt(quantString);
    }
    setSubmitting(false);
    setFieldValue('items[' + index + '].quantity', quantity);
    // setFieldValue('quantity_change', true);
  };

  const getvals = () => {
    if (itemsList.length === 0) {
      setAddItem(false);
    }
    setWarehouseNames([]);
    if (yikes[0] !== null) {
      for (let i = 0; i < yikes.length; i++) {
        for (let j = 0; j < warehouseList.length; j++) {
          if (yikes[i] === warehouseList[j].id) {
            let arr = [];
            arr = warehouseNames;
            arr.push(warehouseList[j].name);
            setWarehouseNames(arr);
          }
        }
      }
    } else {
      for (let i = 0; i < values.items.length; i++) {
        for (let j = 0; j < warehouseList.length; j++) {
          if (genericWarehouseId === warehouseList[j].id) {
            let arr = [];
            arr = warehouseNames;
            arr.push(warehouseList[j].name);
            setWarehouseNames(arr);
          }
        }
      }
    }

    let arr = [];
    arr = warehouseNames;
    arr.splice(yikes.length);
    setWarehouseNames(arr);
  };

  // RemHere
  const handleRemoveItem = (removeIndex, currentItem) => {
    setItemDeleteFlag(true);
    setWarehouseNamesFlag(true);
    setAddItem(true);
    let updatedItems = values.items.filter((_, index) => index !== removeIndex);
    setFieldValue('items', updatedItems);
    packageFakeItem = false;
    if (!currentItem.id) return;

    setStatus({
      ...status,
      itemsList: [...status.itemsList, currentItem],
    });
  };

  const [flag, setflag] = React.useState(false);
  const [, setItemDeleteFlag] = React.useState(false);
  const [, setWarehouseNamesFlag] = React.useState(false);
  const [addItem, setAddItem] = React.useState(false);
  const [warehouseList, setWarehouseList] = React.useState({});
  const [warehouseNames, setWarehouseNames] = React.useState([]);
  const [yikes, setyikes] = React.useState([]);

  useEffect(() => {
    setWarehouseList(warehouseResult);
    setyikes(warehouseListRecieve);
    setflag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(values.items);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFieldValue('items', items);
  };

  const handleArrowsKey = (e, index, fieldname) => {
    if (e.key === 'ArrowDown' && index + 1 < values?.items?.length) {
      const element = document.getElementById(
        `items[${index + 1}].${fieldname}`
      );
      setTimeout(() => {
        element.select();
        element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
      }, 100);
    }
  };

  const handleArrowUpKey = (e, index, fieldname) => {
    if (e.key === 'ArrowUp' && index - 1 >= 0) {
      const element = document.getElementById(
        `items[${index - 1}].${fieldname}`
      );
      element.select();
      element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
    }
  };

  const renderSelectedItemsTable = () => {
    const { items } = values;
    if (warehouseList.length > 0) {
      for (let i = 0; i < items.length; i++) {
        for (let j = 0; j < warehouseList.length; j++) {
          if (items[i].warehouse_idd === warehouseList[j].id) {
            items[i].warehouse_idd = warehouseList[j].name;
            items[i].warehouse_id = warehouseList[j].id;
          }
        }
      }
    }

    restRequestStop = items.some((item) => {
      return item.quantity < 1 || item.quantityForErrorChec < item.quantity;
    });
    return (
      <div className="package-item-info sales_return_table float-left w-100">
        {!flag ? <Loader /> : null}
        <table className="table sub_tabs_transaction_main details-table return_recieve_sales_order">
          <thead className="table-header">
            <tr className="table--row">
              <th>Items</th>
              <th>Warehouse</th>
              <th className="right-align">Return Qty.</th>
              <th className="right-align">Already Received</th>
              <th className="right-align">Currently Receiving</th>
            </tr>
          </thead>
          {/* <tbody onLoad={getvals()}> */}
          <DragDropItems handleOnDragEnd={handleOnDragEnd} onLoad={getvals()}>
            {items.map((item, index) => {
              return (
                <Draggable
                  key={item.dragDropID}
                  draggableId={item.dragDropID}
                  index={index}
                >
                  {(provided) => (
                    <tr
                      className="custom_height_return_recieve"
                      key={index}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <td className={item.id ? '' : 'no padding'}>
                        <div className="__dragDrop">
                          <div
                            {...provided.dragHandleProps}
                            className={
                              values.items.length >= 2
                                ? '__dragDrop_iconParent'
                                : '__dragDrop_iconParent notDisplay'
                            }
                            tabIndex="-1"
                          >
                            <MdDragIndicator />
                          </div>
                          <div className="__drag-righ">
                            {item.id ? (
                              <ListTableImageColumn
                                name={item.name}
                                images={item.images}
                                sku={item.sku}
                              />
                            ) : (
                              <Field
                                type="dropdown"
                                value={item.id}
                                options={itemsList}
                                dropdownValue="id"
                                dropdownLabel="name"
                                placeholder="Select Item"
                                additionalSearchFilter="sku,upc,mpn,ean,isbn"
                                clearable={false}
                                withImage={true}
                                handleChange={(option) =>
                                  handleSelectedItem(option, index)
                                }
                                error={getError(
                                  errors,
                                  touched,
                                  `items[${index}].name`
                                )}
                                onBlur={() =>
                                  setFieldTouched(`items[${index}].name`, true)
                                }
                              />
                            )}
                          </div>
                        </div>
                      </td>

                      <td className="right-align">
                        <Field
                          size="large"
                          id="warehouseId"
                          value={
                            typeof item.warehouse_idd === 'string'
                              ? item.warehouse_idd
                              : ''
                          }
                          disabled={true}
                          clearable={false}
                          required
                          error={touched.warehouseId && errors.warehouseId}
                        />
                        {
                          <div className="table-extra-data right-align">
                            {item.inventoryType === 'noninventory'
                              ? 'Un-tracked item'
                              : ''}
                          </div>
                        }
                      </td>
                      <td className="right-align">
                        <Quantity quantity={item.ordered} unit={item.unit} />
                      </td>
                      <td className="right-align">
                        <Quantity
                          quantity={item.received_quantity}
                          unit={item.unit}
                        />
                      </td>
                      <td className="right-align no-padding with-close--btn add_return_recieve_modal">
                        <Field
                          size="small"
                          /*disabled = {item.quantity > 0 ? false : true}*/
                          type="number"
                          value={item.quantity}
                          decimalScale={6}
                          id={`items[${index}].quantity`}
                          allowNegative={false}
                          thousandSeparator=","
                          onValueChange={(value) =>
                            handleItemQuantityChange(value, index)
                          }
                          error={
                            (item.quantity === 0 && (
                              <p style={{ marginBottom: 'auto' }}>
                                {' '}
                                Cannot be Zero
                              </p>
                            )) ||
                            (item.quantityForErrorChec < item.quantity && (
                              <p style={{ marginBottom: '1px' }}>
                                {' '}
                                Equal or less than returned Qty.
                              </p>
                            ))
                          }
                          onBlur={handleBlur}
                          onKeyDown={(e) =>
                            handleArrowsKey(e, index, 'quantity')
                          }
                          onKeyUp={(e) =>
                            handleArrowUpKey(e, index, 'quantity')
                          }
                        />
                        <button
                          type="button"
                          disabled={items.length === 1}
                          className="close-btn button"
                          style={{ marginTop: '20px' }}
                          onClick={() => handleRemoveItem(index, item)}
                        >
                          <DeleteIcon className="bin" />
                        </button>
                      </td>
                    </tr>
                  )}
                </Draggable>
              );
            })}
          </DragDropItems>
          {/* </tbody> */}
        </table>
      </div>
    );
  };

  return (
    <Form
      title={title}
      className="package-form return_recieve_popup"
      onSubmit={(event) =>
        formSubmit(event, values.items, setFieldValue, handleSubmit)
      }
    >
      <div className="form-fields-container d-flex flex-wrap">
        <Field
          className="flex-25"
          name="Return Receive No."
          id="returnReceiveNo"
          placeholder="Return Receive No"
          value={values.packageNo}
          handleChange={handleChange}
          onBlur={handleBlur}
          required
          disabled={true}
          error={touched.packageNo && errors.packageNo}
        />

        <Field
          type="date"
          className="flex-25"
          name="Sales Return Date"
          id="saleReturnDate"
          value={values.saleReturnData}
          handleChange={(date) => setFieldValue('packageDate', date)}
          onBlur={() => setFieldTouched('packageDate', true)}
          required
          disabled={true}
          error={touched.packageDate && errors.packageDate}
        />
        <Field
          type="date"
          className="flex-25"
          name="Return Receive Date"
          id="returnReceiveDate"
          value={
            typeof values.packageDate == 'string'
              ? moment(values.packageDate)
              : values.packageDate
          }
          minDate={
            typeof values.saleReturnData == 'string'
              ? moment(values.saleReturnData)
              : values.saleReturnData
          }
          // value={values.packageDate}
          // minDate={values.saleReturnData}
          handleChange={(date) => {
            setFieldValue(
              'packageDate',
              date && date.format('YYYY-MM-DD 00:00:00')
            );
          }}
          onBlur={() => setFieldTouched('packageDate', true)}
          required
          error={touched.packageDate && errors.packageDate}
        />
      </div>
      <br />
      <div className="bold" style={{ marginTop: '10px' }}>
        Return Reason
      </div>
      <p className="word-break-break-all w-97">{values.returnReason}</p>
      <div className="form-field form-table--container">
        <FieldArray name="items" render={() => renderSelectedItemsTable()} />
        {addItem && <React.Fragment>{renderAddItem()} </React.Fragment>}
      </div>

      <FormAction
        disabled={isSubmitting}
        marginFromRight={2}
        onCancel={() => {
          if (inModal) {
            let response;
            const { checkout } = validateSalesReturnForm(values, staticVal);
            if (!checkout) response = confirmationAlert();
            if (response || checkout) close();
          } else history.push('/salesreturns');
        }}
      />
      <ErrorFocus />
    </Form>
  );
};

export default formEnhancer(PackageForm);
