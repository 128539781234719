import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonIcons } from '../index';

const LinkType = ({ menu }) => {
  const {
    tooTip = '',
    icon = '',
    label = false,
    to = '',
    mobileLable = '',
    mobileDimension = false,
  } = menu;

  return (
    <>
      <Link
        to={to}
        className={`${
          label ? 'checked-menu--button' : 'checked-menu--button--icon-only'
        }`}
        title={tooTip && tooTip}
      >
        {ButtonIcons[icon]()}
        {!mobileDimension && label && (
          <span className="checked-menu--label">{label}</span>
        )}
        {mobileDimension && mobileLable && (
          <span className="mobile-label">{mobileLable}</span>
        )}
      </Link>
    </>
  );
};
export default LinkType;
