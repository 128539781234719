import React, { Component } from 'react';

import { WarehouseIcon } from '../../Assets/Settings/SettingsIcon';

import WarehouseForm from '../../Components/Form/WarehouseForm/WarehouseForm';
import FormLayout from '../../Components/Layout/FormLayout';

class AddWarehouse extends Component {
  componentDidMount() {
    document.title = 'Add Warehouse';
  }

  renderWarehouseForm() {
    return <WarehouseForm type="add" handleToast={this.props.handleToast} />;
  }

  render() {
    return (
      <FormLayout
        title="Warehouses"
        Icon={WarehouseIcon}
        subtitle="Add New"
        closeUrl="/warehouses"
      >
        {this.renderWarehouseForm()}
      </FormLayout>
    );
  }
}

export default AddWarehouse;
