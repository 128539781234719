import React, { Component } from 'react';
import { Link, } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import queryString from 'query-string';

import { restRequest } from '../../Helpers/RequestHelper';
import {
  required,
  ValidateRequired,
  ValidateEmail,
} from '../../Helpers/Validator';
import { logout, setTokenRefreshToken } from '../../Helpers/AuthHelper';
import { MdCheck } from '../../Common/Icons';

import Form from '../../Components/Form/Form';
import Field from '../../Components/Form/Field/Field';

import AuthContainer from '../AuthContainer';
import Alert from '../../Components/Alert/Alert';
import { Loader } from '../../Components';
import { ssoSignInPath, ssosignoutPath } from '../../Helpers/UrlHelper';
//import { validToken } from '../../Helpers';

import './Login.css';
import { setObjInLS } from '../../Helpers';

const sso_connected =
  process.env.REACT_APP_SSO_ENABLED === 'false' ? false : true;
class Login extends Component {
  ssoLoginLink = ssoSignInPath();
  ssosignoutLink = ssosignoutPath();

  state = {
    type: 'password',
    email: null,
    link: false,
    crossed: false,
    loading: true,
  };

  componentDidMount() {
    const self = this;
    document.title = 'Seebiz | Inventory Home';
    if (localStorage.getItem('notFound')) {
      this.props.handleToast(
        'Due to another active session, you have been logged out from this device.',
        'error'
      );
      localStorage.removeItem('notFound');
      setTimeout(function () {
        self.logoutSso();
      }, 2000);
    } else {
      setTimeout(function () {
        self.adminSettingsHandler();
      }, 2000);
    }
  }

  adminSettingsHandler = () => {
    const { st = false, acc = '' } = queryString.parse(
      this.props.location.search
    );
    if (st) {
      this.props.history.replace('/login');
      restRequest('post', `auth/sso_login?token=${st}`)
        .then((res) => {
          setTokenRefreshToken(res.id_token, res.refresh_token, true);
          const resetCode = localStorage.getItem('reset_code');
          if (resetCode) {
            this.props.history.push(
              '/myaccount?changePassword=true&q=' + resetCode
            );
            localStorage.removeItem('reset_code');
          } else if (acc) {
            window.open(`${process.env.REACT_APP_WP_URL}`, '_self');
          } else {
            this.props.history.push('/');
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    } else {
      this.checkSsoSetting();
    }
  };

  logoutSso = () => {
    // restRequest('get', 'ssoSettings')
    //   .then((res) => {
    //console.log('i m here in login sso logout function', sso_connected);
    if (sso_connected) {
      window.location.href = this.ssosignoutLink;
      //this.setState({ loading: false });
    }
    // })
    // .catch((error) => {
    //   this.setState({ loading: false });
    // });
  };

  checkSsoSetting = () => {
    // restRequest('get', 'ssoSettings')
    // .then((res) => {
    //   res?.sso_connected
    if (sso_connected) {
      window.location.href = this.ssoLoginLink;
    } else {
      this.setState({ loading: false });
    }
    // })
    // .catch((error) => {
    //   this.setState({ loading: false });
    // });
  };
  handleReconfirm() {
    let data = new FormData();
    let emailVar = this.state.email;
    data.append('email', emailVar);
    restRequest('post', 'register/reconfirm', data)
      .then(() => {
        this.props.handleToast('Email sent successfully', 'success');
      })
      .catch((error) => {
        this.props.handleToast(error, 'error');
      });
  }

  handleSubmit(values, setSubmitting) {
    this.setState({ email: values.email.toLowerCase() });
    restRequest('post', 'auth/login', {
      email: values.email.toLowerCase(),
      password: values.password,
      remember_me: values.remember,
    })
      .then((res) => {
        setTokenRefreshToken(res.id_token, res.refresh_token, values.remember);
        const resetCode = localStorage.getItem('reset_code');
        if (resetCode) {
          this.props.history.push(
            '/myaccount?changePassword=true&q=' + resetCode
          );
          localStorage.removeItem('reset_code');
        } else {
          this.props.history.push('/');
        }
      })
      .catch((err) => {
        if (err.response.status === 419) {
          const userdetail = {
            email: this.state.email,
          }
          setObjInLS("userdetail", userdetail)
          return this.props.history.push('/userdeactivate');
        }
        this.props.handleToast(err, 'error');
        if (
          err.response &&
          err.response.status === 400 &&
          err.response.data.message.includes('confirmed')
        ) {
          this.setState({ link: true });
        }
        setSubmitting(false);
      });
  }

  togglePasswordView = () => {
    this.setState({
      type: this.state.type === 'text' ? 'password' : 'text',
    });
  };

  renderFooter() {
    return (
      <React.Fragment>
        <div className="auth--message">
          <h5>
            <Link to="/password/request">Forgot Password?</Link>
          </h5>
          <h5>
            Not a member? <Link to="/signup">Sign up</Link>
          </h5>
        </div>
        {this.state.link && (
          <h5>
            <div style={{ color: 'red' }}>
              Please activate your account first by confirming your email
              address
            </div>{' '}
            <br />
            Haven't received the confirmation email yet?{' '}
            <div
              // style={{ cursor: 'pointer', color: 'blue' }}
              onClick={() => this.handleReconfirm()}
              className="resend-text"
            >
              Resend Email
            </div>
          </h5>
        )}
      </React.Fragment>
    );
  }

  onClickHandler = () => {
    this.setState((state) => ({ crossed: !state.crossed }));
  };

  renderAlert() {
    const { location } = this.props;
    let { crossed } = this.state;
    if (
      location.state &&
      location.state.fromSignup &&
      location.state.invited &&
      !crossed
    ) {
      return (
        <Alert
          title="Please Check Your Email"
          onClickHandler={this.onClickHandler}
        >
          We have sent an email to{' '}
          <span
            className="color-inherit semi-bold"
            style={{ whiteSpace: 'pre' }}
          >
            {location.state.email}
          </span>{' '}
          with a link to confirm your account.
        </Alert>
      );
    }
    return null;
  }

  renderLoginForm() {
    return (
      <Formik
        initialValues={{ email: '', password: '', remember: true }}
        validationSchema={() =>
          Yup.object().shape({
            email: required(ValidateEmail),
            password: ValidateRequired,
          })
        }
        onSubmit={(values, { setSubmitting }) => {
          this.handleSubmit(values, setSubmitting);
        }}
        render={({
          values,
          errors,
          isSubmitting,
          setFieldValue,
          touched,
          handleBlur,
          handleSubmit,
          handleChange,
        }) => {
          return (
            <AuthContainer
              title="Sign in to Your Account"
              subtitle="Enter Login Credentials"
              footer={this.renderFooter()}
              afterLogo={this.renderAlert()}
            >
              <Form onSubmit={handleSubmit} className="auth--form">
                <Field
                  size="full"
                  value={values.email}
                  id="email"
                  name="Email"
                  autoFocus={true}
                  required
                  autoComplete="on"
                  onBlur={handleBlur}
                  handleChange={handleChange}
                  error={touched.email && errors.email}
                />
                <Field
                  size="full"
                  value={values.password}
                  id="password"
                  name="Password"
                  required
                  type={this.state.type}
                  passwordToggleView={this.togglePasswordView}
                  onBlur={handleBlur}
                  handleChange={handleChange}
                  error={touched.password && errors.password}
                />
                <div className="salesperson-role margin-top-sm">
                  <label className="custom-checkbox">
                    <div>Remember Me:</div>
                    <input
                      type="checkbox"
                      name="remember"
                      id="remember"
                      checked={values.remember}
                      onChange={(event) =>
                        handleRememberChange(event, setFieldValue)
                      }
                    />
                    <span>
                      <MdCheck />
                    </span>
                  </label>
                </div>
                <button
                  id="click_login_button"
                  className="button--full-width"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Login
                </button>
              </Form>
            </AuthContainer>
          );
        }}
      />
    );
  }

  render() {
    return this.state.loading ? <Loader /> : this.renderLoginForm();
  }
}

const handleRememberChange = (event, setFieldValue) => {
  const remember = event.target.checked;
  setFieldValue('remember', remember);
};
export default Login;
