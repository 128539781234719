import React, { Component } from 'react';
import { restRequest } from '../../Helpers/RequestHelper';
import Loader from '../../Components/Loader/Loader';
import AccountFormEnhancer from './AccountFormEnhancer';
import { checkError } from '../../Helpers/AuthHelper';
import './AccountForm.css';

class AccountForm extends Component {
  state = {
    accountGroups: [],
    accounts: {
      accGrpId: null,
      accTitle: '',
      openingBalance: null,
      currentBalance: null,
    },
    loading: true,
  };

  componentDidMount() {
    document.title = 'New Inventory Account';
    this.fetchAccountGroups();
  }

  fetchAccountGroups = () => {
    restRequest(
      'get',
      `accounts/create?type=${this.props.accountType || 'all'}`,
      {}
    )
      .then((accountGroupsResponse) => {
        this.setState({
          accountGroups: accountGroupsResponse,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
  };

  renderAccountForm() {
    return (
      <AccountFormEnhancer
        {...this.props}
        account={this.state.accounts}
        accountGroups={this.state.accountGroups}
      />
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return this.renderAccountForm();
  }
}

export default AccountForm;
