import React from 'react'

export default ({
  className = ''
}) => (

<svg className={className} xmlns="http://www.w3.org/2000/svg" width="28.438" height="24.165" viewBox="0 0 28.438 24.165">
  <path className="a"
        d="M17.5-25.259,3.965-19.619l-.684.308V-1.094H31.719V-19.312l-.684-.308Zm0,2.393,12.031,5.024V-3.281H27.344V-15.312H7.656V-3.281H5.469V-17.842ZM9.844-13.125H25.156v9.844H9.844Z"
        transform="translate(-3.281 25.259)"/>
</svg>
   )
