import React from 'react';
import ActiveIcon from '../../../Assets/General/ActiveIcon';
import InactiveIcon from '../../../Assets/General/InactiveIcon';
import ExportIcon from '../../../Assets/General/ExportIcon';
import {
  MdAdd as AddIcon,
  MdFullscreen as ExpandIcon,
  //FaFilePdf as PDF,
  MdCached as Switch,
  FaRegFilePdf,
} from '../../../Common/Icons';
import EditIcon from '../../../Assets/General/EditIcon';
import CloneIcon from '../../../Assets/General/CloneIcon';
import EmailIcon from '../../../Assets/General/EmailIcon';
import DeleteIcon from '../../../Assets/General/DeleteIcon';
import PrintIcon from '../../../Assets/General/PrintIcon';
import AttachmentIcon from '../../../Assets/General/AttachmentIcon';
import AdjustIcon from '../../../Assets/General/AdjustIcon';
import ShipNowIcon from '../../../Assets/General/ShipNowIcon';
import RecordPayment from '../../../Assets/General/RecordPaymentIcon';
import AddRetrunReceive from '../../../Assets/General/AddRetrunReceive';

export const ButtonIcons = {
  delete: () => (
    <DeleteIcon
      className="material-icon delete_icon"
      id="click_delete_Item_adjustment_icon"
    />
  ),
  clone: () => <CloneIcon />,
  print: () => <PrintIcon className="material-icon" />,
  attachment: () => <AttachmentIcon className="material-icon" />,
  email: () => <EmailIcon className="material-icon" />,
  edit: () => <EditIcon className="material-icon" />,
  active: () => <ActiveIcon className="material-icon" />,
  inactive: () => <InactiveIcon className="material-icon" />,
  pdf: () => <FaRegFilePdf className="material-icon" />,
  switch: () => <Switch className="material-icon" />,
  expand: () => <ExpandIcon className="material-icon" />,
  new: () => <AddIcon />,
  adjust: () => <AdjustIcon className="material-icon" />,
  export: () => <ExportIcon className="material-icon" />,
  resetFilters: () => <ExportIcon className="material-icon" />,
  shipicon: () => <ShipNowIcon className="material-icon" />,
  receivePayment: () => <RecordPayment className="material-icon" />,
  addReturnReceive: () => <AddRetrunReceive className="material-icon" />,
};
