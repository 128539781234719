import React, {useEffect} from 'react';
import InternetConnection from './Common/InternetConnection';

import Routes from './Router/Routes';
//import {ssoMessageEvent} from "./Helpers";

const App = () => {

  useEffect(()=>{
    //ssoMessageEvent();
  },[]);

  return (
    <>
      <InternetConnection />
      <Routes />
    </>
  );
};

export default App;
