import React, { Component } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { SALUTATIONS } from '../../../Constants';
import { restRequest } from '../../../Helpers/RequestHelper';
import {
  required,
  ValidateMax50,
  // ValidateMax25,
  ValidateMax20,
  // ValidatePhoneNumber,
  // ValidateMobileNumber,
  // ValidateMax6,
  // ValidateMax100
} from '../../../Helpers/Validator';
import { getError } from '../../Form/FormHelper';
// import COUNTRY_STATES from '../../../Constants/CountryState'

import Field from '../Field/Field';
import Loader from '../../Loader/Loader';
import FormAction from '../FormAction';
import ErrorFocus from '../ErrorFocus';

import './ContactPersonForm.css';
import { handlePhone } from '../../../Helpers/addressFieldsHandler';
import { checkError } from '../../../Helpers/AuthHelper';

class ContactPersonForm extends Component {
  type = this.props.type || 'add'; // add, edit

  state = {
    person: {
      id: this.props.personId || null,
      salutation: '',
      first_name: '',
      last_name: '',
      department: '',
      designation: '',
      email: '',
      mobile: '',
      work_phone: '',
    },
    loading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ loading: true });

    switch (this.type) {
      case 'edit':
        await Promise.all([this.fetchContactPerson()]);
        // this.populateCountryStates(230)
        break;
      case 'add':
      default:
      // await this.fetchCountryList()
    }
    // this.populateCountryStates(230)
    this.setState({ loading: false });
  };

  fetchContactPerson() {
    return restRequest(
      'get',
      `contacts/${this.props.contactId}/persons/${this.props.personId}/edit`
    )
      .then((res) => {
        // console.log(res)
        let contact_person = res;
        this.setState({
          person: {
            id: contact_person.id,
            salutation: res.salutation,
            first_name: res.first_name,
            last_name: res.last_name,
            email: res.email,
            department: res.department,
            designation: res.designation,
            mobile: res.mobile,
            work_phone: res.work_phone,
          },
        });
      })
      .catch((error) => {
        checkError(error);
      });
  }

  submitRequestType() {
    if (this.type === 'edit') {
      return 'put';
    }
    return 'post';
  }

  submitRequestUrl() {
    // console.log(this.state.person.id)
    switch (this.type) {
      case 'edit':
        return `contacts/${this.props.contactId}/persons/${this.state.person.id}`;
      case 'add':
      default:
        return `contacts/${this.props.contactId}/persons`;
    }
  }

  successMessage() {
    switch (this.type) {
      case 'edit':
        this.props.handleToast('Contact Person has been updated', 'success');
        break;
      case 'add':
      default:
        this.props.handleToast('Contact Person added successfully', 'success');
    }
  }

  handleSubmit(values, setSubmitting) {
    restRequest(this.submitRequestType(), this.submitRequestUrl(), {
      salutation: values.salutation,
      first_name: values.first_name,
      last_name: values.last_name,
      department: values.department,
      designation: values.designation,
      email: values.email,
      mobile: values.mobile,
      work_phone: values.work_phone,
    })
      .then((res) => {
        this.props.onSubmit(res.data);
        this.successMessage();
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, 'error')
        setSubmitting(false);
      });
  }

  renderContactPersonForm() {
    return (
      <Formik
        initialValues={{
          ...this.state.person,
          billingAddress: this.props.billingAddress,
          countryId: 230,
        }}
        validationSchema={() =>
          Yup.object().shape({
            first_name: required(ValidateMax50),
            mobile: ValidateMax20,
            work_phone: ValidateMax20,
          })
        }
        onSubmit={(values, { setSubmitting }) => {
          this.handleSubmit(values, setSubmitting);
        }}
        render={({
          values,
          errors,
          isSubmitting,
          touched,
          handleBlur,
          handleSubmit,
          handleChange,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form className="address-form" onSubmit={handleSubmit}>
              <div>
                <Field
                  size="large"
                  className="inline-field salute"
                  name="Salutation"
                  value={values.salutation}
                  options={SALUTATIONS}
                  dropdownValue="value"
                  dropdownLabel="label"
                  handleChange={(option) =>
                    setFieldValue('salutation', option ? option.value : '')
                  }
                  type="dropdown"
                />

                <Field
                  size="large"
                  name="First Name"
                  className="inline-field"
                  value={values.first_name}
                  id="first_name"
                  handleChange={handleChange}
                  required
                  onBlur={handleBlur}
                  error={touched.first_name && errors.first_name}
                />

                <Field
                  className="inline-field"
                  name="Last Name"
                  value={values.last_name}
                  size="large"
                  id="last_name"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.last_name && errors.last_name}
                />

                <Field
                  className="inline-field"
                  name="Department"
                  value={values.department}
                  size="large"
                  id="department"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.department && errors.department}
                />

                {/* <Field
                size="large"
                name="Zip Code"
                value={values.zipCode}
                className="inline-field"
                id="zipCode"
                handleChange={ handleChange => {
                  let zip = handleChange.target.value;
                  if (zip.length > 0) {
                    let labelString = zip;
                    if (labelString.length > 6) {
                      labelString = labelString.slice(0, 6)
                    }
                    zip = labelString;
                  }
                  setFieldValue('zipCode', zip)
                }}
                onBlur={handleBlur}
                error={getError(errors, touched, 'zipCode')}
              /> */}

                <Field
                  size="large"
                  className="inline-field"
                  name="Designation"
                  value={values.designation}
                  id="designation"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.designation && errors.designation}
                />

                <Field
                  size="large"
                  className="inline-field"
                  value={values.email}
                  id="email"
                  name="Email"
                  onBlur={handleBlur}
                  handleChange={handleChange}
                  error={touched.email && errors.email}
                />

                <Field
                  size="large"
                  //type="phone"
                  name="Phone (Primary)"
                  value={values.work_phone}
                  className="inline-field"
                  id="work_phone"
                  //handleChange={handleChange}
                  handleChange={(value) =>
                    setFieldValue('work_phone', handlePhone(value, 20))
                  }
                  onBlur={handleBlur}
                  error={getError(errors, touched, 'work_phone')}
                />

                <Field
                  size="large"
                  name="Mobile (Primary)"
                  //type="phone"
                  value={values.mobile}
                  className="inline-field"
                  id="mobile"
                  //handleChange={handleChange}
                  handleChange={(value) =>
                    setFieldValue('mobile', handlePhone(value, 20))
                  }
                  onBlur={handleBlur}
                  error={getError(errors, touched, 'mobile')}
                />
              </div>
              <FormAction
                marginFromRight={3.5}
                disabled={isSubmitting}
                onCancel={this.props.onCancel}
              />
              <ErrorFocus />
            </form>
          );
        }}
      />
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return this.renderContactPersonForm();
  }
}

export default ContactPersonForm;
