import React, { useEffect } from 'react';
import { PackageForm } from '../../../Components/Form';
import { FormLayout } from '../../../Components';
import { NewPackageIcon } from '../../../Assets/Navigation/NavigationIcons';

const PackageEdit = ({ handleToast }) => {
  useEffect(() => {
    document.title = 'Edit Package';
  }, []);

  return (
    <FormLayout
      title="Packages"
      Icon={NewPackageIcon}
      subtitle="Edit"
      iconstyle="general_module_icon"
      closeUrl="/packages"
      layoutBoxClass="uiux-form-redesign"
    >
      <PackageForm
        // title="Edit Package"
        type="edit"
        handleToast={handleToast}
      />
    </FormLayout>
  );
};

export default PackageEdit;
