import React, { Component, Fragment } from 'react';
import { NewPurchaseOrderIcon } from '../../../Assets/Navigation/NavigationIcons';
import { restRequest } from '../../../Helpers/RequestHelper';
import getOverallTaxDetails from '../../../Helpers/GetOverallTaxDetails';

import NewBillIcon from '../../../Assets/General/BillIcon';
import pageIdentify from '../../../Helpers/PageIdentify';
import CancelItemPO from '../../../Assets/General/CancelItemPO';

import Loader from '../../../Components/Loader/Loader';
import HeaderWrapper from '../../../Components/HeaderWrapper/HeaderWrapper';
import PurchaseOrderInfo from './Info/PurchaseOrderInfo';
import Address from '../../../Components/Address/Address';
import PurchaseOrderSummary from './Summary/PurchaseOrderSummary';
import CancelItems from '../View/CancelItems';
import CustomModal from '../../../Components/CustomModal/CustomModal';
import PDFViewer from '../../../Components/PDFViewer/PDFViewer';
import { DetailsHeader } from '../../../Components/Details/Details';
import { ActionMenu } from '../../../Components/CheckedMenu/CheckedMenu';
import NotFound from '../../../Components/NotFound/NotFound';
import Box from '../../../Components/Layout/Box';
import Bills from './Tabs/Bills/Bills';
import Receives from './Tabs/Receives/Receives';
import { getObjFromLS, setObjInLS } from '../../../Helpers/LocalStorage';
import AccessDenied from '../../../Components/AccessDenied/AccessDenied';
import controllerNames from '../../../Constants/ControllerNames';
import TermsCondition from '../../../Components/Terms/Terms';
import { pdfDownlaod } from '../../../Helpers/PdfDownload';
import Menu from '../../../Components/Menu/Menu';
import MenuData from '../../../Components/Menu/MenuData';

import History from '../../../Assets/General/History';
import CreateReceive from '../../Receives/CreateReceive';
import BillForm from '../../../Components/Form/BillForm/BillForm';
import MarkAsIssuedIcon from '../../../Assets/General/MarkAsIssuedIcon';
import MarkAsCancelledIcon from '../../../Assets/General/MarkAsCancelledIcon';
import ReopenCancelItemIcon from '../../../Assets/General/ReopenCancelItemIcon';
import PurchaseReceiveIcon from '../../../Assets/General/PurchaseReceiveIcon';
import getCurrentMenu from '../../../Helpers/getCurrentMenu';
import { checkError } from '../../../Helpers/AuthHelper';
import { HeaderMenus } from '../../../Components';
import useMobileDetection from '../../../Hooks/MobileDetect';

class PurchaseOrderDetails extends Component {
  //id = this.props.idFromHeaderWrapper ? this.props.idFromHeaderWrapper: this.props.match.params.id
  id = this.props.id
    ? this.props.id
    : this.props.idFromHeaderWrapper
      ? this.props.idFromHeaderWrapper
      : this.props.match?.params?.id;

  state = {
    //id : this.props.idFromHeaderWrapper ? this.props.idFromHeaderWrapper: this.props.match.params.id,
    purchaseOrderInfo: {},
    billTabDescription: 'No bills created yet',
    currency: {},
    detailsNo: '',
    currentMenu: getCurrentMenu(window.location.search),
    showNewReceiveModal: false,
    showNewBillModal: false,
    loading: true,
    pdf: false,
    showPDF: false,
    cancelItemsModal: false,
    notFound: false,
    updateBillsList: false,
    updateReceivesList: false,
    showPrompt: false,
  };

  componentDidMount() {
    if (!this.props.inModal) {
      document.title = 'Purchase Order Details';
    }
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ loading: true });
    restRequest('get', `purchaseorders/${this.id}`)
      .then((resInfo) => {

        this.setState({
          purchaseOrderInfo: resInfo,
          billTabDescription: resInfo.bill_tab_description,
          id: resInfo.id,
          detailsNo: resInfo.purchase_order_no,
          currency: resInfo.currency,
          loading: false,
        });

        setTimeout(() => {
          let dataFromLS = getObjFromLS('module');
          if (
            dataFromLS &&
            dataFromLS.queryParam &&
            dataFromLS.queryParam.includes(this.state.currentMenu)
          ) {
            let query = dataFromLS?.queryParam;
            query = query.replace(`&${this.state.currentMenu}=true`, '');
            query = query.replace(`?${this.state.currentMenu}=true`, '');
            setObjInLS('module', {
              ...dataFromLS,
              queryParam: query,
              presistDetailPage: true,
            });
            let newurl =
              window.location.protocol +
              '//' +
              window.location.host +
              window.location.pathname +
              query;
            window.history.pushState({ path: newurl }, '', newurl);
          } else {
            setObjInLS('module', {
              ...dataFromLS,
              presistDetailPage: true,
            });
          }
        }, 1000);
      })
      .catch((error) => {
        checkError(error);
        this.setState({
          loading: false,
          notFound: true,
        });
      });
  };

  purchaseOrderStatusChange = () => {
    if (!pageIdentify(window.location.pathname)) {
      this.props.history.push('/r');
    } else this.fetchData();
  };

  markAsCancelled = (id) => {
    restRequest('put', `purchaseorders/${id}/status/cancelled`)
      .then(() => {
        this.props.handleToast(
          'Purchase Order marked as Cancelled.',
          'success'
        );
        if (!pageIdentify(window.location.pathname)) {
          this.props.history.push('/r');
        } else {
          const updatedPurchaseOrder = {
            ...this.state.purchaseOrderInfo,
            status: 'cancelled',
          };
          this.setState({ purchaseOrderInfo: updatedPurchaseOrder });
        }
      })
      .catch((error) => checkError(error, this.props.handleToast));
  };

  markAsIssued = (id) => {
    restRequest('put', `purchaseorders/${id}/status/issued`)
      .then(() => {
        this.props.handleToast('Purchase Order marked as Issued.', 'success');
        if (!pageIdentify(window.location.pathname)) {
          this.props.history.push('/r');
          sessionStorage.setItem('fullscreen', false);
        } else {
          const updatedPurchaseOrder = {
            ...this.state.purchaseOrderInfo,
            status: 'issued',
          };
          this.setState({ purchaseOrderInfo: updatedPurchaseOrder });
        }
      })
      .catch((error) => checkError(error, this.props.handleToast));
  };

  reopenCancelItems = (id) => {
    restRequest('put', `purchaseorders/${id}/reopen-cancelled-items`)
      .then(() => {
        this.props.handleToast('Cancelled items has been reopened.', 'success');
        if (!pageIdentify(window.location.pathname)) {
          this.props.history.push('/r');
        } else {
          this.fetchData();
        }
      })
      .catch((error) => checkError(error, this.props.handleToast));
  };
  hasPermissionCustom(name, type) {
    let permissions = getObjFromLS('role').permissions;
    if (permissions.superAccess) {
      if (type === 'All') return [true, true, true];
      return true;
    }

    if (!['Create', 'Edit', 'Delete', 'All'].includes(type))
      throw new Error('Type must be one of Create, Edit, Delete, All');
    if (!(name in controllerNames))
      throw new Error('Invalid name is receieved for permission check');
    if (type !== 'All') {
      return permissions[`${controllerNames[name]}${type}`];
    }
    return [
      permissions[`${controllerNames[name]}Create`],
      permissions[`${controllerNames[name]}Edit`],
      permissions[`${controllerNames[name]}Delete`],
    ];
  }
  openPdf(id) {
    const [hasMobile] = useMobileDetection();
    if (hasMobile) {
      window.open(`/print/${id}/purchaseorders`, '_blank');
    } else {
      this.setState({ showPDF: true });
      restRequest('get', `purchaseorders/${id}/pdf`)
        .then((res) => {
          this.setState({ pdf: res });
        })
        .catch((error) => checkError(error));
    }
  }

  closePDFModal = () => {
    this.setState({
      showModal: false,
      showPDF: false,
      pdf: null,
    });
  };

  PDFModal() {
    return (
      <PDFViewer
        showPDF={this.state.showPDF}
        hidePDFModal={this.closePDFModal}
        pdf={this.state.pdf}
      />
    );
  }

  toggleCancelItemsModal = () => {
    this.setState({
      cancelItemsModal: !this.state.cancelItemsModal,
    });
  };

  cancelItemsModal() {
    const checkedId = this.id;
    return (
      <CustomModal
        showModal={this.state.cancelItemsModal}
        size="large"
        title="Cancel Items"
        renderActions={false}
        Icon={CancelItemPO}
        onClose={this.toggleCancelItemsModal}
        className="uiux_cancel_item_popup"
      >
        <CancelItems
          id={checkedId}
          fetchData={this.fetchData}
          closeModal={this.toggleCancelItemsModal}
          updateCheckedState={this.purchaseOrderStatusChange}
          handleToast={this.props.handleToast}
        />
      </CustomModal>
    );
  }

  populateConditionalOptions(
    id,
    status,
    isItemCancelled,
    current_bill_status,
    receive_status,
    bill_status
  ) {
    const hasEditPermission = this.hasPermission('purchaseorder', 'Edit');
    const options = [];
    if (!hasEditPermission) return options;
    if (status === 'draft') {
      options.push({
        label: 'Mark as Issued',
        icon: MarkAsIssuedIcon,
        onClick: () => this.markAsIssued(id),
      });
    }
    if (
      status === 'issued' &&
      current_bill_status !== 'paid' &&
      !receive_status &&
      !bill_status
    ) {
      options.push({
        label: 'Mark as Cancelled',
        icon: MarkAsCancelledIcon,
        onClick: () => this.markAsCancelled(id),
      });
    }
    if (
      status !== 'cancelled' &&
      status !== 'draft' &&
      status !== 'closed' &&
      isItemCancelled !== 1 &&
      current_bill_status !== 'paid' &&
      !receive_status &&
      !bill_status
    ) {
      options.push({
        label: 'Cancel Items',
        icon: CancelItemPO,
        onClick: () => this.toggleCancelItemsModal(),
      });
    }
    if (isItemCancelled === 1) {
      options.push({
        label: 'Reopen Cancel Items',
        icon: ReopenCancelItemIcon,
        onClick: () => this.reopenCancelItems(id),
      });
    }

    return options;
  }

  checkIfEditable = (purchaseOrder) => {
    if (
      purchaseOrder.bill_status ||
      purchaseOrder.receive_status ||
      purchaseOrder.status === 'closed' ||
      purchaseOrder.status === 'cancelled' ||
      purchaseOrder.type === 'dropship'
    )
      return false;
    return true;
  };

  newReceiveModal() {
    const { handleToast } = this.props;
    return (
      <CustomModal
        showModal={this.state.showNewReceiveModal}
        title=" New Receive"
        Icon={PurchaseReceiveIcon}
        renderActions={false}
        onClose={() => this.setState({ showNewReceiveModal: false })}
        size="large"
        showPrompt={this.state.showPrompt}
      >
        <CreateReceive
          inModal
          purchaseOrderId={this.state.purchaseOrderInfo.id}
          onClose={() => this.setState({ showNewReceiveModal: false })}
          onSubmit={() => {
            this.setState({ showNewReceiveModal: false });
            this.fetchData();
          }}
          handleToast={handleToast}
          handleprompt={this.handleprompt}
          showPrompt={this.state.showPrompt}
        />
      </CustomModal>
    );
  }

  // optionsForDropDown = [
  //   {
  //     label: 'Purchase Receive',
  //     onClick: () => this.setState({showNewReceiveModal: true})
  //   },
  //   {
  //     label: 'New Bill',
  //     onClick:  () => this.setState({showNewBillModal: true})
  //   },
  // ]
  isBillCreatable(billStatus, receiveStatus, orderStatus) {
    if (
      billStatus === 'billed' ||
      receiveStatus === 'received' ||
      orderStatus === 'cancelled' ||
      orderStatus === 'draft'
    ) {
      return false;
    }

    return true;
  }

  isReceiveCreatable(receiveStatus, orderStatus, purchaseOrderType) {
    if (
      receiveStatus === 'received' ||
      orderStatus === 'cancelled' ||
      orderStatus === 'draft' ||
      purchaseOrderType === 'dropship'
    )
      return false;
    return true;
  }
  optionsForDropDown(purchaseOrderInfo) {
    const hasPurchaseReceiveCreatePermission = this.hasPermissionCustom(
      'receive',
      'Create'
    );
    const hasBillCreatePermission = this.hasPermissionCustom('bill', 'Create');
    const options = [];

    if (
      hasPurchaseReceiveCreatePermission &&
      purchaseOrderInfo.type != 'dropship' &&
      this.isReceiveCreatable(
        purchaseOrderInfo.receive_status,
        purchaseOrderInfo.status,
        purchaseOrderInfo.backorder
      )
    ) {
      options.push({
        label: 'Receive',
        icon: PurchaseReceiveIcon,
        onClick: () => this.setState({ showNewReceiveModal: true }),
      });
    }
    if (
      hasBillCreatePermission &&
      this.isBillCreatable(
        purchaseOrderInfo.bill_status,
        purchaseOrderInfo.receive_status,
        purchaseOrderInfo.status
      )
    ) {
      options.push({
        label: 'New Bill',
        icon: NewBillIcon,
        onClick: () => this.setState({ showNewBillModal: true }),
      });
    }
    return options;
  }

  handleprompt = (status = true) => {
    this.setState({
      showPrompt: status,
    });
  };

  newBillModal() {
    const { handleToast } = this.props;
    return (
      <CustomModal
        showModal={this.state.showNewBillModal}
        title="New Bill"
        fill="#000"
        Icon={NewBillIcon}
        onClose={() => this.setState({ showNewBillModal: false })}
        renderActions={false}
        size="large"
        showPrompt={this.state.showPrompt}
        className="purchase-invoice-popup"
      >
        <BillForm
          history={this.props.history}
          inModal
          type="add"
          purchaseOrderId={this.state.purchaseOrderInfo.id}
          onSubmit={() => {
            this.setState({ showNewBillModal: false });
            this.fetchData();
          }}
          onClose={() => this.setState({ showNewBillModal: false })}
          handleToast={handleToast}
          handleBillPrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }
  renderActionMenu() {
    const id = this.id;
    const purchaseOrder = this.state.purchaseOrderInfo;
    const createOptions = this.optionsForDropDown(this.state.purchaseOrderInfo);
    const options = this.populateConditionalOptions(
      id,
      purchaseOrder.status,
      purchaseOrder.is_item_cancelled,
      purchaseOrder.current_bill_status,
      purchaseOrder.receive_status,
      purchaseOrder.bill_status
    );
    const [hasCreatePermission, hasEditPermission, hasDeletePermission] =
      this.hasPermission('purchaseorder', 'All');
    const menusList = [
      {
        type: 'options',
        options: options,
        className: 'options-dropdown',
        enable: !!options.length,
      },
      {
        type: 'link',
        icon: 'edit',
        enable: this.state.loading
          ? false
          : hasEditPermission && this.checkIfEditable(purchaseOrder),
        to: `/purchaseorders/edit/${id}`,
        tooTip: 'Edit',
        mobileLable: 'Edit',
        isMobile: true,
      },
      {
        type: 'button',
        icon: 'pdf',
        tooTip: 'Download PDF',
        isMobile: true,
        mobileLable: 'Download PDF',
        handleClick: () =>
          pdfDownlaod(
            `purchaseorders/${id}/pdf?download=true`,
            this.state.detailsNo,
            this.props.handleToast
          ),
      },
      {
        type: 'button',
        icon: 'print',
        isMobile: true,
        mobileLable: 'Print',
        tooTip: 'Print',
        handleClick: () => this.openPdf(id),
      },
      {
        type: 'link',
        icon: 'email',
        tooTip: 'Email',
        isMobile: true,
        mobileLable: 'Email',
        enable: hasCreatePermission,
        to: `/purchaseorders/email/${id}`,
      },
      {
        type: 'link',
        icon: 'clone',
        tooTip: 'Clone',
        mobileLable: 'Clone',
        isMobile: true,
        enable: hasCreatePermission,
        to: `/purchaseorders/clone/${id}`,
      },
      {
        type: 'button',
        icon: 'delete',
        tooTip: 'Delete',
        mobileLable: 'Delete',
        isMobile: true,
        handleClick: () =>
          this.openDeleteModal(
            id,
            this.props.forceRedirect,
            this.props.forceRedirectFlag
          ),
        enable: this.state.loading
          ? false
          : hasDeletePermission && this.checkIfEditable(purchaseOrder),
      },
      this.state.purchaseOrderInfo.status === 'issued' &&
      createOptions.length > 0 && {
        type: 'dropdown',
        className:
          'custom-dropdown transaction_Dropdwon mr-10 sales_order_dropdwon abcd button-text',
        options: createOptions,
        dropdownLable: 'Create',
        enable: true,
      },
      {
        type: 'button',
        className: 'button--primary float-left mr-3',
        handleClick: () => this.props.history.push(`/purchaseorders/add`),
        label: `New`,
        icon: 'new',
      },
    ];
    return (
      <ActionMenu>
        <HeaderMenus menusList={menusList} loading={this.state.loading} />
      </ActionMenu>
    );
  }

  renderMenuForHistory() {
    return (
      <MenuData
        name="History"
        purchaseOrderId={this.purchaseOrderId}
        url={this.url}
      />
    );
  }
  renderMenuForReceives() {
    return (
      <MenuData
        name="receives"
        url={this.url}
        history={this.history}
        currency={this.currency}
        purchaseOrderStatusChange={this.purchaseOrderStatusChange}
        purchaseOrderType={this.purchaseOrderType}
        updateBills={this.updateBills}
        updateReceivesList={this.updateReceivesList}
        toggleUpdateReceives={this.toggleUpdateReceives}
        purchaseOrderId={this.purchaseOrderId}
        purchaseOrderTotal={this.purchaseOrderTotal}
        purchaseOrderNo={this.purchaseOrderNo}
        receiveStatus={this.receiveStatus}
        purchaseOrderStatus={this.purchaseOrderStatus}
        updateReceives={this.updateReceives}
        handleToast={this.handleToast}
        forceRedirectFlag={this.forceRedirectFlag}
        hasPermissionCustom={this.hasPermissionCustom}
        hasViewPermission={this.hasViewPermission}
        inModal={this.inModal}
      />
    );
  }

  renderMenuBills() {
    return (
      <MenuData
        name="bills"
        url={this.url}
        history={this.history}
        currency={this.currency}
        purchaseOrderStatusChange={this.purchaseOrderStatusChange}
        updateBillsList={this.updateBillsList}
        toggleUpdateBills={this.toggleUpdateBills}
        purchaseOrderId={this.purchaseOrderId}
        purchaseOrderNo={this.purchaseOrderNo}
        purchaseOrderTotal={this.purchaseOrderTotal}
        billStatus={this.billStatus}
        receiveStatus={this.receiveStatus}
        purchaseOrderStatus={this.purchaseOrderStatus}
        updateReceives={this.updateReceives}
        handleToast={this.handleToast}
        forceRedirectFlag={this.forceRedirectFlag}
        hasPermissionCustom={this.hasPermissionCustom}
        hasViewPermission={this.hasViewPermission}
        billTabDescription={this.billTabDescription}
        inModal={this.inModal}
      />
    );
  }

  handleTabChange(tab) {
    this.setState({ currentMenu: tab });
  }

  menus() {
    return [
      {
        id: 'history',
        label: 'History',
        purchaseOrderId: this.state.purchaseOrderInfo.id,
        url: `purchaseorders/${this.state.purchaseOrderInfo.id}/activity`,
        icon: () => <History />,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('history');
        },
        renderContent: this.renderMenuForHistory,
      },
      {
        id: 'receives',
        label: 'Receives',
        purchaseOrderNo: this.state.detailsNo,
        purchaseOrderId: this.state.purchaseOrderInfo.id,
        url: `purchaseorders/${this.state.purchaseOrderInfo.id}/activity`,
        currency: this.state.currency,
        receiveStatus: this.state.purchaseOrderInfo.receive_status,
        purchaseOrderStatus: this.state.purchaseOrderInfo.status,
        purchaseOrderType: this.state.purchaseOrderInfo.type,
        handleToast: this.props.handleToast,
        history: this.props.history,
        purchaseOrderTotal: this.state.purchaseOrderInfo.total,
        forceRedirectFlag: this.props.forceRedirectFlag,
        hasPermissionCustom: this.hasPermissionCustom,
        hasViewPermission: this.hasViewPermission('receive'),
        purchaseOrderStatusChange: this.purchaseOrderStatusChange,
        updateBills: this.updateBills,
        updateReceivesList: this.state.updateReceivesList,
        toggleUpdateReceives: this.toggleUpdateReceives,
        inModal: this.props.inModal,
        icon: () => <PurchaseReceiveIcon />,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('receives');
        },
        renderContent: this.renderMenuForReceives,
      },
      {
        id: 'bills',
        label: 'Bills',
        purchaseOrderNo: this.state.detailsNo,
        purchaseOrderId: this.state.purchaseOrderInfo.id,
        url: `purchaseorders/${this.state.purchaseOrderInfo.id}/activity`,
        currency: this.state.currency,
        billStatus: this.state.purchaseOrderInfo.bill_status,
        receiveStatus: this.state.purchaseOrderInfo.receive_status,
        purchaseOrderStatus: this.state.purchaseOrderInfo.status,
        handleToast: this.props.handleToast,
        history: this.props.history,
        purchaseOrderTotal: this.state.purchaseOrderInfo.total,
        forceRedirectFlag: this.props.forceRedirectFlag,
        updateReceives: this.updateReceives,
        hasPermissionCustom: this.hasPermissionCustom,
        hasViewPermission: this.hasViewPermission('bill'),
        purchaseOrderStatusChange: this.purchaseOrderStatusChange,
        updateBillsList: this.state.updateBillsList,
        billTabDescription: this.state.billTabDescription,
        toggleUpdateBills: this.toggleUpdateBills,
        inModal: this.props.inModal,
        icon: () => <NewBillIcon fill="#000" />,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('bills');
        },
        renderContent: this.renderMenuBills,
      },
    ];
  }

  renderModals() {
    return (
      <Fragment>
        {this.cancelItemsModal()}
        {this.PDFModal()}
        {this.newReceiveModal()}
        {this.newBillModal()}
      </Fragment>
    );
  }

  print() {
    this.setState({ loading: true });
    restRequest('get', `purchaseorders/${this.id}/print`)
      .then((res) => {
        var w = window.open();
        w.document.write(res.body);
        w.document.close();
        setTimeout(function () {
          w.focus();
          w.print();
          w.close();
        }, 3000);

        this.setState({ loading: false });
      })
      .catch((error) => {
        //this.props.handleToast(error,'error')
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
  }

  renderLayoutView() {
    const { purchaseOrderInfo, currency, detailsNo } = this.state;
    const {
      // purchase_order_no: purchaseOrderNo,
      status,
      type,
      // vendor,
      purchase_order_item_details: purchaseOrderItemDetails,
    } = purchaseOrderInfo;
    const billingAddress = purchaseOrderInfo.billing_address || null;
    const shippingAddress = purchaseOrderInfo.shipping_address || {
      address_type: 'shipping',
      attention: null,
      city: null,
      country_name: 'United States',
      fax: '',
      phone: '',
      state: '',
      street: '',
      zip_code: '',
    };
    return (
      <div className="float-left w-100 position-relative sales-return-main purchase-order-set po-summary">
        <Menu
          menus={this.menus()}
          currentMenu={this.state.currentMenu}
          history={this.props.history}
          inModal={this.props.inModal || false}
        />
        <div className="float-left w-100 overflow_custom_height">
          <div id="printSO" className="sale_Order_Details">
            <div className="float-left w-100 sales_order_info">
              <Fragment>
                {this.renderModals()}
                {type === 'dropship' ? (
                  <div
                    className="float-left w-100 purchase_order_details"
                    style={{ marginBottom: '10px' }}
                  >
                    <div className="float-left w-50 details_header_left mb_3 purchase-order-main">
                      <DetailsHeader
                        title="Purchase Order"
                        no={detailsNo}
                        status={status}
                        type={type}
                        customer={purchaseOrderInfo.vendor.display_name}
                        contactID={purchaseOrderInfo.vendor.id}
                        inModal={this.props.inModal}
                      />

                      {/* <GridLayout className="layout-padding" grid="1-1"> */}
                      <PurchaseOrderInfo
                        purchaseOrder={purchaseOrderInfo}
                        inModal={this.props.inModal}
                      />
                    </div>
                    <div className="po-details-top">
                      {billingAddress != null && (
                        <div
                          className="float-left w-50 package_address details_header_right"
                          style={{ marginTop: '4rem' }}
                        >
                          {' '}
                          <Address
                            address={{
                              type: billingAddress.address_type,
                              attention: billingAddress.attention,
                              company: purchaseOrderInfo.vendor.company_name,
                              street: billingAddress.street,
                              city: billingAddress.city,
                              state: billingAddress.state,
                              zip: billingAddress.zip_code,
                              country: billingAddress.country_name,
                              phone: billingAddress.phone,
                              fax: billingAddress.fax,
                            }}
                          />
                        </div>
                      )}
                      {type === 'dropship' && shippingAddress != null && (
                        <div
                          className="float-left w-50 package_address details_header_right"
                          style={{ marginTop: '4rem' }}
                        >
                          {' '}
                          <Address
                            address={{
                              type: 'shipping',
                              attention: shippingAddress.attention,
                              company: purchaseOrderInfo.customerInfo
                                ? purchaseOrderInfo.customerInfo[0].company_name
                                : '',
                              street: shippingAddress.street,
                              city: shippingAddress.city,
                              state: shippingAddress.state,
                              zip: shippingAddress.zip_code,
                              country: shippingAddress.country_name,
                              phone: shippingAddress.phone,
                              fax: shippingAddress.fax,
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    className="float-left w-100 purchase_order_details"
                    style={{ marginBottom: '10px' }}
                  >
                    <div className="float-left w-50 details_header_left mb_3 purchase-order-main">
                      <DetailsHeader
                        title="Purchase Order"
                        no={detailsNo}
                        type={type}
                        status={status}
                        customer={purchaseOrderInfo.vendor.display_name}
                        contactID={purchaseOrderInfo.vendor.id}
                        inModal={this.props.inModal}
                      />

                      {/* <GridLayout className="layout-padding" grid="1-1"> */}
                      <PurchaseOrderInfo
                        purchaseOrder={purchaseOrderInfo}
                        inModal={this.props.inModal}
                      />
                    </div>
                    {billingAddress != null && (
                      <div
                        className="float-left w-100 package_address details_header_right"
                        style={{ marginTop: '4rem' }}
                      >
                        {' '}
                        <Address
                          address={{
                            type: billingAddress.address_type,
                            attention: billingAddress.attention,
                            company: purchaseOrderInfo.vendor.company_name,
                            street: billingAddress.street,
                            city: billingAddress.city,
                            state: billingAddress.state,
                            zip: billingAddress.zip_code,
                            country: billingAddress.country_name,
                            phone: billingAddress.phone,
                            fax: billingAddress.fax,
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
                {/* </GridLayout> */}
                <PurchaseOrderSummary
                  currency={currency}
                  purchaseOrder={purchaseOrderInfo}
                  overallTax={getOverallTaxDetails(purchaseOrderItemDetails)}
                  inModal={this.props.inModal}
                />
                <hr className="float-left w-100 mb-1 divider" />
                <hr className="float-left w-100 divider" />
                <TermsCondition
                  term={this.state.purchaseOrderInfo.terms_and_conditions}
                />
                {this.state.purchaseOrderInfo.terms_and_conditions && (
                  <hr className="float-left w-100 divider" />
                )}
              </Fragment>
            </div>
          </div>
        </div>
      </div>
    );
  }

  updateBills = () => {
    this.setState({ updateBillsList: true });
  };
  toggleUpdateBills = () => {
    this.setState({ updateBillsList: !this.state.updateBillsList });
  };

  updateReceives = () => {
    this.setState({ updateReceivesList: true });
  };
  toggleUpdateReceives = () => {
    this.setState({ updateReceivesList: !this.state.updateReceivesList });
  };

  renderSidebar() {
    const { purchaseOrderInfo, currency } = this.state;
    const { handleToast, history } = this.props;
    return (
      <Fragment>
        <Box title="Receives">
          {!this.hasViewPermission('receive') ? (
            <AccessDenied className="small dashboard" type="section" />
          ) : (
            <Receives
              purchaseOrderStatusChange={this.purchaseOrderStatusChange}
              updateBills={this.updateBills}
              updateReceivesList={this.state.updateReceivesList}
              toggleUpdateReceives={this.toggleUpdateReceives}
              purchaseOrderId={purchaseOrderInfo.id}
              receiveStatus={purchaseOrderInfo.receive_status}
              purchaseOrderStatus={purchaseOrderInfo.status}
              purchaseOrderType={purchaseOrderInfo.type}
              handleToast={handleToast}
              hasPermissionCustom={this.hasPermissionCustom}
            />
          )}
        </Box>
        <Box title="Bills">
          {!this.hasViewPermission('bill') ? (
            <AccessDenied className="small dashboard" type="section" />
          ) : (
            <Bills
              history={history}
              currency={currency}
              purchaseOrderStatusChange={this.purchaseOrderStatusChange}
              updateBillsList={this.state.updateBillsList}
              toggleUpdateBills={this.toggleUpdateBills}
              purchaseOrderId={purchaseOrderInfo.id}
              billStatus={purchaseOrderInfo.bill_status}
              receiveStatus={purchaseOrderInfo.receive_status}
              purchaseOrderStatus={purchaseOrderInfo.status}
              updateReceives={this.updateReceives}
              handleToast={handleToast}
              hasPermissionCustom={this.hasPermissionCustom}
            />
          )}
        </Box>
      </Fragment>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    return (
      //<GridLayout>
      //<Grid>{this.renderLayoutView()}</Grid>
      //<Grid transparent>{this.renderSidebar()}</Grid>
      //</GridLayout>
      <Fragment>{this.renderLayoutView()}</Fragment>
    );
  }
}

export default HeaderWrapper(PurchaseOrderDetails, {
  name: 'Purchase Orders',
  deleteName: 'Purchase Order',
  // tableIcon:'purchaseOrder_details_table_icon',
  tableIcon: 'general_module_icon',
  Icon: NewPurchaseOrderIcon,
  baseUrl: 'purchaseorders',
  redirectUrl: '/purchaseorders',
  onlyMenu: true,
  showName: true,
  permissionName: 'purchaseorder',
});
