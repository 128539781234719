import { isEqual } from 'lodash';

export const validateInvoiceForm = (data = {}, defaultValues = {}) => {
  let checkout = true;
  if (data && defaultValues) {
    const { invoiceDate = null, dueDate = null } = defaultValues;
    const actuaInvoiceDate =
      invoiceDate && invoiceDate.format('YYYY-MM-DD 00:00:00');
    const actualDueDate = dueDate && dueDate.format('YYYY-MM-DD 00:00:00');

    const checkoutEqualityInvoiceDate = Boolean(
      isEqual(
        data?.invoiceDate?.format('YYYY-MM-DD 00:00:00'),
        actuaInvoiceDate
      )
    );
    const checkoutEqualityDueeDate = Boolean(
      isEqual(data?.dueDate?.format('YYYY-MM-DD 00:00:00'), actualDueDate)
    );

    const updatedData = { ...data };
    const actualData = { ...defaultValues };
    delete updatedData.invoiceDate;
    delete updatedData.dueDate;

    delete actualData.invoiceDate;
    delete actualData.dueDate;
    const checkEquality = Boolean(isEqual(updatedData, actualData));
    if (
      !checkEquality ||
      !checkoutEqualityInvoiceDate ||
      !checkoutEqualityDueeDate
    ) {
      checkout = false;
    }
  }

  return { checkout };
};

export const validateShipmentForm = (data = {}, defaultValues = {}) => {
  const checkEquality = Boolean(isEqual(data, defaultValues));
  let checkout = true;
  if (!checkEquality) {
    checkout = false;
  }
  return { checkout };
};
