import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CheckIcon } from '../../Assets/Mixed/SearchIcons';

import './HamburgerMenu.css';

export default class HamburgerMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      open2: false,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick);
  }

  handleOutsideClick = (e) => {
    // ignore clicks on the component itself

    if (this.node !== null) {
      if (this.node.contains(e.target)) {
        return;
      }
      this.handleClick();
    }
  };

  handleMenu() {
    this.setState({
      open: !this.state.open,
      open2: !this.state.open2,
    });
  }

  handleClick() {
    this.setState({
      open: false,
      open2: false,
    });
  }

  getTitle(options) {
    var exportTitle,
      importTitle = false;
    options.forEach((option) => {
      if (option.label.includes('Export')) {
        exportTitle = true;
      }
      if (option.label.includes('Import')) {
        importTitle = true;
      }
    });
    if (exportTitle && !importTitle) {
      return 'Export';
    } else if (importTitle && !exportTitle) {
      return 'Import';
    } else {
      return 'Import/Export';
    }
  }
  render() {
    return (
      <div
        className="tyt"
        ref={(node) => {
          this.node = node;
        }}
      >
        <div
          onClick={() => this.handleMenu()}
          className={`menu-btn ${this.state.open ? 'open' : ''}`}
        >
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <ul className={`hamburger_details ${this.state.open2 ? 'open2' : ''}`}>
          {this.props.options && this.props.options.length > 0 && (
            <li className="heading">Sort By</li>
          )}
          {this.props.options &&
            this.props.options.length > 0 &&
            this.props.options.map((option, index) => {
              return (
                <Link
                  to={option.onClick()}
                  key={index}
                  // onClick={this.handleClick}
                >
                  {option.label}
                  {this.props.selectedSort &&
                    this.props.selectedSort.id === option.id && <CheckIcon />}
                </Link>
              );
            })}
          {this.props.imExOptions && (
            <React.Fragment>
              {this.props.options && this.props.options.length > 0 && <hr></hr>}
              <li className="heading">
                {this.getTitle(this.props.imExOptions)}
              </li>
              {this.props.imExOptions.map((option, index) => {
                return (
                  <button
                    onClick={() => {
                      option.onClick();
                      this.handleClick();
                    }}
                    disabled={option.disabled()}
                    key={index}
                  >
                    {option.icon}
                    {option.label}
                  </button>
                );
              })}
            </React.Fragment>
          )}
        </ul>
      </div>
    );
  }
}
