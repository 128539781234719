import React from 'react';
import RecordPaymentIcon from '../../Assets/General/RecordPaymentIcon';
import { FormLayout } from '../../Components';
import RecivedPaymentForm from './ReceivedPaymentForm';

const EditPaymentsReceived = (props) => {
  return (
    <FormLayout
      title="Update Customer Payment"
      Icon={RecordPaymentIcon}
      closeUrl="/paymentsmade"
      iconstyle="general_module_icon"
      layoutBoxClass="uiux-form-redesign"
    >
      <RecivedPaymentForm
        type="edit"
        {...props}
        id={
          props.idFromHeaderWrapper
            ? props.idFromHeaderWrapper
            : props.match.params.id
        }
      />
    </FormLayout>
  );
};

export default EditPaymentsReceived;
