import React, { useEffect, useState } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { MdCheck, MdInfo } from '../../../Common/Icons';
import {
  NewContactIcon,
  NewItemIcon,
  NewSalesOrderIcon,
  NewPurchaseOrderIcon,
  NewOrganizationIcon,
  SettingIcon,
  NewSalesReturnIcon,
  NewDashboardIcon,
  NewReportIcon,
} from '../../../Assets/Navigation/NavigationIcons';
import { restRequest, checkError, confirmationAlert } from '../../../Helpers';
import { required, ValidateMax50 } from '../../../Helpers/Validator';
import { Field, Form, FormAction } from '../';
import { validateUserRoleForm } from '../../../Helpers/FormValidator';
import './roleFormEnhancer.css';

const formEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: required(ValidateMax50),
  }),
  mapPropsToValues: ({ role }) => ({
    ...role,
  }),
  mapPropsToStatus: ({ checkedPermissions }) => ({
    ...checkedPermissions,
  }),
  handleSubmit: (values, bag) => {
    if (!values.salesPerson) {
      values = {
        ...values,
        slug: null
      }
    }
    
    handleFormSubmit(values, bag);
  },
});
const RoleForm = ({
  values: { name, salesPerson, slug, selectAll, permissions, role_preferences },
  status,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
  setStatus,
  isSubmitting,
  errors,
  touched,
  title,
  onCancel,
  role,
  action,
  ...props
}) => {
  const [staticVal, setStaticValues] = useState({});
  
  useEffect(() => {
    return () => {
      contactFlag = false;
      inventoryFlag = false;
      salesFlag = false;
      purchaseFlag = false;
      organizationFlag = false;
      settingsFlag = false;
      returnFlag = false;
      dashboardFlag = false;
      reportsFlag = false;
      selectAll = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleAlert();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, selectAll, permissions, salesPerson, action, staticVal]);

  useEffect(() => {
    let roleList = role.permissions;

    roleList['ReturnRecieveEdit'] = false;
    setStaticValues({
      name,
      selectAll,
      permissions: { ...roleList },
      salesPerson,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAlert = () => {
    let { checkout } = validateUserRoleForm(
      {
        name,
        selectAll,
        permissions,
        salesPerson,
        slug
      },
      staticVal
    );
    if (!checkout) {
      props.handleConfirmFormInviteForm(true);
      props.setPrompt(true);
    } else {
      props.handleConfirmFormInviteForm(false);
      props.setPrompt(false);
    }
  };
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  return (
    <Form className="role-form float-left w-100" onSubmit={handleSubmit}>
      <div className="float-left w-100" style={{ paddingLeft: '5rem' }}>
        <Field
          size="large"
          name="Role Name"
          id="name"
          autoFocus={true}
          value={name}
          placeholder="Role Name"
          handleChange={handleChange}
          onBlur={handleBlur}
          required
          error={touched.name && errors.name}
        />
      </div>
      <div className="float-left w-100" style={{ paddingLeft: '5rem' }}>
        <div className="salesperson-role margin-top-sm usearccess-sec-main">
          <label className="custom-checkbox">
            <div>Sales Person</div>
            <input
              type="checkbox"
              name="salesPerson"
              id="View"
              checked={salesPerson}
              onChange={(event) => {
                handleSalesChange(event, permissions, setFieldValue);
                salesFlag = !salesFlag;
              }}
            />
            <span>
              <MdCheck />
            </span>
          </label>
          <span className="salesperson-info">
            {' '}
            <MdInfo size={20} fill="#306ab4" />
            Click here to make this role only for sales persons. Can only view his own data regarding sales.
          </span>
         </div>
        <div className="salesperson-info">(if you want to display admin related sales data to this role, then visit "Sales Person" settings in "Settings>Preferences>Sale Order" & "Settings>Preferences>Invoice"; after configuring this role.)</div>
        {salesPerson &&
          <div className="form-fields-container d-flex flex-wrap  position-relative w-100">
          <Field
          className="flex-25"
          name="Sales Role"
          id="slug"
          value={slug}
          options={role_preferences?.filter(option => option.status === "active") }
          clearable={false}
          required
d         dropdownValue="slug"
          dropdownLabel="name"
          handleChange={(option) => {
            setFieldValue('slug', option ? option.slug : '');
          }}
          type="dropdown"
        />
        </div> }
      </div>
      <div
        className="float-left w-100"
        style={{
          paddingLeft: '5rem',
          marginTop: '2rem',
          marginBottom: '2rem',
        }}
      >
        <div className="bold_add_role">Select User Access Modules</div>
      </div>
      <hr className="form-hr" />
      <div className="float-left w-70 add_role_form">
        {renderPermissions(permissions, setFieldValue, setStatus, status)}
        <FormAction
          className="action_buttons_add_role"
          disabled={isSubmitting}
          onCancel={async () => {
            let response;
            let { checkout } = validateUserRoleForm(
              {
                name,
                selectAll,
                permissions,
                salesPerson,
              },
              staticVal
            );
            if (!checkout) {
              response = confirmationAlert();
              if (response) {
                await props.setPrompt(false);
                await props.handleConfirmFormInviteForm(false);
              }
            }
            if (response || checkout) onCancel();
          }}
        />
      </div>
    </Form>
  );
};
let selectAll = false;
let contactFlag = false;
let inventoryFlag = false;
let salesFlag = false;
let purchaseFlag = false;
let organizationFlag = false;
let settingsFlag = false;
let returnFlag = false;
let dashboardFlag = false;
let reportsFlag = false;
let allContactPermissions = false;
let allItemPermissions = false;
let itemGroupPermissions = false;
let allItemAdjustmentPermissions = false;
let allWarehousePermissions = false;
let allSalesOrderPermissions = false;
let allInvoicePermissions = false;
let allInvoicePaymentPermissions = false;
let allPackagePermissions = false;
let allShipmentPermissions = false;
let allPurchaseOrderPermissions = false;
let allBillPermissions = false;
let allBillPaymentPermissions = false;
let allReceivePermissions = false;
let allOrganizationPermissions = false;
let allInvitedUserPermissions = false;
let allActiveUserPermissions = false;
let allPreferencePermissions = false;
let allCurrencyPermissions = false;
let allRolePermissions = false;
let allInventoryAccountPermissions = false;
let allTaxPermissions = false;
let allSalesReturnPermissions = false;
let allCreditNotePermissions = false;
let allReturnRecievePermissions = false;
let allDashboardOrderDetailsPermissions = false;
let allDashboardSalesInformationPermissions = false;
let allDashboardPackageAndShipmentPermissions = false;
let allDashboardTopSellingItemPermissions = false;
let allDashboardTopCustomersPermissions = false;
let allDashboardSalesvsPurchasePermissions = false;
let allDashboardSalesSummaryPermissions = false;
let allDashboardReceivablesPermissions = false;
let allDashboardPayablePermissions = false;
let allDashboardPurchaseInformationPermissions = false;
let allReportInventoryPermissions = false;
let allReportSalesPermissions = false;
let allReportPurchasePermissions = false;
let allReportTaxPermissions = false;
let allDeliveryMethodPermissions = false;
let allPaymentModePermissions = false;

const renderPermissions = (permissions, setFieldValue, setStatus, status) => {
  return (
    <div>
      <div className="float-left w-100">
        <table className="table new-role-table float-left w-100">
          <thead className="float-left w-100">
            <tr>
              <th checked={true} className="module-name-pad-left">
                <input
                  type="checkbox"
                  name="selectAll"
                  id="ViewSelectAll"
                  checked={selectAll}
                  onChange={(event) =>
                    handleAdminChange(event, permissions, setFieldValue)
                  }
                />
                Module Name
              </th>
              <th
                onClick={() =>
                  handleViewPermissions(
                    false,
                    permissions,
                    status,
                    setFieldValue,
                    setStatus
                  )
                }
                className="new-role-table-title--hover"
              >
                View
              </th>
              <th
                onClick={() =>
                  handleCreatePermissions(
                    false,
                    permissions,
                    status,
                    setFieldValue,
                    setStatus
                  )
                }
                className="new-role-table-title--hover"
              >
                Create
              </th>
              <th
                onClick={() =>
                  handleEditPermissions(
                    false,
                    permissions,
                    status,
                    setFieldValue,
                    setStatus
                  )
                }
                className="new-role-table-title--hover"
              >
                Edit
              </th>
              <th
                onClick={() =>
                  handleDeletePermissions(
                    false,
                    permissions,
                    status,
                    setFieldValue,
                    setStatus
                  )
                }
                className="new-role-table-title--hover"
              >
                Delete
              </th>
            </tr>
          </thead>
          <tbody className="float-left w-100">
            <tr className="group-header-parent">
              <td className="group-header" colSpan={5}>
                <input
                  type="checkbox"
                  name="salesPerson"
                  id="View"
                  onClick={() => (contactFlag = !contactFlag)}
                  checked={contactFlag}
                  onChange={(event) =>
                    handleContactChange(event, permissions, setFieldValue)
                  }
                />
                <div className="svg-Down">
                  <NewContactIcon fill="#111" height="11" width="11" />
                  Contact
                </div>
              </td>
            </tr>
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Contacts',
              'Contact'
            )}
            <tr className="group-header-parent">
              <td className="group-header" colSpan={5}>
                <input
                  type="checkbox"
                  name="salesPerson"
                  id="View"
                  onClick={() => (inventoryFlag = !inventoryFlag)}
                  checked={inventoryFlag}
                  onChange={(event) =>
                    handleInventoryChange(event, permissions, setFieldValue)
                  }
                />
                <NewItemIcon fill="#111" />
                Inventory
              </td>
            </tr>
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Items',
              'Item'
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Item Group',
              'ItemGroup'
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Item Adjustments',
              'ItemAdjustment'
            )}
            <tr className="group-header-parent">
              <td className="group-header" colSpan={5}>
                <input
                  type="checkbox"
                  name="salesPerson"
                  id="View"
                  onClick={() => (salesFlag = !salesFlag)}
                  checked={salesFlag}
                  onChange={(event) =>
                    handleSalesChange(event, permissions, setFieldValue)
                  }
                />
                <div className="svg-Down">
                  <NewSalesOrderIcon fill="#111" />
                  Sales Order
                </div>
              </td>
            </tr>
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Sales Orders',
              'SalesOrder'
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Invoices',
              'Invoice'
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Payments Received',
              'InvoicePaymentRecord'
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Packages',
              'Package'
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Shipments',
              'Shipment'
            )}
            <tr className="group-header-parent">
              <td className="group-header" colSpan={5}>
                <input
                  type="checkbox"
                  name="salesPerson"
                  id="View"
                  onClick={() => (purchaseFlag = !purchaseFlag)}
                  checked={purchaseFlag}
                  onChange={(event) =>
                    handlePurchaseChange(event, permissions, setFieldValue)
                  }
                />
                <div className="svg-Down">
                  <NewPurchaseOrderIcon fill="#111" />
                  Purchase Order
                </div>
              </td>
            </tr>
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Purchase Orders',
              'PurchaseOrder'
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Bills',
              'Bill'
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Bill Payments',
              'PaymentRecord'
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Receives',
              'Receive'
            )}
            <tr className="group-header-parent">
              <td className="group-header" colSpan={5}>
                <input
                  type="checkbox"
                  name="salesPerson"
                  id="View"
                  onClick={() => (organizationFlag = !organizationFlag)}
                  checked={organizationFlag}
                  onChange={(event) =>
                    handleOrganizationChange(event, permissions, setFieldValue)
                  }
                />
                <div className="svg-Down">
                  <NewOrganizationIcon fill="#111" />
                  Business
                </div>
              </td>
            </tr>
            {/*{renderFeaturePermissionOptions(permissions, setFieldValue, 'Organization', 'Organization', false, false, false, false)}*/}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Invited Users',
              'Invite',
              true,
              true,
              true,
              true
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Active Users',
              'User',
              true,
              false,
              true,
              true
            )}
            <tr className="group-header-parent">
              <td className="group-header" colSpan={5}>
                <input
                  type="checkbox"
                  name="salesPerson"
                  id="View"
                  onClick={() => (settingsFlag = !settingsFlag)}
                  checked={settingsFlag}
                  onChange={(event) =>
                    handleSettingsChange(event, permissions, setFieldValue)
                  }
                />
                <div className="svg-Down">
                  <SettingIcon fill="#111" height="15" width="15" />
                  Settings
                </div>
              </td>
            </tr>
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Preferences',
              'Preference',
              true,
              false,
              true,
              false
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Currencies',
              'Currency'
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'User Permissions & Roles',
              'Role'
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Inventory Accounts',
              'Account'
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Taxes',
              'Tax'
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Delivery Methods',
              'DeliveryMethod'
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Payment Mode',
              'PaymentMode'
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Warehouses',
              'Warehouse'
            )}
            {/* start sales return */}
            <tr className="group-header-parent">
              <td className="group-header" colSpan={5}>
                <input
                  type="checkbox"
                  name="salesReturn"
                  id="View"
                  onClick={() => (returnFlag = !returnFlag)}
                  checked={returnFlag}
                  onChange={(event) =>
                    handleReturnsChange(event, permissions, setFieldValue)
                  }
                />
                <NewSalesReturnIcon fill="#111" />
                Sales Return
              </td>
            </tr>
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Sales Returns',
              'SalesReturn'
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Credit Notes',
              'CreditNote'
            )}
            {/* {renderFeaturePermissionOptions(permissions, setFieldValue, 'Return Recieves', 'ReturnRecieve')} */}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Return Recieves',
              'ReturnRecieve',
              true,
              true,
              false,
              true
            )}

            {/* end sales return */}
            <tr className="group-header-parent">
              <td className="group-header" colSpan={5}>
                <input
                  type="checkbox"
                  name="salesPerson"
                  id="View"
                  onClick={() => (dashboardFlag = !dashboardFlag)}
                  checked={dashboardFlag}
                  onChange={(event) =>
                    handleDashboardChange(event, permissions, setFieldValue)
                  }
                />
                <div className="svg-Down">
                  <NewDashboardIcon fill="#111" />
                  Dashboard
                </div>
              </td>
            </tr>
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Sales Information',
              'DashboardSalesInformation',
              true,
              false,
              false,
              false
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Package & Shipment',
              'DashboardPackageAndShipment',
              true,
              false,
              false,
              false
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Purchase Information',
              'DashboardPurchaseInformation',
              true,
              false,
              false,
              false
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Receivables',
              'DashboardReceivables',
              true,
              false,
              false,
              false
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Payable',
              'DashboardPayable',
              true,
              false,
              false,
              false
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Top Selling Items',
              'DashboardTopSellingItems',
              true,
              false,
              false,
              false
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Top Customers',
              'DashboardTopCustomers',
              true,
              false,
              false,
              false
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Order Details',
              'DashboardOrderDetails',
              true,
              false,
              false,
              false
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Sales vs Purchase',
              'DashboardSalesVsPurchase',
              true,
              false,
              false,
              false
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Sales Order Summary',
              'DashboardSalesOrderSummary',
              true,
              false,
              false,
              false
            )}

            <tr className="group-header-parent">
              <td className="group-header" colSpan={5}>
                <input
                  type="checkbox"
                  name="salesPerson"
                  id="View"
                  onClick={() => (reportsFlag = !reportsFlag)}
                  checked={reportsFlag}
                  onChange={(event) =>
                    handleReportsChange(event, permissions, setFieldValue)
                  }
                />
                <NewReportIcon fill="#111" />
                Reports
              </td>
            </tr>
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Inventory',
              'InventoryReport',
              true,
              false,
              false,
              false
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Sales',
              'SalesOrderReport',
              true,
              false,
              false,
              false
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Purchases',
              'PurchaseOrderReport',
              true,
              false,
              false,
              false
            )}
            {renderFeaturePermissionOptions(
              permissions,
              setFieldValue,
              'Taxes',
              'TaxReport',
              true,
              false,
              false,
              false
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const renderFeaturePermissionOptions = (
  permissions,
  setFieldValue,
  featureName,
  objectKeyName,
  _view = true,
  _create = true,
  _edit = true,
  _delete = true
) => {
  checkModulePermissions(permissions, featureName, objectKeyName);
  return (
    <tr>
      <td>{featureName}</td>
      <td>
        {_view && (
          <label className="custom-checkbox">
            <input
              name={objectKeyName + 'View'}
              id="View"
              type="checkbox"
              checked={permissions[[objectKeyName] + 'View']}
              onChange={(event) =>
                handlePermissionChange(event, permissions, setFieldValue)
              }
            />
            <span>
              <MdCheck />
            </span>
          </label>
        )}
      </td>
      <td>
        {_create && (
          <label className="custom-checkbox">
            <input
              name={objectKeyName + 'Create'}
              id="Create"
              type="checkbox"
              checked={permissions[[objectKeyName] + 'Create']}
              onChange={(event) =>
                handlePermissionChange(event, permissions, setFieldValue)
              }
            />
            <span>
              <MdCheck />
            </span>
          </label>
        )}
      </td>
      <td>
        {_edit && (
          <label className="custom-checkbox">
            <input
              name={objectKeyName + 'Edit'}
              id="Edit"
              type="checkbox"
              checked={permissions[[objectKeyName] + 'Edit']}
              onChange={(event) =>
                handlePermissionChange(event, permissions, setFieldValue)
              }
            />
            <span>
              <MdCheck />
            </span>
          </label>
        )}
      </td>
      <td>
        {_delete && (
          <label className="custom-checkbox">
            <input
              name={objectKeyName + 'Delete'}
              id="Delete"
              type="checkbox"
              checked={permissions[[objectKeyName] + 'Delete']}
              onChange={(event) =>
                handlePermissionChange(event, permissions, setFieldValue)
              }
            />
            <span>
              <MdCheck />
            </span>
          </label>
        )}
      </td>
    </tr>
  );
};

const handlePermissionChange = (event, permissions, setFieldValue) => {
  let updatablePermissions = permissions;
  updatablePermissions['ReturnRecieveEdit'] = false;
  const targetName = event.target.name;
  const viewTarget = targetName.substr(0, targetName.length - 4);
  const createTarget = targetName.substr(0, targetName.length - 6);
  const editTarget = targetName.substr(0, targetName.length - 4);
  if (
    (targetName.endsWith('View') &&
      (updatablePermissions[`${viewTarget}Create`] ||
        updatablePermissions[`${viewTarget}Edit`])) ||
    (targetName.endsWith('Create') &&
      updatablePermissions[`${createTarget}Edit`]) ||
    (targetName.endsWith('Edit') && updatablePermissions[`${editTarget}Delete`])
  ) {
    return;
  }
  updatablePermissions = handleCreateEditDeleteCheck(
    targetName,
    updatablePermissions
  );
  updatablePermissions[[targetName]] = !permissions[[targetName]];

  setFieldValue('permissions', { ...updatablePermissions });
};

const handleCreateEditDeleteCheck = (targetName, toUpdatePerm) => {
  if (targetName.endsWith('Create') && !toUpdatePerm[targetName]) {
    const viewTarget = targetName.substr(0, targetName.length - 6);
    toUpdatePerm[[`${viewTarget}View`]] = true;
  } else if (targetName.endsWith('Edit') && !toUpdatePerm[targetName]) {
    const viewTarget = targetName.substr(0, targetName.length - 4);
    // TODO: check later
    if (toUpdatePerm.hasOwnProperty(`${viewTarget}Create`)) {
      toUpdatePerm[[`${viewTarget}Create`]] = true;
    }
    toUpdatePerm[[`${viewTarget}View`]] = true;
  } else if (targetName.endsWith('Delete') && !toUpdatePerm[targetName]) {
    const viewTarget = targetName.substr(0, targetName.length - 6);
    toUpdatePerm[[`${viewTarget}View`]] = true;
    if (toUpdatePerm.hasOwnProperty(`${viewTarget}Create`)) {
      toUpdatePerm[[`${viewTarget}Create`]] = true;
    }
    toUpdatePerm[[`${viewTarget}Edit`]] = true;
  }

  return toUpdatePerm;
};

const handleViewPermissions = (
  check,
  permissions,
  { viewPermissionsChecked },
  setFieldValue,
  setStatus
) => {
  let updateViewPermissions = permissions;
  let getViewPermissions = Object.keys(updateViewPermissions).filter((view) => {
    return view.endsWith('View');
  });
  if (!check) {
    let getPermissions = Object.keys(updateViewPermissions).filter((create) => {
      return create.endsWith('Create') && updateViewPermissions[create];
    });
    if (getPermissions.length) return;
    if (viewPermissionsChecked) {
      getViewPermissions.map((viewPermission) => {
        return (updateViewPermissions[viewPermission] = false);
      });
      setStatus({ viewPermissionsChecked: !viewPermissionsChecked });
    } else {
      getViewPermissions.map((viewPermission) => {
        return (updateViewPermissions[viewPermission] = true);
      });
      setStatus({ viewPermissionsChecked: !viewPermissionsChecked });
    }
  } else {
    getViewPermissions.map((viewPermission) => {
      return (updateViewPermissions[viewPermission] = true);
    });
    setStatus({ viewPermissionsChecked: !viewPermissionsChecked });
  }

  setFieldValue('permissions', updateViewPermissions);
};

const handleCreatePermissions = (
  check,
  permissions,
  status,
  setFieldValue,
  setStatus
) => {
  const { createPermissionsChecked } = status;
  let updateCreatePermissions = permissions;
  let getCreatePermissions = Object.keys(updateCreatePermissions).filter(
    (create) => {
      return create.endsWith('Create');
    }
  );
  if (!check) {
    let getPermissions = Object.keys(updateCreatePermissions).filter((edit) => {
      return edit.endsWith('Edit') && updateCreatePermissions[edit];
    });
    if (getPermissions.length) return;
    if (createPermissionsChecked) {
      getCreatePermissions.map((createPermission) => {
        return (updateCreatePermissions[createPermission] = false);
      });
      setStatus({ createPermissionsChecked: !createPermissionsChecked });
    } else {
      getCreatePermissions.map((createPermission) => {
        return (updateCreatePermissions[createPermission] = true);
      });
      handleViewPermissions(
        true,
        permissions,
        status,
        setFieldValue,
        setStatus
      );
      setStatus({ createPermissionsChecked: !createPermissionsChecked });
    }
  } else {
    getCreatePermissions.map((createPermission) => {
      return (updateCreatePermissions[createPermission] = true);
    });
    handleViewPermissions(true, permissions, status, setFieldValue, setStatus);
    setStatus({ createPermissionsChecked: !createPermissionsChecked });
  }
  setFieldValue('permissions', updateCreatePermissions);
};

const handleEditPermissions = (
  check,
  permissions,
  status,
  setFieldValue,
  setStatus
) => {
  const { editPermissionsChecked } = status;
  let updateEditPermissions = permissions;
  let getEditPermissions = Object.keys(updateEditPermissions).filter((edit) => {
    return edit.endsWith('Edit');
  });
  if (!check) {
    let getPermissions = Object.keys(updateEditPermissions).filter((del) => {
      return del.endsWith('Delete') && updateEditPermissions[del];
    });
    if (getPermissions.length) return;
    if (editPermissionsChecked) {
      getEditPermissions.map((editPermission) => {
        return (updateEditPermissions[editPermission] = false);
      });
      setStatus({ editPermissionsChecked: !editPermissionsChecked });
    } else {
      getEditPermissions.map((editPermission) => {
        return (updateEditPermissions[editPermission] = true);
      });
      handleCreatePermissions(
        true,
        permissions,
        status,
        setFieldValue,
        setStatus
      );
      setStatus({ editPermissionsChecked: !editPermissionsChecked });
    }
  } else {
    getEditPermissions.map((editPermission) => {
      return (updateEditPermissions[editPermission] = true);
    });
    handleCreatePermissions(
      true,
      permissions,
      status,
      setFieldValue,
      setStatus
    );
    setStatus({ editPermissionsChecked: !editPermissionsChecked });
  }
  setFieldValue('permissions', updateEditPermissions);
};

const handleDeletePermissions = (
  check,
  permissions,
  status,
  setFieldValue,
  setStatus
) => {
  const { deletePermissionsChecked } = status;
  let updateDeletePermissions = permissions;
  let getDeletePermissions = Object.keys(updateDeletePermissions).filter(
    (del) => {
      return del.endsWith('Delete');
    }
  );
  if (deletePermissionsChecked) {
    getDeletePermissions.map((deletePermission) => {
      return (updateDeletePermissions[deletePermission] = false);
    });
    setStatus({ deletePermissionsChecked: !deletePermissionsChecked });
  } else {
    getDeletePermissions.map((deletePermission) => {
      return (updateDeletePermissions[deletePermission] = true);
    });
    handleEditPermissions(true, permissions, status, setFieldValue, setStatus);
    setStatus({ deletePermissionsChecked: !deletePermissionsChecked });
  }
  setFieldValue('permissions', updateDeletePermissions);
};

/* Hanlders */

//  Sales Permission

const handleSalesChange = (event, permissions, setFieldValue) => {
  const salesPerson = event.target.checked;
  if (salesPerson) {
    toggleSalesOrderPermissions(true, permissions, setFieldValue);
  } else {
    toggleSalesOrderPermissions(false, permissions, setFieldValue);
  }
  setFieldValue('salesPerson', salesPerson);
};
const handleReturnsChange = (event, permissions, setFieldValue) => {
  const salesPerson = event.target.checked;
  if (salesPerson) {
    toggleSalesReturnPermissions(true, permissions, setFieldValue);
  } else {
    toggleSalesReturnPermissions(false, permissions, setFieldValue);
  }
  // setFieldValue('salesPerson', salesPerson)
};
const handlePurchaseChange = (event, permissions, setFieldValue) => {
  const salesPerson = event.target.checked;
  if (salesPerson) {
    togglePurchasePermissions(true, permissions, setFieldValue);
  } else {
    togglePurchasePermissions(false, permissions, setFieldValue);
  }
  // setFieldValue('salesPerson', salesPerson)
};
const handleOrganizationChange = (event, permissions, setFieldValue) => {
  const salesPerson = event.target.checked;
  if (salesPerson) {
    toggleOrganizationPermissions(true, permissions, setFieldValue);
  } else {
    toggleOrganizationPermissions(false, permissions, setFieldValue);
  }
  // setFieldValue('salesPerson', salesPerson)
};
const handleSettingsChange = (event, permissions, setFieldValue) => {
  const salesPerson = event.target.checked;
  if (salesPerson) {
    toggleSettingsPermissions(true, permissions, setFieldValue);
  } else {
    toggleSettingsPermissions(false, permissions, setFieldValue);
  }
  // setFieldValue('salesPerson', salesPerson)
};
const handleDashboardChange = (event, permissions, setFieldValue) => {
  const salesPerson = event.target.checked;
  if (salesPerson) {
    toggleDashboardPermissions(true, permissions, setFieldValue);
  } else {
    toggleDashboardPermissions(false, permissions, setFieldValue);
  }
  // setFieldValue('salesPerson', salesPerson)
};
const handleReportsChange = (event, permissions, setFieldValue) => {
  const salesPerson = event.target.checked;
  if (salesPerson) {
    toggleReportsPermissions(true, permissions, setFieldValue);
  } else {
    toggleReportsPermissions(false, permissions, setFieldValue);
  }
  // setFieldValue('salesPerson', salesPerson)
};

const handleInventoryChange = (event, permissions, setFieldValue) => {
  const salesPerson = event.target.checked;
  if (salesPerson) {
    toggleInventoryPermissions(true, permissions, setFieldValue);
  } else {
    toggleInventoryPermissions(false, permissions, setFieldValue);
  }
  // setFieldValue('salesPerson', salesPerson)
};

const handleContactChange = (event, permissions, setFieldValue) => {
  const salesPerson = event.target.checked;
  if (salesPerson) {
    toggleContactPermissions(true, permissions, setFieldValue);
  } else {
    toggleContactPermissions(false, permissions, setFieldValue);
  }
  // setFieldValue('salesPerson', salesPerson)
};

// Handle super admin
const handleAdminChange = (event, permissions, setFieldValue) => {
  selectAll = event.target.checked;
  if (selectAll) {
    contactFlag = true;
    inventoryFlag = true;
    salesFlag = true;
    purchaseFlag = true;
    organizationFlag = true;
    settingsFlag = true;
    returnFlag = true;
    dashboardFlag = true;
    reportsFlag = true;
    toggleAllPermissionsVariable(true);
    toggleAdminOrderPermissions(true, permissions, setFieldValue);
  } else {
    contactFlag = false;
    inventoryFlag = false;
    salesFlag = false;
    purchaseFlag = false;
    organizationFlag = false;
    settingsFlag = false;
    returnFlag = false;
    dashboardFlag = false;
    reportsFlag = false;
    toggleAllPermissionsVariable(false);
    toggleAdminOrderPermissions(false, permissions, setFieldValue);
  }
  setFieldValue('selectAll', selectAll);
};

/* Toggles */

const toggleSalesOrderPermissions = (
  toggle = true,
  permissions,
  setFieldValue
) => {
  const salesOrderPermissions = {
    InvoiceView: toggle,
    InvoiceCreate: toggle,
    InvoiceEdit: toggle,
    InvoiceDelete: toggle,

    PackageView: toggle,
    PackageCreate: toggle,
    PackageEdit: toggle,
    PackageDelete: toggle,

    InvoicePaymentRecordView: toggle,
    InvoicePaymentRecordCreate: toggle,
    InvoicePaymentRecordEdit: toggle,
    InvoicePaymentRecordDelete: toggle,

    SalesOrderView: toggle,
    SalesOrderCreate: toggle,
    SalesOrderEdit: toggle,
    SalesOrderDelete: toggle,

    ShipmentView: toggle,
    ShipmentCreate: toggle,
    ShipmentEdit: toggle,
    ShipmentDelete: toggle,
  };

  allInvoicePermissions = toggle;
  allInvoicePaymentPermissions = toggle;
  allPackagePermissions = toggle;
  allShipmentPermissions = toggle;
  allSalesOrderPermissions = toggle;

  setFieldValue('permissions', { ...permissions, ...salesOrderPermissions });
};
const toggleSalesReturnPermissions = (
  toggle = true,
  permissions,
  setFieldValue
) => {
  const salesReturnPermissions = {
    SalesReturnView: toggle,
    SalesReturnCreate: toggle,
    SalesReturnEdit: toggle,
    SalesReturnDelete: toggle,

    CreditNoteView: toggle,
    CreditNoteCreate: toggle,
    CreditNoteEdit: toggle,
    CreditNoteDelete: toggle,

    ReturnRecieveView: toggle,
    ReturnRecieveCreate: toggle,
    ReturnRecieveEdit: toggle,
    ReturnRecieveDelete: toggle,
  };
  allSalesReturnPermissions = toggle;
  allCreditNotePermissions = toggle;
  allReturnRecievePermissions = toggle;
  setFieldValue('permissions', { ...permissions, ...salesReturnPermissions });
};

const toggleInventoryPermissions = (
  toggle = true,
  permissions,
  setFieldValue
) => {
  const inventoryPermissions = {
    ItemView: toggle,
    ItemCreate: toggle,
    ItemEdit: toggle,
    ItemDelete: toggle,

    ItemGroupView: toggle,
    ItemGroupCreate: toggle,
    ItemGroupEdit: toggle,
    ItemGroupDelete: toggle,

    ItemAdjustmentView: toggle,
    ItemAdjustmentCreate: toggle,
    ItemAdjustmentEdit: toggle,
    ItemAdjustmentDelete: toggle,

    // WarehouseView: toggle,
    // WarehouseCreate: toggle,
    // WarehouseEdit: toggle,
    // WarehouseDelete: toggle, // Inventory
  };
  allItemPermissions = toggle;
  itemGroupPermissions = toggle;
  allItemAdjustmentPermissions = toggle;
  // allWarehousePermissions = toggle;
  setFieldValue('permissions', { ...permissions, ...inventoryPermissions });
};
const toggleContactPermissions = (
  toggle = true,
  permissions,
  setFieldValue
) => {
  const contactPermissons = {
    // Contact
    ContactView: toggle,
    ContactCreate: toggle,
    ContactEdit: toggle,
    ContactDelete: toggle,
  };
  allContactPermissions = toggle;
  setFieldValue('permissions', { ...permissions, ...contactPermissons });
};
const toggleReportsPermissions = (
  toggle = true,
  permissions,
  setFieldValue
) => {
  const reportPermissons = {
    // Contact
    InventoryReportView: toggle,
    SalesOrderReportView: toggle,
    PurchaseOrderReportView: toggle,
    TaxReportView: toggle,
  };
  allReportInventoryPermissions = toggle;
  allReportSalesPermissions = toggle;
  allReportPurchasePermissions = toggle;
  allReportTaxPermissions = toggle;
  setFieldValue('permissions', { ...permissions, ...reportPermissons });
};
const toggleDashboardPermissions = (
  toggle = true,
  permissions,
  setFieldValue
) => {
  const dashPermissons = {
    DashboardOrderDetailsView: toggle,
    DashboardSalesInformationView: toggle,
    DashboardPackageAndShipmentView: toggle,
    DashboardTopSellingItemsView: toggle,
    DashboardTopCustomersView: toggle,
    DashboardSalesVsPurchaseView: toggle,
    DashboardSalesOrderSummaryView: toggle,
    DashboardReceivablesView: toggle,
    DashboardPayableView: toggle,
    DashboardPurchaseInformationView: toggle,
  };
  allDashboardOrderDetailsPermissions = toggle;
  allDashboardSalesInformationPermissions = toggle;
  allDashboardPackageAndShipmentPermissions = toggle;
  allDashboardTopSellingItemPermissions = toggle;
  allDashboardTopCustomersPermissions = toggle;
  allDashboardSalesvsPurchasePermissions = toggle;
  allDashboardSalesSummaryPermissions = toggle;
  allDashboardReceivablesPermissions = toggle;
  allDashboardPayablePermissions = toggle;
  allDashboardPurchaseInformationPermissions = toggle;
  setFieldValue('permissions', { ...permissions, ...dashPermissons });
};
const toggleSettingsPermissions = (
  toggle = true,
  permissions,
  setFieldValue
) => {
  const settingsPermissons = {
    PreferenceView: toggle,
    PreferenceEdit: toggle,

    CurrencyView: toggle,
    CurrencyCreate: toggle,
    CurrencyEdit: toggle,
    CurrencyDelete: toggle,

    RoleView: toggle,
    RoleCreate: toggle,
    RoleEdit: toggle,
    RoleDelete: toggle,

    AccountView: toggle,
    AccountCreate: toggle,
    AccountEdit: toggle,
    AccountDelete: toggle,

    TaxView: toggle,
    TaxCreate: toggle,
    TaxEdit: toggle,
    TaxDelete: toggle,

    DeliveryMethodView: toggle,
    DeliveryMethodCreate: toggle,
    DeliveryMethodEdit: toggle,
    DeliveryMethodDelete: toggle,

    PaymentModeView: toggle,
    PaymentModeCreate: toggle,
    PaymentModeEdit: toggle,
    PaymentModeDelete: toggle,

    WarehouseView: toggle,
    WarehouseCreate: toggle,
    WarehouseEdit: toggle,
    WarehouseDelete: toggle,
  };

  allPreferencePermissions = toggle;
  allCurrencyPermissions = toggle;
  allRolePermissions = toggle;
  allInventoryAccountPermissions = toggle;
  allTaxPermissions = toggle;
  allDeliveryMethodPermissions = toggle;
  allPaymentModePermissions = toggle;
  allWarehousePermissions = toggle;

  setFieldValue('permissions', { ...permissions, ...settingsPermissons });
};
const toggleOrganizationPermissions = (
  toggle = true,
  permissions,
  setFieldValue
) => {
  const organizationPermissons = {
    //OrganizationEdit: toggle,
    //OrganizationDelete: toggle,

    InviteView: toggle,
    InviteCreate: toggle,
    InviteEdit: toggle,
    InviteDelete: toggle,

    UserView: toggle,
    UserEdit: toggle,
    UserDelete: toggle,
  };
  allOrganizationPermissions = toggle;
  allInvitedUserPermissions = toggle;
  allActiveUserPermissions = toggle;
  setFieldValue('permissions', { ...permissions, ...organizationPermissons });
};
const togglePurchasePermissions = (
  toggle = true,
  permissions,
  setFieldValue
) => {
  const purchaseOrderPermissions = {
    // Purchase order
    PurchaseOrderView: toggle,
    PurchaseOrderCreate: toggle,
    PurchaseOrderEdit: toggle,
    PurchaseOrderDelete: toggle,

    BillView: toggle,
    BillCreate: toggle,
    BillEdit: toggle,
    BillDelete: toggle,

    PaymentRecordView: toggle,
    PaymentRecordCreate: toggle,
    PaymentRecordEdit: toggle,
    PaymentRecordDelete: toggle,

    ReceiveView: toggle,
    ReceiveCreate: toggle,
    ReceiveEdit: toggle,
    ReceiveDelete: toggle,
  };
  allPurchaseOrderPermissions = toggle;
  allBillPermissions = toggle;
  allBillPaymentPermissions = toggle;
  allReceivePermissions = toggle;
  setFieldValue('permissions', {
    ...permissions,
    ...purchaseOrderPermissions,
  });
};

// Super admin

const toggleAdminOrderPermissions = (
  toggle = true,
  permissions,
  setFieldValue
) => {
  const contactPermissons = {
    // Contact
    ContactView: toggle,
    ContactCreate: toggle,
    ContactEdit: toggle,
    ContactDelete: toggle,
  };
  const inventoryPermissions = {
    ItemView: toggle,
    ItemCreate: toggle,
    ItemEdit: toggle,
    ItemDelete: toggle,

    ItemAdjustmentView: toggle,
    ItemAdjustmentCreate: toggle,
    ItemAdjustmentEdit: toggle,
    ItemAdjustmentDelete: toggle,

    ItemGroupView: toggle,
    ItemGroupCreate: toggle,
    ItemGroupEdit: toggle,
    ItemGroupDelete: toggle,

    WarehouseView: toggle,
    WarehouseCreate: toggle,
    WarehouseEdit: toggle,
    WarehouseDelete: toggle, // Inventory
  };
  const salesPermissions = {
    InvoiceView: toggle,
    InvoiceCreate: toggle,
    InvoiceEdit: toggle,
    InvoiceDelete: toggle,

    PackageView: toggle,
    PackageCreate: toggle,
    PackageEdit: toggle,
    PackageDelete: toggle,

    InvoicePaymentRecordView: toggle,
    InvoicePaymentRecordCreate: toggle,
    InvoicePaymentRecordEdit: toggle,
    InvoicePaymentRecordDelete: toggle,

    SalesOrderView: toggle,
    SalesOrderCreate: toggle,
    SalesOrderEdit: toggle,
    SalesOrderDelete: toggle,

    ShipmentView: toggle,
    ShipmentCreate: toggle,
    ShipmentEdit: toggle,
    ShipmentDelete: toggle,
  };
  const purchaseOrderPermissions = {
    // Purchase order
    PurchaseOrderView: toggle,
    PurchaseOrderCreate: toggle,
    PurchaseOrderEdit: toggle,
    PurchaseOrderDelete: toggle,

    BillView: toggle,
    BillCreate: toggle,
    BillEdit: toggle,
    BillDelete: toggle,

    PaymentRecordView: toggle,
    PaymentRecordCreate: toggle,
    PaymentRecordEdit: toggle,
    PaymentRecordDelete: toggle,

    ReceiveView: toggle,
    ReceiveCreate: toggle,
    ReceiveEdit: toggle,
    ReceiveDelete: toggle,

    /*OrganizationEdit: toggle,
    OrganizationDelete: toggle,*/

    InviteView: toggle,
    InviteCreate: toggle,
    InviteEdit: toggle,
    InviteDelete: toggle,

    UserView: toggle,
    UserEdit: toggle,
    UserDelete: toggle,
  };
  const adminOrderPermissions = {
    // Organization
    /* OrganizationEdit: toggle,
    OrganizationDelete: toggle,*/

    InviteView: toggle,
    InviteCreate: toggle,
    InviteEdit: toggle,
    InviteDelete: toggle,

    UserView: toggle,
    UserEdit: toggle,
    UserDelete: toggle,

    // Settings
    PreferenceView: toggle,
    PreferenceEdit: toggle,

    CurrencyView: toggle,
    CurrencyCreate: toggle,
    CurrencyEdit: toggle,
    CurrencyDelete: toggle,

    RoleView: toggle,
    RoleCreate: toggle,
    RoleEdit: toggle,
    RoleDelete: toggle,

    AccountView: toggle,
    AccountCreate: toggle,
    AccountEdit: toggle,
    AccountDelete: toggle,

    TaxView: toggle,
    TaxCreate: toggle,
    TaxEdit: toggle,
    TaxDelete: toggle,

    // Dashboard
    DashboardOrderDetailsView: toggle,
    DashboardSalesInformationView: toggle,
    DashboardPackageAndShipmentView: toggle,
    DashboardTopSellingItemsView: toggle,
    DashboardSalesOrderSummaryView: toggle,
    DashboardSalesVsPurchaseView: toggle,
    DashboardTopCustomersView: toggle,
    DashboardReceivablesView: toggle,
    DashboardPayableView: toggle,
    DashboardPurchaseInformationView: toggle,

    // Reports
    InventoryReportView: toggle,
    SalesOrderReportView: toggle,
    PurchaseOrderReportView: toggle,
    TaxReportView: toggle,

    // Delivery Methods
    DeliveryMethodView: toggle,
    DeliveryMethodCreate: toggle,
    DeliveryMethodEdit: toggle,
    DeliveryMethodDelete: toggle,

    //Payment Mode
    PaymentModeView: toggle,
    PaymentModeCreate: toggle,
    PaymentModeEdit: toggle,
    PaymentModeDelete: toggle,
  };

  const salesReturnPermissions = {
    // Sales Return
    SalesReturnView: toggle,
    SalesReturnCreate: toggle,
    SalesReturnEdit: toggle,
    SalesReturnDelete: toggle,

    CreditNoteView: toggle,
    CreditNoteCreate: toggle,
    CreditNoteEdit: toggle,
    CreditNoteDelete: toggle,

    ReturnRecieveView: toggle,
    ReturnRecieveCreate: toggle,
    ReturnRecieveEdit: toggle,
    ReturnRecieveDelete: toggle,
  };

  const finalPermissionArray = {
    ...adminOrderPermissions,
    ...contactPermissons,
    ...inventoryPermissions,
    ...salesPermissions,
    ...purchaseOrderPermissions,
    ...salesReturnPermissions,
  };

  setFieldValue('permissions', { ...permissions, ...finalPermissionArray });
};

function checkModulePermissions(permissions, featureName, objectKeyName) {
  let defRoles = ['View', 'Create', 'Edit', 'Delete'];
  let objVar = true;
  defRoles.forEach(function (def, index) {
    if (permissions[objectKeyName + def] !== undefined) {
      if (permissions[objectKeyName + def] === false) {
        objVar = false;
        return false;
      }
    }
  });

  switch (objectKeyName) {
    case 'Contact':
      allContactPermissions = objVar;
      break;
    case 'Item':
      allItemPermissions = objVar;
      break;
    case 'ItemGroup':
      itemGroupPermissions = objVar;
      break;
    case 'ItemAdjustment':
      allItemAdjustmentPermissions = objVar;
      break;
    case 'Warehouse':
      allWarehousePermissions = objVar;
      break;
    case 'SalesOrder':
      allSalesOrderPermissions = objVar;
      break;
    case 'Invoice':
      allInvoicePermissions = objVar;
      break;
    case 'InvoicePaymentRecord':
      allInvoicePaymentPermissions = objVar;
      break;
    case 'Package':
      allPackagePermissions = objVar;
      break;
    case 'Shipment':
      allShipmentPermissions = objVar;
      break;
    case 'PurchaseOrder':
      allPurchaseOrderPermissions = objVar;
      break;
    case 'Bill':
      allBillPermissions = objVar;
      break;
    case 'PaymentRecord':
      allBillPaymentPermissions = objVar;
      break;
    case 'Receive':
      allReceivePermissions = objVar;
      break;
    case 'Organization':
      allOrganizationPermissions = objVar;
      break;
    case 'Invite':
      allInvitedUserPermissions = objVar;
      break;
    case 'User':
      allActiveUserPermissions = objVar;
      break;
    case 'Preference':
      allPreferencePermissions = objVar;
      break;
    case 'Currency':
      allCurrencyPermissions = objVar;
      break;
    case 'Role':
      allRolePermissions = objVar;
      break;
    case 'Account':
      allInventoryAccountPermissions = objVar;
      break;
    case 'Tax':
      allTaxPermissions = objVar;
      break;
    case 'SalesReturn':
      allSalesReturnPermissions = objVar;
      break;
    case 'CreditNote':
      allCreditNotePermissions = objVar;
      break;
    case 'ReturnRecieve':
      allReturnRecievePermissions = objVar;
      break;
    case 'DashboardOrderDetails':
      allDashboardOrderDetailsPermissions = objVar;
      break;
    case 'DashboardSalesInformation':
      allDashboardSalesInformationPermissions = objVar;
      break;
    case 'DashboardPackageAndShipment':
      allDashboardPackageAndShipmentPermissions = objVar;
      break;
    case 'DashboardTopSellingItems':
      allDashboardTopSellingItemPermissions = objVar;
      break;
    case 'DashboardTopCustomers':
      allDashboardTopCustomersPermissions = objVar;
      break;
    case 'DashboardSalesVsPurchase':
      allDashboardSalesvsPurchasePermissions = objVar;
      break;
    case 'DashboardReceivables':
      allDashboardReceivablesPermissions = objVar;
      break;
    case 'DashboardSalesOrderSummary':
      allDashboardSalesSummaryPermissions = objVar;
      break;
    case 'DashboardPayable':
      allDashboardPayablePermissions = objVar;
      break;
    case 'DashboardPurchaseInformation':
      allDashboardPurchaseInformationPermissions = objVar;
      break;
    case 'InventoryReport':
      allReportInventoryPermissions = objVar;
      break;
    case 'SalesOrderReport':
      allReportSalesPermissions = objVar;
      break;
    case 'DeliveryMethod':
      allDeliveryMethodPermissions = objVar;
      break;
    case 'PaymentMode':
      allPaymentModePermissions = objVar;
      break;
    case 'TaxReport':
      allReportTaxPermissions = objVar;
      break;
    default:
      allReportPurchasePermissions = objVar;
      break;
  }
  checkAllModulesPermission();
}

function checkAllModulesPermission() {
  if (allContactPermissions) {
    contactFlag = true;
  }
  if (
    allItemPermissions &&
    allItemAdjustmentPermissions &&
    allWarehousePermissions &&
    itemGroupPermissions
  ) {
    inventoryFlag = true;
  }
  if (
    allSalesOrderPermissions &&
    allInvoicePermissions &&
    allInvoicePaymentPermissions &&
    allPackagePermissions &&
    allShipmentPermissions
  ) {
    salesFlag = true;
  }
  if (
    allPurchaseOrderPermissions &&
    allBillPermissions &&
    allBillPaymentPermissions &&
    allReceivePermissions
  ) {
    purchaseFlag = true;
  }
  if (
    allSalesReturnPermissions &&
    allCreditNotePermissions &&
    allReturnRecievePermissions
  ) {
    returnFlag = true;
  }
  if (allInvitedUserPermissions && allActiveUserPermissions) {
    organizationFlag = true;
  }
  if (
    allPreferencePermissions &&
    allCurrencyPermissions &&
    allRolePermissions &&
    allInventoryAccountPermissions &&
    allTaxPermissions &&
    allDeliveryMethodPermissions &&
    allPaymentModePermissions
  ) {
    settingsFlag = true;
  }
  if (
    allReportPurchasePermissions &&
    allReportTaxPermissions &&
    allReportSalesPermissions &&
    allReportInventoryPermissions
  ) {
    reportsFlag = true;
  }
  if (
    allDashboardOrderDetailsPermissions &&
    allDashboardSalesInformationPermissions &&
    allDashboardPackageAndShipmentPermissions &&
    allDashboardTopSellingItemPermissions &&
    allDashboardTopCustomersPermissions &&
    allDashboardSalesvsPurchasePermissions &&
    allDashboardSalesSummaryPermissions &&
    allDashboardReceivablesPermissions &&
    allDashboardPayablePermissions &&
    allDashboardPurchaseInformationPermissions
  ) {
    dashboardFlag = true;
  }
  if (
    contactFlag &&
    inventoryFlag &&
    salesFlag &&
    purchaseFlag &&
    returnFlag &&
    organizationFlag &&
    settingsFlag &&
    reportsFlag &&
    dashboardFlag
  ) {
    selectAll = true;
  }
}

function toggleAllPermissionsVariable(value) {
  allContactPermissions = value;
  allItemPermissions = value;
  itemGroupPermissions = value;
  allItemAdjustmentPermissions = value;
  allWarehousePermissions = value;
  allSalesOrderPermissions = value;
  allInvoicePermissions = value;
  allInvoicePaymentPermissions = value;
  allPackagePermissions = value;
  allShipmentPermissions = value;
  allPurchaseOrderPermissions = value;
  allBillPermissions = value;
  allBillPaymentPermissions = value;
  allReceivePermissions = value;
  allOrganizationPermissions = value;
  allInvitedUserPermissions = value;
  allActiveUserPermissions = value;
  allPreferencePermissions = value;
  allCurrencyPermissions = value;
  allRolePermissions = value;
  allInventoryAccountPermissions = value;
  allTaxPermissions = value;
  allSalesReturnPermissions = value;
  allCreditNotePermissions = value;
  allReturnRecievePermissions = value;
  allDashboardOrderDetailsPermissions = value;
  allDashboardSalesInformationPermissions = value;
  allDashboardPackageAndShipmentPermissions = value;
  allDashboardTopSellingItemPermissions = value;
  allDashboardTopCustomersPermissions = value;
  allDashboardSalesvsPurchasePermissions = value;
  allDashboardSalesSummaryPermissions = value;
  allDashboardReceivablesPermissions = value;
  allDashboardPayablePermissions = value;
  allDashboardPurchaseInformationPermissions = value;
  allReportInventoryPermissions = value;
  allReportSalesPermissions = value;
  allReportPurchasePermissions = value;
  allReportTaxPermissions = value;
  allDeliveryMethodPermissions = value;
  allPaymentModePermissions = value;
}

const handleFormSubmit = (
  values,
  {
    setSubmitting,
    props: {
      requestType,
      requestUrl,
      handleToast,
      onSubmit,
      successMessage,
      handleConfirmFormInviteForm,
      setPrompt,
    },
  }
) => {
  console.log({values})
  var arr = Object.values(values.permissions);
  // hidden permissions which are set to true by default are OrganizationCreate and OrganizationView
  const aCount = arr.filter((x) => x === true).length;
  if (aCount > 2) {
    restRequest(requestType, requestUrl, {
      name: values.name,
      sales_person: values.salesPerson,
      permissions: values.permissions,
      id: values.id,
      slug: values.slug
    })
      .then(() => {
        setPrompt(false);
        onSubmit();
        handleConfirmFormInviteForm(false);
        successMessage();
        resetValues();
      })
      .catch((error) => {
        checkError(error);
        handleToast(error, 'error');
        setSubmitting(false);
      });
  } else {
    setSubmitting(false);
    handleToast('Please select at least one module against role', 'error');
  }
};

function resetValues() {
  selectAll = false;
  contactFlag = false;
  inventoryFlag = false;
  salesFlag = false;
  purchaseFlag = false;
  organizationFlag = false;
  settingsFlag = false;
  returnFlag = false;
  dashboardFlag = false;
  reportsFlag = false;
  allContactPermissions = false;
  allItemPermissions = false;
  itemGroupPermissions = false;
  allItemAdjustmentPermissions = false;
  allWarehousePermissions = false;
  allSalesOrderPermissions = false;
  allInvoicePermissions = false;
  allInvoicePaymentPermissions = false;
  allPackagePermissions = false;
  allShipmentPermissions = false;
  allPurchaseOrderPermissions = false;
  allBillPermissions = false;
  allBillPaymentPermissions = false;
  allReceivePermissions = false;
  allOrganizationPermissions = false;
  allInvitedUserPermissions = false;
  allActiveUserPermissions = false;
  allPreferencePermissions = false;
  allCurrencyPermissions = false;
  allRolePermissions = false;
  allInventoryAccountPermissions = false;
  allTaxPermissions = false;
  allSalesReturnPermissions = false;
  allCreditNotePermissions = false;
  allReturnRecievePermissions = false;
  allDashboardOrderDetailsPermissions = false;
  allDashboardSalesInformationPermissions = false;
  allDashboardPackageAndShipmentPermissions = false;
  allDashboardTopSellingItemPermissions = false;
  allDashboardTopCustomersPermissions = false;
  allDashboardSalesvsPurchasePermissions = false;
  allDashboardSalesSummaryPermissions = false;
  allDashboardReceivablesPermissions = false;
  allDashboardPayablePermissions = false;
  allDashboardPurchaseInformationPermissions = false;
  allReportInventoryPermissions = false;
  allReportSalesPermissions = false;
  allReportPurchasePermissions = false;
  allReportTaxPermissions = false;
  allDeliveryMethodPermissions = false;
  allPaymentModePermissions = false;
}

export default formEnhancer(RoleForm);
