export const handleStreet = (bstreetValue,max) => {
  max=max+1;
  let bstreet = bstreetValue.target.value
  if (bstreet.length > 0) {
    let bstreetString = bstreet
    if (bstreetString.length > max) {
      bstreetString = bstreetString.slice(0, max)
    }
    bstreet = bstreetString
  }
  return bstreet
}

export const handleCity = (bcityValue,max)  => {
  max=max+1;
  let bcity = bcityValue.target.value
  if (bcity.length > 0) {
    let bcityString = bcity
    if (bcityString.length > max) {
      bcityString = bcityString.slice(0, max)
    }
    bcity = bcityString
  }
  return bcity
}

export const handleState = (bcityValue,max)  => {
  max=max+1;
  let bcity = bcityValue.target.value
  if (bcity.length > 0) {
    let bcityString = bcity
    if (bcityString.length > max) {
      bcityString = bcityString.slice(0, max)
    }
    bcity = bcityString
  }
  return bcity
}

export const handlePhone = (bcityValue,max)  => {
  max=max+1;
  let bcity = bcityValue.target.value
  if (bcity.length > 0) {
    let bcityString = bcity
    if (bcityString.length > max) {
      bcityString = bcityString.slice(0, max)
    }
    bcity = bcityString
  }
  return bcity
}

export const handleExtraDescription = (value,max)  => {
  //max=max+1;
  if (value.length > 0) {
    let bcityString = value
    if (bcityString.length > max) {
      bcityString = bcityString.slice(0, max)
    }
    value = bcityString
  }
  return value
}