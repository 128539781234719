import React, { Component, Fragment } from "react";
import { restRequest } from '../../../Helpers/RequestHelper'
import Loader from '../../../Components/Loader/Loader'
import TimelineCard from '../../../Components/TimelineCard/TimelineCard'
import EmptyTable from "../../../Components/ListTable/EmptyTable";
import AccessDenied from '../../../Components/AccessDenied/AccessDenied'
import {checkError} from "../../../Helpers/AuthHelper";
export default class DashboardUpdates extends Component {
  constructor(props) {
    super()
    this.state = {
      data: {},
      loading: true,
      accessDenied:false
    }
  }
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.setState({ loading: true });
    restRequest(
      "get",
      `releases`
    )
      .then((res) => {
        this.setState({ data: res.releases, loading: false });
      })
      .catch((err) => {
        checkError(err)
        this.setState({ accessDenied:true, loading: false });
        //console.log(err);
      });
  }

  render() {
    if (this.state.loading) return <Loader />
    if(this.state.accessDenied) return <AccessDenied className="small dashboard " type="Dashboard Updates" />
    if (this.state.data.length === 0) {
      return <EmptyTable />
    }
    const { data } = this.state
    return (
      <div className="menu-history">
      {
        data.map(data => {
          return (
            <Fragment key={data.id}>
            <TimelineCard
              key={data.id}
              description={data.version_updates}
              icon={data.icon}
              date={data.date}
              time={data.time}
              fromDashboard
            />
            </Fragment>
          )
        })
      }
    </div>
    );
  }
}
