import { isEqual } from 'lodash';

export const validateCreatePackageForm = (data = {}, defaultValues = {}) => {
  let checkout = true;
  const checkEquality = Boolean(isEqual(data, defaultValues));

  if (!checkEquality) {
    checkout = false;
  }
  return { checkout };
};
