import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import CheckedIcon from '../../Assets/Mixed/CheckedIcon';
import CheckCircle from '../../Assets/General/Checkmark';
import { MdAdd, MdLocationOn } from '../../Common/Icons';
import SettingsPageIcon from '../../Assets/General/SettingPageIcon';

import {
  getOrganization,
  setOrganization,
  getCurrentOrganizaton,
  checkError,
} from '../../Helpers/AuthHelper';
import { setCurrentOrganization } from '../../Helpers/CurrentSession';
import { imagePathS3 } from '../../Helpers/RequestHelper';
import { restRequest } from '../../Helpers/RequestHelper';
import { getPackagePlanIConClass } from '../../Helpers/PackagePlan';
// import { OrganizationIcon } from '../../Assets/Navigation/NavigationIcons'

// import WithContext from '../../Context/UserContextHOC'
import Layout, {
  Header,
  Body,
  GridLayout,
  Grid,
} from '../../Components/Layout/Layout';
import StandardPackageIcon from '../../Assets/General/StandardPackageIcon';
// import ProPackageIcon from '../../Assets/General/ProfessionalPackageIcon'
import getDateFormat from '../../Helpers/Date/GetDateFormat';
import getDate from '../../Helpers/Date/GetDate';
import './ChooseOrganization.css';
import SingleOrganizationCard from './SingleOrganizationCard';
import HeaderWrapper from '../../Components/HeaderWrapper/HeaderWrapper';
// import { setObjInSS } from '../../Helpers';
// import { StateShortName } from '../../Helpers/StateNameConvertion'
class ChooseOrganization extends Component {
  state = {
    organizations: [],
    loading: true,
  };

  componentDidMount() {
    document.title = 'Choose A Business';
    this.fetchData();
  }

  fetchData() {
    restRequest('get', 'organizations')
      .then((res) => {
        this.setState({
          organizations: res.organizations,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error);
      });
  }
  handleSwitchOrganization(organizationId, oranizationName) {
    const currentOrganizationId = parseInt(getOrganization());
    const currentOrganization = JSON.parse(getCurrentOrganizaton());
    const {
      context: { changeRole },
      handleToast,
      history,
    } = this.props;

    if (currentOrganizationId === organizationId) return;

    restRequest('get', `organizations/${organizationId}`)
      .then((res) => {
        localStorage.setItem('switch_org', JSON.stringify(true));
        restRequest('get', 'currentuser/role')
          .then((role) => {
            changeRole(role);
            setOrganization(organizationId);
            setCurrentOrganization(res);
            handleToast(
              `Switching from organization ${currentOrganization.name} to organization ${oranizationName}`,
              'success'
            );
            localStorage.setItem('org_switch', true);
            history.push('/oc/organization/choose');
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            checkError(error, handleToast);
            //handleToast(error, 'error')
          });
      })
      .catch((error) => {
        checkError(error, handleToast);
        //handleToast(error, 'error')
      });
  }

  prepareImage(logo, name) {
    if (logo)
      return (
        <img
          src={imagePathS3(logo)}
          className="organization-logo avatar"
          alt="organization-logo"
        />
      );
    return <div className="organization-logo avatar">{name.charAt(0)}</div>;
  }

  renderOrganizationCard({ ...organization }) {
    const currentOrganizationId = parseInt(getOrganization());
    if (organization?.company_postal_code) {
      organization.company_postal_code = `, ${organization?.company_postal_code}`;
    }
    return (
      <div
        className="organization-card"
        onClick={() =>
          this.handleSwitchOrganization(organization?.id, organization?.name)
        }
      >
        {currentOrganizationId === organization.id && <CheckedIcon />}
        {this.prepareImage(organization?.logo, organization?.name)}
        <div className="margin-top-md semi-bold margin-bottom-xs">
          {organization.name}
        </div>
        <div className="margin-bottom-sm">
          {organization.organization_type.label}
        </div>
        <div className="organization-card--location">
          {(organization?.company_street ||
            organization?.company_city ||
            organization?.country) && <MdLocationOn />}
          <div>
            {organization?.company_street && (
              <div className="text-secondary margin-bottom-xs">
                {organization?.company_street}
              </div>
            )}

            {organization?.company_city && (
              <div className="text-secondary margin-bottom-xs">
                {organization?.company_city}{' '}
                {organization?.company_city &&
                  organization?.company_postal_code}{' '}
              </div>
            )}
            <div className="text-secondary">
              {organization?.country && organization?.country?.country_name}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderOrganizations() {
    return this.state.organizations.map((organization) => (
      <Grid className="no-height">
        {this.renderOrganizationCard(organization?.organizations)}
      </Grid>
    ));
  }

  setOrgAsDefault(id) {
    const { handleToast } = this.props;
    restRequest('post', `organizations/set_default/${id}`)
      .then((res) => {
        handleToast('Set as default', 'success');
        this.fetchData();
      })
      .catch((error) => checkError(error, handleToast));
  }

  renderOrganizationsListing() {
    const currentOrganization = JSON.parse(getCurrentOrganizaton());
    const hasEditPermission = this.hasPermission('organization', 'Edit');
    return (
      <Fragment>
        {this.state.organizations.map((org, index) => {
          const organization = org.organizations;
          const { slug = '' } = organization?.organization_plan;
          const { name = '', package_duration = '' } =
            organization?.organization_plan;
          return (
            <div
              key={org.id}
              className={`choose_organization ${
                currentOrganization?.id === organization?.id ? 'active' : ''
              }`}
            >
              <div className="float-left w-100 ml" style={{ height: '290px' }}>
                <div
                  className="float-left h-100 choose_organiartion_card"
                  style={{ width: '70%', border: '1px solid #F0F2F5' }}
                >
                  <div
                    className="float-left w-30 h-100 left_Sec"
                    style={{ padding: '10px' }}
                  >
                    {currentOrganization?.id === organization?.id && (
                      <>
                        <div className="icon_left organization-main">
                          <CheckCircle className="organization-tick" />{' '}
                        </div>
                        <span
                          className={`organization-label-set ${
                            org.role_id === 1 ? 'owner-label' : 'invited-label'
                          }`}
                        >
                          {' '}
                          {org.role_id === 1 ? 'Owner' : 'Invited'}{' '}
                        </span>
                      </>
                    )}
                    {organization?.is_default !== true && (
                      <span
                        className={`organization-label-set ${
                          org.role_id === 1 ? 'owner-label' : 'invited-label'
                        }`}
                      >
                        {' '}
                        {org?.role_id === 1 ? 'Owner' : 'Invited'}{' '}
                      </span>
                    )}

                    {slug && (
                      <div className="icon_top">
                        <span
                          title={`${name} (${
                            package_duration === '1 Months'
                              ? '1 Month'
                              : package_duration === '1 Years'
                              ? '1 Year'
                              : package_duration
                          })`}
                        >
                          {' '}
                          <StandardPackageIcon
                            className={`package_icon ${getPackagePlanIConClass(
                              slug
                            )}`}
                          />
                        </span>
                      </div>
                    )}
                    {organization?.is_default === true && (
                      <>
                        <p className="Default_Organization">Default Business</p>
                        <span
                          className={`organization-label-set ${
                            org.role_id === 1 ? 'owner-label' : 'invited-label'
                          }`}
                        >
                          {' '}
                          {org.role_id === 1 ? 'Owner' : 'Invited'}{' '}
                        </span>
                      </>
                    )}
                    <div className="image_orgnaization">
                      <div className="m_auto">
                        <img
                          src={imagePathS3(organization?.logo)}
                          alt="organization pic"
                        />
                      </div>
                    </div>
                    <div className="name_organization text-center float-left w-100">
                      {organization?.name}
                    </div>
                    <div className="about_organization text-center float-left w-100">
                      {organization &&
                        organization.organization_type &&
                        organization?.organization_type?.label}
                    </div>
                    <div className="organzation_action_buttons float-left w-100">
                      {currentOrganization?.id !== organization?.id && (
                        <a
                          onClick={() =>
                            this.handleSwitchOrganization(
                              organization?.id,
                              organization?.name
                            )
                          }
                        >
                          Switch
                        </a>
                      )}

                      {organization?.is_default !== true && (
                        <a
                          onClick={() => this.setOrgAsDefault(organization?.id)}
                          href="#"
                        >
                          Set Default
                        </a>
                      )}
                      {currentOrganization?.id === organization?.id &&
                        hasEditPermission && (
                          <Link
                            to={`/organization/edit?id=${organization?.id}`}
                          >
                            Edit
                          </Link>
                        )}
                    </div>
                  </div>
                  <div
                    className="float-left w-70 h-100 right_sec"
                    style={{ padding: '10px 10px 10px 25px' }}
                  >
                    <div className="float-left w-50 padding_right_custom">
                      <SingleOrganizationCard
                        title="Base Currency"
                        value={organization?.get_currency.name}
                      />
                      <SingleOrganizationCard
                        title="Time Zone"
                        value={organization?.time_zone}
                      />
                      <SingleOrganizationCard
                        title="Inventory Start Date"
                        value={getDate(organization?.inventory_start_date)}
                      />
                      <SingleOrganizationCard
                        title="Date Format"
                        value={getDateFormat(organization?.date_format)}
                      />
                      <SingleOrganizationCard
                        title="Name"
                        id="choose_org_name"
                        value={organization?.primary_contact_name}
                      />
                      <SingleOrganizationCard
                        title="Email"
                        id="choose_org_email"
                        value={organization?.primary_contact_email}
                      />
                    </div>
                    <div className="float-left w-50 padding_right_custom">
                      <SingleOrganizationCard
                        title="License No"
                        value={
                          organization.license_no &&
                          organization.license_no !== 'null' &&
                          organization.license_no
                        }
                      />
                      <SingleOrganizationCard
                        title="Phone"
                        value={organization?.phone}
                      />
                      <SingleOrganizationCard
                        title="Website"
                        id="choose_org_web"
                        value={organization?.company_website}
                      />
                      {/* { getAddress(organization.company_street) && <SingleOrganizationCard title="Address" value={getAddress(organization.company_street)} />}
                        { getAddress(organization.company_street) && <SingleOrganizationCard title="null" value={getAddress(organization.company_street, organization.company_city, organization.company_province && StateShortName(organization.company_province)+' '+organization.company_postal_code, (organization.country && organization.country.country_name !== null) ? organization.country.country_name : "United States")} />}
                        { !getAddress(organization.company_street) && <SingleOrganizationCard title="Address" value={getAddress(organization.company_city, organization.company_province && StateShortName(organization.company_province)+' '+organization.company_postal_code, (organization.country && organization.country.country_name !== null) ? organization.country.country_name : "United States")} />} */}
                      {/*<SingleOrganizationCard title="Address" tooltip={getAddress(organization.company_street, organization.company_city, organization.company_province && StateShortName(organization.company_province)+' '+organization.company_postal_code, (organization.country && organization.country.country_name !== null) ? organization.country.country_name : "United States")} value={organization} />*/}
                      <SingleOrganizationCard
                        title="Address"
                        tooltip={getAddress(
                          organization?.company_street,
                          organization?.company_city,
                          organization?.company_province &&
                            organization?.company_province +
                              ' ' +
                              organization?.company_postal_code,
                          organization?.country &&
                            organization?.country?.country_name !== null
                            ? organization?.country?.country_name
                            : 'United States'
                        )}
                        value={organization}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Fragment>
    );
  }

  renderTitle(title, subtitle = '') {
    return (
      <span>
        {title} / <span className="semi-bold">{subtitle}</span>
      </span>
    );
  }

  render() {
    if (this.state.loading) return null;
    return (
      <Layout>
        <Header Icon={SettingsPageIcon} title="Settings - Manage Businesses">
          {/* <Link to=""> */}
          {/* </Link> */}

          <button
            onClick={() => this.props?.history.push(`/organization/add`)}
            className="button--primary float-left mr-3"
          >
            <MdAdd />
            Add Business
          </button>
        </Header>
        <Body>
          {/* {this.renderMyAccount()} */}
          {/* <div className="margin-bottom-md text-secondary">Please choose an organization that you will work with, you can switch the organization later</div> */}

          <div className="float-left w-100">
            {this.renderOrganizationsListing()}
            <GridLayout grid="1-1-1">
              {/* {this.renderOrganizations()} */}
            </GridLayout>
          </div>
        </Body>
      </Layout>
    );
  }
}

export function getAddress(...params) {
  var stringBuilder = [];
  params.forEach((p) => {
    if (p) stringBuilder.push(p.trim());
  });
  return stringBuilder.join(', ');
}

// export default WithContext(ChooseOrganization)
export default HeaderWrapper(ChooseOrganization, {
  renderHeaderWrapper: false,
  name: 'Organization',
  permissionName: 'organization',
});
