import React, { Component, Fragment } from 'react';
import queryString from 'query-string';
import { NewItemIcon } from '../../../Assets/Navigation/NavigationIcons';
import { routesPath } from '../../Navigation/NavigationUtils';
// import ItemImage from '../../../Components/Onboarding/Diagrams/ItemImage'
import ItemFlowDiagram from '../../../Components/Onboarding/Diagrams/ItemFlowDiagram';
import { restRequest } from '../../../Helpers/RequestHelper';
import { formatNumber } from '../../../Helpers/FormatNumber';
// import { HorizontalColumnJoinICON } from '../../../Components/ListTable/ListTableColumnJoin'
import Alert from '../../../Components/Alert/Alert';
import HeaderWrapper from '../../../Components/HeaderWrapper/HeaderWrapper';
import ListTable from '../../../Components/ListTable/ListTable';
import Onboarding from '../../../Components/Onboarding/InitialOnboardingScreen';
import ListTableImageColumn from '../../../Components/ListTable/ListTableImageColumn';
import CheckedMenu, {
  Button,
  ButtonLink,
  Options,
} from '../../../Components/CheckedMenu/CheckedMenu';
import SearchResult from '../../../Components/SearchBar/SearchResult';
import CustomModal from '../../../Components/CustomModal/CustomModal';
import Export from '../../../Components/Export/Export';
import './ItemList.css';
//import Onboarding from '../../../Components/Onboarding/Onboarding'
// import {BackorderIcon, DropshipIcon} from "../../../Assets/List/ListIcons";
// import getStatusColor from "../../../Helpers/GetStatusColor";
import {
  removeValueFromLS,
  getObjFromLS,
  setObjInLS,
  getStringValueFromLS,
} from '../../../Helpers/LocalStorage';
import ItemDetails from '../../../Pages/Items/ItemDetails';
import AddItem from '../../../Pages/Items/AddItem';
import EditItem from '../../../Pages/Items/EditItem';
import ExportIcon from '../../../Assets/General/ExportIcon';
import CloneIcon from '../../../Assets/General/CloneIcon';
import EditIcon from '../../../Assets/General/EditIcon';
import DeleteIcon from '../../../Assets/General/DeleteIcon';
import ActiveIcon from '../../../Assets/General/ActiveIcon';
import InactiveIcon from '../../../Assets/General/InactiveIcon';
// import EmptyTable from '../../../Components/ListTable/EmptyTable';
import ImportIcon from '../../../Assets/General/ImportIcon';
import { checkError } from '../../../Helpers/AuthHelper';
import AccessDenied from '../../../Components/AccessDenied/AccessDenied';

class ItemList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      dataExpanded: [],
      showExportModal: false,
      organizationCurrency: {},
      store_connection: {},

      totalPage: 1,
      currentPage: 1,
      viewPerPage: 20,
      totalRecords: 20,
      itemId: null,
      currentFilter: 'all',
      orderBy: 'asc',
      sortFilter: 'name',
      preference: {},
      search: '',
      accessDenied: false,
      disableExportBtn: false,
    };
    this.getID = this.getID.bind(this);
  }

  filterOptions = [
    {
      id: 'none',
      label: 'Status',
      type: 'tagline',
    },
    {
      id: 'all',
      label: 'All',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'all', page: 1 }),
    },
    {
      id: 'active',
      label: 'Active',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'active', page: 1 }),
    },
    {
      id: 'inactive',
      label: 'Inactive',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'inactive', page: 1 }),
    },
    {
      id: 'lowstock',
      label: 'Low Stock',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'lowstock', page: 1 }),
    },
    {
      id: 'market_place',
      label: 'Market Place',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'market_place', page: 1 }),
    },
    {
      id: 'woocom',
      label: 'Woo-Commerce',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'woocom', page: 1 }),
    },
    {
      id: 'shopify',
      label: 'Shopify',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'shopify', page: 1 }),
    },
    {
      id: 'tracked',
      label: 'Tracked',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'tracked', page: 1 }),
    },
    {
      id: 'untracked',
      label: 'Un-Tracked',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'untracked', page: 1 }),
    } /*,
    {
      id: 'inventory',
      label: 'Inventory',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'inventory', page: 1 })
    },
    {
      id: 'noninventory',
      label: 'Non-Inventory',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'noninventory', page: 1 })
    },
    {
      id: 'services',
      label: 'Services',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'services', page: 1 })
    }*/,
  ];

  importExportOptions = [
    {
      label: 'Import Items',
      icon: <ImportIcon />,
      onClick: () => {
        this.props.history.push('/items/import');
      },
      disabled: () => this.hasCreatePermission(),
    },
    {
      label: 'Export Items',
      icon: <ExportIcon />,
      onClick: () => this.openExportModal(),
      disabled: () =>
        !this.hasViewPermission('item') ||
        this.state.disableExportBtn ||
        JSON.parse(localStorage.getItem('exportBtn')),
    },
  ];

  sortOptions = [
    {
      id: 'name',
      label: 'Name',
      type: 'link',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('name') }),
    },
    {
      id: 'created_at',
      label: 'Created Time',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('created_at') }),
    },
    {
      id: 'sku',
      label: 'SKU',
      type: 'link',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('sku') }),
    },
    {
      id: 'stock',
      label: 'Stock',
      type: 'link',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('stock') }),
    },
    /*{
      id: 'purchase_unit_price',
      label: 'Purchase Price',
      type: 'link',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('purchase_unit_price') })
    },*/
    {
      id: 'sales_unit_price',
      label: 'Selling Price',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('sales_unit_price') }),
    },
    {
      id: 'reorder_level',
      label: 'Reorder Level',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('reorder_level') }),
    },
  ];

  componentDidMount() {
    document.title = 'View Items';
    this.fetchDataWithQueryString();
  }

  componentDidUpdate() {
    this.handleOnUpdateQueryString();
  }
  handleOnUpdateQueryString() {
    const { filter, page, sort, orderBy, view, search } =
      this.parseQueryString();
    if (filter !== this.state.currentFilter) {
      this.setState(
        {
          currentFilter: filter,
        },
        () => this.fetchData()
      );
    }
    if (sort !== this.state.sortFilter) {
      this.setState(
        {
          sortFilter: sort,
        },
        () => this.fetchData()
      );
    }
    if (orderBy !== this.state.orderBy) {
      this.setState(
        {
          orderBy: orderBy,
        },
        () => this.fetchData()
      );
    }
    if (page !== this.state.currentPage) {
      sessionStorage.setItem('current_page', parseInt(page));
      this.setState(
        {
          currentPage: parseInt(page),
        },
        () => this.fetchData()
      );
    }
    if (view !== this.state.viewPerPage) {
      this.handleResetCheckbox(true);
      sessionStorage.setItem('view', parseInt(view));
      this.setState(
        {
          viewPerPage: parseInt(view),
        },
        () => this.fetchData()
      );
    }
    if (search !== this.state.search) {
      this.setState({ search: search, bulkSelectedData: [] }, () =>
        this.fetchData()
      );
    }
  }

  MarketFilterOptions() {
    const { wocom, shopify } = this.state.store_connection;
    let array;
    if (wocom && !shopify) {
      array = this.filterOptions.filter((option) => {
        return option.id !== 'shopify' && option.id !== 'market_place';
      });
    } else if (!wocom && shopify) {
      array = this.filterOptions.filter((option) => {
        return option.id !== 'woocom' && option.id !== 'market_place';
      });
    } else if (!wocom && !shopify) {
      array = this.filterOptions.filter((option) => {
        return (
          option.id !== 'woocom' &&
          option.id !== 'shopify' &&
          option.id !== 'market_place'
        );
      });
    } else {
      return;
    }

    this.filterOptions = array;
  }

  fetchDataWithQueryString() {
    const { filter, page, sort, orderBy, view, search } =
      this.parseQueryString();
    this.setState(
      {
        sortFilter: sort,
        currentFilter: filter,
        currentPage: parseInt(page),
        orderBy: orderBy,
        viewPerPage: parseInt(view),
        search: search,
      },
      () => this.fetchData()
    );
  }

  parseQueryString() {
    const query = queryString.parse(this.props.location.search);
    let filter = '';
    switch (query.filter) {
      case 'all':
      case 'active':
      case 'inactive':
      case 'lowstock':
      case 'market_place':
      case 'woocom':
      case 'shopify':
      case 'tracked':
      case 'untracked':
        filter = query.filter;
        break;
      default:
        filter = 'all';
    }

    let sort = '';
    switch (query.sort) {
      case 'name':
      case 'created_at':
      case 'sku':
      case 'stock':
      case 'sales_unit_price':
      case 'reorder_level':
        sort = query.sort;
        break;
      case 'accounting_stock':
      case 'physical_stock':
        sort = 'stock';
        break;
      default:
        sort = 'name';
    }

    let orderBy = '';
    switch (query.orderBy) {
      case 'asc':
      case 'desc':
        orderBy = query.orderBy;
        break;
      default:
        orderBy = 'asc';
    }

    let page = parseInt(query.page);
    if (!page) page = 1;
    let view = parseInt(query.view);
    if (!view) view = 20;

    let search = query.search;
    if (!search) search = '';

    return {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
    };
  }

  fetchData = async () => {
    this.setState({ loading: true });

    await this.fetchItemsList();

    this.setState({ loading: false });
  };

  fetchItemsList() {
    sessionStorage.setItem('view', parseInt(this.state.viewPerPage));
    sessionStorage.setItem('current_page', parseInt(this.state.currentPage));
    let searchkey = this.state.search.replace('&', '%26');
    return restRequest(
      'get',
      `items?filter=${this.state.currentFilter}&page=${this.state.currentPage}&sort_by=${this.state.sortFilter}&order_by=${this.state.orderBy}&view=${this.state.viewPerPage}&search=${searchkey}`
    )
      .then((res) => {
        const baseCurrency = res.base_currency;
        sessionStorage.setItem('total_items', parseInt(res.items.data.length));
        let obj = {
          prevId: null,
          id: res.items.data[0] && res.items.data[0].id,
          nextId: res.items.data[1] && res.items.data[1].id,
          moduleName: 'items',
          urlPath: window.location.pathname,
          queryParam: window.location.search,
        };
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS && dataFromLS.moduleName !== 'items') {
          sessionStorage.setItem('fullscreen', true);
          sessionStorage.setItem('once', true);
          removeValueFromLS('module');
          if (res.items.data[0]) {
            setObjInLS('module', obj);
          }
        }
        if (!res.items.data[0]) {
          removeValueFromLS('module');
        }
        if (!dataFromLS || dataFromLS.action === 'add') {
          if (res.items.data[0]) {
            setObjInLS('module', obj);
          }
        } else if (dataFromLS.action === 'bulkdelete') {
          if (res.items.data[0]) {
            setObjInLS('module', { ...obj, lolo: 'no' });
          }
        } else if (
          dataFromLS &&
          dataFromLS.moduleName === 'items' &&
          dataFromLS.queryParam !== window.location.search
        ) {
          if (res.items.data[0]) {
            setObjInLS('module', obj);
          }
        }
        if (!res.items?.data?.length) {
          sessionStorage.setItem('fullscreen', true);
          setObjInLS('module', obj);
        }
        this.setState(
          {
            data: this.prepareDataForTableCollapsed(
              res.items.data,
              res.stock_preference,
              baseCurrency
            ),
            dataExpanded: this.prepareDataForTable(
              res.items.data,
              res.stock_preference,
              baseCurrency
            ),
            totalPage: res.items.last_page,
            totalRecords: res.items.total,
            organizationCurrency: baseCurrency,
            preference: res.stock_preference,
            store_connection: res.store_connection,
          },
          () => {
            this.MarketFilterOptions();
            this.setState({
              mainLoading: false,
            });
          }
        );
      })
      .catch((error) => {
        this.setState({
          accessDenied: checkError(error, this.props.handleToast),
        });
        //this.props.handleToast(error, "error")
      });
  }

  prepareDataForTable(data, preference, baseCurrency) {
    return data.map((item) => ({
      id: item.id,
      Item: {
        id: 'name',
        sortable: true,
        market_place: item?.association,
        // getValue: () => <ListTableImageColumn name={item.name} sku={item.sku} images={item.images} />
        getValue: () => (
          <ListTableImageColumn name={item.name} images={item.images} />
        ),
      },
      // 'Created Date': {
      //   id: 'created_at',
      //   sortable: true,
      //   getValue: function () { return item[this.id] }
      // },

      SKU: {
        id: 'sku',
        sortable: true,
        getValue: function () {
          return item[this.id];
        },
      },
      Stock: {
        id: 'stock',
        sortable: true,
        alignRight: true,
        getValue: function () {
          if (item.inventory_type === 'noninventory') {
            return '-';
          } else if (
            parseFloat(
              item[
                `${
                  preference.preferences.physical
                    ? 'physical_stock'
                    : 'accounting_stock'
                }`
              ]
            ) < parseFloat(item.reorder_level)
          ) {
            return (
              <Fragment>
                <div className={'list-table--link-error'}>
                  {formatNumber(
                    item[
                      `${
                        preference.preferences.physical
                          ? 'physical_stock'
                          : 'accounting_stock'
                      }`
                    ]
                  )}
                </div>
                <div className="small-td-info list-table--link-error">
                  {item.unit}
                </div>
              </Fragment>
            );
          } else {
            return (
              <Fragment>
                {formatNumber(
                  item[
                    `${
                      preference.preferences.physical
                        ? 'physical_stock'
                        : 'accounting_stock'
                    }`
                  ]
                )}
                <div className="small-td-info">{item.unit}</div>
              </Fragment>
            );
          }
        },
      },
      /*'Status': {
        id: 'status',
        alignRight: true,
        hideStatusColor: true,
        getValue: function () {
          const Icons = []
          const tooltips = []
          // Added for clone indication
          if (item.clone_status === 1) {
            Icons.push(CloneIcon)
            tooltips.push('Clone Item')
          }
          return(
            <HorizontalColumnJoinICONd
              Icons={Icons}
              tooltips={tooltips}
            />
          )
        }
       },
      'Reorder': {
        id: 'reorder_level',
        alignRight: true,
        getValue: function () { return formatNumber(item[this.id]) }
      },*/
      /*'Purchase Price': {
        id: 'purchase_unit_price',
        sortable: true,
        alignRight: true,
        getValue: function () {
          return `${baseCurrency.symbol}${formatNumber(item[this.id])}`
        }
      },*/
      'Selling Price': {
        id: 'sales_unit_price',
        alignRight: true,
        sortable: true,
        getValue: function () {
          return (
            `${baseCurrency.symbol}` + parseFloat(item[this.id])?.toFixed(2)
          );
        },
      },
      'Reorder Level': {
        id: 'reorder_level',
        alignRight: true,
        sortable: true,
        getValue: function () {
          return item[this.id];
        },
      },
      // not displayable
      additional: {
        isActive: item.is_active,
      },
    }));
  }

  prepareDataForTableCollapsed(data, preference, baseCurrency) {
    return data.map((item) => ({
      id: item.id,
      Item: {
        id: 'name',
        sortable: true,
        // getValue: () => <ListTableImageColumn name={item.name} sku={item.sku} images={item.images} />
        getValue: () => (
          <ListTableImageColumn name={item.name} images={item.images} />
        ),
      },
      // not displayable
      additional: {
        isActive: item.is_active,
      },
    }));
  }

  // check status whether its active or not
  checkActiveStatus = (id) =>
    this.state.data.find((one) => {
      return one.id === id;
    }).additional.isActive;

  // render action menu for single selected data
  renderSingleActionMenu = () => {
    const id = this.state.bulkSelectedData[0];
    const isActive = this.checkActiveStatus(id);
    const [, hasEditPermission, hasDeletePermission] = this.hasPermission(
      'item',
      'All'
    );
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <ButtonLink
          enable={hasEditPermission}
          to={`/items/edit/${this.state.bulkSelectedData[0]}`}
          type="edit"
        />
        <Button
          enable={hasDeletePermission}
          handleClick={() =>
            this.openDeleteModal(this.state.bulkSelectedData[0])
          }
          type="delete"
        />
        <Button
          enable={hasEditPermission}
          // label={`Mark as ${isActive ? 'inactive' : 'active'}`}
          handleClick={() =>
            this.toggleActive(
              this.state.bulkSelectedData[0],
              !isActive ? 'active' : 'inactive'
            )
          }
          type={isActive ? 'inactive' : 'active'}
        />
        <Button handleClick={() => this.exportCurrentView()} type="export" />
      </CheckedMenu>
    );
  };

  exportCurrentView = () => {
    restRequest('post', `items/exportcurrentview`, {
      item_ids: this.state.bulkSelectedData,
    })
      .then((res) => {
        let csvFile = 'data:text/csv;charset=utf-8,' + res;
        let encodedUri = encodeURI(csvFile);
        let link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `items.csv`);
        document.body.appendChild(link);
        link.click();
        this.props.handleToast(`items exported successfully`, 'success');
        this.setState({ bulkSelectedData: [] });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, 'error')
      });
  };

  populateConditionalOptions() {
    const options = [
      {
        label: 'Mark as Active',
        onClick: () => this.toggleAllActive('active'),
      },
      {
        label: 'Mark as Inactive',
        onClick: () => this.toggleAllActive('inactive'),
      },
      {
        label: 'Export Selected',
        onClick: () => this.exportCurrentView(),
        disabled: () => !this.hasViewPermission('item'),
      },
    ];
    return options;
  }

  // render action menu for multiple selected data
  renderMultipleActionMenu = () => {
    const [, hasEditPermission, hasDeletePermission] = this.hasPermission(
      'item',
      'All'
    );
    const options = this.populateConditionalOptions();
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <Button
          enable={hasDeletePermission}
          handleClick={this.openBulkDeleteModal}
          type="delete"
        />
        <Options
          enable={hasEditPermission && !!options.length}
          options={options}
        />
      </CheckedMenu>
    );
  };
  hasCreatePermission() {
    const [hasCreatePermission] = this.hasPermission('item', 'All');
    return !hasCreatePermission;
  }
  hasEditPermission() {
    const hasEditPermission = this.hasPermission('item', 'Edit');
    return hasEditPermission;
  }
  hasDeltePermission() {
    const hasDeltePermission = this.hasPermission('item', 'Delete');
    return hasDeltePermission;
  }

  prepareRowOptions() {
    const rowOptions = [
      {
        label: 'Edit',
        type: this.hasEditPermission() && 'link',
        icon: EditIcon,
        onClick: (id) => `items/edit/${id}`,
        disabled: () => !this.hasEditPermission(),
      },
      {
        label: 'Clone',
        // type: 'link',
        icon: CloneIcon,
        onClick: (id) => this.props.history.push(`items/clone/${id}`),
        disabled: () => this.hasCreatePermission(),
      },
      {
        label: 'Delete',
        icon: DeleteIcon,
        onClick: this.openDeleteModal,
        disabled: () => !this.hasDeltePermission(),
      },
      {
        label: 'Mark Inactive',
        icon: InactiveIcon,
        disabled: (id) =>
          !this.hasEditPermission() ? true : !this.checkActiveStatus(id),
        onClick: (id) => this.toggleActive(id, 'inactive'),
      },
      {
        label: 'Mark Active',
        icon: ActiveIcon,
        disabled: (id) =>
          !this.hasEditPermission() ? true : this.checkActiveStatus(id),
        onClick: (id) => this.toggleActive(id, 'active'),
      },
    ];

    return rowOptions;
  }

  renderTable() {
    const {
      data,
      dataExpanded,
      bulkSelectedData,
      sortFilter,
      orderBy,
      //loading,
      accessDenied,
      mainLoading,
    } = this.state;
    if (accessDenied) {
      return <AccessDenied />;
    }
    if (!mainLoading) {
      if (this.checkIfOnboarding()) {
        let obj = {
          prevId: null,
          id: null,
          nextId: null,
          moduleName: 'items',
          urlPath: window.location.pathname,
          queryParam: window.location.search,
        };
        setObjInLS('module', obj);
        return (
          <Onboarding
            name="Item"
            FlowDiagram={ItemFlowDiagram}
            videoFrame={
              <iframe
                width="700"
                height="500"
                src="https://www.youtube.com/embed/nzA65-Au0Uc"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            }
            DisplayIcon={NewItemIcon}
            headerTitle="Items"
            description="Create , manage and make adjustment to your items"
            buttonLabel="Create Item"
            buttonLink="/items/add"
            secondaryButtonLabel="Import Item(s)"
            secondaryButtonLink="/items/import"
          />
        );
      }
    }
    let fullscreen = getStringValueFromLS('fullscreen');
    return (
      <ListTable
        className={`item-listing-woocom ${
          fullscreen === 'true' ? 'fullscreen ' : 'salesorder_listing'
        } ${orderBy === 'asc' ? 'so-asc-active' : 'so-desc-active'} `}
        data={fullscreen === 'true' ? dataExpanded : data}
        selectedData={bulkSelectedData}
        rowOptions={this.prepareRowOptions()}
        linkTo="/items"
        sortFilter={sortFilter}
        orderBy={orderBy}
        loading={mainLoading}
        isBlur="isActive"
        generateRoute={this.generateRoute}
        handleCheckAll={this.handleCheckAll}
        handleCheckSingle={this.handleCheckSingle}
        getID={this.getID}
        moduleName="items"
        totalRecords={this.state.totalRecords}
      />
    );
  }

  renderSearchDetails() {
    const { search } = this.state;
    if (search) {
      return (
        <Alert
          className="full-width green"
          id="elasticSearch"
          title="Search Criteria"
          linkTo={routesPath.items}
        >
          <SearchResult
            criterias={[
              'Name',
              'SKU',
              'UPC',
              'MPN',
              'EAN',
              'ISBN',
              'Manufacturer',
              'Brand',
            ]}
            searchKey={search.replace('%26', '&')}
          />
        </Alert>
      );
    }
    return null;
  }

  openExportModal() {
    this.setState({ showExportModal: true });
  }

  closeModal = () => {
    this.setState({ showExportModal: false });
  };

  getID(prevId, id, nextId) {
    let obj = {
      id,
      prevId: prevId ? prevId : null,
      nextId: nextId ? nextId : null,
      moduleName: 'items',
      urlPath: window.location.pathname,
      queryParam: window.location.search,
    };
    setObjInLS('module', obj);
    this.setState({ itemId: id });
    // setValueInLS('id',id)
  }

  renderExportModal() {
    return (
      <CustomModal
        showModal={this.state.showExportModal}
        title="Export Items"
        Icon={ExportIcon}
        onClose={this.closeModal}
        renderActions={false}
      >
        <Export
          name="Items"
          onCancel={this.closeModal}
          submitURL="items/export"
          handleToast={this.props.handleToast}
          disableExportBtn={this.state.disableExportBtn}
          setDisableExportBtn={(value) =>
            this.setState({ disableExportBtn: value })
          }
        />
      </CustomModal>
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderExportModal()}
        {this.renderTable()}
      </Fragment>
    );
  }
}

export default HeaderWrapper(
  ItemList,
  {
    name: 'Items',
    tableIcon: 'general_module_icon',
    Icon: NewItemIcon,
    baseUrl: 'items',
    deleteName: 'Item',
    buttonUrl: '/items/add',
    buttonLabel: 'New Item',
    routeName: 'items',
    showDetailsComp: true,
    bypassLoadingState: false,
    permissionName: 'item',
  },
  ItemDetails,
  AddItem,
  EditItem
);
