import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withFormik, FieldArray } from 'formik';
import DeleteIcon from '../../../Assets/General/DeleteIcon';
import moment from 'moment';
import {
  getObjFromLS,
  checkError,
  setObjInLS,
  restRequest,
  confirmationAlert,
} from '../../../Helpers';
import {
  moreThanZero,
  required,
  ValidateDate,
  ValidateNumber,
  ValidateRequired,
  ValidateMax50,
  ValidateMax255,
  ValidateMax10Billion,
} from '../../../Helpers/Validator';
import { Field, Form, AddItem, ErrorFocus, FormAction } from '../';
import { getError, validate, formSubmit } from '../FormHelper';
import { ListTableImageColumn, Loader, Quantity } from '../../';
import { validateSalesReturnForm } from '../../../Helpers/FormValidator';
import DragDropItems from '../../../Common/DragDrop';
import { MdDragIndicator } from 'react-icons/md';
import { Draggable } from 'react-beautiful-dnd';

let stopRestRequest = false;
let resBackToStock;
let rescontactPersonIdEnhancer = null;
let resShipmentId;
let reasonForReturnErr = 0;
const getYupValidationSchema = () => {
  return Yup.object().shape({
    saleReturnNo: required(ValidateMax50),
    salesOrderId: required(ValidateNumber),
    customerNotes: required(ValidateMax255),
    salesReturnDate: required(ValidateDate),
    items: Yup.array()
      .of(
        Yup.object().shape({
          name: ValidateRequired,
          quantity: required(
            moreThanZero(ValidateMax10Billion).integer('Invalid Value')
          ),
        })
      )
      .min(1, 'At least one item is required'),
  });
};

const formEnhancer = withFormik({
  validate: validate(getYupValidationSchema),
  mapPropsToValues: ({ saleorderInfo }) => ({
    ...saleorderInfo,
  }),
  mapPropsToStatus: (props) => ({
    itemsList: props.itemsList,
    emptyItem: props.emptyItem,
  }),
  handleSubmit: (values, bag) => {
    handleFormSubmit(values, bag);
  },
});

const handleFormSubmit = (
  values,
  {
    setSubmitting,
    props: {
      submitRequestType,
      submitRequestUrl,
      successMessage,
      inModal,
      history,
      handleToast,
      warehousesList,
      type,
    },
  }
) => {
  let payload = {
    sales_order_id: values.salesOrderId,
    sales_return_no: values.saleReturnNo,
    contact_person: rescontactPersonIdEnhancer,
    back_to_stock: resBackToStock,
    return_note: values.customerNotes,
    return_reason_description: values.returnReasonDescription,
    shipment_id: resShipmentId,
    return_date: values.salesReturnDate ? values.salesReturnDate : null,
    items: values.items.map((item, index) => ({
      id: item.id,
      sort_order: index,
      warehouse_id: item.res_warehouse_id,
      targetWarehouseId: item.warehouse_idd,
      oldTargetWarehouseId: item.old_warehouse_idd,
      sales_order_item_detail_id: item.salesOrderItemDetailId,
      quantity: item.quantity || 0,
    })),
  };

  if (!stopRestRequest) {
    restRequest(submitRequestType(), submitRequestUrl(), payload)
      .then((res) => {
        successMessage();
        let dataFromLS = getObjFromLS('module');

        if (inModal) {
          setObjInLS('module', {
            ...dataFromLS,
            queryParam: `${
              dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
            }salesReturn=true`,
          });
          return history.push('/r');
        } else {
          // setObjInLS('module',{...dataFromLS , action: type ==='add' ? 'add' : 'justedit' , queryParam :dataFromLS.queryParam})
          if (type === 'add') {
            setObjInLS('module', {
              ...dataFromLS,
              moduleName: 'salesreturns',
              action: type === 'add' ? 'add' : 'justedit',
              queryParam: dataFromLS.queryParam,
            });
          } else if (type === 'clone') {
            setObjInLS('module', {
              ...dataFromLS,
              moduleName: 'salesreturns',
              action: 'add',
              queryParam: dataFromLS.queryParam,
            });
          }
          type === 'edit' &&
            setObjInLS('module', {
              ...dataFromLS,
              action: type === 'add' ? 'add' : 'justedit',
              queryParam: dataFromLS.queryParam ? dataFromLS.queryParam : '',
            });
          return history.push('/r');
        }
      })
      .catch((error) => {
        checkError(error, handleToast);
        setSubmitting(false);
      });
  }
  if (stopRestRequest) {
    setSubmitting(false);
  }
};

const PackageForm = ({
  // props from formik
  values,
  status,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
  setStatus,
  setValues,
  isSubmitting,
  errors,
  touched,
  setFieldTouched,

  // custom props
  title,
  shipmentId,
  inModal,
  returnReason,
  returnReasonsFilter,
  SaleOrderNo,
  CustomerName,
  returnReasonDescription,
  addBackToStock,
  warehouseListRecieve,
  genericWarehouseId,
  warehouseResult,
  warehousesList,
  contactPersonId,
  contactPerson,
  type,
  allContactsList,
  salesOrderList,
  history,
  close,
  ...props
}) => {
  const { itemsList, emptyItem } = status;
  // postWarehouses=warehouseListRecieve
  var packageFakeItem = false;
  const [staticVal, setStaticValues] = useState({});

  useEffect(() => {
    if (!inModal) props.handleCrossAlert(values);
    if (inModal) handleAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, staticVal]);

  useEffect(() => {
    setStaticValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAlert = () => {
    const { checkout } = validateSalesReturnForm(values, staticVal);
    if (!checkout) props.handlePrompt();
    else props.handlePrompt(false);
  };

  const handleSalesOrderChange = async (selected) => {
    setflag(false);
    // resWarehouseId=[]
    try {
      const res = await restRequest(
        'get',
        `salesreturns/create?sales_order_id=${selected.value}`
      );
      // warehouseResultWarehouseChange=res
      // console.log('%c Ware hosue result  atr' ,'background: orange ; color :black', warehouseResultWarehouseChange)
      shipmentId = res.sales_order.shipmentId;
      let arraywareid = [];
      for (let i = 0; i < res.sales_order.sales_order_details.length; i++) {
        arraywareid.push(res.sales_order.sales_order_details[i].warehouse_id);
      }
      setyikes(arraywareid);
      if (arraywareid[0] !== null) {
        let arr = [];
        for (let i = 0; i < arraywareid.length; i++) {
          for (let j = 0; j < warehouseList.length; j++) {
            if (arraywareid[i] === warehouseList[j].id) {
              arr.push(warehouseList[j].name);
            }
          }
        }

        setWarehouseNames(arr);
      }
      const resItems = res.sales_order.sales_order_details.map(
        (item, index) => ({
          id: item.item_id,
          salesOrderItemDetailId: item.id,
          name: item.items.name,
          images: item.items.images,
          sku: item.items.sku,
          unit: item.items.unit,
          inventoryType: item.items.inventory_type,
          ordered: item.return_quantity_added,
          //warehouse_idd: res.sales_order.sales_order_details[index].warehouse_id ? res.sales_order.sales_order_details[index].warehouse_id : res.sales_order.warehouse_id,
          //res_warehouse_id:  res.sales_order.sales_order_details[index].warehouse_id ? res.sales_order.sales_order_details[index].warehouse_id : res.sales_order.warehouse_id,
          warehouse_idd:
            res.sales_return_details[index].target_warehouse_id !== null
              ? res.sales_return_details[index].target_warehouse_id
              : res.sales_return_details[index].warehouse_id,
          old_warehouse_idd:
            res.sales_return_details[index].target_warehouse_id !== null
              ? res.sales_return_details[index].target_warehouse_id
              : res.sales_return_details[index].warehouse_id,
          res_warehouse_id: res.sales_return_details[index].warehouse_id
            ? res.sales_return_details[index].warehouse_id
            : res.sales_order.warehouse_id,
          packed: item.packed,
          shipped: item.shipped,
          stocks:
            item.items.stocks.find(
              (stock) => stock.warehouse_id === res.sales_order.warehouse_id
            ) || {},
          quantity: item.return_quantity_remaining,
        })
      );
      setValues({
        ...values,
        salesOrderId: selected.value,
        orderDate: moment(res.sales_order.order_date),
        items: [...resItems],
      });
    } catch (error) {
      setValues({
        ...values,
        salesOrderId: selected.value,
        items: [],
      });
      //checkError(error)
    }
    setflag(true);
  };

  let primaryWarehouse = null;
  warehousesList.forEach(function (warehouse) {
    if (warehouse.is_primary) {
      primaryWarehouse = warehouse.id;
    }
  });

  const handleSelectedItem = (option, index) => {
    const newItem = { ...option, quantity: option.quantity };
    const updatedItems = [
      ...Object.assign([...values.items], { [index]: newItem }),
    ];
    setFieldValue('items', updatedItems);
    setStatus({
      ...status,
      itemsList: status.itemsList.filter(
        (item) => item.salesOrderItemDetailId !== option.salesOrderItemDetailId
      ),
    });
  };

  const renderAddItem = () => {
    const { items } = values;
    items.forEach((item) => {
      if (item.id == null) {
        packageFakeItem = true;
      }
    });
    if (packageFakeItem) {
      return '';
    } else {
      return (
        <AddItem
          onClick={() => setFieldValue('items', [...values.items, emptyItem])}
        />
      );
    }
  };

  // function handleSaveAndSend(event , submitFlag) {
  //   setFieldValue('saveAndSend', true)
  //   if(submitFlag=== false || submitFlag=== undefined ){
  //     event.preventDefault()
  //   }else{
  //     setTimeout(() => {
  //       return formSubmit(event, values.items, setFieldValue, handleSubmit)}, 1)
  //   }
  // }
  // function handleSaveAndPrint(event , submitFlag) {
  //   setFieldValue('saveAndPrint', true)
  //   if(submitFlag=== false){
  //     event.preventDefault()
  //   }else{
  //     setTimeout(() => {
  //       return formSubmit(event, values.items, setFieldValue, handleSubmit)}, 1)
  //   }
  // }

  const renderAddItemButton = () => {
    const { itemsList } = status;
    packageFakeItem = itemsList.length > 0 ? false : true;
    if (packageFakeItem) {
      return '';
    } else {
      return (
        <AddItem
          onClick={() => setFieldValue('items', [...values.items, emptyItem])}
        />
      );
    }
  };

  const handleItemQuantityChange = (quantityValue, index) => {
    // Price_Quantity_Limit

    // price and quantity limit from 5 digits to 10 digits
    let quantity = quantityValue.floatValue;
    if (quantity > 0) {
      let quantString = quantity.toString();
      if (quantString.length > 10) {
        quantString = quantString.slice(0, 10);
      }
      quantity = parseInt(quantString);
    }
    setFieldValue('items[' + index + '].quantity', quantity);
  };

  const getvals = () => {
    if (itemsList.length === 0) {
      setAddItem(false);
    }
    setWarehouseNames([]);
    if (yikes[0] !== null) {
      for (let i = 0; i < yikes.length; i++) {
        for (let j = 0; j < warehouseList.length; j++) {
          if (yikes[i] === warehouseList[j].id) {
            let arr = [];
            arr = warehouseNames;
            arr.push(warehouseList[j].name);
            setWarehouseNames(arr);
          }
        }
      }
    } else {
      for (let i = 0; i < values.items.length; i++) {
        for (let j = 0; j < warehouseList.length; j++) {
          if (genericWarehouseId === warehouseList[j].id) {
            let arr = [];
            arr = warehouseNames;
            arr.push(warehouseList[j].name);
            setWarehouseNames(arr);
          }
        }
      }
    }

    let arr = [];
    arr = warehouseNames;
    arr.splice(yikes.length);
    setWarehouseNames(arr);
    // setAddItem(false)
  };
  const handleRemoveItem = (removeIndex, currentItem) => {
    setAddItem(true);
    let updatedItems = values.items.filter((_, index) => index !== removeIndex);
    setFieldValue('items', updatedItems);
    packageFakeItem = false;
    if (!currentItem.id) return;

    setStatus({
      ...status,
      itemsList: [...status.itemsList, currentItem],
    });
    // valFlag=true
    // reArrangeWarehouses()
  };
  // let location = useLocation();
  const [flag, setflag] = React.useState(false);
  const [addItem, setAddItem] = React.useState(false);
  const [warehouseList, setWarehouseList] = React.useState({});
  const [warehouseNames, setWarehouseNames] = React.useState([]);
  const [yikes, setyikes] = React.useState([]);
  const [salesOrderListEnhancer, setSalesOrderListEnhancer] =
    React.useState(salesOrderList);
  const [contactPersonIdEnhancer, setContactPersonIdEnhancer] =
    React.useState(contactPersonId);
  const [backToStock, setBackToStock] = React.useState(true);
  useEffect(() => {
    addBackToStock !== null && setBackToStock(addBackToStock);
    values.customerNotes = returnReason;
    values.returnReasonDescription = returnReasonDescription;
    // =returnReason
    setWarehouseList(warehouseResult);
    setyikes(warehouseListRecieve);
    setflag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(values.items);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFieldValue('items', items);
  };

  const renderSelectedItemsTable = () => {
    const { items } = values;
    if (warehouseList.length > 0) {
      // let arr = [];
      for (let i = 0; i < items.length; i++) {
        for (let j = 0; j < warehouseList.length; j++) {
          if (items[i].warehouse_idd === warehouseList[j].id) {
            //items[i].warehouse_idd =warehouseList[j].name
            items[i].warehouse_idd = warehouseList[j].id;
          }
        }
      }
    }
    resShipmentId = shipmentId;
    return (
      <div className="so-tbletabs">
        <table className="list-table form-table border horizontal-items-table sales-return add_sales_reurn form-focus">
          <thead className="table-header">
            <tr className="table--row">
              <th>Items</th>
              <th className="">Warehouse</th>
              <th className="right-align">Shipped Qty.</th>
              <th className="right-align">Already Returned</th>
              <th className="right-align">To Be Received</th>
            </tr>
          </thead>
          {/* <tbody onLoad={getvals()}> */}

          <DragDropItems handleOnDragEnd={handleOnDragEnd} onLoad={getvals()}>
            {items.map((item, index) => {
              return (
                <Draggable
                  key={item.dragDropID}
                  draggableId={item.dragDropID}
                  index={index}
                >
                  {(provided) => (
                    <tr
                      key={index}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <td className={item.id ? '' : 'no padding'}>
                        <div className="__dragDrop">
                          <div
                            {...provided.dragHandleProps}
                            className={
                              values.items.length >= 2
                                ? '__dragDrop_iconParent'
                                : '__dragDrop_iconParent notDisplay'
                            }
                            tabIndex="-1"
                          >
                            <MdDragIndicator />
                          </div>
                          <div className="__drag-righ">
                            {item.id ? (
                              <ListTableImageColumn
                                name={item.name}
                                images={item.images}
                                sku={item.sku}
                                id={item.id}
                                showModalUponClick={inModal ? false : true}
                              />
                            ) : (
                              <Field
                                type="dropdown"
                                value={item.id}
                                options={itemsList}
                                dropdownValue="id"
                                withImage={true}
                                dropdownLabel="name"
                                placeholder="Select Item"
                                additionalSearchFilter="sku,upc,mpn,ean,isbn"
                                clearable={false}
                                handleChange={(option) =>
                                  handleSelectedItem(option, index)
                                }
                                error={getError(
                                  errors,
                                  touched,
                                  `items[${index}].name`
                                )}
                                onBlur={() =>
                                  setFieldTouched(`items[${index}].name`, true)
                                }
                              />
                            )}
                          </div>
                        </div>
                      </td>
                      <td>
                        {/*console.log('item.warehouse_idd[index]' , item.warehouse_idd[index])*/}
                        {/*console.log('item.warehouse_idd' , index+'-->'+item.warehouse_idd)*/}
                        <Field
                          id="targetWarehouseId"
                          type="dropdown"
                          disabled={item.inventoryType === 'noninventory'}
                          placeholder="Select "
                          value={
                            item.warehouse_idd
                              ? item.warehouse_idd
                              : primaryWarehouse
                          }
                          options={warehousesList}
                          dropdownValue="id"
                          dropdownLabel="name"
                          clearable={false}
                          // disabled={item.salesOrderItemDetailId}
                          required
                          handleChange={(option) =>
                            setFieldValue(
                              `items[${index}].warehouse_idd`,
                              option ? option.value : null
                            )
                          }
                        />
                        {/*<input className="form-input large" placeholder="" id="warehouseId" type="hidden" value={(item.warehouse_idd) ?  item.warehouse_idd : ''} />*/}
                        {/*<Field
                          size='large'
                          id='warehouseId'
                          value={typeof  item.warehouse_idd === 'string' ?  item.warehouse_idd : ''}
                          disabled={true}
                          clearable={false}
                          displayType = 'hidden'
                          required
                          error={
                            touched.warehouseId && errors.warehouseId
                          }
                        />*/}
                        {
                          <div className="table-extra-data right-align">
                            {item.inventoryType === 'noninventory'
                              ? 'Un-tracked item'
                              : ''}
                          </div>
                        }
                      </td>
                      <td className="right-align">
                        <Quantity quantity={item.shipped} unit={item.unit} />
                      </td>
                      <td className="right-align">
                        <Quantity quantity={item.ordered} unit={item.unit} />
                      </td>
                      <td className="right-align no-padding with-close--btn">
                        {item.quantity > item.shipped
                          ? (stopRestRequest = true)
                          : (stopRestRequest = false)}
                        {item.quantity < 1
                          ? (stopRestRequest = true)
                          : (stopRestRequest = false)}
                        <Field
                          className="right-align"
                          size="small"
                          type="number"
                          value={item.quantity}
                          decimalScale={6}
                          id={`items[${index}].quantity`}
                          allowNegative={false}
                          thousandSeparator=","
                          //onValueChange={value => setFieldValue(`items[${index}].quantity`, value.floatValue || null)}
                          onValueChange={(value) =>
                            handleItemQuantityChange(value, index)
                          }
                          error={
                            (touched.items &&
                              item.quantity < 1 &&
                              'Can`t be zero') ||
                            (touched.items &&
                              item.quantity > item.shipped &&
                              'Can`t be more than shipped')
                          }
                          onBlur={handleBlur}
                          onKeyDown={(e) =>
                            handleArrowsKey(e, index, 'quantity')
                          }
                          onKeyUp={(e) =>
                            handleArrowUpKey(e, index, 'quantity')
                          }
                        />
                        <button
                          type="button"
                          disabled={items.length === 1}
                          className="close-btn button"
                          onClick={() => handleRemoveItem(index, item)}
                        >
                          <DeleteIcon className="bin" />
                        </button>
                      </td>
                    </tr>
                  )}
                </Draggable>
              );
            })}
          </DragDropItems>

          {/* </tbody> */}
        </table>
      </div>
    );
  };
  resBackToStock = backToStock;
  rescontactPersonIdEnhancer = contactPersonIdEnhancer;

  if (values.customerNotes !== undefined || values.customerNotes === '') {
    reasonForReturnErr = 1;
  }

  if (reasonForReturnErr === 1 && values.customerNotes === '') {
    errors['resturnreason'] = true;
  }
  if (
    values.customerNotes === 'Other' &&
    values.returnReasonDescription === ''
  ) {
    errors['resturnreasondescp'] = true;
    errors['returnReasonDescription'] = 'Required';
  }
  let submitFlag = true;
  const [stopRequest] = useState(false);
  const handleArrowsKey = (e, index, fieldname) => {
    if (e.key === 'ArrowDown' && index + 1 < values?.items?.length) {
      const element = document.getElementById(
        `items[${index + 1}].${fieldname}`
      );
      setTimeout(() => {
        element.select();
        element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
      }, 100);
    }
  };

  const handleArrowUpKey = (e, index, fieldname) => {
    if (e.key === 'ArrowUp' && index - 1 >= 0) {
      const element = document.getElementById(
        `items[${index - 1}].${fieldname}`
      );
      element.select();
      element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
    }
  };
  return (
    <Form
      title={title}
      className="package-form"
      onSubmit={(event) => {
        if (stopRequest === true) event.preventDefault();
        else if (submitFlag === false) {
          event.preventDefault();
        } else {
          formSubmit(event, values.items, setFieldValue, handleSubmit);
        }
      }}
    >
      <div className="form-fields-container d-flex flex-wrap  position-relative">
        <Field
          name="Sales Return No."
          className="flex-25"
          id="saleReturnNo"
          placeholder="Sales Return No"
          value={values.saleReturnNo}
          handleChange={handleChange}
          onBlur={handleBlur}
          disabled
          // disabled={type === 'edit'}
          required
          error={touched.saleReturnNo && errors.saleReturnNo}
        />
        {!inModal ? (
          <>
            <Field
              type="dropdown"
              name="Customer Name"
              className="flex-25"
              autoFocus={true}
              id="salesOrderId"
              disabled={inModal || type === 'edit'}
              value={contactPersonIdEnhancer}
              options={contactPerson}
              dropdownValue="id"
              dropdownLabel="name"
              handleChange={(option) => {
                setContactPersonIdEnhancer(option.id);
                let myOrderList = [];
                allContactsList.forEach((cp) => {
                  if (option.id === cp.id) {
                    cp.order_list.forEach((ol) => {
                      let tempraryObj = {};
                      tempraryObj['id'] = ol.id;
                      tempraryObj['name'] = ol.sales_order_no;
                      myOrderList.push(tempraryObj);
                    });
                  }
                });
                if (myOrderList[0].id) setSalesOrderListEnhancer(myOrderList);
                let tempObj = {};
                tempObj['id'] = myOrderList[0].id;
                tempObj['value'] = myOrderList[0].id;
                tempObj['name'] = myOrderList[0].name;
                tempObj['label'] = myOrderList[0].name;
                handleSalesOrderChange(tempObj);
              }}
              clearable={false}
              onBlur={handleBlur}
              required
              error={touched.salesOrderId && errors.salesOrderId}
            />
            <Field
              type="dropdown"
              name="Sales Order No"
              id="salesOrderId"
              className="flex-25"
              value={values.salesOrderId}
              options={salesOrderListEnhancer}
              dropdownValue="id"
              dropdownLabel="name"
              handleChange={(option) => handleSalesOrderChange(option)}
              clearable={false}
              disabled={inModal || type === 'edit'}
              onBlur={handleBlur}
              required
              error={touched.salesOrderId && errors.salesOrderId}
            />
          </>
        ) : (
          <>
            <Field
              name="Customer Name"
              className="flex-25"
              id="customerId"
              placeholder="Customer Name"
              value={CustomerName}
              disabled
            />
            <Field
              className="flex-25"
              name="Sales Order No."
              id="salesOrderNo"
              placeholder="Sales Order No."
              value={SaleOrderNo}
              disabled
            />
          </>
        )}
        {!flag ? <Loader className={''} /> : null}
        <Field
          type="date"
          className="flex-25"
          name="Return Date"
          id="salesReturnDate"
          value={values.salesReturnDate}
          minDate={values?.orderDate}
          handleChange={(date) => {
            setFieldValue(
              'salesReturnDate',
              date && date.format('YYYY-MM-DD 00:00:00')
            );
          }}
          onBlur={() => setFieldTouched('salesReturnDate', true)}
          required
          error={touched.salesReturnDate && errors.salesReturnDate}
        />
        <Field
          type="dropdown"
          className="flex-25"
          name="Reason for return"
          id="customerNotes"
          value={values.customerNotes}
          options={returnReasonsFilter}
          dropdownValue="id"
          dropdownLabel="name"
          handleChange={(option) => {
            setFieldValue('customerNotes', option.id);
          }}
          clearable={false}
          onBlur={handleBlur}
          required
          error={touched.customerNotes && errors.customerNotes}
        />
        {values.customerNotes === 'Other' && (
          <Field
            className="flex-50"
            type="textarea"
            name="Return Reason Description"
            value={values.returnReasonDescription}
            id="returnReasonDescription"
            handleChange={handleChange}
            onBlur={handleBlur}
            required
            error={
              touched.returnReasonDescription && errors.returnReasonDescription
            }
          />
        )}
      </div>
      <div
        className={`form-field d-flex align-center sr-popup-inner ${
          values.customerNotes === 'Other' ? ' margin_top_ ' : ''
        }`}
        style={{ marginTop: '2rem' }}
      >
        <input
          className="checkbox_with_label"
          type="checkbox"
          name="backToStock"
          id="View"
          onChange={() => {
            setBackToStock(!backToStock);
            setFieldValue('back_to_stock', !backToStock);
          }}
          checked={backToStock}
        />
        <label
          className="bold fs_16 lh-0"
          htmlFor="backToStock"
          onClick={() => setBackToStock(!backToStock)}
        >
          Add Items back to stock
        </label>
      </div>
      <div className="form-field form-table--container">
        <label className="form-label">Select Items</label>
        <FieldArray name="items" render={() => renderSelectedItemsTable()} />
        {type === 'add' && addItem && (
          <React.Fragment>{renderAddItem()} </React.Fragment>
        )}
        {type === 'edit' && (
          <React.Fragment>{renderAddItemButton()} </React.Fragment>
        )}
      </div>

      <FormAction
        disabled={isSubmitting}
        marginFromRight={2.4}
        onCancel={() => {
          let dataFromLS = getObjFromLS('module');
          let redirectUrl = '',
            response;
          if (dataFromLS && dataFromLS.moduleName === 'salesreturns') {
            redirectUrl = dataFromLS.queryParam;
          }
          let { checkout } = validateSalesReturnForm(values, staticVal);
          if (!checkout) {
            response = confirmationAlert();
          }
          if (response || checkout) {
            if (inModal) {
              close();
            } else if (
              window.location.pathname.includes('add') ||
              window.location.pathname.includes('edit')
            ) {
              history.push('/salesreturns' + redirectUrl);
            } else {
              setObjInLS('module', { ...dataFromLS, action: null });
              history.push('/r');
            }
          }
        }}
      />
      <ErrorFocus />
    </Form>
  );
};

export default formEnhancer(PackageForm);
