import React from 'react'

import { NewPurchaseOrderIcon } from '../../Assets/Navigation/NavigationIcons'

import Email from '../../Components/Email/Email'

export default (props) => {
  return (
    <Email
      {...props}
      Icon={NewPurchaseOrderIcon}
      name="Purchase Orders"
      onCancelURL="/purchaseorders"
      emailAPI={`purchaseorders/${props.match.params.id}/mail`}
      fetchDataAPI={`purchaseorders/${props.match.params.id}/mail`}
      getBackTab
    />
  )
}