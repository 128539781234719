import React, { Component } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  // Legend,
  ResponsiveContainer,
} from 'recharts';
// import { MdFormatAlignJustify } from '../../../Common/Icons';
import CreditNoteIcon from '../../../Assets/Dashboard/CreditNoteIcon';
import './DashboardSOSummary.css';
import Loader from '../../../Components/Loader/Loader';
import { restRequest } from '../../../Helpers/RequestHelper';
import SaleReturnIcon from '../../../Assets/Dashboard/SalesReturnIcon';
import { Link } from 'react-router-dom';
import { formatNumber } from '../../../Helpers/FormatNumber';
import AccessDenied from '../../../Components/AccessDenied/AccessDenied';
import DataError from '../../../Components/DashboardDataError/DB_DataError';
import { checkError } from '../../../Helpers/AuthHelper';
import {setDashboardAmount} from "../../../Helpers/setAmount";
// const data = [
//   {
//     name: 'Page A',
//     purchase: 4000,
//     sales: 2400,
//   },
//   {
//     name: 'Page B',
//     purchase: 3000,
//     sales: 1398,
//   },
//   {
//     name: 'Page C',
//     purchase: 2000,
//     sales: 9800,
//   },
//   {
//     name: 'Page D',
//     purchase: 2780,
//     sales: 3908,
//   },
//   {
//     name: 'Page E',
//     purchase: 1890,
//     sales: 4800,
//   },
//   {
//     name: 'Page F',
//     purchase: 2390,
//     sales: 3800,
//   },
//   {
//     name: 'Page G',
//     purchase: 3490,
//     sales: 4300,
//   },
// ]

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    //console.log('data', payload)
    return (
      <div className="so-summary-custom-tooltip">
        <div className="so-summary-intro">{label}</div>
        <div className="so-summary-label first_parent">
          {`Sales${'\u00A0'} `}{' '}
          <span className="first">
            {payload[0].payload.unit}
            {setDashboardAmount(payload[0].value)}
          </span>
        </div>
        <div className="so-summary-label">
          {`Credit${'\u00A0'} `}{' '}
          <span>
            {payload[1].payload.unit}
            {setDashboardAmount(payload[1].value)}
          </span>
        </div>
      </div>
    );
  }

  return null;
};
export default class DashboardSOSummary extends Component {
  constructor(props) {
    super();
    this.state = {
      data: {},
      chartData: {},
      loading: true,
      accessDenied: false,
      accessError: false
    };
  }
  dashboard_preferences = JSON.parse(localStorage.getItem("dashboardPreferences"))


  componentDidMount() {
    this.fetchData(this.dashboard_preferences.sales_order_summary);
  }

  //   shouldComponentUpdate(nextProps) {
  //     return (this.props !== nextProps) ? false : true;
  // }

  fetchData(currentDateRange, startData, endDate) {
    this.setState({ loading: true });
    restRequest(
      'get',
      `dashboard/salesordersummary/${currentDateRange === 'custom'
        ? `custom?starting_date=${startData}&ending_date=${endDate}`
        : currentDateRange
      }`
    )
      .then((res) => {
        this.setState({
          data: res,
          chartData: this.prepareChartData(res.currency, res.chart_data),
          loading: false,
          accessDenied: false,
        });
      })
      .catch((err) => {
        checkError(err);
        if (err.response.status === 403) {
          this.setState({ accessDenied: true, loading: false });
        } else {
          this.setState({ accessError: true, loading: false });

        }
      });
  }

  componentWillReceiveProps(nextProps) {
    JSON.stringify(nextProps) !== JSON.stringify(this.props) &&
      this.fetchData(
        nextProps.currentDateRange,
        nextProps.startData,
        nextProps.endDate
      );
  }

  prepareChartData(currency, chartData = {}) {
    let resultData = [];

    Object.keys(chartData.sales_order).forEach((val, i) => {
      resultData.push({});
      resultData[i]['name'] = val;
    });

    Object.values(chartData.sales_order).forEach((val, i) => {
      resultData[i]['sales'] = val;
    });
    Object.values(chartData.credit_notes).forEach((val, i) => {
      resultData[i]['credit'] = val;
    });

    resultData.forEach(function (res) {
      res.unit = currency;
    });

    return resultData;
  }

  yaxisNumber(label) {
    if (label >= 1000) {
      return `${label / 1000}k`;
    } else {
      return `${label}`;
    }
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.accessDenied)
      return (
        <AccessDenied className="small dashboard " type="Sales Order Summary" />
      );
    if (this.state.accessError)
      return (
        <DataError className="small dashboard " type="Sales Order Summary" />
      );
    const { data } = this.state;
    const { currency } = data;
    return (
      <>
        {/* <div className="so-summary-custom-tooltip">
        <div className="so-summary-intro">23 Apr</div>
        <div className="so-summary-label">{`Sales${'\u00A0'}`} <span>$307.65</span></div>
        <div className="so-summary-label">{`Credit${'\u00A0'}`} <span>$30337.65</span></div>
      </div> */}
        <div className="height_100 sales-order-main">
          <div
            className="dashboard_so_summary"
            style={{
              float: 'left',
              width: '66.66%',
              paddingRight: '10px',
              minHeight: '300px',
            }}
          >
            <ResponsiveContainer width="100%" height="100%" minHeight={300}>
              <LineChart
                minHeight={300}
                data={this.state.chartData}
                margin={{
                  top: 5,
                  right: 10,
                  left: 10,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis tickFormatter={(label) => this.yaxisNumber(label)} />
                {/*<Tooltip />*/}
                <Tooltip content={<CustomTooltip />} />
                {/*<Legend />*/}
                <Line
                  type="monotone"
                  dataKey="sales"
                  stroke="#33A348"
                  activeDot={{ r: 8 }}
                />
                <Line type="monotone" dataKey="credit" stroke="red" />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div
            style={{ float: 'left', width: '33.33%' }}
            className="sales-summary-right"
          >
            <div className="left_section">
              <div className="gray_background so_sum_data">
                <h4 className="mt_0 mb__10 fs_20">
                  Sale <span className=" fs_14">(Total)</span>
                </h4>
                <h3 className="bold__ no_margin fs_20">
                  {currency}
                  {`${setDashboardAmount(data.total_sales_order)}`}
                </h3>
              </div>
              <div className="so_sum_data">
                <h4 className="mt_0 mb__10 fs_20">
                  Credits <span className=" fs_14">(Issued)</span>
                </h4>
                <h3 className="bold__ no_margin fs_20">
                  {currency}
                  {`${setDashboardAmount(data.credit_notes.total_amount)}`}
                </h3>
              </div>
              <div className="gray_background so_sum_data">
                <h4 className="mt_0 mb__10 fs_20">
                  Sale <span className=" fs_14">(Net)</span>
                </h4>
                <h3 className="bold__ no_margin fs_20">
                  {currency}
                  {`${setDashboardAmount(data.total_sales_amount)}`}
                </h3>
              </div>
            </div>

            <div className="right_section">
              <div className="align_items_">
                {/*<Link className="so_summ_sr_cn float-left w-100" to="salesreturns?filter=all&sort=created_at&page=1&orderBy=desc&view=20">*/}
                <Link
                  className="so_summ_sr_cn float-left w-100"
                  to={`salesreturns?filter=all&sort=created_at&page=1&orderBy=desc&view=20&start=${data.start_date}&end=${data.end_date}`}
                >
                  <div className="float-left w-100">
                    <div className="float-left w-100">
                      <SaleReturnIcon className="material-icon fill-green float-left" />{' '}
                      <span
                        className="float-left bold__"
                        style={{ marginTop: '5px', fontSize: '16px' }}
                      >
                        Sales Return{' '}
                      </span>
                    </div>
                    <div className="float-left w-100">
                      <span className="float-left w-50">No. of Returns</span>
                      <p className="float-left">:</p>
                      <span className="red_font float-left">
                        {data.sales_return_details.sales_return_orders}
                      </span>
                    </div>
                    <div className="float-left w-100">
                      <span className="float-left w-50">Total Value </span>
                      <span className="float-left bold color_black w-50">
                        <p className="float-left">:</p>
                        {currency}
                        {`${setDashboardAmount(
                          data.sales_return_details.sales_return_total
                        )}`}{' '}
                      </span>
                    </div>
                  </div>
                </Link>
                <Link
                  className="float-left w-100 so_summ_sr_cn"
                  to={`creditnotes?filter=all&sort=created_at&page=1&orderBy=desc&view=20&start=${data.start_date}&end=${data.end_date}`}
                >
                  <div className="float-left w-100">
                    <div className="float-left w-100">
                      {/* <MdFormatAlignJustify className="material-icon fill-green float-left" />{' '} */}
                      <CreditNoteIcon className="material-icon fill-green float-left" />{' '}
                      <span
                        className="float-left bold__"
                        style={{ marginTop: '0px', fontSize: '16px' }}
                      >
                        Credit Notes{' '}
                      </span>
                    </div>
                    <div className="float-left w-100">
                      <span className="float-left w-50">
                        No. of Credit Notes.
                      </span>
                      <p className="float-left">:</p>
                      <span className="red_font float-left">
                        {data.credit_notes.credit_notes_issued}
                      </span>
                    </div>
                    <div className="float-left w-100">
                      <span className="float-left w-50">Total Amount</span>
                      <span className="float-left bold color_black w-50">
                        <p className="float-left">:</p>
                        {currency}
                        {`${setDashboardAmount(data.credit_notes.total_amount)}`}
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
