import React, { Fragment, useState } from 'react';
import './AmountInformation.css';
import CustomModal from '../../../../Components/CustomModal/CustomModal';
import { NewContactIcon } from '../../../../Assets/Navigation/NavigationIcons';
import ContactDetails from '../../../../Pages/Contact/ContactDetails/index';
const AmountInformation = ({
  price,
  account: { accountTitle, accountGroup },
  description,
  Tax = null,
  preferredVendor = null,
  inModal = false,
}) => {
  const [showContactModal, setShowContactModal] = useState(false);

  const viewContactModal = () => {
    return (
      <CustomModal
        size="large"
        className="contacts_modal_view"
        showModal={showContactModal}
        title="View Contact"
        modaltitle="inventory_modal_title"
        // minWidth={120}
        Icon={NewContactIcon}
        renderActions={false}
        onClose={() => setShowContactModal(false)}
        linkTo={
          preferredVendor &&
          preferredVendor.id &&
          `contacts/${preferredVendor.id}`
        }
      >
        <ContactDetails id={preferredVendor?.id || ''} inModal />
      </CustomModal>
    );
  };
  const handleModel = () => {
    setShowContactModal(true);
  };
  return (
    <Fragment>
      {showContactModal && viewContactModal()}

      <div className="section-details--info-details">
        <div className="item-purchase-sales-info">
          <div className="section-details--info-title bold">Price</div>
          <div className="margin-bottom-sm">{price}</div>
        </div>

        <div className="item-purchase-sales-info">
          <div className="section-details--info-title bold">Account</div>
          <div className="margin-bottom-sm">{`${accountTitle} (${accountGroup})`}</div>
        </div>

        {Tax && (
          <div className="item-purchase-sales-info">
            <div className="section-details--info-title bold">Tax</div>
            <div className="margin-bottom-sm">
              {Tax.name} ({Tax.rate}%) {Tax.authority && `- ${Tax.authority}`}
            </div>
          </div>
        )}

        {preferredVendor && preferredVendor.id && (
          <div className="item-purchase-sales-info">
            <div className="section-details--info-title bold">
              Preferred Vendor
            </div>
            <div className="margin-bottom-sm">
              {inModal ? (
                <a
                  href={`/contacts/${preferredVendor.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {preferredVendor.display_name}
                </a>
              ) : (
                <div onClick={handleModel}>{preferredVendor.display_name}</div>
              )}
            </div>
          </div>
        )}
        <div className="item-purchase-sales-info mt-2">
          <div className="section-details--info-title bold">Description</div>
          <div className="margin-bottom-sm w-100">
            {description ? description : 'N/A'}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AmountInformation;
