import React from 'react';
import { GridLayout } from '../../Layout/Layout';

const TotalBillSection = ({
  symbol = '$',
  subTotal = 0,
  discountTotal = 0,
  shippingCharges = 0,
  totalTax = 0,
  total = 0,
}) => {
  return (
    <>
      <GridLayout className="layout-padding" grid="3-2">
        <div className="section-details--footer-amount">
          <div>
            <span>Sub Total </span>
            <span style={{ marginLeft: '-20%' }}>{`${symbol}${parseFloat(
              subTotal
            ).toFixed(2)}`}</span>
          </div>
          <div>
            <span className="discount-text-set">Discount</span>
            <span>
              {symbol}
              {parseFloat(discountTotal).toFixed(2)}
            </span>
          </div>
          <div>
            <span>Shipping & Handling</span>
            <span style={{ marginLeft: '-20%' }}>
              {symbol}
              {shippingCharges ? parseFloat(shippingCharges).toFixed(2) : `0.00`}
            </span>
          </div>
          <div>
            <span>Total Tax</span>
            <span style={{ marginLeft: '-20%' }}>
              {symbol}
              {parseFloat(totalTax).toFixed(2)}
            </span>
          </div>
        </div>
      </GridLayout>
      <hr className="float-left w-100 divider" />
      <div className="total_field">
        <span className="semi-bold bottom_title">Total </span>
        <span className="semi-bold">
          {symbol}
          {parseFloat(total).toFixed(2)}
        </span>
      </div>
    </>
  );
};

export default TotalBillSection;
