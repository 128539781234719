import React, { Fragment, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransitionGroup } from 'react-transition-group';
import classNames from 'classnames';
import { MdClose, MdOpenInNew } from '../../Common/Icons';
import { confirmationAlert } from '../../Helpers/FormConfirmAlert';
import './CustomModal.css';

export default ({
  children,
  Icon = () => null,
  className = 'invoice-popup-set',
  modaltitle = '',
  //style = '',
  showModal = false,
  onClose = () => {},
  onSubmit = () => {},
  title = 'Confirm this action?',
  renderActions = true,
  confirmLabel = 'Confirm',
  closeLabel = 'Close',
  size = 'large', //'large', 'medium'
  pdf = false,
  showActionInMenu = false,
  type, // delete
  fill = '',
  renderShipmentActionMenu,
  renderPaymentActionMenu,
  renderReturnReceiveActionMenu,
  renderReceiveActionMenu,
  renderBillPaymentActionMenu,
  extraClass = '',
  customWidth = '',
  linkTo = '',
  minWidth = null,
  extraTitle = '',
  classNames: chilClassName = '',
  handleSubmiting = false,
  showPrompt = false,
  mainContainerClass = '',
  TItleClass = '',
  disabled = false,
}) => {
  const handleCloseAlert = () => {
    let alert = JSON.parse(localStorage.getItem('alert') || 'false');
    let response = true;
    if (showPrompt || alert) {
      response = confirmationAlert();
    }
    if (response) {
      onClose();
    }
  };

  useEffect(() => {
    showModal && sessionStorage.setItem('modal', true);
  }, [showModal]);
  return ReactDOM.createPortal(
    <CSSTransitionGroup
      component={Fragment}
      transitionName="ims-modal-transition"
      transitionEnterTimeout={300}
      transitionLeaveTimeout={300}
    >
      {showModal && (
        <div
          key={1}
          className={`ims-modal-container ${
            renderShipmentActionMenu ? 'shipment_modal' : ''
          } ${mainContainerClass ? mainContainerClass : ''}`}
          // onClick={handleOutsideClick}
        >
          <div
            style={{ minWidth: `${minWidth ? minWidth : ''}rem` }}
            className={`ims-modal white ${size} ${className} ${customWidth} ${chilClassName}`}
          >
            <Fragment>
              <div
                className={`ims-modal--title ${TItleClass} ${
                  showActionInMenu ? 'shipment_header' : ''
                }`}
              >
                <span className={modaltitle}>
                  {Icon.length > 0 && (
                    <span
                      style={{ width: '25px', height: '24px', float: 'left' }}
                    >
                      <Icon className={'show-icon'} fill={fill} />
                    </span>
                  )}
                  {title}
                </span>
                {extraTitle && <span>{extraTitle}</span>}
                {showActionInMenu ? (
                  <div className="jjgjgjgg">
                    {renderShipmentActionMenu && renderShipmentActionMenu}
                    {renderPaymentActionMenu && renderPaymentActionMenu}
                    {renderReturnReceiveActionMenu &&
                      renderReturnReceiveActionMenu}
                    {renderReceiveActionMenu && renderReceiveActionMenu}
                    {renderBillPaymentActionMenu && renderBillPaymentActionMenu}
                    <span className="close-button">
                      {linkTo && (
                        <a
                          href={'/' + linkTo}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <MdOpenInNew />
                        </a>
                      )}
                      <button onClick={onClose}>
                        <MdClose />
                      </button>
                    </span>
                  </div>
                ) : (
                  <span className="close-button">
                    {linkTo && (
                      <a
                        href={'/' + linkTo}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <MdOpenInNew />
                      </a>
                    )}
                    <button onClick={handleSubmiting ? null : handleCloseAlert}>
                      <MdClose />
                    </button>
                  </span>
                )}
              </div>
              <div
                className={`ims-modal--body ${
                  pdf ? 'modal-pdf' : ''
                } ${extraClass}`}
              >
                {children}
              </div>
              {renderActions && (
                <div className="ims-modal--action">
                  <button
                    className={classNames(
                      'button',
                      type === 'delete'
                        ? 'ims-modal--action--delete-cancel'
                        : 'ims-modal--action--cancel cancel_button'
                    )}
                    onClick={onClose}
                  >
                    {type === 'delete' ? 'Cancel' : closeLabel}
                  </button>
                  <button
                    id="Click_Delete_Button"
                    className={classNames(
                      'button',
                      type === 'delete'
                        ? 'ims-modal--action--delete'
                        : 'ims-modal--action--confirm confitm_button'
                    )}
                    onClick={onSubmit}
                    disabled={disabled}
                  >
                    {type === 'delete' ? 'Delete' : confirmLabel}
                  </button>
                </div>
              )}
            </Fragment>
          </div>
        </div>
      )}
    </CSSTransitionGroup>,
    document.body
  );
};
