const GenerateOptions = (
  [
    hasCreatePermission,
    hasEditPermission,
    hasDeletePermission
  ],
  rowOptions,
  ...otherChecks // array first index = edit permission check
) => {

  const [
    editCriteria
  ] = otherChecks

  const generatedOptions = []
  
  for(let option of rowOptions) {
    if(option.label === 'Clone' && !hasCreatePermission ) continue
    if(option.label === 'Edit' && !hasEditPermission) continue
    if(option.label === 'Delete' && !hasDeletePermission) continue

    if(!!editCriteria && editCriteria.includes(option.label) && !hasEditPermission) continue

    generatedOptions.push(option)
  }

  return generatedOptions
}

export default GenerateOptions