import React from 'react';
import { withRouter } from 'react-router-dom';
import { Guideline } from '../../Helpers/getGuideline';
import FileUploader from '../FileUploader/FileUploader';
import FormSection from '../Form/FormSection/FormSection';
import FormAction from '../Form/FormAction';

const AddImportFile = ({ ...props }) => {
  let handleImportFile = props.handleImportFile;
  let handleImportDuplicate = props.handleImportDuplicate;
  let handleImportType = props.handleImportType;
  let onFileDrop = props.onFileDrop;
  let csvFile = props.csvFile;
  let duplicate = props.duplicate;
  let importType = props.importType;
  // let sampleFileLink = props.sampleFileLink
  let disableNext = props.disableNext;

  return (
    <FormSection title="Select File">
      <form onSubmit={handleImportFile}>
        <section>
          <div className="subtitle">Import From</div>
          <div className="radio-container">
            <select
              className="form-field inline-field"
              value={importType}
              name="importType"
              id="importType"
              onChange={handleImportType}
            >
              <option value="seebiz">SeeBiz CSV File</option>
              <option value="zoho">Zoho CSV File</option>
              <option value="quickbookc">Quick Book Export CSV File</option>
              <option value="quickbookr">Quick Book Report CSV File</option>
            </select>
          </div>
        </section>
        <section>
          <div className="subtitle">
            Steps To Prepare Your Data For Import Process:
          </div>
          {Guideline(importType, window.location.pathname)}
          {/* <div className="import-file--sample">
          Download a <a
          href={sampleFileLink}
          rel="noopener noreferrer"
          target="_blank">sample file</a> for SeeBiz reference so you may ensure you have an acceptable file.
        </div> */}
          <br />
          <div className="subtitle">Upload File</div>
          {csvFile.name !== undefined && <div>{csvFile.name}</div>}
          <FileUploader accept=".CSV" onDrop={onFileDrop} />
          <div>File formats accepted: CSV</div>
        </section>

        <section>
          <div className="subtitle">Duplicate Records</div>
          <div className="radio-container">
            <div className="radio-btn">
              <input
                type="radio"
                id="skip"
                value="skip"
                checked={duplicate === 'skip'}
                onChange={handleImportDuplicate}
              />
              <label htmlFor="skip">Skip</label>
              <span className="radio-circle" />
            </div>
            <div className="radio-btn">
              <input
                type="radio"
                id="overwrite"
                value="overwrite"
                checked={duplicate === 'overwrite'}
                onChange={handleImportDuplicate}
              />
              <label htmlFor="overwrite">Overwrite</label>
              <span className="radio-circle" />
            </div>
          </div>
        </section>

        <FormAction
          submitLabel="Next"
          disabled={disableNext}
          onCancel={() => props.history.goBack()}
        />
      </form>
    </FormSection>
  );
};

export default withRouter(AddImportFile);
