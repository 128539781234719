import React from 'react';

import './SearchResult.css';

// used in list pages to show result criterias
export default ({
  criterias = [], // array or string
  searchKey = '',
}) => (
  <div className="search-result">
    {criterias instanceof Array ? (
      criterias.map((critera, index) => (
        <span key={index}>
          <u>{critera}</u>
          {index !== criterias.length - 1 ? ' or ' : ' '}{' '}
        </span>
      ))
    ) : (
      <span>
        <u>{criterias}</u>
      </span>
    )}{' '}
    contains: <span className="semi-bold">"{searchKey}"</span>
  </div>
);
