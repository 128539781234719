import React from 'react';

import RecordPaymentIcon from '../../../../../../Assets/General/RecordPaymentIcon';

import Email from '../../../../../../Components/Email/Email';

const BillPaymentEmail = (props) => {
  return (
    <Email
      {...props}
      Icon={RecordPaymentIcon}
      name="Bill Payment"
      onCancelURL="/bills"
      emailAPI={`bill_payments/${props.match.params.id}/mail`}
      fetchDataAPI={`bill_payments/${props.match.params.id}/mail`}
      getBackTab
    />
  );
};

export default BillPaymentEmail;
