

import React from 'react'

export default ({
  className = ''
}) => (
  
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
<path id="Path_146" data-name="Path 146" d="M7.973-3.027A3.371,3.371,0,0,0,9-5.5,3.371,3.371,0,0,0,7.973-7.973,3.371,3.371,0,0,0,5.5-9,3.371,3.371,0,0,0,3.027-7.973,3.371,3.371,0,0,0,2-5.5,3.371,3.371,0,0,0,3.027-3.027,3.371,3.371,0,0,0,5.5-2,3.371,3.371,0,0,0,7.973-3.027ZM13,1a.961.961,0,0,1-.3.7A.961.961,0,0,1,12,2a.928.928,0,0,1-.7-.3L8.617-.969A5.35,5.35,0,0,1,5.5,0,5.4,5.4,0,0,1,3.363-.434,5.5,5.5,0,0,1,1.605-1.605,5.5,5.5,0,0,1,.434-3.363,5.4,5.4,0,0,1,0-5.5,5.4,5.4,0,0,1,.434-7.637,5.5,5.5,0,0,1,1.605-9.395a5.5,5.5,0,0,1,1.758-1.172A5.4,5.4,0,0,1,5.5-11a5.4,5.4,0,0,1,2.137.434A5.5,5.5,0,0,1,9.395-9.395a5.5,5.5,0,0,1,1.172,1.758A5.4,5.4,0,0,1,11-5.5a5.35,5.35,0,0,1-.969,3.117L12.711.3A.957.957,0,0,1,13,1Z" transform="translate(0 11)" fill="#306ab4"/>
</svg>
)
