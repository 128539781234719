import React, { Component } from 'react';

import { restRequest } from '../../../../../Helpers/RequestHelper';
import Loader from '../../../../../Components/Loader/Loader';
import NotFound from '../../../../../Components/NotFound/NotFound';
import TimelineCard from '../../../../../Components/TimelineCard/TimelineCard';

import './ContactActivity.css';
import { checkError } from '../../../../../Helpers/AuthHelper';

class ContactActivityTab extends Component {
    state = {
        activities: [],
        loading: true,
    };

    componentDidMount() {
        this.fetchActivityList();
    }

    fetchActivityList() {
        this.setState({ loading: true });
        restRequest('get', `contacts/${this.props.contactId}/activity`)
            .then((activityList) => {
                this.setState({
                    activities: activityList,
                    loading: false,
                });
            })
            .catch((error) => {
                checkError(error, this.props.handleToast);
            });
    }

    render() {
        if (this.state.loading) {
            return <Loader />;
        }

        if (this.state.activities.length === 0) {
            return <NotFound />;
        }

        return (
            <div>
                {this.state.activities.map((activity) => (
                    <TimelineCard
                        key={activity.id}
                        name={activity.creator}
                        description={activity.description}
                        date={activity.date}
                        time={activity.time}
                        subject={activity.subject}
                        type={activity.type}
                    />
                ))}
            </div>
        );
    }
}

export default ContactActivityTab;
