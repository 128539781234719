import React from 'react'

export default ({
  className = ''
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="162" height="162" viewBox="0 0 162 162" className={className}>
    <g transform="translate(-281 -172)">
      <text transform="translate(307 257)" fill="#dfe1e5" stroke="rgba(0,0,0,0)" strokeWidth="1" fontSize="14" fontFamily="Roboto-Medium, Roboto" fontWeight="500">
        <tspan x="0" y="0">No Data Available</tspan>
      </text>
      <g transform="translate(281 172)" fill="none" stroke="#dfe1e5" strokeWidth="20">
        <circle cx="81" cy="81" r="81" stroke="none"/>
        <circle cx="81" cy="81" r="71" fill="none"/>
      </g>
    </g>
  </svg>
)

  /*<svg xmlns="http://www.w3.org/2000/svg" width="17" height="22.5" viewBox="0 0 17 22.5" className={className}>
  <g transform="translate(-644 -266.25)">
  <path fill="#33a348"
d="M16.2-14.738l-4.207-4.211a1.03,1.03,0,0,0-.73-.3H11v5.5h5.5v-.262A1.028,1.028,0,0,0,16.2-14.738ZM9.625-13.406V-19.25H1.031A1.029,1.029,0,0,0,0-18.219V1.719A1.029,1.029,0,0,0,1.031,2.75H15.469A1.029,1.029,0,0,0,16.5,1.719V-12.375H10.656A1.034,1.034,0,0,1,9.625-13.406ZM2.75-16.156a.344.344,0,0,1,.344-.344H6.531a.344.344,0,0,1,.344.344v.688a.344.344,0,0,1-.344.344H3.094a.344.344,0,0,1-.344-.344Zm0,3.438v-.687a.344.344,0,0,1,.344-.344H6.531a.344.344,0,0,1,.344.344v.688a.344.344,0,0,1-.344.344H3.094A.344.344,0,0,1,2.75-12.719ZM8.938-1.38V-.344A.344.344,0,0,1,8.594,0H7.906a.344.344,0,0,1-.344-.344V-1.387a2.46,2.46,0,0,1-1.348-.488A.344.344,0,0,1,6.19-2.4l.5-.482A.353.353,0,0,1,7.13-2.91a1.034,1.034,0,0,0,.551.16H8.889A.54.54,0,0,0,9.4-3.317a.561.561,0,0,0-.377-.547l-1.934-.58A1.945,1.945,0,0,1,5.729-6.308,1.913,1.913,0,0,1,7.562-8.245V-9.281a.344.344,0,0,1,.344-.344h.688a.344.344,0,0,1,.344.344v1.044a2.457,2.457,0,0,1,1.348.488.344.344,0,0,1,.024.522l-.5.482a.353.353,0,0,1-.435.031,1.031,1.031,0,0,0-.551-.16H7.611a.54.54,0,0,0-.507.567.561.561,0,0,0,.377.547l1.934.58a1.945,1.945,0,0,1,1.357,1.864A1.913,1.913,0,0,1,8.938-1.38Z"
transform="translate(644 286)"/>
  <path fill="#306ab4" d="M6061,56.523v-5.5h.262a1.037,1.037,0,0,1,.731.3l4.207,4.212a1.028,1.028,0,0,1,.3.727v.262Z"
transform="translate(-5406 215.727)"/>
  </g>
</svg>*/