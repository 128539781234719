import React from 'react';
import { Options } from '../index';

const OptionType = ({ menu }) => {
  const { className = '', options = null, enable = false } = menu;
  return (
    <>
      <div className={className ? className : 'options-dropdown'}>
        <Options enable={enable} options={options} />
      </div>
    </>
  );
};

export default OptionType;
