

import React from 'react'

export default ({
  className = ''
}) => (

  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="18.997" height="19" viewBox="0 0 18.997 19">
  <g id="Group_806" data-name="Group 806" transform="translate(0 0)">
    <g id="Icon_ionic-ios-chatboxes" data-name="Icon ionic-ios-chatboxes" transform="translate(-3.375 -3.375)">
      <path id="Path_139" data-name="Path 139" d="M23.548,11.981H13.426a1.743,1.743,0,0,0-1.74,1.74v7.048a1.739,1.739,0,0,0,1.74,1.736h5.792a.5.5,0,0,1,.347.146l2.878,2.654c.16.155.425.091.425-.132V22.861c0-.274.174-.361.448-.361h.046a1.9,1.9,0,0,0,1.923-1.736V13.722A1.733,1.733,0,0,0,23.548,11.981Z" transform="translate(-2.912 -3.015)"/>
      <path id="Path_140" data-name="Path 140" d="M9.149,7.847h8.1V4.864a1.489,1.489,0,0,0-1.489-1.489H4.864A1.489,1.489,0,0,0,3.375,4.864V12.41A1.489,1.489,0,0,0,4.864,13.9H7.655V9.336A1.5,1.5,0,0,1,9.149,7.847Z" transform="translate(0 0)"/>
    </g>
  </g>
  </svg>
  )
