import React, { useEffect } from 'react';
import { PurchaseOrderForm } from '../../Components/Form';
import { FormLayout } from '../../Components';
import { NewPurchaseOrderIcon } from '../../Assets/Navigation/NavigationIcons';
import { getObjFromLS } from '../../Helpers';
import { useLocation } from 'react-router-dom';

const AddPurchaseOrder = ({ handleToast }) => {
  const location = useLocation();
  useEffect(() => {
    document.title = 'Add Purcahse Orders';
  }, []);

  const handleRedirection = () => {
    const { search = '' } = location;
    let dataFromLS = getObjFromLS('module');
    if (dataFromLS.moduleName === 'contacts' && search) return '/contacts';
    else return '/purchaseorders';
  };

  return (
    <FormLayout
      title="Create Purchase Order"
      Icon={NewPurchaseOrderIcon}
      // closeUrl="/purchaseorders"
      closeUrl={handleRedirection()}
      iconstyle="general_module_icon"
      layoutBoxClass="uiux-form-redesign"
    >
      <PurchaseOrderForm type="add" handleToast={handleToast} />
    </FormLayout>
  );
};

export default AddPurchaseOrder;
