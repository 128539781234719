import React from 'react'

export default () => (
  <svg width="268" height="200" viewBox="0 0 268 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M194.471 2.52247L7.6277 60.5799C3.41504 61.8889 1.06347 66.3726 2.37534 70.5945L36.0621 179.007C37.3739 183.229 41.8524 185.59 46.0651 184.281L232.908 126.223C237.121 124.914 239.472 120.431 238.16 116.209L204.474 7.79674C203.162 3.57485 198.683 1.21347 194.471 2.52247Z" fill="url(#paint0_linear)" />
    <path d="M193.458 4.08836L10.7808 61.0023C6.56917 62.3144 4.21584 66.8015 5.52452 71.0243L37.7726 175.083C39.0813 179.306 43.5564 181.665 47.7681 180.353L230.445 123.439C234.657 122.127 237.01 117.64 235.701 113.417L203.453 9.35867C202.144 5.13579 197.669 2.7762 193.458 4.08836Z" fill="white" />
    <path d="M205.958 17.4408L8.02917 79.1065L13.3335 96.2227L211.262 34.557L205.958 17.4408Z" fill="#0071BC" />
    <path d="M99.2373 139.969L48.9477 155.637L51.2415 163.038L101.531 147.37L99.2373 139.969Z" fill="#BDBDBD" />
    <path d="M132.276 115.979L45.0768 143.147L47.3706 150.548L134.57 123.381L132.276 115.979Z" fill="#E0E0E0" />
    <path d="M259.924 70.0962H63.1216C58.7112 70.0962 55.136 73.6809 55.136 78.103V191.782C55.136 196.204 58.7112 199.789 63.1216 199.789H259.924C264.334 199.789 267.909 196.204 267.909 191.782V78.103C267.909 73.6809 264.334 70.0962 259.924 70.0962Z" fill="url(#paint1_linear)" />
    <path d="M256.83 71.4185H65.5372C61.1269 71.4185 57.5516 75.0033 57.5516 79.4254V188.391C57.5516 192.814 61.1269 196.398 65.5372 196.398H256.83C261.24 196.398 264.815 192.814 264.815 188.391V79.4254C264.815 75.0033 261.24 71.4185 256.83 71.4185Z" fill="white" />
    <path d="M112.879 132.091H106.84V149.287H112.879V132.091Z" fill="#E0E0E0" />
    <path d="M120.61 132.091H114.57V149.287H120.61V132.091Z" fill="#E0E0E0" />
    <path d="M128.34 132.091H122.301V149.287H128.34V132.091Z" fill="#E0E0E0" />
    <path d="M141.868 132.091H135.828V149.287H141.868V132.091Z" fill="#E0E0E0" />
    <path d="M149.597 132.091H143.558V149.287H149.597V132.091Z" fill="#E0E0E0" />
    <path d="M157.328 132.091H151.289V149.287H157.328V132.091Z" fill="#E0E0E0" />
    <path d="M170.855 132.091H164.816V149.287H170.855V132.091Z" fill="#E0E0E0" />
    <path d="M178.586 132.091H172.546V149.287H178.586V132.091Z" fill="#E0E0E0" />
    <path d="M186.315 132.091H180.276V149.287H186.315V132.091Z" fill="#E0E0E0" />
    <path d="M199.843 132.091H193.804V149.287H199.843V132.091Z" fill="#E0E0E0" />
    <path d="M207.573 132.091H201.534V149.287H207.573V132.091Z" fill="#E0E0E0" />
    <path d="M215.304 132.091H209.264V149.287H215.304V132.091Z" fill="#E0E0E0" />
    <path d="M106.746 104.162H70.7382V123.96H106.746V104.162Z" fill="#0071BC" />
    <path opacity="0.1" d="M232.773 169.09H210.704V191.218H232.773V169.09Z" fill="#0071BC" />
    <path opacity="0.1" d="M243.807 169.09H221.738V191.218H243.807V169.09Z" fill="#0071BC" />
    <defs>
      <linearGradient id="paint0_linear" x1="120.723" y1="185.029" x2="121.643" y2="1.78312" gradientUnits="userSpaceOnUse">
        <stop stopColor="#808080" stopOpacity="0.25" />
        <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
        <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
      </linearGradient>
      <linearGradient id="paint1_linear" x1="116936" y1="88167.5" x2="116936" y2="30397" gradientUnits="userSpaceOnUse">
        <stop stopColor="#808080" stopOpacity="0.25" />
        <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
        <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
      </linearGradient>
    </defs>
  </svg>

)