let calculateTaxAmount = (quantity, unitPrice, taxRate) => {
  return parseFloat(((quantity * unitPrice * taxRate) / 100).toFixed(2));
};

export default function getOverallTaxDetails(items) {
  let overallTax = [];
  for (let item of items) {
    let taxInfo = item.tax || item.get_tax || item.taxInfo;
    let taxAmount =
      item.taxAmount || item.tax_amount || item.invoice_tax || item.salesTax;
    if (!!taxInfo) {
      if (taxInfo.id !== null) {
        if (typeof taxAmount === 'undefined') {
          // SIQ-1211
          taxAmount =
            calculateTaxAmount(
              item.quantity,
              item.rate ? item.rate : item.unitPrice,
              taxInfo.rate
            ) || 0;
        }
        let previousTax = false;
        for (let index in overallTax) {
          if (overallTax[index].id === taxInfo.id) {
            previousTax = true;
            overallTax[index] = {
              ...overallTax[index],
              total: parseFloat(
                (overallTax[index].total + taxAmount).toFixed(2)
              ),
            };
          }
        }
        if (!previousTax) {
          overallTax.push({
            id: taxInfo.id,
            rate: taxInfo.rate || taxInfo.rate,
            name: taxInfo.name || taxInfo.name,
            total: taxAmount || 0,
          });
        }
      }
    }
  }
  return overallTax;
}
