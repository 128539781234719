import React, { Fragment, useState } from 'react';
import CustomModal from '../../../../../Components/CustomModal/CustomModal';

import getDate from '../../../../../Helpers/Date/GetDate';
import PurchaseOrderDetails from '../../../PurchaseOrderDetails';
import { PurchaseOrderIcon } from '../../../../../Assets/Navigation/NavigationIcons';

export default ({ bill, currency, history, inModal }) => {
  const {
    order_no: purchaseOrderNo,
    bill_date: billDate,
    due_date: dueDate,
    total,
    balance_due: balanceDue,
    // vendor: { display_name, id: vendorId },
    bill_created_by: { name: createdBy },
    purchase_orders = [],
  } = bill;
  const [showPOModal, setShowPOModal] = useState(false);

  function viewPurchaseOrderModal() {
    return (
      <CustomModal
        showModal={showPOModal}
        title="View Purchase Order"
        className='popup-height'
        renderActions={false}
        size="large"
        Icon={PurchaseOrderIcon}
        linkTo={`purchaseorders/${purchase_orders[0]?.id}`}
        onClose={() => setShowPOModal(false)}
      >
        <PurchaseOrderDetails id={purchase_orders[0]?.id} inModal={true} />
      </CustomModal>
    );
  }

  return (
    <>
      {viewPurchaseOrderModal()}
      <div className="section-details--info position-relative mb_30">
        {purchaseOrderNo && (
          <Fragment>
            <div className="details_row">
              {' '}
              <div className="section-details--info-title semi-bold">
                Order No.
              </div>
              {inModal ? (
                <div>{purchaseOrderNo}</div>
              ) : (
                <div
                  className={
                    purchase_orders.length
                      ? 'text_underline pointer'
                      : 'text_underline'
                  }
                  onClick={() => purchase_orders.length && setShowPOModal(true)}
                >
                  {purchaseOrderNo}
                </div>
              )}
            </div>
          </Fragment>
        )}
        <Fragment>
          <div className="details_row">
            <div className="section-details--info-title semi-bold margin-top-sm">
              Bill Date
            </div>
            <div>{getDate(billDate)}</div>{' '}
          </div>
        </Fragment>
        <Fragment>
          <div className="details_row">
            <div className="section-details--info-title semi-bold margin-top-sm">
              Due Date
            </div>
            <div>{getDate(dueDate)}</div>{' '}
          </div>
        </Fragment>
        <Fragment>
          <div className="details_row">
            <div className="section-details--info-title semi-bold margin-top-sm">
              Created By
            </div>
            <div>{createdBy}</div>
          </div>
        </Fragment>
        <Fragment>
          <div className="details_row">
            <div className="section-details--info-title semi-bold margin-top-sm">
              Total
            </div>
            <div>
              {currency.symbol}
              {parseFloat(total).toFixed(2)}
            </div>{' '}
          </div>
        </Fragment>

        <Fragment>
          <div className="details_row">
            <div className="section-details--info-title semi-bold margin-top-sm">
              Balance Due
            </div>
            <div className="semi-bold-amount">
              {currency.symbol}
              {parseFloat(balanceDue).toFixed(2)}
            </div>
          </div>
        </Fragment>
      </div>
    </>
  );
};
