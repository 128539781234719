import { isEqual } from 'lodash';
export const validateCreateSalesOrderForm = (data = {}, defaultValues = {}) => {
  let checkout = true;
  if (data && defaultValues) {
    const { orderDate = null, expectedShipmentDate = null } = defaultValues;
    const actuaorderDate = orderDate && orderDate.format('YYYY-MM-DD 00:00:00');
    const actualexpectedShipmentDate =
      expectedShipmentDate &&
      expectedShipmentDate.format('YYYY-MM-DD 00:00:00');

    const checkoutEqualityOrderDate = Boolean(
      isEqual(data?.orderDate?.format('YYYY-MM-DD 00:00:00'), actuaorderDate)
    );
    const checkoutEqualityShipmentDate = Boolean(
      isEqual(
        data?.expectedShipmentDate?.format('YYYY-MM-DD 00:00:00'),
        actualexpectedShipmentDate
      )
    );

    const updatedData = { ...data };
    const actualData = { ...defaultValues };
    delete updatedData.orderDate;
    delete updatedData.expectedShipmentDate;

    delete actualData.orderDate;
    delete actualData.expectedShipmentDate;
    const checkEquality = Boolean(isEqual(updatedData, actualData));
    if (
      !checkEquality ||
      !checkoutEqualityOrderDate ||
      !checkoutEqualityShipmentDate
    ) {
      checkout = false;
    }
  }
  return { checkout };
};
