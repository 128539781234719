import React from 'react';

import { InventoryIcon } from '../../Assets/Settings/SettingsIcon';

import FormLayout from '../../Components/Layout/FormLayout';
import AccountForm from './AccountForm';

const CreateAccount = ({ handleToast, history }) => (
    <FormLayout
        title="Inventory Account"
        Icon={InventoryIcon}
        subtitle="Add New"
        closeUrl="/settings"
    >
        <AccountForm history={history} handleToast={handleToast} />
    </FormLayout>
);

export default CreateAccount;
