import { useState } from 'react';
import { confirmationAlert } from '../Helpers';
import { useHistory } from 'react-router-dom';

const useToggleClick = () => {
  const [toggle, settoggle] = useState(true);
  const [data, setData] = useState({});
  const history = useHistory();

  const handleCrossAlert = (values) => {
    setData(values);
  };

  const actionHandler = (path, validator) => {
    let { checkout } = validator(data);
    if (!checkout) {
      let ans = confirmationAlert();
      settoggle(ans);
      if (ans) history.push(path);
    } else history.push(path);
  };

  const handleCrossClick = (path, validator) => (_ev) => {
    actionHandler(path, validator);
  };

  return [toggle, handleCrossAlert, handleCrossClick, actionHandler];
};

export default useToggleClick;
