import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { restRequest } from '../../../../../../Helpers/RequestHelper';
import getDate from '../../../../../../Helpers/Date/GetDate';

import Loader from '../../../../../../Components/Loader/Loader';
// import HeaderWrapper from '../../../../../../Components/HeaderWrapper/HeaderWrapper'
// import { Grid, GridLayout } from '../../../../../../Components/Layout/Layout'
// import { DetailsHeader } from '../../../../../../Components/Details/Details'
import {
  Button,
  ActionMenu,
} from '../../../../../../Components/CheckedMenu/CheckedMenu';
// import { StateShortName } from '../../../../../../Helpers/StateNameConvertion'
import PDFViewer from '../../../../../../Components/PDFViewer/PDFViewer';
// import Box from '../../../../../../Components/Layout/Box'
import NotFound from '../../../../../../Components/NotFound/NotFound';

// import { BillIcon } from '../../../../../../Assets/Navigation/NavigationIcons'
import Table from '../../../../../../Components/Table/Table';
import {
  CellIcon,
  UserIcon,
  LocationIcon,
  PhoneIcon,
  CompanyOffice,
} from '../../../../../../Assets/Contact/OverviewContact'; // ContactCard, FaxIcon, CurrencyIcon,

// import { getAddress } from '../../../../../Organization/ChooseOrganization'
import { checkError } from '../../../../../../Helpers/AuthHelper';

class BillPaymentDetails extends Component {
  id = this.props.id;
  state = {
    billInfo: {},
    paymentInfo: {},
    activityLog: [],
    currency: {},
    detailsNo: '',
    pdf: null,
    showPDF: false,
    loading: true,
    notFound: false,
  };

  componentDidMount() {
    document.title = 'Bill Payment Details';
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ loading: true });
    return restRequest('get', `billpaymentrecords/${this.id}`)
      .then((res) => {
        this.setState({
          billInfo: res.bill,
          paymentInfo: {
            ...this.state.paymentInfo,
            id: res.id,
            paymentDate: getDate(res.payment_date),
            paymentMade: res.payment_made,
            paymentMode: res.payment_mode,
            reference: res.reference,
            notes: res.note,
          },
          currency: res.currency,
          detailsNo: `${res.bill.bill_no} / Payment No.${res.id}`,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error);
        this.setState({
          loading: false,
          notFound: true,
        });
      });
  };

  openPdf(id) {
    this.setState({ showPDF: true });
    restRequest('get', `billpaymentrecords/${id}/pdf`)
      .then((res) => {
        this.setState({ pdf: res });
      })
      .catch((error) => checkError(error));
  }

  closeAllModals = () => {
    this.setState({
      showPDF: false,
      pdf: null,
    });
  };

  renderActionMenu() {
    const id = this.id;
    return (
      <ActionMenu>
        <Button handleClick={() => this.openDeleteModal(id)} type="delete" />
        <Button handleClick={() => this.openPdf(id)} type="pdf" />
      </ActionMenu>
    );
  }

  renderPaymentInfo = () => {
    let { paymentInfo, billInfo, currency } = this.state;

    console.log(billInfo);
    let billingAddress = {};
    if (billInfo.billing_address) {
      let address = JSON.parse(billInfo.billing_address);
      billingAddress = {
        id: address.id,
        type: address.address_type,
        attention: address.attention,
        street: address.street,
        city: address.city,
        //state: StateShortName(address.state),
        state: address.state,
        zip: address.zip_code,
        countryName: address.country_name,
        phone: address.phone,
        fax: address.fax,
      };
    }
    return (
      <Fragment>
        <div className="float-left w-100 payment_modal mb_3 payment_info_bills">
          <div style={{ width: '50%' }}>
            <div style={{ width: '90%' }} className="heading_title">
              Payment Details
              {/* <hr /> */}
            </div>
            {/* // <GridLayout className="layout-padding" grid="3-1"> */}
            {/* <div className="section-details--info">
          <div className="section-details--info-title semi-bold">Amount Paid</div>
          <div>{currency.symbol}{paymentInfo.paymentMade}</div>
          <div className="section-details--info-title semi-bold">Paid To</div>
          <Link to={`/contacts/${billInfo.vendor_id}`}>
            <div>{billInfo.vendor.display_name}</div>
          </Link>
        </div>
        <div className="section-details--info">
          <Fragment>
            <div className="section-details--info-title semi-bold">Payment Date</div>
            <div>{paymentInfo.paymentDate}</div>
          </Fragment>
          <Fragment>
            <div className="section-details--info-title semi-bold margin-top-sm">Payment Mode</div>
            <div>{paymentInfo.paymentMode}</div>
          </Fragment>
          <Fragment>
            <div className="section-details--info-title semi-bold margin-top-sm">Reference</div>
            <div>{paymentInfo.reference}</div>
          </Fragment>
        </div> */}
            <div className="section-details--info">
              {paymentInfo.id && (
                <div className="details_fields">
                  <Fragment>
                    <div className="section-details--info-title semi-bold payment_details_heading_title">
                      Payment No
                    </div>
                    <div className="payment_details_fields_value">
                      {paymentInfo.id}
                    </div>
                  </Fragment>
                </div>
              )}
            </div>

            <div className="section-details--info">
              {paymentInfo.paymentDate && (
                <div className="details_fields">
                  <Fragment>
                    <div className="section-details--info-title semi-bold payment_details_heading_title">
                      Date
                    </div>
                    <div className="payment_details_fields_value">
                      {paymentInfo.paymentDate}
                    </div>
                  </Fragment>
                </div>
              )}
              {paymentInfo.paymentMade && (
                <div className="details_fields">
                  <Fragment>
                    <div className="section-details--info-title semi-bold payment_details_heading_title">
                      Amount Paid
                    </div>
                    <div className="payment_details_fields_value">
                      {currency.symbol}
                      {/*paymentInfo.paymentMade*/}
                      {parseFloat(paymentInfo.paymentMade).toFixed(2)}
                    </div>
                  </Fragment>
                </div>
              )}
              {paymentInfo.paymentMode && (
                <div className="details_fields">
                  <Fragment>
                    <div className="section-details--info-title semi-bold payment_details_heading_title">
                      Payment Mode
                    </div>
                    <div className="payment_details_fields_value">
                      {paymentInfo.paymentMode}
                    </div>
                  </Fragment>
                </div>
              )}
              {paymentInfo.reference && (
                <div className="details_fields">
                  <Fragment>
                    <div className="section-details--info-title semi-bold payment_details_heading_title">
                      Reference
                    </div>
                    <div className="payment_details_fields_value">
                      {paymentInfo.reference}
                    </div>
                  </Fragment>
                </div>
              )}
            </div>
          </div>

          <div style={{ width: '50%' }}>
            <div style={{ width: '90%' }} className="heading_title">
              Paid To
              {/* <hr /> */}
            </div>

            <div className="section-details--info">
              {billInfo.vendor.display_name && (
                <div
                  className="bill_vendor_details"
                  style={{ wordWrap: 'anywhere' }}
                >
                  <Fragment>
                    {this.props.inModal ? (
                      <div className="float-left w-100 paid_data">
                        <UserIcon customWidth="17" customHeight="17" />
                        <div
                          className="float-left"
                          style={{
                            float: 'left',
                            width: '80%',
                            wordBreak: 'break-word',
                          }}
                        >
                          {billInfo.vendor.display_name}
                        </div>
                      </div>
                    ) : (
                      <div className="float-left w-100 paid_data">
                        <UserIcon customWidth="17" customHeight="17" />{' '}
                        <Link to={`/contacts/${billInfo.vendor_id}`}>
                          <div
                            className="float-left"
                            style={{
                              float: 'left',
                              width: '80%',
                              wordBreak: 'break-word',
                            }}
                          >
                            {billInfo.vendor.display_name}
                          </div>
                        </Link>
                      </div>
                    )}
                    {billInfo.vendor.company_name !== '' ? (
                      <div className="float-left w-100 paid_data submit">
                        <CompanyOffice customWidth="17" customHeight="17" />
                        <div
                          className="float-left"
                          style={{
                            float: 'left',
                            wordBreak: 'break-word',
                          }}
                        >
                          {billInfo.vendor.company_name}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {/* <div className="section-details--info-title semi-bold payment_details_heading_title">
                      <UserIcon customWidth="17" customHeight="17" />
                    </div>
                    <div className="payment_details_fields_value">
                    <Link to={`/contacts/${billInfo.vendor_id}`}>
            <div>{billInfo.vendor.display_name}</div>
                </Link>
                    </div> */}
                  </Fragment>
                </div>
              )}
            </div>

            <div className="section-details--info">
              {billingAddress && (
                <div
                  className="bill_vendor_details"
                  style={{ wordWrap: 'anywhere' }}
                >
                  <Fragment>
                    <div className="float-left w-100">
                      <div
                        className="float-left paid_data w-100"
                        style={{ display: 'flex' }}
                      >
                        {(billingAddress.street ||
                          billingAddress.city ||
                          billingAddress.state ||
                          billingAddress.zip) && (
                            <Fragment>
                              <div className="icon">
                                <LocationIcon
                                  customWidth="17"
                                  customHeight="17"
                                />
                              </div>
                              <div className="float-left">
                                <div>
                                  {!!billingAddress.attention &&
                                    `${billingAddress.attention},`}
                                </div>
                                <div>
                                  {!!billingAddress.street &&
                                    `${billingAddress.street},`}
                                </div>
                                <div>
                                  {!!billingAddress.city &&
                                    `${billingAddress.city},`}{' '}
                                  {!!billingAddress.state &&
                                    `${billingAddress.state} `}{' '}
                                  {!!billingAddress.zip &&
                                    `${billingAddress.zip},`}{' '}
                                  {billingAddress.countryName}
                                </div>
                              </div>
                            </Fragment>
                          )}
                      </div>
                    </div>
                  </Fragment>
                </div>
              )}
              {billInfo.vendor.mobile && (
                <div className="float-left" style={{ marginRight: '10px' }}>
                  <Fragment>
                    <div className="float-left w-100">
                      <div className="float-left paid_data">
                        <PhoneIcon customWidth="17" customHeight="17" />
                        <div className="detail">{billInfo.vendor.mobile}</div>
                      </div>
                    </div>
                    {/* <div className="section-details--info-title semi-bold payment_details_heading_title">
                    
                    </div>
                    <div className="payment_details_fields_value">
                      {billInfo.vendor.mobile}
                    </div> */}
                  </Fragment>
                </div>
              )}
              {billInfo.vendor.work_phone && (
                <div className="float-left">
                  <Fragment>
                    <div className="float-left w-100">
                      <div className="float-left paid_data">
                        <CellIcon customWidth="17" customHeight="17" />
                        <div className="detail">
                          {billInfo.vendor.work_phone}
                        </div>
                      </div>
                    </div>
                    {/* <div className="section-details--info-title semi-bold payment_details_heading_title">
                    
                    </div>
                    <div className="payment_details_fields_value">
                      {billInfo.vendor.work_phone}
                    </div> */}
                  </Fragment>
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
      // {/* </GridLayout> */}
    );
  };

  renderLayoutView() {
    const { billInfo, paymentInfo, currency, showPDF, pdf } = this.state;
    const { className } = this.props;

    return (
      <Fragment>
        <PDFViewer
          showPDF={showPDF}
          hidePDFModal={this.closeAllModals}
          pdf={pdf}
        />

        {/* <DetailsHeader
          title="Payment"
          no={`Payment No. ${paymentInfo.id}`}
        /> */}

        {this.renderPaymentInfo()}

        <Table
          list={[
            {
              billNo: this.props.inModal ? (
                `${billInfo.bill_no}`
              ) : (
                <Link to={`/bills/${billInfo.id}`}>{billInfo.bill_no}</Link>
              ),
              billDate: getDate(billInfo.bill_date),
              //billAmount: `${currency.symbol}${billInfo.total}`,
              //paidAmount: `${currency.symbol}${paymentInfo.paymentMade}`,
              billAmount: `${currency.symbol}${parseFloat(
                billInfo.total
              ).toFixed(2)}`,
              paidAmount: `${currency.symbol}${parseFloat(
                paymentInfo.paymentMade
              ).toFixed(2)}`,
            },
          ]}
          tableHeaders={['Bill No.', 'Date', 'Bill Amount', 'Payment Made']}
          tableData={['billNo', 'billDate', 'billAmount', 'paidAmount']}
          rightAlignColumns={[3, 4]}
          className={`details-table table_view_invoice table_bottom ${className}`}
        />

        {/* <GridLayout className="layout-padding" grid="3-2"> */}
        {paymentInfo.notes && (
          <div className="notes_field bills_noteS_popup">
            <div className="semi-bold text-small margin-bottom-sm">Notes</div>
            <div className="payment-desc-set">
              <p>{paymentInfo.notes}</p>
            </div>
          </div>
        )}
        {/* </GridLayout> */}
      </Fragment>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    return (
      // <GridLayout>
      // <Grid transparent>
      // <Box className="no-padding">
      <Fragment>{this.renderLayoutView()}</Fragment>
    );
  }
}

export default BillPaymentDetails;

// export default HeaderWrapper(
//   BillPaymentDetails,
//   {
//     name: 'Bills',
//     subName: 'Payment',
//     Icon: BillIcon,
//     baseUrl: 'paymentrecords',
//     redirectUrl: '/bills',
//     onlyMenu: true,
//     permissionName:'bill'

//   }
// )
