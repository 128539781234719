import React from 'react';
import Autosuggest from 'react-autosuggest';
import { FaTrash } from '../../Common/Icons';
import './AutoSuggest.css';

const renderInputComponent = (inputProps) => {
  delete inputProps.className;
  return <input className="form-input" {...inputProps} />;
};

const getSuggestionValue = (suggestion) => suggestion.name;

const renderSuggestion = (suggestion, removeSuggestion) => (
  <div className="auto-complete--suggestion">
    {suggestion.name}
    {removeSuggestion && (
      <button
        id="remove"
        className="button"
        onClick={() => {
          removeSuggestion(suggestion.name);
        }}
      >
        <FaTrash />
      </button>
    )}
  </div>
);

const onSuggestionSelected = (event, { method }) => {
  if (method === 'enter') {
    event.preventDefault();
  }
};

const shouldRenderSuggestions = () => true;

export default ({
  suggestions,
  onSuggestionsFetchRequested,
  onSuggestionsClearRequested,
  inputProps,
  removeSuggestion,
}) => (
  <Autosuggest
    suggestions={suggestions}
    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
    onSuggestionsClearRequested={onSuggestionsClearRequested}
    getSuggestionValue={getSuggestionValue}
    renderSuggestion={(suggestion) =>
      renderSuggestion(suggestion, removeSuggestion)
    }
    inputProps={inputProps}
    renderInputComponent={renderInputComponent}
    shouldRenderSuggestions={shouldRenderSuggestions}
    onSuggestionSelected={onSuggestionSelected}
    focusInputOnSuggestionClick={false}
  />
);
