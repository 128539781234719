import React, { Component } from 'react';
import { FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import { MdClose } from '../../../../Common/Icons';
import {
  moreThanZero,
  required,
  ValidateMax10Million,
  ValidateMax25,
} from '../../../../Helpers/Validator';
import { restRequest } from '../../../../Helpers/RequestHelper';

import { getErrorByKey } from '../../../../Components/Form/FormHelper';
import Field from '../../../../Components/Form/Field/Field';
import Form from '../../../../Components/Form/Form';

import './InvoiceTermUpdate.css';
import { checkError } from '../../../../Helpers/AuthHelper';
import { confirmationAlert } from '../../../../Helpers';

class InvoiceTermUpdate extends Component {
  handleSubmit = (values, setSubmitting) => {
    const { handleToast = () => null } = this.props;
    restRequest('put', 'invoiceterms', {
      invoice_terms: values.termsList,
    })
      .then((res) => {
        handleToast(res.message, 'success');
        this.props.updateTermsList();
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, 'error')
        setSubmitting(false);
      });
  };

  handleCancel = () => {
    const { close, showPrompt } = this.props;
    let response = true;
    if (showPrompt) {
      response = confirmationAlert();
    }
    if (!showPrompt || response) {
      close();
    }
  };

  checkAlert = (data) => {
    const { handlePrompt } = this.props;
    if (data) handlePrompt();
    else handlePrompt(false);
  };

  render() {
    let termsList = this.props.invoiceTermsList
      .filter((term) => term.deletable === 1)
      .map((term) => ({
        name: term.name,
        value: parseInt(term.value),
      }));
    return (
      <Formik
        initialValues={{ termsList }}
        validationSchema={() =>
          Yup.object().shape({
            termsList: Yup.array().of(
              Yup.object().shape({
                name: required(ValidateMax25),
                value: required(moreThanZero(ValidateMax10Million)),
              })
            ),
          })
        }
        onSubmit={(values, { setSubmitting }) => {
          this.handleSubmit(values, setSubmitting);
        }}
        render={({
          values,
          errors,
          isSubmitting,
          setFieldValue,
          touched,
          handleBlur,
          handleSubmit,
          handleChange,
        }) => {
          return (
            <Form onSubmit={handleSubmit} className="invoice-terms--form">
              <FieldArray
                name="termsList"
                render={() => (
                  <table className="form-table--invoice-terms">
                    <thead>
                      <tr>
                        <th>Label</th>
                        <th>Number of days</th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.termsList.map((terms, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Field
                                value={terms.name}
                                id={`termsList[${index}].name`}
                                handleChange={(labelValue) => {
                                  let label = labelValue.target.value;
                                  if (label.length > 0) {
                                    let labelString = label;
                                    if (labelString.length > 25) {
                                      labelString = labelString.slice(0, 25);
                                    }
                                    label = labelString;
                                  }
                                  setFieldValue(
                                    `termsList[${index}].name`,
                                    label
                                  );
                                  this.checkAlert(label);
                                }}
                                onBlur={handleBlur}
                                error={getErrorByKey(
                                  errors,
                                  touched,
                                  `termsList[${index}].name`
                                )}
                              />
                            </td>

                            <td>
                              <Field
                                type="number"
                                value={terms.value}
                                decimalScale={0}
                                id={`termsList[${index}].value`}
                                allowNegative={false}
                                thousandSeparator=","
                                onValueChange={(value) => {
                                  // let flagVal=0
                                  if (value.value === '0') {
                                    handleBlur();
                                    //  flagVal=1
                                  } else if (value.value !== '0') {
                                    let adjustment = value.floatValue;
                                    if (adjustment > 0) {
                                      let adjustmentString =
                                        adjustment.toString();
                                      if (adjustmentString.length > 5) {
                                        adjustmentString =
                                          adjustmentString.slice(0, 5);
                                      }
                                      adjustment = parseFloat(adjustmentString);
                                    }
                                    setFieldValue(
                                      `termsList[${index}].value`,
                                      adjustment
                                    );
                                  }
                                  // Price_Quantity_Limit

                                  // price and quantity limit from 5 digits to 10 digits
                                  else if (value.value !== '0') {
                                    let adjustment = value.floatValue;
                                    if (adjustment > 0) {
                                      let adjustmentString =
                                        adjustment.toString();
                                      if (adjustmentString.length > 10) {
                                        adjustmentString =
                                          adjustmentString.slice(0, 10);
                                      }
                                      adjustment = parseFloat(adjustmentString);
                                    }
                                    setFieldValue(
                                      `termsList[${index}].value`,
                                      adjustment
                                    );
                                    this.checkAlert(adjustment);
                                  }
                                }}
                                error={getErrorByKey(
                                  errors,
                                  touched,
                                  `termsList[${index}].value`
                                )}
                                onBlur={handleBlur}
                              />
                            </td>

                            <td className="table--row-delete">
                              <button
                                type="button"
                                className="close-btn button"
                                onClick={() => {
                                  setFieldValue(
                                    'termsList',
                                    values.termsList.filter(
                                      (paymentTerm) =>
                                        values.termsList.indexOf(
                                          paymentTerm
                                        ) !== index
                                    )
                                  );
                                  this.checkAlert(true);
                                }}
                              >
                                <MdClose />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              />

              <button
                className="button invoice-terms--add-button"
                type="button"
                onClick={() => {
                  setFieldValue('termsList', [
                    ...values.termsList,
                    {
                      name: '',
                      value: null,
                    },
                  ]);
                  this.checkAlert(true);
                }}
              >
                <strong>+</strong> &nbsp; Add New
              </button>

              <div className="invoice-terms--action-buttons invoice_term_btn">
                <button
                  type="submit"
                  id="click_Item_Save_Button"
                  className="action-buttons--submit next-btn float-left mr_3"
                  disabled={isSubmitting}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="action-buttons--cancel cancel-btn float-left mr_3"
                  onClick={this.handleCancel}
                >
                  Cancel
                </button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

export default InvoiceTermUpdate;
