import React, { useEffect } from 'react';

import { InvoiceForm } from '../../../Components/Form';
import { FormLayout } from '../../../Components';
import { NewInvoiceIcon } from '../../../Assets/Navigation/NavigationIcons';
import { getObjFromLS } from '../../../Helpers';
import { useLocation } from 'react-router-dom';

const AddInvoice = ({ handleToast }) => {
  const location = useLocation();
  useEffect(() => {
    document.title = 'Add Invoice';
  }, []);

  const handleRedirection = () => {
    const { search = '' } = location;
    let dataFromLS = getObjFromLS('module');
    if (dataFromLS.moduleName === 'contacts' && search) return '/contacts';
    else return '/invoices';
  };

  return (
    <FormLayout
      title="Create Invoice"
      Icon={NewInvoiceIcon}
      // closeUrl="/invoices"
      closeUrl={handleRedirection()}
      iconstyle="general_module_icon"
      layoutBoxClass="uiux-form-redesign"
      // className="create-invoice-main"
    >
      <InvoiceForm
        title="New Invoice"
        type="add"
        handleToast={handleToast}
        className="create-invoice-main"
      />
    </FormLayout>
  );
};

export default AddInvoice;
