import React, { Fragment, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withFormik, FieldArray } from 'formik';
import DeleteIcon from '../../../Assets/General/DeleteIcon';
import { MdAdd, MdRemove } from '../../../Common/Icons';
import {
  restRequest,
  findByValue,
  checkError,
  getObjFromLS,
  setObjInLS,
  formatAccountList,
} from '../../../Helpers';
import { Field, Form, FormSection, FormAction, AddItem, ErrorFocus } from '../';
import { formSubmit, getError, validate } from '../FormHelper';
import { ADJUSTMENT_OPTIONS } from '../../../Constants';
import ListTableImageColumn from '../../ListTable/ListTableImageColumn';
import Quantity from '../../Fragments/Quantity/Quantity';
import {
  required,
  ValidateDate,
  ValidateNumber,
  ValidateRequired,
  ValidateMax200,
} from '../../../Helpers/Validator';
import { validateItemAdjustment } from '../../../Helpers/FormValidator';
import { Loader } from '../..';
import ContactIconListing from '../../../Assets/General/ContactIconListing';
import { emptyItem } from '../../../Api/Invoice/Utils';

let allItems = [];

const getYupValidationSchema = () => {
  return Yup.object().shape({
    adjustmentType: ValidateRequired,
    date: required(ValidateDate),
    purchaseAccountID: required(ValidateNumber),
    reason: required(ValidateMax200),
    warehouseID: required(ValidateNumber),
    items: Yup.array().of(
      Yup.object().shape({
        name: ValidateRequired,
        //adjustedQuantity: required(moreThanZero(ValidateMax10Billion)),
      })
    ),
    description: Yup.string().max(
      250,
      'please enter description less than 250 characters'
    ),
  });
};

const formEnhancer = withFormik({
  validate: validate(getYupValidationSchema),
  mapPropsToValues: ({
    warehouseFromDB,
    adjustments,
    itemId,
    primaryWarehouse,
  }) => ({
    ...adjustments,
    // warehouseID: itemId && primaryWarehouse.id,
    // warehouseID:
    //   warehouseFromDB &&
    //   warehouseFromDB.length === 1 &&
    //   warehouseFromDB[0].id,
  }),
  mapPropsToStatus: (props) => ({
    wareHouseLoading: false,
    warehouseItemsFromDB: [],
    emptyItem: props.emptyItem,
  }),
  handleSubmit: (values, bag) => {
    handleFormSubmit(values, bag);
  },
});

const handleFormSubmit = (
  values,
  { setSubmitting, props: { history, handleToast, type, setPrompt } }
) => {
  var checkedItems = values.items.filter((item) => item.adjustedQuantity === 0);
  if (checkedItems && checkedItems.length === 0) {
    restRequest('post', 'itemadjustments', {
      type: values.adjustmentType,
      purchase_account_id: values.purchaseAccountID,
      date: values.date ? values.date.format('YYYY-MM-DD 00:00:00') : '',
      reason: values.reason,
      description: values.description,
      warehouse_id: values.warehouseID,
      items: values.items.map((item) => ({
        id: item.id,
        adjusted_quantity: item.adjustedQuantity,
      })),
    })
      .then(() => {
        setPrompt(false);
        let dataFromLS = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLS,
          moduleName: 'item-adjustments',
          urlPath: 'item-adjustments',
          action: type === 'add' ? 'add' : 'justedit',
          queryParam: '',
        });
        handleToast('Items adjusted successfully', 'success');
        if (dataFromLS && dataFromLS.urlPath !== window.location.pathname) {
          return history.push('/item-adjustments');
        }
        return history.push('/r');
      })
      .catch((error) => {
        checkError(error, handleToast);
        setSubmitting(false);
      });
  } else {
    setSubmitting(false);
  }
};

const AdjustmentForm = ({
  // props from formik
  values,
  status,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
  setStatus,
  setValues,
  isSubmitting,
  errors,
  touched,
  setFieldTouched,
  formikBag,

  // custom props
  title,
  purchaseAccounts,
  warehouseFromDB,
  primaryWarehouse,
  itemId,
  stockPreference,
  history,
  ...props
}) => {
  useEffect(() => {
    handleAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  useEffect(() => {
    if (itemId && values.warehouseID) {
      fetchItemsForSelectedWarehouse(values.warehouseID);
      // setItemIds(itemId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId && values.warehouseID]);

  useEffect(() => {
    if (warehouseFromDB && warehouseFromDB.length === 1) {
      const option = {
        id: warehouseFromDB[0].id,
        is_primary: warehouseFromDB[0].is_primary,
        label: warehouseFromDB[0].name + '(PRI)',
        name: warehouseFromDB[0].name,
        value: warehouseFromDB[0].id,
      };
      handleWarehouseChange(option);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseFromDB]);

  const handleAlert = () => {
    let { checkout } = validateItemAdjustment(values);
    if (!checkout) props.setPrompt();
    else props.setPrompt(false);
  };

  const handleAdjustmentTypeChange = (option) => {
    if (option !== null) {
      setFieldValue('adjustmentType', option.value);
    } else {
      setFieldValue('adjustmentType', '');
    }
  };

  const [warehouseId, setWarehouseId] = useState(
    values.warehouseID ? values.warehouseID : ''
  );
  const [itemIds, setItemIds] = useState([]);
  const [showAddItemButton, setshowAddItemButton] = useState(false);
  const [renderLoadOption, setRenderLoadOption] = useState(false);

  const fetchItemsForSelectedWarehouse = async (watehouse) => {
    setStatus({
      ...status,
      wareHouseLoading: true,
    });
    await fetchWarehouseItems(watehouse).then((list) => {
      setStatus({
        ...status,
        // warehouseItemsFromDB: list,
        wareHouseLoading: false,
      });
      // let items = list.find((l) => l.id === itemIds);

      handleSelectedItem(list, 0);
    });
  };

  const fetchWarehouseItems = async (warehouseID) => {
    return restRequest(
      'get',
      `itemadjustments/create/warehouseitemlist/${warehouseID}${
        itemIds.length
          ? `?item_ids=${itemIds}`
          : itemId
          ? `?item_ids=${itemId}`
          : null
      }`
    ).then((warehouseItems) => {
      // let warehouses = stocks.map((ware) => {
      //   return ware.warehouse;
      // });
      let found_warehouse = itemIds.find((l) => l.id === warehouseItems.id);

      // if (found_warehouse) {
      //   return warehouseItems;
      // } else {
      //   return;
      // }
      return warehouseItems;
    });
  };

  const getItemQuantity = (item) => {
    if (stockPreference?.preferences?.physical) {
      return item?.physical_quantity;
    } else if (stockPreference?.preferences?.accounting) {
      return item?.accounting_quantity;
    } else return 0;
  };

  const handleWarehouseChange = async (option) => {
    setRenderLoadOption(!renderLoadOption);

    setWarehouseId(option?.id);
    if (!itemId) {
      setStatus({
        ...status,
        wareHouseLoading: true,
      });
    }
    let warehouseID = option ? option.id : null;

    let updatedWarehouseItemsList = [];
    if (warehouseID !== null && itemIds.length > 0) {
      await fetchWarehouseItems(warehouseID).then((list) => {
        list.map((value, index) => {
          value['availableQuantity'] = getItemQuantity(value);
          value['updatedQuantity'] = getItemQuantity(value) + 1;
          value['adjustedQuantity'] = 1;
        });

        updatedWarehouseItemsList.push(...list);
        if (updatedWarehouseItemsList.length && !showAddItemButton) {
          setshowAddItemButton(true);
        }
        // allItems.push(...list);
      });
    }

    if (!updatedWarehouseItemsList.length) {
      updatedWarehouseItemsList.push(status.emptyItem);
      setshowAddItemButton(false);
    }
    setValues({
      ...values,
      warehouseID,
      items: updatedWarehouseItemsList,
    });
    setStatus({
      ...status,
      warehouseItemsFromDB: updatedWarehouseItemsList,
      wareHouseLoading: false,
    });
  };

  const handleSelectedItem = (option, index) => {
    let value = option[0] ? option[0] : option;
    let updatedNewItem = {
      id: value.id,
      name: value.name,
      images: value.images,
      sku: value.sku,
      availableQuantity: getItemQuantity(value),
      updatedQuantity: getItemQuantity(value),
      adjustedQuantity: values.items[index].adjustedQuantity
        ? values.items[index].adjustedQuantity
        : 1,
      unit: value.unit,
    };
    if (!itemId) {
      setItemIds([...itemIds, option.id]);
      setshowAddItemButton(true);
    }
    setValues({
      ...values,
      items: [...Object.assign([...values.items], { [index]: updatedNewItem })],
    });
    setStatus({
      ...status,
      warehouseItemsFromDB: status.warehouseItemsFromDB.filter(
        (item) => item.id !== option.id
      ),
    });
  };

  const handleBlurEvent = (e, index) => {
    var value = e.target.value;
    if (!value) {
      let updatedQuantity = values.items[index].availableQuantity;

      setFieldValue('items', [
        ...Object.assign([...values.items], {
          [index]: {
            ...values.items[index],
            adjustedQuantity: 0,
            updatedQuantity,
          },
        }),
      ]);
    }
  };
  const handleItemQuantityChange = (quantity, index) => {
    if (typeof quantity.floatValue !== 'undefined') {
      let adjustedQuantity = quantity.floatValue;
      let updatedQuantity =
        values.items[index].availableQuantity + (adjustedQuantity || 0);

      setFieldValue('items', [
        ...Object.assign([...values.items], {
          [index]: {
            ...values.items[index],
            adjustedQuantity: adjustedQuantity ? parseInt(adjustedQuantity) : 0,
            updatedQuantity,
          },
        }),
      ]);
    }
  };

  const handleRemoveItem = (option, removeIndex) => {
    if (values.items.length === 1) {
      values.items.push(emptyItem);
      setshowAddItemButton(false);
    }
    let item = allItems.find((item) => item.id === option.id);
    if (option.id != null) {
      setStatus({
        ...status,
        warehouseItemsFromDB: [...status.warehouseItemsFromDB, item].filter(
          (item) => item !== undefined
        ),
      });
    }
    let updatedItems = values.items.filter((_, index) => index !== removeIndex);
    let updatedItemIds = itemIds.filter((_, index) => index !== removeIndex);
    setItemIds(updatedItemIds);

    setFieldValue('items', updatedItems);
  };

  const renderItemSelector = () => {
    return (
      <FieldArray
        name="items"
        render={() => {
          return (
            <table className="list-table form-focus form-table border adjustment-form-table add_adjustment">
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th className="right-align">Available Qty.</th>
                  <th className="right-align">Updated Qty.</th>
                  <th className="right-align">Adjusted Qty.</th>
                </tr>
              </thead>
              <tbody>
                {values.items.map((item, index) => (
                  <tr key={index} className="table--row adjust-width">
                    {item.id ? (
                      <td>
                        <ListTableImageColumn
                          name={item.name}
                          sku={item.sku}
                          images={item.images}
                        />
                      </td>
                    ) : (
                      <td className="no-padding">
                        <Field
                          id="adjustment_items"
                          type="DynamicPaginationDropdown"
                          placeholder={
                            warehouseId ? 'Select Item' : 'Select WareHouse'
                          }
                          size="large"
                          renderLoadOption={renderLoadOption}
                          disabled={warehouseId ? false : true}
                          withImage={true}
                          selecteditem={itemIds}
                          from="adjustment"
                          value={item.id}
                          addButton={false}
                          api={`itemadjustments/create/warehouseitemlist/${warehouseId}`}
                          alphabetSort
                          searchKeys={[
                            'name',
                            'sku',
                            'upc',
                            'mpn',
                            'ean',
                            'isbn',
                          ]}
                          add
                          clearable={warehouseId ? false : true}
                          handleChange={(option) =>
                            handleSelectedItem(option, index)
                          }
                          error={getError(
                            errors,
                            touched,
                            `items[${index}].name`
                          )}
                          onBlur={() =>
                            setFieldTouched(`items[${index}].name`, true)
                          }
                        />
                      </td>
                    )}

                    <td className="right-align">
                      <Quantity
                        quantity={item.availableQuantity}
                        unit={item.unit}
                      />
                    </td>

                    <td className="right-align">
                      <Quantity
                        quantity={item.updatedQuantity}
                        unit={item.unit}
                      />
                    </td>

                    <td className="right-align no-padding with-close--btn">
                      <div className="adjustment-qty">
                        <MdRemove
                          className="material-icon adjustment-icon-gray"
                          onClick={() =>
                            item.id === null
                              ? false
                              : setFieldValue(
                                  `items[${index}].adjustedQuantity`,
                                  item.adjustedQuantity - 1
                                )
                          }
                        />
                        <Field
                          size="xs"
                          type="number"
                          value={item.adjustedQuantity}
                          className="adjustment-field"
                          decimalScale={6}
                          id={`items[${index}].adjustedQuantity`}
                          thousandSeparator=","
                          placeholder="e.g. 5 or -5"
                          allowNegative
                          itemsAdjustment={true}
                          onValueChange={(value) =>
                            handleItemQuantityChange(value, index)
                          }
                          //error={getError(errors, touched, `items[${index}].adjustedQuantity`) || (parseInt(item.adjustedQuantity) === 0 && 'Cannot be 0')}
                          error={
                            parseInt(item.adjustedQuantity) === 0 &&
                            'Cannot be 0'
                          }
                          onBlur={(value) => handleBlurEvent(value, index)}
                          //onBlur={handleBlur}onBlur={(value)=>{ handleBlurEvent(value, index); {handleBlur} }}
                          disabled={item.id === null}
                          isAllowed={({ floatValue }) => {
                            // Price_Quantity_Limit

                            // price and quantity limit from 5 digits to 10 digits
                            if (!floatValue) {
                              return true;
                            } else {
                              return floatValue <= 9999999999;
                            }
                          }}
                        />
                        <MdAdd
                          className="material-icon adjustment-icon-green"
                          onClick={() =>
                            item.id === null
                              ? false
                              : setFieldValue(
                                  `items[${index}].adjustedQuantity`,
                                  item.adjustedQuantity + 1
                                )
                          }
                        />
                      </div>
                      <div className="remove_row_button">
                        <button
                          type="button"
                          className='close-btn button'
                          disabled={
                            !itemIds.length && values.items[0].id === null
                          }
                          onClick={() => handleRemoveItem(item, index)}
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          );
        }}
      />
    );
  };
  return (
    <Fragment>
      <Form
        onSubmit={(event) =>
          formSubmit(event, values.items, setFieldValue, handleSubmit)
        }
      >
        <FormSection title={title}>
          <div className="form-fields-container d-flex flex-wrap  position-relative">
            <Field
              className="flex-25"
              type="dropdown"
              name="Adjustment Type"
              id="adjustmentType"
              value={values.adjustmentType}
              autoFocus={true}
              options={ADJUSTMENT_OPTIONS}
              dropdownValue="value"
              dropdownLabel="label"
              handleChange={handleAdjustmentTypeChange}
              onBlur={handleBlur}
              clearable={false}
              required
              error={touched.adjustmentType && errors.adjustmentType}
            />

            <Field
              type="date"
              name="Date"
              id="date"
              className="flex-25"
              value={values.date}
              handleChange={(date) => {
                setFieldValue('date', date);
                setFieldValue('date_update', true);
              }}
              onBlur={() => setFieldTouched('date', true)}
              required
              error={touched.date && errors.date}
            />

            <Field
              className="flex-25"
              name="Purchase Account"
              required
              defaultValue={findByValue(
                values.purchaseAccountID,
                formatAccountList(purchaseAccounts)
              )}
              id="purchaseAccountID"
              type="groupdropdown"
              options={formatAccountList(purchaseAccounts)}
              handleChange={(option) => {
                setFieldValue('purchaseAccountID', option.value);
                setFieldValue('purchase_update', true);
              }}
              clearable={false}
            />

            <Field
              className="flex-25"
              type="dropdown"
              name="Warehouse"
              id="warehouseID"
              value={values.warehouseID}
              options={warehouseFromDB}
              dropdownValue="id"
              dropdownLabel="name"
              //handleChange={handleWarehouseChange}
              handleChange={(option) => {
                setFieldValue('warehouseID', option ? option.id : null);
                handleWarehouseChange(option);
              }}
              onBlur={handleBlur}
              required
              error={touched.warehouseID && errors.warehouseID}
            />

            <Field
              name="Reason"
              type="textarea"
              className="flex-50 textarea-height"
              value={values.reason}
              id="reason"
              placeholder="Reason"
              handleChange={handleChange}
              onBlur={handleBlur}
              required
              error={touched.reason && errors.reason}
            />

            <Field
              type="textarea"
              name="Description"
              className="flex-50 textarea-height"
              value={values.description}
              id="description"
              placeholder="Description"
              onBlur={handleBlur}
              handleChange={handleChange}
              error={touched.description && errors.description}
            />
          </div>

          <div className="form-field form-table--container w-100">
            <label className="form-label">Select Items</label>
            {status.wareHouseLoading ? <Loader /> : renderItemSelector()}
            {showAddItemButton && (
              <AddItem
                onClick={() =>
                  setValues({
                    ...values,
                    items: [...values.items, status.emptyItem],
                  })
                }
              />
            )}
          </div>

          <FormAction
            disabled={isSubmitting}
            marginFromRight={2}
            setPrompt={props.setPrompt}
            onSubmit={handleSubmit}
            onCancel={() => {
              let dataFromLS = getObjFromLS('module');
              let redirectUrl = '';
              if (dataFromLS && dataFromLS.moduleName === 'items') {
                redirectUrl = dataFromLS.queryParam;
              }
              if (
                window.location.pathname.includes('add') ||
                window.location.pathname.includes('edit')
              ) {
                history.push('/item-adjustments' + redirectUrl);
              } else {
                setObjInLS('module', { ...dataFromLS, action: null });
                history.push('/r');
              }
            }}
          />
        </FormSection>
        <ErrorFocus />
      </Form>
    </Fragment>
  );
};

export default formEnhancer(AdjustmentForm);
