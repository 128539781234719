import React from 'react'

export default ({
  className = ''
}) => (

  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 73.863 56.227">
    <g transform="translate(-624.075 -390.937)">
      <path d="M692.729,397a4.336,4.336,0,0,0-5.883-.61L653.066,421.4l-6.418-14.6a3.1,3.1,0,0,0-4.146-1.358l-1.934.85a3.1,3.1,0,0,0-1.8,3.974l8.266,18.8a3.351,3.351,0,0,0,3.555,2.143,9.119,9.119,0,0,0,3.9-1.333l37.115-27.343A3.873,3.873,0,0,0,692.729,397Z" transform="translate(4.48 1.422)"/>
      <path d="M673.323,423.337a21.614,21.614,0,1,1-1.68-13.531L677,405.859a28.1,28.1,0,1,0,3.293,13.406Z"/>
    </g>
  </svg>
   )
