import React from 'react'

import styles from './MatchingTable.module.css'

const MatchingTable = (
  {
    headers = [],
    data = [] // array of objects. e.g. [{data1: a, data2: b}, {data1: x, data2: y}] renders 2 column with
    // respective data
  }) => {
  return (
    <table className={styles.matchingTable} rules='cols'>
      <thead>
      <tr>
        {
          headers.map((header, index) => <th key={index}>{header}</th>)
        }
      </tr>
      </thead>
      <tbody>
      {
        data.map((row, index) => (
          <tr key={index}>
            {
              Object.values(row).map((colData, index) => <td key={index}>{colData}</td>)
            }
          </tr>
        ))
      }
      </tbody>
    </table>
  )
}

export default MatchingTable