import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import getDate from '../../../Helpers/Date/GetDate';
import getStatusColor from '../../../Helpers/GetStatusColor';
import { restRequest } from '../../../Helpers/RequestHelper';
// import {
//   moreThanMinus,
//   moreThanZero,
//   required,
//   ValidateMax255,
//   ValidateMax50,
//   ValidateNumber,
//   ValidateRequired
// } from '../../../Helpers/Validator'
import { getOrganizationDate } from '../../../Helpers/Date/OrganizationDate';

import Loader from '../../Loader/Loader';
import NotFound from '../../NotFound/NotFound';
import Field from '../../Form/Field/Field';
import Form from '../../Form/Form';
import FormAction from '../FormAction';
import ErrorFocus from '../ErrorFocus';

import './ApplyOnInvoiceForm.css';
import { getObjFromLS, setObjInLS } from '../../../Helpers/LocalStorage';
import { Link, withRouter } from 'react-router-dom';
// import {formatNumber} from "../../../Helpers/FormatNumber";
// import {getError} from "../FormHelper";
import { confirmationAlert } from '../../../Helpers';

let flag;
class ApplyOnInvoiceForm extends Component {
  type = this.props.type; // "invoice", "bill"
  disableSaveBtn = true;
  id = this.props.id || null;
  saveAndSend = false;
  state = {
    loading: true,
    creditNote: {},
    invoices: {},
    totalCreditAmount: 0,
    totalAppliedInvoices: 0,
    paymentDue: 0,
    issuedCredits: 0,
    currency: {},
    notFound: false,
    credit_utilize_date: moment(getOrganizationDate()),
    alertState: false,
  };

  componentDidMount() {
    localStorage.setItem('alert', false);
    this.setState({ loading: true });
    this.fetchData().then(() => this.setState({ loading: false }));
    window.onbeforeunload = function () {
      return true;
    };
  }
  componentWillUnmount() {
    flag = undefined;
    window.onbeforeunload = null;
  }

  formValidator() {
    const { invoices } = this.state;
    let checkout = true;
    if (invoices.length) {
      const checkEquality = invoices.some((credit) => credit.used_credits);
      if (checkEquality) {
        checkout = false;
      }
    }
    return { checkout };
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.alertState !== this.state.alertState) {
      const { checkout } = this.formValidator();
      if (!checkout) localStorage.setItem('alert', true);
      else localStorage.setItem('alert', false);
    }
  }

  handlealertFlag = () => {
    this.setState({
      alertState: !this.state.alertState,
    });
  };

  fetchData = async () => {
    this.setState({ loading: true });

    await this.fetchPayment(this.id);

    this.setState({ loading: false });
  };

  fetchPayment = async (id) => {
    await restRequest('get', `creditutilize/${id}/create/credit`)
      .then((res) => {
        //console.log('res', res)
        flag = res;
        this.setState({
          invoices: res.invoices,
          creditNote: res.creditNote,
          currency: res.currency,
          issuedCredits: res.creditNote.balance,
          credit_utilize_date: res?.creditNote?.credit_note_date
            ? moment(res?.creditNote?.credit_note_date)
            : moment(getOrganizationDate()),
        });
      })
      .catch((error) => {
        // console.log('res ,r' ,error.response.data.message)
        this.props.handleToast(error, 'error');
        if (this.props.inModal) {
          this.props.onClose();
        }
      });
  };

  handleSubmit = (values, setSubmitting) => {
    let payload = {
      creditNoteId: this.state.creditNote.id,
      creditNotesList: values.filter((cr) => cr.used_credits > 0),
      appliedCreditNotes: this.state.totalAppliedInvoices,
      amountToCredit: this.state.totalCreditAmount,
      invoiceBalanceDue: this.state.creditNote.balance,
      credit_utilize_date: this.state.credit_utilize_date.format(
        'YYYY-MM-DD 00:00:00'
      ),
    };

    restRequest('post', 'creditutilize/store', payload)
      .then((res) => {
        //console.log('res', res)
        // this.props.onSubmit()
        this.props.handleToast(
          'Payment has been recorded successfully',
          'success'
        );
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS === null) {
          let obj = {
            prevId: null,
            id: null,
            nextId: null,
            moduleName: '',
            urlPath: window.location.pathname,
            queryParam: window.location.search,
          };
          setObjInLS('module', obj);
          dataFromLS = getObjFromLS('module');
        }
        window.location.pathname.includes('creditnotes') &&
          setObjInLS('module', {
            ...dataFromLS,
            moduleName: 'creditnotes',
            urlPath: window.location.pathname,
            queryParam: `${
              dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
            }appliedOnInvoices=true`,
          });
        return this.props.history.push('/r');
      })
      .catch((error) => {
        //console.log('error', error)
        if (typeof setSubmitting !== undefined) {
          setSubmitting(false);
        }
        this.props.handleToast(
          'Payments made cannot be greater than balance due.',
          'error'
        );
      });
  };

  renderFooter = () => {
    let { totalCreditAmount, totalAppliedInvoices, currency } = this.state;
    return (
      <div
        className="float-left w-100 mt_3 summary_table"
        style={{ paddingRight: '2%' }}
      >
        <hr className="margin float-left w-100"></hr>
        <div className="float-left w-70 p_10">
          <div className="float-left w-100 so-mg-1">
            <span className="float-left" style={{ marginRight: '10px' }}>
              Applied Invoices:{' '}
            </span>
            <span className="float-left">{totalAppliedInvoices}</span>
          </div>
        </div>
        <div className="float-left w-30 p_10 pr-0">
          <div className="float-left w-100 so-mg-1">
            <span className="float-left">Credits Applied</span>
            <span className="float-right bold">
              {' '}
              {currency.symbol}
              {totalCreditAmount}
            </span>
          </div>
          <div className="float-left w-100 so-mg-1">
            <span className="float-left">
              <b>Remaining Credits</b>
            </span>
            <span className="float-right bold">
              {' '}
              {currency.symbol}
              {this.state.issuedCredits.toFixed(2)}
            </span>
          </div>
        </div>
        <hr className="margin float-left w-100" />
        <hr className="margin float-left w-100" />
      </div>
    );
  };
  handleDisableButton(amount) {
    if (amount > this.state.creditNote.balance) {
      this.disableSaveBtn = true;
    } else {
      this.disableSaveBtn = false;
    }
    return;
  }
  handleItemQuantityChange = (quantityValue, index, item) => {
    this.setState({
      ...this.state,
      issuedCredits: this.state.creditNote.balance,
    });
    if (quantityValue.value === '') {
      let currentCreaditAmount =
        Number(this.state.totalCreditAmount) -
        Number(this.state.invoices[index].used_credits);
      this.setState(
        {
          totalCreditAmount: currentCreaditAmount,
          issuedCredits:
            Number(this.state.creditNote.balance) -
            Number(currentCreaditAmount),
        },
        () => {
          if (this.state.totalCreditAmount === 0) this.disableSaveBtn = true;
        }
      );
      this.setState({
        ...(this.state.invoices[index].used_credits = ''),
        // used_credits: '',
      });
      this.handleDisableButton(currentCreaditAmount);
      return;
    }
    //console.log(quantityValue);
    let credits = quantityValue.floatValue;
    // Price_Quantity_Limit

    // price and quantity limit from 5 digits to 10 digits
    if (credits > 0) {
      let quantString = credits.toString();
      if (quantString.length > 10) {
        quantString = quantString.slice(0, 10);
      }
      credits = parseFloat(quantString);
    }
    //console.log(credits);
    // this.state.invoices[index].used_credits = credits;
    this.setState({
      ...(this.state.invoices[index].used_credits = credits),
      // used_credits: credits,
    });
    let applied_Credits = this.state.invoices.filter(
      (el) => el.used_credits > 0
    );
    let total_Credits = applied_Credits.reduce(function (prev, current) {
      return prev + current.used_credits;
    }, 0);
    this.setState({
      ...this.state,
      totalCreditAmount: total_Credits,
      totalAppliedInvoices: applied_Credits.length,
      issuedCredits:
        Number(this.state.creditNote.balance) - Number(total_Credits),
    });
    if (
      total_Credits > this.state.creditNote.balance ||
      applied_Credits.length <= 0 ||
      Number(this.state.creditNote.balance) - Number(total_Credits) < 0 ||
      item.used_credits > item.due
    ) {
      this.disableSaveBtn = true;
    } else {
      this.disableSaveBtn = false;
    }
    let checkInvoices = this.state.invoices.filter(
      (inv) => inv.used_credits > inv.due
    );
    if (checkInvoices && checkInvoices.length > 0) {
      this.disableSaveBtn = true;
    }
  };

  handlCancel = () => {
    const { onClose } = this.props;
    let response;
    const { checkout } = this.formValidator();

    if (!checkout) response = confirmationAlert();
    if (response || checkout) onClose();
  };

  renderRecordPaymentForm() {
    let { invoices, currency } = this.state;
    return (
      <Formik
        enableReinitialize
        initialValues={invoices}
        validationSchema={Yup.object().shape({
          /*amount: required(moreThanMinus(ValidateNumber)),
            date: ValidateRequired.nullable(),
            mode: required(ValidateMax50),
            reference: ValidateMax50,
            notes: ValidateMax255*/
        })}
        onSubmit={(values, { setSubmitting }) => {
          this.handleSubmit(values, setSubmitting);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          handleBlur,
          submitCount,
          setSubmitting,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="credit_note_utilization">
            <div className="so-tbletabs">
              <table
                className={`list-table form-table border create_invoice horizontal-items-table`}
              >
                <thead>
                  <tr className="table--row">
                    <th className="">Invoice Date</th>
                    <th className="">Invoice No.</th>
                    <th className="">Status</th>
                    <th className="right-align">Amount</th>
                    <th className="right-align">Balance Due</th>
                    <th className="">Amount To Credit</th>
                  </tr>
                </thead>
                <tbody>
                  {invoices.map((item, index) => {
                    return (
                      <tr key={index} className="table--row">
                        <td className="">{getDate(item.invoice_date)}</td>
                        <td className="">
                          <Link
                            to={`/invoices/${item.invoice_id}`}
                            target="_blank"
                          >
                            {item.invoice_no}
                          </Link>
                        </td>
                        <td className="">
                          <span className={getStatusColor(item.status)}>
                            {' '}
                            {item.status === 'partially paid'
                              ? 'PRTL Paid'
                              : item.status}
                          </span>
                        </td>
                        <td className="right-align">
                          {currency.symbol}
                          {item.total}
                        </td>
                        <td className="right-align">
                          {currency.symbol}
                          {item.due}
                        </td>
                        <td className="right-align no-padding">
                          <Field
                            size="small"
                            type="number"
                            value={item.used_credits}
                            decimalScale={2}
                            id={`invoices[${index}].used_credits`}
                            allowNegative={false}
                            placeholder="$0"
                            thousandSeparator=","
                            onValueChange={(value) => {
                              this.handleItemQuantityChange(value, index, item);
                              this.handlealertFlag();
                            }}
                            error={
                              touched &&
                              touched.invoices &&
                              touched.invoices[index] &&
                              touched.invoices[index].used_credits &&
                              invoices[index].used_credits > invoices[index].due
                            }
                            onBlur={handleBlur}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {this.renderFooter()}
            <FormAction
              hasHr={true}
              marginFromRight={4}
              disabled={isSubmitting || this.disableSaveBtn}
              onCancel={this.handlCancel}
              showSaveAndSendButton={false}
            />

            <ErrorFocus />
          </Form>
        )}
      />
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    return this.renderRecordPaymentForm();
  }
}

export default withRouter(ApplyOnInvoiceForm);
