import React from 'react';
import { MdAdd } from '../../Common/Icons';
import Select from 'react-select';
import SortingSearchableDropdown from './SortingSearchableDropdown';
import CreatableSelect from 'react-select/creatable';
import { imagePathS3 } from '../../Helpers/RequestHelper';
// import {
//     StateShortName,
//     StateFullName,
// } from '../../Helpers/StateNameConvertion';
import { EmailContext } from '../Email/EmailEnhancer';
import './SearchableDropdown.css';

const SearchableDropdown = ({
  id,
  state,
  options,
  multi = false,
  removeSelected = false,
  placeholder = 'Select...',
  value = [],
  label,
  handleChange,
  addButton = false,
  disableOption = '',
  itemsDropDown = false,
  onInputChange: InputChange,
  disabled = false,
  searchable = true,
  clearable = true,
  className = '',
  isAddress = false,
  withImage = false,
  creatable = false,
  badge = false,
  validationCheck = false,
  onBlur,
  additionalSearchFilter = '',
  size = 'medium',
  autoFocus = false,
  alphabetSort = false,
  defaultValue,
  onKeyDown,
  addType,
}) => {
  let updatedOptions;
  if (badge === false) updatedOptions = handleOptions(options);
  else updatedOptions = handleOptionsForBadge(options);

  function prepareOptionForContactAddress(option) {
    return {
      value: option[value],
      label: (
        // <div>
        //   <div>{option.attention}</div>
        //   <div>{option.street}</div>
        //   <div>{option.city}</div>
        //   <div>{option.state}</div>
        //   <div>{option.countryName}</div>
        // </div>
        <div>
          <div>{!!option.attention && `${option.attention},`}</div>
          <div>{!!option.street && `${option.street},`}</div>
          {/*<div>{!!option.city && `${option.city},`} {!!option.state && `${ StateShortName(option.state)} `} {!!option.zip_code && `${option.zip_code},`} {option.country_name}</div>*/}
          <div>
            {!!option.city && `${option.city},`}{' '}
            {!!option.state && `${option.state} `}{' '}
            {!!option.zip_code && `${option.zip_code},`} {option.country_name}
          </div>

          {!!option.phone && <div>Phone: {option.phone}</div>}
          {!!option.fax && <div>Fax: {option.fax}</div>}
        </div>
      ),
    };
  }

  const addressDropdownStyles = {
    singleValue: (styles) => {
      return {
        ...styles,
        top: 0,
        transform: 'unset',
      };
    },
  };

  function prepareOptionWithImage(option) {
    var image =
      typeof option?.images !== 'undefined' ? option?.images?.[0] : '';
    return {
      ...option,
      label: (
        <div className="option--with-image">
          <div className="image">
            <img
              src={imagePathS3(image)}
              role="presentation"
              alt="presentation"
            />
          </div>
          <div className="desc">
            {option?.name || ''}
            <span className="small-td-info">{option?.sku || ''}</span>
          </div>
        </div>
      ),

      value: option?.[value],
    };
  }

  function filterOptions(option, filterString) {
    if (filterString) {
      // To escape some special characters that regex does not support
      filterString = filterString.replace(
        //eslint-disable-next-line
        /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
        //eslint-disable-next-line
        '\\$&'
      );
      var indexOFSearchFilter = additionalSearchFilter.indexOf(',');
      if (indexOFSearchFilter === -1) {
        return (
          option.data[label].match(new RegExp(`${filterString}`, 'gi')) ||
          option.data[additionalSearchFilter].match(
            new RegExp(`${filterString}`, 'gi')
          ) ||
          Number(filterString) === option.data[value]
        );
      } else {
        var searchFields = additionalSearchFilter.split(',');
        var valueofFind = [];
        for (var field of searchFields) {
          if (option.data[label] && option.data[field]) {
            var searchedResult =
              option.data[label].match(new RegExp(`${filterString}`, 'gi')) ||
              option.data[field].match(new RegExp(`${filterString}`, 'gi')) ||
              Number(filterString) === option.data[value];
            if (searchedResult && searchedResult.length > 0) {
              valueofFind = searchedResult;
              break;
            }
          }
        }
        if (valueofFind.length > 0) {
          return valueofFind;
        }
      }
    } else {
      // To escape some special characters that regex does not support
      filterString = filterString.replace(
        //eslint-disable-next-line
        /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
        //eslint-disable-next-line
        '\\$&'
      );
      return (
        option?.data?.[label]?.match(new RegExp(`${filterString}`, 'gi')) ||
        option?.data?.[additionalSearchFilter]?.match(
          new RegExp(`${filterString}`, 'gi')
        ) ||
        Number(filterString) === option?.data?.[value]
      );
    }
  }
  function handleOptionsForBadge(options) {
    let renderOptions = [];
    options.forEach((option) => {
      if (option.email) {
        renderOptions.push({ label: option.email, value: option.id });
      } else {
        renderOptions.push({ label: option.email, value: option.id });
      }
    });
    return renderOptions;
  }
  function handleOptions(options) {
    let dropdownOption = {
      value: '',
      label: '',
    };
    var renderOptions = [];
    if (
      id === 'contactId' ||
      id === 'vendorID' ||
      id === 'customerId' ||
      id === 'vendorId'
    ) {
      if (addButton) {
        let addNew = {
          value: 'createNew',
          label: (
            <div className="DDTopElement">
              <MdAdd /> Add New
            </div>
          ),
          className: 'add-new-option',
        };
        renderOptions.push(addNew);
      }
    }
    if (id === 'itemId') {
      if (addButton) {
        let addNew = {
          value: 'createNew',
          label: (
            <div className="DDTopElement">
              <MdAdd /> Add New
            </div>
          ),
          className: 'add-new-option',
          id: '',
          name: '',
          sku: '',
          unit: '',
          sales_unit_price: '',
          images: '',
          tax_id: '',
          inventory_type: '',
          upc: '',
          mpn: '',
          ean: '',
          isb: '',
        };
        renderOptions.push(addNew);
      }
    }
    let renderOptionsExist = options.map((option) => {
      if (typeof option === 'string' || typeof option === 'number') {
        dropdownOption = {
          ...option,
          value: option,
          label: option.is_primary ? option + ' (PRI)' : option,
        };
      } else if (isAddress) {
        dropdownOption = prepareOptionForContactAddress(option);
      } else if (withImage) {
        dropdownOption = prepareOptionWithImage(option);
      } else {
        dropdownOption = {
          ...option,
          value: option[value],
          label: option.is_primary ? option[label] + ' (PRI)' : option[label],
        };
      }
      return dropdownOption;
    });

    if (renderOptions.length > 0) {
      renderOptionsExist.forEach(function (option) {
        renderOptions.push(option);
      });
    } else {
      renderOptions = renderOptionsExist;
    }

    if (
      id !== 'contactId' &&
      id !== 'vendorID' &&
      id !== 'customerId' &&
      id !== 'vendorId' &&
      id !== 'itemId'
    ) {
      if (addButton) {
        let addNew = {
          value: 'createNew',
          label: (
            <div className="DDTopElement">
              <MdAdd /> Add New
            </div>
          ),
          className: 'add-new-option',
        };
        renderOptions.push(addNew);
      }
    }

    if (disableOption !== '') {
      let addNew = {
        value: 'existing',
        label: <div>{disableOption}</div>,
        className: 'add-new-option',
        isDisabled: true,
      };
      renderOptions.push(addNew);
    }
    return renderOptions;
  }

  if (badge) {
    value = value.map((val) => ({ label: val, value: val }));
    return (
      <EmailContext.Consumer>
        {(selected) => {
          return addType ? (
            <CreatableSelect
              onChange={handleChange}
              isMulti
              defaultValue={selected}
              autoFocus={autoFocus}
              noOptionsMessage={() =>
                window.location.pathname.includes('item') ? null : 'No Options'
              }
              placeholder={
                window.location.pathname.includes('item') ? 'Options' : 'To...'
              }
              formatCreateLabel={(value) => `Add...${value}`}
              name="colors"
              options={updatedOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              value={value}
            />
          ) : (
            <CreatableSelect
              onChange={handleChange}
              isMulti
              defaultValue={selected}
              autoFocus={autoFocus}
              noOptionsMessage={() =>
                window.location.pathname.includes('item') ? null : 'No Options'
              }
              placeholder={
                window.location.pathname.includes('item') ? 'Options' : 'To...'
              }
              formatCreateLabel={(value) => `Add...${value}`}
              name="colors"
              options={updatedOptions}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          );
        }}
      </EmailContext.Consumer>
    );
  }
  if (creatable) {
    return (
      <CreatableSelect
        inputId={id}
        value={updatedOptions.filter((option) => option.value === state)}
        options={updatedOptions}
        isMulti={multi}
        onChange={handleChange}
        placeholder={placeholder}
        isDisabled={disabled}
        isSearchable={searchable}
        isClearable={clearable}
        className={`searchable-dropdown ${size} ${className} ${
          withImage ? 'select--with-image' : ''
        } ${validationCheck ? 'required' : ''}`}
        classNamePrefix="searchable-dropdown"
        hideSelectedOptions={removeSelected}
        onBlur={onBlur}
        // filterOption={!!additionalSearchFilter ? filterOptions : undefined}
        menuPlacement="auto"
        styles={isAddress ? addressDropdownStyles : {}}
      />
    );
  }
  if (itemsDropDown) {
    return (
      <>
        <Select
          autoFocus={autoFocus}
          inputId={id}
          value={updatedOptions.filter((option) => option.value === state)}
          options={updatedOptions}
          isMulti={multi}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          isDisabled={disabled}
          isSearchable={searchable}
          isClearable={clearable}
          onInputChange={InputChange}
          className={`searchable-dropdown ${size} ${className} ${
            withImage ? 'select--with-image' : ''
          } ${validationCheck ? 'required' : ''}`}
          classNamePrefix="searchable-dropdown"
          hideSelectedOptions={removeSelected}
          onBlur={onBlur}
          filterOption={!!additionalSearchFilter ? filterOptions : undefined}
          menuPlacement="auto"
          styles={isAddress ? addressDropdownStyles : {}}
        />
      </>
    );
  }

  if (alphabetSort) {
    return (
      <>
        <SortingSearchableDropdown
          id={id}
          autoFocus={autoFocus}
          state={state}
          options={options}
          multi={multi}
          removeSelected={removeSelected}
          value={value}
          label={label}
          itemsDropDown={itemsDropDown}
          handleChange={handleChange}
          // onInputChange={onInputChange}
          placeholder={placeholder}
          disabled={disabled}
          searchable={searchable}
          addButton={addButton}
          disableOption={disableOption}
          withImage={withImage}
          className={className}
          creatable={creatable}
          clearable={clearable}
          validationCheck={validationCheck}
          onBlur={onBlur}
          size={size}
          isAddress={isAddress}
          additionalSearchFilter={additionalSearchFilter}
          alphabetSort={alphabetSort}
          updatedOptions={updatedOptions}
        />
      </>
    );
  }

  return (
    <Select
      autoFocus={autoFocus}
      inputId={id}
      value={updatedOptions.filter((option) => option.value === state)}
      options={updatedOptions}
      isMulti={multi}
      onChange={handleChange}
      placeholder={placeholder}
      isDisabled={disabled}
      isSearchable={searchable}
      isClearable={clearable}
      className={`searchable-dropdown ${size} ${className} ${
        withImage ? 'select--with-image' : ''
      } ${validationCheck ? 'required' : ''}`}
      classNamePrefix="searchable-dropdown"
      hideSelectedOptions={removeSelected}
      onBlur={onBlur}
      filterOption={!!additionalSearchFilter ? filterOptions : undefined}
      menuPlacement="auto"
      styles={isAddress ? addressDropdownStyles : {}}
    />
  );
};

export default SearchableDropdown;
