import React, { useEffect, useRef, useState } from 'react';
import {
  CenterContent,
  restRequest,
  OrganizationsList,
  getOrganization,
} from './index';

const OrganizationChoose = (props) => {
  const [pageLoading, setPageLoading] = useState(true);
  const [organizations, setOrganizations] = useState([]);
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState();
  const [, setCurrentOrgId] = useState(null);

  const stopDidMount = useRef(false);
  const currentOrganizationId = parseInt(getOrganization());

  useEffect(() => {
    restRequest('get', 'auth/user')
      .then((res) => {
        if (res) {
          setUserId(res.id);
          setUser(res);
          setCurrentOrgId(res?.default_org?.default_organization_id);
        }
      })
      .catch((error) => {
        if (
          error?.response?.data?.message === `Couldn't find your IMS Account`
        ) {
          props.history.push('/login');
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stopDidMount.current) {
      restRequest('get', `organizations`, {})
        .then((res) => {
          if (res?.organizations?.length) {
            let chkexist = res?.organizations.filter(
              (org) =>
                org.organization_id === currentOrganizationId &&
                org.status === 0
            );

            if (chkexist.length !== 0) {
              setOrganizations(res?.organizations);
              setPageLoading(false);
            } else {
              props.history.push('/');
            }
          }
        })
        .catch((error) => {
          if (
            error?.response?.data?.message === `Couldn't find your IMS Account`
          ) {
            props.history.push('/login');
          }
        });
    } else {
      stopDidMount.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <>
      <CenterContent props={props} user={user} loading={pageLoading}>
        {pageLoading ? (
          'loading'
        ) : (
          <OrganizationsList organizations={organizations} props={props} />
        )}
      </CenterContent>
    </>
  );
};
export default OrganizationChoose;
