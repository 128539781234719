import React from 'react'

export default ({
  className = ''
}) => (

<svg className={className} xmlns="http://www.w3.org/2000/svg; http://www.w3.org/1999/xlink" width="20.003" height="20.003" viewBox="0 0 20.003 20.003">
  <defs>
    <clipPath id="clip-path">
      <rect width="20.003" height="20.003" fill="none"/>
    </clipPath>
  </defs>
  <g id="Repeat_Grid_4" data-name="Repeat Grid 4" clip-path="url(#clip-path)">
    <g id="Group_804" data-name="Group 804" transform="translate(-59.998 -10)">
      <path id="Icon_material-question-answer" data-name="Icon material-question-answer" d="M22,7H20v9H7v2a1,1,0,0,0,1,1H19l4,4V8A1,1,0,0,0,22,7Zm-4,6V4a1,1,0,0,0-1-1H4A1,1,0,0,0,3,4V18l4-4H17A1,1,0,0,0,18,13Z" transform="translate(56.998 7)" fill="#306ab4"/>
    </g>
  </g>
</svg>
  )
