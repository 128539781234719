import React, { useState } from 'react';
import Select from 'react-select';
import { matchSorter } from 'match-sorter';

const SortingSearchableDropdown = ({
  id,
  state,
  options,
  multi = false,
  removeSelected = false,
  placeholder = 'Select...',
  value,
  label,
  handleChange,
  addButton = false,
  disableOption = '',
  itemsDropDown = false,
  // onInputChange: InputChange,
  disabled = false,
  searchable = true,
  clearable = true,
  className = '',
  isAddress = false,
  withImage = false,
  creatable = false,
  badge = false,
  validationCheck = false,
  onBlur,
  additionalSearchFilter = '',
  size = 'medium',
  autoFocus = false,
  alphabetSort = false,
  updatedOptions = [],
}) => {
  const [searchoptions, setSearchOptions] = useState(updatedOptions);

  function filterOptions(option, filterString) {
    if (filterString) {
      // To escape some special characters that regex does not support
      filterString = filterString.replace(
        //eslint-disable-next-line
        /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
        //eslint-disable-next-line
        '\\$&'
      );
      var indexOFSearchFilter = additionalSearchFilter.indexOf(',');
      if (indexOFSearchFilter === -1) {
        return (
          option.data[label].match(new RegExp(`${filterString}`, 'gi')) ||
          option.data[additionalSearchFilter].match(
            new RegExp(`${filterString}`, 'gi')
          ) ||
          Number(filterString) === option.data[value]
        );
      } else {
        var searchFields = additionalSearchFilter.split(',');
        var valueofFind = [];
        for (var field of searchFields) {
          if (option.data[label] && option.data[field]) {
            var searchedResult =
              option.data[label].match(new RegExp(`${filterString}`, 'gi')) ||
              option.data[field].match(new RegExp(`${filterString}`, 'gi')) ||
              Number(filterString) === option.data[value];
            if (searchedResult && searchedResult.length > 0) {
              valueofFind = searchedResult;
              break;
            }
          }
        }
        if (valueofFind.length > 0) {
          return valueofFind;
        }
      }
    } else {
      // To escape some special characters that regex does not support
      filterString = filterString.replace(
        //eslint-disable-next-line
        /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
        //eslint-disable-next-line
        '\\$&'
      );
      return (
        option.data[label].match(new RegExp(`${filterString}`, 'gi')) ||
        option.data[additionalSearchFilter].match(
          new RegExp(`${filterString}`, 'gi')
        ) ||
        Number(filterString) === option.data[value]
      );
    }
  }
  const addressDropdownStyles = {
    singleValue: (styles) => {
      return {
        ...styles,
        top: 0,
        transform: 'unset',
      };
    },
  };

  return (
    <>
      <Select
        autoFocus={autoFocus}
        inputId={id}
        value={updatedOptions.filter((option) => option.value === state)}
        options={searchoptions}
        isMulti={multi}
        onChange={handleChange}
        placeholder={placeholder}
        isDisabled={disabled}
        isSearchable={searchable}
        isClearable={clearable}
        className={`alphabatic-search searchable-dropdown ${size} ${className} ${
          withImage ? 'select--with-image' : ''
        } ${validationCheck ? 'required' : ''}`}
        classNamePrefix="searchable-dropdown"
        hideSelectedOptions={removeSelected}
        onBlur={onBlur}
        filterOption={!!additionalSearchFilter ? filterOptions : undefined}
        menuPlacement="auto"
        styles={isAddress ? addressDropdownStyles : {}}
        onInputChange={(inputValue) => {
          setSearchOptions(
            matchSorter(updatedOptions, inputValue, { keys: ['label'] })
          );
        }}
      />
    </>
  );
};
export default SortingSearchableDropdown;
