import React, { Fragment } from 'react';

import { formatNumber } from '../../../Helpers/FormatNumber';

import styles from './Quantity.module.css';

const Quantity = ({ quantity = 0, unit = 'unit' }) => {
  return (
    <Fragment>
      <div className={styles.quantityPadding}>
        {formatNumber(isNaN(quantity) ? 0 : quantity)}
      </div>
      <div className={styles.quantityUnit}>
        {unit}
        {unit && <Fragment>(s)</Fragment>}
      </div>
    </Fragment>
  );
};

export default Quantity;
