import React, { Component } from 'react';
import queryString from 'query-string';
import { ListTable, restRequest } from '../';
import { HeaderWrapper } from '../..';
import { NewItemIcon } from '../../../Assets/Navigation/NavigationIcons';
import {
  AsyncSelectPaginate,
  AsyncSelectStock,
  Button as CommonButton,
} from '../../../Common';
import classNames from 'classnames';
import { MdClose, SiShopify } from '../../../Common/Icons';

class UnSycnItemsMapping extends Component {
  orderId = null;
  storeId = null;
  unMappedItemsEndPoint = '';
  imsItemsEndPoint = '';
  tableColumns = [
    {
      HeaderName: 'Item Name',
      value: 'name',
    },
    {
      HeaderName: 'SKU',
      value: 'sku',
    },
    {
      HeaderName: 'asyncPaginate',
      value: 'asyncPaginate',
    },
    {
      HeaderName: 'stocks',
      value: 'stocks',
    },
  ];

  state = {
    data: [],
    itemsInfo: [],
    currentStore: {},
    configImsItemsData: [],
    loading: true,
    currentPage: 1,
    viewPerPage: 20,
    totalPage: 1,
    totalRecords: 0,
    orderBy: 'asc',
    sortFilter: 'created_at',
    mappedBtnLoader: false,
    storeType: 'woocom',
  };

  componentWillMount() {
    const {
      history: {
        location: { pathname },
      },
    } = this.props;
    const array = pathname?.split('/');
    this.orderId = Number(array[2]);
    this.storeId = Number(array[3]);
    this.unMappedItemsEndPoint = `${array[4]}/remaining_orders/order_items/${this.orderId}`;
    this.imsItemsEndPoint = `${array[4]}/items/ims/${this.storeId}`;
    this.setState({
      storeType: array[4],
    });
  }

  componentDidMount() {
    document.title = 'Un sync orders items';
    this.fetchDataWithQueryString();
  }

  dataNotFound = () => {
    this.setState({
      loading: false,
    });
  };

  fetchDataWithQueryString() {
    const { page, orderBy, view } = this.parseQueryString();
    this.setState(
      {
        currentPage: parseInt(page),
        orderBy: orderBy,
        viewPerPage: parseInt(view),
      },
      () => this.fetchData()
    );
  }

  fetchData = (from = '') => {
    this.fetchItemsList(from);
  };

  fetchItemsList(from) {
    return restRequest(
      'get',
      `${this.unMappedItemsEndPoint}?view=${this.state.viewPerPage}&page=${this.state.currentPage}`
    )
      .then((res) => {
        if (res?.woocomItems?.data.length === 0) {
          !from && this.props.handleToast('Please sync item first', 'error');
          this.props.history.push('/un-sync-orders');
        }
        this.setState(
          {
            itemsInfo: res?.woocomItems?.data || [],
            data: this.prepareDataForTable(res.woocomItems.data),
            totalPage: res.woocomItems.last_page,
            totalRecords: res.woocomItems.total,
            currentStore: res?.store || {},
          },
          () => {
            let defaultSelectedOptins = this.state.itemsInfo.map((v, i) => {
              return {
                label: 'Add New',
                value: 0,
                isdisabled: false,
                rowIndex: i,
                mainrowId: v.id,
                sync_item_name: v.name,
                inventory_type: 'inventory',
                market_place_item_id: v.market_place_item_id,
                woocommerceStockValue: v.stock,
                imsStockValue: null,
                totalStockValue: 0,
                stockStatus: { label: 'custom', value: 'custom_stock' },
              };
            });
            this.setState(
              { configItemData: { selectedData: defaultSelectedOptins } },
              () => {
                this.fetchItemsDropdownList();
              }
            );
          }
        );
      })
      .catch((error) => {
        this.props.handleToast(error, 'error');
        this.dataNotFound();
        this.props.history.push('/un-sync-orders');
      });
  }

  fetchItemsDropdownList() {
    const preappendAddNew = {
      name: 'Add New',
      label: 'Add New',
      value: 0,
      id: 0,
      isdisabled: false,
      inventory_type: 'inventory',
    };
    restRequest('get', `${this.imsItemsEndPoint}`)
      .then((responce) => {
        this.setState({ stock_type: responce.stock_preference });
        if (responce?.ims_items.length) {
          let ims_itemsClone = responce?.ims_items.map((ims_item_single) => {
            return {
              ...ims_item_single,
              isdisabled: false,
              label: ims_item_single.name,
              value: ims_item_single.id,
              preference: responce?.stock_preference,
            };
          });
          this.setState(
            {
              configItemData: {
                ...this.state.configItemData,
                ims_items: [preappendAddNew, ...ims_itemsClone],
              },
              configImsItemsData: [preappendAddNew, ...ims_itemsClone],
            },
            () => {
              this.setState({ loading: false });
            }
          );
        }
      })
      .catch((error) => {
        this.props.handleToast(error, 'error');
        this.dataNotFound();
      });
  }

  parseQueryString() {
    const {
      orderBy = 'asc',
      page = 1,
      view = 20,
    } = queryString.parse(this.props.location?.search) || {};
    return { orderBy, page, view };
  }

  // Callbacks function
  selectedDataUpdateHandler = (selectedSingleItem, selectedData, rowId) => {
    let cloneSelectedData = [...selectedData];
    cloneSelectedData = cloneSelectedData?.map((singleItem, index) => {
      if (singleItem.rowIndex === rowId) {
        return {
          ...singleItem,
          value: selectedSingleItem.value,
          name: selectedSingleItem.name,
          label: selectedSingleItem.name,
          imsStockValue:
            selectedSingleItem.preference === 'physical_stock'
              ? selectedSingleItem.physical_stock
              : selectedSingleItem.accounting_stock,
          inventory_type: selectedSingleItem.inventory_type,
          totalStockValue: 0,
          stockStatus: { label: 'custom', value: 'custom_stock' },
        };
      } else {
        return singleItem;
      }
    });

    return cloneSelectedData;
  };

  imsItemsUpdateHandler = (selectedSingleItem, ims_items, rowId) => {
    let cloneIms_items = [...ims_items];
    cloneIms_items = cloneIms_items.map((singleIms_itemsList) => {
      if (singleIms_itemsList.id === selectedSingleItem.value) {
        return {
          ...singleIms_itemsList,
          isdisabled: true,
        };
      } else {
        return singleIms_itemsList;
      }
    });
    return cloneIms_items;
  };

  addNewNullHandler = (selectedSingleItem, selectedData, rowId) => {
    let cloneSelectedData = [...selectedData];
    cloneSelectedData = cloneSelectedData?.map((singleItem, index) => {
      if (singleItem.rowIndex === rowId) {
        return {
          ...singleItem,
          value: 0,
          name: 'Add New',
          label: 'Add New',
          totalStockValue: 0,
          stockStatus: { label: 'custom', value: 'custom_stock' },
        };
      } else {
        return singleItem;
      }
    });

    return cloneSelectedData;
  };

  imsItemsDisablityHandler = () => {
    const { selectedData } = this.state.configItemData;
    let cloneIms_items = [...this.state.configImsItemsData];
    let notZeroValues = selectedData.filter((item) => item.value !== 0);
    if (notZeroValues.length) {
      let matchedValues = [];
      notZeroValues.forEach((val) => {
        matchedValues.push(val.value);
      });
      if (matchedValues.length) {
        cloneIms_items = cloneIms_items.map((item) => {
          if (matchedValues.includes(item.value)) {
            return item;
          } else {
            return {
              ...item,
              isdisabled: false,
            };
          }
        });
        this.setState({
          configImsItemsData: cloneIms_items,
        });
      }
    } else {
      cloneIms_items = cloneIms_items.map((item) => {
        return {
          ...item,
          isdisabled: false,
        };
      });
      this.setState({
        configImsItemsData: cloneIms_items,
      });
    }
  };

  configItemDataUpdateHandler = (selectedSingleItem, rowId) => {
    const { selectedData } = this.state.configItemData;
    if (selectedSingleItem !== null) {
      const newSelectedData = this.selectedDataUpdateHandler(
        selectedSingleItem,
        selectedData,
        rowId
      );
      const newImsItems = this.imsItemsUpdateHandler(
        selectedSingleItem,
        this.state.configImsItemsData,
        rowId
      );
      this.setState(
        {
          configItemData: {
            ...this.state.configItemData,
            selectedData: newSelectedData,
          },
          configImsItemsData: newImsItems,
        },
        () => {
          this.imsItemsDisablityHandler();
        }
      );
    } else {
      const newSelectedData = this.addNewNullHandler(
        selectedSingleItem,
        selectedData,
        rowId
      );
      this.setState(
        {
          configItemData: {
            ...this.state.configItemData,
            selectedData: newSelectedData,
          },
        },
        () => {
          this.imsItemsDisablityHandler();
        }
      );
    }
  };

  stockStatusChangeHandler = (val, rowId) => {
    const { selectedData } = this.state.configItemData;
    let cloneSelected = [...selectedData];
    cloneSelected = cloneSelected.map((item) => {
      if (item.rowIndex === rowId) {
        if (val.value === 'woocom_item_stock' || val.value === 'shopify_item_stock') {
          return {
            ...item,
            stockStatus: { label: val.label, value: val.value },
            totalStockValue:
              item.woocommerceStockValue < 0 ? 0 : item.woocommerceStockValue,
          };
        } else if (val.value === 'ims_item_stock') {
          return {
            ...item,
            stockStatus: { label: val.label, value: val.value },
            totalStockValue: item.imsStockValue < 0 ? 0 : item.imsStockValue,
          };
        } else if (val.value === 'ims_woocom_stock') {
          let plusBothValues = item.imsStockValue + item.woocommerceStockValue;
          return {
            ...item,
            stockStatus: { label: val.label, value: val.value },
            totalStockValue: plusBothValues < 0 ? 0 : plusBothValues,
          };
        } else {
          return {
            ...item,
            stockStatus: { label: val.label, value: val.value },
            totalStockValue: 0,
          };
        }
      } else {
        return item;
      }
    });
    this.setState({
      configItemData: {
        ...this.state.configItemData,
        selectedData: cloneSelected,
      },
    });
  };

  stockInputChangeHandler = (val, rowId) => {
    const { selectedData } = this.state.configItemData;
    let cloneSelected = [...selectedData];

    let totalStockVal;
    if (val >= 0) {
      totalStockVal = parseInt(val);
    } else if (val < 0) {
      totalStockVal = -val;
    } else {
      totalStockVal = val;
    }

    cloneSelected = cloneSelected.map((item) => {
      if (item.rowIndex === rowId) {
        return {
          ...item,
          totalStockValue: totalStockVal,
        };
      } else {
        return item;
      }
    });

    this.setState({
      configItemData: {
        ...this.state.configItemData,
        selectedData: cloneSelected,
      },
    });
  };

  // callbacks function ending

  prepareDataForTable = (data) => {
    let modifiedData = data.map((item, index) => {
      let obj = {
        id: item.id,
      };
      this.tableColumns.map((customItems) => {
        if (customItems.HeaderName === 'asyncPaginate') {
          obj['Ims Items'] = {
            getValue: () => {
              const { configItemData } = this.state;
              return (
                <>
                  <AsyncSelectPaginate
                    data={configItemData}
                    imsItems={this.state.configImsItemsData}
                    fullRowId={index}
                    onChange={this.configItemDataUpdateHandler}
                  />
                </>
              );
            },
          };
        } else if (customItems.HeaderName === 'stocks') {
          obj['Stock Items'] = {
            getValue: () => {
              const { configItemData } = this.state;
              const { storeType = 'woocom' } = this.state;
              return (
                <>
                  <AsyncSelectStock
                    data={configItemData}
                    fullRowId={index}
                    storeType={storeType}
                    onChange={this.stockStatusChangeHandler}
                    inputChanage={this.stockInputChangeHandler}
                  />
                </>
              );
            },
          };
        } else {
          obj[customItems.HeaderName] = {
            id: customItems.value,
            getValue: function () {
              return item[customItems.value];
            },
          };
        }
      });
      return obj;
    });
    return modifiedData;
  };
  renderTable() {
    const { data, bulkSelectedData, loading, stock_type } = this.state;

    return (
      <ListTable
        data={data}
        selectedData={bulkSelectedData}
        tableRowColor={true}
        linkTo="/sync-history"
        loading={loading}
        isBlur="isActive"
        generateRoute={this.generateRoute}
        handleCheckAll={this.handleCheckAll}
        handleCheckSingle={this.handleCheckSingle}
        moduleName="sync-history"
        totalRecords={this.state.totalRecords}
        tableClass="ecom-sync-table"
        stock_type={stock_type}
        className="ecom-sync-table unsync-table"
        mapping
      />
    );
  }

  mappedDataHandler = () => {
    if (this.state.selectedSyncIems.length <= 0) return;
    this.setState({ mappedBtnLoader: true }, () => {
      const { selectedSyncIems, configItemData } = this.state;
      const { selectedData = [] } = configItemData;
      let data = [];
      selectedSyncIems.forEach((myItem) => {
        const findObj = selectedData.filter((item) => item.rowIndex === myItem);
        if (findObj.length) {
          findObj.forEach((singleData) => {
            let newObj = {
              inventory_type: singleData.inventory_type,
              item_id: singleData.value,
              market_place_item_id: singleData.market_place_item_id,
              rowIndex: singleData.rowIndex,
              stock: singleData.totalStockValue,
              stock_status: singleData?.stockStatus?.value,
              sync_item_id: singleData.mainrowId,
              sync_item_name: singleData.sync_item_name,
            };
            data.push(newObj);
          });
        }
      });
      const payload = {
        store_id: this.storeId,
        items: data,
      };
      const { storeType = 'woocom' } = this.state;
      if (data.length) {
        restRequest('post', `${storeType}/items/associate_items`, payload)
          .then((res) => {
            this.props.handleToast(res.message, 'success');
            this.setState({
              mappedBtnLoader: false,
              bulkSelectedData: [],
              selectedSyncIems: [],
            });
            this.fetchData('same');
          })
          .catch((error) => {
            this.setState({ mappedBtnLoader: false });
            this.props.handleToast(error.message, 'error');
          });
      }
    });
  };

  render() {
    const {
      history: {
        location: { state },
      },
    } = this.props;
    const { storeType = 'woocom' } = this.state;
    return (
      <>
        <div className="close_icon close-icon-pos so_cross_icon">
          <MdClose
            onClick={() => {
              this.props.history.push({
                pathname: '/un-sync-orders',
                state: state,
              });
            }}
          />
        </div>
        <div
          className={classNames('sync-history-dropdown', 'position-relative')}
        >
          <div className={classNames('sync-history-left')}>
            <div className={classNames('sync-logo')}>
              {storeType === 'woocom' && (
                <img
                  src="https://i.ibb.co/JzyLVF2/Woo-Commerce-logo-Woo-Commerce.png"
                  alt="Woo-Commerce-logo"
                />
              )}
              {storeType === 'shopify' && (
                <SiShopify size={20} color="#95BE46" />
              )}
              &nbsp;/&nbsp;
              <span className="store-name">
                {this.state.currentStore?.name ||
                  this.state.currentStore?.store_url}
              </span>
            </div>
          </div>

          <div className="mappBtn  map-btn-pagination">
            <CommonButton
              btnText="Map"
              disabled={
                this.state.mappedBtnLoader ||
                this.state.selectedSyncIems.length <= 0
              }
              btnLoading={this.state.mappedBtnLoader}
              className={classNames('btn-style btn-green br-2 btnloader')}
              clickHandler={() => this.mappedDataHandler()}
            />

            <div className={classNames()}>
              Total selected: {this.state.selectedSyncIems.length}
            </div>
          </div>
        </div>

        {this.renderTable()}
      </>
    );
  }
}
export default HeaderWrapper(UnSycnItemsMapping, {
  name: 'Sync History',
  tableIcon: 'general_module_icon',
  Icon: NewItemIcon,
  baseUrl: 'items',
  deleteName: 'Item',
  buttonUrl: '/items/add',
  buttonLabel: 'New Item',
  showDetailsComp: false,
  bypassLoadingState: false,
  permissionName: 'item',
  syncHistory: true,
  pagination: false,
  showNewButton: false,
});
