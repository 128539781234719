import React, { useState } from 'react';

import getDate from '../../../../Helpers/Date/GetDate';
import CustomModal from '../../../../Components/CustomModal/CustomModal';
import {
  NewInvoiceIcon,
  NewSalesReturnIcon,
  NewSalesOrderIcon,
} from '../../../../Assets/Navigation/NavigationIcons';
import AddRetrunReceive from '../../../../Assets/General/AddRetrunReceive';
import InvoiceDetails from '../../../../Pages/SalesOrder/Invoices/InvoiceDetails/index';
import SalesReturnDetails from '../../../../Pages/SalesReturn/SalesReturnDetails/index';
import SalesOrderDetails from '../../../../Pages/SalesOrder/SalesOrderDetails/index';
import ReturnReceiveDetails from '../../../../Components/Form/ReturnReceive/ReturnReceiveDetails';

const PackageInfo = ({
  info: {
    creditNoteDate,
    salesOrderNo,
    InvoiceNo,
    InvoiceID,
    salesOrderID,
    ReturnReceiveNo,
    ReturnReceiveID,
    SaleReturnNo,
    SaleReturnID,
    // customerName,
    // customerID,
    // history,
    remaining_credits,
    totalInvoice,
    currency,
    // totalQuantity = 0,
  },
  inModal = false,
}) => {
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [showSRModal, setShowSRModal] = useState(false);
  const [showSOModal, setShowSOModal] = useState(false);
  const [showReturnReceiveViewModal, setShowReturnReceiveViewModal] =
    useState(false);
  function viewInvoiceModal() {
    return (
      <CustomModal
        Icon={NewInvoiceIcon}
        showModal={showInvoiceModal}
        title="View Invoice "
        className='popup-height'
        modaltitle="inventory_modal_title"
        renderActions={false}
        size="large"
        onClose={() => setShowInvoiceModal(false)}
        linkTo={`invoices/${InvoiceID}`}
      >
        <InvoiceDetails id={InvoiceID} inModal={true} />
      </CustomModal>
    );
  }

  function viewSaleReturnModal() {
    return (
      <CustomModal
        showModal={showSRModal}
        title="View Sales Return"
        renderActions={false}
        size="large"
        Icon={NewSalesReturnIcon}
        linkTo={`salesreturns/${SaleReturnID}`}
        onClose={() => setShowSRModal(false)}
      >
        <SalesReturnDetails id={SaleReturnID} inModal={true} />
      </CustomModal>
    );
  }
  function viewSaleOrderModal() {
    return (
      <CustomModal
        showModal={showSOModal}
        title="View Sales Order"
        renderActions={false}
        size="large"
        Icon={NewSalesOrderIcon}
        linkTo={`salesorders/${salesOrderID}`}
        onClose={() => setShowSOModal(false)}
      >
        <SalesOrderDetails id={salesOrderID} inModal={true} />
      </CustomModal>
    );
  }

  function renderReturnReceiveModal() {
    return (
      <CustomModal
        showModal={showReturnReceiveViewModal}
        title={`Return Receive | ${ReturnReceiveNo}`}
        Icon={AddRetrunReceive}
        renderActions={false}
        size="medium"
        onClose={() => setShowReturnReceiveViewModal(false)}
        showActionInMenu={false} // true for make print pdf mail and delete option enable
        classNames="return-receive-popup"
      >
        <ReturnReceiveDetails inModal type="add" id={ReturnReceiveID} />
      </CustomModal>
    );
  }

  return (
    <>
      {viewInvoiceModal()}
      {viewSaleReturnModal()}
      {viewSaleOrderModal()}
      {renderReturnReceiveModal()}

      <div className="section-details--info position-relative mtb cn-summary-info">
        {creditNoteDate && (
          <div className="credit_feild">
            <div className="section-details--info-title semi-bold">
              Credit Note Date
            </div>
            <div className="">{getDate(creditNoteDate)}</div>
          </div>
        )}
        <hr />

        {salesOrderID && salesOrderNo && (
          <>
            <div className="credit_feild">
              <div className="section-details--info-title semi-bold">
                Sale Order No.
              </div>
              {inModal ? (
                <div className="float-right">{salesOrderNo} </div>
              ) : (
                <div
                  onClick={() => setShowSOModal(true)}
                  className="float-right colorBlue pointer"
                >
                  {salesOrderNo}{' '}
                </div>
              )}
            </div>
            <hr />
          </>
        )}
        {SaleReturnID && SaleReturnNo && (
          <>
            <div className="credit_feild">
              <div className="section-details--info-title semi-bold">
                Sale Return No.
              </div>
              {inModal ? (
                <div className="float-right">{SaleReturnNo} </div>
              ) : (
                <div
                  onClick={() => setShowSRModal(true)}
                  className="float-right colorBlue pointer"
                >
                  {SaleReturnNo}{' '}
                </div>
              )}
            </div>
            <hr />
          </>
        )}
        {InvoiceID && InvoiceNo && (
          <>
            <div className="credit_feild">
              <div className="section-details--info-title semi-bold">
                Invoice No.
              </div>
              {inModal ? (
                <div className="float-right">{InvoiceNo} </div>
              ) : (
                <div
                  onClick={() => setShowInvoiceModal(true)}
                  className="float-right colorBlue pointer"
                >
                  {InvoiceNo}{' '}
                </div>
              )}
            </div>

            <hr />
          </>
        )}
        {ReturnReceiveNo && (
          <>
            <div className="credit_feild">
              <div className="section-details--info-title semi-bold">
                Return Receive No
              </div>
              {inModal ? (
                <div className="semi-bold-amount">{ReturnReceiveNo}</div>
              ) : (
                <div
                  onClick={() => setShowReturnReceiveViewModal(true)}
                  className="float-right colorBlue pointer"
                >
                  {ReturnReceiveNo}{' '}
                </div>
              )}
            </div>
            <hr />
          </>
        )}

        {totalInvoice && (
          <>
            <div className="credit_feild">
              <div className="section-details--info-title semi-bold">
                Invoice Amount
              </div>
              <div className="semi-bold-amount">
                {currency.symbol}
                {totalInvoice.toFixed(2)}
              </div>
            </div>
            <hr />
          </>
        )}

        <>
          <div className="credit_feild">
            <div className="section-details--info-title semi-bold">
              Total Issued Credits
            </div>
            <div className="semi-bold-amount">
              {currency.symbol}
              {parseFloat(totalInvoice - remaining_credits).toFixed(2)}
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default PackageInfo;
