import React, { Component } from 'react';
import PaymentsPage from '../../Components/Payments/Payments';

class PaymentFailed extends Component {
  // constructor(props) {
  //   super(props)
  // }

  componentDidMount() {
    //this.redirect();
  }
  redirect() {
    setTimeout(() => {
      window.location.href = '/dashboard';
    }, 5000);
  }

  render() {
    return <PaymentsPage paymentResponse="fail" history={this.props.history} />;
  }
}

export default PaymentFailed;
