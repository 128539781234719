import React, { useEffect } from 'react';
import AdjustmentForm from '../../../Components/Form/ItemForm/AdjustmentForm';
import FormLayout from '../../../Components/Layout/FormLayout';
import { NewItemAdjustmentIcon } from '../../../Assets/Navigation/NavigationIcons';

const AddAdjustments = ({ handleToast }) => {
  useEffect(() => {
    document.title = 'Item Adjustments';
  }, []);

  return (
    <FormLayout
      title="Item Adjustments"
      Icon={NewItemAdjustmentIcon}
      closeUrl="/item-adjustments"
      iconstyle="general_module_icon"
      layoutBoxClass="uiux-form-redesign"
    >
      <AdjustmentForm type="add" title="Create Adjustments" handleToast={handleToast} />
    </FormLayout>
  );
};

export default AddAdjustments;
