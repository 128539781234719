import React, { Component } from 'react';
import queryString from 'query-string';
import classNames from 'classnames';
import HeaderWrapper from '../../../../Components/HeaderWrapper/HeaderWrapper';
import { NewItemIcon } from '../../../../Assets/Navigation/NavigationIcons';
import ItemDetails from '../../../../Pages/Items/ItemDetails';

import {
  restRequest,
  ListTable,
  MdClose,
  setObjInLS,
  checkError,
  AccessDenied,
} from './';
class SyncItemDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      currency: '',
      totalPage: 1,
      currentPage: 1,
      viewPerPage: 20,
      totalRecords: 20,
      currentFilter: 'all',
      orderBy: 'asc',
      sortFilter: 'created_at',
      search: '',
      accessDenied: false,
      currentStore: {},
    };
    this.getID = this.getID.bind(this);
  }

  componentDidMount() {
    document.title = 'Sync Woo Commerce Store History';
    this.fetchDataWithQueryString();
  }

  componentDidUpdate() {
    this.handleOnUpdateQueryString();
  }

  fetchDataWithQueryString = () =>
    this.handleUpdateState(this.parseQueryString());

  handleOnUpdateQueryString() {
    const { filter, page, sort, orderBy, view } = this.parseQueryString();
    if (
      filter !== this.state.currentFilter ||
      sort !== this.state.sortFilter ||
      orderBy !== this.state.orderBy ||
      page !== this.state.currentPage ||
      view !== this.state.viewPerPage
    ) {
      this.handleUpdateState({
        filter,
        sort,
        orderBy,
        page: parseInt(page),
        view: parseInt(view),
      });
    }
  }

  handleUpdateState = ({ filter, page, sort, orderBy, view }) =>
    this.setState(
      {
        currentFilter: filter,
        sortFilter: sort,
        orderBy: orderBy,
        currentPage: parseInt(page),
        viewPerPage: parseInt(view),
      },
      () => this.fetchData()
    );

  parseQueryString() {
    const query = queryString.parse(this.props.location.search);
    let filter = query.filter || 'all';
    let sort = query.sort || 'created_at';
    let orderBy = query.orderBy || 'asc';
    let page = parseInt(query.page) || 1;
    let view = parseInt(query.view) || 20;
    let search = query.search || '';
    return {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
    };
  }

  fetchData = async () => {
    this.setState({ loading: true });
    await this.fetchItemsList();
  };

  fetchItemsList() {
    sessionStorage.setItem('view', parseInt(this.state.viewPerPage));
    sessionStorage.setItem('current_page', parseInt(this.state.currentPage));
    const { currentFilter, currentPage, sortFilter, orderBy, viewPerPage } =
      this.state;
    return restRequest(
      'get',
      `woocom/syncHistory/${this.props.match.params.id}?filter=${currentFilter}&page=${currentPage}&sort_by=${sortFilter}&order_by=${orderBy}&view=${viewPerPage}`
    )
      .then((res) => {
        this.setState({
          data: this.prepareDataForTable(
            res?.history?.data,
            res?.currency?.symbol
          ),
          totalPage: res?.history?.last_page,
          totalRecords: res?.history?.total,
          currency: res?.currency?.symbol,
          currentStore: res?.store,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          accessDenied: checkError(error, this.props.handleToast),
        });
      });
  }

  prepareDataForTable = (data, symbol) =>
    data.map((item) => ({
      id: item.id,
      'Items Name': {
        id: 'itemName',
        // sortable: true,
        getValue: function () {
          const itemResponse = JSON.parse(item.response);
          return itemResponse.name;
        },
      },
      SKU: {
        id: 'sku',
        // sortable: true,
        getValue: function () {
          return item[this.id];
        },
      },
      'Selling Price': {
        id: 'sellingPrice',
        // sortable: true,
        getValue: function () {
          const itemResponse = JSON.parse(item.response);
          const price = `${symbol}${itemResponse.price}`;
          return price;
        },
      },
      'Opening Stock': {
        id: 'openingStock',
        // sortable: true,
        getValue: function () {
          const itemResponse = JSON.parse(item.response);
          return itemResponse.stock;
        },
      },
    }));

  renderTable() {
    const { data, loading, accessDenied } = this.state;
    if (accessDenied) {
      return <AccessDenied type="section" />;
    }
    return (
      <>
        <div
          className={classNames('sync-history-dropdown', 'position-relative', 'sync-item-single')}
        >
          <div className={classNames('sync-history-left')}>
            <div className={classNames('ecom-header-logo')}>
              <img
                src="https://i.ibb.co/JzyLVF2/Woo-Commerce-logo-Woo-Commerce.png"
                alt="Woo-Commerce-logo-Woo-Commerce"
              />&nbsp;/&nbsp;
              <span className='store-name'>
                {this.state.currentStore?.name ||
                  this.state.currentStore?.store_url}
              </span>
            </div>
          </div>
          <div>
            <p>
              {/* Total count of items <b>{this.state.totalRecords}</b> */}
            </p>
          </div>
          <div
            className={classNames('close-icon', 'close_icon', 'close-icon-pos')}
            onClick={() =>
              this.props.history.push(
                `/integrations/sync-history/${this.state.currentStore?.id}`
              )
            }
          >
            <MdClose />
          </div>
        </div>
        <ListTable
          data={data}
          linkTo="/sync-history"
          loading={loading}
          isBlur="isActive"
          getID={this.getID}
          moduleName="sync-history"
          totalRecords={this.state.totalRecords}
          tableClass="ecom-sync-table"
          hasCheckbox={false}
          sync
          mapping
          className='sync-item-table ecom-sync-item'
        />
      </>
    );
  }

  getID(prevId, id, nextId) {
    let obj = {
      id,
      prevId: prevId || null,
      nextId: nextId || null,
      moduleName: 'items',
      urlPath: window.location.pathname,
      queryParam: window.location.search,
    };
    setObjInLS('module', obj);
  }

  render() {
    return <>{this.renderTable()}</>;
  }
}

export default HeaderWrapper(
  SyncItemDetails,
  {
    name: 'Sync History',
    tableIcon: 'general_module_icon',
    Icon: NewItemIcon,
    baseUrl: 'items',
    deleteName: 'Item',
    buttonUrl: '/items/add',
    buttonLabel: 'New Item',
    showDetailsComp: false,
    bypassLoadingState: false,
    permissionName: 'item',
    syncHistory: true,
  },
  ItemDetails
);
