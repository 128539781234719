import React, { useState } from 'react';
import { CustomModal } from '../../Components';
import { wooComItemConfirmDialogContent } from '../../static';
import { ImInfo } from '../Icons';
import {
  Button as CommonButton,
  AsyncSelectPaginate,
  AsyncSelectStock,
  UnTouchLayer,
} from '../index';
import classNames from 'classnames';
import Loader from '../../Components/Loader/Loader';
import './tempitems.css';

const confirmItemStockData = [
  {
    label: 'Add New',
    value: 0,
    isdisabled: false,
    inventory_type: 'noninventory',
    imsStockValue: 0,
    totalStockValue: 0,
    stockStatus: { label: 'custom', value: 'custom_stock' },
    toggletype: 'confirm',
  },
];

const TempItemMappedModal = ({
  showModal = false,
  loading = false,
  closeModal = () => {},
  modalTitle = 'Map Item',
  confirmBtnClicks = () => {},
  itemData = {},
  itemsList = [],
  btnLoader = false,
}) => {
  const [toggle, setToggle] = useState('');
  //const [firstItem] = useState(itemsList[0]);
  const [configItemData, setConfigItemData] = useState({
    selectedData: [],
  });
  // variable which is selected first dropdown from Asycn paginate
  let firstTimeSelectedData = itemsList
    ?.map((item, i) => {
      if (i === 0) {
        return {
          label: item.name,
          value: item.id,
          isdisabled: false,
          inventory_type: item.inventory_type,
          imsStockValue: item.ims_stock,
          totalStockValue: 0,
          stockStatus: { label: 'custom', value: 'custom_stock' },
          toggletype: 'exist',
        };
      }
    })
    .filter((notUndefined) => notUndefined !== undefined);

  // Method calls on Merge Button Click
  const mergeBtnHandler = () => {
    const { selectedData = [] } = configItemData;
    let cloneSelectedData = selectedData[0];
    confirmBtnClicks({
      id: itemData.id,
      type: 'exist',
      store_id: itemData.store_id,
      stockSelect: cloneSelectedData?.stockStatus?.value,
      stockSelectValue: cloneSelectedData?.totalStockValue,
      sync_item_name: itemData.name,
      market_place_item_id: itemData.market_place_item_id,
      storeType: itemData.storeType,
      mappedItemData: {
        inventory_type: cloneSelectedData.inventory_type,
        id: cloneSelectedData.value,
      },
    });
  };

  // Method run onchange of Asyn Paginate Option
  const asyncSelectPaginateHandler = (obj, fullRowId) => {
    if (obj) {
      const { selectedData = [] } = configItemData;
      let cloneSelectedData = { ...selectedData[fullRowId] };
      cloneSelectedData = {
        ...cloneSelectedData,
        value: obj.id,
        label: obj.name,
        inventory_type: obj.inventory_type,
        imsStockValue: obj.ims_stock,
        totalStockValue: 0,
        stockStatus: { label: 'custom', value: 'custom_stock' },
      };
      setConfigItemData({
        selectedData: [cloneSelectedData],
      });
    } else {
      setConfigItemData({
        // selectedData: firstTimeSelectedData,
        selectedData: {},
      });
    }
  };

  // Method run onChange of Stock Status
  const configItemStockHandler = (val, fullRowId) => {
    const { selectedData = [] } = configItemData;
    let cloneSelectedData = selectedData[fullRowId];
    let totalStockValue = 0;
    if (val.value === 'woocom_item_stock') {
      totalStockValue = itemData.store_stock;
    } else if (val.value === 'shopify_item_stock') {
      totalStockValue = itemData.store_stock;
    } else if (val.value === 'ims_item_stock') {
      totalStockValue = cloneSelectedData.imsStockValue;
    } else if (val.value === 'ims_woocom_stock') {
      totalStockValue = cloneSelectedData.imsStockValue + itemData.store_stock;
    } else if (val.value === 'ims_shopify_stock') {
      totalStockValue = cloneSelectedData.imsStockValue + itemData.store_stock;
    }
    cloneSelectedData = {
      ...cloneSelectedData,
      stockStatus: val,
      totalStockValue: totalStockValue,
    };
    setConfigItemData({
      selectedData: [cloneSelectedData],
    });
  };

  // Method Run onChange of Input field value
  const configItemStockValueHandler = (val, rowId) => {
    const { selectedData = [] } = configItemData;
    let cloneSelectedData = selectedData[rowId];
    cloneSelectedData = {
      ...cloneSelectedData,
      totalStockValue: isNaN(val) ? 0 : parseInt(val),
    };
    setConfigItemData({
      selectedData: [cloneSelectedData],
    });
  };

  // show this data when user click on Link to Existing Item
  const LinkToExistingItem = () => {
    const data = wooComItemConfirmDialogContent[1];
    return (
      <div className={classNames('sync-settings-popup')}>
        <p className="unconfirmed-msg">{data.content[0]}</p>
        <div>
          <div>
            <p>
              Unmapped Item:{' '}
              <b>
                {itemData?.sku && `[${itemData?.sku}] - `} {`${itemData?.name}`}
              </b>
            </p>
            <p>Select Master Item:</p>
          </div>
          <div className={classNames('d-flex', 'existing_items')}>
            <div className="select-product">
              <AsyncSelectPaginate
                data={configItemData}
                fullRowId={0}
                imsItems={itemsList}
                onChange={asyncSelectPaginateHandler}
              />
            </div>
            <div className="select-brand">
              {configItemData.selectedData.length && (
                <AsyncSelectStock
                  data={configItemData}
                  fullRowId={0}
                  storeType={itemData.storeType}
                  onChange={configItemStockHandler}
                  inputChanage={configItemStockValueHandler}
                />
              )}
            </div>
          </div>
          <div className={classNames('ecom-action-btns', 'action-btns')}>
            {/* <CommonButton
              btnText={data.btnText}
              className={classNames('text-blue')}
              clickHandler={mergeBtnHandler}
              disabled={!configItemData.selectedData.length || btnLoader}
              btnLoading={btnLoader}
              tolTip
              tolTipText="Link to existing item"
            /> */}
          </div>
        </div>
      </div>
    );
  };

  // Method Run when user mapped item in case of confirm
  const confirmBtnHandler = () => {
    const { selectedData = [] } = configItemData;
    let cloneSelectedData = selectedData[0];
    confirmBtnClicks({
      id: itemData.id,
      type: 'confirm',
      store_id: itemData.store_id,
      stockSelect: cloneSelectedData?.stockStatus?.value,
      stockSelectValue: cloneSelectedData?.totalStockValue,
      sync_item_name: itemData.name,
      market_place_item_id: itemData.market_place_item_id,
      storeType: itemData.storeType,
    });
  };

  // show this data when user click on Confirm Button
  const confirmItemHandler = () => (
    <>
      <AsyncSelectStock
        data={configItemData}
        storeType={itemData.storeType}
        fullRowId={0}
        onChange={configItemStockHandler}
        inputChanage={configItemStockValueHandler}
      />

      {/* <CommonButton
        btnText="Confirm"
        className={classNames('text-blue')}
        clickHandler={confirmBtnHandler}
        disabled={btnLoader}
        btnLoading={btnLoader}
        tolTip
      /> */}
    </>
  );

  return (
    <>
      <CustomModal
        size="medium"
        className={classNames('classname', 'medium-modal')}
        showModal={showModal}
        title={modalTitle}
        renderActions={false}
        onClose={() => {
          closeModal();
          setToggle('');
        }}
      >
        <>
          {loading ? (
            <Loader />
          ) : (
            <UnTouchLayer touch={btnLoader}>
              <div
                style={{
                  textAlign: 'center ',
                }}
              >
                {/* <div style={{ width: '95%', backgroundColor: '#ffeeda' }}> */}
                {toggle !== 'link' && (
                  <div>
                    <ImInfo
                      style={{
                        fontSize: '50px',
                        color: '#EECD99',
                        marginBottom: '5px',
                      }}
                    />
                    <p
                      style={{
                        marginBottom: '0',
                        fontSize: '16px',
                        fontWeight: '500',
                      }}
                    >
                      This item from is yet to be confirmed!
                    </p>
                    <p>
                      You can either confirm the addition of this item or link
                      it with an existing item in Inventory.
                    </p>
                  </div>
                )}
                {toggle === 'link' ? (
                  <div
                    style={{
                      paddingTop: '2rem',
                    }}
                  >
                    {LinkToExistingItem()}
                  </div>
                ) : toggle === 'confirm' ? (
                  <div className="confirm-item">{confirmItemHandler()}</div>
                ) : null}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: '15px 0 0',
                  }}
                >
                  {itemsList.length ? (
                    <button
                      className={classNames(
                        'btn-style',
                        'btn-gray',
                        'br-2',
                        'mr-15'
                      )}
                      onClick={() => {
                        setConfigItemData({
                          selectedData: firstTimeSelectedData,
                        });
                        if (!toggle) {
                          setToggle('link');
                        } else {
                          setToggle('');
                        }
                      }}
                    >
                      {toggle === 'link' || toggle === 'confirm'
                        ? 'Back'
                        : ' Link to Existing Item'}
                    </button>
                  ) : null}
                  <button
                    className={classNames('btn-style', 'btn-green', 'br-2')}
                    onClick={
                      toggle === 'confirm'
                        ? confirmBtnHandler
                        : toggle === 'link'
                        ? mergeBtnHandler
                        : () => {
                            setConfigItemData({
                              selectedData: confirmItemStockData,
                            });
                            if (toggle === 'confirm') {
                              setToggle('');
                            } else {
                              setToggle('confirm');
                            }
                          }
                    }
                    disabled={
                      toggle === 'confirm'
                        ? btnLoader
                        : (toggle === 'link' &&
                            !configItemData.selectedData.length) ||
                          btnLoader
                    }
                  >
                    {toggle === 'confirm' || !toggle
                      ? 'Confirm'
                      : toggle === 'link' && 'Merge'}
                  </button>
                </div>
              </div>
            </UnTouchLayer>
          )}
        </>
      </CustomModal>
    </>
  );
};

export default TempItemMappedModal;
