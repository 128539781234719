import React from 'react';
import './Address.css'

import {
  UserIcon,
  LocationIcon,
  PhoneIcon,
  CompanyOffice,
} from '../../../Assets/Contact/OverviewContact';
import {getAddress} from "../../../Pages/Organization/ChooseOrganization";

const UnSynOrderAddress = ({ addressData = {} }) => {
  const {
    type = 'Shipping Address',
    userName = '',
    company = '',
    address = '',
    country = '',
    state = '',
    zipCode = '',
    city = '',
    phone = '',
  } = addressData;
  return (
    <>

      <div className="more_details float-left w-50">
        <span style={{ fontWeight: '500' }}>{type}</span>
        <div className="left_sec">
          <ul className="float-left w-100">
            {userName && (
              <li className="details_row no-space">
                <UserIcon customWidth="20" customHeight="15" />{' '}
                <span className="details_data">{userName}</span>
              </li>
            )}
            {company && (
              <li className="details_row no-space">
                <CompanyOffice customWidth="20" customHeight="15" />
                <span className="details_data">{company}</span>
              </li>
            )}
            <li className="details_row no-space">
              <LocationIcon customWidth="20" customHeight="15" />
              <span className="details_data">
                {getAddress(address) && <span>{getAddress(address)}</span>}
                <span>
                  {!!city && `${city},`}{' '}
                  {!!state && `${state},`}
                  {!!zipCode && `${zipCode},`}
                  {!!country && `${country} `}{' '}
                  </span>
              </span>
            </li>
            {phone && (
              <li className="details_row no-space">
                <PhoneIcon customWidth="20" customHeight="15" />
                <span className="details_data"> {phone}</span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default UnSynOrderAddress;
