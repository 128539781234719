import React, { Component, Fragment } from 'react';

import { restRequest } from '../../../../../../Helpers/RequestHelper';
import getDate from '../../../../../../Helpers/Date/GetDate';
import CreditNoteIcon from '../../../../../../Assets/General/CreditNoteIcon';
import Loader from '../../../../../../Components/Loader/Loader';
import OverlayLoader from '../../../../../../Components/Loader/OverlayLoader';
import Table from '../../../../../../Components/Table/Table';
import CreditNote from '../../../../../../Components/Form/CreditNote/CreditNoteForm';
import { NoItemsBox } from '../../../../../../Components/Layout/Box';
import CustomModal from '../../../../../../Components/CustomModal/CustomModal';
import { withRouter } from 'react-router-dom';
import EditIcon from '../../../../../../Assets/General/EditIcon';
import DeleteIcon from '../../../../../../Assets/General/DeleteIcon';
import { MdAdd } from '../../../../../../Common/Icons';

import CreditNoteDetails from '../../../../../../Pages/CreditNote/CreditNoteDetails/index';
import {
  getObjFromLS,
  setObjInLS,
} from '../../../../../../Helpers/LocalStorage';
import MarkAsConfirmedIcon from '../../../../../../Assets/General/MarkAsOpenIcon';
import PDFViewer from '../../../../../../Components/PDFViewer/PDFViewer';
import PdfIcon from '../../../../../../Assets/General/PdfIcon';
import EmailIcon from '../../../../../../Assets/General/EmailIcon';
import { checkError } from '../../../../../../Helpers/AuthHelper';
import './CreditNotes.css';

class Payments extends Component {
  state = {
    loading: true,
    creditNoteId: null,
    creditNotes: [],
    showAddButton: false,
    showRecordPaymentModal: false,
    showDeletePaymentModal: false,
    showCreditNoteEditModal: false,
    showCreditNoteViewModal: false,
    viewCNId: null,
    markAsDraftModal: false,
    markAsDraftId: '',
    showPDF: false,
    showPrompt: false,
    processing: false,
  };

  // check status whether its active or not
  checkStatus = (status, id = false) => {
    const currentId = id ? id : this.state.creditNotes[0];
    return (
      this.state.creditNotes.find((one) => {
        return one.id === currentId;
      }).status === status
    );
  };

  tableRowOptions = [
    {
      label: 'Mark as Open',
      icon: MarkAsConfirmedIcon,
      onClick: (id) => this.markAsConfirmed(id),
      disabled: (id) =>
        this.checkStatus('open', id) ||
        this.checkStatus('void', id) ||
        this.checkStatus('consumed', id) ||
        this.checkStatus('partially applied', id),
    },
    {
      label: 'Edit',
      icon: EditIcon,
      onClick: (id) => this.openEditCreditNoteModal(id),
      disabled: (id) =>
        !this.props.hasPermissionCustom('creditnotes', 'Edit') ||
        this.checkStatus('void', id) ||
        this.checkStatus('consumed', id) ||
        this.checkStatus('partially applied', id),
    },
    {
      label: 'Print & PDF',
      icon: PdfIcon,
      onClick: (id) => this.openPdf(id),
    },
    {
      label: 'Email',
      icon: EmailIcon,
      onClick: (id) => {
        let dataFromLS = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLS,
          queryParam: `${
            dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
          }creditnotes=true`,
        });
        this.props.history.push(`/creditnotes/email/${id}`);
      },
      disabled: () => !this.props.hasPermissionCustom('creditnotes', 'Create'),
    },
    {
      label: 'Delete',
      icon: DeleteIcon,
      onClick: (id) => this.openDeletePaymentModal(id),
      disabled: (id) =>
        !this.props.hasPermissionCustom('creditnotes', 'Delete') ||
        this.checkStatus('consumed', id) ||
        this.checkStatus('partially applied', id),
    },
  ];

  componentDidMount() {
    this.fetchData();
  }

  close = () => {
    this.setState({
      showPDF: false,
      pdf: null,
    });
  };

  handlePrompt = (status = true) => {
    this.setState({
      showPrompt: status,
    });
  };

  openPdf(id) {
    this.setState({ showPDF: true });
    restRequest('get', `creditnotes/${id}/pdf`)
      .then((res) => {
        this.setState({ pdf: res });
      })
      .catch((err) => checkError(err));
  }

  fetchData = () => {
    restRequest('get', `invoices/${this.props.invoiceId}/creditnotes`)
      .then((res) => {
        this.setState({
          creditNoteId:
            res.credit_notes && res.credit_notes.length
              ? res.credit_notes[0].id
              : null,
          creditNotes: res.credit_notes,
          showAddButton: res.show_add_button,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error);
        this.setState({
          loading: false,
        });
      });
  };
  openConvertToDraftModal = (id) => {
    this.setState({
      markAsDraftModal: true,
      markAsDraftId: id,
    });
  };
  renderConvertToDraftModal() {
    return (
      <CustomModal
        showModal={this.state.markAsDraftModal}
        title="Confirm to Mark as Draft"
        onClose={this.closeAllModals}
        onSubmit={() => this.markAsDraft()}
      >
        Are you sure you want to mark this Credit Note as draft?
      </CustomModal>
    );
  }
  markAsDraft = () => {
    restRequest('put', `creditnotes/${this.state.markAsDraftId}/draft`)
      .then(() => {
        this.props.handleToast(
          'Credit Note has been marked as draft.',
          'success'
        );
        this.fetchData();
        this.updateCheckedState();
        this.closeAllModals();
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.closeAllModals();
      });
  };
  markAsConfirmed = (id) => {
    this.setState({ processing: true });
    restRequest('put', `creditnotes/${id}/confirmed`)
      .then(() => {
        this.setState({ processing: false });
        this.props.handleToast(
          'Credit Note has been marked as open.',
          'success'
        );
        //this.updateCheckedState()
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS) {
          setObjInLS('module', {
            ...dataFromLS,
            queryParam: `${
              dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
            }creditnotes=true`,
          });
        } else {
          let obj = {
            moduleName: 'invoices',
            urlPath: window.location.pathname,
            queryParam: `${
              window.location.search ? window.location.search + '&' : '?'
            }creditnotes=true`,
          };
          setObjInLS('module', obj);
        }
        this.fetchData();
        this.props.updateInvoice();
      })
      .catch((error) => {
        this.setState({ processing: false });
        checkError(error, this.props.handleToast);
      });
  };
  openNewRecordPaymentModal = () => {
    const status = this.state.creditNotes.find(
      (creditnote) => creditnote.status === 'draft'
    );
    if (status) {
      this.props.handleToast(
        'Please change the "Draft" credit note to "Open", before proceeding.',
        'error'
      );
      return;
    }
    this.setState({
      showRecordPaymentModal: true,
    });
  };
  openCreditNoteViewModal = (id) => {
    this.setState({
      showCreditNoteViewModal: true,
      viewCNId: id,
    });
  };
  openEditCreditNoteModal = (id) => {
    this.setState({
      showCreditNoteEditModal: true,
      creditNoteId: id,
    });
  };
  openDeletePaymentModal = (id) => {
    this.setState({
      showDeletePaymentModal: true,
      clickedId: id,
    });
  };
  closeAllModals = () => {
    this.setState({
      showRecordPaymentModal: false,
      showDeletePaymentModal: false,
      showCreditNoteEditModal: false,
      showCreditNoteViewModal: false,
      markAsDraftModal: false,
      showPrompt: false,
    });
  };

  deletePaymentModal() {
    return (
      <CustomModal
        type="delete"
        showModal={this.state.showDeletePaymentModal}
        title="Confirm Credit Note Delete"
        onClose={this.closeAllModals}
        onSubmit={this.deletePayment}
      >
        Are you sure you want to delete this Credit Notes?
      </CustomModal>
    );
  }

  deletePayment = () => {
    restRequest('delete', `creditnotes/${this.state.clickedId}`)
      .then((res) => {
        let dataFromLS = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLS,
          queryParam: `${
            dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
          }creditnotes=true`,
        });
        this.closeAllModals();
        this.fetchData();
        this.props.updateInvoice();
        this.props.handleToast('Credit Note deleted successfully', 'success');
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, 'error')
      });
  };

  handleRecordPaymentSubmit = () => {
    this.closeAllModals();
    this.props.updateInvoice();
    this.fetchData();
  };

  newRecordPaymentModal() {
    const { invoiceId, handleToast } = this.props;
    return (
      <CustomModal
        showModal={this.state.showRecordPaymentModal}
        title="Add Credit Note"
        Icon={CreditNoteIcon}
        renderActions={false}
        customWidth="add_creditnote_modal_Width"
        onClose={this.closeAllModals}
        showPrompt={this.state.showPrompt}
        className={'add_cn_popup'}
        mainContainerClass="dragDropPopupContainer "
      >
        <CreditNote
          inModal
          type="add"
          invoiceId={invoiceId}
          handleToast={handleToast}
          onSubmit={this.handleRecordPaymentSubmit}
          close={this.closeAllModals}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }
  editCreditNoteModal() {
    const { handleToast } = this.props;
    return (
      <CustomModal
        showModal={this.state.showCreditNoteEditModal}
        title="Edit Credit Note"
        Icon={CreditNoteIcon}
        renderActions={false}
        className="add_cn_popup"
        customWidth="add_creditnote_modal_Width"
        onClose={this.closeAllModals}
        showPrompt={this.state.showPrompt}
        mainContainerClass="dragDropPopupContainer"
        // className={'Io_popup_dragDrop'}
      >
        <CreditNote
          inModal
          type="edit"
          creditNoteId={this.state.creditNoteId}
          handleToast={handleToast}
          onSubmit={this.handleRecordPaymentSubmit}
          close={this.closeAllModals}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  renderModals() {
    return (
      <Fragment>
        {this.ViewCreditNoteModal()}
        {this.newRecordPaymentModal()}
        {this.deletePaymentModal()}
        {this.editCreditNoteModal()}
        {this.renderConvertToDraftModal()}
      </Fragment>
    );
  }

  preparePaymentsList(creditNotes) {
    return creditNotes.map((credit_note) => {
      return {
        ...credit_note,
        no: this.props.inModal ? (
          `${credit_note.credit_note_no}`
        ) : (
          <div onClick={() => this.openCreditNoteViewModal(credit_note.id)}>
            {credit_note.credit_note_no}
          </div>
        ),
        credit_note_date: getDate(credit_note.credit_note_date),
        invoiceNumber: credit_note.invoice_no,
        //credits: credit_note.total_credit
        credits:
          this.props.currency.symbol + credit_note.issued_credits.toFixed(2),
        status: credit_note.status,
      };
    });
  }
  renderPaymentsListTable() {
    const tableListData = this.preparePaymentsList(this.state.creditNotes);
    const tableHeaders = ['Date', 'Credit Note No.', 'Status', 'Credits'];
    const tableData = [
      'credit_note_date',
      'no',
      'status',
      'credits',
      this.props.inModal ? '' : 'options',
    ];

    return (
      <Fragment>
        <Table
          list={tableListData}
          tableHeaders={tableHeaders}
          tableData={tableData}
          fromTab={true}
          options={this.props.inModal ? [] : this.tableRowOptions}
          className={`box-table ${
            this.props.label ? this.props.label + '_menu' : ''
          }`}
          inModal={this.props.inModal}
        />
        {this.state.showAddButton &&
          this.props.hasPermissionCustom('creditnotes', 'Create') &&
          (this.props.inModal ? (
            ''
          ) : (
            <button
              className="layout-box--button btn-width"
              onClick={this.openNewRecordPaymentModal}
            >
              <MdAdd className=" black-fill" /> Create Credit Note
            </button>
          ))}
      </Fragment>
    );
  }

  ViewCreditNoteModal() {
    return (
      <CustomModal
        showModal={this.state.showCreditNoteViewModal}
        title="View Credit Note"
        Icon={CreditNoteIcon}
        renderActions={false}
        size="large"
        onClose={this.closeAllModals}
        linkTo={`creditnotes/${this.state.viewCNId}`}
      >
        <CreditNoteDetails id={this.state.viewCNId} inModal={true} />
      </CustomModal>
    );
  }

  renderPaymentsList() {
    return (
      <Fragment>
        {this.renderModals()}
        <PDFViewer
          showPDF={this.state.showPDF}
          hidePDFModal={this.close}
          pdf={this.state.pdf}
        />
        {!this.state.creditNotes.length ? (
          this.props.invoiceStatus !== 'void' ? (
            <NoItemsBox
              subtitle="No Credit Notes created yet."
              buttonText="Credit Notes"
              handleclick={this.openNewRecordPaymentModal}
              showButton={
                this.state.showAddButton &&
                this.props.invoiceStatus !== 'draft' &&
                this.props.hasPermissionCustom('creditnotes', 'Create')
              }
              Icon={CreditNoteIcon}
              inModal={this.props.inModal}
            />
          ) : (
            <NoItemsBox
              subtitle="Void invoices cannot make credit notes"
              showButton={false}
              Icon={CreditNoteIcon}
            />
          )
        ) : (
          this.renderPaymentsListTable()
        )}
      </Fragment>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return (
      <>
        {this.state.processing && <OverlayLoader />}
        {this.renderPaymentsList()}
      </>
    );
  }
}

export default withRouter(Payments);
