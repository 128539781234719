import React from 'react'

export default ({
  className = '',
  fill= '#e6e6e6'
}) => (
  <svg width="20" className={className} height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
  <g>
    <path  d="M15.8,0.5H4.2C3,0.5,2,1.5,2,2.7v14.5c0,1.2,1,2.2,2.2,2.2h8.9l4.8-5.7V2.7C18,1.5,17,0.5,15.8,0.5z
 M12.2,17.9V14h3.9L12.2,17.9z M16.6,12.7h-4c-0.9,0-1.6,0.7-1.6,1.6v3.6H4c-0.3,0-0.6-0.3-0.6-0.6V2.7c0-0.3,0.3-0.6,0.6-0.6H16
c0.3,0,0.6,0.3,0.6,0.6V12.7z"  fill= '#e6e6e6'/>
    <path d="M12.4,7.3c0.2,0,0.6-0.1,0.6-0.6C13,6.2,12.6,6,12.4,6h-1.9V4.8c0-0.1,0.1-0.5-0.5-0.5
c-0.5,0-0.5,0.4-0.5,0.5v1.5H7.6C7,6.7,7,6.7,7,6.7v2.1c0.3,0.3,0.3,0.3,0.6,0.6h4.2v0.8H7.6c-0.2,0-0.6,0.2-0.6,0.6
c0,0.6,0.4,0.6,0.6,0.6h1.9v1.5c0,0.2,0.1,0.6,0.6,0.6c0.5,0,0.6-0.4,0.6-0.6v-1.5h1.9c0.2,0,0.2,0,0.4-0.2c0-0.2,0-0.2,0-0.4v-2
c0-0.2,0-0.2-0.2-0.4s-0.2-0.3-0.4-0.3H8.2V7.3H12.4z" fill= '#e6e6e6'/>
  </g>
</svg>
)
