const PaymentMode = [
  {
    value: 'Cash',
    label: 'Cash'
  },
  {
    value: 'Check',
    label: 'Check'
  },
  {
    value: 'Credit Card',
    label: 'Credit Card'
  },
  {
    value: 'Direct Deposit',
    label: 'Direct Deposit'
  },
  {
    value: 'Mobile Payments',
    label: 'Mobile Payments'
  },
  {
    value: "Cashier's Check",
    label: "Cashier's Check"
  },
  {
    value: 'Cash on Delivery',
    label: 'Cash on Delivery'
  },
  {
    value: 'Money Order',
    label: 'Money Order'
  }
]

export default PaymentMode