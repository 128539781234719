import React from 'react';

import { NewPackageIcon } from '../../../Assets/Navigation/NavigationIcons';

import Email from '../../../Components/Email/Email';

const PackageEmail = (props) => {
  return (
    <Email
      {...props}
      Icon={NewPackageIcon}
      name="Shipments"
      onCancelURL="/packages"
      emailAPI={`shipments/${props.match.params.id}/mail`}
      fetchDataAPI={`shipments/${props.match.params.id}/mail`}
      getBackTab
    />
  );
};

export default PackageEmail;
