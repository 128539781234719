import React, { Component } from 'react';

import Tabs from '../../../../Components/Tabs/Tabs';
import ContactEmployeesTab from './Employees/ContactEmployeesTab';
import ContactActivityTab from './Activity/ContactActivityTab';
import ContactTransactionTab from './Transaction/ContactTransactionTab';
import ContactStatementTab from './Statement/ContactStatementTab';
import OverViewContact from './OverviewContact/OverViewContact';
import { getObjFromLS, setObjInLS } from '../../../../Helpers/LocalStorage';

class ContactDetailsOutterTabs extends Component {
  constructor(props) {
    super();
    let dataFromLS = getObjFromLS('module');
    if (
      dataFromLS &&
      dataFromLS.moduleName === 'contacts' &&
      dataFromLS.currentOuterTab
    ) {
      this.state = { currentTab: dataFromLS.currentOuterTab };
      setObjInLS('module', { ...dataFromLS, currentOuterTab: '' });
    } else {
      this.state = { currentTab: 'overview' };
    }
  }

  renderAddressTab = () => {
    return (
      <OverViewContact
        history={this.props.history}
        inModal={this.props.inModal}
        contactInfo={this.props.contactInfo}
        contactId={this.props.contactId}
        handleToast={this.props.handleToast}
      />
    );
  };

  renderEmployeesTab = () => {
    return (
      <ContactEmployeesTab
        contactId={this.props.contactId}
        handleToast={this.props.handleToast}
        fetchData={this.props.fetchData}
      />
    );
  };

  renderActivityTab = () => {
    return (
      <ContactActivityTab
        contactId={this.props.contactId}
        handleToast={this.props.handleToast}
      />
    );
  };

  renderTransactionTab = () => {
    return (
      <ContactTransactionTab
        contactId={this.props.contactId}
        contactType={this.props.contactType}
        handleToast={this.props.handleToast}
        history={this.props.history} //adding route and history as props to contacts tabs transaction for further navigation
        inModal={this.props.inModal}
      />
    );
  };

  renderStatementTab = () => {
    return (
      <ContactStatementTab
        contactId={this.props.contactId}
        contactName={this.props.contactName}
        contactType={this.props.contactType}
        handleToast={this.props.handleToast}
        inModal={this.props.inModal}
        hasMobile={this.props.hasMobile}
      />
    );
  };

  handleTabChange(tab) {
    this.setState({ currentTab: tab });
  }

  tabs = [
    {
      id: 'overview',
      label: 'Overview',
      onClick: () => {
        this.handleTabChange('overview');
      },
      renderContent: this.renderAddressTab,
    },
    {
      id: 'activity',
      label: 'Activity',
      onClick: () => {
        this.handleTabChange('activity');
      },
      renderContent: this.renderActivityTab,
    },
    {
      id: 'transaction',
      label: 'Transactions',
      onClick: () => {
        this.handleTabChange('transaction');
      },
      renderContent: this.renderTransactionTab,
    },
    {
      id: 'statement',
      label: 'Statement',
      onClick: () => {
        this.handleTabChange('statement');
      },
      renderContent: this.renderStatementTab,
    },
  ];

  render() {
    return (
      <div className="sales_order_tabs contact-detail-trans">
        <Tabs
          tabs={this.tabs}
          currentTab={this.state.currentTab}
          history={this.props.history}
          inModal={this.props.inModal || false}
        />
      </div>
    );
  }
}

export default ContactDetailsOutterTabs;
