import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Logo404 from '../../Assets/SVG/logo404.svg'
import LogoSeebiz from '../../Assets/SVG/logoSeebiz.svg'

import './NotFoundPage.css'

export default class NotFoundPage extends Component {
    render() {
        return (
            <div className="page-404">
                <div className="page-404--logo">
                    <img src={LogoSeebiz} alt="seebiz-logo" />
                </div>
                <div>
                    <img src={Logo404} alt="404-logo" />
                    <h1 className="page-404--title">404</h1>
                    <h4 className="page-404--message">
                        Oooops! The page you are looking for doesn't exist
                    </h4>
                    <h4 className="page-404--message">
                        Please check your URL or return <Link to="/">
                            Dashboard
                        </Link>
                    </h4>
                </div>
                <div className="page-404--footer">
                    © {this.getYear()}, Seebiz. All Rights Reserved.
                </div>
            </div>
        )
    }
    getYear() {
      return new Date().getFullYear();
    }
}