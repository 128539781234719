import moment from 'moment'
import { getOrganizationDate } from './Date/OrganizationDate'

import findByValue from './ArrayHelper/FindByValue'

export const getSelectedDateFormat = (value, separator) => {
  const dateFormatOptions = getDateFormatOptions(separator)
  return findByValue(value, dateFormatOptions)
}

const getDateFormatOptions = separator => {
  const today = moment(getOrganizationDate())
  return [
    {
      label: 'default',
      options: [
        {
          /*value: 'medium1',
          label: `MM${separator}dd${separator}yyyy [${today.format('MM' + separator + 'DD' + separator + 'YYYY')}]`*/
          value: 'long6',
          label: `MMM DD, YY [${today.format('MMM DD, YY')}]`
        }
      ]
    },
    {
      label: 'short',
      options: [
        {
          value: 'short1',
          label: `MM${separator}dd${separator}yy [${today.format('MM' + separator + 'DD' + separator + 'YY')}]`
        },
        {
          value: 'short2',
          label: `dd${separator}MM${separator}yy [${today.format('DD' + separator + 'MM' + separator + 'YY')}]`
        },
        {
          value: 'short3',
          label: `yy${separator}MM${separator}dd [${today.format('YY' + separator + 'MM' + separator + 'DD')}]`
        }
      ]
    },
    {
      label: 'medium',
      options: [
        {
          value: 'medium1',
          label: `MM${separator}dd${separator}yyyy [${today.format('MM' + separator + 'DD' + separator + 'YYYY')}]`
        },
        {
          value: 'medium2',
          label: `dd${separator}MM${separator}yyyy [${today.format('DD' + separator + 'MM' + separator + 'YYYY')}]`
        },
        {
          value: 'medium3',
          label: `yyyy${separator}MM${separator}dd [${today.format('YYYY' + separator + 'MM' + separator + 'DD')}]`
        }
      ]
    },
    {
      label: 'long',
      options: [
        {
          value: 'long1',
          label: `DD MMM YYYY [${today.format('DD MMM YYYY')}]`
        },
        {
          value: 'long2',
          label: `DD MMMM YYYY [${today.format('DD MMMM YYYY')}]`
        },
        {
          value: 'long3',
          label: `MMMM DD, YYYY [${today.format('MMMM DD, YYYY')}]`
        },
        {
          value: 'long4',
          label: `ddd, MMMM DD, YYYY [${today.format('ddd, MMMM DD, YYYY')}]`
        },
        {
          value: 'long5',
          label: `dddd, MMMM DD, YYYY [${today.format('dddd, MMMM DD, YYYY')}]`
        },
        {
          value: 'long6',
          label: `MMM DD, YY [${today.format('MMM DD, YY')}]`
        }
      ]
    }
  ]
}

export default getDateFormatOptions