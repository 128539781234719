import React, { useEffect, useState } from 'react';
import ContactForm from '../../Components/Form/ContactForm/ContactForm';
import ContactIconListing from '../../Assets/General/ContactIconListing';
import Address from '../../Assets/General/Address';
import BasicInfo from '../../Assets/General/BasicInfo';
import OtherDetails from '../../Assets/General/OtherDetails';
import { MdClose as CloseButton } from '../../Common/Icons';
import Layout, { Header, Body } from '../../Components/Layout/Layout';
import Box from '../../Components/Layout/Box';
import { validateContactForm } from '../../Helpers/FormValidator';
import useToggleClick from '../../Hooks';
import classNames from 'classnames';

const AddContact = ({ type, ...props }) => {
  const [, handleCrossAlert, handleCrossClick] = useToggleClick();
  const tabs = [
    { name: 'Basic Info', value: 1, icon: BasicInfo },
    { name: 'Address', value: 2, icon: Address },
    { name: 'Other Details', value: 3, icon: OtherDetails },
  ];
  const [tab, setTab] = useState(tabs[0]);
  function updateTab(val = 1) {
    let obj = tabs.find((t) => t.value === val);
    setTab(obj);
  }
  useEffect(() => {
    window.onbeforeunload = function () {
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <Layout>
      <Header
        title="Contact"
        iconStyle="contact_general_module_icon"
        Icon={ContactIconListing}
        subtitle="Add New"
      >
        {type !== 'purchaseOrder' && type !== 'salesOrder' && (
          <div onClick={handleCrossClick('/contacts', validateContactForm)}>
            <CloseButton className="material-icon  mt-11" />
          </div>
        )}
      </Header>
      <div className={classNames('float-left', 'w-100', 'add_contact_tabs')}>
        <div className={classNames('add_contact_tabs_info')}>
          {tabs.map((t) => {
            let Icon = t.icon;
            return (
              <h1
                key={t.value}
                style={{
                  color: t.value === tab.value ? '#FFF' : '',
                  backgroundColor: t.value === tab.value ? '#306AB4' : '',
                }}
                className={`${t.value === tab.value ? 'active_tab ' : ''}`}
                onClick={() => updateTab(t.value)}
              >
                <Icon
                  className={
                    t.value === tab.value
                      ? 'contact-icon-active'
                      : 'contact-icon '
                  }
                />
                {t.name}
              </h1>
            );
          })}
        </div>
        <Body>
          <Box className="contact-width-full">
            <div className="pl-contacts contact-form-main">
              <ContactForm
                updateTab={updateTab}
                tab={tab.value}
                {...props}
                type={type}
                handleCrossAlert={handleCrossAlert}
              />
            </div>
          </Box>
        </Body>
      </div>
    </Layout>
  );
};

export default AddContact;
