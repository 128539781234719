import React from 'react';
import classNames from 'classnames';
import CustomDropdown from './../Dropdown/CustomDropdown';
import { MdAdd, MdClose, FaShopify } from '../../Common/Icons';
import { withRouter } from 'react-router-dom';
import { setValueInLS } from '../../Helpers/LocalStorage';
import EcomDetailNavigation from '../../Pages/Integrations/Ecom/EcomDetailNavigation';

import './Card.css';
import { ToolTip } from '../../Common';

const Card = ({
  className = '',
  children,
  type,
  inModal = false,
  title = '',
  handleAdd,
  titleWithDropdown = false,
  dropdownOptions,
  headerIcon,
  handleHeaderIconClick,
  headerIconDisabled = false,
  history,
  crossBtnRoute = '',
  showCrossBtn = false,
  handleToast,
  wareHouseData = [],
  cssprp = 'card-body',
  wareHouseConnecHandler = () => {},
}) => {
  const { store_details: { name = '', store_url = '', slug = '' } = {} } =
    wareHouseData;
  //console.log({ wareHouseData });
  const renderAdd = () => (
    <div className={classNames('card')} onClick={handleAdd}>
      <div className={classNames('card-add', 'pointer')}>
        <MdAdd />
        <div className="card-add--title">{title}</div>
      </div>
    </div>
  );

  function renderHeader() {
    return (
      title.length !== 0 && (
        <div className="card-header">
          <div className="card-header--title">
            {type === 'detail' ? (
              <>
                {slug === 'woo-commerce' ? (
                  <>
                    <div className="ecom-header-logo">
                      <img
                        src="https://i.ibb.co/JzyLVF2/Woo-Commerce-logo-Woo-Commerce.png"
                        alt="Woo-Commerce-logo"
                      />
                      &nbsp;/&nbsp;
                      <ToolTip content={`${name || store_url}`}>
                        {name || store_url}
                      </ToolTip>
                    </div>
                  </>
                ) : slug === 'shopify' ?
                  <>
                    <div className="ecom-header-logo">
                      <FaShopify/>
                      &nbsp;/&nbsp;
                      <ToolTip content={`${name || store_url}`}>
                        {name || store_url}
                      </ToolTip>
                    </div>
                  </>
                :''}
              </>
            ) : (
              title
            )}
          </div>
          {titleWithDropdown ? (
            !inModal && (
              <CustomDropdown
                icon={headerIcon}
                dotIcon
                options={dropdownOptions}
              />
            )
          ) : headerIcon ? (
            <button
              onClick={handleHeaderIconClick}
              className="button card-header--btn"
              disabled={headerIconDisabled}
            >
              {headerIcon}
            </button>
          ) : (
            ''
          )}
          {type === 'detail' && (
            <EcomDetailNavigation
              handleToast={handleToast}
              wareHouseData={wareHouseData}
              wareHouseConnecHandler={wareHouseConnecHandler}
            />
          )}
          {showCrossBtn && (
            <div className="close_icon close-icon-pos">
              <MdClose
                onClick={() => {
                  setValueInLS('fullscreen', 'true');
                  sessionStorage.setItem('once', true);
                  history.push(crossBtnRoute);
                }}
              />
            </div>
          )}
        </div>
      )
    );
  }

  const renderDetails = () => (
    <div
      className={`${
        type !== 'ecom' ? 'card' : 'integration-wrapper'
      } ${className}`}
    >
      {type !== 'ecom' && renderHeader()}

      <div className={`${cssprp}`}>{children}</div>
    </div>
  );

  switch (type) {
    case 'add':
      return renderAdd();
    default:
      return renderDetails();
  }
};

export default withRouter(Card);
