import React from 'react'

export default ({
  className = ''
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="18.307" height="21.566" viewBox="0 0 18.307 21.566">
      <g transform="translate(-1830.424 -1164.558)">
        <g transform="translate(1830.5 1164.634)">
          <path fill="#33a348"
                d="M642.939,211.509c-.192-1.679-.766-2.212-2.451-2.28-1.478-.058-2.952-.1-4.43-.138l-1.918-.053-.4.025v.011l-1.712.034c-1.476.033-2.95.063-4.423.109a2.034,2.034,0,0,0-2.181,1.956c-.094,1.163-.177,2.327-.234,3.49a106.089,106.089,0,0,0,.221,13.7,2.051,2.051,0,0,0,2.229,2.087l3.766,0q2.233,0,4.47-.016a1.906,1.906,0,0,0,1.186-.42q2.184-1.975,4.3-4.023a5.279,5.279,0,0,0,1.625-3.514A56.453,56.453,0,0,0,642.939,211.509Zm-5.994,16.446v-.364c0-.929-.005-1.811.009-2.7a.25.25,0,0,1,.277-.273c.54-.009,1.081-.011,1.63-.011l1.422,0A22.722,22.722,0,0,1,636.945,227.955ZM641.2,222.9l-.2-.015c-.4-.034-.779-.065-1.212-.065h-.005q-1.353.011-2.708.109a1.932,1.932,0,0,0-1.826,1.842c-.056.732-.107,1.467-.105,2.2,0,.491.031.984.067,1.491l.016.293c-.031,0-7.471,0-7.471,0-.583,0-.607,0-.659-.639a102.565,102.565,0,0,1-.212-13.373c.032-.665.08-1.328.129-1.992l.094-1.367c.022-.351.148-.417.409-.427l1.279-.06c.94-.047,1.882-.094,2.822-.094v-.391l.054.391c2.4,0,4.793.042,7.187.081l1.608.025c.688.009.688.009.768.7A48.8,48.8,0,0,1,641.2,222.9Z"
                transform="translate(-625.063 -209.038)"/>
          <path fill="#306ab4"
                d="M634.1,215.035a.693.693,0,0,0,.683-.683.644.644,0,0,0-.2-.469.668.668,0,0,0-.48-.2h-2.048v-1.627a.668.668,0,0,0-.659-.668.66.66,0,0,0-.69.668v1.627h-2a.662.662,0,0,0-.666.667v2.3a.656.656,0,0,0,.666.667h4.7v.962h-4.717a.667.667,0,0,0,0,1.333H630.7v1.645a.638.638,0,0,0,.2.471.667.667,0,0,0,.485.2.643.643,0,0,0,.467-.2.627.627,0,0,0,.2-.471v-1.628h2.032a.656.656,0,0,0,.48-.2.628.628,0,0,0,.2-.467v-2.311a.631.631,0,0,0-.2-.467.663.663,0,0,0-.48-.2h-4.7v-.953Z"
                transform="translate(-623.121 -207.129)"/>
        </g>
      </g>
    </svg>
)
