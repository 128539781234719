import React from 'react'

export default ({
  className = ''
}) => (
  <>
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="17.035" height="19.585" viewBox="0 0 17.035 19.585">
      <g id="Group_661" data-name="Group 661" transform="translate(-1419.967 -64.004)">
        <path id="Path_119" data-name="Path 119" d="M34.1,0H20.246A.45.45,0,0,0,19.8.438V3.117a.148.148,0,0,0,0,.042s0,.013,0,.017,0,.013,0,.021,0,.013,0,.021,0,.013,0,.017,0,.017.009.021,0,.008,0,.017,0,.013.009.021,0,.008.009.017.009.013.009.017,0,.013.009.017.009.013.013.017.009.013.013.017a.058.058,0,0,1,.013.017s.009,0,.009.008l5.047,5.2v6.572a.419.419,0,0,0,.252.38.459.459,0,0,0,.185.038.443.443,0,0,0,.287-.1l3.542-2.85a.4.4,0,0,0,.15-.317V8.6l5.043-5.2.009-.008a159.886,159.886,0,0,1,.026-.033.058.058,0,0,1,.013-.017.031.031,0,0,0,.009-.017s.009-.013.013-.017,0-.008.009-.017.009-.013.009-.021,0-.008,0-.017,0-.013.009-.021,0-.013,0-.017,0-.013,0-.021,0-.013,0-.021,0-.013,0-.017,0-.025,0-.042V.434A.446.446,0,0,0,34.1,0ZM28.619,8.162a.388.388,0,0,0-.119.275v3.689l-2.65,2.141V8.437a.388.388,0,0,0-.119-.275L21.248,3.547H33.1Zm5.047-5.449H20.683V.835H33.666Z" transform="translate(1401.2 68)" />
        <path id="Path_120" data-name="Path 120" d="M17,0S5.219,11.953.969,16.2" transform="matrix(1, -0.017, 0.017, 1, 1419.36, 64.658)" fill="none" stroke="#000" stroke-width="1" />
      </g>
    </svg>

    {/* <svg className={className} xmlns="http://www.w3.org/2000/svg" width="30.747" height="35.749" viewBox="0 0 30.747 35.749"><defs></defs><g transform="translate(-1058.968 -536.004)"><g transform="translate(1060.565 543.059)"><g transform="translate(0)"><path fill="#000" d="M46.128,0H20.621A.828.828,0,0,0,19.8.806V5.737a.273.273,0,0,0,.008.077c0,.008.008.023.008.031s.008.023.008.038.008.023.008.038.008.023.008.031.008.031.016.038.008.015.008.031.008.023.016.038.008.015.016.031.016.023.016.031.008.023.016.031.016.023.024.031.016.023.024.031A.107.107,0,0,1,20,6.244c.008.008.016.008.016.015l9.291,9.57v12.1a.771.771,0,0,0,.463.7.845.845,0,0,0,.341.069.815.815,0,0,0,.528-.184l6.519-5.246a.727.727,0,0,0,.276-.584V15.829l9.283-9.577.016-.015a270.862,270.862,0,0,1,.049-.061.107.107,0,0,1,.024-.031.057.057,0,0,0,.016-.031c.008-.008.016-.023.024-.031s.008-.015.016-.031.016-.023.016-.038.008-.015.008-.031.008-.023.016-.038.008-.023.008-.031.008-.023.008-.038.008-.023.008-.038.008-.023.008-.031c0-.023.008-.046.008-.077V.8A.821.821,0,0,0,46.128,0Zm-10.1,15.023a.714.714,0,0,0-.219.507v6.789l-4.877,3.94V15.53a.714.714,0,0,0-.219-.507l-8.25-8.494H44.282Zm9.291-10.03h-23.9V1.536h23.9Z" transform="translate(-19.8)"/></g></g><path fill="#000"  className="a" d="M29.508,0S7.823,22,0,29.824" transform="matrix(1, -0.017, 0.017, 1, 1059.329, 536.876)"/></g></svg> */}
  </>
)