import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import COUNTRY_STATES from '../../../Constants/CountryState';
import { restRequest } from '../../../Helpers/RequestHelper';

import Loader from '../../Loader/Loader';
import WarehouseFormEnhanced from './WarehouseFormEnhancer';

import './WarehouseForm.css';
import PlanExceeded from '../../PlanExceeded/PlanExceeded';

// import { StateShortName } from '../../../Helpers/StateNameConvertion'
import { checkError, getCurrentOrganizaton } from '../../../Helpers/AuthHelper';
class WarehouseForm extends Component {
  type = this.props.type; // "add" or "edit" form

  id = this.props.id || null;

  state = {
    warehouse: {
      name: '',
      email: '',
      address: '',
      city: '',
      state: '',
      countryID: null,
      zipCode: '',
      phone: '',
      planExceeded: null,
    },
    countryDB: [],
    countryStates: [],
    loading: true,
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.fetchData().then(() => this.setState({ loading: false }));
  }

  fetchData = async () => {
    switch (this.type) {
      case 'edit':
        await this.fetchWarehouseEditData();
        this.populateCountryStates(this.state.warehouse.countryID);
        break;
      default:
        return this.fetchWarehouseCreateData();
    }
  };

  fetchWarehouseCreateData() {
    return restRequest('get', 'warehouses/create')
      .then((res) => {
        this.setState((state) => ({
          countryDB: res.countries,
          warehouse: {
            ...state.warehouse,
            countryID: JSON.parse(getCurrentOrganizaton()).country_id,
          },
        }));
        this.populateCountryStates(230);
      })
      .catch((error) => {
        this.setState({
          planExceeded: error.response && error.response.data.status,
          loading: false,
        });
        checkError(error, this.props.handleToast);
        /*if(error.response){
        this.props.handleToast(error.response.data.message,'error')
      }*/
      });
  }

  fetchWarehouseEditData() {
    return restRequest('get', `warehouses/${this.id}/edit`)
      .then((res) => {
        const { warehouse, countries } = res;
        let updatedWarehouse = {
          ...this.state.warehouse,
          name: warehouse.name,
          email: warehouse.email,
          address: warehouse.address,
          city: warehouse.city,
          //state: StateShortName(warehouse.state),
          state: warehouse.state,
          countryID: warehouse.country_id,
          zipCode: warehouse.zip_code,
          phone: warehouse.phone,
        };
        this.setState({ warehouse: updatedWarehouse, countryDB: countries });
      })
      .catch((error) => {
        this.setState({
          planExceeded: error.response && error.response.data.status,
          loading: false,
        });
        checkError(error, this.props.handleToast);
        /*if(error.response){
        this.props.handleToast(error.response.data.message , 'error')
      }*/
      });
  }

  populateCountryStates = (countryId) => {
    if (countryId) {
      const countryFromList = this.state.countryDB.find(
        (country) => country.id === countryId
      );
      if (countryFromList) {
        const country = COUNTRY_STATES.find(
          (item) => item.country === countryFromList.country_name
        );
        if (country) {
          const states = country.states.map((state) => ({
            value: state,
            label: state,
          }));
          this.setState({ countryStates: states });
          return;
        }
      }
    }
    this.setState({ countryStates: [] });
  };

  submitRequestType() {
    if (this.type === 'add') {
      return 'post';
    } else if (this.type === 'edit') {
      return 'put';
    }
  }

  submitRequestUrl() {
    if (this.type === 'add') {
      return 'warehouses';
    } else if (this.type === 'edit') {
      return `warehouses/${this.id}`;
    }
  }

  successMessage = () => {
    if (this.type === 'add') {
      this.props.handleToast('Warehouse created successfully', 'success');
    } else if (this.type === 'edit') {
      this.props.handleToast('Warehouse has been updated', 'success');
    }
  };

  renderWarehouseForm() {
    const { warehouse, countryDB, countryStates, planExceeded } = this.state;
    const {
      history,
      handleToast,
      inModal,
      onSubmitModal,
      closeModal,
      totalRecords,
      handlePrompt = () => null,
    } = this.props;
    if (planExceeded === false) return <PlanExceeded />;
    return (
      <WarehouseFormEnhanced
        type={this.type}
        warehouse={warehouse}
        countryDB={countryDB}
        countryStates={countryStates}
        inModal={inModal}
        onSubmitModal={onSubmitModal}
        closeModal={closeModal}
        populateCountryStates={this.populateCountryStates}
        history={history}
        requestType={this.submitRequestType()}
        requestUrl={this.submitRequestUrl()}
        handleToast={handleToast}
        successMessage={this.successMessage}
        totalRecords={totalRecords}
        handlePrompt={handlePrompt}
      />
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return this.renderWarehouseForm();
  }
}
export default withRouter(WarehouseForm);
