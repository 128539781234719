import { isEqual } from 'lodash';
export const validateUserRoleForm = (data = {}, defaultValues = {}) => {
  const checkEquality = Boolean(isEqual(data, defaultValues));
  let checkout = true;
  if (!checkEquality) checkout = false;
  return { checkout };
};

export const validateUserAccountForm = (data) => {
  let checkout = true;
  const { name_updated, phone_updated } = data;

  if (name_updated || phone_updated) {
    checkout = false;
  }

  return { checkout };
};

export const validateTaxForm = (data) => {
  let checkout = true;

  return { checkout };
};

export const validateCreateAccountForm = (data) => {
  const { accTitle, accGrpId, currentBalance, openingBalance } = data;
  let checkout = true;
  if (accTitle || accGrpId || currentBalance || openingBalance) {
    checkout = false;
  }
  return { checkout };
};

export const validateCreateDeliveryForm = (data = {}, defaultValues = {}) => {
  const checkEquality = Boolean(isEqual(data, defaultValues));
  const { name } = data;
  let checkout = true;
  if (name && !checkEquality) {
    checkout = false;
  }
  return { checkout };
};

export const validateCreateTaxorm = (data = {}, defaultValues = {}) => {
  const checkEquality = Boolean(isEqual(data, defaultValues));
  const { name, rate, authority } = data;
  let checkout = true;
  if ((name || rate || authority) && !checkEquality) {
    checkout = false;
  }
  return { checkout };
};

export const validateAdressForm = (data) => {
  let checkout = true;
  const {
    attention = '',
    city = '',
    countryupdate = false,
    fax = '',
    phone = '',
    state = '',
    street = '',
    zipCode = '',
  } = data;

  if (
    attention ||
    city ||
    countryupdate ||
    fax ||
    phone ||
    state ||
    street ||
    zipCode
  ) {
    checkout = false;
  }

  return { checkout };
};

export const validatePaymentForm = (data = {}, defaultValues = {}) => {
  let checkout = true;
  if (data && defaultValues) {
    const { date = null } = defaultValues;
    const actuadate = date && date.format('YYYY-MM-DD 00:00:00');

    const checkoutEqualitydate = Boolean(
      isEqual(data?.date?.format('YYYY-MM-DD 00:00:00'), actuadate)
    );
    const updatedData = { ...data };
    const actualData = { ...defaultValues };
    delete updatedData.date;

    delete actualData.date;

    const checkEquality = Boolean(isEqual(updatedData, actualData));

    if (!checkEquality || !checkoutEqualitydate) {
      checkout = false;
    }
  }
  return { checkout };
};

export const validateWarehouseForm = (data = {}, defaultValues = {}) => {
  const checkEquality = Boolean(isEqual(data, defaultValues));
  let checkout = true;
  if (!checkEquality) checkout = false;
  return { checkout };
};
