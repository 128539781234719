import { isEqual } from 'lodash';

export const validateCreateItemForm = (data = {}, defaultValues = {}) => {
  const checkEquality = Boolean(isEqual(data, defaultValues));
  // const {
  //   name,
  //   brand,
  //   description,
  //   isbn,
  //   dimension,
  //   ean,
  //   manufacturer,
  //   mpn,
  //   openingStockValue,
  //   sales_update,
  //   purchaseDescription,
  //   purchaseUnitPrice,
  //   reorderLevel,
  //   // purchase_update,
  //   salesDescription,
  //   salesUnitPrice,
  //   sku,
  //   taxID,
  //   unit,
  //   upc,
  //   weight,
  //   vendorID,
  //   uploadImages,
  //   trackInventory,
  //   dimensions = '',
  //   warehouses = [],
  // } = data;
  let checkout = true;
  // warehouses.forEach((warehouse) => {
  //   if (!warehouse.name || warehouse.openingStock) {
  //     checkout = false;
  //   }
  // });
  if (
    // (name ||
    //   brand ||
    //   description ||
    //   isbn ||
    //   dimension ||
    //   ean ||
    //   manufacturer ||
    //   mpn ||
    //   openingStockValue ||
    //   sales_update ||
    //   purchaseDescription ||
    //   purchaseUnitPrice ||
    //   reorderLevel ||
    //   // purchase_update ||
    //   salesDescription ||
    //   salesUnitPrice ||
    //   sku ||
    //   taxID ||
    //   unit ||
    //   upc ||
    //   weight ||
    //   vendorID ||
    //   uploadImages?.length ||
    //   dimensions ||
    //   !trackInventory) &&
    !checkEquality
  ) {
    checkout = false;
  }
  return { checkout };
};
