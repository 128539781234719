import React, { Component } from 'react'
import { restRequest } from '../../Helpers/RequestHelper'
import { checkError } from '../../Helpers/AuthHelper'
import AddImportFile from './AddImportFile'
import AddFields from './AddFields'
import ImportSummary from './ImportSummary'

import './Import.css'


class Import extends Component {

  state = {
    csvFile: {},
    step: 1,
    options: [],
    importType: "seebiz",
    csvLabels: [],
    csvMap: {},
    duplicate: "skip",
    success: 0,
    skipped: [],
    unmapped: [],
    totalWrong: 0,
    wrongRecord: [],
    disableNext: true
  }

  componentDidMount() {
    document.title = `Import ${this.props.name}`
  }

  onFileDrop = (files) => {
    let file = files[0]
    if (file === undefined) {
      this.props.handleToast("Invalid file format. Only csv file type is supported.", "error")
    }
    else {
      this.setState({
          csvFile: {
          name: file.name,
          file: file,
        },
        disableNext: false
      })
      this.props.handleToast("File uploaded", "success")
    }
  }

  previousStep = () => {
    this.setState(({step}) => ({step: step - 1}))
  }

  nextStep = () => {
    this.setState(({step}) => ({
      step: step + 1
    }))
  }

  handleImportDuplicate = (event) => {
    let duplicate = event.target.value
    this.setState({
      duplicate: duplicate
    })
  }

  handleImportType = (event) => {
    // console.log("event", event.target.value)
    let importTypeNew = event.target.value
    this.setState({
      importType: importTypeNew
    })
  }

  handleImportFile = (event) => {
    event.preventDefault()
    let data = new FormData()

    data.append('file', this.state.csvFile.file)
    data.append('importType', this.state.importType)
    restRequest('post', `${this.props.fetchHeadersAPI}`, data).then(res => {
      //console.log('res',res)
      this.setState({
        options: res.labelFields,
        csvLabels: res.csvHeaders
      }, () => this.nextStep())
    }).catch((error) => {
      //this.props.handleToast(error, "error")
      this.setState({
        disableNext: false
      })
      checkError(error,this.props.handleToast)
    })
  }

  checkImportFields () {
    let data = new FormData()
    data.append('file', this.state.csvFile.file)
    data.append('map', JSON.stringify(this.state.csvMap))
    data.append('duplicate', this.state.duplicate)
    data.append('importType', this.state.importType)
    //console.log('jjjj',this.state.csvMap)
    //return false;
    restRequest('post', `${this.props.fetchSummaryAPI}`, data).then(res => {
      if(res.limitError && res.limitError !== null){
        this.props.handleToast(res.limitError, "error")
        if(window.location.pathname.includes('contacts')){
          return this.props.history.push('/contacts')
        }else if(window.location.pathname.includes('items')){
          return this.props.history.push('/items')
        }
      }
      this.setState({
        success: res.readyToBeImported,
        skipped: res.recordsSkipped,
        unmapped: res.unmappedFields,
        totalWrong: res.wrongContacts,
        wrongRecord: res.wrongRecords,
      })
      this.nextStep()
    }).catch((error) => {
      var errors = error.response.data.message.replaceAll('[', '')
      errors = errors.replaceAll(']', '')
      errors = errors.replaceAll('"', '')
      this.props.handleToast(errors.split(','), "error")
      this.setState({
        disableNext: false
      })
      checkError(error)
    })
  }

  handleImportFields = (csvMap) => {
    this.setState({
      csvMap
    }, () => this.checkImportFields())
  }

  renderContent(step) {
    switch(step) {
      case 3:
       // console.log("Step 3", this.state);
        return (
          <ImportSummary
            csvFile={this.state.csvFile}
            csvMap={this.state.csvMap}
            duplicate={this.state.duplicate}
            success={this.state.success}
            totalWrong={this.state.totalWrong}
            wrongRecord={this.state.wrongRecord}
            skipped={this.state.skipped}
            importType = {this.state.importType}
            unmapped={this.state.unmapped}
            importAPICall={this.props.importAPI}
            previousStep={this.previousStep}
            handleToast={this.props.handleToast}
            history={this.props.history}
          />
        )
      case 2:
        //console.log("Step 2", this.state);
        return (
          <AddFields
            previousStep={this.previousStep}
            options={this.state.options}
            csvLabels={this.state.csvLabels}
            csvFile={this.state.csvFile}
            importType = {this.state.importType}
            handleToast={this.props.handleToast}
            handleImportFields={this.handleImportFields}
            disableNext={this.state.disableNext}
          />
        )
      case 1:
      default:
        return (
          <AddImportFile
            handleImportFile={this.handleImportFile}
            onFileDrop={this.onFileDrop}
            csvFile={this.state.csvFile}
            duplicate={this.state.duplicate}
            importType = {this.state.importType}
            handleImportDuplicate={this.handleImportDuplicate}
            handleImportType={this.handleImportType}
            sampleFileLink={this.props.sampleFileLink}
            disableNext={this.state.disableNext}
          />
        )
    }
  }

  renderImport() {
    return (
      this.renderContent(this.state.step)
    )
  }

  render() {
    return this.renderImport()
  }
}

export default Import