import React, { Component, Fragment } from 'react';
import { MdClose } from '../../../../Common/Icons';
import { restRequest } from '../../../../Helpers/RequestHelper';
import { getObjFromLS } from '../../../../Helpers/LocalStorage';
import Loader from '../../../../Components/Loader/Loader';
import Table from '../../../../Components/Table/Table';
import CustomModal from '../../../../Components/CustomModal/CustomModal';
import AccessDenied from '../../../../Components/AccessDenied/AccessDenied';
import RoleForm from '../../../../Components/Form/RoleForm/RoleForm';
import EditIcon from '../../../../Assets/General/EditIcon';
import DeleteIcon from '../../../../Assets/General/DeleteIcon';

import './RolesTab.css';
import { checkError } from '../../../../Helpers/AuthHelper';
let hasEditPermission = false;
let hasDeletePermission = false;
export default class RolesTab extends Component {
  state = {
    loading: true,
    roles: [],
    newRoleToggle: false,
    editRoleToggle: false,
    showDeleteModal: false,
    selectedRoleId: '',
    deleteRoleId: '',
    accessDenied: false,
  };

  dataFromLS = getObjFromLS('role');

  tableRowOptions = [
    {
      label: 'Edit',
      onClick: (id) => this.toggleEditRole(id),
      disabled: (id) => hasEditPermission,
      icon: EditIcon,
    },
    {
      label: 'Delete',
      onClick: (id) => this.openDeleteModal(id),
      disabled: (id) => hasDeletePermission,
      icon: DeleteIcon,
    },
  ];

  componentDidMount() {
    hasEditPermission =
      this.dataFromLS.permissions.RoleEdit === false
        ? !this.dataFromLS.permissions.RoleEdit
        : false;
    hasDeletePermission =
      this.dataFromLS.permissions.RoleDelete === false
        ? !this.dataFromLS.permissions.RoleDelete
        : false;
    this.fetchData();
  }

  fetchData() {
    this.setState({
      loading: true,
    });
    restRequest('get', 'roles')
      .then((res) => {
        this.setState({
          roles: res,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error);
        this.setState({
          accessDenied: true,
          loading: false,
        });
      });
  }

  toggleNewRole = () => {
    this.setState((state) => {
      return {
        ...state,
        newRoleToggle: !state.newRoleToggle,
      };
    });
  };

  toggleEditRole = (id) => {
    this.setState((state) => {
      this.setState({
        editRoleToggle: !state.editRoleToggle,
        selectedRoleId: id, // null if called function with no id, works for now, check later
      });
    });
  };

  onSubmitRole = () => {
    this.setState(
      {
        newRoleToggle: false,
        editRoleToggle: false,
      },
      () => {
        this.fetchData();
        let scrollTopDiv = document.getElementsByClassName(
          'detail_component_inner'
        );
        scrollTopDiv[0].scrollTop = 0;
      }
    );
  };

  openDeleteModal = (id) => {
    this.setState({
      showDeleteModal: true,
      deleteRoleId: id,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
    });
  };

  deleteRole = () => {
    restRequest('delete', `roles/${this.state.deleteRoleId}`)
      .then((response) => {
        this.props.handleToast('Role has been deleted.', 'success');
        this.closeDeleteModal();
        this.fetchData();
      })
      .catch((error) => {
        this.closeDeleteModal();
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
      });
  };

  renderDeleteModal = () => {
    return (
      <CustomModal
        type="delete"
        showModal={this.state.showDeleteModal}
        title="Confirm Role Delete"
        onClose={this.closeDeleteModal}
        onSubmit={this.deleteRole}
      >
        Are you sure you want to delete the Role?
      </CustomModal>
    );
  };

  renderRolesTableForm() {
    if (this.state.newRoleToggle) {
      return (
        <div className="padding--regular">
          <button
            className="new-role-form--close-button"
            onClick={this.toggleNewRole}
          >
            <MdClose className="material-icon blue" />
          </button>
          <RoleForm
            title="Add Role"
            type="add"
            handleToast={this.props.handleToast}
            onCancel={this.toggleNewRole}
            onSubmit={this.onSubmitRole}
            handleConfirmFormInviteForm={this.props.handleConfirmFormInviteForm}
          />
        </div>
      );
    }
    if (this.state.editRoleToggle) {
      return (
        <div className="padding--regular">
          <RoleForm
            title="Edit Role"
            type="edit"
            id={this.state.selectedRoleId}
            onCancel={this.toggleEditRole}
            handleToast={this.props.handleToast}
            onSubmit={this.onSubmitRole}
            handleConfirmFormInviteForm={this.props.handleConfirmFormInviteForm}
            setPrompt={this.props.setPrompt}
          />
        </div>
      );
    }
    const tableHeaders = ['Role Name', 'Role Type', 'Created On', ''];
    const tableData = ['name', 'slug', 'created_on', 'options'];
    return (
      <Fragment>
        {this.renderDeleteModal()}
        {/* <button className="button--primary float-right margin-bottom-md margin--regular-right" onClick={() => this.setState({ newRoleToggle: true })}>
          New Role
        </button> */}
        <div className="package-item-info package_details_table">
          <Table
            className="list-table with-actions"
            list={this.state.roles}
            tableHeaders={tableHeaders}
            tableData={tableData}
            options={this.tableRowOptions}
          />
        </div>
      </Fragment>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.accessDenied)
      return <AccessDenied className="small" type="information" />;
    return this.renderRolesTableForm();
  }
}
