import React, { useState } from 'react';
import getStatusColor from '../../Helpers/GetStatusColor';
// import { Link } from "react-router-dom"
import Customer from '../../Pages/Contact/ContactDetails/index';
import './Details.css';
import CustomModal from '../CustomModal/CustomModal';
import { NewContactIcon } from '../../Assets/Navigation/NavigationIcons';
import DropshipIcon from '../../Assets/General/DropshipIcon';
import BackorderIcon from '../../Assets/General/BackorderIcon';

export const DetailsHeader = ({
  title = '',
  no = '',
  status = '',
  contactID = '',
  inModal = false,
  customer = '',
  type = null,
  utilizedCredits = 0,
  refundCredits = 0,
  actionMenu = () => null,
  myClass = '',
  Icon,
  // payment_type = '',
  track = '',
}) => {
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const contactCreateModal = () => {
    return (
      <CustomModal
        size="large"
        className="contacts_modal_view"
        showModal={showCustomerModal}
        title="View Contact"
        // minWidth={120}
        modaltitle="inventory_modal_title"
        Icon={NewContactIcon}
        renderActions={false}
        onClose={() => setShowCustomerModal(false)}
        linkTo={`contacts/${contactID}`}
      >
        <Customer id={contactID} inModal />
      </CustomModal>
    );
  };
  return (
    <div
      className={`details-header ${myClass} `}
      style={{ paddingLeft: '0rem' }}
    >
      {contactCreateModal()}
      <div className="details-header--title-info">
        <h3
          className="details-header--title"
          style={{
            color: '#33a348',
            fontWeight: '700',
            textTransform: 'uppercase',
            fontSize: '18px'
          }}
        >
          {title} {Icon && status !== 'not shipped' && <Icon />}
        </h3>

        <div>
          {customer && (
            <h5 className={!inModal ? 'customer_name' : ''}>
              {inModal ? (
                <u>{customer}</u>
              ) : (
                <div
                  className={!inModal ? 'pointer' : ''}
                  onClick={() => !inModal && setShowCustomerModal(true)}
                  to={`/contacts/${contactID}`}
                >
                  <u>{customer}</u>
                </div>
              )}
            </h5>
          )}
        </div>

        <div className="details-header--info d-inline">
          {title === 'Sales Order' && <b>Sales Order No. </b>}
          {title === 'Package' && <b>Package No. </b>}
          {title === 'Sales Return' && <b>Sales Return No. </b>}
          {title === 'CREDIT NOTE' && <b>Credit Note No. </b>}
          {title === 'Purchase Order' && <b>Purchase Order No. </b>}
          {title === 'Bill' && <b>Bill No. </b>}
          {title === 'Payments Details' && (
            // payment_type === 'invoice_payment' &&
            <b>Payment No. </b>
          )}
          {title === 'Payments Details' ? (<span>{no}</span>) : no}
          {track && (
            <div style={{ marginTop: '10px' }}>
              <span
                className="status status--confirmed"
                style={{ marginLeft: '0' }}
              >
                {track}
              </span>
            </div>
          )}
          <div className="float-left w-100" style={{ marginTop: '10px' }}>
            {title === 'Sales Return' && (
              <span
                style={{ margin: '0' }}
                className={
                  status === 'receiving'
                    ? getStatusColor('partially received')
                    : status === 'closed'
                      ? getStatusColor('received')
                      : getStatusColor(status)
                }
              >
                {status === 'receiving'
                  ? 'PRTL Received'
                  : status === 'closed'
                    ? 'Received'
                    : status}
              </span>
            )}
            {title !== 'Sales Return' && (
              <span style={{ margin: '0' }} className={getStatusColor(status)}>
                {status === 'partially paid'
                  ? 'PRTL Paid'
                  : status === 'partially applied'
                    ? 'PRTL Applied'
                    : status}
              </span>
            )}
            {type && (type === 'dropship' || type === 'dropshipped') && (
              <div title="Dropship" className="dropshipicon-set">
                {' '}
                <DropshipIcon />
              </div>
            )}
            {type && (type === 'backorder' || type === 'backordered') && (
              <div title="Backorder" className="dropshipicon-set">
                {' '}
                <BackorderIcon />
              </div>
            )}
          </div>
          {title === 'CREDIT NOTE' && (
            <ul className="credit_status_row">
              {utilizedCredits > 0 && (
                <div className="credit_status">
                  <span className="dot"></span>
                  <li>Paid on Invoice</li>
                </div>
              )}
              {refundCredits > 0 && (
                <div className="credit_status">
                  <span className="dot"></span>
                  <li>Refunded</li>
                </div>
              )}
            </ul>
          )}
        </div>
      </div>
      <div className="details-header--action">{actionMenu()}</div>
    </div>
  );
};
