import React, { useEffect } from 'react';
import { AddSalesReturn } from '../../Components/Form';
import { FormLayout } from '../../Components';
import { SalesReturnIcon } from '../../Assets/Navigation/NavigationIcons';
import { validateSalesReturnForm } from '../../Helpers/FormValidator';
import useToggleClick from '../../Hooks';
const EditSalesReturn = (props) => {
  const [, handleCrossAlert, handleCrossClick] = useToggleClick();

  useEffect(() => {
    document.title = 'Edit Sales Return';
  }, []);

  return (
    <FormLayout
      title="Sales Returns"
      Icon={SalesReturnIcon}
      subtitle="Edit"
      closeUrl="/salesreturns"
      iconstyle="salereturn_general_module_icon"
      navigate={false}
      handleCrossClick={handleCrossClick}
      validator={validateSalesReturnForm}
      layoutBoxClass="uiux-form-redesign"
    >
      <AddSalesReturn
        type="edit"
        id={
          props.idFromHeaderWrapper
            ? props.idFromHeaderWrapper
            : props.match.params.id
        }
        handleToast={props.handleToast}
        handleCrossAlert={handleCrossAlert}
      />
    </FormLayout>
  );
};

export default EditSalesReturn;
