import React, { Component, Fragment } from 'react';
import moment from 'moment';

import { restRequest } from '../../../../Helpers/RequestHelper';
import getOverallTaxDetails from '../../../../Helpers/GetOverallTaxDetails';
import { getOrganizationDate } from '../../../../Helpers/Date/OrganizationDate';
import DownloadAttachmentIcon from '../../../../Assets/General/DownloadAttachmentIcon';
import DeleteAttachmentIcon from '../../../../Assets/General/DeleteAttachmentIcon';

import AttachIcon from '../../../../Assets/General/AttachmentIcon';

import { pdfDownlaod } from '../../../../Helpers/PdfDownload';
import { downloadAttachment } from '../../../../Helpers/downloadAttachment';
import Loader from '../../../../Components/Loader/Loader';
import HeaderWrapper from '../../../../Components/HeaderWrapper/HeaderWrapper';
import { DetailsHeader } from '../../../../Components/Details/Details';
import { ActionMenu } from '../../../../Components/CheckedMenu/CheckedMenu';

import BillInfo from './Info/BillInfo';
import BillSummary from './Summary/BillSummary';
import PDFViewer from '../../../../Components/PDFViewer/PDFViewer';
import Box from '../../../../Components/Layout/Box';
import NotFound from '../../../../Components/NotFound/NotFound';

import { NewBillIcon } from '../../../../Assets/Navigation/NavigationIcons';
import Payments from './Tabs/Payments/Payments';
import pageIdentify from '../../../../Helpers/PageIdentify';
import { getObjFromLS, setObjInLS } from '../../../../Helpers/LocalStorage';
import AccessDenied from '../../../../Components/AccessDenied/AccessDenied';
import controllerNames from '../../../../Constants/ControllerNames';
import MarkAsOpenIcon from '../../../../Assets/General/MarkAsOpenIcon';
import RecordPaymentForm from '../../../../Components/Form/RecordPaymentForm/RecordPaymentForm';
import CustomModal from '../../../../Components/CustomModal/CustomModal';

import Menu from '../../../../Components/Menu/Menu';
import MenuData from '../../../../Components/Menu/MenuData';
import History from '../../../../Assets/General/History';
import RecordPaymentIcon from '../../../../Assets/General/RecordPaymentIcon';
import OverlayLoader from '../../../../Components/Loader/OverlayLoader';
import getCurrentMenu from '../../../../Helpers/getCurrentMenu';
import { checkError } from '../../../../Helpers/AuthHelper';
import Notes from '../../../../Components/Notes/Notes';
import TermsCondition from '../../../../Components/Terms/Terms';
import { HeaderMenus } from '../../../../Components';
import useMobileDetection from '../../../../Hooks/MobileDetect';

let idForActivity;
let checkup = [];
let flag = false;
class BillDetails extends Component {
  id = this.props.id
    ? this.props.id
    : this.props.idFromHeaderWrapper
      ? this.props.idFromHeaderWrapper
      : this.props.match.params.id;
  state = {
    billInfo: {},
    activityLog: [],
    currency: {},
    detailsNo: '',
    pdf: null,
    currentMenu: getCurrentMenu(window.location.search),
    showPDF: false,
    attachments: [],
    loading: true,
    showRecordPaymentModal: false,
    notFound: false,
    warehouseList: {},
    type: '',
    showFileDelteModal: false,
    attachmentId: null,
    processingAttachment: false,
    showPrompt: false,
  };

  componentDidMount() {
    if (this.props.idFromHeaderWrapper) {
      this.id = this.props.idFromHeaderWrapper;
    }
    document.title = 'Bill Details';
    this.fetchData();
    idForActivity = this.id;
    checkup = this.state.activityLog;
  }

  componentDidUpdate() {
    // document.title = 'Bill Details'
    if (this.state.activityLog !== checkup && flag) {
      this.setState({
        activityLog: checkup,
      });
    }
    // restRequest('get', `bills/${this.id}/activity-log`).then((res)=>{

    //   this.setState({

    //     activityLog :res.activity_log ,

    //   })
    // })
  }
  componentWillUnmount() {
    flag = false;
  }

  fetchData = async () => {
    let dataFromLS = getObjFromLS('module');
    if (dataFromLS && dataFromLS.moduleName === 'bills' && dataFromLS.id) {
      this.id = dataFromLS.id;
    }
    this.setState({ loading: true });

    let getBillDetails = () => {
      return restRequest('get', `bills/${this.id}`);
    };

    restRequest('get', `bills/${this.id}`)
      .then((res) => {
        let billDetails = res
        let billInfo = billDetails.bill_info;
        let currency = billDetails.base_currency;
        let activityLog = billDetails.activity_log;

        this.setState({
          warehouseList: billDetails.warehouses,
          billInfo,
          currency,
          attachments: billInfo.attachments,
          detailsNo: billInfo.bill_no,
          activityLog,
          loading: false,
        });
        checkup = activityLog;
        let dataFromLS = getObjFromLS('module');
        if (
          dataFromLS &&
          dataFromLS.queryParam &&
          dataFromLS.queryParam.includes(this.state.currentMenu)
        ) {
          let query = dataFromLS.queryParam;
          query = query.replace(`&${this.state.currentMenu}=true`, '');
          query = query.replace(`?${this.state.currentMenu}=true`, '');
          setObjInLS('module', {
            ...dataFromLS,
            queryParam: query,
            presistDetailPage: true,
          });
          let newurl =
            window.location.protocol +
            '//' +
            window.location.host +
            window.location.pathname +
            query;
          window.history.pushState({ path: newurl }, '', newurl);
        } else {
          setObjInLS('module', {
            ...dataFromLS,
            presistDetailPage: true,
          });
        }
      })
      .catch((error) => {
        checkError(error);
        this.setState({
          loading: false,
          notFound: true,
        });
      });
  };

  handleTabChange(tab) {
    this.setState({ currentMenu: tab });
  }

  menus() {
    return [
      {
        id: 'history',
        label: 'History',
        billId: this.id,
        url: `bills/${this.id}/activity-log`,
        icon: () => <History />,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('history');
        },
        renderContent: this.renderMenuForHistory,
      },
      {
        id: 'payments',
        label: 'Payments',
        billId: this.id,
        url: `bills/${this.id}/activity-log`,
        history: this.props.history,
        forceRedirectFlag: this.props.forceRedirectFlag,
        handleLoad: this.handleLoad,
        icon: () => <RecordPaymentIcon />,
        billAmount: this.state.billInfo.total,
        billStatus: this.state.billInfo.status,
        updateStatusOnPaymentChange: this.updateStatusOnPaymentChange,
        currency: this.state.currency,
        handleToast: this.props.handleToast,
        hasPermissionCustom: this.hasPermissionCustom,
        hasViewPermission: this.hasViewPermission('payment'),
        inModal: this.props.inModal,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('payments');
        },
        renderContent: this.renderMenuForBillPayment,
      },
    ];
  }

  renderMenuForHistory() {
    return <MenuData name="History" billId={this.billId} url={this.url} />;
  }
  renderMenuForBillPayment() {
    return (
      <MenuData
        name="payments"
        url={this.url}
        billId={this.billId}
        currency={this.currency}
        history={this.history}
        handleToast={this.handleToast}
        forceRedirectFlag={this.forceRedirectFlag}
        hasPermissionCustom={this.hasPermissionCustom}
        hasViewPermission={this.hasViewPermission}
        handleLoad={this.handleLoad}
        billAmount={this.billAmount}
        billStatus={this.billStatus}
        updateStatusOnPaymentChange={this.updateStatusOnPaymentChange}
        inModal={this.inModal}
      />
    );
  }

  handleAddAttachment(event) {
    document.body.style.cursor = 'wait';
    this.setState({ processingAttachment: true });
    const { handleToast } = this.props;
    let selectedFiles = Array.from(event.target.files);
    if (selectedFiles.length > 10) {
      handleToast('Cannot add more than 10 attachments', 'error');
      setTimeout(() => {
        document.body.style.cursor = 'default';
        this.setState({ processingAttachment: false });
      }, 2000);
      return null;
    }
    let discardedFiles = [];
    let newFiles = selectedFiles.filter((file) => {
      const MAX_FILE_SIZE = 5; // max file size = 5MB
      let fileSize = file.size / 1000 / 1000; // converted from bytes to MB
      if (fileSize > MAX_FILE_SIZE) {
        discardedFiles.push(file);
      }
      return fileSize <= MAX_FILE_SIZE;
    });
    if (!!discardedFiles.length) {
      let errorMessage = discardedFiles.map((file) => {
        return `${file.name} exceeds the file size limit`;
      });
      handleToast(errorMessage, 'error');
    }

    const fd = new FormData();
    let newFileCount = this.state.attachments.length;
    newFiles.forEach((file, index) => {
      // fd.append(`files[${index}]`, file)
      if (newFileCount <= 9) {
        fd.append(`files[${index}]`, file);
        newFileCount++;
      } else {
        // break;
        handleToast('Cannot add more than 10 attachments', 'error');
        return null;
      }
    });
    fd.append('subjectId', this.id);
    fd.append('name', this.state.billInfo.bill_no);
    restRequest('post', 'bills/attachments', fd)
      .then(() => {
        handleToast('Attachment added successfully', 'success');
        this.fetchData();
        document.body.style.cursor = 'default';
        this.setState({ processingAttachment: false });
      })
      .catch((errorMessage) => {
        document.body.style.cursor = 'default';
        this.setState({ processingAttachment: false });
        checkError(errorMessage, handleToast);
        //handleToast(errorMessage, "error")
      });
  }

  async downloadSingleFile(attachment) {
    this.setState({ processingAttachment: true });
    await downloadAttachment(
      `bills/${attachment.id}/download-attachment`,
      this.props.handleToast,
      'single'
    );
    this.setState({ processingAttachment: false });
  }

  async downloadAllFiles() {
    this.setState({ processingAttachment: true });
    await downloadAttachment(
      `bills/${this.id}/download-all`,
      this.props.handleToast,
      'multiple'
    );
    this.setState({ processingAttachment: false });
  }

  deleteAllFiles() {
    this.setState({ processingAttachment: true, showFileDelteModal: false });

    document.body.style.cursor = 'wait';
    restRequest('delete', `bills/attachment/delete_all/${this.id}`)
      .then((res) => {
        document.body.style.cursor = 'default';
        this.props.handleToast(`Attachment deleted successfully`, 'success');
        this.fetchData();
        this.setState({
          showFileDelteModal: false,
          attachmentId: null,
          type: '',
          processingAttachment: false,
        });
      })
      .catch((error) => {
        document.body.style.cursor = 'default';
        //this.props.handleToast(error, 'error')
        checkError(error, this.props.handleToast);
        this.setState({ processingAttachment: false });
      });
  }

  deleteSignleFile(attachment) {
    this.setState({ processingAttachment: true, showFileDelteModal: false });

    document.body.style.cursor = 'wait';
    restRequest('delete', `bills/attachment/${this.state.attachmentId}`)
      .then((res) => {
        document.body.style.cursor = 'default';
        this.props.handleToast(`Attachment deleted successfully`, 'success');
        this.fetchData();
        this.setState({
          showFileDelteModal: false,
          attachmentId: null,
          type: '',
          processingAttachment: false,
        });
      })
      .catch((error) => {
        document.body.style.cursor = 'default';
        //this.props.handleToast(error, 'error')
        checkError(error, this.props.handleToast);
        this.setState({ processingAttachment: false });
      });
  }

  attachmentOptions() {
    const [hasCreatePermission, , hasDeletePermission] = this.hasPermission(
      //hasEditPermission
      'bill',
      'All'
    );
    let obj = [];
    this.state.attachments &&
      this.state.attachments.forEach((attachment) => {
        obj.push({
          label: attachment.file_name,
          icon1: DownloadAttachmentIcon,
          id: attachment.id,
          type: 'attachment',
          onClickForIcon1: () => this.downloadSingleFile(attachment),
          icon2: hasDeletePermission && DeleteAttachmentIcon,
          onClickForIcon2: () => {
            this.showFileDeleteModal(attachment.id, 'single');
          },
        });
      });
    if (this.state.attachments.length > 0)
      obj.push({
        label: 'All Attachments',
        type: 'attachment',
        icon1: DownloadAttachmentIcon,
        icon2: hasDeletePermission && DeleteAttachmentIcon,
        onClickForIcon1: () => this.downloadAllFiles(),
        onClickForIcon2: () => {
          this.showFileDeleteModal(null, 'multiple');
        },
      });
    return [
      hasCreatePermission && {
        label: this.state.processingAttachment
          ? 'Processing...'
          : 'Attach New File',
        type: 'addNewAttachment',
        onClick: (e) => this.handleAddAttachment(e),
      },
      !hasCreatePermission &&
      this.state.attachments.length === 0 && {
        label: 'No Attachments Available',
        onClick: (e) => null,
      },
      ...obj,
    ];
  }

  // attachmentOptions (){
  //   let obj=[]
  //   this.state.attachments  && this.state.attachments.map(attachment =>{
  //     obj.push({
  //       label : attachment.file_name,
  //       icon1: DownloadAttachmentIcon ,
  //       id:attachment.id,
  //       type:'attachment',
  //       onClickForIcon1: ()=>this.downloadSingleFile(attachment),
  //       icon2: DeleteAttachmentIcon ,
  //       onClickForIcon2: ()=>{this.deleteSignleFile(attachment)},
  //     })
  //   })
  //   if(this.state.attachments.length > 0)
  //   obj.push({
  //     label: 'All Attachments',
  //     type:'attachment',
  //     icon1: DownloadAttachmentIcon ,
  //     icon2: DeleteAttachmentIcon ,
  //     onClickForIcon1:() =>this.downloadAllFiles(),
  //     onClickForIcon2: ()=>this.deleteAllFiles(),
  //   })
  //   return [
  //     {
  //       label: 'Attach New File',
  //       type:'addNewAttachment',
  //       onClick: (e) => this.handleAddAttachment(e)
  //     },
  //     ...obj,
  //   ]
  // }

  showFileDeleteModal(attId, filetype) {
    this.setState({
      showFileDelteModal: true,
      attachmentId: attId,
      type: filetype,
    });
  }
  renderFileDeleteModal() {
    return (
      <Fragment>
        <CustomModal
          type="delete"
          showModal={this.state.showFileDelteModal}
          title={`Confirm Delete`}
          onClose={() => this.setState({ showFileDelteModal: false })}
          onSubmit={() => {
            this.state.type === 'single'
              ? this.deleteSignleFile()
              : this.deleteAllFiles();
          }}
        >
          Are you sure you want to delete{' '}
          {this.state.type === 'single'
            ? 'this attachment?'
            : 'all attachments?'}
        </CustomModal>
      </Fragment>
    );
  }

  openPdf(id) {
    const [hasMobile] = useMobileDetection();
    if (hasMobile) {
      window.open(`/print/${id}/bills`, '_blank');
    } else {
      this.setState({ showPDF: true });
      restRequest('get', `bills/${id}/pdf`)
        .then((res) => {
          this.setState({ pdf: res });
        })
        .catch((error) => checkError(error));
    }
  }

  closeAllModals = () => {
    this.setState({
      showPDF: false,
      showRecordPaymentModal: false,
      pdf: null,
    });
  };

  updateStatusOnPaymentChange = () => {
    restRequest('get', `bills/${this.id}`)
      .then((res) => {
        if (pageIdentify(window.location.pathname)) {
          this.setState({ billInfo: res.bill_info });
        } else {
          this.props.history.push('/r');
        }
      })
      .catch((error) => checkError(error));
  };

  updateBillStatus(status) {
    if (!pageIdentify(window.location.pathname)) {
      this.props.history.push('/r');
    } else {
      let original_status = status;
      if (status === 'open') {
        let currentOrgTime = moment(getOrganizationDate());
        if (currentOrgTime.isAfter(this.state.billInfo.due_date)) {
          status = 'overdue';
        }
      }
      this.setState((state) => ({
        billInfo: {
          ...state.billInfo,
          status,
          original_status,
        },
      }));
    }
  }

  markAsOpen(id) {
    restRequest('put', `bills/${id}/markasopen`)
      .then((res) => {
        if (pageIdentify(window.location.pathname)) {
          this.props.handleToast('Bill has been marked as open.', 'success');
          let status = this.state.billInfo.total === 0 ? 'paid' : 'open';
          this.updateBillStatus(status);
        } else {
          this.props.history.push('/r');
        }
      })
      .catch((error) => checkError(error, this.props.handleToast));
  }

  markAsVoid(id) {
    if (this.state.billInfo.status !== 'void') {
      restRequest('put', `bills/${id}/markasvoid`)
        .then((res) => {
          if (pageIdentify(window.location.pathname)) {
            this.props.handleToast('Bill has been marked as void.', 'success');
            this.updateBillStatus('void');
          } else {
            this.props.history.push('/r');
          }
        })
        .catch((error) => checkError(error, this.props.handleToast));
    } else
      this.props.handleToast(
        'This bill has already been marked as Void',
        'error'
      );
  }
  convertToDraft(id) {
    if (this.state.billInfo.status !== 'draft') {
      restRequest('put', `bills/${id}/converttodraft`)
        .then(() => {
          if (pageIdentify(window.location.pathname)) {
            this.props.handleToast('Bill has been marked as draft.', 'success');
            this.updateBillStatus('draft');
          } else {
            this.props.history.push('/r');
          }
        })
        .catch((error) => checkError(error, this.props.handleToast));
    } else
      this.props.handleToast(
        'This bill has already been marked as Draft',
        'error'
      );
  }
  handleLoad() {
    restRequest('get', `bills/${idForActivity}/activity-log`)
      .then((res) => {
        // this.setState({
        //   activityLog :res.activity_log
        // })
        checkup = res.activity_log;
        flag = true;
      })
      .catch((error) => {
        checkError(error);
      });
  }
  hasPermissionCustom(name, type) {
    let permissions = getObjFromLS('role').permissions;
    if (permissions.superAccess) {
      if (type === 'All') return [true, true, true];
      return true;
    }

    if (!['Create', 'Edit', 'Delete', 'All'].includes(type))
      throw new Error('Type must be one of Create, Edit, Delete, All');
    if (!(name in controllerNames))
      throw new Error('Invalid name is receieved for permission check');
    if (type !== 'All') {
      return permissions[`${controllerNames[name]}${type}`];
    }
    return [
      permissions[`${controllerNames[name]}Create`],
      permissions[`${controllerNames[name]}Edit`],
      permissions[`${controllerNames[name]}Delete`],
    ];
  }
  populateConditionalOptions(id) {
    const hasEditPermission = this.hasPermission('bill', 'Edit');
    const options = [];

    if (!hasEditPermission) return options;

    if (this.state.billInfo.status === 'draft') {
      options.push({
        label: 'Mark as Open',
        onClick: () => this.markAsOpen(id),
        icon: MarkAsOpenIcon,
      });
    }
    return options;
  }

  checkIfEditable = (bill) => {
    if (
      bill.status === 'void' ||
      bill.status === 'overdue' ||
      (bill.payment_status === 'paid' && !bill.total !== 0) ||
      bill.payment_status === 'partially paid' ||
      bill.is_billed_from_receive === 1 ||
      bill.is_receive_from_bill === 1
    )
      return false;
    return true;
  };

  checkIfCreateable = (bill) => {
    if (bill.status === 'void' || bill.status === 'paid' || bill.total === 0)
      return false;
    return true;
  };

  renderActionMenu() {
    const id = this.id;
    const { billInfo } = this.state;
    const count = this.state.attachments.length;
    const [, hasEditPermission, hasDeletePermission] = this.hasPermission(
      //hasCreatePermission
      'bill',
      'All'
    );
    const options = this.populateConditionalOptions(id);
    const menusList = [
      {
        type: 'options',
        options: options,
        className: 'options-dropdown',
        enable: !!options.length,
      },
      {
        type: 'link',
        icon: 'edit',
        enable: this.state.loading
          ? false
          : hasEditPermission && this.checkIfEditable(billInfo),
        to: `/bills/edit/${id}`,
        tooTip: 'Edit',
        mobileLable: 'Edit',
        isMobile: true,
      },
      {
        type: 'button',
        icon: 'pdf',
        tooTip: 'Download PDF',
        isMobile: true,
        mobileLable: 'Download PDF',
        handleClick: () =>
          pdfDownlaod(
            `bills/${id}/pdf?download=true`,
            this.state.detailsNo,
            this.props.handleToast
          ),
      },
      {
        type: 'button',
        icon: 'print',
        isMobile: true,
        mobileLable: 'Print',
        tooTip: 'Print',
        handleClick: () => this.openPdf(id),
      },
      {
        type: 'dropdown',
        className: 'attachemnt_buttons',
        options: this.attachmentOptions(),
        // dropdownLable: 'Attachments',
        enable: true,
        attachment: true,
        attachmentCount: count,
        AttachmentIcon: AttachIcon,
      },
      billInfo.status !== 'paid' &&
      billInfo.status !== 'partially paid' && {
        type: 'button',
        icon: 'delete',
        tooTip: 'Delete',
        mobileLable: 'Delete',
        isMobile: true,
        handleClick: () =>
          this.openDeleteModal(
            id,
            this.props.forceRedirect,
            this.props.forceRedirectFlag
          ),
        enable: this.state.loading ? false : hasDeletePermission,
      },
      this.state.loading
        ? false
        : this.hasPermissionCustom('payment', 'Create') &&
        this.checkIfCreateable(billInfo) && {
          type: 'button',
          className: 'button--secondary package_button float-left mr-3',
          handleClick: () => this.openNewRecordPaymentModal(),
          label: `Make Payment`,
          icon: 'receivePayment',
        },
      {
        type: 'button',
        className: 'button--primary float-left mr-3',
        handleClick: () => this.props.history.push(`/bills/add`),
        label: `New`,
        icon: 'new',
      },
    ];
    return (
      <ActionMenu>
        <HeaderMenus menusList={menusList} loading={this.state.loading} />
      </ActionMenu>
    );
  }

  openNewRecordPaymentModal = () => {
    this.setState({ showRecordPaymentModal: true });
    // {this.props.handleLoad()}
  };

  print() {
    this.setState({ loading: true });
    restRequest('get', `bills/${this.id}/print`)
      .then((res) => {
        var w = window.open();
        w.document.write(res.body);
        w.document.close();
        setTimeout(function () {
          w.focus();
          w.print();
          w.close();
        }, 3000);

        this.setState({ loading: false });
      })
      .catch((error) => {
        //this.props.handleToast(err,'error')
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
  }

  handlePrompt = (status = true) => {
    this.setState({
      showPrompt: status,
    });
  };

  renderLayoutView() {
    const {
      billInfo,
      currency,
      showPDF,
      pdf,
      attachments,
      note,
      warehouseList,
    } = this.state;

    const {
      // bill_no: billNo,
      // status,
      bill_item_details: billItemDetails,
    } = billInfo;

    return (
      <div className="float-left w-100 position-relative sales-return-main bill_set_main">
        {this.renderFileDeleteModal()}
        <Menu
          menus={this.menus()}
          currentMenu={this.state.currentMenu}
          history={this.props.history}
          inModal={this.props.inModal || false}
        />
        <div className="float-left w-100 overflow_custom_height">
          <div id="printSO" className="sale_Order_Details">
            <div className="float-left w-100 sales_order_info">
              <Fragment>
                <PDFViewer
                  showPDF={showPDF}
                  hidePDFModal={this.closeAllModals}
                  pdf={pdf}
                />
                <div className="onprint">
                  <DetailsHeader
                    myClass="details_header_package"
                    title="Bill"
                    no={billInfo.bill_no}
                    status={billInfo.status}
                    customer={billInfo.vendor.display_name}
                    contactID={billInfo.vendor.id}
                    inModal={this.props.inModal}
                  />
                  <BillInfo
                    currency={currency}
                    bill={billInfo}
                    note={note}
                    history={this.props.history}
                    inModal={this.props.inModal}
                  />
                </div>
                <BillSummary
                  warehouseList={warehouseList}
                  bill={billInfo}
                  currency={currency}
                  inModal={this.props.inModal}
                  overallTax={getOverallTaxDetails(billItemDetails)}
                />
                {
                  <Fragment>
                    <hr className="float-left w-100 mb-1 border_top_light" />
                    <hr className="margin float-left w-100 divider" />
                  </Fragment>
                }
                {/*{this.renderNotes(billInfo.note, billInfo.terms_and_condition)}*/}
                <Notes note={billInfo.note} />
                <TermsCondition term={billInfo.terms_and_condition} />
                {billInfo.note && (
                  <hr className="float-left w-100 mb-1 border_top_light" />
                )}
                {attachments.length > 0 && (
                  <div className="float-left w-100">
                    <div className="bottom_attachment_area">
                      <AttachIcon />{' '}
                      <span className="attachment_span">
                        {' '}
                        {attachments.length} Attachment(s) added
                      </span>
                    </div>
                  </div>
                )}
                {attachments.length > 0 && (
                  <hr className="float-left w-100 mb-1 border_top_light" />
                )}
              </Fragment>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNotes(note, term) {
    return (
      <Fragment>
        {!!note && (
          <div className="notes">
            <h5 className="heading">Notes</h5>
            <p>{note}</p>
          </div>
        )}

        {!!term && (
          <Fragment>
            <div className="notes">
              <h5 className="heading">Terms & Conditions</h5>
              <p>{term}</p>
            </div>
            <hr className="float-left w-100 mb-1 border_top_light" />
          </Fragment>
        )}
      </Fragment>
    );
  }

  newRecordPaymentModal() {
    return (
      <CustomModal
        showModal={this.state.showRecordPaymentModal}
        title="New Record Payment"
        Icon={RecordPaymentIcon}
        onClose={this.closeAllModals}
        renderActions={false}
        classNames="new-recieve-payment-popup"
        showPrompt={this.state.showPrompt}
      >
        <RecordPaymentForm
          type="bill"
          id={this.id}
          handleToast={this.props.handleToast}
          onSubmit={this.handleRecordPaymentSubmit}
          onClose={this.closeAllModals}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }
  handleRecordPaymentSubmit = () => {
    this.props.history.push('/r');
  };

  renderSidebar() {
    const { billInfo, currency } = this.state;
    const { handleToast } = this.props;
    return (
      <Box title="Payments">
        {!this.hasViewPermission('payment') ? (
          <AccessDenied className="small dashboard" type="section" />
        ) : (
          <Payments
            billId={this.id}
            handleLoad={this.handleLoad}
            billAmount={billInfo.total}
            billStatus={billInfo.status}
            updateStatusOnPaymentChange={this.updateStatusOnPaymentChange}
            currency={currency}
            handleToast={handleToast}
            hasPermissionCustom={this.hasPermissionCustom}
          />
        )}
      </Box>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    return (
      <Fragment>
        {this.newRecordPaymentModal()}
        {this.renderLayoutView()}
        {this.state.processingAttachment && <OverlayLoader opacity={0.7} />}
      </Fragment>
      // <GridLayout>
      //   <Grid transparent>
      //     <Box className="no-padding">
      //       {this.renderLayoutView()}
      //     </Box>
      //     {
      //       pageIdentify(window.location.pathname) &&
      //       <Box title="Activity">
      //         <div>
      //           {
      //             this.state.activityLog.map(activity => {
      //               return (
      //                 <TimelineCard
      //
      //                   key={activity.id}
      //                   name={activity.creator}
      //                   description={activity.description}
      //                   date={activity.date}
      //                   time={activity.time}
      //                   subject={activity.subject}
      //                 />
      //               )
      //             })
      //           }
      //         </div>
      //       </Box>
      //     }
      //   </Grid>
      //
      //   <Grid transparent>{this.renderSidebar()}</Grid>
      // </GridLayout>
    );
  }
}

export default HeaderWrapper(BillDetails, {
  name: 'Bills',
  deleteName: 'Bill',
  // tableIcon:'bills_details_header_icon',
  tableIcon: 'general_module_icon',
  Icon: NewBillIcon,
  baseUrl: 'bills',
  redirectUrl: '/bills',
  onlyMenu: true,
  showName: true,
  permissionName: 'bill',
});
