import React from 'react';
import { SmallLoader } from '../../Components';
import { ToolTip } from '../../Common';
import classNames from './Button.module.css';

const Button = ({
  clickHandler = () => {},
  btnText = 'button',
  className = '',
  disabled = false,
  btnLoading = false,
  tolTip = false,
  tolTipText = '',
}) => (
  <button
    onClick={clickHandler}
    className={`${className} ${btnLoading ? classNames.btnDiv : ''}`}
    disabled={disabled}
    title={tolTip && tolTipText ? tolTipText : 'Add New'}
  >
    {/* <span className={classNames.btntext}> */}
    {btnLoading ? <SmallLoader className="btn-loader" /> : btnText}
    {/* </span> */}
  </button>
);
export default Button;
