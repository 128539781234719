import React, { Component } from 'react';
import './Switch.css';

export default class Switch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // animate: "",
    };
  }
  render() {
    const { options, value, handleChange } = this.props;
    return (
      <div className="switch-wrapper">
        {options.map((option, index) => (
          <span
            key={index}
            className={`switch-label ${option.value === value ? 'switch-active' : ''
              }`}
            onClick={() => {
              handleChange(option);
            }}
          >
            {option.label}
          </span>
        ))}
      </div>
    );
  }
}
