import React, { Component } from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import moment from 'moment';
import { restRequest, checkError, getOrganizationDate } from '../../../Helpers';
import { Loader } from '../..';
import CustomerPaymentFormEnhancer from './CPFormEnhancer';
import './CP.css';
import './../../Table/Table.css';

class CustomerPaymentForm extends Component {
  type = this.props.type || 'add';

  id = this.props.id || null;
  contactId = window.location.search.split('=')[1] || null;
  state = {
    loading: true,
    salesOrder: {
      contactId: null,
      salesOrderNo: '',
      reference: '',
      customerNotes: '',
      termsAndConditions: '',
      items: [],
    },
    paymentDate: moment(getOrganizationDate()),
    selectedPaymentMethod: null,
    customerList: [],
    paymentMethods: [],
    invoiceList: [],
    paymentId: null,
    payment_mode: null,
    organizationCurrency: {},
    showPrompt: false,
  };

  componentDidMount() {
    this.fetchData();
    sessionStorage.setItem('once', false);
    sessionStorage.setItem('fullscreen', false);
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  fetchData = async () => {
    this.setState({ loading: true });
    const { type, id } = this;

    switch (type) {
      case 'edit':
        await this.fetchSalesOrderEditCloneData(this.getCloneEditUrl(id, type));
        break;
      case 'add':
      default:
        await this.fetchCreateCustomerPayment();
        break;
    }

    this.setState({ loading: false });
  };

  getCloneEditUrl(id, type) {
    return `salesorders/${id}/${type === 'edit' ? 'edit' : 'clone'}`;
  }

  fetchCreateCustomerPayment() {
    return restRequest(
      'get',
      `advancepayment/invoice/create?customer_id=${this.contactId}`
    )
      .then((res) => {
        this.setState({
          organizationCurrency: res.base_currency,
          paymentMethods: res.payment_methods,
          invoiceList: res.invoices,
          paymentId: res.payment_no,
          customerList: res.customer_list,
          selectedPaymentMethod: res.payment_methods[0],
        });
      })
      .catch((error) => checkError(error));
  }

  submitRequestType = () => {
    if (this.type === 'edit') {
      return 'put';
    }
    return 'post';
  };

  submitRequestUrl = () => {
    switch (this.type) {
      case 'edit':
        return `salesorders/${this.props.id}`;
      case 'add':
      default:
        return 'advancepayment/invoice';
    }
  };

  successMessage = () => {
    switch (this.type) {
      case 'clone':
        this.props.handleToast('Sales Order has been cloned', 'success');
        break;
      case 'edit':
        this.props.handleToast('Sales Order has been updated', 'success');
        break;
      case 'add':
      default:
        this.props.handleToast('Sales Order created successfully', 'success');
    }
  };

  handlePrompt = (status = true) => this.setState({ showPrompt: status });

  renderSalesOrderForm() {
    const { handleToast, history } = this.props;
    let {
      salesOrder,
      customerList,
      organizationCurrency,
      paymentMethods,
      invoiceList,
      payment_mode,
      paymentId,
      paymentDate,
    } = this.state;
    return (
      <>
        <Prompt
          when={this.state.showPrompt}
          message="Are you sure you want to leave page while changes made?"
        />
        <CustomerPaymentFormEnhancer
          type={this.type}
          id={this.id}
          contactIdTransaction={this.contactId}
          salesOrder={{ ...salesOrder }}
          customerList={customerList}
          paymentMethods={paymentMethods}
          invoiceList={invoiceList}
          organizationCurrency={organizationCurrency}
          submitRequestType={this.submitRequestType}
          submitRequestUrl={this.submitRequestUrl}
          successMessage={this.successMessage}
          history={history}
          paymentDate={paymentDate}
          handleToast={handleToast}
          setPrompt={this.handlePrompt}
          payment_mode={payment_mode}
          paymentId={paymentId}
        />
      </>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return this.renderSalesOrderForm();
  }
}

export default withRouter(CustomerPaymentForm);
