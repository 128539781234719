import React, { Fragment, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import DeleteIcon from '../../../Assets/General/DeleteIcon';
import { MdHelpOutline } from '../../../Common/Icons';
import AddContact from '../../../Pages/Contact/AddContact';
import {
  restRequest,
  formatAccountList,
  findByValue,
  getObjFromLS,
  setObjInLS,
  checkError,
} from '../../../Helpers';
import CustomModal from '../../CustomModal/CustomModal';
import Field from './../../Form/Field/Field';
import Form from './../../Form/Form';
import FormSection from './../../Form/FormSection/FormSection';
import { formSubmit, getError, validate } from '../FormHelper';
import TaxForm from '../../Form/TaxForm/TaxForm';
import FormAction from '../FormAction';
import ImagePreview from '../../ImagePreview/ImagePreview';
import ErrorFocus from '../ErrorFocus';
import AccountForm from '../../../Pages/Account/AccountForm';
import ItemAccountsIcon from '../../../Assets/General/ItemAccountsIcon';
import SmallLoader from '../../SmallLoader/SmallLoader';
import ImageCropper from '../../../Components/Cropper/ImageCropper';
import { validateCreateItemGroupFormStepOne } from '../../../Helpers/FormValidator';
import {
  required,
  ValidateMax10Billion,
  ValidateRequired,
  ValidateMax50,
  ValidateMax15,
  ValidateMax200,
  ValidateMax240,
  wrapValidateMax,
  ValidateMax255,
} from '../../../Helpers/Validator';
import PopOver from '../../../Common/Popover';
import './ItemGroup.css';

const getYupValidationSchema = (_, props) => {
  return Yup.object().shape({
    name: required(ValidateMax240),
    description: ValidateMax255,
    unit: required(ValidateMax15),
    manufacturer: ValidateMax200,
    brand: wrapValidateMax(Yup.string().nullable().trim(), 200),
    salesAccountID: ValidateRequired,
    purchaseAccountID: ValidateRequired,
    attributesArr:
      props.type === 'add'
        ? Yup.array().of(
            Yup.lazy((attr) => {
              return Yup.object().shape({
                attribute: required(ValidateMax50),
              });
            })
          )
        : Yup.mixed().notRequired(),
    itemsList:
      props.type === 'add'
        ? Yup.array().of(
            Yup.lazy((item) => {
              return Yup.object().shape({
                name: ValidateRequired,
                sku: required(ValidateMax50),
                reorderPoint: ValidateMax10Billion,
                sellingPrice: ValidateMax10Billion,
                costPrice: ValidateMax10Billion,
                upc: wrapValidateMax(Yup.string().nullable(), 13),
                ean: wrapValidateMax(Yup.string().nullable(), 14),
                isbn: wrapValidateMax(Yup.string().nullable(), 17),
              });
            })
          )
        : Yup.mixed().notRequired(),
  });
};
// let modalflag = false;
const formEnhancer = withFormik({
  validate: validate(getYupValidationSchema),
  mapPropsToValues: ({ item }) => ({
    ...item,
    loadingAccounts: {
      salesAccounts: false,
      purchaseAccounts: false,
    },
    showAccountModal: false,
    showTaxModal: false,
    showImageCropModal: false,
    showContactCreateModal: false,
  }),
  mapPropsToStatus: (props) => ({
    salesAccounts: props.salesAccounts,
    purchaseAccounts: props.purchaseAccounts,
    vendorsList: props.vendorsList,
    taxList: props.taxList,
    emptyItem: props.emptyItem,
    itemsList: [],
    allWarehouses: props.allWarehouses,
    warehousesList: props.warehousesList,
    currency: props.currency,
    emptyWarehouse: props.emptyWarehouse,
    accountType: '', // sales, purchase
  }),
  handleSubmit: (values, bag) => {
    handleFormSubmit(values, bag);
  },
});
const handleFormSubmit = (
  values,
  {
    setSubmitting,
    status,
    props: {
      requestUrl,
      history,
      updateStep,
      handleToast,
      trackInventory,
      successMessage,
      type,
      onSubmit: onSubmitItem,
      closeModal: closeItem,
      inModal: inModalItem,
      setPrompt,
    },
  }
) => {
  let checkOptions = values.attributesArr.filter(
    (opt) => opt.variations.options && opt.variations.options.length === 0
  );
  if (checkOptions && checkOptions.length > 0 && type === 'add') {
    handleToast('Please fill options against attributes', 'error');
    setSubmitting(false);
    return;
  }
  if (type === 'add' && values.itemsList.length === 0) {
    handleToast('At least one item is required', 'error');
    setSubmitting(false);
    return;
  }

  let newItemsList = [];
  let attributesValues = [];
  if (type === 'add') {
    newItemsList = values.itemsList.map((item) => ({
      name: item.name,
      sku: item.sku,
      ean: item.ean,
      isbn: item.isbn,
      upc: item.upc,
      purchase_unit_price: item.costPrice || 0,
      sales_unit_price: item.sellingPrice || 0,
      reorder_level: item.reorderPoint || 0,
      opening_stock_value: item.purchase_unit_price,
      variant: item.variant,
    }));
    // let x = values.optionsArr.filter(function (el) {
    //   return el;
    // });
    // let y = values.attributesArr.filter(function (el) {
    //   return el;
    // });
    // y.forEach(function (attr, index) {
    //   attributesValues.push({
    //     attr: attr.attribute,
    //     options: x[index].options,
    //   });
    // });
    attributesValues = values.attributesArr.map((item, index) => {
      return {
        attr: item.attribute,
        options: item.variations.options,
      };
    });
  }
  let data = new FormData();
  data.append('type', values.type);
  data.append('name', values.name);
  data.append('unit', values.unit);
  data.append('manufacturer', values.manufacturer);
  data.append('brand', values.brand);
  data.append('description', values.description);
  values.taxID && data.append('tax_id', values.taxID);
  values.salesAccountID &&
    data.append('sales_account_id', values.salesAccountID);
  values.purchaseAccountID &&
    data.append('purchase_account_id', values.purchaseAccountID);
  for (let [index, imageUrl] of values.images.entries()) {
    data.append(`images[${index}]`, imageUrl);
  }
  for (let [index, file] of values.base84Image.entries()) {
    data.append(`base84Image[${index}]`, file);
  }
  if (type === 'add') {
    for (let [index, item] of newItemsList.entries()) {
      data.append(`items[${index}]`, JSON.stringify(item));
    }
    for (let [index, attr] of attributesValues.entries()) {
      data.append(`attributes[${index}]`, JSON.stringify(attr));
    }
  }
  restRequest('post', requestUrl, data)
    .then((res) => {
      setPrompt(false);
      if (inModalItem) {
        onSubmitItem(res);
        closeItem();
      } else {
        successMessage();
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS === null) {
          let obj = {
            prevId: null,
            id: null,
            nextId: null,
            moduleName: 'item_groups',
            urlPath: '/item-groups',
            queryParam: window.location.search,
          };
          setObjInLS('module', obj);
          dataFromLS = getObjFromLS('module');
        }
        if (type === 'add') {
          res.data.items.map((el) => {
            if (el) {
              el['trackInventory'] = trackInventory;
              el['warehouses'] = [values.warehouses[0]];
              el['warehouses'][0].openingStockValue = el.opening_stock_value;
              el['warehousesList'] = [];
            }
          });
          setObjInLS('itemsList', res.data.items);
          setObjInLS('itemGroup', res.data.item_group);
          values.itemsList && updateStep();
        } else if (type === 'clone') {
          setObjInLS('module', {
            ...dataFromLS,
            moduleName: 'item_groups',
            queryParam: dataFromLS.dataFromLS ? dataFromLS.dataFromLS : '',
            id: type === 'clone' ? res.data.id : dataFromLS.id,
            urlPath: '/item-groups',
          });
          return history.push('/r');
        }
        if (type === 'edit') {
          if (res.data.editStocks && res?.data?.items.length !== 0) {
            res.data.items.map((el) => {
              if (el) {
                el['trackInventory'] =
                  el.inventory_type === 'inventory' ? true : false;
                el['warehousesList'] = [];
                el['warehouses'] =
                  el.stocks.length === 0
                    ? [values.warehouses[0]]
                    : el.stocks.map((stock) => ({
                        id: stock.warehouse.id,
                        name: stock.warehouse.name,
                        address: stock.warehouse.address,
                        city: stock.warehouse.city,
                        state: stock.warehouse.state,
                        country: stock.warehouse.country
                          ? stock.warehouse.country.country_name
                          : '',
                        zipCode: stock.warehouse.zip_code,
                        openingStock: stock.opening_quantity,
                        openingStockValue: el.opening_stock_value,
                        is_active: 1,
                      }));
              }
            });
            setObjInLS('itemsList', res.data.items);
            setObjInLS('itemGroup', res.data.item_group);
            values.itemsList && updateStep();
          } else {
            setObjInLS('module', {
              ...dataFromLS,
              action: type === 'add' ? 'add' : 'justedit',
              queryParam: dataFromLS.queryParam ? dataFromLS.queryParam : '',
            });
            return history.push('/r');
          }
        }
      }
    })
    .catch((error) => {
      checkError(error);
      handleToast(error, 'error');
      setSubmitting(false);
    });
};

const ItemGroupForm = ({
  // props from formik
  values,
  status,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  setValues,
  setStatus,
  isSubmitting,
  submitCount,
  errors,
  touched,
  suggestions,
  onSuggestionsFetchRequested,
  attributeSuggestions,
  onSuggestionsClearRequested,
  onSuggestionsClearRequestedAttribute,
  onSuggestionsFetchRequestedAttribute,
  removeSuggestion,
  // custom props
  type,
  handleToast,
  history,
  trackInventory,
  allWarehouses,
  warehousesList,
  // handleTrackInventory,
  closeModal: closeItem,
  inModal: inModalItem,
  submitButtonText,
  ...props
}) => {
  // let validation;
  var base64Images = [];
  const [num, setNum] = useState(1);
  const [comboFlag, setComboFlag] = useState(true);
  const [createComboFlag] = useState(true);
  const [comboList, setComboList] = useState([]);
  const [showPrompt, setShowPrompt] = useState(false);
  const [staticVal, setStaticValues] = useState({});
  const [popovers, setPopovers] = useState({
    costPrice: false,
    sellingPrice: false,
    reorderPoint: false,
  });

  useEffect(() => {
    handleAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, comboFlag, comboList, staticVal]);

  useEffect(() => {
    let itemsList = [];
    setStaticValues({
      ...values,
      comboList,
      itemsList,
      attributesArr: [
        {
          attribute: '',
          variations: { options: [] },
        },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAlert = () => {
    let { checkout } = validateCreateItemGroupFormStepOne(
      {
        ...values,
        comboList,
      },
      staticVal
    );
    if (!checkout) props.setPrompt();
    else props.setPrompt(false);
  };

  useEffect(() => {
    handleCombination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comboFlag, values.name]);

  useEffect(() => {
    values['itemsList'] = [...status.itemsList];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleFiles = (accepted, rejected) => {
    if (rejected.length) {
      if (rejected.length === 1) {
        handleToast('1 invalid file/file size.', 'error');
      } else {
        handleToast(`${rejected.length} invalid files/file sizes.`, 'error');
      }
    }

    if (accepted.length) {
      let previousFiles = [...values.uploadImages];
      if ([...previousFiles, ...accepted].length > 5) {
        handleToast('Cannot add more than 5 images', 'error');
        return null;
      }
      let updatedFiles = accepted.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      if (
        values.images.length +
          values.uploadImages.length +
          updatedFiles.length >
        5
      ) {
        handleToast('Cannot add more than 5 images', 'error');
        return null;
      }
      setFieldValue('imageUpload', [...previousFiles, ...updatedFiles]);
      setFieldValue('showImageCropModal', true);
    }
  };

  const handleImageDelete = (image) => {
    if (typeof image === 'string') {
      // previous uploaded image (url)
      setFieldValue(
        'images',
        values.images.filter((el) => el !== image)
      );
    } else {
      setFieldValue(
        'uploadImages',
        values.uploadImages.filter((el) => el !== image)
      );
      setFieldValue(
        'base84Image',
        values.base84Image.filter((el) => el !== image.preview)
      );
    }
  };

  const handleTaxChange = (option) => {
    if (option !== null) {
      // Add New
      if (option.value === 'createNew') {
        setFieldValue('showTaxModal', true);
      } else {
        // tax selected
        const taxID = option.value;
        setFieldValue('taxID', taxID);
      }
    } else {
      // deselect tax
      setFieldValue('taxID', null);
    }
  };

  const handleAccountModal = (accountType) => {
    // sales, purchase
    setStatus({
      ...status,
      accountType: accountType === 'sales' ? 'sales' : 'purchase',
    });
    setFieldValue('showAccountModal', true);
  };

  function closeModal() {
    setValues({
      ...values,
      showAccountModal: false,
      showTaxModal: false,
      showImageCropModal: false,
      showContactCreateModal: false,
    });
  }

  const updateAccount = (accountType, newAccount) => {
    let accountListState =
      accountType === 'sales' ? 'salesAccounts' : 'purchaseAccounts';
    let accountState =
      accountType === 'sales' ? 'salesAccountID' : 'purchaseAccountID';
    let ItemGroupFormType = type === 'add' ? 'create' : 'edit';

    setValues({
      ...values,
      loadingAccounts: {
        ...values.loadingAccounts,
        [accountListState]: true,
      },
    });

    restRequest(
      'get',
      `items/${ItemGroupFormType}/accounts?type=${accountType}`
    )
      .then((accounts) => {
        setStatus({
          ...status,
          [accountListState]: accounts,
        });
        setValues({
          ...values,
          [accountState]: newAccount.id,
          showAccountModal: false,
          loadingAccounts: {
            ...values.loadingAccounts,
            [accountListState]: false,
          },
        });
      })
      .catch((error) => {
        checkError(error, handleToast);
        setValues({
          ...values,
          loadingAccounts: {
            ...values.loadingAccounts,
            [accountListState]: false,
          },
        });
      });
  };

  const accountDropDownField = (accountType) => {
    // sales, purchase
    const accountList =
      accountType === 'sales' ? status.salesAccounts : status.purchaseAccounts;
    const formattedAccountList = formatAccountList(accountList);
    return (
      <div className="flex-50 position-relative">
        <Field
          className="overForm w-100 flex-100"
          type="groupdropdown"
          size="large"
          name={`${accountType} Account`}
          value={
            accountType === 'sales'
              ? findByValue(values.salesAccountID, formattedAccountList)
              : findByValue(values.purchaseAccountID, formattedAccountList)
          }
          id={accountType === 'sales' ? 'salesAccountID' : 'purchaseAccountID'}
          options={formattedAccountList}
          handleChange={(option) => {
            setFieldValue(
              accountType === 'sales' ? 'salesAccountID' : 'purchaseAccountID',
              option.value
            );
            // setFieldValue(
            //   accountType === 'sales' ? 'sales_update' : 'purchase_update',
            //   true
            // );
          }}
          clearable={false}
        />
        {!inModalItem && (
          <button
            className="button form-field--side-btn account_addnew new_click_set"
            type="button"
            onClick={() => handleAccountModal(accountType)}
          >
            Add new
          </button>
        )}
      </div>
    );
  };
  const handlePrompt = (status = true) => setShowPrompt(status);
  /*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/
  String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  };
  const newAccountModal = () => {
    let { accountType } = status;
    return (
      <CustomModal
        className="widthClass"
        showModal={values.showAccountModal}
        fill="#000000"
        Icon={ItemAccountsIcon}
        title={`New ${accountType.capitalize()} Account`}
        renderActions={false}
        onClose={closeModal}
        classNames="new-sales-account"
        showPrompt={showPrompt}
      >
        <AccountForm
          accountType={accountType}
          inModal
          onSubmit={(account) => updateAccount(accountType, account)}
          onClose={closeModal}
          handleToast={handleToast}
          history={history}
          handlePrompt={handlePrompt}
        />
      </CustomModal>
    );
  };

  const contactCreateModal = () => (
    <CustomModal
      size="large"
      showModal={values.showContactCreateModal}
      title="Add Contact"
      customWidth="contact-modal-width"
      renderActions={false}
      onClose={closeModal}
    >
      <AddContact
        type="purchaseOrder"
        inModal
        onSubmit={(res) => {
          var vendor = res.data;
          vendor.value = res.data.id;
          status.vendorsList.push(res.data);
          setStatus({
            ...status,
            vendorsList: [...status.vendorsList, res.data],
          });
          setValues({
            ...values,
            vendorID: res.data.id,
            showContactCreateModal: false,
          });
        }}
        handleToast={handleToast}
      />
    </CustomModal>
  );

  const newTaxModal = () => (
    <CustomModal
      className="smallWidthClass"
      showModal={values.showTaxModal}
      title="Add New Tax"
      renderActions={false}
      onClose={closeModal}
      showPrompt={showPrompt}
    >
      <TaxForm
        inModal
        type="add"
        onSubmitModal={(newitem) => {
          setStatus({
            ...status,
            taxList: [newitem, ...status.taxList],
          });
          setValues({
            ...values,
            taxID: newitem.id,
            showTaxModal: false,
          });
        }}
        closeModal={closeModal}
        handleToast={handleToast}
        handlePrompt={handlePrompt}
      />
    </CustomModal>
  );
  const hanldeImageUpload = (updatedFiles, croppedImage) => {
    updatedFiles[updatedFiles.length - 1].preview = croppedImage;
    updatedFiles.forEach((el) => {
      base64Images.push(el.preview);
      setFieldValue('base84Image', base64Images);
    });
    setFieldValue('uploadImages', updatedFiles);
    setFieldValue('showImageCropModal', false);
  };
  const ImageCropModal = () => (
    <CustomModal
      className="smallWidthClass"
      showModal={values.showImageCropModal}
      title="Adjust Image"
      renderActions={false}
      onClose={closeModal}
    >
      <ImageCropper
        inModal
        updatedFiles={values.imageUpload}
        type="add"
        onSubmitModal={(updatedFiles, croppedImage) => {
          hanldeImageUpload(updatedFiles, croppedImage);
        }}
        closeModal={closeModal}
        handleToast={handleToast}
      />
    </CustomModal>
  );
  const renderModals = () => {
    return (
      <Fragment>
        {contactCreateModal()}
        {ImageCropModal()}
        {newAccountModal()}
        {newTaxModal()}
      </Fragment>
    );
  };
  const addAttribute = () => {
    setNum((prev) => prev + 1);
    values.attributesArr.push({ attribute: '', variations: { options: [] } });
    setValues({ ...values });
  };
  const removeAttribute = (i) => {
    values.attributesArr.splice(i, 1);
    setNum((prev) => prev - 1);
    setValues({ ...values });
    handleCombination();
  };
  let getProducts = (arrays) => {
    if (arrays.length === 0) {
      return [[]];
    }

    let results = [];
    getProducts(arrays.slice(1)).forEach((product) => {
      arrays[0].forEach((value) => {
        results.push([value].concat(product));
      });
    });

    return results;
  };

  let getAllCombinations = (options) => {
    let attributeNames = values.attributesArr.map((item) => item.attribute);
    let attributeValues = options;
    let checkEmptyOpions = attributeValues.filter((item) => item.length !== 0);
    if (checkEmptyOpions.length) {
      let emptyArrayIndexes = attributeValues
        .map((item, index) => {
          if (item.length === 0) {
            return index;
          }
        })
        .filter((und) => und !== undefined);
      attributeNames = attributeNames.filter(
        (i, index) => !emptyArrayIndexes.includes(index)
      );
      attributeValues = attributeValues.filter((item) => item.length !== 0);
    }
    let itemName = [];
    let varients = getProducts(attributeValues).map((product) => {
      let arr = [];
      let sinle = values.name;
      product.forEach((prod, index) => {
        sinle = index === 0 ? sinle + ' - ' + prod : sinle + ' /  ' + prod;
      });
      itemName.push(sinle);

      attributeNames.forEach((name, i) => {
        arr = [...arr, { [name]: product[i] }];
      });
      return arr;
    });
    return [itemName, varients];
  };

  // const getPossibleCombinations = (options = []) => {
  //   let itemName = values.name;
  //   // console.log(values.attributesArr);
  //   getAllCombinations(options);
  //   // console.log({ options, itemName });
  //   if (values.attributesArr.length === 1) {
  //     // values.attributesArr.varientyoloo = options[0].map((d) => ({
  //     //   [values.attributesArr[0].attribute]: d,
  //     // }));
  //     // console.log(values.attributesArr);
  //     return options[0].map((d) => `${itemName} - ${d}`);
  //   } else if (values.attributesArr.length === 2) {
  //     options[0].forEach((element) => {
  //       options[1].forEach((element2) => {
  //         // console.log(element, element2);
  //       });
  //     });

  //     return options[0].flatMap((d) =>
  //       options[1].map((v) => itemName + ' - ' + d + ' / ' + v)
  //     );
  //   } else if (values.attributesArr.length === 3) {
  //     let array2 = options[0].flatMap((d) =>
  //       options[1].map((v) => d + ' / ' + v)
  //     );
  //     return array2.flatMap((d) =>
  //       options[2].map((v) => itemName + ' - ' + d + ' / ' + v)
  //     );
  //   }
  // };
  // const handleVarient = (item, y) => {
  //   return y.map((val, i) => ({
  //     [val.attribute]: item,
  //   }));
  // };
  const prepareAddItemGroups = (itemsArray, y) => {
    return itemsArray[0].map((item, index) => ({
      lastindex: item.lastIndexOf('/'),
      id: null,
      name: status.itemsList[index]?.updatedName || item,
      sku: status.itemsList[index]?.sku || '',
      ean: status.itemsList[index]?.ean || '',
      isbn: status.itemsList[index]?.isbn || '',
      upc: status.itemsList[index]?.upc || '',
      unit: status.itemsList[index]?.unit || '',
      costPrice: status.itemsList[index]?.costPrice || null,
      sellingPrice: status.itemsList[index]?.sellingPrice || null,
      reorderPoint: status.itemsList[index]?.reorderPoint || null,
      variant: itemsArray[1][index],
      updatedName: status.itemsList[index]?.updatedName || '',
    }));
  };
  const handleCombination = async () => {
    let x = values.attributesArr.map((val) => val.variations.options);
    let y = values.attributesArr.map((el) => ({
      attribute: el.attribute,
      variations: (el.variations && el.variations) || { options: [] },
    }));
    let allPossibleCombinations = getAllCombinations(x);

    let result = prepareAddItemGroups(allPossibleCombinations, y);
    status.itemsList = result;
    // values.itemsList = result;
    setValues({
      ...values,
      itemsList: result,
    });
    setComboList(allPossibleCombinations[0]);
  };
  const handleRemoveItem = (val, index) => {
    status.itemsList.splice(index, 1);
    setStatus({ ...status });
    // handleCombination();
  };

  /*const copyValue = (copy) => {
    let copiedValue = status.itemsList[0][copy];
    status.itemsList.forEach(function (item, index) {
      if (index > 0) {
        item[copy] = copiedValue;
      }
    });
    setStatus({ ...status });
  };*/

  const copyAllValue = (copy) => {
    let copiedValue = status.itemsList[0][copy];
    copiedValue = copiedValue ? copiedValue : 0;
    status.itemsList.forEach(function (item, index) {
      item[copy] = copiedValue;
    });
    setStatus({ ...status });
  };

  const generateSku = () => {
    setFieldValue('skuLoading', true);
    restRequest('get', `itemgroups/generatesku/${status.itemsList.length}`)
      .then((res) => {
        status.itemsList.forEach(function (item, index) {
          item.sku = res[index];
        });
        setStatus({ ...status });
        setFieldValue('skuLoading', false);
      })
      .catch((error) => {
        setFieldValue('skuLoading', false);
        handleToast(error, 'error');
        checkError(error);
      });
  };

  const handleItemPriceChange = (unitPriceValue, index, field) => {
    let unitPrice = unitPriceValue.floatValue;
    if (unitPrice > 0) {
      let unitPriceString = unitPrice.toString();

      // Price_Quantity_Limit

      // price and quantity limit from 5 digits to 10 digits
      if (unitPriceString.length > 10) {
        unitPriceString = unitPriceString.slice(0, 10);
      }
      if (field === 'reorderPoint') unitPrice = parseInt(unitPriceString);
      else unitPrice = parseFloat(unitPriceString);
    }
    status.itemsList[index][field] = unitPrice;
    setStatus({ ...status });
  };
  const togglePopover = (key) => {
    setPopovers({
      // ...popovers,
      [key]: !popovers[key],
    });
  };

  const handleArrowsKey = (e, index, fieldname) => {
    if (e.key === 'ArrowDown' && index + 1 < status?.itemsList?.length) {
      const element = document.getElementById(
        `itemsList[${index + 1}].${fieldname}`
      );
      setTimeout(() => {
        element.select();
        element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
      }, 100);
    }
  };

  const handleArrowUpKey = (e, index, fieldname) => {
    if (e.key === 'ArrowUp' && index - 1 >= 0) {
      const element = document.getElementById(
        `itemsList[${index - 1}].${fieldname}`
      );
      element.select();
      element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
    }
  };

  const renderCombinationList = () => (
    <table
      style={{ width: '100%' }}
      className="list-table form-table border create_sales_order  horizontal-main-set item-group-tbl form-focus"
    >
      <thead>
        <tr>
          <th>
            <span className="th-title">Item Name</span>
          </th>
          <th className="left-align">
            <div
              className={`${
                values.skuLoading ? 'd-flex td-group-inner' : 'td-group-inner'
              }`}
            >
              <span className="sku-span">
                SKU<span className="red"> *</span>
              </span>
              {values.skuLoading ? (
                <span className="display-flex-row-reverse">
                  <SmallLoader />
                </span>
              ) : (
                <button
                  className="attribute_btn float-left w-100 group-btn-sm"
                  type="button"
                  onClick={() => generateSku()}
                >
                  Generate SKU
                </button>
              )}
            </div>
          </th>
          <th className="left-align">
            <div className="td-group-inner_2">
              <span className="th-title">Cost Price</span>
              <span className="icon_div">
                <MdHelpOutline
                  className="material-icon blue-icon help"
                  style={{ marginRight: '1.7rem' }}
                />
                <div className="tooltip_tooltip">
                  Cost price will also be considered as item Opening Stock Value
                  (per unit)
                </div>
              </span>
              {/* <button
                className="attribute_btn float-left w-100 group-btn-sm"
                type="button"
                onClick={() => copyValue('costPrice')}
              >
                Copy to all
              </button> */}

              <PopOver
                title="Copy to all"
                titleClass="group-btn-sm"
                mainClassName="float-left"
                titleClick={() => togglePopover('costPrice')}
                show={popovers.costPrice}
                cancelHandler={() => togglePopover('costPrice')}
                onYesClickHandler={() => {
                  copyAllValue('costPrice');
                  togglePopover('costPrice');
                }}
              >
                <div>
                  Would you like {status.itemsList[0]['costPrice'] || 0} amount
                  to be reflected in all below field
                </div>
              </PopOver>
            </div>
          </th>
          <th className="left-align">
            <span className="th-title">Selling Price</span>
            {/* <button
              className="attribute_btn float-left w-100 group-btn-sm"
              type="button"
              onClick={() => copyValue('sellingPrice')}
            >
              Copy to all
            </button> */}

            <PopOver
              title="Copy to all"
              titleClass="group-btn-sm"
              mainClassName="float-left"
              titleClick={() => togglePopover('sellingPrice')}
              show={popovers.sellingPrice}
              cancelHandler={() => togglePopover('sellingPrice')}
              onYesClickHandler={() => {
                copyAllValue('sellingPrice');
                togglePopover('sellingPrice');
              }}
            >
              <div>
                Would you like {status.itemsList[0]['sellingPrice'] || 0} amount
                to be reflected in all below field
              </div>
            </PopOver>
          </th>
          <th className="left-align">
            <span className="th-title">UPC</span>
          </th>
          <th className="right-align">
            <span className="th-title">EAN</span>
          </th>
          <th className="left-align">
            <span className="th-title">ISBN</span>
          </th>
          <th className="left-align">
            <span className="th-title">Reorder Level</span>
            {/* <button
              className="attribute_btn float-left w-100 group-btn-sm"
              type="button"
              onClick={() => copyValue('reorderPoint')}
            >
              Copy to all
            </button> */}

            <PopOver
              title="Copy to all"
              titleClass="group-btn-sm"
              titleClick={() => togglePopover('reorderPoint')}
              show={popovers.reorderPoint}
              cancelHandler={() => togglePopover('reorderPoint')}
              onYesClickHandler={() => {
                copyAllValue('reorderPoint');
                togglePopover('reorderPoint');
              }}
            >
              <div>
                Would you like {status.itemsList[0]['reorderPoint'] || 0} value
                to be reflected in all below field
              </div>
            </PopOver>
          </th>
          <th className="right-align item-group-heading"></th>
        </tr>
      </thead>
      <tbody>
        {status.itemsList &&
          status.itemsList.map((item, index) => (
            <tr
              key={index}
              className="table--row table_row_desc item-group-items"
            >
              <td className="left-align no-padding icon_div item_group_add">
                <Field
                  size="large"
                  id={`itemsList[${index}].name`}
                  // autoFocus={true}
                  value={item.name}
                  type="text"
                  handleChange={(change) => {
                    status.itemsList[index].name = change.target.value;
                    status.itemsList[index].updatedName = change.target.value;
                    setStatus({ ...status });
                  }}
                  required
                  error={getError(errors, touched, `itemsList[${index}].name`)}
                  onBlur={handleBlur}
                />
                <span className="icon_div">
                  <div className="tooltip_tooltip">
                    {item.name === '' ? <p>No data</p> : item.name}
                  </div>
                </span>
              </td>
              <td className="left-align no-padding">
                <Field
                  size="large"
                  id={`itemsList[${index}].sku`}
                  // autoFocus={true}
                  value={item.sku}
                  handleChange={(change) => {
                    status.itemsList[index].sku = change.target.value;
                    setStatus({ ...status });
                  }}
                  required
                  error={getError(errors, touched, `itemsList[${index}].sku`)}
                  onBlur={() =>
                    setFieldTouched(`itemsList[${index}].sku`, true)
                  }
                  onKeyDown={(e) => handleArrowsKey(e, index, 'sku')}
                  onKeyUp={(e) => handleArrowUpKey(e, index, 'sku')}
                />
              </td>
              <td className="left-align no-padding">
                <Field
                  size="large"
                  id={`itemsList[${index}].costPrice`}
                  placeholder={'0'}
                  type="number"
                  decimalScale={6}
                  thousandSeparator=","
                  value={item.costPrice}
                  disabled={false}
                  allowNegative={false}
                  onValueChange={(values) =>
                    handleItemPriceChange(values, index, 'costPrice')
                  }
                  error={getError(
                    errors,
                    touched,
                    `itemsList[${index}].costPrice`
                  )}
                  onBlur={() =>
                    setFieldTouched(`itemsList[${index}].costPrice`, true)
                  }
                  onKeyDown={(e) => handleArrowsKey(e, index, 'costPrice')}
                  onKeyUp={(e) => handleArrowUpKey(e, index, 'costPrice')}
                />
              </td>
              <td className="left-align no-padding">
                <Field
                  size="large"
                  placeholder={'0'}
                  type="number"
                  decimalScale={6}
                  thousandSeparator=","
                  id={`itemsList[${index}].sellingPrice`}
                  disabled={false}
                  allowNegative={false}
                  onValueChange={(values) =>
                    handleItemPriceChange(values, index, 'sellingPrice')
                  }
                  value={item.sellingPrice}
                  error={getError(
                    errors,
                    touched,
                    `itemsList[${index}].sellingPrice`
                  )}
                  onBlur={() =>
                    setFieldTouched(`itemsList[${index}].sellingPrice`, true)
                  }
                  onKeyDown={(e) => handleArrowsKey(e, index, 'sellingPrice')}
                  onKeyUp={(e) => handleArrowUpKey(e, index, 'sellingPrice')}
                />
              </td>
              <td className="left-align no-padding">
                <Field
                  size="large"
                  id={`itemsList[${index}].upc`}
                  value={item.upc}
                  handleChange={(change) => {
                    status.itemsList[index].upc = change.target.value;
                    setStatus({ ...status });
                  }}
                  required
                  error={getError(errors, touched, `itemsList[${index}].upc`)}
                  onBlur={handleBlur}
                  onKeyDown={(e) => handleArrowsKey(e, index, 'upc')}
                  onKeyUp={(e) => handleArrowUpKey(e, index, 'upc')}
                />
              </td>
              <td className="left-align no-padding">
                <Field
                  size="large"
                  id={`itemsList[${index}].ean`}
                  value={item.ean}
                  handleChange={(change) => {
                    status.itemsList[index].ean = change.target.value;
                    setStatus({ ...status });
                  }}
                  required
                  error={getError(errors, touched, `itemsList[${index}].ean`)}
                  onBlur={handleBlur}
                  onKeyDown={(e) => handleArrowsKey(e, index, 'ean')}
                  onKeyUp={(e) => handleArrowUpKey(e, index, 'ean')}
                />
              </td>
              <td className="left-align no-padding">
                <Field
                  size="large"
                  id={`itemsList[${index}].isbn`}
                  value={item.isbn}
                  handleChange={(change) => {
                    status.itemsList[index].isbn = change.target.value;
                    setStatus({ ...status });
                  }}
                  required
                  error={getError(errors, touched, `itemsList[${index}].isbn`)}
                  onBlur={handleBlur}
                  onKeyDown={(e) => handleArrowsKey(e, index, 'isbn')}
                  onKeyUp={(e) => handleArrowUpKey(e, index, 'isbn')}
                />
              </td>
              <td className="left-align no-padding">
                <Field
                  size="large"
                  id={`itemsList[${index}].reorderPoint`}
                  value={parseInt(item.reorderPoint)}
                  type="number"
                  decimalScale={6}
                  thousandSeparator=","
                  allowNegative={false}
                  onValueChange={(values) =>
                    handleItemPriceChange(values, index, 'reorderPoint')
                  }
                  placeholder={'0'}
                  error={getError(
                    errors,
                    touched,
                    `itemsList[${index}].reorderPoint`
                  )}
                  onBlur={() =>
                    setFieldTouched(`itemsList[${index}].reorderPoint`, true)
                  }
                  onKeyDown={(e) => handleArrowsKey(e, index, 'reorderPoint')}
                  onKeyUp={(e) => handleArrowUpKey(e, index, 'reorderPoint')}
                />
              </td>

              <td className="list-table--amount right-align">
                <button
                  type="button"
                  disabled={
                    status.itemsList.length === 1 &&
                    status.itemsList[0].id === null
                  }
                  className="close-btn button"
                  onClick={(option) => handleRemoveItem(item, index)}
                >
                  <DeleteIcon />
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );

  const renderAttributeProps = (i) => {
    const inputAttributes = {
      value: values.attributesArr[i].attribute,
      onChange: (event, { newValue }) => {
        if (event.target.id !== 'remove') {
          values.attributesArr[i].attribute = newValue;
          values.attributesArr[i].variations.options = [];
          setValues({ ...values });
        }
      },
      onBlur: () => setFieldTouched(`values.attributesArr${[i]}`),
    };
    return inputAttributes;
  };

  const handlechangeOption = (option, i) => {
    if (option) {
      values.attributesArr[i].variations.options =
        (option && [...option.map((a) => a.value)]) || [];
      setComboFlag((prev) => !prev);
    } else {
      values.attributesArr[i].variations.options = [];
      setComboFlag((prev) => !prev);
    }
  };

  const renderAttributeOptions = () =>
    values.attributesArr.map((item, i) => (
      <div
        key={i}
        id={'item_attribute_' + i}
        className="d-flex item-group-inner"
      >
        <Field
          className="attributes-field"
          type="autosuggest"
          name="attributes"
          id={'item_attribute_' + i}
          value={item.attribute}
          placeholder="Color, Storage, Size"
          suggestions={attributeSuggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequestedAttribute}
          onSuggestionsClearRequested={onSuggestionsClearRequestedAttribute}
          removeSuggestion={false}
          inputProps={renderAttributeProps(i)}
          required={values.type === 'goods'}
          error={getError(errors, touched, `attributesArr[${i}].attribute`)}
          onBlur={handleBlur}
        />
        <Field
          size="large"
          type="badge"
          name="Options"
          addType="itemBadge"
          dropdownValue={values.attributesArr[i].variations.options}
          // autoFocus={true}
          className={`full_width email_dropdown group_option ${
            submitCount > 0 &&
            values.attributesArr[i].variations.options.length === 0
              ? 'error'
              : ''
          }`}
          id={'item_attribute_opt' + i}
          handleChange={(option) => {
            handlechangeOption(option, i);
          }}
          required
          error={
            submitCount > 0 &&
            values.attributesArr[i].variations.options.length === 0 &&
            'Required'
          }
          onBlur={handleBlur}
        />

        <button
          className="group_delete_btn"
          type="button"
          onClick={() => num > 1 && removeAttribute(i, item)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12.5"
            height="15"
            viewBox="0 0 12.5 15"
          >
            <path
              d="M9.375-15a1.217,1.217,0,0,0-.9.352,1.217,1.217,0,0,0-.352.9v.625H4.375v1.25H5v10A1.8,1.8,0,0,0,5.557-.557,1.8,1.8,0,0,0,6.875,0h7.5a1.8,1.8,0,0,0,1.318-.557,1.8,1.8,0,0,0,.557-1.318v-10h.625v-1.25h-3.75v-.625a1.217,1.217,0,0,0-.352-.9,1.217,1.217,0,0,0-.9-.352Zm0,1.25h2.5v.625h-2.5ZM6.25-11.875H15v10a.6.6,0,0,1-.186.439.6.6,0,0,1-.439.186h-7.5a.6.6,0,0,1-.439-.186.6.6,0,0,1-.186-.439ZM7.5-10v6.875H8.75V-10ZM10-10v6.875h1.25V-10Zm2.5,0v6.875h1.25V-10Z"
              transform="translate(-4.375 15)"
            ></path>
          </svg>
        </button>
      </div>
    ));

  const inputProps = {
    value: values.unit,
    onChange: (event, { newValue }) => {
      if (event.target.id !== 'remove') {
        setFieldValue('unit', newValue);
      }
    },
    onBlur: () => setFieldTouched('unit'),
  };

  let jango;
  let dummyArr = [];
  allWarehouses.forEach(function (allWare) {
    jango = false;
    values.warehouses.forEach(function (ware) {
      if (allWare.id === ware.id) {
        jango = true;
      }
    });
    if (jango === false) {
      dummyArr.push(allWare);
    }
  });
  const handleName = (val) => {
    setFieldValue('name', val);
    !createComboFlag && handleCombination();
  };
  status.warehousesList = dummyArr;
  return (
    <Fragment>
      {renderModals()}
      <Form
        className="item-form item-group-form"
        onSubmit={(event) => {
          formSubmit(
            event,
            values.warehouses,
            setFieldValue,
            handleSubmit,
            'id',
            'warehouses'
          );
          props.handleStep();
        }}
        type={inModalItem && 'modal'}
      >
        <FormSection title="Item Info">
          <div
            className="form-image images-uploading"
            id="Click_Add_Item_Image"
          >
            <Field
              type="image"
              name="Add Images"
              accept="image/*"
              onDrop={handleFiles}
              maxSize={5 * 1000 * 1000}
              // multiple  disabled multiple image upload
              camIconflag={true}
            />
            <ImagePreview
              images={[...values.images, ...values.uploadImages]}
              onDeleteImage={handleImageDelete}
            />
          </div>
          <div className="form-fields-container d-flex flex-wrap  position-relative item-group-main">
            <div className="position-relative flex-100">
              <Field
                className="full-width-item-name"
                size="large"
                name="Item Name"
                id="name"
                autoFocus={true}
                value={values.name}
                handleChange={(e) => handleName(e.target.value)}
                onBlur={handleBlur}
                required
                error={(touched.name || submitCount > 0) && errors.name}
              />
              <p className="form-field--side-btn maximum_char">
                240 characters maximum
              </p>
            </div>
            <div className="d-flex flex-wrap w-100">
              <div className="group_main_set flex-60 d-flex flex-wrap">
                <Field
                  className="flex-50"
                  type="autosuggest"
                  name="Unit(m, kg, litre etc.)"
                  id="unit"
                  value={values.unit}
                  placeholder="m, kg, litre"
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  /*removeSuggestion={removeSuggestion}*/
                  removeSuggestion={false}
                  inputProps={inputProps}
                  required={values.type === 'goods'}
                  error={(touched.unit || submitCount > 0) && errors.unit}
                />
                <Field
                  className="flex-50"
                  name="Manufacturer"
                  id="manufacturer"
                  value={values.manufacturer}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.manufacturer && errors.manufacturer}
                />

                <Field
                  className="flex-50"
                  name="Brand"
                  id="brand"
                  value={values.brand}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.brand && errors.brand}
                />
                <Field
                  className="flex-50"
                  type="dropdown"
                  name="Item Tax"
                  id="taxID"
                  value={values.taxID}
                  options={status.taxList}
                  dropdownValue="id"
                  dropdownLabel="name"
                  handleChange={(option) => handleTaxChange(option)}
                  addButton={inModalItem ? false : true}
                />
                {accountDropDownField('purchase')}
                {accountDropDownField('sales')}
              </div>
              <div className="description-set flex-40">
                <Field
                  className="w-100 flex-100 d-block h-100"
                  size="large"
                  type="textarea"
                  name="Description"
                  id="description"
                  value={values.description}
                  handleChange={handleChange}
                  error={
                    (touched.description || submitCount > 0) &&
                    errors.description
                  }
                />
              </div>
            </div>
          </div>
          <div className="add-items-desc-sec ">
            {type === 'add' && (
              <div id="top_div" className="d-inline">
                {num < 3 && (
                  <button
                    className="attribute_btn attribute_btn_set "
                    type="button"
                    onClick={() => num < 3 && addAttribute()}
                  >
                    Add new Attribute
                  </button>
                )}
              </div>
            )}
            {type === 'add' && renderAttributeOptions()}
            {type === 'add' && Boolean(values.attributesArr.length) && (
              <div onClick={handleCombination} className="combiation_set">
                Show Combination
              </div>
            )}
            {type === 'add' && comboList.length > 0 && renderCombinationList()}
          </div>
        </FormSection>

        <FormAction
          disabled={isSubmitting}
          submitLabel={submitButtonText}
          marginFromRight={0}
          type="button"
          onSubmit={handleSubmit}
          onCancel={() => {
            let dataFromLS = getObjFromLS('module');
            let redirectUrl = '';
            if (dataFromLS && dataFromLS.moduleName === 'item_groups') {
              redirectUrl = dataFromLS.queryParam;
            }
            if (inModalItem) {
              closeItem();
            } else if (
              window.location.pathname.includes('add') ||
              window.location.pathname.includes('edit')
            ) {
              history.push('/item-groups' + redirectUrl);
            } else {
              setObjInLS('module', { ...dataFromLS, action: null });
              history.push('/r');
            }
          }}
        />

        <ErrorFocus />
      </Form>
    </Fragment>
  );
};

export default formEnhancer(ItemGroupForm);
