import React from 'react';
import Select from 'react-select';

import '../SearchableDropdown/SearchableDropdown';

export default ({
  value,
  defaultValue,
  options,
  handleChange,
  onBlur,
  size = 'medium',
  placeholder = 'Select...',
  className = '',
  disabled = false,
}) => (
  <Select
    {...(value && { value: value })}
    {...(defaultValue && { defaultValue: defaultValue })}
    placeholder={placeholder}
    options={options}
    onBlur={onBlur}
    onChange={handleChange}
    className={`searchable-dropdown ${size} ${className}`}
    classNamePrefix="searchable-dropdown"
    menuPlacement="auto"
    isDisabled={disabled}
  />
);
