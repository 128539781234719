import React, { Component, Fragment } from 'react';
import queryString from 'query-string';
import { restRequest } from '../../Helpers/RequestHelper';
import TaxIcon from '../../Assets/General/TaxIcon';
// import TaxModalIcon from '../../Assets/General/TaxModalIcon';

import ListTable from '../../Components/ListTable/ListTable';
import HeaderWrapper from '../../Components/HeaderWrapper/HeaderWrapper';
import CustomModal from '../../Components/CustomModal/CustomModal';
import './TaxList.css';
import TaxForm from '../../Components/Form/TaxForm/TaxForm';
import EditIcon from '../../Assets/General/EditIcon';
import { setValueInLS } from '../../Helpers/LocalStorage';
import DeleteIcon from '../../Assets/General/DeleteIcon';
import { checkError } from '../../Helpers/AuthHelper';
class TaxList extends Component {
  // addNew =
  state = {
    data: [],
    loading: true,
    sortFilter: 'created_at',
    orderBy: 'asc',
    showCreateTaxModal: window.location.search.slice(1).split('=')[0] === 'new',
    showEditTaxModal: false,
    taxID: null,
    totalRecords: 0,
    activePage: 1,
    showPrompt: false,
  };

  sortOptions = [
    {
      id: 'created_at',
      label: 'Created Time',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('created_at') }),
    },
    {
      id: 'name',
      label: 'Name',
      type: 'link',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('name') }),
    },
    {
      id: 'rate',
      label: 'Rate',
      type: 'link',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('rate') }),
    },
  ];

  componentDidMount() {
    setValueInLS('fullscreen', true);
    document.title = 'Tax List';
    this.fetchDataWithQueryString();
  }

  componentDidUpdate() {
    this.handleOnUpdateQueryString();
  }
  handleOnUpdateQueryString() {
    const { sort, orderBy } = this.parseQueryString();
    if (sort !== this.state.sortFilter) {
      this.setState(
        {
          sortFilter: sort,
        },
        () => this.fetchData()
      );
    }
    if (orderBy !== this.state.orderBy) {
      this.setState(
        {
          orderBy: orderBy,
        },
        () => this.fetchData()
      );
    }
  }

  fetchDataWithQueryString() {
    const { sort } = this.parseQueryString();
    this.setState(
      {
        sortFilter: sort,
      },
      () => this.fetchData()
    );
  }

  parseQueryString() {
    const query = queryString.parse(this.props.location.search);
    let filter = '';
    let sort = '';
    switch (query.sort) {
      case 'name':
      case 'rate':
      case 'created_at':
        sort = query.sort;
        break;
      default:
        sort = 'name';
    }

    let orderBy = '';
    switch (query.orderBy) {
      case 'asc':
      case 'desc':
        orderBy = query.orderBy;
        break;
      default:
        orderBy = 'asc';
    }

    let page = '';
    let view = '';

    return {
      filter,
      page,
      sort,
      orderBy,
      view,
    };
  }

  fetchData = () => {
    this.setState({ loading: true });
    restRequest(
      'get',
      `taxes?page=${this.state.activePage}&sort_by=${this.state.sortFilter}&order_by=${this.state.orderBy}`
    )
      .then((res) => {
        this.setState({
          data: this.prepareDataForTable(res),
          totalRecords: res && res.length ? res.length : 0,
          loading: false,
          showCreateTaxModal: this.props?.location?.search.includes('new')
            ? true
            : !Boolean(res.length),
        });
        let newurl =
          window.location.protocol +
          '//' +
          window.location.host +
          window.location.pathname;
        window.history.pushState({ path: newurl }, '', newurl);
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  };

  prepareDataForTable(data) {
    return data.map((tax) => ({
      id: tax.id,
      Name: {
        id: 'name',
        sortable: true,
        getValue: function () {
          return tax[this.id];
        },
      },
      Authority: {
        id: 'authority',
        getValue: function () {
          return tax[this.id];
        },
      },
      Rate: {
        id: 'rate',
        alignRight: true,
        sortable: true,
        getValue: function () {
          return `${tax[this.id]}%`;
        },
      },
    }));
  }

  hasEditPermission() {
    const hasEditPermission = this.hasPermission('tax', 'Edit');
    return hasEditPermission;
  }
  hasDeltePermission() {
    const hasDeltePermission = this.hasPermission('tax', 'Delete');
    return hasDeltePermission;
  }
  prepareRowOptions() {
    const rowOptions = [
      {
        label: 'Edit',
        onClick: (id) => this.showEditModal(id),
        icon: EditIcon,
        disabled: () => !this.hasEditPermission(),
      },
      {
        label: 'Delete',
        onClick: this.openDeleteModal,
        icon: DeleteIcon,
        disabled: () => !this.hasDeltePermission(),
      },
    ];

    return rowOptions;
  }
  showCreateModal() {
    return this.setState({ showCreateTaxModal: true });
  }

  showEditModal(id) {
    return this.setState({ showEditTaxModal: true, taxID: id });
  }

  closeAndBack(totalRecords) {
    this.setState({ showCreateTaxModal: false });
    this.setState({ showEditTaxModal: false });
    if (totalRecords === undefined || totalRecords === 0) {
      //history.push('/settings')
      window.location.href = '/settings';
    }
  }

  handlePrompt = (status = true) => {
    this.setState({ showPrompt: status });
  };

  renderCreateModal() {
    return (
      <CustomModal
        className="add_new_tax"
        showModal={this.state.showCreateTaxModal}
        title="Create New Tax modal"
        Icon={TaxIcon}
        renderActions={false}
        size="large"
        onClose={() => this.closeAndBack(this.state.totalRecords)}
        showPrompt={this.state.showPrompt}
      >
        <TaxForm
          onSubmitModal={() => {
            this.setState({ showCreateTaxModal: false });
            this.fetchData();
          }}
          closeModal={() => this.setState({ showCreateTaxModal: false })}
          inModal={true}
          type="add"
          modelSource
          handleToast={this.props.handleToast}
          totalRecords={this.state.totalRecords}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }
  renderEditModal(id) {
    return (
      <CustomModal
        className="add_new_tax"
        showModal={this.state.showEditTaxModal}
        title="Edit Tax"
        Icon={TaxIcon}
        renderActions={false}
        size="large"
        showPrompt={this.state.showPrompt}
        //onClose={() => this.setState({ showEditTaxModal: false })}
        onClose={() => this.closeAndBack(this.state.totalRecords)}
        // onSubmit={() => this.setState({showCreateTaxModal : false})}
      >
        <TaxForm
          // title="New Tax"
          onSubmitModal={() => {
            this.setState({ showEditTaxModal: false });
            this.fetchData();
          }}
          closeModal={() => this.setState({ showEditTaxModal: false })}
          inModal={true}
          type="edit"
          id={this.state.taxID}
          handleToast={this.props.handleToast}
          totalRecords={this.state.totalRecords}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  renderTable() {
    return (
      <Fragment>
        {this.renderCreateModal()}
        {this.renderEditModal()}
        <ListTable
          className="system_settings"
          data={this.state.data}
          rowOptions={this.prepareRowOptions()}
          generateRoute={this.generateRoute}
          hasCheckbox={false}
          loading={this.state.loading}
          orderBy={this.state.orderBy}
          sortFilter={this.state.sortFilter}
          totalRecords={this.state.totalRecords}
        />
      </Fragment>
    );
  }

  render() {
    return this.renderTable();
  }
}

export default HeaderWrapper(TaxList, {
  name: 'Taxes',
  Icon: TaxIcon,
  baseUrl: 'taxes',
  redirectUrl: '/taxes',
  routeName: 'taxes',
  buttonUrl: '/taxes/add',
  createModal: true,
  buttonLabel: 'Add New Tax',
  pagination: false,
  permissionName: 'tax',
  // showCreateModal:showCreateModal()
});
