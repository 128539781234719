import React, { Component } from 'react'

import { CurrencyIcon } from '../../Assets/Settings/SettingsIcon'

import CurrencyForm from '../../Components/Form/CurrencyForm/CurrencyForm'
import FormLayout from '../../Components/Layout/FormLayout'

class UpdateCurrency extends Component {
  componentDidMount() {
    document.title = 'Edit Currency'
  }

  renderCurrencyForm() {
    return (
      <CurrencyForm
        type="edit"
        handleToast={this.props.handleToast}
      />
    )
  }

  render() {
    return (
      <FormLayout
        title="Currencies"
        Icon={CurrencyIcon}
        subtitle="Edit"
        closeUrl="/currencies"
      >
        { this.renderCurrencyForm() }
      </FormLayout>
    )
  }
}

export default UpdateCurrency