import React from 'react'

export default () => (
  <svg width="260" height="201" viewBox="0 0 260 201" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.1" d="M253.699 136.983C253.466 137.153 253.252 137.319 253.024 137.48C252.182 138.076 251.3 138.615 250.385 139.092C245.591 141.624 239.682 142.861 233.926 143.701C221.017 145.586 207.838 145.953 194.714 146.312C159.255 147.287 123.71 148.26 88.337 146.013C74.3611 145.127 60.0778 143.642 47.4133 139.092C45.598 138.442 43.8184 137.725 42.0745 136.941C38.5456 135.352 35.1565 133.417 32.338 131.107C29.6896 128.941 27.5419 126.454 26.2585 123.626C23.5821 117.736 24.9983 111.12 28.0544 105.347C29.3236 103.012 30.755 100.768 32.338 98.6338C34.6976 95.3833 37.2994 92.226 39.673 88.9778C48.1191 77.4316 53.6302 62.8239 45.834 50.9813C43.5932 47.5791 40.3555 44.5922 38.5293 41.0406C36.2 36.4973 36.3793 31.4522 37.1201 26.5519C37.2163 25.9296 37.3181 25.3136 37.4252 24.7038C38.69 17.4372 41.0706 9.60831 48.4102 5.07198C56.0341 0.358299 66.8607 0.596317 76.3224 2.27177C91.0366 4.90397 104.61 10.383 118.327 15.3301C132.044 20.2771 146.395 24.7971 161.47 25.2825C174.782 25.7118 190.31 22.4986 199.145 14.147C207.726 6.03572 223.856 5.41734 235.482 10.3177C243.462 13.6803 249.416 19.7381 251.927 26.5519C252.435 27.9199 252.801 29.3368 253.019 30.7802C253.22 32.0993 253.286 33.4356 253.215 34.7682C253.18 35.4262 253.112 36.0773 253.019 36.7213C251.077 50.167 237.094 60.7727 228.594 72.7833C224.441 78.6497 221.508 85.6969 224.364 92.023C228.72 101.663 243.867 105.091 253.015 112.264C254.736 113.569 256.251 115.127 257.51 116.884C261.947 123.269 260.31 131.891 253.699 136.983Z" fill="#0071BC" />
    <path d="M60.0941 76.8062C60.0941 76.8062 49.3792 57.7835 33.2325 52.3044C26.4565 50.0036 20.5424 45.6773 16.4987 39.7642C14.5843 36.9567 12.9313 33.9791 11.5605 30.8688" stroke="#535461" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M1.40718 27.3476C3.24734 29.1281 11.8145 30.9949 11.8145 30.9949C11.8145 30.9949 9.71816 22.4729 7.87335 20.6832C6.98656 19.8419 5.80458 19.3846 4.58355 19.4104C3.36253 19.4362 2.20083 19.9429 1.35021 20.8209C0.499591 21.6988 0.0285753 22.8772 0.0392397 24.1006C0.0499041 25.3241 0.541389 26.4941 1.40718 27.357V27.3476Z" fill="#0071BC" />
    <path d="M6.25449 42.7697C8.76782 43.2691 16.9693 40.1585 16.9693 40.1585C16.9693 40.1585 10.5754 34.1474 8.05971 33.6481C6.8641 33.4372 5.6336 33.7038 4.63191 34.3909C3.63022 35.078 2.93705 36.1309 2.70096 37.3238C2.46486 38.5168 2.70463 39.7549 3.36888 40.7729C4.03313 41.7909 5.069 42.5077 6.25449 42.7697Z" fill="#0071BC" />
    <path d="M23.8988 59.4637C26.3213 58.6236 31.8185 51.7934 31.8185 51.7934C31.8185 51.7934 23.2746 49.8356 20.8544 50.6757C20.2606 50.8605 19.7099 51.1629 19.235 51.5651C18.7601 51.9672 18.3706 52.4608 18.0898 53.0166C17.809 53.5724 17.6424 54.179 17.6001 54.8005C17.5578 55.422 17.6405 56.0457 17.8434 56.6345C18.0463 57.2234 18.3653 57.7654 18.7813 58.2284C19.1973 58.6914 19.7019 59.0659 20.2652 59.3298C20.8284 59.5937 21.4389 59.7415 22.0603 59.7645C22.6817 59.7875 23.3014 59.6852 23.8825 59.4637H23.8988Z" fill="#0071BC" />
    <path d="M40.0879 69.0753C42.6175 68.6553 49.1955 62.8566 49.1955 62.8566C49.1955 62.8566 41.1058 59.48 38.5761 59.8977C37.3613 60.0985 36.2758 60.7746 35.5586 61.7771C34.8413 62.7797 34.551 64.0267 34.7514 65.2437C34.9519 66.4608 35.6267 67.5482 36.6275 68.2667C37.6283 68.9853 38.873 69.2762 40.0879 69.0753Z" fill="#0071BC" />
    <path d="M24.1062 33.4987C24.1062 36.0656 19.4709 43.5164 19.4709 43.5164C19.4709 43.5164 14.8262 36.0702 14.8239 33.5034C14.8101 32.884 14.9199 32.2682 15.1469 31.6919C15.3739 31.1157 15.7136 30.5907 16.1459 30.1477C16.5782 29.7048 17.0946 29.3529 17.6645 29.1126C18.2345 28.8723 18.8467 28.7485 19.4651 28.7485C20.0835 28.7485 20.6956 28.8723 21.2656 29.1126C21.8356 29.3529 22.3519 29.7048 22.7842 30.1477C23.2166 30.5907 23.5562 31.1157 23.7833 31.6919C24.0103 32.2682 24.1201 32.884 24.1062 33.5034V33.4987Z" fill="#0071BC" />
    <path d="M42.8993 44.996C42.1329 47.4461 35.4828 53.1632 35.4828 53.1632C35.4828 53.1632 33.2746 44.6576 34.0316 42.1957C34.4385 41.0686 35.264 40.1423 36.3361 39.6101C37.4082 39.0779 38.6439 38.981 39.7856 39.3395C40.9273 39.6979 41.8868 40.4842 42.4639 41.5341C43.041 42.5841 43.1911 43.8168 42.883 44.975L42.8993 44.996Z" fill="#0071BC" />
    <path d="M59.6328 58.6585C59.2927 61.2044 53.7023 67.9646 53.7023 67.9646C53.7023 67.9646 50.0919 59.9653 50.4413 57.4195C50.6222 56.2119 51.2699 55.1241 52.2446 54.3908C53.2193 53.6576 54.4427 53.3378 55.6507 53.5005C56.8587 53.6632 57.9544 54.2953 58.7012 55.2603C59.448 56.2254 59.7859 57.446 59.6421 58.6585H59.6328Z" fill="#0071BC" />
    <path opacity="0.25" d="M1.40718 27.3476C3.24734 29.1281 11.8145 30.9949 11.8145 30.9949C11.8145 30.9949 9.71816 22.4729 7.87335 20.6832C6.98656 19.8419 5.80458 19.3846 4.58355 19.4104C3.36253 19.4362 2.20083 19.9429 1.35021 20.8209C0.499591 21.6988 0.0285753 22.8772 0.0392397 24.1006C0.0499041 25.3241 0.541389 26.4941 1.40718 27.357V27.3476Z" fill="black" />
    <path opacity="0.25" d="M6.25449 42.7697C8.76782 43.2691 16.9693 40.1585 16.9693 40.1585C16.9693 40.1585 10.5754 34.1474 8.05971 33.6481C6.8641 33.4372 5.6336 33.7038 4.63191 34.3909C3.63022 35.078 2.93705 36.1309 2.70096 37.3238C2.46486 38.5168 2.70463 39.7549 3.36888 40.7729C4.03313 41.7909 5.069 42.5077 6.25449 42.7697Z" fill="black" />
    <path opacity="0.25" d="M23.8988 59.4637C26.3213 58.6236 31.8185 51.7934 31.8185 51.7934C31.8185 51.7934 23.2746 49.8356 20.8544 50.6757C20.2606 50.8605 19.7099 51.1629 19.235 51.5651C18.7601 51.9672 18.3706 52.4608 18.0898 53.0166C17.809 53.5724 17.6424 54.179 17.6001 54.8005C17.5578 55.422 17.6405 56.0457 17.8434 56.6345C18.0463 57.2234 18.3653 57.7654 18.7813 58.2284C19.1973 58.6914 19.7019 59.0659 20.2652 59.3298C20.8284 59.5937 21.4389 59.7415 22.0603 59.7645C22.6817 59.7875 23.3014 59.6852 23.8825 59.4637H23.8988Z" fill="black" />
    <path opacity="0.25" d="M40.0879 69.0753C42.6175 68.6553 49.1955 62.8566 49.1955 62.8566C49.1955 62.8566 41.1058 59.48 38.5761 59.8977C37.3613 60.0985 36.2758 60.7746 35.5586 61.7771C34.8413 62.7797 34.551 64.0267 34.7514 65.2437C34.9519 66.4608 35.6267 67.5482 36.6275 68.2667C37.6283 68.9853 38.873 69.2762 40.0879 69.0753Z" fill="black" />
    <path opacity="0.25" d="M24.1062 33.4987C24.1062 36.0656 19.4709 43.5164 19.4709 43.5164C19.4709 43.5164 14.8262 36.0702 14.8239 33.5034C14.8101 32.884 14.9199 32.2682 15.1469 31.6919C15.3739 31.1157 15.7136 30.5907 16.1459 30.1477C16.5782 29.7048 17.0946 29.3529 17.6645 29.1126C18.2345 28.8723 18.8467 28.7485 19.4651 28.7485C20.0835 28.7485 20.6956 28.8723 21.2656 29.1126C21.8356 29.3529 22.3519 29.7048 22.7842 30.1477C23.2166 30.5907 23.5562 31.1157 23.7833 31.6919C24.0103 32.2682 24.1201 32.884 24.1062 33.5034V33.4987Z" fill="black" />
    <path opacity="0.25" d="M42.8993 44.996C42.1329 47.4461 35.4828 53.1632 35.4828 53.1632C35.4828 53.1632 33.2746 44.6576 34.0316 42.1957C34.4385 41.0686 35.264 40.1423 36.3361 39.6101C37.4082 39.0779 38.6439 38.981 39.7856 39.3395C40.9273 39.6979 41.8868 40.4842 42.4639 41.5341C43.041 42.5841 43.1911 43.8168 42.883 44.975L42.8993 44.996Z" fill="black" />
    <path opacity="0.25" d="M59.6328 58.6585C59.2927 61.2044 53.7023 67.9646 53.7023 67.9646C53.7023 67.9646 50.0919 59.9653 50.4413 57.4195C50.6222 56.2119 51.2699 55.1241 52.2446 54.3908C53.2193 53.6576 54.4427 53.3378 55.6507 53.5005C56.8587 53.6632 57.9544 54.2953 58.7012 55.2603C59.448 56.2254 59.7859 57.446 59.6421 58.6585H59.6328Z" fill="black" />
    <path d="M59.477 76.4282C59.477 76.4282 57.3806 54.6846 44.8303 43.1198C39.5683 38.2661 35.9113 31.919 34.6046 24.8624C33.9912 21.5178 33.6856 18.1239 33.6915 14.7234" stroke="#535461" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M25.8296 7.38917C26.7893 9.77167 33.8727 14.9404 33.8727 14.9404C33.8727 14.9404 35.3984 6.29475 34.4411 3.91224C33.9808 2.76731 33.0854 1.85243 31.9518 1.36886C30.8183 0.885293 29.5394 0.872654 28.3965 1.33372C27.2536 1.79479 26.3404 2.6918 25.8577 3.82741C25.375 4.96303 25.3624 6.24423 25.8226 7.38917H25.8296Z" fill="#0071BC" />
    <path d="M24.0153 23.4554C26.1117 24.9325 34.8629 25.4155 34.8629 25.4155C34.8629 25.4155 31.4691 17.3276 29.3727 15.8505C28.3661 15.1388 27.1184 14.8568 25.9042 15.0666C24.6901 15.2765 23.6088 15.9609 22.8984 16.9694C22.188 17.9779 21.9065 19.2278 22.116 20.4441C22.3254 21.6605 23.0087 22.7436 24.0153 23.4554Z" fill="#0071BC" />
    <path d="M33.4046 45.8756C35.9669 46.0926 43.7561 42.0814 43.7561 42.0814C43.7561 42.0814 36.7379 36.824 34.1826 36.6093C33.5678 36.5437 32.9461 36.6018 32.354 36.7803C31.7619 36.9587 31.2114 37.2538 30.7347 37.6482C30.258 38.0427 29.8647 38.5286 29.5781 39.0774C29.2914 39.6262 29.117 40.2268 29.0652 40.844C29.0133 41.4613 29.0851 42.0826 29.2763 42.6717C29.4674 43.2608 29.7741 43.8056 30.1784 44.2743C30.5826 44.743 31.0762 45.1261 31.6302 45.4011C32.1843 45.6761 32.7875 45.8374 33.4046 45.8756Z" fill="#0071BC" />
    <path d="M44.3154 61.2395C46.7984 61.8835 55.1583 59.2513 55.1583 59.2513C55.1583 59.2513 49.1277 52.8808 46.647 52.2368C45.4629 51.9494 44.2134 52.1399 43.1682 52.7672C42.123 53.3945 41.3658 54.4083 41.0601 55.5898C40.7544 56.7714 40.9247 58.026 41.5341 59.0829C42.1435 60.1399 43.1433 60.9144 44.3177 61.2395H44.3154Z" fill="#0071BC" />
    <path d="M44.0938 22.2279C43.0573 24.5754 35.8038 29.5014 35.8038 29.5014C35.8038 29.5014 34.5693 20.8115 35.6058 18.4616C35.8459 17.8931 36.1965 17.3781 36.6373 16.9468C37.0781 16.5154 37.6001 16.1763 38.1731 15.9491C38.746 15.722 39.3584 15.6114 39.9745 15.6237C40.5905 15.6361 41.198 15.7712 41.7614 16.0212C42.3248 16.2712 42.8328 16.6311 43.256 17.0798C43.6791 17.5285 44.0089 18.0572 44.226 18.6349C44.4431 19.2126 44.5432 19.8278 44.5205 20.4447C44.4978 21.0616 44.3528 21.6677 44.0938 22.2279Z" fill="#0071BC" />
    <path d="M56.6256 40.3546C54.9345 42.2844 46.542 44.8116 46.542 44.8116C46.542 44.8116 47.9582 36.1496 49.6516 34.2198C50.0537 33.7611 50.5421 33.3862 51.0888 33.1165C51.6355 32.8468 52.2299 32.6877 52.838 32.6481C53.4461 32.6086 54.0561 32.6894 54.633 32.886C55.2099 33.0826 55.7426 33.3911 56.2005 33.794C56.6584 34.1968 57.0326 34.686 57.3018 35.2337C57.571 35.7814 57.7299 36.3769 57.7693 36.9861C57.8088 37.5953 57.7281 38.2063 57.5319 38.7843C57.3356 39.3623 57.0277 39.8959 56.6256 40.3546Z" fill="#0071BC" />
    <path d="M66.409 59.6503C65.0673 61.8391 57.2222 65.7501 57.2222 65.7501C57.2222 65.7501 57.157 56.9714 58.4987 54.7826C59.143 53.7305 60.1781 52.9779 61.3764 52.6904C62.5746 52.4029 63.8377 52.604 64.888 53.2495C65.9382 53.895 66.6894 54.932 66.9764 56.1324C67.2634 57.3328 67.0626 58.5982 66.4183 59.6503H66.409Z" fill="#0071BC" />
    <path opacity="0.1" d="M95.0735 199.745C109.421 199.745 121.052 198.228 121.052 196.356C121.052 194.485 109.421 192.968 95.0735 192.968C80.7258 192.968 69.0947 194.485 69.0947 196.356C69.0947 198.228 80.7258 199.745 95.0735 199.745Z" fill="#0071BC" />
    <path opacity="0.1" d="M42.5982 197.448C46.9567 197.448 50.49 196.842 50.49 196.095C50.49 195.347 46.9567 194.742 42.5982 194.742C38.2398 194.742 34.7065 195.347 34.7065 196.095C34.7065 196.842 38.2398 197.448 42.5982 197.448Z" fill="#0071BC" />
    <path d="M44.3127 181.025C44.3127 181.025 45.5914 182.703 43.721 185.225C41.8506 187.748 40.3086 189.892 40.9258 191.472C40.9258 191.472 43.7466 186.77 46.0503 186.705C48.354 186.64 46.8423 183.853 44.3127 181.025Z" fill="#0071BC" />
    <path opacity="0.1" d="M44.3126 181.025C44.4243 181.188 44.5122 181.365 44.5735 181.553C46.8143 184.189 48.0092 186.651 45.8546 186.712C43.8491 186.77 41.4406 190.364 40.8652 191.272C40.8842 191.342 40.9068 191.411 40.9328 191.479C40.9328 191.479 43.7536 186.777 46.0573 186.712C48.361 186.647 46.8422 183.853 44.3126 181.025Z" fill="black" />
    <path d="M46.6914 183.163C46.6914 183.753 46.626 184.231 46.5444 184.231C46.4627 184.231 46.395 183.765 46.395 183.163C46.395 182.561 46.479 182.85 46.5607 182.85C46.6424 182.85 46.6914 182.572 46.6914 183.163Z" fill="#FFD037" />
    <path d="M47.5084 183.867C46.9913 184.15 46.5394 184.32 46.4998 184.248C46.4602 184.175 46.8492 183.888 47.3663 183.606C47.8835 183.324 47.6785 183.529 47.7181 183.606C47.7577 183.683 48.0255 183.585 47.5084 183.867Z" fill="#FFD037" />
    <path d="M37.5531 181.025C37.5531 181.025 36.2743 182.703 38.1447 185.225C40.0152 187.748 41.5572 189.892 40.9399 191.472C40.9399 191.472 38.1191 186.77 35.8154 186.705C33.5117 186.64 35.0281 183.853 37.5531 181.025Z" fill="#0071BC" />
    <path opacity="0.1" d="M37.5533 181.025C37.4415 181.188 37.3537 181.365 37.2924 181.553C35.0516 184.189 33.8566 186.651 36.0112 186.712C38.0191 186.77 40.4253 190.364 41.0006 191.272C40.9827 191.342 40.9601 191.412 40.9331 191.479C40.9331 191.479 38.1123 186.777 35.8086 186.712C33.5049 186.647 35.0283 183.853 37.5533 181.025Z" fill="black" />
    <path d="M35.1753 183.163C35.1753 183.753 35.2406 184.231 35.3223 184.231C35.404 184.231 35.4716 183.765 35.4716 183.163C35.4716 182.561 35.3876 182.85 35.306 182.85C35.2243 182.85 35.1753 182.572 35.1753 183.163Z" fill="#FFD037" />
    <path d="M34.3597 183.867C34.8768 184.15 35.3263 184.32 35.3659 184.248C35.4055 184.175 35.0165 183.888 34.4994 183.606C33.9823 183.324 34.1873 183.529 34.1477 183.606C34.1081 183.683 33.8425 183.585 34.3597 183.867Z" fill="#FFD037" />
    <path d="M35.5825 191.26C35.5825 191.26 39.1603 191.148 40.2411 190.38C41.3219 189.612 45.743 188.691 46.0108 189.925C46.2787 191.16 51.3869 196.06 47.3479 196.093C43.3088 196.125 37.9631 195.463 36.8869 194.807C35.8108 194.151 35.5825 191.26 35.5825 191.26Z" fill="#A8A8A8" />
    <path opacity="0.2" d="M47.4177 195.663C43.3787 195.696 38.033 195.033 36.9568 194.377C36.1369 193.876 35.8108 192.081 35.7013 191.255H35.5825C35.5825 191.255 35.8154 194.144 36.8846 194.802C37.9538 195.46 43.3065 196.121 47.3455 196.088C48.5102 196.088 48.9155 195.663 48.8922 195.047C48.7315 195.425 48.2866 195.656 47.4177 195.663Z" fill="black" />
    <path d="M155.062 191.122C155.062 191.122 145.214 190.527 146.376 197.441C146.376 197.441 146.143 198.662 147.25 199.217C147.25 199.217 147.268 198.706 148.261 198.879C148.614 198.938 148.973 198.956 149.33 198.93C149.813 198.898 150.273 198.708 150.639 198.389C150.639 198.389 153.406 197.245 154.482 192.711C154.482 192.711 155.279 191.722 155.248 191.468L153.585 192.168C153.585 192.168 154.154 193.369 153.706 194.368C153.706 194.368 153.653 192.212 153.334 192.268C153.269 192.268 152.47 192.683 152.47 192.683C152.47 192.683 153.448 194.784 152.703 196.296C152.703 196.296 152.982 193.729 152.157 192.835L150.993 193.519C150.993 193.519 152.134 195.68 151.361 197.444C151.361 197.444 151.559 194.739 150.748 193.684L149.688 194.513C149.688 194.513 150.76 196.643 150.105 198.106C150.105 198.106 150.021 194.959 149.458 194.721C149.458 194.721 148.526 195.54 148.389 195.887C148.389 195.887 149.122 197.432 148.668 198.249C148.668 198.249 148.389 196.149 148.158 196.149C148.158 196.149 147.226 197.549 147.138 198.482C147.138 198.482 147.18 197.066 147.934 196.006C147.402 196.108 146.91 196.363 146.521 196.741C146.521 196.741 146.665 195.759 148.151 195.673C148.151 195.673 148.915 194.618 149.12 194.555C149.12 194.555 147.629 194.429 146.726 194.83C146.726 194.83 147.52 193.897 149.393 194.324L150.438 193.47C150.438 193.47 148.477 193.199 147.643 193.498C147.643 193.498 148.603 192.676 150.72 193.264L151.862 192.583C151.862 192.583 150.187 192.221 149.19 192.35C149.19 192.35 150.243 191.78 152.195 192.396L153.01 192.03C153.01 192.03 151.782 191.797 151.424 191.752C151.065 191.708 151.046 191.615 151.046 191.615C151.823 191.485 152.621 191.574 153.35 191.871C153.35 191.871 155.092 191.232 155.062 191.122Z" fill="#0071BC" />
    <path opacity="0.1" d="M148.279 201C152.128 201 155.248 200.471 155.248 199.819C155.248 199.167 152.128 198.638 148.279 198.638C144.43 198.638 141.31 199.167 141.31 199.819C141.31 200.471 144.43 201 148.279 201Z" fill="#0071BC" />
    <path d="M137.809 191.491C137.809 191.491 132.256 191.155 132.918 195.054C132.884 195.252 132.914 195.455 133.003 195.635C133.091 195.815 133.234 195.962 133.411 196.055C133.411 196.055 133.411 195.766 133.982 195.864C134.181 195.896 134.382 195.907 134.583 195.894C134.855 195.874 135.114 195.767 135.321 195.589C135.321 195.589 136.882 194.942 137.49 192.387C137.49 192.387 137.937 191.829 137.921 191.687L136.989 192.086C136.989 192.086 137.308 192.765 137.057 193.327C137.057 193.327 137.026 192.112 136.847 192.14C136.81 192.14 136.36 192.373 136.36 192.373C136.36 192.373 136.91 193.54 136.495 194.41C136.495 194.41 136.651 192.956 136.188 192.457L135.529 192.844C135.529 192.844 136.172 194.062 135.736 195.057C135.736 195.057 135.848 193.53 135.389 192.938L134.793 193.404C134.793 193.404 135.396 194.606 135.026 195.43C135.026 195.43 134.977 193.654 134.66 193.521C134.433 193.713 134.23 193.931 134.057 194.172C134.057 194.172 134.471 195.045 134.215 195.505C134.215 195.505 134.057 194.322 133.928 194.317C133.928 194.317 133.407 195.101 133.353 195.638C133.377 195.14 133.531 194.657 133.8 194.238C133.5 194.294 133.223 194.438 133.004 194.651C133.004 194.651 133.085 194.095 133.935 194.048C133.935 194.048 134.364 193.453 134.48 193.418C134.48 193.418 133.64 193.346 133.129 193.572C133.129 193.572 133.579 193.05 134.634 193.288L135.224 192.805C135.224 192.805 134.117 192.653 133.649 192.821C133.649 192.821 134.189 192.354 135.384 192.695L136.025 192.312C135.531 192.203 135.025 192.159 134.52 192.182C134.52 192.182 135.114 191.862 136.213 192.207L136.679 192.002C136.679 192.002 135.981 191.864 135.787 191.843C135.594 191.822 135.573 191.766 135.573 191.766C136.011 191.693 136.461 191.743 136.873 191.911C136.873 191.911 137.825 191.554 137.809 191.491Z" fill="#0071BC" />
    <path opacity="0.1" d="M133.984 197.061C136.154 197.061 137.914 196.763 137.914 196.396C137.914 196.029 136.154 195.731 133.984 195.731C131.814 195.731 130.055 196.029 130.055 196.396C130.055 196.763 131.814 197.061 133.984 197.061Z" fill="#0071BC" />
    <path d="M148.377 183.228C148.377 183.228 141.676 182.822 142.466 187.529C142.425 187.767 142.461 188.013 142.568 188.23C142.675 188.447 142.848 188.625 143.062 188.737C143.062 188.737 143.062 188.39 143.749 188.504C143.989 188.543 144.233 188.554 144.476 188.539C144.805 188.515 145.118 188.386 145.368 188.17C145.368 188.17 147.252 187.391 147.984 184.304C147.984 184.304 148.526 183.632 148.505 183.459L147.373 183.942C147.373 183.942 147.76 184.761 147.457 185.44C147.457 185.44 147.42 183.972 147.201 184.007C147.159 184.007 146.614 184.29 146.614 184.29C146.614 184.29 147.28 185.716 146.777 186.749C146.777 186.749 146.968 184.994 146.407 184.392L145.61 184.859C145.61 184.859 146.388 186.329 145.862 187.531C145.862 187.531 145.997 185.69 145.442 184.964L144.72 185.527C144.72 185.527 145.452 186.976 145.007 187.972C145.007 187.972 144.949 185.83 144.564 185.667C144.564 185.667 143.933 186.224 143.838 186.453C143.838 186.453 144.338 187.505 144.026 188.061C144.026 188.061 143.838 186.635 143.681 186.628C143.681 186.628 143.053 187.561 142.983 188.222C143.013 187.622 143.199 187.042 143.523 186.537C143.161 186.606 142.826 186.78 142.561 187.036C142.561 187.036 142.659 186.367 143.679 186.308C143.679 186.308 144.199 185.592 144.338 185.548C144.338 185.548 143.323 185.464 142.708 185.737C142.708 185.737 143.248 185.104 144.525 185.391L145.237 184.81C145.237 184.81 143.9 184.626 143.334 184.829C143.334 184.829 143.987 184.271 145.431 184.677L146.206 184.21C146.206 184.21 145.067 183.977 144.39 184.054C144.39 184.054 145.105 183.667 146.435 184.084L146.989 183.837C146.989 183.837 146.153 183.671 145.908 183.646C145.664 183.62 145.652 183.552 145.652 183.552C146.181 183.464 146.723 183.525 147.22 183.727C147.22 183.727 148.401 183.303 148.377 183.228Z" fill="#0071BC" />
    <path opacity="0.1" d="M143.763 189.951C146.383 189.951 148.508 189.591 148.508 189.148C148.508 188.705 146.383 188.345 143.763 188.345C141.142 188.345 139.018 188.705 139.018 189.148C139.018 189.591 141.142 189.951 143.763 189.951Z" fill="#0071BC" />
    <path d="M253.452 137.433V23.2943H31.8535V130.885C34.6836 133.251 38.0868 135.242 41.6366 136.878C43.3852 137.685 45.171 138.423 46.994 139.092H250.809L253.452 137.433Z" fill="url(#paint0_linear)" />
    <path d="M253.025 137.48V26.5612H32.3389V131.125C35.1573 133.426 38.5465 135.361 42.0754 136.959C43.8177 137.737 45.5973 138.454 47.4142 139.111H250.386L253.025 137.48Z" fill="white" />
    <path d="M253.025 23.6186H32.3413V29.485H253.025V23.6186Z" fill="#5A5773" />
    <path d="M36.7475 28.0687C37.5836 28.0687 38.2615 27.3896 38.2615 26.5519C38.2615 25.7142 37.5836 25.0351 36.7475 25.0351C35.9113 25.0351 35.2334 25.7142 35.2334 26.5519C35.2334 27.3896 35.9113 28.0687 36.7475 28.0687Z" fill="white" />
    <path d="M40.8236 28.0687C41.6598 28.0687 42.3377 27.3896 42.3377 26.5519C42.3377 25.7142 41.6598 25.0351 40.8236 25.0351C39.9874 25.0351 39.3096 25.7142 39.3096 26.5519C39.3096 27.3896 39.9874 28.0687 40.8236 28.0687Z" fill="white" />
    <path d="M44.8998 28.0687C45.736 28.0687 46.4139 27.3896 46.4139 26.5519C46.4139 25.7142 45.736 25.0351 44.8998 25.0351C44.0636 25.0351 43.3857 25.7142 43.3857 26.5519C43.3857 27.3896 44.0636 28.0687 44.8998 28.0687Z" fill="white" />
    <path d="M125.19 42.3776H88.7358V65.3253H125.19V42.3776Z" fill="#0071BC" />
    <path opacity="0.2" d="M74.7154 42.3776H38.2617V65.3253H74.7154V42.3776Z" fill="#5A5773" />
    <path opacity="0.2" d="M175.663 42.3776H139.209V65.3253H175.663V42.3776Z" fill="#5A5773" />
    <path opacity="0.2" d="M74.7154 75.1844H38.2617V98.1321H74.7154V75.1844Z" fill="#5A5773" />
    <path opacity="0.2" d="M125.19 75.1844H88.7358V98.1321H125.19V75.1844Z" fill="#5A5773" />
    <path opacity="0.2" d="M175.663 75.1844H139.209V98.1321H175.663V75.1844Z" fill="#5A5773" />
    <path opacity="0.2" d="M74.7154 107.989H38.2617V130.936H74.7154V107.989Z" fill="#5A5773" />
    <path opacity="0.2" d="M125.19 107.989H88.7358V130.936H125.19V107.989Z" fill="#5A5773" />
    <path opacity="0.2" d="M175.663 107.989H139.209V130.936H175.663V107.989Z" fill="#5A5773" />
    <path opacity="0.1" d="M192.683 8.05183C192.683 8.05183 183.82 7.51746 184.866 13.7386C184.866 13.7386 184.659 14.8377 185.653 15.337C185.653 15.337 185.67 14.8703 186.562 15.0313C186.879 15.0849 187.202 15.1006 187.524 15.078C187.958 15.0479 188.37 14.8767 188.698 14.5903C188.698 14.5903 191.188 13.5612 192.157 9.48227C192.157 9.48227 192.874 8.59087 192.856 8.36219L191.36 9.0039C191.36 9.0039 191.87 10.0843 191.467 10.9827C191.467 10.9827 191.421 9.04124 191.132 9.08791C191.074 9.08791 190.356 9.46127 190.356 9.46127C190.356 9.46127 191.234 11.3444 190.571 12.7142C190.571 12.7142 190.822 10.3947 190.081 9.59894L189.024 10.2267C189.024 10.2267 190.051 12.1705 189.355 13.7572C189.355 13.7572 189.534 11.3234 188.803 10.376L187.848 11.1204C187.848 11.1204 188.814 13.0269 188.232 14.343C188.232 14.343 188.155 11.5078 187.647 11.2954C187.647 11.2954 186.813 12.0328 186.688 12.3361C186.688 12.3361 187.347 13.7362 186.937 14.4596C186.937 14.4596 186.685 12.5742 186.471 12.5648C186.471 12.5648 185.639 13.8156 185.553 14.665C185.594 13.8724 185.84 13.1042 186.268 12.4365C185.789 12.5275 185.346 12.7579 184.996 13.0992C184.996 13.0992 185.125 12.2148 186.473 12.1355C186.473 12.1355 187.16 11.1881 187.344 11.1297C187.344 11.1297 186.003 11.0177 185.19 11.3794C185.19 11.3794 185.905 10.544 187.591 10.9127L188.523 10.1427C188.523 10.1427 186.757 9.9093 186.007 10.1683C186.007 10.1683 186.869 9.43093 188.777 9.96764L189.802 9.35393C189.802 9.35393 188.297 9.02723 187.4 9.14624C187.4 9.14624 188.346 8.63521 190.102 9.18825L190.836 8.85922C190.836 8.85922 189.732 8.64221 189.411 8.6072C189.089 8.5722 189.07 8.48353 189.07 8.48353C189.769 8.36707 190.486 8.44787 191.141 8.71688C191.141 8.71688 192.709 8.14984 192.683 8.05183Z" fill="#0071BC" />
    <path opacity="0.1" d="M180.051 3.00681C180.051 3.00681 172.598 7.83949 177.119 12.2288C177.119 12.2288 177.601 13.2369 178.698 13.0502C178.698 13.0502 178.437 12.6675 179.25 12.2638C179.538 12.1185 179.808 11.9391 180.054 11.7294C180.386 11.4475 180.617 11.065 180.713 10.6397C180.713 10.6397 182.111 8.32953 180.48 4.46991C180.48 4.46991 180.531 3.3265 180.373 3.15848L179.548 4.55859C179.548 4.55859 180.599 5.12563 180.806 6.08703C180.806 6.08703 179.621 4.55392 179.409 4.7616C179.367 4.8036 179.003 5.52466 179.003 5.52466C179.003 5.52466 180.827 6.51873 181.1 8.01451C181.1 8.01451 179.935 5.99602 178.861 5.79768L178.377 6.91776C178.377 6.91776 180.357 7.8745 180.734 9.56629C180.734 9.56629 179.437 7.4988 178.288 7.16978L177.962 8.33653C177.962 8.33653 179.872 9.30727 180.177 10.7167C180.177 10.7167 178.44 8.48121 177.904 8.61655C177.904 8.61655 177.671 9.70396 177.745 10.0167C177.745 10.0167 179.101 10.7447 179.204 11.5801C179.204 11.5801 177.885 10.2103 177.715 10.3247C177.715 10.3247 177.785 11.8251 178.221 12.5672C177.785 11.9046 177.53 11.1397 177.48 10.348C177.147 10.706 176.927 11.1539 176.846 11.6361C176.846 11.6361 176.427 10.8474 177.468 9.98398C177.468 9.98398 177.468 8.81723 177.573 8.65622C177.573 8.65622 176.427 9.35627 175.984 10.1357C175.984 10.1357 176.068 9.03891 177.65 8.34353L177.953 7.16511C177.953 7.16511 176.387 8.01917 175.943 8.67955C175.943 8.67955 176.201 7.57347 178.053 6.87342L178.519 5.76968C178.519 5.76968 177.121 6.40205 176.46 7.02977C176.46 7.02977 176.926 6.05436 178.663 5.45932L179.059 4.75927C179.059 4.75927 178.041 5.23997 177.762 5.40332C177.482 5.56666 177.415 5.50599 177.415 5.50599C177.908 4.99731 178.533 4.63616 179.22 4.46291C179.22 4.46291 180.131 3.07214 180.051 3.00681Z" fill="#0071BC" />
    <path opacity="0.1" d="M168.708 8.63752C168.708 8.63752 164.252 16.3381 170.268 18.1886C170.268 18.1886 171.156 18.8676 172.048 18.1886C172.048 18.1886 171.645 17.9692 172.185 17.2388C172.373 16.9781 172.529 16.6962 172.651 16.3988C172.82 15.9964 172.853 15.5501 172.747 15.127C172.747 15.127 172.945 12.4341 169.742 9.73426C169.742 9.73426 169.276 8.69352 169.043 8.61652L168.943 10.25C168.943 10.25 170.135 10.278 170.757 11.041C170.757 11.041 169.006 10.2126 168.917 10.4903C168.899 10.5463 168.901 11.3537 168.901 11.3537C168.901 11.3537 170.995 11.4167 171.927 12.6255C171.927 12.6255 169.97 11.3584 168.929 11.6664L169.003 12.8845C169.003 12.8845 171.2 12.8378 172.302 14.1749C172.302 14.1749 170.205 12.9218 169.041 13.1505L169.274 14.3383C169.274 14.3383 171.417 14.3383 172.325 15.4537C172.325 15.4537 169.763 14.2496 169.346 14.6066C169.346 14.6066 169.628 15.6847 169.842 15.9344C169.842 15.9344 171.379 15.9671 171.85 16.6648C171.85 16.6648 170.054 16.0417 169.954 16.2237C169.954 16.2237 170.697 17.5282 171.421 17.9902C170.734 17.5998 170.16 17.0369 169.756 16.3567C169.622 16.8267 169.628 17.3256 169.774 17.7919C169.774 17.7919 169.043 17.2808 169.579 16.0371C169.579 16.0371 169.043 14.9963 169.073 14.8073C169.073 14.8073 168.375 15.9577 168.328 16.8468C168.328 16.8468 167.904 15.8317 168.999 14.4946L168.736 13.3045C168.736 13.3045 167.727 14.777 167.629 15.568C167.629 15.568 167.359 14.4643 168.694 13.0012L168.605 11.8087C168.605 11.8087 167.641 13.0105 167.343 13.8669C167.343 13.8669 167.31 12.7888 168.593 11.4657L168.628 10.6607C168.628 10.6607 167.93 11.5497 167.764 11.8274C167.599 12.1051 167.501 12.0654 167.501 12.0654C167.711 11.3873 168.104 10.7806 168.638 10.313C168.638 10.313 168.805 8.65852 168.708 8.63752Z" fill="#0071BC" />
    <path d="M206.967 96.4193C206.307 96.4193 205.663 96.6152 205.115 96.9821C204.567 97.349 204.139 97.8705 203.887 98.4807C203.635 99.0909 203.569 99.7623 203.697 100.41C203.826 101.058 204.143 101.653 204.61 102.12C205.076 102.587 205.67 102.905 206.316 103.034C206.963 103.163 207.633 103.096 208.242 102.844C208.851 102.591 209.372 102.163 209.738 101.614C210.104 101.065 210.3 100.419 210.3 99.7586C210.297 98.8737 209.945 98.0258 209.321 97.4001C208.696 96.7744 207.85 96.4218 206.967 96.4193ZM197.221 70.2164V73.5557H200.554L206.554 85.7319L204.046 89.8225C203.772 90.3063 203.628 90.8531 203.629 91.4093C203.629 93.6448 205.154 94.7485 207.223 94.7485H226.712V91.5376H207.63C207.575 91.5411 207.519 91.5326 207.467 91.5128C207.415 91.493 207.367 91.4624 207.328 91.4229C207.289 91.3834 207.258 91.336 207.238 91.2837C207.218 91.2315 207.21 91.1757 207.213 91.1199C207.257 90.9755 207.317 90.8363 207.39 90.7046L209.056 88.0701H221.473C222.073 88.075 222.663 87.914 223.178 87.6048C223.692 87.2956 224.112 86.8502 224.39 86.3176L230.392 75.9778C230.509 75.7153 230.567 75.4299 230.56 75.1424C230.558 74.9276 230.512 74.7154 230.427 74.5184C230.341 74.3214 230.217 74.1436 230.061 73.9957C229.906 73.8477 229.722 73.7325 229.521 73.6569C229.321 73.5813 229.107 73.5469 228.892 73.5557H204.223L202.639 70.2164H197.221ZM223.376 96.4193C222.717 96.4198 222.073 96.616 221.525 96.9832C220.977 97.3504 220.551 97.8721 220.299 98.4823C220.047 99.0925 219.981 99.7639 220.11 100.411C220.239 101.059 220.556 101.654 221.023 102.121C221.489 102.587 222.083 102.905 222.729 103.034C223.376 103.162 224.046 103.096 224.655 102.843C225.264 102.591 225.784 102.163 226.15 101.614C226.517 101.064 226.712 100.419 226.712 99.7586C226.71 98.8733 226.357 98.025 225.732 97.3993C225.107 96.7735 224.26 96.4212 223.376 96.4193Z" fill="#0071BC" />
    <path d="M219.112 79.4547H216.316V76.6545H214.22V79.4547H211.425V81.5549H214.22V84.3551H216.316V81.5549H219.112V79.4547Z" fill="white" />
    <defs>
      <linearGradient id="paint0_linear" x1="142.652" y1="139.092" x2="142.652" y2="23.2943" gradientUnits="userSpaceOnUse">
        <stop stopColor="#808080" stopOpacity="0.25" />
        <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
        <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
      </linearGradient>
    </defs>
  </svg>

)