import React from 'react'

export default ({
  className = ''
}) => (

  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="31" height="25" viewBox="0 0 31 25">
    <g transform="translate(-1267.5 -248.5)">
      <path fill="#33a348"
            d="M3996.25,84a2.253,2.253,0,0,1-2.25-2.25,2.227,2.227,0,0,1,.138-.75h-9.276a2.2,2.2,0,0,1,.139.75,2.25,2.25,0,0,1-4.5,0,2.2,2.2,0,0,1,.139-.75h-3.888a.751.751,0,0,1-.75-.749V63h-2.25a.75.75,0,0,1-.75-.749v-1.5a.751.751,0,0,1,.75-.75h4.5a.751.751,0,0,1,.749.75V78h23.25a.751.751,0,0,1,.75.75v1.5a.751.751,0,0,1-.75.749h-3.888a2.2,2.2,0,0,1,.138.75A2.252,2.252,0,0,1,3996.25,84Z"
            transform="translate(-2705 189)"/>
      <path fill="#306ab4"
            d="M10.809-4.875a1.231,1.231,0,0,1-1.051-.594L8.125-8.176,6.495-5.469a1.235,1.235,0,0,1-1.054.6A1.168,1.168,0,0,1,5.1-4.921l-3.479-1V-1.4a.81.81,0,0,0,.614.787L7.729.764a1.65,1.65,0,0,0,.787,0L14.011-.609a.814.814,0,0,0,.614-.787v-4.52l-3.479.993A1.167,1.167,0,0,1,10.809-4.875Zm5.4-2.849L14.9-10.334a.415.415,0,0,0-.424-.226l-6.35.81,2.328,3.862a.417.417,0,0,0,.47.185l5.025-1.435A.418.418,0,0,0,16.207-7.724ZM1.351-10.334.043-7.724A.414.414,0,0,0,.3-7.14L5.324-5.705a.417.417,0,0,0,.47-.185L8.125-9.75l-6.353-.81A.415.415,0,0,0,1.351-10.334Z"
            transform="translate(1278 264)"/>
    </g>
  </svg>

  )
