import React, { Component } from 'react';
import getDate from '../../../../../Helpers/Date/GetDate';
//import RecordPaymentIcon from '../../../../../Assets/General/RecordPaymentIcon';
import { restRequest } from '../../../../../Helpers/RequestHelper';
import { checkError, getObjFromLS, setObjInLS } from '../../../../../Helpers';
import { CustomModal, Loader } from '../../../../../Components';
//import EmptyTable from '../../../../../Components/ListTable/EmptyTable';
import { NoItemsBox } from '../../../../../Components/Layout/Box';
import RefundIcon from '../../../../../Assets/General/RefundIcon';
import { RecordPaymentForm } from '../../../../../Components/Form';
import DeleteIcon from '../../../../../Assets/General/DeleteIcon';
import { MdAdd } from '../../../../../Common/Icons';
import Table from '../../../../../Components/Table/Table';
import ShipmentInfoCard from '../../../../SalesOrder/Packages/PackageDetails/Info/ShipmentInfoCard';

class RefundsTab extends Component {
  state = {
    loading: true,
    history: [],
    creditRefundList: [],
    createModal: false,
    showPrompt: false,
    showButton: false,
    showDeleteCreditRefundModal: false,
    showPaymentRefundModal: false,
    selectedCreditNoteRedund: {},
  };

  componentDidMount() {
    this.fetchRefundData();
  }

  fetchRefundData = () => {
    restRequest(
      'get',
      `advancerefund/${this.props.id}/list`
      //`creditrefund/${22}/list`
    )
      .then((res) => {
        this.setState({
          showButton: res?.showButton,
          creditRefundList: res?.refunds,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error);
        this.setState({ loading: false });
      });
  };

  modalHandler = () => {
    this.setState({ createModal: !this.state.createModal });
  };

  handlePrompt = (status = true) => {
    this.setState({ showPrompt: status });
  };

  // Data Modified of listing

  tableRowOptions = [
    {
      label: 'Delete',
      icon: DeleteIcon,
      onClick: (id) => this.openCreditRefundDeleteModal(id),
    },
  ];

  openCreditRefundDeleteModal = (id) => {
    this.setState({
      showDeleteCreditRefundModal: true,
      clickedId: id,
    });
  };

  showRefundModal = (id) => {
    restRequest('get', `advancerefund/${id}/show`)
      .then((res) => {
        this.setState({
          showPaymentRefundModal: true,
          selectedCreditNoteRedund: {
            refund_date: res?.refund_date,
            refund_mode: res?.mode,
            refund_credits: res?.amount,
            reference: res?.reference,
            note: res?.note,
          },
        });
      })
      .catch((error) => {
        checkError(error);
        this.setState({
          selectedCreditNoteRedund: {},
        });
      });
  };

  prepareCreditRefundList(data) {
    return data.map((payment, index) => {
      return {
        ...payment,
        refund_date: getDate(payment.refund_date),
        mode: (
          <div
            className="payment_view"
            onClick={() => this.showRefundModal(payment.id)}
          >
            {payment.mode}
          </div>
        ),
        amount: '$' + parseFloat(payment.amount)?.toFixed(2),
      };
    });
  }

  renderCreditRefundListData() {
    const tableListData = this.prepareCreditRefundList(
      this.state.creditRefundList
    );
    const tableHeaders = ['Date Refunded', 'Payment Mode', 'Amount Credited'];
    const tableData = ['refund_date', 'mode', 'amount', 'options'];

    return (
      <>
        {this.state.creditRefundList.length > 0 && (
          <div className="float-left w-100">
            <Table
              list={tableListData}
              tableHeaders={tableHeaders}
              tableData={tableData}
              fromTab={true}
              options={this.tableRowOptions}
              className={`box-table box-table credit-refund-table`}
            />
          </div>
        )}
        {this.state.showButton ? (
          <button
            className="layout-box--button btn-width"
            onClick={this.modalHandler}
          >
            <MdAdd className=" black-fill" />
            Create Refund
          </button>
        ) : null}
      </>
    );
  }

  closeAllModals = () => {
    this.setState({
      showDeleteCreditRefundModal: false,
      showCreateCreditRefundModal: false,
      showPaymentRefundModal: false,
    });
  };

  deletePayment = () => {
    this.closeAllModals();
    restRequest('delete', `advancerefund/${this.state.clickedId}`)
      .then((res) => {
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS === null) {
          let obj = {
            prevId: null,
            id: null,
            nextId: null,
            moduleName: '',
            urlPath: window.location.pathname,
            queryParam: window.location.search,
          };
          setObjInLS('module', obj);
          dataFromLS = getObjFromLS('module');
        }
        setObjInLS('module', {
          ...dataFromLS,
          queryParam: `${
            dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
          }creditRefunds=true`,
        });
        this.fetchRefundData();
        this.props.updatePaymentDetails();
        this.props.handleToast(
          'Payment Refund deleted successfully',
          'success'
        );
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  };

  deletePaymentModal() {
    return (
      <CustomModal
        type="delete"
        showModal={this.state.showDeleteCreditRefundModal}
        title="Confirm Delete Credit Refund"
        onClose={this.closeAllModals}
        onSubmit={this.deletePayment}
      >
        Are you sure you want to delete this Credit Refund?
      </CustomModal>
    );
  }

  // Data Modified of listing

  CreditRefundModal() {
    return (
      <CustomModal
        Icon={RefundIcon}
        className="AppplyCredits Refund"
        showModal={this.state.createModal}
        title={`Add Refund`}
        renderActions={false}
        size="large"
        onClose={this.modalHandler}
        extraClass="apply_credit_notes" //commenting this to remove extra space from modal. Ticket related
        extraTitle={`Total Available Amount :${this.props.currency?.symbol}${this.props.remainingCredits}`}
        showPrompt={this.state.showPrompt}
      >
        <RecordPaymentForm
          type="paymentMadeRefund"
          id={this.props.id}
          handleToast={this.props.handleToast}
          // onSubmit={this.handleRecordPaymentSubmit}
          currency={this.props.currency}
          onClose={this.modalHandler}
          remainingCredits={this.props.remainingCredits}
          creditNoteDate={this.props.payment_date}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  renderViewCreditRefundModal() {
    return (
      <CustomModal
        showModal={this.state.showPaymentRefundModal}
        title={`Credit Refund Details`}
        Icon={RefundIcon}
        renderActions={false}
        renderShipmentActionMenu={true}
        size="small"
        onClose={this.closeAllModals}
      >
        <ShipmentInfoCard
          type="credit_refund"
          currency={this.props.currency}
          selectedRefund={this.state.selectedCreditNoteRedund}
          closeViewRefundModal={this.closeAllModals}
        />
      </CustomModal>
    );
  }

  render() {
    const { loading, creditRefundList, showButton } = this.state;
    return (
      <>
        {this.deletePaymentModal()}
        {this.renderViewCreditRefundModal()}
        <div>
          {this.CreditRefundModal()}
          {loading ? (
            <Loader />
          ) : !creditRefundList.length ? (
            <NoItemsBox
              subtitle={
                showButton
                  ? 'No refunds made so far.'
                  : 'Refunds Not Applicable On Direct Payments.'
              }
              buttonText="Create Refund"
              showButton={showButton}
              handleclick={this.modalHandler}
              Icon={RefundIcon}
            />
          ) : (
            this.renderCreditRefundListData()
          )}
        </div>
      </>
    );
  }
}
export default RefundsTab;
