import { restRequest } from "./RequestHelper";

export function pdfDownlaod(url, no, toast) {
  document.body.style.cursor = "wait";
  restRequest("get", `${url}`)
    .then((res) => {
      let encodedUri = encodeURI(res);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${no}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.style.cursor = "default";
      toast(`PDF downloaded successfully`, "success");
    })
    .catch((error) => {
      document.body.style.cursor = "default";
      toast(error, "error");
    });

  return;
}
