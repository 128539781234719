import React, { Component } from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import { differenceBy } from 'lodash-es';
import { restRequest, checkError } from '../../../Helpers';
import { Loader, PlanExceeded } from '../../';
import ItemFormEnhancer from './ItemFormEnhancer';
import './ItemForm.css';

class ItemsForm extends Component {
  type = this.props.type; // add, edit,clone
  formType = this.props.formType ? this.props.formType : 'item'; // item, itemGroup
  state = {
    item: {
      type: 'goods',
      name: '',
      sku: '',
      unit: '',
      upc: '',
      ean: '',
      mpn: '',
      isbn: '',
      dimension: '',
      weight: '',
      manufacturer: '',
      description: '',
      brand: '',
      salesUnitPrice: null,
      salesAccountID: null,
      salesDescription: '',
      taxID: null,
      purchaseUnitPrice: null,
      purchaseAccountID: null,
      purchaseDescription: '',
      vendorID: null,
      openingStockValue: null,
      reorderLevel: null,
      warehouses: [],
      images: [],
      uploadImages: [], // images to upload
      base84Image: [],
      varients: [],
    },
    purchaseAccounts: [],
    salesAccounts: [],
    vendorsList: [],
    currency: {},
    taxList: [],
    warehousesList: [],
    suggestions: [],
    allWarehouses: [],
    units: [],
    trackInventory: true,
    loading: true,
    planExceeded: null,
    showPrompt: false,
  };

  handleTrackInventory = () => {
    this.setState((state) => ({ trackInventory: !state.trackInventory }));
  };

  componentDidMount() {
    this.fetchData();
    this.props.inModal
      ? sessionStorage.setItem('fullscreen', true)
      : sessionStorage.setItem('fullscreen', false);
    sessionStorage.setItem('once', false);
    window.onbeforeunload = () => {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  fetchData = async () => {
    this.setState({ loading: true });
    if (this.formType === 'item') {
      switch (this.type) {
        case 'edit':
          await this.fetchDetails('edit');
          break;
        case 'clone':
          await this.fetchDetails('clone');
          break;
        case 'add':
        default:
          await this.fetchCreateData();
      }
    } else {
      await this.fetchCreateItemGroupData();
    }
    this.setState({ loading: false });
  };

  emptyWarehouse = {
    id: null,
    name: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    openingStock: null,
    is_active: 1,
  };

  getWarehouse(warehouseList) {
    if (!warehouseList.length) return this.emptyWarehouse;

    const primaryWarehouse = warehouseList.find(
      (warehouse) => warehouse.is_primary
    );
    return {
      id: primaryWarehouse.id,
      name: primaryWarehouse.name,
      address: primaryWarehouse.address,
      city: primaryWarehouse.city,
      state: primaryWarehouse.state,
      country: primaryWarehouse.country,
      zipCode: primaryWarehouse.zip_code,
      openingStock: null,
      is_active: 1,
    };
  }

  fetchCreateData() {
    return restRequest('get', 'items/create')
      .then((res) => {
        this.setState((state) => ({
          currency: res.base_currency,
          allWarehouses: res.ActiveWarehouses,
          salesAccounts: res.account_groups.sales,
          purchaseAccounts: res.account_groups.purchase,
          item: {
            ...state.item,
            salesAccountID: res.account_groups.sales[0]?.accounts[0]?.id,
            purchaseAccountID: res.account_groups.purchase[0]?.accounts[0]?.id,
            warehouses: [this.getWarehouse(res.ActiveWarehouses)],
          },
          vendorsList: res.vendors,
          taxList: res.taxes,
          warehousesList: differenceBy(
            res.ActiveWarehouses,
            [this.getWarehouse(res.ActiveWarehouses)],
            'id'
          ),
          units: res.item_units,
        }));
      })
      .catch((error) => {
        //this.props.handleToast(error, 'error')
        checkError(error, this.props.handleToast);
        this.setState({
          planExceeded: error.response && error.response.data.status,
          loading: false,
        });
      });
  }

  fetchDetails(reqUrl) {
    return restRequest('get', `items/${this.props.id}/${reqUrl}`)
      .then((res) => {
        let item = res.item_details;
        this.setState({
          currency: res.base_currency,
          salesAccounts: res.account_groups.sales,
          purchaseAccounts: res.account_groups.purchase,
          allWarehouses: res.ActiveWarehouses,
          item: {
            ...this.state.item,
            type: item.type,
            name: item.name,
            varients: item.group_variant,
            sku: res.clone_sku ? res.clone_sku : item.sku,
            dimensions: item.dimensions,
            weight: item.weight,
            unit: item.unit || '',
            upc: item.upc || '',
            ean: item.ean || '',
            mpn: item.mpn || '',
            isbn: item.isbn || '',
            manufacturer: item.manufacturer || '',
            description: item.description || '',
            brand: item.brand || '',
            salesUnitPrice: item.sales_unit_price,
            salesAccountID: item.sales_account.id || '',
            salesDescription: item.sales_description,
            taxID: item.tax_id,
            purchaseUnitPrice: item.purchase_unit_price,
            purchaseAccountID: item.purchase_account_id
              ? item.purchase_account_id
              : null,
            purchaseDescription: item.purchase_description,
            vendorID:
              res.vendors.find((vendor) => item.vendor_id === vendor.id)?.id ||
              null,
            openingStockValue: item.opening_stock_value,
            transactionInfo: item.transactionInfo,
            reorderLevel: item.reorder_level,
            warehouses:
              item.stocks.length === 0
                ? [this.emptyWarehouse]
                : item.stocks.map((stock) => ({
                    id: stock.warehouse.id,
                    name: stock.warehouse.name,
                    address: stock.warehouse.address,
                    city: stock.warehouse.city,
                    state: stock.warehouse.state,
                    country: stock.warehouse.country
                      ? stock.warehouse.country
                      : '',
                    zipCode: stock.warehouse.zip_code,
                    openingStock: stock.opening_quantity,
                    is_active: 1,
                    transactionInfo: item.transactionInfo,
                  })),
            images: item.images,
            uploadImages: [],
            base84Image: [],
          },
          vendorsList: res.vendors,
          taxList: res.taxes,
          warehousesList: differenceBy(
            res.ActiveWarehouses,
            item.stocks.map((stock) => stock.warehouse),
            'id'
          ),
          trackInventory: res.item_details.inventory_type === 'inventory',
          units: res.item_units,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, 'error')
        this.setState({
          planExceeded: error.response && error.response.data.status,
          loading: false,
        });
      });
  }

  fetchCreateItemGroupData() {
    return restRequest('get', `itemgroups/create?itemGroupId=${this.props.id}`)
      .then((res) => {
        this.setState((state) => ({
          currency: res.base_currency,
          allWarehouses: res.ActiveWarehouses,
          salesAccounts: res.account_groups.sales,
          purchaseAccounts: res.account_groups.purchase,
          item: {
            ...state.item,
            salesAccountID: res.item_group_details.sales_account_id
              ? res.item_group_details.sales_account_id
              : res.account_groups.sales[0].accounts[0].id,
            purchaseAccountID: res.item_group_details.purchase_account_id
              ? res.item_group_details.purchase_account_id
              : res.account_groups.purchase[0].accounts[0].id,
            warehouses: [this.getWarehouse(res.ActiveWarehouses)],
            attributes: res.item_group_details.item_attribute,
            itemGroupName: res.item_group_details.name,
            itemGroupId: res.item_group_details.id,
            unit: res.item_group_details.unit,
            type: res.item_group_details.type,
            manufacturer: res.item_group_details.manufacturer,
            description: res.item_group_details.description
              ? res.item_group_details.description
              : '',
            brand: res.item_group_details.brand,
            taxID: res.item_group_details.tax_id,
            /*possibleCombinations: this.getPossibleCombinations(
              res.item_group_details.item_attribute
            ),*/
            possibleCombinations:
              res.item_group_details.possible_combinations &&
              res.item_group_details.possible_combinations.length > 0
                ? res.item_group_details.possible_combinations
                : this.getPossibleCombinations(
                    res.item_group_details.item_attribute
                  ),
          },
          vendorsList: res.vendors,
          taxList: res.taxes,
          warehousesList: differenceBy(
            res.ActiveWarehouses,
            [this.getWarehouse(res.ActiveWarehouses)],
            'id'
          ),
          units: res.item_units,
        }));
        //console.log(this.state.item.possibleCombinations)
      })
      .catch((error) => {
        //this.props.handleToast(error, 'error')
        checkError(error, this.props.handleToast);
        this.setState({
          planExceeded: error.response && error.response.data.status,
          loading: false,
        });
      });
  }

  submitRequestUrl() {
    switch (this.type) {
      case 'edit':
        return `items/${this.props.id}`;
      case 'add':
      case 'clone':
      default:
        return 'items';
    }
  }

  successMessage = () => {
    if (this.formType === 'item') {
      switch (this.type) {
        case 'edit':
          this.props.handleToast('Item has been updated', 'success');
          break;
        case 'clone':
          this.props.handleToast('Item has been Cloned', 'success');
          break;
        case 'add':
        default:
          this.props.handleToast('Item added successfully', 'success');
          break;
      }
    } else {
      this.props.handleToast(
        'Item added successfully in Item Group',
        'success'
      );
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({ suggestions: this.getSuggestions(value) });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? this.state.units
      : this.state.units.filter((unit) =>
          unit.name.toLowerCase().includes(inputValue)
        );
  };

  removeSuggestion = (name) => {
    const units = this.state.units.filter((unit) => unit.name !== name);
    this.setState({ units });
    restRequest('delete', `itemunits/${name}`).catch((error) => {
      checkError(error, this.props.handleToast);
    });
  };

  getPossibleCombinations = (options) => {
    if (Object.keys(options).length === 1) {
      return options[0].options.flatMap((d) => d.toLowerCase());
    } else if (Object.keys(options).length === 2) {
      return options[0].options.flatMap((d) =>
        options[1].options.map((v) => d.toLowerCase() + '/' + v.toLowerCase())
      );
    } else if (Object.keys(options).length === 3) {
      let array2 = options[0].options.flatMap((d) =>
        options[1].options.map((v) => d.toLowerCase() + '/' + v.toLowerCase())
      );
      return array2.flatMap((d) =>
        options[2].options.map((v) => d.toLowerCase() + '/' + v.toLowerCase())
      );
    }
  };

  handlePrompt = (status = true) => this.setState({ showPrompt: status });

  renderItemForm() {
    return (
      <>
        <Prompt
          when={this.state.showPrompt}
          message="Are you sure you want to leave page while changes made?"
        />
        <ItemFormEnhancer
          type={this.type}
          formType={this.formType}
          allWarehouses={this.state.allWarehouses}
          emptyWarehouse={this.emptyWarehouse}
          requestUrl={this.submitRequestUrl()}
          successMessage={this.successMessage}
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          removeSuggestion={this.removeSuggestion}
          handleTrackInventory={this.handleTrackInventory}
          setPrompt={this.handlePrompt}
          {...this.props}
          {...this.state}
        />
      </>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.planExceeded === false) return <PlanExceeded />;
    else return this.renderItemForm();
  }
}

export default withRouter(ItemsForm);
