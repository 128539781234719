// works for nested arrays with options 
// prepared for group dropdown

const findByValue = (value, array) => {
  for(let arrayGroupItem of array) {
    for(let item of arrayGroupItem.options) {
      if(item.value === value) {
        return item
      }
    }
  }
  return null
}

export default findByValue