import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

const formatRemainingTime = (time) => {
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  return `${minutes}:${seconds}`;
};

const renderTimer = ({ remainingTime }) => {
  if (remainingTime === 0) {
    return (
      <div className="timer">
        <h6> Finished</h6>
      </div>
    );
  } else {
    // const minutes = Math.floor((remainingTime % 3600) / 60);
    // const seconds = remainingTime % 60;
    return (
      <div className="timer" style={styles}>
        {/* <div className="text">Remaining</div> */}
        {/* <div className="value">{seconds} </div> */}
        <div className="value"> {formatRemainingTime(remainingTime)} </div>
        {/* <div className="value">{`${minutes} minutes ${seconds} seconds`}</div> */}
      </div>
    );
  }
};
const styles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  fontSize: '20px',
  fontWeight: '400',
};
const alignment = {
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '8px',
  marginTop: '12px',
};
const CircleTimer = ({
  duration = '00:01:00',
  size = 76,
  strokeWidth = 7,
  history = () => {},
  redirectPath = '',
  onComplete = () => {},
}) => {
  // Convert time to seconds
  const durationExplode = duration.split(':');
  duration =
    +durationExplode[0] * 60 * 60 +
    +durationExplode[1] * 60 +
    +durationExplode[2];

  return (
    <>
      <div style={alignment}>
        <CountdownCircleTimer
          isPlaying
          duration={duration}
          size={size}
          strokeWidth={strokeWidth}
          colors={[
            ['#004757', 0.33],
            ['#F7B801', 0.33],
            ['#A30000', 0.33],
          ]}
          //  initialRemainingTime={remainingTime % minuteSeconds}
          onComplete={() => {
            if (redirectPath) {
              history.push(redirectPath);
            } else {
              onComplete();
            }
          }}
        >
          {renderTimer}
        </CountdownCircleTimer>
      </div>
    </>
  );
};

export default CircleTimer;
