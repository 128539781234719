import React, { Component } from 'react';
import './DashboardSalesVsPurchase.css';
import { formatNumber } from '../../../Helpers/FormatNumber';
import AccessDenied from '../../../Components/AccessDenied/AccessDenied';
import DataError from '../../../Components/DashboardDataError/DB_DataError';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  // Legend,
  ResponsiveContainer,
} from 'recharts';
import { restRequest } from '../../../Helpers/RequestHelper';
import Loader from '../../../Components/Loader/Loader';
import {setDashboardAmount} from "../../../Helpers/setAmount";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip sales-purchase">
        <div className="intro">{label}</div>
        <div className="label">
          {`${payload[0].name}${'\u00A0'}${'\u00A0'}${'\u00A0'}${'\u00A0'}${'\u00A0'}${'\u00A0'}${'\u00A0'} : `}
          <span className="sales">
            {`${payload[0].payload.unit}${setDashboardAmount(payload[0].value)}`}
            </span>
        </div>
        <div className="label">
          {`${payload[1].name} : `}
        <span className="purchase">
          {`${payload[1].payload.unit}${setDashboardAmount(payload[1].value)}`}
          </span>
        </div>
      </div>
    );
  }

  return null;
};

export default class DashboardSalesVsPurchase extends Component {
  constructor(props) {
    super();
    this.state = {
      data: {},
      chartData: {},
      loading: true,
      accessDenied: false,
      accessError: false,

    };
  }

  componentDidMount() {
    this.fetchData();
  }
  dashboard_preferences = JSON.parse(localStorage.getItem("dashboardPreferences"))

  fetchData(currentDateRange = this.dashboard_preferences.sales_vs_purchase, startData, endDate) {
    this.setState({ loading: true });
    restRequest(
      'get',
      `dashboard/sales_purchase/${currentDateRange === 'custom'
        ? `custom?starting_date=${startData}&ending_date=${endDate}`
        : currentDateRange
          ? currentDateRange
          : this.props.currentDateRange
      }`
    )
      .then((res) => {
        //currency = res.currency
        this.setState({
          chartData: this.prepareChartData(res.chart_details, res.currency),
          //currency: res.currency,
          loading: false,
        });
      })
      .catch((err) => {
        // checkError(err);
        if (err.response.status === 403) {
          this.setState({ accessDenied: true, loading: false });
        } else {
          this.setState({ accessError: true, loading: false });

        }
      });
  }

  componentWillReceiveProps(nextProps) {
    //  JSON.stringify(nextProps) !== JSON.stringify(this.props) &&
    (nextProps.currentDateRange !== this.props.currentDateRange ||
      nextProps.startData !== this.props.startData ||
      nextProps.endDate !== this.props.endDate) &&
      this.fetchData(
        nextProps.currentDateRange.sales_vs_purchase,
        nextProps.startData,
        nextProps.endDate
      );
  }

  prepareChartData(chartDetails, currency) {
    let resultData = [];

    Object.keys(chartDetails).forEach((val, i) => {
      resultData.push({});
      resultData[i]['name'] = val;
    });
    resultData.forEach((val, i) => {
      //resultData[i].sales = chartDetails[val.name].sales_order;
      resultData[i].sales = chartDetails[val.name].invoices;
      //resultData[i].purchase = chartDetails[val.name].purchase_order;
      resultData[i].purchase = chartDetails[val.name].bills;
    });

    resultData.forEach(function (res) {
      res.unit = currency;
    });
    return resultData;
  }

  yaxisNumber(label) {
    if (label >= 1000) {
      return `${label / 1000}k`;
    } else {
      return `${label}`;
    }
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.accessDenied)
      return (
        <AccessDenied className="small dashboard " type="Sales Purchase" />
      );
    if (this.state.accessError)
      return (
        <DataError className="small dashboard " type="Sales Purchase" />
      );
    return (
      <div className="dashboard_sales_purchases">
        <ResponsiveContainer>
          <BarChart
            width={550}
            height={225}
            data={this.state.chartData}
            margin={{
              top: 5,
              right: 10,
              left: 10,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            {/*<YAxis unit='k'/>*/}
            <YAxis tickFormatter={(label) => this.yaxisNumber(label)} />
            {/*<Tooltip />*/}
            <Tooltip content={<CustomTooltip />} />
            {/*<Legend verticalAlign="top" align="right" height={50}/>*/}
            {/*<Bar dataKey="sales" fill="#33A348" barSize={20} onMouseOver={ () => tooltip="sales" }/>*/}
            <Bar dataKey="sales" fill="#33A348" barSize={20} />
            {/*<Bar dataKey="purchase" fill="#306AB4" barSize={20} onMouseOver={ () => tooltip="purchase" }/>*/}
            <Bar dataKey="purchase" fill="#306AB4" barSize={20} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
