import React, { useEffect } from 'react';
import { CreditNoteForm } from '../../Components/Form';
import { FormLayout } from '../../Components';
import { CreditNoteIcon } from '../../Assets/Navigation/NavigationIcons';

const AddCreditNote = (props) => {
  useEffect(() => {
    document.title = 'Add Credit Note';
  }, []);

  return (
    <FormLayout
      title="Add New Credit Notes"
      Icon={CreditNoteIcon}
      closeUrl="/creditnotes"
      iconstyle="credit_general_module_icon"
    >
      <CreditNoteForm type="add" handleToast={props.handleToast} />
    </FormLayout>
  );
};

export default AddCreditNote;
