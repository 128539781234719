import React from 'react'

const Unchecked = () => {
  return (
    <svg id="CheckBox" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
  <g id="Rectangle_46" data-name="Rectangle 46" fill="#fff" stroke="#000">
    <rect width="18" height="18" rx="2"  stroke=" none;" />
    <rect x="0.5" y="0.5" width="17" height="17" rx="1.5" fill=" none;" />
  </g>
  {/* <text id="_" data-name="" className="cls-2" font-size="12px;"
        font-family="FontAwesome5Free-Solid, 'Font Awesome 5 Free'";
        opacity=" 0;" transform="translate(3 13)"><tspan x="0" y="0"></tspan></text> */}
</svg>
    
    
    
  )
}

const SemiChecked = () => (
  <svg id="CheckBox" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
  <g id="Rectangle_46" data-name="Rectangle 46" fill="#FFF" stroke="#1d1d1d">
    <rect stroke="none" width="18" height="18" rx="2"/>
    <rect x="0.5" y="0.5" fill="none" width="17" height="17" rx="1.5"/>
  </g>
  <rect id="Rectangle_47" data-name="Rectangle 47" width="12" height="3" rx="0.5" transform="translate(3 8)"/>
</svg>
)

const Checked = () => {
  return(
    
    <svg id="CheckBox" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
  <g id="Rectangle_46" data-name="Rectangle 46" fill="#fff" stroke="#1d1d1d">
    <rect stroke="none;" width="18" height="18" rx="2"/>
    <rect fill="none;" x="0.5" y="0.5" width="17" height="17" rx="1.5"/>
  </g>
  <path id="Path_1" data-name="Path 1" fill="#000" d="M4.076-.2a.6.6,0,0,0,.849,0l6.9-6.9a.6.6,0,0,0,0-.849L10.976-8.8a.6.6,0,0,0-.849,0L4.5-3.171,1.873-5.8a.6.6,0,0,0-.849,0L.176-4.95a.6.6,0,0,0,0,.849Z" transform="translate(3 14)"/>
</svg>
  )
}

const BackorderIcon= () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.50802 1H9.49188L10.8 4.39758V11.8H1.19995V4.39758L2.50802 1ZM3.33188 2.2L2.67388 3.90909H9.32602L8.66802 2.2H3.33188ZM9.59995 5.10909H2.39995V10.6H9.59995V5.10909Z" fill="#4B575E" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.3999 4.50907V1.59998H6.5999V4.50907H5.3999Z" fill="#4B575E" />
  </svg>
)

const DropshipIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.3 20L5.2 12.3L5.3 12.2H8L2 8.2C2 8.1 2 8 2 7.9C2 3.5 5.5 0 9.8 0C14.1 0 17.6 3.5 17.6 7.8C17.6 7.9 17.6 8 17.5 8.1L11.5 12.1H14.3L14.4 12.2V19.8V20H5.3ZM13.9 19.3V12.9H5.8V19.3H13.9ZM10.2 11.8L13 8C12.6 7.7 12.1 7.5 11.6 7.5C11.1 7.5 10.5 7.7 10.1 8.1V11.8H10.2ZM9.4 11.8V8C9 7.7 8.5 7.5 8 7.5C7.5 7.5 7 7.7 6.6 8L9.4 11.8ZM15.4 7.5C14.8 7.5 14.3 7.7 13.9 8.1L11.3 11.5L16.8 7.9C16.3 7.6 15.9 7.5 15.4 7.5ZM8.4 11.5L5.7 8C5.3 7.7 4.8 7.5 4.2 7.5C3.7 7.5 3.3 7.7 2.9 7.9L8.4 11.5ZM8 6.7C8.7 6.7 9.3 6.9 9.9 7.3C10.4 6.9 11.1 6.7 11.8 6.7C12.5 6.7 13.1 6.9 13.7 7.3C14.2 6.9 14.9 6.7 15.6 6.7C16.1 6.7 16.6 6.8 17 7.1C16.6 3.5 13.6 0.9 10 0.9C6.4 0.9 3.4 3.6 3 7.1C3.4 6.9 3.9 6.7 4.4 6.7C5.1 6.7 5.7 6.9 6.3 7.3C6.6 6.9 7.3 6.7 8 6.7Z" fill="#4B575E" />
    <path d="M6.3999 15.6V15.5V15V14.9H13.1999L13.2999 15V15.5L13.1999 15.6H6.3999Z" fill="#4B575E" />
  </svg>
)

const CloneIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 24 24" style={{width:'13', height:'12px'}}>
    <path d="M22 6v16h-16v-16h16zm2-2h-20v20h20v-20zm-24 17v-21h21v2h-19v19h-2z" fill="#4B575E" />
  </svg>
)

const ReturnIcon = () => (
<svg width="15" height="15" viewBox="0 0 20 20" fill="black" xmlns="http://www.w3.org/2000/svg">
  <path fill="#4B575E" d="M5.5,3.7l1-2.2L2,4l4.5,2.4l-1.1-2h10.9c0.3,0,0.6,0.2,0.6,0.6v12.7c0,0.3-0.2,0.5-0.5,0.5h-3v0.6h3.3
	c0.5,0,0.8-0.4,0.8-0.8V4.6c0-0.5-0.4-0.8-0.8-0.8H5.5z"/>
</svg>
)


export {
  SemiChecked,
  Checked,
  Unchecked,
  DropshipIcon,
  ReturnIcon,
  BackorderIcon,
  CloneIcon
}