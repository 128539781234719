import React, { Component } from 'react';
import './DashboardBox.css';
import CustomDropdown from './../../../Components/Dropdown/CustomDropdown';
import CustomDatePicker from '../../../Components/CustomDatePicker/CustomDatePicker';
import moment from 'moment';
import { startOfDay, endOfDay } from 'date-fns';
import { getOrganizationDate } from '../../../Helpers/Date/OrganizationDate';
import { ToolTip } from '../../../Common';

export default class DashboardBox extends Component {
  constructor(props) {
    super();

    let dashboard_preferences = JSON.parse(localStorage.getItem("dashboardPreferences"))
    this.state = {
      current_filter: dashboard_preferences,
      currentDateRange: 'month',
      salesOrderSummarySelected: 'month',
      currentFilter: 'quantity',
      isCustomDateRangeSelected: null,
      dateRangePicker: {
        selection: {
          startDate: moment(startOfDay(getOrganizationDate())),
          endDate: moment(endOfDay(getOrganizationDate())),
          key: 'selection',
        },
      },
    };
  }

  dateRangeList = [
    {
      id: 'today',
      label: 'Today',
      onClick: (id) => this.handleDateRangeFilter(id),
    },
    {
      id: 'week',
      label: 'This Week',
      onClick: (id) => this.handleDateRangeFilter(id),
    },
    {
      id: 'month',
      label: 'This Month',
      onClick: (id) => this.handleDateRangeFilter(id),
    },
    {
      id: 'thirty_days',
      label: 'Last 30 Days',
      onClick: (id) => this.handleDateRangeFilter(id),
    },
    {
      id: 'six_months',
      label: 'Last 6 Months',
      onClick: (id) => this.handleDateRangeFilter(id),
    },
    {
      id: 'year',
      label: 'This Year',
      onClick: (id) => this.handleDateRangeFilter(id),
    },
    {
      id: 'last_year',
      label: 'Last Year',
      onClick: (id) => this.handleDateRangeFilter(id),
    },
    {
      id: 'total',
      label: 'Total',
      onClick: (id) => this.handleDateRangeFilter(id),
    },
    {
      id: 'custom',
      label: 'Custom',
      onClick: (id) => this.handleDateRangeFilter(id),
    },
  ];

  dateRangeList2 = [
    {
      id: 'today',
      label: 'Today',
      onClick: (id) => this.handleDateRangeFilter(id),
    },
    {
      id: 'week',
      label: 'This Week',
      onClick: (id) => this.handleDateRangeFilter(id),
    },
    {
      id: 'thirty_days',
      label: 'Last 4 Weeks',
      onClick: (id) => this.handleDateRangeFilter(id),
    },
    {
      id: 'month',
      label: 'This Month',
      onClick: (id) => this.handleDateRangeFilter(id),
    },
    {
      id: 'six_months',
      label: 'Last 6 Months',
      onClick: (id) => this.handleDateRangeFilter(id),
    },
    {
      id: 'year',
      label: 'This Year',
      onClick: (id) => this.handleDateRangeFilter(id),
    },
    {
      id: 'last_year',
      label: 'Last Year',
      onClick: (id) => this.handleDateRangeFilter(id),
    },
    // {
    //   id: 'custom',
    //   label: 'Custom',
    //   onClick: (id) => this.handleDateRangeFilter(id),
    // },
  ];

  filters = [
    {
      id: 'quantity',
      label: 'Quantity',
      onClick: (id) => this.handleFilter(id),
    },
    {
      id: 'amount',
      label: 'Amount',
      onClick: (id) => this.handleFilter(id),
    },
  ];
  salesOrderSummary = [
    {
      id: 'today',
      label: 'Today',
      onClick: (id) => this.handleSalesOrderSummary(id),
    },
    {
      id: 'week',
      label: 'This Week',
      onClick: (id) => this.handleSalesOrderSummary(id),
    },
    {
      id: 'month',
      label: 'This Month',
      onClick: (id) => this.handleSalesOrderSummary(id),
    },
    {
      id: 'thirty_days',
      label: 'Last 30 Days',
      onClick: (id) => this.handleSalesOrderSummary(id),
    },
    {
      id: 'six_months',
      label: 'Last 6 Months',
      onClick: (id) => this.handleSalesOrderSummary(id),
    },
    {
      id: 'year',
      label: 'This Year',
      onClick: (id) => this.handleSalesOrderSummary(id),
    },
    {
      id: 'last_year',
      label: 'Last Year',
      onClick: (id) => this.handleSalesOrderSummary(id),
    },
    {
      id: 'total',
      label: 'Total',
      onClick: (id) => this.handleSalesOrderSummary(id),
    },
    {
      id: 'custom',
      label: 'Custom',
      onClick: (id) => this.handleSalesOrderSummary(id),
    },
  ];

  handleSalesOrderSummary(filter) {
    this.setState({
      salesOrderSummarySelected: filter,
      current_filter: {
        ...this.state.current_filter,
        sales_order_summary: filter
      }
    });
  }

  handleDateRangeFilter(filter) {
    let currentcard = sessionStorage.getItem("card")
    let new_db_default = {}
    switch (currentcard) {
      case 'Sales Information': {
        new_db_default = {
          ...this.state.currentFilter,
          sales_information: filter
        }
      }
        break
      case 'Package & Shipment': {
        new_db_default = {
          ...this.state.currentFilter,
          package_and_shipment: filter
        }
      }
        break
      case 'Purchase Information': {
        new_db_default = {
          ...this.state.currentFilter,
          purchase_information: filter
        }
      }
        break
      case 'Receivables': {
        new_db_default = {
          ...this.state.currentFilter,
          receivables: filter
        }
      }
        break
      case 'Payable': {
        new_db_default = {
          ...this.state.currentFilter,
          payable: filter
        }
      }
        break
      case 'Top Selling Items': {
        new_db_default = {
          ...this.state.currentFilter,
          top_selling_items: filter
        }
      }
        break

      case 'Top Customers': {
        new_db_default = {
          ...this.state.currentFilter,
          top_customers: filter
        }
      }
        break
      case 'Sales vs Purchase': {
        new_db_default = {
          ...this.state.currentFilter,
          sales_vs_purchase: filter
        }
      }
        break
      default:
        return
    }
    this.setState({
      currentDateRange: filter,
      current_filter: new_db_default
    });
    // this.handleUpdateData(filter)

  }

  handleFilter(filter) {
    this.setState({ currentFilter: filter });
  }

  handleRangeChange = (range) => {
    this.setState({
      dateRangePicker: {
        ...this.state.dateRangePicker,
        selection: {
          ...this.state.dateRangePicker.selection,
          startDate: moment(startOfDay(range.selection.startDate)),
          endDate: moment(endOfDay(range.selection.endDate)),
        },
      },
    });
  };


  getinitalvalue = (module) => {
    // console.log(module)
    switch (module) {
      case 'Sales Information':
        return this.state.current_filter.sales_information
      case 'Package & Shipment':
        return this.state.current_filter.package_and_shipment
      case 'Purchase Information':
        return this.state.current_filter.purchase_information
      case 'Receivables':
        return this.state.current_filter.receivables
      case 'Payable':
        return this.state.current_filter.payable
      case 'Top Selling Items':
        return this.state.current_filter.top_selling_items
      case 'Top Customers':
        return this.state.current_filter.top_customers
      case 'Sales vs Purchase':
        return this.state.current_filter.sales_vs_purchase
      case 'Sales Order Summary -':
        return this.state.current_filter.sales_order_summary
      default:
        return 'month'
    }
  }
  render() {
    const {
      className,
      hasDateRange = true,
      salesOrderSummaryFlag,
      salevspurchaseFlag,
    } = this.props;
    const { salesOrderSummarySelected, dateRangePicker } = this.state;
    return (
      <div className={`dashboard_box ${className ? className : ''}`}>
        <div className="dashboard_box_header">
          <p>
            {this.props.title}{' '}
            {this.props.ToolTip ? (
              <span className="dashboardtitleTootip">
                <ToolTip content={this.props.ToolTip} />
              </span>
            ) : null}
          </p>
          {this.props.subTitile ? (
            <p>{this.props.subTitile === 'Confirmed Orders' ? `${this.props.subTitile}` :   `  ( ${this.props.subTitile} )  `}</p>
          ) : <p/>}
          {this.props.filter && (
            <span>
              <CustomDropdown
                className="summary-timeline--date-range"
                textLabel={
                  this.filters.find(
                    (option) => option.id === this.state.currentFilter
                  ).label
                }
                options={this.filters}
              />
            </span>
          )}

          {salesOrderSummaryFlag && (
            <span className="dashboard_box_header_dropdown ">
              {salesOrderSummarySelected === 'custom' && (
                <CustomDatePicker
                  type="range"
                  fromDashboard
                  ranges={dateRangePicker}
                  months={1}
                  onChange={this.handleRangeChange}
                />
              )}
              <CustomDropdown
                fromDashboard
                className="summary-timeline--date-range"
                textLabel={
                  this.salesOrderSummary.find(
                    (option) =>
                      option.id === this.getinitalvalue(this.props.title)
                  ).label
                }
                options={this.salesOrderSummary}
              />
            </span>
          )}
          {hasDateRange && !salesOrderSummaryFlag && (
            <span className="dashboard_box_header_dropdown">
              {this.state.currentDateRange === 'custom' && (
                <CustomDatePicker
                  type="range"
                  fromDashboard
                  ranges={dateRangePicker}
                  months={1} //commnet this portion if you want to disable custom date picker
                  onChange={this.handleRangeChange}
                />
              )}
              <CustomDropdown
                fromDashboard
                module={this.props.title}
                className={`summary-timeline--date-range ${this.state.currentDateRange === 'custom' && 'custom_name'
                  }`}
                textLabel={
                  salevspurchaseFlag
                    ? this.dateRangeList2.find(
                      (option) => option.id === this.getinitalvalue(this.props.title)
                    ).label
                    : this.dateRangeList.find(
                      (option) => option.id === this.getinitalvalue(this.props.title)
                    )?.label
                }
                options={
                  salevspurchaseFlag ? this.dateRangeList2 : this.dateRangeList
                }
              />
            </span>
          )}
        </div>
        <div className="float-left w-100 data_section">
          {this.props.render(
            this.state.current_filter,
            this.props.filter
              ? this.state.currentFilter
              : dateRangePicker.selection.startDate.format(
                'YYYY-MM-DD HH:mm:ss'
              ),
            dateRangePicker.selection.endDate.format('YYYY-MM-DD HH:mm:ss'),
            salesOrderSummarySelected,
            this.props.filter &&
            dateRangePicker.selection.startDate.format('YYYY-MM-DD HH:mm:ss')
          )}
        </div>
      </div>
    );
  }
}
