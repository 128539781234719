import React from 'react'

export default ({
  className = ''
}) => (
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 14.729 14.729">
  <g transform="translate(-914 -238)"><path d="M17.729-10.635a7.312,7.312,0,0,0-1-3.721,7.148,7.148,0,0,0-2.647-2.627A7.207,7.207,0,0,0,10.365-18a7.207,7.207,0,0,0-3.721,1.016,7,7,0,0,0-2.627,2.627A7.207,7.207,0,0,0,3-10.635v.269l4.91,4.3v2.8h4.91v-2.8l4.91-4.3Zm-7.365-5.907a5.719,5.719,0,0,1,.978,1.5,9.842,9.842,0,0,1,.786,2.973,3.814,3.814,0,0,0-1.764-.4,3.814,3.814,0,0,0-1.764.4,9.842,9.842,0,0,1,.786-2.973A5.719,5.719,0,0,1,10.365-16.542ZM7.354-10.386,8.83-6.953H8.754L4.438-10.731a1.753,1.753,0,0,1,1.323-.518,1.862,1.862,0,0,1,1.208.4,1.386,1.386,0,0,1,.384.441Zm2.8,3.433L8.619-10.559a2.177,2.177,0,0,1,1.745-.69,2.663,2.663,0,0,1,1.17.249,2.059,2.059,0,0,1,.595.441L10.576-6.953Zm1.764,0,1.458-3.433v-.019a1.386,1.386,0,0,1,.384-.441,1.814,1.814,0,0,1,1.208-.4,1.792,1.792,0,0,1,1.323.518L11.976-6.953Zm4.373-5.255a3.179,3.179,0,0,0-1.323-.269,3.091,3.091,0,0,0-1.611.422,9.928,9.928,0,0,0-1.477-4.526,6.108,6.108,0,0,1,2.8,1.6A6.011,6.011,0,0,1,16.291-12.208ZM8.849-16.581a9.928,9.928,0,0,0-1.477,4.526,3.091,3.091,0,0,0-1.611-.422,3.179,3.179,0,0,0-1.323.269,6.011,6.011,0,0,1,1.611-2.771A6.108,6.108,0,0,1,8.849-16.581ZM11.592-4.5H9.137V-5.726h2.455Z"
    transform="translate(911 256)" fill="#222629"/>
  </g>
</svg>
)
