import React, { Component } from 'react';

import { restRequest } from '../../../Helpers/RequestHelper';

import NotFound from '../../NotFound/NotFound';
import Loader from '../../Loader/Loader';
import AccessDenied from '../../AccessDenied/AccessDenied';

import WithContext from '../../../Context/UserContextHOC';
import RoleFormEnhanced from './RoleFormEnhancer';
import './RoleForm.css';
import { checkError } from '../../../Helpers/AuthHelper';

class RoleForm extends Component {
  type = this.props.type || 'add'; // add, edit
  id = this.props.id || null;

  state = {
    role: {
      name: '',
      id: '',
      role_preferences:[],
      selectAll: false,
      slug:'sales_person',
      permissions: {
        ContactView: false,
        ContactCreate: false,
        ContactEdit: false,
        ContactDelete: false,

        OrganizationView: true,
        OrganizationCreate: true,
        OrganizationEdit: false,
        OrganizationDelete: false,

        AccountView: false,
        AccountCreate: false,
        AccountEdit: false,
        AccountDelete: false,

        ItemView: false,
        ItemCreate: false,
        ItemEdit: false,
        ItemDelete: false,

        ItemGroupView: false,
        ItemGroupCreate: false,
        ItemGroupEdit: false,
        ItemGroupDelete: false,

        ItemAdjustmentView: false,
        ItemAdjustmentCreate: false,
        ItemAdjustmentEdit: false,
        ItemAdjustmentDelete: false,

        WarehouseView: false,
        WarehouseCreate: false,
        WarehouseEdit: false,
        WarehouseDelete: false,

        // TransferOrderView   : false,
        // TransferOrderCreate : false,
        // TransferOrderEdit : false,
        // TransferOrderDelete : false,

        PurchaseOrderView: false,
        PurchaseOrderCreate: false,
        PurchaseOrderEdit: false,
        PurchaseOrderDelete: false,

        SalesOrderView: false,
        SalesOrderCreate: false,
        SalesOrderEdit: false,
        SalesOrderDelete: false,

        InviteView: false,
        InviteCreate: false,
        InviteEdit: false,
        InviteDelete: false,

        RoleView: false,
        RoleCreate: false,
        RoleEdit: false,
        RoleDelete: false,

        // Preference
        PreferenceView: false,
        PreferenceEdit: false,

        UserView: false,
        UserEdit: false,
        UserDelete: false,

        ReceiveView: false,
        ReceiveCreate: false,
        ReceiveEdit: false,
        ReceiveDelete: false,

        InvoiceView: false,
        InvoiceCreate: false,
        InvoiceEdit: false,
        InvoiceDelete: false,

        PackageView: false,
        PackageCreate: false,
        PackageEdit: false,
        PackageDelete: false,

        BillView: false,
        BillCreate: false,
        BillEdit: false,
        BillDelete: false,

        TaxView: false,
        TaxCreate: false,
        TaxEdit: false,
        TaxDelete: false,

        InvoicePaymentRecordView: false,
        InvoicePaymentRecordCreate: false,
        InvoicePaymentRecordEdit: false,
        InvoicePaymentRecordDelete: false,

        PaymentRecordView: false,
        PaymentRecordCreate: false,
        PaymentRecordEdit: false,
        PaymentRecordDelete: false,

        ShipmentView: false,
        ShipmentCreate: false,
        ShipmentEdit: false,
        ShipmentDelete: false,

        CurrencyView: false,
        CurrencyCreate: false,
        CurrencyEdit: false,
        CurrencyDelete: false,

        DashboardSalesInformationView: false,
        DashboardPackageAndShipmentView: false,
        DashboardPurchaseInformationView: false,
        DashboardReceivablesView: false,
        DashboardPayableView: false,
        DashboardTopSellingItemsView: false,
        DashboardTopCustomersView: false,
        DashboardOrderDetailsView: false,
        DashboardSalesVsPurchaseView: false,
        DashboardSalesOrderSummaryView: false,

        InventoryReportView: false,
        SalesOrderReportView: false,
        PurchaseOrderReportView: false,
        TaxReportView: false,

        SalesReturnView: false,
        SalesReturnCreate: false,
        SalesReturnEdit: false,
        SalesReturnDelete: false,

        CreditNoteView: false,
        CreditNoteCreate: false,
        CreditNoteEdit: false,
        CreditNoteDelete: false,

        ReturnRecieveView: false,
        ReturnRecieveCreate: false,
        ReturnRecieveEdit: false,
        ReturnRecieveDelete: false,

        DeliveryMethodView: false,
        DeliveryMethodCreate: false,
        DeliveryMethodEdit: false,
        DeliveryMethodDelete: false,

        PaymentModeView: false,
        PaymentModeCreate: false,
        PaymentModeEdit: false,
        PaymentModeDelete: false,
      },
      salesPerson: false,
    },
    roleCreated: false,
    roleUpdated: false,
    viewPermissionsChecked: false,
    createPermissionsChecked: false,
    editPermissionsChecked: false,
    deletePermissionsChecked: false,
    loading: true,
    notFound: false,
    accessDenied: false,
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.fetchData().then(() => this.setState({ loading: false }));
  }

  fetchData = async () => {
    this.setState({ loading: true });

    if (this.type === 'edit') {
      await this.fetchRoleDetails();
    } else {
      await this.fetchSalesRole();
    }
 };

  fetchSalesRole() {
    return restRequest('get', `roles/create`).
      then((res) => {
        if (!res) {
          this.setState({
            loading: false,
          });
          return;
        }
        
        this.setState({
          loading: false,
          role: {
            ...this.state.role,
            role_preferences: [...res?.role_preferences],
          }
        });
      })
  }

  fetchRoleDetails() {
    return restRequest('get', `roles/${this.id}/edit`)
      .then((res) => {
        if (!res) {
          this.setState({
            loading: false,
            notFound: true,
          });
          return;
        }

        if (res.type === 'sales') {
          this.setState({
            role: {
              name: res.name,
              id: res.id,
              salesPerson: true,
              permissions: {
                ...this.state.permissions,
                ...JSON.parse(res.permissions),
              },
              slug: res.slug ? res.slug : 'sales_person',
              role_preferences:[...res?.role_preferences]
            },
            loading: false,
          });
        } else {
          this.setState({
            role: {
              name: res.name,
              id: res.id,
              salesPerson: false,
              selectAll: true,
              permissions: {
                ...this.state.permissions,
                ...JSON.parse(res.permissions),
              },
              slug: res.slug ? res.slug : 'sales_person',
              role_preferences:[...res?.role_preferences]
            },
            loading: false,
          });
        }
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, 'error')
        if (error.response && error.response.status === 403) {
          this.setState({
            accessDenied: true,
            loading: false,
          });
        } else {
          this.setState({
            // planExceeded: error && error.response && error.response.data && error.response.data.status,
            loading: false,
          });
        }
      });
  }

  submitRequestType() {
    if (this.type === 'add') {
      return 'post';
    } else if (this.type === 'edit') {
      return 'put';
    }
  }

  submitRequestUrl() {
    if (this.type === 'add') {
      return 'roles';
    } else if (this.type === 'edit') {
      return `roles/${this.id}`;
    }
  }

  successMessage = () => {
    if (this.type === 'add') {
      this.props.handleToast('Role created successfully', 'success');
    } else if (this.type === 'edit') {
      this.props.handleToast('Role has been updated', 'success');
    }
  };

  renderRoleForm() {
    const {
      role,
      viewPermissionsChecked,
      createPermissionsChecked,
      editPermissionsChecked,
      deletePermissionsChecked,
      selectAllPermissions,
    } = this.state;
    const {
      title,
      onSubmit,
      handleToast,
      onCancel,
      handleConfirmFormInviteForm,
      setPrompt,
    } = this.props;
    return (
      <RoleFormEnhanced
        title={title}
        role={role}
        selectAllPermissions={selectAllPermissions}
        checkedPermissions={{
          viewPermissionsChecked,
          createPermissionsChecked,
          editPermissionsChecked,
          deletePermissionsChecked,
        }}
        requestType={this.submitRequestType()}
        requestUrl={this.submitRequestUrl()}
        successMessage={this.successMessage}
        onSubmit={onSubmit}
        handleToast={handleToast}
        onCancel={onCancel}
        action={this.type}
        setPrompt={setPrompt}
        handleConfirmFormInviteForm={handleConfirmFormInviteForm}
      />
    );
  }

  checkPermissions() {
    const {
      context: {
        role: { permissions },
      },
    } = this.props;
    if (!permissions.superAccess) {
      if (
        this.type === 'edit' &&
        (!permissions.RoleEdit || this.state.accessDenied)
      )
        return <AccessDenied className="small" type="section" />;
      if (!permissions.RoleCreate || this.state.accessDenied)
        return <AccessDenied className="small" type="section" />;
    }
    return this.renderRoleForm();
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    return this.checkPermissions();
  }
}

export default WithContext(RoleForm);
