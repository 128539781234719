import React from 'react'

import Import from '../../Components/Import/Import'
import FormLayout from '../../Components/Layout/FormLayout'
import { NewItemIcon } from '../../Assets/Navigation/NavigationIcons'

const ItemsImport = (props) => {
  return (
    <FormLayout
      title="Items"
      Icon={NewItemIcon}
      iconstyle="general_module_icon"
      subtitle="Import"
    >
      <Import
        {...props}
        name="Items"
        fetchHeadersAPI="items/importheader"
        fetchSummaryAPI="items/importpreview"
        importAPI="items/import"
        onCancelURL="items"
        sampleFileLink={`${process.env.REACT_APP_AMAZON_S3}/sample-files/items-sample-file.csv`}
      />
    </FormLayout>
  )
}

export default ItemsImport
