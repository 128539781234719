import React, { Component } from 'react';

import SubNavMenu from '../../../Components/SubNavMenu/SubNavMenu';
import UsersTab from './UsersTab/UsersTab';
import RolesTab from './RolesTab/RolesTab';
import PendingInvitesTab from './PendingInvitesTab/PendingInvitesTab';
import InviteForm from '../../../Components/Form/InviteForm/InviteForm';
import RoleForm from '../../../Components/Form/RoleForm/RoleForm';
import { confirmationAlert } from '../../../Helpers';
export default class OrganizationTabs extends Component {
  state = {
    currentTab: 'users',
    showInvitedUsers: false,
    showUserRolesUsersAdd: false,
    confirmFlagForInviteForm: false,
    confirmFlagForRoleForm: false,
  };

  renderUsersTab = () => {
    return (
      <UsersTab
        handleToast={this.props.handleToast}
        setPrompt={this.props.setPrompt}
        handleConfirmFormInviteForm={this.handleConfirmForRoleForm}
      />
    );
  };

  renderRolesTab = () => {
    return (
      <RolesTab
        handleToast={this.props.handleToast}
        setPrompt={this.props.setPrompt}
        handleConfirmFormInviteForm={this.handleConfirmForRoleForm}
      />
    );
  };

  renderRolesTabAdd = () => {
    return (
      <RoleForm
        title="Add Role"
        type="add"
        handleToast={this.props.handleToast}
        onCancel={this.onSubmit}
        onSubmit={this.onSubmit}
        handleConfirmFormInviteForm={this.handleConfirmForRoleForm}
        setPrompt={this.props.setPrompt}
      />
    );
  };

  renderPendingInvitesTab = () => {
    return (
      <PendingInvitesTab
        handleToast={this.props.handleToast}
        setPrompt={this.props.setPrompt}
        handleConfirmFormInviteForm={this.handleConfirmForRoleForm}
      />
    );
  };

  onSubmit = () => {
    this.setState({
      showInvitedUsers: false,
      showUserRolesUsersAdd: false,
    });
    let scrollTopDiv = document.getElementsByClassName(
      'detail_component_inner'
    );
    this.props.history.push('/useraccess');
    scrollTopDiv[0].scrollTop = 0;
  };

  handleConfirmFormInviteForm = (prop = true) => {
    this.setState({
      confirmFlagForInviteForm: prop,
    });
  };

  handleConfirmForRoleForm = (prop = true) => {
    this.setState({
      confirmFlagForRoleForm: prop,
    });
  };

  renderInvitesTab = () => {
    return (
      <InviteForm
        handleToast={this.props.handleToast}
        toggleInvite={this.onSubmit}
        onSubmitInvite={this.onSubmit}
        history={this.props.history}
        confirmFlagForInviteForm={this.state.confirmFlagForInviteForm}
        handleConfirmFormInviteForm={this.handleConfirmFormInviteForm}
        setPrompt={this.props.setPrompt}
      />
    );
  };

  handleTabChange(tab) {
    this.setState({ currentTab: tab });
  }

  showInvitedUsers() {
    this.handleTabChange('pendingInvites');
    this.setState({ showInvitedUsers: true });
  }

  showUserRolesUsersAdd() {
    this.handleTabChange('roles');
    this.setState({ showUserRolesUsersAdd: true });
  }

  tabs() {
    return [
      {
        id: 'roles',
        label: 'User Roles',
        onClick: async () => {
          let response = true;
          if (
            this.state.confirmFlagForInviteForm ||
            this.state.confirmFlagForRoleForm
          ) {
            response = confirmationAlert();
            if (response) {
              await this.props.setPrompt(false);
            }
          }
          if (response) {
            this.handleTabChange('roles');
            this.setState({ showUserRolesUsersAdd: false });
            this.setState({ confirmFlagForInviteForm: false });
            this.setState({ confirmFlagForRoleForm: false });
            this.props.history.push('/useraccess');
          }
        },
        renderContent: this.state.showUserRolesUsersAdd
          ? this.renderRolesTabAdd
          : this.renderRolesTab,
      },
      {
        id: 'users',
        label: 'Users',
        onClick: async () => {
          let response = true;
          if (
            this.state.confirmFlagForInviteForm ||
            this.state.confirmFlagForRoleForm
          ) {
            response = confirmationAlert();
            if (response) {
              await this.props.setPrompt(false);
            }
          }
          if (response) {
            this.handleTabChange('users');
            this.setState({ confirmFlagForInviteForm: false });
            this.setState({ confirmFlagForRoleForm: false });
            this.props.history.push('/useraccess');
          }
        },
        renderContent: this.renderUsersTab,
      },
      {
        id: 'pendingInvites',
        label: 'Invited Users',
        onClick: async () => {
          let response = true;
          if (
            this.state.confirmFlagForInviteForm ||
            this.state.confirmFlagForRoleForm
          ) {
            response = confirmationAlert();
            if (response) {
              await this.props.setPrompt(false);
            }
          }
          if (response) {
            this.handleTabChange('pendingInvites');
            this.setState({ showInvitedUsers: false });
            this.setState({ confirmFlagForInviteForm: false });
            this.setState({ confirmFlagForRoleForm: false });
            this.props.history.push('/useraccess');
          }
        },
        renderContent: this.state.showInvitedUsers
          ? this.renderInvitesTab
          : this.renderPendingInvitesTab,
        // AddForm : () => null
      },
    ];
  }

  render() {
    return (
      <div className="organization-table">
        <SubNavMenu
          tabs={this.tabs()}
          showInvitedUsers={this.showInvitedUsers.bind(this)}
          showUserRolesUsersAdd={this.showUserRolesUsersAdd.bind(this)}
          currentTab={this.state.currentTab}
          history={this.props.history}
          confirmFlagForInviteForm={this.state.confirmFlagForInviteForm}
          confirmFlagForRoleForm={this.state.confirmFlagForRoleForm}
          handleConfirmFormInviteForm={this.handleConfirmFormInviteForm}
          handleConfirmForRoleForm={this.handleConfirmForRoleForm}
        />
      </div>
    );
  }
}
