import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import './index.css';

const DragDropItems = ({
  children,
  handleOnDragEnd = () => {},
  onLoad = () => {},
}) => {
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="items">
        {(provided) => (
          <tbody
            {...provided.droppableProps}
            ref={provided.innerRef}
            onLoad={onLoad}
          >
            {children}
            {provided.placeholder}
          </tbody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragDropItems;
