import React from 'react'
import { Link } from 'react-router-dom'
import './SettingsCard.css'

const SettingsCard = (
  {
    icon = null,
    title = '',
    subtitle = '',
    link = '',
    showCreate=true,
    showView = true
  }) => {
  let defaultLink = ""
  if(link === ""){
    if(title === "Taxes"){
      defaultLink = "/taxes"
    }else if(title === "Delivery Methods"){
      defaultLink = "/deliverymethods"
    }
  }else{
    defaultLink = link
  }
  let myLink=`${defaultLink}?new=1`

  return (
    <div className="settings-card">
      <Link to={(link) ? link : myLink}>
        {icon}
        <h3 className="settings-card--title">{title}</h3>
        <p className="settings-card--sub-title">{subtitle}</p>
      </Link>
        <div className="settings_action_buttons">
        {
          showCreate &&
          <a href={myLink}>Create</a>
        }
        {showView && link === "" && <a href={myLink}>Manage</a>}
        {showView && link !== "" && <a href={link}>Manage</a>}
        </div>
    </div>
  )
}

export default SettingsCard