import React from 'react'

export default ({
  className = ''
}) => (

<svg className={className+ " currency_icon"} xmlns="http://www.w3.org/2000/svg" width="30.625" height="18.594" viewBox="0 0 30.625 18.594">
  <path className="a"
        d="M2.188-22.969V-4.375H32.813V-22.969Zm4.375,2.188H28.438a2.114,2.114,0,0,0,.632,1.555,2.114,2.114,0,0,0,1.555.632V-8.75a2.114,2.114,0,0,0-1.555.632,2.114,2.114,0,0,0-.632,1.555H6.563A2.114,2.114,0,0,0,5.93-8.118,2.114,2.114,0,0,0,4.375-8.75v-9.844a2.114,2.114,0,0,0,1.555-.632A2.114,2.114,0,0,0,6.563-20.781Zm9.844,2.188a2.346,2.346,0,0,1-.5,1.692,2.346,2.346,0,0,1-1.692.5v2.188a5.139,5.139,0,0,0,2.188-.444V-8.75h2.188v-9.844ZM9.3-15.312a1.582,1.582,0,0,0-1.162.479,1.582,1.582,0,0,0-.479,1.162,1.582,1.582,0,0,0,.479,1.162,1.582,1.582,0,0,0,1.162.479,1.582,1.582,0,0,0,1.162-.479,1.582,1.582,0,0,0,.479-1.162,1.582,1.582,0,0,0-.479-1.162A1.582,1.582,0,0,0,9.3-15.312Zm16.406,0a1.582,1.582,0,0,0-1.162.479,1.582,1.582,0,0,0-.479,1.162,1.582,1.582,0,0,0,.479,1.162,1.582,1.582,0,0,0,1.162.479,1.582,1.582,0,0,0,1.162-.479,1.582,1.582,0,0,0,.479-1.162,1.582,1.582,0,0,0-.479-1.162A1.582,1.582,0,0,0,25.7-15.312Z"
        transform="translate(-2.188 22.969)"/>
</svg>
   )
