export default function getModuleName(name = null) {
  switch (name) {
    case 'items':
      return `Items`;
    case 'item-groups':
    case 'item_groups':
      return 'Item Groups';
    case 'item-adjustments':
      return `Item Adjustments`;
    case 'contacts':
      return `Contacts`;
    case 'salesorders':
    case 'purchaseorders':
      return `Orders`;
    case 'package_listing':
    case 'packages':
      return `Packages`;
    case 'invoices':
      return `Invoices`;
    case 'bills':
      return `Bills`;
    case 'salesreturns':
      return `Sales Returns`;
    case 'creditnotes':
      return `Credit Notes`;
    case 'mapping':
      return `Item Mapping`;
    case 'paymentsmade':
      return `Payments`;
    default:
      return 'Records';
  }
}
