import React from 'react';
import './index.css';

const PopOver = ({
  title = 'clickme',
  show = false,
  titleClick = () => {},
  cancelHandler = () => {},
  mainClassName = '',
  titleClass = 'group-btn-sm',
  children,
  onYesClickHandler = () => {},
}) => {
  return (
    <div className={`popover__wrapper ${mainClassName}`}>
      <span className={`popover__title  ${titleClass}`} onClick={titleClick}>
        {title}
      </span>

      <div className={`popover__content ${show ? 'show' : 'hide'}`}>
        <div className="popover__message">{children}</div>
        <div className="btnsRow">
        <button type="button" className="cancel-btn mr-3" onClick={cancelHandler}>
            No
          </button>
          <button className="next-btn" type="button" onClick={onYesClickHandler}>
            Yes
          </button>
         
        </div>
      </div>
    </div>
  );
};

export default PopOver;
