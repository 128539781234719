import React from 'react'

export default ({
  className = ''
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18" style={{fill:'white', stroke:'black', strokeWidth: '1px' }}>

    <g transform="translate(-1276 -734)">
      <g className="a" transform="translate(1276 734)">
        <rect className="b" width="15" height="18" rx="2"/>
        <rect className="c" x="0.75" y="0.75" width="13.5" height="16.5" rx="1.25"/>
      </g>
      <path d="M-2655.167,2.4h-3.5V3.6L-2661,1.8l2.334-1.8V1.2H-2654V2.4h0l-.005,2.726-1.159.875Z"
            transform="translate(3941 740)"/>
    </g>
  </svg>
  )
