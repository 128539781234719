import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { restRequest } from '../../../Helpers/RequestHelper';

import OverlayLoader from '../../../Components/Loader/OverlayLoader';
import Form from '../../../Components/Form/Form';
import FormAction from '../../../Components/Form/FormAction';
import { getObjFromLS } from '../../../Helpers/LocalStorage'; //, setObjInLS
import SinglePrefixField from './SinglePrefixField';
import './Prefix.css';
import { checkError } from '../../../Helpers/AuthHelper';
import { isEqual } from 'lodash';

class Prefix extends Component {
  state = {
    loading: true,
    showPrompt: false,
    staticVal: {},
    soFormat: '',
    pkFormat: '',
    shFormat: '',
    invFormat: '',
    poFormat: '',
    blFormat: '',
    srFormat: '',
    rrFormat: '',
    crFormat: '',
    rcvFormat: '',
    advInvFormat: '',
    advBilFormat: '',
    skuFormat: '',
    startFrom: '',
    startFromLocked: '',
    skuTerms: '',
    isSubmitting: false,
  };

  componentDidMount() {
    localStorage.setItem('alert', false);
    this.fetchData();
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { staticVal } = this.state;
    const {
      soFormat,
      pkFormat,
      shFormat,
      invFormat,
      poFormat,
      blFormat,
      srFormat,
      rrFormat,
      crFormat,
      rcvFormat,
      //advInvFormat,
      //advBilFormat,
      skuFormat,
      startFrom,
      startFromLocked,
      skuTerms,
    } = this.state;
    const data = {
      soFormat,
      pkFormat,
      shFormat,
      invFormat,
      poFormat,
      blFormat,
      srFormat,
      rrFormat,
      crFormat,
      rcvFormat,
      //advInvFormat,
      //advBilFormat,
      skuFormat,
      startFrom,
      startFromLocked,
      skuTerms,
    };
    if (prevState.showPrompt !== this.state.showPrompt) {
      const checkEquality = Boolean(isEqual(data, staticVal));
      if (!checkEquality) localStorage.setItem('alert', true);
      else localStorage.setItem('alert', false);
    }
  }

  handlePrompt = () => {
    this.setState({
      showPrompt: !this.state.showPrompt,
    });
  };

  async fetchData() {
    this.setState({ loading: true });
    await restRequest('get', 'numberPreferences/all')
      .then((res) => {
        this.setState({
          soFormat: res.sales_order_preference.preferences.soFormat,
          pkFormat: res.package_preference.preferences.pkFormat,
          shFormat: res.shipment_preference.preferences.shFormat,
          invFormat: res.invoice_preference.preferences.invFormat,
          poFormat: res.purchase_order_preference.preferences.poFormat,
          blFormat: res.bill_preference.preferences.blFormat,
          srFormat: res.sales_return_preference.preferences.srFormat,
          rrFormat: res.return_receive_preference.preferences.rrFormat,
          crFormat: res.credit_note_preference.preferences.crFormat,
          rcvFormat: res.receive_preference.preferences.rcvFormat,
          //advInvFormat: res.advance_invoice_payment_preference.preferences.advInvFormat,
          //advBilFormat: res.advance_bill_payment_preference.preferences.advBilFormat,
          skuFormat: res.sku_preference.preferences.skuFormat,
          startFrom: res.sku_preference.preferences.startFrom,
          startFromLocked: res.sku_preference.preferences.startFromLocked,
          skuTerms:
            res.sku_preference.terms !== undefined
              ? res.sku_preference.terms
              : 'auto',

          staticVal: {
            soFormat: res.sales_order_preference.preferences.soFormat,
            pkFormat: res.package_preference.preferences.pkFormat,
            shFormat: res.shipment_preference.preferences.shFormat,
            invFormat: res.invoice_preference.preferences.invFormat,
            poFormat: res.purchase_order_preference.preferences.poFormat,
            blFormat: res.bill_preference.preferences.blFormat,
            srFormat: res.sales_return_preference.preferences.srFormat,
            rrFormat: res.return_receive_preference.preferences.rrFormat,
            crFormat: res.credit_note_preference.preferences.crFormat,
            rcvFormat: res.receive_preference.preferences.rcvFormat,
            //advInvFormat: res.advance_invoice_payment_preference.preferences.advInvFormat,
            //advBilFormat: res.advance_bill_payment_preference.preferences.advBilFormat,
            skuFormat: res.sku_preference.preferences.skuFormat,
            startFrom: res.sku_preference.preferences.startFrom,
            startFromLocked: res.sku_preference.preferences.startFromLocked,
            skuTerms:
              res.sku_preference.terms !== undefined
                ? res.sku_preference.terms
                : 'auto',
          },
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let updatedStates = { ...this.state };
    delete updatedStates.isSubmitting;
    delete updatedStates.loading;
    //this.setState({ isSubmitting: false });
    this.setState({ isSubmitting: true });
    restRequest('put', 'numberPreferences/all', updatedStates)
      .then(() => {
        this.setState({ isSubmitting: false });
        this.props.handleToast('Changes updated successfully', 'success');
        this.props.setPrompt(false);
        this.fetchData();
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });
        this.props.handleToast(error, 'error');
        checkError(error);
      });
  };

  renderActionButtons() {
    let permissions = getObjFromLS('role').permissions;
    if (!permissions.superAccess) {
      if (permissions.PreferenceEdit) {
        return (
          <FormAction
            disabled={this.state.isSubmitting}
            onCancel={() => this.props.history.push('/settings')}
          />
        );
      }
    }
    if (permissions.superAccess) {
      return (
        <FormAction
          disabled={this.state.isSubmitting}
          onCancel={() => this.props.history.push('/settings')}
        />
      );
    }
  }

  changeSOFormat(val) {
    this.setState({ soFormat: val });
    this.handlePrompt();
  }
  changepkFormat(val) {
    this.setState({ pkFormat: val });
    this.handlePrompt();
  }
  changeShFormat(val) {
    this.setState({ shFormat: val });
    this.handlePrompt();
  }
  changeInvFormat(val) {
    this.setState({ invFormat: val });
    this.handlePrompt();
  }
  changePoFormat(val) {
    this.setState({ poFormat: val });
    this.handlePrompt();
  }
  changeBlFormat(val) {
    this.setState({ blFormat: val });
    this.handlePrompt();
  }
  changeRrFormat(val) {
    this.setState({ rrFormat: val });
    this.handlePrompt();
  }
  changeCrFormat(val) {
    this.setState({ crFormat: val });
    this.handlePrompt();
  }
  changeRcvFormat(val) {
    this.setState({ rcvFormat: val });
    this.handlePrompt();
  }
  /*changeAdvInvFormat(val) {
    this.setState({ advInvFormat: val });
    this.handlePrompt();
  }
  changeAdvBillFormat(val) {
    this.setState({advBilFormat: val });
    this.handlePrompt();
  }*/
  changeSrFormat(val) {
    this.setState({ srFormat: val });
    this.handlePrompt();
  }
  changeSkuFormat(val) {
    this.setState({ skuFormat: val });
    this.handlePrompt();
  }

  changeStartFrom(val) {
    this.setState({ startFrom: val });
    this.handlePrompt();
  }

  updateOptions(obj, option) {
    let updatedObj = option;
    let objNew = document.getElementById(option);
    objNew.checked = true;
    objNew.value = option;

    if (updatedObj === 'auto') {
      document.getElementById('auto').checked = true;
      document.getElementById('manual').checked = false;
    } else {
      document.getElementById('auto').checked = false;
      document.getElementById('manual').checked = true;
    }
    return objNew;
  }

  handleRadioChange = (event) => {
    // let stateName = event.target.name;
    // let option = event.target.id;
    // let updatedOptions = this.updateOptions(stateName, option);
    // this.setState((state) => ({
    //   [stateName]: {
    //     ...state[stateName],
    //     ...option,
    //   },
    // }));
    this.setState({
      skuTerms: event.target.value,
    });
    this.handlePrompt();
  };

  handleRadioClick(radioOption, radiostateName) {
    let stateName = radiostateName;
    let option = radioOption;
    this.setState({ skuTerms: radioOption });
    this.updateOptions(stateName, option);
  }

  renderStatusChangeInput() {
    const { loading } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        {loading && <OverlayLoader />}
        <div className="float-left w-100 height_custom__ preference-height prefix-main">
          <div
            className="float-left w-70 h-100 right_sec"
            style={{ padding: '10px 0px 10px 0px' }}
          >
            <div className="add_prefix float-left w-100">
              Add Custom prefixes as per your requirement ({' '}
              <p>
                i.e for <i>Sales order</i> you can use <i>SO</i>{' '}
              </p>{' '}
              )
            </div>
            <div className="float-left w-50 prefixes">
              <SinglePrefixField
                loading={loading}
                label="Sales Order Prefix"
                value={this.state.soFormat}
                handleChange={this.changeSOFormat.bind(this)}
              />
              <SinglePrefixField
                loading={loading}
                label="Shipment Prefix"
                value={this.state.shFormat}
                handleChange={this.changeShFormat.bind(this)}
              />
              <SinglePrefixField
                loading={loading}
                label="Purchase Order"
                value={this.state.poFormat}
                handleChange={this.changePoFormat.bind(this)}
              />
              <SinglePrefixField
                loading={loading}
                label="Sales Return Prefix"
                value={this.state.srFormat}
                handleChange={this.changeSrFormat.bind(this)}
              />
              <SinglePrefixField
                loading={loading}
                label="Credit Note Prefix"
                value={this.state.crFormat}
                handleChange={this.changeCrFormat.bind(this)}
              />
              {/*<SinglePrefixField
                loading={loading}
                label="Advance Invoice Payment Prefix"
                value={this.state.advInvFormat}
                handleChange={this.changeAdvInvFormat.bind(this)}
              />*/}
            </div>
            <div className="float-left w-50">
              <SinglePrefixField
                loading={loading}
                label="Package Prefix"
                value={this.state.pkFormat}
                handleChange={this.changepkFormat.bind(this)}
              />
              <SinglePrefixField
                loading={loading}
                label="Invoices Prefix"
                value={this.state.invFormat}
                handleChange={this.changeInvFormat.bind(this)}
              />
              <SinglePrefixField
                loading={loading}
                label="Bill Prefix"
                value={this.state.blFormat}
                handleChange={this.changeBlFormat.bind(this)}
              />
              <SinglePrefixField
                loading={loading}
                label="Return Receive Prefix"
                value={this.state.rrFormat}
                handleChange={this.changeRrFormat.bind(this)}
              />
              <SinglePrefixField
                loading={loading}
                label="Receive Prefix"
                value={this.state.rcvFormat}
                handleChange={this.changeRcvFormat.bind(this)}
              />
              {/*<SinglePrefixField
                loading={loading}
                label="Advance Bill Payment Prefix"
                value={this.state.advBilFormat}
                handleChange={this.changeAdvBillFormat.bind(this)}
              />*/}
            </div>
            <div
              className="float-left w-50 prefixes prefixes-main-set"
              style={{ paddingTop: '5px', borderTop: '1px solid #CCCCCC' }}
            >
              <SinglePrefixField
                loading={loading}
                label="SKU Prefix"
                value={this.state.skuFormat}
                handleChange={this.changeSkuFormat.bind(this)}
              />
              <SinglePrefixField
                disabled={this.state.startFromLocked !== '' ? true : false}
                loading={loading}
                label="SKU Start From"
                value={this.state.startFrom}
                handleChange={this.changeStartFrom.bind(this)}
              />
            </div>
            <div
              className="float-left w-50 prefixes prefixes-main-set prefixes-main-child"
              style={{ paddingTop: '5px', borderTop: '1px solid #CCCCCC' }}
            >
              <div className="float-left w-5">
                <input
                  type="radio"
                  checked={this.state.skuTerms === 'auto' ? true : false}
                  onChange={(event) => this.handleRadioChange(event)}
                  name="skuTerms"
                  id="auto"
                  // value={this.state.skuTerms}
                  value="auto"
                />
              </div>
              <div className="float-left w-95">
                <label htmlFor="auto" style={{ marginBottom: '5px' }}>
                  Auto Generate SKU Serial Number
                </label>
                <span
                  onClick={() => this.handleRadioClick('auto', 'skuTerms')}
                  className="radio-circle"
                  style={{ border: 'none', width: '16%' }}
                />
              </div>
              <div className="float-left w-5">
                <input
                  type="radio"
                  checked={this.state.skuTerms === 'manual' ? true : false}
                  onChange={(event) => this.handleRadioChange(event)}
                  name="skuTerms"
                  id="manual"
                  // value={this.state.skuTerms}
                  value="manual"
                />
              </div>
              <div className="float-left w-95">
                <label htmlFor="manual" style={{ marginBottom: '5px' }}>
                  Continue From Last SKU Number
                </label>
                <span
                  onClick={() => this.handleRadioClick('manual', 'skuTerms')}
                  className="radio-circle"
                  style={{ border: 'none', width: '14%' }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="float-left w-100">
          <div className="float-left w-70">
            {/* <hr /> */}
            {this.renderActionButtons()}
          </div>
        </div>
      </Form>
    );
  }

  render() {
    return this.renderStatusChangeInput();
  }
}

export default withRouter(Prefix);
