import React, { Component } from 'react';
import { restRequest, publicRequest } from '../../Helpers/RequestHelper';
import { FormGroup, Col, ControlLabel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './InvitedUserSignup.css';

class InvitedUserSignup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            password: '',
            passwordConfirm: '',
            verifiedToken: false,
            missionAccomplished: false,
            loading: true,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentWillMount() {
        this.setState({
            loading: true,
        });
        // Check if the provided token is valid
        publicRequest('post', 'auth/verifyToken', {
            token: this.props.match.params.token,
        })
            .then((response) => {
                if (response.isTokenValid) {
                    this.setState({
                        name: response.name,
                        email: response.email,
                        verifiedToken: true,
                        loading: false,
                    });
                }
            })
            .catch(() => {
                this.props.handleToast(
                    'Unable to handle the request, please try again.',
                    'error'
                );
            });
    }
    componentDidMount() {
        document.title = 'Register to Accept Invitation';
    }
    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    handleSubmit(event) {
        event.preventDefault();
        // Post user data to server.
        restRequest('post', 'auth/createInvitedUserAccount', {
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            password_confirmation: this.state.passwordConfirm,
        })
            .then((userDataResonse) => {
                // User has been created, now map him with the invitee organization
                restRequest('post', 'auth/assignUser', {
                    email: userDataResonse.email,
                    userid: userDataResonse.id,
                    token: this.props.match.params.token,
                }).then((mapUserResponse) => {
                    this.setState({
                        missionAccomplished: mapUserResponse,
                    });
                });
            })
            .catch((error) => {
                this.props.handleToast(error, 'error');
            });
    }

    renderInvitedUserSignupForm() {
        return (
            <div className="container">
                <div className="row mlr0">
                    <br />
                    <br />
                    <div className="col-sm-2"></div>

                    <div className="col-sm-8">
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h4>
                                    Create an account to join the invited
                                    business
                                </h4>
                            </div>
                            <div className="panel-body">
                                <div className="signup">
                                    <form
                                        className="form-horizontal"
                                        method="post"
                                        action="#"
                                        onSubmit={this.handleSubmit}
                                    >
                                        <FormGroup controlId="name">
                                            <Col
                                                componentClass={ControlLabel}
                                                sm={3}
                                            >
                                                Your Name
                                            </Col>
                                            <Col sm={8}>
                                                <input
                                                    type="text"
                                                    validations={['required']}
                                                    className="form-control"
                                                    name="name"
                                                    id="name"
                                                    placeholder="Enter your Name"
                                                    value={this.state.name}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup controlId="email">
                                            <Col
                                                componentClass={ControlLabel}
                                                sm={3}
                                            >
                                                Your Email
                                            </Col>
                                            <Col sm={8}>
                                                <input
                                                    type="text"
                                                    validations={[
                                                        'required',
                                                        'email',
                                                    ]}
                                                    disabled
                                                    className="form-control"
                                                    name="email"
                                                    id="email"
                                                    value={this.state.email}
                                                    // onChange={this.handleChange}
                                                />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup controlId="password">
                                            <Col
                                                componentClass={ControlLabel}
                                                sm={3}
                                            >
                                                Password
                                            </Col>
                                            <Col sm={8}>
                                                <input
                                                    type="password"
                                                    validations={[
                                                        'required',
                                                        'password',
                                                    ]}
                                                    className="form-control"
                                                    name="password"
                                                    id="password"
                                                    placeholder="Enter password"
                                                    value={this.state.password}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup controlId="passwordConfirm">
                                            <Col
                                                componentClass={ControlLabel}
                                                sm={3}
                                            >
                                                Confirm Password
                                            </Col>
                                            <Col sm={8}>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    validations={[
                                                        'required',
                                                        'password',
                                                    ]}
                                                    name="passwordConfirm"
                                                    id="passwordConfirm"
                                                    placeholder="Retype password"
                                                    value={
                                                        this.state
                                                            .passwordConfirm
                                                    }
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup controlId="formHorizontalEmail">
                                            <Col
                                                componentClass={ControlLabel}
                                                sm={3}
                                            ></Col>
                                            <Col sm={8}>
                                                <button
                                                    value="Register"
                                                    className="btn btn-success pull-right btn-block"
                                                >
                                                    Create My Account
                                                </button>
                                            </Col>
                                        </FormGroup>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2"></div>
                </div>
            </div>
        );
    }
    renderInvalidValidationToken() {
        return (
            <div>
                <div className="container" style={{ marginTop: 60 }}>
                    <div className="col-sm-3"></div>
                    <div className="col-sm-6">
                        <div className="panel panel-danger">
                            <div className="panel-heading">Invalid Token!</div>
                            <div className="panel-body">
                                The token was expired or invalid, so we were
                                unable to create your account. <hr />
                                Go to your email and read the instructions
                                carefully and try again.
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3"></div>
                </div>
            </div>
        );
    }
    missionAccomplished() {
        return (
            <div>
                <div className="container" style={{ marginTop: 60 }}>
                    <div className="col-sm-3"></div>
                    <div className="col-sm-6">
                        <div className="panel panel-success">
                            <div className="panel-heading">
                                <h4>
                                    You have successfully joined the business.
                                </h4>
                            </div>
                            <div className="panel-body">
                                You have been successfully assigned to the
                                invited business, you can start working after
                                you login to this application.
                                <hr />
                                Please <Link to="/login">login</Link> to
                                continue.
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3"></div>
                </div>
            </div>
        );
    }
    render() {
        if (this.state.loading === false) {
            if (this.state.missionAccomplished === true) {
                return this.missionAccomplished();
            } else {
                if (this.state.verifiedToken === true) {
                    return this.renderInvitedUserSignupForm();
                } else {
                    return this.renderInvalidValidationToken();
                }
            }
        } else {
            return <div>Please wait...</div>;
        }
    }
}

export default InvitedUserSignup;
