import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import invoice from './../../../../Assets/Img/invoice-and-bills.png'

import './PageFour.css'

class PageFour extends Component {

  render() {
    return (
      <div className="page-four">
        <div className="page-four-title column">
          <div className="home-pages-heading">
            Don't miss out on getting paid!
          </div>
          <div className="home-desc">
            Manage all of your invoices and bills you owe until they are resolved.
          </div>
          <Link to="/signup" className="home--btn">
            Get Started
          </Link>
        </div>
        <div className="page-four-img">
          <img src={invoice} alt=""/>
        </div>
      </div>
    )
  }
}

export default PageFour
