import React, { Component } from 'react';

import PageViewCount from './PageViewCount';
import PageNumber from './PageNumber';

import './Pagination.css';

class Pagination extends Component {
  previousPage = () => {
    const { currentPage, generateRoute } = this.props;

    const page = currentPage !== 1 ? currentPage - 1 : currentPage;

    return generateRoute({ page });
  };

  nextPage = () => {
    const { currentPage, totalPage, generateRoute } = this.props;

    const page = currentPage !== totalPage ? currentPage + 1 : currentPage;

    return generateRoute({ page });
  };

  render() {
    const { viewPerPage, totalPage, currentPage, totalRecords, listing_name,isIntegration=false } =
      this.props;
    return (
      <div
        className={`pagination-container pagination-route ${this.props.className} ${isIntegration? 'unsync-pagination-main':''}`}
      >
        <PageViewCount
          viewPerPage={viewPerPage}
          handleViewCount={(view) =>
            this.props.history.push(this.props.generateRoute({ view, page: 1 }))
          }
        />
        <PageNumber
          totalPage={totalPage}
          currentPage={currentPage}
          handleSelectedPage={(page) => this.props.generateRoute({ page })}
          nextPage={this.nextPage}
          previousPage={this.previousPage}
          history={this.props.history}
          totalRecords={totalRecords}
          listing_name={listing_name}
        />
      </div>
    );
  }
}

export default Pagination;
