import React from 'react';
import ContactForm from '../../Components/Form/ContactForm/ContactForm';
import { NewContactIcon } from '../../Assets/Navigation/NavigationIcons';
import Address from '../../Assets/General/Address';
import OtherDetails from '../../Assets/General/OtherDetails';
import BasicInfo from '../../Assets/General/BasicInfo';
import { MdClose as CloseButton } from '../../Common/Icons';
import classNames from 'classnames';
import Layout, { Header, Body } from '../../Components/Layout/Layout';
import Box from '../../Components/Layout/Box';
import { validateContactForm } from '../../Helpers/FormValidator';
import useToggleClick from '../../Hooks';

const CloneContact = ({ handleToast, idFromHeaderWrapper, ...props }) => {
  const [, handleCrossAlert, handleCrossClick] = useToggleClick();
  const tabs = [
    { name: 'Basic Info', value: 1, icon: BasicInfo },
    { name: 'Address', value: 2, icon: Address },
    { name: 'Other Details', value: 3, icon: OtherDetails },
  ];
  const [tab, setTab] = React.useState(tabs[0]);
  function updateTab(val = 1) {
    let obj = tabs.find((t) => t.value === val);
    setTab(obj);
  }
  return (
    <Layout>
      <Header
        title="Contact"
        iconStyle="contact_general_module_icon"
        Icon={NewContactIcon}
        subtitle="Clone"
      >
        <div onClick={handleCrossClick('/contacts', validateContactForm)}>
          <CloseButton className="material-icon  mt-11" />
        </div>
      </Header>
      <div className="float-left w-100 add_contact_tabs">
        <div className="add_contact_tabs_info">
          {tabs.map((t) => {
            let Icon = t.icon;
            return (
              <h1
                key={t.value}
                style={{
                  color: t.value === tab.value ? '#FFF' : '',
                  backgroundColor: t.value === tab.value ? '#306AB4' : '',
                }}
                className={`${t.value === tab.value ? 'active_tab' : ''}`}
                onClick={() => updateTab(t.value)}
              >
                <Icon
                  className={
                    t.value === tab.value
                      ? 'contact-icon-active'
                      : 'contact-icon '
                  }
                />
                {t.name}
              </h1>
            );
          })}
        </div>
        <Body>
          <Box className={classNames('contact-width-full')}>
            <div className={classNames('pl-contacts')}>
              <ContactForm
                type="clone"
                id={
                  idFromHeaderWrapper
                    ? idFromHeaderWrapper
                    : props.match.params.id
                }
                handleToast={handleToast}
                updateTab={updateTab}
                tab={tab.value}
                handleCrossAlert={handleCrossAlert}
              />
            </div>
          </Box>
        </Body>
      </div>
    </Layout>
  );
};

export default CloneContact;
