import React from 'react'

export default ({
                  className = ''
                }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 12.5 16.25">
    <path
      d="M3.75-15.625V.625h12.5v-16.25ZM5-14.375H15V-.625H5Zm4.629,3.34a.856.856,0,0,0-.566.2.837.837,0,0,0-.273.469A2.318,2.318,0,0,0,8.809-9.3a7.3,7.3,0,0,0,.43,1.152l.156.371q-.039.176-.1.508a5.082,5.082,0,0,1-.215.879,5.524,5.524,0,0,1-.312.645q-.137.254-.2.391a3.434,3.434,0,0,1-.43.137,4.075,4.075,0,0,0-.742.293,2.267,2.267,0,0,0-.8.762,1.035,1.035,0,0,0-.117.566,1.1,1.1,0,0,0,.2.566,1.01,1.01,0,0,0,.479.371.855.855,0,0,0,.6.02,2.1,2.1,0,0,0,.879-.742,3.517,3.517,0,0,0,.391-.762q.137-.332.215-.488l.332-.137a2.852,2.852,0,0,1,.547-.176A4.778,4.778,0,0,1,10.684-5a2.573,2.573,0,0,0,.352-.02l.176.234a2.328,2.328,0,0,0,.234.313,1.829,1.829,0,0,0,1.016.488.95.95,0,0,0,.566-.146,1.209,1.209,0,0,0,.43-.439h.02v-.02a1.171,1.171,0,0,0,.156-.547.75.75,0,0,0-.234-.547.939.939,0,0,0-.43-.273,2.1,2.1,0,0,0-.508-.117,2.659,2.659,0,0,0-.586.039A3.171,3.171,0,0,1,11.484-6l-.234-.293a4.548,4.548,0,0,1-.586-.84,4.038,4.038,0,0,1-.176-.43,2.009,2.009,0,0,0-.117-.312q.039-.156.117-.488a4.741,4.741,0,0,0,.176-.781,3.2,3.2,0,0,0-.117-1.191,1.189,1.189,0,0,0-.352-.508.828.828,0,0,0-.547-.2Zm.41,4.629a1.879,1.879,0,0,0,.2.293q.117.137.176.215a1.041,1.041,0,0,1-.234.02,1.339,1.339,0,0,0-.254.02l-.078.039.117-.254A1.245,1.245,0,0,0,10-6.25Zm2.3,1.27a.91.91,0,0,1,.313.078v.02a1.182,1.182,0,0,1-.078.1.08.08,0,0,1-.059.02h0a.961.961,0,0,1-.352-.2ZM7.969-4.16a.333.333,0,0,0-.049.107.333.333,0,0,1-.049.107,1.217,1.217,0,0,1-.41.391h0v-.02h-.02L7.4-3.633l.039-.078a1.407,1.407,0,0,1,.449-.41Z"
      transform="translate(-3.75 15.625)"/>
  </svg
  >
)
