import React, { Fragment, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withFormik, FieldArray } from 'formik';
import DeleteIcon from '../../../Assets/General/DeleteIcon';
import { MdHelpOutline } from '../../../Common/Icons';
import AddContact from '../../../Pages/Contact/AddContact';
import {
  FormSection,
  Field,
  Form,
  TaxForm,
  AddItem,
  FormAction,
  ErrorFocus,
} from '../';
import { formSubmit, getError, validate } from '../FormHelper';
import ItemAttributeOptionForm from './ItemAttributeOptionForm/ItemAttributeOption';
import AccountForm from '../../../Pages/Account/AccountForm';
//import { Checked } from '../../../Assets/List/ListIcons';
import ItemAccountsIcon from '../../../Assets/General/ItemAccountsIcon';
import {
  ImageCropper,
  CustomModal,
  //Layout,
  ImagePreview,
  SmallLoader,
} from '../../';
import {
  checkError,
  formatAccountList,
  restRequest,
  getObjFromLS,
  setObjInLS,
  findByValue,
  confirmationAlert,
} from '../../../Helpers';
import {
  required,
  ValidateMax10Billion,
  ValidateRequired,
  ValidateMax50,
  ValidateMax15,
  ValidateMax200,
  ValidateMax240,
  wrapValidateMax,
  // ValidateNumber,
  ValidateMax255,
} from '../../../Helpers/Validator';
import { validateCreateItemForm } from '../../../Helpers/FormValidator';
//const { Grid, GridLayout } = Layout;

const getYupValidationSchema = (_, props) => {
  return Yup.object().shape({
    name: required(ValidateMax240),
    description: ValidateMax255,
    salesDescription: wrapValidateMax(Yup.string().nullable(), 255),
    purchaseDescription: wrapValidateMax(Yup.string().nullable(), 255),
    sku: required(ValidateMax50),
    unit: required(ValidateMax15),
    upc: wrapValidateMax(Yup.string().nullable(), 13),
    mpn: wrapValidateMax(Yup.string().nullable(), 17),
    ean: wrapValidateMax(Yup.string().nullable(), 14),
    isbn: wrapValidateMax(Yup.string().nullable(), 17),
    dimensions: wrapValidateMax(Yup.string().nullable(), 17),
    weight: wrapValidateMax(Yup.string().nullable(), 17),
    manufacturer: ValidateMax200,
    brand: wrapValidateMax(Yup.string().nullable().trim(), 200),
    //salesUnitPrice: required(ValidateMax10Billion),
    salesUnitPrice: ValidateMax10Billion,
    salesAccountID: ValidateRequired,
    //purchaseUnitPrice: required(ValidateMax10Billion),
    purchaseUnitPrice: ValidateMax10Billion,
    purchaseAccountID: ValidateRequired,
    // openingStockValue: props.trackInventory //TODO: commented becuase of validations bypass by these checks.
    //   ? ValidateMax10Billion.when('warehouses', {
    //       is: (warehouse) =>
    //         warehouse.some((w) => w.openingStock > 0),
    //       then: Yup.mixed().required('Required'),
    //       otherwise: Yup.mixed().notRequired(),
    //     })
    //   : Yup.mixed().notRequired(),
    reorderLevel: ValidateMax10Billion,
    warehouses: props.trackInventory
      ? Yup.array()
          .of(
            Yup.object().shape({
              name: ValidateRequired,
              openingStock: ValidateMax10Billion,
            })
          )
          .min(1, 'At least one warehouse is required')
      : Yup.mixed().notRequired(),

    attributes:
      props.formType === 'itemGroup'
        ? Yup.array().of(
            Yup.object().shape({
              attributeOptionValue: ValidateRequired,
            })
          )
        : Yup.mixed().notRequired(),
  });
};
let globalIndex = null;
const formEnhancer = withFormik({
  validate: validate(getYupValidationSchema),
  mapPropsToValues: ({ item }) => ({
    ...item,
    loadingAccounts: {
      salesAccounts: false,
      purchaseAccounts: false,
    },
    showAccountModal: false,
    showTaxModal: false,
    showOptionsModal: false,
    showImageCropModal: false,
    showContactCreateModal: false,
  }),
  mapPropsToStatus: (props) => ({
    salesAccounts: props.salesAccounts,
    purchaseAccounts: props.purchaseAccounts,
    vendorsList: props.vendorsList,
    taxList: props.taxList,
    allWarehouses: props.allWarehouses,
    warehousesList: props.warehousesList,
    currency: props.currency,
    emptyWarehouse: props.emptyWarehouse,
    accountType: '', // sales, purchase
  }),
  handleSubmit: (values, bag) => {
    handleFormSubmit(values, bag);
  },
});
const variantName = (id, options) => {
  let name = null;
  options.forEach((opt) => {
    if (id === opt.id) {
      name = opt.name;
    }
  });
  return name;
};
const handleFormSubmit = (
  values,
  {
    setSubmitting,
    props: {
      requestUrl,
      history,
      handleToast,
      trackInventory,
      successMessage,
      type,
      formType,
      onSubmitModal,
      closeModal: closeItem,
      inModal,
      location,
      setPrompt,
    },
  }
) => {
  const { pathname = '', search = 'new-item' } = location;
  let variant = null;
  if (formType === 'itemGroup') {
    let newOpt = '';
    values.attributes.forEach(function (attr, index) {
      if (index === 0) {
        newOpt = attr.attributeOptionName.toLowerCase();
      } else {
        newOpt = newOpt + '/' + attr.attributeOptionName.toLowerCase();
      }
    });
    if (values.possibleCombinations.includes(newOpt)) {
      handleToast(
        'Two or more variants in the item group have identical attributes. Please provide unique attributes for each variant.',
        'error'
      );
      setSubmitting(false);
      return false;
    }
    variant = values.attributes.map((val, i) => ({
      [val.name]: variantName(
        val.attributeOptionValue,
        val.item_attribute_option
      ),
    }));
  }
  if (
    trackInventory &&
    values.openingStockValue > 0 &&
    !values.transactionInfo &&
    !values.warehouses.filter((val) => val.openingStock > 0).length
  ) {
    handleToast('Warehouse Stock required', 'error');
    setSubmitting(false);
    return false;
  } else if (
    trackInventory &&
    (values.openingStockValue <= 0 || !values.openingStockValue) &&
    values.warehouses.filter(
      (val) => val.openingStock > 0 && !val.transactionInfo
    ).length
  ) {
    handleToast('Opening Stock value required', 'error');
    setSubmitting(false);
    return false;
  }
  let data = new FormData();
  if (formType === 'itemGroup') {
    for (let [index, attr] of values.attributes.entries()) {
      data.append(`attributes[${index}]`, JSON.stringify(attr));
    }
    data.append('item_group_name', values.itemGroupName);
    data.append('item_group_id', values.itemGroupId);
    data.append('variant', JSON.stringify(variant));
  }
  data.append('type', values.type);
  data.append('name', values.name);
  data.append('sku', values.sku);
  data.append('unit', values.unit);
  data.append('upc', values.upc);
  data.append('ean', values.ean);
  data.append('mpn', values.mpn);
  data.append('isbn', values.isbn);
  data.append('manufacturer', values.manufacturer);
  data.append('brand', values.brand);
  data.append('dimensions', values.dimensions || '');
  data.append('weight', values.weight || '');
  data.append('clone_status', clone_status);
  data.append('description', values.description);
  // data.append('base84Image', values.base84Image)
  //values.salesUnitPrice && data.append('sales_unit_price', values.salesUnitPrice || 0)
  data.append('sales_unit_price', values.salesUnitPrice || 0);
  values.salesAccountID &&
    data.append('sales_account_id', values.salesAccountID);
  data.append('sales_description', values.salesDescription);
  values.taxID && data.append('tax_id', values.taxID);
  //values.purchaseUnitPrice && data.append('purchase_unit_price', values.purchaseUnitPrice || 0)
  data.append('purchase_unit_price', values.purchaseUnitPrice || 0);
  values.purchaseAccountID &&
    data.append('purchase_account_id', values.purchaseAccountID);
  data.append('purchase_description', values.purchaseDescription);
  values.vendorID && data.append('vendor_id', values.vendorID);
  data.append('opening_stock_value', values.openingStockValue || 0);
  data.append('reorder_level', values.reorderLevel || 0);
  data.append(
    'save-as-new',
    pathname.includes('/items/edit') && search === '?new-item'
  );
  for (let [index, warehouse] of values.warehouses.entries()) {
    if (warehouse.is_active === 1) {
      data.append(`warehouses[${index}][id]`, warehouse.id);
      data.append(
        `warehouses[${index}][opening_stock]`,
        warehouse.openingStock || 0
      );
    }
  }
  for (let [index, imageUrl] of values.images.entries()) {
    data.append(`images[${index}]`, imageUrl);
  }

  data.append('upload_images', []);
  data.append('inventory_type', trackInventory ? 'inventory' : 'noninventory');
  for (let [index, file] of values.base84Image.entries()) {
    data.append(`base84Image[${index}]`, file);
  }
  restRequest('post', requestUrl, data)
    .then((res) => {
      setPrompt(false);
      if (inModal) {
        onSubmitModal(res);
        closeItem();
      } else {
        successMessage();
        let dataFromLS = getObjFromLS('module');
        if (!dataFromLS) {
          let obj = {
            prevId: null,
            id: requestUrl.split('/')[1] || '',
            nextId: null,
            moduleName: 'items',
            urlPath: '/items',
            queryParam: window.location.search,
          };
          setObjInLS('module', obj);
          dataFromLS = getObjFromLS('module');
        }
        if (type === 'add') {
          if (formType === 'itemGroup') {
            setObjInLS('module', {
              ...dataFromLS,
              moduleName: 'item_groups',
              queryParam: dataFromLS.dataFromLS ? dataFromLS.dataFromLS : '',
              urlPath: '/item-groups',
            });
          } else {
            setObjInLS('module', {
              ...dataFromLS,
              moduleName: 'items',
              queryParam: dataFromLS.dataFromLS ? dataFromLS.dataFromLS : '',
              id: type === 'add' ? res.data.id : dataFromLS.id,
              urlPath: '/items',
            });
          }
        } else if (type === 'clone') {
          setObjInLS('module', {
            ...dataFromLS,
            moduleName: 'items',
            queryParam: dataFromLS.dataFromLS ? dataFromLS.dataFromLS : '',
            id: type === 'clone' ? res.data.id : dataFromLS.id,
            urlPath: '/items',
          });
        }
        type === 'edit' &&
          setObjInLS('module', {
            ...dataFromLS,
            action: type === 'add' ? 'add' : 'justedit',
            queryParam: dataFromLS.queryParam ? dataFromLS.queryParam : '',
            urlPath: '/items',
          });
        return history.push('/r');
      }
    })
    .catch((error) => {
      checkError(error);
      handleToast(error, 'error');
      setSubmitting(false);
    });
};

var clone_status = 0;

const setCloneStatus = (type) => {
  if (type === 'clone') {
    clone_status = 1;
  } else {
    clone_status = 0;
  }
};

const ItemForm = ({
  // props from formik
  values,
  status,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  setValues,
  setStatus,
  isSubmitting,
  submitCount,
  errors,
  touched,
  suggestions,
  onSuggestionsFetchRequested,
  onSuggestionsClearRequested,
  // removeSuggestion,
  // custom props
  type,
  formType,
  handleToast,
  history,
  trackInventory,
  allWarehouses,
  warehousesList,
  handleTrackInventory,
  closeModal: closeItem,
  inModal,
  item,
  modalSource = true,
  ...props
}) => {
  var base64Images = [];
  const [showPrompt, setShowPrompt] = useState(false);
  const [staticVal, setStaticValues] = useState({});

  useEffect(() => {
    handleAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, trackInventory, staticVal]);

  useEffect(() => {
    setStaticValues({
      ...values,
      trackInventory,
      type,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAlert = () => {
    let { checkout } = validateCreateItemForm(
      {
        ...values,
        trackInventory,
        type,
      },
      staticVal
    );
    if (!checkout && !inModal) props.setPrompt();
    else if (!checkout && inModal) props.handlePrompt();
    else {
      if (inModal) props.handlePrompt(false);
      props.setPrompt(false);
    }
  };

  const generateSku = () => {
    setFieldValue('skuLoading', true);
    restRequest('get', 'items/generatesku')
      .then((res) => {
        setFieldValue('skuLoading', false);
        setFieldValue('sku', res.sku);
      })
      .catch((error) => {
        setFieldValue('skuLoading', false);
        handleToast(error, 'error');
      });
  };
  setCloneStatus(type);
  const handleFiles = (accepted, rejected) => {
    if (rejected.length) {
      if (rejected.length === 1) {
        handleToast('1 invalid file/file size.', 'error');
      } else {
        handleToast(`${rejected.length} invalid files/file sizes.`, 'error');
      }
    }

    if (accepted.length) {
      let previousFiles = [...values.uploadImages];
      if ([...previousFiles, ...accepted].length > 5) {
        handleToast('Cannot add more than 5 images', 'error');
        return null;
      }
      let updatedFiles = accepted.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      if (
        values.images.length +
          values.uploadImages.length +
          updatedFiles.length >
        5
      ) {
        handleToast('Cannot add more than 5 images', 'error');
        return null;
      }
      setFieldValue('imageUpload', [...previousFiles, ...updatedFiles]);
      setFieldValue('showImageCropModal', true);
    }
  };

  const handleImageDelete = (image) => {
    if (typeof image === 'string') {
      // previous uploaded image (url)
      setFieldValue(
        'images',
        values.images.filter((el) => el !== image)
      );
    } else {
      setFieldValue(
        'uploadImages',
        values.uploadImages.filter((el) => el !== image)
      );
      setFieldValue(
        'base84Image',
        values.base84Image.filter((el) => el !== image.preview)
      );
    }
  };

  const handleTaxChange = (option) => {
    if (option !== null) {
      // Add New
      if (option.value === 'createNew') {
        setFieldValue('showTaxModal', true);
      } else {
        // tax selected
        const taxID = option.value;
        setFieldValue('taxID', taxID);
      }
    } else {
      // deselect tax
      setFieldValue('taxID', null);
    }
  };

  const handleAttrChange = (option, index) => {
    if (option !== null) {
      // Add New
      if (option.value === 'createNew') {
        setFieldValue('showOptionsModal', true);
        globalIndex = index;
      } else {
        const optID = option.id;
        values.attributes[index].attributeOptionValue = optID;
        values.attributes[index].attributeOptionName = option.name;
        setFieldValue(
          `values.attributes[${index}].attributeOptionValue`,
          optID
        );
        setFieldValue(
          `values.attributes[${index}].attributeOptionName`,
          option.name
        );
      }
    } else {
      values.attributes[index].attributeOptionValue = null;
      values.attributes[index].attributeOptionName = null;
      setFieldValue(`values.attributes[${index}].attributeOptionValue`, null);
      setFieldValue(`values.attributes[${index}].attributeOptionName`, null);
    }
  };

  const handleVendorChange = (option) => {
    if (option !== null) {
      if (option.value === 'createNew') {
        setFieldValue('showContactCreateModal', true);
      } else {
        const vendorID = option.value;
        setFieldValue('vendorID', vendorID);
      }
    } else {
      setFieldValue('vendorID', null);
    }
  };

  const handleSelectedWarehouses = (option, index) => {
    let newWarehouse = {
      id: option.id,
      name: option.name,
      address: option.address,
      city: option.city,
      state: option.state,
      country: option.country,
      zipCode: option.zip_code,
      openingStock: values.warehouses[index].openingStock,
      is_active: 1,
    };

    let updatedWarehousesList = [
      ...Object.assign([...values.warehouses], { [index]: newWarehouse }),
    ];
    setFieldValue('warehouses', updatedWarehousesList);

    setStatus({
      ...status,
      warehousesList: status.warehousesList.filter(
        (warehouse) => warehouse.id !== option.id
      ),
    });
  };

  const handleRemoveItem = (removeIndex) => {
    if (values.warehouses.length === 1) {
      values.warehouses.push(status.emptyWarehouse);
    }
    let updatedWarehousesList = values.warehouses.filter(
      (_, index) => index !== removeIndex
    );
    setFieldValue('warehouses', updatedWarehousesList);
    // setFieldValue('warehouse_removed', true);
  };

  const handleAccountModal = (accountType) => {
    // sales, purchase
    setStatus({
      ...status,
      accountType: accountType === 'sales' ? 'sales' : 'purchase',
    });
    setFieldValue('showAccountModal', true);
  };

  function closeModal() {
    setValues({
      ...values,
      showAccountModal: false,
      showTaxModal: false,
      showOptionsModal: false,
      showImageCropModal: false,
      showContactCreateModal: false,
    });
  }

  const updateAccount = (accountType, newAccount) => {
    let accountListState =
      accountType === 'sales' ? 'salesAccounts' : 'purchaseAccounts';
    let accountState =
      accountType === 'sales' ? 'salesAccountID' : 'purchaseAccountID';
    let itemFormType = type === 'add' ? 'create' : 'edit';

    setValues({
      ...values,
      loadingAccounts: {
        ...values.loadingAccounts,
        [accountListState]: true,
      },
    });

    restRequest('get', `items/${itemFormType}/accounts?type=${accountType}`)
      .then((accounts) => {
        setStatus({
          ...status,
          [accountListState]: accounts,
        });
        setValues({
          ...values,
          [accountState]: newAccount.id,
          showAccountModal: false,
          loadingAccounts: {
            ...values.loadingAccounts,
            [accountListState]: false,
          },
        });
        setFieldValue(
          accountType === 'sales' ? 'sales_update' : 'purchase_update',
          true
        );
      })
      .catch((error) => {
        checkError(error, handleToast);
        setValues({
          ...values,
          loadingAccounts: {
            ...values.loadingAccounts,
            [accountListState]: false,
          },
        });
      });
  };

  const accountDropDownField = (accountType) => {
    // sales, purchase
    const accountList =
      accountType === 'sales' ? status.salesAccounts : status.purchaseAccounts;
    const formattedAccountList = formatAccountList(accountList);
    return (
      <div className="flex-100 position-relative">
        <Field
          className="overForm"
          type="groupdropdown"
          size="large"
          name={`${accountType} Account`}
          value={
            accountType === 'sales'
              ? findByValue(values.salesAccountID, formattedAccountList)
              : findByValue(values.purchaseAccountID, formattedAccountList)
          }
          id={accountType === 'sales' ? 'salesAccountID' : 'purchaseAccountID'}
          options={formattedAccountList}
          handleChange={(option) => {
            setFieldValue(
              accountType === 'sales' ? 'salesAccountID' : 'purchaseAccountID',
              option.value
            );
            // if (!inModal)
            //   setFieldValue(
            //     accountType === 'sales' ? 'sales_update' : 'purchase_update',
            //     true
            //   );
          }}
          clearable={false}
        />
        {!inModal && (
          <button
            className="button form-field--side-btn account_addnew"
            type="button"
            onClick={() => handleAccountModal(accountType)}
          >
            Add new
          </button>
        )}
      </div>
    );
  };
  const handlePrompt = (status = true) => setShowPrompt(status);
  /*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/
  String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  };
  const newAccountModal = () => {
    let { accountType } = status;
    return (
      <CustomModal
        className="widthClass"
        showModal={values.showAccountModal}
        fill="#000000"
        Icon={ItemAccountsIcon}
        title={`New ${accountType.capitalize()} Account`}
        renderActions={false}
        onClose={closeModal}
        showPrompt={showPrompt}
      >
        <AccountForm
          accountType={accountType}
          inModal
          onSubmit={(account) => updateAccount(accountType, account)}
          onClose={closeModal}
          handleToast={handleToast}
          history={history}
          handlePrompt={handlePrompt}
        />
      </CustomModal>
    );
  };

  const contactCreateModal = () => {
    return (
      <CustomModal
        size="large"
        showModal={values.showContactCreateModal}
        title="Add Contact"
        customWidth="contact-modal-width"
        renderActions={false}
        onClose={closeModal}
        showPrompt={showPrompt}
      >
        <AddContact
          type="purchaseOrder"
          inModal
          onSubmit={(res) => {
            setStatus({
              ...status,
              vendorsList: [...status.vendorsList, res.data],
            });
            setValues({
              ...values,
              vendorID: res.data.id,
              showContactCreateModal: false,
            });
          }}
          handleToast={handleToast}
          close={closeModal}
          handlePrompt={handlePrompt}
        />
      </CustomModal>
    );
  };

  const newTaxModal = () => {
    return (
      <CustomModal
        className="smallWidthClass"
        showModal={values.showTaxModal}
        title="Add New Tax"
        renderActions={false}
        onClose={closeModal}
        showPrompt={showPrompt}
        classNames="UIUX-addtax-popup invoice-popup-set"
      >
        <TaxForm
          inModal
          type="add"
          onSubmitModal={(newitem) => {
            setStatus({
              ...status,
              taxList: [newitem, ...status.taxList],
            });
            setValues({
              ...values,
              taxID: newitem.id,
              showTaxModal: false,
            });
          }}
          closeModal={closeModal}
          handleToast={handleToast}
          handlePrompt={handlePrompt}
        />
      </CustomModal>
    );
  };
  const newOptionModal = () => {
    return (
      <CustomModal
        showModal={values.showOptionsModal}
        title="Add New Option"
        renderActions={false}
        onClose={closeModal}
        className="add_new_deleivery"
        modaltitle="delivery_modal_title"
        size="large"
      >
        <ItemAttributeOptionForm
          inModal
          type="add"
          onSubmitModal={(opt) => {
            let totalOptions =
              values.attributes[globalIndex].item_attribute_option.length;
            let newItem = {
              id: totalOptions + 1,
              name: opt,
              type: 'new_option',
            };
            values.attributes[globalIndex].item_attribute_option.push(newItem);
            values.attributes[globalIndex].attributeOptionValue = newItem.id;
            values.attributes[globalIndex].attributeOptionName = newItem.name;
            setFieldValue(
              `values.attributes[${globalIndex}].attributeOptionValue`,
              newItem.id
            );
            setFieldValue(
              `values.attributes[${globalIndex}].attributeOptionName`,
              newItem.name
            );
            setValues({
              ...values,
              showOptionsModal: false,
            });
          }}
          closeModal={closeModal}
          handleToast={handleToast}
        />
      </CustomModal>
    );
  };
  const hanldeImageUpload = (updatedFiles, croppedImage) => {
    updatedFiles[updatedFiles.length - 1].preview = croppedImage;
    updatedFiles.forEach((el) => {
      base64Images.push(el.preview);
      setFieldValue('base84Image', base64Images);
    });
    setFieldValue('uploadImages', updatedFiles);
    setFieldValue('showImageCropModal', false);
  };
  const ImageCropModal = () => {
    return (
      <CustomModal
        className="smallWidthClass"
        showModal={values.showImageCropModal}
        title="Adjust Image"
        renderActions={false}
        onClose={closeModal}
      >
        <ImageCropper
          inModal
          updatedFiles={values.imageUpload}
          type="add"
          onSubmitModal={(updatedFiles, croppedImage) => {
            hanldeImageUpload(updatedFiles, croppedImage);
          }}
          closeModal={closeModal}
          handleToast={handleToast}
        />
      </CustomModal>
    );
  };
  const renderModals = () => {
    return (
      <Fragment>
        {contactCreateModal()}
        {ImageCropModal()}
        {newAccountModal()}
        {newTaxModal()}
        {newOptionModal()}
      </Fragment>
    );
  };
  const renderWarehouseTable = () => {
    return (
      <FieldArray
        name="warehouses"
        render={() => {
          return (
            <table
              className="form-focus list-table form-table border add_new_item add-item-variant"
              style={{ width: '100%' }}
            >
              <thead>
                <tr>
                  <th>Warehouse Name</th>
                  <th>Address</th>
                  <th className="right-align">Opening Stock</th>
                </tr>
              </thead>
              <tbody>
                {values?.warehouses?.map((warehouse, index) => {
                  return (
                    <tr key={index}>
                      {warehouse.id ? (
                        <td className="warehouse-select-items-form">
                          {warehouse.name}
                        </td>
                      ) : (
                        <td className="warehouse-select-items-form">
                          <Field
                            type="dropdown"
                            autoFocus={true}
                            placeholder="Select Warehouse"
                            value={warehouse.id}
                            options={status.warehousesList}
                            dropdownValue="id"
                            dropdownLabel="name"
                            clearable={false}
                            required={trackInventory}
                            handleChange={(option) =>
                              handleSelectedWarehouses(option, index)
                            }
                            error={getError(
                              errors,
                              touched,
                              `warehouses[${index}].name`
                            )}
                            onBlur={() =>
                              setFieldTouched(`warehouses[${index}].name`, true)
                            }
                          />
                        </td>
                      )}
                      <td className="wh-location">
                        <div>
                          {warehouse.address !== '' && (
                            <div>{warehouse.address}</div>
                          )}
                          {warehouse.city !== '' && (
                            <span>{warehouse.city}, </span>
                          )}
                          {warehouse.state !== '' && (
                            <span>{warehouse.state}, </span>
                          )}
                          {warehouse.country !== null && (
                            <span>{warehouse.country.country_name}</span>
                          )}
                          {warehouse.zipCode !== '' && (
                            <span> - {warehouse.zipCode}</span>
                          )}
                        </div>
                      </td>

                      <td className="right-align no-padding with-close--btn">
                        <Field
                          className="right-align"
                          type="number"
                          placeholder="0"
                          disabled={values.transactionInfo === true}
                          value={
                            warehouse?.openingStock >= 1
                              ? parseInt(warehouse?.openingStock)
                              : null
                          }
                          // decimalScale={6}
                          id={`warehouses[${index}].openingStock`}
                          thousandSeparator=","
                          onValueChange={(value) => {
                            //console.log('warehouse', warehouse);
                            let val = value?.floatValue;
                            if (val >= 0) {
                              let valString = val?.toString();

                              // Price_Quantity_Limit
                              // price and quantity limit from 5 digits to 10 digits
                              if (valString?.length > 10) {
                                valString = valString?.slice(0, 10);
                              }
                              val = parseInt(valString);
                            }
                            setFieldValue(
                              `warehouses[${index}].openingStock`,
                              val ? val : null
                            );
                          }}
                          onBlur={handleBlur}
                          error={getError(
                            errors,
                            touched,
                            `warehouses[${index}].openingStock`
                          )}
                        />
                        <div className="remove_row_button">
                          <button
                           className='close-btn button'
                            type="button"
                            disabled={
                              values.warehouses.length === 1 ||
                              values.warehouses[0].id === null
                            }
                            onClick={() => handleRemoveItem(index)}
                          >
                            <DeleteIcon />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        }}
      />
    );
  };

  const TrackInventoryTitle = () => (
    <div>
      {' '}
      <div className="form-checkbox items-checkbox">
        <label className="form-checkbox-label track_inv_checkbox">
          <input
            id="trackInventory"
            checked={trackInventory}
            type="checkbox"
            onChange={() => handleTrackInventory()}
          />
          <span className="check-uncheck">
            {trackInventory ? (
              <i className="far fa-check-square"></i>
            ) : (
              <i className="far fa-square"></i>
            )}
            {/* <Checked /> */}
          </span>
        </label>
        <label className="bold" htmlFor="trackInventory">
          Track Inventory
        </label>
        <span className="icon_div">
          <MdHelpOutline
            className="material-icon blue-icon help"
            style={{ marginRight: '1.7rem' }}
          />
          <div className="tooltip_tooltip">
            Enable inventory to track this item's stock based on the sales and
            purchase transactions recorded in your business
          </div>
        </span>
      </div>
      <div className="trackDiv">
        <span className="trackSpan">
          {' '}
          You cannot enable/disable inventory tracking once you've created
          transactions for this item
        </span>
      </div>
    </div>
  );

  const inputProps = {
    value: values.unit || '',
    onChange: (event, { newValue }) => {
      if (event.target.id !== 'remove') {
        setFieldValue('unit', newValue);
      }
    },
    onBlur: () => setFieldTouched('unit'),
  };

  const handleSellingPriceChange = (sellPriceValue) => {
    let sellPrice = sellPriceValue.floatValue;
    if (sellPrice >= 0) {
      let sellPriceString = sellPrice.toString();

      // Price_Quantity_Limit

      // price and quantity limit from 5 digits to 10 digits
      if (sellPriceString.length > 10) {
        sellPriceString = sellPriceString.slice(0, 10);
      }
      sellPrice = parseFloat(sellPriceString);
    }
    setFieldValue('salesUnitPrice', sellPrice);
  };

  const handlePurchasePriceChange = (purchPriceValue) => {
    let purchPrice = purchPriceValue.floatValue;
    if (purchPrice >= 0) {
      let purchPriceString = purchPrice.toString();

      // Price_Quantity_Limit

      // price and quantity limit from 5 digits to 10 digits
      if (purchPriceString.length > 10) {
        purchPriceString = purchPriceString.slice(0, 10);
      }
      purchPrice = parseFloat(purchPriceString);
    }
    setFieldValue('purchaseUnitPrice', purchPrice);
  };
  let jango;
  let dummyArr = [];
  allWarehouses.forEach((allWare) => {
    jango = false;
    values.warehouses.forEach((ware) => {
      if (allWare.id === ware.id) {
        jango = true;
      }
    });
    if (jango === false) {
      dummyArr.push(allWare);
    }
  });
  status.warehousesList = dummyArr;
  return (
    <Fragment>
      {renderModals()}
      <Form
        className="item-form items-main-form"
        onSubmit={(event) =>
          formSubmit(
            event,
            values.warehouses,
            setFieldValue,
            handleSubmit,
            'id',
            'warehouses'
          )
        }
        type={inModal && 'modal'}
      >
        <FormSection title="Item Info">
          <div className="form-fields-container d-flex flex-wrap  position-relative all-items-main">
            <div
              className="form-image images-uploading"
              id="Click_Add_Item_Image"
            >
              <Field
                type="image"
                name="Add Images"
                accept="image/*"
                onDrop={handleFiles}
                maxSize={5 * 1000 * 1000}
                // multiple  disabled multiple image upload
                camIconflag={true}
              />
              <ImagePreview
                images={[...values.images, ...values.uploadImages]}
                onDeleteImage={handleImageDelete}
              />
            </div>
            <Field
              className="flex-100"
              name="Item Name"
              id="name"
              autoFocus={true}
              value={values.name}
              handleChange={handleChange}
              onBlur={handleBlur}
              required
              error={(touched.name || submitCount > 0) && errors.name}
            />
            <p className="form-field--side-btn maximum_char">
              240 characters maximum
            </p>
            <div className="flex-100">
              <div className="d-flex">
                {item?.varients?.map((res, index) => (
                  <div key={index}>
                    &nbsp; &nbsp; <b> {Object.keys(res)}: </b> &nbsp; &nbsp;{' '}
                    {Object.values(res)} &nbsp; &nbsp;
                  </div>
                ))}
              </div>
            </div>
            {formType === 'itemGroup' && (
              <div className="d-flex flex-wrap w-100">
                {values?.attributes?.map((attr, index) => (
                  <Field
                    size="large"
                    type="dropdown"
                    key={index}
                    name={attr.name}
                    className="flex-25"
                    value={attr.attributeOptionValue}
                    options={attr.item_attribute_option}
                    clearable={false}
                    dropdownValue="id"
                    dropdownLabel="name"
                    handleChange={(option) => handleAttrChange(option, index)}
                    addButton={inModal ? false : true}
                    required
                    error={getError(
                      errors,
                      touched,
                      `attributes[${index}].attributeOptionValue`
                    )}
                    onBlur={() =>
                      setFieldTouched(
                        `attributes[${index}].attributeOptionValue`,
                        true
                      )
                    }
                  />
                ))}
              </div>
            )}

            <div className="flex-50">
              <div className="d-flex flex-wrap">
                <Field
                  className="flex-50"
                  size="large"
                  type="autosuggest"
                  name="Unit(m, kg, litre etc.)"
                  id="unit"
                  value={values.unit}
                  placeholder="m, kg, litre"
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  removeSuggestion={false}
                  inputProps={inputProps}
                  required={values.type === 'goods'}
                  error={(touched.unit || submitCount > 0) && errors.unit}
                />

                <div className="position-relative flex-50 items_sku">
                  <Field
                    className="flex-100"
                    size="large"
                    name="SKU"
                    id="sku"
                    value={values.sku}
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    required={values.type === 'goods'}
                    error={(touched.sku || submitCount > 0) && errors.sku}
                  />
                  {values.skuLoading ? (
                    <span className="display-flex-row-reverse">
                      <SmallLoader />
                    </span>
                  ) : (
                    <button
                      type="button"
                      onClick={generateSku}
                      className="button form-field--side-btn"
                      id="Click_generate_item_SKU"
                    >
                      Generate SKU
                    </button>
                  )}
                </div>

                <Field
                  className="flex-50"
                  name="EAN"
                  id="ean"
                  value={values.ean}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.ean && errors.ean}
                />
                <Field
                  className="flex-50"
                  name="ISBN"
                  id="isbn"
                  value={values.isbn}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.isbn && errors.isbn}
                />
                <Field
                  className="flex-50"
                  name="Manufacturer"
                  id="manufacturer"
                  value={values.manufacturer}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.manufacturer && errors.manufacturer}
                />
                <Field
                  className="flex-50"
                  name="Brand"
                  id="brand"
                  value={values.brand}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.brand && errors.brand}
                />
                <Field
                  className="flex-50"
                  name="Dimension (cm) ( L * W * H )"
                  id="dimensions"
                  type="text"
                  value={values.dimensions}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.dimensions && errors.dimensions}
                />
                <Field
                  className="flex-50"
                  name="Weight (kg)"
                  id="weight"
                  type="text"
                  value={values.weight}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.weight && errors.weight}
                />
              </div>
            </div>
            <div className="flex-50">
              <div className="d-flex flex-wrap">
                <Field
                  className="flex-50"
                  name="UPC"
                  id="upc"
                  value={values.upc}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.upc && errors.upc}
                />
                <Field
                  className="flex-50"
                  name="MPN"
                  id="mpn"
                  value={values.mpn}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.mpn && errors.mpn}
                />
                <Field
                  className="flex-100 textarea-height"
                  type="textarea"
                  name="Description"
                  id="description"
                  value={values.description}
                  handleChange={handleChange}
                  error={
                    (touched.description || submitCount > 0) &&
                    errors.description
                  }
                />
              </div>
            </div>
          </div>
        </FormSection>
        <hr className="item-hr" />
        <div className="form-fields-container d-flex flex-wrap  position-relative all-items-main margin-top-lg">
          <div className="flex-50">
            <FormSection title="Purchase Information" className="w-100">
              <div className="d-flex flex-wrap w-100">
                <div className="flex-50">
                  <Field
                    className="flex-100"
                    type="number"
                    name="Purchase Price"
                    id="purchaseUnitPrice"
                    value={values.purchaseUnitPrice}
                    prefix={status.currency.symbol}
                    decimalScale={6}
                    placeholder={`${status.currency.symbol} 0`}
                    onValueChange={(value) => handlePurchasePriceChange(value)}
                    onBlur={handleBlur}
                    error={
                      touched.purchaseUnitPrice &&
                      (submitCount > 0 || errors.purchaseUnitPrice) &&
                      errors.purchaseUnitPrice
                    }
                  />

                  {accountDropDownField('purchase')}

                  <Field
                    className="flex-100 position-relative"
                    type="dropdown"
                    name="Possible Vendor"
                    id="vendorID"
                    value={values.vendorID}
                    options={status.vendorsList}
                    dropdownValue="id"
                    dropdownLabel="display_name"
                    handleChange={(option) => handleVendorChange(option)}
                    addButton={inModal ? false : true}
                  />
                </div>
                <div className="flex-50 textarea-height">
                  <Field
                    type="textarea"
                    name="Description"
                    id="purchaseDescription"
                    value={values.purchaseDescription}
                    handleChange={handleChange}
                    error={
                      (touched.purchaseDescription || submitCount > 0) &&
                      errors.purchaseDescription
                    }
                  />
                </div>
              </div>
            </FormSection>
          </div>
          <div className="flex-50 sales-info-main">
            <FormSection title="Sales Information" className="w-100">
              <div className="d-flex flex-wrap w-100">
                <div className="flex-50">
                  <Field
                    size="large"
                    type="number"
                    name="Selling Price"
                    id="salesUnitPrice"
                    value={values.salesUnitPrice}
                    prefix={status.currency.symbol}
                    decimalScale={6}
                    placeholder={`${status.currency.symbol} 0`}
                    onValueChange={(value) => handleSellingPriceChange(value)}
                    onBlur={handleBlur}
                    error={
                      touched.salesUnitPrice &&
                      (submitCount > 0 || errors.salesUnitPrice) &&
                      errors.salesUnitPrice
                    }
                  />

                  {accountDropDownField('sales')}

                  <Field
                    size="large"
                    type="dropdown"
                    name="Item Tax"
                    className="item_tax_dropdown"
                    id="taxID"
                    value={values.taxID}
                    options={status.taxList}
                    dropdownValue="id"
                    dropdownLabel="name"
                    handleChange={(option) => handleTaxChange(option)}
                    addButton={inModal ? false : true}
                  />
                </div>
                <div className="flex-50 textarea-height">
                  <Field
                    size="large"
                    type="textarea"
                    name="Description"
                    id="salesDescription"
                    value={values.salesDescription}
                    handleChange={handleChange}
                    error={
                      (touched.salesDescription || submitCount > 0) &&
                      errors.salesDescription
                    }
                  />
                </div>
              </div>
            </FormSection>
          </div>
        </div>

        <FormSection title={<TrackInventoryTitle />} className="margin-top-lg">
          {trackInventory && (
            <Fragment>
              <div className="form-field form-table--container no-margin itemform-warehouse">
                <label className="form-label">Choose Warehouse</label>
                {renderWarehouseTable()}
                {(type === 'add' || type === 'edit' || type === 'clone') && (
                  <AddItem
                    className="d-flex add-item-btn"
                    label="Add Warehouse"
                    onClick={() =>
                      setFieldValue('warehouses', [
                        ...values.warehouses,
                        status.emptyWarehouse,
                      ])
                    }
                  />
                )}
              </div>
              <div className="form-fields-container d-flex flex-wrap  position-relative">
                <Field
                  className="flex-25"
                  size="large"
                  type="number"
                  name="Opening Stock Value (Per Unit)"
                  id="openingStockValue"
                  value={values.openingStockValue}
                  prefix={status.currency.symbol}
                  decimalScale={6}
                  disabled={values.transactionInfo === true}
                  placeholder={`${status.currency.symbol} 0`}
                  onValueChange={(value) =>
                    setFieldValue('openingStockValue', value.floatValue)
                  }
                  onBlur={handleBlur}
                  error={
                    (touched.openingStockValue || submitCount > 0) &&
                    errors.openingStockValue
                  }
                />

                <Field
                  className="flex-25"
                  size="large"
                  type="number"
                  name="Reorder Level"
                  id="reorderLevel"
                  value={parseInt(values.reorderLevel)}
                  decimalScale={6}
                  onValueChange={(value) => {
                    setFieldValue(
                      'reorderLevel',
                      value.floatValue && parseInt(value.floatValue)
                    );
                  }}
                  onBlur={handleBlur}
                  error={touched.reorderLevel && errors.reorderLevel}
                />
              </div>
            </Fragment>
          )}
        </FormSection>

        <FormAction
          disabled={isSubmitting}
          type="button"
          marginFromRight={0}
          onSubmit={handleSubmit}
          // setPrompt={props.setPrompt}
          onCancel={() => {
            let redirectItemsURL =
              formType === 'itemGroup' ? '/item-groups' : '/items';

            let dataFromLS = getObjFromLS('module');
            let redirectUrl = '';
            if (
              dataFromLS &&
              (dataFromLS.moduleName === 'items' ||
                dataFromLS.moduleName === 'item_groups')
            ) {
              redirectUrl = dataFromLS.queryParam;
            }
            if (inModal) {
              let response;
              let { checkout } = validateCreateItemForm(
                {
                  ...values,
                  trackInventory,
                  type,
                },
                staticVal
              );
              if (!checkout) {
                response = confirmationAlert();
              }
              if (response || checkout) {
                closeItem();
              }
            } else if (
              window.location.pathname.includes('add') ||
              window.location.pathname.includes('edit')
            ) {
              history.push(redirectItemsURL + redirectUrl);
            } else {
              setObjInLS('module', { ...dataFromLS, action: null });
              history.push('/r');
            }
          }}
        />

        <ErrorFocus />
      </Form>
    </Fragment>
  );
};

export default formEnhancer(ItemForm);
