import React from 'react'

import './Radio.css'

const Radio = (
  {
    id,
    value,
    name,
    onChange,
    checked,
    disabled
   }) => {
    return (
      <div className="radio-btn">
        <input
          
          type="radio"
          id={id}
          value={value}
          onChange={(e) => onChange(e)}
          checked={checked}
          disabled={disabled}
        />
        <span className="radio-circle" />
        <label htmlFor={id} style={{zIndex:'0'}}>{name}</label>
      </div>
    )
  } 

export default Radio