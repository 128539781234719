import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import CaretDown from '../../Assets/SVG/CaretDown';
import { CheckIcon } from '../../Assets/Mixed/SearchIcons';
import { MdSettings } from '../../Common/Icons';
import UploadAttachment from '../../Assets/General/UploadAttachmentIcon';
import CalendarIcon from '../../Assets/Dashboard/CalendarIcon';
import './CustomDropdown.css';
import { setObjInLS } from '../../Helpers';

class CustomDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      options: this.props.options || null,
      dropdownView: '',
      reversePosition: '',
    };
  }


  componentWillReceiveProps(nextProps) {
    this.setState({
      options: nextProps.options,
    });
  }

  handleClick = () => {
    // attach/remove event handler
    if (!this.state.active) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      if (this.props.showFilterFlag) {
        this.props.showFilter(false);
      }
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    if (this.props.module) {
      sessionStorage.setItem("card", this.props.module)
    }

    this.setState(
      (prevState) => ({
        active: !prevState.active,
      }),
      () => !this.state.active && this.setState({ dropdownView: '' })
    );
  };

  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (this.props.showFilterFlag) {
      this.props.showFilter(false);
    }
    if (this.node !== null) {
      if (this.node.contains(e.target)) {
        return;
      }
      this.handleClick();
    }
  };

  renderOrderIcon(id, [selectedId, selectedOrder]) {
    if (id === selectedId) {
      if (selectedOrder) return 'descending';
      return 'ascending';
    }
  }

  renderOrderIconLink(id) {
    const { selectedSort, orderBy } = this.props;
    if (id === selectedSort.id) {
      if (orderBy === 'desc') return 'descending';
      return 'ascending';
    }
  }

  handleDropdownView(event) {
    let dropdownHeight = this.refs.customDropdownContainer.offsetHeight;
    let pageHeight = document.body.scrollHeight;
    let heightFromTop = event.target.getBoundingClientRect().y;
    if (heightFromTop + dropdownHeight > pageHeight) {
      this.setState({ dropdownView: 'up' });
    } else {
      this.setState({ dropdownView: 'down' });
    }
  }
  showCoords(event) {
    var cY = event.clientY;
    var sY = event.screenY;
    if (
      (window.screen.height < 1100 &&
        window.screen.height > 800 &&
        cY > 710 &&
        sY > 780) ||
      (window.screen.height < 800 && cY > 420)
    ) {
      this.setState({ reversePosition: 'reversee' });
    } else {
      this.setState({ reversePosition: '' });
    }
  }

  render() {
    let label = this.props.icon || this.props.textLabel;
    let checkboxIcon = this.props.checkboxIcon || null;
    let type = this.props.type || 'button';
    let id = this.props.id || null;
    let count = this.props.attachmentCount;
    let {
      className,
      onClick,
      header,
      withPreferences,
      withPreferenceRoute,
      iconWithinIcon,
      selectedSort,
      selectedFilter = false,
      dotIcon,
      textLabel,
      showFilter,
    } = this.props;
    // let disabled = this.props.options.disabled || false
    let { AttachmentIcon } = this.props;
    return (
      <div
        className={classNames(
          'custom-dropdown',
          className && `${className} abcd`,
          window.location.pathname.includes('contact') && 'add_trasaction',
          this.props.textLabel && 'button-text',
          iconWithinIcon && 'button-icon',
          dotIcon && 'dot-icon'
        )}
        ref={(node) => {
          this.node = node;
        }}
        onClick={(event) => {
          this.handleDropdownView(event);
          this.showCoords(event);
        }}
      >
        <button
          className={this.state.active ? `button button-active` : 'button'}
          type={this.props.type === 'button' ? this.props.type : ''}
          onClick={() => {
            if (this.props.showFilterFlag) {
              showFilter(true);
            }
            this.handleClick();
          }}
        >
          {this.props.attachment && <AttachmentIcon />}
          {label} {this.props.fromDashboard && <CalendarIcon />}
          {this.props.textLabel && (
            <CaretDown className="custom-dropdown--icon" />
          )}
        </button>

        <div
          className={`custom-dropdown-container ${this.state.dropdownView} ${this.state.reversePosition}`}
          ref="customDropdownContainer"
        >
          {header && <div className="custom-dropdown--header">{header}</div>}
          {this.state.options.map((option, index) => {
            let Icon = option.icon;
            let Icon1 = option.icon1;
            let Icon2 = option.icon2;
            if (type === 'checkbox') {
              return (
                <div className="custom-dropdown--options" key={index}>
                  <label className="list-header-dropdown--option-label">
                    <input
                      type="checkbox"
                      defaultChecked
                      // checked={option.checked}
                      onClick={(event) => {
                        event.persist();
                        onClick(option.id, event);
                      }}
                    />
                    <span>{checkboxIcon}</span>
                    {option.name}
                  </label>
                </div>
              );
            } else {
              switch (option.type) {
                case 'external':
                  return (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="custom-dropdown--options"
                      key={index}
                      href={option.onClick()}
                    >
                      {option.label}
                    </a>
                  );
                case 'link':
                  return (
                    <Link
                      // to={ (option.disabled  && option.disabled(id)) ? undefined :  option.onClick(id)}
                      to={option.onClick(id)}
                      className={classNames(
                        'custom-dropdown--options',
                        selectedSort && this.renderOrderIconLink(option.id),
                        option.disabled
                          ? option.disabled(id)
                            ? 'disabled'
                            : ''
                          : ''
                      )}
                      key={index}
                      // onClick={(option.disabled  && option.disabled(id)) ? e => e.preventDefault() : this.handleClick}
                      onClick={this.handleClick}
                    >
                      {Icon && <Icon className="custom-dropdown-icon" />}
                      {option.label}
                      {textLabel === option.label && <CheckIcon />}
                    </Link>
                  );
                case 'tagline':
                  return (
                    <span className="custom-dropdown--tagline" key={index}>
                      {option.label}
                    </span>
                  );
                case 'addNewAttachment':
                  return (
                    <div key={index} className="form-field">
                      {count >= 10 ? (
                        <Fragment>
                          {' '}
                          <input
                            type="file"
                            className="input-files"
                            multiple
                            id="mailFiles"
                            accept=".pdf, .png, .jpg, .jpeg"
                            onChange={(e) => option.onClick(e)}
                            disabled
                          />
                          <label
                            htmlFor="mailFiles"
                            className="input-files--btn"
                          >
                            {option.label}
                            <UploadAttachment />
                          </label>{' '}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <input
                            type="file"
                            className="input-files"
                            multiple
                            id="mailFiles"
                            accept=".pdf, .png, .jpg, .jpeg"
                            onChange={(e) => option.onClick(e)}
                          />
                          <label
                            htmlFor="mailFiles"
                            className="input-files--btn"
                          >
                            {option.label}
                            <UploadAttachment />
                          </label>{' '}
                        </Fragment>
                      )}
                    </div>
                  );
                case 'attachment':
                  return (
                    <div key={index}>
                      <button
                        className={`custom-dropdown--options ${selectedSort &&
                          this.renderOrderIcon(index, selectedSort)
                          }`}
                        onClick={(e) => {
                          option.onClickForIcon1(e);
                        }}
                      >
                        {option.label}
                      </button>

                      {Icon1 && (
                        <Icon1
                          onClick={(e) => {
                            option.onClickForIcon1(e);
                          }}
                          className="icon_1"
                        />
                      )}
                      {Icon2 && (
                        <Icon2
                          onClick={(e) => {
                            option.onClickForIcon2(e);
                          }}
                          className="icon_2"
                        />
                      )}
                    </div>
                  );
                default:
                  return (
                    <button
                      className={`custom-dropdown--options custom-dropdown-set ${selectedSort &&
                        this.renderOrderIcon(index, selectedSort)
                        }`}
                      title={option.tooltipText ? option.tooltipText : null}
                      key={index}
                      type={this.props.isSO ? 'button' : 'submit'}
                      onClick={
                        this.props.isSO
                          ? (e) => {
                            option.onClick(e);
                          }
                          : () => {
                            this.handleClick();
                            option.onClick(
                              id ? id : option.id,
                              this.props.listIndex
                            );
                          }
                      }
                      disabled={
                        this.props.isSO
                          ? option.disabled
                          : option.disabled
                            ? option.disabled(id)
                            : false
                      }
                    >
                      {Icon && <Icon className="custom-dropdown-icon" />}
                      <span className="custom-dropdown-set">
                        {' '}
                        {iconWithinIcon && option.icon} {option.label}{' '}
                      </span>
                      {selectedFilter && selectedFilter === option.id && (
                        <CheckIcon />
                      )}
                    </button>
                  );
              }
            }
          })}
          {withPreferences && withPreferenceRoute && (
            <div className="custom-dropdown--footer">
              <Link to={withPreferenceRoute}>
                <MdSettings /> {withPreferences}
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CustomDropdown;
