

import React from 'react'

export default ({
  className = ''
}) => (

 
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="18.997" height="14.248" viewBox="0 0 18.997 14.248">
<path id="Icon_awesome-envelope" data-name="Icon awesome-envelope" d="M18.637,9.2a.223.223,0,0,1,.36.174v7.588a1.781,1.781,0,0,1-1.781,1.781H1.781A1.781,1.781,0,0,1,0,16.967V9.383a.222.222,0,0,1,.36-.174c.831.646,1.933,1.466,5.718,4.215.783.571,2.1,1.774,3.421,1.766,1.325.011,2.671-1.217,3.425-1.766C16.708,10.674,17.806,9.85,18.637,9.2ZM9.5,14c.861.015,2.1-1.083,2.723-1.536,4.924-3.573,5.3-3.885,6.434-4.775a.888.888,0,0,0,.341-.7v-.7A1.781,1.781,0,0,0,17.216,4.5H1.781A1.781,1.781,0,0,0,0,6.281v.7a.893.893,0,0,0,.341.7c1.135.887,1.51,1.2,6.434,4.775C7.4,12.915,8.638,14.013,9.5,14Z" transform="translate(0 -4.5)"/>
</svg>

  )
