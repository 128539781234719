import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { restRequest } from '../../../Helpers/RequestHelper';

import OverlayLoader from '../../../Components/Loader/OverlayLoader';
import Form from '../../../Components/Form/Form';
// import Field from '../../../Components/Form/Field/Field'
import FormAction from '../../../Components/Form/FormAction';
import { getObjFromLS } from '../../../Helpers/LocalStorage'; //, setObjInLS

import './ReturnReceivePreference.css';
import { checkError } from '../../../Helpers/AuthHelper';

class ReturnReceivePreference extends Component {
  state = {
    loading: true,
    salesPref: {
      rrFormat: '',
    },
    terms: '',
    isSubmitting: false,
  };

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    this.setState({ loading: true });
    await restRequest('get', 'numberPreferences/returnreceive')
      .then((res) => {
        this.setState({
          salesPref: {
            rrFormat: res.preferences.rrFormat,
          },
          terms: res.terms,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  }

  toggleReturnReceiveFormatChange(value) {
    let rrAddedFormat = value.target.value;
    this.setState((state) => ({
      salesPref: {
        ...state.salesPref,
        rrFormat: rrAddedFormat,
      },
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let { rrFormat } = this.state.salesPref;
    this.setState({ isSubmitting: true });
    restRequest('put', 'numberPreferences/returnreceive', {
      status: {
        rrFormat: rrFormat,
      },
      terms: this.state.terms,
    })
      .then(() => {
        this.setState({ isSubmitting: false });
        this.props.handleToast(
          'Return Receive Number Format preference updated successfully',
          'success'
        );
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });
        //this.props.handleToast(error, "error")
        checkError(error, this.props.handleToast);
      });
  };
  /*
    salesTermsContent() {
      return (
        <div>
          <Field
            name="Terms & Conditions"
            id="terms"
            type="textarea"
            value={this.state.terms}
            placeholder="Terms & Conditions"
            handleChange={this.handleChange}
          />
        </div>
      )
    }
  */
  renderActionButtons() {
    // return (
    //   <FormAction disabled={this.state.isSubmitting} onCancel={() => this.props.history.push('/settings')} />
    // )
    let permissions = getObjFromLS('role').permissions;
    if (!permissions.superAccess) {
      if (permissions.PreferenceEdit) {
        return (
          <FormAction
            disabled={this.state.isSubmitting}
            onCancel={() => this.props.history.push('/settings')}
          />
        );
      }
    }
    if (permissions.superAccess) {
      return (
        <FormAction
          disabled={this.state.isSubmitting}
          onCancel={() => this.props.history.push('/settings')}
        />
      );
    }
  }

  renderReturnReceivePreference() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <div className="po-pref-component">
          {this.state.loading && <OverlayLoader />}
          <div>
            You can change Return Receive Prefix according to your need.
          </div>
          {/*<hr className="pref--hr" />*/}
          <label className="list-table--checkbox-lable">
            <div className="sale-return-preference--label-text semi-bold">
              Return Receive Prefix
            </div>
            <input
              style={{ width: '50px' }}
              name="rrFormat"
              value={this.state.salesPref.rrFormat}
              type="text"
              //pattern='[0-9]{0,5}'
              maxLength={3}
              required
              onChange={(value) => this.toggleReturnReceiveFormatChange(value)}
            />
          </label>
          {this.renderActionButtons()}
        </div>
      </Form>
    );
  }

  render() {
    return this.renderReturnReceivePreference();
  }
}

export default withRouter(ReturnReceivePreference);
