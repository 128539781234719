import React, { useState } from 'react';
import { CustomModal } from '../../../../Components';
import { getStoreUrl } from '../../../../Helpers';
import { SiWordpress, SiShopify } from '../../../../Common/Icons';
import './ItemQuantityDetails.css';

const ItemQuantity = ({
  shiped,
  received,
  reorder,
  current = [],
  handleUnlink,
  inModal,
}) => {
  const [toggle, setToggle] = useState(true);
  const [unlink, setUnlink] = useState({
    unlinkShowModal: false,
    storeDetail: {},
  });
  const [itemtoggle, setItemToggle] = useState([]);
  const [storename, setStorename] = useState('');


  const ToggleDetailsHandler = (name, index) => {
    const toggleitem = []
    for (let i = 0; i < Object.keys(current).length; i++) {
      toggleitem[i] = index === i ? !itemtoggle[index] : false
    }
    setStorename(name);
    setItemToggle(toggleitem);
  };

  const handleUnlinkStore = (store) => {
    setUnlink(() => ({
      unlinkShowModal: true,
      storeDetail: store,
    }));
  };

  const confirmModal = () => (
    <CustomModal
      // className="smallWidthClass"
      className="white  syncModal item-sync-popup medium-modal"
      size="medium"
      showModal={unlink.unlinkShowModal}
      title="Unlink Item"
      onClose={() =>
        setUnlink(() => ({
          unlinkShowModal: false,
          storeDetail: {},
        }))
      }
      onSubmit={() => handleUnlink(unlink.storeDetail)}
    >
      {`Are you sure to unlink item '${unlink?.storeDetail?.sync_item?.name}'
      of ${unlink?.storeDetail?.market_place || ''}
      ${unlink?.storeDetail?.store?.name || ''}?`}
    </CustomModal>
  );

  const cardTitle = (content = '', title = '') => (
    <div className="quantity_box">
      <span className="upper_text">{content}</span>
      <span className="lower_text">{title} Items</span>
    </div>
  );

  return (
    <>
      {confirmModal()}
      {Object.keys(current).length > 0 && (
        <div className="items-group-main space-between d-flex">
          {current.map((store, index) => (
            <div className="items-group-inner">
              <button
                onClick={() => setToggle(!toggle)}
                className={` ${toggle ? 'text-italic-active' : 'text-italic '}`}
                style={{ visibility: index > 0 && 'hidden' }}
              >
                Associated Sales Channels
              </button>

              <div
                className={`item-group-desc ${toggle ? 'group-desc-show' : ''}`}
              >
                <div key={index} className=" d-flex">
                  <div className="group-left-set">
                    <div className="item-group-img">
                      {store.market_place === 'shopify' ? (
                        <SiShopify size={40} />
                      ) : (
                        store.market_place === 'woo-commerce' && (
                          <SiWordpress size={40} />
                        )
                      )}
                    </div>
                  </div>
                  <div className="group-right-set">
                    <h4>{store.market_place}</h4>
                    <div className="group-counts">
                      <p className="mb-0">
                        <b>Store </b>:{' '}
                        {!inModal ? (
                          <a href={getStoreUrl(store.store)} target="_blank">
                            {store.store?.name || store.store?.store_url || ''}
                          </a>
                        ) : (
                          <span>
                            {store.store?.name || store.store?.store_url || ''}
                          </span>
                        )}
                      </p>
                      <p className="inventory-track">{store.store.status}</p>
                    </div>
                  </div>
                  <div
                    className="item_toggle text-center"
                    onClick={() =>
                      ToggleDetailsHandler(store.store?.name || store.store?.store_url, index)
                    }
                  >
                    {!inModal &&
                      <button
                        onClick={() => handleUnlinkStore(store)}
                      >
                        unlink
                      </button>
                    }
                    <i
                      className={`fas fa-chevron-down ${itemtoggle[index] && storename === (store.store?.name || store.store?.store_url)
                        ? 'fa-rotate'
                        : ''
                        }`}
                    />
                  </div>
                </div>

                {/* ending the top section */}

                {/* items table */}
                <div
                  className={` items-table-main ${itemtoggle[index] && storename === (store.store?.name || store.store?.store_url)
                    ? 'd-block'
                    : 'd-none'
                    }`}
                >
                  <table className="item-table-width table">
                    <thead>
                      <tr>
                        <th>
                          <b>Item Name</b>
                        </th>
                        <th>
                          <b>Item SKU</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{store.sync_item?.name}</td>
                        <td>{store.sync_item?.sku}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}
          {/* </CSSTransition> */}
        </div>
      )}
      <div className="quantity_row quantity-mob-row">
        {cardTitle('Quantity to be Shipped', shiped)}
        {cardTitle('Quantity to be Received', received)}
        {cardTitle('Reorder Level', reorder)}
      </div>
    </>
  );
};

export default ItemQuantity;
