import React, { useEffect, useState } from 'react';
import OrganizationIcon from '../../Assets/General/SettingPageIcon';
import { OrganizationForm } from '../../Components/Form';
import { FormLayout } from '../../Components';
import useToggleClick from '../../Hooks';
import { Prompt } from 'react-router-dom';
import './EditOrganization.css';

const EditOrganization = ({ handleToast }) => {
  const [showPrompt, setPrompt] = useState(false);
  const [, handleCrossAlert] = useToggleClick();
  useEffect(() => {
    document.title = 'Update Business';
    window.onbeforeunload = function () {
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const handlePrompt = (status = true) => setPrompt(status);

  return (
    <>
      <Prompt
        when={showPrompt}
        message="Are you sure you want to leave page while changes made?"
      />
      <FormLayout
        title="Business"
        Icon={OrganizationIcon}
        subtitle="Edit Business"
        closeUrl="/oc/organization/choose"
        layoutBoxClass="uiux-form-redesign"
      >
        <OrganizationForm
          type="Edit"
          handleToast={handleToast}
          handleCrossAlert={handleCrossAlert}
          setPrompt={handlePrompt}
        />
      </FormLayout>
    </>
  );
};

export default EditOrganization;
