import React from 'react';
import * as Yup from 'yup';

// import { restRequest } from '../../../../Helpers/RequestHelper'
import { withFormik } from 'formik';

import {
  required,
  ValidateName,
  // ValidatePercentage,
  // ValidateMax50,
} from '../../../../Helpers/Validator';
import Field from '../../Field/Field';
import Form from '../../Form';
import FormAction from '../../FormAction';
import ErrorFocus from '../../ErrorFocus';
// import {checkError} from "../../../../Helpers/AuthHelper";

const formEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    option: required(ValidateName),
  }),
  mapPropsToValues: ({ option }) => ({
    ...option,
  }),
  handleSubmit: (values, bag) => {
    handleFormSubmit(values, bag);
  },
});

const handleFormSubmit = (
  values,
  { setSubmitting, props: { onSubmitModal } } //requestType, requestUrl, inModal,
) => {
  const { option } = values;
  onSubmitModal(option);
};

const TaxForm = (props) => {
  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    // setFieldValue,
    isSubmitting,
    // submitCount,
    errors,
    touched,
    // custom props
    inModal,
    closeModal,
    history,
    title,
  } = props;
  return (
    <Form title={title} onSubmit={handleSubmit} className="tax-form">
      <Field
        autoFocus={true}
        className="inline-field"
        size="large"
        name="Option"
        value={values.option}
        id="option"
        required
        handleChange={handleChange}
        error={touched.option && errors.option}
        onBlur={handleBlur}
      />

      <FormAction
        disabled={isSubmitting}
        onCancel={() => (inModal ? closeModal() : history.goBack())}
      />
      <ErrorFocus />
    </Form>
  );
};

export default formEnhancer(TaxForm);
