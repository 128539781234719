import React, { Component } from 'react';
import { FormGroup, ControlLabel } from 'react-bootstrap';

import { restRequest } from '../../Helpers/RequestHelper';
import './PasswordReset.css';
import Email_confirmation from '../../Assets/Img/Email_confirmation.png';
import Loader from '../../Components/Loader/Loader';
import { checkError } from '../../Helpers/AuthHelper';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      currentPassword: '',
      newPassword: '',
      code: this.props.code,
      confirmNewPassword: '',
      role: '',
      rolesFromDb: '',
      successMsg: false,
      charactersFlag: false,
      symbolFlag: false,
      numberFlag: false,
      caseFlag: false,
      matchCase: false,
      viewPass: false,
      viewPassConfirm: false,
      codeSent: this.props.byPassConfirmation,
      enteringCode: this.props.byPassConfirmation,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResetSubmit = this.handleResetSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleChangeNewPassword(event) {
    if (event.target.value.length < 8 || event.target.value.length > 250) {
      this.setState({ charactersFlag: true });
    } else {
      this.setState({ charactersFlag: false });
    }

    if (!/[-!$%^&*#@()_+|~=`{}[\]:";'<>?,./]+/.test(event.target.value)) {
      this.setState({ symbolFlag: true });
    } else {
      this.setState({ symbolFlag: false });
    }

    if (
      !(
        /.*[a-z]/.test(event.target.value) && /.*[A-Z]/.test(event.target.value)
      )
    ) {
      this.setState({ caseFlag: true });
    } else {
      this.setState({ caseFlag: false });
    }

    if (!/[0-9]+/.test(event.target.value)) {
      this.setState({ numberFlag: true });
    } else {
      this.setState({ numberFlag: false });
    }
    this.setState({ newPassword: event.target.value });
  }

  handleChangeConfirmNew(event) {
    if (event.target.value !== this.state.newPassword) {
      this.setState({ matchCase: true });
    } else {
      this.setState({ matchCase: false });
    }

    this.setState({ confirmNewPassword: event.target.value });
  }

  async fetchData() {
    this.setState({ loading: true });

    await restRequest('get', 'user/profile')
      .then(
        (res) => {
          this.setState({
            email: res.email,
          });
          this.setState({ loading: false });
        },
        (error) => {
          this.setState({ loading: false });
          checkError(error, this.props.handleToast);
          //this.props.handleToast(error, "error")
        }
      )
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  }

  handleSubmit(event) {
    event.preventDefault();
    // Post user data to server.
    restRequest('post', 'password/current/check', {
      currentPassword: this.state.currentPassword,
      email: this.state.email,
    })
      .then((res) => {
        this.setState({ successMsg: true });
        this.props.handleToast('Current password verified', 'success');
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
      });
  }
  handleProceed(event) {
    event.preventDefault();
    this.setState({ loading: true });
    restRequest('post', 'reset_password ')
      .then((res) => {
        this.setState({
          codeSent: true,
        });
        this.setState({ loading: false });
        this.props.handleToast('Verification code sent!', 'success');
      })
      .catch((error) => {
        this.setState({ loading: false });
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
      });
  }
  enterCode(event) {
    event.preventDefault();
    this.setState({
      enteringCode: true,
    });
    if (this.props.showHead) this.props.showHeader(false);
  }

  async handleResetSubmit(event) {
    event.preventDefault();

    restRequest('post', 'user/passwordreset', {
      code: this.state.code,
      password: this.state.newPassword,
      password_confirmation: this.state.confirmNewPassword,
    })
      .then(() => {
        this.props.handleToast(
          'Password has been reset successfully',
          'success'
        );
        this.setState({ code: '' });
        this.props.onClose();
        this.props.history.push('/myaccount');
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
      });
  }

  currentPassword() {
    return (
      <div>
        {/* <Header title="Reset Password" /> */}
        <div className="container container-fix" style={{ padding: '0' }}>
          <div className="panel-group">
            <div className="panel panel-default">
              {/* <div className="body-padding">
              <div className="header-title">Password Reset</div>
            </div> */}
              <div className="panel-body body-padding">
                <form
                  className="form-horizontal column"
                  method="post"
                  action="#"
                  onSubmit={this.handleSubmit}
                >
                  <div className="col-md-12">
                    <FormGroup
                      controlId="formValidationNull"
                      validationState={null}
                    >
                      <ControlLabel className="semi-bold">
                        Current Password
                      </ControlLabel>
                      <input
                        className="form-control"
                        type="password"
                        value={this.state.currentPassword}
                        name="currentPassword"
                        validations={['required']}
                        placeholder="******"
                        required
                        onChange={this.handleChange}
                      />
                    </FormGroup>

                    <div className="password-reset-form-submit">
                      <FormGroup
                        controlId="formValidationNull"
                        validationState={null}
                      >
                        <button
                          type="submit"
                          className="form-footer--btn next-btn semi-bold password-reset-form-submit--btn"
                        >
                          Submit
                        </button>

                        <button
                          type="button"
                          className="form-footer--btn cancel-btn semi-bold"
                          onClick={() => {
                            this.props.history.push('/dashboard');
                          }}
                          style={{ marginLeft: 5 }}
                        >
                          Cancel
                        </button>
                      </FormGroup>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  //  convertToSterics = (email) =>{
  //   return email.replace(/^.{1,7}/, m=> "*".repeat(m.length))
  // }

  sendingEmail() {
    return (
      <div>
        {/* <Header title="Reset Password" /> */}
        <div className="container container-fix" style={{ padding: '0' }}>
          <div className="panel-group" style={{ margin: '0' }}>
            <div className="panel panel-default">
              {/* <div className="body-padding">
              <div className="header-title">Password Reset</div>
            </div> */}
              <div className="panel-body" style={{ padding: '0' }}>
                <form className="form-horizontal column">
                  <div className="col-md-12" style={{ padding: '0' }}>
                    <FormGroup
                      controlId="formValidationNull"
                      validationState={null}
                      style={{ paddingTop: '3rem', paddingBottom: '3rem' }}
                    >
                      <ControlLabel
                        className="semi-bold float-left w-100"
                        style={{
                          padding: '0',
                          fontWeight: '500',
                          marginBottom: '5px',
                        }}
                      >
                        We're about to send you a confirmation email to your
                        account <b>{this.props.email}</b>
                      </ControlLabel>
                      <ControlLabel
                        className="semi-bold float-left w-100"
                        style={{ padding: '0', fontWeight: '500' }}
                      >
                        Click Proceed button if you agree, or Cancel to go back
                        to settings.
                      </ControlLabel>
                    </FormGroup>

                    <div
                      className="password-reset-form-submit float-left w-100"
                      style={{
                        margin: '0',
                        paddingTop: '1.6rem',
                        borderTop: '1px solid var(--border-light)',
                      }}
                    >
                      <FormGroup
                        className="float-right"
                        controlId="formValidationNull"
                        validationState={null}
                      >
                        <button
                          type="button"
                          className="form-footer--btn cancel-btn semi-bold"
                          onClick={() => this.props.onClose()}
                          style={{ marginRight: 10 }}
                        >
                          Cancel
                        </button>
                        <button
                          onClick={(e) => this.handleProceed(e)}
                          className="form-footer--btn next-btn semi-bold password-reset-form-submit--btn"
                        >
                          Proceed
                        </button>
                      </FormGroup>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  emailSent() {
    if (!this.props.showHead) this.props.showHeader(true);
    return (
      <div>
        <div className="container container-fix" style={{ padding: '0' }}>
          <div className="panel-group" style={{ margin: '0' }}>
            <div className="panel panel-default">
              <div className="panel-body" style={{ padding: '0' }}>
                <div className="col-md-12 text-center" style={{ padding: '0' }}>
                  <img
                    style={{ height: '180px' }}
                    src={Email_confirmation}
                    alt="SeeBiz IMS"
                  />
                  <h2 style={{ fontWeight: '700' }}>Please Check Your Email</h2>
                  <p style={{ fontWeight: '500' }}>
                    A confirmation code has been sent to{' '}
                    <b>{this.props.email}</b>
                  </p>
                  <p>
                    Didn't received any code?{' '}
                    <a onClick={(e) => this.handleProceed(e)}>
                      <i
                        style={{
                          fontWeight: '500',
                          textDecoration: 'underline',
                        }}
                      >
                        Send Again
                      </i>
                    </a>
                  </p>
                  <p>
                    Received Code?
                    <a onClick={(e) => this.enterCode(e)}>
                      {' '}
                      <i
                        style={{
                          fontWeight: '500',
                          textDecoration: 'underline',
                        }}
                      >
                        Enter Here
                      </i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  newPassword() {
    return (
      <div>
        {/* <Header title="Reset Password" /> */}
        <div
          className="panel-group container container-fix reset_password"
          style={{ padding: '0', margin: '0' }}
        >
          <div className="panel panel-default">
            {/* <div className="body-padding">
              <div className="header-title">Password Reset</div>
            </div> */}
            <ControlLabel className="semi-bold">
              Confirmation Code <span className="required-str">*</span>{' '}
            </ControlLabel>

            <div className="panel-body" style={{ padding: '0' }}>
              <form
                className="form-horizontal column"
                method="post"
                action="#"
                onSubmit={this.handleResetSubmit}
              >
                <div className="col-md-12" style={{ padding: '0' }}>
                  <div
                    className="form-input-wrapper"
                    style={{ display: 'flex' }}
                  >
                    <input
                      className="form-control"
                      type="text"
                      autoFocus={true}
                      value={this.state.code}
                      name="code"
                      validations={['required']}
                      required
                      onChange={(e) => this.setState({ code: e.target.value })}
                    />
                  </div>
                </div>
                <div className="col-md-12" style={{ padding: '0' }}>
                  <FormGroup
                    controlId="formValidationNull"
                    validationState={null}
                  >
                    <ControlLabel className="semi-bold">
                      New Password <span className="required-str">*</span>{' '}
                    </ControlLabel>
                    <div
                      className="form-input-wrapper"
                      style={{ display: 'flex' }}
                    >
                      <input
                        className="form-control"
                        type={!this.state.viewPass ? 'password' : 'text'}
                        value={this.state.newPassword}
                        name="newPassword"
                        validations={['required']}
                        placeholder="******"
                        required
                        onChange={(e) => this.handleChangeNewPassword(e)}
                      />
                      {this.state.viewPass ? (
                        <svg
                          style={{ marginTop: '6px', marginLeft: '-5%' }}
                          onClick={() => this.setState({ viewPass: false })}
                          width="25"
                          height="25"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 3.5C8.05723 3.50104 6.15976 4.08688 4.55456 5.18127C2.94936 6.27566 1.71077 7.82792 1 9.636C1.70973 11.4448 2.94805 12.9979 4.55347 14.0925C6.15889 15.1872 8.0569 15.7727 10 15.7727C11.9431 15.7727 13.8411 15.1872 15.4465 14.0925C17.0519 12.9979 18.2903 11.4448 19 9.636C18.2892 7.82792 17.0506 6.27566 15.4454 5.18127C13.8402 4.08688 11.9428 3.50104 10 3.5ZM10 13.727C9.19088 13.727 8.39992 13.4871 7.72716 13.0375C7.0544 12.588 6.53005 11.9491 6.22041 11.2016C5.91077 10.454 5.82976 9.63146 5.98761 8.83789C6.14546 8.04431 6.53509 7.31536 7.10723 6.74323C7.67936 6.17109 8.40831 5.78146 9.20189 5.62361C9.99546 5.46576 10.818 5.54677 11.5656 5.85641C12.3131 6.16605 12.952 6.6904 13.4015 7.36316C13.8511 8.03592 14.091 8.82688 14.091 9.636C14.0907 10.7209 13.6596 11.7613 12.8925 12.5285C12.1253 13.2956 11.0849 13.7267 10 13.727ZM10 7.182C9.51445 7.182 9.0398 7.32598 8.63608 7.59574C8.23235 7.8655 7.91769 8.24892 7.73188 8.69751C7.54606 9.14611 7.49745 9.63972 7.59217 10.1159C7.6869 10.5922 7.92071 11.0296 8.26405 11.3729C8.60739 11.7163 9.04483 11.9501 9.52105 12.0448C9.99728 12.1396 10.4909 12.0909 10.9395 11.9051C11.3881 11.7193 11.7715 11.4046 12.0413 11.0009C12.311 10.5972 12.455 10.1226 12.455 9.637C12.4555 9.31446 12.3924 8.99498 12.2692 8.69689C12.146 8.3988 11.9652 8.12795 11.7371 7.89988C11.509 7.6718 11.2382 7.49099 10.9401 7.3678C10.642 7.24461 10.3225 7.18147 10 7.182Z"
                            fill="#ADB6BC"
                          />
                        </svg>
                      ) : (
                        <svg
                          style={{ marginTop: '6px', marginLeft: '-5%' }}
                          onClick={() => this.setState({ viewPass: true })}
                          width="25"
                          height="25"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 5.27406C10.5381 5.27327 11.071 5.37855 11.5683 5.58388C12.0657 5.78921 12.5176 6.09055 12.8984 6.47069C13.2792 6.85082 13.5813 7.30229 13.7875 7.79928C13.9937 8.29626 14.0999 8.82901 14.1 9.36706C14.0997 9.88144 13.9995 10.3909 13.805 10.8671L16.195 13.2571C17.4417 12.2164 18.4064 10.8786 19 9.36706C18.2891 7.55881 17.0506 6.0063 15.4455 4.91144C13.8404 3.81658 11.943 3.23002 10 3.22806C8.88762 3.22693 7.78363 3.42052 6.738 3.80006L8.506 5.56906C8.98032 5.37531 9.48764 5.27514 10 5.27406ZM1.819 3.04006L3.685 4.90606L4.062 5.28306C2.69369 6.3453 1.63612 7.75586 1 9.36706C1.46604 10.5536 2.16193 11.6364 3.04769 12.5532C3.93345 13.47 4.99163 14.2028 6.16144 14.7094C7.33124 15.216 8.58962 15.4865 9.86427 15.5053C11.1389 15.5241 12.4047 15.2909 13.589 14.8191L13.933 15.1631L16.333 17.5531L17.373 16.5131L2.858 2.00006L1.819 3.04006ZM6.345 7.56606L7.614 8.83506C7.57176 9.00927 7.54994 9.18781 7.549 9.36706C7.54847 9.68931 7.61148 10.0085 7.7344 10.3064C7.85733 10.6043 8.03777 10.875 8.2654 11.1031C8.49304 11.3312 8.76341 11.5122 9.06104 11.6357C9.35868 11.7593 9.67775 11.8229 10 11.8231C10.1793 11.8221 10.3578 11.8003 10.532 11.7581L11.8 13.0261C11.0361 13.4106 10.1703 13.5445 9.32594 13.4086C8.48154 13.2728 7.70148 12.8741 7.09672 12.2693C6.49197 11.6646 6.0933 10.8845 5.95745 10.0401C5.82159 9.19573 5.95545 8.32999 6.34 7.56606H6.345ZM9.873 6.92806L12.451 9.50606L12.467 9.37506C12.4674 9.05256 12.4042 8.73314 12.2809 8.43511C12.1577 8.13707 11.9769 7.86628 11.7488 7.63823C11.5208 7.41019 11.25 7.22937 10.952 7.10613C10.6539 6.9829 10.3345 6.91967 10.012 6.92006L9.873 6.92806Z"
                            fill="#ADB6BC"
                          />
                        </svg>
                      )}
                    </div>
                  </FormGroup>
                  <ul className="mismatch-passsword--errors passsword--errors touched">
                    <li className={this.state.charactersFlag ? 'error' : null}>
                      At least 8 characters
                    </li>
                    <li className={this.state.symbolFlag ? 'error' : null}>
                      Contain atleast 1 symbol
                    </li>
                    <li className={this.state.numberFlag ? 'error' : null}>
                      Contain atleast 1 number
                    </li>
                    <li className={this.state.caseFlag ? 'error' : null}>
                      Combine uppercase and lower case letters
                    </li>
                  </ul>
                </div>
                <div className="col-md-12" style={{ padding: '0' }}>
                  <FormGroup
                    controlId="formValidationNull"
                    validationState={null}
                    style={{ marginBottom: '2%' }}
                  >
                    <ControlLabel className="semi-bold">
                      Confirm New Password{' '}
                      <span className="required-str">*</span>
                    </ControlLabel>
                    <div
                      className="form-input-wrapper"
                      style={{ display: 'flex' }}
                    >
                      <input
                        className="form-control"
                        type={!this.state.viewPassConfirm ? 'password' : 'text'}
                        value={this.state.confirmNewPassword}
                        name="confirmNewPassword"
                        validations={['required']}
                        placeholder="******"
                        required
                        onChange={(e) => this.handleChangeConfirmNew(e)}
                      />
                      {this.state.viewPassConfirm ? (
                        <svg
                          style={{ marginTop: '6px', marginLeft: '-5%' }}
                          onClick={() =>
                            this.setState({ viewPassConfirm: false })
                          }
                          width="25"
                          height="25"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 3.5C8.05723 3.50104 6.15976 4.08688 4.55456 5.18127C2.94936 6.27566 1.71077 7.82792 1 9.636C1.70973 11.4448 2.94805 12.9979 4.55347 14.0925C6.15889 15.1872 8.0569 15.7727 10 15.7727C11.9431 15.7727 13.8411 15.1872 15.4465 14.0925C17.0519 12.9979 18.2903 11.4448 19 9.636C18.2892 7.82792 17.0506 6.27566 15.4454 5.18127C13.8402 4.08688 11.9428 3.50104 10 3.5ZM10 13.727C9.19088 13.727 8.39992 13.4871 7.72716 13.0375C7.0544 12.588 6.53005 11.9491 6.22041 11.2016C5.91077 10.454 5.82976 9.63146 5.98761 8.83789C6.14546 8.04431 6.53509 7.31536 7.10723 6.74323C7.67936 6.17109 8.40831 5.78146 9.20189 5.62361C9.99546 5.46576 10.818 5.54677 11.5656 5.85641C12.3131 6.16605 12.952 6.6904 13.4015 7.36316C13.8511 8.03592 14.091 8.82688 14.091 9.636C14.0907 10.7209 13.6596 11.7613 12.8925 12.5285C12.1253 13.2956 11.0849 13.7267 10 13.727ZM10 7.182C9.51445 7.182 9.0398 7.32598 8.63608 7.59574C8.23235 7.8655 7.91769 8.24892 7.73188 8.69751C7.54606 9.14611 7.49745 9.63972 7.59217 10.1159C7.6869 10.5922 7.92071 11.0296 8.26405 11.3729C8.60739 11.7163 9.04483 11.9501 9.52105 12.0448C9.99728 12.1396 10.4909 12.0909 10.9395 11.9051C11.3881 11.7193 11.7715 11.4046 12.0413 11.0009C12.311 10.5972 12.455 10.1226 12.455 9.637C12.4555 9.31446 12.3924 8.99498 12.2692 8.69689C12.146 8.3988 11.9652 8.12795 11.7371 7.89988C11.509 7.6718 11.2382 7.49099 10.9401 7.3678C10.642 7.24461 10.3225 7.18147 10 7.182Z"
                            fill="#ADB6BC"
                          />
                        </svg>
                      ) : (
                        <svg
                          style={{ marginTop: '6px', marginLeft: '-5%' }}
                          onClick={() =>
                            this.setState({ viewPassConfirm: true })
                          }
                          width="25"
                          height="25"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 5.27406C10.5381 5.27327 11.071 5.37855 11.5683 5.58388C12.0657 5.78921 12.5176 6.09055 12.8984 6.47069C13.2792 6.85082 13.5813 7.30229 13.7875 7.79928C13.9937 8.29626 14.0999 8.82901 14.1 9.36706C14.0997 9.88144 13.9995 10.3909 13.805 10.8671L16.195 13.2571C17.4417 12.2164 18.4064 10.8786 19 9.36706C18.2891 7.55881 17.0506 6.0063 15.4455 4.91144C13.8404 3.81658 11.943 3.23002 10 3.22806C8.88762 3.22693 7.78363 3.42052 6.738 3.80006L8.506 5.56906C8.98032 5.37531 9.48764 5.27514 10 5.27406ZM1.819 3.04006L3.685 4.90606L4.062 5.28306C2.69369 6.3453 1.63612 7.75586 1 9.36706C1.46604 10.5536 2.16193 11.6364 3.04769 12.5532C3.93345 13.47 4.99163 14.2028 6.16144 14.7094C7.33124 15.216 8.58962 15.4865 9.86427 15.5053C11.1389 15.5241 12.4047 15.2909 13.589 14.8191L13.933 15.1631L16.333 17.5531L17.373 16.5131L2.858 2.00006L1.819 3.04006ZM6.345 7.56606L7.614 8.83506C7.57176 9.00927 7.54994 9.18781 7.549 9.36706C7.54847 9.68931 7.61148 10.0085 7.7344 10.3064C7.85733 10.6043 8.03777 10.875 8.2654 11.1031C8.49304 11.3312 8.76341 11.5122 9.06104 11.6357C9.35868 11.7593 9.67775 11.8229 10 11.8231C10.1793 11.8221 10.3578 11.8003 10.532 11.7581L11.8 13.0261C11.0361 13.4106 10.1703 13.5445 9.32594 13.4086C8.48154 13.2728 7.70148 12.8741 7.09672 12.2693C6.49197 11.6646 6.0933 10.8845 5.95745 10.0401C5.82159 9.19573 5.95545 8.32999 6.34 7.56606H6.345ZM9.873 6.92806L12.451 9.50606L12.467 9.37506C12.4674 9.05256 12.4042 8.73314 12.2809 8.43511C12.1577 8.13707 11.9769 7.86628 11.7488 7.63823C11.5208 7.41019 11.25 7.22937 10.952 7.10613C10.6539 6.9829 10.3345 6.91967 10.012 6.92006L9.873 6.92806Z"
                            fill="#ADB6BC"
                          />
                        </svg>
                      )}
                    </div>
                  </FormGroup>
                  <ul className="mismatch-passsword--errors touched left_0">
                    {this.state.matchCase && (
                      <li className={this.state.matchCase ? 'error' : null}>
                        {' '}
                        Passwords do not match{' '}
                      </li>
                    )}
                  </ul>
                  <div
                    className="password-reset-form-submit float-left w-100"
                    style={{
                      marginTop: '1.6rem',
                      marginBottom: '0',
                      paddingTop: '1.6rem',
                      borderTop: '1px solid var(--border-light)',
                    }}
                  >
                    <FormGroup
                      className="float-right"
                      controlId="formValidationNull"
                      validationState={null}
                    >
                      <button
                        type="submit"
                        disabled={
                          this.state.charactersFlag ||
                          this.state.symbolFlag ||
                          this.state.numberFlag ||
                          this.state.caseFlag ||
                          this.state.matchCase ||
                          this.state.code === '' ||
                          this.state.newPassword === '' ||
                          this.state.confirmNewPassword !==
                            this.state.newPassword
                        }
                        className="form-footer--btn next-btn semi-bold password-reset-form-submit--btn"
                      >
                        Change Password
                      </button>

                      {/* <button
                      type="button"
                      className='form-footer--btn cancel-btn semi-bold'
                      onClick={() =>  this.props.onClose()}
                      style={{marginLeft: 5}}
                    >
                      Cancel
                    </button> */}
                    </FormGroup>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading)
      return (
        <div>
          <Loader />
        </div>
      );
    return this.state.codeSent === false
      ? this.sendingEmail()
      : this.state.enteringCode === false
      ? this.emailSent()
      : this.newPassword();
    // return (
    //   // this.state.successMsg !== false ? this.newPassword() : this.currentPassword()
    //   // this.sendingEmail()
    //   // this.emailSent()

    // )
  }
}

export default ResetPassword;
