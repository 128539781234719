import React from 'react'

export default ({
  className = ''
}) => (
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 16.875">
  <path d="M10-16.25a1.478,1.478,0,0,0-1,.371A2.084,2.084,0,0,0,8.379-15H3.125V.625h13.75V-15H11.621A2.084,2.084,0,0,0,11-15.879,1.478,1.478,0,0,0,10-16.25ZM10-15a.6.6,0,0,1,.439.186.6.6,0,0,1,.186.439v.625H12.5v1.25h-5v-1.25H9.375v-.625a.6.6,0,0,1,.186-.439A.6.6,0,0,1,10-15ZM4.375-13.75H6.25v2.5h7.5v-2.5h1.875V-.625H4.375ZM13.3-9.2,9.375-5.273,7.324-7.324l-.9.9,2.5,2.5.449.43.449-.43L14.2-8.3Z"
        transform="translate(-3.125 16.25)"/>
</svg>
)
