import React, { useEffect, useMemo, useState } from 'react';
// import MdAdd from 'react-icons/lib/md/add';
import { MdAdd } from '../../Common/Icons';
import { AsyncPaginate } from 'react-select-async-paginate';
import { matchSorter } from 'match-sorter';
import { imagePathS3, restRequest } from '../../Helpers';
import { getFilteredItem } from '../../Helpers';

const addNew = {
  value: 'createNew',
  label: (
    <div className="DDTopElement">
      <MdAdd /> &nbsp; Add New
    </div>
  ),
  className: 'add-new-option',
};

const prepareOptionWithImage = (options) => {
  let modiFiedOptions = options.map((option) => {
    const image = typeof option.images !== 'undefined' ? option.images[0] : '';
    return {
      ...option,
      label: (
        <div className="option--with-image">
          <div className="image">
            <img
              src={imagePathS3(image)}
              role="presentation"
              alt="presentation"
            />
          </div>
          <div className="desc">
            {option?.label}
            <span className="small-td-info">{option?.sku}</span>
          </div>
        </div>
      ),
    };
  });
  return modiFiedOptions;
};

const noOptionFound = {
  value: '0',
  label: 'No Option Found',
  isdisabled: true,
  className: 'noOptionFoundPagination',
};
const SelectDynamicPaginationField = ({
  value = null,
  editCust = {},
  handleChange = () => {},
  error = '',
  disabled = false,
  placeholder = 'Select',
  clearable = true,
  onBlur = () => {},
  onKeyDown = () => {},
  withImage = false,
  autoFocus = false,
  api = '',
  newCust = {},
  addButton = false,
  rowid = 0,
  renderLoadOption = false,
  from = '',
  selecteditem = [],
}) => {
  const [customError] = useState(false);
  const [customerlist, setCustomerList] = useState([]);
  const [customValue, setCustomValue] = useState(null);
  const [count, setCount] = useState(0);
  const [blockEnter, setBlockenter] = useState(false);
  useEffect(() => {
    if (newCust.value) setCustomerList([...customerlist, newCust]);
  }, [newCust]);

  useEffect(() => {
    if (value === null) setCount(1);
  }, [value]);

  useEffect(() => {
    if (from === 'adjustment') {
      if (renderLoadOption) setCount(1);
      else setCount(0);
    }
  }, [renderLoadOption]);

  useEffect(() => {
    if (editCust.value) setCustomerList([...customerlist, editCust]);
  }, [editCust]);

  const notselectedItem = (list) => {
    if (selecteditem.length) {
      for (let i = 0; i < selecteditem.length; i++) {
        list = list.filter((data) => {
          return data.id != selecteditem[i];
        });
      }
    }
    return list;
  };

  const loadOptions = async (search, loadedOptions, { page }) => {
    const responsejson = [];
    let response = [];

    if (search.length >= process.env.REACT_APP_SEARCH_VARIABLE) {
      response = await restRequest(
        'get',
        `${api}?page=${page}&search=${search.replace('&', '%26')}`
      );
      if (response?.data?.length > 0) {
        var item = getFilteredItem(search, notselectedItem(response.data));
        if (item && item.length === 1) {
          handleChange(item[0], rowid);
        }
      }
    }
    if (!search) {
      response = await restRequest('get', `${api}?page=${page}`);
    }
    response.data = notselectedItem(response.data);
    let options = withImage ? prepareOptionWithImage(response.data) : null;
    if (response.data.length === 0) {
      addButton
        ? responsejson.push(addNew, noOptionFound)
        : responsejson.push(noOptionFound);
    } else {
      if (page === 1) {
        withImage
          ? addButton
            ? responsejson.push(addNew, ...options)
            : responsejson.push(...options)
          : addButton
          ? responsejson.push(addNew, ...response.data)
          : responsejson.push(...response.data);
      } else {
        withImage
          ? responsejson.push(...options)
          : responsejson.push(...response.data);
      }
    }

    setCustomerList([...customerlist, ...responsejson]);
    let hasMore = response.current_page < response.last_page;
    return {
      options: responsejson,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const onInputChange = async (val, list = [], from = '') => {
    if (val.length >= 3) setCount(1);
    else setCount(0);
    setBlockenter(val.length >= 7 ? true : false);
    // var item =
    //   from === 'loadoption'
    //     ? getFilteredItem(val, list)
    //     : getFilteredItem(val, customerlist);
    // if (item && item.length === 1) {
    //   handleChange(item[0], rowid);
    // }
  };

  useMemo(() => {
    let findItem = customerlist.find((item) => item.value === value);
    findItem =
      findItem && 'value' in findItem
        ? { ...findItem, label: findItem.label, value: findItem.value }
        : null;
    setCustomValue(findItem);
  }, [value, customerlist]);

  return (
    <>
      <div
        className={`${
          !disabled && customError && !error && !value === true
            ? 'input-error-field asyn-paginate-custom'
            : 'asyn-paginate-custom'
        } ${error ? 'fieldError' : ''}`}
      >
        <AsyncPaginate
          cacheUniqs={[count]}
          editCust={editCust}
          newCust={newCust}
          value={customValue}
          loadOptions={loadOptions}
          onChange={(option) => {
            handleChange(option);
          }}
          isClearable={clearable}
          withImage={withImage}
          isOptionDisabled={(option) => option.isdisabled}
          className="asyncPaginateParent"
          isDisabled={disabled}
          onInputChange={onInputChange}
          placeholder={placeholder}
          onKeyDown={(e) =>
            e.key === 'Enter' && blockEnter
              ? (e.preventDefault(),
                setTimeout(() => {
                  setBlockenter(false);
                }, 10))
              : null
          }
          autoFocus={autoFocus}
          onBlur={onBlur}
          isLoading={false}
          addButton={addButton}
          noOptionsMessage={() => null}
          additional={{
            page: 1,
          }}
        />
        {!disabled && customError && !error && !value && (
          <div className="field-error">Required</div>
        )}
      </div>
    </>
  );
};

export default SelectDynamicPaginationField;
