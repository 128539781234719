import React, { Component } from 'react';
import Dropdown from '../Dropdown/CustomDropdown';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import './SectionHeader.css';

class SectionHeader extends Component {
  render() {
    const {
      filterOptions,
      sortOptions,
      imExOptions,
      currentFilter,
      sortFilter,
      // orderBy
    } = this.props;

    return (
      <div className="section-header ">
        <span className="float-left">
          {filterOptions && (
            <span className="section-header--dropdown float-left">
              {/* <span className="section-header--dropdown-title">Filter:</span> */}
              <Dropdown
                textLabel={
                  filterOptions?.find((option) => option.id === currentFilter)
                    ?.label
                }
                options={filterOptions}
              />
            </span>
          )}
          {sortOptions && (
            <HamburgerMenu
              options={sortOptions}
              selectedSort={sortOptions.find(
                (option) => option.id === sortFilter
              )}
              imExOptions={imExOptions && imExOptions}
            />
          )}
          {/* { 
            sortOptions &&
            <span className="section-header--dropdown">
              <span className="section-header--dropdown-title">Sort:</span>
              <Dropdown
                textLabel={sortOptions.find(option => option.id === sortFilter).label}
                selectedSort={sortOptions.find(option => option.id === sortFilter)}
                orderBy={orderBy}
                options={sortOptions}
              />
            </span>
          }
          {
            imExOptions &&
            <span className="section-header--dropdown">
              {
                window.location.pathname.includes('salesorders') ?
                <Dropdown iconWithinIcon textLabel="Export" options={imExOptions} /> :
                  <Dropdown iconWithinIcon textLabel="Import/Export" options={imExOptions} />
              }

            </span>
          } */}
        </span>
      </div>
    );
  }
}

export default SectionHeader;
