import React, { Component } from 'react';
import { restRequest } from '../../../../../Helpers/RequestHelper';
import Loader from './../../../../../Components/Loader/Loader';
import TransactionsTable from './TransactionsTable';
import SubTabs from '../../../../../Components/Tabs/SubTabs';
import './ContactTransaction.css';
import EmptyTable from '../../../../../Components/ListTable/EmptyTable';
import TabsTable from '../../../../../Components/TabsTable/TabsTable';
import { checkError } from '../../../../../Helpers/AuthHelper';

class ContactTransactionTab extends Component {
  transactionTable = {
    sales_orders: {
      tableHeaders: [
        'Sales Order No.',
        'Date',
        'Reference',
        'Amount',
        'Status',
      ],
      tableData: [
        'sales_order_no',
        'order_date',
        'reference',
        'total',
        'status',
      ],
      rightAlignColumns: [4],
      linkTo: { path: '/salesorders/', linkedColumn: 'sales_order_no' },
    },
    invoices: {
      tableHeaders: ['Date', 'Invoice No.', 'Payment Due', 'Amount', 'Status'],
      tableData: [
        'invoice_date',
        'invoice_no',
        'payment_due',
        'total',
        'status',
      ],
      rightAlignColumns: [3, 4],
      linkTo: { path: '/invoices/', linkedColumn: 'invoice_no' },
    },
    packages: {
      tableHeaders: ['Slip No.', 'Date', 'Status'],
      tableData: ['package_no', 'package_date', 'status'],
      linkTo: { path: '/packages/', linkedColumn: 'package_no' },
    },
    payments_received: {
      tableHeaders: ['Date', 'Payment No', 'Invoice No', 'Reference', 'Amount', 'Type'],
      tableData: [
        'payment_date',
        'payment_no',
        'invoice_no',
        'reference',
        'amount',
        'payment_mode',
        ''
      ],
      rightAlignColumns: [3],
    },
    purchase_orders: {
      tableHeaders: [
        'Purchase Order No.',
        'Date',
        'Reference',
        'Amount',
        'Status',
      ],
      tableData: [
        'purchase_order_no',
        'order_date',
        'reference',
        'total',
        'status',
      ],
      rightAlignColumns: [4],
      linkTo: {
        path: '/purchaseorders/',
        linkedColumn: 'purchase_order_no',
      },
    },
    purchase_receives: {
      tableHeaders: ['Receive No.', 'Purchase Order No.', 'Date', 'Quantity'],
      tableData: [
        'receive_no',
        'purchase_order_no',
        'receive_date',
        'received_quantity',
      ],
      rightAlignColumns: [4],
    },
    bills: {
      tableHeaders: ['Bill No.', 'Date', 'Due', 'Amount', 'Status'],
      tableData: ['bill_no', 'bill_date', 'balance_due', 'total', 'status'],
      rightAlignColumns: [3, 4],
      linkTo: { path: '/bills/', linkedColumn: 'bill_no' },
    },
    payments_made: {
      tableHeaders: ['Date', 'Reference', 'Amount', 'Type'],
      tableData: ['payment_date', 'reference', 'amount', 'payment_mode'],
      rightAlignColumns: [3],
    },
    sales_return: {
      tableHeaders: [
        'Sales Return No.',
        'Date',
        'Add to stock',
        'Return Quantity',
        'Status',
      ],
      tableData: [
        'sales_return_no',
        'sales_return_date',
        'back_stock',
        'return_quantity',
        'status',
      ],
      rightAlignColumns: [0],
      linkTo: { path: '/salesreturns/', linkedColumn: 'sales_return_no' },
    },
    credit_notes: {
      tableHeaders: [
        'Credit Note No.',
        'Date',
        'Invoice No.',
        'Credit',
        'Status',
      ],
      tableData: [
        'credit_note_no',
        'credit_note_date',
        'invoice_no',
        'issued_credits',
        'status',
      ],
      rightAlignColumns: [0],
      linkTo: { path: '/creditnotes/', linkedColumn: 'credit_note_no' },
    },
  };

  filterOptions = []; // options available for current contact depending on contact type
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingList: true,
      activeTab: '',
      list: [],
      currency: {},
      currentTab:
        this.props.contactType === 'vendor' ? 'purchaseOrders' : 'salesOrder',
    };
  }

  componentDidMount() {
    this.fetchData(
      this.props.contactType === 'vendor' ? 'purchase_orders' : 'sales_orders'
    );
    this.setState({ loading: true });
  }
  fetchData(value) {
    this.setState({ loading: true });
    restRequest(
      'get',
      `contacts/${this.props.contactId}/transaction?filter=${value}`
    )
      .then((res) => {
        this.setState({
          loading: false,
          data:
            value === 'payments_received'
              ? res.transactions.data
              : res.transactions,
          currency: res.base_currency,
        });
        this.renderTableData(value);
      })
      .catch((err) => {
        this.setState({ loading: false });
        checkError(err);
      });
  }

  getNewPath(path, value) {
    var url = `?filter=all&sort=created_at&page=1&orderBy=desc&view=20&contactId=${this.props.contactId}`;
    if (
      (path && path.includes('salesorders')) ||
      value.includes('sales_orders')
    ) {
      return `/salesorders${url}`;
    } else if (
      (path && path.includes('invoices')) ||
      value.includes('invoices')
    ) {
      return `/invoices${url}`;
    } else if (
      (path && path.includes('packages')) ||
      value.includes('packages')
    ) {
      return `/packages${url}`;
    } else if (
      (path && path.includes('paymentsmade')) ||
      value.includes('payments_received')
    ) {
      return `/paymentsmade${url}`;
    } else if (
      (path && path.includes('salesreturns')) ||
      value.includes('sales_return')
    ) {
      return `/salesreturns${url}`;
    } else if (
      (path && path.includes('creditnotes')) ||
      value.includes('credit_notes')
    ) {
      return `/creditnotes${url}`;
    } else if (
      (path && path.includes('purchaseorders')) ||
      value.includes('purchase_orders')
    ) {
      return `/purchaseorders${url}`;
    } else if ((path && path.includes('bills')) || value.includes('bills')) {
      return `/bills${url}`;
    } else {
      return null;
    }
  }
  concatCurrencySymbol = (data, currency, value) => {
    if (value === 'purchase_orders') {
      return data.map((item) => {
        return {
          id: item.id,
          purchase_order_no: item.purchase_order_no,
          order_date: item.order_date,
          reference: item.reference,
          total: currency.symbol + parseFloat(item.total)?.toFixed(2),
          status: item.status,
        };
      });
    } else if (value === 'bills') {
      return data.map((item) => {
        return {
          id: item.id,
          bill_no: item.bill_no,
          bill_date: item.bill_date,
          balance_due:
            currency.symbol + parseFloat(item.balance_due).toFixed(2),
          total: currency.symbol + parseFloat(item.total)?.toFixed(2),
          status: item.status,
        };
      });
    } else if (value === 'payments_made') {
      return data.map((item) => {
        return {
          id: item.id,
          payment_date: item.payment_date,
          reference: item.reference,
          amount: currency.symbol + parseFloat(item.amount)?.toFixed(2),
          payment_mode: item.payment_mode,
        };
      });
    } else if (value === 'sales_orders') {
      return data.map((item) => {
        return {
          id: item.id,
          sales_order_no: item.sales_order_no,
          order_date: item.order_date,
          reference: item.reference,
          total: currency.symbol + parseFloat(item.total)?.toFixed(2),
          status: item.status,
        };
      });
    } else if (value === 'invoices') {
      return data.map((item) => {
        return {
          id: item.id,
          invoice_date: item.invoice_date,
          invoice_no: item.invoice_no,
          payment_due:
            currency.symbol + parseFloat(item.payment_due)?.toFixed(2),
          total: currency.symbol + parseFloat(item.total)?.toFixed(2),
          status: item.status,
        };
      });
    } else if (value === 'payments_received') {
      return data.map((item) => {
        return {
          id: item.invoice_id,
          payment_no: item.payment_no,
          payment_date: item.payment_date,
          reference: item.reference,
          amount: currency.symbol + parseFloat(item.payment_made)?.toFixed(2),
          payment_mode: item.payment_mode,
          invoice_no: item.invoice_no
        };
      });
    } else if (value === 'credit_notes') {
      return data.map((item) => {
        return {
          id: item.id,
          credit_note_no: item.credit_note_no,
          credit_note_date: item.credit_note_date,
          invoice_no: item.invoice_no,
          issued_credits:
            currency.symbol + parseFloat(item.issued_credits)?.toFixed(2),
          status: item.status,
        };
      });
    } else {
      return this.state.data;
    }
  };
  renderTableData = (value, title, path) => {
    let newPath = this.getNewPath(path, value);
    const { data, currency } = this.state;
    const dataAfterConcatination = this.concatCurrencySymbol(
      data,
      currency,
      value
    );
    return (
      <div
        className={`${value === 'purchase_receives' ? 'contact_purchase_receives' : ''
          }`}
      >
        <TabsTable
          value={value}
          data={dataAfterConcatination}
          loading={this.state.loading}
          contactId={this.props.contactId}
          inModal={this.props.inModal}
        />
        {
          //displaying view all button on basis of length of data also if title of tab if missing then no button dispaly.
          !this.props.inModal && this.state.data.length > 0 && title && (
            <button
              className="next-btn transaction transaction_view_btn"
              onClick={() => this.props.history.push(newPath)}
            >
              View all {title}
            </button>
          )
        }
      </div>
    );
  };

  renderTransactions(tab) {
    if (this.state.loadingList) {
      return <Loader />;
    }
    if (this.state.list.length === 0) {
      return <EmptyTable />;
    }
    return (
      <TransactionsTable
        list={this.state.list}
        tableHeaders={this.transactionTable[tab].tableHeaders}
        tableData={this.transactionTable[tab].tableData}
        rightAlignColumns={this.transactionTable[tab].rightAlignColumns}
        linkTo={this.transactionTable[tab].linkTo}
        className="list-table"
        currencySymbol={this.state.currency.symbol}
      />
    );
  }

  handleTabChange(tab, val = '') {
    this.fetchData(val);
    this.setState({ currentTab: tab });
  }

  tabsForCustomers = [
    //sending two more parameters in renderContent func second is title of button and third is path
    ///if title missing button will not be dispalyed
    {
      id: 'salesOrder',
      label: 'Sales Orders',
      onClick: () => {
        this.handleTabChange('salesOrder', 'sales_orders');
      },
      renderContent: () =>
        this.renderTableData('sales_orders', 'Sales Order', 'salesorders'),
    },
    {
      id: 'invoice',
      label: 'Invoices',
      onClick: () => {
        this.handleTabChange('invoice', 'invoices');
      },
      renderContent: () =>
        this.renderTableData('invoices', 'Invoices', 'invoices'),
    },
    {
      id: 'package',
      label: 'Packages',
      onClick: () => {
        this.handleTabChange('package', 'packages');
      },
      renderContent: () =>
        this.renderTableData('packages', 'Packages', 'packages'),
    },
    {
      id: 'paymentsReceived',
      label: 'Payments Received',
      onClick: () => {
        this.handleTabChange('paymentsReceived', 'payments_received');
      },
      renderContent: () =>
        this.renderTableData(
          'payments_received',
          'Payments Received',
          'paymentsmade'
        ),
    },
    {
      id: 'salesReturn',
      label: 'Sales Return',
      onClick: () => {
        this.handleTabChange('salesReturn', 'sales_return');
      },
      renderContent: () =>
        this.renderTableData('sales_return', 'Sales Return', 'salesreturns'),
    },
    {
      id: 'creditNotes',
      label: 'Credit Notes',
      onClick: () => {
        this.handleTabChange('creditNotes', 'credit_notes');
      },
      renderContent: () =>
        this.renderTableData('credit_notes', 'Credit Notes', 'creditnotes'),
    },
  ];

  tabsForVendors = [
    {
      id: 'purchaseOrders',
      label: 'Purchase Orders',
      onClick: () => {
        this.handleTabChange('purchaseOrders', 'purchase_orders');
      },
      renderContent: () =>
        this.renderTableData(
          'purchase_orders',
          'Purchase Orders',
          'purchaseorders'
        ),
    },
    {
      id: 'purchaseReceives',
      label: 'Purchase Receives',
      onClick: () => {
        this.handleTabChange('purchaseReceives', 'purchase_receives');
      },
      renderContent: () => this.renderTableData('purchase_receives'),
    },

    {
      id: 'bills',
      label: 'Bills ',
      onClick: () => {
        this.handleTabChange('bills', 'bills');
      },
      renderContent: () => this.renderTableData('bills', 'Bills', 'bills'),
    },
    {
      id: 'paymentsMade',
      label: 'Payments Made',
      onClick: () => {
        this.handleTabChange('paymentsMade', 'payments_made');
      },
      renderContent: () => this.renderTableData('payments_made'),
    },
  ];
  render() {
    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <SubTabs
        tabs={
          this.props.contactType === 'vendor'
            ? this.tabsForVendors
            : this.tabsForCustomers
        }
        currentTab={this.state.currentTab}
      />
    );
  }
}

export default ContactTransactionTab;
