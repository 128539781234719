import React, { Component } from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { restRequest } from '../../Helpers/RequestHelper';
// import { getCurrency } from '../../Helpers/CurrentSession';
// import { getDiscountOptions } from '../../Constants';
import { required, ValidateDate, ValidateMax50 } from '../../Helpers/Validator';
import Switch from '../../Components/Switch/Switch';
import Field from '../../Components/Form/Field/Field';
import Form from '../../Components/Form/Form';
import FormAction from '../../Components/Form/FormAction';
import Quantity from '../../Components/Fragments/Quantity/Quantity';
import { formatNumber } from '../../Helpers/FormatNumber';
import ListTableImageColumn from '../../Components/ListTable/ListTableImageColumn';
import getOverallTaxDetails from '../../Helpers/GetOverallTaxDetails';
import './ConvertToBill.css';
import { getSubtotal } from '../../Components/Form/BillForm/BillFormEnhancer';
import CustomModal from '../../Components/CustomModal/CustomModal';
import { checkError } from '../../Helpers/AuthHelper';
import { getOrganizationDate } from '../../Helpers/Date/OrganizationDate';
import { confirmationAlert, GetDateString } from '../../Helpers';
import DragDropItems from '../../Common/DragDrop';
import { Draggable } from 'react-beautiful-dnd';
import { MdDragIndicator } from '../../Common/Icons';
import { Loader } from '../../Components/index';

class CreateReceive extends Component {
  receiveId = this.props.receiveId;
  unbilledItems = this.props.unbilledItems;

  state = {
    loading: true,
    bill: {
      billNo: '',
      vendorName: '',
      orderNo: '',
      totalAmount: 0,
      tax_amount: 0,
      billDate: moment(getOrganizationDate()),
      dueDate: moment(getOrganizationDate()),
      transactionDiscountType: 'amount',
      transactionDiscount: 0,
      adjustment: 0,
      items: [],
    },
    currency: [],
    currencySymbol: '',
    isSubmitted: false,
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.fetchData();
    // .then(() => this.setState({ loading: false }));
    this.props.handleprompt();
  }

  fetchData = async () => {
    this.setState({ loading: true });

    await restRequest('get', `receives/${this.receiveId}/converttobill`)
      .then((res) => {
        this.setState(
          {
            bill: {
              ...this.state.bill,
              billNo: res.bill_no,
              vendorName: res.purchase_order.vendor.display_name,
              orderNo: res.order_no,
              receive_date: res.receive_date ? moment(res.receive_date) : null,
              billDate:
                res.receive_date &&
                  moment(res.receive_date) > moment(getOrganizationDate())
                  ? moment(res.receive_date)
                  : moment(getOrganizationDate()),
              dueDate:
                res.receive_date &&
                  moment(res.receive_date) > moment(getOrganizationDate())
                  ? moment(res.receive_date)
                  : moment(getOrganizationDate()),
              totalAmount: res.total,
              tax_amount: res.tax_amount,
              transactionDiscountType: res.transactionDiscountType,
              transactionDiscount: res.transactionDiscount,
              adjustment: res.adjustment,
              items: res.receive_items.map((receiveItem) => ({
                id: receiveItem.item_id,
                name: receiveItem.item_name,
                dragDropID: GetDateString(),
                sku: receiveItem.item.sku,
                unit: receiveItem.item.unit,
                images: receiveItem.item.images,
                quantity: receiveItem.quantity,
                totalQuantity: receiveItem.purchase_order_item_detail.quantity,

                totalAmount: receiveItem.purchase_order_item_detail.amount,
                unitPrice: receiveItem.purchase_order_item_detail.rate,
                account: receiveItem.purchase_order_item_detail.account.title,
                warehouse: receiveItem.warehouse.name,
                tax_name: receiveItem.purchase_order_item_detail.tax_name,
                tax_rate: receiveItem.purchase_order_item_detail.tax_rate,
                tax: receiveItem.purchase_order_item_detail.tax,

                // tax:receiveItem.
              })),
            },
            currency: [
              {
                value: 'amount',
                label: res?.currency?.symbol,
              },
              {
                value: 'percent',
                label: '%',
              },
            ],
            currencySymbol: res?.currency?.symbol,
          },
          () => {
            this.setState({ loading: false });
          }
        );
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
    //console.log(this.state)
  };

  handleSubmit = (values, setSubmitting) => {
    restRequest('post', `receives/${this.receiveId}/converttobill`, {
      bill_no: values.billNo,
      order_no: values.orderNo,
      due_date: values.dueDate && values.dueDate.format('YYYY-MM-DD 23:59:59'),
      bill_date: values.billDate && values.billDate.format('YYYY-MM-DD 00:00:00'),
      discount_type: values.transactionDiscountType,
      discount_transaction_level: values.transactionDiscount,
      adjustment: values.adjustment,
    })
      .then(() => {
        this.props.onSubmit();
        this.props.handleToast('Converted to bill successfully', 'success');
      })
      .catch((error) => {
        setSubmitting(false);
        //this.props.handleToast(error, "error")
        checkError(error, this.props.handleToast);
      });
  };

  renderFooter = (
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    currency,
    currencySymbol
  ) => {
    return (
      // <div className="table--summary" style={{maxWidth:'35rem'}}>
      <div className="float-left mt_3 summary_table" style={{ width: '97%' }}>
        <div className="float-right w-40 p_10 pr-0">
          <div className="float-left w-100">
            <div className="float-left w-100 so-mg-1">
              <span className="float-left">Sub Total </span>
              <span className="float-right bold">{`${currencySymbol}${parseFloat(
                getSubtotal(values.items)
              ).toFixed(2)}`}</span>
            </div>
            <div className="float-left w-100">
              {this.renderTotalTax(values.items, currencySymbol)}
            </div>
            <span className="float-left pr">Adjustment</span>
            <Field
              size="xs"
              className="float-right bold discount_switch_summary_table"
              type="number"
              // className="inline-field"
              id="adjustment"
              allowNegatives
              value={parseFloat(values.adjustment).toFixed(2)}
              prefix={currencySymbol}
              decimalScale={2}
              onValueChange={(adjustmentValue) => {
                let adjustment = adjustmentValue.floatValue;
                if (adjustment > 0) {
                  // Price_Quantity_Limit

                  // price and quantity limit from 5 digits to 10 digits
                  let adjustmentString = adjustment.toString();
                  if (adjustmentString.length > 10) {
                    adjustmentString = adjustmentString.slice(0, 10);
                  }
                  adjustment = parseFloat(adjustmentString);
                }
                setFieldValue('adjustment', adjustment);
              }}
              placeholder={`${currencySymbol}0`}
              allowNegative
              thousandSeparator=","
              removeValueOnClick
            />
          </div>

          <div className="float-left w-100">
            <span className="float-left pr">Discount</span>
            <Field
              type="number"
              size="xs"
              className="float-right bold discount_switch_summary_table"
              id="transactionDiscount"
              // value={values.transactionDiscount}
              value={parseFloat(values.transactionDiscount).toFixed(2)}
              decimalScale={2}
              onValueChange={(values) =>
                setFieldValue('transactionDiscount', values.floatValue)
              }
              removeValueOnClick
              rightData={
                // <Field
                //   size="xs"
                //   type="dropdown"
                //   className='discount--dropdown'
                //   value={values.transactionDiscountType}
                //   options={getDiscountOptions(currency)}
                //   searchable={false}
                //   dropdownValue="value"
                //   disabled
                //   dropdownLabel="label"
                //   handleChange={option => setFieldValue('transactionDiscountType', option.value)}
                //   clearable={false}
                // />
                <Switch
                  options={currency}
                  value={values.transactionDiscountType}
                  handleChange={(value) => {
                    setFieldValue('transactionDiscountType', value.value);
                  }}
                />
              }
            />
          </div>
        </div>

        {/* <hr className="margin" /> */}
        <hr className="margin float-left w-100" />
        <div className="float-left w-100">
          <div className="float-left w-60 p_10"></div>
          <div className="baseline float-left w-40 px_10">
            <span className="semi-bold float-left pr_total">Total</span>
            <span className="total-price--value float-right mr-1">
              {currencySymbol}
              {this.getOrderTotal(values, values.totalAmount)}
            </span>
          </div>
        </div>
        <hr className="margin float-left w-100" />
      </div>
    );
  };
  renderTotalTax = (items, currencySymbol) => {
    let overallTax = getOverallTaxDetails(items);
    return overallTax.map((tax) => (
      <div key={tax.id} className="float-left w-100 so-mg-1">
        <span>{`${tax.name} (${tax.rate}%)`}</span>
        <span className="float-right bold">{`${currencySymbol}${formatNumber(
          tax.total
        )}`}</span>
      </div>
    ));
  };

  handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const { items } = this.state.bill;

    const newitems = Array.from(items);
    const [reorderedItem] = newitems.splice(result.source.index, 1);
    newitems.splice(result.destination.index, 0, reorderedItem);
    this.setState({
      bill: {
        ...this.state.bill,
        items: newitems,
      },
    });
  };

  renderUnbilledItems() {
    const { items } = this.state.bill;
    return (
      <div className="so-tbletabs">
        <table className="list-table form-table border  create_sales_order margin-top-sm convert-to-bill-popup">
          <thead>
            <tr className="table--row">
              <th>Item</th>
              <th>Account</th>
              <th>Warehouse</th>
              <th>Qty. To Bill</th>
              <th>Total Qty.</th>
              {/* <th >Received Quantity</th> */}
              {/* <th>Price</th> */}
              <th>Tax</th>
              <th style={{ textAlign: 'right' }}>Amount</th>
            </tr>
          </thead>
          {/* <tbody> */}

          <DragDropItems handleOnDragEnd={this.handleOnDragEnd}>
            {items.map((item, index) => {
              return (
                <Draggable
                  key={item.dragDropID}
                  draggableId={item.dragDropID}
                  index={index}
                >
                  {(provided) => (
                    <tr
                      className="table--row"
                      key={index}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <td>
                        <div className="__dragDrop">
                          <div
                            {...provided.dragHandleProps}
                            className={
                              items.length >= 2
                                ? '__dragDrop_iconParent'
                                : '__dragDrop_iconParent notDisplay'
                            }
                            tabIndex="-1"
                          >
                            <MdDragIndicator />
                          </div>
                          <div className="__drag-righ">
                            <ListTableImageColumn
                              name={item.name}
                              sku={item.sku}
                              images={item.images}
                            />
                          </div>
                        </div>
                      </td>
                      <td>{item.account}</td>
                      <td>{item.warehouse}</td>
                      {/* <td >
                  <Quantity quantity={item.quantity} unit={item.unit} />
                </td> */}
                      <td>
                        <Quantity quantity={item.quantity} unit={item.unit} />
                      </td>
                      <td>
                        <Quantity
                          quantity={item.totalQuantity}
                          unit={item.unit}
                        />
                      </td>
                      {/* <td>
              {item.unitPrice}
              </td> */}
                      <td>{item.tax_name}</td>
                      <td style={{ textAlign: 'right' }}>
                        {/* {item.totalAmount} */}
                        {this.state.currencySymbol
                          ? this.state.currencySymbol
                          : '$'}
                        {parseFloat(item.unitPrice).toFixed(2)}
                      </td>
                    </tr>
                  )}
                </Draggable>
              );
            })}
          </DragDropItems>

          {/* </tbody> */}
        </table>
      </div>
    );
  }

  getOrderTotal(values) {
    var amount = 0;

    var adjustment =
      typeof values.adjustment !== 'undefined' ? values.adjustment : 0;
    if (values.transactionDiscount > 0) {
      //console.log('inside if 202')
      if (values.transactionDiscountType === 'percent') {
        //console.log('inside if 204')
        amount = (
          values.totalAmount -
          (values.transactionDiscount * values.totalAmount) / 100 +
          +adjustment
        ).toFixed(2);
      } else if (values.transactionDiscountType === 'amount') {
        //console.log('inside else if 208')
        amount = (
          values.totalAmount -
          values.transactionDiscount +
          +adjustment
        ).toFixed(2);
      } else {
        //console.log('inside 211')
        amount =
          values.totalAmount -
          (values.transactionDiscount - values.totalAmount) +
          +adjustment;
      }
    } else {
      //console.log('inside else 214')
      amount = +adjustment + values.totalAmount;
    }
    var FinalAmount;
    //if(values.tax_amount > 0){
    FinalAmount = parseFloat(amount) + parseFloat(values.tax_amount);
    //}
    //console.log('amount', FinalAmount)
    return FinalAmount ? parseFloat(FinalAmount).toFixed(2) : 0;
  }

  showZeroAmountConfirmModal(handleSubmit, values, setFieldValue) {
    return (
      <CustomModal
        style={{ marginTop: '5%' }}
        showModal={values.showModalForZeroValue}
        title="Confirm Bill"
        renderActions={false}
        onClose={() => setFieldValue('showModalForZeroValue', false)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <p>Your total bill amount is Zero. Please confirm to continue.</p>

          <button
            type="button"
            className="action-buttons--submit next-btn"
            onClick={() => handleSubmit()}
          >
            Confirm
          </button>
        </div>
      </CustomModal>
    );
  }

  renderReceiveForm() {
    let { bill, currency, currencySymbol } = this.state;
    return (
      <Formik
        enableReinitialize
        initialValues={{ ...bill }}
        validationSchema={() =>
          Yup.object().shape({
            billNo: required(ValidateMax50),
            billDate: required(ValidateDate),
            dueDate: required(ValidateDate),
          })
        }
        onSubmit={(values, { setSubmitting }) => {
          this.handleSubmit(values, setSubmitting);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
        }) => (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              if (this.getOrderTotal(values) <= 0) {
                setFieldValue('showModalForZeroValue', true);
              } else handleSubmit();
            }}
          >
            {this.showZeroAmountConfirmModal(
              handleSubmit,
              values,
              setFieldValue
            )}
            <div className='form-fields-container d-flex flex-wrap'>
              <Field
                className="flex-25"
                name="Bill No."
                id="billNo"
                value={values.billNo}
                required
                handleChange={handleChange}
                onBlur={handleBlur}
                error={touched.billNo && errors.billNo}
              />
              <Field

                name="Vendor Name"
                className="flex-25"
                id="vendorName"
                value={values.vendorName}
                required
                disabled
                handleChange={handleChange}
                onBlur={handleBlur}
                error={touched.vendorName && errors.vendorName}
              />
              <Field

                name="Purchase Order No."
                className="flex-25"
                id="orderNo"
                value={values.orderNo}
                handleChange={handleChange}
              />
              <Field

                type="date"
                name="Bill Date"
                className="flex-25 date-canalder-g"
                id="billDate"
                value={values.billDate}
                minDate={values.receive_date}
                handleChange={(date) => {
                  if (!date) return;
                  setFieldValue('billDate', date);
                  if (values.dueDate < date) {
                    setFieldValue('dueDate', date);
                  }
                }}
                required
                onBlur={() => setFieldTouched('billDate', true)}
                error={touched.billDate && errors.billDate}
              />

              <Field
                size="large"
                type="date"
                name="Due Date"
                className="flex-25"
                id="dueDate"
                value={values.dueDate}
                minDate={values.billDate}
                handleChange={(date) => setFieldValue('dueDate', date)}
                required
                onBlur={() => setFieldTouched('dueDate', true)}
                error={touched.dueDate && errors.dueDate}
              />
            </div>
            <div className="form-field form-table--container">
              <label className="form-label">Select Items</label>
              {this.state.loading ? <Loader /> : this.renderUnbilledItems()}
            </div>
            {this.renderFooter(
              values,
              errors,
              touched,
              setFieldValue,
              handleBlur,
              currency,
              currencySymbol
            )}
            <FormAction
              marginFromRight={1.5}
              disabled={isSubmitting}
              // onCancel={this.props.onClose}
              onCancel={() => {
                let response;
                if (this.props.showPrompt) response = confirmationAlert();
                if (response || !this.props.showPrompt) this.props.onClose();
              }}
            />
          </Form>
        )}
      />
    );
  }

  render() {
    return this.renderReceiveForm();
  }
}

export default CreateReceive;
