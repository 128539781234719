import React, { Component } from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';

import { restRequest } from '../../Helpers/RequestHelper';
import * as Yup from 'yup';
import Form from '../../Components/Form/Form';
import Field from '../../Components/Form/Field/Field';

import AuthContainer from '../AuthContainer';
import { ssoSignUpPath } from '../../Helpers/UrlHelper';

import './Signup.css';
import { setObjInLS } from '../../Helpers/LocalStorage';
import { ValidateMaxOnlyAlpha25 } from '../../Helpers/Validator';
import { Loader } from '../../Components';

const sso_connected =
  process.env.REACT_APP_SSO_ENABLED === 'false' ? false : true;

class Signup extends Component {
  ssoSignUpLink = ssoSignUpPath();
  state = { type: 'password', loading: true };

  componentDidMount() {
    this.handleSettingSSO();
  }

  handleSettingSSO = () => {
    this.setState({
      loading: true,
    });
    /*restRequest('get', 'ssoSettings')
      .then((res) => {
        res?.sso_connected
          ? (window.location.href = this.ssoSignUpLink)
          : this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });*/
    if (sso_connected) {
      window.location.href = this.ssoSignUpLink;
    } else {
      this.setState({ loading: false });
    }
  };

  handleSubmit(values, setSubmitting) {
    restRequest('post', this.props.submitUrl || 'register', {
      name: values.name,
      email: values.email.toLowerCase(),
      password: values.password,
      password_confirmation: values.password,
      invitation_token: this.props?.token,
    })
      .then(() => {
        this.props.handleToast('Registered successfully', 'success');
        if (this.props.submitUrl) {
          this.props.history.push({
            pathname: '/login',
            state: {
              email: values.email,
              fromSignup: true,
              invited: false,
            },
          });
        } else {
          this.props.history.push({
            pathname: '/login',
            state: {
              email: values.email,
              fromSignup: true,
              invited: true,
            },
          });
        }
      })
      .catch((error) => {
        this.props.handleToast(error, 'error');
        setSubmitting(false);
      });
  }

  togglePasswordView = () => {
    this.setState({
      type: this.state.type === 'text' ? 'password' : 'text',
    });
  };

  renderErrorList(touched, errors) {
    return (
      <ul
        className={`passsword--errors position-static ${
          touched.password ? ' touched' : ''
        }`}
      >
        <li
          className={`${
            errors.password && errors.password.minMax ? 'error' : ''
          }`}
        >
          At least 8 characters
        </li>
        <li
          className={`${
            errors.password && errors.password.symbol ? 'error' : ''
          }`}
        >
          Contain atleast 1 symbol
        </li>
        <li
          className={`${
            errors.password && errors.password.number ? 'error' : ''
          }`}
        >
          Contain atleast 1 number
        </li>
        <li
          className={`${
            errors.password && errors.password.uppercaseLowercase ? 'error' : ''
          }`}
        >
          Combine uppercase and lower case letters
        </li>
      </ul>
    );
  }

  validators = (values) => {
    const errors = {};
    if (values.name.length > 100) {
      errors.name = 'The name may not be greater than 100 characters.';
    }

    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    } else if (values.email.length > 100) {
      errors.email = 'Should be no more than 100 characters.';
    }

    if (values.password.length < 8 || values.password.length > 250) {
      errors.password = {
        ...errors.password,
        minMax: true,
      };
    }

    if (!/[0-9]+/.test(values.password)) {
      errors.password = {
        ...errors.password,
        number: true,
      };
    }

    if (!/[-!$%^&*#@()_+|~=`{}[\]:";'<>?,./]+/.test(values.password)) {
      errors.password = {
        ...errors.password,
        symbol: true,
      };
      if (!values.password) {
        errors.required = ' ';
      }
    }

    if (!(/.*[a-z]/.test(values.password) && /.*[A-Z]/.test(values.password))) {
      errors.password = {
        ...errors.password,
        uppercaseLowercase: true,
      };
    }

    return errors;
  };

  renderFooter() {
    return (
      <div className="auth--message">
        <h5>Already have an account?</h5>
        <Link to="/login"> Sign In </Link>
      </div>
    );
  }

  renderSignupForm() {
    if (window.location.search) {
      setObjInLS('package_id', window.location.search.split('=')[1]);
    }
    return (
      <Formik
        initialValues={{
          name: this.props.name || '',
          email: this.props.email || '',
          password: '',
        }}
        validate={this.validators}
        validationSchema={() =>
          Yup.object().shape({
            name: ValidateMaxOnlyAlpha25,
          })
        }
        onSubmit={(values, { setSubmitting }) => {
          this.handleSubmit(values, setSubmitting);
        }}
        render={({
          values,
          errors,
          isSubmitting,
          touched,
          handleBlur,
          handleSubmit,
          handleChange,
        }) => {
          return (
            <AuthContainer
              title="First Time on SeeBiz IMS"
              subtitle="Create an Account?"
              terms="By clicking on 'Register', you are accepting our terms of usage and privacy policy."
              footer={this.renderFooter()}
            >
              <Form onSubmit={handleSubmit} className="auth--form">
                <Field
                  size="full"
                  value={values.name}
                  id="name"
                  autoFocus={true}
                  name="Name"
                  handleChange={handleChange}
                  error={errors.name}
                />
                <Field
                  size="full"
                  value={values.email}
                  id="email"
                  name="Email"
                  required
                  onBlur={handleBlur}
                  handleChange={handleChange}
                  error={touched.email && errors.email}
                />
                <Field
                  size="full"
                  value={values.password}
                  id="password"
                  name="Password"
                  required
                  type={this.state.type}
                  passwordToggleView={this.togglePasswordView}
                  onBlur={handleBlur}
                  handleChange={handleChange}
                  error={touched.password && errors.required}
                />
                {this.renderErrorList(touched, errors)}
                <button
                  className="button--full-width"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Register
                </button>
              </Form>
            </AuthContainer>
          );
        }}
      />
    );
  }

  render() {
    const { loading } = this.state;
    if (loading) return <Loader />;
    return this.renderSignupForm();
  }
}

export default Signup;
