import React, { useEffect, useState } from 'react';
import proptypes from 'prop-types';
import { MdClose } from '../../Common/Icons';

import './Menu.css';
import pageIdentify from '../../Helpers/PageIdentify';
import { setValueInLS } from '../../Helpers/LocalStorage';
import { getObjFromLS, setObjInLS } from '../../Helpers';
const Menu = ({ menus, currentMenu, history, inModal, className }) => {
  const [datePickerRef] = useState(React.createRef()); //, doo
  function isCurrent(id) {
    return id === currentMenu && 'active';
  }

  function renderTabContent() {
    let index = menus.findIndex((tab) => tab.id === currentMenu);

    if (index === -1) return <></>;
    return menus[index].renderContent();
  }

  function handleOutsideClick(event) {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target) &&
      sessionStorage.getItem('modal') !== 'true'
    ) {
      menus[0].onClick(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      className={`menu-component--menu menu_top ${className ? className : ''}`}
      ref={datePickerRef}
    >
      {' '}
      {/* change tab-component--tab to menu-component--menu */}
      <div className="tab-component--buttons border_bottom">
        <ul className="nav_menu">
          {' '}
          {/* change nav-tabs to nav-menus */}
          {menus.map((menu, index) => {
            let Icon = menu && menu.icon;
            let menuDisable = (menu && menu?.disable) || false;
            return (
              <li
                key={index}
                className={`${isCurrent(menu.id)} ${
                  menuDisable ? 'disableTab' : ''
                }`}
                onClick={() => {
                  if (menu.id === currentMenu) menu.onClick(false);
                  else menu.onClick();
                }}
              >
                <a>
                  {Icon && <Icon />}
                  {menu.label}
                </a>
              </li>
            );
          })}
        </ul>
        {!inModal && !pageIdentify(window.location.pathname) && (
          <div className="close_icon close-icon-pos">
            <MdClose
              onClick={() => {
                let dataFromLS = getObjFromLS('module');
                setObjInLS('module', {
                  ...dataFromLS,
                  presistDetailPage: false,
                });
                setValueInLS('fullscreen', 'true');
                sessionStorage.setItem('once', true);

                history.push('/r');
              }}
            />
          </div>
        )}
      </div>
      <div
        className={`menu_top_content box_shadow_custom ${
          !currentMenu ? 'd_none' : ''
        }  `}
      >
        <div
          id="printMe"
          className={`tab-component--content menu_top_inner_content ${className}`}
        >
          <div className="float-left w-100">{renderTabContent()}</div>
        </div>
      </div>
    </div>
  );
};

Menu.propTypes = {
  menus: proptypes.arrayOf(proptypes.object).isRequired,
  currentMenu: proptypes.string.isRequired,
};

export default Menu;
