export default function Decrypt(str) {
  let ostr;
  if (!str || str === 'undefined' || str === 'null') str = '';
  // str = str == "undefined" || str == "null" ? "" : str
  try {
    var key = 146;
    var pos = 0;
    ostr = '';
    while (pos < str.length) {
      ostr = ostr + String.fromCharCode(key ^ str.charCodeAt(pos));
      pos += 1;
    }

    return ostr;
  } catch (ex) {
    return '';
  }
}
