import React, { Component } from 'react';

import { restRequest } from '../../Helpers/RequestHelper';
import Loader from '../../Components/Loader/Loader'
import {checkError} from "../../Helpers/AuthHelper";


class PaymentStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading:false,
      status:'',
      selectedPeriod:'',
      token:'',
      ba_token:''
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  async fetchData() {
    await restRequest('get', 'payment/paymentStatus'+window.location.search).then(res => {
     if(res.status === 200){
       this.props.history.push('/paymentSuccess')
     }else{
       this.props.history.push('/paymentFailed')
     }
    }).catch(error =>{
      checkError(error)
      this.props.history.push('/paymentFailed')
    })

    this.setState({ loading: false })
  }



  render() {
    return <Loader />

  }


}


export default PaymentStatus;