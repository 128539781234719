import React from 'react'

import { PurchaseOrderIcon } from '../../../../Assets/Navigation/NavigationIcons'
import getDate from '../../../../Helpers/Date/GetDate'
import Table from '../../../../Components/Table/Table'
import { LinkStatusColumn } from '../../../../Components/ListTable/ListTableColumnJoin'

export default ({ dropshipments = [] }) => {
  const prepareList = (dropships) => {
    return dropships.map(dropship => {
      return {
        ...dropship,
        purchaseOrderNo: <LinkStatusColumn to={`/purchaseorders/${dropship.id}`} label={dropship.purchase_order_no} status={dropship.status} />,
        orderDate: getDate(dropship.order_date)
      }
    })
  }
  const tableListData = prepareList(dropshipments)
  const tableHeaders = []
  const tableData = ['purchaseOrderNo', 'orderDate']

  return(
    <Table
      list={tableListData}
      tableHeaders={tableHeaders}
      tableData={tableData}
      className="box-table"
      RowIcon={PurchaseOrderIcon}
    />
  )
}