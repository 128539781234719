import React from 'react';
import { MenuType, ActionMenu } from './';

const DesktopView = ({ menusList = [] }) => {
  return (
    <ActionMenu>
      {menusList.map((menu, index) => (
        <MenuType menu={menu} key={index} />
      ))}
    </ActionMenu>
  );
};
export default DesktopView;
