import React from 'react'

export default ({
  className = ''
}) => (

<svg className={className} xmlns="http://www.w3.org/2000/svg" width="16.366" height="20.003" viewBox="0 0 16.366 20.003">
  <g id="Icon_ionic-md-switch" data-name="Icon ionic-md-switch" transform="translate(-7.875 -5.625)">
    <path id="Path_137" data-name="Path 137" d="M11.057,13.353H16.45a4.546,4.546,0,1,0,0-6.365H11.057a3.182,3.182,0,1,0,0,6.365ZM10.1,9.211a1.351,1.351,0,0,1,.96-.4h4.3a4.528,4.528,0,0,0,0,2.728h-4.3a1.358,1.358,0,0,1-.96-2.324Z" transform="translate(0)" fill="#306ab4"/>
    <g id="Group_805" data-name="Group 805">
      <path id="Path_138" data-name="Path 138" d="M21.059,20.489H15.666a4.546,4.546,0,1,0,0,6.365h5.393a3.182,3.182,0,1,0,0-6.365Zm.96,4.143a1.351,1.351,0,0,1-.96.4h-4.3a4.528,4.528,0,0,0,0-2.728h4.3a1.358,1.358,0,0,1,.96,2.324Z" transform="translate(0 -2.589)" fill="#306ab4"/>
    </g>
  </g>
</svg>
  )
