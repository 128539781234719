import React, { Fragment } from 'react'

import './Terms.css'

export default ({
  term
}) => {
  return (
    <Fragment>
      {
        !!term && (
          <Fragment>
            <div className="terms-and-condition terms_condition_main">
              <h5 className="heading">Terms & Conditions</h5>
              {/* <p style={{wordBreak: 'break-all'}}>{term}</p> */}
              <textarea
                className={`float-left w-100 terms-box term-area-set`}
                value={term}
                disabled={true}
              />
              {/* <hr className="float-left w-100"/> */}
            </div>

          </Fragment>
        )
      }
    </Fragment>
  )
}