import { isEqual } from 'lodash';

export const validateCustomerpaymentForm = (data = {}, defaultValues = {}) => {
  let checkout = true;
  if (data && defaultValues) {
    const { paymentDate = null } = defaultValues;
    const actuapaymentDate =
      paymentDate && paymentDate.format('YYYY-MM-DD 00:00:00');

    const checkoutEqualitypaymentDate = Boolean(
      isEqual(
        data?.paymentDate?.format('YYYY-MM-DD 00:00:00'),
        actuapaymentDate
      )
    );
    const updatedData = { ...data };
    const actualData = { ...defaultValues };
    delete updatedData.paymentDate;

    delete actualData.paymentDate;

    const checkEquality = Boolean(isEqual(updatedData, actualData));

    if (!checkEquality || !checkoutEqualitypaymentDate) {
      checkout = false;
    }
  }
  return { checkout };
};
