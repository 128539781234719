import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { restRequest } from '../../Helpers/RequestHelper';
import { required, ValidateEmail } from '../../Helpers/Validator';

import Form from '../../Components/Form/Form';
import Field from '../../Components/Form/Field/Field';

import AuthContainer from '../../Auth/AuthContainer';
import { ssoforgetPassowrdPath } from '../../Helpers/UrlHelper';
import './PasswordRequest.css';
import { Link } from 'react-router-dom';
import { Loader } from '../../Components';
let emailVar;

class PasswordRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: false,
      loading: false,
    };
  }
  componentDidMount() {
    this.checkSsoSetting();
  }
  checkSsoSetting = () => {
    const sso_connected =
      process.env.REACT_APP_SSO_ENABLED === 'false' ? false : true;
    this.setState({
      loading: true,
    });
    // restRequest('get', 'ssoSettings')
    //   .then((res) => {
    if (sso_connected) window.location.href = ssoforgetPassowrdPath();
    else this.setState({ loading: false });
    // })
    // .catch((error) => {
    //   this.setState({ loading: false });
    // });
  };
  handleReconfirm() {
    let data = new FormData();

    data.append('email', emailVar);

    restRequest('post', 'register/reconfirm', data)
      .then(() => {
        this.props.handleToast('Email sent successfully', 'success');
      })
      .catch((error) => {
        this.props.handleToast(error, 'error');
      });
    // console.log('eeeeeeeeeeeeeeeeeeee', emailVar)
  }

  handleSubmit(values, setSubmitting) {
    restRequest('post', 'password/forget', {
      email: values.email.toLowerCase(),
    })
      .then((res) => {
        this.props.handleToast(
          'A confirmation code sent to your email address.',
          'success'
        );
        this.props.history.push({
          pathname: '/password/reset',
          state: { email: values.email },
        });
        // console.log('responese' , res)
      })
      .catch((error) => {
        this.props.handleToast(error, 'error');

        // console.log('err', error.response)
        // console.log('err.data.message', error.response.data.message)
        if (
          error.response.status === 400 &&
          error.response.data.message.includes('confirm')
        ) {
          this.setState({ link: true });
        }
        setSubmitting(false);
      });
  }

  renderFooter() {
    return (
      <React.Fragment>
        <div className="auth--message">
          <h5>
            <Link to="/login">Sign In</Link>
          </h5>
        </div>
        {this.state.link && (
          <h5>
            <div style={{ color: 'red' }}>
              Please activate your account first by confirming your email
              address
            </div>{' '}
            <br />
            Haven't received the confirmation email yet?
            <button
              onClick={() => this.handleReconfirm()}
              className="resend-text"
            >
              Resend Email
            </button>
          </h5>
        )}
      </React.Fragment>
    );
  }

  renderPasswordRequestForm() {
    return (
      <Formik
        initialValues={{ email: '' }}
        validationSchema={() =>
          Yup.object().shape({
            email: required(ValidateEmail),
          })
        }
        onSubmit={(values, { setSubmitting }) => {
          this.handleSubmit(values, setSubmitting);
        }}
        render={({
          values,
          errors,
          isSubmitting,
          touched,
          handleBlur,
          handleSubmit,
          handleChange,
        }) => {
          return (
            <AuthContainer
              title="Forgot Your Password?"
              subtitle="Get Password Confirmation Code Email"
              footer={this.renderFooter()}
            >
              <Form onSubmit={handleSubmit} className="auth--form">
                <Field
                  size="full"
                  value={values.email}
                  id="email"
                  name="Email"
                  autoFocus={true}
                  required
                  onBlur={handleBlur}
                  handleChange={handleChange}
                  error={touched.email && errors.email}
                >
                  {(emailVar = values.email)}
                </Field>
                <button
                  className="button--full-width"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() => this.setState({ link: false })}
                >
                  Send Code
                </button>
              </Form>
            </AuthContainer>
          );
        }}
      />
    );
  }

  render() {
    const { loading } = this.state;
    if (loading) return <Loader />;
    return this.renderPasswordRequestForm();
  }
}

export default PasswordRequest;
