import React from 'react'
import { Link } from 'react-router-dom'

import Logo from '../Assets/Img/logo.svg'
import './AuthContainer.css'

export default ({
  children: form,
  title = '',
  subtitle = '',
  terms = '',
  footer = null,
  afterLogo = null,
  logout = null,
  linkdisable = false,
  className = 'sm-width'
}) => (
  <div className="auth--page">
    {!linkdisable ?
      <Link to={{ pathname: process.env.REACT_APP_ENVIOURMENT_REDIRECT_URL }} target="_blank">
        <img className="auth--logo" src={Logo} alt="logo" />
      </Link>
      :
      <img className="auth--logo" src={Logo} alt="logo" />
    }
    {afterLogo}
    <div className={`auth--form-holder ${className}`}>
      <div className="auth--message">
        {logout}
        <h4 className="semi-bold">{title}</h4>
        {!linkdisable ? <h5>{subtitle}</h5> : <p>{subtitle}</p>}
      </div>
      {form}
      {footer}
    </div>
    {
      terms &&
      <div className="auth--terms">
        {terms}
      </div>
    }
  </div>
)