import React from 'react'

export const CheckIcon = () => (
  <svg className="check_icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.0848 12.8368L3.2912 9.3536L2 10.5312L7.0848 15.2L18 5.1776L16.72 4L7.0848 12.8368Z" fill="#59B54B" />
  </svg>
)

export const SearchIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.435 5.364C14.3877 7.31662 14.3877 10.4824 12.435 12.4351C10.4824 14.3877 7.31658 14.3877 5.36396 12.4351C3.41134 10.4824 3.41134 7.31662 5.36396 5.364C7.31658 3.41138 10.4824 3.41138 12.435 5.364ZM14.5062 13.0921C16.5618 10.3502 16.3428 6.44338 13.8492 3.94979C11.1156 1.21612 6.68342 1.21612 3.94975 3.94979C1.21608 6.68346 1.21608 11.1156 3.94975 13.8493C6.44334 16.3429 10.3502 16.5619 13.092 14.5063L15.2635 16.6777C15.654 17.0682 16.2871 17.0682 16.6777 16.6777C17.0682 16.2872 17.0682 15.654 16.6777 15.2635L14.5062 13.0921Z" fill="#BCBCBC" />
  </svg>
)