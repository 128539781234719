export const showActualprice = (
  totalMonthlyPrice = '',
  discount = '',
  months = '',
  duration = ''
) => {
  if (duration === '1 Years') {
    return Math.floor(
      totalMonthlyPrice * months -
        [totalMonthlyPrice * months * (discount / 100)]
    );
  } else {
    return (
      totalMonthlyPrice * months -
      [totalMonthlyPrice * months * (discount / 100)]
    );
  }
};

export const showMonthlyPrice = (
  actualPrice = '',
  months = '',
  duration = ''
) => {
  if (duration === '1 Years') {
    return Math.floor(actualPrice / months);
  } else {
    return actualPrice / months;
  }
};

export const showSavePrice = (
  totalMonthlyPrice = '',
  monthlyprice = '',
  months = ''
) => {
  return [totalMonthlyPrice - monthlyprice] * months;
};

export const showTotalPrice = (totalMonthlyPrice, months) => {
  return totalMonthlyPrice * months;
};
export const renderMonthlyPrice = (
  totalMonthlyPrice,
  discount,
  months,
  duration
) => {
  const actualPrice = showActualprice(
    totalMonthlyPrice,
    discount,
    months,
    duration
  );
  const monthlyPrice = showMonthlyPrice(actualPrice, months, duration);
  return monthlyPrice;
};
export const renderSavePrice = (
  totalMonthlyPrice,
  discount,
  months,
  duration
) => {
  const actualPrice = showActualprice(
    totalMonthlyPrice,
    discount,
    months,
    duration
  );
  const monthlyPrice = showMonthlyPrice(actualPrice, months, duration);
  const savePrice = showSavePrice(totalMonthlyPrice, monthlyPrice, months);
  return savePrice;
};
export const getPackagePlanIConClass = (slug = '') => {
  let slugClass = '';
  switch (slug) {
    case 'basic-package-monthly':
    case 'basic-package-yearly':
    case 'basic-package-three-months':
      slugClass = 'basic_package_icon';
      break;
    case 'standard-package-monthly':
    case 'standard-package-three-months':
    case 'standard-package-yearly':
      slugClass = 'standard_package_icon';
      break;
    case 'professional-package-monthly':
    case 'professional-package-three-months':
    case 'professional-package-yearly':
      slugClass = 'professional_package_icon';
      break;
    default:
      slugClass = 'free_package_icon';  // defualt free package
  }
  return slugClass;
};
