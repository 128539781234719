import React from 'react';
import './ChooseOrganization.css';

const SingleOrganizationCard = (props) => {
  if (props.title !== 'null') {
    if (props.title === 'Address') {
      const {
        company_city: city = '',
        company_province: state = '',
        company_postal_code: zip = '',
        // country = {},
      } = props?.value;

      // const { country_name = 'United States' } = country;
      return (
        <div
          className="box_data"
          style={{ borderBottom: 'none', paddingBottom: '0px' }}
        >
          <div className="left_box_data">{props.title}</div>
          <div className="mid_box_data"> :</div>
          <div id="choose_org" title={props.tooltip} className="right_box_data">
            <div className="choose_org_address_first">
              {!!props.value.company_street && `${props.value.company_street},`}
            </div>
            <div className="choose_org_address_second">
              {city && city + ', '}
              {state && state + ', '}
              {zip && zip + ', '}
              {props?.value?.country?.country_name
                ? props?.value?.country?.country_name
                : 'United States'}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="box_data">
          <div className="left_box_data">{props.title}</div>
          <div className="mid_box_data"> :</div>
          <div title={props.value} id={props.id} className="right_box_data">
            {props.value}
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className="box_data" style={{ paddingTop: '0px' }}>
        <div className="left_box_data">&nbsp;</div>
        <div className="mid_box_data">&nbsp;</div>
        <div title={props.value} className="right_box_data">
          {props.value}
        </div>
      </div>
    );
  }
};

export default SingleOrganizationCard;
