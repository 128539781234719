import React from 'react'

const EmptyCircle = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="10" height="10">
      <circle cx="8.001" cy="8" r="8" fill="#D3DEE5" />
    </svg>
  )
}

const HalfCircle = ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="10" height="10">
      <circle cx="8.001" cy="8" r="8" fill="#D3DEE5" />
      <path fill={ color ? color : "#306ab4"} d="M8.001 16a8 8 0 010-16v16z" />
    </svg>
  )
}

const FullCircle = ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="10" height="10">
      <circle cx="8.001" cy="8" r="8" fill={color ? color : "#306ab4"} />
    </svg>
  )
}

const CircleStatus = (
  {
    status,
    title
  }) => {
  const getTitle = (title) => {
    switch (title) {
      case 'invoiced':
        return 'Invoiced'
      case 'packed':
        return 'Packed'
      case 'delivered':
        return 'Delivered'
      case 'billed':
        return 'Billed'
      case 'received':
        return 'Received'
      case 'partially invoiced':
        return 'Partially Invoiced'
      case 'partially packed':
        return 'Partially Packed'
      case 'partially billed':
        return 'Partially Billed'
      case 'partially received':
        return 'Partially Received'
      case null:
      default:
        return title
    }
  }

  const getIcon = (status) => {
    switch (status) {
      case 'invoiced':
      case 'received':
        return <FullCircle color='#33A348' />
      case 'packed':
      case 'delivered':
      case 'billed':
        return <FullCircle />
      case 'partially invoiced':
      case 'partially received':
        return <HalfCircle color='#33A348' />
      case 'partially packed':
      case 'partially billed':
        return <HalfCircle />
      case null:
      default:
        return <EmptyCircle />
    }
  }
  return (
    <div style={{ textAlign: 'center' }} title={getTitle(title)}>
      {getIcon(status)}
    </div>
  )
}

export default CircleStatus