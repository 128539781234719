import { controllerNames } from '../Constants';
import { getObjFromLS } from '../Helpers/LocalStorage';

export function hasPermissionCustom(name, type) {
  let permissions = getObjFromLS('role').permissions;
  if (permissions.superAccess) {
    if (type === 'All') return [true, true, true];
    return true;
  }

  if (!['Create', 'Edit', 'Delete', 'All'].includes(type))
    throw new Error('Type must be one of Create, Edit, Delete, All');
  if (!(name in controllerNames))
    throw new Error('Invalid name is receieved for permission check');
  if (type !== 'All') {
    return permissions[`${controllerNames[name]}${type}`];
  }
  return [
    permissions[`${controllerNames[name]}Create`],
    permissions[`${controllerNames[name]}Edit`],
    permissions[`${controllerNames[name]}Delete`],
  ];
}
export function hasViewPermission(name) {
  let permissions = getObjFromLS('role').permissions;
  if (permissions.superAccess) {
    return true;
  }
  if (!(name in controllerNames))
    throw new Error('Invalid name is receieved for permission check');
  return permissions[`${controllerNames[name]}View`];
}
