import React, { Component } from 'react';
// import { PreferencesIcon, CurrencyIcon,PrefixPrefIcon, WarehouseIcon, TaxIcon, InventoryIcon, DeliveryIcon } from '../../Assets/Settings/SettingsIcon'
import { Link } from 'react-router-dom';
import './SettingsDropDown.css';
// import {PrefixPrefIcon} from '../../Assets/Settings/SettingsIcon'
import TaxIcon from '../../Assets/General/TaxIcon';
import InventoryIcon from '../../Assets/General/InventoryAccountIcon';
import CurrencyIcon from '../../Assets/General/CurrencyIcon';
import DeliveryIcon from '../../Assets/General/DeliveryMethodIcon';
import WarehouseIcon from '../../Assets/General/WarehouseIcon';
import PreferencesIcon from '../../Assets/General/PreferenceIcon';
import { IoSettingsOutline } from '../../Common/Icons';

import { routesPath } from '../../Pages/Navigation/NavigationUtils';

//function SettingsDropDown({closeMe}) {
export default class SettingsDropDown extends Component {
  render() {
    let { taxcount, deliverycount } = this.props.value;
    //console.log(this)
    //console.log("taxcount", taxcount)
    let closeMe = this.props.closeMe;
    return (
      <div className="SettingsDropDown">
        <div className="SettingsDropDown-inner">
          {/* <div className="main-heading">General</div> */}
          <div id="settings_div" className="main-heading hover-underline">
            <Link id="settings_link" to={routesPath.settings}>
              <IoSettingsOutline className="material-icon blue-icon settings-transform" />
              Settings
            </Link>
          </div>
          <ul>
            {/* <li> Overview </li> */}
            {taxcount > 0 && (
              <li id="tax_row" onClick={closeMe}>
                <Link to={taxcount > 0 ? '/taxes' : ''} id="tax_link">
                  <TaxIcon
                    className="general_icons"
                    customWidth="20"
                    customHeight="20"
                    giveFloat={true}
                  />{' '}
                  Taxes{' '}
                </Link>
              </li>
            )}
            <li id="accounting_row" onClick={closeMe}>
              <Link to="/accounting/account" id="accounting_link">
                <InventoryIcon
                  className="general_icons"
                  customWidth="20"
                  customHeight="20"
                  giveFloat={true}
                />{' '}
                Inventory Account{' '}
              </Link>
            </li>
            {deliverycount > 0 && (
              <li id="delivery_row" onClick={closeMe}>
                <Link
                  to={deliverycount > 0 ? '/deliverymethods' : ''}
                  id="delivery_link"
                >
                  <DeliveryIcon
                    className="general_icons"
                    customWidth="20"
                    customHeight="20"
                    giveFloat={true}
                  />{' '}
                  Delivery Methods{' '}
                </Link>
              </li>
            )}
            <li id="currency_row" onClick={closeMe}>
              <Link to="/currencies" id="currency_link">
                {' '}
                <CurrencyIcon
                  className="general_icons"
                  customWidth="20"
                  customHeight="20"
                  giveFloat={true}
                />{' '}
                Currency Setting{' '}
              </Link>
            </li>
            <li id="warehouse_row" onClick={closeMe}>
              <Link to="/warehouses" id="warehouse_link">
                {' '}
                <WarehouseIcon
                  className="general_icons"
                  customWidth="20"
                  customHeight="20"
                  giveFloat={true}
                />{' '}
                Warehouses{' '}
              </Link>
            </li>
            <li id="preference_row" onClick={closeMe}>
              <Link to="/preference" id="preference_link">
                <PreferencesIcon
                  className="general_icons"
                  customWidth="20"
                  customHeight="20"
                  giveFloat={true}
                />{' '}
                Preferences{' '}
              </Link>
            </li>
            <li id="delivery_row" onClick={closeMe}>
              <Link to="/paymentmethods" id="preference_link">
                <CurrencyIcon
                  className="general_icons"
                  customWidth="20"
                  customHeight="20"
                  giveFloat={true}
                />{' '}
                Payment Methods{' '}
              </Link>
            </li>
            {/* <li onClick={closeMe}><Link to="/numberPreferences" > <PrefixPrefIcon className="general_icons" customWidth='20' customHeight='25' giveFloat={true}/>  Prefix Preferences </Link> </li> */}
          </ul>
        </div>
      </div>
    );
  }
}

//export default SettingsDropDown
