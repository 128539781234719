import React, { Fragment } from 'react'

import './Notes.css'

export default ({
  note
}) => {
  return (
    <Fragment>
      {
        !!note && (
          <Fragment>
            <div className="notes">
              <h5 className="heading">Notes</h5>
              <p className={`float-left w-100 terms-box notes-box-set`}>{note}</p>
              {/* <textarea
                className={`float-left w-100 terms-box`}
                value={note}
                disabled={true}
              /> */}
            </div>
          </Fragment>
        )
      }
    </Fragment>
  )
}