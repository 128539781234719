import React, { useState } from 'react';
import { getStoreUrl } from '../../../../Helpers';
import { SiWordpress, SiShopify } from '../../../../Common/Icons';

const SycnOrderDetails = ({
  synDetial = {},
  salesOrder = {},
  inModal = false,
}) => {
  const [toggle, setToggle] = useState(true);
  const [itemtoggle, setItemToggle] = useState(false);

  const ToggleDetailsHandler = () => {
    setItemToggle(!itemtoggle);
  };
  return (
    <>
      <div className="items-group-main space-between d-flex pl-0">
        <div className="items-group-inner">
          <button
            onClick={() => setToggle(!toggle)}
            className={` ${toggle ? 'text-italic-active' : 'text-italic '}`}
          >
            Associated Sales Channels
          </button>
          <div className={`item-group-desc ${toggle ? 'group-desc-show' : ''}`}>
            <div className="d-flex">
              <div className="group-left-set">
                <div className="item-group-img">
                  {synDetial?.market_place === 'shopify' ? (
                    <SiShopify size={40} />
                  ) : (
                    synDetial?.market_place === 'woo-commerce' && (
                      <SiWordpress size={40} />
                    )
                  )}
                </div>
              </div>
              <div className="group-right-set">
                <h4>{synDetial?.market_place}</h4>
                <div className="group-counts">
                  <p className="mb-0">
                    <b>Store </b>:{' '}
                    {inModal ? (
                      <span>
                        {synDetial?.store?.name || synDetial?.store?.store_url}
                      </span>
                    ) : (
                      <a
                        href={getStoreUrl(synDetial.store)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {synDetial?.store?.name || synDetial?.store?.store_url}
                      </a>
                    )}
                  </p>
                </div>
              </div>
              <div
                className="item_toggle text-center"
                onClick={ToggleDetailsHandler}
              >
                <i
                  className={`fas fa-chevron-down ${
                    itemtoggle ? 'fa-rotate' : ''
                  }`}
                ></i>
              </div>
            </div>
            <div
              className={` items-table-main ${
                itemtoggle ? 'd-block' : 'd-none'
              }`}
            >
              <table className="item-table-width table">
                <thead>
                  <tr>
                    <th>
                      <b>Order Reference</b>
                    </th>
                    <th>
                      <b>Order No</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{salesOrder?.reference}</td>
                    <td>{salesOrder?.salesOrderNo}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SycnOrderDetails;
