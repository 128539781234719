import React, { Component } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';

import {
  required,
  ValidateEmail,
  ValidateMax50,
  ValidateName,
  ValidatePhoneNumber,
} from '../../../Helpers/Validator';

import Field from './../../Form/Field/Field';
import { getError } from '../../Form/FormHelper';
import { SALUTATIONS } from './../../../Constants';
import FormAction from '../FormAction';
import ErrorFocus from '../ErrorFocus';

import './EmployeesForm.css';

class EmployeesForm extends Component {
  render() {
    return (
      <Formik
        initialValues={{
          salutation: this.props.employee.salutation,
          firstName: this.props.employee.firstName,
          lastName: this.props.employee.lastName,
          email: this.props.employee.email,
          workPhone: this.props.employee.workPhone,
          mobile: this.props.employee.mobile,
          designation: this.props.employee.designation,
          department: this.props.employee.department,
          skype: this.props.employee.skype,
        }}
        validationSchema={() =>
          Yup.object().shape({
            firstName: required(ValidateName),
            lastName: ValidateName,
            email: ValidateEmail.nullable(),
            department: ValidateMax50,
            designation: ValidateMax50,
            skype: ValidateMax50,
            workPhone: ValidatePhoneNumber,
            mobile: ValidatePhoneNumber,
          })
        }
        onSubmit={(values, { setSubmitting }) => {
          this.props.handleSubmit(values, setSubmitting);
        }}
        render={({
          values,
          errors,
          isSubmitting,
          touched,
          handleBlur,
          handleSubmit,
          handleChange,
          setFieldValue,
        }) => {
          return (
            <form className="employees-form" onSubmit={handleSubmit}>
              <Field
                size="small"
                className="inline-field"
                type="dropdown"
                name="Salutation"
                value={values.salutation}
                options={SALUTATIONS}
                dropdownValue="value"
                dropdownLabel="label"
                handleChange={(option) =>
                  setFieldValue('salutation', option ? option.value : '')
                }
              />
              <Field
                name="First Name"
                className="inline-field"
                value={values.firstName}
                id="firstName"
                required
                handleChange={handleChange}
                onBlur={handleBlur}
                error={touched.firstName && errors.firstName}
              />
              <Field
                name="Last Name"
                className="inline-field"
                value={values.lastName}
                id="lastName"
                handleChange={handleChange}
                onBlur={handleBlur}
                error={touched.lastName && errors.lastName}
              />

              <Field
                size="large"
                name="Email"
                value={values.email}
                id="email"
                handleChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && errors.email}
              />
              <Field
                className="inline-field"
                type="phone"
                name="Phone"
                value={values.workPhone}
                id="workPhone"
                handleChange={handleChange}
                onBlur={handleBlur}
                error={getError(errors, touched, 'workPhone')}
              />
              <Field
                name="Mobile"
                className="inline-field"
                type="phone"
                value={values.mobile}
                id="mobile"
                handleChange={handleChange}
                onBlur={handleBlur}
                error={getError(errors, touched, 'mobile')}
              />

              <Field
                size="large"
                name="Skype"
                value={values.skype}
                id="skype"
                handleChange={handleChange}
                onBlur={handleBlur}
                error={getError(errors, touched, 'skype')}
              />

              <Field
                className="inline-field"
                name="Department"
                value={values.department}
                id="department"
                handleChange={handleChange}
                onBlur={handleBlur}
                error={getError(errors, touched, 'department')}
              />

              <Field
                className="inline-field"
                name="Designation"
                value={values.designation}
                id="designation"
                handleChange={handleChange}
                onBlur={handleBlur}
                error={getError(errors, touched, 'designation')}
              />

              <FormAction
                disabled={isSubmitting}
                onCancel={this.props.onCancel}
              />
              <ErrorFocus />
            </form>
          );
        }}
      />
    );
  }
}

export default EmployeesForm;
