import React from 'react';

import './ContactAdditionalInfo.css';

const ContactAdditionalInfo = ({
    facebook,
    twitter,
    instagram,
    notes,
    paymentTerms,
    currencyCode,
}) => {
    return (
        <div className="contact-additional-info">
            <>
                <div className="section-details--info-title semi-bold">
                    Currency Code
                </div>
                <div className="margin-bottom-sm">{currencyCode}</div>
            </>
            <>
                <div className="section-details--info-title semi-bold">
                    Payment Terms
                </div>
                <div className="margin-bottom-sm">{paymentTerms}</div>
            </>
            <>
                <div className="section-details--info-title semi-bold">
                    Facebook
                </div>
                <div className="margin-bottom-sm">{facebook}</div>
            </>
            <>
                <div className="section-details--info-title semi-bold">
                    Twitter
                </div>
                <div className="margin-bottom-sm">{twitter}</div>
            </>
            <>
                <div className="section-details--info-title semi-bold">
                    Instagram
                </div>
                <div className="margin-bottom-sm">{instagram}</div>
            </>
            <>
                <div className="section-details--info-title semi-bold">
                    Notes
                </div>
                <div>{notes}</div>
            </>
        </div>
    );
};

export default ContactAdditionalInfo;
