const ADJUSTMENT_OPTIONS = [
  {
    value: "quantity",
    label: 'Quantity Adjustment'
  },
  // {
  //   value: "price",
  //   label: 'Price Adjustment'
  // }
]

export default ADJUSTMENT_OPTIONS