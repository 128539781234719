import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import inventory from './../../../../Assets/Img/inventory-management.png'

import './PageTwo.css'

class PageTwo extends Component {

  render() {
    return (
      <div className="page-two">
        <div className="page-two-title column">
          <div className="home-pages-heading">
            Convenient inventory management
          </div>
          <div className="home-desc">
            Add, update or simply keep track of your inventory at ease.
          </div>
          <Link to="/signup" className="home--btn">
            Get Started
          </Link>
        </div>
        <div className="page-two-img">
          <img src={inventory} alt=""/>
        </div>
      </div>
    )
  }
}

export default PageTwo
