

import React from 'react'

export default ({
  className = ''
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
  <path id="Icon_ionic-ios-close-circle" data-name="Icon ionic-ios-close-circle" d="M12.875,3.375a9.5,9.5,0,1,0,9.5,9.5A9.5,9.5,0,0,0,12.875,3.375Zm2.407,12.939-2.407-2.407-2.407,2.407a.73.73,0,1,1-1.032-1.032l2.407-2.407L9.436,10.468a.73.73,0,0,1,1.032-1.032l2.407,2.407,2.407-2.407a.73.73,0,0,1,1.032,1.032l-2.407,2.407,2.407,2.407a.733.733,0,0,1,0,1.032A.725.725,0,0,1,15.282,16.314Z" transform="translate(-3.375 -3.375)"/>
</svg>
)
