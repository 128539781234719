import React, { Component } from 'react'

import SaleReturnForm from '../../Components/Form/SaleReturn/AddSalesReturn'
import FormLayout from '../../Components/Layout/FormLayout'

import { SalesReturnIcon } from '../../Assets/Navigation/NavigationIcons'


class AddSalesOrder extends Component {

  componentDidMount() {
    document.title = 'Add Sales Return'
  }

  render() {
    return (
      <FormLayout
        title="Create New Sales Return"
        Icon={SalesReturnIcon}
        // subtitle="Add New"
        closeUrl="/salesreturns"
        iconstyle="salereturn_general_module_icon"
      >
        <SaleReturnForm
          type="add"
          handleToast={this.props.handleToast}
        />
      </FormLayout>
    )
  }
}

export default AddSalesOrder