import { getItemTotalAmount } from '../../Helpers/Calculate';
import { GetDateString } from '../../Helpers/Date/GetDateString';

const getTaxAmount = (quantity, unitPrice, taxRate) => {
  return parseFloat(((quantity * unitPrice * taxRate) / 100).toFixed(2));
};

export const emptyItem = {
  id: null,
  salesOrderItemDetailId: null,
  warehouseId: null,
  dragDropID: GetDateString(),
  name: '',
  image: '',
  sku: '',
  unit: 'unit',
  ordered: 0,
  invoiced: 0,
  previousSellingPrice: 0,
  quantity: 1,
  inventoryType: null,
  unitPrice: null,
  total: '0.00',
  discount: null,
  discountType: 'percent', // percent, amount,
  tax: {
    id: null,
    rate: 0,
    name: '',
  },
  taxAmount: 0,
  stocks: [],
};

export const prepareConfirmedSalesOrdersItems = (
  selectedConfirmedSalesOrdersList
) => {
  const items = [];
  const selectedSalesOrderIds = [];

  for (let salesOrder of selectedConfirmedSalesOrdersList) {
    selectedSalesOrderIds.push(salesOrder.id);
    for (let salesOrderItemDetail of salesOrder.sales_order_item_details) {
      const preparedItem = {
        id: salesOrderItemDetail.item_id,
        salesOrderItemDetailId: salesOrderItemDetail.sales_order_item_detail_id,
        dragDropID: GetDateString(),
        currentSalesOrderId: salesOrder.id,
        warehouseId: salesOrderItemDetail.sales_order_item_warehouse_id,
        name: salesOrderItemDetail.item_name,
        images: salesOrderItemDetail.item_images,
        sku: salesOrderItemDetail.item_sku,
        unit: salesOrderItemDetail.item_unit,
        upc: salesOrderItemDetail.item_upc
          ? salesOrderItemDetail.item_upc
          : null,
        mpn: salesOrderItemDetail.item_mpn
          ? salesOrderItemDetail.item_mpn
          : null,
        ean: salesOrderItemDetail.item_ean
          ? salesOrderItemDetail.item_ean
          : null,
        isbn: salesOrderItemDetail.item_isbn
          ? salesOrderItemDetail.item_isbn
          : null,
        extra_description: salesOrderItemDetail.extra_description
          ? salesOrderItemDetail.extra_description
          : null,
        ordered: salesOrderItemDetail.ordered_quantity,
        invoiced: salesOrderItemDetail.invoiced_quantity,
        quantity: salesOrderItemDetail.remaining_quantity,
        unitPrice: salesOrderItemDetail.sales_order_item_unit_price,
        discount: salesOrderItemDetail.discount_item_level,
        discountType: salesOrderItemDetail.discount_type || 'percent', // percent, amount,
        tax: {
          id: salesOrderItemDetail.tax ? salesOrderItemDetail.tax.id : null,
          rate: salesOrderItemDetail.tax ? salesOrderItemDetail.tax.rate : 0,
          name: salesOrderItemDetail.tax ? salesOrderItemDetail.tax.name : '',
        },
        taxAmount: salesOrderItemDetail.tax
          ? getTaxAmount(
              salesOrderItemDetail.remaining_quantity,
              salesOrderItemDetail.sales_order_item_unit_price,
              salesOrderItemDetail.tax.rate
            )
          : 0,
      };

      items.push({
        ...preparedItem,
        total: getItemTotalAmount(preparedItem),
      });
    }
  }

  return { items, selectedSalesOrderIds };
};

export const prepareAddItems = (itemsArray, warehouseId) => {
  return itemsArray.map((item) => {
    const preparedItem = {
      id: item.item_id,
      salesOrderItemDetailId: item.sales_order_item_detail_id,
      dragDropID: GetDateString(),
      warehouseId: item.warehouse_id,
      name: item.item_name,
      images: item.items.images,
      sku: item.items.sku,
      unit: item.items.unit,
      upc: item.items.upc ? item.items.upc : null,
      mpn: item.items.mpn ? item.items.mpn : null,
      ean: item.items.ean ? item.items.ean : null,
      isbn: item.items.isbn ? item.items.isbn : null,
      extra_description: item.extra_description ? item.extra_description : null,
      ordered: item.ordered_quantity,
      invoiced: item.invoiced_quantity,
      quantity: item.remaining_quantity,
      unitPrice: item.rate,
      stocks: item.items.stocks,
      discount: item.discount_item_level,
      discountType: item.discount_type || 'percent', // percent, amount,
      tax: {
        id: item.tax ? item?.tax?.id : null,
        rate: item.tax ? item?.tax?.rate : 0,
        name: item.tax ? item?.tax?.name : '',
      },
      taxAmount: item.tax
        ? getTaxAmount(item.remaining_quantity, item.rate, item.tax.rate)
        : 0,
    };

    return {
      ...preparedItem,
      total: getItemTotalAmount(preparedItem),
    };
  });
};

export const prepareEditItems = (itemsArray) => {
  return itemsArray.map((item) => ({
    id: item.item_id,
    dragDropID: GetDateString(),
    salesOrderItemDetailId: item.sales_order_item_detail_id,
    invoiceItemDetailId: item.id,
    warehouseId: item.warehouse_id,
    name: item.item.name,
    images: item.item.images,
    sku: item.item.sku,
    unit: item.item.unit,
    upc: item.item.upc ? item.item.upc : null,
    mpn: item.item.mpn ? item.item.mpn : null,
    ean: item.item.ean ? item.item.ean : null,
    isbn: item.item.isbn ? item.item.isbn : null,
    inventoryType: item.item.inventory_type,
    ordered: item.sales_order_item_detail
      ? item.sales_order_item_detail.ordered_quantity
      : 0,
    invoiced: item.sales_order_item_detail
      ? item.sales_order_item_detail.invoiced_quantity
      : 0,
    quantity: item.quantity,
    unitPrice: item.rate,
    total: getItemTotalAmount(item),
    discount: item.discount_item_level,
    discountType: item.discount_type || 'percent', // percent, amount,
    tax: {
      id: item.tax ? item?.tax?.id : null,
      rate: item.tax ? item?.tax?.rate : 0,
      name: item.tax ? item?.tax?.name : '',
    },
    taxAmount: item.tax_amount ? item.tax_amount : 0,
    extra_description: item.extra_description ? item.extra_description : null,
  }));
};

export const prepareItemsList = (itemsArray, warehouseId) => {
  return itemsArray.map((item) => ({
    id: item.id,
    salesOrderItemDetailId: null,
    warehouseId,
    name: item.name,
    images: item.images,
    sku: item.sku,
    inventoryType: item.inventory_type,
    unit: item.unit,
    ordered: 0,
    invoiced: 0,
    quantity: 1,
    unitPrice: item.sales_unit_price || item.unitPrice,
    discount: 0,
    discountType: 'percent',
    upc: item.upc ? item.upc : null,
    mpn: item.mpn ? item.mpn : null,
    ean: item.ean ? item.ean : null,
    isbn: item.isbn ? item.isbn : null,
    tax: {
      id: item.tax ? item?.tax?.id : null,
      rate: item.tax ? item.tax.rate : 0,
      name: item.tax ? item.tax.name : '',
    },
    taxAmount: 0,
    stocks: item.stocks,
  }));
};

export const prepareItemListForSO = (sales_order_items, warehouseId) => {
  return sales_order_items.map((SO) => ({
    id: SO.items.id,
    salesOrderItemDetailId: SO.sales_order_item_detail_id,
    warehouseId,
    name: SO.items.name,
    images: SO.items.images,
    sku: SO.items.sku,
    inventoryType: SO.items.inventory_type,
    unit: SO.items.unit,
    ordered: 0,
    invoiced: 0,
    quantity: 1,
    unitPrice: SO.rate,
    discount: 0,
    discountType: 'percent',
    upc: SO.items.upc ? SO.items.upc : null,
    mpn: SO.items.mpn ? SO.items.mpn : null,
    ean: SO.items.ean ? SO.items.ean : null,
    isbn: SO.items.isbn ? SO.items.isbn : null,
    tax: {
      id: SO.tax ? SO.tax.id : null,
      rate: SO.tax ? SO.tax.rate : 0,
      name: SO.tax ? SO.tax.name : '',
    },
    taxAmount: 0,
    stocks: SO.items.stocks,
    extra_description: SO.extra_description,
  }));
};
