import React, {forwardRef} from 'react';

const  ComponentToPrint = forwardRef(({data}, ref) => {
  return ( 
    <div
      ref={ref}
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {data?.map((img, index) => (
        <img
          key={index}
          src={`${process.env.REACT_APP_AMAZON_S3}/${img}`}
          style={{
            width: 'auto',
            height: 'auto',
          }}
          alt="image1"
        />
      ))}
    </div>
   );
});
 
export default ComponentToPrint;