import React, { useEffect, useState } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { restRequest, checkError, confirmationAlert } from '../../../Helpers';
import {
  ValidateName,
  ValidateRequired,
  required,
  ValidateEmail,
} from '../../../Helpers/Validator';
import { Field, Form, FormAction } from '../';
import { validateInviteUserForm } from '../../../Helpers/FormValidator';

const formEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: required(ValidateName),
    email: required(ValidateEmail),
    role: ValidateRequired.nullable(),
  }),
  mapPropsToValues: ({ user }) => ({
    ...user,
  }),
  handleSubmit: (values, bag) => {
    handleFormSubmit(values, bag);
  },
});

const UserForm = ({
  values: { name, email, role },
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
  isSubmitting,
  errors,
  touched,
  title,
  inviteId,
  rolesList,
  toggleInvite,
  fromUserTab,
  ...props
}) => {
  const [staticVal, setStaticValues] = useState({});

  useEffect(() => {
    handleAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staticVal, name, email, role]);

  useEffect(() => {
    setStaticValues({
      name,
      email,
      role,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAlert = () => {
    let { checkout } = validateInviteUserForm(
      {
        name,
        email,
        role,
      },
      staticVal
    );
    if (!checkout || props.checkFlag) {
      props.setPrompt(true);
      props.handleConfirmFormInviteForm(true);
    } else {
      props.setPrompt(false);
      props.handleConfirmFormInviteForm(false);
    }
  };

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  return (
    <Form
      className="invite-form add_invite float-left w-70"
      onSubmit={handleSubmit}
    >
      <div
        className="float-left w-100 height_buttons_bottom"
        style={{ paddingLeft: '10rem', marginTop: '2rem' }}
      >
        <Field
          className="inline-field"
          size="large"
          name="Name"
          value={name}
          autoFocus={true}
          id="name"
          required
          placeholder="Name"
          handleChange={handleChange}
          onBlur={handleBlur}
          error={touched.name && errors.name}
        />

        <Field
          size="large"
          className="inline-field"
          name="Email"
          value={email}
          id="email"
          required={!inviteId}
          disabled={inviteId}
          placeholder="Email"
          handleChange={handleChange}
          onBlur={handleBlur}
          error={touched.email && errors.email}
        />

        <Field
          size="large"
          className="inline-field"
          name="Role"
          id="role"
          value={role}
          options={rolesList}
          dropdownValue="id"
          dropdownLabel="name"
          handleChange={(option) =>
            setFieldValue('role', option ? option.value : null)
          }
          onBlur={handleBlur}
          type="dropdown"
          required
          error={touched.role && errors.role}
        />
      </div>

      <FormAction
        className="action_buttons_add_role"
        submitLabel={fromUserTab || inviteId ? 'Save' : 'Invite'}
        disabled={isSubmitting}
        onCancel={async () => {
          let response;
          let { checkout } = validateInviteUserForm(
            {
              name,
              email,
              role,
            },
            staticVal
          );
          if (!checkout) {
            response = confirmationAlert();
            if (response) {
              await props.setPrompt(false);
              await props.handleConfirmFormInviteForm(false);
            }
          }
          if (response || checkout) toggleInvite();
        }}
      />
    </Form>
  );
};

const handleFormSubmit = (
  values,
  {
    setSubmitting,
    props: {
      handleToast,
      inviteId,
      fromUserTab,
      onSubmitInvite,
      handleConfirmFormInviteForm,
      setPrompt,
    },
  }
) => {
  if (inviteId) {
    const api = fromUserTab
      ? `organizations/users/${inviteId}`
      : `invites/${inviteId}`;
    restRequest('put', api, {
      name: values.name,
      role_id: values.role,
    })
      .then(() => {
        handleToast('Invited user edited', 'success');
        handleConfirmFormInviteForm(false);
        setPrompt(false);
        onSubmitInvite();
      })
      .catch((error) => {
        checkError(error, handleToast);
        setSubmitting(false);
      });
  } else {
    restRequest('post', 'invites', {
      name: values.name,
      email: values.email,
      role_id: values.role,
    })
      .then(() => {
        handleToast('Invitation Sent!', 'success');
        setPrompt(false);
        handleConfirmFormInviteForm(false);
        onSubmitInvite();
      })
      .catch((error) => {
        checkError(error);
        handleToast(error, 'error');
        setSubmitting(false);
      });
  }
};

export default formEnhancer(UserForm);
