import { isEqual } from 'lodash';
import moment from 'moment';

export const validateCreditNotesForm = (
  data = {},
  defaultValues = {},
  inModal = false
) => {
  const { salesReturnDate } = data;
  const { items = [] } = defaultValues;
  let returnDate = `${moment(salesReturnDate).format('LL')}`;
  let returnDateDefaultValue = `${moment(defaultValues.salesReturnDate).format(
    'LL'
  )}`;
  let checkout = true;

  if (inModal) {
    if (
      !Boolean(isEqual(data.returnreceive, defaultValues.returnreceive)) ||
      !Boolean(isEqual(data.salesreturn, defaultValues.salesreturn)) ||
      !Boolean(
        isEqual(data.salesOrderIdGeniuine, defaultValues.salesOrderIdGeniuine)
      )
    ) {
      checkout = false;
    }
  }

  if (!Boolean(isEqual(returnDate, returnDateDefaultValue))) {
    checkout = false;
  }

  items.forEach((elem, index) => {
    if (
      !Boolean(
        isEqual(
          parseFloat(elem?.adjustment).toFixed(2),
          parseFloat(data?.items[index]?.adjustment).toFixed(2)
        )
      ) ||
      !Boolean(
        isEqual(
          elem?.quantity_processed,
          data?.items[index]?.quantity_processed
        )
      ) ||
      !Boolean(isEqual(elem?.criteria, data.items[index]?.criteria))
    ) {
      checkout = false;
    }
  });

  return { checkout };
};
