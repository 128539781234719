import React from 'react';

import Table from '../../../../../Components/Table/Table';
import getDate from '../../../../../Helpers/Date/GetDate';
import { formatNumber } from '../../../../../Helpers/FormatNumber';

const formatTableData = (transactions, tableData, currencySymbol = '') => {
    let tableListData = [];
    let updatableDateColumns = [
        'order_date',
        'invoice_date',
        'package_date',
        'payment_date',
        'receive_date',
        'bill_date',
        'sales_return_date',
        'credit_note_date',
    ];
    let updatableCurrencyColumns = [
        'total',
        'payment_due',
        'amount',
        'invoice_amount',
        'balance_due',
    ];
    return transactions.map((transactionSlip, index) => {
        updatableDateColumns.forEach((column) => {
            if (transactionSlip.hasOwnProperty(column)) {
                tableListData[index] = {
                    ...transactionSlip,
                    [column]: getDate(transactionSlip[column]),
                };
            }
        });

        updatableCurrencyColumns.forEach((column) => {
            if (transactionSlip.hasOwnProperty(column)) {
                tableListData[index] = {
                    ...tableListData[index],
                    [column]: `${currencySymbol}${formatNumber(
                        transactionSlip[column]
                    )}`,
                };
            }
        });
        return tableListData[index];
    });
};

const TransactionsTable = ({
    list,
    tableHeaders,
    tableData,
    rightAlignColumns,
    linkTo,
    className = '',
    currencySymbol = '',
}) => {
    let formattedTableData = formatTableData(list, tableData, currencySymbol);

    return (
        <Table
            list={formattedTableData}
            tableHeaders={tableHeaders}
            tableData={tableData}
            rightAlignColumns={rightAlignColumns}
            linkTo={linkTo}
            className={className}
            currencySymbol={currencySymbol}
        />
    );
};

export default TransactionsTable;
