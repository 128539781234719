import React from 'react'

const SadEmoji = (
  {
    className = "icon-sad-face"
  }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" width="25" height="25" className={className}>
      <g id={className}>
        <path d='M 12.5 25 C 19.404 25 25 19.404 25 12.5 C 25 5.596 19.404 0 12.5 0 C 5.596 0 0 5.596 0 12.5 C 0 19.404 5.596 25 12.5 25 L 12.5 25 Z M 12.5 24 C 18.851 24 24 18.851 24 12.5 C 24 6.149 18.851 1 12.5 1 C 6.149 1 1 6.149 1 12.5 C 1 18.851 6.149 24 12.5 24 L 12.5 24 Z M 12.481 17 C 9 17 7 19 7 19 L 7 18 C 7 18 9 16 12.481 16 C 15.963 16 18 18 18 18 L 18 19 C 18 19 15.963 17 12.481 17 L 12.481 17 Z'
              fillRule='evenodd' />
        <path d="M 6 9.5 C 6 8.672 6.672 8 7.5 8 C 8.328 8 9 8.672 9 9.5 C 9 10.328 8.328 11 7.5 11 C 6.672 11 6 10.328 6 9.5 Z"
        />
        <path d="M 16 9.5 C 16 8.672 16.672 8 17.5 8 C 18.328 8 19 8.672 19 9.5 C 19 10.328 18.328 11 17.5 11 C 16.672 11 16 10.328 16 9.5 Z"
        />
      </g>
    </svg>
  )
}

export default SadEmoji