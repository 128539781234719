import React from 'react'

import PaymentSuccessLogo from './PaymentSuccessLogo.svg'
import PaymentFailueLogo from './PaymentFailueLogo.svg'
import './PaymentStatus.css'

export default ({
  className = 'full',
  type = 'page',
  paymentResponse = 'fail', history
}) => {
  const timeoutId = setTimeout(function(){
    window.location.href = '/dashboard';
  }, 5000);
  return(
    <div className={`access-denied ${className}`}>
      {
        (paymentResponse === 'fail') ? <img src={PaymentFailueLogo} alt="Payment-Failure-Logo" style={{width: '17%'}}/>
          : <img src={PaymentSuccessLogo} alt="Payment-Success-Logo" style={{width: '17%'}}/>
      }
      <h1>Payment {paymentResponse === 'fail' ? 'Failure' : 'Successful!'} </h1>
      <h4>
        {paymentResponse === 'fail' ? 'Some error occurred. Please try again.' : 'Thank you for choosing us!'}
      </h4>
      <button
        type="button"
        className="action-buttons--submit next-btn"
        onClick={()=>{
          clearTimeout(timeoutId);
          history.push('/dashboard');
          //console.log(`Timeout ID ${timeoutId} has been cleared`);
        }}
      >
        Go to Dashboard
      </button>
    </div>
  )
}