import React from 'react';

const SmileEmoji = (
  {
    className = "icon-smiling-face"
  }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' width='25' height='25' className={className}>
      <g id={className}>
        <path d='M 12.5 25 C 19.404 25 25 19.404 25 12.5 C 25 5.596 19.404 0 12.5 0 C 5.596 0 0 5.596 0 12.5 C 0 19.404 5.596 25 12.5 25 L 12.5 25 Z M 12.5 24 C 18.851 24 24 18.851 24 12.5 C 24 6.149 18.851 1 12.5 1 C 6.149 1 1 6.149 1 12.5 C 1 18.851 6.149 24 12.5 24 L 12.5 24 Z M 12.481 18 C 9 18 7 16 7 16 L 7 17 C 7 17 9 19 12.481 19 C 15.963 19 18 17 18 17 L 18 16 C 18 16 15.963 18 12.481 18 L 12.481 18 Z'
              fillRule='evenodd' />
        <path d='M 7 9.5 C 7 8.672 7.448 8 8 8 C 8.552 8 9 8.672 9 9.5 C 9 10.328 8.552 11 8 11 C 7.448 11 7 10.328 7 9.5 Z'
        />
        <path d='M 16 9.5 C 16 8.672 16.448 8 17 8 C 17.552 8 18 8.672 18 9.5 C 18 10.328 17.552 11 17 11 C 16.448 11 16 10.328 16 9.5 Z'
        />
      </g>
    </svg>
  )
}

export default SmileEmoji