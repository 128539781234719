import React, { Component } from 'react'
import './SmallLoader.css'
export class SmallLoader extends Component {

  render() {
    return (
      <>
        {/* <div className="small_loader"></div> */}
        <div className={`small_loader ${this.props.className} `}></div>
      </>
    )
  }
}

export default SmallLoader
