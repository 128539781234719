import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import proptypes from 'prop-types';

import { restRequest } from '../../../Helpers/RequestHelper';

import Loader from '../../../Components/Loader/Loader';
import FormAction from '../../../Components/Form/FormAction';

import { Checked } from '../../../Assets/List/ListIcons';
import './BackorderDropship.css';
import { checkError } from '../../../Helpers/AuthHelper';
import { confirmationAlert } from '../../../Helpers';

class BulkDropshipBackorder extends Component {
  state = {
    loading: true,
    data: [],
    checkedData: [],
    warehouseId: null,
    itemWithQuantity: [],
    renderDropship: false,
    renderBackorder: false,
  };
  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    this.setState({ loading: true });
    await restRequest(
      'get',
      `salesorders/${this.props.type}?ids=${this.props.ids}`
    )
      .then((res) => {
        const data = this.prepareData(res.sales_order_list);
        this.setState({
          data,
          warehouseId: res.warehouse_id,
        });
      })
      .catch((error) => {
        this.props.closeModal();
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, 'error')
      });
    this.setState({ loading: false });
  }

  prepareData(data) {
    const items = [];
    for (let key in data) {
      for (let item of data[key]) {
        items.push({
          id: item.id, // sales order item detail id
          itemId: item.item_id,
          ordered: item.quantity,
          quantity:
            this.props.type === 'dropship'
              ? item.quantity
              : item.quantity - item.stock_in_hand,
          name: item.items.name,
          type: item.items.type,
          saleOrderId: item.sales_order_id,
          salesOrderNo: item.sales_order_no,
          customerName: item.contact,
          extra_description: item.extra_description,
        });
      }
    }
    return items;
  }

  singleChecked = (event) => {
    const { handlePrompt = () => null } = this.props;
    const id = parseInt(event.target.name, 10);

    if (event.target.checked) {
      const checkedItem = this.state.data.find((one) => one.id === id);
      this.setState(
        {
          checkedData: [...this.state.checkedData, checkedItem],
        },
        () => {
          if (this.state.checkedData.length) handlePrompt();
          else handlePrompt(false);
        }
      );
    } else {
      const restCheckedItems = this.state.checkedData.filter(
        (one) => one.id !== id
      );
      this.setState(
        {
          checkedData: restCheckedItems,
        },
        () => {
          if (this.state.checkedData.length > 0) handlePrompt();
          else handlePrompt(false);
        }
      );
    }
  };

  checkAll = (event) => {
    const { handlePrompt = () => null } = this.props;
    let checkedData = [];
    if (event.target.checked) {
      checkedData = [...this.state.data];
    } else {
      checkedData = [];
    }
    this.setState({ checkedData }, () => {
      if (this.state.checkedData.length > 0) handlePrompt();
      else handlePrompt(false);
    });
  };

  handleDropshipBackorder = () => {
    const checkedData = this.state.checkedData;
    const itemWithQuantity = [];

    for (let item of checkedData) {
      itemWithQuantity.push({
        id: item.id,
        itemId: item.itemId,
        quantity: item.quantity,
        extra_description: item.extra_description,
        saleOrderId: item.saleOrderId,
      });
    }

    if (!itemWithQuantity.length) {
      this.props.handleToast('Please select atleast one item', 'error');
      return;
    }

    if (this.props.type === 'dropship') {
      this.setState({
        itemWithQuantity,
        renderDropship: true,
      });
    } else if (this.props.type === 'backorder') {
      this.setState({
        itemWithQuantity,
        renderBackorder: true,
      });
    }
  };

  isChecked = (id) => {
    return this.state.checkedData.find((data) => data.id === id);
  };

  isAllChecked = () => {
    return this.state.data.length === this.state.checkedData.length;
  };

  handleCancle = () => {
    let response;

    if (this.state.checkedData.length) response = confirmationAlert();
    if (response || !this.state.checkedData.length) this.props.closeModal();
  };

  renderSalesOrderData() {
    return (
      <div className="uiux-dropship-popup">
        <h5 className="modal-section--title">
          Choose the items to be{' '}
          {this.props.type === 'dropship' ? 'dropshipped' : 'backordered'}
        </h5>
        <div className="dropship-popup-inner">
          <table className="list-table border dropship_modal">
            <thead>
              <tr>
                <th>
                  <label className="list-table--checkbox-lable pointer">
                    <input
                      checked={this.isAllChecked()}
                      type="checkbox"
                      onChange={this.checkAll}
                    />
                    <span>
                      <Checked />
                    </span>
                  </label>
                </th>
                <th>Item Name</th>
                <th>Sales Order No.</th>
                <th>Customer Name</th>
                <th className="text-right">Ordered Qty.</th>
                <th className="text-right">
                  {`${this.props.type
                    .charAt(0)
                    .toUpperCase()}${this.props.type.substr(1)}`}{' '}
                  Qty.{' '}
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <label className="list-table--checkbox-lable pointer">
                        <input
                          checked={this.isChecked(item.id)}
                          type="checkbox"
                          name={`${item.id}`}
                          onChange={this.singleChecked}
                        />
                        <span>
                          <Checked />
                        </span>
                      </label>
                    </td>
                    <td className="modal-table--field">{item.name}</td>
                    <td className="modal-table--field">{item.salesOrderNo}</td>
                    <td className="modal-table--field">{item.customerName}</td>
                    <td className="modal-table--field text-right">
                      {item.ordered}
                    </td>
                    <td className="modal-table--field text-right">
                      {item.quantity}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <FormAction
          className="margin-top-md"
          onSubmit={this.handleDropshipBackorder}
          // onCancel={this.props.closeModal}
          onCancel={this.handleCancle}
          submitLabel="Next"
        />
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="loader-without-topbar">
          <Loader />
        </div>
      );
    } else if (this.state.renderDropship) {
      return (
        <Redirect
          push
          to={{
            pathname: '/dropship/add',
            state: {
              dropshipItems: this.state.itemWithQuantity,
            },
          }}
        />
      );
    } else if (this.state.renderBackorder) {
      return (
        <Redirect
          push
          to={{
            pathname: '/backorder/add',
            state: {
              backorderItems: this.state.itemWithQuantity,
              warehouseID: this.state.warehouseId,
            },
          }}
        />
      );
    }
    return this.renderSalesOrderData();
  }
}

BulkDropshipBackorder.propTypes = {
  handleToast: proptypes.func.isRequired,
  closeModal: proptypes.func.isRequired,
  type: proptypes.string.isRequired,
  ids: proptypes.array.isRequired,
};

export default withRouter(BulkDropshipBackorder);
