const controllerNames = {
  salesorder: 'SalesOrder',
  salesreturns: 'SalesReturn',
  returnreceive: 'ReturnRecieve',
  creditnotes: 'CreditNote',
  purchaseorder: 'PurchaseOrder',
  contact: 'Contact',
  item: 'Item',
  invoice: 'Invoice',
  package: 'Package',
  bill: 'Bill',
  itemadjustment: 'ItemAdjustment',
  itemGroups: 'ItemGroup',
  warehouse: 'Warehouse',
  currency: 'Currency',
  receive: 'Receive',
  tax: 'Tax',
  shipment: 'Shipment',
  payment: 'PaymentRecord',
  invoicepayment: 'InvoicePaymentRecord',
  organization: 'Organization',
  deliverymethod: 'DeliveryMethod',
  paymentmode: 'PaymentMode',
  account: 'Account',
  preference: 'Preference',
  invite: 'Invite',
};
export default controllerNames;
