import React from 'react'

const InvoiceIcon = ({ customWidth,customHeight, giveFloat}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 29.25 39"
         width={`${customWidth ? customWidth : '48'}`} height={`${customHeight ? customHeight : '48'}`}
         style={{ float: `${ giveFloat? 'left' :''}`,fill:'#8e8e8e' }}
    >
      <path className="a" d="M28.717-26.127,21.26-33.592a1.827,1.827,0,0,0-1.295-.533H19.5v9.75h9.75v-.465A1.822,1.822,0,0,0,28.717-26.127ZM17.063-23.766V-34.125H1.828A1.824,1.824,0,0,0,0-32.3V3.047A1.824,1.824,0,0,0,1.828,4.875H27.422A1.824,1.824,0,0,0,29.25,3.047V-21.937H18.891A1.833,1.833,0,0,1,17.063-23.766ZM4.875-28.641a.609.609,0,0,1,.609-.609h6.094a.609.609,0,0,1,.609.609v1.219a.609.609,0,0,1-.609.609H5.484a.609.609,0,0,1-.609-.609Zm0,6.094v-1.219a.609.609,0,0,1,.609-.609h6.094a.609.609,0,0,1,.609.609v1.219a.609.609,0,0,1-.609.609H5.484A.609.609,0,0,1,4.875-22.547Zm10.969,20.1V-.609A.609.609,0,0,1,15.234,0H14.016a.609.609,0,0,1-.609-.609V-2.46a4.362,4.362,0,0,1-2.39-.865.61.61,0,0,1-.043-.925l.9-.854a.625.625,0,0,1,.772-.056,1.834,1.834,0,0,0,.976.283h2.141a.958.958,0,0,0,.9-1,.994.994,0,0,0-.668-.97L12.561-7.878a3.447,3.447,0,0,1-2.406-3.305,3.392,3.392,0,0,1,3.25-3.433v-1.837a.609.609,0,0,1,.609-.609h1.219a.609.609,0,0,1,.609.609v1.85a4.356,4.356,0,0,1,2.39.865.61.61,0,0,1,.043.925l-.9.854a.625.625,0,0,1-.772.056,1.827,1.827,0,0,0-.976-.283H13.492a.958.958,0,0,0-.9,1,.994.994,0,0,0,.668.97l3.428,1.028A3.447,3.447,0,0,1,19.094-5.88,3.392,3.392,0,0,1,15.844-2.447Z" transform="translate(0 34.125)"/>
    </svg>
  )
}

const PackageIcon = ({ customWidth,customHeight, giveFloat}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 48.753 34.126"
         width={`${customWidth ? customWidth : '48'}`} height={`${customHeight ? customHeight : '48'}`}
         style={{ float: `${ giveFloat? 'left' :''}`,fill:'#8e8e8e' }}
    >
      <path className="a" d="M32.426-14.625a3.692,3.692,0,0,1-3.154-1.782l-4.9-8.12-4.89,8.12a3.7,3.7,0,0,1-3.161,1.79,3.5,3.5,0,0,1-1.013-.145L4.875-17.748V-4.189A2.43,2.43,0,0,0,6.718-1.828L23.187,2.293a4.951,4.951,0,0,0,2.361,0L42.032-1.828a2.443,2.443,0,0,0,1.843-2.361V-17.748L33.439-14.77A3.5,3.5,0,0,1,32.426-14.625ZM48.62-23.171,44.7-31a1.244,1.244,0,0,0-1.272-.678L24.375-29.25,31.36-17.664a1.252,1.252,0,0,0,1.409.556l15.074-4.3A1.255,1.255,0,0,0,48.62-23.171ZM4.052-31,.13-23.171A1.242,1.242,0,0,0,.9-21.42l15.074,4.3a1.252,1.252,0,0,0,1.409-.556L24.375-29.25,5.317-31.68A1.245,1.245,0,0,0,4.052-31Z" transform="translate(0.003 31.69)"/>
    </svg>
  )
}

const PurchaseOrderIcon = ({ customWidth,customHeight, giveFloat}) => {
  return (
<svg  xmlns="http://www.w3.org/2000/svg" width="10.5" height="12" viewBox="0 0 10.5 12"><path d="M8.25-6.75V-7.5a3,3,0,0,0-3-3,3,3,0,0,0-3,3v.75H0V-.375A1.875,1.875,0,0,0,1.875,1.5h6.75A1.875,1.875,0,0,0,10.5-.375V-6.75ZM3.75-7.5A1.5,1.5,0,0,1,5.25-9a1.5,1.5,0,0,1,1.5,1.5v.75h-3ZM7.5-4.687a.562.562,0,0,1-.562-.562A.562.562,0,0,1,7.5-5.812a.562.562,0,0,1,.563.563A.562.562,0,0,1,7.5-4.687Zm-4.5,0a.562.562,0,0,1-.562-.562A.562.562,0,0,1,3-5.812a.562.562,0,0,1,.563.563A.562.562,0,0,1,3-4.687Z" transform="translate(0 10.5)" /></svg>
  )
}

const EmailedIcon = ({ customWidth,customHeight, giveFloat}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 39 30.643"
         width={`${customWidth ? customWidth : '48'}`} height={`${customHeight ? customHeight : '48'}`}
         style={{ float: `${ giveFloat? 'left' :''}`,fill:'#8e8e8e' }}
    >
      <path className="a" d="M39-17.977V-.7a3.353,3.353,0,0,1-1.023,2.459,3.353,3.353,0,0,1-2.459,1.023H3.482A3.353,3.353,0,0,1,1.023,1.763,3.353,3.353,0,0,1,0-.7v-17.28a11.542,11.542,0,0,0,2.2,1.893q7.878,5.354,10.816,7.508,1.241.914,2.013,1.426A12.436,12.436,0,0,0,17.084-6.1a6.272,6.272,0,0,0,2.394.533h.044A6.272,6.272,0,0,0,21.916-6.1a12.436,12.436,0,0,0,2.057-1.045q.773-.511,2.013-1.426,3.7-2.677,10.838-7.508A12,12,0,0,0,39-17.977Zm0-6.4a5.752,5.752,0,0,1-1.066,3.286,10.347,10.347,0,0,1-2.655,2.677q-8.183,5.68-10.185,7.073-.218.152-.925.664t-1.175.827q-.468.316-1.132.707a6.592,6.592,0,0,1-1.251.588,3.459,3.459,0,0,1-1.088.2h-.044a3.459,3.459,0,0,1-1.088-.2,6.592,6.592,0,0,1-1.251-.588q-.664-.392-1.132-.707t-1.175-.827q-.707-.511-.925-.664-1.98-1.393-5.7-3.972t-4.461-3.1A10.806,10.806,0,0,1,1.2-20.926,5.082,5.082,0,0,1,0-23.9a4.393,4.393,0,0,1,.9-2.829,3.108,3.108,0,0,1,2.579-1.132H35.518a3.364,3.364,0,0,1,2.448,1.023A3.336,3.336,0,0,1,39-24.375Z" transform="translate(0 27.857)"/>
    </svg>
  )
}

const PeopleIcon = ({ customWidth,customHeight, giveFloat}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 30.643 33.429"
         width={`${customWidth ? customWidth : '48'}`} height={`${customHeight ? customHeight : '48'}`}
         style={{ float: `${ giveFloat? 'left' :''}`,fill:'#8e8e8e' }}
    >
      <path className="a" d="M30.643-2.851a5.424,5.424,0,0,1-1.589,4.124,5.869,5.869,0,0,1-4.222,1.513H5.811A5.869,5.869,0,0,1,1.589,1.273,5.424,5.424,0,0,1,0-2.851Q0-4,.076-5.1a22.165,22.165,0,0,1,.3-2.372A18.446,18.446,0,0,1,.958-9.837a11.188,11.188,0,0,1,.936-2.122,7.652,7.652,0,0,1,1.349-1.763A5.677,5.677,0,0,1,5.1-14.886a6.536,6.536,0,0,1,2.427-.435,2.659,2.659,0,0,1,.914.468q.718.468,1.621,1.045a10.285,10.285,0,0,0,2.35,1.045,9.4,9.4,0,0,0,2.905.468,9.4,9.4,0,0,0,2.905-.468,10.285,10.285,0,0,0,2.35-1.045q.9-.577,1.621-1.045a2.659,2.659,0,0,1,.914-.468,6.536,6.536,0,0,1,2.427.435A5.677,5.677,0,0,1,27.4-13.722a7.652,7.652,0,0,1,1.349,1.763,11.188,11.188,0,0,1,.936,2.122,18.447,18.447,0,0,1,.577,2.361,22.165,22.165,0,0,1,.3,2.372Q30.643-4,30.643-2.851ZM21.23-28.194a8.052,8.052,0,0,1,2.448,5.909,8.052,8.052,0,0,1-2.448,5.909,8.052,8.052,0,0,1-5.909,2.448,8.052,8.052,0,0,1-5.909-2.448,8.052,8.052,0,0,1-2.448-5.909,8.052,8.052,0,0,1,2.448-5.909,8.052,8.052,0,0,1,5.909-2.448A8.052,8.052,0,0,1,21.23-28.194Z" transform="translate(0 30.643)"/>
    </svg>
  )
}

const SalesOrderIcon = ({ customWidth,customHeight, giveFloat}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 39.001 39"
         width={`${customWidth ? customWidth : '48'}`} height={`${customHeight ? customHeight : '48'}`}
         style={{ float: `${ giveFloat? 'left' :''}`,fill:'#8e8e8e' }}
    >
      <path className="a" d="M38.931-5.271,36.9-17.459a2.438,2.438,0,0,0-2.407-2.034H15.844v-4.875h7.313a1.222,1.222,0,0,0,1.219-1.219v-7.32a1.222,1.222,0,0,0-1.219-1.219H3.656a1.222,1.222,0,0,0-1.219,1.219v7.313a1.222,1.222,0,0,0,1.219,1.219h7.313V-19.5H4.5a2.444,2.444,0,0,0-2.407,2.034L.061-5.279a4.729,4.729,0,0,0-.069.8V2.438A2.435,2.435,0,0,0,2.43,4.875H36.555a2.435,2.435,0,0,0,2.438-2.437V-4.471A4.043,4.043,0,0,0,38.931-5.271Zm-17.6-9.963a1.222,1.222,0,0,1,1.219-1.219h1.219a1.222,1.222,0,0,1,1.219,1.219v1.219A1.222,1.222,0,0,1,23.766-12.8H22.547a1.222,1.222,0,0,1-1.219-1.219Zm-2.437,4.875h1.219a1.222,1.222,0,0,1,1.219,1.219v1.219A1.222,1.222,0,0,1,20.109-6.7H18.891a1.222,1.222,0,0,1-1.219-1.219V-9.141A1.222,1.222,0,0,1,18.891-10.359Zm-2.437-6.094a1.222,1.222,0,0,1,1.219,1.219v1.219A1.222,1.222,0,0,1,16.453-12.8H15.234a1.222,1.222,0,0,1-1.219-1.219v-1.219a1.222,1.222,0,0,1,1.219-1.219ZM6.094-28.031v-2.437H20.719v2.438ZM9.141-12.8H7.922A1.222,1.222,0,0,1,6.7-14.016v-1.219a1.222,1.222,0,0,1,1.219-1.219H9.141a1.222,1.222,0,0,1,1.219,1.219v1.219A1.222,1.222,0,0,1,9.141-12.8Zm1.219,4.875V-9.141a1.222,1.222,0,0,1,1.219-1.219H12.8a1.222,1.222,0,0,1,1.219,1.219v1.219A1.222,1.222,0,0,1,12.8-6.7H11.578A1.222,1.222,0,0,1,10.359-7.922ZM26.813.609a.611.611,0,0,1-.609.609H12.8a.611.611,0,0,1-.609-.609V-.609a.611.611,0,0,1,.609-.609H26.2a.611.611,0,0,1,.609.609Zm1.828-8.531A1.222,1.222,0,0,1,27.422-6.7H26.2a1.222,1.222,0,0,1-1.219-1.219V-9.141A1.222,1.222,0,0,1,26.2-10.359h1.219a1.222,1.222,0,0,1,1.219,1.219ZM32.3-14.016A1.222,1.222,0,0,1,31.078-12.8H29.859a1.222,1.222,0,0,1-1.219-1.219v-1.219a1.222,1.222,0,0,1,1.219-1.219h1.219A1.222,1.222,0,0,1,32.3-15.234Z" transform="translate(0.008 34.125)"/>
    </svg>
  )
}

const PaymentIcon = ({ customWidth,customHeight, giveFloat}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 48.75 29.25"
         width={`${customWidth ? customWidth : '48'}`} height={`${customHeight ? customHeight : '48'}`}
         style={{ float: `${ giveFloat? 'left' :''}`,fill:'#8e8e8e' }}
    >
      <path className="a" d="M46.313-29.25H2.438A2.438,2.438,0,0,0,0-26.812V-2.437A2.438,2.438,0,0,0,2.438,0H46.313A2.438,2.438,0,0,0,48.75-2.437V-26.812A2.438,2.438,0,0,0,46.313-29.25ZM3.656-3.656V-8.531A4.875,4.875,0,0,1,8.531-3.656Zm0-17.062v-4.875H8.531A4.875,4.875,0,0,1,3.656-20.719ZM24.375-7.312c-3.366,0-6.094-3.275-6.094-7.312s2.729-7.312,6.094-7.312,6.094,3.274,6.094,7.313S27.74-7.312,24.375-7.312ZM45.094-3.656H40.219a4.875,4.875,0,0,1,4.875-4.875Zm0-17.062a4.875,4.875,0,0,1-4.875-4.875h4.875Z" transform="translate(0 29.25)"/>
    </svg>
  )
}

const BillIcon = ({ customWidth,customHeight, giveFloat}) => {
  return (
    <svg  xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12"><path d="M1.125-10.5A1.126,1.126,0,0,0,0-9.375v1.5A.375.375,0,0,0,.375-7.5H2.25V-9.375A1.126,1.126,0,0,0,1.125-10.5ZM6-.83V-2.25h6.75v-6A2.252,2.252,0,0,0,10.5-10.5H2.615A1.857,1.857,0,0,1,3-9.375V-.75A1.5,1.5,0,0,0,4.752.729,1.556,1.556,0,0,0,6-.83Zm.75-.67v.75A2.253,2.253,0,0,1,4.5,1.5h7.875A2.625,2.625,0,0,0,15-1.125a.375.375,0,0,0-.375-.375Z" transform="translate(0 10.5)" /></svg>
  )
}

const ItemIcon = ({ customWidth,customHeight, giveFloat}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 15 12"
         width={`${customWidth ? customWidth : '48'}`} height={`${customHeight ? customHeight : '48'}`}
         style={{ float: `${ giveFloat? 'left' :''}`,fill:'#8e8e8e' }}
    >
      <path className="a" d="M11.671-5.2,6.7-10.171a1.122,1.122,0,0,0-.8-.329H1.125A1.125,1.125,0,0,0,0-9.375v4.784a1.122,1.122,0,0,0,.329.8L5.3,1.171a1.125,1.125,0,0,0,1.591,0l4.784-4.784A1.125,1.125,0,0,0,11.671-5.2ZM2.625-6.75A1.125,1.125,0,0,1,1.5-7.875,1.125,1.125,0,0,1,2.625-9,1.125,1.125,0,0,1,3.75-7.875,1.125,1.125,0,0,1,2.625-6.75ZM14.671-3.614a1.125,1.125,0,0,0,0-1.591L9.7-10.171a1.122,1.122,0,0,0-.8-.329H7.767l4.6,4.6a2.1,2.1,0,0,1,.618,1.492,2.1,2.1,0,0,1-.618,1.492L8.287,1.162l.008.008a1.125,1.125,0,0,0,1.591,0Z" transform="translate(0 10.5)"
            />
    </svg>
  )
}

const CreditNoteIcon = ({ customWidth,customHeight, giveFloat}) => {
  return (
    <svg width="20"  height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path  d="M15.8,0.5H4.2C3,0.5,2,1.5,2,2.7v14.5c0,1.2,1,2.2,2.2,2.2h8.9l4.8-5.7V2.7C18,1.5,17,0.5,15.8,0.5z
   M12.2,17.9V14h3.9L12.2,17.9z M16.6,12.7h-4c-0.9,0-1.6,0.7-1.6,1.6v3.6H4c-0.3,0-0.6-0.3-0.6-0.6V2.7c0-0.3,0.3-0.6,0.6-0.6H16
  c0.3,0,0.6,0.3,0.6,0.6V12.7z"  fill= '#8e8e8e'/>
      <path d="M12.4,7.3c0.2,0,0.6-0.1,0.6-0.6C13,6.2,12.6,6,12.4,6h-1.9V4.8c0-0.1,0.1-0.5-0.5-0.5
  c-0.5,0-0.5,0.4-0.5,0.5v1.5H7.6C7,6.7,7,6.7,7,6.7v2.1c0.3,0.3,0.3,0.3,0.6,0.6h4.2v0.8H7.6c-0.2,0-0.6,0.2-0.6,0.6
  c0,0.6,0.4,0.6,0.6,0.6h1.9v1.5c0,0.2,0.1,0.6,0.6,0.6c0.5,0,0.6-0.4,0.6-0.6v-1.5h1.9c0.2,0,0.2,0,0.4-0.2c0-0.2,0-0.2,0-0.4v-2
  c0-0.2,0-0.2-0.2-0.4s-0.2-0.3-0.4-0.3H8.2V7.3H12.4z" fill= '#8e8e8e'/>
    </g>
  </svg>
  )
}

export { InvoiceIcon, PackageIcon, EmailedIcon, PurchaseOrderIcon, BillIcon, PeopleIcon, SalesOrderIcon, CreditNoteIcon, PaymentIcon, ItemIcon}