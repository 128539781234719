import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import dashboard from './../../../../Assets/Img/dashboard.png'

import './PageOne.css'

class PageOne extends Component {

  render() {
    return (
      <div className="page-one">
        <div className="page-one-title column">
          <div className="home-pages-heading">
            Powerful Dashboard for quick overview
          </div>
          <div className="home-desc">
            View an easy to follow overview of your current business standing.
          </div>
          <Link to="/signup" className="home--btn">
            Get Started
          </Link>
        </div>
        <div className="page-one-img">
          <img src={dashboard} alt=""/>
        </div>
      </div>
    )
  }
}

export default PageOne
