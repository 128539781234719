import * as Yup from 'yup';

export const MAX_NUMBER = 10000000000;
export const MAX_NUMBER_MESSAGE = 'Less than 10 billion';

export const MAX_NUMBER_MILLION = 1000000;
export const MAX_NUMBER_MESSAGE_MILLION = 'Less than 10 million';

export const required = (Validator) => Validator.required('Required');
export const moreThanZero = (Validator) => Validator.moreThan(0, 'Cannot be 0');
export const moreThanMinus = (Validator) =>
  Validator.moreThan(-1, 'Cannot be 0');

// assumed string
export const wrapValidateMax = (Validator, max) =>
  Validator.max(max, `No more than ${max} characters.`);
export const ValidateAmountNumber = (Validator, maximum) =>
  !isNaN(Validator) &&
  Yup.number().lessThan(maximum + 1, 'Greater than Amount'); //handled the 'nan' value check

export const ValidateRequired = Yup.string().trim().required('Required');

export const ValidateNumber = Yup.number().nullable();

export const ValidateZipCode = Yup.string()
  .max(20, 'No more than 20 characters')
  .nullable();

export const ValidateDate = Yup.date().nullable();

export const ValidateName = Yup.string()
  .trim()
  .max(100, 'No more than 100 characters');

export const ValidateName45 = Yup.string()
  .trim()
  .max(45, 'No more than 45 characters');

export const ValidateUrl = Yup.string().url('Invalid URL');

export const ValidateEmail = Yup.string()
  .max(100, 'No more than 100 characters')
  .email('Invalid email address');

/*export const ValidateContactEmail = Yup.object().shape({
  email: Yup.array()
    .transform(function(value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(Yup.string().email(({ value }) => `${value} is not a valid email`)),
});*/

export const ValidateContactEmail = Yup.string()
  .max(250, 'No more than 250 characters')
  .matches(
    // for regex backup ([\w\055%]*\.?[\w+\055%]+@[A-Za-z0-9-]{2,61}\.[A-Za-z0-9-]{2,61}(\.[A-Za-z0-9-]{2,61})*\,\s?)
    // /^(([\w\055%]*\.?){0,4}[\w+\055%]+@[A-Za-z0-9-]{2,61}\.[A-Za-z0-9-]{2,61}(\.[A-Za-z0-9-]{2,61})*\,\s?)*([\w\055%]*\.?[\w+\055%]+@[A-Za-z0-9-]{1,61}\.[A-Za-z0-9-]{2,61}(\.[A-Za-z0-9-]{2,61})*\s?)$/,
    /* eslint-disable no-useless-escape */
    /^(([\w\055%]*\.?){0,4}[\w+\055%]+@[A-Za-z0-9-]{2,61}\.[A-Za-z0-9-]{2,61}(\.[A-Za-z0-9-]{2,61})*\,\s?)*([\w\055%]*\.?[\w+\055%]+@[A-Za-z0-9-]{1,61}\.[A-Za-z0-9-]{2,61}(\.[A-Za-z0-9-]{2,61})*\s?)$/,
    // /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    'Inavlid Format'
  );

export const ValidatePercentage = Yup.number()
  .moreThan(0, 'Greater than 0')
  .max(100, 'No more than or equal to 100')
  .nullable();

export const ValidateItems = Yup.array()
  .of(
    Yup.object().shape({
      name: ValidateRequired,
      quantity: required(moreThanZero(ValidateNumber)),
      unitPrice: required(moreThanZero(ValidateNumber)),
    })
  )
  .min(1, 'At least one item is required');

export const ValidateMax255 = Yup.string()
  .trim()
  .max(255, 'No more than 255 Characters.');

export const ValidateMax1000 = Yup.string()
  .trim()
  .max(1000, 'No more than 1000 Characters.');

export const ValidateEqual0 = Yup.string().trim();

export const ValidateMax200 = Yup.string()
  .trim()
  .max(200, 'No more than 200 Characters.');

export const ValidateMax240 = Yup.string()
  .trim()
  .max(240, 'No more than 240 Characters.');

export const ValidateMaxOnlyAlpha30 = Yup.string()
  .matches(/^[ A-Z a-z ]+$/, 'Only alphabets are allowed')
  .trim()
  .max(30, 'No more than 30 Characters.');
export const ValidateMaxOnlyAlpha25 = Yup.string()
  .matches(/^[ A-Z a-z ]+$/, 'Only alphabets are allowed')
  .trim()
  .max(25, 'No more than 25 Characters.');

export const ValidateMaxOnlyAlpha200 = Yup.string()
  .matches(/^[A-Za-z]+$/, 'Only alphabets are allowed')
  .trim()
  .max(200, 'No more than 200 Characters.');

export const ValidateMaxOnlyAlphaWithSpace200 = Yup.string()
  .matches(/^[A-Z a-z ]+$/, 'Only alphabets are allowed')
  .trim()
  .max(200, 'No more than 200 Characters.');

export const ValidateMaxOnlyDigi50 = Yup.string()
  .matches(/^[A-Za-z0-9-]+$/, 'Only numbers and "-" are allowed')
  .trim()
  .max(50, 'No more than 50 Characters.');

export const ValidateMax120 = Yup.string()
  .trim()
  .max(120, 'No more than 120 Characters.');

export const ValidateMax100 = Yup.string()
  .trim()
  .max(100, 'No more than 100 Characters.');

export const ValidateMax20 = Yup.string()
  .trim()
  .max(20, 'No more than 20 Characters.');

export const ValidateMax50 = Yup.string()
  .trim()
  .max(50, 'No more than 50 Characters.');

export const ValidateMax60 = Yup.string()
  .trim()
  .max(60, 'No more than 60 Characters.');

export const ValidateMax55 = Yup.string()
  .trim()
  .max(55, 'No more than 55 Characters.');

export const ValidateMax30 = Yup.string()
  .trim()
  .max(30, 'No more than 30 Characters.');

export const ValidateMax25 = Yup.string()
  .trim()
  .max(25, 'No more than 25 Characters.');

export const ValidateMax6 = Yup.string()
  .trim()
  .max(6, 'No more than 6 Characters.');

export const ValidateMax15 = Yup.string()
  .trim()
  .max(15, 'No more than 15 Characters.');

export const ValidateMax4 = Yup.string()
  .trim()
  .max(4, 'No more than 4 Characters.');

export const ValidatePhoneNumber = Yup.string().test(
  'is-valid',
  'Invalid Phone Number.',
  (value) => {
    if (!value) return true;
    return !value.includes('_');
  }
);

export const ValidateMobileNumber = Yup.string().test(
  'is-valid',
  'Invalid Mobile Number.',
  (value) => {
    if (!value) return true;
    return !value.includes('_');
  }
);

export const ValidateFaxNumber = Yup.string().test(
  'is-valid',
  'Invalid Fax Number.',
  (value) => {
    if (!value) return true;
    return !value.includes('_');
  }
);

export const ValidateMax10Billion = Yup.number()
  .nullable()
  .lessThan(MAX_NUMBER, MAX_NUMBER_MESSAGE);

export const ValidateMax10Million = Yup.number()
  .nullable()
  .lessThan(MAX_NUMBER_MILLION, MAX_NUMBER_MESSAGE_MILLION);

export const ValidateMax10BillionPos = Yup.number()
  .nullable()
  .positive('Shipping & Handling should be greater than 0')
  .lessThan(MAX_NUMBER, MAX_NUMBER_MESSAGE);

export const ValidateMax100Number = Yup.number()
  .nullable()
  .max(100, 'No more than 100.');

export const ValidateWebsite = Yup.string()
  .matches(
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,20}(:[0-9]{1,5})?(\/.*)?$/,
    'Invalid url.'
  )
  .max(255, `No more than 255 characters.`);

export const ValidateNumerMoreThenZero = Yup.number()
  .required('Required')
  .moreThan(0, 'Greater than 0')
  .nullable();
