import React from 'react';
import Select from 'react-select';

const AsyncSelectStock = ({
  fullRowId = '',
  data = [],
  onChange = () => {},
  inputChanage = () => {},
  storeType = 'woocommerce',
}) => {
  const { selectedData } = data;
  const specificSelectedData = selectedData[fullRowId];
  let stockOptions = [];
  if (
    specificSelectedData.inventory_type === 'inventory' &&
    specificSelectedData.value
  ) {
    stockOptions = [
      { label: 'IMS', value: 'ims_item_stock' },
      { label: 'woocommerce', value: 'woocom_item_stock' },
      { label: 'Both', value: 'ims_woocom_stock' },
      { label: 'custom', value: 'custom_stock' },
    ];
    if (storeType === 'shopify') {
      stockOptions = [
        { label: 'IMS', value: 'ims_item_stock' },
        { label: 'shopify', value: 'shopify_item_stock' },
        { label: 'Both', value: 'ims_shopify_stock' },
        { label: 'custom', value: 'custom_stock' },
      ];
    }
  } else {
    stockOptions = [
      { label: 'woocommerce', value: 'woocom_item_stock' },
      { label: 'custom', value: 'custom_stock' },
    ];
    if (storeType === 'shopify') {
      stockOptions = [
        { label: 'shopify', value: 'shopify_item_stock' },
        { label: 'custom', value: 'custom_stock' },
      ];
    }
  }

  return (
    <>
      <Select
        options={stockOptions}
        value={specificSelectedData.stockStatus}
        onChange={(e) => onChange(e, fullRowId)}
      />
      <input
        type="number"
        value={specificSelectedData.totalStockValue}
        disabled={
          specificSelectedData.stockStatus.value !== 'custom_stock'
            ? true
            : false
        }
        //  min={0}
        onChange={(e) => inputChanage(e.target.value, fullRowId)}
        onBlur={(e) => {
          if (!e.target.value) {
            inputChanage(0, fullRowId);
          }
        }}
        onClick={() => {
          if (!specificSelectedData.totalStockValue) {
            inputChanage('', fullRowId);
          }
        }}
      />
    </>
  );
};
export default AsyncSelectStock;
