import React, { Component } from 'react';

import { restRequest } from '../../../../Helpers/RequestHelper';
import Loader from '../../../../Components/Loader/Loader';
// import NotFound from '../../../../Components/NotFound/NotFound'
import TimelineCard from '../../../../Components/TimelineCard/TimelineCard';
import EmptyTable from '../../../../Components/ListTable/EmptyTable';

import './ItemActivity.css';
import { checkError } from '../../../../Helpers/AuthHelper';

class ItemActivity extends Component {
  state = {
    activities: [],
    loading: true,
  };

  componentDidMount() {
    this.fetchActivityList();
  }

  fetchActivityList() {
    this.setState({ loading: true });
    restRequest('get', `items/${this.props.itemId}/activity`)
      .then((activityList) => {
        this.setState({
          activities: activityList,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    if (this.state.activities.length === 0) {
      return <EmptyTable />;
    }

    return (
      <div>
        {this.state.activities.map((activity) => {
          return (
            <TimelineCard
              key={activity.id}
              name={activity.creator}
              description={activity.description}
              date={activity.date}
              time={activity.time}
              subject={activity.subject}
              type={activity.type}
            />
          );
        })}
      </div>
    );
  }
}

export default ItemActivity;
