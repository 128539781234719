import React from 'react'
import { imagePath } from '../../Helpers/RequestHelper'

export default ({
  image = '',
  name
}) => (
  <span className="list-table--user--col">
    <div>
      <img
        src={imagePath(image, 'user')}
        alt={'profile'}
      />
    </div>
    <span className="name_align">
      {name}
    </span>
  </span>
)