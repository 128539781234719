import React, { useState, useEffect } from 'react';
import { PurchaseOrderForm } from '../../Components/Form';
import { Loader, FormLayout } from '../../Components';
import DropshipIcon from '../../Assets/General/DropshipIcon';
import useToggleClick from '../../Hooks';
import { validateCreateSalesOrderForm } from '../../Helpers/FormValidator';

const AddDropship = (props) => {
  const [loading, setLoading] = useState(false);
  const [, handleCrossAlert, handleCrossClick] = useToggleClick();

  useEffect(() => {
    document.title = 'Add Dropship';
    if (!props.location.state) {
      props.handleToast('Please select Sales Order to dropship', 'error');
      props.history.push('/salesorders');
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPurchaseOrderForm = () => {
    let { dropshipItems } = props?.location?.state;
    return (
      <FormLayout
        title="Dropship"
        Icon={DropshipIcon}
        subtitle="Add New"
        closeUrl="/salesorders"
        iconstyle="general_module_icon"
        navigate={false}
        handleCrossClick={handleCrossClick}
        validator={validateCreateSalesOrderForm}
        layoutBoxClass="uiux-form-redesign"
      >
        <PurchaseOrderForm
          type="dropship"
          dropshipItems={dropshipItems}
          title="Add Dropship"
          handleToast={props.handleToast}
          handleCrossAlert={handleCrossAlert}
        />
      </FormLayout>
    );
  };

  return (
    <>
      {loading && <Loader />}
      {renderPurchaseOrderForm()}
    </>
  );
};

export default AddDropship;
