import React from 'react';
import { Link } from 'react-router-dom';
import { MdClose } from '../../Common/Icons';
import Layout, { Header, Body } from './Layout';
import Box from './Box';

const FormLayout = ({
  title = '',
  Icon,
  subtitle,
  children,
  closeUrl = '/',
  iconstyle = '',
  handleCrossClick = () => {},
  navigate = true,
  validator,
  layoutBoxClass = '',
}) => {
  let lc = window.location.pathname;
  let flag = lc.includes('add') || lc.includes('edit') || lc.includes('clone');
  if (lc === '/creditnotes' || lc === '/packagesplan') {
    flag = false;
  }

  return (
    <Layout>
      <Header
        iconStyle={iconstyle}
        title={title}
        Icon={Icon}
        subtitle={subtitle}
      >
        {flag &&
          (navigate ? (
            <Link to={closeUrl}>
              <MdClose className="material-icon  mt-11 animate-close-icon" />
            </Link>
          ) : (
            <div onClick={handleCrossClick(closeUrl, validator)}>
              <MdClose className="material-icon  mt-11 animate-close-icon" />
            </div>
          ))}
      </Header>
      {flag ? (
        <Body>
          <Box layoutBoxClass={layoutBoxClass}>{children}</Box>
        </Body>
      ) : (
        <div style={{ marginTop: '1%' }}>
          <Box layoutBoxClass={layoutBoxClass}>{children}</Box>
        </div>
      )}
    </Layout>
  );
};

export default FormLayout;
