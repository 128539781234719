import React, { Fragment } from 'react';
import getDate from '../../../../Helpers/Date/GetDate';
import DropshipMultipleLink from '../../../../Helpers/DropshipMultipleLink';
import './SalesOrderInfo.css';

const SalesOrderInfo = ({
  info: {
    // name,
    // contactID,
    reference,
    orderDate,
    expectedShipmentDate,
    deliveryMethod,
    salesPerson,
    dropShipments,
    backOrders,
    inModal,
  },
  className
}) => {
  return (
    <div className={` section-details--info marginfromtop ${className}`}>
      <div className="section-details--info-details details_column">
        {orderDate && (
          <Fragment>
            {' '}
            <div className="details_row">
              <div className="section-details--info-title semi-bold text_transform_lower">
                Order Date
              </div>
              <div>{getDate(orderDate)}</div>{' '}
            </div>
          </Fragment>
        )}

        {expectedShipmentDate && (
          <Fragment>
            {' '}
            <div className="details_row">
              <div className="section-details--info-title semi-bold text_transform_lower">
                Expected Shipment Date
              </div>
              <div>{getDate(expectedShipmentDate)}</div>{' '}
            </div>
          </Fragment>
        )}

        {deliveryMethod && (
          <Fragment>
            {' '}
            <div className="details_row">
              <div className="section-details--info-title semi-bold text_transform_lower">
                Delivery Method
              </div>
              <div style={{ width: '44%' }}>{deliveryMethod.name}</div>{' '}
            </div>
          </Fragment>
        )}
        {salesPerson && (
          <Fragment>
            {' '}
            <div className="details_row">
              <div className="section-details--info-title semi-bold text_transform_lower">
                Sales Rep
              </div>
              <div className="reference">{salesPerson}</div>{' '}
            </div>
          </Fragment>
        )}
        {reference && (
          <Fragment>
            {' '}
            <div className="details_row">
              <div className="section-details--info-title semi-bold text_transform_lower">
                Reference
              </div>
              <div className="reference">{reference}</div>{' '}
            </div>
          </Fragment>
        )}
        {dropShipments && dropShipments.length > 0 && (
          <Fragment>
            {' '}
            <div className="details_row">
              <div className="section-details--info-title semi-bold text_transform_lower">
                Dropship Reference
              </div>
              <div className="reference">
                <DropshipMultipleLink
                  dropShipments={dropShipments}
                  field={'purchase_order_no'}
                  redirect={'purchaseorders'}
                  inModal={inModal}
                  showCustomModal={'purchaseorder'}
                />
              </div>
            </div>
          </Fragment>
        )}
        {backOrders && backOrders.length > 0 && (
          <Fragment>
            {' '}
            <div className="details_row">
              <div className="section-details--info-title semi-bold text_transform_lower">
                Backorder Reference
              </div>
              <div className="reference">
                <DropshipMultipleLink
                  dropShipments={backOrders}
                  field={'purchase_order_no'}
                  redirect={'purchaseorders'}
                  inModal={inModal}
                  showCustomModal={'purchaseorder'}
                />
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default SalesOrderInfo;
