import React, { Component, Fragment } from 'react';
import queryString from 'query-string';
import ExportIcon from '../../../Assets/General/ExportIcon';
import CustomModal from '../../../Components/CustomModal/CustomModal';
import Export from '../../../Components/Export/Export';
import { NewPackageIcon } from '../../../Assets/Navigation/NavigationIcons';
import { restRequest } from '../../../Helpers/RequestHelper';
import GenerateOptions from '../../../Helpers/ListHelper/GenerateOptions';
import { routesPath } from '../../Navigation/NavigationUtils';
import NewPackageEdit from '../../../Pages/SalesOrder/Packages/EditPackage';
import Alert from '../../../Components/Alert/Alert';
import HeaderWrapper from '../../../Components/HeaderWrapper/HeaderWrapper';
import PDFViewer from '../../../Components/PDFViewer/PDFViewer';
import ListTable from '../../../Components/ListTable/ListTable';
import CheckedMenu, {
  Button,
  ButtonLink,
  Options,
} from '../../../Components/CheckedMenu/CheckedMenu';
import SearchResult from '../../../Components/SearchBar/SearchResult';
import {
  removeValueFromLS,
  getObjFromLS,
  setObjInLS,
  getStringValueFromLS,
} from '../../../Helpers/LocalStorage';
import Onboarding from '../../../Components/Onboarding/InitialOnboardingScreen';
import PackageFlowDiagram from '../../../Components/Onboarding/Diagrams/PackageFlowDiagram';
import PackageDetails from '../../../Pages/SalesOrder/Packages/PackageDetails';
import NewPackageCreate from '../../../Pages/SalesOrder/Packages/AddPackage';
import EditIcon from '../../../Assets/General/EditIcon';
import PdfIcon from '../../../Assets/General/PdfIcon';
import DeleteIcon from '../../../Assets/General/DeleteIcon';
import { checkError } from '../../../Helpers/AuthHelper';
import './NewPackageCreate.css';
class PackageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      packageId: null,
      loading: true,
      data: [],
      dataExpanded: [],
      allData: [],
      currentFilter: 'all',
      sortFilter: 'created_at',
      orderBy: 'desc',

      totalPage: 1,
      currentPage: 1,
      viewPerPage: 20,
      totalRecords: 0,
      fetchD: null,
      pdf: null,
      showPDF: false,
      showExportModal: false,
      search: '',
      start: '',
      end: '',
      contactId: '',
      contactName: null,
    };
    this.getID = this.getID.bind(this);
  }

  filterOptions = [
    {
      id: 'none',
      label: 'Status',
      type: 'tagline',
    },
    {
      id: 'all',
      type: 'link',
      label: 'All',
      onClick: () => this.generateRoute({ filter: 'all', page: 1 }),
    },
    {
      id: 'not shipped',
      type: 'link',
      label: 'Not Shipped',
      onClick: () => this.generateRoute({ filter: 'not shipped', page: 1 }),
    },
    {
      id: 'shipped',
      type: 'link',
      label: 'Shipped',
      onClick: () => this.generateRoute({ filter: 'shipped', page: 1 }),
    },
    {
      id: 'delivered',
      type: 'link',
      label: 'Delivered',
      onClick: () => this.generateRoute({ filter: 'delivered', page: 1 }),
    },
  ];

  sortOptions = [
    {
      id: 'package_no',
      type: 'link',
      label: 'Package No',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('package_no') }),
    },
    {
      id: 'sales_order_no',
      type: 'link',
      label: 'Sales Order No',
      onClick: () =>
        this.generateRoute({
          ...this.prepareSortFilter('sales_order_no'),
        }),
    },
    {
      id: 'display_name',
      type: 'link',
      label: 'Customer Name',
      onClick: () =>
        this.generateRoute({
          ...this.prepareSortFilter('display_name'),
        }),
    },
    {
      id: 'status',
      type: 'link',
      label: 'Status',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('status') }),
    },
    {
      id: 'package_date',
      type: 'link',
      label: 'Date',
      onClick: () =>
        this.generateRoute({
          ...this.prepareSortFilter('package_date'),
        }),
    },
    /*{
      id: 'total_quantity',
      type: 'link',
      label: 'Quantity',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('total_quantity') })
    },*/
    {
      id: 'created_at',
      type: 'link',
      label: 'Created Time',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('created_at') }),
    },
  ];

  importExportOptions = [
    {
      label: 'Export Packages',
      icon: <ExportIcon />,
      onClick: () => this.openExportModal(),
      disabled: () =>
        !this.hasViewPermission('package') ||
        JSON.parse(localStorage.getItem('exportBtn')),
    },
  ];

  componentDidMount() {
    document.title = 'Packages';
    this.fetchDataWithQueryString();
  }

  componentDidUpdate() {
    this.handleOnUpdateQueryString();
  }

  handleOnUpdateQueryString() {
    const {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
      contactId = '',
    } = this.parseQueryString();
    if (filter !== this.state.currentFilter) {
      if (filter !== 'required delivery') {
        this.setState({
          start: '',
          end: '',
        });
        for (var i = 0; i < this.filterOptions.length; i++) {
          if (i > 4) {
            this.filterOptions.splice(i, 1);
            i--; // decrement index if item is removed
          }
        }
      }
      this.setState(
        {
          currentFilter: filter,
        },
        () => this.fetchData()
      );
    }
    if (sort !== this.state.sortFilter) {
      this.setState(
        {
          sortFilter: sort,
        },
        () => this.fetchData()
      );
    }
    if (orderBy !== this.state.orderBy) {
      this.setState(
        {
          orderBy: orderBy,
        },
        () => this.fetchData()
      );
    }
    if (page !== this.state.currentPage) {
      this.setState(
        {
          currentPage: parseInt(page),
          bulkSelectedData: [],
        },
        () => this.fetchData()
      );
    }
    if (view !== this.state.viewPerPage) {
      this.setState(
        {
          viewPerPage: parseInt(view),
          bulkSelectedData: [],
        },
        () => this.fetchData()
      );
    }
    if (search !== this.state.search) {
      this.setState({ search: search, bulkSelectedData: [] }, () =>
        this.fetchData()
      );
    }
    if (contactId !== this.state.contactId) {
      this.setState(
        {
          contactId: contactId,
        },
        () => this.fetchData()
      );
    }
  }

  fetchDataWithQueryString() {
    const { filter, page, sort, orderBy, view, search, start, end, contactId } =
      this.parseQueryString();
    this.setState(
      {
        sortFilter: sort,
        currentFilter: filter,
        currentPage: parseInt(page),
        orderBy: orderBy,
        viewPerPage: parseInt(view),
        search: search,
        start: start,
        end: end,
        contactId: contactId,
      },
      () => this.fetchData()
    );
  }

  parseQueryString() {
    const query = queryString.parse(this.props.location.search);
    let filter = '';
    switch (query.filter) {
      case 'all':
      case 'not shipped':
      case 'shipped':
      case 'delivered':
      case 'required delivery':
        filter = query.filter;
        break;
      default:
        filter = 'all';
    }

    let sort = '';
    switch (query.sort) {
      case 'package_no':
      case 'sales_order_no':
      case 'carrier':
      case 'created_at':
      case 'tracking_no':
      case 'shipment_date':
      case 'display_name':
      case 'total_quantity':
      case 'status':
      case 'package_date':
        sort = query.sort;
        break;
      default:
        sort = 'created_at';
    }

    let orderBy = '';
    switch (query.orderBy) {
      case 'asc':
      case 'desc':
        orderBy = query.orderBy;
        break;
      default:
        orderBy = 'desc';
    }

    let page = parseInt(query.page);
    if (!page) page = 1;
    let view = parseInt(query.view);
    if (!view) view = 20;

    let search = query.search;
    if (!search) search = '';

    let start = query.start;
    if (!start) start = '';

    let end = query.end;
    if (!end) end = '';

    let contactId = query.contactId;
    if (!contactId) contactId = '';

    return {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
      start,
      end,
      contactId,
    };
  }

  fetchData = async () => {
    this.setState({
      loading: true,
    });

    let currentFilter =
      this.state.currentFilter[0].toUpperCase() +
      this.state.currentFilter.substr(1);
    currentFilter =
      currentFilter === 'All' ? 'all' : currentFilter.toLowerCase();
    var url = `packages?filter=${currentFilter}&page=${this.state.currentPage}&sort_by=${this.state.sortFilter}&order_by=${this.state.orderBy}&view=${this.state.viewPerPage}&search=${this.state.search}`;
    if (this.state.start && this.state.end) {
      url = `${url}&start=${this.state.start}&end=${this.state.end}`;
      if (this.filterOptions.length === 5) {
        this.filterOptions.push({
          id: 'required delivery',
          type: 'link',
          label: 'Ship & Deliver',
          onClick: () =>
            this.generateRoute({
              filter: 'required delivery',
              page: 1,
            }),
        });
      }
    }
    if (this.state.contactId) {
      url = `${url}&contactId=${this.state.contactId}`;
    }
    await restRequest('get', url)
      .then((res) => {
        let obj = {
          prevId: null,
          id: res.data[0] && res.data[0].id,
          nextId: res.data[1] && res.data[1].id,
          moduleName: 'packages',
          urlPath: window.location.pathname,
          queryParam: window.location.search,
        };
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS && dataFromLS.moduleName !== 'packages') {
          sessionStorage.setItem('fullscreen', true);
          sessionStorage.setItem('once', true);
          removeValueFromLS('module');
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
        }
        if (dataFromLS && !dataFromLS.id) {
          setObjInLS('module', {
            ...dataFromLS,
            id: res.data[0] && res.data[0].id,
            nextId: res.data[1] && res.data[1].id,
          });
        }
        if (!res.data[0]) {
          removeValueFromLS('module');
        }
        if (!dataFromLS || dataFromLS.action === 'add') {
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
        } else if (dataFromLS.action === 'bulkdelete') {
          if (res.data[0]) {
            setObjInLS('module', { ...obj, lolo: 'no' });
          }
        } else if (
          dataFromLS &&
          dataFromLS.moduleName === 'packages' &&
          dataFromLS.queryParam !== window.location.search
        ) {
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
        }

        if (!res.data?.length) {
          sessionStorage.setItem('fullscreen', true);
          setObjInLS('module', obj);
        }
        this.setState(
          {
            data: this.prepareDataForTableCollapsed(res.data),
            dataExpanded: this.prepareDataForTable(res.data),
            totalPage: res.last_page,
            totalRecords: res.total,
            loading: false,
            allData: res?.data || [],
          },
          () => {
            this.setState({ mainLoading: false });
          }
        );
        if (this.state.contactId) {
          this.setState({
            // contactName: res.data[0]?.sales_order?.contact?.display_name,
            //  code for ticket 6294 remove sale order and contact because there is no such object in the response
            contactName: res.data[0]?.display_name,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        checkError(error);
        this.props.handleToast(error, 'error');
      });
    this.setState({
      loading: false,
    });
  };

  prepareDataForTable(data) {
    this.setState({
      fetchD: this.fetchData,
    });
    return data.map((packageItem) => ({
      id: packageItem.id,
      Date: {
        id: 'package_date',
        sortable: true,
        getValue: function () {
          return packageItem[this.id];
        },
      },
      Package: {
        id: 'package_no',
        sortable: true,
        getValue: function () {
          return packageItem[this.id];
        },
      },
      'Sales Order': {
        id: 'sales_order_no',
        sortable: true,
        getValue: function () {
          return packageItem[this.id];
        },
      },

      Customer: {
        id: 'display_name',
        sortable: true,
        getValue: function () {
          return packageItem[this.id];
        },
      },
      Status: {
        id: 'status',
        sortable: true,
        getValue: function () {
          return packageItem[this.id];
        },
      },
      /*'Quantity': {
        id: 'total_quantity',
        sortable: true,
        alignRight: true,
        getValue: function () { return formatNumber(packageItem[this.id]) }
      },*/
      // not displayable
      additional: {
        status: packageItem.status,
      },
    }));
  }

  prepareDataForTableCollapsed(data) {
    this.setState({
      fetchD: this.fetchData,
    });
    return data.map((packageItem) => ({
      id: packageItem.id,
      Date: {
        id: 'package_date',
        sortable: true,
        getValue: function () {
          return packageItem[this.id];
        },
      },
      // 'Package No.': {
      //   id: 'package_no',
      //   sortable: true,
      //   getValue: function () { return packageItem[this.id] }
      // },
      Customer: {
        id: 'display_name',
        sortable: true,
        getValue: function () {
          return packageItem[this.id];
        },
      },
      Status: {
        id: 'status',
        sortable: true,
        getValue: function () {
          return packageItem[this.id];
        },
      },
      /*'Quantity': {
        id: 'total_quantity',
        sortable: true,
        alignRight: true,
        getValue: function () { return formatNumber(packageItem[this.id]) }
      },*/
      // not displayable
      additional: {
        status: packageItem.status,
      },
    }));
  }

  openPdf = (id) => {
    this.setState({ showPDF: true });
    restRequest('get', `packages/${id}/pdf`)
      .then((res) => {
        this.setState({ pdf: res });
      })
      .catch((error) => checkError(error));
  };

  print(id) {
    this.setState({ loading: true });
    restRequest('get', `packages/${id}/print`)
      .then((res) => {
        var w = window.open();
        w.document.write(res.body);
        w.document.close();
        setTimeout(function () {
          w.focus();
          w.print();
          w.close();
        }, 100);

        this.setState({ loading: false });
      })
      .catch((err) => {
        checkError(err, this.props.handleToast);
        //this.props.handleToast(err,'error')
        this.setState({ loading: false });
      });
  }

  openExportModal() {
    this.setState({ showExportModal: true });
  }

  closeExportModal = () => {
    this.setState({ showExportModal: false });
  };

  renderExportModal() {
    return (
      <CustomModal
        showModal={this.state.showExportModal}
        title="Export Packages"
        Icon={ExportIcon}
        onClose={this.closeExportModal}
        renderActions={false}
      >
        <Export
          name="Packages"
          onCancel={this.closeExportModal}
          submitURL="packages/export"
          handleToast={this.props.handleToast}
        />
      </CustomModal>
    );
  }

  closeAllModals = () => {
    this.setState({
      showPDF: false,
      pdf: null,
      showExportModal: false,
    });
  };
  getID(prevId, id, nextId) {
    let obj = {
      id,
      prevId: prevId ? prevId : null,
      nextId: nextId ? nextId : null,
      moduleName: 'packages',
      urlPath: window.location.pathname,
      queryParam: window.location.search,
    };
    setObjInLS('module', obj);

    this.setState({ packageId: id });
    // setValueInLS('id',id)
  }
  checkPackageStatus = (status, id = false) => {
    const currentId = id ? id : this.state.bulkSelectedData[0];
    let packageData = this.state.data.find((one) => {
      return one.id === currentId;
    });
    return status.includes(packageData.additional.status);
  };

  // render action menu for single selected data
  renderSingleActionMenu = () => {
    const checkedId = this.state.bulkSelectedData[0];
    const [hasCreatePermission, hasEditPermission, hasDeletePermission] =
      this.hasPermission('package', 'All');
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <ButtonLink
          enable={this.checkPackageStatus('not shipped') && hasEditPermission}
          to={`/packages/edit/${checkedId}`}
          type="edit"
        />
        <Button
          handleClick={() => this.openPdf(checkedId, '/pdf/invoice/')}
          type="pdf"
        />
        <Button handleClick={() => this.print(checkedId)} type="print" />
        <ButtonLink
          enable={hasCreatePermission}
          to={`/packages/email/${checkedId}`}
          type="email"
        />
        <Button
          enable={hasDeletePermission}
          handleClick={() => this.openDeleteModal(checkedId)}
          type="delete"
        />
        <Button handleClick={() => this.exportCurrentView()} type="export" />
      </CheckedMenu>
    );
  };

  // render action menu for multiple selected data
  renderMultipleActionMenu = () => {
    const hasDeletePermission = this.hasPermission('package', 'Delete');
    const options = this.populateConditionalOptionsBulkSelect();
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <Button
          enable={hasDeletePermission}
          handleClick={() => this.openBulkDeleteModal()}
          type="delete"
        />
        <Options enable={!!options.length} options={options} />
      </CheckedMenu>
    );
  };

  populateConditionalOptionsBulkSelect() {
    const options = [];
    options.push({
      label: 'Export Selected',
      onClick: () => this.exportCurrentView(),
      disabled: () => !this.hasViewPermission('package'),
    });

    return options;
  }

  exportCurrentView = () => {
    restRequest('post', `packages/exportCurrentView`, {
      package_ids: this.state.bulkSelectedData,
    })
      .then((res) => {
        let csvFile = 'data:text/csv;charset=utf-8,' + res;
        let encodedUri = encodeURI(csvFile);
        let link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `Packages.csv`);
        document.body.appendChild(link);
        link.click();
        this.props.handleToast(`Packages exported successfully`, 'success');
        this.setState({ bulkSelectedData: [] });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, 'error')
      });
  };

  isSyncOrderExist = (id) =>
    this.state.allData?.some(
      (order) => order.id === id && order?.sales_order?.sync_order
    );

  prepareRowOptions() {
    const rowOptions = [
      {
        label: 'Edit',
        icon: EditIcon,
        onClick: (id) => this.props.history.push(`/packages/edit/${id}`),
        disabled: (id) =>
          !this.checkPackageStatus(['not shipped'], id) ||
          this.isSyncOrderExist(id),
      },
      {
        label: 'Delete',
        icon: DeleteIcon,
        onClick: this.openDeleteModal,
        disabled: (id) =>
          this.checkPackageStatus(['shipped', 'delivered'], id) ||
          this.isSyncOrderExist(id),
      },
      {
        label: 'View PDF',
        icon: PdfIcon,
        onClick: (id) => this.openPdf(id),
      },
    ];

    const permissions = this.hasPermission('package', 'All');

    const allowedRowOptions = GenerateOptions(permissions, rowOptions);

    return allowedRowOptions;
  }

  renderTable() {
    const {
      showPDF,
      pdf,
      data,
      dataExpanded,
      bulkSelectedData,
      sortFilter,
      orderBy,
      //loading,
      mainLoading,
    } = this.state;
    if (!mainLoading) {
      if (this.checkIfOnboarding()) {
        return (
          <Onboarding
            name="Package"
            FlowDiagram={PackageFlowDiagram}
            videoFrame={
              <iframe
                width="700"
                height="400"
                src="https://www.youtube.com/embed/5sMO_nRHXO4"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            }
            DisplayIcon={NewPackageIcon}
            headerTitle="Packages"
            description="Create & manage packages"
            buttonLabel="Create Package"
            buttonLink="/packages/add"
            style={{ width: '100%' }}
          />
        );
      }
    }
    let fullscreen = getStringValueFromLS('fullscreen');
    let fullscreenFlag = getStringValueFromLS('fullscreenFlag');
    if (fullscreenFlag === 'true') {
      sessionStorage.setItem('fullscreen', false);
      sessionStorage.setItem('fullscreenFlag', false);
    }
    return (
      <Fragment>
        {this.renderExportModal()}
        <PDFViewer
          showPDF={showPDF}
          hidePDFModal={this.closeAllModals}
          pdf={pdf}
        />
        <ListTable
          data={fullscreen === 'true' ? dataExpanded : data}
          selectedData={bulkSelectedData}
          rowOptions={this.prepareRowOptions()}
          linkTo="/packages"
          sortFilter={sortFilter}
          orderBy={orderBy}
          loading={mainLoading}
          generateRoute={this.generateRoute}
          handleCheckAll={this.handleCheckAll}
          handleCheckSingle={this.handleCheckSingle}
          getID={this.getID}
          moduleName="packages"
          totalRecords={this.state.totalRecords}
          className={
            fullscreen === 'true'
              ? 'package-list-main'
              : 'package-list-compressed'
          }
        />
      </Fragment>
    );
  }

  onClickHandler = () => {
    this.setState({ contactId: '', contactName: '' });
    let dataFromLS = getObjFromLS('module');
    if (dataFromLS && dataFromLS.moduleName === 'packages') {
      setObjInLS('module', {
        ...dataFromLS,
        queryParam: '?filter=all&sort=created_at&page=1&orderBy=desc&view=20',
      });
      this.props.history.push('/r');
    } else {
      this.props.history.push(routesPath.packages);
    }
  };

  renderSearchDetails() {
    const { search, contactId, contactName } = this.state;
    if (search) {
      return (
        <Alert
          className="full-width green"
          id="elasticSearch"
          title="Search Criteria"
          linkTo={routesPath.packages}
        >
          <SearchResult
            criterias={['Sales Order No', 'Customer Name', 'Package No']}
            searchKey={search.replace('%26', '&')}
          />
        </Alert>
      );
    }
    if (contactId) {
      return (
        <Alert
          className="full-width green"
          id="elasticSearch"
          title="Packages"
          linkTo={routesPath.packages}
          crossIcon={false}
          //onClickHandler={this.onClickHandler}
        >
          Displaying all results from contact "{contactName}"
        </Alert>
      );
    }
    return null;
  }

  render() {
    return this.renderTable();
  }
}

export default HeaderWrapper(
  PackageList,
  {
    name: 'Packages',
    Icon: NewPackageIcon,
    tableIcon: 'general_module_icon',
    deleteName: 'Package',
    baseUrl: 'packages',
    redirectUrl: '/packages',
    buttonUrl: '/packages/add',
    buttonLabel: 'New Package',
    routeName: 'packages',
    showDetailsComp: true,
    bypassLoadingState: false,
    permissionName: 'package',
  },
  PackageDetails,
  NewPackageCreate,
  NewPackageEdit
);
