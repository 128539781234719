import React from 'react'

import Card from './Card'


const AddCard = (
  {
    title,
    handleAdd
  }) => {
  return (
    <Card
      type="add"
      title={title}
      handleAdd={() => handleAdd()}
    />
  )
}

export default AddCard
