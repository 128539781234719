export default function getDiscountOptions(currency) {
  return (
    [
      {
        value: 'amount',
        label: currency.symbol
      },
      {
        value: 'percent',
        label: '%'
      }
    ]
  )
}