import React from 'react';
import proptypes from 'prop-types';
import './SubTabs.css';

const SubTabs = ({ tabs, currentTab, className }) => {
  function isCurrent(id) {
    return id === currentTab && 'current';
  }

  function renderTabContent() {
    let index = tabs.findIndex((tab) => tab.id === currentTab);
    return tabs[index].renderContent();
  }

  return (
    <div className="tab-component--tab">
      <div className="tab-component-sub-tab">
        <div className="tab-component--buttons border_bottom_sub_tab item-responsive-tab">
          {tabs.map((tab, index) => {
            return (
              <button
                key={index}
                className={`tab-component--button ${isCurrent(
                  tab.id
                )}`}
                onClick={() => tab.onClick()}
              >
                {tab.label}
              </button>
            );
          })}
        </div>
        <div className={`tab-component--content border-0 p-0 ${className} `}>
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};

SubTabs.propTypes = {
  tabs: proptypes.arrayOf(proptypes.object).isRequired,
  currentTab: proptypes.string.isRequired,
};

export default SubTabs;
