import React from 'react';

const ItemExpandedIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1775 8L10.5 10.4723L7.8225 8L7 8.76113L10.5 12L14 8.76113L13.1775 8Z"
        fill="#8e8e8e"
      />
    </svg>
  );
};

const ItemExpandIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 13.1775L10.4723 10.5L8 7.8225L8.76113 7L12 10.5L8.76113 14L8 13.1775Z"
        fill="#8e8e8e"
      />
    </svg>
  );
};

const AddIcon = ({ className = '' }) => {
  return (
    <svg
      width="20"
      className={className}
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.8 4H7.2V7.2H4V8.8H7.2V12H8.8V8.8H12V7.2H8.8V4ZM8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8 14.4C4.472 14.4 1.6 11.528 1.6 8C1.6 4.472 4.472 1.6 8 1.6C11.528 1.6 14.4 4.472 14.4 8C14.4 11.528 11.528 14.4 8 14.4Z"
        fill="#8e8e8e"
      />
    </svg>
  );
};
const DashboardIcon = ({ className = '' }) => {
  return (
    <svg
      width="20"
      className={className}
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {' '}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 16.2121C16 16.7641 15.552 17.0001 15 17.0001H14C13.448 17.0001 13 16.7641 13 16.2121V15.2121C13 14.1071 12.105 13.0001 11 13.0001H9C7.895 13.0001 7 14.1071 7 15.2121V16.2121C7 16.7641 6.552 17.0001 6 17.0001H5C4.448 17.0001 4 16.7641 4 16.2121V8.14913C4 8.01613 4.053 7.88913 4.146 7.79513L9.283 2.65913C9.674 2.26813 10.307 2.26813 10.697 2.65913L15.854 7.81513C15.947 7.90913 16 8.03613 16 8.16813V16.2121ZM18 7.62513C18 7.36013 17.895 7.10713 17.709 6.91913L11.41 0.58913C10.63 -0.19387 9.363 -0.19687 8.58 0.58313L2.294 6.84813C2.106 7.03613 2 7.29013 2 7.55613V17.2121C2 18.3171 2.895 19.0001 4 19.0001H7C8.105 19.0001 9 18.3171 9 17.2121V16.2121C9 15.6601 9.448 15.2121 10 15.2121C10.552 15.2121 11 15.6601 11 16.2121V17.2121C11 18.3171 11.895 19.0001 13 19.0001H16C17.105 19.0001 18 18.3171 18 17.2121V7.62513Z"
        fill="#E1E4E5"
      />{' '}
    </svg>
  );
};

const NewDashboardIcon = ({ className = '', fill = '#fff' }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="9.75"
      viewBox="0 0 15 9.75"
    >
      <path
        d="M3-4.5A1.5,1.5,0,0,0,1.5-6,1.5,1.5,0,0,0,0-4.5,1.5,1.5,0,0,0,1.5-3,1.5,1.5,0,0,0,3-4.5Zm-1.5,3a.75.75,0,0,0-.75.75A.75.75,0,0,0,1.5,0a.75.75,0,0,0,.75-.75A.75.75,0,0,0,1.5-1.5Zm0-8.25A1.5,1.5,0,0,0,0-8.25a1.5,1.5,0,0,0,1.5,1.5A1.5,1.5,0,0,0,3-8.25,1.5,1.5,0,0,0,1.5-9.75Zm3.75,4.5a.75.75,0,0,0-.75.75.75.75,0,0,0,.75.75A.75.75,0,0,0,6-4.5.75.75,0,0,0,5.25-5.25Zm0,3.75a.75.75,0,0,0-.75.75A.75.75,0,0,0,5.25,0,.75.75,0,0,0,6-.75.75.75,0,0,0,5.25-1.5Zm0-8.25a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.5-1.5A1.5,1.5,0,0,0,5.25-9.75Zm5.25,4.5a.75.75,0,0,0-.75.75.75.75,0,0,0,.75.75.75.75,0,0,0,.75-.75A.75.75,0,0,0,10.5-5.25Zm0,3.75a.75.75,0,0,0-.75.75A.75.75,0,0,0,10.5,0a.75.75,0,0,0,.75-.75A.75.75,0,0,0,10.5-1.5Zm0-8.25A1.5,1.5,0,0,0,9-8.25a1.5,1.5,0,0,0,1.5,1.5A1.5,1.5,0,0,0,12-8.25,1.5,1.5,0,0,0,10.5-9.75Zm3.75,4.5a.75.75,0,0,0-.75.75.75.75,0,0,0,.75.75A.75.75,0,0,0,15-4.5.75.75,0,0,0,14.25-5.25Zm0,3.75a.75.75,0,0,0-.75.75.75.75,0,0,0,.75.75A.75.75,0,0,0,15-.75.75.75,0,0,0,14.25-1.5Zm0-7.5a.75.75,0,0,0-.75.75.75.75,0,0,0,.75.75A.75.75,0,0,0,15-8.25.75.75,0,0,0,14.25-9Z"
        transform="translate(0 9.75)"
        fill={fill}
      />
    </svg>
  );
};

const TimeLineDashboardIcon = ({
  className = '',
  fill = 'rgb(142, 142, 142)',
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="9.75"
      viewBox="0 0 15 9.75"
    >
      <path
        d="M3-4.5A1.5,1.5,0,0,0,1.5-6,1.5,1.5,0,0,0,0-4.5,1.5,1.5,0,0,0,1.5-3,1.5,1.5,0,0,0,3-4.5Zm-1.5,3a.75.75,0,0,0-.75.75A.75.75,0,0,0,1.5,0a.75.75,0,0,0,.75-.75A.75.75,0,0,0,1.5-1.5Zm0-8.25A1.5,1.5,0,0,0,0-8.25a1.5,1.5,0,0,0,1.5,1.5A1.5,1.5,0,0,0,3-8.25,1.5,1.5,0,0,0,1.5-9.75Zm3.75,4.5a.75.75,0,0,0-.75.75.75.75,0,0,0,.75.75A.75.75,0,0,0,6-4.5.75.75,0,0,0,5.25-5.25Zm0,3.75a.75.75,0,0,0-.75.75A.75.75,0,0,0,5.25,0,.75.75,0,0,0,6-.75.75.75,0,0,0,5.25-1.5Zm0-8.25a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.5-1.5A1.5,1.5,0,0,0,5.25-9.75Zm5.25,4.5a.75.75,0,0,0-.75.75.75.75,0,0,0,.75.75.75.75,0,0,0,.75-.75A.75.75,0,0,0,10.5-5.25Zm0,3.75a.75.75,0,0,0-.75.75A.75.75,0,0,0,10.5,0a.75.75,0,0,0,.75-.75A.75.75,0,0,0,10.5-1.5Zm0-8.25A1.5,1.5,0,0,0,9-8.25a1.5,1.5,0,0,0,1.5,1.5A1.5,1.5,0,0,0,12-8.25,1.5,1.5,0,0,0,10.5-9.75Zm3.75,4.5a.75.75,0,0,0-.75.75.75.75,0,0,0,.75.75A.75.75,0,0,0,15-4.5.75.75,0,0,0,14.25-5.25Zm0,3.75a.75.75,0,0,0-.75.75.75.75,0,0,0,.75.75A.75.75,0,0,0,15-.75.75.75,0,0,0,14.25-1.5Zm0-7.5a.75.75,0,0,0-.75.75.75.75,0,0,0,.75.75A.75.75,0,0,0,15-8.25.75.75,0,0,0,14.25-9Z"
        transform="translate(0 9.75)"
        fill={fill}
      />
    </svg>
  );
};

const ContactIcon = ({ className = '' }) => {
  return (
    <svg
      width="20"
      className={className}
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 9.99998C7.7835 9.99998 5.98095 8.20598 5.98095 5.99998C5.98095 3.79398 7.7835 1.99998 10 1.99998C12.2165 1.99998 14.019 3.79398 14.019 5.99998C14.019 8.20598 12.2165 9.99998 10 9.99998ZM13.7759 10.673C15.3704 9.39598 16.2999 7.33098 15.9582 5.06998C15.5614 2.44698 13.369 0.347977 10.7224 0.041977C7.07012 -0.381023 3.97143 2.44898 3.97143 5.99998C3.97143 7.88998 4.8516 9.57398 6.22411 10.673C2.85213 11.934 0.390463 14.895 0.0046342 18.891C-0.0516324 19.482 0.411563 20 1.00839 20C1.51981 20 1.95588 19.616 2.0011 19.109C2.404 14.646 5.83727 12 10 12C14.1627 12 17.596 14.646 17.9989 19.109C18.0441 19.616 18.4802 20 18.9916 20C19.5884 20 20.0516 19.482 19.9954 18.891C19.6095 14.895 17.1479 11.934 13.7759 10.673Z"
        fill="#E1E4E5"
      />
    </svg>
  );
};
const NewContactIcon = ({
  className = '',
  fill = '#fff',
  width = '12',
  height = '12',
}) => {
  className = className + ' new_contact_icon';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 15 10.5"
    >
      <path
        d="M2.25-5.25a1.5,1.5,0,0,0,1.5-1.5,1.5,1.5,0,0,0-1.5-1.5,1.5,1.5,0,0,0-1.5,1.5A1.5,1.5,0,0,0,2.25-5.25Zm10.5,0a1.5,1.5,0,0,0,1.5-1.5,1.5,1.5,0,0,0-1.5-1.5,1.5,1.5,0,0,0-1.5,1.5A1.5,1.5,0,0,0,12.75-5.25Zm.75.75H12a1.5,1.5,0,0,0-1.057.436A3.428,3.428,0,0,1,12.7-1.5H14.25A.749.749,0,0,0,15-2.25V-3A1.5,1.5,0,0,0,13.5-4.5Zm-6,0a2.624,2.624,0,0,0,2.625-2.625A2.624,2.624,0,0,0,7.5-9.75,2.624,2.624,0,0,0,4.875-7.125,2.624,2.624,0,0,0,7.5-4.5Zm1.8.75H9.105A3.692,3.692,0,0,1,7.5-3.375,3.7,3.7,0,0,1,5.895-3.75H5.7A2.7,2.7,0,0,0,3-1.05v.675A1.125,1.125,0,0,0,4.125.75h6.75A1.125,1.125,0,0,0,12-.375V-1.05A2.7,2.7,0,0,0,9.3-3.75ZM4.057-4.064A1.5,1.5,0,0,0,3-4.5H1.5A1.5,1.5,0,0,0,0-3v.75a.749.749,0,0,0,.75.75H2.295A3.437,3.437,0,0,1,4.057-4.064Z"
        transform="translate(0 9.75)"
        fill={fill}
      />
    </svg>
  );
};

const ItemIcon = ({ className = '' }) => {
  return (
    <svg
      width="20"
      className={className}
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.97773 5.02015C3.97773 4.44427 4.44508 3.97689 5.02093 3.97689C5.59678 3.97689 6.06413 4.44427 6.06413 5.02015C6.06413 5.59603 5.59678 6.0634 5.02093 6.0634C4.44508 6.0634 3.97773 5.59603 3.97773 5.02015ZM11.2749 17.1762L2.23975 8.14053C2.14169 8.04246 2.08745 7.90997 2.08745 7.77121V3.12977C2.08745 2.55285 2.55376 2.08547 3.13065 2.08547H7.77185C7.9106 2.08547 8.04308 2.14076 8.14115 2.23883L17.1763 11.2745C17.5832 11.6824 17.5832 12.3428 17.1763 12.7496L12.75 17.1762C12.3432 17.583 11.6828 17.583 11.2749 17.1762ZM15.9339 7.08162C15.7607 6.9074 8.98405 0.13145 9.15827 0.304631C8.96214 0.109542 8.69717 0 8.42072 0H2.08745C0.934709 0 0 0.933714 0 2.08547V8.42012C0 8.69658 0.110579 8.96157 0.305658 9.1577L10.5374 19.3889C11.3521 20.2037 12.6728 20.2037 13.4876 19.3889C13.6732 19.2032 19.4547 13.4225 19.3889 13.4872C20.2037 12.6724 20.2037 11.3517 19.3889 10.5369L15.9339 7.08162Z"
        fill="#E1E4E5"
      />
    </svg>
  );
};

const NewItemIcon = ({ className = '', fill = '#fff' }) => {
  className = className + ' new_item_icon';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="12"
      viewBox="0 0 15 12"
    >
      <path
        d="M11.671-5.2,6.7-10.171a1.122,1.122,0,0,0-.8-.329H1.125A1.125,1.125,0,0,0,0-9.375v4.784a1.122,1.122,0,0,0,.329.8L5.3,1.171a1.125,1.125,0,0,0,1.591,0l4.784-4.784A1.125,1.125,0,0,0,11.671-5.2ZM2.625-6.75A1.125,1.125,0,0,1,1.5-7.875,1.125,1.125,0,0,1,2.625-9,1.125,1.125,0,0,1,3.75-7.875,1.125,1.125,0,0,1,2.625-6.75ZM14.671-3.614a1.125,1.125,0,0,0,0-1.591L9.7-10.171a1.122,1.122,0,0,0-.8-.329H7.767l4.6,4.6a2.1,2.1,0,0,1,.618,1.492,2.1,2.1,0,0,1-.618,1.492L8.287,1.162l.008.008a1.125,1.125,0,0,0,1.591,0Z"
        transform="translate(0 10.5)"
        fill={fill}
      />
    </svg>
  );
};

const NewItemAdjustmentIcon = ({ className = '' }) => {
  className = className + ' new_item_adjustment_icon';

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="12"
      viewBox="0 0 108.5 108.5"
    >
      <path
        className="a"
        d="M1.75-42A54.25,54.25,0,0,0,56,12.25,54.25,54.25,0,0,0,110.25-42,54.25,54.25,0,0,0,56-96.25,54.25,54.25,0,0,0,1.75-42ZM56-1.75v-80.5A40.228,40.228,0,0,1,96.25-42,40.228,40.228,0,0,1,56-1.75Z"
        transform="translate(-1.75 96.25)"
      />
    </svg>
  );
};

const SalesOrderIcon = ({ className = '' }) => {
  return (
    <svg
      width="20"
      className={className}
      height="20"
      viewBox="0 0 39.001 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="a"
        d="M38.931-5.271,36.9-17.459a2.438,2.438,0,0,0-2.407-2.034H15.844v-4.875h7.313a1.222,1.222,0,0,0,1.219-1.219v-7.32a1.222,1.222,0,0,0-1.219-1.219H3.656a1.222,1.222,0,0,0-1.219,1.219v7.313a1.222,1.222,0,0,0,1.219,1.219h7.313V-19.5H4.5a2.444,2.444,0,0,0-2.407,2.034L.061-5.279a4.729,4.729,0,0,0-.069.8V2.438A2.435,2.435,0,0,0,2.43,4.875H36.555a2.435,2.435,0,0,0,2.438-2.437V-4.471A4.043,4.043,0,0,0,38.931-5.271Zm-17.6-9.963a1.222,1.222,0,0,1,1.219-1.219h1.219a1.222,1.222,0,0,1,1.219,1.219v1.219A1.222,1.222,0,0,1,23.766-12.8H22.547a1.222,1.222,0,0,1-1.219-1.219Zm-2.437,4.875h1.219a1.222,1.222,0,0,1,1.219,1.219v1.219A1.222,1.222,0,0,1,20.109-6.7H18.891a1.222,1.222,0,0,1-1.219-1.219V-9.141A1.222,1.222,0,0,1,18.891-10.359Zm-2.437-6.094a1.222,1.222,0,0,1,1.219,1.219v1.219A1.222,1.222,0,0,1,16.453-12.8H15.234a1.222,1.222,0,0,1-1.219-1.219v-1.219a1.222,1.222,0,0,1,1.219-1.219ZM6.094-28.031v-2.437H20.719v2.438ZM9.141-12.8H7.922A1.222,1.222,0,0,1,6.7-14.016v-1.219a1.222,1.222,0,0,1,1.219-1.219H9.141a1.222,1.222,0,0,1,1.219,1.219v1.219A1.222,1.222,0,0,1,9.141-12.8Zm1.219,4.875V-9.141a1.222,1.222,0,0,1,1.219-1.219H12.8a1.222,1.222,0,0,1,1.219,1.219v1.219A1.222,1.222,0,0,1,12.8-6.7H11.578A1.222,1.222,0,0,1,10.359-7.922ZM26.813.609a.611.611,0,0,1-.609.609H12.8a.611.611,0,0,1-.609-.609V-.609a.611.611,0,0,1,.609-.609H26.2a.611.611,0,0,1,.609.609Zm1.828-8.531A1.222,1.222,0,0,1,27.422-6.7H26.2a1.222,1.222,0,0,1-1.219-1.219V-9.141A1.222,1.222,0,0,1,26.2-10.359h1.219a1.222,1.222,0,0,1,1.219,1.219ZM32.3-14.016A1.222,1.222,0,0,1,31.078-12.8H29.859a1.222,1.222,0,0,1-1.219-1.219v-1.219a1.222,1.222,0,0,1,1.219-1.219h1.219A1.222,1.222,0,0,1,32.3-15.234Z"
        transform="translate(0.008 34.125)"
      />
    </svg>
  );
};

const NewSalesOrderIcon = ({ className = '', fill = '#fff' }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <path
        d="M11.979-1.622l-.626-3.75A.75.75,0,0,0,10.612-6H4.875V-7.5h2.25A.376.376,0,0,0,7.5-7.873v-2.252a.376.376,0,0,0-.375-.375h-6a.376.376,0,0,0-.375.375v2.25a.376.376,0,0,0,.375.375h2.25V-6H1.385a.752.752,0,0,0-.741.626L.019-1.624A1.455,1.455,0,0,0,0-1.378V.75a.749.749,0,0,0,.75.75h10.5A.749.749,0,0,0,12,.75V-1.376A1.244,1.244,0,0,0,11.979-1.622ZM6.563-4.687a.376.376,0,0,1,.375-.375h.375a.376.376,0,0,1,.375.375v.375a.376.376,0,0,1-.375.375H6.938a.376.376,0,0,1-.375-.375Zm-.75,1.5h.375a.376.376,0,0,1,.375.375v.375a.376.376,0,0,1-.375.375H5.813a.376.376,0,0,1-.375-.375v-.375A.376.376,0,0,1,5.813-3.187Zm-.75-1.875a.376.376,0,0,1,.375.375v.375a.376.376,0,0,1-.375.375H4.688a.376.376,0,0,1-.375-.375v-.375a.376.376,0,0,1,.375-.375ZM1.875-8.625v-.75h4.5v.75Zm.938,4.688H2.438a.376.376,0,0,1-.375-.375v-.375a.376.376,0,0,1,.375-.375h.375a.376.376,0,0,1,.375.375v.375A.376.376,0,0,1,2.813-3.937Zm.375,1.5v-.375a.376.376,0,0,1,.375-.375h.375a.376.376,0,0,1,.375.375v.375a.376.376,0,0,1-.375.375H3.563A.376.376,0,0,1,3.188-2.437ZM8.25.188a.188.188,0,0,1-.187.188H3.938A.188.188,0,0,1,3.75.188V-.187a.188.188,0,0,1,.188-.187H8.063a.188.188,0,0,1,.188.188Zm.563-2.625a.376.376,0,0,1-.375.375H8.063a.376.376,0,0,1-.375-.375v-.375a.376.376,0,0,1,.375-.375h.375a.376.376,0,0,1,.375.375ZM9.938-4.312a.376.376,0,0,1-.375.375H9.188a.376.376,0,0,1-.375-.375v-.375a.376.376,0,0,1,.375-.375h.375a.376.376,0,0,1,.375.375Z"
        transform="translate(0.002 10.5)"
        fill={fill}
      />
    </svg>
  );
};
const SalesReturnIcon = ({ className = '' }) => {
  return (
    <svg
      width="20"
      className={className}
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="a"
        d="M4.6,16.5v-5.9H3c-0.4,0-0.8-0.4-0.8-0.8V9.1c0-1.6,1.1-2.7,2.7-2.7h4.9c1.6,0,2.7,1.1,2.7,2.7V15h1.6
	c0.4,0,0.8,0.4,0.8,0.8v0.6c0,1.4-1.3,2.7-2.7,2.7H7.3C7.3,19.1,4.5,19.2,4.6,16.5z M6.3,16.6c0,0.5,0.3,0.9,0.8,1v-1
	c0-0.9,0.7-1.6,1.6-1.6h2.2V9.1c0-0.6-0.5-1-1-1H6.3V16.6z M12.2,17.5c0.5,0,0.9-0.3,1-0.8h-4c-0.1,0-0.3,0.2-0.3,0.3v0.5H12.2z
	 M4.6,8.9V8.1C4.3,8.2,4,8.5,3.9,8.9H4.6z M7.3,13c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8h2.4c0.4,0,0.8,0.4,0.8,0.8
	c0,0.4-0.4,0.8-0.8,0.8H7.3z M7.3,10.6c-0.4,0-0.8-0.4-0.8-0.8C6.5,9.4,6.9,9,7.3,9h2.4c0.5,0,1,0.4,1,0.8c0,0.4-0.4,0.8-0.8,0.8
	H7.3z"
      />
      <path
        className="a"
        d="M14.4,11.5c-0.4,0-0.8-0.3-0.8-0.7S14,10,14.4,10h1.2c0.1,0,0.2,0,0.2,0V4.1c0-0.1-0.1-0.2-0.2-0.2h-4.3
	c0,0.1,0,0.1,0,0.2c0.1,0.3,0.1,0.6,0.3,0.8c0.1,0.1,0.1,0.2,0,0.3c-0.1,0.1-0.1,0.1-0.2,0.1h-0.1L7.2,3C7.1,3,7.1,2.9,7.1,2.8
	c0-0.1,0.1-0.2,0.1-0.2l4-2.2h0.1c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.1,0.2,0.1,0.3c-0.2,0.4-0.4,0.9-0.4,1.3H16c0.4,0,0.8,0.2,1.1,0.5
	c0.2,0.2,0.3,0.5,0.2,0.8v6.8c0,0.7-0.5,1.3-1.3,1.3L14.4,11.5z"
      />
    </svg>
  );
};

const NewSalesReturnIcon = ({ className = '', fill = '#ffff' }) => {
  className = className + ' new_sales_return';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="12.599"
      height="15.563"
      viewBox="0 0 12.599 15.563"
    >
      <g transform="translate(-817.914 -531)">
        <g transform="translate(817.914 531)">
          <path
            d="M4.2,14.805V9.9H2.866A.716.716,0,0,1,2.2,9.23V8.647A2.141,2.141,0,0,1,4.447,6.4H8.525a2.141,2.141,0,0,1,2.247,2.247v4.91H12.1a.716.716,0,0,1,.666.666v.5a2.352,2.352,0,0,1-2.247,2.247H6.444S4.114,17.053,4.2,14.805Zm1.415.083a.81.81,0,0,0,.666.832v-.832a1.315,1.315,0,0,1,1.332-1.332H9.44V8.647a.834.834,0,0,0-.832-.832h-3Zm4.91.749a.81.81,0,0,0,.832-.666H8.026a.375.375,0,0,0-.25.25v.416ZM4.2,8.481V7.815a.951.951,0,0,0-.583.666Zm2.247,3.412a.666.666,0,0,1,0-1.332h2a.666.666,0,0,1,0,1.332Zm0-2a.716.716,0,0,1-.666-.666.716.716,0,0,1,.666-.666h2a.822.822,0,0,1,.832.666.716.716,0,0,1-.666.666Z"
            transform="translate(-2.2 -1.407)"
            fill={fill}
          />
          <path
            d="M13.175,9.638a.643.643,0,0,1-.666-.583.716.716,0,0,1,.666-.666H14.34V3.479a.179.179,0,0,0-.166-.166H10.6v.166c.083.25.083.5.25.666a.156.156,0,0,1,0,.25c-.083.083-.083.083-.166.083H10.6L7.183,2.564C7.1,2.564,7.1,2.481,7.1,2.4a.291.291,0,0,1,.083-.166L10.512.4H10.6c.083,0,.083,0,.166.083a.306.306,0,0,1,.083.25,2.712,2.712,0,0,0-.333,1.082h3.995a1.336,1.336,0,0,1,.915.416.647.647,0,0,1,.166.666V8.556a1.049,1.049,0,0,1-1.082,1.082Z"
            transform="translate(-3.022 -0.4)"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
};
const SalesReturnReceiveIcon = ({ className = '' }) => {
  return (
    <svg
      width="20"
      className={className}
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="a"
        d="M8.1,8.2h3.9c0.5,0,0.9,0.5,0.9,0.9c0,0.5-0.5,0.9-0.9,0.9H8.1c-0.7,0-1.1-0.5-1.1-0.9
	C6.9,8.5,7.4,8.2,8.1,8.2z M12.9,5.3c0,0.5-0.5,0.9-0.9,0.9H8.1c-0.5,0-0.9-0.5-0.9-0.9c0-0.5,0.5-0.9,0.9-0.9h3.9
	C12.4,4.4,12.9,4.6,12.9,5.3z M2.1,4.4C2.1,3.2,3,2.5,4,2.5v1.8H2.1z M6,2.3h5.7c1.1,0,1.8,0.9,1.8,1.8v9.6H9.9
	c-1.1,0-1.8,0.9-1.8,1.8v1.8C6.9,17.8,6,16.9,6,15.8V2.3z M9.9,16.9c0-0.5,0.5-0.9,0.9-0.9h6.8c0,1.1-0.9,1.8-1.8,1.8H9.9V16.9z
	 M0.3,4.4v0.9c0,0.5,0.5,0.9,0.9,0.9h3v9.6c0,2.1,1.8,3.9,3.9,3.9h7.8c2.1,0,3.9-1.8,3.9-3.9v-0.9c0-0.5-0.5-0.9-0.9-0.9h-3V4.4
	c0-2.1-1.8-3.9-3.9-3.9H4.2C1.9,0.5,0.3,2.1,0.3,4.4z"
      />
      <g>
        <path
          className="a"
          d="M3.8,9.9L1.5,8.7c0.1,0.3,0.2,0.6,0.3,0.8H0.5c-0.2,0-0.3,0.2-0.3,0.3V10c0,0.1,0.1,0.2,0.3,0.2h1.1
		c0,0.3-0.1,0.6-0.3,0.9L3.8,9.9z"
        />
        <path
          className="a"
          d="M18.6,11.1c-0.2-0.3-0.3-0.6-0.3-0.9h1.1c0.2,0,0.3-0.1,0.3-0.2V9.9c0-0.2-0.1-0.3-0.3-0.3h-1.2
		c0.1-0.3,0.2-0.6,0.3-0.8l-2.3,1.2L18.6,11.1z"
        />
      </g>
    </svg>
  );
};
const CreditNoteIcon = ({ className = '' }) => {
  return (
    <svg
      width="24"
      style={{ height: '22px', width: '22px' }}
      className={className}
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          className="a"
          d="M15.8,0.5H4.2C3,0.5,2,1.5,2,2.7v14.5c0,1.2,1,2.2,2.2,2.2h8.9l4.8-5.7V2.7C18,1.5,17,0.5,15.8,0.5z
		 M12.2,17.9V14h3.9L12.2,17.9z M16.6,12.7h-4c-0.9,0-1.6,0.7-1.6,1.6v3.6H4c-0.3,0-0.6-0.3-0.6-0.6V2.7c0-0.3,0.3-0.6,0.6-0.6H16
		c0.3,0,0.6,0.3,0.6,0.6V12.7z"
        />
        <path
          className="a"
          d="M12.4,7.3c0.2,0,0.6-0.1,0.6-0.6C13,6.2,12.6,6,12.4,6h-1.9V4.8c0-0.1,0.1-0.5-0.5-0.5
		c-0.5,0-0.5,0.4-0.5,0.5v1.5H7.6C7,6.7,7,6.7,7,6.7v2.1c0.3,0.3,0.3,0.3,0.6,0.6h4.2v0.8H7.6c-0.2,0-0.6,0.2-0.6,0.6
		c0,0.6,0.4,0.6,0.6,0.6h1.9v1.5c0,0.2,0.1,0.6,0.6,0.6c0.5,0,0.6-0.4,0.6-0.6v-1.5h1.9c0.2,0,0.2,0,0.4-0.2c0-0.2,0-0.2,0-0.4v-2
		c0-0.2,0-0.2-0.2-0.4s-0.2-0.3-0.4-0.3H8.2V7.3H12.4z"
        />
      </g>
    </svg>
  );
};

const PackageIcon = ({ className = '' }) => {
  return (
    <svg
      width="20"
      className={className}
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.18011 1H15.8199L18 6.66263V19H2V6.66263L4.18011 1ZM5.55322 3L4.45655 5.84848H15.5434L14.4468 3H5.55322ZM16 7.84848H4V17H16V7.84848Z"
        fill="#E1E4E5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 6.84848V2H11V6.84848H9Z"
        fill="#E1E4E5"
      />
    </svg>
  );
};

const NewPackageIcon = ({ className = '', fill = '#ffff' }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="15.001"
      height="10.5"
      viewBox="0 0 15.001 10.5"
    >
      <path
        d="M9.977-4.5a1.136,1.136,0,0,1-.97-.548L7.5-7.547,6-5.048a1.14,1.14,0,0,1-.973.551,1.078,1.078,0,0,1-.312-.045L1.5-5.461v4.172a.748.748,0,0,0,.567.727L7.134.705a1.524,1.524,0,0,0,.727,0L12.933-.562a.752.752,0,0,0,.567-.727V-5.461l-3.211.916A1.077,1.077,0,0,1,9.977-4.5ZM14.96-7.13,13.753-9.539a.383.383,0,0,0-.391-.209L7.5-9,9.649-5.435a.385.385,0,0,0,.434.171l4.638-1.324A.386.386,0,0,0,14.96-7.13ZM1.247-9.539.04-7.13a.382.382,0,0,0,.237.539L4.915-5.266a.385.385,0,0,0,.434-.171L7.5-9,1.636-9.748A.383.383,0,0,0,1.247-9.539Z"
        transform="translate(0.001 9.751)"
        fill={fill}
      />
    </svg>
  );
};

const InvoiceIcon = ({ className = '' }) => {
  return (
    <svg
      width="20"
      className={className}
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 9C13 9.552 12.552 10 12 10H8C7.448 10 7 9.552 7 9C7 8.448 7.448 8 8 8H12C12.552 8 13 8.448 13 9ZM8 4H12C12.552 4 13 4.448 13 5C13 5.552 12.552 6 12 6H8C7.448 6 7 5.552 7 5C7 4.448 7.448 4 8 4ZM16 4V2C17.103 2 18 2.897 18 4H16ZM14 16C14 17.103 13.103 18 12 18V16C12 14.895 11.105 14 10 14H6V4C6 2.895 6.895 2 8 2H14V16ZM10 18H4C2.897 18 2 17.103 2 16H9C9.552 16 10 16.448 10 17V18ZM16 0H8C5.791 0 4 1.791 4 4V14H1C0.448 14 0 14.448 0 15V16C0 18.209 1.791 20 4 20H12C14.209 20 16 18.209 16 16V6H19C19.552 6 20 5.552 20 5V4C20 1.791 18.209 0 16 0Z"
        fill="#E1E4E5"
      />
    </svg>
  );
};

const NewInvoiceIcon = ({ className = '', fill = '#ffff' }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="12"
      viewBox="0 0 9 12"
    >
      <path
        d="M8.836-8.039l-2.295-2.3a.562.562,0,0,0-.4-.164H6v3H9v-.143A.561.561,0,0,0,8.836-8.039ZM5.25-7.312V-10.5H.563A.561.561,0,0,0,0-9.937V.938A.561.561,0,0,0,.563,1.5H8.438A.561.561,0,0,0,9,.938V-6.75H5.813A.564.564,0,0,1,5.25-7.312ZM1.5-8.812A.187.187,0,0,1,1.688-9H3.563a.187.187,0,0,1,.188.188v.375a.187.187,0,0,1-.187.188H1.688A.187.187,0,0,1,1.5-8.437Zm0,1.875v-.375A.187.187,0,0,1,1.688-7.5H3.563a.187.187,0,0,1,.188.188v.375a.187.187,0,0,1-.187.188H1.688A.187.187,0,0,1,1.5-6.937ZM4.875-.753v.565A.187.187,0,0,1,4.688,0H4.313a.187.187,0,0,1-.187-.187V-.757a1.342,1.342,0,0,1-.735-.266.188.188,0,0,1-.013-.285l.275-.263a.192.192,0,0,1,.237-.017.564.564,0,0,0,.3.087h.659a.3.3,0,0,0,.277-.309.306.306,0,0,0-.206-.3L3.865-2.424a1.061,1.061,0,0,1-.74-1.017,1.044,1.044,0,0,1,1-1.056v-.565a.187.187,0,0,1,.188-.187h.375a.187.187,0,0,1,.188.188v.569a1.34,1.34,0,0,1,.735.266.188.188,0,0,1,.013.285l-.275.263a.192.192,0,0,1-.237.017.562.562,0,0,0-.3-.087H4.151a.3.3,0,0,0-.277.309.306.306,0,0,0,.206.3l1.055.316a1.061,1.061,0,0,1,.74,1.017A1.044,1.044,0,0,1,4.875-.753Z"
        transform="translate(0 10.5)"
        fill={fill}
      />
    </svg>
  );
};

const PurchaseOrderIcon = ({ className = '' }) => {
  return (
    <svg
      width="20"
      className={className}
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8464 18H3.15345C2.54445 18 2.07745 17.461 2.16345 16.858C3.49145 7.557 2.90745 8 4.99945 8C4.99945 9.639 4.75145 11 5.99945 11C6.55245 11 6.99945 10.552 6.99945 10V8H12.9994V10C12.9994 10.552 13.4474 11 13.9994 11C14.5524 11 14.9994 10.552 14.9994 10V8C15.8364 8 16.5624 7.94 16.6934 8.858L17.8364 16.858C17.9224 17.461 17.4544 18 16.8464 18ZM6.99945 5C6.99945 3.345 8.34545 2 9.99945 2C11.6544 2 12.9994 3 12.9994 5V6H6.99945V5ZM19.9796 17.717L18.5505 7.717C18.2675 5.733 16.2355 6 14.9995 6V5C14.9995 2 12.7565 0 9.9995 0C7.24249 0 4.99947 2.243 4.99947 5V6H3.73447C2.73946 6 1.58946 6.732 1.44846 7.717L0.0204481 17.717C-0.151553 18.922 0.783452 20 2.00046 20H17.9995C19.2165 20 20.1516 18.922 19.9796 17.717Z"
        fill="#E1E4E5"
      />
    </svg>
  );
};

const NewPurchaseOrderIcon = ({ className = '', fill = '#fff' }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="10.5"
      height="12"
      viewBox="0 0 10.5 12"
    >
      <path
        d="M8.25-6.75V-7.5a3,3,0,0,0-3-3,3,3,0,0,0-3,3v.75H0V-.375A1.875,1.875,0,0,0,1.875,1.5h6.75A1.875,1.875,0,0,0,10.5-.375V-6.75ZM3.75-7.5A1.5,1.5,0,0,1,5.25-9a1.5,1.5,0,0,1,1.5,1.5v.75h-3ZM7.5-4.687a.562.562,0,0,1-.562-.562A.562.562,0,0,1,7.5-5.812a.562.562,0,0,1,.563.563A.562.562,0,0,1,7.5-4.687Zm-4.5,0a.562.562,0,0,1-.562-.562A.562.562,0,0,1,3-5.812a.562.562,0,0,1,.563.563A.562.562,0,0,1,3-4.687Z"
        transform="translate(0 10.5)"
        fill={fill}
      />
    </svg>
  );
};

const BillIcon = ({ className = '' }) => {
  return (
    <svg
      width="20"
      className={className}
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20L7.14286 18.0198L4.57143 19.802H4.28571C3.68204 19.7935 3.10537 19.5405 2.67847 19.0966C2.25156 18.6528 2.00813 18.0533 2 17.4257V2.37624C2.00813 1.74865 2.25156 1.14915 2.67847 0.705335C3.10537 0.261524 3.68204 0.00845608 4.28571 0L15.7143 0C16.318 0.00845608 16.8946 0.261524 17.3215 0.705335C17.7484 1.14915 17.9919 1.74865 18 2.37624V17.5248C17.9919 18.1523 17.7484 18.7518 17.3215 19.1957C16.8946 19.6395 16.318 19.8925 15.7143 19.901H15.4286L12.8571 18.0198L10 20ZM12.8571 15.9406L15.9048 18.0198C16.0158 17.9684 16.1105 17.8852 16.1779 17.7801C16.2454 17.6749 16.2827 17.5521 16.2857 17.4257V2.37624C16.2757 2.22212 16.2123 2.077 16.1072 1.96777C16.0021 1.85854 15.8625 1.7926 15.7143 1.78218H4.28571C4.20981 1.77882 4.13406 1.79188 4.06327 1.82055C3.99248 1.84921 3.92818 1.89285 3.87446 1.9487C3.82074 2.00455 3.77876 2.07139 3.75119 2.14498C3.72362 2.21858 3.71105 2.29733 3.71429 2.37624V17.5248C3.71726 17.6511 3.75463 17.774 3.82206 17.8791C3.88949 17.9842 3.98421 18.0674 4.09524 18.1188L7.14286 15.9406L10 17.9208L12.8571 15.9406Z"
        fill="#E1E4E5"
      />
      <path
        d="M14.381 5.04932H5.61914V6.83149H14.381V5.04932Z"
        fill="#E1E4E5"
      />
      <path
        d="M14.381 9.00977H5.61914V10.7919H14.381V9.00977Z"
        fill="#E1E4E5"
      />
      <path
        d="M14.381 12.9702H5.61914V14.7524H14.381V12.9702Z"
        fill="#E1E4E5"
      />
    </svg>
  );
};

const NewBillIcon = ({ className = '', fill = '#fff' }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="12"
      viewBox="0 0 15 12"
    >
      <path
        d="M1.125-10.5A1.126,1.126,0,0,0,0-9.375v1.5A.375.375,0,0,0,.375-7.5H2.25V-9.375A1.126,1.126,0,0,0,1.125-10.5ZM6-.83V-2.25h6.75v-6A2.252,2.252,0,0,0,10.5-10.5H2.615A1.857,1.857,0,0,1,3-9.375V-.75A1.5,1.5,0,0,0,4.752.729,1.556,1.556,0,0,0,6-.83Zm.75-.67v.75A2.253,2.253,0,0,1,4.5,1.5h7.875A2.625,2.625,0,0,0,15-1.125a.375.375,0,0,0-.375-.375Z"
        transform="translate(0 10.5)"
        fill={fill}
      />
    </svg>
  );
};

const PaymentIcon = ({ className = '' }) => (
  <svg
    width="64"
    className={className}
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.6 28.8C41.6 30.5664 40.1664 32 38.4 32H25.6C23.8336 32 22.4 30.5664 22.4 28.8C22.4 27.0336 23.8336 25.6 25.6 25.6H38.4C40.1664 25.6 41.6 27.0336 41.6 28.8ZM25.6 12.8H38.4C40.1664 12.8 41.6 14.2336 41.6 16C41.6 17.7664 40.1664 19.2 38.4 19.2H25.6C23.8336 19.2 22.4 17.7664 22.4 16C22.4 14.2336 23.8336 12.8 25.6 12.8ZM51.2 12.8V6.4C54.7296 6.4 57.6 9.2704 57.6 12.8H51.2ZM44.8 51.2C44.8 54.7296 41.9296 57.6 38.4 57.6V51.2C38.4 47.664 35.536 44.8 32 44.8H19.2V12.8C19.2 9.264 22.064 6.4 25.6 6.4H44.8V51.2ZM32 57.6H12.8C9.2704 57.6 6.4 54.7296 6.4 51.2H28.8C30.5664 51.2 32 52.6336 32 54.4V57.6ZM51.2 0H25.6C18.5312 0 12.8 5.7312 12.8 12.8V44.8H3.2C1.4336 44.8 0 46.2336 0 48V51.2C0 58.2688 5.7312 64 12.8 64H38.4C45.4688 64 51.2 58.2688 51.2 51.2V19.2H60.8C62.5664 19.2 64 17.7664 64 16V12.8C64 5.7312 58.2688 0 51.2 0Z"
      fill="#ADB6BC"
    />
  </svg>
);

const ReportIcon = ({ className = '' }) => {
  return (
    <svg
      width="20"
      className={className}
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7143 20H4.28571C3.68204 19.9915 3.10537 19.7359 2.67847 19.2876C2.25156 18.8394 2.00813 18.2339 2 17.6V2.4C2.00813 1.76614 2.25156 1.16064 2.67847 0.712388C3.10537 0.264139 3.68204 0.00854064 4.28571 0L15.7143 0C16.318 0.00854064 16.8946 0.264139 17.3215 0.712388C17.7484 1.16064 17.9919 1.76614 18 2.4V17.7C17.9717 18.3177 17.719 18.9005 17.2937 19.3284C16.8685 19.7562 16.3032 19.9966 15.7143 20ZM4.28571 1.8C4.20981 1.7966 4.13406 1.8098 4.06327 1.83875C3.99248 1.8677 3.92818 1.91178 3.87446 1.96818C3.82074 2.02459 3.77876 2.0921 3.75119 2.16643C3.72362 2.24076 3.71105 2.3203 3.71429 2.4V17.7C3.72431 17.8557 3.78774 18.0022 3.89281 18.1126C3.99787 18.2229 4.13746 18.2895 4.28571 18.3H15.7143C15.8625 18.2895 16.0021 18.2229 16.1072 18.1126C16.2123 18.0022 16.2757 17.8557 16.2857 17.7V2.4C16.2757 2.24434 16.2123 2.09777 16.1072 1.98745C16.0021 1.87712 15.8625 1.81053 15.7143 1.8H4.28571Z"
        fill="#E1E4E5"
      />
      <path d="M5 12L5 16L6.98018 16L6.98018 12L5 12Z" fill="#E1E4E5" />
      <path
        d="M8.96045 10L8.96045 16L10.9406 16L10.9406 10L8.96045 10Z"
        fill="#E1E4E5"
      />
      <path
        d="M13.02 8L13.02 16L15.0002 16L15.0002 8L13.02 8Z"
        fill="#E1E4E5"
      />
    </svg>
  );
};

const NewReportIcon = ({ className = '', fill = '#fff' }) => {
  className = className + ' new_report_icon';
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="9"
      viewBox="0 0 12 9"
    >
      <path
        d="M11.625-1.5H1.5V-8.625A.375.375,0,0,0,1.125-9H.375A.375.375,0,0,0,0-8.625V-.75A.75.75,0,0,0,.75,0H11.625A.375.375,0,0,0,12-.375v-.75A.375.375,0,0,0,11.625-1.5Zm-.75-6.75H8.108a.562.562,0,0,0-.4.96l.759.759L6.75-4.811,5.03-6.53a.75.75,0,0,0-1.061,0L2.36-4.92a.375.375,0,0,0,0,.53l.53.53a.375.375,0,0,0,.53,0L4.5-4.939,6.22-3.22a.75.75,0,0,0,1.061,0L9.53-5.47l.759.759a.562.562,0,0,0,.96-.4V-7.875A.375.375,0,0,0,10.875-8.25Z"
        transform="translate(0 9)"
        fill={fill}
      />
    </svg>
  );
};

const OrganizationIcon = ({ className = '' }) => {
  return (
    <svg
      width="20"
      className={className}
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 8H11V6H9V8ZM9 12H11V10H9V12ZM11 18V14H9V18H6V5H9H11H14V18H11ZM18 18H16V3H14H11V0H9V3H6H4V18H2H0V20H4H6H14H16H20V18H18Z"
        fill="#E1E4E5"
      />
    </svg>
  );
};
const NewOrganizationIcon = ({ className = '', fill = '#fff' }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="10.5"
      height="12"
      viewBox="0 0 10.5 12"
    >
      <path
        d="M3-7.031a.282.282,0,0,0,.281.281h.938A.282.282,0,0,0,4.5-7.031v-.937a.282.282,0,0,0-.281-.281H3.281A.282.282,0,0,0,3-7.969Zm3.281.281h.938A.282.282,0,0,0,7.5-7.031v-.937a.282.282,0,0,0-.281-.281H6.281A.282.282,0,0,0,6-7.969v.938A.282.282,0,0,0,6.281-6.75Zm-3,2.25h.938A.282.282,0,0,0,4.5-4.781v-.937A.282.282,0,0,0,4.219-6H3.281A.282.282,0,0,0,3-5.719v.938A.282.282,0,0,0,3.281-4.5Zm3,0h.938A.282.282,0,0,0,7.5-4.781v-.937A.282.282,0,0,0,7.219-6H6.281A.282.282,0,0,0,6-5.719v.938A.282.282,0,0,0,6.281-4.5ZM4.5-2.531v-.937a.282.282,0,0,0-.281-.281H3.281A.282.282,0,0,0,3-3.469v.938a.282.282,0,0,0,.281.281h.938A.282.282,0,0,0,4.5-2.531Zm1.781.281h.938A.282.282,0,0,0,7.5-2.531v-.937a.282.282,0,0,0-.281-.281H6.281A.282.282,0,0,0,6-3.469v.938A.282.282,0,0,0,6.281-2.25ZM10.5.656a.282.282,0,0,0-.281-.281H9.762V-9.937A.561.561,0,0,0,9.2-10.5H1.3a.561.561,0,0,0-.562.563V.375H.281A.282.282,0,0,0,0,.656V1.5H10.5ZM1.863.352l.012-9.727,6.762.023v9.7H6v-1.57A.282.282,0,0,0,5.719-1.5H4.781a.282.282,0,0,0-.281.281V.352Z"
        transform="translate(0 10.5)"
        fill={fill}
      />
    </svg>
  );
};

const InventoryIcon = ({ className = '' }) => {
  return (
    <svg
      width="20"
      height="20"
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.66666 9.58334C2.11437 9.58334 1.66666 10.0311 1.66666 10.5833V16.0833C1.66666 16.6356 2.11437 17.0833 2.66666 17.0833H8.16665C8.71894 17.0833 9.16665 16.6356 9.16665 16.0833V10.5833C9.16665 10.0311 8.71894 9.58334 8.16666 9.58334H7.29165V10.3333H3.54166V9.58334H2.66666Z"
          fill="#4B575E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.8333 9.58334C11.281 9.58334 10.8333 10.0311 10.8333 10.5833V16.0833C10.8333 16.6356 11.281 17.0833 11.8333 17.0833H17.3333C17.8856 17.0833 18.3333 16.6356 18.3333 16.0833V10.5833C18.3333 10.0311 17.8856 9.58334 17.3333 9.58334H16.4583V10.3333H12.7083V9.58334H11.8333Z"
          fill="#4B575E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.25 0.416672C6.69772 0.416672 6.25 0.864387 6.25 1.41667V6.91667C6.25 7.46896 6.69772 7.91667 7.25 7.91667H12.75C13.3023 7.91667 13.75 7.46896 13.75 6.91667V1.41667C13.75 0.864387 13.3023 0.416672 12.75 0.416672H11.875V1.16667H8.125V0.416672H7.25Z"
          fill="#4B575E"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const SettingIcon = ({
  className = '',
  fill = '#E1E4E5',
  width = '20',
  height = '20',
}) => {
  return (
    <svg
      className={className}
      with={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3 1C11.5761 1 11.8 1.22386 11.8 1.5V3.02683C12.4606 3.19687 13.0846 3.45831 13.6576 3.79688L14.7376 2.71681C14.9329 2.52155 15.2495 2.52155 15.4447 2.71681L17.2832 4.55529C17.4785 4.75055 17.4785 5.06713 17.2832 5.26239L16.2031 6.34247C16.5417 6.91543 16.8031 7.53937 16.9732 8.2H18.5C18.7761 8.2 19 8.42386 19 8.7V11.3C19 11.5761 18.7761 11.8 18.5 11.8H16.9732C16.8031 12.4607 16.5417 13.0847 16.2031 13.6576L17.283 14.7376C17.4783 14.9329 17.4783 15.2495 17.283 15.4447L15.4446 17.2832C15.2493 17.4785 14.9327 17.4785 14.7375 17.2832L13.6574 16.2032C13.0845 16.5417 12.4606 16.8031 11.8 16.9732V18.5C11.8 18.7761 11.5761 19 11.3 19H8.7C8.42386 19 8.2 18.7761 8.2 18.5V16.9732C7.53937 16.8031 6.91543 16.5417 6.34247 16.2031L5.26239 17.2832C5.06713 17.4785 4.75055 17.4785 4.55528 17.2832L2.71681 15.4447C2.52155 15.2495 2.52155 14.9329 2.71681 14.7376L3.79688 13.6576C3.45831 13.0846 3.19687 12.4606 3.02683 11.8H1.5C1.22386 11.8 1 11.5761 1 11.3V8.7C1 8.42386 1.22386 8.2 1.5 8.2H3.02683C3.19686 7.5394 3.45828 6.91549 3.79682 6.34256L2.71664 5.26238C2.52138 5.06712 2.52138 4.75054 2.71664 4.55527L4.55512 2.7168C4.75038 2.52153 5.06696 2.52153 5.26222 2.7168L6.34236 3.79693C6.91535 3.45834 7.53932 3.19688 8.2 3.02683V1.5C8.2 1.22386 8.42386 1 8.7 1H11.3ZM10 15.4C12.9823 15.4 15.4 12.9823 15.4 10C15.4 7.01766 12.9823 4.6 10 4.6C7.01766 4.6 4.6 7.01766 4.6 10C4.6 12.9823 7.01766 15.4 10 15.4Z"
        fill={fill}
      />
      <circle cx="10" cy="10" r="2" fill="#E1E4E5" />
    </svg>
  );
};

const ArrowIcon = ({ className = '' }) => {
  return (
    <svg
      width="20"
      height="20"
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.40991 14.59L10.9899 10L6.40991 5.41L7.81991 4L13.8199 10L7.81991 16L6.40991 14.59Z"
        fill="#0071BC"
      />
    </svg>
  );
};
const BulletIcon = () => {
  return (
    <svg height="18" width="13">
      <circle cx="5" cy="9" r="2" stroke="black" strokeWidth="3" fill="black" />
    </svg>
  );
};
const LockIcon = () => {
  return (
    <svg
      fill="#000"
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 14.81 16.925"
    >
      <path
        d="M13.223-3.787h-8.2V-7.2a2.4,2.4,0,0,1,2.357-2.41,2.384,2.384,0,0,1,2.4,2.38V-6.7a.791.791,0,0,0,.793.793h1.058A.791.791,0,0,0,12.43-6.7v-.529A5.03,5.03,0,0,0,7.388-12.25,5.057,5.057,0,0,0,2.38-7.176v3.388H1.587A1.587,1.587,0,0,0,0-2.2V3.089A1.587,1.587,0,0,0,1.587,4.675H13.223A1.587,1.587,0,0,0,14.81,3.089V-2.2A1.587,1.587,0,0,0,13.223-3.787Zm-4.5,5.025a1.322,1.322,0,1,1-2.645,0V-.349A1.322,1.322,0,0,1,7.4-1.672,1.322,1.322,0,0,1,8.727-.349Z"
        transform="translate(0 12.25)"
      />
    </svg>
  );
};

const SubscriptionPlanIcon = ({ className = '', fill = '#ffff' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 45 37.971"
    >
      <defs></defs>
      <path
        fill="#fff"
        d="M12.27-36.255a1.211,1.211,0,0,0-.747-.31,1.211,1.211,0,0,0-.747.31l-5.6,5.564-2-1.944a1.212,1.212,0,0,0-.747-.31,1.211,1.211,0,0,0-.747.31L.31-31.253a1.211,1.211,0,0,0-.31.747,1.211,1.211,0,0,0,.31.747l4.183,4.166a1.294,1.294,0,0,0,.774.309,1.294,1.294,0,0,0,.774-.309l1.37-1.373,6.346-6.345a1.22,1.22,0,0,0,.311-.751,1.223,1.223,0,0,0-.3-.743Zm0,13.991a1.211,1.211,0,0,0-.747-.31,1.211,1.211,0,0,0-.747.31l-5.6,5.6-2-1.942a1.211,1.211,0,0,0-.747-.31,1.211,1.211,0,0,0-.747.31L.31-17.227a1.211,1.211,0,0,0-.31.747,1.211,1.211,0,0,0,.31.747l4.172,4.175a1.293,1.293,0,0,0,.773.309,1.293,1.293,0,0,0,.773-.309l1.38-1.379,6.346-6.347a1.211,1.211,0,0,0,.31-.747,1.212,1.212,0,0,0-.3-.739ZM5.625-7.031A4.265,4.265,0,0,0,1.354-2.812,4.266,4.266,0,0,0,5.625,1.406,4.22,4.22,0,0,0,9.844-2.812,4.22,4.22,0,0,0,5.625-7.031ZM43.594-5.625H18.281a1.407,1.407,0,0,0-1.406,1.406v2.813A1.407,1.407,0,0,0,18.281,0H43.594A1.407,1.407,0,0,0,45-1.406V-4.219A1.407,1.407,0,0,0,43.594-5.625Zm0-28.125H18.281a1.407,1.407,0,0,0-1.406,1.406v2.813a1.407,1.407,0,0,0,1.406,1.406H43.594A1.407,1.407,0,0,0,45-29.531v-2.812A1.407,1.407,0,0,0,43.594-33.75Zm0,14.063H18.281a1.407,1.407,0,0,0-1.406,1.406v2.813a1.407,1.407,0,0,0,1.406,1.406H43.594A1.407,1.407,0,0,0,45-15.469v-2.812A1.407,1.407,0,0,0,43.594-19.687Z"
        transform="translate(0 36.565)"
      />
    </svg>
  );
};

export {
  DashboardIcon,
  NewDashboardIcon,
  ContactIcon,
  NewContactIcon,
  ItemIcon,
  NewItemIcon,
  NewItemAdjustmentIcon,
  SalesOrderIcon,
  NewSalesOrderIcon,
  PackageIcon,
  NewPackageIcon,
  SalesReturnIcon,
  NewSalesReturnIcon,
  SalesReturnReceiveIcon,
  CreditNoteIcon,
  InvoiceIcon,
  NewInvoiceIcon,
  PurchaseOrderIcon,
  NewPurchaseOrderIcon,
  BillIcon,
  NewBillIcon,
  PaymentIcon,
  ReportIcon,
  OrganizationIcon,
  NewReportIcon,
  NewOrganizationIcon,
  InventoryIcon,
  SettingIcon,
  AddIcon,
  ItemExpandIcon,
  ItemExpandedIcon,
  ArrowIcon,
  BulletIcon,
  SubscriptionPlanIcon,
  LockIcon,
  TimeLineDashboardIcon,
};
