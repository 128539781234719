import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import Loader from '../../../Loader/Loader'
import EnhancedForm from './ItemAttributeOptionFormEnhancer'

import '../../DeliveryForm/DeliveryMethodForm.css'
class itemAttributeOptionForm extends Component {
  type = this.props.type // "add" or "edit" form

  id = this.props.id || null

  state = {
    option: null,
    loading: true
  }

  componentDidMount() {
    this.setState({ loading: false })
    //this.fetchData().then(() => this.setState({ loading: false }))
  }
  
  itemAttributeOption() {
    const { option } = this.state
    const {
      title,
      history,
      inModal,
      closeModal,
      onSubmitModal,
      handleToast
    } = this.props

    return (
      <EnhancedForm 
        title={title}
        option={{ ...option }}
        onSubmitModal={onSubmitModal}
        handleToast={handleToast}
        history={history}
        inModal={inModal}
        closeModal={closeModal}
      />
    )
  }
  
  render() {
    if (this.state.loading) return <Loader />
    return this.itemAttributeOption()
  }
}

export default withRouter(itemAttributeOptionForm)