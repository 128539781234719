import React from 'react'
import { Link } from 'react-router-dom'
import PlanExceededLogo from './PlanExceededLogo.svg'
import './PlanExceeded.css'

export default ({
  className = 'full',
  type = 'page',
}) => {
  return(
    <div className={`access-denied ${className}`}>
      <img src={PlanExceededLogo} alt="access-denied" />
      <h1>Plan Exceeded!</h1>
      <h4>
        Your Plan limit exceeded. Please <span onClick={()=> (<Link to="packagesplan" />)}>Upgrade</span> to continue. 
      </h4>
    </div>
  )
}