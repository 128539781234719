import React, { useEffect, useState } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import { restRequest } from '../../../Helpers/RequestHelper';
import {
  handleStreet,
  handleCity,
  handlePhone,
  handleState,
} from '../../../Helpers/addressFieldsHandler';

import {
  required,
  ValidateEmail,
  ValidateName,
  // ValidatePhoneNumber,
  ValidateMax100,
  ValidateMax200,
  ValidateMax20,
  ValidateMax50,
  // ValidateMax6
} from '../../../Helpers/Validator';
import Field from '../Field/Field';
import Form from '../Form';
import FormAction from '../FormAction';
import FormSection from '../FormSection/FormSection';
import ErrorFocus from '../ErrorFocus';
// import { StateFullName } from '../../../Helpers/StateNameConvertion'
import { checkError } from '../../../Helpers/AuthHelper';
import { validateWarehouseForm } from '../../../Helpers/FormValidator';
import { confirmationAlert } from '../../../Helpers';

const formEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: required(ValidateName),
    //phone: ValidatePhoneNumber,
    phone: ValidateMax20,
    email: ValidateEmail,
    address: ValidateMax200,
    state: ValidateMax100,
    zipCode: ValidateMax50,
    city: ValidateMax100,
  }),
  mapPropsToValues: ({ warehouse }) => ({
    ...warehouse,
    // countryID:230
    countryID: warehouse.countryID,
  }),
  handleSubmit: (values, bag) => {
    handleFormSubmit(values, bag);
  },
});

const WarehouseForm = ({
  values: { name, address, city, state, countryID, zipCode, phone, email },
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
  isSubmitting,
  errors,
  touched,
  type,
  history,
  countryDB,
  inModal,
  closeModal,
  countryStates,
  populateCountryStates,
  totalRecords,
  values,
  handlePrompt,
}) => {
  const [staticVal, setStaticValues] = useState({});

  useEffect(() => {
    handleAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, staticVal]);

  useEffect(() => {
    setStaticValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAlert = () => {
    let { checkout } = validateWarehouseForm(values, staticVal);
    handlePrompt(!checkout);
  };

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };
    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  const closeAndBack = (history, closeModal, totalRecords) => {
    let { checkout } = validateWarehouseForm(values, staticVal);
    let response = true;
    if (!checkout) {
      response = confirmationAlert();
    }
    if (checkout || response) {
      closeModal();
      if (totalRecords === undefined || totalRecords === 0) {
        history.push('/settings');
      }
    }
  };

  return (
    <Form className="warehouse-form" onSubmit={handleSubmit}>
      <FormSection
      // title={type === 'add' ? 'New Warehouse' : 'Edit Warehouse'}
      >
        <div className='form-fields-container d-flex flex-wrap  position-relative'>


          <Field
            size="large"
            className="flex-25"
            name="Warehouse Name"
            value={name}
            id="name"
            autoFocus={true}
            required
            handleChange={handleChange}
            onBlur={handleBlur}
            error={touched.name && errors.name}
          />

          <Field
            size="large"
            name="Street Address"
            className="flex-25"
            value={address}
            id="address"
            handleChange={(value) =>
              setFieldValue('address', handleStreet(value, 200))
            }
            onBlur={handleBlur}
            error={touched.address && errors.address}
          />

          <Field
            size="large"
            name="City"
            value={city}
            className="flex-25"
            id="city"
            handleChange={(value) =>
              setFieldValue('city', handleCity(value, 100))
            }
            onBlur={handleBlur}
            error={touched.city && errors.city}
          />

          {/*<Field
          type="dropdown"
          size="large"
          className="flex-25"
          name="State"
          value={state}
          id="state"
          options={countryStates}
          dropdownLabel="label"
          dropdownValue="value"
          handleChange={option => setFieldValue('state', option ? option.value : '')}
        />*/}

          <Field
            size="large"
            className="flex-25"
            name="State"
            value={state}
            id="state"
            handleChange={(value) =>
              setFieldValue('state', handleState(value, 100))
            }
            onBlur={handleBlur}
            error={touched.state && errors.state}
          />

          <Field
            size="large"
            name="Zip Code"
            value={zipCode}
            id="zipCode"
            className="flex-25"
            handleChange={(handleChange) => {
              let zip = handleChange.target.value;
              if (zip.length > 0) {
                let labelString = zip;
                if (labelString.length > 15) {
                  labelString = labelString.slice(0, 15);
                }
                zip = labelString;
              }
              setFieldValue('zipCode', zip);
            }}
            onBlur={handleBlur}
            error={touched.zipCode && errors.zipCode}
          />

          <Field
            size="large"
            type="dropdown"
            name="Country"
            value={countryID}
            className="flex-25"
            options={countryDB}
            dropdownLabel="country_name"
            dropdownValue="id"
            handleChange={(option) => {
              setFieldValue('countryID', option ? option.value : null);
              //if(!option) setFieldValue('state', '') // reset state value if no country selected
              //populateCountryStates(option ? option.value: null)
            }}
            clearable={false}
          />

          <Field
            size="large"
            //type="phone"
            name="Phone"
            value={phone}
            className="flex-25"
            id="phone"
            handleChange={(value) =>
              setFieldValue('phone', handlePhone(value, 20))
            }
            //handleChange={handleChange}
            onBlur={handleBlur}
            error={touched.phone && errors.phone}
          />

          <Field
            size="large"
            className="flex-25"
            name="Email"
            value={email}
            id="email"
            handleChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && errors.email}
          />
        </div>
        <hr className="margin float-left w-100" />

        <FormAction
          disabled={isSubmitting}
          onCancel={() => {
            if (inModal) return closeAndBack(history, closeModal, totalRecords);
            history.goBack();
          }}
        />
      </FormSection>
      <ErrorFocus />
    </Form>
  );
};

const handleFormSubmit = (
  values,
  {
    setSubmitting,
    props: {
      requestType,
      requestUrl,
      history,
      successMessage,
      handleToast,
      onSubmitModal,
      inModal,
    },
  }
) => {
  restRequest(requestType, requestUrl, {
    name: values.name,
    email: values.email,
    address: values.address,
    city: values.city,
    //state: StateFullName(values.state),
    state: values.state,
    country_id: values.countryID,
    zip_code: values.zipCode,
    phone: values.phone,
  })
    .then(() => {
      if (inModal) {
        onSubmitModal();
      }
      history.push('/warehouses');
      successMessage();
    })
    .catch((error) => {
      checkError(error, handleToast);
      //handleToast(error, "error")
      setSubmitting(false);
    });
};

export default formEnhancer(WarehouseForm);
