import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import logo from './../../../Assets/Img/logo.png'

import './Footer.css'

class Footer extends Component {
  render() {
    return (
      <div className="home-footer">
        <div className="home-footer-container">
          <Link to="/" className="home-footer-logo">
            <img src={logo} alt="seebiz-inventory" />
          </Link>
        </div>
      </div>
    )
  }
}
 
export default Footer
