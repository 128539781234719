import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import sales from './../../../../Assets/Img/sales-and-purchase.png'

import './PageThree.css'

class PageThree extends Component {

  render() {
    return (
      <div className="page-three">
        <div className="page-three-title">
          <div className="home-pages-heading">
            Track your sales and purchase orders
          </div>
          <div className="home-desc">
            Confirm and send sales and purchase orders via numerous platforms like your email.
          </div>
          <Link to="/signup" className="home--btn">
            Get Started
          </Link>
        </div>
        <div className="page-three-img">
          <img src={sales} alt=""/>
        </div>
      </div>
    )
  }
}

export default PageThree
