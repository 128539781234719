import React from 'react'

import './FormSection.css'

const FormSection = ({
  title = '',
  className = '',
  rightContent = () => null,
  children
}) => (
  <div className={`form-section ${className}`}>
    <div className="form-section--header">
      <div className="form-section--title">{title}</div>
      <div className="form-section--right">{rightContent()}</div>
    </div>
    {/* <hr className='form-section--header--hr'/> */}
    <div className="form-section--body">{children}</div>
  </div>
)

export default FormSection