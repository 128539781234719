import React, { Component } from 'react';
import { Route, Redirect, withRouter, Switch } from 'react-router-dom';
//import Loader from '../Components/Loader/Loader';
import LandingPage from '../Components/LandingPage/LandingPage';
import ImsDeviceDetection from '../Components/DeviceDetection';
import Login from '../Auth/Login/Login';
import Signup from '../Auth/Signup/Signup';
import UserConfirmation from '../Auth/UserConfirmation/UserConfirmation';
import InvitedUserSignup from '../Auth/InvitedUserSignup/InvitedUserSignup';
import PasswordReset from '../Pages/Password/PasswordReset';
import PasswordRequest from '../Pages/Password/PasswordRequest';
import DeactivateUser from '../Auth/DeactivatedUsers';

class PublicRoutes extends Component {
  render() {
    let { withNotification } = this.props;
    return (
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route
          exact
          path="/login"
          render={(props) => withNotification(Login, props)}
        />
        <Route
          exact
          path="/signup"
          render={(props) => withNotification(Signup, props)}
        />

        {/*ACCOUNT CONFIRM*/}
        <Route
          exact
          path="/account/confirm/:token"
          render={(props) => withNotification(UserConfirmation, props)}
        />

        {/*INVITED USER SIGNUP*/}
        <Route
          exact
          path="/invitedUserSignup/:token"
          render={(props) => withNotification(InvitedUserSignup, props)}
        />

        {/*PASSWORD*/}
        <Route
          exact
          path="/password/request"
          render={(props) => withNotification(PasswordRequest, props)}
        />
        <Route
          exact
          path="/password/reset"
          render={(props) => withNotification(PasswordReset, props)}
        />
        <Route
          exact
          path="/imsdevice"
          render={(props) => withNotification(ImsDeviceDetection, props)}
        />

        {/*ACCOUNT Deactivated User*/}
        <Route
          exact
          path="/userdeactivate"
          render={(props) => withNotification(DeactivateUser, props)}
        />
        <Route render={() => <Redirect to="/login" />} />
      </Switch>
    );
  }
}

export default withRouter(PublicRoutes);
