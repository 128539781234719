import React from 'react'

export default () => (
  <svg width="204" height="200" viewBox="0 0 204 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M204 44.9763V59.4156L190.653 53.5526L185.236 51.1725L181.021 49.3213L159.353 39.8008L155.138 37.9496L149.721 35.5695L71.77 1.3223H104.637L204 44.9763Z" fill="#F2F2F2" />
    <path d="M175.197 34.9084C184.829 34.9084 192.637 27.0939 192.637 17.4542C192.637 7.8145 184.829 0 175.197 0C165.565 0 157.756 7.8145 157.756 17.4542C157.756 27.0939 165.565 34.9084 175.197 34.9084Z" fill="#0071BC" />
    <path d="M184.445 98.9071C183.818 98.9071 183.205 98.721 182.684 98.3722C182.162 98.0235 181.756 97.5279 181.516 96.948C181.276 96.3681 181.213 95.7301 181.335 95.1145C181.458 94.4989 181.76 93.9334 182.203 93.4896C182.647 93.0458 183.212 92.7435 183.827 92.6211C184.442 92.4986 185.079 92.5615 185.659 92.8017C186.238 93.0419 186.734 93.4486 187.082 93.9705C187.43 94.4924 187.616 95.1059 187.616 95.7336C187.615 96.575 187.281 97.3816 186.687 97.9765C186.092 98.5715 185.286 98.9061 184.445 98.9071ZM184.445 93.089C183.923 93.089 183.412 93.2441 182.977 93.5347C182.543 93.8253 182.204 94.2383 182.004 94.7215C181.804 95.2048 181.752 95.7365 181.854 96.2495C181.956 96.7625 182.207 97.2337 182.577 97.6036C182.946 97.9734 183.417 98.2253 183.93 98.3274C184.442 98.4294 184.974 98.377 185.457 98.1769C185.939 97.9767 186.352 97.6377 186.643 97.2028C186.933 96.7679 187.088 96.2566 187.088 95.7336C187.087 95.0324 186.808 94.3603 186.313 93.8645C185.818 93.3687 185.146 93.0898 184.445 93.089Z" fill="#3F3D56" />
    <path d="M6.87047 55.2715H0V48.3956H6.87047V55.2715ZM0.528498 54.7426H6.34197V48.9246H0.528498V54.7426Z" fill="#3F3D56" />
    <path d="M109.135 7.93369H102.264V1.0578H109.135V7.93369ZM102.793 7.40477H108.606V1.58671H102.793V7.40477Z" fill="#3F3D56" />
    <path d="M80.4637 43.0062H31.3135V82.9392H80.4637V43.0062Z" fill="#0071BC" />
    <path d="M167.402 95.1042H31.3135V99.3355H167.402V95.1042Z" fill="#F2F2F2" />
    <path d="M77.2925 80.8234H27.6138V40.3615H77.2925V80.8234ZM28.1423 80.2945H76.764V40.8904H28.1423V80.2945Z" fill="#3F3D56" />
    <path d="M147.583 53.9812H100.018V58.2125H147.583V53.9812Z" fill="#0071BC" />
    <path opacity="0.3" d="M167.401 65.3528H100.018V69.5841H167.401V65.3528Z" fill="#0071BC" />
    <path d="M144.412 56.0967H96.3188V51.3364H144.412V56.0967ZM96.8473 55.5678H143.884V51.8654H96.8473V55.5678Z" fill="#3F3D56" />
    <path d="M164.231 67.4683H96.3188V62.7081H164.231V67.4683ZM96.8473 66.9394H163.702V63.237H96.8473V66.9394Z" fill="#3F3D56" />
    <path d="M164.23 97.2199H27.6138V92.4596H164.23V97.2199ZM28.1423 96.6909H163.702V92.9885H28.1423V96.6909Z" fill="#3F3D56" />
    <path d="M167.402 108.327H31.3135V112.558H167.402V108.327Z" fill="#F2F2F2" />
    <path d="M164.23 110.443H27.6138V105.682H164.23V110.443ZM28.1423 109.914H163.702V106.211H28.1423V109.914Z" fill="#3F3D56" />
    <path d="M167.402 121.55H31.3135V125.781H167.402V121.55Z" fill="#F2F2F2" />
    <path d="M164.23 123.666H27.6138V118.905H164.23V123.666ZM28.1423 123.137H163.702V119.434H28.1423V123.137Z" fill="#3F3D56" />
    <path d="M167.402 134.773H31.3135V139.004H167.402V134.773Z" fill="#F2F2F2" />
    <path d="M164.23 136.888H27.6138V132.128H164.23V136.888ZM28.1423 136.36H163.702V132.657H28.1423V136.36Z" fill="#3F3D56" />
    <path d="M167.402 147.996H31.3135V152.227H167.402V147.996Z" fill="#F2F2F2" />
    <path d="M164.23 150.111H27.6138V145.351H164.23V150.111ZM28.1423 149.582H163.702V145.88H28.1423V149.582Z" fill="#3F3D56" />
    <path d="M167.402 161.218H31.3135V165.45H167.402V161.218Z" fill="#F2F2F2" />
    <path d="M164.23 163.334H27.6138V158.574H164.23V163.334ZM28.1423 162.805H163.702V159.103H28.1423V162.805Z" fill="#3F3D56" />
    <path d="M175.593 188.858H19.8188V17.0891H175.593V188.858ZM20.3473 188.329H175.065V17.618H20.3473V188.329Z" fill="#3F3D56" />
    <path d="M16.4893 192.028C16.48 192.031 16.4711 192.035 16.4629 192.041C16.7776 191.361 17.0711 190.674 17.3382 189.975C17.37 189.893 17.3991 189.809 17.4308 189.727C17.4282 189.748 17.4282 189.769 17.4255 189.793C17.4176 189.925 17.407 190.057 17.3964 190.192C17.3806 190.401 17.3673 190.613 17.3647 190.824C17.362 190.891 17.3621 190.954 17.3621 191.02C17.3629 191.278 17.3895 191.535 17.4414 191.787H17.4308C17.3647 191.792 17.3039 191.795 17.2404 191.806C16.991 191.839 16.7478 191.908 16.5184 192.012C16.5082 192.016 16.4984 192.021 16.4893 192.028Z" fill="url(#paint0_linear)" />
    <path opacity="0.1" d="M11.5225 79.5984C11.6806 79.697 11.8569 79.7627 12.0409 79.7915C12.2727 79.859 12.5164 79.8753 12.7552 79.8395C12.9939 79.8037 13.2221 79.7166 13.4239 79.5841L11.5225 79.5984Z" fill="black" />
    <defs>
      <linearGradient id="paint0_linear" x1="16.9521" y1="192.041" x2="16.9521" y2="189.727" gradientUnits="userSpaceOnUse">
        <stop stopColor="#808080" stopOpacity="0.25" />
        <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
        <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
      </linearGradient>
    </defs>
  </svg>

)