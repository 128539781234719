import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { unregister } from './registerServiceWorker'
import './Assets/CSS/Style.css'
import './Assets/CSS/CustomBootstrap.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles.css'
import './global.css'

unregister()

const render = Component => {
  ReactDOM.render(
      <Component/>,
    document.getElementById('root')
  )
}
// Render the main component into the dom
render(App)

if (module.hot) {
  module.hot.accept('./Router/Routes', () => {
    const NextRootContainer = require('./Router/Routes').default
    render(NextRootContainer)
  })
}
