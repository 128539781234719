import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CustomModal from '../../Components/CustomModal/CustomModal';

import {
  publicSeebizRequest,
  restRequest,
  checkError,
  // confirmationAlert,
} from '../../Helpers';
import {
  // ValidatePhoneNumber,
  // ValidateName,
  ValidateMax20,
  // ValidateMax30,
  ValidateMaxOnlyAlpha25,
  required,
} from '../../Helpers/Validator';
import withContext from '../../Context/UserContextHOC';

import Field from '../../Components/Form/Field/Field';
import Form from '../../Components/Form/Form';
import OverlayLoader from '../../Components/Loader/OverlayLoader';
import FormAction from '../../Components/Form/FormAction';
import { LockIcon } from '../../Assets/Navigation/NavigationIcons';
import ResetPassword from '../Password/ResetPassword';
import './MyAccount.css';
import { lookResetCodeInUrl } from './resetCodeUrlParser';
// import { validateUserAccountForm } from '../../Helpers/FormValidator';
class General extends Component {
  resetUrl = lookResetCodeInUrl(window.location.search);
  state = {
    loading: true,
    email: '',
    name: '',
    phoneNumber: '',
    packagePlan: '',
    resetPasswordModal: this.resetUrl.isResetUrl,
    byPassConfirmation: this.resetUrl.isResetUrl,
    showHeader: false,
  };

  componentDidMount() {
    this.fetchData();
    // this.fetchBusinessInfos();
  }
  fetchBusinessInfos() {
    const self = this;
    if (
      self.state.name === '' ||
      self.state.phoneNumber === '' ||
      self.state.phoneNumber === null
    ) {
      self.setState({ loading: true });
      publicSeebizRequest('get', 'profile/businessInfos/' + self.state.email)
        .then((res) => {
          if (res.status) {
            if (
              self.state.phoneNumber === '' ||
              self.state.phoneNumber === null
            ) {
              if (res.userBusinessInfos.phone.office !== null) {
                self.state.phoneNumber = res.userBusinessInfos.phone.office;
              }
            }
            if (self.state.name === '' || self.state.name === null) {
              if (res.userBusinessInfos.username !== null) {
                self.state.name = res.userBusinessInfos.username;
              }
            }
            self.setState({
              loading: false,
            });
          } else {
            self.setState({
              loading: false,
            });
          }
        })
        .catch((err) => {
          self.setState({
            loading: false,
          });
        });
    }
  }

  fetchData() {
    restRequest('get', 'user/profile')
      .then((res) => {
        this.setState({
          name: res.name,
          packagePlan: res.current_organization_limits.name,
          phoneNumber: res.phone,
          email: res.email,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });

    this.setState({ loading: false });
  }

  handleSubmit = (values, setSubmitting) => {
    const { handleToast, context } = this.props;
    restRequest('put', 'user/profile', {
      name: values.name,
      phone: values.phoneNumber,
    })
      .then(() => {
        context.changeUserName(values.name);
        handleToast('User Information updated successfully', 'success');
        setSubmitting(false);
        this.props.setPrompt(false);
      })
      .catch((error) => {
        checkError(error);
        handleToast(error, 'error');
        setSubmitting(false);
      });
  };

  renderGeneralForm() {
    const { email, name, phoneNumber } = this.state;
    return (
      <Formik
        enableReinitialize
        initialValues={{ email, name, phoneNumber }}
        validationSchema={() =>
          Yup.object().shape({
            name: required(ValidateMaxOnlyAlpha25),
            phoneNumber: ValidateMax20.nullable(),
          })
        }
        onSubmit={(values, { setSubmitting }) => {
          this.handleSubmit(values, setSubmitting);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form
            className="my-account--form float-left w-100"
            onSubmit={handleSubmit}
            autocomplete="off"
          >
            <div className="float-left w-70 genral_tab_content ">
              <div className="myaccountplan">
                <div>
                  <Field
                    className="inline-field"
                    size="large"
                    name="email"
                    value={values.email}
                    id="email"
                    disabled
                  />
                  <Field
                    className="inline-field"
                    size="large"
                    name="Name"
                    value={values.name}
                    id="name"
                    required
                    handleChange={(e) => {
                      handleChange(e);
                      setFieldValue('name_updated', true);
                      this.props.setPrompt();
                    }}
                    onBlur={handleBlur}
                    error={touched.name && errors.name}
                  />
                  <Field
                    className="inline-field"
                    size="large"
                    //type='phone'
                    name="Phone Number"
                    value={values.phoneNumber}
                    id="phoneNumber"
                    handleChange={(e) => {
                      handleChange(e);
                      setFieldValue('phone_updated', true);
                      this.props.setPrompt();
                    }}
                    onBlur={handleBlur}
                    error={touched.phoneNumber && errors.phoneNumber}
                  />
                </div>
                <div>
                  <FormAction
                    className="bottom-buttons"
                    disabled={isSubmitting}
                    onCancel={() => this.props.history.goBack()}
                  />
                </div>
              </div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  this.showPasswordResetModal();
                }}
                className="flot-left next-btn"
              >
                Change Password
              </button>
            </div>
          </Form>
        )}
      />
    );
  }
  showHeaderInModal(val) {
    this.setState({ showHeader: val });
  }
  showPasswordResetModal() {
    return this.setState({ resetPasswordModal: true });
  }
  renderPasswordReset() {
    return (
      <CustomModal
        className={"uiux-my-account" + this.state.showHeader ? 'remove-header' : ''}
        showModal={this.state.resetPasswordModal}
        title="New Password"
        fill="#000"
        Icon={LockIcon}
        renderActions={false}
        size="large"
        onClose={() => this.setState({ resetPasswordModal: false })}
      >
        <ResetPassword
          handleToast={this.props.handleToast}
          byPassConfirmation={this.state.byPassConfirmation}
          showHead={this.state.showHeader}
          code={this.resetUrl.resetCode}
          showHeader={this.showHeaderInModal.bind(this)}
          onClose={() =>
            this.setState({
              resetPasswordModal: false,
              byPassConfirmation: false,
            })
          }
          history={this.props.history}
          email={this.state.email}
        />
      </CustomModal>
    );
  }
  render() {
    return (
      <div className="my-account float-left w-100">
        {this.state.loading && <OverlayLoader />}
        {this.renderGeneralForm()}
        {this.renderPasswordReset()}
      </div>
    );
  }
}

export default withContext(General);
