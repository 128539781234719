import React from 'react';

import RecordPaymentIcon from '../../../../../../Assets/General/RecordPaymentIcon';

import Email from '../../../../../../Components/Email/Email';

const PaymentEmail = (props) => {
  return (
    <Email
      {...props}
      Icon={RecordPaymentIcon}
      name="Invoice Payment"
      onCancelURL="/invoices"
      emailAPI={`invoice_payments/${props.match.params.id}/mail`}
      fetchDataAPI={`invoice_payments/${props.match.params.id}/mail`}
      getBackTab
    />
  );
};

export default PaymentEmail;
