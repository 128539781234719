import React, { Component } from 'react';

import { restRequest } from '../../Helpers/RequestHelper';

import EmailEnhancer from './EmailEnhancer';
import Loader from './../Loader/Loader';
import FormLayout from '../Layout/FormLayout';

import './Email.css';
import { checkError } from '../../Helpers/AuthHelper';

class Email extends Component {
  state = {
    mail: {
      address: '',
      subject: '',
      body: '',
      files: [],
      defaultFileName: '',
      defaultFileStatus: true,
    },
    loading: true,
  };

  componentDidMount() {
    document.title = `Email ${this.props.name}`;
    this.fetchData();
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  fetchData = async () => {
    this.setState({ loading: true });

    await restRequest('get', this.props.fetchDataAPI)
      .then((res) => {
        let updatedMail = {
          ...this.state.mail,
          address: res.email,
          subject: res.subject,
          body: res.body,
          defaultFileName: res.defaultFileName,
          contactPersons:
            res.contact_persons
              .map((el) => el.email.replace(/^\s*|\s*$/g, '').split(/\s*,\s*/))
              .flat() || [],
        };

        let result = [];
        updatedMail.contactPersons &&
          updatedMail.contactPersons.length >= 1 &&
          updatedMail.contactPersons.map((el, ind) => {
            return result.push({ id: ind, email: el });
          });
        if (updatedMail.contactPersons.length >= 1)
          updatedMail.contactPersons = result;
        let selected =
          updatedMail.contactPersons.length > 0
            ? this.handleOptionsForBadge([updatedMail.contactPersons[0]])
            : [];
        updatedMail['selected'] = selected;
        this.setState({ mail: updatedMail });
      })
      .catch((error) => {
        checkError(error);
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  };

  handleOptionsForBadge = (options) => {
    let renderOptions = [];
    options.forEach((option) => {
      if (option.email) {
        renderOptions.push({ label: option.email, value: option.id });
      } else {
        renderOptions.push({ label: option.email, value: option.id });
      }
    });
    return renderOptions;
  };
  renderEmail() {
    const { mail } = this.state;
    const {
      Icon,
      name,
      onCancelURL,
      emailAPI,
      history,
      handleToast,
      getBackTab = false,
    } = this.props;

    return (
      <FormLayout
        Icon={Icon}
        title={name}
        subtitle="Email"
        iconstyle={
          window.location.pathname.includes('contacts')
            ? 'contact_general_module_icon'
            : 'general_module_icon'
        }
        closeUrl={onCancelURL}
      >
        {this.state.loading ? (
          <Loader />
        ) : (
          <EmailEnhancer
            mail={{ ...mail }}
            name=""
            emailAPI={emailAPI}
            onCancelURL={onCancelURL}
            handleToast={handleToast}
            history={history}
            getBackTab={getBackTab}
          />
        )}
      </FormLayout>
    );
  }

  render() {
    return this.renderEmail();
  }
}

export default Email;
