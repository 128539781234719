import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { restRequest } from '../../../../../../Helpers/RequestHelper';
import getDate from '../../../../../../Helpers/Date/GetDate';

import Loader from '../../../../../../Components/Loader/Loader';
// import HeaderWrapper from '../../../../../../Components/HeaderWrapper/HeaderWrapper'
// import { Grid, GridLayout } from '../../../../../../Components/Layout/Layout'
// import { DetailsHeader } from '../../../../../../Components/Details/Details'
import {
  Button,
  ActionMenu,
} from '../../../../../../Components/CheckedMenu/CheckedMenu';

import PDFViewer from '../../../../../../Components/PDFViewer/PDFViewer';
// import Box from '../../../../../../Components/Layout/Box'
import NotFound from '../../../../../../Components/NotFound/NotFound';

// import { InvoiceIcon } from '../../../../../../Assets/Navigation/NavigationIcons'
import Table from '../../../../../../Components/Table/Table';
import './InvoicePaymentDetails.css';
import { checkError } from '../../../../../../Helpers/AuthHelper';

class InvoicePaymentDetails extends Component {
  id = this.props.id ? this.props.id : this.props.match.params.id;
  state = {
    invoiceInfo: {},
    paymentInfo: {},
    activityLog: [],
    currency: {},
    detailsNo: '',
    pdf: null,
    showPDF: false,
    loading: true,
    notFound: false,
  };

  componentDidMount() {
    document.title = 'Invoice Payment Details';
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ loading: true });

    return restRequest('get', `invoicepaymentrecords/${this.id}`)
      .then((res) => {
        //console.log(res)
        this.setState({
          invoiceInfo: res.invoice,
          paymentInfo: {
            ...this.state.paymentInfo,
            id: res.id,
            paymentDate: getDate(res.payment_date),
            paymentMade: res.payment_made,
            paymentMode: res.payment_mode,
            reference: res.reference,
            notes: res.note,
            payment_no: res?.payment_no || res?.advance_payment?.payment_no,
          },
          currency: res.currency,
          detailsNo: `${res.invoice.invoice_no} / Payment #${res.id}`,
          loading: false,
        });
      })
      .catch((err) => {
        checkError(err);
        this.setState({
          loading: false,
          notFound: true,
        });
      });
  };

  openPdf(id) {
    this.setState({ showPDF: true });
    restRequest('get', `invoicepaymentrecords/${id}/pdf`)
      .then((res) => {
        this.setState({ pdf: res });
      })
      .catch((error) => checkError(error));
  }

  closeAllModals = () => {
    this.setState({
      showPDF: false,
      pdf: null,
    });
  };

  renderActionMenu() {
    const id = this.id;
    return (
      <ActionMenu>
        <Button handleClick={() => this.openDeleteModal(id)} type="delete" />
        <Button handleClick={() => this.openPdf(id)} type="pdf" />
      </ActionMenu>
    );
  }

  renderPaymentInfo = () => {
    let {
      paymentInfo,
      // invoiceInfo,
      currency,
    } = this.state;
    return (
      <Fragment>
        <div className="float-left w-100 payment_modal mb_3 invoice-payment-modal">
          <div className="heading_title">
            Payment Details
            {/* <hr /> */}
          </div>

          <div className="section-details--info">
            {paymentInfo.payment_no && (
              <div className="details_fields">
                <Fragment>
                  <div className="section-details--info-title semi-bold">
                    Payment No
                  </div>
                  <div>{paymentInfo.payment_no}</div>
                </Fragment>
              </div>
            )}
          </div>

          <div className="section-details--info">
            {paymentInfo.paymentDate && (
              <div className="details_fields">
                <Fragment>
                  <div className="section-details--info-title semi-bold">
                    Date
                  </div>
                  <div>{paymentInfo.paymentDate}</div>
                </Fragment>
              </div>
            )}
            {paymentInfo.paymentMade && (
              <div className="details_fields">
                <Fragment>
                  <div className="section-details--info-title semi-bold">
                    Received
                  </div>
                  <div>
                    {currency.symbol}
                    {paymentInfo.paymentMade}
                  </div>
                </Fragment>
              </div>
            )}
            {paymentInfo.paymentMode && (
              <div className="details_fields">
                <Fragment>
                  <div className="section-details--info-title semi-bold">
                    Payment Mode
                  </div>
                  <div>{paymentInfo.paymentMode}</div>
                </Fragment>
              </div>
            )}
            {paymentInfo.reference && (
              <div className="details_fields">
                <Fragment>
                  <div className="section-details--info-title semi-bold">
                    Reference
                  </div>
                  <div>{paymentInfo.reference}</div>
                </Fragment>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  };

  renderLayoutView() {
    const { invoiceInfo, paymentInfo, currency, showPDF, pdf } = this.state;
    const { className } = this.props;

    return (
      <Fragment>
        <PDFViewer
          showPDF={showPDF}
          hidePDFModal={this.closeAllModals}
          pdf={pdf}
        />

        {/* <DetailsHeader
          title="Payment"
          no={`Payment #${paymentInfo.id}`}
        /> */}

        {this.renderPaymentInfo()}

        <Table
          list={[
            {
              invoiceNo: this.props.inModal ? (
                `${invoiceInfo.invoice_no}`
              ) : (
                <Link to={`/invoices/${invoiceInfo.id}`}>
                  {invoiceInfo.invoice_no}
                </Link>
              ),
              invoiceDate: getDate(invoiceInfo.invoice_date),
              //invoiceAmount: `${currency.symbol}${invoiceInfo.total}`,
              //receivedAmount: `${currency.symbol}${paymentInfo.paymentMade}`
              invoiceAmount: `${currency.symbol}${parseFloat(
                invoiceInfo.total
              ).toFixed(2)}`,
              receivedAmount: `${currency.symbol}${parseFloat(
                paymentInfo.paymentMade
              ).toFixed(2)}`,
            },
          ]}
          tableHeaders={[
            'Invoice No.',
            'Invoice Date',
            'Invoice Amount',
            'Payment Received',
          ]}
          tableData={[
            'invoiceNo',
            'invoiceDate',
            'invoiceAmount',
            'receivedAmount',
          ]}
          rightAlignColumns={[3, 4]}
          className={`details-table table_view_invoice ${className}`}
        />

        {paymentInfo.notes && (
          <div className="notes_field">
            <div className="semi-bold margin-bottom-sm">Notes</div>
            <div>{paymentInfo.notes}</div>
          </div>
        )}
        <div className="mt-10 bottom_notes"></div>
      </Fragment>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    return <Fragment>{this.renderLayoutView()}</Fragment>;
  }
}

export default InvoicePaymentDetails;
