import React from 'react'

export default ({
  className = ''
}) => (
<svg className ={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 245.334 245.334" xlink="http://www.w3.org/1999/xlink" enableBackground="new 0 0 245.334 245.334">
  <g>
    <path d="M122.667,0C55.028,0,0,55.028,0,122.667s55.027,122.667,122.666,122.667s122.667-55.028,122.667-122.667   S190.305,0,122.667,0z M122.667,215.334C71.57,215.334,30,173.764,30,122.667S71.57,30,122.667,30s92.667,41.57,92.667,92.667   S173.763,215.334,122.667,215.334z"/>
    <rect width="30" x="107.667" y="109.167" height="79"/>
    <rect width="30" x="107.667" y="57.167" height="29"/>
  </g>
</svg>
  )
