import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {
  restRequest,
  getOrganizationDate,
  checkError,
  GetDateString,
} from '../../../Helpers';
import { Loader, NotFound } from '../../';
import AddSalesReturnEnhancer from './AddSalesReturnEnhancer';
import './AddSalesReturnForm.css';

let warehouseResult = [];
class SalesReturnForm extends Component {
  type = this.props.type; // "add" or "edit" form
  id = this.props.id || this.props.match.params.id || null;

  emptyItem = {
    id: null,
    dragDropID: GetDateString(),
    contactPerson: [],
    contactPersonId: null,
    salesOrderItemDetailId: null,
    name: '',
    image: '',
    allContactsList: [],
    sku: '',
    unit: 'unit',
    ordered: 0,
    shipmentId: null,
    packed: 0,
    shipped: 0,
    stocks: 0,
    quantity: null,
  };

  state = {
    returnReasonsFilter: [
      { id: 'Excess Quantity Shipped', name: 'Excess Quantity Shipped' },
      { id: 'Defective Goods', name: 'Defective Goods' },
      {
        id: 'Product Specs Are Incorrect',
        name: 'Product Specs Are Incorrect',
      },
      { id: 'Shipped Too Late', name: 'Shipped Too Late' },
      { id: 'Size Exchanged', name: 'Size Exchanged' },
      { id: 'Wrong Item Shipped', name: 'Wrong Item Shipped' },
      { id: 'Other', name: 'Other' },
    ],
    saleorderInfo: {
      saleReturnNo: '',
      salesOrderId: null,
      salesReturnDate: null,
      orderDate: null,
      back_to_stock: true,
      items: [this.emptyItem],
    },
    // New Functionality
    warehouseList: [],
    warehousesList: [],
    returnReason: '',
    returnReasonDescription: '',
    genericWarehouseId: null,
    itemsList: [],
    SaleOrderNo: '',
    CustomerName: '',
    backToStock: null,
    salesOrderList: [],
    currency: {
      currency_code: '',
      name: '',
      symbol: '',
    },
    loading: true,
    notFound: false,
  };

  componentDidMount() {
    this.fetchData();
    sessionStorage.setItem('once', false);
    sessionStorage.setItem('fullscreen', false);
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }
  async fetchData() {
    if (this.type !== 'edit') {
      const salesOrderId = await this.setSalesOrderId();

      if (!salesOrderId) {
        this.props.handleToast(
          'No Sale Order with shipped status. Please ship any Package to activate Sales Return.',
          'error'
        );
        this.setState({ loading: false });
        return;
      }
    }
    switch (this.type) {
      case 'edit':
        await this.fetchSalesReturnEditData();
        break;
      case 'add':
      default:
        await this.fetchSalesReturnCreateData(
          this.state.saleorderInfo.salesOrderId
        );
    }

    this.setState({ loading: false });
  }
  async setSalesOrderId() {
    if (this.props.inModal) {
      this.setState({
        saleorderInfo: {
          ...this.state.saleorderInfo,
          salesOrderId: this.props.salesOrderId,
        },
      });
      return this.props.salesOrderId;
    }

    const res = await restRequest('get', 'salesreturns/create');
    if (res.contacts.length) {
      var i;
      var salesOrderId;
      for (i = 0; i < res.contacts.length; i++) {
        if (res.contacts[i].order_list.length) {
          const salesOrderList = res.contacts[i].order_list.map(
            (salesorder) => ({
              id: salesorder.id,
              name: salesorder.sales_order_no,
            })
          );
          const makeContactPersonList = res.contacts.map((contact) => ({
            id: contact.id,
            name: contact.display_name,
          }));
          this.setState({
            salesOrderList: salesOrderList,
            saleorderInfo: {
              ...this.state.saleorderInfo,
              salesOrderId: salesOrderList[0].id,
            },
            contactPerson: makeContactPersonList,
            contactPersonId: res.contacts[i].id,
            allContactsList: res.contacts,
          });
          if (salesOrderList.length && salesOrderList[0].id !== null) {
            salesOrderId = salesOrderList[0].id;
            if (salesOrderId !== null) {
              break;
            }
          }
        }
      }
      return salesOrderId;
    }
  }
  prepareEditItemsList(
    itemsArray,
    salesOrderItemDetails,
    packageSalesOrder,
    genericWareId
  ) {
    return itemsArray.map((item, index) => ({
      id: item.item_id,
      //salesOrderItemDetailId: item.sales_order_id,
      //packageItemDetailId: item.id,
      salesOrderItemDetailId: item.id,
      dragDropID: GetDateString(),
      //packageItemDetailId: item.id,
      name: item.item_name,
      images: item.items.images,
      sku: item.items.sku,
      unit: item.items.unit,
      inventoryType: item.items.inventory_type,
      warehouse_idd:
        salesOrderItemDetails[index].target_warehouse_id !== null
          ? salesOrderItemDetails[index].target_warehouse_id
          : salesOrderItemDetails[index].warehouse_id,
      old_warehouse_idd:
        salesOrderItemDetails[index].target_warehouse_id !== null
          ? salesOrderItemDetails[index].target_warehouse_id
          : salesOrderItemDetails[index].warehouse_id,
      res_warehouse_id: salesOrderItemDetails[index].warehouse_id
        ? salesOrderItemDetails[index].warehouse_id
        : genericWareId,
      ordered: item.return_quantity_added,
      packed: item.packed,
      shipped: item.shipped,
      quantity: item.sale_return.quantity,
    }));
  }

  /*prepareAddItemsList(arrayList, salesOrder) {
    return arrayList.map((item,index) => ({
      id: item.item_id,
      salesOrderItemDetailId: item.id,
      name: item.items.name,
      images: item.items.images,
      shipped:item.shipped,
      sku: item.items.sku,
      unit: item.items.unit,
      warehouse_idd: salesOrder.sales_order_details[index].warehouse_id ? salesOrder.sales_order_details[index].warehouse_id : salesOrder.warehouse_id,
      res_warehouse_id: salesOrder.sales_order_details[index].warehouse_id ? salesOrder.sales_order_details[index].warehouse_id : salesOrder.warehouse_id,
      ordered: item.return_quantity_added,
      packed: item.packed,
      stocks: item.items.stocks.find(stock => stock.warehouse_id === salesOrder.warehouse_id) || {},
      quantity: item.return_quantity_remaining
    }))
  } */
  prepareItemsList(itemsArray, genericWareId) {
    return itemsArray.map((item) => ({
      id: item.item_id,
      salesOrderItemDetailId: item.id,
      name: item.item_name,
      images: item.items.images,
      sku: item.items.sku,
      unit: item.items.unit,
      inventoryType: item.items.inventory_type,
      ordered: item.return_quantity_added,
      packed: item.packed,
      shipped: item.shipped,
      quantity: item.remaining_quantity,
      warehouse_idd:
        item.hasOwnProperty('target_warehouse_id') &&
        item.target_warehouse_id !== null
          ? item.target_warehouse_id
          : item.warehouse_id,
      old_warehouse_idd:
        item.hasOwnProperty('target_warehouse_id') &&
        item.target_warehouse_id !== null
          ? item.target_warehouse_id
          : item.warehouse_id,
      res_warehouse_id: item.warehouse_id ? item.warehouse_id : genericWareId,
    }));
  }

  fetchSalesReturnEditData() {
    return restRequest('get', `salesreturns/${this.id}/edit`)
      .then((res) => {
        warehouseResult = res.warehouse_list;
        if (res.sales_Return.sales_return_details[0].warehouse_id)
          this.setState({
            genericWarehouseId:
              res.sales_Return.sales_return_details[0].warehouse_id,
          });

        let arr = [];
        for (
          let i = 0;
          i < res.sales_Return.sale_order.salesOrderDetails.length;
          i++
        ) {
          arr = this.state.warehouseList;
          arr.push(
            res.sales_Return.sale_order.salesOrderDetails[i].warehouse_id
          );
          this.setState({ warehouseList: arr });
        }

        const salesOrderList = res.sales_order_list.map((salesorder) => ({
          id: salesorder.id,
          name: salesorder.sales_order_no,
        }));
        const saleorderInfo = {
          ...this.state.sales_Return,
          saleReturnNo: res.sales_Return.sales_return_no,
          salesOrderId: res.sales_Return.sale_order_id,
          salesReturnDate: moment(res.sales_Return.sales_return_date).format(
            'YYYY-MM-DD 00:00:00'
          ),
          orderDate: moment(res?.sales_Return?.sale_order?.order_date),
          // orderDate: moment(res.sales_Return.sales_order.order_date),
          items: this.prepareEditItemsList(
            res.sales_Return.sale_order.salesOrderDetails,
            res.sales_Return.sales_return_details,
            res.sales_Return.sale_order,
            res.sales_Return.sales_return_details[0].warehouse_id
          ),
        };
        //console.log('saleorderInfo', saleorderInfo)
        //const leftoverItems = this.prepareItemsList(res.sales_Return.sale_order.salesOrderDetails, res.sales_Return.sales_order)
        const leftoverItems = this.prepareItemsList(
          res.sales_Return.sale_order.remaining_items,
          res.sales_Return.sales_return_details[0].warehouse_id
        );
        //console.log('remaining items',leftoverItems)

        const makeContactPersonList = res.contacts.map((contact) => ({
          id: contact.id,
          name: contact.display_name,
        }));
        this.setState({
          // allContactsList:res.contacts,
          contactPerson: makeContactPersonList,
          salesOrderList: salesOrderList,

          allContactsList: res.contacts,
          returnReason: res.sales_Return.return_reason,
          returnReasonDescription: res.sales_Return.return_reason_description,
          backToStock: res.sales_Return.add_back_stock,
          contactPersonId: res.sales_Return.customer_id,
          saleorderInfo: {
            ...this.state.saleorderInfo,
            ...saleorderInfo,

            items: [...saleorderInfo.items],
          },
          warehousesList: res.warehouse_list,
          shipmentId: parseInt(res.sales_Return.shipped_id),
          itemsList: leftoverItems,
        });
      })
      .catch((error) => {
        // console.log('err',error)
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
        if (this.props.inModal) {
          this.props.close();
        } else {
          this.setState({
            loading: false,
            notFound: true,
          });
        }
      });
  }

  prepareAddItemsList(arrayList, salesOrder) {
    return arrayList.map((item, index) => ({
      id: item.item_id,
      salesOrderItemDetailId: item.id,
      dragDropID: GetDateString(),
      name: item.items.name,
      images: item.items.images,
      shipped: item.shipped,
      sku: item.items.sku,
      unit: item.items.unit,
      inventoryType: item.items.inventory_type,
      warehouse_idd: salesOrder.sales_order_details[index].warehouse_id
        ? salesOrder.sales_order_details[index].warehouse_id
        : salesOrder.warehouse_id,
      old_warehouse_idd: salesOrder.sales_order_details[index].warehouse_id
        ? salesOrder.sales_order_details[index].warehouse_id
        : salesOrder.warehouse_id,
      res_warehouse_id: salesOrder.sales_order_details[index].warehouse_id
        ? salesOrder.sales_order_details[index].warehouse_id
        : salesOrder.warehouse_id,
      ordered: item.return_quantity_added,
      packed: item.packed,
      stocks:
        item.items.stocks.find(
          (stock) => stock.warehouse_id === salesOrder.warehouse_id
        ) || {},
      quantity: item.return_quantity_remaining,
    }));
  }

  fetchSalesReturnCreateData = async (salesOrderId) => {
    if (salesOrderId === null) {
      return;
    }
    return await restRequest(
      'get',
      `salesreturns/create?sales_order_id=${salesOrderId}`
    )
      .then((res) => {
        warehouseResult = res.warehouse_list;
        if (res.sales_order.warehouse_id)
          this.setState({
            genericWarehouseId: res.sales_order.warehouse_id,
            SaleOrderNo: res.sales_order.sales_order_no,
            CustomerName: res.sales_order.customer_name,
          });
        let arr = [];
        for (let i = 0; i < res.sales_order.sales_order_details.length; i++) {
          arr = this.state.warehouseList;
          arr.push(res.sales_order.sales_order_details[i].warehouse_id);
          this.setState({ warehouseList: arr });
        }

        const saleorderInfo = {
          ...this.state.saleorderInfo,
          saleReturnNo: res.sales_return_no,
          salesReturnDate:
            res?.sales_order?.order_date &&
            moment(res?.sales_order?.order_date) < moment(getOrganizationDate())
              ? moment(getOrganizationDate()).format('YYYY-MM-DD 00:00:00')
              : moment(res?.sales_order?.order_date),
          orderDate: moment(res.sales_order.order_date),
          items: this.prepareAddItemsList(
            res.sales_order.sales_order_details,
            res.sales_order
          ),
        };
        this.setState({
          saleorderInfo: {
            ...saleorderInfo,
            items:
              saleorderInfo.items.length > 0
                ? [...saleorderInfo.items]
                : [this.emptyItem],
          },
          itemsList: [],
          warehousesList: res.warehouse_list,
          contactPersonId: res.sales_order.contactId,
          shipmentId: res.sales_order.shipmentId,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, 'error')
        if (this.props.inModal) {
          this.props.close();
        }
      });
  };

  submitRequestType = () => {
    switch (this.type) {
      case 'edit':
        return 'put';
      case 'add':
      default:
        return 'post';
    }
  };

  submitRequestUrl = () => {
    switch (this.type) {
      case 'edit':
        return `salesreturns/${this.id}`;
      case 'add':
      default:
        return 'salesreturns';
    }
  };

  successMessage = () => {
    if (this.type === 'add') {
      this.props.handleToast('Sales Return created successfully', 'success');
    } else if (this.type === 'edit') {
      this.props.handleToast('Sales Return updated successfully.', 'success');
    }
  };

  renderSalesReturnForm() {
    const {
      saleorderInfo,
      itemsList,
      salesOrderList,
      allContactsList,
      currency,
      returnReason,
      returnReasonDescription,
      returnReasonsFilter,
      backToStock,
      contactPerson,
      CustomerName,
      SaleOrderNo,
      contactPersonId,
      warehousesList,
      shipmentId,
    } = this.state;

    const {
      title,
      inModal,
      close,
      onSubmit,
      handleToast,
      history,
      handleCrossAlert,
    } = this.props;
    let warehouseListProp = this.state.warehouseList;
    let warehouseIdForNullCase = this.state.genericWarehouseId;
    return (
      <AddSalesReturnEnhancer
        warehouseListRecieve={warehouseListProp}
        genericWarehouseId={warehouseIdForNullCase}
        title={title}
        contactPerson={contactPerson}
        type={this.type}
        shipmentId={shipmentId}
        allContactsList={allContactsList}
        returnReason={returnReason}
        returnReasonDescription={returnReasonDescription}
        contactPersonId={contactPersonId}
        addBackToStock={backToStock}
        warehouseResult={warehouseResult}
        warehousesList={warehousesList}
        saleorderInfo={saleorderInfo}
        SaleOrderNo={SaleOrderNo}
        CustomerName={CustomerName}
        inModal={inModal || null}
        salesOrderList={salesOrderList}
        itemsList={itemsList}
        emptyItem={this.emptyItem}
        currency={currency}
        submitRequestType={this.submitRequestType}
        submitRequestUrl={this.submitRequestUrl}
        successMessage={this.successMessage}
        close={close}
        onSubmit={onSubmit}
        history={history}
        handleToast={handleToast}
        returnReasonsFilter={returnReasonsFilter}
        handleCrossAlert={handleCrossAlert}
        handlePrompt={this.props.handlePrompt}
      />
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    return this.renderSalesReturnForm();
  }
}

export default withRouter(SalesReturnForm);
