import { getIn } from 'formik';

// use only for object keys when they are in form items table selectors
export const getError = (errors, touched, name) =>
  getIn(touched, name) && errors[name];

export const getErrorByKey = (errors, touched, name) =>
  getIn(touched, name) && getIn(errors, name);

export const validate = (getValidationSchema) => {
  return (values, props) => {
    const validationSchema = getValidationSchema(values, props);
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

const getErrorsFromValidationError = (validationError) => {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
};

// strip items from dropdown which are already added
export const removeExistingItems = (itemsList, existingItems) => {
  const addableItems = [];

  for (let item of itemsList) {
    for (let existingItem of existingItems) {
      if (item.id === existingItem.id) continue;
      addableItems.push(item);
    }
  }

  return addableItems;
};

export const formSubmit = (
  event,
  items,
  setFieldValue,
  handleSubmit,
  fieldId = 'id',
  fieldValue = 'items'
) => {
  event.preventDefault();
  //const filteredItems = items.filter(item => item[fieldId])
  const filteredItems = items;
  if (!filteredItems.length) {
    setFieldValue(fieldValue, [items[0]]);
  } else {
    setFieldValue(fieldValue, filteredItems);
  }
  setTimeout(() => handleSubmit(), 1);
};
