import React from 'react';
import * as Yup from 'yup';

import { restRequest } from '../../../Helpers/RequestHelper';
import { withFormik } from 'formik';

import { required, ValidateEmail } from '../../../Helpers/Validator';
// import Field from '../Field/Field'
import Form from '../Form';
// import FormAction from '../FormAction'
// import ErrorFocus from '../ErrorFocus'
import PlansTable from './PlansTable';
import { checkError } from '../../../Helpers/AuthHelper';

const formEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    email: required(ValidateEmail),
    // rate: required(ValidatePercentage),
    // authority: ValidateMax50
  }),
  mapPropsToValues: ({ tax }) => ({
    ...tax,
  }),
  handleSubmit: (values, bag) => {
    handleFormSubmit(values, bag);
  },
});

const handleFormSubmit = (
  values,
  { setSubmitting, props: { requestType, requestUrl } }
) => {
  const { email } = values;
  restRequest(requestType, requestUrl, {
    email,
  })
    .then((res) => {
      window.location.href = res.url;
    })
    .catch((error) => {
      checkError(error);
      setSubmitting(false);
    });
};

const PackagesPlanFormnhancer = (props) => {
  const {
    // values,
    handleSubmit,
    // handleChange,
    // handleBlur,
    // isSubmitting,
    // errors,
    // touched,
    // custom props
    // inModal,
    // closeModal,
     history,
    title,
    handleToast,
  } = props;
  return (
    <Form title={title} onSubmit={handleSubmit}>
      <PlansTable handleToast={handleToast} history={history}/>
      {/* <Field
        className="float-left w-100"
        size="large"
        value={values.email}
        id="email"
        name="Email"
        required
        autoComplete='on'
        onBlur={handleBlur}
        handleChange={handleChange}
        error={touched.email && errors.email}
      />

      <FormAction disabled={isSubmitting} onCancel={() => inModal ? closeModal() : history.goBack()} />
      <ErrorFocus /> */}
    </Form>
  );
};

export default formEnhancer(PackagesPlanFormnhancer);
