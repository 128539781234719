import React from 'react'
import CaretDown from '../../Assets/SVG/CaretDown'
import "./ActionButton.css"

export default function ActionButton() {
  return (
    <div className="CaretDownButton">
        Actions <CaretDown />
    </div>
  )
}
