import React, { Component } from 'react';
import { withRouter } from 'react-router';
import BillIcon from '../../../../Assets/General/BillIcon';
import CustomModal from '../../../../Components/CustomModal/CustomModal';
import ApplyOnInvoiceForm from '../../../../Components/Form/ApplyOnInvoiceForm/ApplyOnInvoiceForm';
import { NoItemsBox } from '../../../../Components/Layout/Box';
import ListTableColumnJoin from '../../../../Components/ListTable/ListTableColumnJoin';
import Loader from '../../../../Components/Loader/Loader';
import Table from '../../../../Components/Table/Table';
import getDate from '../../../../Helpers/Date/GetDate';
import { restRequest } from '../../../../Helpers/RequestHelper';
import { MdAdd } from '../../../../Common/Icons';
import DeleteIcon from '../../../../Assets/General/DeleteIcon';
import { getObjFromLS, setObjInLS } from '../../../../Helpers/LocalStorage';
import {
  NewBillIcon,
  NewInvoiceIcon,
} from '../../../../Assets/Navigation/NavigationIcons';
import InvoiceDetails from '../../../SalesOrder/Invoices/InvoiceDetails';
import { checkError } from '../../../../Helpers/AuthHelper';
class AppliedOnInvoiceTab extends Component {
  state = {
    loading: true,
    showApplyOnInvoice: false,
    applyInvoiceList: [],
    showDeleteInvoiceModal: false,
    viewApplyOnInvoiceModa: false,
    showButton: false,
    showPrompt: false,
  };
  componentDidMount() {
    this.fetchData();
  }

  handlePrompt = (status = true) => {
    this.setState({
      showPrompt: status,
    });
  };

  async fetchData() {
    await restRequest(
      'get',
      `creditnotes/${this.props.creditNoteId}/utilizaecredits`
    )
      .then((res) => {
        this.setState({
          showButton: res.show_button,
          applyInvoiceList: res.utilize_credits_list,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error);
        this.setState({ loading: false });
      });
  }

  openApplyOnInvoiceModal = () => {
    this.setState({
      showApplyOnInvoice: true,
    });
  };

  viewApplyOnInvoiceModal = (id) => {
    this.setState({
      viewApplyOnInvoiceModa: true,
      invoiceId: id,
    });
  };

  closeAllModals = () => {
    this.setState({
      showApplyOnInvoice: false,
      showDeleteInvoiceModal: false,
      viewApplyOnInvoiceModa: false,
    });
    document.title = 'Credit Note Details';
  };

  renderViewInvoiceModal() {
    return (
      <CustomModal
        Icon={NewInvoiceIcon}
        showModal={this.state.viewApplyOnInvoiceModa}
        title="View Invoice"
        renderActions={false}
        size="large"
        className='popup-height'
        onClose={this.closeAllModals}
        linkTo={`invoices/${this.state.invoiceId}`}
      >
        <InvoiceDetails id={this.state.invoiceId} inModal={true} />
      </CustomModal>
    );
  }

  deletePaymentModal() {
    return (
      <CustomModal
        type="delete"
        showModal={this.state.showDeleteInvoiceModal}
        title="Confirm Delete Credits"
        onClose={this.closeAllModals}
        onSubmit={this.deletePayment}
      >
        Are you sure you want to delete the credits applied against selected
        invoice?
      </CustomModal>
    );
  }

  deletePayment = () => {
    this.closeAllModals();
    restRequest('delete', `creditutilize/${this.state.clickedId}`)
      .then((res) => {
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS === null) {
          let obj = {
            prevId: null,
            id: null,
            nextId: null,
            moduleName: '',
            urlPath: window.location.pathname,
            queryParam: window.location.search,
          };
          setObjInLS('module', obj);
          dataFromLS = getObjFromLS('module');
        }
        setObjInLS('module', {
          ...dataFromLS,
          queryParam: `${
            dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
          }appliedOnInvoices=true`,
        });
        this.fetchData();
        this.props.updateCreditNote();
        this.props.handleToast(
          'Credits applied against selected invoice deleted successfully',
          'success'
        );
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  };
  renderApplyOnInvoiceModal() {
    const { creditNoteId, handleToast } = this.props;
    return (
      <CustomModal
        Icon={BillIcon}
        className="AppplyCredits"
        showModal={this.state.showApplyOnInvoice}
        title={`Apply Credits | ${this.props.creditNoteNo}`}
        renderActions={false}
        size="large"
        onClose={this.closeAllModals}
        extraClass="apply_credit_notes" //commenting this to remove extra space from modal. Ticket related
        extraTitle={`Total Available Credits : ${this.props.currency.symbol}${this.props.totalAvailableCredits}`}
        showPrompt={this.state.showPrompt}
      >
        <ApplyOnInvoiceForm
          inModal
          type="invoice"
          id={creditNoteId}
          handleToast={handleToast}
          onSubmit={this.handleRecordPaymentSubmit}
          onClose={this.closeAllModals}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }
  renderModals() {
    return (
      <>
        {this.renderApplyOnInvoiceModal()}
        {this.deletePaymentModal()}
        {this.renderViewInvoiceModal()}
      </>
    );
  }
  tableRowOptions = [
    {
      label: 'Delete',
      icon: DeleteIcon,
      onClick: (id) => this.openInvoiceDeleteModal(id),
      disabled: () => !this.props.hasPermissionCustom('creditnotes', 'Delete'),
    },
  ];

  openInvoiceDeleteModal = (id) => {
    this.setState({
      showDeleteInvoiceModal: true,
      clickedId: id,
    });
  };

  prepareApplyInvoiceList(data) {
    return data.map((invList) => {
      return {
        ...invList,
        paymentDate: getDate(invList.credit_utilize_date),
        no: this.props.inModal ? (
          invList.invoice.invoice_no
        ) : (
          <ListTableColumnJoin
            column1={
              <div
                className="payment_view"
                onClick={() => this.viewApplyOnInvoiceModal(invList.invoice_id)}
              >
                {invList.invoice.invoice_no}
              </div>
            }
          />
        ),
        amount_credited: `${this.props.currency.symbol}${invList.used_credits}`,
      };
    });
  }

  renderApplyInvoiceTableData() {
    const tableListData = this.prepareApplyInvoiceList(
      this.state.applyInvoiceList
    );
    const tableHeaders = ['Date Credited', 'Invoice No.', 'Amount Credited'];
    const tableData = [
      'paymentDate',
      'no',
      'amount_credited',
      !this.props.inModal && 'options',
    ];

    return (
      <>
        {this.state.applyInvoiceList.length > 0 && (
          <div className="float-left w-100">
            <Table
              list={tableListData}
              tableHeaders={tableHeaders}
              tableData={tableData}
              fromTab={true}
              options={this.props.inModal ? [] : this.tableRowOptions}
              className={`box-table ${
                this.props.label ? this.props.label + '_menu' : ''
              }`}
            />
          </div>
        )}
        {this.state.showButton &&
          this.props.hasPermissionCustom('creditnotes', 'Create') &&
          (this.props.inModal ? (
            ''
          ) : (
            <button
              className="layout-box--button btn-width"
              onClick={this.openApplyOnInvoiceModal}
            >
              <MdAdd className=" black-fill" />
              Apply To Invoice
            </button>
          ))}
      </>
    );
  }
  ApplyOnInvoiceList() {
    return (
      <>
        {this.renderModals()}
        {!this.state.applyInvoiceList.length ? (
          <NoItemsBox
            subtitle={
              this.state.showButton
                ? ''
                : 'Crediting Invoices Not Applicable Yet!'
            }
            buttonText="Apply to Invoice"
            showButton={this.state.showButton}
            handleclick={this.openApplyOnInvoiceModal}
            handleclickSecondary={this.openApplyCreditsPaymentModal}
            Icon={NewBillIcon}
            inModal={this.props.inModal}
          />
        ) : (
          this.renderApplyInvoiceTableData()
        )}
      </>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return this.ApplyOnInvoiceList();
  }
}
export default withRouter(AppliedOnInvoiceTab);
