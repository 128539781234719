import React, { Component, Fragment } from 'react';

import { restRequest } from '../../../../Helpers/RequestHelper';

import Loader from '../../../../Components/Loader/Loader';
import Table from '../../../../Components/Table/Table';
import CustomModal from '../../../../Components/CustomModal/CustomModal';
import AccessDenied from '../../../../Components/AccessDenied/AccessDenied';

import InviteForm from '../../../../Components/Form/InviteForm/InviteForm';
import WithContext from '../../../../Context/UserContextHOC';
import { getObjFromLS } from '../../../../Helpers/LocalStorage';
import EditIcon from '../../../../Assets/General/EditIcon';
import DeleteIcon from '../../../../Assets/General/DeleteIcon';
import MarkAsActive from '../../../../Assets/General/ActiveIcon';
import MarkAsInactive from '../../../../Assets/General/InactiveIcon';
import { checkError } from '../../../../Helpers/AuthHelper';

let hasEditPermission = false;
let hasDeletePermission = false;

class UsersTab extends Component {
  state = {
    loading: true,
    users: [],
    inviteToggle: false,
    showDeleteModal: false,
    deleteUserId: null,
    invitedUserId: null,
    editInviteToggle: false,
  };
  dataFromLS = getObjFromLS('role');
  tableRowOptions = [
    {
      label: 'Edit',
      onClick: (id) => this.toggleEditInvite(id),
      disabled: (id) => this.isAdmin(id) || hasEditPermission,
      icon: EditIcon,
    },
    {
      label: 'Delete',
      onClick: (id) => this.openDeleteModal(id),
      disabled: (id) => this.isAdminOrUser(id) || hasDeletePermission,
      icon: DeleteIcon,
    },
    {
      label: 'Mark as Active',
      onClick: (id) => this.markAsActive(id),
      disabled: (id) =>
        this.isAdmin(id) || this.checkActiveStatus(id) || hasEditPermission,
      icon: MarkAsActive,
    },
    {
      label: 'Mark as Inactive',
      onClick: (id) => this.markAsInactive(id),
      disabled: (id) =>
        this.isAdmin(id) || this.checkInactiveStatus(id) || hasEditPermission,
      icon: MarkAsInactive,
    },
  ];

  componentDidMount() {
    hasEditPermission =
      this.dataFromLS.permissions.UserEdit === false
        ? !this.dataFromLS.permissions.UserEdit
        : false;
    hasDeletePermission =
      this.dataFromLS.permissions.UserDelete === false
        ? !this.dataFromLS.permissions.UserDelete
        : false;
    this.fetchData();
  }

  fetchData() {
    this.setState({
      loading: true,
    });
    restRequest('get', 'users')
      .then((res) => {
        const users = res.map((user) => {
          let fullName = `${user.invite_name}${
            user.name ? ` (${user.name})` : ''
          }`;
          if (!user.invite_name) {
            fullName = user.name;
          }

          return {
            ...user,
            name: fullName,
            status: user.status === 0 ? 'Inactive' : 'Active',
            // statusString:user.status===0 ? 'Inactive' : 'Active'
          };
        });
        this.setState({
          users,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error);
      });
  }
  markAsActive(id) {
    restRequest('put', `user/${id}/active`)
      .then((res) => {
        this.props.handleToast(res.message, 'success');
        this.fetchData();
      })
      .catch((error) => checkError(error, this.props.handleToast));
  }

  markAsInactive(id) {
    restRequest('put', `user/${id}/inactive`)
      .then((res) => {
        this.props.handleToast(res.message, 'success');
        this.fetchData();
      })
      .catch((error) => checkError(error, this.props.handleToast));
  }

  checkActiveStatus(id) {
    return this.state.users.find((user) => user.id === id).status === 'Active'
      ? true
      : false;
  }

  checkInactiveStatus(id) {
    return this.state.users.find((user) => user.id === id).status === 'Inactive'
      ? true
      : false;
  }
  isAdmin(id) {
    return (
      this.state.users.find((user) => user.id === id).role_type ===
      'super admin'
    );
  }

  isAdminOrUser(id) {
    return this.isAdmin(id) || id === this.props.context.currentUser.id;
  }

  toggleInvite = () => {
    this.setState((state) => {
      return {
        ...state,
        inviteToggle: !state.inviteToggle,
      };
    });
  };

  toggleEditInvite = (id = false) => {
    this.setState((state) => {
      return {
        ...state,
        editInviteToggle: !state.editInviteToggle,
        invitedUserId: id,
      };
    });
  };

  onSubmitInvite = () => {
    this.setState(
      {
        editInviteToggle: false,
        inviteToggle: false,
      },
      () => {
        this.fetchData();
      }
    );
  };

  openDeleteModal = (id) => {
    this.setState({
      showDeleteModal: true,
      deleteUserId: id,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
    });
  };

  deleteUser = () => {
    this.closeDeleteModal();
    restRequest('delete', `organizations/users/${this.state.deleteUserId}`)
      .then((response) => {
        this.props.handleToast('User has been deleted.', 'success');
        this.fetchData();
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  };

  renderDeleteModal = () => {
    return (
      <CustomModal
        type="delete"
        showModal={this.state.showDeleteModal}
        title="Confirm User Delete"
        onClose={this.closeDeleteModal}
        onSubmit={this.deleteUser}
      >
        Are you sure you want to delete the User?
      </CustomModal>
    );
  };

  checkPermissions() {
    const {
      context: {
        role: { permissions },
      },
      handleToast,
    } = this.props;

    if (this.state.inviteToggle) {
      return (
        <div className="padding--regular">
          <InviteForm
            handleToast={handleToast}
            toggleInvite={this.toggleInvite}
            onSubmitInvite={this.onSubmitInvite}
            setPrompt={this.props.setPrompt}
            handleConfirmFormInviteForm={this.props.handleConfirmFormInviteForm}
          />
        </div>
      );
    }

    if (this.state.editInviteToggle) {
      return (
        <div className="padding--regular">
          <InviteForm
            fromUserTab
            inviteId={this.state.invitedUserId}
            handleToast={handleToast}
            toggleInvite={this.toggleEditInvite}
            onSubmitInvite={this.onSubmitInvite}
            setPrompt={this.props.setPrompt}
            handleConfirmFormInviteForm={this.props.handleConfirmFormInviteForm}
          />
        </div>
      );
    }

    if (!permissions.superAccess && !permissions.UserView) {
      return (
        <Fragment>
          {permissions.InviteCreate && (
            <button
              className="button--primary margin-bottom-md float-right margin--regular-right"
              onClick={() => this.setState({ inviteToggle: true })}
            >
              Invite
            </button>
          )}
          <AccessDenied className="small" type="section" />
        </Fragment>
      );
    }

    if (this.state.loading) return <Loader />;
    const tableHeaders = ['User Name', 'Email', 'Role', 'Status', ''];
    const tableData = ['name', 'email', 'role_name', 'status', 'options'];

    return (
      <Fragment>
        {/* {
          (permissions.superAccess || permissions.InviteCreate) &&
          <button className="button--primary margin-bottom-md float-right margin--regular-right" onClick={() => this.setState({ inviteToggle: true })}>
            Invite
          </button>
        } */}
        {this.renderDeleteModal()}
        <div className="package-item-info package_details_table">
          <Table
            className="list-table with-actions" // make actions th, td text center
            list={this.state.users}
            tableHeaders={tableHeaders}
            tableData={tableData}
            options={this.tableRowOptions}
          />
        </div>
      </Fragment>
    );
  }

  render() {
    return this.checkPermissions();
  }
}

export default WithContext(UsersTab);
