import React from 'react'

import './RadioContainer.css'

const RadioContainer = (
  {
    children,
    inline
  }) => {
  return (
    <div className={`radio-container ${inline ? 'inline' : ''}`} style={{height: "2.2rem"}}>
      {children}
    </div>
  )
}

export default RadioContainer