import React, { Component } from 'react';

import { restRequest } from '../../../../../Helpers/RequestHelper';
import { checkError } from '../../../../../Helpers/AuthHelper';
import AddCard from '../../../../../Components/Card/AddCard';
import CardContainer from '../../../../../Components/Card/CardContainer';
import AddContactEmployees from './CreateUpdateContactEmployees';
import EditContactEmployees from './CreateUpdateContactEmployees';
import ContactEmployees from './ContactEmployees';
import Loader from '../../../../../Components/Loader/Loader';
import CustomModal from '../../../../../Components/CustomModal/CustomModal';

class ContactEmployeesTab extends Component {
    state = {
        contactEmployees: [],
        renderAddEmployee: false,
        renderEditEmployee: false,
        editEmployee: {},
        showDeleteModal: false,
        deleteEmployee: {},
        loading: true,
    };

    componentDidMount() {
        this.fetchContactEmployees();
    }

    fetchContactEmployees = async () => {
        this.setState({ loading: true });
        await restRequest('get', `contacts/${this.props.contactId}/persons`)
            .then((list) => {
                this.setState({ contactEmployees: list });
            })
            .catch((error) => {
                this.setState({ loading: false });
                checkError(error, this.props.handleToast);
            });
        this.setState({ loading: false });
    };

    handleAdd = () => {
        this.setState({
            renderAddEmployee: true,
        });
    };

    handleAddSubmit = () => {
        this.setState({ renderAddEmployee: false });
        this.fetchContactEmployees();
    };

    handleEdit = (employee) => {
        this.setState({
            editEmployee: employee,
            renderEditEmployee: true,
        });
    };

    handleEditSubmit = () => {
        this.setState({ renderEditEmployee: false });
        this.fetchContactEmployees();
    };

    handleDelete = (employee) => {
        this.setState({
            deleteEmployee: employee,
            showDeleteModal: true,
        });
    };

    closeDeleteModal = () => {
        this.setState({
            showDeleteModal: false,
        });
    };

    confirmDelete(id) {
        restRequest('delete', `contacts/${this.props.contactId}/persons/${id}`)
            .then(() => {
                let updatedContactEmployees =
                    this.state.contactEmployees.filter(
                        (employee) => employee.id !== id
                    );
                this.setState({
                    contactEmployees: updatedContactEmployees,
                    showDeleteModal: false,
                });
                this.props.handleToast(
                    `Employee deleted successfully`,
                    'success'
                );
            })
            .catch((error) => {
                this.closeDeleteModal();
                checkError(error, this.props.handleToast);
            });
    }

    renderDeleteModal() {
        let employee = this.state.deleteEmployee;
        return (
            <CustomModal
                type="delete"
                showModal={this.state.showDeleteModal}
                title="Confirm Delete"
                onClose={this.closeDeleteModal}
                onSubmit={() => this.confirmDelete(employee.id)}
            >
                {`Are you sure you want to delete ${employee.salutation} ${employee.first_name} ${employee.last_name}?`}
            </CustomModal>
        );
    }

    markAsPrimary = (id) => {
        restRequest(
            'put',
            `contacts/${this.props.contactId}/persons/${id}/primary`
        )
            .then(() => {
                this.props.fetchData();
            })
            .catch((error) => {
                checkError(error, this.props.handleToast);
            });
    };

    renderEmployees() {
        if (this.state.renderAddEmployee) {
            return (
                <AddContactEmployees
                    contactId={this.props.contactId}
                    onCancel={() => this.setState({ renderAddEmployee: false })}
                    onSubmit={this.handleAddSubmit}
                    handleToast={this.props.handleToast}
                    type="add"
                />
            );
        }
        if (this.state.renderEditEmployee) {
            return (
                <EditContactEmployees
                    contactId={this.props.contactId}
                    employee={this.state.editEmployee}
                    onCancel={() =>
                        this.setState({ renderEditEmployee: false })
                    }
                    onSubmit={this.handleEditSubmit}
                    handleToast={this.props.handleToast}
                    type="edit"
                />
            );
        }
        return (
            <>
                {this.renderDeleteModal()}
                <CardContainer>
                    <AddCard title="Add Employees" handleAdd={this.handleAdd} />
                    <ContactEmployees
                        contactEmployees={this.state.contactEmployees}
                        handleEdit={this.handleEdit}
                        handleDelete={this.handleDelete}
                        markAsPrimary={this.markAsPrimary}
                    />
                </CardContainer>
            </>
        );
    }

    render() {
        if (this.state.loading) return <Loader />;
        return <div className="padding--regular">{this.renderEmployees()}</div>;
    }
}

export default ContactEmployeesTab;
