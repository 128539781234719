import React, { Fragment, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withFormik, FieldArray } from 'formik';
import {
  getObjFromLS,
  checkError,
  setObjInLS,
  restRequest,
  // getItemTotalAmount,
  // formatNumber,
  getDate,
} from '../../../Helpers';
import {
  //moreThanZero,
  //moreThanMinus,
  required,
  ValidateDate,
  //ValidateMax100Number,
  //ValidateMax10Billion,
  //ValidateAmountNumber,
  ValidateMax255,
  ValidateMax50,
  ValidateNumber,
  // ValidateEqual0,
  ValidateRequired,
  // ValidateMax1000,
} from '../../../Helpers/Validator';
import { Field, Form, FormSection, FormAction, ErrorFocus } from '..';
import { formSubmit, getError, validate } from '../FormHelper';
import { CustomModal, Loader } from '../..';
import Customer from '../../../Pages/Contact/ContactDetails/index';
import {
  NewContactIcon,
  NewInvoiceIcon,
} from '../../../Assets/Navigation/NavigationIcons';
import InvoiceDetails from '../../../Pages/SalesOrder/Invoices/InvoiceDetails';

//let allValls = [];

//let confirm = false;
const getYupValidationSchema = (values) => {
  return Yup.object().shape({
    contactId: required(ValidateNumber),
    paymentId: ValidateRequired,
    amount_received: ValidateRequired,
    paymentDate: required(ValidateDate),
    reference: ValidateMax50,
    customerNotes: ValidateMax255,
    // items: Yup.array()
    //   .of(
    //     Yup.lazy((item) => {
    //       return Yup.object().shape({
    //         name: ValidateRequired,
    //         quantity: required(
    //           moreThanZero(ValidateMax10Billion).integer('Invalid Value')
    //         ),
    //         unitPrice: required(moreThanMinus(ValidateMax10Billion)),
    //         discount:
    //           item.discountType === 'percent'
    //             ? ValidateMax100Number
    //             : ValidateAmountNumber(
    //                 item.discount,
    //                 item.quantity * item.unitPrice
    //               ),
    //       });
    //     })
    //   )
    //   .min(1, 'At least one item is required'),
  });
};

const formEnhancer = withFormik({
  validate: validate(getYupValidationSchema),
  mapPropsToValues: ({ salesOrder, paymentMethods, ...props }) => ({
    // ...salesOrder,
    saveAndSend: false,
    saveAndPrint: false,
    ...props,
  }),
  mapPropsToStatus: (props) => ({
    showContactCreateModal: false,
    showBulkAddItemsModal: false,
    showEditBillingAddressModal: false,
    showEditShippingAddressModal: false,
    showAddBillingAddressModal: false,
    showAddShippingAddressModal: false,
    loadingAddress: false,
    loadingItems: false,
    ...props,
  }),
  handleSubmit: (values, bag) => {
    sessionStorage.setItem('showPrint', false);
    handleFormSubmit(values, bag);
  },
});
const handleFormSubmit = (
  values,
  {
    setSubmitting,
    props: {
      id,
      submitRequestType,
      submitRequestUrl,
      successMessage,
      type,
      history,
      handleToast,
      setPrompt,
    },
  }
) => {
  restRequest(submitRequestType(), submitRequestUrl(), {
    customer_id: values.contactId,
    payment_no: values.paymentId,
    payment_date:
      values.paymentDate && values.paymentDate.format('YYYY-MM-DD 00:00:00'),
    payment: +values.amount_received,
    reference: values.reference,
    payment_mode: values._payment_mode,
    note: values.customerNotes,
    invoices: values.invoiceList
      .filter((item) => item.payment > 0)
      .map((item) => ({
        invoice_id: item.id,
        payment: item.payment,
      })),
  })
    .then((res) => {
      setPrompt(false);
      successMessage();
      // if (values.saveAndSend) {
      //   history.push(`/salesorders/email/${id ? id : res.data.id}`);
      // } else if (values.saveAndPrint) {
      //   sessionStorage.setItem('showPrint', true);
      //   let dataFromLS = getObjFromLS('module');
      //   setObjInLS('module', {
      //     ...dataFromLS,
      //     moduleName: 'salesorders',
      //     urlPath: '/salesorders',
      //     showPrint: true,
      //     action: type === 'add' ? 'add' : 'justedit',
      //     queryParam: dataFromLS.queryParam ? dataFromLS.queryParam : '',
      //   });
      //   return history.push('/r');
      // } else {
      let dataFromLS = getObjFromLS('module');
      if (type === 'add') {
        setObjInLS('module', {
          ...dataFromLS,
          moduleName: 'customer_payments',
          urlPath: '/customer_payments',
          action: type === 'add' ? 'add' : 'justedit',
          queryParam: '',
        });
      } else if (type === 'clone') {
        setObjInLS('module', {
          ...dataFromLS,
          moduleName: 'customer_payments',
          urlPath: '/customer_payments',
          action: 'add',
          queryParam: '',
        });
      }
      type === 'edit' &&
        setObjInLS('module', {
          ...dataFromLS,
          moduleName: 'customer_payments',
          urlPath: '/customer_payments',
          action: type === 'add' ? 'add' : 'justedit',
          queryParam: dataFromLS.queryParam ? dataFromLS.queryParam : '',
        });
      history.push(`/contacts/${values.contactId}`);
      // history.push('/r');
      sessionStorage.setItem('fullscreen', false);
      // }
    })
    .catch((error) => {
      setSubmitting(false);
      checkError(error);
      handleToast(error, 'error');
    });
};

const CustomerPaymentForm = ({
  // props from formik
  values,
  status,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
  setStatus,
  setValues,
  isSubmitting,
  errors,
  touched,
  setFieldTouched,

  // custom props
  type,
  organizationCurrency,
  contactIdTransaction,
  customerList,
  newItem,
  history,
  handleToast,
  invoiceList,
  cofirmState,
  ...props
}) => {
  // const [showPrompt, setShowPrompt] = useState(false);
  const [staticVal, setStaticValues] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [invoiceId, setInvoiceId] = useState();
  const [fullAmountRec, setFullAmountRec] = useState(false);

  useEffect(() => {
    handleAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, staticVal]);

  useEffect(() => {
    setStaticValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAlert = () => {
    // let { checkout } = validateCreateSalesOrderForm(values, staticVal);
    // if (!checkout)
    props.setPrompt();
    // else
    // props.setPrompt(false);
  };

  const handlePaymentChange = (option) => {
    if (option === null) {
      setFieldValue('payment_mode', null);
    } else {
      let isNewOption = !status.paymentMethods.find(
        (method) => method.name === option.label
      );
      if (!isNewOption) {
        setFieldValue('payment_mode', option.id);
        setFieldValue('_payment_mode', option.name);
      }
    }
  };

  const [, setShowMergeModal] = useState(false);

  useEffect(() => {
    contactIdTransaction &&
      setFieldValue('contactId', parseInt(contactIdTransaction));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    return () => {
      //allValls = [];
      setShowMergeModal(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loadingItem] = useState(false);

  const handleItemQuantityChange = (quantityValue, index) => {
    let quantity = quantityValue.floatValue;
    let itemList = [...values.invoiceList];
    let currentItem = itemList[index];
    itemList[index] = { ...currentItem, payment: quantity };
    setFieldValue('invoiceList', itemList);
  };

  /*function handleSaveAndSend(event, submitFlag) {
    setFieldValue('saveAndSend', true);
    if (submitFlag === false || submitFlag === undefined) {
      event.preventDefault();
      setShowMergeModal(true);
    } else {
      return formSubmit(
        event,
        values.items,
        setFieldValue,
        handleSubmit
        // wareId
      );
    }
  }*/

  const closeModal = () => {
    setShowModal(false);
  };

  const viewInvoiceModal = () => {
    return (
      <CustomModal
        Icon={NewInvoiceIcon}
        showModal={showModal}
        title="View Invoice"
        renderActions={false}
        size="large"
        onClose={closeModal}
        linkTo={`invoices/${invoiceId}`}
        classNames="so_view_invoices"
      >
        <InvoiceDetails id={invoiceId} inModal />
      </CustomModal>
    );
  };

  const payFullHandler = (index) => {
    const updatedList = values.invoiceList.map((singleInvoice, i) => {
      return index === i
        ? { ...singleInvoice, payment: singleInvoice.payment_due }
        : singleInvoice;
    });
    setFieldValue('invoiceList', updatedList);
  };

  function renderList() {
    return (
      <FieldArray
        name="items"
        render={() => {
          return (
            <div className="so-tbletabs">
              <table
                style={{ width: '100%' }}
                className={`list-table form-table border create_sales_orde`}
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th className="left-align">Invoice Number</th>
                    <th className="left-align">Invoice Amount</th>
                    <th className="right-align">Amount Due</th>
                    <th className="right-align">Payment</th>
                  </tr>
                </thead>
                <tbody>
                  {values.invoiceList.map((item, index) => (
                    <tr key={index} className="table--row">
                      <td className="list-table--amount">
                        <span>{getDate(new Date(`${item.invoice_date}`))}</span>
                      </td>
                      <td className="list-table--amount">
                        <span
                          className="item_view_click_btn"
                          onClick={() => {
                            setInvoiceId(item.id);
                            setShowModal(true);
                          }}
                        >
                          {item.invoice_no}
                        </span>
                      </td>
                      <td className="list-table--amount">
                        <span>{item.total}</span>
                      </td>
                      <td className="list-table--amount">
                        <span>{item.payment_due}</span>
                      </td>
                      <td className="right-align no-padding previous_sold">
                        <div>
                          <Field
                            size="small"
                            type="number"
                            value={item?.payment || 0}
                            decimalScale={6}
                            id={`items[${index}].payment`}
                            allowNegative={false}
                            thousandSeparator=","
                            onValueChange={(value) =>
                              handleItemQuantityChange(value, index)
                            }
                            error={getError(
                              errors,
                              touched,
                              `items[${index}].payment`
                            )}
                            onBlur={handleBlur}
                          />
                          <small
                            onClick={() => payFullHandler(index)}
                            className="payFullbtn"
                          >
                            Pay in Full
                          </small>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          );
        }}
      />
    );
  }

  const getExcessAmmount = () => {
    let amountReceived = values.amount_received
      ? values.amount_received.replace(/,/g, '')
      : 0;
    let Payments = values.invoiceList.reduce(
      (total, val) => (val.payment ? total + val.payment : total + 0),
      0
    );
    let total = amountReceived - Payments;
    return total.toFixed(2);
  };

  const renderSummary = () => {
    let currencySymbol = organizationCurrency.symbol || '$';
    return (
      <div className="float-left w-100 mt_3 summary_table">
        <hr className="margin float-left w-100" />
        <div className="float-left w-60 p_10"></div>
        <div className="float-left w-40 p_10 pr-0">
          <div className="float-left w-100 so-mg-1">
            <span className="float-left">Amount Received </span>
            <span className="float-right semi-bold">{`${currencySymbol}${
              values.amount_received
                ? Number(values.amount_received.replace(/,/g, '')).toFixed(2)
                : 0
            }`}</span>
          </div>

          <div className="float-left w-100 so-mg-1">
            <span className="float-left">Amount used for Payments </span>
            <span className="float-right semi-bold">{`${currencySymbol}${parseFloat(
              values.invoiceList.reduce(
                (total, val) => (val.payment ? total + val.payment : total + 0),
                0
              ) || 0
            ).toFixed(2)}`}</span>
          </div>
          {/* 
          <div className="float-left w-100 so-mg-1">
            <span className="float-left">Amount Refunded </span>
            <span className="float-right semi-bold">{`${currencySymbol}${parseFloat(
              18.5
            )}`}</span>
          </div> */}

          <div className="float-left w-100 so-mg-1">
            <span className="float-left">Amount in Excess </span>
            <span className="float-right semi-bold">{`${currencySymbol}${getExcessAmmount()}`}</span>
          </div>
        </div>

        <hr className="margin float-left w-100" />
        <hr className="margin float-left w-100" />
      </div>
    );
  };

  const renderModals = () => {
    return (
      <Fragment>
        {viewInvoiceModal()}
        {showCustomerModal && contactDeatilModal()}
      </Fragment>
    );
  };

  const handleClearAmount = (e) => {
    e.preventDefault();
    const list = [...values.invoiceList];
    let updatedList = list.map((val) => ({ ...val, payment: 0 }));
    setFieldValue('invoiceList', updatedList);
  };

  let submitFlag = true;
  const [stopRequest] = useState(false);
  //const [setstopRequest] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [customerId, setCustomerId] = useState('');
  const handleCustomerModal = (id) => {
    setCustomerId(id);
    setShowCustomerModal(true);
  };
  const contactDeatilModal = () => {
    return (
      <CustomModal
        size="large"
        className="contacts_modal_view"
        showModal={showCustomerModal}
        title="View Contact"
        modaltitle="inventory_modal_title"
        Icon={NewContactIcon}
        renderActions={false}
        onClose={() => setShowCustomerModal(false)}
        linkTo={`contacts/${customerId && customerId}`}
      >
        <Customer id={customerId && customerId} inModal={true} />
      </CustomModal>
    );
  };

  const handleReceivedAmount = ({ target }) => {
    setFullAmountRec(target.checked);
    if (target.checked) {
      let val = [...values.invoiceList];
      let list = val.map((val) => ({ ...val, payment: val.payment_due }));
      let receivedfullAmount = values.invoiceList.reduce(
        (total, val) => total + val.payment_due,
        0
      );
      setValues({
        ...values,
        amount_received: receivedfullAmount.toString(),
        invoiceList: list,
      });
    } else {
      let val = [...values.invoiceList];
      let list = val.map((val) => ({ ...val, payment: 0 }));
      setValues({
        ...values,
        amount_received: '0',
        invoiceList: list,
      });
    }
  };
  return (
    <Fragment>
      {renderModals()}
      <Form
        className="salesorder-form feilds_so"
        onSubmit={(event) => {
          if (stopRequest === true) event.preventDefault();
          else if (submitFlag === false) {
            event.preventDefault();
            setShowMergeModal(true);
          } else {
            formSubmit(event, values.items, setFieldValue, handleSubmit);
          }
        }}
      >
        <FormSection
          title={`${
            type && type === 'add' ? 'Add' : type === 'edit' ? 'Edit' : 'Clone'
          } Sales Order`}
        >
          <Fragment>
            <div className="customer-name-fileds">
              <Field
                className="inline-field so_feilds uiux_so-fields"
                type="PaginationDropdown"
                name="Customer Name"
                id="contactId"
                value={values.contactId}
                options={customerList}
                dropdownValue="id"
                dropdownLabel="display_name"
                required
                error={touched.contactId && errors.contactId}
                size="large"
                disabled
              />

              {values.contactId && (
                <button
                  type="button"
                  className="view-details-btn"
                  //
                  style={{ zIndex: 999 }}
                  onClick={() => handleCustomerModal(values.contactId)}
                >
                  {' '}
                  View Details
                </button>
              )}
            </div>
            <div className="customer-name-fileds">
              <Field
                className="inline-field so_feilds"
                size="large"
                type="number"
                name="Amount Received"
                id="amount_received"
                placeholder="0"
                value={values.amount_received}
                handleChange={handleChange}
                onBlur={handleBlur}
                required
                allowNegative={false}
                thousandSeparator=","
                error={touched.amount_received && errors.amount_received}
                disabled={fullAmountRec}
              />
              <input
                type="checkbox"
                className="view-details-btn"
                id="check"
                onClick={handleReceivedAmount}
              />
              <label
                style={{ cursor: 'pointer', paddingLeft: '5px' }}
                htmlFor="check"
              >
                {`Received full amount  (${values.invoiceList.reduce(
                  (total, val) => total + val.payment_due,
                  0
                )})`}
              </label>
            </div>
            <Field
              className="inline-field so_feilds"
              type="number"
              size="large"
              name="Payment No"
              id="paymentId"
              placeholder=""
              value={values.paymentId}
              handleChange={handleChange}
              onBlur={handleBlur}
              error={touched.paymentId && errors.paymentId}
              disabled
            />
          </Fragment>

          <div className="so-form-inner">
            <Field
              className="inline-field so_feilds"
              type="date"
              name="Payment Date"
              id="paymentDate"
              size="large"
              value={values.paymentDate}
              handleChange={(date) => {
                setFieldValue('paymentDate', date);
              }}
              onBlur={() => setFieldTouched('paymentDate', true)}
              required
              error={touched.paymentDate && errors.paymentDate}
            />

            <Field
              className="inline-field so_feilds"
              size="large"
              type="dropdown"
              name="Payment Mode"
              id="payment_mode"
              value={values.payment_mode}
              options={status.paymentMethods}
              dropdownValue="id"
              dropdownLabel="name"
              handleChange={handlePaymentChange}
            />
            <div className="middle-row">
              <Field
                className="inline-field description_box "
                size="large"
                type="textarea"
                name="Customer Notes"
                value={values.customerNotes}
                id="customerNotes"
                handleChange={handleChange}
                onBlur={handleBlur}
                error={touched.customerNotes && errors.customerNotes}
              />
            </div>
            <Field
              className="inline-field"
              name="Reference"
              size="large"
              id="reference"
              value={values.reference}
              handleChange={handleChange}
              onBlur={handleBlur}
              error={touched.reference && errors.reference}
            />
          </div>

          <div
            className="form-field form-table--container"
            style={{ width: '95%' }}
          >
            <div style={{ display: 'flex' }}>
              <label className="semi-bold float-left w-100 mb_3">
                Select Items
              </label>
              <div style={{ width: '15rem' }}>
                <button
                  style={{ cursor: 'pointer', color: 'blue', fontSize: '12px' }}
                  onClick={handleClearAmount}
                >
                  Clear Applied Amount
                </button>
              </div>
            </div>
            {loadingItem && <Loader />}
            {renderList()}
            {renderSummary()}
          </div>

          <FormAction
            className="So-actions"
            disabled={isSubmitting}
            marginFromRight={5.5}
            saveBtnFlag
            onSubmit={handleSubmit}
            onCancel={() => {
              let dataFromLS = getObjFromLS('module');
              let redirectUrl = '';
              if (dataFromLS && dataFromLS.moduleName === 'salesorders') {
                redirectUrl = dataFromLS.queryParam;
              }
              if (
                window.location.pathname.includes('add') ||
                window.location.pathname.includes('edit')
              ) {
                history.push('/salesorders' + redirectUrl);
              } else {
                setObjInLS('module', {
                  ...dataFromLS,
                  action: null,
                });
                history.push('/r');
              }
            }}
            // isSalesOrder={true}
            // dropUpSO={[
            //   {
            //     label: 'Save & Send',
            //     onClick: (event) => {
            //       setstopRequest(true);
            //       handleSaveAndSend(event, submitFlag);
            //       handleSubmit();
            //     },
            //   },
            // ]}
          />
        </FormSection>
        <ErrorFocus />
      </Form>
    </Fragment>
  );
};

export default formEnhancer(CustomerPaymentForm);
