import React, { Component } from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import moment from 'moment';
import {
  restRequest,
  checkError,
  getOrganizationDate,
  getObjFromLS,
  setObjInLS,
  GetDateString,
} from '../../../Helpers';
import { Loader, NotFound } from '../../';
import CreditNoteEnhancer from './CreditNoteEnhancer';

// import './SalesReturnForm.css'
let warehouseResult = [];
class CreditNoteForm extends Component {
  type = this.props.type; // "add" or "edit" form
  id =
    this.props.creditNoteId ||
    this.props.id ||
    this.props?.match?.params?.id ||
    null;

  emptyItem = {
    id: null,
    contactPerson: [],
    dragDropID: GetDateString(),
    contactPersonId: null,
    salesOrderItemDetailId: null,
    name: '',
    image: '',
    allContactsList: [],
    sku: '',
    unit: 'unit',
    ordered: 0,
    shipmentId: null,
    packed: 0,
    shipped: 0,
    stocks: 0,
    quantity: null,
    saleOrdersList: [],
    returnReceivesList: [],
    salesReturnList: [],
    preferences: {
      adjustement_criteria: '',
      adjustment: null,
    },
  };

  state = {
    creditNoteNo: '',
    invoiceInfo: {
      invoiceId: null,
    },
    saleorderInfo: {
      saleReturnNo: '',
      salesOrderId: null,
      salesReturnDate: moment(getOrganizationDate()),
      orderDate: null,
      items: [this.emptyItem],
      invoice: null,
    },
    // New Functionality
    warehouseList: [],
    directSoldItemsArray: [],
    directSoldItemsFlag: false,
    returnReason: '',
    genericWarehouseId: null,
    itemsList: [],
    backToStock: null,
    allResult: {},
    invoiceNumber: '',
    contactDisplayName: '',
    isSaleOrder: false,
    salesOrderList: [],
    currency: {
      currency_code: '',
      name: '',
      symbol: '',
    },
    loading: true,
    notFound: false,
    showPrompt: false,
  };

  componentDidMount() {
    this.fetchData();
    sessionStorage.setItem('once', false);
    sessionStorage.setItem('fullscreen', false);
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  async fetchData() {
    switch (this.type) {
      case 'edit':
        await this.fetchSalesReturnEditData();
        break;
      case 'add':
      default:
        await this.setInvoiceId();
        await this.fetchCreditNoteCreateData(this.state.invoiceInfo.invoiceId);
    }

    this.setState({ loading: false });
  }

  async setInvoiceId() {
    if (this.props.inModal) {
      this.setState({
        invoiceInfo: {
          ...this.state.invoiceInfo,
          invoiceId: this.props.invoiceId,
        },
      });
    } else {
      var res = null;
      await restRequest(
        'get',
        `creditnotes/create?invoice_id=${this.props.invoiceId
        }&first_time=${true}`
      )
        .then((result) => {
          res = result;
        })
        .catch((error) => {
          let dataFromLS = getObjFromLS('module');
          setObjInLS('module', {
            ...dataFromLS,
            queryParam: `${dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
              }creditnotes=true`,
          });
          checkError(error, this.props.handleToast);
          this.props.history.push('/r');
        });
      if (res && res.contacts.length && res.contacts[0].invoice_list) {
        let invoice_list = res.contacts[0].invoice_list.map((invoice) => ({
          id: invoice.id,
          name: invoice.invoice_no,
        }));
        const salesOrderList = res.contacts[0].invoice_list.map(
          (salesorder) => ({
            id: salesorder.id,
            name: salesorder.invoice_no,
          })
        );
        const makeContactPersonList = res.contacts.map((contact) => ({
          id: contact.id,
          name: contact.display_name,
        }));
        let invoiceListForModal;
        if (this.props.inModal) {
          res.contacts.forEach((contact) => {
            if (
              invoiceListForModal === undefined ||
              invoiceListForModal === null
            ) {
              invoiceListForModal = contact.invoice_list.find((inv) => {
                return inv.id === this.props.invoiceId;
              });
              if (invoiceListForModal !== undefined) {
                invoice_list = contact.invoice_list.map((invoice) => ({
                  id: invoice.id,
                  name: invoice.invoice_no,
                }));
              }
            }
          });
        }
        this.setState({
          salesOrderList: this.props.inModal ? invoice_list : salesOrderList,
          saleorderInfo: {
            ...this.state.saleorderInfo,
            salesOrderId: this.props.inModal
              ? invoiceListForModal && invoiceListForModal.id
              : salesOrderList[0].id,
          },
          contactPerson: makeContactPersonList,
          contactPersonId: res.contacts[0].id,
          currency: res.currency,
          allContactsList: res.contacts,
        });
        if (!this.props.inModal) {
          this.setState({
            invoiceInfo: {
              ...this.state.invoiceInfo,
              invoiceId: invoice_list[0].id,
            },
          });
        }
        return invoice_list[0].id;
      } else {
        return null;
      }
    }
  }

  prepareEditItemsList(
    itemsArray,
    salesOrderItemDetails,
    packageSalesOrder,
    genericWareId
  ) {
    return itemsArray.map((item, index) => ({
      id: item.item_id,
      salesOrderItemDetailId: item.sales_order_id,
      packageItemDetailId: item.id,
      name: item.item_name,
      images: item.items.images,
      sku: item.items.sku,
      unit: item.items.unit,
      inventoryType: item.items.inventory_type,
      warehouse_idd: item.warehouse_id,
      res_warehouse_id: item.warehouse_id,
      ordered: item.return_quantity_added,
      packed: item.packed,
      shipped: item.shipped,
      quantity: item.saleReturn.quantity,
    }));
  }
  prepareEditItemsListforSO(
    itemsArray,
    salesOrderItemDetails,
    preferences,
    itemsCredits,
    invoice
  ) {
    return itemsArray.map((item, index) => ({
      id: item.item_id,
      salesOrderItemDetailId: item.sales_order_item_detail_id,
      dragDropID: GetDateString(),
      name: item.items.name,
      images: item.items.images,
      sku: item.items.sku,
      unit: item.items.unit,
      inventoryType: item.items.inventory_type,
      warehouse_idd: item.warehouse_id,
      res_warehouse_id: item.warehouse_id,
      ordered: item.return_quantity_added,
      packed: item.packed,
      stocks:
        // item.items.stocks.find(
        //   (stock) => stock.warehouse_id === salesOrderItemDetails.warehouse_id
        // ) ||
        {},
      quantity: itemsCredits[index].credits,
      price: item.items.sales_unit_price,
      sold_price: invoice.invoice_details.find((p) => p.item_id === item.item_id && p?.sales_order_item_detail_id === item?.sales_order_item_detail_id).rate || 0,
      actualQuantity: item.quantity,
      quantity_processed: this.type === 'edit' ? itemsCredits[index].quantity_processed : item.quantity,
      adjustment: itemsCredits[index].adjustment,
      criteria: itemsCredits[index].adjustement_criteria,
      invoice_details_id: invoice.invoice_details.find((p) => p.item_id === item.item_id && p?.sales_order_item_detail_id === item?.sales_order_item_detail_id).id,
    }));
  }

  prepareItemsList(itemsArray, salesOrder, preferences, itemsCredits) {
    return itemsCredits.map((item, index) => ({
      id: item.item_id,
      salesOrderItemDetailId: item.sales_order_item_detail_id,
      dragDropID: GetDateString(),
      name: item.items.name,
      images: item.items.images,
      sku: item.items.sku,
      unit: item.items.unit,
      warehouse_idd: salesOrder.invoice_details[index].warehouse_id
        ? salesOrder.invoice_details[index].warehouse_id
        : salesOrder.sales_return_details.warehouse_id,
      res_warehouse_id: salesOrder.invoice_details[index].warehouse_id
        ? salesOrder.invoice_details[index].warehouse_id
        : salesOrder.sales_return_details.warehouse_id,
      ordered: item.return_quantity_added,
      packed: item.packed,
      stocks:
        // item.items.stocks.find(
        //   (stock) => stock.warehouse_id === salesOrder.warehouse_id
        // ) ||
        {},
      quantity:
        itemsCredits[index] !== undefined ? itemsCredits[index].credits : 0,
      price: item.items.sales_unit_price,
      //sold_price: item.item.sales_unit_price,
      sold_price: itemsArray.find((p) => p.item_id === item.item_id && p?.sales_order_item_detail_id === item?.sales_order_item_detail_id).rate || 0,
      actualQuantity: itemsArray.filter((el) => el.item_id === item.item_id)[0]
        .quantity,
      quantity_processed:
        itemsCredits[index] !== undefined
          ? itemsCredits[index].quantity_processed
          : item.quantity,
      adjustment:
        itemsCredits[index] !== undefined ? itemsCredits[index].adjustment : 0,
      criteria:
        itemsCredits[index] !== undefined
          ? itemsCredits[index].adjustement_criteria
          : '%',
      invoice_details_id: item.invoice_item_details_id,
    }));
  }

  fetchSalesReturnEditData() {
    return restRequest('get', `creditnotes/${this.id}/edit`)
      .then((res) => {
        this.setState({
          creditNoteNo: res.invoice.creditNote.credit_note_no,
          currency: res.currency,
          saleorderInfo: {
            salesReturnDate: moment(res.invoice.creditNote.credit_note_date),
          },
          preferences: {
            adjustement_criteria: res.preferences.adjustement_criteria,
            adjustment: res.preferences.adjustment,
          },
        });
        warehouseResult = res.warehouse_list;
        let arr = [];
        for (let i = 0; i < res.invoice.invoice_details.length; i++) {
          arr = this.state.warehouseList;
          arr.push(res.invoice.invoice_details[i].warehouse_id);
          this.setState({ warehouseList: arr });
        }
        if (
          res.invoice.saleOrder.length > 0 &&
          res.invoice.creditNote.sale_order_id !== null
        ) {
          const salesOrdersList = res.invoice.saleOrder.map((salesorder) => ({
            id: salesorder.id,
            name: salesorder.sales_order_no,
          }));
          const returnReceiveList =
            res.invoice.creditNote.sale_order.saleReturn[0].return_receives.map(
              (salesorder) => ({
                id: salesorder.id,
                name: salesorder.return_receives_no,
              })
            );
          const salesReturnsList =
            res.invoice.creditNote.sale_order.saleReturn.map((salesorder) => ({
              id: salesorder.id,
              name: salesorder.sales_return_no,
            }));
          const saleorderInfo = {
            ...this.state.saleorderInfo,
            saleReturnNo: res.invoice.creditNote.credit_note_no,
            invoice: res.invoice,
            orderDate: moment(res.invoice.invoice_date),
            items: this.prepareEditItemsListforSO(
              res.invoice.creditNote.sale_order.saleReturn[0].return_receives[0].returnReceivesDetails,
              res.invoice.creditNote.sale_order.saleReturn[0],
              res.preferences,
              res.invoice.creditNote.credit_note_details,
              res.invoice
            ),
          };
          this.setState({
            contactDisplayName: res.invoice.customer.display_name,
            saleorderInfo: {
              ...saleorderInfo,
              items:
                saleorderInfo.items.length > 0
                  ? [...saleorderInfo.items]
                  : [this.emptyItem],
              salesOrderId: res.invoice.id,
            },
            itemsList: [],
            invoiceNumber: res.invoice.invoice_no,
            saleOrdersList: salesOrdersList,
            returnReceivesList: returnReceiveList,
            salesReturnList: salesReturnsList,
            isSaleOrder: true,
            contactPersonId: res.invoice.customer.id,
            shipmentId: res.invoice.shipping_address_id,
            allResult: res,
          });
        } else {
          //console.log('here 2', this.state.saleorderInfo)
          const saleorderInfo = {
            ...this.state.saleorderInfo,
            saleReturnNo: res.invoice.creditNote.credit_note_no,
            orderDate: moment(res.invoice.invoice_date),
            invoice: res.invoice,
            items: this.prepareItemsList(
              res.invoice.invoice_details,
              res.invoice,
              res.preferences,
              res.invoice.creditNote.credit_note_details
            ),
          };
          this.setState({
            saleorderInfo: {
              ...saleorderInfo,
              items:
                saleorderInfo.items.length > 0
                  ? [...saleorderInfo.items]
                  : [this.emptyItem],
            },
            itemsList: [],
            contactPersonId: res.invoice.customer.id,
            invoiceNumber: res.invoice.invoice_no,
            contactDisplayName: res.invoice.customer.display_name,
            shipmentId: res.invoice.shipping_address_id,
            allResult: res,
          });
        }
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        if (this.props.inModal) {
          this.props.close();
        } else {
          this.setState({
            loading: false,
            notFound: true,
          });
        }
      });
  }

  prepareAddItemsList(arrayList, salesOrder, preferences) {
    return arrayList.map((item, index) => ({
      id: item.item_id,
      salesOrderItemDetailId: item.sales_order_item_detail_id,
      dragDropID: GetDateString(),
      name: item.item.name,
      images: item.item.images,
      sku: item.item.sku,
      unit: item.item.unit,
      warehouse_idd: item.warehouse_id,
      res_warehouse_id: item.warehouse_id,
      ordered: item.return_quantity_added,
      packed: item.packed,
      stocks:
        // item.item.stocks.find(
        //   (stock) => stock.warehouse_id === salesOrder.warehouse_id
        // ) ||
        {},
      quantity: 0,
      price: item.item.sales_unit_price,
      sold_price: salesOrder.invoice_details.find((p) => p.item_id === item.item_id && p?.id === item?.id).rate || 0,
      actualQuantity: item.quantity,
      quantity_processed: item.quantity,
      adjustment: preferences.adjustment,
      criteria: preferences.adjustement_criteria,
      invoice_details_id: item.id
    }));
  }
  prepareAddItemsListForSO(arrayList, invoiceItems, preferences) {
    return arrayList.map((item, index) => ({
      id: item.item_id,
      salesOrderItemDetailId: item.sales_order_item_detail_id,
      dragDropID: GetDateString(),
      name: item.items.name,
      images: item.items.images,
      sku: item.items.sku,
      unit: item.items.unit,
      inventoryType: item.items.inventory_type,
      warehouse_idd:
        item.target_warehouse_id !== null
          ? item.target_warehouse_id
          : item.warehouse_id,
      res_warehouse_id:
        item.target_warehouse_id !== null
          ? item.target_warehouse_id
          : item.warehouse_id,
      ordered: item.return_quantity_added,
      packed: item.packed,
      stocks:
        // item.items.stocks.find(
        //   (stock) => stock.warehouse_id === salesOrder.warehouse_id
        // ) ||
        {},
      quantity: 0,
      price: item.items.sales_unit_price,
      sold_price: invoiceItems.find((p) => p.item_id === item.item_id && p?.sales_order_item_detail_id === item?.sales_order_item_detail_id).rate || 0,
      actualQuantity: item.quantity,
      quantity_processed: item.quantity,
      adjustment: preferences.adjustment,
      criteria: preferences.adjustement_criteria,
      invoice_details_id: invoiceItems.id
    }));
  }

  fetchCreditNoteCreateData = async (invoiveId) => {
    if (invoiveId === null) {
      return;
    }
    return await restRequest(
      'get',
      `creditnotes/create?invoice_id=${invoiveId}&first_time=${false}`
    )
      .then((res) => {
        this.setState({
          contactDisplayName: res.invoice.customer.display_name,
          creditNoteNo: res.credit_notes_no,
          currency: res.currency,
          preferences: {
            adjustement_criteria: res.preferences.adjustement_criteria,
            adjustment: res.preferences.adjustment,
          },
          invoiceNumber: res.invoice.invoice_no,
        });
        warehouseResult = res.warehouse_list;
        // let arr = [];
        // for (let i = 0; i < res.invoice.invoice_details.length; i++) {
        //   arr = this.state.warehouseList;
        //   arr.push(res.invoice.invoice_details[i].warehouse_id);
        //   this.setState({ warehouseList: arr });
        // }

        if (res.invoice.saleOrder.length > 0) {
          const salesOrdersList = res.invoice.saleOrder.map((salesorder) => ({
            id: salesorder.id,
            name: salesorder.sales_order_no,
          }));
          const returnReceiveList =
            res.invoice.saleOrder[0].saleReturn[0].return_receives.map(
              (salesorder) => ({
                id: salesorder.id,
                name: salesorder.return_receives_no,
              })
            );
          const salesReturnsList = res.invoice.saleOrder[0].saleReturn.map(
            (salesorder) => ({
              id: salesorder.id,
              name: salesorder.sales_return_no,
            })
          );
          const saleorderInfo = {
            ...this.state.saleorderInfo,
            saleReturnNo: res.credit_notes_no,
            orderDate: moment(res.invoice.invoice_date),
            salesReturnDate:
              res.invoice.invoice_date &&
                moment(res.invoice.invoice_date) < moment(getOrganizationDate())
                ? moment(getOrganizationDate())
                : moment(res.invoice.invoice_date),
            items: this.prepareAddItemsListForSO(
              res.invoice.saleOrder[0].saleReturn[0].return_receives[0].returnReceivesDetails,
              //res.invoice.saleOrder[0],
              res.invoice.invoice_details,
              res.preferences
            ),
            invoice: res.invoice,
          };
          this.setState({
            saleorderInfo: {
              ...saleorderInfo,
              items:
                saleorderInfo.items.length > 0
                  ? [...saleorderInfo.items]
                  : [this.emptyItem],
            },
            itemsList: [],
            saleOrdersList: salesOrdersList,
            returnReceivesList: returnReceiveList,
            salesReturnList: salesReturnsList,
            isSaleOrder: true,
            contactPersonId: res.invoice.customer.id,
            shipmentId: res.invoice.shipping_address_id,
            allResult: res,
          });
          // Prepare item list for direct sold items
          //  -First check if a direct sold item exist in the invoice item details, id exist then utilize
          let directSoldItemsArray = [];
          let directSoldItemsFlag = false;
          res.invoice.invoice_details.forEach((item, index) => {
            if (item.sales_order_id === null) {
              directSoldItemsFlag = true;
              directSoldItemsArray.push({
                id: item.item_id,
                salesOrderItemDetailId: item.sales_order_item_detail_id,
                name: item.item.name,
                images: item.item.images,
                sku: item.item.sku,
                unit: item.item.unit,
                warehouse_idd: res.invoice.invoice_details[index].warehouse_id
                  ? res.invoice.invoice_details[index].warehouse_id
                  : res.invoice.sales_return_details.warehouse_id,
                res_warehouse_id: res.invoice.invoice_details[index]
                  .warehouse_id
                  ? res.invoice.invoice_details[index].warehouse_id
                  : res.invoice.sales_return_details.warehouse_id,
                ordered: item.return_quantity_added,
                packed: item.packed,
                stocks:
                  // item.item.stocks.find(
                  //   (stock) => stock.warehouse_id === res.invoice.warehouse_id
                  // ) ||
                  {},
                quantity: 0,
                price: item.item.sales_unit_price,
                sold_price: item.rate,
                actualQuantity: item.quantity,
                quantity_processed: item.quantity,
                adjustment: res.preferences.adjustment,
                criteria: res.preferences.adjustement_criteria,
              });
            }
          });
          this.setState({
            directSoldItemsFlag: directSoldItemsFlag,
            directSoldItemsArray: directSoldItemsArray,
          });
        } else {
          const saleorderInfo = {
            ...this.state.saleorderInfo,
            saleReturnNo: res.credit_notes_no,
            salesReturnDate: res.invoice.invoice_date
              ? moment(res.invoice.invoice_date)
              : moment(getOrganizationDate()),
            orderDate: moment(res.invoice.invoice_date),
            items: this.prepareAddItemsList(
              res.invoice.invoice_details,
              res.invoice,
              res.preferences
            ),
            invoice: res.invoice,
          };
          this.setState({
            saleorderInfo: {
              ...saleorderInfo,
              items:
                saleorderInfo.items.length > 0
                  ? [...saleorderInfo.items]
                  : [this.emptyItem],
            },
            itemsList: [],
            contactPersonId: res.invoice.customer.id,
            shipmentId: res.invoice.shipping_address_id,
            allResult: res,
          });
        }
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        if (this.props.inModal) {
          this.props.close();
        }
      });
  };

  submitRequestType = () => {
    switch (this.type) {
      case 'edit':
        return 'put';
      case 'add':
      default:
        return 'post';
    }
  };

  submitRequestUrl = () => {
    switch (this.type) {
      case 'edit':
        return `creditnotes/${this.id}`;
      case 'add':
      default:
        return 'creditnotes';
    }
  };

  successMessage = () => {
    if (this.type === 'add') {
      this.props.handleToast('Credit Notes created successfully', 'success');
    } else if (this.type === 'edit') {
      this.props.handleToast('Credit Notes updated successfully.', 'success');
    }
  };

  setPrompt = (status = true) => this.setState({ showPrompt: status });

  renderCreditNoteForm() {
    const {
      saleorderInfo,
      invoiceInfo,
      creditNoteNo,
      itemsList,
      salesOrderList,
      allContactsList,
      currency,
      returnReason,
      backToStock,
      contactPerson,
      contactPersonId,
      shipmentId,
      saleOrdersList,
      invoiceNumber,
      contactDisplayName,
      returnReceivesList,
      salesReturnList,
      isSaleOrder,
      allResult,
      preferences,
      directSoldItemsArray,
      directSoldItemsFlag,
    } = this.state;
    const {
      title,
      inModal,
      close,
      onSubmit,
      handleToast,
      history,
      handlePrompt = () => null,
    } = this.props;
    let warehouseListProp = this.state.warehouseList;
    let warehouseIdForNullCase = this.state.genericWarehouseId;
    return (
      <>
        <Prompt
          when={this.state.showPrompt}
          message="Are you sure you want to leave page while changes made?"
        />
        <CreditNoteEnhancer
          warehouseListRecieve={warehouseListProp}
          genericWarehouseId={warehouseIdForNullCase}
          title={title}
          contactPerson={contactPerson}
          type={this.type}
          shipmentId={shipmentId}
          allContactsList={allContactsList}
          returnReason={returnReason}
          contactPersonId={contactPersonId}
          alResult={allResult}
          directSoldItemsArray={directSoldItemsArray}
          directSoldItemsFlag={directSoldItemsFlag}
          saleOrdersList={saleOrdersList}
          returnReceivesList={returnReceivesList}
          isSaleOrder={isSaleOrder}
          preferences={preferences}
          salesReturnList={salesReturnList}
          addBackToStock={backToStock}
          warehouseResult={warehouseResult}
          invoiceInfo={invoiceInfo}
          contactDisplayName={contactDisplayName}
          invoiceNumber={invoiceNumber}
          creditNoteNo={creditNoteNo}
          saleorderInfo={saleorderInfo}
          inModal={inModal || null}
          salesOrderList={salesOrderList}
          itemsList={itemsList}
          emptyItem={this.emptyItem}
          currency={currency}
          submitRequestType={this.submitRequestType}
          submitRequestUrl={this.submitRequestUrl}
          successMessage={this.successMessage}
          close={close}
          onSubmit={onSubmit}
          history={history}
          handleToast={handleToast}
          handlePrompt={handlePrompt}
          setPrompt={this.setPrompt}
        />
      </>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    return this.renderCreditNoteForm();
  }
}

export default withRouter(CreditNoteForm);
