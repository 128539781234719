import React from 'react'

import Loader from './../Loader/Loader'
import CustomModal from '../CustomModal/CustomModal'

import './PDFViewer.css'

const PDFViewer = (
  {
    showPDF,
    hidePDFModal,
    pdf
  }) => {
  const title = (
    <div className='pdf-header'>
      <div className='pdf-title'>PDF Viewer</div>
      {
        pdf && (
          <button
            className="pdf-button--new-tab button--primary"
            onClick={() => {
              let pdfWindow = window.open('', '_blank', 'pdf')
              pdfWindow.document.write("<iframe title='pdf' style='border: none' width='100%' height='100%'" +
                " src='data:application/pdf;base64, " + encodeURI(pdf) + "'></iframe>")
            }}
          >
            Open PDF in new window
          </button>
        )
      }
    </div>
  )
  let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  return (
    <CustomModal
      showModal={showPDF}
      title={!isChrome && title}
      renderActions={false}
      onClose={hidePDFModal}
      size="large"
      pdf
      classNames="print-pdf-popup"
    >
      {
        showPDF && !pdf ? (
          <Loader />
        ) : <object data={`data:application/pdf;base64,${pdf}`} type="application/pdf" style={{ width: '100%', height: '75vh' }} aria-label='pdf' />
      }
    </CustomModal>
  )
}

export default PDFViewer