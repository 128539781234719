import React from 'react'

import AddRetrunReceive from '../../../../Assets/General/AddRetrunReceive'

import Email from '../../../../Components/Email/Email'

export default (props) => {
  return (
    <Email
      {...props}
      Icon={AddRetrunReceive}
      name="Return Receive"
      onCancelURL="/salesreturns"
      emailAPI={`returnreceives/${props.match.params.id}/mail`}
      fetchDataAPI={`returnreceives/${props.match.params.id}/mail`}
    />
  )
}