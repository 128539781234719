import React, { Component } from 'react'
import { Formik } from 'formik'

import { restRequest } from '../../Helpers/RequestHelper'

import Form from '../../Components/Form/Form'
import Field from '../../Components/Form/Field/Field'

import AuthContainer from '../../Auth/AuthContainer'

import './PasswordReset.css'
import {checkError} from "../../Helpers/AuthHelper";

class PasswordReset extends Component {
  state = {
    passwordType: 'password',
    confirmPasswordType: 'password'
  }

  handleSubmit(values, setSubmitting) {
    // console.log('values.confirmationCode', values.confirmationCode)
    restRequest('post', 'password/forget/reset', {
      confirmation_code: values.confirmationCode,
      password: values.password,
      password_confirmation: values.passwordConfirm,
      email: values.email.toLowerCase()
    }).then(() => {
      this.props.handleToast('Your password has been reset.', "success")
      this.props.history.push('/login')
    }).catch(error => {
      checkError(error,this.props.handleToast)
      //this.props.handleToast(error, "error")
      values.confirmationCode=''
      setSubmitting(false)
    })
  }

  togglePasswordView = () => {
    this.setState({ passwordType: this.state.passwordType === 'text' ? 'password': 'text' })
  }

  toggleConfirmPasswordView = () => {
    this.setState({ confirmPasswordType: this.state.confirmPasswordType === 'text' ? 'password': 'text' })
  }

  renderErrorList(touched, errors, values) {
    return(
      <ul className={`passsword--errors${touched.password ? ' touched' : ''}`}>
        {
          values.password === '' && touched.password &&
            <li className={`${errors.password && errors.password.isEmpty ? 'error' : ''}`}>Required</li>
        }
        <li className={`${errors.password && errors.password.min ? 'error' : ''}`}>At least 8 characters</li>
        <li className={`${errors.password && errors.password.symbol ? 'error' : ''}`}>Contain atleast 1 symbol</li>
        <li className={`${errors.password && errors.password.number ? 'error' : ''}`}>Contain atleast 1 number</li>
        <li className={`${errors.password && errors.password.uppercaseLowercase ? 'error' : ''}`}>Combine uppercase and lower case letters</li>
      </ul>
    )
  }

  validators = values => {
    const errors = {}
    if(!values.email) {
      errors.email = 'Required'
    }
    else if(values.email.length > 100) {
      errors.email = 'Should be no more than 100 characters.'
    }

    if(!values.confirmationCode) {
      errors.confirmationCode = 'Required'
    }
    else if(values.confirmationCode.length > 6) {
      errors.confirmationCode = 'Invalid Confirmation Code'
    }
    else if (!/^[0-9]+$/.test(values.confirmationCode)) {
      errors.confirmationCode = 'Invalid Confirmation Code'
    }

    if (values.password === '') {
      errors.password = {
        ...errors.password,
        isEmpty: true
      }
    }

    if (values.password.length < 8) {
      errors.password = {
        ...errors.password,
        min: true
      }
    }

    if (!/[0-9]+/.test(values.password)) {
      errors.password = {
        ...errors.password,
        number: true
      }
    }

    if (!/[-!$%^&*#@()_+|~=`{}[\]:";'<>?,./]+/.test(values.password)) {
      errors.password = {
        ...errors.password,
        symbol: true
      }
    }

    if (!(/.*[a-z]/.test(values.password) && /.*[A-Z]/.test(values.password))) {
      errors.password = {
        ...errors.password,
        uppercaseLowercase: true
      }
    }

    if (values.password !== values.passwordConfirm) {
      errors.passwordConfirm = 'Password did not match'
    }

    if (!values.passwordConfirm) {
      errors.passwordConfirm = 'Required'
    }

    return errors
  }

  passwordErrors(passwordErrors, touched) {
    return (touched && passwordErrors) && ['isEmpty', 'min', 'number', 'symbol', 'uppercaseLowercase'].some(property =>
      passwordErrors.hasOwnProperty(property))
  }

  renderPasswordResetForm() {
    return(
      <Formik
        initialValues={{ 
          password: '', 
          passwordConfirm: '', 
          confirmationCode: '',
          email: (this.props.location.state && this.props.location.state.email) ? this.props.location.state.email: '' 
        }}
        initialStatus={{
          hasEmail: !!(this.props.location.state && this.props.location.state.email)
        }}
        validate={this.validators}
        onSubmit={(values, { setSubmitting }) => {
          this.handleSubmit(values, setSubmitting)
        }}
        render={({
                   values,
                   status,
                   errors,
                   isSubmitting,
                   touched,
                   handleBlur,
                   handleSubmit,
                   handleChange
                 }) => {
          return(
            <AuthContainer
              title="Reset Password"
              subtitle="Enter your verification code"
            >
              <Form onSubmit={handleSubmit} className="auth--form">
                {
                  !status.hasEmail &&
                  <Field
                    size="full"
                    value={values.email}
                    id="email"
                    name="Email"
                    required
                    onBlur={handleBlur}
                    handleChange={handleChange}
                    error={touched.email && errors.email}
                  />
                }
                <Field
                  size="full"
                  value={values.confirmationCode}
                  id="confirmationCode"
                  name="Confirmation Code"
                  required
                  onBlur={handleBlur}
                  handleChange={handleChange}
                  error={touched.confirmationCode && errors.confirmationCode}
                />
                <Field
                  size="full"
                  required
                  value={values.password}
                  id="password"
                  name="New Password"
                  type={this.state.passwordType}
                  passwordToggleView={this.togglePasswordView}
                  onBlur={handleBlur}
                  handleChange={handleChange}
                  error={touched.password && this.passwordErrors(errors.password, touched.password)}
                />
                { this.renderErrorList(touched, errors, values) }

                <Field
                  size="full"
                  required
                  value={values.passwordConfirm}
                  id="passwordConfirm"
                  name="Confirm New Password"
                  type={this.state.confirmPasswordType}
                  passwordToggleView={this.toggleConfirmPasswordView}
                  onBlur={handleBlur}
                  handleChange={handleChange}
                  error={touched.passwordConfirm && errors.passwordConfirm}
                  className='confirm-password-margin'
                />
                <button
                  className="button--full-width"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Change Password
                </button>
              </Form>
            </AuthContainer>
          )
        }}
      />
    )
  }

  render() {
    return this.renderPasswordResetForm()
  }

}

export default PasswordReset
