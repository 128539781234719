export default function getCurrentMenu(val, items) {
  const value = val.trim();
  var invalidChars = '!,\\,|,;,@,#,$,%,^,&,*,(,),<,>,/,~,`';
  var found = 'false';
  if (value !== undefined && value !== null) {
    var invCharsArr = invalidChars.split(',');
    var fieldsArr = value.split('');
    for (var i = 0; i < fieldsArr.length; i++) {
      if (invCharsArr.indexOf(fieldsArr[i]) > -1) {
        found = 'true';
        break;
      }
    }
  }

  let searchedItems;
  if (found === 'true') {
    return null;
  } else if (value !== '') {
    searchedItems = items.filter(
      (item) =>
        (item.name &&
          item.name.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
        (item.sku &&
          item.sku.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
        (item.unit &&
          item.unit.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
        (item.ean &&
          item.ean.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
        (item.isbn &&
          item.isbn.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
        (item.mpn &&
          item.mpn.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
        (item.upc && item.upc.toLowerCase().indexOf(value.toLowerCase()) > -1)
    );
  } else {
    return null;
  }
  if (searchedItems && searchedItems.length === 1) {
    var searchedItems1 = searchedItems.filter(
      (item) =>
        (item.name && item.name.toLowerCase() === value.toLowerCase()) ||
        (item.sku && item.sku.toLowerCase() === value.toLowerCase()) ||
        (item.unit && item.unit.toLowerCase() === value.toLowerCase()) ||
        (item.ean && item.ean.toLowerCase() === value.toLowerCase()) ||
        (item.isbn && item.isbn.toLowerCase() === value.toLowerCase()) ||
        (item.mpn && item.mpn.toLowerCase() === value.toLowerCase()) ||
        (item.upc && item.upc.toLowerCase() === value.toLowerCase())
    );
    return searchedItems1 && searchedItems1.length === 1
      ? searchedItems1
      : null;
    //return searchedItems
  } else {
    return null;
  }
}
