import React from 'react';
import { Link } from 'react-router-dom';
import EmptyIcon from '../../Assets/Mixed/EmptyIcon';

import './EmptyTable.css';

export default (props) => {
  /*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/
  String.prototype.beginsWith = function (string) {
    return this.indexOf(string) === 0;
  };

  let filter = 'no data';
  let start_message = false;
  if (props.inModal) {
    //to display no data text in COntact modal empty Transaction table
    filter = 'no data';
  } else if (window.location.pathname.includes('contacts')) {
    if (window.location.search.includes('?filter=vendor')) {
      filter = 'no vendor contact';
    } else if (window.location.search.includes('?filter=customer')) {
      filter = 'no customer contact';
    } else if (window.location.search.includes('?filter=active')) {
      filter = 'no active contact';
    } else if (window.location.search.includes('?filter=inactive')) {
      filter = 'no inactive contact';
    }
  } else if (window.location.search.includes('&start=')) {
    filter = "You don't have any data for the selected filter.";
    start_message = true;
  } else if (window.location.pathname.beginsWith('/items')) {
    filter = 'no matched item';
  } else if (window.location.pathname.beginsWith('/salesorders')) {
    filter = 'no matched sales order';
  } else if (window.location.pathname.beginsWith('/packages')) {
    filter = 'no matched package';
  } else if (window.location.pathname.beginsWith('/invoices')) {
    filter = 'no matched invoice';
  } else if (window.location.pathname.beginsWith('/salesreturns')) {
    filter = 'no matched sales return';
  } else if (window.location.pathname.beginsWith('/creditnotes')) {
    filter = 'no matched credit notes';
    // filter = 'no data';
  } else if (window.location.pathname.beginsWith('/deliverymethods')) {
    filter = 'no delivery method';
  } else if (window.location.pathname.beginsWith('/taxes')) {
    filter = 'no tax';
  } else if (window.location.pathname.beginsWith('/warehouses')) {
    filter = 'no warehouses';
  } else if (window.location.pathname.beginsWith('/currencies')) {
    filter = 'no currency';
  }
  return (
    <div className="empty-table">
      <EmptyIcon />
      {!start_message && <h5>There is {filter} available.</h5>}
      {start_message && <h5>{filter}</h5>}
      {start_message && (
        <Link to="/dashboard">
          <h5 className="text_underline pointer">Go To Dashboard</h5>
        </Link>
      )}
    </div>
  );
};
