import React, { Fragment } from 'react';

import { imagePathS3 } from '../../../Helpers/RequestHelper';
// import getDate from '../../../Helpers/Date/GetDate'
// import getDateFormat from '../../../Helpers/Date/GetDateFormat'

import './OrganizationInfo.css';

export default ({ info, logo, uploadedLogo }) => {
  const {
    name,
    get_type: { label: type },
    time_zone: timeZone,
    primary_contact_name: contactName,
    primary_contact_email: contactEmail,
  } = info;
  function prepareImage() {
    if (uploadedLogo) {
      return uploadedLogo;
    }
    return imagePathS3(logo);
  }
  return (
    <div className="organization-info">
      <div className="organization_info_image_details">
        <div className="organization-info--image-container float-left">
          {logo || uploadedLogo ? (
            <Fragment>
              <div className="organization_logo_settings">
                <img
                  src={prepareImage()}
                  className="organization-logo"
                  alt="organization-logo"
                />
              </div>
            </Fragment>
          ) : null}
        </div>
        <div className="float-left top-10 ml_20">
          <div className="bold organization_info_name">{name}</div>
          <div>{type}</div>
        </div>
      </div>
      {/* <hr className="section-details--separator" /> */}
      <div className="section-details--info">
        <div className="float-left w-100 mb_10">
          <div className="float-left w-30">Time Zone </div>
          <div className="float-left w-10 text-center"> : </div>
          <div className="float-left w-60 font_700">{timeZone}</div>
        </div>
        <div className="float-left w-100 mb_10">
          <div className="float-left w-30">Name </div>
          <div className="float-left w-10 text-center"> : </div>
          <div className="float-left w-60 font_700">{contactName}</div>
        </div>
        <div className="float-left w-100 mb_10">
          <div className="float-left w-30">Email </div>
          <div className="float-left w-10 text-center"> : </div>
          <div
            className="float-left w-60 font_700"
            style={{ wordBreak: 'break-word' }}
          >
            {contactEmail}
          </div>
        </div>
      </div>
    </div>
  );
};
