import React from 'react';
import { OptionType, LinkType, ButtonType, DropDownType } from '../index';

const MenuType = ({ menu }) => {
  const { type = '', enable = true } = menu;

  switch (type) {
    case 'options':
      return <OptionType menu={menu} />;
    case 'link':
      if (enable) return <LinkType menu={menu} />;
      return null;

    case 'button':
      if (enable) return <ButtonType menu={menu} />;
      return null;

    case 'dropdown':
      if (enable) return <DropDownType menu={menu} />;
      return null;
    default:
      return null;
  }
};

export default MenuType;
