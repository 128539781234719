import React, { Component, Fragment } from 'react';

import { restRequest } from '../../../../../../Helpers/RequestHelper';
import getDate from '../../../../../../Helpers/Date/GetDate';
import { CreditNoteIcon } from '../../../../../../Assets/Navigation/NavigationIcons'; //PaymentIcon,
import { formatNumber } from '../../../../../../Helpers/FormatNumber';

import Loader from '../../../../../../Components/Loader/Loader';
import Table from '../../../../../../Components/Table/Table';
import RecordPaymentForm from '../../../../../../Components/Form/RecordPaymentForm/RecordPaymentForm';
import UtilizationFromInvoice from '../../../../../../Components/Form/CreditNoteUtilizationForm/UtilizationFromInvoice';
import { NoItemsBox } from '../../../../../../Components/Layout/Box';
import CustomModal from '../../../../../../Components/CustomModal/CustomModal';
import ListTableColumnJoin from '../../../../../../Components/ListTable/ListTableColumnJoin';
import { withRouter } from 'react-router-dom';
import RecordPaymentIcon from '../../../../../../Assets/General/RecordPaymentIcon';
import DeleteIcon from '../../../../../../Assets/General/DeleteIcon';
import InvoicePaymentDetails from './InvoicePaymentDetails';
import {
  Button,
  ButtonLink,
  ActionMenu,
} from '../../../../../../Components/CheckedMenu/CheckedMenu';
import PDFViewer from '../../../../../../Components/PDFViewer/PDFViewer';
import { MdAdd } from '../../../../../../Common/Icons';
import {
  getObjFromLS,
  setObjInLS,
} from '../../../../../../Helpers/LocalStorage';
import { pdfDownlaod } from '../../../../../../Helpers/PdfDownload';
import CreditNoteDetails from '../../../../../CreditNote/CreditNoteDetails';
import { checkError } from '../../../../../../Helpers/AuthHelper';
class Payments extends Component {
  state = {
    loading: true,
    payments: [],
    creditPayments: [],
    selectedCreditNote: [],
    showPaymentViaCreditsButton: false,
    showRecordPaymentModal: false,
    showCreditNotePaymentModal: false,
    showApplyCreditNoteModal: false,
    showViewRecordPaymentModal: false,
    showDeletePaymentModal: false,
    paymentID: null,
    paymentNo: null,
    pdf: null,
    deleteFlag: '',
    showPDF: false,
    showPrompt: false,
  };

  tableRowOptions = [
    {
      label: 'Delete',
      icon: DeleteIcon,
      onClick: (id) => this.openDeletePaymentModal(id, 'payments'),
      disabled: () =>
        !this.props.hasPermissionCustom('invoicepayment', 'Delete'),
    },
  ];

  tableRowOptionsTwo = [
    {
      label: 'Delete',
      icon: DeleteIcon,
      onClick: (id) => this.openDeletePaymentModal(id, 'credits'),
      disabled: () =>
        !this.props.hasPermissionCustom('invoicepayment', 'Delete'),
    },
  ];
  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    restRequest('get', `invoices/${this.props.invoiceId}/payments`)
      .then((res) => {
        this.setState({
          payments: res.invoicePayments,
          creditPayments: res.creditPayments,
          showPaymentViaCreditsButton: res.utilizeableCredits,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error);
        this.setState({
          loading: false,
        });
      });
  };

  openNewRecordPaymentModal = () => {
    this.setState({
      showRecordPaymentModal: true,
      showPrompt: false,
    });
  };

  openApplyCreditsPaymentModal = () => {
    this.setState({
      showCreditNotePaymentModal: true,
    });
  };

  viewApplyCreditNotePaymentModal = (selected) => {
    this.setState({
      showApplyCreditNoteModal: true,
      selectedCreditNote: selected,
    });
  };

  openViewRecordPaymentModal = (paymentSlip) => {
    this.setState({
      showViewRecordPaymentModal: true,
      paymentID: paymentSlip.id,
      paymentNo:
        paymentSlip?.payment_no || paymentSlip?.advance_payment?.payment_no,
    });
  };

  openDeletePaymentModal = (id, flag) => {
    this.setState({
      showDeletePaymentModal: true,
      clickedId: id,
      deleteFlag: flag,
    });
  };

  closePDFModal = () => {
    this.setState({
      pdf: null,
      showPDF: false,
    });
  };

  closeAllModals = () => {
    this.setState({
      showRecordPaymentModal: false,
      showCreditNotePaymentModal: false,
      showDeletePaymentModal: false,
      showViewRecordPaymentModal: false,
      showApplyCreditNoteModal: false,
      pdf: null,
      showPDF: false,
    });
    this.props.closeAllModals && this.props.closeAllModals();
  };
  renderPaymentActionMenu(id) {
    return (
      <ActionMenu>
        {this.props.hasPermissionCustom('invoicepayment', 'Delete') && (
          <Button
            handleClick={() => this.openDeletePaymentModal(id, 'payments')}
            type="delete"
          />
        )}
        <Button
          // handleClick={() => this.OpenPaymentPdf(id)}
          handleClick={() =>
            pdfDownlaod(
              `invoicepaymentrecords/${id}/pdf?download=true`,
              `Payment-${this.state.paymentNo}`,
              this.props.handleToast
            )
          }
          type="pdf"
        />
        <Button handleClick={() => this.OpenPaymentPdf(id)} type="print" />
        <ButtonLink to={`invoice_payments/email/${id}`} type="email" />
      </ActionMenu>
    );
  }

  OpenPaymentPdf = (id) => {
    this.setState({ showPDF: true });
    restRequest('get', `invoicepaymentrecords/${id}/pdf`)
      .then((res) => {
        this.setState({ pdf: res });
      })
      .catch((error) => checkError(error));
  };
  deletePaymentModal() {
    return (
      <CustomModal
        type="delete"
        showModal={this.state.showDeletePaymentModal}
        title={
          this.state.deleteFlag === 'payments'
            ? 'Confirm Payment Delete'
            : 'Confirm Credit Note Delete'
        }
        onClose={this.closeAllModals}
        onSubmit={this.deletePayment}
      >
        {this.state.deleteFlag === 'payments'
          ? 'Are you sure you want to delete this payment?'
          : 'Are you sure you want to delete this Credit Note?'}
      </CustomModal>
    );
  }
  deletePayment = () => {
    this.closeAllModals();
    restRequest(
      'delete',
      `${
        this.state.deleteFlag === 'payments'
          ? 'invoicepaymentrecords'
          : 'creditutilize'
      }/${this.state.clickedId}`
    )
      .then((res) => {
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS === null) {
          let obj = {
            prevId: null,
            id: null,
            nextId: null,
            moduleName: '',
            urlPath: window.location.pathname,
            queryParam: window.location.search,
          };
          setObjInLS('module', obj);
          dataFromLS = getObjFromLS('module');
        }
        setObjInLS('module', {
          ...dataFromLS,
          queryParam: `${
            dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
          }payments=true`,
        });
        this.fetchData();
        this.props.updateInvoice();
        this.state.deleteFlag === 'payments'
          ? this.props.handleToast('Payment deleted successfully', 'success')
          : this.props.handleToast(
              'Credit Note deleted successfully',
              'success'
            );
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, 'error')
      });
  };

  handleRecordPaymentSubmit = () => {
    let dataFromLS = getObjFromLS('module');
    setObjInLS('module', {
      ...dataFromLS,
      queryParam: `${
        dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
      }payments=true`,
    });
    this.closeAllModals();
    this.props.updateInvoice();
    this.fetchData();
  };

  handlePrompt = (status = true) => {
    this.setState({
      showPrompt: status,
    });
  };

  newRecordPaymentModal = () => {
    const { invoiceId, handleToast } = this.props;
    return (
      <CustomModal
        Icon={RecordPaymentIcon}
        showModal={this.state.showRecordPaymentModal}
        title="New Receive Payment"
        renderActions={false}
        size="large"
        onClose={this.closeAllModals}
        classNames="new-recieve-payment-popup"
        showPrompt={this.state.showPrompt}
        // extraClass="invoices-large"    //commenting this to remove extra space from modal. Ticket related
      >
        <RecordPaymentForm
          inModal
          type="invoice"
          id={invoiceId}
          handleToast={handleToast}
          onSubmit={this.handleRecordPaymentSubmit}
          onClose={this.closeAllModals}
          handlePrompt={this.handlePrompt}
          showPrompt={this.state.showPrompt}
        />
      </CustomModal>
    );
  };

  viewCreditNotePaymentModal() {
    const {
      //  invoiceId,
      handleToast,
    } = this.props;
    return (
      <CustomModal
        Icon={CreditNoteIcon}
        showModal={this.state.showApplyCreditNoteModal}
        title={`View Apply Credits | ${
          this.state.selectedCreditNote &&
          this.state.selectedCreditNote.credit_note &&
          this.state.selectedCreditNote.credit_note.credit_note_no
        }`}
        renderActions={false}
        size="large"
        onClose={this.closeAllModals} //commenting this to remove extra space from modal. Ticket related
      >
        <CreditNoteDetails
          inModal
          type="invoice"
          id={this.state.selectedCreditNote.credit_note_id}
          handleToast={handleToast}
          onClose={this.closeAllModals}
        />
      </CustomModal>
    );
  }

  newCreditNotePaymentModal() {
    const { invoiceId, handleToast } = this.props;
    return (
      <CustomModal
        Icon={CreditNoteIcon}
        showModal={
          this.state.showCreditNotePaymentModal ||
          this.props.openCreditNoteModal ||
          false
        }
        className="AppplyCredits"
        title={`Apply Credits | ${this.props.invoiceNo}`}
        renderActions={false}
        size="large"
        onClose={this.closeAllModals}
        extraClass="apply_credit_notes"
        extraTitle={`Invoice Balance : ${this.props.currency.symbol}${this.props.InvoiceDue}`}
        showPrompt={this.state.showPrompt}
      >
        <UtilizationFromInvoice
          inModal
          type="invoice"
          id={invoiceId}
          handleToast={handleToast}
          onSubmit={this.handleRecordPaymentSubmit}
          onClose={this.closeAllModals}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  viewRecordPaymentModal() {
    return (
      <CustomModal
        className="invoice-payment-popup"
        Icon={RecordPaymentIcon}
        showModal={this.state.showViewRecordPaymentModal}
        title={'Payment | Payment #' + this.state.paymentNo}
        renderActions={false}
        size="small"
        renderPaymentActionMenu={this.renderPaymentActionMenu(
          this.state.paymentID
        )}
        onClose={this.closeAllModals}
        showActionInMenu={true}
      >
        <InvoicePaymentDetails
          id={this.state.paymentID}
          inModal={true}
          className="td-hover-unset"
        />
      </CustomModal>
    );
  }

  renderModals() {
    return (
      <Fragment>
        {this.newRecordPaymentModal()}
        {this.newCreditNotePaymentModal()}
        {this.viewRecordPaymentModal()}
        {this.deletePaymentModal()}
        {this.viewCreditNotePaymentModal()}
      </Fragment>
    );
  }

  preparePaymentsList(payments) {
    return payments.map((paymentSlip) => {
      return {
        ...paymentSlip,
        no: this.props.inModal ? (
          `${`Payment # ${
            paymentSlip?.payment_no || paymentSlip?.advance_payment?.payment_no
          }`}`
        ) : (
          <ListTableColumnJoin
            column1={
              <div
                className="payment_view"
                onClick={() => this.openViewRecordPaymentModal(paymentSlip)}
                to={`/invoices/${this.props.invoiceId}/payments/${paymentSlip.id}`}
              >{`Payment # ${
                paymentSlip?.payment_no ||
                paymentSlip?.advance_payment?.payment_no
              }`}</div>
            }
            column2={`${paymentSlip.payment_mode} - ${
              paymentSlip.currency.symbol
            }${formatNumber(paymentSlip.payment_made)}`}
            /*column3={`${paymentSlip.note}`}*/
          />
        ),
        payment_mode: paymentSlip.payment_mode,
        //payment_made: paymentSlip.currency.symbol + paymentSlip.payment_made,
        payment_made:
          paymentSlip.currency.symbol +
          parseFloat(paymentSlip.payment_made).toFixed(2),
        paymentDate: getDate(paymentSlip.payment_date),
        payment_currency: paymentSlip.currency,
      };
    });
  }

  preparePaymentsListForCredit(creditPayments) {
    return creditPayments.map((paymentSlip) => {
      return {
        ...paymentSlip,
        no: this.props.inModal ? (
          `${`${paymentSlip.credit_note.credit_note_no}`}`
        ) : (
          <ListTableColumnJoin
            column1={
              <div
                className="payment_view"
                onClick={() =>
                  this.viewApplyCreditNotePaymentModal(paymentSlip)
                }
                to={`/creditnotes/${paymentSlip.credit_note_id}`}
              >{`${paymentSlip.credit_note.credit_note_no}`}</div>
            }
            column2={`${this.props.currency.symbol}${formatNumber(
              paymentSlip.payment_made
            )}`}
          />
        ),
        //payment_made: this.props.currency.symbol + paymentSlip.used_credits,
        payment_made:
          this.props.currency.symbol +
          parseFloat(paymentSlip.used_credits).toFixed(2),
        paymentDate: getDate(paymentSlip.credit_utilize_date),
        payment_currency: paymentSlip.currency,
      };
    });
  }

  renderPaymentsListTable() {
    const tableListData = this.preparePaymentsList(this.state.payments);
    const tableHeaders = [
      'Payment Date',
      'Payment No.',
      'Payment Mode',
      'Amount',
    ];
    const tableData = [
      'paymentDate',
      'no',
      'payment_mode',
      'payment_made',
      !this.props.inModal && 'options',
    ];
    const tableListDataforCredits = this.preparePaymentsListForCredit(
      this.state.creditPayments
    );
    const tableHeadersforCredits = [
      'Date',
      'Credit Note No.',
      'Credits Applied',
    ];
    const tableDataforCredits = [
      'paymentDate',
      'no',
      'payment_made',
      !this.props.inModal && 'options',
    ];

    return (
      <Fragment>
        <div className="paymentParent">
          {this.state.payments.length > 0 && (
            <div className="float-left w-100 payment_tab">
              <div className="float-left w-100 paymentinv_table_head_title">
                <h4>Payments Received</h4>
              </div>
              <Table
                list={tableListData}
                tableHeaders={tableHeaders}
                tableData={tableData}
                fromTab={true}
                options={this.props.inModal ? [] : this.tableRowOptions}
                className={`box-table ${
                  this.props.label ? this.props.label + '_menu' : ''
                }`}
                // RowIcon={PaymentIcon}
              />
            </div>
          )}
          {this.state.creditPayments.length > 0 && (
            <div className="float-left w-100 payment_tab credits_applied">
              <div className="float-left w-100 paymentinv_table_head_title">
                <h4>Credits Applied</h4>
              </div>
              <Table
                list={tableListDataforCredits}
                tableHeaders={tableHeadersforCredits}
                fromTab={true}
                tableData={tableDataforCredits}
                options={this.props.inModal ? [] : this.tableRowOptionsTwo}
                className={`box-table ${
                  this.props.label ? this.props.label + '_menu' : ''
                }`}
                // RowIcon={PaymentIcon}
              />
            </div>
          )}
          <div className="buttons_parent">
            {this.props.hasPermissionCustom('invoicepayment', 'Create') &&
              this.props.invoiceStatus !== 'paid' &&
              (this.props.inModal ? (
                ''
              ) : (
                <button
                  className="d-flex align-center"
                  onClick={this.openNewRecordPaymentModal}
                >
                  <MdAdd className=" black-fill" />
                  Receive Payment
                </button>
              ))}
            {this.props.hasPermissionCustom('invoicepayment', 'Create') &&
              this.props.invoiceStatus !== 'paid' &&
              this.state.showPaymentViaCreditsButton &&
              (this.props.inModal ? (
                ''
              ) : (
                <button
                  className="d-flex align-center"
                  onClick={this.openApplyCreditsPaymentModal}
                >
                  <MdAdd className=" black-fill" />
                  Pay Via Credits
                </button>
              ))}
          </div>
        </div>
      </Fragment>
    );
  }

  renderPaymentsList() {
    const { openCreditNoteModal = undefined } = this.props;
    return (
      <Fragment>
        <PDFViewer
          showPDF={this.state.showPDF}
          hidePDFModal={this.closeAllModals}
          pdf={this.state.pdf}
        />
        {this.renderModals()}
        {openCreditNoteModal === undefined &&
          (!this.state.payments.length && !this.state.creditPayments.length ? (
            this.props.InvoiceTotal === 0 &&
            this.props.InvoiceDue === 0 &&
            this.props.invoiceStatus !== 'void' ? (
              <NoItemsBox
                subtitle="Not Eligible For Receiving Payments"
                buttonText="Receive Payment"
                buttonTextSecondary="Pay Via Credits"
                showSecondaryButton={
                  this.state.showPaymentViaCreditsButton &&
                  this.props.InvoiceTotal !== 0 &&
                  this.props.invoiceStatus !== 'paid'
                }
                showButton={
                  this.props.hasPermissionCustom('invoicepayment', 'Create') &&
                  this.props.invoiceStatus !== 'paid' &&
                  this.props.InvoiceTotal !== 0
                }
                handleclick={this.openNewRecordPaymentModal}
                handleclickSecondary={this.openApplyCreditsPaymentModal}
                Icon={RecordPaymentIcon}
                inModal={this.props.inModal}
              />
            ) : this.props.InvoiceTotal > 0 &&
              this.props.invoiceStatus !== 'void' ? (
              <NoItemsBox
                subtitle="No Payments received so far"
                buttonText="Receive Payment"
                id="click_SO_receivePayments"
                buttonTextSecondary="Pay Via Credits"
                showSecondaryButton={
                  this.state.showPaymentViaCreditsButton &&
                  this.props.InvoiceTotal !== 0 &&
                  this.props.invoiceStatus !== 'paid'
                }
                showButton={
                  this.props.hasPermissionCustom('invoicepayment', 'Create') &&
                  this.props.invoiceStatus !== 'paid' &&
                  this.props.InvoiceTotal !== 0
                }
                handleclick={this.openNewRecordPaymentModal}
                handleclickSecondary={this.openApplyCreditsPaymentModal}
                Icon={RecordPaymentIcon}
                inModal={this.props.inModal}
              />
            ) : (
              <NoItemsBox
                subtitle="Void invoices cannot receive payment"
                showButton={false}
                // Icon={PaymentIcon}
                Icon={RecordPaymentIcon}
              />
            )
          ) : (
            this.renderPaymentsListTable()
          ))}
      </Fragment>
    );
  }

  render() {
    const { openCreditNoteModal } = this.props;
    if (this.state.loading && openCreditNoteModal === undefined)
      return <Loader />;
    return <>{this.renderPaymentsList()}</>;
  }
}
export default withRouter(Payments);
