import React from 'react';
import _ from 'lodash';
import SmileEmoji from './../../Assets/SVG/emoji-smile';
import SadEmoji from './../../Assets/SVG/emoji-sad';

import './Toaster.css';

class Toaster extends React.Component {

  constructor( props ) {
    super( props )
    this.state = {
      messages: props.message,
      type: props.type,
    }
  }

  componentWillReceiveProps( nextProps ) {
    this.setState( {
      messages : nextProps.message,
      type: nextProps.type,
    } )
  }

  renderEmoji(emojiType) {
    if (emojiType === "error") {
      return <SadEmoji className="toaster-emoji toaster-icon-error" />
    }
    else if (emojiType === "success") {
      return <SmileEmoji className="toaster-emoji toaster-icon-success" />
    }
  }

  renderToastBody() {
    let messages  = this.state.messages
    if ( typeof messages === 'string' ) {
      if ( messages.length <= 1000 ) {
        let temp_msg = []
        temp_msg.push( messages )
        messages = temp_msg
      } else {
        messages = [ 'Unexpected errors!', 'Please refresh the page and try again.' ]
      }
    }
    return (
      <div className={`toaster-body toast-fall toast-${this.state.type}`}>
        <div className="toaster-icon">
          {this.renderEmoji(this.state.type)}
        </div>
        <div className="toaster-message">
          <div className="error-type">{this.state.type}!</div>
          {
            _.map(messages, ( message, index ) => {
              return (
                <div key={ index }>
                  { message }
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  render() {
    if( this.state.messages !== false && this.state.messages !== '' ) {
      return (
        <div className='toaster-container'>
          { this.renderToastBody() }
        </div>
      )
    }
    return null
  }
}

export default Toaster