import React from 'react';
import {
  isMobile,
  isMobileOnly,
  isBrowser,
  isAndroid,
  isWinPhone,
  isIOS,
  isChrome,
  isFirefox,
  isSafari,
  isOpera,
  isIE,
  isMobileSafari,
  browserName,
  mobileVendor,
  mobileModel,
  deviceType,
  isWindows,
  isMacOs,
} from 'mobile-device-detect';

import useMobileDetection from '../../Hooks/MobileDetect';

const ImsDevice = ({ devices = [] }) => {
  return (
    <>
      {devices.length &&
        devices.map((device, index) => (
          <div key={index}>
            <b>
              {device.label}
              {'=>'}
            </b>
            <i>
              {device.value !== undefined &&
                device.value !== null &&
                device.value.toString()}
            </i>
          </div>
        ))}
    </>
  );
};

const ImsDeviceDetection = () => {
  const [hasMobile] = useMobileDetection();
  const device = [
    { label: 'Mobile', value: isMobile },
    { label: 'isMobileOnly', value: isMobileOnly },
    { label: 'isBrowser', value: isBrowser },
    { label: 'isAndroid', value: isAndroid },
    { label: 'isWinPhone', value: isWinPhone },
    { label: 'isIOS', value: isIOS },
    { label: 'isChrome', value: isChrome },
    { label: 'isFirefox', value: isFirefox },
    { label: 'isSafari', value: isSafari },
    { label: 'isOpera', value: isOpera },
    { label: 'Internet Explorer', value: isIE },
    { label: 'Mobile Safari', value: isMobileSafari },
    { label: 'browser name', value: browserName },
    { label: 'mobile device vendor (e.g LG, iPhone etc)', value: mobileVendor },
    { label: ' mobile device model (e.g Nexus 5)', value: mobileModel },
    { label: 'device type (e.g mobile or tablet)', value: deviceType },
    { label: 'is Windows', value: isWindows },
    { label: ' is Mac OS', value: isMacOs },
  ];

  return (
    <>
      <h1>Devices Information</h1>
      {<ImsDevice devices={device} />}
      <div
        style={{
          border: '1px solid gray',
          display: 'inline-block',
          padding: '10px',
        }}
      >
        <h4>Your have opened Ims on {hasMobile ? 'Mobile' : 'Desktop'}</h4>
      </div>
    </>
  );
};

export default ImsDeviceDetection;
