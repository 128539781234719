import React from 'react';

import { SalesReturnIcon } from '../../Assets/Navigation/NavigationIcons';

import Email from '../../Components/Email/Email';

export default (props) => {
  return (
    <Email
      {...props}
      Icon={SalesReturnIcon}
      name="Sales Return"
      onCancelURL="/salesreturns"
      emailAPI={`salesreturns/${props.match.params.id}/mail`}
      fetchDataAPI={`salesreturns/${props.match.params.id}/mail`}
      getBackTab
    />
  );
};
