import React, { Component } from 'react';

import { restRequest } from '../../Helpers/RequestHelper';
import Loader from '../../Components/Loader/Loader';
// import NotFound from '../../Components/NotFound/NotFound'
import TimelineCard from '../../Components/TimelineCard/TimelineCard';
import EmptyTable from '../../Components/ListTable/EmptyTable';
import Packages from '../../Pages/SalesOrder/SalesOrderDetails/Tabs/PackagesTab';
import Invoices from '../../Pages/SalesOrder/SalesOrderDetails/Tabs/InvoicesTab';
import Payments from '../../Pages/SalesOrder/Invoices/InvoiceDetails/Tabs/Payments/Payments';
import CreditNotes from '../../Pages/SalesOrder/Invoices/InvoiceDetails/Tabs/CreditNotes/CreditNotes';
import SalesReturn from '../../Pages/SalesOrder/SalesOrderDetails/Tabs/SalesReturn';
import ReturnReceive from '../../Pages/SalesReturn/SalesReturnDetails/Info/ReturnReceive';
import Receives from '../../Pages/PurchaseOrder/PurchaseOrderDetails/Tabs/Receives/Receives';
import Bills from '../../Pages/PurchaseOrder/PurchaseOrderDetails/Tabs/Bills/Bills';
import BillPayments from '../../Pages/PurchaseOrder/Bills/BillDetails/Tabs/Payments/Payments';
import AccessDenied from '../../Components/AccessDenied/AccessDenied';
import AppliedOnInvoiceTab from '../../Pages/CreditNote/CreditNoteDetails/Tabs/AppliedOnInvoiceTab';
import CreditNoteRefundTab from '../../Pages/CreditNote/CreditNoteDetails/Tabs/CreditNoteRefundTab';

export default class MenuData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      history: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchHistory();
  }

  fetchHistory() {
    this.setState({ loading: true });
    restRequest('get', `${this.props.url}`).then((History) => {
      this.setState({
        history: History,
        loading: false,
      });
    });
  }
  renderHistory() {
    if (this.state.loading) return <Loader />;
    if (this.state.history.length === 0) return <EmptyTable />;

    return (
      <div className="menu-history h-255">
        {this.state.history.map((data) => (
          <TimelineCard
            key={data.id}
            name={data.creator}
            description={data.description}
            date={data.date}
            time={data.time}
            subject={data.subject}
            type={data.type}
          />
        ))}
      </div>
    );
  }
  render() {
    return (
      <div>
        {this.props.name === 'History' && this.renderHistory()}
        {this.props.name !== 'History' && !this.props.hasViewPermission && (
          <AccessDenied className="small dashboard" type="section" />
        )}
        {this.props.name === 'Packages' && this.props.hasViewPermission && (
          <Packages label={this.props.name} {...this.props} />
        )}

        {this.props.name === 'Invoices' && this.props.hasViewPermission && (
          <Invoices label={this.props.name} {...this.props} />
        )}

        {this.props.name === 'AppliedOnInvoices' &&
          this.props.hasViewPermission && (
            <AppliedOnInvoiceTab label={this.props.name} {...this.props} />
          )}

        {this.props.name === 'creditRefunds' &&
          this.props.hasViewPermission && (
            <CreditNoteRefundTab label={this.props.name} {...this.props} />
          )}

        {this.props.name === 'salesReturn' && this.props.hasViewPermission && (
          <SalesReturn label={this.props.name} {...this.props} />
        )}
        {this.props.name === 'Payments' && this.props.hasViewPermission && (
          <Payments label={this.props.name} {...this.props} />
        )}
        {this.props.name === 'creditnotes' && this.props.hasViewPermission && (
          <CreditNotes label={this.props.name} {...this.props} />
        )}
        {this.props.name === 'returnReceive' &&
          this.props.hasViewPermission && (
            <ReturnReceive
              label={this.props.name}
              salesReturnId={this.props.saleReturnId}
              {...this.props}
            />
          )}
        {this.props.name === 'receives' && this.props.hasViewPermission && (
          <Receives label={this.props.name} {...this.props} />
        )}
        {this.props.name === 'bills' && this.props.hasViewPermission && (
          <Bills label={this.props.name} {...this.props} />
        )}
        {this.props.name === 'payments' && this.props.hasViewPermission && (
          <BillPayments label={this.props.name} {...this.props} />
        )}
      </div>
    );
  }
}
