import React, { useEffect, useState } from 'react';
import { ItemGroupForm } from '../../../Components/Form';
import FormLayout from '../../../Components/Layout/FormLayout';
import { NewItemIcon } from '../../../Assets/Navigation/NavigationIcons';

const AddItemGroups = ({ handleToast }) => {
  const [, setStep] = useState(false);

  useEffect(() => {
    document.title = 'Item Groups';
  }, []);

  const handleStep = () => setStep(true);
  return (
    <FormLayout
      title="Item Groups"
      Icon={NewItemIcon}
      closeUrl="/item-groups"
      iconstyle="general_module_icon"
      layoutBoxClass="uiux-form-redesign"
    >
      <ItemGroupForm
        type="add"
        title="Create Item Groups"
        handleToast={handleToast}
        handleStep={handleStep}
      />
    </FormLayout>
  );
};

export default AddItemGroups;
