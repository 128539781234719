import React, { Fragment } from 'react';

import Table from '../../../../../Components/Table/Table';
//import { GridLayout } from '../../../../../Components/Layout/Layout';
import ListTableImageColumn from '../../../../../Components/ListTable/ListTableImageColumn';
import Quantity from '../../../../../Components/Fragments/Quantity/Quantity';
import { getItemTotalAmount } from '../../../../../Helpers/Calculate';

export default ({
  invoice,
  currency,
  overallTax,
  subTotal,
  inModal,
  warehouseList,
  invoiceDetailsForWarehouses,
}) => {
  const {
    items: invoiceItems,
    total,
    // note,
    paymentDue,
    adjustment,
    shippingCharge,
    discountLevel,
    discountTransactionLevel,
    discountType,
    discount,
    payment_made,
    payment_via_credits,
  } = invoice;
  let ADJUSTMENT_AMPOUNT = parseFloat(adjustment).toFixed(2);
  let DISCOUNT_AMOUNT = getDiscount(
    discountTransactionLevel,
    subTotal,
    discountType
  );
  let tableHeaders = ['Items', 'Qty.', 'Price', 'Tax', 'Amount'];
  let tableData = [
    'itemName',
    'invoicedQuantity',
    'invoicePrice',
    'invoiceTax',
    'amount',
  ];
  const rightAlignColumns = [3, 4];

  if (discountLevel === 'item') {
    tableHeaders.splice(tableHeaders.length - 1, 0, 'Discount');
    tableData.splice(tableData.length - 1, 0, 'discount');
    subTotal = subTotal - discount;
  }

  /*for (let i = 0; i < invoiceItems.length; i++) {
    for (let j = 0; j < warehouseList.length; j++) {
      if (invoiceItems[i].warehouse_id !== null) {
        if (invoiceItems[i].warehouse_id === warehouseList[j].id) {
          invoiceItems[i].warehouse_id = warehouseList[j].name;
        }
      } else {
        invoiceItems[i].warehouse_id = 'No warehouse';
      }
    }
  }*/
  const items = invoiceItems.map((invoiceDetail, index) => {
    return {
      index: index + 1,

      itemName: (
        <ListTableImageColumn
          name={invoiceDetail.item_name}
          sku={invoiceDetail.item.sku}
          images={invoiceDetail.item.images}
          id={invoiceDetail.item.id}
          showModalUponClick={inModal ? false : true}
          value={invoiceDetail.extra_description}
          showExtraDisableDescription={!!invoiceDetail.extra_description}
        />
      ),
      invoicePrice: `${currency.symbol}${parseFloat(invoiceDetail.rate).toFixed(
        2
      )}`,
      warehouse: invoiceDetail.warehouses.name,
      invoicedQuantity: (
        <Quantity
          quantity={invoiceDetail.quantity}
          unit={invoiceDetail.item.unit}
        />
      ),
      invoiceTax: `${
        invoiceDetail.tax_amount
          ? `${currency.symbol}${parseFloat(invoiceDetail.tax_amount).toFixed(
              2
            )}`
          : `${currency.symbol}0.00`
      }`,
      discount: `${
        invoiceDetail.discount_type === 'amount'
          ? currency.symbol +
            parseFloat(invoiceDetail.discount_item_level).toFixed(2)
          : ''
      }${
        invoiceDetail.discount_type === 'percent'
          ? invoiceDetail.discount_item_level + '%'
          : ''
      }`,
      amount: `${currency.symbol}${
        discountLevel === 'item'
          ? parseFloat(getItemTotalAmount(invoiceDetail)).toFixed(2)
          : parseFloat(invoiceDetail.quantity * invoiceDetail.rate).toFixed(2)
      }`,
    };
  });
  //console.log('items--------------', items)
  function getDiscount(discount, amount, type) {
    if (type === 'amount') {
      return currency.symbol + parseFloat(discount).toFixed(2);
    } else {
      return currency.symbol + parseFloat((amount / 100) * discount).toFixed(2);
    }
  }

  function renderOverallTax() {
    return overallTax.map((tax) => (
      <div className="single-summary-rec mb-6" key={tax.id}>
        <span className="">{`${tax.name} (${tax.rate}%)`} </span>
        <span className="semi-bold">{`${currency.symbol}${parseFloat(
          tax.total
        ).toFixed(2)}`}</span>
      </div>
      // <div key={tax.id}>
      //   <span>{`${tax.name} (${tax.rate}%)`}</span>
      //   <span className="padddding_right">{`${currency.symbol}${parseFloat(
      //     tax.total
      //   ).toFixed(2)}`}</span>
      // </div>
    ));
  }
  return (
    <Fragment>
      <Table
        list={items}
        tableHeaders={tableHeaders}
        tableData={tableData}
        rightAlignColumns={rightAlignColumns}
        className={
          'details-table table_view_invoice  invoice-summary uiux_invoice_table_summary ' +
          (discountLevel === 'item' ? 'discountAdded' : 'discountRemoved')
        }
      />
      <div className="invoice-summary-table">
        <div className="cus_padding">
          <div className="cust-table-summary">
            <div className="w-70 left-col">
              {/* <span>Applied Invoices:&nbsp;0</span> */}
            </div>
            <div className="w-30 right-col">
              <div className="single-summary-rec mb-6 border-bottom">
                <span className="semi-bold">Sub Total </span>
                <span className="semi-bold">
                  {`${currency.symbol}${parseFloat(subTotal).toFixed(2)}`}
                </span>
              </div>
              {renderOverallTax()}
              {discountLevel === 'transaction' ||
              discountTransactionLevel !== 0 ? (
                <div className="single-summary-rec mb-6 ">
                  <span className="">
                    {' '}
                    {discountType === 'percent'
                      ? `Discount (${discountTransactionLevel}%)`
                      : 'Discount'}{' '}
                  </span>
                  {parseFloat(DISCOUNT_AMOUNT.substring(1)) > 0 ? (
                    <span className="semi-bold" style={{ color: 'red' }}>
                      (-) {DISCOUNT_AMOUNT.substring(1)}
                    </span>
                  ) : (
                    <span className="semi-bold">{DISCOUNT_AMOUNT}</span>
                  )}
                </div>
              ) : null}
              <div className="single-summary-rec mb-6">
                <span className="">Adjustment </span>
                {parseFloat(adjustment) >= 0 ? (
                  <span className="semi-bold">
                    {' '}
                    {currency.symbol}
                    {ADJUSTMENT_AMPOUNT}
                  </span>
                ) : (
                  <span className="semi-bold" style={{ color: 'red' }}>
                    (-) {ADJUSTMENT_AMPOUNT.substring(1)}
                  </span>
                )}
              </div>

              <div className="single-summary-rec mb-6">
                <span className="">Shipping & Handling </span>
                <span className="semi-bold">
                  {' '}
                  {currency.symbol}
                  {parseFloat(shippingCharge).toFixed(2)}
                </span>
              </div>

              <div className="single-summary-rec mb-6">
                <span className="semi-bold">Total </span>
                <span className="semi-bold">
                  {' '}
                  {currency.symbol}
                  {parseFloat(total).toFixed(2)}{' '}
                </span>
              </div>
              {payment_made > 0 && (
                <div className={payment_made > 0 ? 'border-top' : null}>
                  <div className="single-summary-rec mb-0 py-7">
                    <span>Payment Made </span>{' '}
                    <span className="" style={{ color: 'red' }}>
                      {' '}
                      {`(-) ${parseFloat(payment_made).toFixed(2)}`}
                    </span>
                  </div>
                </div>
              )}
              {payment_via_credits > 0 && (
                <div
                  className={
                    payment_made <= 0 && payment_via_credits > 0
                      ? 'border-top'
                      : null
                  }
                >
                  <div className="single-summary-rec mb-0 py-7">
                    <span>Credits Applied </span>{' '}
                    <span className="" style={{ color: 'red' }}>
                      {' '}
                      {`(-) ${parseFloat(payment_via_credits).toFixed(2)}`}
                    </span>
                  </div>
                </div>
              )}
              <div className="single-summary-rec mb-6 border-top">
                <span className="semi-bold">Balance Due </span>
                <span className="semi-bold">
                  {' '}
                  {currency.symbol}
                  {parseFloat(paymentDue).toFixed(2)}{' '}
                </span>
              </div>
            </div>
          </div>
          {/* previous code */}
          {/* <div className="section-details--footer-amount">
          <div className="border-bottom">
            <span className="semi-bold">Sub Total </span>
            <span className="semi-bold padddding_right">{`${
              currency.symbol
            }${parseFloat(subTotal).toFixed(2)}`}</span>
          </div>
          {renderOverallTax()}
          {discountLevel === 'transaction' || discountTransactionLevel !== 0 ? (
            <div>
              <span>
                {discountType === 'percent'
                  ? `Discount (${discountTransactionLevel}%)`
                  : 'Discount'}
              </span>
              {parseInt(DISCOUNT_AMOUNT.substring(1)) > 0 ? (
                <span className="padddding_right" style={{ color: 'red' }}>
                  (-) {DISCOUNT_AMOUNT.substring(1)}
                </span>
              ) : (
                <span className="padddding_right">{DISCOUNT_AMOUNT}</span>
              )}
            </div>
          ) : null}
          <div>
            <span>Adjustment </span>{' '}
            {parseInt(adjustment) >= 0 ? (
              <span className="padddding_right">
                {' '}
                {currency.symbol}
                {ADJUSTMENT_AMPOUNT}
              </span>
            ) : (
              <span className="padddding_right" style={{ color: 'red' }}>
                (-) {ADJUSTMENT_AMPOUNT.substring(1)}
              </span>
            )}
          </div>

          <div>
            <span>Shipping & Handling </span>{' '}
            <span className="padddding_right">
              {' '}
              {currency.symbol}
              {parseFloat(shippingCharge).toFixed(2)}
            </span>
          </div>
          <div>
            <span className="semi-bold">Total </span>
            <span className="semi-bold padddding_right">
              {' '}
              {currency.symbol}
              {parseFloat(total).toFixed(2)}{' '}
            </span>
          </div>
          {payment_made > 0 && (
            <div className={payment_made > 0 ? 'border-top' : null}>
              <span>Payment Made </span>{' '}
              <span className="padddding_right" style={{ color: 'red' }}>
                {' '}
                {`(-) ${parseFloat(payment_made).toFixed(2)}`}
              </span>
            </div>
          )}
          {payment_via_credits > 0 && (
            <div
              className={
                payment_made <= 0 && payment_via_credits > 0
                  ? 'border-top'
                  : null
              }
            >
              <span>Credits Applied </span>{' '}
              <span className="padddding_right" style={{ color: 'red' }}>
                {' '}
                {`(-) ${parseFloat(payment_via_credits).toFixed(2)}`}
              </span>
            </div>
          )}
          <div className="border-top">
            <span className="semi-bold">Balance Due </span>{' '}
            <span className="semi-bold padddding_right">
              {' '}
              {currency.symbol}
              {parseFloat(paymentDue).toFixed(2)}{' '}
            </span>
          </div>
        </div> */}
        </div>
      </div>
    </Fragment>
  );
};
