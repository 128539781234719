import React, { Fragment, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withFormik, FieldArray } from 'formik';
import DeleteIcon from '../../../Assets/General/DeleteIcon';

import { MdHelpOutline } from '../../../Common/Icons';
import {
  restRequest,
  getObjFromLS,
  removeObjFromLS,
  setObjInLS,
  checkError,
} from '../../../Helpers';
import Field from './../../Form/Field/Field';
import Form from './../../Form/Form';
import { formSubmit, getError, validate } from '../FormHelper';
import AddItem from '../AddItem';
import FormAction from '../FormAction';
import ErrorFocus from '../ErrorFocus';
import { Checked } from '../../../Assets/List/ListIcons';
import { validateCreateItemGroupFormStepTwo } from '../../../Helpers/FormValidator';
import {
  ValidateMax10Billion,
  ValidateRequired,
  ValidateNumber,
} from '../../../Helpers/Validator';
import PopOver from '../../../Common/Popover';

const getYupValidationSchema = (_, props) => {
  return Yup.object().shape({
    warehouses: props.trackInventory
      ? Yup.array()
        .of(
          Yup.object().shape({
            name: ValidateRequired,
            openingStock: ValidateMax10Billion,
            openingStockValue: ValidateNumber,
          })
        )
        .min(1, 'At least one warehouse is required')
      : Yup.mixed().notRequired(),
  });
};
// let modalflag = false;
const formEnhancer = withFormik({
  validate: validate(getYupValidationSchema),
  mapPropsToValues: ({ item }) => ({
    ...item,
    itemsList: [...getObjFromLS('itemsList')],
    itemGroup: getObjFromLS('itemGroup'),
  }),
  mapPropsToStatus: (props) => ({
    allWarehouses: props.allWarehouses,
    warehousesList: props.warehousesList,
    currency: props.currency,
    emptyWarehouse: props.emptyWarehouse,
  }),
  handleSubmit: (values, bag) => {
    handleFormSubmit(values, bag);
  },
});

const handleFormSubmit = (
  values,
  {
    setSubmitting,
    props: {
      // requestUrl,
      history,
      handleToast,
      trackInventory,
      // successMessage,
      type,
      onSubmitModal: onSubmitItem,
      closeModal: closeItem,
      inModal: inModalItem,
      setPrompt,
    },
  }
) => {
  values.itemsList.forEach(function (item, index) {
    item.stock = item.warehouses.map((w) => ({
      id: item.id,
      name: item.name,
      warehouseId: w.id,
      warehouseName: w.name,
      openingStock: w.openingStock || 0,
      openingStockValue: w.openingStockValue || 0,
      is_active: w.is_active,
    }));
  });
  let data = new FormData();
  data.append('type', type);
  data.append('itemGroupId', values.itemGroup.id);
  for (let [index, item] of values.itemsList.entries()) {
    data.append(`items[${index}]`, JSON.stringify(item));
  }
  restRequest('post', 'itemgroups/stock', data)
    .then((res) => {
      setPrompt(false);
      if (inModalItem) {
        onSubmitItem(res);
        closeItem();
      } else {
        handleToast('Item Stocks saved successfully', 'success');
        removeObjFromLS('itemsList');
        removeObjFromLS('itemGroup');
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS === null) {
          let obj = {
            prevId: null,
            id: null,
            nextId: null,
            moduleName: 'item_groups',
            urlPath: '/item-groups',
            queryParam: window.location.search,
          };
          setObjInLS('module', obj);
          dataFromLS = getObjFromLS('module');
        }
        if (type === 'add') {
          setObjInLS('module', {
            ...dataFromLS,
            queryParam: dataFromLS.dataFromLS ? dataFromLS.dataFromLS : '',
            id: values.itemGroup.id,
            urlPath: '/item-groups',
            moduleName: 'item_groups',
          });
        } else if (type === 'clone') {
          setObjInLS('module', {
            ...dataFromLS,
            moduleName: 'item_groups',
            queryParam: dataFromLS.dataFromLS ? dataFromLS.dataFromLS : '',
            id: type === 'clone' ? res.data.id : dataFromLS.id,
            urlPath: '/item-groups',
          });
        }
        type === 'edit' &&
          setObjInLS('module', {
            ...dataFromLS,
            action: type === 'add' ? 'add' : 'justedit',
            queryParam: dataFromLS.queryParam ? dataFromLS.queryParam : '',
          });
        return history.push('/r');
      }
    })
    .catch((error) => {
      checkError(error);
      handleToast(error, 'error');
      setSubmitting(false);
    });
};

const ItemGroupEnhancerStepTwo = ({
  // props from formik
  values,
  status,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  setValues,
  setStatus,
  isSubmitting,
  submitCount,
  errors,
  touched,
  suggestions,
  onSuggestionsFetchRequested,
  stepOne,
  onSuggestionsClearRequested,
  removeSuggestion,
  // custom props
  type,
  handleToast,
  history,
  trackInventory,
  allWarehouses,
  warehousesList,
  handleTrackInventory,
  closeModal: closeItem,
  inModal: inModalItem,
  ...props
}) => {
  useEffect(() => {
    handleAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    values.itemsList.forEach((el) => {
      if (type === 'edit') {
        if (el) {
          if (el.warehouses.length > 0) {
            let oldValues = [];
            el.warehouses.forEach(function (warehouse) {
              oldValues.push(warehouse.id);
            });
            warehousesList.forEach(function (list) {
              if (!oldValues.includes(list.id)) {
                el.warehousesList.push(list);
              }
            });
          } else {
            el.warehousesList = [...warehousesList];
          }
        }
      } else {
        el.warehousesList = [...warehousesList];
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let x = [];
    values.itemsList.forEach((el) => {
      el.warehouses.forEach((el2) => {
        el2.id === null ? x.push('null') : x.push(el.id);
      });
      x.includes('null') ? setEnable(true) : setEnable(false);
    });
  }, [values.itemsList]);

  const [enable, setEnable] = useState(false);
  const [popovers, setPopovers] = useState({
    openingStock: false,
  });

  const handleAlert = () => {
    let { checkout } = validateCreateItemGroupFormStepTwo(values);
    if (!checkout) props.setPrompt();
    else props.setPrompt(false);
  };

  const handleSelectedWarehouses = (option, index, itemsListIndex) => {
    let newWarehouse = {
      id: option.id,
      name: option.name,
      address: option.address,
      city: option.city,
      state: option.state,
      country: option.country,
      zipCode: option.zip_code,
      openingStock:
        values.itemsList[itemsListIndex].warehouses[index].openingStock,
      openingStockValue: values.itemsList[itemsListIndex].opening_stock_value,
      is_active: 1,
    };

    let updatedWarehousesList = [
      ...Object.assign([...values.itemsList[itemsListIndex].warehouses], {
        [index]: newWarehouse,
      }),
    ];
    // setFieldValue('warehouses', updatedWarehousesList)
    setFieldValue(
      `itemsList[${itemsListIndex}].warehouses`,
      updatedWarehousesList
    );

    let updatedOptions = values.itemsList[itemsListIndex].warehousesList.filter(
      (warehouse) => warehouse.id !== option.id
    );
    values.itemsList[itemsListIndex].warehousesList = [...updatedOptions];
  };
  const handleRemoveWareHouse = (removeIndex, itemsListIndex) => {
    values.itemsList[itemsListIndex].warehouses[removeIndex].id &&
      values.itemsList[itemsListIndex].warehousesList.push(
        values.itemsList[itemsListIndex].warehouses[removeIndex]
      );
    if (values.itemsList[itemsListIndex].warehouses.length === 1) {
      values.itemsList[itemsListIndex].warehouses.push(status.emptyWarehouse);
    }
    let updatedWarehousesList = values.itemsList[
      itemsListIndex
    ].warehouses.filter((_, index) => index !== removeIndex);
    setFieldValue(
      `itemsList[${itemsListIndex}].warehouses`,
      updatedWarehousesList
    );
  };
  /*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/
  String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  };
  const renderWarehouseTable = (i) => {
    return (
      <FieldArray
        name="warehouses"
        render={() => {
          return (
            <table
              className="list-table form-table border add_new_item newitem_form_set"
              style={{ width: '100%' }}
            >
              <tbody>
                {values.itemsList[i].warehouses.map((warehouse, index) => {
                  return (
                    <tr key={index}>
                      {warehouse.id ? (
                        <td className="selected-warehouse-name">
                          {warehouse.name}
                        </td>
                      ) : (
                        <td className="warehouse-name">
                          <Field
                            type="dropdown"
                            autoFocus={true}
                            placeholder="Select Warehouse"
                            value={warehouse.id}
                            options={values.itemsList[i].warehousesList}
                            dropdownValue="id"
                            dropdownLabel="name"
                            clearable={false}
                            required={values.itemsList[i].trackInventory}
                            handleChange={(option) =>
                              handleSelectedWarehouses(option, index, i)
                            }
                            error={
                              // getError(errors, touched, `itemsList[${i}].warehouses[${index}].name`)
                              values.itemsList[i].warehouses[index].id ===
                              null && 'Required'
                            }
                            onBlur={() =>
                              setFieldTouched(
                                `itemsList[${i}].warehouses[${index}].name`,
                                true
                              )
                            }
                          />
                        </td>
                      )}
                      <td className="right-align no-padding with-close--btn">
                        <Field
                          className="right-align"
                          type="number"
                          placeholder="0"
                          //disabled={type === "edit"}
                          value={parseInt(warehouse.openingStock)}
                          decimalScale={6}
                          id={`itemsList[${index}].warehouses[${index}].openingStock`}
                          allowNegative={false}
                          thousandSeparator=","
                          onValueChange={(value) =>
                            setFieldValue(
                              `itemsList[${i}].warehouses[${index}].openingStock`,
                              value.floatValue && parseInt(value.floatValue)
                            )
                          }
                          onBlur={handleBlur}
                          error={getError(
                            errors,
                            touched,
                            `itemsList[${i}].warehouses[${index}].openingStock`
                          )}
                        />
                      </td>
                      <td className="right-align no-padding with-close--btn">
                        <Field
                          className="right-align"
                          type="number"
                          placeholder="0"
                          disabled={true}
                          value={values.itemsList[i].opening_stock_value}
                          decimalScale={6}
                          id={`itemsList[${index}].warehouses[${index}].openingStockValue`}
                          allowNegative={true}
                          thousandSeparator=","
                          onValueChange={(value) =>
                            setFieldValue(
                              `itemsList[${i}].warehouses[${index}].openingStockValue`,
                              value.floatValue
                            )
                          }
                          onBlur={handleBlur}
                          error={getError(
                            errors,
                            touched,
                            `itemsList[${i}].warehouses[${index}].openingStock`
                          )}
                        />
                        <div className="remove_row_button remove_item_set icon_div">
                          <button
                            type="button"
                            disabled={
                              values.itemsList[i].warehouses.length === 1 &&
                              values.itemsList[i].warehouses[0].id === null
                            }
                            onClick={() => handleRemoveWareHouse(index, i)}
                          >
                            <DeleteIcon />
                          </button>
                          <span className="icon_div">
                            <div className="tooltip_tooltip">
                              Delete Warehouse
                            </div>
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        }}
      />
    );
  };
  const handleChangeInventory = (selected) => {
    values.itemsList[selected].trackInventory =
      !values.itemsList[selected].trackInventory;
    if (values.itemsList[selected].trackInventory === false)
      values.itemsList[selected].warehouses = [
        ...values.itemsList[selected].warehouses.filter(
          (el2) => el2.id !== null
        ),
      ];
    if (values.itemsList[selected].trackInventory === false) setEnable(false);
    setFieldValue(
      `trackInventory + ${selected}`,
      values.itemsList[selected].trackInventory
    );
    // `trackInventory + ${selected}` && below line field value are
    // dependent
    setFieldValue(`trackInventory `, values.itemsList[selected].trackInventory);
  };
  const TrackInventoryTitle = ({ selected }) => (
    <div id={selected}>
      {' '}
      <div className="form-checkbox">
        <label className="form-checkbox-label track_inv_checkbox">
          <input
            id={`trackInventory + ${selected}`}
            checked={values.itemsList[selected].trackInventory}
            type="checkbox"
            onChange={() => handleChangeInventory(selected)}
          />
          <span>
            <Checked />
          </span>
        </label>
        {/* <span className="icon_div"><MdHelpOutline className="material-icon blue-icon help" style={{ marginRight: '1.7rem' }} />
        <div className="tooltip_tooltip">
          <label className='bold' htmlFor="trackInventory">Track Inventory</label><br />
          Enable inventory to track this item's stock based on the sales and purchase transactions recorded in your business.<br />
          You cannot enable/disable inventory tracking once you've created transactions for this item
        </div>
      </span> */}
      </div>
    </div>
  );

  const setEmptyWareHouse = (setFieldValue, item, index) => {
    status.emptyWarehouse.openingStockValue = item.opening_stock_value;
    setFieldValue(`itemsList[${index}].warehouses`, [
      ...values.itemsList[index].warehouses,
      status.emptyWarehouse,
    ]);
  };

  /*const copyValue = (copy, setFieldValue) => {
    const result = values.itemsList.filter((item) => item.trackInventory);
    values.itemsList.forEach(function (item, index) {
      item.warehouses.forEach(function (warehouse, i) {
        setFieldValue(
          `itemsList[${index}].warehouses[${i}][${copy}]`,
          result[0].warehouses[0][copy]
        );
      });
    });
  };*/

  const copyStockValue = (copy, setFieldValue) => {
    const result = values.itemsList.filter((item) => item.trackInventory);

    let FirstCopyValue = 0;
    if (result.length) {
      FirstCopyValue = copyResultValue[0]?.warehouses[0]?.openingStock || 0;
    }

    values.itemsList.forEach((item, index) => {
      if (item.trackInventory) {
        item.warehouses.forEach((warehouse, i) => {
          setFieldValue(
            `itemsList[${index}].warehouses[${i}][${copy}]`,
            FirstCopyValue
          );
        });
      }
    });
  };

  const togglePopover = (key) =>
    setPopovers({
      ...popovers,
      [key]: !popovers[key],
    });

  const copyResultValue = values.itemsList.filter(
    (item) => item.trackInventory
  );
  let FirstCopyValue = 0;
  if (copyResultValue.length) {
    FirstCopyValue = copyResultValue[0]?.warehouses[0]?.openingStock || 0;
  }

  return (
    <Fragment>
      <Form
        className="item-form item-group-form-main"
        onSubmit={(event) =>
          formSubmit(
            event,
            values.warehouses,
            setFieldValue,
            handleSubmit,
            'id',
            'warehouses'
          )
        }
        type={inModalItem && 'modal'}
      >
        <div className="group-set-main">
          <table className="w-100 group-table-set">
            <tr>
              <th className="d-inline">Item</th>
              <th className="d-inline">
                Track Inventory
                <span className="icon_div">
                  <MdHelpOutline
                    className="material-icon blue-icon help"
                    style={{ marginRight: '1.7rem' }}
                  />
                  <div className="tooltip_tooltip">
                    <label className="bold" htmlFor="trackInventory">
                      <span> Track Inventory</span>
                    </label>
                    <br />
                    Enable inventory to track this item's stock based on the
                    sales and purchase transactions recorded in your business.
                    <br />
                    You cannot enable/disable inventory tracking once you've
                    created transactions for this item
                  </div>
                </span>
              </th>
              <th className="d-inline">Warehouse</th>
              <th className="d-inline">
                Opening Stock
                {/* <button
                  className="attribute_btn float-left w-100 group-btn-sm"
                  type="button"
                  onClick={() => copyValue('openingStock', setFieldValue)}
                >
                  <span>Copy to all</span>
                </button> */}
                {copyResultValue.length ? (
                  <PopOver
                    title="Copy to all"
                    titleClass="group-btn-sm"
                    mainClassName="attribute_btn float-left w-100 group-btn-sm item-group-add"
                    titleClick={() => togglePopover('openingStock')}
                    show={popovers.openingStock}
                    cancelHandler={() => togglePopover('openingStock')}
                    onYesClickHandler={() => {
                      copyStockValue('openingStock', setFieldValue);
                      togglePopover('openingStock');
                    }}
                  >
                    <div>
                      Would you like {FirstCopyValue || 0} amount to be
                      reflected in all below field
                    </div>
                  </PopOver>
                ) : null}
              </th>
              <th className="d-inline">
                Opening Stock Value
                <p className="attribute_btn float-left w-100 item-opening-stock-per-unit">
                  <span> Per Unit</span>
                </p>
              </th>
            </tr>
          </table>
          <table className="w-100 detail-table-set">
            <tbody>
              {values.itemsList.map((el, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <table className="w-100 mt-6">
                        <tr>
                          <td className="item-name-set">
                            <div className="item-checkbox">
                              <h3>{el.name}</h3>
                            </div>
                          </td>
                          <td className="inventory-status">
                            <TrackInventoryTitle selected={i} />
                          </td>
                          <td>
                            {el.trackInventory ? (
                              <Fragment>
                                <div className="form-field form-table--container no-margin">
                                  {renderWarehouseTable(i)}
                                  <AddItem
                                    label="Add Warehouse"
                                    onClick={() =>
                                      setEmptyWareHouse(
                                        setFieldValue,
                                        values.itemsList[i],
                                        i
                                      )
                                    }
                                  />
                                </div>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <table style={{ width: '100%' }}>
                                  <tbody>
                                    <tr>
                                      <td colSpan={3}>
                                        <div className="text-center No-product">
                                          <p>Non Inventory Product</p>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </Fragment>
                            )}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="item-group-action">
          <FormAction
            disabled={isSubmitting || enable}
            marginFromRight={0}
            // setPrompt={props.setPrompt}
            onCancel={() => {
              let dataFromLS = getObjFromLS('module');
              removeObjFromLS('itemsList');
              removeObjFromLS('itemGroup');
              let redirectUrl = '';
              if (dataFromLS && dataFromLS.moduleName === 'item_groups') {
                redirectUrl = dataFromLS.queryParam;
              }
              if (inModalItem) {
                closeItem();
              } else if (
                window.location.pathname.includes('add') ||
                window.location.pathname.includes('edit')
              ) {
                history.push('/item-groups' + redirectUrl);
              } else {
                setObjInLS('module', { ...dataFromLS, action: null });
                history.push('/r');
              }
            }}
          />
        </div>
        <ErrorFocus />
      </Form>
    </Fragment>
  );
};

export default formEnhancer(ItemGroupEnhancerStepTwo);
