import React, { useEffect } from 'react';
import { InvoiceForm } from '../../../Components/Form';
import { FormLayout } from '../../../Components';
import { NewInvoiceIcon } from '../../../Assets/Navigation/NavigationIcons';

const EditInvoice = (props) => {
  useEffect(() => {
    document.title = 'Edit Invoice';
  }, []);

  return (
    <FormLayout
      title="Invoices"
      Icon={NewInvoiceIcon}
      subtitle="Edit"
      closeUrl="/invoices"
      iconstyle="general_module_icon"
      layoutBoxClass="uiux-form-redesign"
    >
      <InvoiceForm
        title="Edit Invoice"
        type="edit"
        idFromHeaderWrapper={props.idFromHeaderWrapper}
        handleToast={props.handleToast}
      />
    </FormLayout>
  );
};

export default EditInvoice;
