import React, { Component, Fragment } from 'react';
import moment from 'moment';

import { restRequest } from '../../../../../Helpers/RequestHelper';
import { getOrganizationDate } from '../../../../../Helpers/Date/OrganizationDate';
import { NewBillIcon } from '../../../../../Assets/Navigation/NavigationIcons';
import { getObjFromLS, setObjInLS } from '../../../../../Helpers/LocalStorage';
import Table from '../../../../../Components/Table/Table';
import Loader from '../../../../../Components/Loader/Loader';
import PDFViewer from '../../../../../Components/PDFViewer/PDFViewer';
import RecordPaymentForm from '../../../../../Components/Form/RecordPaymentForm/RecordPaymentForm';
import BillForm from '../../../../../Components/Form/BillForm/BillForm';
import CustomModal from '../../../../../Components/CustomModal/CustomModal';
import { NoItemsBox } from '../../../../../Components/Layout/Box';
import { MdAdd } from '../../../../../Common/Icons';
import getDate from '../../../../../Helpers/Date/GetDate';
import PdfIcon from '../../../../../Assets/General/PdfIcon';
import EditIcon from '../../../../../Assets/General/EditIcon';
import DeleteIcon from '../../../../../Assets/General/DeleteIcon';
import RecordPaymentIcon from '../../../../../Assets/General/RecordPaymentIcon';
import MarkAsOpenIcon from '../../../../../Assets/General/MarkAsOpenIcon';
import { hasPermissionCustom } from '../../../../../Helpers/CustomPermission';
import BillDetails from '../../../../../Pages/PurchaseOrder/Bills/BillDetails/index';
import { checkError } from '../../../../../Helpers/AuthHelper';

export default class Bills extends Component {
  state = {
    loading: true,
    bills: [],
    showNewBillModal: false,
    pdf: null,
    showPDF: false,
    showRecordPaymentModal: false,
    clickedBillId: '',
    showEditBillModal: false,
    showDeleteBillModal: false,
    showViewBillModal: false,
    viewBillId: null,
    showPrompt: false,
  };

  openBillViewModal = (id) => {
    this.setState({
      showViewBillModal: true,
      viewBillId: id,
    });
  };

  componentDidUpdate() {
    if (this.props.updateBillsList === true) {
      this.fetchData();
      this.props.toggleUpdateBills();
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ loading: true });

    await restRequest(
      'get',
      `purchaseorders/${this.props.purchaseOrderId}/bills`
    )
      .then((res) => {
        this.setState({ bills: res });
      })
      .catch((error) => checkError(error));

    this.setState({ loading: false });
  };

  updateBillStatus(id, index, status) {
    let updatedBillList = [...this.state.bills];
    let updatedBill = { ...updatedBillList[index] };
    updatedBill.status = status;
    updatedBill.original_status = status;
    if (status === 'open') {
      let currentOrgTime = moment(getOrganizationDate());
      if (currentOrgTime.isAfter(updatedBillList[index].due_date)) {
        updatedBill.status = 'overdue';
      }
    }
    updatedBillList[index] = updatedBill;
    this.setState({
      bills: updatedBillList,
    });
  }

  markAsOpen(id, index) {
    restRequest('put', `bills/${id}/markasopen`)
      .then(() => {
        this.props.handleToast('Bill has been marked as open.', 'success');
        let status = this.state.bills[index].total === 0 ? 'paid' : 'open';
        this.updateBillStatus(id, index, status);
      })
      .catch((error) => checkError(error, this.props.handleToast));
  }

  markAsVoid(id, index) {
    restRequest('put', `bills/${id}/markasvoid`)
      .then(() => {
        this.props.handleToast('Bill has been marked as void.', 'success');
        this.updateBillStatus(id, index, 'void');
      })
      .catch((error) => checkError(error, this.props.handleToast));
  }

  convertToDraft(id, index) {
    restRequest('put', `bills/${id}/converttodraft`)
      .then(() => {
        this.props.handleToast('Bill has been marked as draft.', 'success');
        this.updateBillStatus(id, index, 'draft');
      })
      .catch((error) => checkError(error, this.props.handleToast));
  }

  isDisabled(id, status) {
    let bill = this.state.bills.find((bill) => bill.id === id);
    return status.includes(bill.original_status);
  }

  checkIfMadeFromReceive(id) {
    let bill = this.state.bills.find((bill) => bill.id === id);
    return bill.is_billed_from_receive === 1 || bill.is_receive_from_bill === 1
      ? true
      : false;
  }

  checkIfBillIsEditable(id) {
    let result = this.props.hasPermissionCustom('bill', 'Edit')
      ? true
      : this.props.purchaseOrderStatus === 'closed' ||
        this.state.bills.map((bill) =>
          bill.id === id ? bill.original_status === 'paid' : false
        );
    return typeof result === 'object' ? result[0] : result;
  }

  tableRowOptions = [
    {
      label: 'Record Payment',
      icon: RecordPaymentIcon,
      onClick: (id) => this.openRecordPaymentModal(id),
      disabled: (id) =>
        !this.props.hasPermissionCustom('payment', 'Create')
          ? true
          : this.getClickedBill(id),
    },
    {
      label: 'Print & PDF',
      icon: PdfIcon,
      onClick: (id) => this.openPdf(id),
    },
    {
      label: 'Edit',
      icon: EditIcon,
      onClick: (id) => this.openEditBillModal(id),
      // disabled: id =>  !this.checkIfBillIsEditable(id) || this.checkIfMadeFromReceive(id)
      disabled: (id) =>
        !this.props.hasPermissionCustom('bill', 'Edit')
          ? true
          : this.isDisabled(id, ['open', 'void', 'paid', 'partially paid']) ||
            this.checkIfMadeFromReceive(id),
    },
    {
      label: 'Delete',
      icon: DeleteIcon,
      onClick: (id) => this.openDeleteBillModal(id),
      disabled: (id) =>
        !this.props.hasPermissionCustom('bill', 'Delete')
          ? true
          : this.isDisabled(id, ['paid', 'partially paid']),
    },
    {
      label: 'Mark as Open',
      icon: MarkAsOpenIcon,
      onClick: (id, index) => this.markAsOpen(id, index),
      disabled: (id) =>
        !this.props.hasPermissionCustom('bill', 'Edit')
          ? true
          : this.isDisabled(id, ['open', 'void', 'paid', 'partially paid']),
    },
    /*{
      label: 'Mark as Void',
      icon:MarkAsVoidIcon,
      onClick: (id, index) => this.markAsVoid(id, index),
      disabled: id => !this.props.hasPermissionCustom('bill','Edit') ? true : this.isDisabled(id, ['void', 'paid', 'partially paid'])
    },
    {
      label: 'Convert to Draft',
      icon: ConvertToDraftIcon,
      onClick: (id, index) => this.convertToDraft(id, index),
      disabled: (id) =>
        !this.props.hasPermissionCustom('bill', 'Edit')
          ? true
          : this.isDisabled(id, ['draft', 'open', 'paid', 'partially paid']),
    },*/
  ];

  getClickedBill = (id) => {
    let bill = this.state.bills.find((bill) => bill.id === id);
    if (bill.status === 'paid' || bill.total === 0) {
      return true;
    }
    return false;
  };

  openNewBillModal = async () => {
    const { billStatus, receiveStatus, purchaseOrderId, handleToast } =
      this.props;
    if (
      billStatus === 'partially billed' &&
      receiveStatus === 'partially received'
    ) {
      try {
        await restRequest(
          'get',
          `bills/create?purchase_order_id=${purchaseOrderId}`
        );
      } catch (error) {
        //handleToast(error, 'error')
        checkError(error, handleToast);
        return;
      }
    }
    this.setState({ showNewBillModal: true });
  };

  openEditBillModal = (id) => {
    this.setState({
      showEditBillModal: true,
      clickedBillId: id,
    });
  };

  openRecordPaymentModal = (id) => {
    this.setState({
      showRecordPaymentModal: true,
      clickedBillId: id,
    });
  };

  openDeleteBillModal = (id) => {
    this.setState({
      showDeleteBillModal: true,
      clickedBillId: id,
    });
  };

  openPdf = (id) => {
    this.setState({ showPDF: true });
    restRequest('get', `bills/${id}/pdf`)
      .then((res) => {
        this.setState({ pdf: res });
      })
      .catch((error) => checkError(error));
  };

  closeAllModals = () => {
    this.setState({
      pdf: null,
      showPDF: false,
      showNewBillModal: false,
      showRecordPaymentModal: false,
      showEditBillModal: false,
      showDeleteBillModal: false,
      showViewBillModal: false,
    });
  };

  deleteBillModal() {
    return (
      <CustomModal
        type="delete"
        showModal={this.state.showDeleteBillModal}
        title="Confirm Bill Delete"
        onClose={this.closeAllModals}
        onSubmit={this.deleteBill}
      >
        Are you sure you want to delete this bill?
      </CustomModal>
    );
  }

  deleteBill = () => {
    // Delete the bill from database
    restRequest('delete', `bills/${this.state.clickedBillId}`)
      .then(() => {
        let dataFromLS = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLS,
          queryParam: `${
            dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
          }bills=true`,
        });
        this.closeAllModals();
        this.fetchData();
        this.props.handleToast('Bill deleted successfully', 'success');
        this.props.purchaseOrderStatusChange();
        this.props.updateReceives();
        sessionStorage.setItem('fullscreen', false);
      })
      .catch((error) => {
        this.closeAllModals();
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, 'error')
      });
  };

  handleBillFormSubmit = () => {
    this.closeAllModals();
    this.props.purchaseOrderStatusChange();
    this.fetchData();
  };

  handlePrompt = (status = true) => {
    this.setState({
      showPrompt: status,
    });
  };

  newBillModal() {
    const { purchaseOrderId, handleToast } = this.props;
    return (
      <CustomModal
        showModal={this.state.showNewBillModal}
        title="New Bill"
        extraClass="BillsFormPopup"
        modaltitle="new_bill_modal_title"
        fill="#000"
        Icon={NewBillIcon}
        onClose={this.closeAllModals}
        renderActions={false}
        size="large"
        showPrompt={this.state.showPrompt}
        className="w-1288"
        mainContainerClass="dragDropPopupContainer "
      >
        <BillForm
          history={this.props.history}
          inModal
          type="add"
          purchaseOrderId={purchaseOrderId}
          onSubmit={this.handleBillFormSubmit}
          onClose={this.closeAllModals}
          handleToast={handleToast}
          handleBillPrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  handleRecordPaymentSubmit = () => {
    this.fetchData();
    this.props.purchaseOrderStatusChange();
    this.closeAllModals();
  };

  newRecordPaymentModal() {
    return (
      <CustomModal
        showModal={this.state.showRecordPaymentModal}
        title="New Record Payment"
        Icon={RecordPaymentIcon}
        renderActions={false}
        onClose={this.closeAllModals}
        classNames="new-recieve-payment-popup"
        showPrompt={this.state.showPrompt}
      >
        <RecordPaymentForm
          type="bill"
          id={this.state.clickedBillId}
          history={this.props.history}
          handleToast={this.props.handleToast}
          onSubmit={this.handleRecordPaymentSubmit}
          onClose={this.closeAllModals}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  billEditModal() {
    return (
      <CustomModal
        showModal={this.state.showEditBillModal}
        title="Bill Edit"
        fill="#000"
        Icon={NewBillIcon}
        renderActions={false}
        onClose={this.closeAllModals}
        size="large"
        showPrompt={this.state.showPrompt}
        extraClass="BillsFormPopup"
        className="w-1288"
        mainContainerClass="dragDropPopupContainer"
      >
        <BillForm
          history={this.props.history}
          inModal
          type="edit"
          billId={this.state.clickedBillId}
          purchaseOrderId={this.props.purchaseOrderId}
          onSubmit={this.handleBillFormSubmit}
          onClose={this.closeAllModals}
          handleToast={this.props.handleToast}
          handleBillPrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  billViewModal() {
    return (
      <CustomModal
        // title="Bill"
        showModal={this.state.showViewBillModal}
        title="View Bill"
        Icon={NewBillIcon}
        renderActions={false}
        size="large"
        onClose={this.closeAllModals}
        linkTo={`bills/${this.state.viewBillId}`}
      >
        <BillDetails id={this.state.viewBillId} inModal={true} />
      </CustomModal>
    );
  }

  renderModals() {
    return (
      <Fragment>
        <PDFViewer
          showPDF={this.state.showPDF}
          hidePDFModal={this.closeAllModals}
          pdf={this.state.pdf}
        />
        {this.billViewModal()}
        {this.newBillModal()}
        {this.newRecordPaymentModal()}
        {this.billEditModal()}
        {this.deleteBillModal()}
      </Fragment>
    );
  }

  prepareBillsList(bills) {
    return bills.map((bill) => {
      return {
        ...bill,
        // billNo: <LinkStatusColumn to={`/bills/${bill.id}`} label={bill.bill_no} status={bill.status} />,
        billNo: (
          <div
            onClick={() =>
              !this.props.inModal && this.openBillViewModal(bill.id)
            }
          >
            {bill.bill_no}
          </div>
        ),
        //amount: `${this.props.currency.symbol} ${bill.total}`,
        //balanceDue: `${this.props.currency.symbol} ${bill.balance_due}`,
        amount: `${this.props.currency.symbol} ${parseFloat(bill.total).toFixed(
          2
        )}`,
        balanceDue: `${this.props.currency.symbol} ${parseFloat(
          bill.balance_due
        ).toFixed(2)}`,
        vendorName: `${bill.display_name}`,
        status: `${bill.status}`,
        dueDate: getDate(bill.due_date),
      };
    });
  }

  isBillCreatable(billStatus, receiveStatus, orderStatus) {
    if (!hasPermissionCustom('bill', 'Create')) return false;
    if (
      billStatus === 'billed' ||
      receiveStatus === 'received' ||
      orderStatus === 'cancelled' ||
      orderStatus === 'draft'
    ) {
      return false;
    }

    return true;
  }

  renderBillsListTable() {
    const { billStatus, receiveStatus, purchaseOrderStatus, label } =
      this.props;
    const billsList = this.prepareBillsList(this.state.bills);
    const tableHeaders = [
      'Bill No.',
      'Due Date',
      'Vendor',
      'Status',
      'Amount',
      'Balance Due',
    ];
    const tableData = [
      'billNo',
      'dueDate',
      'vendorName',
      'status',
      'amount',
      'balanceDue',
      'options',
    ];
    const rightAlignColumns = [5, 6];
    return (
      <Fragment>
        <Table
          list={billsList}
          tableHeaders={tableHeaders}
          tableData={tableData}
          fromTab={true}
          rightAlignColumns={rightAlignColumns}
          options={!this.props.inModal ? this.tableRowOptions : ''}
          className={`box-table ${label ? label + '_menu po-bills-menu' : ''}`}
          inModal={this.props.inModal}
        />
        {this.isBillCreatable(
          billStatus,
          receiveStatus,
          purchaseOrderStatus
        ) && (
          <button
            className="layout-box--button btn-width"
            onClick={this.openNewBillModal}
          >
            <MdAdd className=" black-fill" />
            Add New Bill
          </button>
        )}
      </Fragment>
    );
  }

  renderBillsList() {
    const {
      billStatus,
      receiveStatus,
      purchaseOrderStatus,
      billTabDescription,
    } = this.props;
    return (
      <Fragment>
        {this.renderModals()}
        {!this.state.bills.length ? (
          <NoItemsBox
            subtitle={billTabDescription}
            showButton={this.isBillCreatable(
              billStatus,
              receiveStatus,
              purchaseOrderStatus
            )}
            buttonText="Add new bill"
            handleclick={this.openNewBillModal}
            Icon={NewBillIcon}
            inModal={this.props.inModal}
          />
        ) : (
          this.renderBillsListTable()
        )}
      </Fragment>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return this.renderBillsList();
  }
}
