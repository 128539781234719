import React, { useEffect } from 'react';
import { SalesOrderIcon } from '../../../Assets/Navigation/NavigationIcons';
import { FormLayout } from '../../../Components';
import { CPForm } from '../../../Components/Form';

const AddCustomerPayment = ({handleToast}) => {
  useEffect(() => {
    document.title = 'Add Customer Payment';
  }, []);

  return (
    <FormLayout
      title="Create Customer Payment"
      Icon={SalesOrderIcon}
      closeUrl="/contacts"
      iconstyle="general_module_icon"
    >
      <CPForm type="add" handleToast={handleToast} />
    </FormLayout>
  );
};

export default AddCustomerPayment;
