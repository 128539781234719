import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// import { restRequest } from '../../../Helpers/RequestHelper'
import Loader from '../../Loader/Loader';
import PackagesPlanFormnhancer from './PackagesPlanFormEnhancer';

// import './PackagesPlanForm.css'
class PackagesPlanForm extends Component {
  type = this.props.type; // "add" or "edit" form

  id = this.props.id || null;

  state = {
    tax: {
      name: '',
      rate: null,
      authority: '',
    },
    loading: false,
  };

  submitRequestType() {
    if (this.type === 'add') {
      return 'post';
    }
  }

  submitRequestUrl() {
    if (this.type === 'add') {
      return 'payment/paypal_payment';
    }
  }

  successMessage = () => {
    if (this.type === 'add') {
      this.props.handleToast('Packgae Plan created successfully', 'success');
    }
  };

  taxContent() {
    const { tax } = this.state;
    const { title, history, inModal, closeModal, onSubmitModal, handleToast } =
      this.props;

    return (
      <PackagesPlanFormnhancer
        title={title}
        tax={{ ...tax }}
        requestType={this.submitRequestType()}
        requestUrl={this.submitRequestUrl()}
        onSubmitModal={onSubmitModal}
        successMessage={this.successMessage}
        handleToast={handleToast}
        history={history}
        inModal={inModal}
        closeModal={closeModal}
      />
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return this.taxContent();
  }
}

export default withRouter(PackagesPlanForm);
