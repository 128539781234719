import { isEqual } from 'lodash';

export const validateCreateItemGroupFormStepOne = (
  data = {},
  defaultValues = {}
) => {
  const checkEquality = Boolean(isEqual(data, defaultValues));
  // const {
  //   name,
  //   brand,
  //   description,
  //   manufacturer,
  //   sales_update,
  //   purchase_update,
  //   salesUnitPrice,
  //   unit,
  //   uploadImages,
  //   taxID = null,
  //   comboList,
  //   attributesArr,
  // } = data;
  let checkout = true;

  // data.attributesArr.forEach((elem) => {
  //   if (elem.attribute) {
  //     checkout = false;
  //   }
  // });
  if (
    // name ||
    // brand.length ||
    // comboList.length ||
    // attributesArr.length > 1 ||
    // description.length ||
    // manufacturer.length ||
    // sales_update ||
    // purchase_update ||
    // salesUnitPrice ||
    // unit.length ||
    // uploadImages.length ||
    // taxID
    !checkEquality
  ) {
    checkout = false;
  }
  return { checkout };
};

export const validateCreateItemGroupFormStepTwo = (data) => {
  const { warehouses, trackInventory } = data;
  let checkout = true;

  if (warehouses.length > 1 || trackInventory) {
    checkout = false;
  }
  warehouses.forEach((elem) => {
    if (elem.name.length || elem.openingStockValue) checkout = false;
  });
  return { checkout };
};
