export const validateItemAdjustment = (data) => {
  const {
    description,
    purchase_update = false,
    reason,
    warehouseID,
    date_update = false,
    items = [],
  } = data;
  let checkout = true;

  if (description || purchase_update || reason || warehouseID || date_update) {
    checkout = false;
  }

  items.forEach((elem) => {
    if (elem?.name?.length || elem?.adjustedQuantity) {
      checkout = false;
    }
  });

  return { checkout };
};
