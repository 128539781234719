import React, { useEffect } from 'react';
import { PurchaseOrderForm } from '../../Components/Form';
import { NewPurchaseOrderIcon } from '../../Assets/Navigation/NavigationIcons';
import { FormLayout } from '../../Components';

const ClonePurchaseOrder = (props) => {
  useEffect(() => {
    document.title = 'Clone Purcahse Orders';
  }, []);

  return (
    <FormLayout
      title="Clone Purchase Order"
      Icon={NewPurchaseOrderIcon}
      // subtitle="Clone"
      closeUrl="/purchaseorders"
      iconstyle="general_module_icon"
      layoutBoxClass="uiux-form-redesign"
    >
      <PurchaseOrderForm
        type="clone"
        // title="Clone Purchase Order"
        id={props.match.params.id}
        handleToast={props.handleToast}
      />
    </FormLayout>
  );
};

export default ClonePurchaseOrder;
