import React from 'react'

import { SalesOrderIcon } from '../../Assets/Navigation/NavigationIcons'

import Email from '../../Components/Email/Email'

export default (props) => {

  return (
    <Email
      {...props}
      Icon={SalesOrderIcon}
      name="Sales Orders"
      onCancelURL="/salesorders"
      emailAPI={`salesorders/${props.match.params.id}/mail`}
      fetchDataAPI={`salesorders/${props.match.params.id}/mail`}
      getBackTab
    />
  )
}