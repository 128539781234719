import React from 'react';

export default () => (
  <svg
    className="invoice"
    xmlns="http://www.w3.org/2000/svg"
    width="9.42"
    height="12.561"
    viewBox="0 0 9.42 12.561"
  >
    <path
      d="M9.249-7.924l-2.4-2.4A.588.588,0,0,0,6.43-10.5H6.28v3.14H9.42v-.15A.587.587,0,0,0,9.249-7.924ZM5.5-7.164V-10.5H.589A.587.587,0,0,0,0-9.911V1.472a.587.587,0,0,0,.589.589H8.832a.587.587,0,0,0,.589-.589V-6.575H6.084A.591.591,0,0,1,5.5-7.164ZM1.57-8.734a.2.2,0,0,1,.2-.2H3.729a.2.2,0,0,1,.2.2v.393a.2.2,0,0,1-.2.2H1.766a.2.2,0,0,1-.2-.2Zm0,1.963v-.393a.2.2,0,0,1,.2-.2H3.729a.2.2,0,0,1,.2.2v.393a.2.2,0,0,1-.2.2H1.766A.2.2,0,0,1,1.57-6.771ZM5.1-.3V.294a.2.2,0,0,1-.2.2H4.514a.2.2,0,0,1-.2-.2V-.3a1.4,1.4,0,0,1-.77-.278.2.2,0,0,1-.014-.3l.288-.275a.2.2,0,0,1,.249-.018.591.591,0,0,0,.314.091h.69A.308.308,0,0,0,5.365-1.4a.32.32,0,0,0-.215-.312l-1.1-.331a1.11,1.11,0,0,1-.775-1.064A1.092,1.092,0,0,1,4.317-4.217v-.592a.2.2,0,0,1,.2-.2h.393a.2.2,0,0,1,.2.2v.6a1.4,1.4,0,0,1,.77.278.2.2,0,0,1,.014.3L5.6-3.361a.2.2,0,0,1-.249.018.589.589,0,0,0-.314-.091h-.69a.308.308,0,0,0-.289.324.32.32,0,0,0,.215.312l1.1.331A1.11,1.11,0,0,1,6.15-1.4,1.092,1.092,0,0,1,5.1-.3Z"
      transform="translate(0 10.5)"
    />
  </svg>
);
