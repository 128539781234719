import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';

const AsyncSelectPaginate = ({
  data = [],
  fullRowId = '',
  imsItems = [],
  onChange = () => {},
}) => {
  const { selectedData = [] } = data;

  const loadOptions = async (search, prevOptions) => {
    const sleep = (ms) =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, ms);
      });
    await sleep(1000);
    let filteredOptions;
    if (!search) {
      filteredOptions = imsItems;
    } else {
      const searchLower = search.toLowerCase();
      filteredOptions = imsItems.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }
    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );
    return {
      options: slicedOptions,
      hasMore,
    };
  };

  return (
    <AsyncPaginate
      cacheUniqs={[Math.random()]}
      name="syncItem"
      value={selectedData && selectedData[fullRowId]}
      isClearable
      loadOptions={loadOptions}
      onChange={(obj) => onChange(obj, fullRowId)}
      isOptionDisabled={(option) => option.isdisabled}
    />
  );
};

export default AsyncSelectPaginate;
