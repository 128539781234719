import React, { Fragment } from 'react';

import Table from '../../../../../Components/Table/Table';
//import { GridLayout } from '../../../../../Components/Layout/Layout';
import ListTableImageColumn from '../../../../../Components/ListTable/ListTableImageColumn';
import Quantity from '../../../../../Components/Fragments/Quantity/Quantity';
// import {formatNumber} from "../../../../../Helpers/FormatNumber";

export default ({ bill, currency, overallTax, warehouseList, inModal }) => {
  const {
    adjustment,
    bill_item_details,
    discount_transaction_level,
    discount_type,
    balance_due,
    total,
  } = bill;

  /*var tableHeaders = ['Items', 'Quantity', 'Price', 'Amount']
  var tableData = ['itemName','quantity', 'rate', 'amount']
  if(warehouseList.length > 1){
      tableHeaders = ['Items','Warehouse', 'Quantity', 'Price', 'Amount']
      tableData = ['itemName','warehouse' ,'quantity', 'rate', 'amount']
  }*/
  const tableHeaders = ['Items', 'Warehouse', 'Qty.', 'Price', 'Amount'];
  const tableData = ['itemName', 'warehouse', 'quantity', 'rate', 'amount'];

  const rightAlignColumns = [2, 3, 4];
  let subtotal = 0;
  for (let i = 0; i < bill.bill_item_details.length; i++) {
    for (let j = 0; j < warehouseList.length; j++) {
      if (bill.bill_item_details[i].warehouse_id !== null) {
        if (bill.bill_item_details[i].warehouse_id === warehouseList[j].id) {
          bill.bill_item_details[i].warehouse_id = warehouseList[j].name;
        }
      } else {
        bill.bill_item_details[i].warehouse_id = 'No warehouse';
      }
    }
  }
  const items = bill_item_details.map((billDetail, index) => {
    subtotal += billDetail.quantity * billDetail.rate;
    return {
      itemName: (
        <ListTableImageColumn
          name={billDetail.item_name}
          sku={billDetail.item.sku}
          images={billDetail.item.images}
          id={billDetail.item.id}
          showModalUponClick={inModal ? false : true}
          value={billDetail.extra_description}
          showExtraDisableDescription={!!billDetail.extra_description}
        />
      ),
      warehouse: bill.bill_item_details[index].warehouse_id,
      quantity: (
        <Quantity quantity={billDetail.quantity} unit={billDetail.item.unit} />
      ),
      rate: `${currency.symbol}${parseFloat(billDetail.rate).toFixed(2)}`,
      //rate: `${currency.symbol}${billDetail.rate}`,
      //amount: `${currency.symbol}${(billDetail.quantity * billDetail.rate).toFixed(2)}`
      amount: `${currency.symbol}${parseFloat(
        billDetail.quantity * billDetail.rate
      ).toFixed(2)}`,
    };
  });

  function renderOverallTax() {
    return overallTax.map((tax) => (
      <div className="single-summary-rec mb-6" key={tax.id}>
        <span className="">{`${tax.name} (${tax.rate}%)`} </span>
        <span className="semi-bold">{`${currency.symbol}${parseFloat(
          tax.total
        ).toFixed(2)}`}</span>
      </div>
      // <div key={tax.id}>
      //   <span>{`${tax.name} (${tax.rate}%)`}</span>
      //   <span>{`${currency.symbol}${parseFloat(tax.total).toFixed(2)}`}</span>
      // </div>
    ));
  }

  let transactionDiscount = 0;
  let DISCOUNT_LABEL = 'Discount';
  let DISCOUNT_AMOUNT = 0;
  if (discount_type !== null) {
    if (discount_type === 'amount') {
      transactionDiscount += discount_transaction_level;
      DISCOUNT_AMOUNT = parseFloat(discount_transaction_level).toFixed(2);
    } else if (discount_type === 'percent') {
      transactionDiscount = (discount_transaction_level * subtotal) / 100;
      DISCOUNT_LABEL = `${DISCOUNT_LABEL} (${discount_transaction_level}%)`;
      DISCOUNT_AMOUNT = parseFloat(transactionDiscount).toFixed(2);
    }
  }
  let ADJUSTMENT_AMPOUNT = parseFloat(adjustment).toFixed(2);
  return (
    <Fragment>
      <div className="package-item-info package_details_table bills-table-main">
        <Table
          list={items}
          tableHeaders={tableHeaders}
          tableData={tableData}
          rightAlignColumns={rightAlignColumns}
          className="details-table bill_details_Table uiux_bills_summary"
        />
        <div className="custom-padding">
          {/* <div /> */}
          <div className="cust-table-summary">
            <div className="w-70 left-col">
              {/* <span>Applied Invoices:&nbsp;0</span> */}
            </div>
            <div className="w-30 right-col">
              <div className="single-summary-rec mb-6">
                <span className="">Sub Total </span>
                <span className="semi-bold">
                  {currency.symbol}
                  {parseFloat(subtotal).toFixed(2)}{' '}
                </span>
              </div>
              <div className="single-summary-rec mb-6">
                <span>{DISCOUNT_LABEL}</span>
                {parseFloat(DISCOUNT_AMOUNT) > 0 ? (
                  <span className="semi-bold" style={{ color: 'red' }}>
                    (-) {DISCOUNT_AMOUNT}
                  </span>
                ) : (
                  <span className="semi-bold">
                    {currency.symbol}
                    {DISCOUNT_AMOUNT}
                  </span>
                )}
              </div>
              {renderOverallTax()}
              <div className="single-summary-rec mb-6">
                <span>Adjustment</span>
                {parseFloat(adjustment) >= 0 ? (
                  <span className="semi-bold">
                    {currency.symbol}
                    {ADJUSTMENT_AMPOUNT}
                  </span>
                ) : (
                  <span className="semi-bold" style={{ color: 'red' }}>
                    (-) {ADJUSTMENT_AMPOUNT.substring(1)}
                  </span>
                )}
              </div>
              <div className="single-summary-rec mb-6">
                <span className="semi-bold">Total </span>
                <span className="semi-bold">
                  {' '}
                  {currency.symbol}
                  {parseFloat(total).toFixed(2)}{' '}
                </span>
              </div>
              <div className="single-summary-rec mb-6">
                <span>Payment Made </span>
                <span className="semi-bold">
                  {' '}
                  {currency.symbol}
                  {parseFloat(total - balance_due).toFixed(2)}{' '}
                </span>
              </div>
            </div>
          </div>
          {/* previous code */}
          {/* <div className="section-details--footer-amount">
            <div>
              <span>Sub Total </span><span className="semi-bold">{currency.symbol}{subtotal.toFixed(2)} </span> // it needs to be commented by default
              <span>Sub Total </span>
              <span className="semi-bold">
                {currency.symbol}
                {parseFloat(subtotal).toFixed(2)}{' '}
              </span>
            </div>
            <div>
              <span>{DISCOUNT_LABEL}</span><span className="semi-bold">{currency.symbol}{formatNumber(transactionDiscount)}</span>  // it needs to be commented by default
              <span>{DISCOUNT_LABEL}</span>
              {parseInt(DISCOUNT_AMOUNT) > 0 ? (
                <span className="semi-bold" style={{ color: 'red' }}>
                  (-) {DISCOUNT_AMOUNT}
                </span>
              ) : (
                <span className="semi-bold">
                  {currency.symbol}
                  {DISCOUNT_AMOUNT}
                </span>
              )}
            </div>
            {renderOverallTax()}
            <div>
              <span>Adjustment </span> <span className="semi-bold">{currency.symbol}{adjustment} </span> // it needs to be commented by default
              <span>Adjustment </span>{' '}
              {parseInt(adjustment) >= 0 ? (
                <span className="semi-bold">
                  {currency.symbol}
                  {ADJUSTMENT_AMPOUNT}
                </span>
              ) : (
                <span className="semi-bold" style={{ color: 'red' }}>
                  (-) {ADJUSTMENT_AMPOUNT.substring(1)}
                </span>
              )}
            </div>
            <div>
              <span className="semi-bold">Total </span><span className="semi-bold"> {currency.symbol}{total} </span> // it needs to be commented by default
              <span className="semi-bold">Total </span>
              <span className="semi-bold">
                {' '}
                {currency.symbol}
                {parseFloat(total).toFixed(2)}{' '}
              </span>
            </div>
            <div>
              <span>Payment Made </span><span className="semi-bold"> {currency.symbol}{(total - balance_due).toFixed(2)} </span> // it needs to be commented by default
              <span>Payment Made </span>
              <span className="semi-bold">
                {' '}
                {currency.symbol}
                {parseFloat(total - balance_due).toFixed(2)}{' '}
              </span>
            </div>
          </div> */}
        </div>

        <hr className="float-left w-100 divider" />
        <div className="cust-table-summary">
          <div className="w-70 left-col">
            {/* <span>Applied Invoices:&nbsp;0</span> */}
          </div>

          <div className="w-30 right-col">
            <div className="single-summary-rec mb-0">
              {/*<span className='semi-bold customTop bottom_title'>Balance Due </span> <span className="semi-bold customTop"> {currency.symbol}{balance_due.toFixed(2)}</span>*/}
              <span className="semi-bold">Balance Due </span>{' '}
              <span className="semi-bold">
                {' '}
                {currency.symbol}
                {parseFloat(balance_due).toFixed(2)}
              </span>
            </div>
          </div>
        </div>

        {/* <hr className='margin float-left w-100 divider' /> */}
      </div>
    </Fragment>
  );
};
