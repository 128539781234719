export default function Encrypt(str) {
  let ostr;
  if (!str || str === 'undefined' || str === 'null') str = '';
  // str = str === 'undefined' || str === 'null' ? '' : str;
  try {
    var key = 146;
    var pos = 0;
    ostr = '';
    while (pos < str.length) {
      ostr = ostr + String.fromCharCode(str.charCodeAt(pos) ^ key);
      pos += 1;
    }

    return ostr;
  } catch (ex) {
    return '';
  }
}
