import React, { Component, Fragment } from 'react';
import { Checked } from '../../../Assets/List/ListIcons';
import getDate from '../../../Helpers/Date/GetDate';
import FormAction from '../FormAction';
class BulkAddConfirmedSalesOrders extends Component {
  state = {
    selectedData: [],
  };

  selectAll = (event) => {
    let selectedData = [];
    if (event.target.checked) {
      selectedData = [...this.props.salesOrdersList];
      this.props.updateconfirmedSO(this.props.salesOrdersList.length);
    } else {
      selectedData = [];
      this.props.updateconfirmedSO(0);
    }
    this.setState({ selectedData });
  };

  selectSingle = (event) => {
    const id = parseInt(event.target.name, 10);

    if (event.target.checked) {
      const selectedItem = this.props.salesOrdersList.find(
        (one) => one.id === id
      );
      this.setState({
        selectedData: [...this.state.selectedData, selectedItem],
      });
      this.props.updateconfirmedSO(this.state.selectedData.length + 1);
    } else {
      const restCheckedItems = this.state.selectedData.filter(
        (one) => one.id !== id
      );
      this.setState({
        selectedData: restCheckedItems,
      });
      this.props.updateconfirmedSO(this.state.selectedData.length - 1);
    }
  };

  handleSubmit = () => {
    if (this.state.selectedData.length > 5) {
      this.props.handleToast('Select only 5 orders', 'error');
      return;
    }
    if (!this.state.selectedData.length) {
      this.props.handleToast('Please select at least one sale order', 'error');
      return;
    }
    this.props.updateconfirmedSO(0);
    const { onSubmit } = this.props;
    onSubmit(this.state.selectedData);
  };

  renderTable() {
    const { salesOrdersList, onClose, loader } = this.props;
    const { selectedData } = this.state;
    return loader ? (
      <div className="issued_SO_PO bulk_inv">
        <span className="test">Please wait...</span>{' '}
      </div>
    ) : (
      <Fragment>
        <div className="issued_SO_PO">
          <table className="list-table border">
            <thead>
              <tr>
                <th>
                  <label className="list-table--checkbox-lable pointer">
                    <input
                      checked={selectedData.length === salesOrdersList.length}
                      type="checkbox"
                      onChange={this.selectAll}
                    />
                    <span>
                      <Checked />
                    </span>
                  </label>
                </th>
                <th>Sales Order Details</th>
                <th>Date</th>
                <th className="right-align" style={{ paddingRight: '2rem' }}>
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {salesOrdersList.map((salesOrder, index) => (
                <tr key={index}>
                  <td>
                    <label className="list-table--checkbox-lable pointer">
                      <input
                        checked={selectedData.includes(salesOrder)}
                        type="checkbox"
                        name={salesOrder.id}
                        onChange={this.selectSingle}
                      />
                      <span>
                        <Checked />
                      </span>
                    </label>
                  </td>
                  <td>{salesOrder.sales_order_no}</td>
                  <td>{getDate(salesOrder.order_date)}</td>
                  <td className="right-align" style={{ paddingRight: '2rem' }}>
                    {salesOrder.currency_symbol}
                    {salesOrder.total}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <FormAction
          onSubmit={() => this.handleSubmit()}
          onCancel={() => onClose()}
          submitLabel="Add"
        />
      </Fragment>
    );
  }

  render() {
    return this.renderTable();
  }
}

export default BulkAddConfirmedSalesOrders;
