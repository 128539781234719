import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { restRequest } from '../../../Helpers/RequestHelper';

import OverlayLoader from '../../../Components/Loader/OverlayLoader';
import Form from '../../../Components/Form/Form';
import Field from '../../../Components/Form/Field/Field';
import FormAction from '../../../Components/Form/FormAction';
import { getObjFromLS } from '../../../Helpers/LocalStorage'; //, setObjInLS
import { checkError } from '../../../Helpers/AuthHelper';
import { isEqual } from 'lodash';
import { Checked } from '../../../Assets/List/ListIcons';

class SalesOrderPreference extends Component {
  state = {
    loading: true,
    showPrompt: false,
    staticVal: {},
    salesPref: {
      invoice: false,
      shipment: false,
      both: false,
    },
    terms: '',
    show_admin_records: true,
    isSubmitting: false,
  };

  componentDidMount() {
    localStorage.setItem('alert', false);
    this.fetchData();
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { terms, salesPref, staticVal } = this.state;
    const data = { ...salesPref, terms };
    if (prevState.showPrompt !== this.state.showPrompt) {
      const checkEquality = Boolean(isEqual(data, staticVal));
      if (!checkEquality) localStorage.setItem('alert', true);
      else localStorage.setItem('alert', false);
    }
  }

  handlePrompt = () => {
    this.setState({
      showPrompt: !this.state.showPrompt,
    });
  };

  async fetchData() {
    this.setState({ loading: true });
    await restRequest('get', 'preferences/salesorder')
      .then((res) => {
        if (res.preferences.invoice && res.preferences.shipment) {
          this.setState({
            salesPref: {
              invoice: false,
              shipment: false,
              both: true,
            },
            terms: res.terms,
            show_admin_records: res.preferences.show_admin_records,
            staticVal: {
              invoice: false,
              shipment: false,
              both: true,
              terms: res.terms,
            },
          });
        } else {
          this.setState({
            salesPref: {
              invoice: res.preferences.invoice,
              shipment: res.preferences.shipment,
              both: false,
            },
            terms: res.terms,
            show_admin_records: res.preferences.show_admin_records,
            staticVal: {
              invoice: res.preferences.invoice,
              shipment: res.preferences.shipment,
              both: false,
              terms: res.terms,
            },
          });
        }
      })
      .catch((error) => {
        this.props.handleToast(error, 'error');
        checkError(error);
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  }

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
    this.handlePrompt();
  };

  updateOptions(obj, option) {
    let updatedObj = obj;
    for (let key in updatedObj) {
      if (key === option) updatedObj[key] = true;
      else updatedObj[key] = false;
    }
    return updatedObj;
  }

  handleRadioChange = (event) => {
    this.handlePrompt();
    let stateName = event.target.name;
    let option = event.target.id;
    let updatedOptions = this.updateOptions(this.state[stateName], option);
    this.setState((state) => ({
      [stateName]: {
        ...state[stateName],
        ...updatedOptions,
      },
    }));
  };

  handleRadioClick(radioOption, radiostateName) {
    this.handlePrompt();
    let stateName = radiostateName;
    let option = radioOption;
    let updatedOptions = this.updateOptions(this.state[stateName], option);
    this.setState((state) => ({
      [stateName]: {
        ...state[stateName],
        ...updatedOptions,
      },
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let { invoice, shipment, both } = this.state.salesPref;
    this.setState({ isSubmitting: true });
    restRequest('put', 'preferences/salesorder', {
      status: {
        invoice: invoice || both,
        shipment: shipment || both,
        show_admin_records: this.state.show_admin_records

      },
      terms: this.state.terms,
    })
      .then(() => {
        this.setState({ isSubmitting: false });
        this.props.handleToast(
          'Sales Order preference updated successfully',
          'success'
        );
        this.props.setPrompt(false);
        this.fetchData();
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });
        checkError(error);
        this.props.handleToast(error, 'error');
      });
  };

  salesOrderStatus() {
    let { salesPref } = this.state;
    return (
      <div className="float-left w-100">
        <h4 className="mb_5 float-left w-100 mt_0">
          When do you want your Sales Orders to be closed?
        </h4>

        <div className="preference-label radio-btn float-left w-100">
          <input
            type="radio"
            name="salesPref"
            checked={salesPref.invoice}
            onChange={(event) => this.handleRadioChange(event)}
            id="invoice"
          />
          <label htmlFor="invoice">When invoice is created</label>
          <span
            onClick={() => this.handleRadioClick('invoice', 'salesPref')}
            className="radio-circle"
          />
        </div>

        <div className="preference-label radio-btn float-left w-100">
          <input
            type="radio"
            name="salesPref"
            checked={salesPref.shipment}
            onChange={(event) => this.handleRadioChange(event)}
            id="shipment"
          />
          <label htmlFor="shipment">When shipment is fulfilled</label>
          <span
            onClick={() => this.handleRadioClick('shipment', 'salesPref')}
            className="radio-circle"
          />
        </div>

        <div className="preference-label radio-btn float-left w-100">
          <input
            type="radio"
            name="salesPref"
            checked={salesPref.both}
            onChange={(event) => this.handleRadioChange(event)}
            id="both"
          />
          <label htmlFor="both">
            When shipment is fulfilled and invoice is created
          </label>
          <span
            onClick={() => this.handleRadioClick('both', 'salesPref')}
            className="radio-circle"
          />
        </div>
      </div>
    );
  }

  salesAdminRecord() {
    return (
      <div className="float-left w-100 invoice-terms-pref __invoice-main--block">
      <label>Sales Person</label>
    <label className="list-table--checkbox-lable pointer">
      <input
        className="float-left"
        checked={this.state.show_admin_records}
        type="checkbox"
        onChange={() => this.setState({ show_admin_records: !this.state.show_admin_records })}
        id="country"
      />
      <span className="float-left">
          <Checked />
        </span>
      <div style={{ marginLeft: "25px" }} className="admin-block">
        Show Admin Related Entries To Sales Manager <span>(this is directly linked to the User Permissions & Roles. Be careful when selecting it)</span>
      </div>
    </label>
    </div>
    );
  }

  salesTermsContent() {
    return (
      <div className="float-left w-100">
        <Field
          name="Terms & Conditions"
          id="terms"
          type="textarea"
          value={this.state.terms}
          placeholder="Terms & Conditions"
          handleChange={this.handleChange}
        />
      </div>
    );
  }
  renderActionButtons() {
    // return (
    //   <FormAction disabled={this.state.isSubmitting} onCancel={() => this.props.history.push('/settings')} />
    // )
    let permissions = getObjFromLS('role').permissions;
    if (!permissions.superAccess) {
      if (permissions.PreferenceEdit) {
        return (
          <FormAction
            disabled={this.state.isSubmitting}
            onCancel={() => this.props.history.push('/settings')}
          />
        );
      }
    }
    if (permissions.superAccess) {
      return (
        <FormAction
          disabled={this.state.isSubmitting}
          onCancel={() => this.props.history.push('/settings')}
        />
      );
    }
  }
  renderSalesOrderPreference() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <div className="po-pref-component float-left w-100">
          <div className="float-left w-70 genral_tab_content general_tabs_set">
            {this.state.loading && <OverlayLoader />}
            {this.salesOrderStatus()}
            {this.salesAdminRecord()}
            <hr className="pref--hr float-left w-100" />
            {this.salesTermsContent()}
            {this.renderActionButtons()}
          </div>
        </div>
      </Form>
    );
  }

  render() {
    return this.renderSalesOrderPreference();
  }
}

export default withRouter(SalesOrderPreference);
