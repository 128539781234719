import React, { Component } from 'react'
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { Editor } from 'react-draft-wysiwyg'
import _ from 'lodash'

import './EmailEditor.css'

class EmailEditor extends Component {

  state = {
    editorState: this.setEditorState()
  }

  setEditorState() {
    if (this.props.bodyContent) {
      const bodyContent = convertFromHTML(this.props.bodyContent)
      const contentState = ContentState.createFromBlockArray(
        bodyContent.contentBlocks,
        bodyContent.entityMap
      )
      return EditorState.createWithContent(contentState)
    }
    else {
      return EditorState.createEmpty()
    }
  }

  handleEditorChange = (editorState) => {
    let data = convertToRaw(editorState.getCurrentContent())
    _.map(data.entityMap, block => {
      if (block.type === 'IMAGE') {
        block.data.alt = ' ' // an empty alt tag to render broken image.
      }
    })
    this.setState({
      editorState
    }, this.props.getEditorContent(draftToHtml(data)))
  }

  onContentStateChange = (contentState) => {
    this.setState({ contentState })
  }

  render() {
    const { editorState } = this.state
    return (
      <div className="email-editor">
        <Editor
          editorState={editorState}
          wrapperClassName="editor-container"
          editorClassName="editor-text-container"
          toolbarClassName="editor-toolbar-container"
          onEditorStateChange={this.handleEditorChange}
          onContentStateChange={this.onContentStateChange}
          //readOnly={this.props.readOnly || false}
          readOnly = {false}
          //toolbarHidden = {true}
          toolbar={{
            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'image', 'history'],
            inline: {
              inDropdown: true,
              className: undefined,
              options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript']
            },
            blockType: {
              options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote', 'Code'],
            },
          }}
        />
      </div>
    )
  }
}


export default EmailEditor
