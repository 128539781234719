import React from 'react';
import { MdAdd } from '../../Common/Icons';
import './QuickAdd.css';
import {
  // Link,
  Redirect,
} from 'react-router-dom';
import { setObjInLS } from '../../Helpers/LocalStorage';

const RenderGroup = ({ title = '', routes = [], handleAction }) => (
  <div className="quick-add-inner">
    <div className="main-heading">{title}</div>
    <ul>
      {routes.map((val, index) => (
        <li
          key={index}
          onClick={() => handleAction(val.route, Boolean(val?.flag))}
        >
          <MdAdd /> {val.name}
        </li>
      ))}
    </ul>
  </div>
);

function QuickAdd({ hideQuickAdd }) {
  const [redirect, setRedirect] = React.useState(false);

  if (redirect) return <Redirect to="/r" />;

  const doRedirect = (url, vendorFlag = false) => {
    setObjInLS('module', { urlPath: url, vendorFlag: vendorFlag });
    setRedirect(true);
    hideQuickAdd();
  };

  return (
    <div className="quick-add">
      <RenderGroup
        title="General"
        routes={[
          { route: '/contacts/add', name: 'Contact' },
          { route: '/items/add', name: 'Items' },
          { route: '/item-adjustments/add', name: 'Item Adjustments' },
        ]}
        handleAction={doRedirect}
      />
      <RenderGroup
        title="Sales"
        routes={[
          { route: '/contacts/add', name: 'Customer' },
          { route: '/invoices/add', name: 'Invoices' },
          { route: '/salesorders/add', name: 'Sales Order' },
        ]}
        handleAction={doRedirect}
      />
      <RenderGroup
        title="Purchase"
        routes={[
          { route: '/contacts/add', name: 'Vendor', flag: true },
          { route: '/bills/add', name: 'Bills' },
          { route: '/purchaseorders/add', name: 'Purchase Order' },
        ]}
        handleAction={doRedirect}
      />
    </div>
  );
}

export default QuickAdd;
