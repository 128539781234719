import React, { Component, Fragment } from 'react';
import queryString from 'query-string';
import { NewItemIcon } from '../../../Assets/Navigation/NavigationIcons';
import { routesPath } from '../../Navigation/NavigationUtils';
import ItemFlowDiagram from '../../../Components/Onboarding/Diagrams/ItemFlowDiagram';
import { restRequest } from '../../../Helpers/RequestHelper';
import Alert from '../../../Components/Alert/Alert';
import HeaderWrapper from '../../../Components/HeaderWrapper/HeaderWrapper';
import ListTable from '../../../Components/ListTable/ListTable';
import Onboarding from '../../../Components/Onboarding/InitialOnboardingScreen';
import ListTableImageColumn from '../../../Components/ListTable/ListTableImageColumn';
import CheckedMenu, {
  Button,
  ButtonLink,
  Options,
} from '../../../Components/CheckedMenu/CheckedMenu';
import SearchResult from '../../../Components/SearchBar/SearchResult';
import './ItemGroup.css';
import {
  removeValueFromLS,
  getObjFromLS,
  setObjInLS,
  getStringValueFromLS,
} from '../../../Helpers/LocalStorage';
import ItemGroupDetails from './ItemGroupDetails';
import AddItemGroup from '../../../Pages/Items/ItemGroups/AddItemGroups';
import EditIcon from '../../../Assets/General/EditIcon';
import DeleteIcon from '../../../Assets/General/DeleteIcon';
import ActiveIcon from '../../../Assets/General/ActiveIcon';
import InactiveIcon from '../../../Assets/General/InactiveIcon';
import { checkError } from '../../../Helpers/AuthHelper';
import AccessDenied from '../../../Components/AccessDenied/AccessDenied';
import EditItemGroups from './EditItemGroups';

class ItemGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      dataExpanded: [],
      showExportModal: false,
      organizationCurrency: {},
      totalPage: 1,
      currentPage: 1,
      viewPerPage: 20,
      totalRecords: 20,
      itemId: null,
      currentFilter: 'all',
      orderBy: 'asc',
      sortFilter: 'name',
      preference: {},
      search: '',
      accessDenied: false,
    };
    this.getID = this.getID.bind(this);
  }

  filterOptions = [
    {
      id: 'none',
      label: 'Status',
      type: 'tagline',
    },
    {
      id: 'all',
      label: 'All',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'all', page: 1 }),
    },
    {
      id: 'active',
      label: 'Active',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'active', page: 1 }),
    },
    {
      id: 'inactive',
      label: 'Inactive',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'inactive', page: 1 }),
    },
  ];

  sortOptions = [
    {
      id: 'name',
      label: 'Name',
      type: 'link',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('name') }),
    },
    {
      id: 'created_at',
      label: 'Created Time',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('created_at') }),
    },
  ];

  componentDidMount() {
    document.title = 'Item Groups';
    this.fetchDataWithQueryString();
  }

  componentDidUpdate() {
    this.handleOnUpdateQueryString();
  }

  handleOnUpdateQueryString() {
    const { filter, page, sort, orderBy, view, search } =
      this.parseQueryString();
    if (filter !== this.state.currentFilter) {
      this.setState(
        {
          currentFilter: filter,
        },
        () => this.fetchData()
      );
    }
    if (sort !== this.state.sortFilter) {
      this.setState(
        {
          sortFilter: sort,
        },
        () => this.fetchData()
      );
    }
    if (orderBy !== this.state.orderBy) {
      this.setState(
        {
          orderBy: orderBy,
        },
        () => this.fetchData()
      );
    }
    if (page !== this.state.currentPage) {
      sessionStorage.setItem('current_page', parseInt(page));
      this.setState(
        {
          currentPage: parseInt(page),
        },
        () => this.fetchData()
      );
    }
    if (view !== this.state.viewPerPage) {
      this.handleResetCheckbox(true);
      sessionStorage.setItem('view', parseInt(view));
      this.setState(
        {
          viewPerPage: parseInt(view),
        },
        () => this.fetchData()
      );
    }
    if (search !== this.state.search) {
      this.setState({ search: search, bulkSelectedData: [] }, () =>
        this.fetchData()
      );
    }
  }

  fetchDataWithQueryString() {
    const { filter, page, sort, orderBy, view, search } =
      this.parseQueryString();
    this.setState(
      {
        sortFilter: sort,
        currentFilter: filter,
        currentPage: parseInt(page),
        orderBy: orderBy,
        viewPerPage: parseInt(view),
        search: search,
      },
      () => this.fetchData()
    );
  }

  parseQueryString() {
    const query = queryString.parse(this.props.location.search);
    let filter = '';
    switch (query.filter) {
      case 'all':
      case 'active':
      case 'inactive':
        filter = query.filter;
        break;
      default:
        filter = 'all';
    }

    let sort = '';
    switch (query.sort) {
      case 'name':
      case 'created_at':
      case 'manufacturer':
      case 'unit':
      case 'brand':
        sort = query.sort;
        break;
      default:
        sort = 'name';
    }

    let orderBy = '';
    switch (query.orderBy) {
      case 'asc':
      case 'desc':
        orderBy = query.orderBy;
        break;
      default:
        orderBy = 'asc';
    }

    let page = parseInt(query.page);
    if (!page) page = 1;
    let view = parseInt(query.view);
    if (!view) view = 20;

    let search = query.search;
    if (!search) search = '';

    return {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
    };
  }

  fetchData = async () => {
    this.setState({ loading: true });

    await this.fetchItemGroupsList();

    this.setState({ loading: false });
  };

  fetchItemGroupsList() {
    sessionStorage.setItem('view', parseInt(this.state.viewPerPage));
    sessionStorage.setItem('current_page', parseInt(this.state.currentPage));

    return restRequest(
      'get',
      `itemgroups?filter=${this.state.currentFilter}&page=${this.state.currentPage}`+
      `&sort_by=${this.state.sortFilter}&order_by=${this.state.orderBy}&view=${this.state.viewPerPage}`+
      `&search=${this.state.search.replace('&', '%26')}`
    )
      .then((res) => {
        const baseCurrency = res.base_currency;
        sessionStorage.setItem('total_items', parseInt(res.items.data.length));
        let obj = {
          prevId: null,
          id: res.items.data[0] && res.items.data[0].id,
          nextId: res.items.data[1] && res.items.data[1].id,
          moduleName: 'item_groups',
          urlPath: window.location.pathname,
          queryParam: window.location.search,
        };
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS && dataFromLS.moduleName !== 'item_groups') {
          sessionStorage.setItem('fullscreen', true);
          sessionStorage.setItem('once', true);
          removeValueFromLS('module');
          if (res.items.data[0]) {
            setObjInLS('module', obj);
          }
        }
        if (!res.items.data[0]) {
          removeValueFromLS('module');
        }
        if (!dataFromLS || dataFromLS.action === 'add') {
          if (res.items.data[0]) {
            setObjInLS('module', obj);
          }
        } else if (dataFromLS.action === 'bulkdelete') {
          if (res.items.data[0]) {
            setObjInLS('module', { ...obj, lolo: 'no' });
          }
        } else if (
          dataFromLS &&
          dataFromLS.moduleName === 'item_groups' &&
          dataFromLS.queryParam !== window.location.search
        ) {
          if (res.items.data[0]) {
            setObjInLS('module', obj);
          }
        }
        if (!res.items?.data?.length) {
          sessionStorage.setItem('fullscreen', true);
          setObjInLS('module', obj);
        }

        this.setState(
          {
            data: this.prepareDataForTableCollapsed(res.items.data),
            dataExpanded: this.prepareDataForTable(res.items.data),
            totalPage: res.items.last_page,
            totalRecords: res.items.total,
            organizationCurrency: baseCurrency,
          },
          () => {
            this.setState({
              mainLoading: false,
            });
          }
        );
      })
      .catch((error) => {
        if (error?.response?.data?.status !== 'access_denied') {
          this.setState({
            accessDenied: checkError(error, this.props.handleToast),
          });
        } else {
          this.setState({ accessDenied: checkError(error) });
        }
      });
  }

  prepareDataForTable(data) {
    return data.map((item) => ({
      id: item.id,
      'Item Group': {
        id: 'name',
        sortable: true,
        getValue: () => (
          <ListTableImageColumn name={item.name} images={item.images} />
        ),
      },
      Manufacturer: {
        id: 'manufacturer',
        sortable: true,
        getValue: function () {
          return item[this.id];
        },
      },
      Brand: {
        id: 'brand',
        sortable: true,
        getValue: function () {
          return item[this.id];
        },
      },
      Unit: {
        id: 'unit',
        sortable: true,
        getValue: function () {
          return item[this.id];
        },
      },
      'Variants Count': {
        id: 'total_items',
        sortable: false,
        alignRight: true,
        getValue: function () {
          return item[this.id];
        },
      },
      // not displayable
      additional: {
        isActive: item.is_active,
      },
    }));
  }

  prepareDataForTableCollapsed(data) {
    return data.map((item) => ({
      id: item.id,
      'Item Group': {
        id: 'name',
        sortable: true,
        getValue: () => (
          <ListTableImageColumn name={item.name} images={item.images} />
        ),
      },
      'Total Variants': {
        id: 'total_items',
        sortable: false,
        alignRight: true,
        getValue: function () {
          return item[this.id];
        },
      },
      // not displayable
      additional: {
        isActive: item.is_active,
      },
    }));
  }

  // check status whether its active or not
  checkActiveStatus = (id) =>
    this.state.data.find((one) => {
      return one.id === id;
    }).additional.isActive;

  // render action menu for single selected data
  renderSingleActionMenu = () => {
    const id = this.state.bulkSelectedData[0];
    const isActive = this.checkActiveStatus(id);
    const [, hasEditPermission, hasDeletePermission] = this.hasPermission(
      'itemGroups',
      'All'
    );
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <ButtonLink
          enable={hasEditPermission}
          to={`/itemgroups/edit/${this.state.bulkSelectedData[0]}`}
          type="edit"
        />
        <Button
          enable={hasDeletePermission}
          handleClick={() =>
            this.openDeleteModal(this.state.bulkSelectedData[0])
          }
          type="delete"
        />
        <Button
          enable={hasEditPermission}
          // label={`Mark as ${isActive ? 'inactive' : 'active'}`}
          handleClick={() =>
            this.toggleActive(
              this.state.bulkSelectedData[0],
              !isActive ? 'active' : 'inactive'
            )
          }
          type={isActive ? 'inactive' : 'active'}
        />
        {/*<Button
          handleClick={()=> this.exportCurrentView()}
          type="export"
        />*/}
      </CheckedMenu>
    );
  };

  /*exportCurrentView = () =>{
    restRequest('post', `items/exportcurrentview`,{item_ids : this.state.bulkSelectedData})
      .then(res => {
        let csvFile = 'data:text/csv;charset=utf-8,' + res
        let encodedUri = encodeURI(csvFile)
        let link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', `items.csv`)
        document.body.appendChild(link)
        link.click()
        this.props.handleToast(`items exported successfully`, 'success')
        this.setState({ bulkSelectedData: [] })
      })
      .catch(error => {
        checkError(error, this.props.handleToast)
        //this.props.handleToast(error, 'error')
      })
  }*/

  populateConditionalOptions() {
    const options = [
      {
        label: 'Mark as Active',
        onClick: () => this.toggleAllActive('active'),
      },
      {
        label: 'Mark as Inactive',
        onClick: () => this.toggleAllActive('inactive'),
      },
      /*{
        label: 'Export Selected',
        onClick: () => this.exportCurrentView(),
        disabled: ()=> !this.hasViewPermission('item')
      }*/
    ];
    return options;
  }

  // render action menu for multiple selected data
  renderMultipleActionMenu = () => {
    const [, hasEditPermission, hasDeletePermission] = this.hasPermission(
      'itemGroups',
      'All'
    );
    const options = this.populateConditionalOptions();
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <Button
          enable={hasDeletePermission}
          handleClick={this.openBulkDeleteModal}
          type="delete"
        />
        <Options
          enable={hasEditPermission && !!options.length}
          options={options}
        />
      </CheckedMenu>
    );
  };
  hasCreatePermission() {
    const [hasCreatePermission] = this.hasPermission('itemGroups', 'All');
    return !hasCreatePermission;
  }
  hasEditPermission() {
    const hasEditPermission = this.hasPermission('itemGroups', 'Edit');
    return hasEditPermission;
  }
  hasDeltePermission() {
    const hasDeltePermission = this.hasPermission('itemGroups', 'Delete');
    return hasDeltePermission;
  }

  prepareRowOptions() {
    const rowOptions = [
      {
        label: 'Edit',
        type: this.hasEditPermission() && 'link',
        icon: EditIcon,
        onClick: (id) => `/item-groups/edit/${id}`,
        disabled: () => !this.hasEditPermission(),
      },
      /*{
        label: 'Clone',
        // type: 'link',
        icon: CloneIcon,
        onClick: id => this.props.history.push(`items/clone/${id}`),
        disabled : () => this.hasCreatePermission()
      },*/
      {
        label: 'Delete',
        icon: DeleteIcon,
        onClick: this.openDeleteModal,
        disabled: () => !this.hasDeltePermission(),
      },
      {
        label: 'Mark Inactive',
        icon: InactiveIcon,
        disabled: (id) =>
          !this.hasEditPermission() ? true : !this.checkActiveStatus(id),
        onClick: (id) => this.toggleActive(id, 'inactive'),
      },
      {
        label: 'Mark Active',
        icon: ActiveIcon,
        disabled: (id) =>
          !this.hasEditPermission() ? true : this.checkActiveStatus(id),
        onClick: (id) => this.toggleActive(id, 'active'),
      },
    ];

    return rowOptions;
  }

  renderTable() {
    const {
      data,
      dataExpanded,
      bulkSelectedData,
      sortFilter,
      orderBy,
      //loading,
      accessDenied,
      mainLoading,
    } = this.state;
    if (accessDenied) {
      return <AccessDenied type="section" />;
    }
    if (!mainLoading) {
      if (this.checkIfOnboarding()) {
        let obj = {
          prevId: null,
          id: null,
          nextId: null,
          moduleName: 'item_groups',
          urlPath: window.location.pathname,
          queryParam: window.location.search,
        };
        setObjInLS('module', obj);
        return (
          <Onboarding
            name="Item"
            FlowDiagram={ItemFlowDiagram}
            videoFrame={
              <iframe
                width="700"
                height="500"
                src="https://www.youtube.com/embed/nzA65-Au0Uc"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            }
            DisplayIcon={NewItemIcon}
            headerTitle="Item Groups"
            description="Create and manage your item groups"
            buttonLabel="Create Item Group"
            buttonLink="/item-groups/add"
            /*secondaryButtonLabel="Import Item(s)"
            secondaryButtonLink="/itemgroups/import"*/
          />
        );
      }
    }
    let fullscreen = getStringValueFromLS('fullscreen');
    return (
      <ListTable
        data={fullscreen === 'true' ? dataExpanded : data}
        className={`${
          fullscreen === 'true'
            ? ' expand-item-groups-table'
            : 'collapsed-item-groups-table '
        } item-listing-items`}
        selectedData={bulkSelectedData}
        rowOptions={this.prepareRowOptions()}
        linkTo="/item-groups"
        sortFilter={sortFilter}
        orderBy={orderBy}
        loading={mainLoading}
        isBlur="isActive"
        generateRoute={this.generateRoute}
        handleCheckAll={this.handleCheckAll}
        handleCheckSingle={this.handleCheckSingle}
        getID={this.getID}
        moduleName="item_groups"
        totalRecords={this.state.totalRecords}
      />
    );
  }

  renderSearchDetails() {
    const { search } = this.state;
    if (search) {
      return (
        <Alert
          className="full-width green"
          id="elasticSearch"
          title="Search Criteria"
          linkTo={routesPath.itemGroups}
        >
          <SearchResult
            criterias={['Name', 'Manufacturer', 'Brand']}
            searchKey={search.replace('%26', '&')}
          />
        </Alert>
      );
    }
    return null;
  }

  openExportModal() {
    this.setState({ showExportModal: true });
  }

  closeModal = () => {
    this.setState({ showExportModal: false });
  };

  getID(prevId, id, nextId) {
    let obj = {
      id,
      prevId: prevId ? prevId : null,
      nextId: nextId ? nextId : null,
      moduleName: 'item_groups',
      urlPath: window.location.pathname,
      queryParam: window.location.search,
    };
    setObjInLS('module', obj);
    this.setState({ itemId: id });
    // setValueInLS('id',id)
  }

  /*renderExportModal() {
    return (
      <CustomModal
        showModal={this.state.showExportModal}
        title="Export Items"
        Icon={ExportIcon}
        onClose={this.closeModal}
        renderActions={false}
      >
        <Export
          name="Items"
          onCancel={this.closeModal}
          submitURL="items/export"
          handleToast={this.props.handleToast}
        />
      </CustomModal>
    )
  }*/

  render() {
    return (
      <Fragment>
        {/*this.renderExportModal() */}
        {this.renderTable()}
      </Fragment>
    );
  }
}

export default HeaderWrapper(
  ItemGroups,
  {
    name: 'Item Groups',
    tableIcon: 'general_module_icon',
    Icon: NewItemIcon,
    baseUrl: 'itemgroups',
    deleteName: 'Item Group',
    buttonUrl: '/item-groups/add',
    buttonLabel: 'New Item Group',
    routeName: 'item-groups',
    showDetailsComp: true,
    bypassLoadingState: false,
    permissionName: 'itemGroups',
  },
  ItemGroupDetails,
  AddItemGroup,
  EditItemGroups
);
