import React from 'react';
import {
  MdLocalPhone,
  MdPhoneAndroid,
  MdOutlineMoreVert,
} from '../../../../../Common/Icons';
import Card from '../../../../../Components/Card/Card';
import Avatar from './../../../../../Components/Avatar/Avatar';
import CustomDropdown from './../../../../../Components/Dropdown/CustomDropdown';

import './ContactEmployee.css';

const ContactEmployees = ({
  contactEmployees,
  handleEdit,
  handleDelete,
  markAsPrimary,
}) => {
  function renderDropdownOptions(employee) {
    return [
      {
        label: 'Edit',
        onClick: () => handleEdit(employee),
      },
      {
        label: 'Delete',
        onClick: () => handleDelete(employee),
      },
      {
        label: 'Mark As Primary',
        onClick: () => markAsPrimary(employee.id),
      },
    ];
  }

  function renderCard(contactEmployee) {
    return (
      <div className="contact-employee-card" key={contactEmployee.id}>
        <Card>
          <CustomDropdown
            icon={<MdOutlineMoreVert />}
            options={renderDropdownOptions(contactEmployee)}
            id={contactEmployee.id}
            dotIcon
          />
          <Avatar>
            {contactEmployee.first_name && contactEmployee.first_name.charAt(0)}
          </Avatar>
          <div className="semi-bold">
            {`${contactEmployee.salutation} ${contactEmployee.first_name} ${contactEmployee.last_name}`}
          </div>
          <div>{contactEmployee.designation}</div>
          <div>{contactEmployee.department}</div>
          <div>{contactEmployee.email}</div>
          {contactEmployee.work_phone && (
            <div>
              <MdLocalPhone className="details-icon" />
              {contactEmployee.work_phone}
            </div>
          )}
          {contactEmployee.mobile && (
            <div>
              <MdPhoneAndroid className="details-icon" />
              {contactEmployee.mobile}
            </div>
          )}
        </Card>
      </div>
    );
  }

  return contactEmployees.map((contactEmployee) => renderCard(contactEmployee));
};

export default ContactEmployees;
