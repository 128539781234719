import React, { useState, useEffect, useRef } from 'react';
import { MenuType, ActionMenu, ScreenSize } from './';

const MobileView = ({ menusList = [] }) => {
  const {
    size: { width },
  } = ScreenSize();

  const outSideClickRef = useRef(null);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        outSideClickRef.current &&
        !outSideClickRef.current.contains(event.target)
      ) {
        setToggle(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [outSideClickRef]);

  const notInDropdown = menusList?.filter((menu) => !menu.isMobile);
  let inDropDownn = menusList?.filter((menu) => menu.isMobile);
  if (inDropDownn.length) {
    inDropDownn = inDropDownn.map((item) => {
      return {
        ...item,
        mobileDimension: true,
      };
    });
  }
  return (
    <div className="mobile-menus-dynamic">
      <div ref={outSideClickRef} className="mobile___menus">
        {toggle && (
          <div className="mobile-menu">
            <ul className="mobileListing">
              {inDropDownn?.map((menu, index) => (
                <li className="mobileList" key={index}>
                  <MenuType menu={menu} key={index} />
                </li>
              ))}
            </ul>
          </div>
        )}
        {width <= 1250 && (
          <>
            <button
              className={'buttonMobileIcon'}
              onClick={() => {
                setToggle(!toggle);
              }}
            >
              <i className="fas fa-bars ims-mob-bars fa-2x"></i>
            </button>
          </>
        )}
      </div>

      <ActionMenu>
        {notInDropdown.map((menu, index) => (
          <MenuType menu={menu} key={index} />
        ))}
      </ActionMenu>
    </div>
  );
};

export default MobileView;
