import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'

import { restRequest } from '../../../../../../Helpers/RequestHelper'
import getDate from '../../../../../../Helpers/Date/GetDate'

import Loader from '../../../../../../Components/Loader/Loader'
import HeaderWrapper from '../../../../../../Components/HeaderWrapper/HeaderWrapper'
import { Grid, GridLayout } from '../../../../../../Components/Layout/Layout'
import { DetailsHeader } from '../../../../../../Components/Details/Details'
import { Button, ActionMenu } from '../../../../../../Components/CheckedMenu/CheckedMenu'

import PDFViewer from '../../../../../../Components/PDFViewer/PDFViewer'
import Box from '../../../../../../Components/Layout/Box'
import NotFound from '../../../../../../Components/NotFound/NotFound'

import { BillIcon } from '../../../../../../Assets/Navigation/NavigationIcons'
import Table from '../../../../../../Components/Table/Table'
import { checkError } from "../../../../../../Helpers/AuthHelper";
// import '../Notes.css'

class BillPaymentDetails extends Component {
  id = this.props.match.params.id
  state = {
    billInfo: {},
    paymentInfo: {},
    activityLog: [],
    currency: {},
    detailsNo: '',
    pdf: null,
    showPDF: false,
    loading: true,
    notFound: false
  }

  componentDidMount() {
    document.title = 'Bill Payment Details'
    this.fetchData()
  }

  fetchData = async () => {
    this.setState({ loading: true })
    return restRequest('get', `billpaymentrecords/${this.id}`).then(res => {
      this.setState({
        billInfo: res.bill,
        paymentInfo: {
          ...this.state.paymentInfo,
          id: res.id,
          paymentDate: getDate(res.payment_date),
          paymentMade: res.payment_made,
          paymentMode: res.payment_mode,
          reference: res.reference,
          notes: res.note
        },
        currency: res.currency,
        detailsNo: `${res.bill.bill_no} / Payment No.${res.id}`,
        loading: false
      })
    }).catch(error => {
      checkError(error)
      this.setState({
        loading: false,
        notFound: true
      })
    })
  }

  openPdf(id) {
    this.setState({ showPDF: true })
    restRequest('get', `billpaymentrecords/${id}/pdf`).then(res => {
      this.setState({ pdf: res })
    }).catch(error => checkError(error))
  }

  closeAllModals = () => {
    this.setState({
      showPDF: false,
      pdf: null
    })
  }

  renderActionMenu() {
    const id = this.id
    return (
      <ActionMenu>
        <Button
          handleClick={() => this.openDeleteModal(id)}
          type="delete"
        />
        <Button
          handleClick={() => this.openPdf(id)}
          type="pdf"
        />
      </ActionMenu>
    )
  }

  renderPaymentInfo = () => {
    let { paymentInfo, billInfo, currency } = this.state
    return (
      <GridLayout className="layout-padding" grid="3-1">
        <div className="section-details--info">
          <div className="section-details--info-title semi-bold">Amount Paid</div>
          <div>{currency.symbol}{paymentInfo.paymentMade}</div>
          <div className="section-details--info-title semi-bold">Paid To</div>
          <Link to={`/contacts/${billInfo.vendor_id}`}>
            <div>{billInfo.vendor.display_name}</div>
          </Link>
        </div>
        <div className="section-details--info">
          <Fragment>
            <div className="section-details--info-title semi-bold">Payment Date</div>
            <div>{paymentInfo.paymentDate}</div>
          </Fragment>
          <Fragment>
            <div className="section-details--info-title semi-bold margin-top-sm">Payment Mode</div>
            <div>{paymentInfo.paymentMode}</div>
          </Fragment>
          <Fragment>
            <div className="section-details--info-title semi-bold margin-top-sm">Reference</div>
            <div>{paymentInfo.reference}</div>
          </Fragment>
        </div>
      </GridLayout>
    )
  }

  renderLayoutView() {
    const {
      billInfo,
      paymentInfo,
      currency,
      showPDF,
      pdf
    } = this.state

    return (
      <Fragment>
        <PDFViewer
          showPDF={showPDF}
          hidePDFModal={this.closeAllModals}
          pdf={pdf}
        />

        <DetailsHeader
          title="Payment"
          no={`Payment No. ${paymentInfo.id}`}
        />

        {this.renderPaymentInfo()}

        <Table
          list={[{
            billNo: <Link to={`/bills/${billInfo.id}`}>{billInfo.bill_no}</Link>,
            billDate: getDate(billInfo.bill_date),
            //billAmount: `${currency.symbol}${billInfo.total}`,
            //paidAmount: `${currency.symbol}${paymentInfo.paymentMade}`,
            billAmount: `${currency.symbol}${parseFloat(billInfo.total).toFixed(2)}`,
            paidAmount: `${currency.symbol}${parseFloat(paymentInfo.paymentMade).toFixed(2)}`
          }]}
          tableHeaders={['Bill No.', 'Date', 'Bill Amount', 'Payment Made']}
          tableData={['billNo', 'billDate', 'billAmount', 'paidAmount']}
          rightAlignColumns={[3, 4]}
          className="details-table"
        />

        <GridLayout className="layout-padding" grid="3-2">
          <div>
            <div className="semi-bold text-small margin-bottom-sm">Notes</div>
            <div>{paymentInfo.notes}</div>
          </div>
        </GridLayout>
      </Fragment>
    )
  }

  render() {
    if (this.state.loading) return <Loader />
    if (this.state.notFound) return <NotFound />
    return (
      <GridLayout>
        <Grid transparent>
          <Box className="no-padding">
            {this.renderLayoutView()}
          </Box>
        </Grid>
      </GridLayout>
    )
  }
}

export default HeaderWrapper(
  BillPaymentDetails,
  {
    name: 'Bills',
    subName: 'Payment',
    Icon: BillIcon,
    baseUrl: 'paymentrecords',
    redirectUrl: '/bills',
    onlyMenu: true,
    permissionName: 'bill'

  }
)
