import React, { Component } from 'react'

import { TaxIcon } from '../../Assets/Settings/SettingsIcon'

import DeliveryMethodForm from '../../Components/Form/DeliveryForm/DeliveryMethodForm'
import FormLayout from '../../Components/Layout/FormLayout'

class EditDeliveryMethod extends Component {
  componentDidMount() {
    document.title = 'Edit Delivery Method'
  }

  renderDeliveryMethodForm() {
    return (
      <DeliveryMethodForm
        title="Edit Delivery Method"
        inModal={this.props.inModal || false}
        type="edit"
        id={this.props.match.params.id}
        handleToast={this.props.handleToast}
      />
    )
  }

  render() {
    return (
      <FormLayout
        title="Delivery Method"
        Icon={TaxIcon}
        subtitle="Edit"
        closeUrl="/deliverymethods"
      >
        { this.renderDeliveryMethodForm() }
      </FormLayout>
    )
      
  }
}

export default EditDeliveryMethod