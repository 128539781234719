import React from 'react';
import { NewBillIcon } from '../../../../Assets/Navigation/NavigationIcons';
import { BillForm } from '../../../../Components/Form';
import { FormLayout } from '../../../../Components';

const EditBill = ({ match, handleToast, history, idFromHeaderWrapper }) => {
  const id = match && parseInt(match.params.id, 10);

  return (
    <FormLayout
      title=" Edit Bills"
      Icon={NewBillIcon}
      // subtitle="Edit"
      closeUrl="/bills"
      iconstyle="general_module_icon"
      layoutBoxClass="uiux-form-redesign"
    >
      <BillForm
        history={history}
        type="edit"
        billId={idFromHeaderWrapper ? idFromHeaderWrapper : id}
        idFromHeaderWrapper={idFromHeaderWrapper}
        onSubmit={() => history.push('/bills')}
        onClose={() => history.push('/bills')}
        handleToast={handleToast}
      />
    </FormLayout>
  );
};

export default EditBill;
