import React from 'react'
import { withFormik } from 'formik'
import * as Yup from 'yup'

import { restRequest } from '../../../Helpers/RequestHelper'

import { ValidateRequired, ValidateNumber, required, moreThanZero, ValidateMax50, ValidateMax4 } from '../../../Helpers/Validator'
import Field from '../Field/Field'
import Form from '../Form'
import FormSection from '../FormSection/FormSection'
import FormAction from '../FormAction'
import ErrorFocus from '../ErrorFocus'
import {checkError} from "../../../Helpers/AuthHelper";

const formEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: ValidateRequired,
    currencyCode: required(ValidateMax50),
    symbol: required(ValidateMax4),
    exchangeRate: required(moreThanZero(ValidateNumber))
  }),
  mapPropsToValues: ({ currency, currencyId }) => ({
    ...currency, currencyId
  }),
  handleSubmit: (values, bag) => {
    handleFormSubmit(values, bag)
  }
})

const CurrencyForm = ({
  values: {
    name,
    currencyId,
    currencyCode,
    symbol,
    exchangeRate
  },
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  setValues,
  submitCount,
  isSubmitting,
  errors,
  touched,
  history,
  type,
  currencyListFromDB
}) => {
  return (
    <Form className="currency-form" onSubmit={handleSubmit}>
      <FormSection title={type === 'add' ? 'New Currency': 'Edit Currency'}>
        {
          type === 'edit' ?
          <Field
            size="large"
            name="Currency"
            value={name}
            id="name"
            handleChange={handleChange}
            required
            disabled
          />:
          <Field
            size="large"
            name="Currency"
            id="currencyId"
            value={currencyId}
            options={currencyListFromDB}
            dropdownValue="id"
            dropdownLabel="name"
            handleChange={option => handleCurrencyChange(option, currencyListFromDB, setValues)}
            onBlur={() => setFieldTouched('currencyId', true)}
            type="dropdown"
            disabled={type === 'edit'}
            clearable={false}
            required
            error={touched.currencyId && errors.name}
          />
        }

        <Field
          size="large"
          name="Currency Code"
          value={currencyCode}
          id="currencyCode"
          required
          handleChange={handleChange}
          onBlur={handleBlur}
          disabled={type === 'edit'}
          error={touched.currencyCode && errors.currencyCode}
        />

        <Field
          size="large"
          name="Symbol"
          value={symbol}
          id="symbol"
          required
          handleChange={handleChange}
          onBlur={handleBlur}
          error={touched.symbol && errors.symbol}
        />

        <Field
          size="large"
          type="number"
          name="Exchange Rate"
          value={exchangeRate}
          id="exchangeRate"
          decimalScale={6}
          required
          onValueChange={value => setFieldValue('exchangeRate', value.floatValue)}
          onBlur={handleBlur}
          error={(touched.exchangeRate || submitCount > 0) && errors.exchangeRate}
        />

        <FormAction disabled={isSubmitting} onCancel={() => history.goBack()} />
      </FormSection>
      <ErrorFocus />
    </Form>
  )
}

const handleCurrencyChange = (option, currencyListFromDB, setValues) => {
  if (option) {
    let selectedCurrency = currencyListFromDB.find(currency =>
      currency.id === option.value
    )

    setValues({
      currencyId: option.value,
      name: selectedCurrency.name,
      currencyCode: selectedCurrency.currency_code,
      symbol: selectedCurrency.symbol,
      exchangeRate: null
    })
  }
}

const handleFormSubmit = (
  values,
  {
    setSubmitting,
    props: {
      requestType,
      requestUrl,
      history,
      successMessage,
      handleToast,
      type
    }
  }) => {
  const submitRequestData = () => {
    const {
      name,
      currencyCode,
      symbol,
      exchangeRate
    } = values

    switch (type) {
      case 'edit':
        return {
          symbol: symbol,
          exchange_rate: exchangeRate
        }
      case 'add':
      default:
        return {
          name: name,
          currency_code: currencyCode,
          symbol: symbol,
          exchange_rate: exchangeRate
        }
    }
  }

  restRequest(requestType, requestUrl, submitRequestData()).then(() => {
    successMessage()
    history.push('/currencies')
  }).catch(error => {
    checkError(error,handleToast)
    //handleToast(error, "error")
    setSubmitting(false)
  })
}

export default formEnhancer(CurrencyForm)