import React from 'react'


const NoDashboardDataFound = ({message}) => {
  return (
    <div className="empty-data">
      {message}
    </div>
  )
}

export default NoDashboardDataFound
