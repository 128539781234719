import React from 'react'

export default ({
  className = '',
  fill = ''
}) => (
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="17" height="17" 
viewBox="0 0 89.255 54.191">
  <path d="M-8.75-13.125V41.066H80.505V-13.125ZM4-6.75H67.754A6.161,6.161,0,0,0,69.6-2.217,6.161,6.161,0,0,0,74.13-.374V28.315a6.294,6.294,0,0,0-6.375,6.375H4a6.161,6.161,0,0,0-1.843-4.532,6.161,6.161,0,0,0-4.532-1.843V-.374A6.161,6.161,0,0,0,2.158-2.217,6.161,6.161,0,0,0,4-6.75ZM35.878-.374a11.416,11.416,0,0,0-6.425,1.942A13.606,13.606,0,0,0,24.82,6.8a15.429,15.429,0,0,0-1.693,7.172,15.429,15.429,0,0,0,1.693,7.172,13.606,13.606,0,0,0,4.632,5.23,11.6,11.6,0,0,0,12.85,0,13.607,13.607,0,0,0,4.632-5.23,15.429,15.429,0,0,0,1.693-7.172A15.429,15.429,0,0,0,46.935,6.8,13.607,13.607,0,0,0,42.3,1.568,11.416,11.416,0,0,0,35.878-.374Zm0,6.375A5.622,5.622,0,0,1,40.36,8.342a8.67,8.67,0,0,1,1.893,5.628A8.67,8.67,0,0,1,40.36,19.6a5.462,5.462,0,0,1-8.965,0A8.67,8.67,0,0,1,29.5,13.97a8.67,8.67,0,0,1,1.893-5.628A5.622,5.622,0,0,1,35.878,6ZM11.97,9.189A4.762,4.762,0,0,0,7.188,13.97a4.762,4.762,0,0,0,4.782,4.782,4.762,4.762,0,0,0,4.782-4.782A4.762,4.762,0,0,0,11.97,9.189Zm47.815,0A4.762,4.762,0,0,0,55,13.97a4.782,4.782,0,1,0,9.563,0,4.762,4.762,0,0,0-4.782-4.782Z" 
  transform="translate(8.75 13.125)" fill={fill}/>
</svg>
)
