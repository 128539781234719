import React from 'react'
import Loader from './Loader'

import './OverlayLoader.css'

export default ({opacity}) => (
  <div className="overlay-loader" style={{opacity : opacity? opacity : ''}}>
    <Loader />
  </div>
)
