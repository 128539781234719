import React, { Component } from 'react';
import HeaderWrapper from '../HeaderWrapper/HeaderWrapper';
import {
  restRequest,
  checkError,
  Quantity,
  UnSynOrderAddress,
  TotalBillSection,
  OrderInfo,
  ListTableImageColumn,
  Table,
  Loader,
  SalesOrderIcon,
} from '.';
import { TempItemMappedModal } from '../../Common';
import { MdClose } from '../../Common/Icons';

class UnSyncOrderDetail extends Component {
  state = {
    loading: true,
    items: [],
    currencySymbol: '',
    order: {},
    order_total: 0,
    SubTotal: 0,
    customer: {},
    billing: {},
    shipping: {},
    store: {},
    store_type: 'woocom',
    ims_items: [],
    detailsNo: '',
    customerName: '',
    showConfirmationModal: false,
    confirmationModalDataItem: {},
    confirmationModalLoading: false,
    confirmationModalWoocomList: [],
    confirmationModalBtnLoader: false,
    store_item: [],
  };

  fetchData = () => {
    const {
      history: {
        location: { state },
      },
    } = this.props;
    const id = this.props.match.params.id;
    restRequest('get', `${this.state.store_type}/un_sync_order/${id}`)
      .then((res) => {
        if (res?.Woocom_order_items.length === 0) {
          this.props.handleToast('Please sync item first', 'error');
          this.props.history.push({
            pathname: '/un-sync-orders',
            state: state,
          });
        }
        let Response = res?.data?.response;
        if (res?.Woocom_order_items.length < Response?.items?.length) {
          this.props.handleToast('Please sync item first', 'error');
        }
        this.setState({
          store: res?.store,
          store_type: res?.store?.slug === 'shopify' ? 'shopify' : 'woocom',
          ims_items: res?.ims_items,
          order_total: res?.data?.order_total,
          store_item: res?.Woocom_order_items,
          items:
            this.orderList(
              // Response?.items,
              res?.Woocom_order_items || [],
              res?.currency?.symbol,
              res?.ims_items,
              res?.data?.store_id,
              res?.data?.market_place_order_id
            ) || [],
          currencySymbol: res?.currency?.symbol || '',
          loading: false,
          order: Response?.order,
          customerName: Response?.customer?.first_name
            ? Response?.customer?.first_name +
              ' ' +
              Response?.customer?.last_name
            : Response?.billing?.first_name +
              ' ' +
              Response?.billing?.last_name,
          //detailsNo: Response?.order?.id,
          detailsNo: res?.store?.name || res?.store?.store_url,
          SubTotal: this.getSubTotal(res?.Woocom_order_items) || 0,
          customer: Response?.customer,
          billing: Response?.billing,
          shipping: Response?.shipping,
        });
      })
      .catch((error) => {
        checkError(error);
        this.props.handleToast(error, 'error');
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    const {
      history: {
        location: { pathname },
      },
    } = this.props;
    const slug = pathname?.split('/');
    const market_place = slug.find(
      (item) => item === 'woocom' || item === 'shopify'
    );
    this.setState(
      {
        store_type: market_place ? market_place : 'woocom',
      },
      () => {
        this.fetchData();
      }
    );
  }

  tableHeaders = ['Items', 'Quantity', 'Price', 'Amount'];
  tableData = ['name', 'quantity', 'unitPrice', 'total'];
  rightAlignColumns = [2, 3, 4];

  checkImsItem = (item, ims_items) => {
    let data = { name: item.item_name, sku: item.product_sku, matched: false };
    if (typeof ims_items.length !== 'undefined') {
      for (let i = 0; i < ims_items.length; i++) {
        if (
          ims_items[i].product_id === item.product_id &&
          ims_items[i].variation_id === item.variation_id
        ) {
          data = {
            name: ims_items[i].name,
            sku: ims_items[i].sku,
            matched: true,
          };
          break;
        }
      }
    }
    return data;
  };

  tempModalhandler = (itemData) => {
    this.setState(
      {
        confirmationModalLoading: true,
        showConfirmationModal: true,
        confirmationModalDataItem: itemData,
      },
      () => {
        restRequest(
          'get',
          `${this.state.store_type}/items/ims/${itemData.store_id}`
        )
          .then((res) => {
            if (res?.ims_items?.length) {
              let options = res?.ims_items.map((option, i) => {
                const stock_preference = res.stock_preference;
                let ims_stock;
                if (stock_preference === 'physical_stock') {
                  ims_stock = option.physical_stock;
                } else {
                  ims_stock = option.accounting_stock;
                }
                return {
                  ...option,
                  label: option.name,
                  value: option.id,
                  ims_stock: ims_stock,
                };
              });
              this.setState({ confirmationModalWoocomList: options }, () => {
                this.setState({ confirmationModalLoading: false });
              });
            } else {
              // this.props.handleToast('Ims items not found', 'error');
              // this.setState({ showConfirmationModal: false });
              this.setState({ confirmationModalLoading: false });
            }
          })
          .catch((error) => {
            this.props.handleToast(error, 'error');
            this.setState({ showConfirmationModal: false });
          });
      }
    );
  };

  orderList = (data, symbol, ims_items, store_id, market_place_order_id) =>
    data?.map((item) => {
      let {
        name = '',
        sku = '',
        matched = false,
      } = ims_items.length !== 0
        ? this.checkImsItem(item, ims_items)
        : { name: item.item_name, sku: item.product_sku, matched: false };
      return {
        name: matched ? (
          <ListTableImageColumn
            name={name}
            sku={sku}
            images={item.images}
            id={item.product_id}
            showModalUponClick={false}
            value={item.extra_description}
            showExtraDisableDescription={item.extra_description}
          />
        ) : (
          <span
            onClick={() => {
              const itemData = {
                id: item.product_id,
                name: item.item_name,
                sku: item.product_sku,
                store_id: store_id,
                // woocom_stock: item.stock_on_hand || 0,
                store_stock: item.stock_on_hand || 0,
                market_place_item_id: market_place_order_id,
                storeType: this.state.store_type,
              };
              this.tempModalhandler(itemData);
            }}
          >
            <ListTableImageColumn
              name={name}
              sku={sku}
              images={item.images}
              //showModalUponClick={!this.props.inModal}
              id={item.product_id}
              unSyncIcon={true}
              unSyncText={`This item is not mapped`}
            />
          </span>
        ),
        quantity: <Quantity quantity={item.quantity} unit={'piece'} />,
        unitPrice: symbol + parseFloat(item.product_price).toFixed(2),
        total: symbol + parseFloat(item.line_total).toFixed(2),
        rowColor: !matched,
      };
    });

  getSubTotal = (data) => {
    let subTotal = 0;
    data.forEach((element) => {
      subTotal = subTotal + parseInt(element.line_subtotal);
    });
    return subTotal;
  };

  closeConfirmationModalHandler = () => {
    this.setState({ showConfirmationModal: false });
  };

  btnClicksHandler = ({ id = null, type = '', ...rest }) => {
    const {
      history: {
        location: { state },
      },
    } = this.props;
    this.setState({ confirmationModalBtnLoader: true });
    let payload;
    const {
      store_id = null,
      mappedItemData = {},
      stockSelect = '',
      stockSelectValue = '',
      market_place_item_id = null,
      sync_item_name = '',
    } = rest;

    if (type === 'confirm') {
      payload = {
        store_id: store_id,
        items: [
          {
            inventory_type: 'inventory',
            item_id: 0,
            market_place_item_id: market_place_item_id,
            // rowIndex: 0,
            stock: stockSelectValue,
            stock_status: stockSelect,
            sync_item_id: id,
            sync_item_name: sync_item_name,
          },
        ],
      };
    } else if (type === 'exist') {
      payload = {
        store_id: store_id,
        items: [
          {
            inventory_type: mappedItemData.inventory_type,
            item_id: mappedItemData.id,
            market_place_item_id: market_place_item_id,
            // rowIndex: 0,
            stock: stockSelectValue,
            stock_status: stockSelect,
            sync_item_id: id,
            sync_item_name: sync_item_name,
          },
        ],
      };
    }
    //console.log(payload);
    //this.setState({ confirmationModalBtnLoader: false, loading: false,});
    //return false
    restRequest(
      'post',
      `${this.state.store_type}/items/associate_items`,
      payload
    )
      .then((res) => {
        this.props.handleToast(
          'Items Association successfully completed',
          'success'
        );
        this.setState(
          {
            showConfirmationModal: false,
            confirmationModalBtnLoader: false,
            loading: true,
          },
          () => {
            if (
              this.state.store_item?.length -
                (this.state.ims_items?.length + 1) >
              0
            ) {
              this.fetchData();
            } else {
              this.props.history.push({
                pathname: '/un-sync-orders',
                state: state,
              });
            }
          }
        );
      })
      .catch((error) => {
        this.setState({ confirmationModalBtnLoader: false });
        this.props.handleToast(error.message, 'error');
      });
  };

  rendercontent = () => {
    const {
      history: {
        location: { state },
      },
    } = this.props;
    const addressList = [
      {
        type: 'Shipping Address',
        userName:
          this.state.store_type === 'shopify'
            ? this.state.shipping?.first_name
            : this.state.shipping?.order_shipping_first_name,
        company:
          this.state.store_type === 'shopify'
            ? this.state.shipping?.company
            : this.state.shipping?.order_shipping_company,
        country:
          this.state.store_type === 'shopify'
            ? this.state.shipping?.country
            : this.state.shipping?.order_shipping_country,
        city:
          this.state.store_type === 'shopify'
            ? this.state.shipping?.city
            : this.state.shipping?.order_shipping_city,
        state:
          this.state.store_type === 'shopify'
            ? this.state.shipping?.state
            : this.state.shipping?.order_shipping_state,
        zipCode:
          this.state.store_type === 'shopify'
            ? this.state.shipping?.postcode
            : this.state.shipping?.order_shipping_postcode,
        address:
          this.state.store_type === 'shopify'
            ? this.state.shipping?.address_1
            : this.state.shipping?.order_shipping_address_1,
        phone: '',
      },
      {
        type: 'Billing Address',
        userName: this.state.billing?.first_name,
        company: this.state.billing?.company,
        country: this.state.billing?.country,
        city: this.state.billing?.city,
        state: this.state.billing?.state,
        zipCode: this.state.billing?.postcode,
        address: this.state.billing?.address_1,
        phone: this.state.billing?.phone,
      },
    ];

    return (
      <div className="float-left w-100 position-relative sales_order_set unsync_salesOrder">
        <div className="close_icon close-icon-pos">
          <MdClose
            onClick={() => {
              this.props.history.push({
                pathname: '/un-sync-orders',
                state: state,
              });
            }}
          />
        </div>
        <TempItemMappedModal
          itemData={this.state.confirmationModalDataItem}
          showModal={this.state.showConfirmationModal}
          closeModal={this.closeConfirmationModalHandler}
          confirmBtnClicks={this.btnClicksHandler}
          itemsList={this.state.confirmationModalWoocomList}
          loading={this.state.confirmationModalLoading}
          btnLoader={this.state.confirmationModalBtnLoader}
        />
        <div className="float-left w-100 overflow_custom_height">
          <div id="printSO" className="sale_Order_Details">
            <OrderInfo
              customerName={this.state.customerName}
              orderNo={
                this.state.order?.order_number
                  ? this.state.order?.order_number
                  : this.state.order?.id
              }
              dateCreated={this.state.order?.date_created}
              storeName={this.state.store?.name}
              storeUrl={this.state.store?.store_url}
              store={this.state.store}
            />
            <div className="float-left w-100 sales_address">
              {addressList?.map((address, index) => (
                <UnSynOrderAddress addressData={address} key={index} />
              ))}
            </div>
            <div
              className="salesordersummary"
              style={{ float: 'left', width: '100%' }}
            >
              <Table
                list={this.state.items}
                tableHeaders={this.tableHeaders}
                tableData={this.tableData}
                rightAlignColumns={this.rightAlignColumns}
                className="details-table"
                rowColor
              />
              <TotalBillSection
                symbol={this.state.currencySymbol}
                subTotal={this.state.SubTotal}
                discountTotal={this.state.order?.discount_total}
                shippingCharges={this.state.order?.shipping_total}
                totalTax={this.state.order?.total_tax}
                total={this.state.order?.total}
              />
            </div>
            <hr className="float-left w-100 mb-1 divider" />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { loading } = this.state;
    return <>{loading ? <Loader /> : this.rendercontent()}</>;
  }
}

export default HeaderWrapper(UnSyncOrderDetail, {
  name: 'Un sync Sales Orders',
  tableIcon: 'general_module_icon',
  deleteName: 'Sales Order',
  Icon: SalesOrderIcon,
  baseUrl: 'unsyncorder',
  redirectUrl: '/unsyncorder',
  onlyMenu: true,
  showName: true,
  permissionName: 'salesorder',
});
