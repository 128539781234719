import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { restRequest } from '../../Helpers/RequestHelper';
import { ValidateMax100, required } from '../../Helpers/Validator';

import Form from './../../Components/Form/Form';
import Field from './../../Components/Form/Field/Field';
import FormAction from '../../Components/Form/FormAction';
import ErrorFocus from '../../Components/Form/ErrorFocus';
//import classNames from 'classnames';
import './NewAccount.css';
import { checkError } from '../../Helpers/AuthHelper';
import { confirmationAlert } from '../../Helpers';

class NewAccount extends React.Component {
  state = {
    newAccountGroupName: '',
    newAccountGroupType: this.props.accountType || 'sales', // sales, purchase
    fieldchange: false,
  };

  componentWillUnmount() {
    window.onbeforeunload = null;
  }
  componentDidMount() {
    window.onbeforeunload = () => {
      return true;
    };
  }

  handleSubmit = (values, setSubmitting) => {
    restRequest('post', 'accountGroups', {
      account_group_name: values.newAccountGroupName,
      account_type:
        values.newAccountGroupType === 'sales' ? 'Sales' : 'Purchase',
    })
      .then((res) => {
        this.props.handleToast('New Account Group added', 'success');
        if (this.props.inModal) {
          this.props.onSubmit(res.data.id);
          this.props.close();
        }
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        setSubmitting(false);
      });
  };

  handleChangeName = (e) => {
    this.setState({ newAccountGroupName: e.target.value });
    if (e.target.value) this.props.handlePrompt();
    else this.props.handlePrompt(false);
  };

  handleChangeType = (e) => {
    this.setState({ fieldchange: e.target.checked });
    if (e.target.checked) this.props.handlePrompt();
    else this.props.handlePrompt(false);
  };

  renderNewAccountGroupForm() {
    const { newAccountGroupName, newAccountGroupType } = this.state;
    return (
      <Formik
        initialValues={{ newAccountGroupName, newAccountGroupType }}
        validationSchema={Yup.object().shape({
          newAccountGroupName: required(ValidateMax100),
        })}
        onSubmit={(values, { setSubmitting }) => {
          this.handleSubmit(values, setSubmitting);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          isSubmitting,
        }) => (
          <Form
            onSubmit={handleSubmit}
            className="new-account-form float-left w-100 "
          >
            <div className="float-left w-100 mb_3">
              <Field
                autoFocus={true}
                className="float-left w-auto"
                name="Group Name"
                size="large"
                value={values.newAccountGroupName}
                id="newAccountGroupName"
                required
                onBlur={handleBlur}
                handleChange={(e) => {
                  handleChange(e);
                  this.handleChangeName(e);
                }}
                error={
                  touched.newAccountGroupName && errors.newAccountGroupName
                }
              />
              <Field
                type="radio"
                name="Group Type: "
                className="fields_align float-left w-auto ml_3"
              >
                <div
                  className="radio-container inline"
                  onChange={(e) => {
                    handleChange(e);
                    this.handleChangeType(e);
                  }}
                >
                  <div className="radio-btn ml-5">
                    <input
                      type="radio"
                      name="newAccountGroupType"
                      id="sales"
                      value="sales"
                      defaultChecked={newAccountGroupType === 'sales'}
                      disabled={this.props.accountType === 'purchase'}
                    />
                    <label htmlFor="sales">Sales</label>
                    <span className="radio-circle" />
                  </div>

                  <div className="radio-btn">
                    <input
                      type="radio"
                      name="newAccountGroupType"
                      id="purchase"
                      value="purchase"
                      defaultChecked={newAccountGroupType === 'purchase'}
                      disabled={this.props.accountType === 'sales'}
                    />
                    <label htmlFor="purchase">Purchase</label>
                    <span className="radio-circle" />
                  </div>
                </div>
              </Field>
            </div>

            <div className="float-left w-100">
              <FormAction
                disabled={isSubmitting}
                onCancel={() => {
                  if (this.props.inModal) {
                    if (
                      this.state.newAccountGroupName ||
                      this.state.fieldchange
                    ) {
                      let response = confirmationAlert();
                      if (response) this.props.close();
                    } else this.props.close();
                  }
                }}
              />
            </div>
            <ErrorFocus />
          </Form>
        )}
      />
    );
  }

  render() {
    return this.renderNewAccountGroupForm();
  }
}

export default NewAccount;
