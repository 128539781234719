import React, { Component, Fragment } from 'react';

import { restRequest } from '../../../../Helpers/RequestHelper';
import getDate from '../../../../Helpers/Date/GetDate';

import { NewInvoiceIcon } from '../../../../Assets/Navigation/NavigationIcons';
import Table from '../../../../Components/Table/Table';
import Loader from '../../../../Components/Loader/Loader';
import OverlayLoader from '../../../../Components/Loader/OverlayLoader';
import PDFViewer from '../../../../Components/PDFViewer/PDFViewer';
import RecordPaymentForm from '../../../../Components/Form/RecordPaymentForm/RecordPaymentForm';
import CustomModal from '../../../../Components/CustomModal/CustomModal';
import { getObjFromLS, setObjInLS } from '../../../../Helpers/LocalStorage';
import InvoiceForm from './../../../../Components/Form/InvoiceForm/InvoiceForm';
import { NoItemsBox } from '../../../../Components/Layout/Box';
import moment from 'moment';
import { getOrganizationDate } from '../../../../Helpers/Date/OrganizationDate';
import EditIcon from '../../../../Assets/General/EditIcon';
import DeleteIcon from '../../../../Assets/General/DeleteIcon';
import RecordPaymentIcon from '../../../../Assets/General/RecordPaymentIcon';
import EmailIcon from '../../../../Assets/General/EmailIcon';
import PdfIcon from '../../../../Assets/General/PdfIcon';
import MarkAsSentIcon from '../../../../Assets/General/MarkAsSentIcon';
import { MdAdd } from '../../../../Common/Icons';

import InvoiceDetails from '../../Invoices/InvoiceDetails/index';
import { checkError } from '../../../../Helpers/AuthHelper';

export default class Invoices extends Component {
  state = {
    loading: true,
    invoiceList: [],
    showInvoiceCreateModal: false,
    showInvoiceViewModal: false,
    pdf: false,
    showPDF: false,
    showInvoiceEditModal: false,
    currentInvoiceID: null,
    invoiceID: null,
    showInvoiceDeleteModal: false,
    showRecordPaymentModal: false,
    showPrompt: false,
    processing: false,
  };

  tableRowOptions = [
    {
      label: 'Receive Payment',
      onClick: (id) => this.openRecordPaymentModal(id),
      icon: RecordPaymentIcon,
      disabled: (id) =>
        this.props.hasPermissionCustom('invoicepayment', 'Create') === false
          ? true
          : this.getCurrentInvoice(id).status === 'paid' ||
            this.getCurrentInvoice(id).status === 'void' ||
            this.getCurrentInvoice(id).total === 0,
    },
    {
      label: 'Edit',
      onClick: (id) => this.handleEditInvoice(id),
      icon: EditIcon,
      disabled: (id) =>
        this.props.sync_order
          ? true
          : this.props.hasPermissionCustom('invoice', 'Edit') === false
          ? true
          : !this.isInvoiceEditable(id),
    },
    {
      label: 'Print & PDF',
      icon: PdfIcon,
      onClick: (id) => this.openPdf(id),
    },
    {
      label: 'Email',
      icon: EmailIcon,
      onClick: (id) => {
        let dataFromLS = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLS,
          queryParam: `${
            dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
          }invoices=true`,
        });
        this.props.history.push(`/invoices/email/${id}`);
      },
    },
    {
      label: 'Delete',
      icon: DeleteIcon,
      onClick: (id) => this.openInvoiceDeleteModal(id),
      disabled: (id) =>
        this.props.sync_order
          ? true
          : this.checkInvoiceStatus('draft', id) &&
            this.props.hasPermissionCustom('invoice', 'Delete')
          ? false
          : true,
    },
    {
      label: 'Mark as Sent',
      onClick: (id, index) => this.markAsSent(id, index),
      icon: MarkAsSentIcon,
      disabled: (id) =>
        this.props.hasPermissionCustom('invoice', 'Edit') === false
          ? true
          : !this.checkInvoiceStatus('draft', id),
    } /*,
    {
      label: 'Mark as Void',
      onClick: (id, index) => this.markAsVoid(id, index),
      icon: MarkAsVoidIcon,
      disabled: id =>  this.props.hasPermissionCustom('invoice','Edit') === false ? true :   (!(this.checkInvoiceStatus('draft', id) || this.checkInvoiceStatus('sent', id)))
    }*/,
  ];
  // .filter((item) =>
  //   this.props.sync_order
  //     ? item.label === 'Edit' || item.label === 'Delete'
  //       ? undefined
  //       : item
  //     : item
  // );

  markAsSent = (id, index) => {
    this.setState({ processing: true });
    let updatedInvoiceList = [...this.state.invoiceList];
    let updatedInvoice = { ...updatedInvoiceList[index] };
    restRequest('put', `invoices/${id}/sent`)
      .then(() => {
        this.setState({ processing: false });
        updatedInvoice = {
          ...updatedInvoice,
          status: updatedInvoice.total === 0 ? 'paid' : 'sent',
          original_status: updatedInvoice.total === 0 ? 'paid' : 'sent',
        };
        // check if overdue
        let currentOrgTime = moment(getOrganizationDate());
        if (
          currentOrgTime.isAfter(updatedInvoice.due_date) &&
          updatedInvoice.status === 'sent'
        ) {
          updatedInvoice.status = 'overdue';
        }
        updatedInvoiceList[index] = updatedInvoice;
        this.props.handleToast('Invoice marked as sent.', 'success');
        this.setState({
          invoiceList: updatedInvoiceList,
        });
      })
      .catch((error) => {
        /*this.props.handleToast(error, "error")*/
        this.setState({ processing: false });
        checkError(error, this.props.handleToast);
      });
  };

  markAsVoid = (id, index) => {
    let updatedInvoiceList = [...this.state.invoiceList];
    let updatedInvoice = { ...updatedInvoiceList[index] };
    restRequest('put', `invoices/${id}/void`)
      .then(() => {
        updatedInvoice.status = 'void';
        updatedInvoice.original_status = 'void';
        updatedInvoice = {
          ...updatedInvoice,
          status: 'void',
          original_status: 'void',
        };
        updatedInvoiceList[index] = updatedInvoice;
        this.props.handleToast('Invoice marked as Void.', 'success');
        this.setState({
          invoiceList: updatedInvoiceList,
        });
      })
      .catch((error) =>
        /*this.props.handleToast(error, "error")*/ checkError(
          error,
          this.props.handleToast
        )
      );
  };

  checkInvoiceStatus = (status, id) => {
    return (
      this.state.invoiceList.find((one) => {
        return one.id === id;
      }).original_status === status
    );
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    return restRequest('get', `salesorders/${this.props.salesOrderID}/invoices`)
      .then((list) => {
        this.setState({
          invoiceList: list,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error);
        this.setState({
          loading: false,
        });
      });
  };

  handlePrompt = (status = true) => {
    this.setState({ showPrompt: status });
  };

  getCurrentInvoice = (id) => {
    return this.state.invoiceList.find((obj) => obj.id === id);
  };

  handleEditInvoice(id) {
    const currentInvoice = this.getCurrentInvoice(id);
    if (currentInvoice.status === 'void') {
      this.props.handleToast('You cannot edit void invoices.', 'error');
      return;
    }
    if (
      (currentInvoice.status === 'paid' && currentInvoice.total !== 0) ||
      currentInvoice.status === 'partially paid'
    ) {
      this.props.handleToast(
        'Invoices with receive payments cannot be edited.',
        'error'
      );
      return;
    }
    this.openInvoiceEditModal(id);
  }

  isInvoiceEditable(id) {
    const currentInvoice = this.getCurrentInvoice(id);
    return !(
      currentInvoice.status === 'void' ||
      currentInvoice.status === 'sent' ||
      currentInvoice.status === 'overdue' ||
      (currentInvoice.status === 'paid' && !currentInvoice.total !== 0) ||
      currentInvoice.status === 'partially paid'
    );
  }

  handleRecordPaymentSubmit = () => {
    this.handleInvoiceActionSubmit();
  };

  renderRecordPaymentModal() {
    return (
      <CustomModal
        fill="#000000"
        Icon={RecordPaymentIcon}
        showModal={this.state.showRecordPaymentModal}
        title="New Receive Payment"
        renderActions={false}
        onClose={this.closeAllModals}
        classNames="new-recieve-payment-popup"
        showPrompt={this.state.showPrompt}
      >
        <RecordPaymentForm
          type="invoice"
          id={this.state.currentInvoiceID}
          handleToast={this.props.handleToast}
          onSubmit={this.handleRecordPaymentSubmit}
          history={this.props.history}
          onClose={this.closeAllModals}
          showPrompt={this.state.showPrompt}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  openRecordPaymentModal = (id) => {
    this.setState({
      showRecordPaymentModal: true,
      currentInvoiceID: id,
    });
  };

  openPdf = (id) => {
    this.setState({ showPDF: true });
    restRequest('get', `invoices/${id}/pdf`)
      .then((res) => {
        this.setState({ pdf: res });
      })
      .catch((error) => checkError(error));
  };

  closeAllModals = () => {
    this.setState({
      showInvoiceCreateModal: false,
      showPDF: false,
      showInvoiceEditModal: false,
      showInvoiceDeleteModal: false,
      showInvoiceViewModal: false,
      showRecordPaymentModal: false,
    });
  };

  handleDeleteInvoice = () => {
    this.closeAllModals();
    restRequest('delete', `invoices/${this.state.currentInvoiceID}`)
      .then(() => {
        let dataFromLS = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLS,
          queryParam: `${
            dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
          }invoices=true`,
        });
        this.handleInvoiceActionSubmit();
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
      });
  };

  renderInvoiceDeleteModal() {
    return (
      <CustomModal
        type="delete"
        showModal={this.state.showInvoiceDeleteModal}
        title="Confirm Invoice Delete"
        onClose={this.closeAllModals}
        onSubmit={this.handleDeleteInvoice}
      >
        Are you sure you want to delete this invoice?
      </CustomModal>
    );
  }

  openInvoiceDeleteModal(id) {
    this.setState({
      showInvoiceDeleteModal: true,
      currentInvoiceID: id,
    });
  }

  renderInvoiceEditModal() {
    const { salesOrderID } = this.props;
    return (
      <CustomModal
        // classNames="create-invoice-popupset uiux-scroll"
        showModal={this.state.showInvoiceEditModal}
        title={'Edit Invoice | ' + this.props.saleOrderNo}
        renderActions={false}
        onClose={this.closeAllModals}
        size="large"
        showPrompt={this.state.showPrompt}
        className="create-invoice-popupset  so-invoice-popup"
        extraClass="salesOrderFormPopup"
        mainContainerClass="dragDropPopupContainer"
      >
        <InvoiceForm
          inModal
          type="edit"
          handleToast={this.props.handleToast}
          id={this.state.currentInvoiceID}
          close={this.closeAllModals}
          salesOrderId={salesOrderID}
          onSubmit={this.handleInvoiceActionSubmit}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  openInvoiceEditModal(id) {
    this.setState({
      showInvoiceEditModal: true,
      currentInvoiceID: id,
    });
  }

  handleInvoiceActionSubmit = () => {
    this.props.updateSalesOrderStatus();
    this.closeAllModals();
    this.fetchData();
  };

  renderInvoiceCreateModal() {
    const { handleToast, salesOrderID } = this.props;
    return (
      <CustomModal
        showModal={this.state.showInvoiceCreateModal}
        extraClass="invoices-large salesOrderFormPopup"
        Icon={NewInvoiceIcon}
        modaltitle="modal_invoice_title_align"
        title={'Create Invoice | ' + this.props.saleOrderNo}
        renderActions={false}
        onClose={this.closeAllModals}
        size="large"
        showPrompt={this.state.showPrompt}
        className="uiux-so-invoice-popup"
        mainContainerClass="dragDropPopupContainer"
      >
        <InvoiceForm
          inModal
          type="add"
          handleToast={handleToast}
          salesOrderId={salesOrderID}
          close={this.closeAllModals}
          onSubmit={this.handleInvoiceActionSubmit}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  createNewInvoice() {
    this.setState({ showInvoiceCreateModal: true });
  }

  openInvoiceViewModal = (id) => {
    this.setState({
      showInvoiceViewModal: true,
      invoiceID: id,
    });
  };

  prepareInvoiceList(invoices) {
    return invoices.map((invoice) => {
      return {
        ...invoice,
        dueDate: getDate(invoice.due_date),
        invoice_date: getDate(invoice.invoice_date),
        invoiceNo: this.props.inModal ? (
          invoice.invoice_no
        ) : (
          <div onClick={() => this.openInvoiceViewModal(invoice.id)}>
            {invoice.invoice_no}
          </div>
        ),
        // invoiceNo: <LinkStatusColumn to={`/invoices/${invoice.id}`} label={invoice.invoice_no} />,
      };
    });
  }

  viewInvoiceModal() {
    return (
      <CustomModal
        Icon={NewInvoiceIcon}
        showModal={this.state.showInvoiceViewModal}
        title="View Invoice"
        renderActions={false}
        size="large"
        onClose={this.closeAllModals}
        linkTo={`invoices/${this.state.invoiceID}`}
        classNames="so_view_invoices"
      >
        <InvoiceDetails id={this.state.invoiceID} inModal={true} />
      </CustomModal>
    );
  }

  isInvoiceCreatable(invoiceStatus, orderStatus) {
    if (
      invoiceStatus === 'invoiced' ||
      ['onhold', 'void'].includes(orderStatus)
    ) {
      return false;
    } else if (orderStatus === 'draft') {
      return false;
    }

    return true;
  }

  renderInvoicesListTable() {
    const { invoiceStatus, salesOrderStatus, label } = this.props;
    const tableListData = this.prepareInvoiceList(this.state.invoiceList);
    const tableHeaders = ['Invoice', 'Customer', 'Date', 'Due Date', 'Status'];
    const tableData = [
      'invoiceNo',
      'customer_name',
      'invoice_date',
      'dueDate',
      'status',
      this.props.inModal ? '' : 'options',
    ];
    return (
      <Fragment>
        <Table
          list={tableListData}
          tableHeaders={tableHeaders}
          tableData={tableData}
          options={this.props.inModal ? [] : this.tableRowOptions}
          className={`box-table ${label ? label + '_menu' : ''}`}
          fromTab={true}
          inModal={this.props.inModal}
        />
        {this.props.hasPermissionCustom('invoice', 'Create') &&
          this.isInvoiceCreatable(invoiceStatus, salesOrderStatus) &&
          (this.props.inModal ? (
            ''
          ) : (
            <button
              className="layout-box--button btn-width"
              onClick={() => this.createNewInvoice()}
            >
              <MdAdd className=" black-fill" />
              {/* Add Invoice */}
              Create Invoice
            </button>
          ))}
      </Fragment>
    );
  }

  renderModals() {
    return (
      <Fragment>
        <PDFViewer
          showPDF={this.state.showPDF}
          hidePDFModal={this.closeAllModals}
          pdf={this.state.pdf}
        />
        {this.renderRecordPaymentModal()}
        {this.renderInvoiceCreateModal()}
        {this.renderInvoiceEditModal()}
        {this.renderInvoiceDeleteModal()}
        {this.viewInvoiceModal()}
      </Fragment>
    );
  }

  renderInvoicesList() {
    const { invoiceStatus, salesOrderStatus } = this.props;
    return (
      <Fragment>
        {this.renderModals()}
        {!this.state.invoiceList.length ? (
          <NoItemsBox
            subtitle="No Invoices created yet."
            buttonText="Create Invoice"
            showButton={
              this.props.hasPermissionCustom('invoice', 'Create') &&
              this.isInvoiceCreatable(invoiceStatus, salesOrderStatus)
            }
            handleclick={() => this.createNewInvoice()}
            inModal={this.props.inModal}
            Icon={NewInvoiceIcon} //icon styling remaining
            btnDisabled={this.props.sync_order ? true : false}
          />
        ) : (
          this.renderInvoicesListTable()
        )}
      </Fragment>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return (
      <>
        {this.state.processing && <OverlayLoader />}
        {this.renderInvoicesList()}
      </>
    );
  }
}
