export default function getCurrentMenu(lc) {
  let splittedLc = lc.slice(1).split('&')
  let res = splittedLc.find(
    (item) =>
      item.includes('invoices=true') ||
      item.includes('packages=true') ||
      item.includes('salesReturn=true') ||
      item.includes('payments=true') ||
      item.includes('creditnotes=true') ||
      item.includes('returnReceive=true') ||
      item.includes('receives=true') ||
      item.includes('bills=true') ||
      item.includes('appliedOnInvoices') ||
      item.includes('creditRefunds')
  )
  return res ? res.split('=')[0] : ''
}
