import React from "react"

import Field from "../../../Components/Form/Field/Field"

const SinglePrefixField = ({disabled = false, ...props}) => {
  function getError(val){
    const re = /^[a-zA-Z0-9_]+$/;
    return !re.test(val);
  }
  return (
    <label className="list-table--checkbox-lable">
      <div className="sale-return-preference--label-text">{props.label}</div>
      <Field
        size="xs"
        // type='text'
        value={props.value}
        disabled={disabled }
        className="single_prefix_field"
        maxLength={3}
        required
        handleChange={(value) => props.handleChange(value.target.value)}
        error={props.loading===false  && (getError(props.value) && "No Special Characters")}
      />
    </label>
  )
}

export default SinglePrefixField
