import React, { Component } from 'react';
import queryString from 'query-string';
import RecordPaymentIcon from '../../Assets/General/RecordPaymentIcon';
import InvoiceFlowDiagram from '../../Components/Onboarding/Diagrams/InvoiceFlowDiagram';
import { restRequest } from '../../Helpers/RequestHelper';
import AccessDenied from '../../Components/AccessDenied/AccessDenied';
import HeaderWrapper from '../../Components/HeaderWrapper/HeaderWrapper';
import ListTable from '../../Components/ListTable/ListTable';
import CheckedMenu, { Button } from '../../Components/CheckedMenu/CheckedMenu';
import {
  getObjFromLS,
  setObjInLS,
  getStringValueFromLS,
  removeValueFromLS,
} from '../../Helpers/LocalStorage';

import EditIcon from '../../Assets/General/EditIcon';
import DeleteIcon from '../../Assets/General/DeleteIcon';
import AddPaymentsReceived from './AddPaymentReceived';

import PaymentsDetails from './PaymentsDetails';
import Onboarding from '../../Components/Onboarding/InitialOnboardingScreen';
//import EmptyTable from '../../Components/ListTable/EmptyTable';
import '../Contact/Contact.css';
import { checkError } from '../../Helpers';
//import GenerateOptions from '../../Helpers/ListHelper/GenerateOptions';
import { routesPath } from '../Navigation/NavigationUtils';

import Alert from '../../Components/Alert/Alert';
import SearchResult from '../../Components/SearchBar/SearchResult';

class CustomersPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentId: null,
      data: [],
      dataExpanded: [],
      staticRes: [],
      currentFilter: 'all',
      sortFilter: 'created_at',
      orderBy: 'desc',
      totalPage: 1,
      totalRecords: 0,
      currentPage: 1,
      viewPerPage: 20,
      search: '',
      showExportModal: false,
      accessDenied: false,
      loading: true,
      start: '',
      end: '',
      contactId: '',
    };
    this.getID = this.getID.bind(this);
  }

  filterOptions = [
    {
      id: 'none',
      label: 'Status',
      type: 'tagline',
    },
    {
      id: 'all',
      label: 'All',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'all', page: 1 }),
    },
    {
      id: 'advance',
      label: 'Advance',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'advance', page: 1 }),
    },
    {
      id: 'invoice',
      label: 'Direct',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'invoice', page: 1 }),
    },
  ];

  sortOptions = [
    {
      id: 'payment_date',
      label: 'Date',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('payment_date') }),
    },
    {
      id: 'payment_no',
      label: 'Payment No',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('payment_no') }),
    },
    {
      id: 'display_name',
      label: 'Customer',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('display_name') }),
    },
    {
      id: 'reference',
      label: 'Reference',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('reference') }),
    },
    {
      id: 'payment_made',
      label: 'Payment Made',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('payment_made') }),
    },
    {
      id: 'unused_amount',
      label: 'Unused Amount',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('unused_amount') }),
    },
  ];
  hasCreatePermission() {
    const hasCreatePermission = this.hasPermission('invoicepayment', 'Create');
    return !hasCreatePermission;
  }

  componentDidMount() {
    document.title = 'Customers Payment';
    this.fetchDataWithQueryString();
  }

  componentDidUpdate() {
    this.handleOnUpdateQueryString();
  }

  handleOnUpdateQueryString() {
    const {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
      contactId = '',
    } = this.parseQueryString();
    if (filter !== this.state.currentFilter) {
      this.setState(
        {
          currentFilter: filter,
        },
        () => this.fetchData()
      );
    }
    if (sort !== this.state.sortFilter) {
      this.setState(
        {
          sortFilter: sort,
        },
        () => this.fetchData()
      );
    }
    if (orderBy !== this.state.orderBy) {
      this.setState(
        {
          orderBy: orderBy,
        },
        () => this.fetchData()
      );
    }
    if (page !== this.state.currentPage) {
      this.setState(
        {
          currentPage: parseInt(page),
          bulkSelectedData: [],
        },
        () => this.fetchData()
      );
    }
    if (view !== this.state.viewPerPage) {
      this.setState(
        {
          viewPerPage: parseInt(view),
          bulkSelectedData: [],
        },
        () => this.fetchData()
      );
    }
    if (search !== this.state.search) {
      this.setState({ search: search, bulkSelectedData: [] }, () =>
        this.fetchData()
      );
    }
    if (contactId !== this.state.contactId) {
      this.setState(
        {
          contactId: contactId,
        },
        () => this.fetchData()
      );
    }
  }

  fetchDataWithQueryString() {
    const {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
      start,
      end,
      contactId = 179,
    } = this.parseQueryString();
    this.setState(
      {
        sortFilter: sort,
        currentFilter: filter,
        currentPage: parseInt(page),
        orderBy: orderBy,
        viewPerPage: parseInt(view),
        search: search,
        start: start,
        end: end,
        contactId: contactId,
      },
      () => this.fetchData()
    );
  }

  parseQueryString() {
    const query = queryString.parse(this.props.location.search);
    let filter = '';
    switch (query.filter) {
      case 'all':
      case 'advance':
      case 'invoice':
        filter = query.filter;
        break;
      default:
        filter = 'all';
    }

    let sort = '';
    switch (query.sort) {
      case 'payment_date':
      case 'display_name':
      case 'payment_no':
      case 'reference':
      case 'payment_mode':
      case 'payment_made':
      case 'unused_amount':
        sort = query.sort;
        break;
      default:
        sort = 'payment_no';
    }

    let orderBy = '';
    switch (query.orderBy) {
      case 'asc':
      case 'desc':
        orderBy = query.orderBy;
        break;
      default:
        orderBy = 'desc';
    }

    let page = parseInt(query.page);
    if (!page) page = 1;
    let view = parseInt(query.view);
    if (!view) view = 20;

    let search = query.search;
    if (!search) search = '';

    let start = query.start;
    if (!start) start = '';

    let end = query.end;
    if (!end) end = '';

    let contactId = query.contactId;
    if (!contactId) contactId = '';

    return {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
      start,
      end,
      contactId,
    };
  }

  fetchData = async (value = '') => {
    this.setState({ loading: true });

    const {
      currentFilter,
      currentPage,
      sortFilter,
      orderBy,
      viewPerPage,
      search,
      contactId,
    } = this.state;
    var url = `invoicepaymentrecords?filter=${currentFilter}&page=${currentPage}&sort_by=${sortFilter}&order_by=${orderBy}&view=${viewPerPage}&search=${search}`;
    if (contactId) {
      url = `${url}&contactId=${contactId}`;
    }
    await restRequest('get', url)
      .then((res) => {
        let obj = {
          prevId: null,
          id:
            typeof res.data[0] !== 'undefined' && res.data[0].payment_no
              ? res.data[0].payment_no
              : null,
          nextId:
            typeof res.data[1] !== 'undefined' && res.data[1].payment_no
              ? res.data[1].payment_no
              : null,
          moduleName: 'advancepayment',
          urlPath: window.location.pathname,
          queryParam: window.location.search,
        };
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS && dataFromLS.moduleName !== 'advancepayment') {
          sessionStorage.setItem('fullscreen', true);
          // sessionStorage.setItem('fullscreenFlag', true);
          sessionStorage.setItem('once', true);
          removeValueFromLS('module');
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
        }
        if (dataFromLS && !dataFromLS.id) {
          setObjInLS('module', {
            ...dataFromLS,
            id: res.data[0] && parseInt(res.data[0].payment_no),
            nextId: res.data[1] && parseInt(res.data[1].payment_no),
          });
        }
        if (!res.data[0]) {
          removeValueFromLS('module');
        }
        if (!dataFromLS || dataFromLS.action === 'add') {
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
        } else if (dataFromLS.action === 'bulkdelete') {
          if (res.data[0]) {
            setObjInLS('module', { ...obj, lolo: 'no' });
          }
        } else if (
          dataFromLS &&
          dataFromLS.moduleName === 'advancepayment' &&
          dataFromLS.queryParam !== window.location.search
        ) {
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
        }
        if (!res.data?.length) {
          let obj = {
            moduleName: 'advancepayment',
            urlPath: window.location.pathname,
            queryParam: window.location.search,
          };
          setObjInLS('module', obj);
          sessionStorage.setItem('fullscreen', true);
        }
        if (this.state.contactId) {
          this.setState({
            contactName: res.data[0]?.display_name,
          });
        }
        this.setState(
          {
            staticRes: res?.data,
            data: this.prepareDataForTableCollapsed(res.data),
            dataExpanded: this.prepareDataForTableExpanded(res.data),
            totalRecords: res.total,
            totalPage: res.last_page,
            loading: false,
          },
          () => {
            this.setState({ mainLoading: false });
          }
        );
      })
      .catch((error) => {
        this.setState({ loading: false });
        checkError(error);
        this.props.handleToast(error, 'error');
      });
  };

  prepareDataForTableCollapsed(data) {
    return data.map((CPayment) => ({
      id: +CPayment.payment_no,
      Date: {
        id: 'payment_date',
        sortable: true,
        getValue: () => {
          return CPayment.payment_date;
        },
      },
      Customer: {
        id: 'display_name',
        sortable: true,
        getValue: function () {
          return CPayment.display_name;
        },
      },
      Balance: {
        id: 'unused_amount',
        sortable: true,
        alignRight: true,
        getValue: function () {
          return `${CPayment.currency.symbol}${parseFloat(
            CPayment.unused_amount
          ).toFixed(2)}`;
        },
      },
      // not displayable
      additional: {
        isActive: true,
      },
    }));
  }

  prepareDataForTableExpanded(data) {
    return data.map((CPayment) => ({
      id: +CPayment.payment_no,
      Date: {
        id: 'payment_date',
        sortable: true,
        getValue: () => {
          return CPayment.payment_date;
        },
      },
      'Payment #': {
        id: 'payment_no',
        sortable: true,

        getValue: function () {
          return CPayment.payment_no;
        },
      },
      Customer: {
        id: 'display_name',
        sortable: true,
        getValue: function () {
          return CPayment.display_name;
        },
      },
      Invoice: {
        id: 'invoice_no',
        getValue: function () {
          return CPayment.invoice_no;
        },
      },
      Reference: {
        id: 'reference',
        sortable: true,
        getValue: function () {
          return CPayment.reference;
        },
      },
      Amount: {
        id: 'payment_made',
        sortable: true,
        alignRight: true,
        getValue: function () {
          return `${CPayment.currency.symbol}${parseFloat(
            CPayment.payment_made
          ).toFixed(2)}`;
        },
      },
      Balance: {
        id: 'unused_amount',
        sortable: true,
        alignRight: true,
        getValue: function () {
          return `${CPayment.currency.symbol}${parseFloat(
            CPayment.unused_amount
          ).toFixed(2)}`;
        },
      },
      // not displayable
      additional: {
        isActive: true,
      },
    }));
  }

  getID(prevId, id, nextId) {
    let obj = {
      id,
      prevId: prevId ? prevId : null,
      nextId: nextId ? nextId : null,
      moduleName: 'advancepayment',
      urlPath: window.location.pathname,
      queryParam: window.location.search,
    };
    setObjInLS('module', obj);
    this.setState({ paymentId: id });
  }

  // render action menu for single selected data
  renderSingleActionMenu = () => {
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <span>Checkbox select</span>
      </CheckedMenu>
    );
  };

  // render action menu for multiple selected data
  renderMultipleActionMenu = () => {
    const hasDeletePermission = this.hasPermission('invoicepayment', 'Delete');

    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <Button
          enable={hasDeletePermission}
          handleClick={this.openBulkDeleteModal}
          type="delete"
        />
      </CheckedMenu>
    );
  };

  hasEditPermission() {
    const hasEditPermission = this.hasPermission('invoicepayment', 'Edit');
    return hasEditPermission;
  }
  // hasDeltePermission() {
  //   const hasDeltePermission = this.hasPermission('invoicepayment', 'Delete');
  //   return hasDeltePermission;
  // }
  checkDisabledHandler(payment_no) {
    return this.state.staticRes.some(
      (pay) =>
        parseInt(pay.payment_no) === payment_no &&
        pay.payment_type === 'advance'
    );
  }

  prepareRowOptions() {
    const rowOptions = [
      {
        label: 'Edit',
        icon: EditIcon,
        onClick: (id) => this.props.history.push(`/paymentsmade/edit/${id}`),
        disabled: (id) =>
          !this.checkDisabledHandler(id) ||
          !this.hasPermission('invoicepayment', 'Edit'),
      },
      /*{
        label: 'Email',
        // type: 'link',
        icon: EmailIcon,
        // onClick: id => `/invoices/email/${id}`,
        onClick: (id) => this.props.history.push(`/invoices/email/${id}`),
        disabled: () => this.isOrderCreateable(),
      },
      */
      {
        label: 'Delete',
        icon: DeleteIcon,
        onClick: (id) => this.openDeleteModal(id),
        disabled: (id) => !this.hasPermission('invoicepayment', 'Delete'),
      },
    ];

    // const permissions = this.hasPermission('invoicepayment', 'All');

    //  const allowedRowOptions = GenerateOptions(permissions, rowOptions);
    // console.log({allowedRowOptions});
    return rowOptions;
  }

  renderTable() {
    const {
      data,
      dataExpanded,
      bulkSelectedData,
      sortFilter,
      orderBy,
      //loading,
      accessDenied,
      mainLoading,
    } = this.state;

    if (accessDenied) {
      return <AccessDenied type="section" />;
    }
    if (!mainLoading) {
      if (this.checkIfOnboarding()) {
        return (
          <Onboarding
            name="Payments Received"
            FlowDiagram={InvoiceFlowDiagram}
            videoFrame={
              <iframe
                width="700"
                height="500"
                src="https://www.youtube.com/embed/VanjBMN14IE"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            }
            DisplayIcon={RecordPaymentIcon}
            headerTitle="Payments Received"
            description="Create and manage your customer payments here"
            buttonLabel="Create Customer Payment"
            buttonLink="/paymentsmade/add"
          />
        );
      }
    }
    let fullscreen = getStringValueFromLS('fullscreen');
    let fullscreenFlag = getStringValueFromLS('fullscreenFlag');
    // if (fullscreenFlag === 'true') {
    //   sessionStorage.setItem('fullscreen', false);
    //   sessionStorage.setItem('fullscreenFlag', false);
    // }
    return (
      <ListTable
        data={fullscreen === 'true' ? dataExpanded : data}
        selectedData={bulkSelectedData}
        rowOptions={this.prepareRowOptions()}
        linkTo="/paymentsmade"
        sortFilter={sortFilter}
        orderBy={orderBy}
        loading={mainLoading}
        isBlur="isActive"
        generateRoute={this.generateRoute}
        handleCheckAll={this.handleCheckAll}
        handleCheckSingle={this.handleCheckSingle}
        getID={this.getID}
        fetchData={this.fetchData}
        moduleName="advancepayment"
        history={this.props.history}
        totalRecords={this.state.totalRecords}
        className={
          fullscreen === 'true'
            ? 'customer-payment-listing'
            : 'received-payment'
        }
      />
    );
  }

  renderSearchDetails() {
    const { search, contactId, contactName } = this.state;
    if (search) {
      return (
        <Alert
          className="full-width green"
          id="elasticSearch"
          title="Search Criteria"
          linkTo={routesPath.customerPayment}
        >
          <SearchResult
            criterias={[
              'Payment',
              'Customer Name',
              'Invoice No.',
              'Reference',
              'Amount',
            ]}
            searchKey={search.replace('%26', '&')}
          />
        </Alert>
      );
    }
    if (contactId) {
      return (
        <Alert
          className="full-width green"
          id="elasticSearch"
          title="Payments Received"
          linkTo={routesPath.customerPayment}
          crossIcon={false}
        // onClickHandler={this.onClickHandler}
        >
          Displaying all results from contact "{contactName}"
        </Alert>
      );
    }
    return null;
  }

  render() {
    return <>{this.renderTable()}</>;
  }
}

export default HeaderWrapper(
  CustomersPayment,
  {
    name: 'Payments Received',
    deleteName: 'Payment Received',
    Icon: RecordPaymentIcon,
    tableIcon: 'contact_general_module_icon contact_top',
    baseUrl: 'advancepayment',
    buttonUrl: '/paymentsmade/add',
    buttonLabel: 'new paymentsmade',
    routeName: 'paymentsmade',
    showDetailsComp: true,
    bypassLoadingState: false,
    permissionName: 'invoicepayment',
    pagination: true,
    showRecordsInfo: true,
  },
  PaymentsDetails,
  AddPaymentsReceived
  // EditContact
);
