import React, { Fragment } from 'react';
import Dropzone from 'react-dropzone';
import styles from './FileUploader.module.css';
import { FaCamera, FaFile } from '../../Common/Icons';

const FileUploader = ({
  accept,
  onDrop,
  multiple = false,
  maxSize = 5 * 1000 * 1000,
  title,
  className,
  camIcon = false,
  customLabel,
  circular,
}) => {
  const inputBody = () => {
    if (customLabel) return customLabel;
    if (circular) return camIcon === true ? <FaCamera /> : <FaFile />;
    return (
      <Fragment>
        {/*<img src={`${process.env.REACT_APP_AMAZON_S3}/media/image-placeholder.svg`} alt="icon" className="margin" />*/}
        {/* <FaCamera />
        <FaFile /> */}
        {camIcon === true ? <FaCamera /> : <FaFile />}
        <div className="semi-bold">Drag and Drop</div>
        <div>or</div>
        <div className="link semi-bold">Browse</div>
      </Fragment>
    );
  };

  return (
    <Dropzone
      onDrop={(accepted, rejected) => onDrop(accepted, rejected)}
      multiple={multiple}
      accept={accept}
      maxSize={maxSize}
      title={title}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()}
          className={`${styles.fileUploader} ${className} ${
            circular && styles.fileUploader_circle
          }`}
        >
          <input {...getInputProps()} />
          {inputBody()}
        </div>
      )}
    </Dropzone>
  );
};

export default FileUploader;
