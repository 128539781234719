import React from 'react';
import { Redirect } from 'react-router-dom';
import { getObjFromLS } from './LocalStorage';

export default function Hes() {
  let dataFromLS = getObjFromLS('module');
  if (dataFromLS && !dataFromLS.urlPath) {
    dataFromLS.urlPath = '';
  }
  if (dataFromLS && !dataFromLS.queryParam) {
    dataFromLS.queryParam = '';
  }
  return (
    <div>
      <Redirect
        to={`${dataFromLS && dataFromLS.urlPath}${
          dataFromLS && dataFromLS.queryParam
        }`}
      />
    </div>
  );
}
