import React, { Fragment, useState } from 'react';
import CustomModal from '../../../../Components/CustomModal/CustomModal';
import { NewSalesOrderIcon } from '../../../../Assets/Navigation/NavigationIcons';
import getDate from '../../../../Helpers/Date/GetDate';
import SalesOrderDetails from '../../../../Pages/SalesOrder/SalesOrderDetails/index';
import { formatNumber } from '../../../../Helpers/FormatNumber';

const PackageInfo = ({
  info: {
    packageDate,
    salesOrderNo,
    salesOrderID,
    // customerName,
    // customerID,
    returnReason,
    backToStock,
    // totalQuantity = 0,
    totalItems = 0,
  },
  inModal = false,
}) => {
  const [showSOModal, setShowSOModal] = useState(false);

  function viewSaleOrderModal() {
    return (
      <CustomModal
        showModal={showSOModal}
        title="View Sales Order"
        className='popup-height'
        renderActions={false}
        size="large"
        Icon={NewSalesOrderIcon}
        linkTo={`salesorders/${salesOrderID}`}
        onClose={() => setShowSOModal(false)}
      >
        <SalesOrderDetails id={salesOrderID} inModal={true} />
      </CustomModal>
    );
  }
  return (
    <div className="section-details--info mtb">
      {viewSaleOrderModal()}
      <Fragment>
        <div className="fields_data">
          <div className="section-details--info-title semi-bold title">
            Sales Order No.
          </div>
          {inModal ? (
            <div>{salesOrderNo}</div>
          ) : (
            <div
              onClick={() => setShowSOModal(true)}
              className="float-right colorBlue pointer"
            >
              {salesOrderNo}{' '}
            </div>
          )}
        </div>
      </Fragment>
      <hr />
      <div className="fields_data">
        <Fragment>
          <div className="section-details--info-title semi-bold title">
            Return Date
          </div>
          <div className="float-left w-50 text-right">
            {getDate(packageDate)}
          </div>
        </Fragment>
      </div>
      <hr />
      <div className="fields_data">
        <Fragment>
          <div className="section-details--info-title semi-bold margin-top-sm title">
            Total Return Items
          </div>
          <div className="semi-bold-amount float-left w-50 text-right">
            {formatNumber(totalItems)} Item{totalItems > 1 ? 's' : ''}
          </div>
        </Fragment>
      </div>
      <hr />
      <div className="fields_data">
        <Fragment>
          <div className="section-details--info-title semi-bold title">
            Items added back to stock
          </div>
          <div className="semi-bold-amount float-left w-50 text-right">
            {backToStock}
          </div>
        </Fragment>
      </div>

      <hr />
      <Fragment>
        <div className="section-details--info-title semi-bold margin-top-sm title">
          Return Reason
        </div>
        <div className="semi-bold-amount float-left w-100 word-break--break-all">
          {returnReason}
        </div>
      </Fragment>
    </div>
  );
};

export default PackageInfo;
