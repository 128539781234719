import React from 'react'

import { imagePathS3 } from '../../Helpers/RequestHelper'

import './Avatar.css'

const Avatar = (
  {
    image = '',
    alt = false,
    children
  }) => {
  if (!!image) {
    return (
      <img src={imagePathS3(image)} className='avatar' alt={alt ? alt : 'presentation'} />
    )
  }
  return (
    <div className="avatar">
      {children}
    </div>
  )
}

export default Avatar
