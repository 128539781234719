import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {
  MdOutlineMoreVert,
  SiWordpress,
  SiShopify,
  // SiAmazon,
} from '../../Common/Icons';
import { Checked, Unchecked, SemiChecked } from '../../Assets/List/ListIcons';
import getStatusColor from '../../Helpers/GetStatusColor';
import {
  getStringValueFromLS,
  getObjFromLS,
  setValueInLS,
  setObjInLS,
} from '../../Helpers/LocalStorage';
import Dropdown from '../Dropdown/CustomDropdown';
import AlphabetSortIcon from '../../../src/Assets/Mixed/AlphabetSortIcon';
import OverlayLoader from '../Loader/OverlayLoader';
import EmptyTable from './EmptyTable';
import moment from 'moment';
import './ListTable.css';
import getDateFormat from '../../Helpers/Date/GetDateFormat';
import getModuleName from '../../Helpers/getModuleName';
import useMobileDetection from '../../Hooks/MobileDetect';
import { ToolTip } from '../../Common';
import Contact from '../../Pages/Contact/Contact';
import InfiniteScroll from 'react-infinite-scroll-component';
// import Loader from '../ContenLoader/index'
// import Loader from '../../Components/ContenLoader';
import Loader from '../../Components/Loader/Loader';
const headers = (data) => {
  if (data[0]) {
    return Object.keys(data[0]).filter(
      (value) => value !== 'id' && value !== 'additional'
    );
  }

  return [];
};

const renderStatus = (status) => (
  <span className={getStatusColor(status)}>{status}</span>
);

const renderData = (array, key) => {
  if (key === 'additional') return;
  const value = array[key].getValue();
  if (array[key].alignRight) return <div className="text-right">{value}</div>;
  else if (['Customer', 'Item', 'Item Group', 'Name'].includes(key)) {
    const val = array[key]?.market_place || [];
    let wocom = false;
    let shopify = false;
    let wocom_toolTipData = null;
    let shopify_toolTipData = null;
    const wocom_stores = [];
    const shopify_stores = [];
    val.forEach(function (v) {
      if (v.market_place === 'woo-commerce') {
        wocom = true;
        wocom_stores.push(v?.store?.name || v?.store?.store_url)
        wocom_toolTipData = wocom_stores?.map((store) => <li> {store} </li>)
      }
      if (v.market_place === 'shopify') {
        shopify = true;
        shopify_stores.push(v?.store?.name || v?.store?.store_url)
        shopify_toolTipData = shopify_stores?.map((store) => <li> {store} </li>)

      }
    });
    return (
      <div>
        <span>{value}</span>
        <span style={{ display: 'flex' }}>
          {wocom &&
            <ToolTip content={wocom_toolTipData}>
              {<SiWordpress size={20} />}
            </ToolTip>
          }
          {shopify &&
            <ToolTip content={shopify_toolTipData}>
              {<SiShopify size={20} color="#95BE46" />}
            </ToolTip>
          }
        </span>
      </div>
    );
  }
  return value;
};

const spreadValues = (arrayElement, tableHeaders, hasCheckbox) => {
  const values = [];
  for (let key in arrayElement) {
    if (key === 'id') continue;
    if (!hasCheckbox) {
      values.push(renderData(arrayElement, key));
      continue;
    }
    if (tableHeaders.includes(key)) {
      if (
        ['Status', 'status'].includes(key) &&
        !arrayElement[key].hideStatusColor
      ) {
        values.push(renderStatus(arrayElement[key].getValue()));
        continue;
      } else if (
        [
          'Date',
          'Order Date',
          'Bill Due Date',
          'Created Date',
          'date',
          'Package Date',
          'Delivery Date',
          'Due Date',
          'Invoice Date',
          'Return Date',
          'Credit Note Date',
        ].includes(key)
      ) {
        //values.push(moment(arrayElement[key].getValue()).format("MMM DD, YY"))
        values.push(
          moment(arrayElement[key].getValue()).format(getDateFormat())
        );
        continue;
      }
      values.push(renderData(arrayElement, key));
    }
  }
  return values;
};

const renderCheckboxIcon = (selectedDataLength, totalLength) => {
  if (selectedDataLength !== 0) {
    if (totalLength === selectedDataLength) return <Checked />;
    return <SemiChecked />;
  }

  return <Unchecked />;
};

const renderHeader = (
  header,
  filters,
  sortFilter,
  orderBy,
  generateRoute,
  isHeaderRightAlign,
  additionalClass
) => {
  const flatten = [].concat(...filters);
  const index = flatten.indexOf(header);
  if (header === 'Status') {
    additionalClass = 'status';
  } else {
    additionalClass = null;
  }
  if (index !== -1)
    return (
      <th
        key={header}
        className={`${additionalClass !== null ? additionalClass : ''} ${sortFilter === flatten[index + 1] ? 'sorted-background' : ''
          } ${isHeaderRightAlign ? 'text-right' : ''}`}
      >
        <Link
          className="list-table--sort-header"
          to={generateRoute({
            sort: flatten[index + 1],
            orderBy: orderBy === 'asc' ? 'desc' : 'asc',
          })}
        >
          <span
            className={classNames(
              'pointer',
              sortFilter === flatten[index + 1]
                ? orderBy === 'asc'
                  ? 'ascending'
                  : 'descending'
                : null
            )}
          >
            {header}
          </span>
        </Link>
      </th>
    );
  return (
    <th
      key={header}
      className={`${additionalClass !== null ? additionalClass : ''} ${isHeaderRightAlign ? 'text-right' : ''
        }`}
    >
      {header}
    </th>
  );
};

const isHeaderRightAlign = (header, data) => {
  if (data[0]) {
    return !!data[0][header].alignRight;
  }

  return false;
};

const prepareSortFilterMapper = (data) => {
  const filterMapper = [];
  if (data[0])
    for (let key in data[0]) {
      if (data[0][key]?.sortable) {
        filterMapper.push([key, data[0][key].id]);
      }
    }
  return filterMapper;
};

const constrenderAlphabets = () => {
  let result = [];
  for (var i = 'A'.charCodeAt(0); i <= 'Z'.charCodeAt(0); i++) {
    result.push(String.fromCharCode(i));
  }
  result[27] = '#';
  return result;
};
const ListTable = ({
  data = [],
  selectedData = [],
  rowOptions = [],
  linkTo = false,
  sortFilter = '',
  orderBy = 'desc',
  // preference = false,
  // preferenceLink = '',
  hasCheckbox = true,
  fromDashboard = false,
  // showSettingsIcon,
  isBlur = false,
  fromWareHouse = false,
  className = '',
  divClassName_rs = '',
  getID = () => { },
  loading = true,
  generateRoute = () => { },
  handleCheckAll = () => { },
  handleCheckSingle = () => { },
  moduleName = '',
  showAlphabet = null,
  fetchData = () => null,
  additionalClass,
  totalRecords,
  tableClass,
  totalPage = null,
  fetchDataWithQueryString = () => null,
  mypage = 1,
  next_page_url = null,
  contactProp,
  history = () => null,
  sync = false,
  mapping = false,
  tableRowColor = false,
  resetPagination = () => null,
  // isPageDefault = () => null,
}) => {
  const [hasMobile = false] = useMobileDetection();
  const tableHeaders = headers(data);
  const selectedDataLength = selectedData.length;
  const dataLength = data.length;
  const filters = prepareSortFilterMapper(data);
  let characters = constrenderAlphabets();
  const hasData = !!data.length;
  const selectedAlphabet = localStorage.getItem("selectedChar")

  rowOptions =
    rowOptions.length && hasMobile
      ? rowOptions.filter((item) => item.label !== 'View PDF')
      : rowOptions;

  function onlyOnce() {
    let dataFromLS = getObjFromLS('module');
    let fullscreen = getStringValueFromLS('fullscreen');
    if (fullscreen === 'true') {
      data.forEach((singleRowItem) =>
        singleRowItem.hasOwnProperty('additional')
          ? delete singleRowItem.additional.selectedItemRow
          : ''
      );
    } else if (dataFromLS && moduleName === dataFromLS.moduleName) {
      data.forEach((item) => {
        if (item.id === dataFromLS.id) {
          item.additional['selectedItemRow'] = true;
        }
      });
    } else {
      let ss = data.some(
        (singleRowItem) =>
          singleRowItem.additional &&
          singleRowItem.additional.selectedItemRow === true
      );
      if (!ss && data[0] && data[0].additional) {
        data[0].additional['selectedItemRow'] = true;
      }
    }
  }
  function getSectionId(data) {
    let result =
      data &&
      data.Name &&
      data.Name.getValue() &&
      data.Name.getValue().props &&
      data.Name.getValue().props.name &&
      data.Name.getValue().props.name.charAt(0).toUpperCase();
    result =
      parseInt(result) || (result && !result.match(/[a-z]/i)) ? '#' : result;
    return result;
  }

  const [selectdIndex, setSelectedindex] = useState();
  useEffect(() => {
    if (showAlphabet) {
      let selectedElem = document.getElementsByClassName('operatedElem');
      if (selectedElem[0]) selectedElem[0].scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectdIndex]);

  useEffect(() => {
    let selectedItemIndex = data.findIndex(
      (element) => element?.additional?.selectedItemRow === true
    );
    selectedItemIndex !== -1 && setSelectedindex(selectedItemIndex);
  }, [data]);

  useEffect(() => {
    if (!showAlphabet) {
      let result = document.getElementsByClassName('selected');
      if (result[0])
        result[0].scrollIntoView({ block: 'center', inline: 'center' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function moveToHoveredCharacter(char, elem) {
    // <Contact myword ={char}/>
    // fetchData(char);
    // let arr = [...elem],
    //   moveToElem;
    // let contFlag = arr.find((a) => char === a);
    // if (!contFlag) return;
    resetPagination({ page: 1, char: char });
    localStorage.setItem("selectedChar", char)
    // fetchData(char);
    // let index = arr.indexOf(char);
    // if (!index) moveToElem = 'thead';
    // else moveToElem = '#' + arr[index - 1];
    // var nodes = document.querySelectorAll(`${moveToElem}`);
    // var last = nodes[nodes.length - 1];

    // last.scrollIntoView();
  }
  function renderEmptyTable() {
    let dataFromLS = getObjFromLS('module');
    setObjInLS('module', {
      ...dataFromLS,
      moduleName: moduleName,
      queryParam: window.location.search,
      action: null,
    });
    sessionStorage.setItem('fullscreen', true);
    return <EmptyTable />;
  }

  function RenderTable() {
    return (
      <table
        onLoad={onlyOnce()}
        className={`lopi list-table  ${linkTo ? 'linked-row' : ''} ${!hasData ? 'hidden' : ''
          } ${className}  ${fullscreen === 'true' ? '' : 'compressed-list-main'}`}
      >
        <thead className={`${fullscreen !== 'true' ? 'table_header_fix' : ''}`}>
          <tr>
            {hasCheckbox && (
              <th className="list-table--checkbox">
                <label className="list-table--checkbox-lable pointer">
                  <input
                    checked={selectedDataLength}
                    type="checkbox"
                    onChange={handleCheckAll}
                  />
                  {renderCheckboxIcon(selectedDataLength, dataLength)}
                </label>
              </th>
            )}
            {tableHeaders.map((header) =>
              renderHeader(
                header,
                filters,
                sortFilter,
                orderBy,
                generateRoute,
                isHeaderRightAlign(header, data),
                additionalClass
              )
            )}
            {/* Removed Settings tyre icon in new-2  because it has no use and added alphabet icon for contacts section */}
            <th className="list-table--settings">
              {showAlphabet && (
                <span
                  className="all-contacts-icon"
                  onClick={() => {
                    resetPagination({ page: 1, char: '' });
                    localStorage.removeItem("selectedChar")
                    sessionStorage.setItem('fullscreen', true);
                  }}
                >
                  <AlphabetSortIcon />
                </span>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((dataSingle, dataSingleIndex) => (
            <Fragment key={dataSingle.id}>
              <tr
                key={dataSingle.id}
                id={getSectionId(dataSingle)}
                style={{ marginTop: '1rem' }}
                className={`table-row ${dataSingle.hasOwnProperty('additional') &&
                  dataSingle.additional.selectedItemRow === true
                  ? 'selected'
                  : ''
                  } ${selectedData.includes(dataSingle.id) ? 'selected' : ''} ${!mapping && isBlur && !dataSingle.additional[isBlur]
                    ? 'inactive'
                    : 'active'
                  } ${dataSingleIndex % 2 === 1 ? 'grey-background' : ''}
                ${!mapping &&
                    data[dataSingleIndex + 1] &&
                    data[dataSingleIndex + 1].additional &&
                    data[dataSingleIndex + 1].additional.selectedItemRow
                    ? 'operatedElem'
                    : ''
                  }
                ${tableRowColor === true &&
                    dataSingle?.additional?.colorRow === true
                    ? 'unsyncOrders'
                    : ''
                  }
                `}
              >
                {hasCheckbox && (
                  <td>
                    <label className="list-table--checkbox-lable pointer">
                      <input
                        checked={selectedData.includes(dataSingle.id)}
                        type="checkbox"
                        onChange={handleCheckSingle}
                        name={dataSingle.id}
                      />
                      <span>
                        <Checked />
                      </span>
                    </label>
                    {dataSingleIndex !== 0 &&
                      getSectionId(dataSingle) !==
                      getSectionId(data[dataSingleIndex - 1]) ? (
                      <div className="border_contact"></div>
                    ) : (
                      <Fragment />
                    )}
                  </td>
                )}
                {spreadValues(dataSingle, tableHeaders, hasCheckbox).map(
                  (value, index) => (
                    <td
                      key={index}
                      onClick={() => {
                        if (data[0].additional) {
                          !fromWareHouse && setValueInLS('fullscreen', 'false');
                          getID(
                            data[dataSingleIndex - 1] &&
                            data[dataSingleIndex - 1].id,
                            dataSingle.id,
                            data[dataSingleIndex + 1] &&
                            data[dataSingleIndex + 1].id
                          );
                          data.forEach(
                            (singleRowItem) =>
                              delete singleRowItem.additional.selectedItemRow
                          );
                          dataSingle.additional['selectedItemRow'] = true;
                        }
                      }}
                    >
                      {linkTo ? (
                        <div className="list-table--link">
                          <span className="list_items_desc">{value}</span>
                        </div>
                      ) : (
                        value
                      )}
                      {dataSingleIndex !== 0 &&
                        getSectionId(dataSingle) !==
                        getSectionId(data[dataSingleIndex - 1]) ? (
                        <div className="border_contact"></div>
                      ) : (
                        <Fragment />
                      )}
                    </td>
                  )
                )}
                <td
                  style={{
                    textAlign: `${showAlphabet ? 'left' : 'right'}`,
                  }}
                  className={`list-table--options ${fullscreen !== 'true' && showAlphabet
                    ? 'collapsed_table'
                    : ''
                    }`}
                >
                  {
                    // sync hisotry options for Resync button removed
                    !mapping ? (
                      <>
                        <Dropdown
                          icon={<MdOutlineMoreVert />}
                          options={rowOptions}
                          id={dataSingle.id}
                        />
                        {dataSingleIndex !== 0 &&
                          getSectionId(dataSingle) !==
                          getSectionId(data[dataSingleIndex - 1]) ? (
                          <div className="border_contact"></div>
                        ) : (
                          <Fragment />
                        )}
                      </>
                    ) : (
                      rowOptions
                    )
                  }
                </td>
              </tr>
            </Fragment>
          ))}

          {showAlphabet && (
            <tr className="alphabatical_listing"
            >
              <td>
                {characters.map((char, index) => (
                  <a
                    key={index}
                    onClick={
                      () => moveToHoveredCharacter(char, set)
                    }
                    className={selectedAlphabet == char ? `hoverstyle` : ""}
                  >
                    {char}
                  </a>
                ))}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  let set = new Set();
  data.map((d) => set.add(getSectionId(d)));
  let dataFromLS = getObjFromLS('module');
  let fullscreen = getStringValueFromLS('fullscreen');
  let divClassName =
    className.indexOf('unset_heights') !== -1 ? 'unset_heights' : '';

  return (
    <div
      className={`list-table--wrapper ${divClassName_rs} ${divClassName} ${fromDashboard ? 'custom_height_dashboard' : ''
        }`}
    >
      {loading && <OverlayLoader />}
      {!hasData &&
        !loading &&
        (dataFromLS ? dataFromLS.action !== 'delete' : true) &&
        (dataFromLS &&
          dataFromLS.moduleName === 'creditnotes' &&
          dataFromLS.id >= 0
          ? renderEmptyTable()
          : true) &&
        renderEmptyTable()}
      {dataFromLS.moduleName === 'contacts' ? (
        <InfiniteScroll
          className="contact-infinite-scroll"
          dataLength={data.length}
          next={mypage === 1 ? fetchDataWithQueryString : fetchData}
          hasMore={next_page_url ? true : false}
          loader={<Loader />}
          height={moduleName === 'contacts' ? 768 : 'unset'}
          style={{ overflow: moduleName === 'contacts' ? 'auto' : 'unset' }}
        >
          {RenderTable()}
        </InfiniteScroll>
      ) : (
        RenderTable()
      )}
      {/*disabling the total count row */}
      {totalRecords && totalRecords > 0 ? (
        <div
          className={`total_count2 ${moduleName === 'contacts' ? 'contacts_count' : ''
            }`}
        >
          {`Total ${getModuleName(moduleName)} : ${totalRecords ? totalRecords : 0
            }`}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ListTable;
