import React, { Fragment } from 'react';

import './Form.css';

const Form = ({ type, title = '', onSubmit, children, className = '' }) => {
  return (
    <form
      className={`form ${className}`}
      onSubmit={onSubmit}
      autoComplete="off"
    >
      {type !== 'modal' && title && (
        <Fragment>
          <div className={title.length !== 0 ? 'form-title semi-bold' : ''}>
            {title}
          </div>
          <hr className="form-section--header--hr" />
        </Fragment>
      )}
      {children}
    </form>
  );
};

export default Form;
