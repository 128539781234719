import React from 'react';
import { Link } from 'react-router-dom';
import { MdClose as CloseIcon, MdInfoOutline } from '../../Common/Icons';
import './Alert.css';

export default ({
  title = '',
  className = '',
  id = '',
  // autoFocus = false,
  // ref = '',
  children,
  linkTo = '',
  onClickHandler,
  crossIcon = true,
}) => (
  <div className={`alert ${className}`} id={`${id}`} tabIndex="-1">
    <div className="alert--title">
      <span className="d-flex align-center">
        <MdInfoOutline className="alert--search-icon" />
        <span>{title}</span>
      </span>
      {linkTo ? (
        <Link to={linkTo}>
          {title !== 'Search Criteria' && crossIcon ? (
            <CloseIcon />
          ) : (
            <u>Clear Search</u>
          )}
        </Link>
      ) : (
        <button className="button" onClick={onClickHandler}>
          {title !== 'Search Criteria' ? <CloseIcon /> : <u>Clear Search</u>}
        </button>
      )}
    </div>
    <div className="alert--body">{children}</div>
  </div>
);
