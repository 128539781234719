import React from 'react'

export default ({
  className = ''
}) => (
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19.815 19.815">
  <path d="M13.125-15a3.322,3.322,0,0,0-1.279.254,3.366,3.366,0,0,0-1.1.742L4.883-8.145a4.664,4.664,0,0,0-1.24,2.139,4.741,4.741,0,0,0,0,2.451,4.662,4.662,0,0,0,1.24,2.148A4.662,4.662,0,0,0,7.031-.166a4.741,4.741,0,0,0,2.451,0,4.664,4.664,0,0,0,2.139-1.24l3.906-3.906-.879-.879L10.742-2.285a3.43,3.43,0,0,1-1.592.918,3.54,3.54,0,0,1-1.807,0,3.432,3.432,0,0,1-1.582-.918,3.432,3.432,0,0,1-.918-1.582,3.54,3.54,0,0,1,0-1.807,3.43,3.43,0,0,1,.918-1.592l5.859-5.859a2.1,2.1,0,0,1,.957-.557,2.115,2.115,0,0,1,1.1,0,2.1,2.1,0,0,1,.967.557,2.1,2.1,0,0,1,.557.967,2.115,2.115,0,0,1,0,1.1,2.1,2.1,0,0,1-.557.957L8.789-4.238A.739.739,0,0,1,8.252-4a.739.739,0,0,1-.537-.234.739.739,0,0,1-.234-.537.739.739,0,0,1,.234-.537l5.371-5.371-.879-.879L6.836-6.191A1.929,1.929,0,0,0,6.25-4.775a1.929,1.929,0,0,0,.586,1.416,1.929,1.929,0,0,0,1.416.586,1.929,1.929,0,0,0,1.416-.586l5.859-5.859a3.259,3.259,0,0,0,.869-1.523,3.457,3.457,0,0,0,0-1.748A3.26,3.26,0,0,0,15.527-14a3.339,3.339,0,0,0-1.113-.742A3.4,3.4,0,0,0,13.125-15Z"
        transform="translate(8.145 22.28) rotate(-45)"/>
</svg>
)
