import React, { Component } from 'react';

import { NewContactIcon } from '../../../Assets/Navigation/NavigationIcons';
import { restRequest } from '../../../Helpers/RequestHelper';

import HeaderWrapper from '../../../Components/HeaderWrapper/HeaderWrapper';
import { ActionMenu } from '../../../Components/CheckedMenu/CheckedMenu';
import Loader from '../../../Components/Loader/Loader';
import ContactInfo from './Info/ContactInfo';
import ContactAdditionalInfo from './Info/ContactAdditionalInfo';
import TransactionSummary from './Info/TransactionSummary';
import NotFound from '../../../Components/NotFound/NotFound';
import Box from '../../../Components/Layout/Box';
import ContactDetailsOutterTabs from './Tabs/ContactDetailsOutterTabs';
import { checkError } from '../../../Helpers/AuthHelper';
import { getObjFromLS, setObjInLS } from '../../../Helpers/LocalStorage';
import { HeaderMenus } from '../../../Components';

class ContactDetails extends Component {
  id = this.props.id
    ? this.props.id
    : this.props.idFromHeaderWrapper
    ? this.props.idFromHeaderWrapper
    : this.props?.match?.params?.id;

  state = {
    contactInfo: {},
    transactionSummary: {},
    detailsNo: '',
    currency: {},
    loading: true,
    notFound: true,
  };

  componentDidMount() {
    let dataFromLS = getObjFromLS('module');
    const { moduleName } = dataFromLS;
    if (!this.props.inModal) {
      document.title = 'Contact Details';
    }
    if (
      this.props.inModal ||
      moduleName === 'contacts' ||
      this.props?.match?.params?.id
    )
      this.fetchData();
  }

  fetchData = async () => {
    this.setState({
      loading: true,
      notFound: true,
    });
    restRequest('get', `contacts/${this.id}`)
      .then((res) => {
        this.setState({
          contactInfo: res,
          detailsNo: res.display_name,
          currency: res.currency,
          transactionSummary: res.transaction_summary,
          loading: false,
          notFound: false,
        });
        let dataFromLS = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLS,
          presistDetailPage: false,
        });
      })
      .catch((error) => {
        if (error?.response?.data?.message !== 'Contact ID not found.') {
          checkError(error, this.props.handleToast);
        }
        this.setState({
          loading: false,
          notFound: true,
        });
      });
  };
  optionsListForVendor = [
    {
      label: 'Bill',
      onClick: (id) =>
        this.props.history.push(
          `/bills/add?contactId=${this.state.contactInfo.id}`
        ),
    },
    {
      label: 'Purchase Order',
      onClick: (id) =>
        this.props.history.push(
          `/purchaseorders/add?contactId=${this.state.contactInfo.id}`
        ),
    },
  ];

  optionsListForCustomer = [
    {
      label: 'Invoice',
      onClick: (id) =>
        this.props.history.push(
          `/invoices/add?contactId=${this.state.contactInfo.id}`
        ),
    },
    {
      label: 'Sales Order',
      onClick: (id) =>
        this.props.history.push(
          `/salesorders/add?contactId=${this.state.contactInfo.id}`
        ),
    },
    {
      label: 'Customer Payments',
      onClick: (id) =>
        this.props.history.push(
          `/paymentsmade/add?contactId=${this.state.contactInfo.id}`
        ),
      disabled: () => !this.hasPermission('invoicepayment', 'Create'),
    },
  ];

  renderActionMenu() {
    const id = this.id;
    // const isActive = this.state.contactInfo.is_active;
    const [hasCreatePermission, hasEditPermission, hasDeletePermission] =
      this.hasPermission('contact', 'All');
    const menusList = [
      {
        type: 'link',
        icon: 'edit',
        enable: hasEditPermission,
        to: `/contacts/edit/${id}`,
        tooTip: 'Edit',
        mobileLable: 'Edit',
        isMobile: true,
      },
      {
        type: 'link',
        icon: 'clone',
        tooTip: 'Clone',
        mobileLable: 'Clone',
        isMobile: true,
        enable: hasCreatePermission,
        to: `/contacts/clone/${id}`,
      },
      {
        type: 'link',
        icon: 'email',
        tooTip: 'Email',
        isMobile: true,
        mobileLable: 'Email',
        to: `/contacts/email/${id}`,
      },
      {
        type: 'button',
        icon: 'delete',
        tooTip: 'Delete',
        mobileLable: 'Delete',
        isMobile: true,
        handleClick: () => {
          const { attach_with_item, has_transaction } = this.state.contactInfo;
          this.openDeleteModal(
            id,
            this.props.forceRedirect,
            this.props.forceRedirectFlag,
            attach_with_item || has_transaction,
            has_transaction
              ? 'Can not delete this contact as it is already in use.'
              : 'Vendor is linked with an item(s). You cannot delete this.'
          );
        },
        enable: hasDeletePermission,
      },
      this.state.contactInfo?.is_active === 1 && {
        type: 'dropdown',
        options:
          this.state.contactInfo.contact_type === 'customer'
            ? this.optionsListForCustomer
            : this.optionsListForVendor,
        className:
          'custom-dropdown transaction_Dropdwon mr-10 sales_order_dropdwon button-text transaction-left',
        dropdownLable: 'Add Transaction',
        enable: true,
      },
      {
        type: 'button',
        className: 'button--primary float-left mr-3',
        handleClick: () => this.props.history.push(`/contacts/add`),
        label: `New`,
        icon: 'new',
      },
    ];
    return (
      <ActionMenu>
        <HeaderMenus menusList={menusList} loading={this.state.loading} />
      </ActionMenu>
    );
  }

  renderLayoutView() {
    const { contactInfo, transactionSummary } = this.state;
    return (
      <>
        <Box className="no-padding">
          <ContactInfo
            id={contactInfo.id}
            photo={contactInfo.photo}
            displayName={contactInfo.display_name}
            salutation={contactInfo.salutation}
            firstName={contactInfo.first_name}
            lastName={contactInfo.last_name}
            designation={contactInfo.designation}
            department={contactInfo.department}
            companyName={contactInfo.company_name}
            workPhone={contactInfo.work_phone}
            mobile={contactInfo.mobile}
            email={contactInfo.email}
            skype={contactInfo.skype}
          />
          <TransactionSummary
            {...transactionSummary}
            currencySymbol={
              contactInfo.currency ? contactInfo.currency.symbol : null
            }
          />
        </Box>
        <Box title="Other Details" className="no-margin">
          <ContactAdditionalInfo
            facebook={contactInfo.facebook}
            twitter={contactInfo.twitter}
            instagram={contactInfo.instagram}
            notes={contactInfo.note}
            currencyCode={
              contactInfo.currency ? contactInfo.currency.currency_code : null
            }
            paymentTerms={contactInfo.payment_terms}
          />
        </Box>
      </>
    );
  }

  renderSidebar() {
    const { contactInfo, deleteContactRequest = false } = this.state;
    const [hasMobile = false] = this.hasMobile;
    if (!deleteContactRequest) {
      return (
        <ContactDetailsOutterTabs
          contactId={contactInfo.id}
          inModal={this.props.inModal}
          contactType={contactInfo.contact_type}
          contactName={contactInfo.display_name}
          handleToast={this.props.handleToast}
          fetchData={this.fetchData}
          history={this.props.history}
          contactInfo={contactInfo}
          hasMobile={hasMobile}
        />
      );
    } else {
      return;
    }
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    return (
      // Structure Change from grid to block
      <div className="tabs-section">
        <div className="right-tabs-section">
          <div className="right-tabs-section-inner">{this.renderSidebar()}</div>
        </div>
      </div>
    );
  }
}

export default HeaderWrapper(ContactDetails, {
  name: 'Contacts',
  deleteName: 'Contact',
  tableIcon: 'contact_general_module_icon contact_top',
  Icon: NewContactIcon,
  baseUrl: 'contacts',
  redirectUrl: '/contacts',
  onlyMenu: true,
  showName: true,
  permissionName: 'contact',
  // renderHeaderWrapperAll :window.location.pathname === "/contacts" ? true  : false
});
