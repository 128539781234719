import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { restRequest, getObjFromLS, checkError } from '../../../Helpers';
import { OverlayLoader } from '../../../Components';
import { Form, Field, FormAction } from '../../../Components/Form';
import './SaleReturnPolicyPreference.css';
import { isEqual } from 'lodash';

class SaleReturnPolicyPreference extends Component {
  state = {
    loading: true,
    staticVal: {},
    saleReturnPref: {
      adjustment: 0,
      adjustement_criteria: 'amount',
    },
    allCriteria: [],
    isSubmitting: false,
  };

  componentDidMount() {
    localStorage.setItem('alert', false);
    this.fetchData();
    this.fetchAllCriteria();
    window.onbeforeunload = function () {
      return true;
    };
  }
  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { saleReturnPref, staticVal } = this.state;
    if (
      prevState.saleReturnPref?.adjustment !== saleReturnPref.adjustment ||
      prevState.saleReturnPref?.adjustement_criteria !==
        saleReturnPref.adjustement_criteria
    ) {
      const checkEquality = Boolean(isEqual(saleReturnPref, staticVal));
      if (!checkEquality) localStorage.setItem('alert', true);
      else localStorage.setItem('alert', false);
    }
  }

  async fetchData() {
    this.setState({ loading: true });
    await restRequest('get', 'preferences/salereturnpolicy')
      .then((res) => {
        this.setState({
          saleReturnPref: {
            adjustment: res.preferences.adjustment,
            adjustement_criteria: res.preferences.adjustement_criteria,
          },
          staticVal: {
            adjustment: res.preferences.adjustment,
            adjustement_criteria: res.preferences.adjustement_criteria,
          },
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  }

  async fetchAllCriteria() {
    this.setState({ loading: true });
    await restRequest('get', 'preferences/allsalereturncriteria')
      .then((res) => {
        this.setState({
          allCriteria: res,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let { adjustment } = this.state.saleReturnPref;
    let { adjustement_criteria } = this.state.saleReturnPref;
    if (adjustment === undefined)
      return this.props.handleToast(
        'The CN Deduction Value field is required',
        'error'
      );
    if (adjustement_criteria === 'percent' && adjustment > 100) {
      return this.props.handleToast(
        'Percentage value can not be more than 100',
        'error'
      );
    }
    this.setState({ isSubmitting: true });
    restRequest('put', 'preferences/salereturnpolicy', {
      status: {
        adjustment: adjustment,
        adjustement_criteria: adjustement_criteria,
      },
    })
      .then((res) => {
        this.setState({ isSubmitting: false, status: false });
        this.props.handleToast(res.message, 'success');
        this.props.setPrompt(false);
        this.fetchData();
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });
        // checkError(error, this.props.handleToast);
        checkError(error);
        this.props.handleToast(error, 'error');
      });
  };

  toggleSaleRetunAdjustmentStatus(value) {
    const { saleReturnPref } = this.state;
    // let adjustedQuantity = value.floatValue
    // Price_Quantity_Limit

    // price and quantity limit from 5 digits to 10 digits
    let sellPrice = value.floatValue;
    if (sellPrice > 0) {
      let sellPriceString = sellPrice.toString();
      if (sellPriceString.length > 10) {
        sellPriceString = sellPriceString.slice(0, 10);
      }
      sellPrice = parseFloat(sellPriceString);
    }
    this.setState((state) => ({
      saleReturnPref: {
        ...state.saleReturnPref,
        adjustment:
          saleReturnPref.adjustement_criteria === 'percent' && sellPrice > 100
            ? 100
            : sellPrice,
      },
      status: true,
    }));
  }

  toggleSaleRetunCriteriaStatus(value) {
    const { saleReturnPref } = this.state;
    this.setState((state) => ({
      saleReturnPref: {
        ...state.saleReturnPref,
        adjustement_criteria: value,
        adjustment:
          value === 'percent' && saleReturnPref.adjustment > 100
            ? 0
            : saleReturnPref.adjustment,
      },
      status: true,
    }));
  }

  renderActionButtons() {
    let permissions = getObjFromLS('role').permissions;
    if (!permissions.superAccess && permissions.PreferenceEdit) {
      return (
        <FormAction
          disabled={this.state.isSubmitting}
          onCancel={() => this.props.history.push('/settings')}
        />
      );
    }
    if (permissions.superAccess) {
      return (
        <FormAction
          disabled={this.state.isSubmitting}
          onCancel={() => this.props.history.push('/settings')}
        />
      );
    }
  }

  renderStatusChangeInput() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <div className="sale-return-preference float-left w-100  preference-sn">
          <div className="float-left w-70 genral_tab_content">
            {this.state.loading && <OverlayLoader />}

            <label className="list-table--checkbox-lable float-left w-100 preferences-deduction">
              <div className="sale-return-preference--label-text float-left w-auto">
                CN Deduction Type
              </div>
              <Field
                className="float-left"
                type="dropdown"
                size="medium"
                value={this.state.saleReturnPref.adjustement_criteria}
                options={this.state.allCriteria}
                dropdownValue="value"
                dropdownLabel="label"
                searchable={false}
                clearable={false}
                handleChange={(value) => {
                  this.toggleSaleRetunCriteriaStatus(value.value);
                }}
              />
            </label>
            <label className="list-table--checkbox-lable float-left w-100 deduction-field">
              <div className="sale-return-preference--label-text float-left w-auto">
                CN Deduction Value
              </div>
              <Field
                // size='xs'
                className="float-left"
                type="number"
                decimalScale={2}
                value={this.state.saleReturnPref.adjustment}
                allowNegative={false}
                thousandSeparator=","
                required
                onValueChange={(value) => {
                  this.toggleSaleRetunAdjustmentStatus(value);
                }}
              />
            </label>

            {this.renderActionButtons()}
          </div>
        </div>
      </Form>
    );
  }

  render() {
    return this.renderStatusChangeInput();
  }
}

export default withRouter(SaleReturnPolicyPreference);
