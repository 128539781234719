import React from 'react';

const TaxIcon = ({ customWidth, customHeight, giveFloat }) => {
  return (
    <svg
      width={`${customWidth ? customWidth : '48'}`}
      height={`${customHeight ? customHeight : '48'}`}
      style={{ float: `${giveFloat ? 'left' : ''}` }}
      viewBox="0 0 74 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M50.7144 28.24H29.6858V32.56H50.7144V28.24Z" fill="#ADB6BC" />
      <path d="M50.7144 37.84H29.6858V42.16H50.7144V37.84Z" fill="#ADB6BC" />
      <path d="M39 47.44H29.6858V51.76H39V47.44Z" fill="#ADB6BC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.0625 64C40.7899 62.8768 39.7574 61.4878 39.0517 59.92L26.4857 59.92C26.1299 59.8947 25.7949 59.7349 25.5427 59.4701C25.2906 59.2054 25.1384 58.8536 25.1143 58.48L25.1143 21.76C25.1065 21.5687 25.1367 21.3778 25.2029 21.1994C25.269 21.021 25.3698 20.859 25.4987 20.7236C25.6276 20.5883 25.782 20.4825 25.9519 20.413C26.1218 20.3435 26.3036 20.3119 26.4857 20.32L53.9143 20.32C54.2701 20.3453 54.6051 20.5051 54.8573 20.7699C55.1094 21.0346 55.2617 21.3864 55.2857 21.76L55.2857 44.2238C56.8924 45.0134 58.2967 46.1517 59.4 47.54L59.4 21.76C59.3805 20.2387 58.7963 18.7855 57.7717 17.7097C56.7471 16.6339 55.3631 16.0205 53.9143 16L26.4857 16C25.0369 16.0205 23.6529 16.6339 22.6283 17.7097C21.6038 18.7855 21.0195 20.2387 21 21.76L21 58.24C21.0195 59.7613 21.6038 61.2145 22.6283 62.2903C23.6529 63.3661 25.0369 63.9795 26.4857 64L42.0625 64Z"
        fill="#ADB6BC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.8 64C55.1019 64 59.4 59.7019 59.4 54.4C59.4 49.0981 55.1019 44.8 49.8 44.8C44.4981 44.8 40.2 49.0981 40.2 54.4C40.2 59.7019 44.4981 64 49.8 64ZM54.0426 50.1586C53.5739 49.69 52.8141 49.69 52.3455 50.1586L45.5573 56.9469C45.0886 57.4155 45.0886 58.1753 45.5573 58.6439C46.0259 59.1125 46.7857 59.1125 47.2543 58.6439L54.0426 51.8557C54.5112 51.3871 54.5112 50.6273 54.0426 50.1586ZM45.5574 51.8557C46.026 52.3243 46.7858 52.3243 47.2545 51.8557C47.7231 51.3871 47.7231 50.6273 47.2545 50.1586C46.7858 49.69 46.026 49.69 45.5574 50.1586C45.0888 50.6273 45.0888 51.3871 45.5574 51.8557ZM54.0426 58.6439C53.5739 59.1125 52.8141 59.1126 52.3455 58.6439C51.8769 58.1753 51.8769 57.4155 52.3455 56.9469C52.8141 56.4782 53.5739 56.4782 54.0426 56.9469C54.5112 57.4155 54.5112 58.1753 54.0426 58.6439Z"
        fill="#ADB6BC"
      />
    </svg>
  );
};

const PreferencesIcon = ({ customWidth, customHeight, giveFloat }) => {
  return (
    <svg
      width={`${customWidth ? customWidth : '48'}`}
      height={`${customHeight ? customHeight : '48'}`}
      style={{ float: `${giveFloat ? 'left' : ''}` }}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 14.0002H8.37C9.198 16.3222 11.396 18.0002 14 18.0002C16.604 18.0002 18.802 16.3222 19.63 14.0002H42C43.106 14.0002 44 13.1062 44 12.0002C44 10.8942 43.106 10.0002 42 10.0002H19.63C18.802 7.67821 16.604 6.00021 14 6.00021C11.396 6.00021 9.198 7.67821 8.37 10.0002H6C4.894 10.0002 4 10.8942 4 12.0002C4 13.1062 4.894 14.0002 6 14.0002ZM38 18.0002C35.396 18.0002 33.198 19.6782 32.37 22.0002H6C4.894 22.0002 4 22.8942 4 24.0002C4 25.1062 4.894 26.0002 6 26.0002H32.37C33.198 28.3222 35.396 30.0002 38 30.0002C41.308 30.0002 44 27.3082 44 24.0002C44 20.6922 41.308 18.0002 38 18.0002ZM27.63 34.0002H42C43.106 34.0002 44 34.8942 44 36.0002C44 37.1062 43.106 38.0002 42 38.0002H27.63C26.802 40.3222 24.604 42.0002 22 42.0002C19.396 42.0002 17.198 40.3222 16.37 38.0002H6C4.894 38.0002 4 37.1062 4 36.0002C4 34.8942 4.894 34.0002 6 34.0002H16.37C17.198 31.6782 19.396 30.0002 22 30.0002C24.604 30.0002 26.802 31.6782 27.63 34.0002Z"
        fill="#ADB6BC"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="4"
        y="6"
        width="40"
        height="37"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 14.0002H8.37C9.198 16.3222 11.396 18.0002 14 18.0002C16.604 18.0002 18.802 16.3222 19.63 14.0002H42C43.106 14.0002 44 13.1062 44 12.0002C44 10.8942 43.106 10.0002 42 10.0002H19.63C18.802 7.67821 16.604 6.00021 14 6.00021C11.396 6.00021 9.198 7.67821 8.37 10.0002H6C4.894 10.0002 4 10.8942 4 12.0002C4 13.1062 4.894 14.0002 6 14.0002ZM38 18.0002C35.396 18.0002 33.198 19.6782 32.37 22.0002H6C4.894 22.0002 4 22.8942 4 24.0002C4 25.1062 4.894 26.0002 6 26.0002H32.37C33.198 28.3222 35.396 30.0002 38 30.0002C41.308 30.0002 44 27.3082 44 24.0002C44 20.6922 41.308 18.0002 38 18.0002ZM27.63 34.0002H42C43.106 34.0002 44 34.8942 44 36.0002C44 37.1062 43.106 38.0002 42 38.0002H27.63C26.802 40.3222 24.604 42.0002 22 42.0002C19.396 42.0002 17.198 40.3222 16.37 38.0002H6C4.894 38.0002 4 37.1062 4 36.0002C4 34.8942 4.894 34.0002 6 34.0002H16.37C17.198 31.6782 19.396 30.0002 22 30.0002C24.604 30.0002 26.802 31.6782 27.63 34.0002Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect width="48" height="48" fill="#ADB6BC" />
      </g>
    </svg>
  );
};

const numberPreferencesIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 14.0002H8.37C9.198 16.3222 11.396 18.0002 14 18.0002C16.604 18.0002 18.802 16.3222 19.63 14.0002H42C43.106 14.0002 44 13.1062 44 12.0002C44 10.8942 43.106 10.0002 42 10.0002H19.63C18.802 7.67821 16.604 6.00021 14 6.00021C11.396 6.00021 9.198 7.67821 8.37 10.0002H6C4.894 10.0002 4 10.8942 4 12.0002C4 13.1062 4.894 14.0002 6 14.0002ZM38 18.0002C35.396 18.0002 33.198 19.6782 32.37 22.0002H6C4.894 22.0002 4 22.8942 4 24.0002C4 25.1062 4.894 26.0002 6 26.0002H32.37C33.198 28.3222 35.396 30.0002 38 30.0002C41.308 30.0002 44 27.3082 44 24.0002C44 20.6922 41.308 18.0002 38 18.0002ZM27.63 34.0002H42C43.106 34.0002 44 34.8942 44 36.0002C44 37.1062 43.106 38.0002 42 38.0002H27.63C26.802 40.3222 24.604 42.0002 22 42.0002C19.396 42.0002 17.198 40.3222 16.37 38.0002H6C4.894 38.0002 4 37.1062 4 36.0002C4 34.8942 4.894 34.0002 6 34.0002H16.37C17.198 31.6782 19.396 30.0002 22 30.0002C24.604 30.0002 26.802 31.6782 27.63 34.0002Z"
        fill="#ADB6BC"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="4"
        y="6"
        width="40"
        height="37"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 14.0002H8.37C9.198 16.3222 11.396 18.0002 14 18.0002C16.604 18.0002 18.802 16.3222 19.63 14.0002H42C43.106 14.0002 44 13.1062 44 12.0002C44 10.8942 43.106 10.0002 42 10.0002H19.63C18.802 7.67821 16.604 6.00021 14 6.00021C11.396 6.00021 9.198 7.67821 8.37 10.0002H6C4.894 10.0002 4 10.8942 4 12.0002C4 13.1062 4.894 14.0002 6 14.0002ZM38 18.0002C35.396 18.0002 33.198 19.6782 32.37 22.0002H6C4.894 22.0002 4 22.8942 4 24.0002C4 25.1062 4.894 26.0002 6 26.0002H32.37C33.198 28.3222 35.396 30.0002 38 30.0002C41.308 30.0002 44 27.3082 44 24.0002C44 20.6922 41.308 18.0002 38 18.0002ZM27.63 34.0002H42C43.106 34.0002 44 34.8942 44 36.0002C44 37.1062 43.106 38.0002 42 38.0002H27.63C26.802 40.3222 24.604 42.0002 22 42.0002C19.396 42.0002 17.198 40.3222 16.37 38.0002H6C4.894 38.0002 4 37.1062 4 36.0002C4 34.8942 4.894 34.0002 6 34.0002H16.37C17.198 31.6782 19.396 30.0002 22 30.0002C24.604 30.0002 26.802 31.6782 27.63 34.0002Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect width="48" height="48" fill="#ADB6BC" />
      </g>
    </svg>
  );
};

const CurrencyIcon = ({ customWidth, customHeight, giveFloat }) => {
  return (
    <svg
      width={`${customWidth ? customWidth : '48'}`}
      height={`${customHeight ? customHeight : '48'}`}
      style={{ float: `${giveFloat ? 'left' : ''}` }}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42ZM19.0669 32.1763C19.8986 32.4655 20.9743 32.6644 22.2941 32.7729V36H25.711V32.6915C26.5969 32.5469 27.3381 32.3119 27.9347 31.9864C28.5494 31.661 29.0466 31.2723 29.4263 30.8203C29.8059 30.3684 30.0771 29.8802 30.2398 29.3559C30.4025 28.8136 30.4839 28.2712 30.4839 27.7288C30.4839 26.9333 30.3393 26.2373 30.05 25.6407C29.7788 25.0441 29.3901 24.5288 28.8839 24.0949C28.3958 23.6429 27.8082 23.2452 27.1212 22.9017C26.4523 22.5582 25.7201 22.2418 24.9246 21.9525C24.4003 21.7718 23.9483 21.609 23.5686 21.4644C23.2071 21.3017 22.8997 21.139 22.6466 20.9763C22.3935 20.8136 22.2037 20.6328 22.0771 20.4339C21.9686 20.2169 21.9144 19.9638 21.9144 19.6746C21.9144 19.2045 22.1042 18.8249 22.4839 18.5356C22.8636 18.2463 23.4873 18.1017 24.3551 18.1017C25.2229 18.1017 26.0455 18.1921 26.8229 18.3729C27.6184 18.5537 28.3144 18.7706 28.911 19.0237L29.7517 15.7695C29.354 15.5887 28.8116 15.4079 28.1246 15.2271C27.4557 15.0463 26.6511 14.9107 25.711 14.8203V12H22.2941V14.9288C21.4986 15.0734 20.8116 15.3085 20.2331 15.6339C19.6545 15.9412 19.1845 16.3209 18.8229 16.7729C18.4613 17.2249 18.1901 17.7311 18.0093 18.2915C17.8466 18.8339 17.7653 19.4034 17.7653 20C17.7653 20.7774 17.9008 21.4463 18.172 22.0068C18.4613 22.5492 18.8319 23.0102 19.2839 23.3898C19.754 23.7695 20.2692 24.0949 20.8297 24.3661C21.4082 24.6192 21.9958 24.8542 22.5924 25.0712C23.2071 25.2881 23.7494 25.496 24.2195 25.6949C24.6895 25.8757 25.0782 26.0655 25.3856 26.2644C25.6929 26.4633 25.928 26.6893 26.0907 26.9424C26.2534 27.1774 26.3347 27.4667 26.3347 27.8102C26.3347 28.3525 26.1268 28.7774 25.711 29.0847C25.3133 29.374 24.563 29.5186 23.4602 29.5186C22.4839 29.5186 21.5528 29.3921 20.6669 29.139C19.7992 28.8859 19.0489 28.6056 18.4161 28.2983L17.25 31.4169C17.6477 31.6158 18.2534 31.8689 19.0669 32.1763Z"
        fill="#ADB6BC"
      />
      <circle cx="24" cy="24" r="23" stroke="#ADB6BC" strokeWidth="2" />
    </svg>
  );
};

const WarehouseIcon = ({ customWidth, customHeight, giveFloat }) => {
  return (
    <svg
      width={`${customWidth ? customWidth : '48'}`}
      height={`${customHeight ? customHeight : '48'}`}
      style={{ float: `${giveFloat ? 'left' : ''}` }}
      viewBox="0 0 52 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 11.84V1.59999H0.400024V47.68H51.6V11.84H26ZM10.64 42.56H5.52002V37.44H10.64V42.56ZM10.64 32.32H5.52002V27.2H10.64V32.32ZM10.64 22.08H5.52002V16.96H10.64V22.08ZM10.64 11.84H5.52002V6.71999H10.64V11.84ZM20.88 42.56H15.76V37.44H20.88V42.56ZM20.88 32.32H15.76V27.2H20.88V32.32ZM20.88 22.08H15.76V16.96H20.88V22.08ZM20.88 11.84H15.76V6.71999H20.88V11.84ZM46.48 42.56H26V37.44H31.12V32.32H26V27.2H31.12V22.08H26V16.96H46.48V42.56ZM41.36 22.08H36.24V27.2H41.36V22.08ZM41.36 32.32H36.24V37.44H41.36V32.32Z"
        fill="#ADB6BC"
      />
    </svg>
  );
};

const InventoryIcon = ({ customWidth, customHeight, giveFloat }) => {
  return (
    <svg
      width={`${customWidth ? customWidth : '48'}`}
      height={`${customHeight ? customHeight : '48'}`}
      style={{ float: `${giveFloat ? 'left' : ''}` }}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="45" width="48" height="3" fill="#ADB6BC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 23C4.44772 23 4 23.4477 4 24V40C4 40.5523 4.44772 41 5 41H21C21.5523 41 22 40.5523 22 40V24C22 23.4477 21.5523 23 21 23H17.5V24.8H8.5V23H5Z"
        fill="#ADB6BC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 23C26.4477 23 26 23.4477 26 24V40C26 40.5523 26.4477 41 27 41H43C43.5523 41 44 40.5523 44 40V24C44 23.4477 43.5523 23 43 23H39.5V24.8H30.5V23H27Z"
        fill="#ADB6BC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 1C15.4477 1 15 1.44772 15 2V18C15 18.5523 15.4477 19 16 19H32C32.5523 19 33 18.5523 33 18V2C33 1.44772 32.5523 1 32 1H28.5V2.8H19.5V1H16Z"
        fill="#ADB6BC"
      />
    </svg>
  );
};
const PrefixPrefIcon = ({ customWidth, customHeight, giveFloat }) => {
  return (
    <svg
      width={`${customWidth ? customWidth : '48'}`}
      height={`${customHeight ? customHeight : '48'}`}
      style={{ float: `${giveFloat ? 'left' : ''}` }}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
    >
      {' '}
      <image
        id="image0"
        width={`${customWidth ? customWidth : '48'}`}
        height={`${customHeight ? customHeight : '48'}`}
        style={{ float: `${giveFloat ? 'left' : ''}` }}
        x="0"
        y="0"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAncAAAGLCAYAAAClcrSdAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
CXBIWXMAAA7EAAAOxAGVKw4bAAA0mUlEQVR42u3deXycZ3nv/+u671HsbGQjK9kXQliSEBKWX1lj
2bHkJCSRnLCWQkmAliWErbTQvqDQcyAtpUDPgXMoBfqCUxJJLoklOfFIYWmhPeW0ZWmbspUCIQGy
kt2a57p+f8i2JFu2ZXueuWae5/N++aVtZu7ne49HM1898ywiAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMql0QEAAPVz/frmke52poskFynE
xVzERVxURGdfnVREfPsbu+9o2N1+TfPdvL7OX4Qu+Klu+STuhYp8+/KLVv2y0/crIEK5AwB02Mh4
8/RHN236yvJl+xyxxJu4b1/odreX7XDsJVxHF/1et7S57d3/4IM/2G/5sjMvv2j1Q23KCSxZIzoA
AKA+xiZvyZrk95frkoudiIiqbtehunrlxP777XeCih0jIt+PzoL6SdEBAAB14s/LKQ1FpyhbUm2Y
+xnROVBPlDsAQEesm5helpK+bmZmZll0lk5Q0adGZ0A9Ue4AAB1hYk93s0ujc3RKSvnXrr/xZl5n
0XE86AAApRubvGW/lNLvmXuOztIpKaWjRPSw6ByoH8odAKADbHUj5wuiU3SSqh4hKsdE50D9UO4A
AKUam5w+pJHTWzfNzERH6TA/QkROjU6B+uFQKACAkvkF5vKs6BSdVhSWROT46ByoH9bcAQBKs27D
LYep6u8WhUVHCaGqT7juhpu6+ph8qB7KHQCgNG7FK0XkKdE5oqjKqapai0O/oHtQ7gAApRibmDoh
5/w6s3qutRMRmWm1znW3A6NzoF4odwCAUrj4cKsoTo7OEamR8/6i+tjoHKgXyh0AoO1GJ6aOMLd3
ROeIpqrZxWv7tjRiUO4AAG3nYr+VUz48Okc3yCmfE50B9UK5AwC01ehE88y+3LjS3aOjdAl93sj4
RvaYRcdQ7gAAbaWqr24VBWdm2KyR0+Gqekh0DtQH5Q4A0DZjk9OnmtsronN0k5TyEaJ6QnQO1Afl
DgDQFmMTU0nE36qij4nO0k1s9lAoT43Ogfqg3AEA2kJVzmvkfEV0jm5TFIWoyHHROVAflDsAwF5b
NzndEJG3z7RaB0dn6UaqetroxBSvuegIHmgAgL3m4k8TlTXRObrYiSK+X3QI1APlDgCwV0YnpvZ1
94+aOedQ3QF3P01EDo7OgXqg3AEA9oqLX9RoNM6LztHNzOwwdzsiOgfqgXIHANhj6zZMH5xTenOr
1YqO0tVUNZv7k6NzoB4odwCAPWZm55vZM6Nz9IKcEms30RGUOwDAHhmbmDpYVd8bnaNXmMvzozOg
Hih3AIA94mIvTik9KTpHr8gpHTyyvnlodA5UH+UOALDbxiamjs05v7YoiugoPSOldKiLnBydA9VH
uQMA7DYXv6Io7MzoHD3FfT8Rf3p0DFQf5Q4AsFtGx5tHp5TeEJ2j1xRmoqqnRedA9VHuAABLNrK+
qS5+lYifEJ2lF6nqUdfdcFMjOgeqjXIHAFiylPTURl/jFWYeHaUnqfpxInJAdA5UG+UOALBkLv6e
olWcFJ2jV7n7KS5+eHQOVBvlDgCwJCPrN54qLpdG5+htepSIHBWdAtVGuQMA7NLI+o19Iv5+UVke
naUCnhIdANVGuQMALMW5jb6+NdEhqiDnfHZ0BlQb5Q4AsFMj6zcmTfqOoij2j85SBYXZiugMqDbK
HQBgp1z8LHNnrV2bJNUDr1t/84HROVBdlDsAwA6NrG/ul1Tfk1Q5Nlub5JwPFNVTonOguih3AIAd
cvEL+vr6BqNzVImqLlfRs6JzoLoodwCARY2ON/dt5PzGmVYrR2epEjOTpHJedA5UF+UOALAoM1tt
bs+PzlFFKaUTRsabfdE5UE2UOwDAdkbWNw/JjfyW6ByVpXK0qB8cHQPVRLkDAGzHxS7PKf1adI6q
UpdTxOVx0TlQTez9BABYYHT9xkNzI7+2VRTRUSqrMDvYxY+IzoFqYs0dAGABF//twuzs6ByV5/6k
6AioJtbcAQC2Gp1oHqsqV7lHJ6m+pPmc6AyoJtbcAQC2cvc3iuix0TnqwNzOjM6AaqLcAQBERGR0
onlyzunlzmq7jijMT7juhpsPiM6B6qHcAQC2eIOZHxUdoi4aSfdzcU5Dhraj3AEAZHRi6nQVfWV0
jjrRlPpEhZ0q0HaUOwCouZH1G5OLvUlUDorOUjdJ08roDKgeyh0A1J3KeY3UeAnb2nVeSnr0dTfc
zJEr0FaUOwCoOVV9U2EFa+0CqOqRIn5YdA5UC+UOAGpsZHzjs8R9KDpHXSXVU0TlpOgcqBZWBQNL
NDo+ta+Lnyqqfaqqc5f4gk+L0K0fdnjp9j/Y4fV7xw6nsLQ3//bkLULd+U8WS+Q7+9Z39eUuk7hu
ubr65q9cRHzeo8a33EBFk8w+uJKKq5vNqOq3Lx04v5TzgI2MN/dVlTeKyD5ljI9dK8wOdPfjROTv
o7OgOih3wBIVVnxaVS5WSY3Ft03a2QZLqgsunFcy1LetHFsbwFL6nW53w/rwpfxo3k90J/fQzu67
2f9Y3/5HSwq31CbrMq8Fipj5o6ryjUbOLxKRn7f/7hNxtwsafX3DRYtzyEZKSU+NzoBqqcDKAaB8
I+PNM9ztmymlvugsqIeUkojIOy5dff4Hyxh/dGJqmbv9g6qeFT3X2lP5vBX+srUXrqzbH2goCdvc
AbswMj61XFU+SLFDJ7nZd4qi+GRZ45sVQxS77uDmZ4vIsugcqA7KHbAr6i/IjcyxqNAx7u6F2x8P
r1l5dxnjj6zfeHgj57dEzxOzCrMTRWT/6ByoDsodsBOjE1PLs+pbilbBX9XomJTSl918oqzxXfxy
UTknep6YlVNa7uKnRedAdVDugJ1wt+cUZudH50B9pKSPzrRaH7/8ogt+Wcb4YxNTR+aUrjZj865u
oapJUzo7Ogeqg3IH7MDo+NRBKaV3LzzsCVCuVqv4hpv/TVnju9tLXIS9M7tMEh2IzoDqoNwBO+Bi
a3NOz4nOgfows02F+7te9MLVj5Yx/uh48+SU85uj54ntadKjRieaHJ4MbUG5AxYxNjF9cF9uvLbF
8b/QQZrSRhX5hzLGHh2fSqL+GyJyXPQ8sb2keqiIHB6dA9VAuQMW4VK8rGXF06JzoD5SSne6+/uu
uOiCh8sYX5OcnlJ+jZlFTxWL0KRHi8gJ0TlQDZQ7YBtjk9NH55xfG50D9VKY3ejmpay1ExFRkVeb
2RHR88TirLD93dljFu1BuQO24W5vLwp7UnQO1IeK3isiH7z8olWl7MI6Or7xHE16ZfQ8sWNmJqpC
uUNbUO6AecYmp09MSa+IzoGaUb1ueLD/1jKGHh1vLtOkV5r5gdHTxM6llM4YWc9OFdh7lDtgAb/G
zI+OToH6yDn/xNw+Wtb4Lv4sd/9Nd45r1+3M/CkuzpkqsNcod8BmoxNTp4jIS6NzoF5aRfHZ4cH+
75Q1fk76LhHlvMg9oCiK492FNazYa5Q7QERGJ6ZURN4qIodGZ0F9mPkvRORDZY0/OrFxpWqq7BlW
+hp9Dy9fvryUYwJGyDnv6+InRudA76PcASKiKufmnF7MW1foJFX90+HB/rvLGHtsYupA1XRNYVbZ
M6wUbn8005r55+gc7aQqT43OgN5HuUPtjU1MJVW9piiKg6KzoD6S6ndE/PNlje/iq1JKq6PnWRbV
9C8uvq4o7MfRWdopp3RedAb0Psodas/Fz7PCLonOgfpQVWmZfXJ4zcpSisnYxNS+KvruoqjmGVZS
SmZun7HCfuQiP4nO007ufub1N9zMHrPYK5Q71NrYxNSypHqNqCyPzoL6aLWKH4vIX5Y1votfKCpn
Rc+zLGb2byK+fniw/0EXuSc6TzvllA5x9SOjc6C3Ue5Qa5vfurokOgfqo5GzaNZ3rF2z8ldljD82
OX1MUv2dKm8/6m6fEPcfbP72rpSq81KWcz5aRE6KzoHeVp3fCGA3rZucyimlN7eKYp/oLKgPc/+K
umwoa3x3u0STnhM9z/LmJz9W1S8Or5k9m4eqfj+pPBSdq10KK/pE/MzoHOhtlDvUVlEULzGzF0Tn
QH2klB4tzN4/vGblvWWMv25y+nAVeUdRWPRUS6Pqn3WX27Z+L/5zc3kgOle7mLnknE+JzoHeRrlD
LY1NNA9pNBpvis6BenHxr4jIV0ob3+13XOT46HmWRVV/6u5jw2tWzm+vd0uFyp2ISErptOvXb+Qd
Bewxyh1qycVeZu5Pi86B+kiqD5v5e4cH+x8pY/yxDdNPSTlX+gwrZnaDu3xr4U/9Fy5eqXLnLmeI
ysHROdC7KHeonXWTUweJpDdUeYNzdCHVCRX9v2UN726/URRFZfeyLIriYXf/wNoLVy04voubtcyL
ypylYvNcj3fnbDnYc5Q71I65/YaInBadA/WRc/6Vuf/J0OCKTWWMPzY5daqbXBU9zzJpSn+19sJV
2x0XcHhwlauWV5ojpJT2Ea3u2+soH+UOtTI2MXVcSvmN0TlQL+6+fmhgxdfLGHvdhluSqrxbVQ6I
nmdZkqa7zPwvdnS5WXFrdMb2z1nPjc6A3kW5Q624+CvF/eToHKgR9/sLs/eVuIBn5JQvi55mmczt
JnH/lx3eA+bf94ptZ5FUOQ0Z9hjlDrUxNjl1StL0GqvWawC6XUqfHh7s//cyhl63YXofEX/jTKtV
2bV2IvpIYXbtFRdfsMO3tFX1J+ZeylveUQqzp4yMb8zROdCbKHeoDRW5ytyOic6B+sg53+Gy47cT
95a7nyuil0bPs1zeNPNv7vwqcr+4V+rgfjnlg0TkiOgc6E2UO9TC2MTU2arpNdE5UC9mdv3wQP83
936k7a3bML1MRN5tZsui51mWlPThwv1DL7r4gl2tbn9Uk94XnbedctbDROT06BzoTZQ7VN7oeHMf
TfJqczsoOgvqQ1V/4iL/vazx3f3ZOaUV0fMsl35ZXf5pCVd8pJHS96LTtlNRmIr4s6JzoDdR7lB5
mvRclfSKim1vjS6WUxIX+ZOhgRU/K2P80YnmYSrye62i6Iuea1lyzq2iKP507YUrl7JG7lFx+fES
rtcz3F1SyidG50Bvotyh8lTl/YUVFd7gHN3GRb4l4iPlje8vSClV+rzIVtj/dZevLvEOmTH3n0Zn
brek6cTR8eZ+0TnQeyh3qLSxieaAqj4vOgfqpfDij4YG+m/b+5G2t27D9EFu/ietotj7wbpYy+3D
ay9c+fBSrrv24tWFuZeyljSSi5zgLodF50DvodyhskbHmweklN44u+0K0DHfFpcbyhrc3V7VaORK
n71AVb+l4ktba7eZu1fqFGQiIuZ2rLsfHp0DvYdyh8py8X4XWR2dA/WRUjIX+aPhwaWtcdpdoxPN
E1LKV5lVd/vRlJK0rPj02jWr7titG6r8R0p6T3T+tnLf38SPjY6B3kO5QyWtm5hallL6fbNKHfoK
XU5Vv6KqG8oa390vL4riCdHzLFNh9l13/+we3PROd7k3On+7qcpTozOg91DuUEku/kIVnhTROao6
U5hdOzSw4t4yxh+bnDrMxa+JnmfZCrOPXXHhBXft/i39Hnev1LHuRERU9ZzoDOg9lDtUztjE1JEp
53dwmjF0ln5taGDFRFmju/ubkqajomdZJhf5gap8fg9vfre7/yp6Du1WFMU5X/jiBk5Dht1CuUMV
DYk7f+2iY9zlEXN7f1njj443T+9r5N+InmeZVFXc/dOXr1m1B2vtRC6/cPXD4v5A9DzaLed0gKtz
GjLsFsodKmXd5C2HiMhbCra1Qwc1GnkypfSVMsYeGW+qqLxhplUcFz3Pkn1PXPZkW7s5Kv8cPYl2
62s0DlbRSm9nifaj3KFSXOwlLn5ydA7UR1K9v3D7yGWrzy/lUBwp6amq+vLoeZatZXb92gtX7tVZ
JszsB9HzaDczF1VZFZ0DvYVyh8oYnZg+TVXfEp0D9eJJPz20esWXyhh7dKLZUJF3u/tjoudZLv+l
ivz53o6SRH7o7pU6urO7SyM3TojOgd5CuUNlqPqLReSk6Byoj5TyneLyv0tcxNNyo3FZ9DzLp9ev
XbNyr88woaq3V63ciYhI0iOvX79x/+gY6B2UO1TC2OT0Ka1W8XaOa4dOSUlFVP7istXnf7uM8WfP
KervmpmZqfSLekrpp4XZ/2zHWKp6h7tU7klAVY8XFXaqwJJR7lAJKem7ck6VfhFEt9Hb3P0vyhrd
xc91l8HoWZatZXbj5Reu+k47xlKRViOlap2lQkTc/Fh3eVx0DvQOyh163tiGW56uIpdG50C9mPiH
L1t9/vfKGHt0orlf0nStqlb6OdpFHhL3j7RtQNVWzvk/o+fVbu6+XFROjM6B3lHpJw5U37oNt6iK
/1arKA6KzoL6UNUfiMvnyluCvzA38tOj51m2pHrz2jUrb23fiFqI+l7tcdutkuiZ0RnQOyh36HF+
jru/KDoF6iMlFRf5n0MDK24vY/zRialDU0qvabVa0VMtlapsate2dltcOthfmPkPo+dWknOjA6B3
UO7Qs9ZtuCWr6vvcfVl0FtSHi3xbRP6qtPHdBsz8edHzLJ82k+iX2j2qif8oemZl2DSz6SnRGdA7
KHfoWS7+fBVdEZ0DteLm/uGhgRW/KGPwdZNTj8k5/270JMumqq3C7CNDa/o3tXtsF71PRCt3Yumc
8vLrbryJPWaxJJQ79KR1G255jKr8Tqto9UVnQa38u4r+n7IGd/FXqcgToydZNnf/xto1K28qY2xV
+XHO1dtjttHIB7g4pyHDklDu0JPM7blJtD86B2rE3Vzkz4YGVjxcxvDrJqePzSm/qg7nRTa3D5Y4
/H3ifm/0HMugqmdFZ0BvoNyh56y76UsHJdU/bBXVOxA9upem9EUVLW0PWXO7slUUld+uKqV0i6o2
yxrfRe5x0buj51mGrIk/aLEklDv0HHd7jYicHZ0D9WHujxZufzo0uOLBMsYfm2gemTS9OnqeZcs5
S+H2+eHBlfeXthCXX7nYfdFzLYOmfPjI+uYB0TnQ/Sh36Cnrbv7SKX2N/DpOM4ZOaqR049rBlV8t
Y+zR8aaKyJtc/JjoeZbN3f81iX6hzGUMD6x4yF1+FT3XMqjq0e7Vf5xg71Hu0FOS+NpNm2ZOjM6B
+mjk/ICJ/I/SFqBylqZ0pXvldvDcjrn9+WUDK8pba7eZu/0geq4lzexYF85UgV2j3KFn/M3k9AEP
PfzIVdE5UCtm7teryNdKW4L7NWb22OiJli3n/K8qOtaJZZn7N1SjZ1zCvMwaKnJ8dA50v0Z0AGCp
3G35sr6+v8hZ93UXdxFzd1cRldkncpW5LxYfQ8TFd3TRvE/tyrxgedt/sQ1dNLku+LSj4LuK7jv9
dtc33Om9ttfa8kKsSxpp6905d3XVeT+ZdzU3s4fc7brLBvofbdNMFxgd33hKYbY251zG8F0jpySF
22eHBlb8vBPLU5Efm0lLtXqvcZq08ofKwd6r3AMf1XXpYP+dIvL+6BxAO4xNTPWJ2Ptzzsujs3TA
D8W81G3t5lOR29y9paqVe41TkSdd98Wb8uUvvIDDBWCHeFsWAAK4+PNyo++S6BydYO6fHhrs/69O
LU9Ffu7ulSw/hdkTRTnlInaOcgcAHTY63lymqr/darUq/yLtbveb2+c7ucyhNaseFZFS3koPp3K4
i1R+G03sHcodAHSYuz2zKOzC6BydkFLjk8ODKzu+9+o+jfyt6LmXIae0zMXPiM6B7ka5A4AOGpuc
OjDl/DspVW97sG3lnO80t9LOxbszLtKxt4E7LWk6JzoDuhvlDgA6yN0uySldEJ2jM3P18eHB/n8M
Wvb3oudfFk26MjoDuhvlDgA6ZGyieWDSfGWrKCp4FLaFiqJ42Nw+ELV8d/9+9H1QlkZqHHr9+MYD
o3Oge1HuAKBDCivWmttzonN0QqOv8YWhgf5/j0ugd3tFT/uhSY8S0eOic6B7Ue4AoAPGJqaObDT6
3hCdoxNyIz/o7p8JDZHkvpxT6ac6C3KkiJwVHQLdi3IHAB3hb3T3s6NTdIK6NFX170IziN6tmm6P
vi/KUBSFqHIaMuwY5Q4ASjY6vvHEnNMrK/ou4QIu0mpZce1lq1fMBCe5T8Tvjr4/yqKqlDvsEOUO
AMr3qlZhR0eH6ISc0ldV9OvROUT0ARG5KzpFabMTOWNkfGPlD4KNPUO5A4ASjU00T9Kkr43O0QmN
nM3cP3rZwAqLznLZwIqHxeXe6BxlMbPTRYQ9ZrEoyh0AlGR0vJlE5Y0ienh0lk5wlb8T0S9H59jC
zB6IzlAWdz/cxQ+LzoHuRLkDgNL42TnnV9RhW7uck5j5x4YGzu+e7dxUvpxTNV/mUkp97pyGDIur
5qMeALqAqvxmq1UcEp2jE2ZaxW3uPh6dYz51uc3dg3fsKE8SPTc6A7oT5Q4ASjA60XyqS3pVdI6O
UX330MCKB6NjLIwkP3WRR6NzlMVFVkdnQHei3AFAm41ONJenlK5W8eXRWTohpfRdFdkYnWM7qr9w
8U3RMcqScz5wZLzJThXYDuUOANrv/JzSi6u/pZ1ISknM/VPDg/0/jc6yHZeHxL2IjlGWpHqYu58Y
nQPdh3IHAG00OjGlqvr6mVarLzpLJ7j7z9z9L6NzLOaSgRWeU/5RdI6yaE6HiurJ0TnQfSh3ANBG
7naRu18QnaNTzOUjw4P9v4jOsRPfiA5QltZMS7Ump7TD7qHcAUCbjE1OHZRzeqfU5Lk1pfQzEf/r
6Bw7U7h9PzpDmTTpsdfdcLNG50B3aUQHqLKJL/19n7iKi6iIi7ioi6vMO+jVli907ktZcNnON9pZ
wiY9ut0XOxhq4eU+/8ulbjmk23+nO758afZoq6XdX5DqIrfzvYiwxxNeyhJ3c9yFw+34kGttuK+3
3mXbjOXiO3kcbfPA0R1PcP5ls1/73M9Utxt39iIVd3ezTRetfHape066+1rV9My9edD0ClUVUfnc
8GD/f0Vn2Rl3/a5W+P9DRU8Vkf1FpLIHbMbuo9yVYP3U32pRFH8sVpwpKmlLcVNV9bnK5i6+5UVp
3seFNleubZ+ZtnsVk+1eD7e0Fd926MUWtuii55aqi2XY+e0XnZJvibqD126frcA7zDG/77btqLA6
e+eV/uTf7r+sfdcX7vDPg8V6lm99tOzivtDFG7svHHbLQ13ct/5fqcvcN4v8Vy8s2Lr9Y2jLFTaX
NldVVRFPuiWYy5ZFmLiIqmZRUXdvFWa333DzV9928arn3NHm/wsRERmbnD5Q3N5kFn7mrY5IKd1R
mH0qOscuqdzp5qaqlVybam6nyeyZKih32IpyV4KiaL1wZmbm9a3WzD7RWQDMUtV/beS+h8oa39wu
UZEnR8+zUwqz64YGVtwanWOXVO7POd9jZlU9VdfRon6YiHT1GlR0ViX/kol0w8avHJGT/qGqUuyA
LuHupjm/7+JVz/1VGeOvm5w+Mqf0e9Hz7BRNep+L/I/oHEt0v6qWsra2G6hqcpcnROdAd6HctZvb
pa3CavPXO9ALcs7/YIVNlTW+q/+6iJwePc9OUdEbhgdW/Ed0jiV6wEXujA5RppzzedEZ0F0od220
bsMtB5vZNUVR2WNmAj0npbSpZfahoYHzf1nG+Os2TB+vor9dl23t9unre9DcemWtnYjIwyLSzYdq
2Wsq8ozoDOgulLs2Mi8uKsweH50DwBxznxDX0k5ob25XmNkJ0fPslE2tmX9yl3+MzrFUw6tXPOxu
P4/OUSZVPXFkvLl/dA50D8pdm6y76UvH5ZR/PzoHgDkqcr+7vG/tmhUPlzH+2IbpxyfVa6Ln2Slm
NuMiVw8P9vfU2xPm/kvV6h4KTnM6yMVPi86B7kG5axNVf7m4nxqdA8AcTekmF/lWGWOvm5xWFf9N
ETkqep6dknL6B3H5l+gcu8vF/zXn6r7cJdX9Ukq8a4Stqvto76B1N91ynLi8rqjJNjdAL2jkxl3m
+sG1gytmSlmA6lkppVebVfcAufPllNxFrx0e7O+5Jzp1uV3cSzsMTrRWqxAR+bXoHOgelLs2cLPf
LYri2OgcAOa03K8bGnhBaduGudsftFrFodHz7BRX+aq4N6Nz7AkV+blVuNyJiCRNR4xONHN0DnQH
yt1eWnfTLU9OKV0RnQPAHHO/R0Q+UNb4Y5NTz9Skq6Pn2SmqKq3C/nh4sL9HC5L/3F16NPuSneQi
j4kOge5AudsL6zZMp6RyTVEUh0RnATAn5fyZodUvKOWI/WMbbtlXVd9uhS2PnmfHuH9HRDZEx9hT
Q4OrHnD3TdE5yuRip7l7bbb/xM5R7vaCizzJCmOtHdBFUs4/cpf/XdoC3J4h4oPR8+yUlFRc9SNr
B/vL2XaxY/NI3X+qtL3g5oduPscsQLnbK+7vMvf9omMAmKWqYu6fGR44/9/KGH/d5HRy8T8w82XR
c+0U1fQNd78xOsfeMrNvR2com7uzxyxEhHK3x8Y2TJ+Xcro4OgeAOUVR3CkiHylrfHN7too+N3qe
nZJSEnf//PBgf8+fm9XcfxidoWyqem50BnQHyt0eGJucXp6S/H7RKuqzzQ3QA1Kjce3Q6vPvLmPs
dZPTByXV97p7bZ43XeT7ovpX0TnaNJv/cPeeOvjy7k+R05BhVm2epNrJxZ4uLquicwCYk1L6rriM
lDW+i6/SVJ+1diIiKvI5EbkrOkc7uMhdZtbT2w3uco7ux113481sKgRpRAfoNWOT030i9t6isH2i
swCYlZKKuX9iaGBFKW+9jU1O7S8if1AUVt1zWG1jn77G/a7pe+Zy9t9s/IqJi4h4UnFxd3dx8y1f
iohvPZbz3EGdfeHxnbd8t6v7cOGtdPbDIjfSrZ91wfc+7xt1FzH3JCqnpVzt17yc0/6tlp8iIpXf
vhA7V+kHehlc/LlJ9Tnu9TgqPdAb9Fua9FNljW5mg6r6pOhZdlJhfqCq/bmKt0Q0qWpWlSSiIq6z
hU7cxMVdxLdWua3fy+ZPmy+Y1/m2WdTC5rbj6229/uxpYlVlS/WbHWGx0uibC2ghIlncG1bh5+6U
8n6aitOFcld7lLvdsG7D9EGa9D1Fq+DtbKBLpJTcVf7bZRe84N4yxh+bnD5OVd5lNTu9YFEUIiIH
RefA0pmZqMhqkfI2T0BvoKTsBne7WDl/H9BdVL8s5jeVtwAfVtUzo6cJLEVO6bDrbri5NpsPYHGU
uyVat2F6f1F9x+YTNAPoAjnnR83sjy8bWHFPGeOPTkw9rjB79+a1WEDXc/GjXJyzJtUc5W6JzIqr
3LxW29wAPeArqjpV1uDu9qakygsleoaqHiMiR0TnQCzK3RKMTU6d2Gg0XhedA8A87g+b2fsvW33+
I2UMPzrRfEIj55dETxPYHSpyvIsfF50DsSh3S+K/3moVp0WnADAn5bzOXb5extgj65uqIq8rzB4X
PU9gd5i7qMgTonMgFuVuF9ZNTj/WXV4fnQPAnJzyfYXZx4cGV2wqY3xNcpqqvjJ6nsCeyDmfFZ0B
sSh3u2BevFlEDo/OAWCOi984PNj/1TLGHlnfTCL+TnM/MHqewJ5w82ddd+PNvL7XGP/5OzE60Tyt
kfPLo3MAmNMqiofM7b+VNb4mOSunxiXR8wT2VOF2rIhwFqUao9ztwNjEVFKVt7QKY8NUoIv0NRr/
a2ig/9/KGHt0fGpZUv3dwoqDo+cJ7Kmc834u/vjoHIhDudsRldNV04ujYwCYk1K63d3/qrQFqJ+X
Uro4ep7A3kiqjZTSE6NzIA7lbhEj480s4m8zs8dEZwEwx8X/bGiw/5/KGHt0fGofEXlnqyh4Ows9
T0XWRmdAHMrdIlTlaSnloegcAOao6m3q8pmyxnfx56aUV0bPE2iHlPLBI+Mbc3QOxKDcbWN0YmpZ
SultVhSstQO6REpJXOXPLh1YcUcZ44+MNw/KOb3NrOiLnivQDqp+mIsfGp0DMSh323tW0vRCj04B
YCtV/aa4/HV5S/DVqspaO1TG5tOQHROdAzEod/OMjDf3cbcPtooWf70DXSKpSlEUHx0aWPGTMsYf
GW8uN7P3FEWh0XMF2sXMD3e3k6NzIAblbh53G+prNM6LzgFgjol8W1X/T2nju72i0WicHj1PoJ3c
XVSVcldTjegA3WJscurgnNPrWq0iOgqArdTd/dqhwf6Hyhh9dLx5hCa90p0NMVA9qulJ0RkQgzV3
cy5ptYrnRIcAMCfnNK2qN5Y1vou93N2fFj1PoAzu/ozr19+8b3QOdB7lTkTGJqaWq8g7o3MAmJNT
spbZx4cGVtxbxvhjE1OPcZfXR88TKIsVfoK77BedA51HuRMRc7tSRDhVC9BFCve/Gx5YMVLW+GbF
m1JKJ0bPEyhLzmlfdz8lOgc6r/blbt3k9DH79PVdaWxzA3QNM9vk5u8ta/zR8ebJudF4efQ8gTKp
alLVM6JzoPNqX+5c/dWtovWU6BwA5jQajRtV9W/LGHtk/cYkIq83s9Oi5wmUTZP+WnQGdF6ty93o
xNThbv4aVtoB3SNpeqAoio8PDa54pIzxNekpqvLS6HkCnaCiT77+hps5MkbN1LrcudtVwhG8ga7i
4p8bXrOyWcbYI+ubKu5vcJEjoucJdEJSOcTVHxudA51V23I3Njl9eqPRuCo6B4A5SdNdLv6xssZX
lbNzo8FaO9RGyulIETk+Ogc6q5blbmS8qar+ZjPjAQ90CVUVUfn80ED/d8oYf3S8uVxVrjEzTqaO
2jDzQ9z9zOgc6KxaljsRf2KrVbySo9IDXeU/zezPyxrcxZ9RmL+I33vUibtLTulx0TnQWbUrd9eP
N1VU351S2ic6C4A5hflfDg32/0cZY4+ON7OqviclZcNy1E7K+Ykj482+6BzonNqVOxF/TqORL4xO
AWCOi/9IVT5e1vjmdmEjZ04viLp6vAhnqqiTWpW7kfFmX1/Ov1W0iv2jswCYYy6fGB7s/2UZY4+O
Nw/MOb+6VRS1er4DtjCzU13k4Ogc6JxaPdm5+zNaRTEcnQPAAv8o4p8qa3BzGzCzwehJAoH2d3d2
IKyR2pS70YmpRs7p3aqao7MAmNMy+9Tla1b9ooyxR8eby5Kmt6lqbZ7rgG2pqqakZ0XnQOfU5gnP
xS/OOfVH5wAwx8y+n5J+srzxi5fknM6NnicQLWl6fnQGdE4tyt3YxNTB+zQaV7dabHMDdBXVD60d
XNkqY+jR8eYxjUbjqsIsepZAOHc7Y3SiyTtXNVGLsuNua4qiYE85oIuo6gZVua68JfiLXeSZ0fME
ukFK6SAXOTw6Bzqj8uVudGLqoJTzW40DlwLd5NHCig8PD668q4zBR8ebh2lKb+SAxcCsnNLhIn5S
dA50RuXLnXlxqaqeHZ0DwDyq00nTLWUN726/LpxPE9iqVRT7mPn/F50DnVHpo7WPjDePbTTy1UVR
REcBsJmq3utuHxoaXLmpjPFHxpun56yvNWOtHbDF5tOQHROdA51R6TV3qvIyd2f3b6C7bHCXr5c1
uIq/SlweHz1JoNvkpKeMjjc59WYNVLbcjaxvHjXTal1t7CkHdA+Xuwor/mR4zcoHyxh+dHzjKYXZ
G9jGFtiei57kLo+JzoHyVfZt2U2bHnl5y/w+K6ylqllVVERVNn+Yfep3l9m11b7l683fL2p2jM23
dZl/LV/wSWTbEXTBp+1+vqhdjbmDcXSxq8+fkm8zkm/z4+55VfSuyNOO5etOLtHdGGexQRf5v5/3
M537uKMF7fqxsZPF69Z/s79bKmn2kKmzP1MRdZn9/TLzVkr6xZT0/7XhPt3O6HhTRf2dOed9yxgf
6HVW2Mnu/lgRuTM6C8pV2XL3kssuvPZz6yY/bDpbxWZfiUxkm9e4za96C5vNXPtbYHPr0x284u2i
BCx8FV9Sb/HtX213Ou5c9ZSdvpRvH2TB9ZfeZkruXXtcezpkz6fv877SPR1pwY1UFh/GF15jtoMt
co1F/qLZSajNvzY67+G25Rdo83R8boQti0wq8qIXDpRyTLvZpfmzG43GcNFiG1tgUSoHmPjjROTW
6CgoV2XLnYjISy8dmInOAKB8o+PNPk36qqJVHBSdBehmSfSpIjIVnQPlquw2dwDqxM81s5dGpwC6
nSZ5bnQGlK/Sa+4AVN/oxNS+qvJOce+LzhLF3QuZfbtcVVV3ft1t3jdf2mYBOv/zrpZR6lxFXHa2
w8zmDT6XeL955FwimPmTojOgfJQ7AD3NxVY1cuOCVg23tVPVXxVm73WRW11URVzFN29ivKCzzS8w
c/tQ7WzbX932tnN78OjCoZeyY9giFyxtS9Mdbki68IJ5EX3hhsTbdr2tX/nsVtRJJSfRP3T3M3b7
P6AH5ZQO+MIXNxx1xQtX3xGdBeWh3AHoWaMTzf2T6utaraKWx+4ys42i8rHLB1c+Gp2lV10/0UyF
+OokUo9yl/OhraJ1kohQ7iqMbe4A9C73AVG9IDpGzNT9QRf/0FqK3V5ZO9hvIvK96Byd4uINEXly
dA6Ui3IHoCeNTUw9RlN6S10PVK4pTanqP0fnqAJV+WF0hk4xM8k5PS06B8pFuQPQk8ztkpzSM6Nz
RMgpP+LunxkeXPlwdJYqSCJ3bd4ppRZSyseNjG9cFp0D5aHcAeg5oxPNY1JKb28VtXk9XsDFv6Yq
zegcVaEqD+WUavP2tqqeKCKHRudAeSh3AHqOi691r+8hHVpF6xNDA/2/is5RHXpXSunn0Sk6xdxP
cZFjo3OgPJQ7AD1ldHL6CDd5Z3SOMO7fE5HJ6BiV4n6fiPwiOkYH57vMRY6JjoHyUO4A9BT34rU5
pyOjc0Qxkf+1ds2q+6NzVIm7POjid0bn6KSkemZ0BpSHcgegZ4xONE9vpMZVvsQj4FZNSulbqvK5
6BxVc9lg/0Pu/svoHJ2kok+PzoDyUO4A9AwVuaqw4nHROaIUZn89PLjy9ugcVeTmM9EZOsnMnhid
AeWh3AHoCWOT06ea+5XROaKY2x2i8rHoHFXl4l/LqU4viX7YdTfedHh0CpSjTo9kAD1qbHI6ifhb
VPXA6CxhNH1ieKCfbe1K4i7/aeK1ORxKzo3HuPip0TlQDsodgB7g5zVy46XRKaLklH4oIn8dnaPS
VG4zs4eiY3SOq4o8NToFykG5A9DVxjZMZ1V520xrppZr7VRVTOSLawf7b43OUnF3uEltzvjh7pJS
fnZ0DpSDcgegq7nbM939ougccfP3+8yKj0fnqLrhwZUPmFitdqrIKR05sn7j8ugcaD/KHYCuNbZh
ej8VudZd9onOEkb1C8ODK78bHaMOkqb6HMh41jEuwk4VFUS5A9C1zIoLc8rPis4RpZEbD7j7Z6Jz
1EUj6TejM3SSi5xk7idH50D7NaIDAMBixianDxDxt7aKIjpKHJWmqn4jOkZdmPi/i2h0jI5x92XK
acgqiXIHoCu52woROS86R5ScU9EqWp8cGujfFJ2lLlTke3U794mqcjDjCuJtWQBdZ2xy+mBV/UB0
jkiF+deHBvrHo3PUiare7u4WnaOjcxY5NzoD2o9yB6DrmBcvTimdHp0jbP5mM2b23ugcdZNUH27k
/Eh0jk4qzJ70hS9u4F28iqHcAegqo5NTj8spvb6o8bZ2jUZjSlW/Fp2jblTT/Snnn0fn6Oyk5TB3
Z4/ZiqHcAegq7naFmdd2O6BGoyGF2WeHB/sfjM5SNyrygKrcFZ2jkxo57ycqp0XnQHtR7gB0jc1r
7a6OzhGpKIpvqPgN0TnqSe9Xl3qtuRMRUXlGdAS0F+UOQNdwt9eI6HHROSIVZtcODa5krV2Ai1Y9
f8Zcfhado9NySs+JzoD2otwB6AqjE1NnNHLjVWa12llxgZTSP4vIdHSOOjMr7onO0Gk5NQ6/fv3N
tTx3c1VR7gB0CX9PURSPi04RJecs5vaXa9esvDM6S525yrcajRwdo6M06ZEiWtvfvSqi3AEINzrR
fLKqXhSdI5K73ermfxOdo+5c5GciUqsDR6eUjhGl3FUJ5Q5AqJH1zX1E5D3uvjw6S6RWYR8dXrPy
J9E5ID8z8/ujQ3RSq9VaJiJPiM6B9qHcAYilck5OaSA6RrDb3H1ddAiIuMgv3f2B6BydllRPjs6A
9qHcAQjlbu8tzPaNzhF6H4h86vILV90enQMi7n6Pu9fqLBUiIipy5vXrN3Kmioqg3AEIc/36jU9T
1RXROSKlpHe4FV+MzoFZw6tXuKjW7u3xVlE80UVqvWlElVDuAIQYGZ/aX1X/SFVr/TzUMlu39sIL
/l90DsxxkW9FZwhwiIgfEh0C7VHrJ1UAcVxsTV8j90fniNRqtR5wsw9E58BCZsX3ojN0Ws5pX3Pn
NGQVQbkD0HEj41P7JdWrW0VR6+eg3MjXX37hBf8VnQPb+W50gBj+7OgEaI9aP7ECiOFuLxSRZ0Xn
iKRJ73H3T0bnwPZU9HZ3L6JzdFoSfV50BrQH5Q5AR42MTz22kdPV0TmiuflN4vKP0TmwPRV5WGp2
IGMRkZzzodev31jrPdergnIHoKNc/KWa0tOjcwR7uHD/8NoLV81EB8H2ksoDjZxr93Z5SumxInp8
dA7sPcodgI65frz5WDe7uihq947Xtv5WRdhDtkup6COqWrtz/CbVI1WFgxlXAOUOQOe4vznndGJ0
jEg5pU0i8uG1a1a2orNghx4RkToe665PRM6OzoG9R7kD0BEj483jc04vi84RzUX+zt3/PjoHduzS
wf6Wu98WnSNCSnpcdAbsPcodgNJdt35jEpW3uXutt+dJObVaRfGR4TUr747Ogp1z93uiMwR5whdu
2LBfdAjsHcodgNKpyulJ9UXROaK1Zoqfisgt0TmwJD9r5BydoeOKoniiux0UnQN7h3IHoFQj482c
VN/u7o+NzhJNVf9w7ZqV90XnwK5p0h+kpA9F5+j8xPUwVeE0ZD2OcgegVCnpuZp0KDpHNFW9VcRv
js6BJVL9pbs/GB2j05JqI6X0hOgc2DuUOwClWTc5vW9O6R1ufmB0lkgpqYjIp4fXrPxpdBYsjYr+
wkRquZa1r9HgNGQ9rhEdAEB1WVEc4p72beTGraqiLmKbL0qqoiqaRETn3cQXGcZdXMTF510++7Mt
t3HZMZ37uPlLXfQaiy7f3Xeca/OYOn+MbccWc9tUFPZ9Ef9sh+52tIGb3evuX2/kPJNzXi4i6uJb
Hr+isvlBsflx6bMPyfmPT9/mkelLXvg2UeYtcj7d9qNuE00WfjH/90dk9vdPt17kYrOZvVUUBd2g
x+neDwEAO3bdjc2jJGmy2ReWlogUqiKqknS25IkufHGS7QqY++YXoc0fFzYun/+zBXT7r3Thh53b
ZWncptNtMwEXUXF3N390eE3/XR2/87FXxiamD5Uk+7qomoi5SyHituUxu/3jdvPj1EUXPHh864c9
Ne/GuuMHrm775ZY4srDc6dZx0ubvt/xk9nFr/uhlAytqdxBnAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAECF/P+CziJssWg15AAAACV0RVh0ZGF0ZTpj
cmVhdGUAMjAyMC0wOS0wMVQxMToxMDozNSswMzowMDOiCicAAAAldEVYdGRhdGU6bW9kaWZ5ADIw
MjAtMDktMDFUMTE6MTA6MzUrMDM6MDBC/7KbAAAAAElFTkSuQmCC"
      />
    </svg>
  );
};

const SettingsIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3 1C11.5761 1 11.8 1.22386 11.8 1.5V3.02683C12.4606 3.19687 13.0846 3.45831 13.6576 3.79688L14.7376 2.71681C14.9329 2.52155 15.2495 2.52155 15.4447 2.71681L17.2832 4.55529C17.4785 4.75055 17.4785 5.06713 17.2832 5.26239L16.2031 6.34247C16.5417 6.91543 16.8031 7.53937 16.9732 8.2H18.5C18.7761 8.2 19 8.42386 19 8.7V11.3C19 11.5761 18.7761 11.8 18.5 11.8H16.9732C16.8031 12.4607 16.5417 13.0847 16.2031 13.6576L17.283 14.7376C17.4783 14.9329 17.4783 15.2495 17.283 15.4447L15.4446 17.2832C15.2493 17.4785 14.9327 17.4785 14.7375 17.2832L13.6574 16.2032C13.0845 16.5417 12.4606 16.8031 11.8 16.9732V18.5C11.8 18.7761 11.5761 19 11.3 19H8.7C8.42386 19 8.2 18.7761 8.2 18.5V16.9732C7.53937 16.8031 6.91543 16.5417 6.34247 16.2031L5.26239 17.2832C5.06713 17.4785 4.75055 17.4785 4.55528 17.2832L2.71681 15.4447C2.52155 15.2495 2.52155 14.9329 2.71681 14.7376L3.79688 13.6576C3.45831 13.0846 3.19687 12.4606 3.02683 11.8H1.5C1.22386 11.8 1 11.5761 1 11.3V8.7C1 8.42386 1.22386 8.2 1.5 8.2H3.02683C3.19686 7.5394 3.45828 6.91549 3.79682 6.34256L2.71664 5.26238C2.52138 5.06712 2.52138 4.75054 2.71664 4.55527L4.55512 2.7168C4.75038 2.52153 5.06696 2.52153 5.26222 2.7168L6.34236 3.79693C6.91535 3.45834 7.53932 3.19688 8.2 3.02683V1.5C8.2 1.22386 8.42386 1 8.7 1H11.3ZM10 15.4C12.9823 15.4 15.4 12.9823 15.4 10C15.4 7.01766 12.9823 4.6 10 4.6C7.01766 4.6 4.6 7.01766 4.6 10C4.6 12.9823 7.01766 15.4 10 15.4Z"
      fill="#525D66"
    />
    <circle cx="10" cy="10" r="2" fill="#525D66" />
  </svg>
);

const DeliveryIcon = ({ customWidth, customHeight, giveFloat }) => {
  return (
    <svg
      width={`${customWidth ? customWidth : '48'}`}
      height={`${customHeight ? customHeight : '48'}`}
      style={{ float: `${giveFloat ? 'left' : ''}` }}
      viewBox="0 0 32 32"
      fill="#ADB6BC"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 18l-4-8h-6v-4c0-1.1-0.9-2-2-2h-18c-1.1 0-2 0.9-2 2v16l2 2h2.536c-0.341 0.588-0.536 1.271-0.536 2 0 2.209 1.791 4 4 4s4-1.791 4-4c0-0.729-0.196-1.412-0.536-2h11.073c-0.341 0.588-0.537 1.271-0.537 2 0 2.209 1.791 4 4 4s4-1.791 4-4c0-0.729-0.196-1.412-0.537-2h2.537v-6zM22 18v-6h4.146l3 6h-7.146z"
      ></path>
    </svg>
  );
};

const NewAccountIcon = ({ customWidth, customHeight, giveFloat }) => {
  return (
    <svg
      className="float-left inventory_Accounts"
      width={`${customWidth ? customWidth : '48'}`}
      height={`${customHeight ? customHeight : '48'}`}
      style={{ float: `${giveFloat ? 'left' : ''}` }}
      viewBox="0 0 28.438 26.25"
    >
      <path
        className="a"
        d="M10.938-26.25v12.031H24.063V-26.25Zm2.188,2.188h2.188v4.375L17.5-21.875l2.188,2.188v-4.375h2.188v7.656h-8.75ZM3.281-12.031V0H16.406V-12.031Zm15.313,0V0H31.719V-12.031ZM5.469-9.844H7.656v4.375L9.844-7.656l2.188,2.188V-9.844h2.188v7.656H5.469Zm15.313,0h2.188v4.375l2.188-2.187,2.188,2.188V-9.844h2.188v7.656h-8.75Z"
        transform="translate(-3.281 26.25)"
      />
    </svg>
  );
};

export {
  PreferencesIcon,
  NewAccountIcon,
  CurrencyIcon,
  WarehouseIcon,
  TaxIcon,
  InventoryIcon,
  SettingsIcon,
  DeliveryIcon,
  PrefixPrefIcon,
  numberPreferencesIcon,
};
