import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { restRequest } from '../../../Helpers/RequestHelper';
import { withFormik } from 'formik';
import {
  required,
  ValidateName,
  ValidatePercentage,
  ValidateMax50,
} from '../../../Helpers/Validator';
import { validateCreateTaxorm } from '../../../Helpers/FormValidator';
import { confirmationAlert } from '../../../Helpers';
import { Field, Form, FormAction, ErrorFocus } from '../';

const formEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: required(ValidateName),
    rate: required(ValidatePercentage),
    authority: ValidateMax50,
  }),
  mapPropsToValues: ({ tax }) => ({
    ...tax,
  }),
  handleSubmit: (values, bag) => {
    handleFormSubmit(values, bag);
  },
});

const handleFormSubmit = (
  values,
  {
    setSubmitting,
    props: {
      requestType,
      requestUrl,
      inModal,
      onSubmitModal,
      taxValueFetchAutomatically = () => null,
      history,
      successMessage,
      handleToast,
    },
  }
) => {
  const { name, rate, authority } = values;
  restRequest(requestType, requestUrl, {
    name,
    rate,
    authority,
  })
    .then((res) => {
      if (inModal) {
        onSubmitModal(res.data);
        taxValueFetchAutomatically(res.data);
        if (history?.location?.pathname.includes('/taxes')) {
          history.push('/taxes');
        }
      } else {
        history.push('/taxes');
      }
      successMessage();
    })
    .catch((error) => {
      let errorMess = error?.response?.data?.errors?.name[0];
      if (errorMess && errorMess === 'The name has already been taken.') {
        handleToast(`Tax of the same name is already added`, 'error');
      } else {
        handleToast(error?.response?.data?.message, 'error');
      }
      setSubmitting(false);
    });
};

const closeAndBack = (history, closeModal, totalRecords, modelSource) => {
  if (!modelSource) {
    closeModal();
  } else {
    closeModal();
    if (totalRecords === undefined || totalRecords === 0) {
      history.push('/settings');
    }
  }
};

const TaxForm = (props) => {
  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    isSubmitting,
    submitCount,
    errors,
    touched,
    // custom props
    inModal,
    closeModal,
    history,
    title,
    totalRecords,
    modelSource = '',
    handlePrompt,
  } = props;

  const [staticVal, setStaticValues] = useState({});

  useEffect(() => {
    handleAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, staticVal]);

  useEffect(() => {
    setStaticValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAlert = () => {
    let { checkout } = validateCreateTaxorm(values, staticVal);
    handlePrompt(!checkout);
    return checkout;
  };

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  const handleCancel = () => {
    let status = handleAlert();
    let response = true;
    if (!status) {
      response = confirmationAlert();
    }
    if (status || response) {
      if (inModal) closeAndBack(history, closeModal, totalRecords, modelSource);
      else history.push('/taxes');
    }
  };

  return (
    <Form title={title} onSubmit={handleSubmit} className="tax-form">
      <div className='form-fields-container d-flex flex-wrap  position-relative w-100'>


        <Field
          autoFocus={true}
          className="flex-33"
          name="Tax Name"
          value={values.name}
          id="name"
          required
          handleChange={handleChange}
          error={touched.name && errors.name}
          onBlur={handleBlur}
        />
        <Field
          className="flex-33"
          type="number"
          name="Tax Rate"
          value={values.rate}
          decimalScale={6}
          id="rate"
          suffix=" %"
          required
          onValueChange={(value) => setFieldValue('rate', value.floatValue)}
          onBlur={handleBlur}
          error={(touched.rate || submitCount > 0) && errors.rate}
        />

        <Field
          className="flex-33"
          name="Tax Authority"
          value={values.authority}
          id="authority"
          handleChange={handleChange}
          onBlur={handleBlur}
          error={touched.authority && errors.authority}
        />
      </div>
      <FormAction disabled={isSubmitting} onCancel={handleCancel} />
      <ErrorFocus />
    </Form>
  );
};

export default formEnhancer(TaxForm);
