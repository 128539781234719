var __url__backend__ = process.env.REACT_APP_BACKEND;
var __url__SEEBIZ__backend__ = process.env.REACT_APP_SEEBIZ_BACKEND;
var __url_sso__ = process.env.REACT_APP_SSO_REDIRECT_DEV;

var __url_seebiz__cloud__ = process.env.REACT_APP_SEEBIZ_CLOUD_URL;
var url_fe = process.env.REACT_APP_URL;

module.exports.restPath = function (endpoint) {
  return __url__backend__ + '/api/v1/' + endpoint;
};

module.exports.restSeebizPath = function (endpoint) {
  return __url__SEEBIZ__backend__ + '/api/1.0/' + endpoint;
};

// Get only the address of the backend.
module.exports.backendPath = function () {
  return __url__backend__;
};

// Get SSO Login URl
module.exports.ssoSignInPath = function () {
  return `${__url_sso__}/login?serviceUrl=${url_fe}`;
};

// Get SSO LogOut URl
module.exports.ssosignoutPath = function () {
  return `${__url_sso__}/logout?lg=im`;
};

// Get SSO SignUp Link
module.exports.ssoSignUpPath = function () {
  return `${__url_sso__}/signup?serviceUrl=${url_fe}`;
};
// SSO forget password path
module.exports.ssoforgetPassowrdPath = function () {
  return `${__url_sso__}/forgot_password?serviceUrl=${url_fe}`;
};

// My account or profile path
module.exports.ssoMyProfilePath = function () {
  return `${__url_sso__}/myProfile`;
};

module.exports.seebizCloudUrl = function () {
  return `${__url_seebiz__cloud__}`;
};
