import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { withFormik, FieldArray } from 'formik';
import { restRequest } from '../../../Helpers/RequestHelper';
import {
  moreThanZero,
  required,
  ValidateDate,
  ValidateRequired,
  ValidateMax50,
  ValidateMax10Billion,
} from '../../../Helpers/Validator';
import getDate from '../../../Helpers/Date/GetDate';
import Field from '../Field/Field';
import Form from '../Form';
import { getError, validate, formSubmit } from '../FormHelper';
import ListTableImageColumn from '../../ListTable/ListTableImageColumn';
import AddItem from '../AddItem';
import Quantity from '../../Fragments/Quantity/Quantity';
import ErrorFocus from '../ErrorFocus';
import Loader from '../../Loader/Loader';
let postWarehouses = [];
let restRequestStop = true;

const getYupValidationSchema = () => {
  return Yup.object().shape({
    packageNo: required(ValidateMax50),
    // salesOrderId: required(ValidateNumber),
    packageDate: required(ValidateDate),
    items: Yup.array()
      .of(
        Yup.object().shape({
          name: ValidateRequired,
          quantity: required(
            moreThanZero(ValidateMax10Billion).integer('Invalid Value')
          ),
        })
      )
      .min(1, 'At least one item is required'),
  });
};

const formEnhancer = withFormik({
  validate: validate(getYupValidationSchema),
  mapPropsToValues: ({ packageInfo }) => ({
    ...packageInfo,
  }),
  mapPropsToStatus: (props) => ({
    itemsList: props.itemsList,
    emptyItem: props.emptyItem,
    rrId: props.rrId,
  }),
  handleSubmit: (values, bag) => {
    handleFormSubmit(values, bag);
  },
});

const handleFormSubmit = (
  values,
  {
    setSubmitting,
    props: {
      submitRequestType,
      submitRequestUrl,
      successMessage,
      inModal,
      close,
      onSubmit,
      history,
      handleToast,
    },
  }
) => {
  if (restRequestStop) {
    restRequest(submitRequestType(), submitRequestUrl(), {
      sales_return_id: values.salesOrderId,
      return_receives_no: values.packageNo,
      customer_id: values.customerId,
      sale_order_id: values.saleOrderId,
      return_receive_date: values.packageDate
        ? values.packageDate.format('YYYY-MM-DD 00:00:00')
        : null,
      items: values.items.map((item, index) => ({
        item_id: item.id,
        warehouse_id: postWarehouses[index],
        sales_order_item_detail_id: item.salesOrderItemDetailId,
        damaged_reason: null,
        package_item_detail_id: item.packageItemDetailId,
        quantity: item.quantity || 0,
      })),
    })
      .then(() => {
        if (inModal) {
          close();
          onSubmit();
        } else {
          history.push('/salesreturns');
        }
        successMessage();
      })
      .catch((error) => {
        handleToast(error, 'error');
        setSubmitting(false);
      });
  }
  setSubmitting(false);
};

const PackageForm = ({
  // props from formik
  values,
  status,
  handleSubmit,
  // handleChange,
  // handleBlur,
  setFieldValue,
  setStatus,
  // setValues,
  // isSubmitting,
  // submitCount,
  errors,
  touched,
  setFieldTouched,

  // custom props
  title,
  // inModal,
  warehouseListRecieve,
  genericWarehouseId,
  warehouseResult,
  // type,
  // salesOrderList,
  // history,
  // close
}) => {
  const { itemsList, emptyItem } = status;
  postWarehouses = warehouseListRecieve;
  var packageFakeItem = false;

  const handleSelectedItem = (option, index) => {
    const newItem = { ...option, quantity: values.items[index].quantity };
    const updatedItems = [
      ...Object.assign([...values.items], { [index]: newItem }),
    ];
    setFieldValue('items', updatedItems);
    setStatus({
      ...status,
      itemsList: status.itemsList.filter((item) => item.id !== option.id),
    });
  };

  const renderAddItem = () => {
    const { items } = values;
    items.forEach((item) => {
      if (item.id == null) {
        packageFakeItem = true;
      }
    });
    if (packageFakeItem) {
      return '';
    } else {
      return (
        <AddItem
          onClick={() => setFieldValue('items', [...values.items, emptyItem])}
        />
      );
    }
  };

  const getvals = () => {
    if (itemsList.length === 0) {
      setAddItem(false);
    }
    setWarehouseNames([]);
    if (yikes[0] !== null) {
      for (let i = 0; i < yikes.length; i++) {
        for (let j = 0; j < warehouseList.length; j++) {
          if (yikes[i] === warehouseList[j].id) {
            let arr = [];
            arr = warehouseNames;
            arr.push(warehouseList[j].name);
            setWarehouseNames(arr);
          }
        }
      }
    } else {
      for (let i = 0; i < values.items.length; i++) {
        for (let j = 0; j < warehouseList.length; j++) {
          if (genericWarehouseId === warehouseList[j].id) {
            let arr = [];
            arr = warehouseNames;
            arr.push(warehouseList[j].name);
            setWarehouseNames(arr);
          }
        }
      }
    }

    let arr = [];
    arr = warehouseNames;
    arr.splice(yikes.length);
    setWarehouseNames(arr);
  };

  const [flag, setflag] = React.useState(false);
  const [addItem, setAddItem] = React.useState(false);
  const [warehouseList, setWarehouseList] = React.useState({});
  const [warehouseNames, setWarehouseNames] = React.useState([]);
  const [yikes, setyikes] = React.useState([]);

  useEffect(() => {
    setWarehouseList(warehouseResult);
    setyikes(warehouseListRecieve);
    setflag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSelectedItemsTable = () => {
    const { items } = values;
    if (warehouseList.length > 0) {
      for (let i = 0; i < items.length; i++) {
        for (let j = 0; j < warehouseList.length; j++) {
          if (items[i].warehouse_idd === warehouseList[j].id) {
            items[i].warehouse_idd = warehouseList[j].name;
          }
        }
      }
    }
    let errorArray = [];
    let errorArray1 = [];
    for (let index = 0; index < items.length; index++) {
      if (
        items[index].totalQuantity <
        items[index].quantityForErrorCheck + items[index].quantity
      ) {
        errorArray[index] = true;
      } else {
        errorArray[index] = false;
      }
      if (items[index].quantityForErrorChec < items[index].quantity) {
        errorArray1[index] = true;
      } else {
        errorArray1[index] = false;
      }
    }

    for (let index = 0; index < errorArray.length; index++) {
      if (errorArray[index] === true) {
        restRequestStop = false;
        break;
      } else {
        restRequestStop = true;
      }
      if (errorArray1[index] === true) {
        restRequestStop = false;
        break;
      } else {
        restRequestStop = true;
      }
    }
    return (
      <div className="package_details_table return_recieve_table_border float-left w-100 so-tbletabs">
        {!flag ? <Loader /> : null}
        <table
          className="list-table form-table border"
          style={{ width: '100%' }}
        >
          <thead className="table-header">
            <tr className="table--row">
              <th>Items</th>
              <th>Warehouse</th>
              <th className="right-align">Return Qty.</th>
              <th className="right-align">Received Qty.</th>
            </tr>
          </thead>
          <tbody onLoad={getvals()}>
            {items.map((item, index) => {
              return (
                <tr key={index}>
                  {item.id ? (
                    <td>
                      <ListTableImageColumn
                        name={item.name}
                        images={item.images}
                        sku={item.sku}
                      />
                    </td>
                  ) : (
                    <td className="no padding">
                      <Field
                        type="dropdown"
                        value={item.id}
                        options={itemsList}
                        dropdownValue="id"
                        dropdownLabel="name"
                        placeholder="Select Item"
                        additionalSearchFilter="sku,upc,mpn,ean,isbn"
                        clearable={false}
                        handleChange={(option) =>
                          handleSelectedItem(option, index)
                        }
                        error={getError(
                          errors,
                          touched,
                          `items[${index}].name`
                        )}
                        onBlur={() =>
                          setFieldTouched(`items[${index}].name`, true)
                        }
                      />
                    </td>
                  )}

                  <td>
                    <Field
                      size="large"
                      id="warehouseId"
                      value={
                        typeof item.warehouse_idd === 'string'
                          ? item.warehouse_idd
                          : ''
                      }
                      disabled={true}
                      clearable={false}
                      required
                      error={touched.warehouseId && errors.warehouseId}
                    />
                  </td>
                  <td className="right-align">
                    <Quantity quantity={item.ordered} unit={item.unit} />
                  </td>
                  <td className="right-align">
                    <Quantity quantity={item.quantity} unit={item.unit} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <Form
      title={title}
      className="package-form"
      onSubmit={(event) =>
        formSubmit(event, values.items, setFieldValue, handleSubmit)
      }
    >
      <div className="float-left w-60">
        <div className="float-left w-100 pt_10">
          <div
            className="returnreceivefont float-left semi-bold"
            style={{ width: '27%' }}
          >
            Sales Return Date
          </div>
          <span style={{ float: 'left', width: '5%' }}>:</span>
          <p className="float-left mb-0" style={{ width: '68%' }}>
            {getDate(values.saleReturnData)}
          </p>
        </div>
        <div className="float-left w-100 pt_10">
          <div
            className="returnreceivefont float-left semi-bold mb_10"
            style={{ width: '27%' }}
          >
            Sales Return No.
          </div>
          <span style={{ float: 'left', width: '5%' }}>:</span>
          <p className="float-left mb-0" style={{ width: '68%' }}>
            {values.saleReturnNo}
          </p>
        </div>
      </div>
      <div className="float-left w-40">
        <div className="float-left w-100 pt_10">
          <div
            className="returnreceivefont float-left semi-bold"
            style={{ width: '45%' }}
          >
            Return Receive Date
          </div>
          <span style={{ float: 'left', width: '5%' }}>:</span>
          <p className="float-left mb-0" style={{ width: '50%' }}>
            {getDate(values.packageDate._i)}
          </p>
        </div>
        <div className="float-left w-100 pt_10">
          <div
            className="returnreceivefont float-left semi-bold mb_10"
            style={{ width: '45%' }}
          >
            Return Receive No.{' '}
          </div>
          <span style={{ float: 'left', width: '5%' }}>:</span>
          <p className="float-left mb-0" style={{ width: '50%' }}>
            {values.packageNo}
          </p>
        </div>
      </div>
      <div
        className="float-left w-100"
        style={{ borderBottom: '1px solid var(--border-light)' }}
      ></div>
      <div className="float-left w-100 pt_10 mb_10">
        <div
          className="returnreceivefont float-left semi-bold"
          style={{ width: '100%' }}
        >
          Return Reason
        </div>
        <p
          className="float-left"
          style={{ wordBreak: 'break-word', width: '100%' }}
        >
          {values.returnReason}
        </p>
      </div>

      {/* {console.log('valuesasssas' , values.items)} */}
      {/* {itemDeleteFlag && getWarehousesLatest()} */}
      <div className="form-field form-table--container">
        <FieldArray name="items" render={() => renderSelectedItemsTable()} />
        {addItem && <React.Fragment>{renderAddItem()} </React.Fragment>}
      </div>
      {/*<button
      type="button"
      className="action-buttons--submit next-btn"

      onClick={close}
    >
      Close
    </button>*/}
      {/* <FormAction disabled={isSubmitting} onCancel={() => inModal ? close() : history.push('/packages')} /> */}
      <ErrorFocus />
    </Form>
  );
};

export default formEnhancer(PackageForm);
