import React, { Component } from 'react';
import {seebizCloudUrl} from '../../../Helpers/UrlHelper'
import './DashboardFooter.css';

var d = new Date();
var n = d.getFullYear();
const seebizUrl = seebizCloudUrl();

export default class DashboardFooter extends Component {
  render() {
    return (
      <div className="dashboard_footer">
        <p>Copyright &copy; {n} SeeBiz Inc.</p>
        <p>
          <a href={`https://www.${seebizUrl}/page/terms`} target="blank">
            Terms & Conditions
          </a>{' '}
          |{' '}
          <a href={`https://www.${seebizUrl}/page/privacy`} target="blank">
            Privacy Policy
          </a>{' '}
          | <a href="tel:+1 213 986 9911">+1 213 986 9911</a> |{' '}
          <a href="mailto:support@seebiz-inventory.com">
            support@seebiz-inventory.com
          </a>
        </p>
      </div>
    );
  }
}
