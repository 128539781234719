import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
//import { Link } from 'react-router-dom';

import './Nav.css';

export default ({ links = [] }) => {
  const currentLocation = useLocation();
  const [Toggle, setToggle] = React.useState(false);
  const OpenSidebar = () => {
    setToggle(true);
  };
  /*const closeSidebar = () => {
    setToggle(false);
  };*/
  return (
    <>
      <div
        className={`ecom_mob_nav ${
          currentLocation.pathname === '/integrations'
            ? 'mob_nav_show'
            : 'mob_nav_show'
        }`}
        onClick={OpenSidebar}
      >
        <i className="fas fa-cog"></i>
      </div>
      {/* <div className='ecom-nav-overlay'></div> */}
      <nav
        className={`${Toggle ? 'ims-sidenav ecom-nav-show' : 'ims-sidenav'}`}
      >
        {/* <div className="sidenav-heading">
          <h4>My Apps</h4>
          <i className="fas fa-cog"></i>
          <i className="fas fa-times" onClick={closeSidebar}></i>
        </div>
        <div className="sidenav-heading bg-gray">
          <h2>SeeBiz App Store</h2>
          <Link to="/integrations">View All</Link>
        </div> */}
        {links.map((link, index) =>
          link.show ? null : (
            <NavLink
              key={index}
              exact
              to={link.path}
              className="ims-sidenav--link"
              activeClassName="ims-sidenav--link active"
              title={link.title}
            >
              {link.title}
            </NavLink>
          )
        )}
        {/* <div className="sidenav-heading bg-gray">
          <h2>Preferences</h2>
        </div> */}
      </nav>
    </>
  );
};
