import React from 'react';
import { CustomDropdown } from '../index';

const DropDownType = ({ menu }) => {
  const {
    className = 'transaction_Dropdwon mr-10 sales_order_dropdwon',
    options = null,
    dropdownLable = '',
  } = menu;
  return (
    <CustomDropdown
      textLabel={dropdownLable}
      className={className}
      options={options}
      {...menu}
    />
  );
};
export default DropDownType;
