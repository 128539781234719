import React from 'react'

import './Dots.css'

export default ({
  count = 2,
  page
}) => (
  <div className="dots">
    {[...Array(count)].map((_, i) =>
      <div key={i} className={`dots--dot${page === i ? ' active': ''}`} />
    )}
  </div>
)