import React, { Component, Fragment } from 'react';
import { restRequest } from '../../Helpers/RequestHelper';
import CurrencyIcon from '../../Assets/General/CurrencyIcon';
import ListTable from '../../Components/ListTable/ListTable';
import HeaderWrapper from '../../Components/HeaderWrapper/HeaderWrapper';
import CustomModal from '../../Components/CustomModal/CustomModal';
import './PaymentModeList.css';
import PaymentModeForm from '../../Components/Form/PaymentModeForm/PaymentModeForm';
import EditIcon from '../../Assets/General/EditIcon';
import { setValueInLS } from '../../Helpers/LocalStorage';
import DeleteIcon from '../../Assets/General/DeleteIcon';
import { checkError } from '../../Helpers/AuthHelper';

class PaymentModeList extends Component {
  state = {
    data: [],
    allData: [],
    loading: true,
    showCreatePaymentModal:
      window.location.search.slice(1).split('=')[0] === 'new',
    showEditPaymentModal: false,
    paymentModeId: null,
    totalRecords: 0,
    showPrompt: false,
  };

  componentDidMount() {
    setValueInLS('fullscreen', true);
    document.title = 'Payment Methods List';
    this.fetchData();
  }

  fetchData = () => {
    this.setState({ loading: true });
    restRequest('get', 'paymentmethods')
      .then((res) => {
        this.setState({
          data: this.prepareDataForTable(res),
          allData: res,
          totalRecords: res && res.length ? res.length : 0,
          loading: false,
          showCreatePaymentModal: this.props?.location?.search.includes('new')
            ? true
            : !Boolean(res.length),
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  };

  prepareDataForTable(data) {
    return data.map((paymentMode) => ({
      id: paymentMode.id,
      Name: {
        id: 'name',
        getValue: function () {
          return paymentMode[this.id];
        },
      },
    }));
  }

  isPaymentModeDeleteable() {
    const [, , hasDeletePermission] = this.hasPermission('paymentmode', 'All');
    return !hasDeletePermission;
  }

  isPaymentModeEditable() {
    const [, hasEditPermission] = this.hasPermission('paymentmode', 'All');
    return !hasEditPermission;
  }
  checkDeletable = (id) =>
    this.state.allData.find((one) => one.id === id).deletable === 0;

  prepareRowOptions() {
    const rowOptions = [
      {
        label: 'Edit',
        // type: 'link',
        onClick: (id) => this.showEditModal(id),
        icon: EditIcon,
        disabled: () => this.isPaymentModeEditable(),
      },
      {
        label: 'Delete',
        onClick: this.openDeleteModal,
        icon: DeleteIcon,
        disabled: (id) =>
          this.isPaymentModeDeleteable() || this.checkDeletable(id),
      },
    ];

    return rowOptions;
  }

  showCreateModal() {
    return this.setState({ showCreatePaymentModal: true });
  }

  showEditModal(id) {
    return this.setState({ showEditPaymentModal: true, paymentModeId: id });
  }

  closeAndBack(totalRecords) {
    this.setState({ showCreatePaymentModal: false });
    this.setState({ showEditPaymentModal: false });
    if (totalRecords === undefined || totalRecords === 0) {
      //history.push('/settings')
      window.location.href = '/settings';
    }
  }

  renderCreateModal() {
    return (
      <CustomModal
        className="add_new_payment popup-title-align" 
        showModal={this.state.showCreatePaymentModal}
        title="Create New Payment Method"
        renderActions={false}
        Icon={CurrencyIcon}
        fill="#000000"
        modaltitle="payment_modal_title"
        size="large"
        onClose={() => {
          this.closeAndBack(this.state.totalRecords);
          this.props.history.push('/paymentmethods');
        }}
        showPrompt={this.state.showPrompt}
      >
        <PaymentModeForm
          inModal={true}
          closeModal={() => this.setState({ showCreatePaymentModal: false })}
          onSubmitModal={() => {
            this.setState({ showCreatePaymentModal: false });
            this.fetchData();
          }}
          type="add"
          modelSource
          handleToast={this.props.handleToast}
          totalRecords={this.state.totalRecords}
          handleAlertmodal={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  handlePrompt = (status = true) => {
    this.setState({ showPrompt: status });
  };

  renderEditModal() {
    return (
      <CustomModal
        className="add_new_payment popup-title-align"
        showModal={this.state.showEditPaymentModal}
        title="Edit Payment Method"
        renderActions={false}
        fill="#000000"
        modaltitle="edit_payment_modal_title"
        size="large"
        Icon={CurrencyIcon}
        // onClose={() => this.setState({showEditPaymentModal : false})}
        onClose={() => this.closeAndBack(this.state.totalRecords)}
        showPrompt={this.state.showPrompt}
      >
        <PaymentModeForm
          inModal={true}
          closeModal={() => this.setState({ showEditPaymentModal: false })}
          onSubmitModal={() => {
            this.setState({ showEditPaymentModal: false });
            this.fetchData();
          }}
          type="edit"
          modelSource
          id={this.state.paymentModeId}
          handleToast={this.props.handleToast}
          totalRecords={this.state.totalRecords}
          handleAlertmodal={this.handlePrompt}
        />
      </CustomModal>
    );
  }
  renderTable() {
    return (
      <Fragment>
        {this.renderCreateModal()}
        {this.renderEditModal()}
        <ListTable
          className="system_settings"
          data={this.state.data}
          rowOptions={this.prepareRowOptions()}
          hasCheckbox={false}
          loading={this.state.loading}
          showSettingsIcon={false}
          totalRecords={this.state.totalRecords}
        />
      </Fragment>
    );
  }

  render() {
    return this.renderTable();
  }
}

export default HeaderWrapper(PaymentModeList, {
  name: 'Payment Methods',
  Icon: CurrencyIcon,
  baseUrl: 'paymentmethods',
  redirectUrl: '/paymentmethods',
  routeName: 'paymentmethods',
  buttonUrl: '/paymentmethods/add',
  createModal: true,
  buttonLabel: 'Add Payment Method',
  pagination: false,
  permissionName: 'paymentmode',
  tableIcon: 'payment_mode_table_icon',
});
