import React, { useEffect } from 'react';
//import { logout } from '../../Helpers/AuthHelper';
//import { ssosignoutPath } from '../../Helpers/UrlHelper';
import { Loader } from '../../Components';
import {removeTokenCookies} from "../../Helpers/AuthHelper";
//import { restRequest } from '../../Helpers/RequestHelper';

export const LogoutUrl = () => {
  useEffect(() => {
    //removeTokenCookies()
    window.location.href = '/';
    //logout();
    //window.open('/', '_self');
  });
  return <Loader />;
};

export const wordpressLogout = () => {
  this.props.history.push('/');
  /*const sso_connected = process.env.REACT_APP_SSO_ENABLED === 'false' ? false : true;
  const ssosignoutLink = ssosignoutPath();

  // restRequest('get', 'ssoSettings')
  //   .then((res) => {
  if (sso_connected) {
    window.location.href = ssosignoutLink;
  } else {
    logout();
    this.props.history.push('/');
  }
  // })
  // .catch((error) => {});
*/
  return <Loader />;
};
