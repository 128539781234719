export default function getStatusColor(status) {
  switch (status) {
    case 'draft':
      return 'status status--draft';
    case 'open':
    case 'confirmed':
      return 'status status--confirmed';
    case 'Active':
      return 'status status--shipped';
    case 'Inactive':
      return 'status status--draft';
    case 'closed':
      return 'status status--closed';
    case 'onhold':
      return 'status status--onhold';
    case 'received':
      return 'status status--received';
    case 'receiving':
      return 'status status--receiving';
    case 'cancelled':
      return 'status status--cancelled';
    case 'partially received':
      return 'status status--partially-received';
    case 'issued':
      return 'status status--issued';
    case 'complete':
      return 'status status--complete';
    case 'unpaid':
      return 'status status--unpaid';
    case 'paid':
      return 'status status--paid';
    case 'partially paid':
      return 'status status--partially-paid';
    case 'PRTL Paid':
      return 'status status--partially-paid';
    case 'overdue':
      return 'status status--overdue';
    case 'not shipped':
      return 'status status--not-shipped';
    case 'delivered':
      return 'status status--delivered';
    case 'shipped':
      return 'status status--shipped';
    case 'void':
      return 'status status--void';
    case 'sent':
      return 'status status--sent';
    case 'consumed':
      return 'status status--paid';
    case 'partially applied':
      return 'status status--partially-paid';
    default:
      return 'status';
  }
}
