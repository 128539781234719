import React from 'react';
import { ScreenSize, MobileView, DesktopView } from './';
//import useMobileDetection from '../../Hooks/MobileDetect';

import './Menu.css';

const MenusData = ({ menusList = [] }) => {
  const {
    size: { width },
  } = ScreenSize();

  return width > 1250 ? (
    <DesktopView menusList={menusList} />
  ) : (
    <MobileView menusList={menusList} />
  );
};

const HeaderMenus = ({ menusList = [], loading = false }) => {
  // const [hasMobile] = useMobileDetection();

  // menusList = hasMobile
  //   ? menusList.filter((singleMenu) => singleMenu.icon !== 'print')
  //   : menusList;

  return !loading && <MenusData menusList={menusList} />;
};

export default HeaderMenus;
