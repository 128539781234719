import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { restRequest } from '../../../../../Helpers/RequestHelper';
import getDate from '../../../../../Helpers/Date/GetDate';
// import {PurchaseOrderIcon, PackageIcon} from '../../../../../Assets/Navigation/NavigationIcons'

import Loader from '../../../../../Components/Loader/Loader';
import {
  Button,
  // ButtonLink, Options,
  ActionMenu,
} from '../../../../../Components/CheckedMenu/CheckedMenu';
import Table from '../../../../../Components/Table/Table';
import { MdAdd } from '../../../../../Common/Icons';
// import PurchaseReceiveIcon from '../../../../../Assets/General/PurchaseReceiveIcon'
import CreateReceive from '../../../../Receives/CreateReceive';
import ConvertToBill from '../../../../Receives/ConvertToBill';
import ReceivesDetails from './ReceivesDetails';
import CustomModal from '../../../../../Components/CustomModal/CustomModal';
import { NoItemsBox } from '../../../../../Components/Layout/Box';
import ListTableColumnJoin from '../../../../../Components/ListTable/ListTableColumnJoin';
import { getObjFromLS, setObjInLS } from '../../../../../Helpers/LocalStorage';
import './Receives.css';
import DeleteIcon from '../../../../../Assets/General/DeleteIcon';
import ConvertToBillIcon from '../../../../../Assets/General/ConvertToBillIcon';
import PurchaseReceiveIcon from '../../../../../Assets/General/PurchaseReceiveIcon';
// import ConvertToDraftIcon from '../../../../../Assets/General/ConvertToDraftIcon'
import { checkError } from '../../../../../Helpers/AuthHelper';
export default class Receives extends Component {
  state = {
    loading: true,
    receives: [],
    showNewReceiveModal: false,
    showConvertToBillModal: false,
    showDeleteReceiveModal: false,
    clickedReceiveId: null,
    showReceiveDetailsModal: false,
    receivesDetails: {},
    selectedReceive: {},
    showPrompt: false,
  };

  tableRowOptions = [
    {
      label: 'Convert To Bill',
      icon: ConvertToBillIcon,
      onClick: (id) => this.openConvertToBillModal(id),
      disabled: (id) =>
        this.props.hasPermissionCustom('bill', 'Create') === false
          ? true
          : this.isBilled(id),
    },
    {
      label: 'Delete',
      icon: DeleteIcon,
      onClick: (id) => this.openDeleteReceiveModal(id),
      disabled: (id) =>
        this.props.hasPermissionCustom('receive', 'Delete') === false
          ? true
          : this.checkBillStatus(id),
    },
  ];

  checkBillStatus = (id) => {
    const { receives } = this.state;
    let bill_status = receives.find((bill) => bill.id === id);
    if (
      bill_status?.bill_receives[0]?.bill?.status === 'paid' ||
      bill_status.is_received_from_bill === 1
    )
      return true;
    return false;
  };

  renderReceiveActionMenus(id) {
    // const id = this.state.receives.id
    return (
      <ActionMenu>
        {this.props.hasPermissionCustom('receive', 'Delete') && (
          <Button
            handleClick={() =>
              /*(!this.props.hasPermissionCustom('returnreceive','Delete'))*/ this.openDeleteReceiveModal(
              id
            )
            }
            type="delete"
          />
        )}
      </ActionMenu>
    );
  }
  componentDidUpdate() {
    if (this.props.updateReceivesList === true) {
      this.fetchData();
      this.props.toggleUpdateReceives();
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  // fetch data and check if receive creatable
  fetchData = async () => {
    this.setState({ loading: true });
    await restRequest(
      'get',
      `purchaseorders/${this.props.purchaseOrderId}/receives`
    )
      .then((res) => {
        this.setState({
          receives: res,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error);
        this.setState({ loading: false });
      });
  };

  isBilled(id) {
    return this.state.receives.find((receive) => receive.id === id).is_billed;
  }

  openNewReceiveModal = () => {
    this.setState({ showNewReceiveModal: true });
  };

  openConvertToBillModal = (id) => {
    this.setState({
      showConvertToBillModal: true,
      clickedReceiveId: id,
    });
  };

  openDeleteReceiveModal = (id) => {
    this.setState({
      showDeleteReceiveModal: true,
      clickedReceiveId: id,
    });
  };

  closeAllModals = () => {
    this.setState({
      showNewReceiveModal: false,
      showConvertToBillModal: false,
      showDeleteReceiveModal: false,
      showReceiveDetailsModal: false,
    });
  };

  deleteReceiveModal() {
    return (
      <CustomModal
        type="delete"
        showModal={this.state.showDeleteReceiveModal}
        title="Confirm Receive Delete"
        onClose={this.closeAllModals}
        onSubmit={this.deleteReceive}
      >
        Are you sure you want to delete this receive?
      </CustomModal>
    );
  }

  deleteReceive = () => {
    restRequest('delete', `receives/${this.state.clickedReceiveId}`)
      .then(() => {
        let dataFromLS = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLS,
          queryParam: `${dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
            }receives=true`,
        });
        this.fetchData();
        this.props.handleToast('Receive deleted successfully', 'success');
        this.props.purchaseOrderStatusChange();
        this.closeAllModals();
        sessionStorage.setItem('fullscreen', false);
      })
      .catch((error) => {
        //this.props.handleToast(error, "error")
        checkError(error, this.props.handleToast);
      });
  };

  handleReceiveSubmit = () => {
    this.props.purchaseOrderStatusChange();
    this.closeAllModals();
    this.fetchData();
  };

  newReceiveModal() {
    const { purchaseOrderId, handleToast } = this.props;
    return (
      <CustomModal
        showModal={this.state.showNewReceiveModal}
        Icon={PurchaseReceiveIcon}
        title="New Receive"
        renderActions={false}
        onClose={this.closeAllModals}
        size="large"
        className="purchase-popup-set"
        showPrompt={this.state.showPrompt}
      >
        <CreateReceive
          inModal
          purchaseOrderId={purchaseOrderId}
          onClose={this.closeAllModals}
          onSubmit={this.handleReceiveSubmit}
          handleToast={handleToast}
          handleprompt={this.handleprompt}
        />
      </CustomModal>
    );
  }

  handleConvertToBillSubmit = () => {
    let dataFromLS = getObjFromLS('module');
    setObjInLS('module', {
      ...dataFromLS,
      queryParam: `${dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
        }bills=true`,
    });
    this.fetchData();
    this.props.purchaseOrderStatusChange();
    this.props.updateBills();
    this.closeAllModals();
    sessionStorage.setItem('fullscreen', false);
  };

  handleprompt = (status = true) => {
    this.setState({
      showPrompt: status,
    });
  };

  convertToBillModal() {
    return (
      <CustomModal
        showModal={this.state.showConvertToBillModal}
        title="Convert Receive to Bill"
        Icon={ConvertToBillIcon}
        renderActions={false}
        minWidth={123}
        size="large"
        onClose={this.closeAllModals}
        showPrompt={this.state.showPrompt}
        mainContainerClass="dragDropPopupContainer"
        className='convert-bill-popup'
      >
        <ConvertToBill
          receiveId={this.state.clickedReceiveId}
          onClose={this.closeAllModals}
          onSubmit={this.handleConvertToBillSubmit}
          handleToast={this.props.handleToast}
          handleprompt={this.handleprompt}
          showPrompt={this.state.showPrompt}
        />
      </CustomModal>
    );
  }

  openReceiveDetailsModal = (receive) => {
    this.setState({ showReceiveDetailsModal: true, receivesDetails: receive });
  };

  renderReceiveDetailsModal() {
    const { showReceiveDetailsModal, receivesDetails } = this.state;
    return (
      <CustomModal
        className="receive_modal received-item-popup"
        showModal={showReceiveDetailsModal}
        title="Received Item Details"
        showActionInMenu={true}
        modaltitle="receive_modal_headeR_icon"
        Icon={PurchaseReceiveIcon}
        renderReceiveActionMenu={this.renderReceiveActionMenus(
          receivesDetails.id
        )}
        renderActions={false}
        onClose={this.closeAllModals}
      >
        <ReceivesDetails receive={receivesDetails} />
      </CustomModal>
    );
  }

  renderModals() {
    return (
      <Fragment>
        {this.deleteReceiveModal()}
        {this.convertToBillModal()}
        {this.newReceiveModal()}
        {this.renderReceiveDetailsModal()}
      </Fragment>
    );
  }

  wrapWithButton(receive) {
    return (
      <button
        className="underline"
        onClick={() =>
          !this.props.inModal && this.openReceiveDetailsModal(receive)
        }
      >
        {receive.receive_no}
      </button>
    );
  }

  joinColumn(receiveSlip) {
    const billReceives = receiveSlip.bill_receives;
    const status = 'Bills';
    let receiveBilled = false;
    const bills = billReceives.map((bill) => {
      if (bill.bill_id) receiveBilled = true;
      return bill.bill_id ? (
        <Link to={`/bills/${bill.bill_id}`} key={bill.bill_id}>
          {bill.bill.bill_no}{' '}
        </Link>
      ) : null;
    });

    if (!receiveBilled) return this.wrapWithButton(receiveSlip);
    else {
      return (
        <ListTableColumnJoin
          column1={this.wrapWithButton(receiveSlip)}
          column2={
            <span className="receives--bill-status-column">
              {status}
              <span>{bills}</span>
            </span>
          }
        />
      );
    }
  }

  prepareReceivesList(receives) {
    return receives.map((receiveSlip) => {
      return {
        ...receiveSlip,
        receiveNo: this.joinColumn(receiveSlip),
        receiveDate: getDate(receiveSlip.receive_date),
        vendorName: receiveSlip.vendor_name,
        isBilledColumn: !receiveSlip.is_billed ? (
          <a
            className="receive_convert_to_bill"
            onClick={() => this.openConvertToBillModal(receiveSlip.id)}
          >
            Convert to Bill
          </a>
        ) : null,
      };
    });
  }

  isReceiveCreatable(receiveStatus, orderStatus, purchaseOrderType) {
    if (
      receiveStatus === 'received' ||
      orderStatus === 'cancelled' ||
      orderStatus === 'draft' ||
      purchaseOrderType === 'dropship'
    )
      return false;
    return true;
  }

  renderReceivesListTable() {
    const { receiveStatus, purchaseOrderStatus, purchaseOrderType, label } =
      this.props;
    const { receives } = this.state;

    const tableHeaders = ['Receive No.', 'Vendor Name', 'Receive Date', ''];
    const tableListData = this.prepareReceivesList(receives);
    const tableData = [
      'receiveNo',
      'vendorName',
      'receiveDate',
      'isBilledColumn',
      'options',
    ];

    return (
      <Fragment>
        <Table
          list={tableListData}
          tableHeaders={tableHeaders}
          tableData={tableData}
          fromTab={true}
          options={!this.props.inModal ? this.tableRowOptions : ''}
          className={`box-table ${label ? label + '_menu po-receives-menu' : ''}`}
        />
        {this.props.hasPermissionCustom('receive', 'Create') &&
          this.isReceiveCreatable(
            receiveStatus,
            purchaseOrderStatus,
            purchaseOrderType
          ) && (
            <button
              className="layout-box--button btn-width"
              onClick={this.openNewReceiveModal}
            >
              <MdAdd className=" black-fill" />
              New Purchase Receive
            </button>
          )}
      </Fragment>
    );
  }

  renderReceivesList() {
    const { receiveStatus, purchaseOrderStatus, purchaseOrderType } =
      this.props;
    return (
      <Fragment>
        {this.renderModals()}
        {!this.state.receives.length ? (
          <NoItemsBox
            subtitle="Nothing received yet"
            buttonText="New Purchase Receive"
            showButton={
              this.props.hasPermissionCustom('receive', 'Create') &&
              this.isReceiveCreatable(
                receiveStatus,
                purchaseOrderStatus,
                purchaseOrderType
              )
            }
            handleclick={this.openNewReceiveModal}
            Icon={PurchaseReceiveIcon}
            inModal={this.props.inModal}
          />
        ) : (
          this.renderReceivesListTable()
        )}
      </Fragment>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return this.renderReceivesList();
  }
}
