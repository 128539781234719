import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'


const ItemAccountInformation = (
  {
    price,
    account: {
      accountTitle,
      accountGroup
    },
    description,
    Tax = null,
    preferredVendor = null
  }) => {
  return (
    <div className="section-details--info-details">
      <Fragment>
        <div className="section-details--info-title semi-bold">Price</div>
        <div className="green margin-bottom-sm">{price}</div>
      </Fragment>

      <Fragment>
        <div className="section-details--info-title semi-bold">Account</div>
        <div className="margin-bottom-sm">{`${accountTitle} (${accountGroup})`}</div>
      </Fragment>
      {
        Tax && (
          <div className="item-purchase-sales-info">
            <div className="section-details--info-title bold">Tax</div>
            <div className="margin-bottom-sm">{Tax.name}({Tax.rate}%) {Tax.authority}</div>
          </div>
        )
      }

      <Fragment>
        <div className="section-details--info-title semi-bold">Description</div>
        <div className="margin-bottom-sm">{description ? description: 'N/A'}</div>
      </Fragment>

      {
        preferredVendor && (
          <Fragment>
            <div className="section-details--info-title semi-bold">Preferred Vendor</div>
            <Link to={`/contacts/${preferredVendor.id}`}>{preferredVendor.display_name}</Link>
          </Fragment>
        )
      }
    </div>
  )
}

export default ItemAccountInformation
