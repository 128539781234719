import { isEqual } from 'lodash';
export const validateBussinessForm = (data = {}, defaultValues = {}) => {
  const checkEquality = Boolean(isEqual(data, defaultValues));
  let checkout = true;

  if (!checkEquality) {
    checkout = false;
  } else checkout = true;
  return { checkout };
};
