import React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import InfoIcon from '../Assets/Dashboard/infoIcon';

const ToolTip = ({ placement = 'bottom', content = '', children = '', className = '' }) => (
  <OverlayTrigger
    placement={placement}
    overlay={<Tooltip id="tooltip">{content}</Tooltip>}
  >
    <span className={className}>
      {!children ? <InfoIcon /> :
        children}
    </span>
  </OverlayTrigger>
)

export default ToolTip;
