import React, { useState } from 'react';
import {
  NewSalesOrderIcon,
  NewPurchaseOrderIcon,
} from '../Assets/Navigation/NavigationIcons';
import CustomModal from '../Components/CustomModal/CustomModal';
import SalesOrderDetails from '../Pages/SalesOrder/SalesOrderDetails/index';
import PurchaseOrderDetails from '../Pages/PurchaseOrder/PurchaseOrderDetails/index';

let showModalSelectedId = null;
// let showModalSelectedNo = null;
export default ({
  dropShipments = [],
  field = 'purchase_order_no',
  redirect = 'purchaseorders',
  inModal = false,
  showCustomModal = 'purchaseorder',
}) => {
  const [showModal, setShowSOModal] = useState(false);
  function setOrderId(id, no) {
    showModalSelectedId = id;
    // showModalSelectedNo = no;
    setShowSOModal(true);
  }

  function viewPurchaseOrderModal() {
    return (
      <CustomModal
        showModal={showModal}
        title="View Purchase Order"
        className="uiux-po-drop"
        renderActions={false}
        size="large"
        Icon={NewPurchaseOrderIcon}
        linkTo={`${redirect}/${showModalSelectedId}`}
        onClose={() => setShowSOModal(false)}
      >
        <PurchaseOrderDetails id={showModalSelectedId} inModal={true} />
      </CustomModal>
    );
  }

  function viewSalesOrderModal() {
    return (
      <CustomModal
      className='popup-height'
        showModal={showModal}
        title="View Sales Order"
        renderActions={false}
        size="large"
        Icon={NewSalesOrderIcon}
        linkTo={`${redirect}/${showModalSelectedId}`}
        onClose={() => setShowSOModal(false)}
      >
        <SalesOrderDetails id={showModalSelectedId} inModal={true} />
      </CustomModal>
    );
  }

  return (
    <div>
      {showCustomModal === 'purchaseorder' && viewPurchaseOrderModal()}
      {showCustomModal === 'salesorder' && viewSalesOrderModal()}
      {dropShipments.map((order, index) =>
        inModal ? (
          <div className="float-left">
            {order[field]}
            <span>{index + 1 !== dropShipments.length ? ', ' : ''}</span>
          </div>
        ) : (
          <div
            onClick={() => setOrderId(order.id, order[field])}
            className="float-left colorBlue pointer"
          >
            {order[field]}
            <span>{index + 1 !== dropShipments.length ? ', ' : ''}</span>
          </div>
        )
      )}
    </div>
  );
};
