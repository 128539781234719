import React from 'react'

export default ({
                  className = ''
                }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="21.875" height="28.438" viewBox="0 0 21.875 28.438">
    <path className="a"
          d="M6.563-27.344V1.094H28.438V-27.344ZM8.75-25.156h17.5V-1.094H8.75Zm2.188,2.188v6.563H24.063v-6.562Zm2.188,2.188h8.75v2.188h-8.75Zm-1.094,6.563v2.188h2.188v-2.187Zm4.375,0v2.188h2.188v-2.187Zm4.375,0v2.188h2.188v-2.187Zm-8.75,4.375v2.188h2.188V-9.844Zm4.375,0v2.188h2.188V-9.844Zm4.375,0v2.188h2.188V-9.844Zm-8.75,4.375v2.188h2.188V-5.469Zm4.375,0v2.188h2.188V-5.469Zm4.375,0v2.188h2.188V-5.469Z"
          transform="translate(-6.563 27.344)"/></svg>
)
