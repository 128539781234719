import React, { Component, Fragment } from 'react';

import { SalesReturnIcon } from '../../../Assets/Navigation/NavigationIcons';
import { checkError, logout, validToken } from '../../../Helpers/AuthHelper';
import { restRequest } from '../../../Helpers/RequestHelper';

import HeaderWrapper from '../../../Components/HeaderWrapper/HeaderWrapper';
import Loader from '../../../Components/Loader/Loader';
import { DetailsHeader } from '../../../Components/Details/Details';
import Box from '../../../Components/Layout/Box';
import PackageInfo from './Info/PackageInfo';
import PackageItemInfo from './Info/PackageItemInfo';
import ReturnReceive from '../../../Components/Form/ReturnReceive/ReturnReceive';
import PDFViewer from '../../../Components/PDFViewer/PDFViewer';
import { ActionMenu } from '../../../Components/CheckedMenu/CheckedMenu';
import NotFound from '../../../Components/NotFound/NotFound';
import CustomModal from '../../../Components/CustomModal/CustomModal';
import pageIdentify from '../../../Helpers/PageIdentify';
import {
  getObjFromLS,
  setObjInLS,
  setValueInLS,
} from '../../../Helpers/LocalStorage';
import AccessDenied from '../../../Components/AccessDenied/AccessDenied';
import { controllerNames } from '../../../Constants';
import MarkAsConfirmedIcon from '../../../Assets/General/MarkAsConfirmedIcon';
import ConvertToDraftIcon from '../../../Assets/General/ConvertToDraftIcon';
import AddRetrunReceive from '../../../Assets/General/AddRetrunReceive';

import Menu from '../../../Components/Menu/Menu';
import MenuData from '../../../Components/Menu/MenuData';
import History from '../../../Assets/General/History';
import { pdfDownlaod } from '../../../Helpers/PdfDownload';
import '../NewSalesReturn.css';
import { MdClose } from '../../../Common/Icons';

import getCurrentMenu from '../../../Helpers/getCurrentMenu';
import { HeaderMenus } from '../../../Components';
import useMobileDetection from '../../../Hooks/MobileDetect';

class PackageDetails extends Component {
  id = this.props.id
    ? this.props.id
    : this.props.idFromHeaderWrapper
    ? this.props.idFromHeaderWrapper
    : this.props.match.params.id;
  state = {
    detailsNo: null,
    package: {},
    details: [],
    activityLog: [],
    pdf: null,
    currentMenu: getCurrentMenu(window.location.search),
    showReturnReceiveViewModal: false,
    status: '',
    showPDF: false,
    showReturnReceive: false,
    loading: true,
    loadingActivity: false,
    notFound: false,

    markAsVoidModal: false,
    markAsVoidId: '',
    salesreturns: false,
  };

  componentDidMount() {
    document.title = 'Sales Return Details';
    if (validToken()) {
      this.fetchData();
    } else {
      logout();
      this.props.history.push('/login');
    }
  }

  fetchData = async () => {
    this.setState({ loading: true });
    await restRequest('get', `salesreturns/${this.id}`)
      .then((res) => {
        //console.log(res);
        this.setState({
          package: res.sales_return[0],
          activityLog: res.activity_log,
          details: res.sales_return[0].sales_return_details,
          status: res.sales_return[0].sales_return_status,
          detailsNo: res.sales_return[0].sales_return_no,
        });
        let dataFromLS = getObjFromLS('module');
        if (
          dataFromLS &&
          dataFromLS.queryParam &&
          dataFromLS.queryParam.includes(this.state.currentMenu)
        ) {
          let query = dataFromLS.queryParam;
          query = query.replace(`&${this.state.currentMenu}=true`, '');
          query = query.replace(`?${this.state.currentMenu}=true`, '');
          setObjInLS('module', {
            ...dataFromLS,
            queryParam: query,
            presistDetailPage: true,
          });
          let newurl =
            window.location.protocol +
            '//' +
            window.location.host +
            window.location.pathname +
            query;
          window.history.pushState({ path: newurl }, '', newurl);
        } else {
          // var path = window.location.pathname.split('/')[2];
          let obj = {
            // prevId: null,
            // id: path,
            // nextId: null,
            // moduleName: 'salesreturns',
            // urlPath: window.location.pathname,
            // queryParam: window.location.search,
            ...dataFromLS,
            presistDetailPage: true,
          };
          setObjInLS('module', obj);
        }
      })
      .catch((error) => {
        checkError(error);
        this.setState({ notFound: true });
      });

    this.setState({ loading: false });
  };

  renderDetailsHeader() {
    if (this.state.loading) {
      return 'Loading...';
    } else {
      return `Package Details (${this.state.package.package_no})`;
    }
  }

  markSaleReturnAsConfirmed = (saleReturnId) => {
    this.setState({ processing: true });
    restRequest('put', `salesreturns/${saleReturnId}/confirmed`)
      .then(() => {
        this.setState({ processing: false });
        if (pageIdentify(window.location.pathname)) {
          this.fetchData();
          this.props.handleToast(
            'Sales return has been marked as confirmed.',
            'success'
          );
        } else {
          this.props.history.push('/r');
        }
      })
      .catch((error) => {
        this.setState({ processing: false });
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
      });
  };

  convertToDraft = (saleReturnId) => {
    restRequest('put', `salesreturns/${saleReturnId}/draft`)
      .then(() => {
        if (pageIdentify(window.location.pathname)) {
          this.fetchData();
          this.props.handleToast(
            'Sales return has been convert to draft.',
            'success'
          );
        } else {
          this.props.history.push('/r');
        }
      })
      .catch((error) => {
        //this.props.handleToast(error, "error")
        checkError(error, this.props.handleToast);
      });
  };

  markSaleReturnAsVoid = () => {
    restRequest('put', `salesreturns/${this.state.markAsVoidId}/void`)
      .then(() => {
        if (pageIdentify(window.location.pathname)) {
          this.fetchData();
          this.closeMarkAsVoidModal();
          this.props.handleToast(
            'Sales return has been marked as void.',
            'success'
          );
        } else {
          this.props.history.push('/r');
        }
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error");
        this.closeMarkAsVoidModal();
      });
  };

  openMarkAsVoidModal = (id) => {
    this.setState({
      markAsVoidModal: true,
      markAsVoidId: id,
    });
  };

  renderMarkAsVoidModal() {
    return (
      <CustomModal
        showModal={this.state.markAsVoidModal}
        title="Confirm to Mark as Void"
        onClose={this.closeMarkAsVoidModal}
        onSubmit={() => this.markSaleReturnAsVoid()}
      >
        Are you sure you want to mark this sales return as void?
      </CustomModal>
    );
  }

  closeMarkAsVoidModal = () => {
    this.setState({
      markAsVoidModal: false,
    });
  };

  openPdf = (id) => {
    const [hasMobile] = useMobileDetection();
    if (hasMobile) {
      window.open(`/print/${id}/salesreturns`, '_blank');
    } else {
      this.setState({ showPDF: true });
      restRequest('get', `salesreturns/${id}/pdf`)
        .then((res) => {
          this.setState({ pdf: res });
        })
        .catch((error) => checkError(error));
    }
  };

  closeAllModals = () => {
    this.setState({
      showPDF: false,
      pdf: null,
      showReturnReceiveViewModal: false,
      showReturnReceive: false,
    });
  };
  hasPermissionCustom(name, type) {
    let permissions = getObjFromLS('role').permissions;
    if (permissions.superAccess) {
      if (type === 'All') return [true, true, true];
      return true;
    }

    if (!['Create', 'Edit', 'Delete', 'All'].includes(type))
      throw new Error('Type must be one of Create, Edit, Delete, All');
    if (!(name in controllerNames))
      throw new Error('Invalid name is receieved for permission check');
    if (type !== 'All') {
      return permissions[`${controllerNames[name]}${type}`];
    }
    return [
      permissions[`${controllerNames[name]}Create`],
      permissions[`${controllerNames[name]}Edit`],
      permissions[`${controllerNames[name]}Delete`],
    ];
  }

  populateConditionalOptions(id) {
    const hasEditPermission = this.hasPermission('salesreturns', 'Edit');
    const options = [];
    if (!hasEditPermission) return options;
    options.push({
      label: 'Mark As Confirmed',
      icon: MarkAsConfirmedIcon,
      onClick: () => this.markSaleReturnAsConfirmed(id),
      disabled: () =>
        this.state.status === 'receiving' ||
        this.state.status === 'confirmed' ||
        this.state.status === 'void' ||
        this.state.status === 'closed',
    });
    /*options.push({
      label: 'Mark As Void',
      icon: MarkAsVoidIcon,
      onClick: () => this.openMarkAsVoidModal(id),
      disabled: () => this.state.status === 'receiving' || this.state.status === 'draft' || this.state.status === 'void' || this.state.status === 'closed'
    })*/
    options.push({
      label: 'Convert to Draft',
      icon: ConvertToDraftIcon,
      onClick: () => this.convertToDraft(id),
      disabled: () => this.state.status !== 'void',
    });
    return options;
  }

  renderActionMenu() {
    const id = this.id;
    const options = this.populateConditionalOptions(id);
    const [hasCreatePermission] = this.hasPermission('salesreturns', 'All');
    const menusList = [
      {
        type: 'link',
        icon: 'edit',
        enable:
          this.hasPermission('salesreturns', 'Edit') &&
          this.state.status === 'draft',
        to: `/salesreturns/edit/${id}`,
        tooTip: 'Edit',
        mobileLable: 'Edit',
        isMobile: true,
      },
      {
        type: 'button',
        icon: 'pdf',
        tooTip: 'Download PDF',
        isMobile: true,
        mobileLable: 'Download PDF',
        handleClick: () =>
          pdfDownlaod(
            `salesreturns/${id}/pdf?download=true`,
            this.state.package.sales_return_no,
            this.props.handleToast
          ),
      },
      {
        type: 'button',
        icon: 'print',
        isMobile: true,
        mobileLable: 'Print',
        tooTip: 'Print',
        handleClick: () => this.openPdf(id),
      },
      {
        type: 'link',
        icon: 'email',
        tooTip: 'Email',
        isMobile: true,
        mobileLable: 'Email',
        enable: hasCreatePermission,
        to: `/salesreturns/email/${id}`,
      },
      {
        type: 'button',
        icon: 'delete',
        tooTip: 'Delete',
        mobileLable: 'Delete',
        isMobile: true,
        handleClick: () =>
          this.openDeleteModal(
            id,
            this.props.forceRedirect,
            this.props.forceRedirectFlag
          ),
        enable:
          this.hasPermission('salesreturns', 'Delete') &&
          (this.state.status === 'draft' || this.state.status === 'confirmed'),
      },
      {
        type: 'options',
        options: options,
        className: 'options-dropdown',
        enable: !!options.length,
      },
      this.state.status !== 'draft' &&
        this.state.status !== 'void' &&
        this.hasPermissionCustom('returnreceive', 'Create') &&
        this.state.status !== 'closed' && {
          type: 'button',
          className: 'button--secondary package_button float-left mr-3',
          handleClick: () => this.createNewPackage(),
          label: `Add Return Receive`,
          icon: 'addReturnReceive',
        },
    ];
    return (
      <ActionMenu>
        <HeaderMenus menusList={menusList} loading={this.state.loading} />
      </ActionMenu>
    );
  }

  print() {
    this.setState({ loading: true });
    restRequest('get', `salesreturns/${this.id}/print`)
      .then((res) => {
        var w = window.open();
        w.document.write(res.body);
        w.document.close();
        setTimeout(function () {
          w.focus();
          w.print();
          w.close();
        }, 3000);

        this.setState({ loading: false });
      })
      .catch((error) => {
        //this.props.handleToast(error,'error')
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
  }

  renderMenuForHistory() {
    return (
      <MenuData
        name="History"
        saleReturnId={this.saleReturnId}
        url={this.url}
      />
    );
  }
  renderMenuForReturnReceive() {
    return (
      <MenuData
        name="returnReceive"
        saleReturnId={this.saleReturnId}
        saleReturnNo={this.saleReturnNo}
        url={this.url}
        salesOrderStatus={this.salesOrderStatus}
        handleToast={this.handleToast}
        updateSalesOrderStatus={this.updateSalesOrderStatus}
        hasPermissionCustom={this.hasPermissionCustom}
        hasViewPermission={this.hasViewPermission}
        history={this.history}
        inModal={this.inModal}
      />
    );
  }
  handleTabChange(tab) {
    this.setState({ currentMenu: tab });
  }
  menus() {
    if (this.state.package.sales_return_status === 'void') {
      return [
        {
          id: 'history',
          label: 'History',
          saleReturnId: this.id,
          url: `salesreturns/${this.id}/activity-log`,
          icon: () => <History />,
          onClick: (flag = true) => {
            if (flag === false) this.handleTabChange('');
            else this.handleTabChange('history');
          },
          renderContent: this.renderMenuForHistory,
        },
      ];
    } else {
      return [
        {
          id: 'history',
          label: 'History',
          saleReturnId: this.id,
          url: `salesreturns/${this.id}/activity-log`,
          icon: () => <History />,
          onClick: (flag = true) => {
            if (flag === false) this.handleTabChange('');
            else this.handleTabChange('history');
          },
          renderContent: this.renderMenuForHistory,
        },
        {
          id: 'returnReceive',
          label: 'Return Receive',
          saleReturnId: this.id,
          saleReturnNo: this.state.package.sales_return_no,
          url: `salesreturns/${this.id}/activity-log`,
          handleToast: this.props.handleToast,
          updateSalesOrderStatus: this.updateSalesOrderStatus,
          salesOrderStatus: this.state.package.sales_return_status,
          hasPermissionCustom: this.hasPermissionCustom,
          hasViewPermission: this.hasViewPermission('returnreceive'),
          history: this.props.history,
          icon: () => <AddRetrunReceive />,
          inModal: this.props.inModal,
          onClick: (flag = true) => {
            if (flag === false) this.handleTabChange('');
            else this.handleTabChange('returnReceive');
          },
          renderContent: this.renderMenuForReturnReceive,
        },
      ];
    }
  }

  handlePrompt = (status = true) => {
    this.setState({ showPrompt: status });
  };

  createNewPackage() {
    this.setState({ showReturnReceive: true });
  }

  renderPackageCreateModal() {
    return (
      <CustomModal
        showModal={this.state.showReturnReceive}
        title="Create Return Receive"
        Icon={AddRetrunReceive}
        fill="#000000"
        size="large"
        renderActions={false}
        onClose={this.closeAllModals}
        showPrompt={this.state.showPrompt}
        mainContainerClass="dragDropPopupContainer"
      >
        <ReturnReceive
          inModal
          type="add"
          salesReturnId={this.state.package.id}
          salesOrderId={this.props.salesOrderID}
          close={this.closeAllModals}
          onSubmit={this.handlePackageActionSubmit}
          handleToast={this.props.handleToast}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  getTotalQuantity(items) {
    return items.reduce((total, item) => item.sale_return.quantity + total, 0);
  }

  renderLayoutView() {
    const { package: packageInfo, currentMenu } = this.state;
    return (
      <div className="float-left w-100 sale_return_section sales-return-main sales-return-main-set sales_return_popup">
        <Menu
          menus={this.menus()}
          currentMenu={currentMenu}
          history={this.props.history}
          inModal={this.props.inModal || false}
          className="sales-return-details"
        />
        <div className="float-left w-100 overflow_custom_height">
          <div id="printSR" className="sales_return_details">
            <div className="float-left w-100 sales_order_info">
              <Fragment>
                <PDFViewer
                  showPDF={this.state.showPDF}
                  hidePDFModal={this.closeAllModals}
                  pdf={this.state.pdf}
                />

                {/* added cross icon functionality and is relative to details header div. will be fixed soon. */}
                <div className="upper_row">
                  <div className="close_icon">
                    <MdClose
                      onClick={() => {
                        setValueInLS('fullscreen', 'true');
                        sessionStorage.setItem('once', true);
                        this.props.history.push('/r');
                      }}
                    />
                  </div>
                  <div className="float-left w-50 sales_reutn_inf0">
                    <DetailsHeader
                      title="Sales Return"
                      no={this.state.package.sales_return_no}
                      status={this.state.status}
                      customer={
                        this.state.package.sale_order.contact.display_name
                      }
                      contactID={packageInfo.customer_id}
                      inModal={this.props.inModal}
                    />
                  </div>
                  <PackageInfo
                    info={{
                      packageDate: this.state.package.sales_return_date,
                      salesOrderNo:
                        this.state.package.sale_order.sales_order_no,
                      salesOrderID: this.state.package.sale_order.id,
                      // backToStock: this.state.package.add_back_stock,
                      backToStock: this.state.package.back_stock,
                      returnReason:
                        packageInfo.return_reason !== 'Other'
                          ? packageInfo.return_reason
                          : packageInfo.return_reason_description,
                      customerName:
                        this.state.package.sale_order.contact.display_name,
                      customerID: packageInfo.customer_id,
                      totalQuantity: this.getTotalQuantity(
                        this.state.package.sale_order.salesOrderDetails
                      ),
                      totalItems:
                        this.state.package.sale_order.salesOrderDetails.length,
                    }}
                    inModal={this.props.inModal}
                  />
                </div>
                {/* </GridLayout> */}
                <PackageItemInfo
                  className="uiux_sr_summary"
                  itemList={packageInfo.sale_order.salesOrderDetails}
                  packageInfo={packageInfo}
                  itemWareList={packageInfo.sale_order.salesReturnDetails}
                  totalQuantity={this.getTotalQuantity(
                    this.state.package.sale_order.salesOrderDetails
                  )}
                  inModal={this.props.inModal}
                />
              </Fragment>
            </div>
          </div>
        </div>
      </div>
    );
  }
  updateSalesOrderStatus = () => {
    if (pageIdentify(window.location.pathname)) {
      this.setState({
        loadingActivity: true,
      });
      this.setState({ status: '. . .' });
    } else {
      this.props.history.push('/r');
    }
    restRequest('get', `salesreturns/${this.state.package.id}`)
      .then((res) => {
        this.setState({
          status: res.sales_return[0].sales_return_status,
          activityLog: res.activity_log,
          loadingActivity: false,
        });
        // this.fetchData()
      })
      .catch((error) => checkError(error));
  };
  renderSidebar() {
    const { handleToast } = this.props;
    return (
      <div className="right_align_box">
        <Box title="Return Receive">
          {!this.hasPermissionCustom('returnreceive', 'Create') ? (
            <AccessDenied className="small dashboard" type="section" />
          ) : (
            <ReturnReceive
              salesOrderID={this.state.package.id}
              salesOrderStatus={this.state.package.sales_return_status}
              handleToast={handleToast}
              updateSalesOrderStatus={this.updateSalesOrderStatus}
              hasPermissionCustom={this.hasPermissionCustom}
              handlePrompt={this.handlePrompt}
            />
          )}
        </Box>
      </div>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    return (
      // <GridLayout>
      <Fragment>
        {this.renderMarkAsVoidModal()}
        {this.renderPackageCreateModal()}
        {/* <Grid transparent> */}
        {this.renderLayoutView()}
        {/* {pageIdentify(window.location.pathname) && 
              <Box title="Activity">
                {this.state.loadingActivity ? <Loader /> :
                  <div>
                  {
                    this.state.activityLog.map(activity => {
                      return (
                        <TimelineCard
                          key={activity.id}
                          name={activity.creator}
                          description={activity.description}
                          date={activity.date}
                          time={activity.time}
                          subject={activity.subject}
                        />
                      )
                    })
                  }
                  </div>
                }
              </Box>
              
            } */}

        {/* </Grid>

        <Grid transparent>{this.renderSidebar()}</Grid>
      </GridLayout> */}
      </Fragment>
    );
  }
}

export default HeaderWrapper(PackageDetails, {
  name: 'Sales Returns',
  deleteName: 'Sales Return',
  tableIcon: 'salereturn_general_module_icon',
  Icon: SalesReturnIcon,
  baseUrl: 'salesreturns',
  redirectUrl: '/salesreturns',
  onlyMenu: true,
  showName: true,
  permissionName: 'salesreturns',
});
