import React, { Component } from 'react';
import queryString from 'query-string';
import classNames from 'classnames';
import HeaderWrapper from '../../../../Components/HeaderWrapper/HeaderWrapper';
import { NewItemIcon } from '../../../../Assets/Navigation/NavigationIcons';
import ItemDetails from '../../../../Pages/Items/ItemDetails';

import {
  // NewItemIcon,
  restRequest,
  ListTable,
  MdClose,
  setObjInLS,
  // ItemDetails,
  checkError,
  AccessDenied,
} from './';
import '../Ecommerce.css';
class SyncSaleOrdersDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      dataExpanded: [],
      showExportModal: false,
      organizationCurrency: {},
      totalPage: 1,
      currentPage: 1,
      viewPerPage: 20,
      totalRecords: 0,
      currentFilter: 'all',
      orderBy: 'asc',
      sortFilter: 'created_at',
      preference: {},
      search: '',
      accessDenied: false,
      overallHisotyFilter: '',
      currentStore: {},
    };
    this.getID = this.getID.bind(this);
  }

  componentDidMount() {
    document.title = 'Sync Woo Commerce Store History';
    this.fetchDataWithQueryString();
  }

  componentDidUpdate() {
    this.handleOnUpdateQueryString();
  }

  fetchDataWithQueryString() {
    const { filter, page, sort, orderBy, view, search } =
      this.parseQueryString();
    this.setState(
      {
        sortFilter: sort,
        currentFilter: filter,
        currentPage: parseInt(page),
        orderBy: orderBy,
        viewPerPage: parseInt(view),
        search: search,
      },
      () => this.fetchData()
    );
  }

  parseQueryString() {
    const query = queryString.parse(this.props.location.search);
    let filter = query.filter || 'all';

    let sort = query.sort || 'created_at';

    let orderBy = query.orderBy || 'desc';

    let page = parseInt(query.page) || 1;
    // if (!page) page = 1;
    let view = parseInt(query.view) || 20;
    // if (!view) view = 20;

    let search = query.search || '';
    // if (!search) search = '';

    return {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
    };
  }

  handleOnUpdateQueryString() {
    const { filter, page, sort, orderBy, view } = this.parseQueryString();
    if (filter !== this.state.currentFilter) {
      this.setState(
        {
          currentFilter: filter,
        },
        () => this.fetchData()
      );
    }
    if (sort !== this.state.sortFilter) {
      this.setState(
        {
          sortFilter: sort,
        },
        () => this.fetchData()
      );
    }
    if (orderBy !== this.state.orderBy) {
      this.setState(
        {
          orderBy: orderBy,
        },
        () => this.fetchData()
      );
    }
    if (page !== this.state.currentPage) {
      sessionStorage.setItem('current_page', parseInt(page));
      this.setState(
        {
          currentPage: parseInt(page),
        },
        () => this.fetchData()
      );
    }
    if (view !== this.state.viewPerPage) {
      this.handleResetCheckbox(true);
      sessionStorage.setItem('view', parseInt(view));
      this.setState(
        {
          viewPerPage: parseInt(view),
        },
        () => this.fetchData()
      );
    }
  }

  fetchData = async () => {
    this.setState({ loading: true });
    await this.fetchItemsList();
    this.setState({ loading: false });
  };

  fetchItemsList() {
    sessionStorage.setItem('view', parseInt(this.state.viewPerPage));
    sessionStorage.setItem('current_page', parseInt(this.state.currentPage));

    return restRequest(
      'get',
      `woocom/syncHistory/${this.props.match.params.id}?filter=${this.state.currentFilter}&page=${this.state.currentPage}&sort_by=${this.state.sortFilter}&order_by=${this.state.orderBy}&view=${this.state.viewPerPage}`
    )
      .then((res) => {
        let modifiedData = this.prepareDataForTable(
          res?.history?.data,
          res?.currency?.symbol
        );
        this.setState({
          data: modifiedData,
          totalPage: res?.history?.last_page,
          totalRecords: res?.history?.total,
          currency: res?.currency?.symbol,
          currentStore: res?.store,
        });
      })
      .catch((error) => {
        this.setState({
          accessDenied: checkError(error, this.props.handleToast),
        });
      });
  }

  prepareDataForTable = (data, symbol) =>
    data?.map((item) => ({
      id: item.id,
      'Order No': {
        id: 'orderNo',
        getValue: function () {
          return item?.response?.order?.id;
        },
      },
      'Total Items': {
        id: 'totalItems',
        getValue: function () {
          return item?.response?.items.length;
        },
      },
      'Order Price': {
        id: 'orderPrice',
        getValue: function () {
          return `${symbol} ${item?.response?.order?.total}`;
        },
      },
      Status: {
        id: 'order_status',
        getValue: function () {
          return item?.order_status ? 'Active' : 'InActive';
        },
      },
    }));

  renderTable() {
    const { data, loading, accessDenied } = this.state;
    if (accessDenied) {
      return <AccessDenied type="section" />;
    }
    return (
      <>
        <div
          className={classNames('sync-history-dropdown', 'position-relative', 'sync-item-single')}
        >
          <div className={classNames('sync-history-left', 'pb-0')}>
            <div className={classNames('ecom-header-logo')}>
              <img
                src="https://i.ibb.co/JzyLVF2/Woo-Commerce-logo-Woo-Commerce.png"
                alt="Woo-Commerce-logo-Woo-Commerce"
              />&nbsp;/&nbsp;
              <span className={classNames('store-name')}>
                {/* <h4> */}
                {/* <b>Store: </b> */}
                {this.state.currentStore?.name ||
                  this.state.currentStore?.store_url}
                {/* </h4> */}
              </span>
              {/* <p>
                Total count of Sale Orders <b>{this.state.totalRecords}</b>
              </p> */}
            </div>

          </div>
          <div
            className={classNames('close-icon', 'close_icon', 'close-icon-pos')}
            onClick={() =>
              this.props.history.push(
                `/integrations/sync-history/${this.state.currentStore?.id}`
              )
            }
          >
            <MdClose />
          </div>
        </div>
        <ListTable
          data={data}
          className="sync-so-table"
          linkTo="/sync-history"
          loading={loading}
          isBlur="isActive"
          getID={this.getID}
          moduleName="sync-salesorder-detail"
          totalRecords={this.state.totalRecords}
          tableClass="ecom-sync-table"
          hasCheckbox={false}
          sync
          mapping
        />
      </>
    );
  }

  getID(prevId, id, nextId) {
    let obj = {
      id,
      prevId: prevId ? prevId : null,
      nextId: nextId ? nextId : null,
      moduleName: 'items',
      urlPath: window.location.pathname,
      queryParam: window.location.search,
    };
    setObjInLS('module', obj);
  }

  render() {
    return <>{this.renderTable()}</>;
  }
}

export default HeaderWrapper(
  SyncSaleOrdersDetails,
  {
    name: 'Sync History',
    tableIcon: 'general_module_icon',
    Icon: NewItemIcon,
    baseUrl: 'items',
    deleteName: 'Item',
    buttonUrl: '/items/add',
    buttonLabel: 'New Item',
    showDetailsComp: false,
    bypassLoadingState: false,
    permissionName: 'item',
    syncHistory: true,
  },
  ItemDetails
);
