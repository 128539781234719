import React, { useEffect, useState } from 'react';

import { ItemGroupForm } from '../../../Components/Form';
import FormLayout from '../../../Components/Layout/FormLayout';
import { NewItemIcon } from '../../../Assets/Navigation/NavigationIcons';

const EditItemGroups = ({ handleToast, idFromHeaderWrapper, ...props }) => {
  const [, setStep] = useState(false);

  useEffect(() => {
    document.title = 'Edit Item Group';
  }, []);
  const handleStep = () => setStep(true);

  return (
    <FormLayout
      title="Item Groups"
      Icon={NewItemIcon}
      closeUrl="/item-groups"
      iconstyle="general_module_icon"
      subtitle="Edit"
      layoutBoxClass="uiux-form-redesign"
    >
      <ItemGroupForm
        type="edit"
        id={idFromHeaderWrapper ? idFromHeaderWrapper : props.match.params.id}
        handleToast={handleToast}
        handleStep={handleStep}
      />
    </FormLayout>
  );
};

export default EditItemGroups;
