import React, { useState } from 'react';
import CustomModal from '../../../../../Components/CustomModal/CustomModal';
import { NewSalesOrderIcon } from '../../../../../Assets/Navigation/NavigationIcons';
import getDate from '../../../../../Helpers/Date/GetDate';
import '../../NewPackageCreate.css';
import SalesOrderDetails from '../../../../../Pages/SalesOrder/SalesOrderDetails/index';

const PackageInfo = ({
  handleShipmentView,
  info: { packageDate, salesOrderNo, salesOrderID, shipmentNo, shipment },
  inModal = false,
  myClass = '',
}) => {
  const [showSOModal, setShowSOModal] = useState(false);

  function viewSaleOrderModal() {
    return (
      <CustomModal
        classNames="package-saleorder-view view-details-popup"
        showModal={showSOModal}
        title="View Sales Order"
        className='popup-height'
        renderActions={false}
        size="large"
        Icon={NewSalesOrderIcon}
        linkTo={`salesorders/${salesOrderID}`}
        onClose={() => setShowSOModal(false)}
      >
        <SalesOrderDetails id={salesOrderID} inModal={true} />
      </CustomModal>
    );
  }
  return (
    <>
      <div className={`section-details--info ${myClass}`}>
        {viewSaleOrderModal()}
        {shipmentNo ? (
          <div className="float-left w-100 py_5">
            <div className="section-details--info-title semi-bold float-left">
              Shipment No.
            </div>
            <button
              className={`float-right ${
                inModal ? 'no-cursor' : 'shipment_no'
              } `}
              onClick={() => !inModal && handleShipmentView()}
            >
              {shipmentNo}
            </button>
          </div>
        ) : (
          ''
        )}
        {shipment && shipment.carrier ? (
          <hr className="float-left w-100"></hr>
        ) : (
          ''
        )}
        {shipment && shipment.carrier ? (
          <div className="float-left w-100 py_5">
            <div className="section-details--info-title semi-bold float-left">
              Carrier
            </div>
            <div className="float-right">{shipment.carrier}</div>
          </div>
        ) : (
          ''
        )}
        {shipment && shipment.tracking_no ? (
          <hr className="float-left w-100"></hr>
        ) : (
          ''
        )}
        {shipment && shipment.tracking_no ? (
          <div className="float-left w-100 py_5">
            <div className="section-details--info-title semi-bold float-left">
              Tracking No.
            </div>
            <div className="float-right">
              <a
                href={`https://www.google.com/search?q=${shipment.tracking_no}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {shipment.tracking_no}
              </a>
            </div>
          </div>
        ) : (
          ''
        )}
        {shipmentNo ? <hr className="float-left w-100" /> : ''}
        <div className="float-left w-100 py_5">
          <div className="section-details--info-title semi-bold float-left">
            Package Date
          </div>
          <div className="float-right">{getDate(packageDate)}</div>
        </div>
        <hr className="float-left w-100" />
        <div className="float-left w-100 py_5">
          <div className="section-details--info-title semi-bold float-left">
            Sales Order
          </div>
          {inModal ? (
            <div className="float-right"> {salesOrderNo}</div>
          ) : (
            <div
              onClick={() => setShowSOModal(true)}
              className="float-right colorBlue pointer"
            >
              {salesOrderNo}{' '}
            </div>
          )}
        </div>
      </div>
      <div className="main-table-wraper">
        <table className="main-table-w" cellPadding="0" cellSpacing="0">
          <tbody>
            <tr>
              <td>
                <table className="table-w">
                  <tbody>
                    {shipmentNo && (
                      <tr>
                        <td>
                          <table className="bottom-border-set table-w">
                            <tbody>
                              <tr>
                                <td className="w-50 td-left-set">
                                  Shipment No.
                                </td>
                                <td className="w-50 td-right-set">
                                  <button
                                    className={`float-right ${
                                      inModal ? 'no-cursor' : 'shipment_no'
                                    } `}
                                    onClick={() =>
                                      !inModal && handleShipmentView()
                                    }
                                  >
                                    {shipmentNo}
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                    {shipment && shipment.carrier && (
                      <tr>
                        <td>
                          <table className="bottom-border-set table-w">
                            <tbody>
                              <tr>
                                <td className="w-50  td-left-set">Carrier</td>
                                <td className="w-50 td-right-set ">
                                  {shipment.carrier}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                    {shipment && shipment.tracking_no && (
                      <tr>
                        <td>
                          <table className="bottom-border-set table-w">
                            <tbody>
                              <tr>
                                <td className="w-50  td-left-set">
                                  Tracking No
                                </td>
                                <td className="w-50 td-right-set">
                                  <a
                                    href={`https://www.google.com/search?q=${shipment.tracking_no}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {shipment.tracking_no}
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        <table className="bottom-border-set table-w">
                          <tbody>
                            <tr>
                              <td className="w-50 td-left-set">Package Date</td>
                              <td className="w-50 td-right-set">
                                {getDate(packageDate)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table className="table-w">
                          <tbody>
                            <tr>
                              <td className="w-50 td-left-set">Sales Order</td>
                              {inModal ? (
                                <td className="w-50 td-right-set">
                                  {salesOrderNo}
                                </td>
                              ) : (
                                <td
                                  onClick={() => setShowSOModal(true)}
                                  className="w-50 td-right-set colorBlue pointer"
                                >
                                  {salesOrderNo}{' '}
                                </td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PackageInfo;
