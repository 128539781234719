import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ErrorImg from '../Assets/Img/error.png';
import { seebizCloudUrl } from '../Helpers/UrlHelper';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log(error);
    console.log(info);
  }

  render() {
    const seebizUrl = seebizCloudUrl();
    if (this.state.hasError) {
      return (
        <div style={{ textAlign: 'center' }} className="error-boundary">
          <img src={ErrorImg} alt="Error pic" />
          <h4>
            An Error has occured. We have been notified. Go{' '}
            <Link to="/dashboard">DashBoard</Link>
          </h4>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={`https://www.${seebizUrl}/inventory/contact-us/`}
          >
            Contact us
          </a>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
