import React, { Component, Fragment } from 'react';

import RecordPaymentIcon from '../../../Assets/General/RecordPaymentIcon';
import { restRequest } from '../../../Helpers/RequestHelper';
import { checkError } from '../../../Helpers/AuthHelper';
import { ActionMenu } from '../../../Components/CheckedMenu/CheckedMenu';
import HeaderWrapper from '../../../Components/HeaderWrapper/HeaderWrapper';
import Loader from '../../../Components/Loader/Loader';
import CustomModal from '../../../Components/CustomModal/CustomModal';
import NotFound from '../../../Components/NotFound/NotFound';
import InvoicePaymentDetails from './InvoicePaymentmadeDetails';

import { getObjFromLS, setValueInLS } from '../../../Helpers/LocalStorage';

import { MdClose } from '../../../Common/Icons';
import { HeaderMenus, Menu, OverlayLoader } from '../../../Components';
import { pdfDownlaod } from '../../../Helpers/PdfDownload';
import getDate from '../../../Helpers/Date/GetDate';
import { PDFViewer } from '../../../Components';
import { DetailsHeader } from '../../../Components/Details/Details';
import PaymentmodeInfo from './PaymentmodeInfo';
import { NewInvoiceIcon } from '../../../Assets/Navigation/NavigationIcons';
import InvoiceDetails from '../../SalesOrder/Invoices/InvoiceDetails';
import Notes from '../../../Components/Notes/Notes';
import History from '../../../Assets/General/History';
import HitoryTab from './Tabs/History';
import RefundsTab from './Tabs/Refund';
import RefundIcon from '../../../Assets/General/RefundIcon';
import getCurrentMenu from '../../../Helpers/getCurrentMenu';
import { downloadAttachment, setObjInLS } from '../../../Helpers';
import pageIdentify from '../../../Helpers/PageIdentify';
import AttachmentIcon from '../../../Assets/General/AttachmentIcon';
import DownloadAttachmentIcon from '../../../Assets/General/DownloadAttachmentIcon';
import DeleteAttachmentIcon from '../../../Assets/General/DeleteAttachmentIcon';
import AttachIcon from '../../../Assets/General/AttachmentIcon';
import useMobileDetection from '../../../Hooks/MobileDetect';

class PaymentsDetails extends Component {
  id = this.props.id
    ? this.props.id
    : this.props.idFromHeaderWrapper
      ? this.props.idFromHeaderWrapper
      : this.props?.match?.params?.id;

  state = {
    loading: true,
    notFound: false,
    payment_type: '',
    showPDF: false,
    pdf: null,
    detailsNo: '',
    paymentInfo: {},
    invoice: {},
    payment_date: '',
    currency: {},
    customer: {},
    invoice_payment: [],
    total_amount: 0,
    unused_amount: 0,
    showInvoiceModal: false,
    invoice_id_index: null,
    attachments: [],
    showFileDelteModal: false,
    type: '',
    attachmentId: null,
    // currentMenu: '',
    processingAttachment: false,
    customId: null,
    currentMenu: getCurrentMenu(window.location.search),
  };

  componentDidMount() {
    document.title = 'Payments Details';
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ loading: true });
    let dataFromLS = getObjFromLS('module');
    if (
      dataFromLS &&
      dataFromLS.moduleName === 'advancepayment' &&
      dataFromLS.id
    ) {
      this.id = dataFromLS.id;
      if (JSON.parse(sessionStorage.getItem('showPrint'))) {
        let printId = JSON.parse(sessionStorage.getItem('showPrintId'));
        this.openPdf(printId || this.id || dataFromLS.id);
      }
      // sessionStorage.removeItem('showPrint');
    }


    await restRequest('get', `advancepayment/${this.id}`)
      .then((res) => {
        this.setState({
          paymentInfo: {
            paymentDate: getDate(res.payment_date),
            paymentMade: res.payment_made,
            paymentMode: res.payment_mode,
            reference: res.reference,
            notes: res.note,
            payment_no: res?.payment_no || res?.advance_payment?.payment_no,
          },
          invoice: res.invoice,
          loading: false,
          payment_type: res.payment_type,
          detailsNo: `Payment #  ${res.payment_no}`,
          payment_date: res.payment_date,
          customer: res.customer,
          invoice_payment:
            res.payment_type === 'advance'
              ? this.prepareTableData(res.invoice_payments)
              : [],
          total_amount: res.payment_type === 'advance' && res.payment,
          unused_amount: res.payment_type === 'advance' && res.unused_amount,
          currency: res.currency,
          reference: res?.reference || '',
          customId: res?.id,
          attachments: res?.attachments,
        });
        let dataFromLS = getObjFromLS('module');
        if (
          dataFromLS &&
          dataFromLS.queryParam &&
          dataFromLS.queryParam.includes(this.state.currentMenu)
        ) {
          let query = dataFromLS.queryParam;
          query = query.replace(`&${this.state.currentMenu}=true`, '');
          query = query.replace(`?${this.state.currentMenu}=true`, '');
          setObjInLS('module', {
            ...dataFromLS,
            queryParam: query,
            presistDetailPage: true,
          });

          let newurl =
            window.location.protocol +
            '//' +
            window.location.host +
            window.location.pathname +
            query;
          window.history.pushState({ path: newurl }, '', newurl);
        } else {
          setObjInLS('module', {
            ...dataFromLS,
            presistDetailPage: true,
          });
        }
      })
      .catch((error) => {
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS && dataFromLS.action && dataFromLS.action === 'delete') {
          checkError(error);
        } else {
          checkError(error);
          // this.props.handleToast(error, 'error');
        }
        this.setState({
          notFound: true,
          loading: false,
        });
      });
  };

  deleteSignleFile() {
    document.body.style.cursor = 'wait';
    this.setState({ processingAttachment: true, showFileDelteModal: false });
    restRequest(
      'delete',
      `advancepayment/attachment/${this.state.attachmentId}`
    )
      .then((res) => {
        document.body.style.cursor = 'default';
        this.props.handleToast(`Attachment deleted successfully`, 'success');
        this.fetchData();
        this.setState({
          showFileDelteModal: false,
          attachmentId: null,
          type: '',
        });
        this.setState({ processingAttachment: false });
      })
      .catch((error) => {
        document.body.style.cursor = 'default';
        //this.props.handleToast(error, 'error')
        checkError(error, this.props.handleToast);
        this.setState({ processingAttachment: false });
      });
  }

  deleteAllFiles() {
    this.setState({ processingAttachment: true, showFileDelteModal: false });
    document.body.style.cursor = 'wait';
    restRequest(
      'delete',
      `advancepayment/attachment/delete_all/${this.state.customId}`
    )
      .then((res) => {
        document.body.style.cursor = 'default';
        this.props.handleToast(`Attachment deleted successfully`, 'success');
        this.fetchData();
        this.setState({
          showFileDelteModal: false,
          attachmentId: null,
          type: '',
        });
        this.setState({ processingAttachment: false });
      })
      .catch((error) => {
        document.body.style.cursor = 'default';
        //this.props.handleToast(error, 'error')
        checkError(error, this.props.handleToast);
        this.setState({ processingAttachment: false });
      });
  }
  openPdf(id) {
    this.setState({ showPDF: true });
    restRequest('get', `advancepayment/${id}/pdf`)
      .then((res) => {
        this.setState({ pdf: res });
      })
      .catch((error) => checkError(error));
  }

  print() {
    this.setState({ loading: true });
    restRequest('get', `advancepayment/${this.id}/print`)
      .then((res) => {
        var w = window.open();
        w.document.write(res.body);
        w.document.close();
        setTimeout(function () {
          w.focus();
          w.print();
          w.close();
        }, 3000);
        this.setState({ loading: false });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.prhandleToast(error,'error')
        this.setState({ loading: false });
      });
  }
  // ------------------------------------------------------Files Module
  handleAddAttachment(event) {
    document.body.style.cursor = 'wait';
    const { handleToast } = this.props;
    let selectedFiles = Array.from(event.target.files);
    if (selectedFiles.length > 10) {
      handleToast('Cannot add more than 10 attachments', 'error');
      setTimeout(() => {
        document.body.style.cursor = 'default';
        this.setState({ processingAttachment: false });
      }, 2000);
      return null;
    }
    let discardedFiles = [];
    let newFiles = selectedFiles.filter((file) => {
      const MAX_FILE_SIZE = 5; // max file size = 5MB
      let fileSize = file.size / 1000 / 1000; // converted from bytes to MB
      if (fileSize > MAX_FILE_SIZE) {
        discardedFiles.push(file);
      }
      return fileSize <= MAX_FILE_SIZE;
    });
    if (!!discardedFiles.length) {
      let errorMessage = discardedFiles.map((file) => {
        return `${file.name} exceeds the file size limit`;
      });
      handleToast(errorMessage, 'error');
    }

    const fd = new FormData();
    let newFileCount = this.state.attachments.length;
    newFiles.forEach((file, index) => {
      if (newFileCount <= 9) {
        fd.append(`files[${index}]`, file);
        newFileCount++;
      } else {
        // break;
        handleToast('Cannot add more than 10 attachments', 'error');
        return;
      }
    });
    fd.append('subjectId', this.state.customId);
    fd.append(
      'module',
      this.state.payment_type === 'advance'
        ? 'advance_payment'
        : 'invoice_payment'
    );
    fd.append('name', `Payment # ${this.state.paymentInfo.payment_no}`);
    this.setState({ processingAttachment: true });
    restRequest('post', 'advancepayment/attachments', fd)
      .then(() => {
        handleToast('Attachment added successfully', 'success');
        this.fetchData();
        document.body.style.cursor = 'default';
        this.setState({ processingAttachment: false });
      })
      .catch((errorMessage) => {
        document.body.style.cursor = 'default';
        checkError(errorMessage, handleToast);
        //handleToast(errorMessage, "error")
        this.setState({ processingAttachment: false });
      });
  }

  async downloadSingleFile(attachment) {
    this.setState({ processingAttachment: true });
    await downloadAttachment(
      `advancepayment/${attachment.id}/download-attachment`,
      this.props.handleToast,
      'single'
    );
    this.setState({ processingAttachment: false });
  }

  showFileDeleteModal(attId, filetype) {
    this.setState({
      showFileDelteModal: true,
      attachmentId: attId,
      type: filetype,
    });
  }

  async downloadAllFiles() {
    this.setState({ processingAttachment: true });
    await downloadAttachment(
      `advancepayment/${this.state.customId}/download-all`,
      this.props.handleToast,
      'multiple'
    );
    this.setState({ processingAttachment: false });
  }

  renderFileDeleteModal() {
    return (
      <Fragment>
        <CustomModal
          type="delete"
          showModal={this.state.showFileDelteModal}
          title={`Confirm Delete`}
          onClose={() => this.setState({ showFileDelteModal: false })}
          onSubmit={() => {
            this.state.type === 'single'
              ? this.deleteSignleFile()
              : this.deleteAllFiles();
            // alert('ts');
          }}
        >
          Are you sure you want to delete{' '}
          {this.state.type === 'single'
            ? 'this attachment?'
            : 'all attachments?'}
        </CustomModal>
      </Fragment>
    );
  }

  // ------------------------------------------------------------files module ended
  prepareTableData = (list = []) =>
    list.map((payment, index) => ({
      invoiceNo: (
        <span
          onClick={() =>
            this.setState({
              showInvoiceModal: true,
              invoice_id_index: payment.invoice_id,
            })
          }
          style={{ color: '#306AB4', cursor: 'pointer' }}
        >
          {payment?.get_invoice?.invoice_no}
        </span>
      ),
      invoiceDate: getDate(payment?.get_invoice?.invoice_date),
      invoiceAmount: payment?.get_invoice?.total.toFixed(2),
      receivedAmount: payment?.payment_made.toFixed(2),
    }));

  attachmentOptions() {
    const [hasCreatePermission, , hasDeletePermission] = this.hasPermission(
      'invoicepayment',
      'All'
    );
    let obj = [];
    this.state.attachments &&
      this.state.attachments.forEach((attachment) => {
        obj.push({
          label: attachment.file_name,
          icon1: DownloadAttachmentIcon,
          id: attachment.id,
          type: 'attachment',
          onClickForIcon1: () => {
            this.downloadSingleFile(attachment);
          },
          icon2: hasDeletePermission && DeleteAttachmentIcon,
          onClickForIcon2: () => {
            this.showFileDeleteModal(attachment.id, 'single');
          },
        });
      });
    if (this.state.attachments.length > 0)
      obj.push({
        label: 'All Attachments',
        type: 'attachment',
        icon1: DownloadAttachmentIcon,
        icon2: hasDeletePermission && DeleteAttachmentIcon,
        onClickForIcon1: () => {
          this.downloadAllFiles();
        },
        onClickForIcon2: () => {
          this.showFileDeleteModal(null, 'multiple');
        },
      });
    return [
      hasCreatePermission && {
        label: this.state.processingAttachment
          ? 'Processing...'
          : 'Attach New File',
        type: 'addNewAttachment',
        onClick: (e) => {
          this.handleAddAttachment(e);
        },
      },
      !hasCreatePermission &&
      this.state.attachments.length === 0 && {
        label: 'No Attachments Available',
        onClick: (e) => null,
      },
      ...obj,
    ];
  }

  renderActionMenu() {
    const { payment_type } = this.state;
    const count = this.state.attachments.length;
    const [hasCreatePermission, hasEditPermission, hasDeletePermission] =
      this.hasPermission('invoicepayment', 'All');
    const menusList = [
      {
        type: 'link',
        icon: 'edit',
        enable:
          payment_type !== 'advance'
            ? false
            : payment_type === 'advance'
              ? hasEditPermission
              : false,
        to: `/paymentsmade/edit/${this.id}`,
        tooTip: 'Edit',
        mobileLable: 'Edit',
        isMobile: true,
      },
      {
        type: 'button',
        icon: 'pdf',
        tooTip: 'Download PDF',
        isMobile: true,
        enable: true,
        mobileLable: 'Download PDF',
        handleClick: () =>
          pdfDownlaod(
            `advancepayment/${this.id}/pdf?download=true`,
            `Payment-${this.id}`,
            this.props.handleToast
          ),
      },
      {
        type: 'button',
        icon: 'print',
        isMobile: false,
        enable: true,
        mobileLable: 'Print',
        tooTip: 'Print',
        handleClick: () => this.OpenPaymentPdf(this.id),
      },
      {
        type: 'link',
        icon: 'email',
        tooTip: 'Email',
        isMobile: true,
        mobileLable: 'Email',
        enable: hasCreatePermission,
        to: `paymentsmade/email/${this.id}`,
      },
      {
        type: 'dropdown',
        className: 'attachemnt_buttons',
        options: this.attachmentOptions(),
        enable: true,
        attachment: true,
        attachmentCount: count,
        AttachmentIcon: AttachmentIcon,
      },
      {
        type: 'button',
        icon: 'delete',
        tooTip: 'Delete',
        mobileLable: 'Delete',
        isMobile: true,
        handleClick: () =>
          this.openDeleteModal(
            this.id,
            this.props.forceRedirect,
            this.props.forceRedirectFlag
          ),
        enable: hasDeletePermission,
      },
      {
        type: 'button',
        className: 'button--primary float-left mr-3',
        handleClick: () => this.props.history.push(`/paymentsmade/add`),
        label: `New`,
        icon: 'new',
      },
    ];
    return (
      <ActionMenu>
        <HeaderMenus menusList={menusList} loading={this.state.loading} />
      </ActionMenu>
    );
  }

  closeAllModals = () => {
    sessionStorage.removeItem('showPrint');
    sessionStorage.removeItem('showPrintId');
    this.setState({
      showPDF: false,
      pdf: null,
    });
  };

  OpenPaymentPdf = (id) => {
    const [hasMobile] = useMobileDetection();
    if (hasMobile) {
      window.open(`/print/${id}/advancepayment`, '_blank');
    } else {
      this.setState({ showPDF: true });
      restRequest('get', `advancepayment/${id}/pdf`)
        .then((res) => {
          this.setState({ pdf: res });
        })
        .catch((error) => checkError(error));
    }
  };

  viewInvoiceModal = () => {
    const { invoice_id_index } = this.state;
    return (
      <CustomModal
        Icon={NewInvoiceIcon}
        showModal={this.state.showInvoiceModal}
        title="View Invoice"
        modaltitle="inventory_modal_title"
        renderActions={false}
        size="large"
        onClose={() => this.setState({ showInvoiceModal: false })}
        linkTo={`invoices/${invoice_id_index}`}
      >
        <InvoiceDetails id={invoice_id_index} inModal={true} />
      </CustomModal>
    );
  };

  handleTabChange(tab) {
    this.setState({ currentMenu: tab });
  }

  updateCreditNote = () => {
    if (!pageIdentify(window.location.pathname)) {
      this.props.history.push('/r');
    } else this.fetchData();
  };

  menus() {
    return [
      {
        id: 'history',
        label: 'History',
        customId: this.state.customId,
        type: this.state.payment_type,
        url: `packages/${this.id}/activity`,
        icon: () => <History />,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('history');
        },
        renderContent: this.renderMenuForHistory,
      },
      {
        id: 'creditRefunds',
        label: 'Refunds',
        customId: this.state.customId,
        type: this.state.payment_type,
        handleToast: this.props.handleToast,
        url: `packages/${this.id}/activity`,
        remainingCredits: this.state.unused_amount,
        payment_date: this.state.payment_date,
        currency: this.state.currency,
        updatePaymentDetails: this.updateCreditNote,
        // disable: this.state.payment_type !== 'advance',
        icon: () => <RefundIcon />,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('creditRefunds');
        },
        renderContent: this.renderMenuForRefund,
      },
    ];
  }

  renderMenuForHistory() {
    //return <MenuData name="History" packageId={this.id} url={this.url} />;
    return <HitoryTab type={this.type} id={this.customId} />;
  }

  renderMenuForRefund() {
    return (
      <RefundsTab
        type={this.type}
        id={this.customId}
        handleToast={this.handleToast}
        remainingCredits={this.remainingCredits}
        payment_date={this.payment_date}
        currency={this.currency}
        updatePaymentDetails={this.updatePaymentDetails}
      />
    );
  }

  renderLayoutView() {
    const {
      invoice,
      showPDF,
      pdf,
      paymentInfo,
      currency,
      payment_type,
      customer,
      total_amount,
      unused_amount,
      invoice_payment,
      reference,
      currentMenu,
      attachments,
    } = this.state;
    return (
      <Fragment>
        {this.renderFileDeleteModal()}
        {this.state.processingAttachment && <OverlayLoader opacity={0.7} />}
        <Menu
          menus={this.menus()}
          currentMenu={currentMenu}
          history={this.props.history}
          inModal={false}
          className="uiux_customer_payment"
        />

        <div className="float-left w-100 position-relative payment_details payment_details-main">
          <PDFViewer
            showPDF={showPDF}
            hidePDFModal={this.closeAllModals}
            pdf={pdf}
          />
          <div className="close_icon customet-close-icon">
            <MdClose
              onClick={() => {
                setValueInLS('fullscreen', 'true');
                sessionStorage.setItem('once', true);
                this.props.history.push('/r');
              }}
            />
          </div>
          <div className="float-left w-100 overflow_custom_height">
            <div
              id="printSO"
              className="sale_Order_Details received-payment-detail"
            >
              <div className="float-left w-100 sales_order_info">
                <Fragment>
                  <DetailsHeader
                    myClass="details_header_package"
                    title="Payments Details"
                    track={
                      payment_type === 'invoice_payment' ? 'Direct' : 'Advance'
                    }
                    no={paymentInfo.payment_no}
                    payment_type={payment_type}
                    customer={
                      payment_type === 'invoice_payment'
                        ? invoice?.customer?.display_name
                        : customer?.display_name
                    }
                    contactID={
                      payment_type === 'invoice_payment'
                        ? invoice?.customer?.id
                        : customer?.id
                    }
                  />
                  <PaymentmodeInfo
                    info={{
                      Paymentno: paymentInfo.payment_no,
                      date: paymentInfo.paymentDate,
                      payment_mode: paymentInfo.paymentMode,
                      receive: paymentInfo.paymentMade,
                      currency: currency,
                      inModal: true,
                      payment_type: payment_type,
                      total_amount: total_amount,
                      unused_amount: unused_amount,
                      used_amount: total_amount - unused_amount,
                      reference: reference,
                    }}
                  />
                  <InvoicePaymentDetails
                    payment_type={payment_type}
                    invoice_payment={invoice_payment}
                    invoiceInfo={invoice}
                    paymentInfo={paymentInfo}
                    currency={currency}
                  />
                  <Notes note={paymentInfo.notes} />

                  {attachments.length > 0 && (
                    <div className="float-left w-100">
                      <div className="bottom_attachment_area">
                        <AttachIcon />
                        <span className="attachment_span">
                          {attachments.length} Attachment(s) added
                        </span>
                      </div>
                    </div>
                  )}
                  {attachments.length > 0 && (
                    <hr className="float-left w-100 mb-1 border_top_light" />
                  )}
                </Fragment>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    return (
      <>
        {this.viewInvoiceModal()}
        <Fragment>{this.renderLayoutView()}</Fragment>
      </>
    );
  }
}

export default HeaderWrapper(PaymentsDetails, {
  name: 'Payment',
  deleteName: 'Payment Received',
  tableIcon: 'general_module_icon',
  Icon: RecordPaymentIcon,
  baseUrl: 'advancepayment',
  redirectUrl: '/paymentsmade',
  onlyMenu: true,
  showName: true,
  permissionName: 'invoicepayment',
});
