import React from 'react'

export default ({
  className = ''
}) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.2616 6.66666C11.9333 6.66666 11.6666 6.34916 11.6666 5.95832V3.31499L14.7849 6.66666H12.2616ZM12.4999 15H7.49992C7.03992 15 6.66659 14.6267 6.66659 14.1667C6.66659 13.7067 7.03992 13.3333 7.49992 13.3333H12.4999C12.9608 13.3333 13.3333 13.7067 13.3333 14.1667C13.3333 14.6267 12.9608 15 12.4999 15ZM7.49992 9.99999H9.99992C10.4599 9.99999 10.8333 10.3733 10.8333 10.8333C10.8333 11.2933 10.4599 11.6667 9.99992 11.6667H7.49992C7.03992 11.6667 6.66659 11.2933 6.66659 10.8333C6.66659 10.3733 7.03992 9.99999 7.49992 9.99999ZM16.4499 6.10666L12.7466 1.93999C12.5891 1.76582 12.3649 1.66666 12.1299 1.66666H5.46325C4.28909 1.66666 3.33325 2.60166 3.33325 3.74999V16.25C3.33325 17.3983 4.28909 18.3333 5.46325 18.3333H14.5366C15.7116 18.3333 16.6666 17.3983 16.6666 16.25V6.66666C16.6666 6.45916 16.5891 6.25999 16.4499 6.10666Z" fill="#231F20" />
    <mask id="mask-file-icon" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="1" width="14" height="18">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.2616 6.66666C11.9333 6.66666 11.6666 6.34916 11.6666 5.95832V3.31499L14.7849 6.66666H12.2616ZM12.4999 15H7.49992C7.03992 15 6.66659 14.6267 6.66659 14.1667C6.66659 13.7067 7.03992 13.3333 7.49992 13.3333H12.4999C12.9608 13.3333 13.3333 13.7067 13.3333 14.1667C13.3333 14.6267 12.9608 15 12.4999 15ZM7.49992 9.99999H9.99992C10.4599 9.99999 10.8333 10.3733 10.8333 10.8333C10.8333 11.2933 10.4599 11.6667 9.99992 11.6667H7.49992C7.03992 11.6667 6.66659 11.2933 6.66659 10.8333C6.66659 10.3733 7.03992 9.99999 7.49992 9.99999ZM16.4499 6.10666L12.7466 1.93999C12.5891 1.76582 12.3649 1.66666 12.1299 1.66666H5.46325C4.28909 1.66666 3.33325 2.60166 3.33325 3.74999V16.25C3.33325 17.3983 4.28909 18.3333 5.46325 18.3333H14.5366C15.7116 18.3333 16.6666 17.3983 16.6666 16.25V6.66666C16.6666 6.45916 16.5891 6.25999 16.4499 6.10666Z" fill="white" />
    </mask>
    <g mask="url(#mask-file-icon)">
      <rect width="20" height="20" fill="#E68845" />
    </g>
  </svg>
)