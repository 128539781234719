import React, { Component } from 'react';

import { restRequest } from '../../../../../Helpers/RequestHelper';
import AddCard from '../../../../../Components/Card/AddCard';
import AddContactAddress from './AddContactAddress';
import ContactAddress from './ContactAddress';
import AddressForm from '../../../../../Components/Form/AddressForm/AddressForm';
import CardContainer from './../../../../../Components/Card/CardContainer';
import Loader from './../../../../../Components/Loader/Loader';
import CustomModal from '../../../../../Components/CustomModal/CustomModal';
import { hasPermissionCustom } from '../../../../../Helpers/CustomPermission';
import { checkError } from '../../../../../Helpers/AuthHelper';

class ContactAddressTab extends Component {
  state = {
    loading: true,
    contactAddresses: [],
    renderAddAddress: false,
    renderEditAddress: false,
    rendershowAdress: true,
    editAddressId: null,
    editAddressType: null,
    showDeleteModal: false,
    deleteAddress: {},
    showPrompt: false,
    updateoverAll: false,
    updateModal: false,
    disableBtn: false,
  };

  componentDidMount() {
    this.fetchContactAddresses();
  }

  fetchContactAddresses = () => {
    this.setState({ loading: true });
    restRequest('get', `contacts/${this.props.contactId}/addresses`)
      .then((addresses) => {
        this.setState({
          contactAddresses: addresses,
          contactAddressLimit:
            addresses && addresses.length ? addresses.length : 0,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  };

  handleAdd = () => {
    this.setState({ renderAddAddress: true });
  };

  handleAddSubmit = () => {
    this.fetchContactAddresses();
    this.setState({ renderAddAddress: false });
  };

  handleEdit = (address) => {
    this.setState({
      renderEditAddress: true,
      editAddressId: address.id,
      editAddressType: address.address_type,
    });
  };

  handleEditSubmit = () => {
    this.setState({ renderEditAddress: false });
    this.fetchContactAddresses();
  };

  handleDelete = (address) => {
    this.setState({
      deleteAddress: address,
      showDeleteModal: true,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
    });
  };

  confirmDelete(id) {
    this.setState({
      disableBtn: true,
    });
    restRequest('delete', `contacts/${this.props.contactId}/addresses/${id}`)
      .then(() => {
        let updatedContactAddresses = this.state.contactAddresses.filter(
          (address) => address.id !== id
        );
        this.setState({
          contactAddresses: updatedContactAddresses,
          showDeleteModal: false,
        });
        this.props.handleToast(`Address deleted successfully`, 'success');
      })
      .catch((error) => {
        this.setState({
          disableBtn: false,
        });
        checkError(error, this.props.handleToast);
        this.closeDeleteModal();
      });
  }

  handlePrompt = (status = true) => this.setState({ showPrompt: status });
  handleUpdate = (status = true) => this.setState({ updateoverAll: status });
  handleUpdateModal = (status = true) => this.setState({ updateModal: status });

  renderDeleteModal() {
    let address = this.state.deleteAddress;
    return (
      <CustomModal
        type="delete"
        showModal={this.state.showDeleteModal}
        title="Confirm Delete"
        onClose={this.closeDeleteModal}
        onSubmit={() => this.confirmDelete(address.id)}
        disabled={this.state.disableBtn}
      >
        Are you sure you want to delete this address?
      </CustomModal>
    );
  }

  handleDefaultAddress = (id, type) => {
    const { updateoverAll } = this.state;
    const payload = {
      updateoverAll,
    };
    restRequest(
      'put',
      `contacts/${this.props.contactId}/addresses/${id}/mark/${type}`,
      payload
    )
      .then(() => {
        this.fetchContactAddresses();
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  };

  closeAddressModal = () => {
    this.setState({
      renderAddAddress: false,
      renderEditAddress: false,
    });
  };

  renderAddContactModal = () => (
    <CustomModal
      showModal={this.state.renderAddAddress}
      title="Add Billing/Shipping Address"
      renderActions={false}
      minWidth={110}
      size="large"
      onClose={() => {
        this.closeAddressModal();
        this.handlePrompt(false);
      }}
      showPrompt={this.state.showPrompt}
    >
      <AddContactAddress
        contactId={this.props.contactId}
        contactAddresses={this.state.contactAddresses}
        onCancel={() => this.setState({ renderAddAddress: false })}
        onSubmit={this.handleAddSubmit}
        handleToast={this.props.handleToast}
        handlePrompt={this.handlePrompt}
      />
    </CustomModal>
  );

  renderEditCOntactModal = () => {
    var label = 'Address';
    if (this.state.editAddressType != null) {
      label =
        this.state.editAddressType.charAt(0).toUpperCase() +
        this.state.editAddressType.slice(1);
    }
    return (
      <>
        <CustomModal
          showModal={this.state.renderEditAddress}
          title={`Edit ${label} Address`}
          renderActions={false}
          minWidth={110}
          size="large"
          onClose={() => {
            this.closeAddressModal();
          }}
          showPrompt={this.state.showPrompt}
        >
          <AddressForm
            type="edit"
            contactId={this.props.contactId}
            addressId={this.state.editAddressId}
            onSubmit={this.handleEditSubmit}
            onCancel={() => this.setState({ renderEditAddress: false })}
            handleToast={this.props.handleToast}
            handlePrompt={this.handlePrompt}
          />
        </CustomModal>
      </>
    );
  };
  renderContactAddresses() {
    return (
      <>
        {this.renderDeleteModal()}
        <CardContainer>
          {hasPermissionCustom('contact', 'Create') ? (
            this.state.contactAddressLimit < 6 &&
            !this.props.inModal && (
              <AddCard title="Add Address" handleAdd={this.handleAdd} />
            )
          ) : (
            <div className="card">
              <div className="card-add pointer">
                <div className="card-add--title">Address</div>
              </div>
            </div>
          )}
          {(this.state.contactAddressLimit >= 6 || this.props.inModal) && (
            <div className="card">
              <div className="card-add pointer">
                <div className="card-add--title">Address</div>
              </div>
            </div>
          )}
          {this.renderAddContactModal()}
          {this.renderEditCOntactModal()}
          <ContactAddress
            contactAddresses={this.state.contactAddresses}
            inModal={this.props.inModal}
            handleEdit={this.handleEdit}
            handleDelete={this.handleDelete}
            handleDefaultAddress={this.handleDefaultAddress}
            handleUpdate={this.handleUpdate}
            handleUpdateModal={this.handleUpdateModal}
            updateoverAll={this.state.updateoverAll}
            updateModal={this.state.updateModal}
          />
        </CardContainer>
      </>
    );
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <div className="float-left w-100">{this.renderContactAddresses()}</div>
    );
  }
}

export default ContactAddressTab;
