import React, { Component, Fragment } from 'react';

import { restRequest } from '../../../../Helpers/RequestHelper';
import getDate from '../../../../Helpers/Date/GetDate';

import { NewPackageIcon } from '../../../../Assets/Navigation/NavigationIcons';
import Table from '../../../../Components/Table/Table';
import Loader from '../../../../Components/Loader/Loader';
import OverlayLoader from '../../../../Components/Loader/OverlayLoader';
import PDFViewer from '../../../../Components/PDFViewer/PDFViewer';
import CustomModal from '../../../../Components/CustomModal/CustomModal';

import { NoItemsBox } from '../../../../Components/Layout/Box';
import PackageForm from './../../../../Components/Form/PackageForm/PackageForm';
import ShipmentCreate from './../../Packages/ShipmentCreate';
// import { LinkStatusColumn } from '../../../../Components/ListTable/ListTableColumnJoin'
import EditIcon from '../../../../Assets/General/EditIcon';
import DeleteIcon from '../../../../Assets/General/DeleteIcon';
import MarkAsDeliveredIcon from '../../../../Assets/General/MarkAsDeliveredIcon';
import MarkAsUndeliveredIcon from '../../../../Assets/General/MarkAsUndeliveredIcon';
import ShipManuallyIcon from '../../../../Assets/General/ShipManuallyIcon';
import DeleteShipmentIcon from '../../../../Assets/General/DeleteShipmentIcon';
import PdfIcon from '../../../../Assets/General/PdfIcon';
import { MdAdd } from '../../../../Common/Icons';

import ShipNowIcon from '../../../../Assets/General/ShipNowIcon';
import PackageDetails from '../../Packages/PackageDetails/index';
import { getObjFromLS, setObjInLS } from '../../../../Helpers/LocalStorage';
import { checkError } from '../../../../Helpers/AuthHelper';

export default class Packages extends Component {
  state = {
    loading: true,
    packageList: [],
    showPackageCreateModal: false,
    pdf: false,
    showPDF: false,
    showPackageEditModal: false,
    showPackagesViewModal: false,
    showPackageDeleteModal: false,
    currentPackageID: null,
    packageID: null,
    showManualShippingModal: false,
    showShipmentDeleteModal: false,
    showCreateButton: false,
    showPrompt: false,
    processing: false,
  };

  tableRowOptions = [
    {
      label: 'Ship Manually',
      onClick: (id) => this.handleManualShipping(id),
      icon: ShipManuallyIcon,

      disabled: (id) =>
        this.props.hasPermissionCustom('shipment', 'Create') === false
          ? true
          : this.getCurrentPackage(id).status !== 'not shipped',
    },
    {
      label: 'Delete Shipment',
      onClick: (id) => this.openShipmentDeleteModal(id),
      icon: DeleteShipmentIcon,

      disabled: (id) =>
        this.props.hasPermissionCustom('shipment', 'Delete') === false
          ? true
          : this.getCurrentPackage(id).status !== 'shipped',
    },
    {
      label: 'Mark As Delivered',
      onClick: (id) => this.markPackageAsDelivered(id),
      icon: MarkAsDeliveredIcon,

      disabled: (id) =>
        this.props.hasPermissionCustom('package', 'Edit') === false
          ? true
          : this.getCurrentPackage(id).status !== 'shipped',
    },
    {
      label: 'Mark As Undelivered',
      onClick: (id) => this.markPackageAsUndelivered(id),
      icon: MarkAsUndeliveredIcon,
      disabled: (id) =>
        this.props.hasPermissionCustom('package', 'Edit') === false
          ? true
          : this.getCurrentPackage(id).status !== 'delivered',
    },
    {
      label: 'Print & PDF',
      icon: PdfIcon,
      onClick: (id) => this.openPdf(id),
    },
    {
      label: 'Edit',
      onClick: (id) => this.handleEditPackage(id),
      icon: EditIcon,
      disabled: (id) =>
        this.props.sync_order
          ? true
          : this.props.hasPermissionCustom('package', 'Edit') === false
          ? true
          : this.getCurrentPackage(id).status !== 'not shipped',
    },
    {
      label: 'Delete',
      onClick: (id) => this.openPackageDeleteModal(id),
      icon: DeleteIcon,
      disabled: (id) =>
        this.props.sync_order
          ? true
          : this.props.hasPermissionCustom('package', 'Delete') === false
          ? true
          : this.getCurrentPackage(id).status !== 'not shipped',
    },
  ];
  //.filter((item) =>
  //   this.props.sync_order
  //     ? item.label === 'Edit' || item.label === 'Delete'
  //       ? undefined
  //       : item
  //     : item
  // );

  componentDidMount() {
    this.fetchData();
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  fetchData() {
    restRequest('get', `salesorders/${this.props.salesOrderID}/packages`)
      .then((res) => {
        this.setState({
          packageList: res.list,
          showCreateButton: res.hide_button,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error);
        this.setState({
          loading: false,
        });
      });
  }

  getCurrentPackage = (id) => {
    return this.state.packageList.find((obj) => obj.id === id);
  };

  handleEditPackage(id) {
    const currentPackage = this.getCurrentPackage(id);
    if (currentPackage.status === 'not shipped') {
      this.openPackageEditModal(id);
      return;
    }
    this.props.handleToast(
      'You cannot edit shipped or delivered package',
      'error'
    );
  }

  renderManualShippingModal() {
    const currentPackage = this.getCurrentPackage(this.state.currentPackageID);
    const slipID = currentPackage ? currentPackage.package_no : null;
    return (
      <CustomModal
        className="Shipment_modals_fields ship-modal-res-popup "
        showModal={this.state.showManualShippingModal}
        fill="#000000"
        Icon={ShipNowIcon}
        title="Create Shipment"
        renderActions={false}
        onClose={this.closeAllModals}
        showPrompt={this.state.showPrompt}
      >
        <ShipmentCreate
          packageId={this.state.currentPackageID}
          packageNo={slipID}
          onClose={this.closeAllModals}
          onSubmit={this.handlePackageActionSubmit}
          handleToast={this.props.handleToast}
          Componenthistory={this.props.Componenthistory}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  handleManualShipping = (packageId) => {
    this.setState({
      showManualShippingModal: true,
      currentPackageID: packageId,
    });
  };

  handleDeleteShipment = () => {
    this.setState({ processing: true });
    this.closeAllModals();
    restRequest('delete', `shipments/${this.state.currentPackageID}`)
      .then(() => {
        this.setState({ processing: false });
        let dataFromLS = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLS,
          queryParam: `${
            dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
          }packages=true`,
        });
        this.handlePackageActionSubmit();
        this.props.handleToast('Shipment has been deleted', 'success');
      })
      .catch((error) => {
        this.setState({ processing: false });
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
      });
  };

  renderShipmentDeleteModal() {
    return (
      <CustomModal
        type="delete"
        showModal={this.state.showShipmentDeleteModal}
        title="Delete Shipment"
        onClose={this.closeAllModals}
        onSubmit={this.handleDeleteShipment}
      >
        Are you sure you want to delete this shipment?
      </CustomModal>
    );
  }

  openShipmentDeleteModal = (packageId) => {
    this.setState({
      showShipmentDeleteModal: true,
      currentPackageID: packageId,
    });
  };

  markPackageAsDelivered = (packageId) => {
    this.setState({ processing: true });
    restRequest('put', `shipments/${packageId}/deliver`)
      .then(() => {
        this.setState({ processing: false });
        let dataFromLS = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLS,
          queryParam: `${
            dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
          }packages=true`,
        });
        this.handlePackageActionSubmit();
        this.props.handleToast('Package has been delivered', 'success');
      })
      .catch((error) => {
        this.setState({ processing: false });
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
      });
  };

  markPackageAsUndelivered = (packageId) => {
    this.setState({ processing: true });
    restRequest('put', `shipments/${packageId}/undeliver`)
      .then(() => {
        this.setState({ processing: false });
        let dataFromLS = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLS,
          queryParam: `${
            dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
          }packages=true`,
        });
        this.handlePackageActionSubmit();
        this.props.handleToast('Package has been undelivered', 'success');
      })
      .catch((error) => {
        this.setState({ processing: false });
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
      });
  };

  openPdf = (id) => {
    // restRequest('get', `packages/${id}/pdf`).then(res => {
    //   this.setState({
    //     pdf: res,
    //     showPDF: true
    //   })
    // })
    this.setState({ showPDF: true });
    restRequest('get', `packages/${id}/pdf`)
      .then((res) => {
        this.setState({ pdf: res });
      })
      .catch((error) => checkError(error));
  };

  closeAllModals = () => {
    this.setState({
      showPackageCreateModal: false,
      showPDF: false,
      showPackageEditModal: false,
      showPackageDeleteModal: false,
      showManualShippingModal: false,
      showPackagesViewModal: false,
      showShipmentDeleteModal: false,
    });
  };

  handleDeletePackage = () => {
    this.closeAllModals();
    restRequest('delete', `packages/${this.state.currentPackageID}`)
      .then((message) => {
        let dataFromLS = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLS,
          queryParam: `${
            dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
          }packages=true`,
        });
        this.props.handleToast(message.message, 'success');
        this.handlePackageActionSubmit();
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
      });
  };

  renderPackageDeleteModal() {
    return (
      <CustomModal
        type="delete"
        showModal={this.state.showPackageDeleteModal}
        title="Confirm Package Delete"
        onClose={this.closeAllModals}
        onSubmit={this.handleDeletePackage}
      >
        Are you sure you want to delete this package?
      </CustomModal>
    );
  }

  openPackageDeleteModal(id) {
    this.setState({
      showPackageDeleteModal: true,
      currentPackageID: id,
    });
  }

  renderPackageEditModal() {
    return (
      <CustomModal
        showModal={this.state.showPackageEditModal}
        title={'Edit Package | ' + this.props.saleOrderNo}
        renderActions={false}
        size="medium"
        onClose={this.closeAllModals}
        showPrompt={this.state.showPrompt}
        mainContainerClass="dragDropPopupContainer"
      >
        <PackageForm
          inModal
          type="edit"
          id={this.state.currentPackageID}
          close={this.closeAllModals}
          onSubmit={this.handlePackageActionSubmit}
          handleToast={this.props.handleToast}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  openPackageEditModal(id) {
    this.setState({
      showPackageEditModal: true,
      currentPackageID: id,
    });
  }

  handlePackageActionSubmit = () => {
    let dataFromLS = getObjFromLS('module');
    setObjInLS('module', {
      ...dataFromLS,
      queryParam: `${
        dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
      }packages=true`,
    });
    this.fetchData();
    this.props.updateSalesOrderStatus();
    this.closeAllModals();
  };

  handlePrompt = (status = true) => {
    this.setState({ showPrompt: status });
  };

  renderPackageCreateModal() {
    return (
      <CustomModal
        showModal={this.state.showPackageCreateModal}
        title={'Create Package | ' + this.props.saleOrderNo}
        Icon={NewPackageIcon}
        renderActions={false}
        size="medium"
        onClose={this.closeAllModals}
        showPrompt={this.state.showPrompt}
        className="so-package-popup so-package-main"
        mainContainerClass="dragDropPopupContainer"
      >
        <PackageForm
          className="so-package-dropdown"
          inModal
          type="add"
          salesOrderId={this.props.salesOrderID}
          close={this.closeAllModals}
          onSubmit={this.handlePackageActionSubmit}
          handleToast={this.props.handleToast}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  async createNewPackage() {
    const { packageStatus, dropshipStatus, salesOrderID, handleToast } =
      this.props;
    // check for this case: package can be created or not
    if (
      packageStatus === 'partially packed' &&
      dropshipStatus === 'partially dropshipped'
    ) {
      try {
        await restRequest(
          'get',
          `packages/create?sales_order_id=${salesOrderID}`
        );
      } catch (error) {
        checkError(error, handleToast);
        //handleToast(error, 'error')
        return;
      }
    }
    this.setState({ showPackageCreateModal: true });
  }
  openPackagesViewModal = (id) => {
    this.setState({
      showPackagesViewModal: true,
      packageID: id,
    });
  };
  preparePackageList(packages) {
    return packages.map((packageSlip) => {
      return {
        ...packageSlip,
        packageNo: this.props.inModal ? (
          packageSlip.package_no
        ) : (
          <div onClick={() => this.openPackagesViewModal(packageSlip.id)}>
            {packageSlip.package_no}
          </div>
        ),
        // packageNo: <LinkStatusColumn to={`/packages/${packageSlip.id}`} label={packageSlip.package_no} />,
        packageDate: getDate(packageSlip.package_date),
      };
    });
  }

  isPackageCreatable(packageStatus, orderStatus, dropshipStatus) {
    if (
      packageStatus === 'packed' ||
      ['onhold', 'void'].includes(orderStatus) ||
      dropshipStatus === 'dropshipped'
    )
      return false;

    return true;
  }

  viewRecordPaymentModal() {
    return (
      <CustomModal
        Icon={NewPackageIcon}
        showModal={this.state.showPackagesViewModal}
        title="View Package"
        modaltitle="inventory_modal_title"
        renderActions={false}
        linkTo={`packages/${this.state.packageID}`} //commenting this as requested by QA to disable only for package
        size="large"
        onClose={this.closeAllModals}
        classNames="view-package-popup"
      >
        <PackageDetails id={this.state.packageID} inModal={true} />
      </CustomModal>
    );
  }

  renderPackagesListTable() {
    const { packageStatus, salesOrderStatus, dropshipStatus, label } =
      this.props;
    const tableListData = this.preparePackageList(this.state.packageList);
    // const tableHeaders = []
    // const tableData = ['packageNo', 'packageDate', 'options']
    const tableHeaders = ['Package No.', 'Date', 'Customer Name', 'Status'];
    const tableData = [
      'packageNo',
      'packageDate',
      'customer_name',
      'status',
      this.props.inModal ? '' : 'options',
    ];
    let showButton = this.props.inModal
      ? this.props.inModal
      : this.state.showCreateButton;
    return (
      <Fragment>
        <Table
          list={tableListData}
          tableHeaders={tableHeaders}
          tableData={tableData}
          fromTab={true}
          options={this.props.inModal ? [] : this.tableRowOptions}
          // linkTo={{ path: '/packages/', linkedColumn: 'packageNo' }}
          className={`box-table ${label ? label + '_menu' : ''}`}
          inModal={this.props.inModal}
        />
        {this.props.hasPermissionCustom('package', 'Create') &&
          this.isPackageCreatable(
            packageStatus,
            salesOrderStatus,
            dropshipStatus
          ) && (
            <Fragment>
              {showButton ? (
                ''
              ) : (
                <button
                  className="layout-box--button btn-width"
                  onClick={() => this.createNewPackage()}
                >
                  <MdAdd className=" black-fill" />
                  Create Package
                </button>
              )}
            </Fragment>
          )}
      </Fragment>
    );
  }

  renderModals() {
    return (
      <Fragment>
        <PDFViewer
          showPDF={this.state.showPDF}
          hidePDFModal={this.closeAllModals}
          pdf={this.state.pdf}
        />
        {this.renderManualShippingModal()}
        {this.viewRecordPaymentModal()}
        {this.renderShipmentDeleteModal()}
        {this.renderPackageCreateModal()}
        {this.renderPackageEditModal()}
        {this.renderPackageDeleteModal()}
      </Fragment>
    );
  }

  renderPackagesList() {
    const { packageStatus, salesOrderStatus, dropshipStatus } = this.props;
    let showButton = this.props.inModal
      ? this.props.inModal
      : this.state.showCreateButton;
    return (
      <Fragment>
        {this.renderModals()}
        {!this.state.packageList.length ? (
          <NoItemsBox
            subtitle="No Packages created yet."
            buttonText="Create Package"
            showButton={
              this.props.hasPermissionCustom('package', 'Create') &&
              this.isPackageCreatable(
                packageStatus,
                salesOrderStatus,
                dropshipStatus
              )
            }
            handleclick={() => this.createNewPackage()}
            inModal={showButton}
            Icon={NewPackageIcon} //add colors to new sbg
            btnDisabled={this.props.sync_order?.order_status}
          />
        ) : (
          this.renderPackagesListTable()
        )}
      </Fragment>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return (
      <>
        {this.state.processing && <OverlayLoader />}
        {this.renderPackagesList()}
      </>
    );
  }
}
