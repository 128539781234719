import React, { useEffect } from 'react';
import { TaxIcon } from '../../Assets/Settings/SettingsIcon';
import TaxForm from '../../Components/Form/TaxForm/TaxForm';
import FormLayout from '../../Components/Layout/FormLayout';

const AddTax = (handleToast) => {
  useEffect(() => {
    document.title = 'Add Tax';
  }, []);

  const renderTaxForm = () => (
    <TaxForm title="New Tax" type="add" handleToast={handleToast} />
  );

  return (
    <FormLayout
      title="Taxes"
      Icon={TaxIcon}
      subtitle="Add New"
      closeUrl="/taxes"
    >
      {renderTaxForm()}
    </FormLayout>
  );
};

export default AddTax;
