import React, { useEffect, useState } from 'react';
import OrganizationIcon from '../../Assets/General/SettingPageIcon';
import { OrganizationForm } from '../../Components/Form';
import { FormLayout } from '../../Components';
import useToggleClick from '../../Hooks';
import { Prompt } from 'react-router-dom';
import './AddOrganization.css';

const AddOrganization = ({ handleToast }) => {
  const [, handleCrossAlert] = useToggleClick();
  const [showPrompt, setPrompt] = useState(false);

  useEffect(() => {
    document.title = 'Add Business';
    window.onbeforeunload = function () {
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const handlePrompt = (status = true) => setPrompt(status);

  return (
    <>
      <Prompt
        when={showPrompt}
        message="Are you sure you want to leave page while changes made?"
      />
      <FormLayout
        title="Add New Business"
        Icon={OrganizationIcon}
        closeUrl="/oc/organization/choose"
        layoutBoxClass="uiux-form-redesign"
      >
        <OrganizationForm
          type="Add"
          handleToast={handleToast}
          handleCrossAlert={handleCrossAlert}
          setPrompt={handlePrompt}
        />
      </FormLayout>
    </>
  );
};

export default AddOrganization;
