import React, { Component } from 'react';
import { restRequest } from '../../Helpers/RequestHelper';
import ListTable from '../../Components/ListTable/ListTable';
import HeaderWrapper from '../../Components/HeaderWrapper/HeaderWrapper';
import DeleteIcon from '../../Assets/General/DeleteIcon';
import EmptyTable from '../../Components/ListTable/EmptyTable';
import Loader from '../../Components/Loader/Loader';
import { checkError } from '../../Helpers/AuthHelper';
import { setObjInLS } from '../../Helpers/LocalStorage';
class AccountList extends Component {
  // addNew =
  state = {
    data: [],
    allData: [],
    loading: true,
    sortFilter: 'created_at',
    orderBy: 'desc',
    showCreateTaxModal: window.location.search.slice(1).split('=')[0] === 'new',
    showEditTaxModal: false,
    taxID: null,
    totalRecords: 0,
  };
  componentDidMount() {
    document.title = 'Inventory Accounts';
    this.fetchData();
  }

  fetchData = () => {
    this.setState({ loading: true });
    restRequest(
      'get',
      `accountGroups?page=${this.state.activePage}&sort_by=${this.state.sortFilter}&order_by=${this.state.orderBy}`
    )
      .then((res) => {
        this.setState({
          data: this.prepareDataForTable(res),
          allData: res,
          totalRecords: res && res.length ? res.length : 0,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
  };

  prepareDataForTable = (data) =>
    data.map((accountGroup) => ({
      id: accountGroup.id,
      'Group Name': {
        id: 'name',
        sortable: false,
        getValue: function () {
          return accountGroup[this.id];
        },
      },
      Type: {
        id: 'type',
        getValue: function () {
          return accountGroup[this.id];
        },
      },
    }));

  hasDeltePermission() {
    const hasDeltePermission = this.hasPermission('account', 'Delete');
    return hasDeltePermission;
  }
  checkDeletable = (id) =>
    this.state.allData.find((one) => one.id === id).deletable === 0;

  prepareRowOptions() {
    const rowOptions = [
      {
        label: 'Delete',
        onClick: (id) => this.deleteAccountData(id),
        icon: DeleteIcon,
        disabled: (id) => !this.hasDeltePermission() || this.checkDeletable(id),
      },
    ];

    return rowOptions;
  }

  deleteAccountData = (id) => {
    this.openDeleteModal(id);
    setObjInLS('tab', 'accountGroup');
  };

  renderTable() {
    if (this.state.loading) return <Loader />;
    if (this.state.data.length === 0) return <EmptyTable />;
    return (
      <>
        <ListTable
          data={this.state.data}
          rowOptions={this.prepareRowOptions()}
          generateRoute={this.generateRoute}
          hasCheckbox={false}
          loading={this.state.loading}
          orderBy={this.state.orderBy}
          sortFilter={this.state.sortFilter}
          totalRecords={this.state.totalRecords}
        />
      </>
    );
  }

  render() {
    return this.renderTable();
  }
}

export default HeaderWrapper(AccountList, {
  renderHeaderWrapper: false,
  baseUrl: 'accountGroups',
  redirectUrl: '/accounting/account',
  permissionName: 'account',
  subName: 'Account Group',
});
