import React, { Component } from 'react';
import moment from 'moment';
import { Calendar } from 'react-date-range';

import getDate from '../../Helpers/Date/GetDate';

import singleDatePicker from './SingleDatePicker.module.css';

class SingleDatePicker extends Component {
  datePickerRef = React.createRef();

  state = {
    showDatePicker: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  }

  handleOutsideClick = (event) => {
    if (
      this.datePickerRef.current &&
      !this.datePickerRef.current.contains(event.target)
    ) {
      if (this.state.showDatePicker) this.handleDatePickerToggle();
    }
  };

  handleDatePickerToggle = () => {
    this.setState((state) => ({ showDatePicker: !state.showDatePicker }));
  };

  handleChange = (event) => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      this.props.handleChange(null);
    }
  };

  handleDateChange = (date) => {
    this.props.handleChange(moment(date));
    this.handleDatePickerToggle();
  };

  render() {
    let {
      className,
      placeholder,
      id,
      value,
      onBlur,
      disabled,
      handleFocus,
      minDate = null,
    } = this.props;

    return (
      <div className="form-input-wrapper" ref={this.datePickerRef}>
        <input
          className={className}
          placeholder={placeholder}
          id={id}
          value={this.props.value ? getDate(this.props.value) : ''}
          onBlur={onBlur}
          disabled={disabled}
          onFocus={handleFocus}
          onClick={this.handleDatePickerToggle}
          onKeyDown={this.handleChange}
          onChange={this.handleChange}
        />
        {this.state.showDatePicker && (
          <div className={`${singleDatePicker.singleDatePicker_body} single-calander-main`}>
            <Calendar
              className='single-calander-main'
              date={value}
              onChange={this.handleDateChange}
              minDate={new Date(minDate)}
            />
          </div>
        )}
      </div>
    );
  }
}

export default SingleDatePicker;
