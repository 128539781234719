import React from 'react'

import RecordPaymentIcon from "../../Assets/General/RecordPaymentIcon";

import Email from '../../Components/Email/Email'

const CustomerPaymentEmail = (props) => {
  return (
    <Email
      {...props}
      Icon={RecordPaymentIcon}
      name="Customer Payment"
      onCancelURL="/paymentsmade"
      emailAPI={`advancepayment/${props.match.params.id}/mail`}
      fetchDataAPI={`advancepayment/${props.match.params.id}/mail`}
      getBackTab
    />
  )
}

export default CustomerPaymentEmail
