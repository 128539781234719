

import React from 'react'

export default ({
  className = ''
}) => (

  
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
<g id="Group_795" data-name="Group 795" transform="translate(-1639 -893.649)">
  <circle id="Ellipse_46" data-name="Ellipse 46" cx="13" cy="13" r="13" transform="translate(1639 893.649)" fill="#306ab4"/>
  <path id="Icon_material-call" data-name="Icon material-call" d="M7.439,10.824a12.3,12.3,0,0,0,5.35,5.35l1.786-1.786a.807.807,0,0,1,.828-.195,9.26,9.26,0,0,0,2.9.463.814.814,0,0,1,.812.812V18.3a.814.814,0,0,1-.812.812A13.8,13.8,0,0,1,4.5,5.312.814.814,0,0,1,5.312,4.5H8.153a.814.814,0,0,1,.812.812,9.222,9.222,0,0,0,.463,2.9.815.815,0,0,1-.2.828L7.439,10.824Z" transform="translate(1640.194 894.843)" fill="#fff"/>
</g>
</svg>

  )
