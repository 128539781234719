import React from 'react';
import proptypes from 'prop-types';
import { MdClose } from '../../Common/Icons';
import './Tabs.css';
import pageIdentify from '../../Helpers/PageIdentify';
import {
  setValueInLS,
  getObjFromLS,
  setObjInLS,
} from '../../Helpers/LocalStorage';
// import { Redirect } from 'react-router-dom'
import InfoIcon from '../../../src/Assets/Dashboard/infoIcon';
const Tabs = ({
  tabs,
  currentTab,
  history,
  inModal,
  showCross = true,
  fromDashboard = false,
}) => {
  function isCurrent(id) {
    return id === currentTab && 'active';
  }

  function renderTabContent() {
    let index = tabs.findIndex((tab) => tab.id === currentTab);
    return tabs[index].renderContent();
  }

  return (
    <div className="tab-component--tab ">
      <div className="tab-component--buttons border_bottom">
        <ul className="nav_tabs">
          {tabs.map((tab, index) => {
            return (
              <li
                key={index}
                className={`${isCurrent(tab.id)}`}
                onClick={() => tab.onClick()}
              >
                <a>{tab.label}</a>
              </li>
            );
          })}
        </ul>
        {fromDashboard && (
          <div className="dash_info_div dash_info-mob">
            {' '}
            <InfoIcon className="dashboardTableFill" />{' '}
            <span className="dashboard_info_text">Showing Last 10 Entries</span>{' '}
          </div>
        )}
        {!inModal && !pageIdentify(window.location.pathname) && !fromDashboard && (
          <div className="close_icon">
            <MdClose
              onClick={() => {
                let dataFromLS = getObjFromLS('module');
                setObjInLS('module', {
                  ...dataFromLS,
                  presistDetailPage: false,
                });
                setValueInLS('fullscreen', 'true');
                sessionStorage.setItem('once', true);

                history.push('/r');
              }}
            />
          </div>
        )}
      </div>
      <div id="printMe" className="tab-component--content">
        {renderTabContent()}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  tabs: proptypes.arrayOf(proptypes.object).isRequired,
  currentTab: proptypes.string.isRequired,
};

export default Tabs;
