import React, { Component } from 'react';

import { restRequest } from '../../../../../Helpers/RequestHelper';
import AddCard from '../../../../../Components/Card/AddCard';
import AddContactAddress from './AddContactPerson';
import ContactPerson from './ContactPerson';
import ContactPersonForm from '../../../../../Components/Form/ContactPersonForm/ContactPersonForm';
import CardContainer from './../../../../../Components/Card/CardContainer';
import Loader from './../../../../../Components/Loader/Loader';
import CustomModal from '../../../../../Components/CustomModal/CustomModal';
import './AddContactPerson.css';
import { hasPermissionCustom } from '../../../../../Helpers/CustomPermission';
import { checkError } from '../../../../../Helpers/AuthHelper';

class ContactAddressTab extends Component {
  state = {
    loading: true,
    contactDetails: [],
    renderAddContact: false,
    renderEditAddress: false,
    rendershowAdress: true,
    editPersonId: null,
    showDeleteModal: false,
    deleteContact: {},
    disableBtn: false,
  };

  componentDidMount() {
    this.fetchcontactDetails();
  }

  fetchcontactDetails = () => {
    this.setState({ loading: true });
    restRequest('get', `contacts/${this.props.contactId}/persons`)
      .then((contact) => {
        this.setState({
          contactDetails: contact,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  };

  handleAdd = () => {
    this.setState({ renderAddContact: true });
  };

  handleAddSubmit = () => {
    this.fetchcontactDetails();
    this.setState({ renderAddContact: false });
  };

  handleEdit = (address) => {
    this.setState({
      renderEditAddress: true,
      editPersonId: address.id,
    });
  };

  handleEditSubmit = () => {
    this.setState({ renderEditAddress: false });
    this.fetchcontactDetails();
  };

  handleDelete = (details) => {
    this.setState({
      deleteContact: details,
      showDeleteModal: true,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
    });
  };

  confirmDelete(id) {
    this.setState({
      disableBtn: true,
    });
    restRequest('delete', `contacts/${this.props.contactId}/persons/${id}`)
      .then(() => {
        let updatedcontactDetails = this.state.contactDetails.filter(
          (personDetails) => personDetails.id !== id
        );
        this.setState({
          contactDetails: updatedcontactDetails,
          showDeleteModal: false,
        });
        this.props.handleToast(
          `Contact Person deleted successfully`,
          'success'
        );
      })
      .catch((error) => {
        this.setState({
          disableBtn: false,
        });
        this.closeDeleteModal();
        checkError(error, this.props.handleToast);
      });
  }

  renderDeleteModal() {
    let deleteContactPerson = this.state.deleteContact;
    return (
      <CustomModal
        type="delete"
        showModal={this.state.showDeleteModal}
        title="Confirm Delete"
        onClose={this.closeDeleteModal}
        onSubmit={() => this.confirmDelete(deleteContactPerson.id)}
        disabled={this.state.disableBtn}
      >
        Are you sure you want to delete this Contact Person?
      </CustomModal>
    );
  }

  handleDefaultAddress = (id, type) => {
    restRequest(
      'put',
      `contacts/${this.props.contactId}/addresses/${id}/mark/${type}`
    )
      .then(() => {
        this.fetchcontactDetails();
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  };

  closeAddressModal = () => {
    this.setState({
      renderAddContact: false,
      renderEditAddress: false,
    });
  };

  renderAddContactModal = () => (
    <CustomModal
      showModal={this.state.renderAddContact}
      title="Add Contact Person"
      renderActions={false}
      minWidth={110}
      size="large"
      onClose={() => {
        this.closeAddressModal();
      }}
    >
      <AddContactAddress
        contactId={this.props.contactId}
        contactDetails={this.state.contactDetails}
        onCancel={() => this.setState({ renderAddContact: false })}
        onSubmit={this.handleAddSubmit}
        handleToast={this.props.handleToast}
      />
    </CustomModal>
  );

  renderEditCOntactModal = () => (
    <>
      <CustomModal
        showModal={this.state.renderEditAddress}
        title="Edit Contact Person"
        renderActions={false}
        minWidth={110}
        size="large"
        onClose={() => {
          this.closeAddressModal();
        }}
      >
        <ContactPersonForm
          type="edit"
          contactId={this.props.contactId}
          personId={this.state.editPersonId}
          onSubmit={this.handleEditSubmit}
          onCancel={() => this.setState({ renderEditAddress: false })}
          handleToast={this.props.handleToast}
        />
      </CustomModal>
    </>
  );

  rendercontactDetails() {
    return (
      <div className="contact_person">
        {this.renderDeleteModal()}
        <CardContainer>
          {hasPermissionCustom('contact', 'Create') ? (
            !this.props.inModal && (
              <AddCard title="Contact Person " handleAdd={this.handleAdd} />
            )
          ) : (
            <div className="card">
              <div className="card-add pointer">
                <div className="card-add--title">Contact Person</div>
              </div>
            </div>
          )}
          {this.renderAddContactModal()}
          {this.renderEditCOntactModal()}
          <ContactPerson
            inModal={this.props.inModal}
            contactDetails={this.state.contactDetails}
            handleEdit={this.handleEdit}
            handleDelete={this.handleDelete}
          />
        </CardContainer>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <div className="float-left w-100 mb_20">
        {this.rendercontactDetails()}
      </div>
    );
  }
}

export default ContactAddressTab;
