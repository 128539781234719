import React, { Component } from 'react';
//import getDate from '../../../../../Helpers/Date/GetDate';
//import RecordPaymentIcon from '../../../../../Assets/General/RecordPaymentIcon';
import { restRequest } from '../../../../../Helpers/RequestHelper';
import { checkError } from '../../../../../Helpers';
import { Loader } from '../../../../../Components';
import EmptyTable from '../../../../../Components/ListTable/EmptyTable';
import TimelineCard from '../../../../../Components/TimelineCard/TimelineCard';

class HitoryTab extends Component {
  state = {
    loading: true,
    history: [],
  };

  componentDidMount() {
    this.fetchHistory();
  }

  fetchHistory = () => {
    restRequest(
      'get',
      `advancepayment/${this.props.id}/activity-log/${this.props.type}`
    )
      .then((History) => {
        this.setState({
          history: History,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        checkError(error);
      });
  };

  render() {
    const { loading, history } = this.state;
    return (
      <div>
        {loading ? (
          <Loader />
        ) : history.length === 0 ? (
          <EmptyTable />
        ) : (
          <div className="packages_activity_container">
            {history.length > 0 &&
              history.map((data) => {
                return (
                  <TimelineCard
                    key={data.id}
                    name={data.creator}
                    description={data.description}
                    date={data.date}
                    time={data.time}
                    subject={data.subject}
                    type={data.type}
                  />
                );
              })}
          </div>
        )}
      </div>
    );
  }
}
export default HitoryTab;
