import React from 'react';
import { MdAdd } from '../../Common/Icons';
import './Box.css';

export const NoItemsBox = ({
  subtitle,
  showButton = true,
  voidBill = false,
  inModal = false,
  showSecondaryButton = false,
  buttonText,
  buttonTextSecondary,
  Icon = () => null,
  handleclick = () => { },
  handleclickSecondary = () => { },
  btnDisabled = false,
}) => {
  //passing this fill to view the svg in following color. whenever new icon will be used, it will automatically change the color.
  //Also added plus icon and applied the same black-fill styles in css file
  return (
    <div className="layout--box--body--empty">
      <Icon fill="#e6e6e6" />
      <div className="layout--box--subtitle">{subtitle}</div>
      <div className="buttons_parent" id="click_New_Purchase_Receives_btn">
        {!voidBill && (
          <div>
            {showButton &&
              (inModal ? (
                ' '
              ) : (
                <button
                  className="layout-box--button"
                  onClick={handleclick}
                  disabled={btnDisabled}
                >
                  <MdAdd className=" black-fill" />
                  {buttonText}
                </button>
              ))}
          </div>
        )}
        {!voidBill && (
          <div>
            {showSecondaryButton &&
              (inModal ? (
                ' '
              ) : (
                <button
                  className="layout-box--button"
                  onClick={handleclickSecondary}
                >
                  <MdAdd className=" black-fill" />
                  {buttonTextSecondary}
                </button>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ({ title = '', className = '', children, layoutBoxClass = '' }) => {
  return (
    <div className={`layout--box ${className} ${layoutBoxClass}`}>
      {title && <h5 className="layout--box--title">{title}</h5>}
      <div className="layout--box--body">{children}</div>
    </div>
  );
};
