export const woocommerceModalsContent = [
  {
    heading: `Sync Settings`,
    content: ` `,
    btnText: 'Save',
    btnHandler: 'syncsave',
  },
  {
    heading: `Are you sure you want to disable Woocommerce Integraion ?`,
    subHeading: 'Please note the following before disabling:',

    content: [
      'Please note the following before disabling:',
      'The Woo-Commerce integration will be disable permanently.',
      'The products will no longer be mapped to Woo-Commerce',
      `Actions such as stock update and shipment update to Woo-Commerce
            will not be triggered.`,
    ],
    btnText: 'Disable',
    btnHandler: 'disable',
  },
  {
    heading: `Are you sure you want to enable Woocommerce Integraion ?`,
    subHeading: 'Please note the following enabling:',
    content: [
      'The Woo-Commerce integration will be enable permanently.',
      'The products will no longer be mapped to Woo-Commerce',
      `Actions such as stock update and shipment update to Woo-Commerce
            will not be triggered.`,
    ],
    btnText: 'Enable',
    btnHandler: 'enable',
  },
  {
    heading: `Are you sure you want to disconnect Woocommerce Integraion ?`,
    subHeading: 'Please note the following disconnecting:',
    content: [
      'The Woo-Commerce integration will be disconnecting permanently.',
      'The products will no longer be mapped to Woo-Commerce',
      `Actions such as stock update and shipment update to Woo-Commerce
            will not be triggered.`,
    ],
    btnText: 'Disconnect',
    btnHandler: 'disconnect',
  },
];
export const shopifyModalsContent = [
  {
    heading: `Sync Settings`,
    content: ` `,
    btnText: 'Save',
    btnHandler: 'syncsave',
  },
  {
    heading: `Are you sure you want to disable shopify Integraion ?`,
    subHeading: 'Please note the following before disabling:',

    content: [
      'Please note the following before disabling:',
      'The shopify integration will be disable permanently.',
      'The products will no longer be mapped to shopify',
      `Actions such as stock update and shipment update to shopify
            will not be triggered.`,
    ],
    btnText: 'Disable',
    btnHandler: 'disable',
  },
  {
    heading: `Are you sure you want to enable shopify Integraion ?`,
    subHeading: 'Please note the following enabling:',
    content: [
      'The shopify integration will be enable permanently.',
      'The products will no longer be mapped to shopify',
      `Actions such as stock update and shipment update to shopify
            will not be triggered.`,
    ],
    btnText: 'Enable',
    btnHandler: 'enable',
  },
  {
    heading: `Are you sure you want to disconnect shopify Integraion ?`,
    subHeading: 'Please note the following disconnecting:',
    content: [
      'The shopify integration will be disconnecting permanently.',
      'The products will no longer be mapped to shopify',
      `Actions such as stock update and shipment update to shopify
            will not be triggered.`,
    ],
    btnText: 'Disconnect',
    btnHandler: 'disconnect',
  },
];

export const woocommerceSyncSettingTime = [
  {
    value: '30 min',
    label: '30 min',
  },
  {
    value: '60 min',
    label: '1 Hour',
  },
  {
    value: '120 min',
    label: '2 Hours',
  },
  {
    value: '180 min',
    label: '3 Hours',
  },
];

export const wooCommerceProductListing = [
  {
    heading: 'Products',
    seebizHeading: 'Items',
    imgSrc: 'https://i.ibb.co/rmCjFd1/image.png',
    title: 'Online Store',
    item: 'SeeBiz Inventory',
    lastSynDate: `Jan 24 , 21 10:30 AM`,
    syncBtnText: 'Sync Manually',
    sync: false,
    syncType: 'items',
    tooltip: 'Sync Woo Commerce Items',
  },
  {
    heading: 'Orders',
    seebizHeading: 'Sale Orders',
    imgSrc: 'https://i.ibb.co/Y2dMfy1/wight-cal.png',
    title: 'Online Store',
    item: 'SeeBiz Inventory',
    lastSynDate: `Jan 24 , 21 10:30 AM`,
    syncBtnText: 'Sync Manually',
    sync: false,
    syncType: 'sales_order',
    tooltip: 'Sync Woo Commerce Sale Orders',
  },
  // {
  //   heading: 'Stock',
  //   seebizHeading: 'Stock',
  //   imgSrc: 'https://i.ibb.co/LS3TGj4/package.png',
  //   title: 'Online Store',
  //   item: 'SeeBiz Inventory',
  //   lastSynDate: `Jan 24 , 21 10:30 AM`,
  //   syncBtnText: 'Disable',
  //   sync: false,
  //   syncType: 'invoice',
  // },
];
export const shopifyProductListing = [
  {
    heading: 'Products',
    seebizHeading: 'Items',
    imgSrc: 'https://i.ibb.co/rmCjFd1/image.png',
    title: 'Online Store',
    item: 'SeeBiz Inventory',
    lastSynDate: `Jan 24 , 21 10:30 AM`,
    syncBtnText: 'Sync Manually',
    sync: false,
    syncType: 'items',
    tooltip: 'Sync Shopify Items',
  },
  {
    heading: 'Orders',
    seebizHeading: 'Sale Orders',
    imgSrc: 'https://i.ibb.co/Y2dMfy1/wight-cal.png',
    title: 'Online Store',
    item: 'SeeBiz Inventory',
    lastSynDate: `Jan 24 , 21 10:30 AM`,
    syncBtnText: 'Sync Manually',
    sync: false,
    syncType: 'sales_order',
    tooltip: 'SyncShopify Sale Orders',
  },
  // {
  //   heading: 'Stock',
  //   seebizHeading: 'Stock',
  //   imgSrc: 'https://i.ibb.co/LS3TGj4/package.png',
  //   title: 'Online Store',
  //   item: 'SeeBiz Inventory',
  //   lastSynDate: `Jan 24 , 21 10:30 AM`,
  //   syncBtnText: 'Disable',
  //   sync: false,
  //   syncType: 'invoice',
  // },
];
export const wooComItemConfirmDialogContent = [
  {
    heading:
      'On confirming the item, its available quantity of 10 will be updated to your sales channel.',
    subHeading: '',
    btnText: 'Confirm',
    btnHandler: 'confirm',
    content: [
      `Available Quantity = Incoming Quantity - Quantity Committed for online orders.`,
      `Please ensure that the item prices are accurate.`,
    ],
  },
  {
    heading: 'Item Merge interface',
    subHeading: '',
    btnText: 'Merge',
    btnHandler: 'merge',
    content: [`Merge unconfirmed item with existing item in SeeBiz Inventory`],
  },
];
