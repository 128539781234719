import React, { Component, Fragment } from 'react';
import { NewPackageIcon } from '../../../../Assets/Navigation/NavigationIcons';
import { checkError, logout, validToken } from '../../../../Helpers/AuthHelper';
import { restRequest } from '../../../../Helpers/RequestHelper';
import AttachIcon from '../../../../Assets/General/AttachmentIcon';
import DeleteAttachmentIcon from '../../../../Assets/General/DeleteAttachmentIcon';
import DownloadAttachmentIcon from '../../../../Assets/General/DownloadAttachmentIcon';
import {
  getObjFromLS,
  setObjInLS,
  setValueInLS,
} from '../../../../Helpers/LocalStorage';
import { MdClose } from '../../../../Common/Icons';
import HeaderWrapper from '../../../../Components/HeaderWrapper/HeaderWrapper';
import Loader from '../../../../Components/Loader/Loader';
import Address from '../../../../Components/Address/Address';
import { DetailsHeader } from '../../../../Components/Details/Details';
import Box from '../../../../Components/Layout/Box';
import PackageInfo from './Info/PackageInfo';
import ShipmentInfo from './Info/ShipmentInfo';
import PackageItemInfo from './Info/PackageItemInfo';
import PDFViewer from '../../../../Components/PDFViewer/PDFViewer';
import {
  Button,
  ButtonLink,
  ActionMenu,
} from '../../../../Components/CheckedMenu/CheckedMenu';
import NotFound from '../../../../Components/NotFound/NotFound';
import AccessDenied from '../../../../Components/AccessDenied/AccessDenied';
import { controllerNames } from '../../../../Constants';
import TimelineCard from '../../../../Components/TimelineCard/TimelineCard';
import './Info/PackageDetails.css';
import Menu from '../../../../Components/Menu/Menu';
import MenuData from '../../../../Components/Menu/MenuData';
import ShipmentCreate from '../ShipmentCreate';
import CustomModal from '../../../../Components/CustomModal/CustomModal';
import '../NewPackageCreate.css';
import History from '../../../../Assets/General/History';
import ShipNowIcon from '../../../../Assets/General/ShipNowIcon';
import MarkAsDeliveredIcon from '../../../../Assets/General/MarkAsDeliveredIcon';
import MarkAsUndeliveredIcon from '../../../../Assets/General/MarkAsUndeliveredIcon';
import OverlayLoader from '../../../../Components/Loader/OverlayLoader';

import { pdfDownlaod } from '../../../../Helpers/PdfDownload';
import { downloadAttachment } from '../../../../Helpers/downloadAttachment';
import { HeaderMenus } from '../../../../Components';
import useMobileDetection from '../../../../Hooks/MobileDetect';
class PackageDetails extends Component {
  id = this.props.id
    ? this.props.id
    : this.props.idFromHeaderWrapper
    ? this.props.idFromHeaderWrapper
    : this.props?.match?.params?.id;
  state = {
    package: {},
    shipmentNo: null,
    shipmentId: null,
    currency: {},
    history: [],
    detailsNo: '',
    pdf: null,
    showPDF: false,
    loading: true,
    attachments: [],
    notFound: false,
    shipmentCreateModal: false,
    shipmentViewModal: false,
    showShipmentDeleteModalLocal: false,
    currentMenu: '',
    type: '',
    showFileDelteModal: false,
    attachmentId: null,
    processingAttachment: false,
    sync_order: false,
    showPrompt: false,
  };

  componentDidMount() {
    if (this.props.idFromHeaderWrapper) {
      this.id = this.props.idFromHeaderWrapper;
    }
    document.title = 'Package Details';
    if (validToken()) {
      this.fetchData();
    } else {
      logout();
      this.props.history.push('/login');
    }
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  fetchData = async () => {
    this.setState({ loading: true });

    await restRequest('get', `packages/${this.id}`)
      .then((res) => {
        this.setState({
          package: res,
          detailsNo: res.package_no,
          attachments: res.attachments,
          shipmentNo: res.shipment_no,
          loading: false,
          shipmentId: res.shipment_id,
        });
        // this.fetchHistory()
        let obj = getObjFromLS('module');
        setObjInLS('module', { ...obj, presistDetailPage: true });
      })
      .catch((error) => {
        checkError(error);
        this.setState({ loading: false });
        this.setState({ notFound: true });
      });
    this.setState({ loading: false });
  };

  handleTabChange(tab) {
    this.setState({ currentMenu: tab });
  }
  fetchHistory = async () => {
    this.setState({ loading: true });
    await restRequest('get', `packages/${this.id}/activity`)
      .then((PackageHistory) => {
        this.setState({
          history: PackageHistory,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error);
        this.setState({ loading: false });
      });
  };

  renderDetailsHeader() {
    if (this.state.loading) {
      return 'Loading...';
    } else {
      return `Package Details (${this.state.package.package_no})`;
    }
  }

  markPackageAsDelivered = (packageId) => {
    this.setState({ processing: true });
    restRequest('put', `shipments/${packageId}/deliver`)
      .then(() => {
        this.setState({ processing: false });
        let obj = getObjFromLS('module');
        setObjInLS('module', { ...obj, action: 'deliver' });
        if (this.props.forceRedirectFlag) this.props.forceRedirect();
        this.fetchData();
        this.props.handleToast('Package has been delivered', 'success');
      })
      .catch((error) => {
        this.setState({ processing: false });
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
      });
  };

  markPackageAsUndelivered = (packageId) => {
    this.setState({ processing: true });
    restRequest('put', `shipments/${packageId}/undeliver`)
      .then(() => {
        this.setState({ processing: false });
        let obj = getObjFromLS('module');
        setObjInLS('module', { ...obj, action: 'undeliver' });
        if (this.props.forceRedirectFlag) this.props.forceRedirect();
        this.fetchData();
        this.props.handleToast('Package has been undelivered', 'success');
      })
      .catch((error) => {
        this.setState({ processing: false });
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
      });
  };

  async createNewShipment() {
    let obj = getObjFromLS('module');
    setObjInLS('module', { ...obj, action: 'ShipmentCreate' });
    this.setState({ shipmentCreateModal: true });
  }

  print() {
    this.setState({ loading: true });
    restRequest('get', `packages/${this.id}/print`)
      .then((res) => {
        var w = window.open();
        w.document.write(res.body);
        w.document.close();
        setTimeout(function () {
          w.focus();
          w.print();
          w.close();
        }, 3000);

        this.setState({ loading: false });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error,'error')
        this.setState({ loading: false });
      });
  }

  openPdf = (id) => {
    const [hasMobile] = useMobileDetection();
    if (hasMobile) {
      window.open(`/print/${id}/packages`, '_blank');
    } else {
      this.setState({ showPDF: true });
      restRequest('get', `packages/${id}/pdf`)
        .then((res) => {
          this.setState({ pdf: res });
        })
        .catch((error) => checkError(error));
    }
  };

  shipmentPrint() {
    this.setState({ loading: true });
    restRequest('get', `shipments/${this.id}/print`)
      .then((res) => {
        var w = window.open();
        w.document.write(res.body);
        w.document.close();
        setTimeout(function () {
          w.focus();
          w.print();
          w.close();
        }, 3000);

        this.setState({ loading: false });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error,'error')
        this.setState({ loading: false });
      });
  }

  openShipmentPdf = (id) => {
    this.setState({ showPDF: true });
    restRequest('get', `shipments/${id}/pdf`)
      .then((res) => {
        this.setState({ pdf: res });
      })
      .catch((error) => checkError(error));
  };

  hasPermissionCustom(name, type) {
    let permissions = getObjFromLS('role').permissions;
    if (permissions.superAccess) {
      if (type === 'All') return [true, true, true];
      return true;
    }

    if (!['Create', 'Edit', 'Delete', 'All'].includes(type))
      throw new Error('Type must be one of Create, Edit, Delete, All');
    if (!(name in controllerNames))
      throw new Error('Invalid name is receieved for permission check');
    if (type !== 'All') {
      return permissions[`${controllerNames[name]}${type}`];
    }
    return [
      permissions[`${controllerNames[name]}Create`],
      permissions[`${controllerNames[name]}Edit`],
      permissions[`${controllerNames[name]}Delete`],
    ];
  }

  closeAllModals = () => {
    this.setState({
      showPDF: false,
      shipmentCreateModal: false,
      shipmentViewModal: false,
      showShipmentDeleteModalLocal: false,
      pdf: null,
    });
  };

  closeShipmentDeleteModals = () => {
    this.setState({ showShipmentDeleteModalLocal: false });
  };

  closeShipmentPDFModal = () => {
    this.setState({ showPDF: false });
  };

  handleShipmentCreateSubmit = () => {
    this.props.history.push('/r');
  };

  handleShipmentView = () => {
    this.setState({
      shipmentViewModal: true,
    });
  };

  populateConditionalOptions(id) {
    const hasEditPermission = this.hasPermission('package', 'Edit');
    const options = [];
    if (!hasEditPermission) return options;
    options.push({
      label: 'Mark As Delivered',
      icon: MarkAsDeliveredIcon,
      onClick: () => this.markPackageAsDelivered(id),
      disabled: () => this.state.package.status !== 'shipped',
    });
    options.push({
      label: 'Mark As Undelivered',
      icon: MarkAsUndeliveredIcon,
      onClick: () => this.markPackageAsUndelivered(id),
      disabled: () => this.state.package.status !== 'delivered',
    });
    return options;
  }

  openShipmentDeleteModalLocal = (id) => {
    let obj = getObjFromLS('module');
    setObjInLS('module', { ...obj, action: 'ShipmentDelete' });
    this.setState({ showShipmentDeleteModalLocal: true });
  };

  renderShipmentDeleteModalLocal() {
    return (
      <CustomModal
        type="delete"
        showModal={this.state.showShipmentDeleteModalLocal}
        title="Delete Shipment"
        onClose={this.closeShipmentDeleteModals}
        onSubmit={this.handleDeleteShipment}
      >
        Are you sure you want to delete this Shipment?
      </CustomModal>
    );
  }

  handleDeleteShipment = () => {
    restRequest('delete', `shipments/${this.state.package.id}`)
      .then(() => {
        if (this.props.forceRedirectFlag) {
          this.props.forceRedirect();
        }
        //this.props.fetchData();
        this.closeAllModals();
        this.props.handleToast('Shipment has been deleted', 'success');
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  };

  renderShipmentActionMenu() {
    // const hasDeletePermission = this.hasPermissionCustom('shipment', 'Delete');
    const id = this.id;
    return (
      <ActionMenu>
        <Button
          handleClick={() =>
            pdfDownlaod(
              `shipments/${id}/pdf?download=true`,
              this.state.shipmentNo,
              this.props.handleToast
            )
          }
          type="pdf"
        />
        <Button handleClick={() => this.openShipmentPdf(id)} type="print" />
        <ButtonLink to={`/shipment/email/${id}`} type="email" />
        <Button
          handleClick={() => this.openShipmentDeleteModalLocal(id)}
          type="delete"
        />
      </ActionMenu>
    );
  }

  handleAddAttachment(event) {
    document.body.style.cursor = 'wait';
    this.setState({ processingAttachment: true });

    const { handleToast } = this.props;
    let selectedFiles = Array.from(event.target.files);
    if (selectedFiles.length > 10) {
      handleToast('Cannot add more than 10 attachments', 'error');
      setTimeout(() => {
        document.body.style.cursor = 'default';
        this.setState({ processingAttachment: false });
      }, 2000);
      return null;
    }
    let discardedFiles = [];
    let newFiles = selectedFiles.filter((file) => {
      const MAX_FILE_SIZE = 5; // max file size = 5MB
      let fileSize = file.size / 1000 / 1000; // converted from bytes to MB
      if (fileSize > MAX_FILE_SIZE) {
        discardedFiles.push(file);
      }
      return fileSize <= MAX_FILE_SIZE;
    });
    if (!!discardedFiles.length) {
      let errorMessage = discardedFiles.map((file) => {
        return `${file.name} exceeds the file size limit`;
      });
      handleToast(errorMessage, 'error');
    }

    const fd = new FormData();
    let newFileCount = this.state.attachments.length;
    newFiles.forEach((file, index) => {
      if (newFileCount <= 9) {
        fd.append(`files[${index}]`, file);
        newFileCount++;
      } else {
        // break;
        handleToast('Cannot add more than 10 attachments', 'error');
        return;
      }
    });
    fd.append('subjectId', this.id);
    fd.append('name', this.state.package.package_no);
    restRequest('post', 'packages/attachments', fd)
      .then(() => {
        handleToast('Attachment added successfully', 'success');
        this.fetchData();
        document.body.style.cursor = 'default';
        this.setState({ processingAttachment: false });
      })
      .catch((errorMessage) => {
        document.body.style.cursor = 'default';
        this.setState({ processingAttachment: false });
        checkError(errorMessage, handleToast);
        //handleToast(errorMessage, "error")
      });
  }

  async downloadSingleFile(attachment) {
    this.setState({ processingAttachment: true });
    await downloadAttachment(
      `packages/${attachment.id}/download-attachment`,
      this.props.handleToast,
      'single'
    );
    this.setState({ processingAttachment: false });
  }

  async downloadAllFiles() {
    this.setState({ processingAttachment: true });
    await downloadAttachment(
      `packages/${this.id}/download-all`,
      this.props.handleToast,
      'multiple'
    );
    this.setState({ processingAttachment: false });
  }

  deleteAllFiles() {
    document.body.style.cursor = 'wait';
    this.setState({
      processingAttachment: true,
      showFileDelteModal: false,
    });

    restRequest('delete', `packages/attachment/delete_all/${this.id}`)
      .then((res) => {
        document.body.style.cursor = 'default';
        this.setState({ processingAttachment: false });

        this.props.handleToast(`Attachment deleted successfully`, 'success');
        this.fetchData();
        this.setState({
          showFileDelteModal: false,
          attachmentId: null,
          type: '',
        });
      })
      .catch((error) => {
        document.body.style.cursor = 'default';
        //this.props.handleToast(error, 'error')
        checkError(error, this.props.handleToast);
        this.setState({ processingAttachment: false });
      });
  }

  deleteSignleFile(attachment) {
    document.body.style.cursor = 'wait';
    this.setState({
      processingAttachment: true,
      showFileDelteModal: false,
    });

    restRequest('delete', `packages/attachment/${this.state.attachmentId}`)
      .then((res) => {
        document.body.style.cursor = 'default';

        this.props.handleToast(`Attachment deleted successfully`, 'success');
        this.fetchData();
        this.setState({
          showFileDelteModal: false,
          attachmentId: null,
          type: '',
          processingAttachment: false,
        });
      })
      .catch((error) => {
        document.body.style.cursor = 'default';
        //this.props.handleToast(error, 'error')
        checkError(error, this.props.handleToast);
        this.setState({ processingAttachment: false });
      });
  }

  attachmentOptions() {
    const [hasCreatePermission, , hasDeletePermission] = this.hasPermission(
      'package',
      'All'
    );
    let obj = [];
    this.state.attachments &&
      this.state.attachments.forEach((attachment) => {
        obj.push({
          label: attachment.file_name,
          icon1: DownloadAttachmentIcon,
          id: attachment.id,
          type: 'attachment',
          onClickForIcon1: () => this.downloadSingleFile(attachment),
          icon2: hasDeletePermission && DeleteAttachmentIcon,
          onClickForIcon2: () => {
            this.showFileDeleteModal(attachment.id, 'single');
          },
        });
      });
    if (this.state.attachments.length > 0)
      obj.push({
        label: 'All Attachments',
        type: 'attachment',
        icon1: DownloadAttachmentIcon,
        icon2: hasDeletePermission && DeleteAttachmentIcon,
        onClickForIcon1: () => this.downloadAllFiles(),
        onClickForIcon2: () => this.showFileDeleteModal(null, 'multiple'),
      });
    return [
      hasCreatePermission && {
        label: this.state.processingAttachment
          ? 'Processing...'
          : 'Attach New File',
        type: 'addNewAttachment',
        onClick: (e) => this.handleAddAttachment(e),
      },
      !hasCreatePermission &&
        this.state.attachments.length === 0 && {
          label: 'No Attachments Available',
          onClick: (e) => null,
        },
      ...obj,
    ];
  }
  showFileDeleteModal(attId, filetype) {
    this.setState({
      showFileDelteModal: true,
      attachmentId: attId,
      type: filetype,
    });
  }
  renderFileDeleteModal() {
    return (
      <Fragment>
        <CustomModal
          type="delete"
          showModal={this.state.showFileDelteModal}
          title={`Confirm Delete`}
          onClose={() => this.setState({ showFileDelteModal: false })}
          onSubmit={() => {
            this.state.type === 'single'
              ? this.deleteSignleFile()
              : this.deleteAllFiles();
          }}
        >
          Are you sure you want to delete{' '}
          {this.state.type === 'single'
            ? 'this attachment?'
            : 'all attachments?'}
        </CustomModal>
      </Fragment>
    );
  }

  renderActionMenu() {
    const id = this.id;
    const options = this.populateConditionalOptions(id);
    const count = this.state.attachments.length;
    const [hasCreatePermission, hasEditPermission, hasDeletePermission] =
      this.hasPermission('package', 'All');
    const { package: packageInfo } = this.state;
    const menusList = [
      {
        type: 'options',
        options: options,
        className: 'options-dropdown',
        enable: !!options.length && this.state.package.status !== 'not shipped',
      },
      {
        type: 'link',
        icon: 'edit',
        enable: packageInfo.status === 'not shipped' && hasEditPermission,
        to: `/packages/edit/${id}`,
        tooTip: 'Edit',
        mobileLable: 'Edit',
        isMobile: true,
      },
      {
        type: 'button',
        icon: 'pdf',
        tooTip: 'Download PDF',
        isMobile: true,
        mobileLable: 'Download PDF',
        handleClick: () =>
          pdfDownlaod(
            `packages/${id}/pdf?download=true`,
            this.state.detailsNo,
            this.props.handleToast
          ),
      },
      {
        type: 'button',
        icon: 'print',
        isMobile: true,
        mobileLable: 'Print',
        tooTip: 'Print',
        handleClick: () => this.openPdf(id),
      },
      {
        type: 'link',
        icon: 'email',
        tooTip: 'Email',
        isMobile: true,
        mobileLable: 'Email',
        enable: hasCreatePermission,
        to: `/packages/email/${id}`,
      },
      {
        type: 'dropdown',
        className: 'attachemnt_buttons',
        options: this.attachmentOptions(),
        // dropdownLable: 'Attachments',
        enable: true,
        attachment: true,
        attachmentCount: count,
        AttachmentIcon: AttachIcon,
      },
      {
        type: 'button',
        icon: 'delete',
        tooTip: 'Delete',
        mobileLable: 'Delete',
        isMobile: true,
        handleClick: () =>
          this.openDeleteModal(
            id,
            this.props.forceRedirect,
            this.props.forceRedirectFlag
          ),
        enable: packageInfo.status === 'not shipped' && hasDeletePermission,
      },
      this.hasPermissionCustom('shipment', 'Create') &&
        packageInfo.status === 'not shipped' && {
          type: 'button',
          className: 'button--secondary package_button float-left mr-3',
          handleClick: () => this.createNewShipment(),
          label: `Ship Now`,
          icon: 'shipicon',
        },
      {
        type: 'button',
        className: 'button--primary float-left mr-3',
        handleClick: () => this.props.history.push(`/packages/add`),
        label: `New`,
        icon: 'new',
      },
    ];
    return (
      <ActionMenu>
        <HeaderMenus menusList={menusList} loading={this.state.loading} />
      </ActionMenu>
    );
  }

  getTotalQuantity(items) {
    return items
      ? items.reduce((total, item) => item && item.quantity + total, 0)
      : 0;
  }

  handlePrompt = (status = true) => {
    this.setState({ showPrompt: status });
  };

  renderShippingModal() {
    return (
      <CustomModal
        showModal={this.state.shipmentCreateModal}
        // className="cus-popup-set"
        title="Create Shipment"
        Icon={ShipNowIcon}
        renderActions={false}
        extraClass="shipment-large cus-shipment-popup-set"
        size="large"
        onClose={this.closeAllModals}
        classNames="new-recieve-payment-popup create-shipment-popup"
        showPrompt={this.state.showPrompt}
      >
        <ShipmentCreate
          packageId={this.id}
          packageNo={this.state.detailsNo}
          onClose={this.closeAllModals}
          onSubmit={this.handleShipmentCreateSubmit}
          handleToast={this.props.handleToast}
          Componenthistory={this.props.history}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  renderShippingViewModal() {
    const { package: packageInfo, shipmentNo } = this.state;
    const { handleToast } = this.props;
    return (
      <CustomModal
        showModal={this.state.shipmentViewModal}
        title={`Shipment Details | ${shipmentNo}`}
        Icon={ShipNowIcon}
        renderActions={false}
        renderShipmentActionMenu={this.renderShipmentActionMenu()}
        size="small"
        showActionInMenu={true}
        onClose={this.closeAllModals}
      >
        {!this.hasViewPermission('shipment') ? (
          <AccessDenied className="small dashboard" type="section" />
        ) : (
          <ShipmentInfo
            packageID={packageInfo.id}
            packageStatus={packageInfo.status}
            packageNo={packageInfo.package_no}
            forceRedirect={this.props.forceRedirect}
            forceRedirectFlag={this.props.forceRedirectFlag}
            fetchData={this.fetchData}
            handleToast={handleToast}
            onClose={this.closeAllModals}
            hasPermissionCustom={this.hasPermissionCustom}
          />
        )}
      </CustomModal>
    );
  }
  menus() {
    return [
      {
        id: 'history',
        label: 'History',
        packageId: this.id,
        url: `packages/${this.id}/activity`,
        icon: () => <History />,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('history');
        },
        renderContent: this.renderMenuForHistory,
      },
    ];
  }

  renderMenuForHistory() {
    return <MenuData name="History" packageId={this.id} url={this.url} />;
  }
  renderLayoutView() {
    const {
      package: packageInfo,
      shipmentNo,
      // shipment,
      attachments,
      currentMenu,
    } = this.state;

    // const { package_no: packageNo, status } = packageInfo;

    const shippingAddress = packageInfo.shipping_address || {};
    return (
      <Fragment>
        <div className="float-left w-100 position-relative sales-return-main packages_set-main">
          {this.renderFileDeleteModal()}
          <Menu
            menus={this.menus()}
            currentMenu={currentMenu}
            history={this.props.history}
            inModal={this.props.inModal || false}
          />

          {this.renderShippingModal()}
          {this.renderShippingViewModal()}
          {this.renderShipmentDeleteModalLocal()}
          <PDFViewer
            showPDF={this.state.showPDF}
            hidePDFModal={this.closeShipmentPDFModal}
            pdf={this.state.pdf}
          />
          <div id="printPackage" className="Package_Details">
            <div className="close_icon">
              <MdClose
                onClick={() => {
                  setValueInLS('fullscreen', 'true');
                  sessionStorage.setItem('once', true);
                  this.props.history.push('/r');
                }}
              />
            </div>
            <div className="package_middle_row">
              <div className="float-left w-50">
                <div className="pckage-module-inner">
                  <DetailsHeader
                    myClass={
                      shipmentNo
                        ? 'details_header_package_no_bottom'
                        : 'details_header_package'
                    }
                    title="Package"
                    no={packageInfo.package_no}
                    status={packageInfo.status}
                    customer={packageInfo.customer_name}
                    contactID={packageInfo.customer_id}
                    Icon={ShipNowIcon}
                    inModal={this.props.inModal}
                  />
                  <PackageInfo
                    handleShipmentView={this.handleShipmentView}
                    myClass="package_details pk-1"
                    info={{
                      shipmentNo: shipmentNo ? shipmentNo : null,
                      shipment: packageInfo.shipment
                        ? packageInfo.shipment
                        : null,
                      packageDate: packageInfo.package_date,
                      salesOrderNo: packageInfo.sales_order_no,
                      salesOrderID: packageInfo.sales_order_id,
                      customerName: packageInfo.customer_name,
                      customerID: packageInfo.customer_id,
                      // totalQuantity: this.getTotalQuantity(packageInfo.package_details)
                      totalQuantity:
                        packageInfo.package_details &&
                        packageInfo.package_details.length,
                    }}
                    inModal={this.props.inModal}
                  />
                </div>
              </div>
              <div className="float-left w-50 package_address">
                <Address
                  address={{
                    type: 'shipping',
                    attention: shippingAddress.attention,
                    company: packageInfo.company_name,
                    street: shippingAddress.street,
                    city: shippingAddress.city,
                    state: shippingAddress.state,
                    zip: shippingAddress.zip_code,
                    country: shippingAddress.country_name,
                    phone: shippingAddress.phone,
                    fax: shippingAddress.fax,
                  }}
                />
              </div>
            </div>

            {/* </GridLayout> */}
            <div className="margin_bottom_yes">
              <PackageItemInfo
                itemList={packageInfo.package_details}
                packageInfo={packageInfo}
                attLength={attachments.length > 0}
                inModal={this.props.inModal}
                className="uiux_package_summary"
              />
              {/* <hr className="float-left w-100 mb-1 border_top_light"/> */}
              {attachments.length > 0 && (
                <div className="float-left w-100">
                  <div className="bottom_attachment_area">
                    <AttachIcon />{' '}
                    <span className="attachment_span">
                      {' '}
                      {attachments.length} Attachment(s) added
                    </span>
                  </div>
                </div>
              )}
              {attachments.length > 0 && (
                <hr className="float-left w-100 mb-1 border_top_light" />
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
  renderHistory() {
    if (this.state.loading) {
      return <Loader />;
    }

    // if (this.state.history.length === 0) {
    //   return <EmptyTable />
    // }

    return (
      <div className="packages_activity_container">
        {this.state.history.length > 0 &&
          this.state.history.map((data) => {
            return (
              <TimelineCard
                key={data.id}
                name={data.creator}
                description={data.description}
                date={data.date}
                time={data.time}
                subject={data.subject}
                type={data.type}
              />
            );
          })}
      </div>
    );
  }

  renderSidebar() {
    const { package: packageInfo } = this.state;
    const { handleToast } = this.props;
    return (
      <Box title="Shipping Detail">
        {!this.hasViewPermission('shipment') ? (
          <AccessDenied className="small dashboard" type="section" />
        ) : (
          <ShipmentInfo
            packageID={packageInfo.id}
            packageStatus={packageInfo.status}
            packageNo={packageInfo.package_no}
            forceRedirect={this.props.forceRedirect}
            forceRedirectFlag={this.props.forceRedirectFlag}
            fetchData={this.fetchData}
            handleToast={handleToast}
            hasPermissionCustom={this.hasPermissionCustom}
          />
        )}
      </Box>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    return (
      // <GridLayout>
      //   <Grid>{this.renderLayoutView()}</Grid>
      //   <Grid transparent>{this.renderSidebar()}</Grid>
      // </GridLayout>
      <Fragment>
        {this.renderHistory()}
        {this.renderLayoutView()}
        {this.state.processingAttachment && <OverlayLoader opacity={0.7} />}
      </Fragment>
    );
  }
}

export default HeaderWrapper(PackageDetails, {
  name: 'Packages',
  deleteName: 'Package',
  tableIcon: 'general_module_icon',
  Icon: NewPackageIcon,
  baseUrl: 'packages',
  redirectUrl: '/packages',
  onlyMenu: true,
  showName: true,
  permissionName: 'package',
});
