import React, { Component } from 'react';
import { publicRequest } from '../../Helpers/RequestHelper';
import {validToken, logout, setInvitationToken} from '../../Helpers/AuthHelper';
import Loader from '../../Components/Loader/Loader';
import Signup from '../../Auth/Signup/Signup';
import Login from '../../Auth/Login/Login';

class Accept extends Component {
  state = {
    loading: true,
    validToken: false,
    newUser: true,
    user: {
      name: '',
      email: '',
    },
  };

  componentDidMount = async () => {
    let checkPath = window.location.pathname.indexOf('/invite/accept/');
    if (checkPath === -1 && !validToken()) {
      logout();
    }
    this.checkToken().then(() => {
      this.setState({ loading: false });
    });
  };

  checkToken() {
    return publicRequest('post', 'invites/accept', {
      token: this.props.match.params.token,
    })
      .then((res) => {
        if (process.env.REACT_APP_SSO_ENABLED === 'true') {
          setInvitationToken(this.props?.match?.params?.token)
        }
        this.setState({
          validToken: true,
          newUser: res.new_user,
          user: {
            name: res.name,
            email: res.email,
          },
        });
      })
      .catch((error) => {
        this.props.history.push('/login');
        this.props.handleToast(error, 'error');
      });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }
    if (this.state.validToken) {
      const { newUser, user } = this.state;
      return newUser ? (
        <Signup
          {...this.props}
          name={user.name}
          email={user.email}
          submitUrl="register/inviteduser"
          token={this.props?.match?.params?.token}
        />
      ) : (
        <>
          {validToken() ? (
            this.props.history.push('/oc/organization/choose')
          ) : (
            <Login {...this.props} email={user.email} />
          )}
        </>
      );
    }
    return null;
  }
}

export default Accept;
