import React from 'react';
import { Link } from 'react-router-dom';
import CustomDropdown from '../Dropdown/CustomDropdown';

import ActiveIcon from '../../Assets/General/ActiveIcon';
import InactiveIcon from '../../Assets/General/InactiveIcon';
import ExportIcon from '../../Assets/General/ExportIcon';
import {
  FaRegFilePdf,
  MdCached,
  MdFullscreen,
  MdAdd,
} from '../../Common/Icons';
import EditIcon from '../../../src/Assets/General/EditIcon';
import CloneIcon from '../../../src/Assets/General/CloneIcon';
import EmailIcon from '../../../src/Assets/General/EmailIcon';
import DeleteIcon from '../../../src/Assets/General/DeleteIcon';

import PrintIcon from '../../Assets/General/PrintIcon';
import AttachmentIcon from '../../Assets/General/AttachmentIcon';
import AdjustIcon from '../../Assets/General/AdjustIcon';
import './CheckedMenu.css';

const ButtonIcons = {
  delete: () => (
    <DeleteIcon
      className="material-icon delete_icon"
      id="click_delete_Item_adjustment_icon"
    />
  ),
  clone: () => <CloneIcon className="material-icon" />,
  print: () => <PrintIcon className="material-icon" />,
  attachment: () => <AttachmentIcon className="material-icon" />,
  email: () => <EmailIcon className="material-icon" />,
  edit: () => <EditIcon className="material-icon" />,
  active: () => <ActiveIcon className="material-icon" />,
  inactive: () => <InactiveIcon className="material-icon" />,
  pdf: () => <FaRegFilePdf className="material-icon" />,
  switch: () => <MdCached className="material-icon" />,
  expand: () => <MdFullscreen className="material-icon" />,
  new: () => <MdAdd className="material-icon" />,
  adjust: () => <AdjustIcon className="material-icon" />,
  export: () => <ExportIcon className="material-icon" />,
  resetFilters: () => <ExportIcon className="material-icon" />,
};
let item = 'Item';
let items = 'Items';
export default ({ count = 0, children }) => {
  React.useEffect(() => {
    return () => {
      item = 'Item';
      items = 'Items';
    };
  });
  String.prototype.beginsWith = function (string) {
    return this.indexOf(string) === 0;
  };
  if (window.location.pathname.beginsWith('/packages')) {
    item = 'Package';
    items = 'Packages';
  } else if (window.location.pathname.beginsWith('/salesorders')) {
    item = 'Sales Order';
    items = 'Sale Orders';
  } else if (window.location.pathname.beginsWith('/purchaseorders')) {
    item = 'Purchase Order';
    items = 'Purchase Orders';
  } else if (window.location.pathname.beginsWith('/bills')) {
    item = 'Bill';
    items = 'Bills';
  } else if (window.location.pathname.beginsWith('/invoices')) {
    item = 'Invoice';
    items = 'Invoices';
  } else if (window.location.pathname.beginsWith('/items')) {
    item = 'Item';
    items = 'Items';
  } else if (window.location.pathname.beginsWith('/item-groups')) {
    item = 'Item Group';
    items = 'Item Groups';
  } else if (window.location.pathname.beginsWith('/contacts')) {
    item = 'Contact';
    items = 'Contacts';
  } else if (window.location.pathname.beginsWith('/salesreturns')) {
    item = 'Sale Return';
    items = 'Sales Return';
  } else if (window.location.pathname.beginsWith('/creditnotes')) {
    item = 'Credit Note';
    items = 'Credit Notes';
  }else if (window.location.pathname.beginsWith('/customer-payments')) {
    item = 'Payment Received';
    items = 'Payments Received';
  }
  return (
    <div className="checked-menu">
      <span className="checked-menu--toolbar">
        <span className="checked-menu--count">
          {count} {`${count > 1 ? items : item}`} Selected
        </span>
        <span className="checked-menu--buttons">{children}</span>
      </span>
    </div>
  );
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const ActionMenu = ({ children }) => {
  return (
    <span className="checked-menu--toolbar action-menu secondry_buttons_group mr-3 checkmenu-inner">
      {children}
    </span>
  );
};

export const Button = ({
  enable = true,
  handleClick = () => {},
  type = 'delete',
  label = false,
  myClass = '',
}) => {
  if (enable)
    return (
      <button
        id="click_delete_Item_icon"
        className={`${
          label ? 'checked-menu--button' : 'checked-menu--button--icon-only'
        } ${myClass}`}
        onClick={handleClick}
        title={type === 'pdf' ? 'Download PDF' : capitalizeFirstLetter(type)}
      >
        {ButtonIcons[type]()}{' '}
        {label && <span className="checked-menu--label">{label}</span>}
      </button>
    );
  return null;
};

export const ButtonLink = ({
  enable = true,
  to = '/',
  type = 'delete',
  label = false,
}) => {
  if (enable)
    return (
      <Link
        id="click_DB_edit_icon"
        to={to}
        className={`${
          label ? 'checked-menu--button' : 'checked-menu--button--icon-only'
        }`}
        title={capitalizeFirstLetter(type)}
      >
        {ButtonIcons[type]()}{' '}
        {label && <span className="checked-menu--label">{label}</span>}
      </Link>
    );
  return null;
};

export const Options = ({ options = [], enable = true }) => {
  if (enable)
    return (
      <span className="checked-menu--dropdown">
        <CustomDropdown textLabel="Options" options={options} />
      </span>
    );
  return null;
};
