import React from 'react';

import { NewInvoiceIcon } from '../../../Assets/Navigation/NavigationIcons';
import Email from '../../../Components/Email/Email';

const InvoiceEmail = (props) => {
  return (
    <Email
      {...props}
      Icon={NewInvoiceIcon}
      name="Invoices"
      onCancelURL="/invoices"
      emailAPI={`invoices/${props.match.params.id}/mail`}
      fetchDataAPI={`invoices/${props.match.params.id}/mail`}
      getBackTab
    />
  );
};

export default InvoiceEmail;
