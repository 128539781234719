import React from 'react'

export default ({
  className = ''
}) => (
<svg xmlns="http://www.w3.org/2000/svg" width="28.512" height="19.755" viewBox="0 0 28.512 19.755" className={className}>
  <g transform="translate(-874.493 -267.619)">
    <path fill="#33a348"
          d="M18.292-8.25a2.083,2.083,0,0,1-1.779-1.005l-2.763-4.58-2.759,4.58a2.09,2.09,0,0,1-1.783,1.01,1.976,1.976,0,0,1-.571-.082L2.75-10.012v7.648A1.371,1.371,0,0,0,3.79-1.031l9.29,2.325a2.793,2.793,0,0,0,1.332,0l9.3-2.325a1.378,1.378,0,0,0,1.04-1.332v-7.648l-5.887,1.68A1.975,1.975,0,0,1,18.292-8.25Zm9.135-4.821-2.213-4.417a.7.7,0,0,0-.718-.382L13.75-16.5l3.94,6.536a.706.706,0,0,0,.795.314l8.5-2.428A.708.708,0,0,0,27.427-13.071ZM2.286-17.488.073-13.071a.7.7,0,0,0,.434.988l8.5,2.428a.706.706,0,0,0,.795-.314L13.75-16.5,3-17.871A.7.7,0,0,0,2.286-17.488Z"
          transform="translate(875 286)"/>
    <path fill="#306ab4"
          d="M6075.432,61.065l-3.941-6.535,10.747-1.37a.7.7,0,0,1,.717.382l2.213,4.418a.706.706,0,0,1-.438.991l-8.5,2.429a.706.706,0,0,1-.794-.315Zm-8.68.31-8.5-2.427a.7.7,0,0,1-.434-.988l2.214-4.418a.7.7,0,0,1,.712-.382l10.751,1.37-3.944,6.531a.705.705,0,0,1-.794.315Z"
          transform="translate(-5182.74 214.97)"/>
  </g>
</svg>
  )
