import React from 'react'

export default ({
  className = ''
}) => (
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 15 15">
  <path d="M5.625-15v4.375H4.375a1.8,1.8,0,0,0-1.318.557A1.8,1.8,0,0,0,2.5-8.75V-2.5H5.625V0h8.75V-2.5H17.5V-8.75a1.8,1.8,0,0,0-.557-1.318,1.8,1.8,0,0,0-1.318-.557h-1.25V-15Zm1.25,1.25h6.25v3.125H6.875Zm-2.5,4.375h11.25a.608.608,0,0,1,.449.176.608.608,0,0,1,.176.449v5H14.375v-2.5H5.625v2.5H3.75v-5A.608.608,0,0,1,3.926-9.2.608.608,0,0,1,4.375-9.375ZM5-8.75a.6.6,0,0,0-.439.186.6.6,0,0,0-.186.439.6.6,0,0,0,.186.439A.6.6,0,0,0,5-7.5a.6.6,0,0,0,.439-.186.6.6,0,0,0,.186-.439.6.6,0,0,0-.186-.439A.6.6,0,0,0,5-8.75ZM6.875-5h6.25v3.75H6.875Z"
        transform="translate(-2.5 15)"/>
</svg>
)
