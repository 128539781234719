import React, { Component } from 'react';
import './DashboardOrderDetails.css';

import Tabs from './../../../Components/Tabs/Tabs';
import Loader from '../../../Components/Loader/Loader';
import { restRequest } from '../../../Helpers/RequestHelper';
// import ListTable from '../../../Components/ListTable/ListTable'
import { formatNumber } from '../../../Helpers/FormatNumber';
import getStatusColor from '../../../Helpers/GetStatusColor';
import AccessDenied from '../../../Components/AccessDenied/AccessDenied';
import DataError from '../../../Components/DashboardDataError/DB_DataError';
import OrderDetailsTable from './OrderDetailsTable';
import { checkError } from '../../../Helpers/AuthHelper';
export default class DashboardOrderDetails extends Component {
  constructor(props) {
    super();
    this.state = {
      currentTab: 'salesorder',
      loading: true,
      saleOrderData: [],
      invoiceData: [],
      purchaseOrderData: [],
      billsData: [],
      accessDenied: false,
      accessError: false
    };
  }
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.setState({ loading: true });
    let promises = [];

    promises.push(restRequest('get', `dashboard/order_details/sales_orders`));

    promises.push(restRequest('get', `dashboard/order_details/invoices`));

    promises.push(
      restRequest('get', `dashboard/order_details/purchase_orders`)
    );

    promises.push(restRequest('get', `dashboard/order_details/bills`));

    Promise.all(promises)
      .then((promisRes) => {
        this.setState({
          saleOrderData: promisRes[0],
          invoiceData: promisRes[1],
          purchaseOrderData: promisRes[2],
          billsData: promisRes[3],
          loading: false,
        });
      })
      .catch((err) => {
        checkError(err);
        if (err.response.status === 403) {
          this.setState({ accessDenied: true, loading: false });
        } else {
          this.setState({ accessError: true, loading: false });
        }
      });
  }
  // fetchData(param = 'sales_orders') {
  //   this.setState({ loading: true })
  //   restRequest('get', `dashboard/order_details/${param}`)
  //     .then((res) => {
  //       this.setState({
  //         data:
  //           param === 'invoices'
  //             ? this.prepareInvoicesData(res)
  //             : param === 'purchase_orders'
  //             ? this.preparePurchaseOrderData(res)
  //             : param === 'bills'
  //             ? this.prepareBillsData(res)
  //             : this.prepareSalesOrderData(res),
  //         // loading: false,
  //       })
  //     })
  //     .catch((res) => this.setState({ loading: false }))
  // }

  prepareSalesOrderData(data) {
    return data.map((salesorder) => ({
      // 'id': salesorder.id,

      'Order Date': {
        id: 'order_date',
        sortable: true,
        getValue: function () {
          return salesorder[this.id];
        },
      },
      'Sales Order No.': {
        id: 'sales_order_no',
        sortable: true,
        getValue: function () {
          return salesorder[this.id];
        },
      },

      Customer: {
        id: 'display_name',
        sortable: true,
        getValue: function () {
          return salesorder[this.id];
        },
      },

      Amount: {
        id: 'total',
        sortable: true,
        alignRight: true,
        getValue: function () {
          return `${salesorder.currency.symbol}${formatNumber(
            salesorder[this.id]
          )}`;
        },
      },
      Status: {
        id: 'status',
        sortable: true,
        getValue: function () {
          return (
            <span
              className={` float-right  ${getStatusColor(salesorder[this.id])}`}
            >
              {salesorder[this.id]}
            </span>
          );
        },
      },
    }));
  }
  prepareBillsData(data) {
    return data.map((bill) => ({
      'Bill Due Date': {
        id: 'due_date',
        sortable: true,
        getValue: function () {
          return bill[this.id];
        },
      },
      'Bill No.': {
        id: 'bill_no',
        sortable: true,
        getValue: function () {
          return bill[this.id];
        },
      },
      'Balance Due': {
        id: 'balance_due',
        sortable: true,
        alignRight: true,
        getValue: function () {
          return `${bill.currency.symbol}${bill[this.id]}`;
        },
      },
      Amount: {
        id: 'total',
        sortable: true,
        alignRight: true,
        getValue: function () {
          return `${bill.currency.symbol}${bill[this.id]}`;
        },
      },
      Status: {
        id: 'status',
        sortable: true,
        getValue: function () {
          return (
            <span className={` float-right  ${getStatusColor(bill[this.id])}`}>
              {bill[this.id]}
            </span>
          );
        },
      },
    }));
  }

  preparePurchaseOrderData(data) {
    return data.map((purchaseorder) => ({
      // 'id': purchaseorder.id,

      'Order Date': {
        id: 'order_date',
        sortable: true,
        getValue: function () {
          return purchaseorder[this.id];
        },
      },
      'Purchase Order No.': {
        id: 'purchase_order_no',
        sortable: true,
        getValue: function () {
          return purchaseorder[this.id];
        },
      },
      Vendor: {
        id: 'display_name',
        sortable: true,
        getValue: function () {
          return purchaseorder['vendor'][this.id];
        },
      },
      Amount: {
        id: 'total',
        sortable: true,
        alignRight: true,
        getValue: function () {
          return `${purchaseorder.currency.symbol}${formatNumber(
            purchaseorder[this.id]
          )}`;
        },
      },
      Status: {
        id: 'status',
        sortable: true,
        getValue: function () {
          return (
            <span
              className={` float-right  ${getStatusColor(
                purchaseorder[this.id]
              )}`}
            >
              {purchaseorder[this.id]}
            </span>
          );
        },
      },
    }));
  }

  prepareInvoicesData(data) {
    return data.map((invoice) => ({
      // 'id': invoice.id,
      'Invoice Date': {
        id: 'invoice_date',
        sortable: true,
        getValue: function () {
          return invoice[this.id];
        },
      },
      'Invoice No.': {
        id: 'invoice_no',
        sortable: true,
        getValue: function () {
          return invoice[this.id];
        },
      },
      'Balance Due': {
        id: 'payment_due',
        sortable: true,
        alignRight: true,
        getValue: function () {
          return `${invoice.currency.symbol}${formatNumber(invoice[this.id])}`;
        },
      },
      Amount: {
        id: 'total',
        sortable: true,
        alignRight: true,
        getValue: function () {
          return `${invoice.currency.symbol}${formatNumber(invoice[this.id])}`;
        },
      },

      Status: {
        id: 'status',
        sortable: true,
        getValue: function () {
          return (
            <span
              className={` float-right  ${getStatusColor(invoice[this.id])}`}
            >
              {invoice[this.id]}
            </span>
          );
        },
      },
    }));
  }

  renderTab = (data) => {
    const { currentTab } = this.state; //loading
    return (
      <OrderDetailsTable
        data={data}
        tab={currentTab}
      // loading={loading}
      // hasCheckbox={false}
      // fromDashboard
      />
    );
  };
  handleTabChange(tab) {
    this.setState({ currentTab: tab });
  }

  tabs = [
    {
      id: 'salesorder',
      label: 'Sales Order',
      onClick: () => {
        this.handleTabChange('salesorder');
        // this.fetchData('sales_orders')
      },
      renderContent: () => this.renderTab(this.state.saleOrderData),
    },
    {
      id: 'invoice',
      label: 'Invoice',
      onClick: () => {
        this.handleTabChange('invoice');
        // this.fetchData('invoices')
      },
      renderContent: () => this.renderTab(this.state.invoiceData),
    },
    {
      id: 'purchaseorder',
      label: 'Purchase Order',
      onClick: () => {
        this.handleTabChange('purchaseorder');
        // this.fetchData('purchase_orders')
      },
      renderContent: () => this.renderTab(this.state.purchaseOrderData),
    },
    {
      id: 'bills',
      label: 'Bills',
      onClick: () => {
        this.handleTabChange('bills');
        // this.fetchData('bills')
      },
      renderContent: () => this.renderTab(this.state.billsData),
    },
  ];
  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.accessDenied) return <AccessDenied />
    if (this.state.accessError) return <DataError />
    return (
      <Tabs
        fromDashboard={true}
        tabs={this.tabs}
        showCross={false}
        currentTab={this.state.currentTab}
      />
    );
  }
}
