import React, { useEffect } from 'react';
import { ItemForm } from '../../Components/Form';
import FormLayout from '../../Components/Layout/FormLayout';
import { NewItemIcon } from '../../Assets/Navigation/NavigationIcons';

const EditItem = ({ handleToast, idFromHeaderWrapper, ...props }) => {
  useEffect(() => {
    document.title = 'Edit Item';
  }, []);

  return (
    <FormLayout
      title="Items"
      Icon={NewItemIcon}
      subtitle="Edit"
      closeUrl="/items"
      iconstyle="general_module_icon"
      layoutBoxClass="uiux-form-redesign"
    >
      <ItemForm
        type="edit"
        id={idFromHeaderWrapper ? idFromHeaderWrapper : props.match.params.id}
        handleToast={handleToast}
      />
    </FormLayout>
  );
};

export default EditItem;
