import React, { useEffect, useState, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { useParams } from 'react-router-dom';
import { getImagesUrl } from './ReportUrls';
import ComponentToPrint from './ComponentToPrint';
import { Loader } from '../../Components';
import { restRequest , checkError } from '../../Helpers';

const MobilePrint = (props) => {
  const [images, setImages] = useState([]);
  const [loading, setloading] = useState(true);
  const { id, from } = useParams();
  const componentRef = useRef();
  const printRef = useRef();
  const print = () => {
    printRef.current.click();
  };

  const RemoveImages = () => {
    if (images.length) {
      const payload = {
        images,
      };
      setloading(true);
      restRequest('post', 'pdfmobiledelete', payload)
        .then((res) => {
          window.close();
          setloading(false);
        })
        .catch((error) => {
          setloading(false);
          checkError(error);
        });
    }
  };

  useEffect(() => {
    if (images.length) print();
  }, [images]);
  
  
  useEffect(() => {
    restRequest('get', getImagesUrl(from, id))
      .then((res) => {
        setImages(res.path);
        setloading(false);
      })
      .catch((error) => {
        setloading(false);
        props.handleToast(error.message, 'error');
      });
  }, [id, from]);

  const goBackAlert = () => {
    return (
      <>
        <div style={{ clear: 'both' }}>
            <div className="unsync-alert">
              <div className="unsync-desc">
                <p>After taking print, you can close this window</p>
                <a href="#" onClick={RemoveImages}>Click to close</a>
              </div>
            </div>
        </div>
      </>
    );
  };
 
  return (
    <>
      {loading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <Loader />
        </div>
      )}
       {goBackAlert()}
      <ReactToPrint
        trigger={() => (
          <button ref={printRef} style={{ display: 'none' }}>
            Print this out!
          </button>
        )}
        content={() => componentRef.current}
      />
      <ComponentToPrint data={images} ref={componentRef} />
    </>
  );
};
export default MobilePrint;