import React from 'react';
import { getObjFromLS } from '../../Helpers';
import AuthContainer from '../AuthContainer';

const DeactivateUser = (props) => {
  localStorage.setItem("redirect", true)
  let detail = JSON.parse(localStorage.getItem("wordpress"))
  if (!detail) {
    detail = getObjFromLS("userdetail")
  }
  const subtitle =
    (detail.name) ?
      [`Hello ${detail.name}`, <br />, <b>{detail.email}</b>, <br />, <br />, `Your account has been deactivated.`]
      : [`Hello`, <br />, <b>{detail.email}</b>, <br />, <br />, `Your account has been deactivated.`];
  return (
    <AuthContainer title='Account Deactivated' subtitle={subtitle} linkdisable >
      <button
        id="click_login_button"
        className="button--full-width"
        type="submit"
        onClick={() => props.history.push('/')}
      >
        Back
      </button>
    </AuthContainer>
  );
};

export default DeactivateUser;