import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { restRequest } from '../../../Helpers/RequestHelper';
import { withFormik } from 'formik';

import { required, ValidateMax30 } from '../../../Helpers/Validator'; //ValidateMaxOnlyAlpha30,
import Field from '../Field/Field';
import Form from '../Form';
import FormAction from '../FormAction';
import ErrorFocus from '../ErrorFocus';
import { checkError } from '../../../Helpers/AuthHelper';
import { confirmationAlert } from '../../../Helpers';
import { validateCreateDeliveryForm } from '../../../Helpers/FormValidator';

const formEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    // name: required(ValidateMaxOnlyAlpha30),
    name: required(ValidateMax30),
  }),
  mapPropsToValues: ({ deliveryMethod }) => ({
    ...deliveryMethod,
  }),
  handleSubmit: (values, bag) => {
    handleFormSubmit(values, bag);
  },
});

const handleFormSubmit = (
  values,
  {
    setSubmitting,
    props: {
      requestType,
      requestUrl,
      inModal,
      onSubmitModal,
      history,
      successMessage,
      handleToast,
    },
  }
) => {
  const { name } = values;
  restRequest(requestType, requestUrl, {
    name,
  })
    .then((res) => {
      if (inModal) {
        onSubmitModal(res.data);
        if (history?.location?.pathname.includes('/deliverymethods')) {
          history.push('/deliverymethods');
        }
      } else {
        history.push('/deliverymethods');
      }
      successMessage();
    })
    .catch((error) => {
      handleToast(error, 'error');
      checkError(error);
      setSubmitting(false);
    });
};

const closeAndBack = (history, closeModal, totalRecords, modelSource) => {
  if (!modelSource) {
    closeModal();
  } else {
    closeModal();
    if (totalRecords === undefined || totalRecords === 0) {
      history.push('/settings');
    }
  }
};

const DeliveryMethodsForm = (props) => {
  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    // setFieldValue,
    isSubmitting,
    // submitCount,
    errors,
    touched,
    // custom props
    inModal,
    closeModal,
    history,
    title,
    deliveryMethod,
    totalRecords,
    modelSource = '',
    handlePrompt = () => null,
  } = props;

  const [staticVal, setStaticValues] = useState({});

  useEffect(() => {
    handleAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, staticVal]);

  useEffect(() => {
    setStaticValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAlert = () => {
    let { checkout } = validateCreateDeliveryForm(values, staticVal);
    handlePrompt(!checkout);
    return checkout;
  };

  const handleCancel = () => {
    let status = handleAlert();
    let response = true;
    if (!status) {
      response = confirmationAlert();
    }
    if (status || response) {
      if (inModal) closeAndBack(history, closeModal, totalRecords, modelSource);
      else history.push('/deliverymethods');
    }
  };

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };
    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  return (
    <Form
      title={title}
      onSubmit={handleSubmit}
      className="deliveryMethods-form"
    >
      <Field
        size="large"
        name="Delivery Method Name"
        value={values.name}
        id="name"
        autoFocus={true}
        required
        handleChange={handleChange}
        error={touched.name && errors.name}
        onBlur={handleBlur}
      />
      <div style={{ marginTop: '1%' }} />
      <hr className="margin float-left w-100" />

      <FormAction
        disabled={deliveryMethod.name === values.name || isSubmitting}
        onCancel={handleCancel}
      />
      <ErrorFocus />
    </Form>
  );
};

export default formEnhancer(DeliveryMethodsForm);
