import React, { Component } from 'react';
import { Prompt, withRouter } from 'react-router-dom';

import { restRequest } from '../../../Helpers/RequestHelper';
import Loader from '../../Loader/Loader';
import ContactEnhancedForm from './ContactFormEnhancer';
import { COUNTRY_STATE } from '../../../Constants';
import { getObjFromLS, setObjInLS } from '../../../Helpers/LocalStorage';
import PlanExceeded from '../../PlanExceeded/PlanExceeded';

import './ContactForm.css';
import AccessDenied from '../../AccessDenied/AccessDenied';
import { checkError } from '../../../Helpers/AuthHelper';

class ContactForm extends Component {
  type = this.props.type || 'add'; // add, edit, clone, purchaseOrder, salesOrder

  id = this.props.id || null;

  state = {
    contact: {
      currentImage: null,
      contactImage: null,
      salutation: '',
      firstName: '',
      lastName: '',
      companyName: '',
      displayName: '',
      email: '',
      workPhone: '',
      mobile: '',
      mobile_secondary: '',
      workPhone_secondary: '',
      designation: '',
      department: '',
      website: '',
      license: '',
      sellerPermit: '',
      contactTypeId: 2, //customer
      currencyID: JSON.parse(localStorage.getItem('currentOrganization'))
        ?.base_currency_id,
      billingAddress: {
        attention: '',
        street: '',
        city: '',
        state: '',
        zipCode: '',
        // countryID: 230,
        countryID:
          this.type === 'add' || 'purchaseOrder' || 'salesOrder'
            ? JSON.parse(localStorage.getItem('currentOrganization'))
                ?.country_id
            : 230,
        fax: '',
        phone: '',
      },
      shippingAddress: {
        attention: '',
        street: '',
        city: '',
        state: '',
        zipCode: '',
        // countryID: 230,
        countryID:
          this.type === 'add' || 'purchaseOrder' || 'salesOrder'
            ? JSON.parse(localStorage.getItem('currentOrganization'))
                ?.country_id
            : 230,
        fax: '',
        phone: '',
      },
      facebook: '',
      twitter: '',
      skype: '',
      instagram: '',
      notes: '',
    },
    currencyEditable: true,
    InvoiceTerms: [],
    currencyDB: [],
    countryDB: [],
    CountryStatesCombo: [],
    accessDenied: false,
    billingAddressCountryStates: [],
    shippingAddressCountryStates: [],
    isUniqueDisplayName: true,
    checkboxShipping: false,
    loading: true,
    planExceeded: null,
    showPrompt: false,
    updateoverAll: false,
    base84Image: [],
  };

  componentDidMount() {
    let getDocumentTitle = () => {
      switch (this.type) {
        case 'edit':
          return 'Edit Contact';
        case 'clone':
          return 'Clone Contact';
        case 'add':
        case 'purchaseOrder':
        case 'salesOrder':
        default:
          return 'Add Contact';
      }
    };
    if (!this.props.inModal) {
      document.title = getDocumentTitle();
    }
    this.handleContactType(this.type);
    this.setState({ loading: true });
    this.fetchData().then(() => this.setState({ loading: false }));
    sessionStorage.setItem('once', false);
    sessionStorage.setItem('fullscreen', false);
    this.props.inModal
      ? sessionStorage.setItem('fullscreen', true)
      : sessionStorage.setItem('fullscreen', false);
    if (this.type === 'add') return;
    let dataFromLS = getObjFromLS('module');
    let editID = parseInt(window.location.pathname.split('/')[3]);
    if (dataFromLS && dataFromLS.id !== editID) {
      setObjInLS('module', { ...dataFromLS, id: editID });
    }
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  handleContactType(type) {
    let dataFromLS = getObjFromLS('module');
    if (dataFromLS && dataFromLS.vendorFlag === true) {
      this.setState({ contact: { ...this.state.contact, contactTypeId: 1 } });
    } else if (type === 'purchaseOrder') {
      this.setState({ contact: { ...this.state.contact, contactTypeId: 1 } }); //vendor
    }
  }

  fetchData = async () => {
    this.setState({ loading: true });

    switch (this.type) {
      case 'edit':
        await Promise.all([this.fetchDetails('edit'), this.fetchCountryList()]);
        this.populateCountryStates(
          this.state.contact.billingAddress.countryID || 230,
          'billing'
        );
        this.populateCountryStates(
          this.state.contact.shippingAddress.countryID || 230,
          'shipping'
        );
        break;
      case 'clone':
        await Promise.all([
          this.fetchDetails('clone'),
          this.fetchCountryList(),
        ]);
        this.populateCountryStates(
          this.state.contact.billingAddress.countryID || 230,
          'billing'
        );
        this.populateCountryStates(
          this.state.contact.shippingAddress.countryID || 230,
          'shipping'
        );
        break;
      case 'add':
      default:
        await Promise.all([this.fetchCreateData(), this.fetchCountryList()]);
        this.populateCountryStates(
          this.state.contact.billingAddress.countryID || 230,
          'billing'
        );
        this.populateCountryStates(
          this.state.contact.shippingAddress.countryID || 230,
          'shipping'
        );
        break;
    }
    this.setState({ loading: false });
  };

  fetchCountryList() {
    return restRequest('get', 'country')
      .then((countryList) => {
        this.setState({ countryDB: countryList });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  }

  fetchCreateData() {
    return restRequest('get', 'contacts/create')
      .then((res) => {
        let CountryStates = COUNTRY_STATE.find(
          (element) => element.country === 'United States'
        ).statesCombination;
        this.setState({ CountryStatesCombo: CountryStates });
        this.setState({
          currencyDB: res.currencies,
          InvoiceTerms: res.invoice_terms,
          currencyEditable: res.currency_editable,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        if (error.response && error.response.status === 403) {
          this.setState({
            accessDenied: true,
            loading: false,
          });
        } else {
          this.setState({
            planExceeded:
              error &&
              error.response &&
              error.response.data &&
              error.response.data.status,
            loading: false,
          });
        }
      });
  }

  fetchDetails(reqUrl) {
    return restRequest('get', `contacts/${this.id}/${reqUrl}`)
      .then((res) => {
        let updatedContact = {
          currentImage: res.contact.photo,
          salutation: res.contact.salutation,
          firstName: res.contact.first_name,
          lastName: res.contact.last_name,
          companyName: res.contact.company_name,
          displayName: reqUrl !== 'clone' ? res.contact.display_name : '',
          email: res.contact.email || '',
          workPhone: res.contact.work_phone,
          mobile: res.contact.mobile,
          designation: res.contact.designation,
          department: res.contact.department,
          website: res.contact.website,
          currencyID: res.contact.currency_id,
          invoiceTermId: res.contact.invoice_term_id,
          contactTypeId: res.contact.contact_type_id,
          license: res.contact.license_no,
          sellerPermit: res.contact.permit_no,
          mobile_secondary: res.contact.mobile_secondary
            ? res.contact.mobile_secondary
            : '',
          workPhone_secondary: res.contact.work_phone_secondary
            ? res.contact.work_phone_secondary
            : '',
          ...this.populateAddress(res.contact.contact_addresses),
          skype: res.contact.skype,
          instagram: res.contact.instagram,
          facebook: res.contact.facebook,
          twitter: res.contact.twitter,
          notes: res.contact.note,
          displayNameEdit:
            this.type === 'edit'
              ? {
                  id: 1,
                  label: res.contact.display_name,
                }
              : '',
          displayNames:
            this.type === 'edit'
              ? [
                  {
                    // id: res.contact.display_name,
                    id: 1,
                    label: res.contact.display_name,
                  },
                ]
              : [],
          displayNameForEdit:
            this.type === 'edit'
              ? [
                  {
                    // id: res.contact.display_name,
                    id: 1,
                    label: res.contact.display_name,
                  },
                ]
              : [],
          dupDisplayNames: [],
          base84Image: [],
        };
        // console.log('objecasdsat',this.type === "edit" ? [{id:res.contact.display_name ,label:res.contact.display_name}]  : [])
        this.setState({
          contact: updatedContact,
          currencyEditable: res.currency_editable,
          currencyDB: res.currencies,
          InvoiceTerms: res.invoice_terms,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        if (error.response && error.response.status === 403) {
          this.setState({
            accessDenied: true,
            loading: false,
          });
        } else {
          this.setState({
            planExceeded: error.response && error.response.data.status,
            loading: false,
          });
        }
      });
  }

  populateAddress(addresses) {
    let billingAddress = addresses.find(
      (address) => address.address_type === 'billing'
    );
    let shippingAddress = addresses.find(
      (address) => address.address_type === 'shipping'
    );
    let CountryStates = COUNTRY_STATE.find(
      (element) => element.country === 'United States'
    ).statesCombination;
    this.setState({ CountryStatesCombo: CountryStates });
    return {
      billingAddress: {
        attention: billingAddress ? billingAddress.attention : '',
        street: billingAddress ? billingAddress.street : '',
        city: billingAddress ? billingAddress.city : '',
        state: billingAddress ? billingAddress.state : '',
        zipCode: billingAddress ? billingAddress.zip_code : '',
        countryID: billingAddress ? billingAddress.country_id : 230,
        fax: billingAddress ? billingAddress.fax : '',
        phone: billingAddress ? billingAddress.phone : '',
      },
      shippingAddress: {
        attention: shippingAddress ? shippingAddress.attention : '',
        street: shippingAddress ? shippingAddress.street : '',
        city: shippingAddress ? shippingAddress.city : '',
        state: shippingAddress ? shippingAddress.state : '',
        zipCode: shippingAddress ? shippingAddress.zip_code : '',
        countryID: shippingAddress ? shippingAddress.country_id : 230,
        fax: shippingAddress ? shippingAddress.fax : '',
        phone: shippingAddress ? shippingAddress.phone : '',
      },
    };
  }

  populateCountryStates = (countryId, type) => {
    if (type === 'billing') {
      let billingCountry = countryId
        ? this.state.countryDB.find((element) => {
            return element.id === countryId;
          })
        : null;
      let billingCountryStates = billingCountry
        ? COUNTRY_STATE.find((element) => {
            return element.country === billingCountry.country_name;
          })
        : null;
      this.setState({
        billingAddressCountryStates: billingCountryStates
          ? billingCountryStates.states
          : [].map((state) => ({
              value: state,
              label: state,
            })),
      });
    }
    if (type === 'shipping') {
      let shippingCountry = countryId
        ? this.state.countryDB.find((element) => {
            return element.id === countryId;
          })
        : null;
      let shippingCountryStates = shippingCountry
        ? COUNTRY_STATE.find((element) => {
            return element.country === shippingCountry.country_name;
          })
        : null;
      this.setState({
        shippingAddressCountryStates: shippingCountryStates
          ? shippingCountryStates.states
          : [].map((state) => ({
              value: state,
              label: state,
            })),
      });
    }
  };

  submitRequestType() {
    switch (this.type) {
      case 'edit':
        return 'post';
      case 'add':
      case 'clone':
      case 'purchaseOrder':
      case 'salesOrder':
      default:
        return 'post';
    }
  }

  submitRequestUrl() {
    switch (this.type) {
      case 'edit':
        return `contacts/${this.id}`;
      case 'add':
      case 'clone':
      case 'purchaseOrder':
      case 'salesOrder':
      default:
        return 'contacts';
    }
  }

  successMessage = () => {
    switch (this.type) {
      case 'edit':
        this.props.handleToast('Contact has been updated', 'success');
        break;
      case 'add':
      case 'clone':
      case 'purchaseOrder':
      case 'salesOrder':
      default:
        this.props.handleToast('Contact created successfully', 'success');
        break;
    }
  };

  handlePrompt = (status = true) => this.setState({ showPrompt: status });
  handleUpdate = (status = true) => this.setState({ updateoverAll: status });

  renderContactForm() {
    const {
      contact,
      currencyDB,
      InvoiceTerms,
      currencyEditable,
      countryDB,
      billingAddressCountryStates,
      shippingAddressCountryStates,
      CountryStatesCombo,
      updateoverAll,
    } = this.state;

    return (
      <>
        <Prompt
          when={this.state.showPrompt}
          message="Are you sure you want to leave page while changes made?"
        />
        <ContactEnhancedForm
          contact={{
            ...contact,
            contactTypeId: this.props.contactTypeId || contact.contactTypeId,
          }}
          status={{ isUniqueDisplayName: true }}
          CountryStatesCombo={CountryStatesCombo}
          InvoiceTerms={InvoiceTerms}
          requestType={this.submitRequestType()}
          requestUrl={this.submitRequestUrl()}
          successMessage={this.successMessage}
          currencyDB={currencyDB}
          currencyEditable={currencyEditable}
          countryDB={countryDB}
          billingAddressCountryState={billingAddressCountryStates}
          shippingAddressCountryState={shippingAddressCountryStates}
          populateCountryStates={this.populateCountryStates}
          updateTab={this.props.updateTab}
          tab={this.props.tab}
          setPrompt={this.handlePrompt}
          handleUpdate={this.handleUpdate}
          updateoverAll={updateoverAll}
          {...this.props}
          type={this.type}
        />
      </>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.planExceeded === false) return <PlanExceeded />;
    if (this.state.accessDenied) return <AccessDenied />;
    return this.renderContactForm();
  }
}

export default withRouter(ContactForm);
