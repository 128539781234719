import React from 'react';
import RecordPaymentIcon from '../../Assets/General/RecordPaymentIcon';
import { FormLayout } from '../../Components';
import RecivedPaymentForm from './ReceivedPaymentForm';
import { getObjFromLS } from '../../Helpers';
import { useLocation } from 'react-router-dom';

const AddPaymentsReceived = (props) => {
  const location = useLocation();

  const handleRedirection = () => {
    const { search = '' } = location;
    let dataFromLS = getObjFromLS('module');
    if (dataFromLS.moduleName === 'contacts' && search) return '/contacts';
    else return '/paymentsmade';
  };
  return (
    <FormLayout
      title="Create Customer Payment"
      Icon={RecordPaymentIcon}
      // closeUrl="/paymentsmade"
      closeUrl={handleRedirection()}
      iconstyle="general_module_icon"
      layoutBoxClass="uiux-form-redesign"
    >
      <RecivedPaymentForm type="add" {...props} />
    </FormLayout>
  );
};

export default AddPaymentsReceived;
