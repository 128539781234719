import React from 'react';

// import { formatNumber } from '../../../../../Helpers/FormatNumber';

import Table from './../../../../../Components/Table/Table';
import { GridLayout } from '../../../../../Components/Layout/Layout';
import ListTableImageColumn from '../../../../../Components/ListTable/ListTableImageColumn';
import Quantity from '../../../../../Components/Fragments/Quantity/Quantity';

const PackageItemInfo = ({
  itemList = [],
  // packageInfo,
  // attLength,
  inModal,
}) => {
  const tableHeaders = ['Item', 'SKU', 'Warehouse', 'Qty.'];
  const tableData = ['itemName', 'sku', 'warehouse', 'quantity'];

  const rightAlignColumns = [];
  const packedItems = itemList?.map((packedItem) => {
    return {
      itemName: (
        <ListTableImageColumn
          images={packedItem.item.images}
          name={packedItem.item_name}
          id={packedItem.item.id}
          showModalUponClick={inModal ? false : true}
          value={packedItem.extra_description}
          showExtraDisableDescription={!!packedItem.extra_description}
        />
      ),
      sku: packedItem.item.sku,
      quantity: (
        <Quantity quantity={packedItem.quantity} unit={packedItem.item.unit} />
      ),
      warehouse: packedItem.warehouse_name,
    };
  });
  return (
    <div className={`package-item-info package_details_table`}>
      <Table
        list={packedItems}
        tableHeaders={tableHeaders}
        tableData={tableData}
        rightAlignColumns={rightAlignColumns}
        className="details-table uiux_package_details"
      />
      <hr className="float-left w-100" />
      <GridLayout className="custom_paddding row-flex" grid="3-2">
        <div />
        <div className="section-details--footer-amount">
          <div style={{ gridTemplateColumns: '2fr 2fr' }}>
            <span className="semi-bold">Total Items</span>
            <span className="semi-bold">{itemList?.length}</span>
          </div>
        </div>
      </GridLayout>
      <hr className="float-left w-100" />
    </div>
  );
};

export default PackageItemInfo;
