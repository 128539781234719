import React from 'react';
import { publicRequest } from '../../Helpers/RequestHelper';

class UserConfirmation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: '',
        };
    }

    componentDidMount() {
        publicRequest('post', 'register/confirm', {
            register_confirm_token: this.props.match.params.token,
        })
            .then((res) => {
                if (res.hasOwnProperty('msg')) {
                    this.props.handleToast(res.msg, 'success');
                } else {
                    this.props.handleToast(
                        'Account confirmed successfully',
                        'success'
                    );
                }
                this.props.history.push('/login');
            })
            .catch((error) => {
                this.props.handleToast(error, 'error');
            });
    }

    confirmComponent() {
        return (
            <div className="container">
                <div className="row">
                    <div className="panel panel-warning text-center">
                        <div className="panel-heading">
                            <h4>Verifying your account ...</h4>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return <div>{this.confirmComponent()}</div>;
    }
}

export default UserConfirmation;
