import React from 'react'

export default ({
                  className = ''
                }) => (

<svg className={className+ " delivery_icon"} xmlns="http://www.w3.org/2000/svg" width="32.813" height="26.25" viewBox="0 0 32.813 26.25">
  <path className="a"
        d="M1.094-26.25V-3.281H4.546A4.341,4.341,0,0,0,6.084-.923,4.168,4.168,0,0,0,8.75,0a4.168,4.168,0,0,0,2.666-.923,4.341,4.341,0,0,0,1.538-2.358h9.092A4.341,4.341,0,0,0,23.584-.923,4.168,4.168,0,0,0,26.25,0a4.168,4.168,0,0,0,2.666-.923,4.341,4.341,0,0,0,1.538-2.358h3.452V-14.663L33.6-15l-6.562-6.562-.342-.308H20.781V-26.25Zm2.188,2.188H18.594V-5.469h-5.64a4.341,4.341,0,0,0-1.538-2.358A4.168,4.168,0,0,0,8.75-8.75a4.168,4.168,0,0,0-2.666.923A4.341,4.341,0,0,0,4.546-5.469H3.281Zm17.5,4.375h4.99l5.947,5.947v8.271H30.454a4.341,4.341,0,0,0-1.538-2.358A4.168,4.168,0,0,0,26.25-8.75a4.168,4.168,0,0,0-2.666.923,4.341,4.341,0,0,0-1.538,2.358H20.781ZM8.75-6.562a2.114,2.114,0,0,1,1.555.632,2.114,2.114,0,0,1,.632,1.555,2.114,2.114,0,0,1-.632,1.555,2.114,2.114,0,0,1-1.555.632A2.114,2.114,0,0,1,7.195-2.82a2.114,2.114,0,0,1-.632-1.555A2.114,2.114,0,0,1,7.195-5.93,2.114,2.114,0,0,1,8.75-6.562Zm17.5,0a2.114,2.114,0,0,1,1.555.632,2.114,2.114,0,0,1,.632,1.555,2.114,2.114,0,0,1-.632,1.555,2.114,2.114,0,0,1-1.555.632,2.114,2.114,0,0,1-1.555-.632,2.114,2.114,0,0,1-.632-1.555,2.114,2.114,0,0,1,.632-1.555A2.114,2.114,0,0,1,26.25-6.562Z"
        transform="translate(-1.094 26.25)"/>
</svg>
)
