import React from 'react'
import { useLocation } from 'react-router-dom';
import './Layout.css'
import InfoIcon from '../../Assets/Dashboard/infoIcon'

// let location
const PreferencesIcon = () => {
  let location = useLocation()
  // console.log(location.pathname)
  if (location.pathname.includes('/preference')) {
    return (
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M6 14.0002H8.37C9.198 16.3222 11.396 18.0002 14 18.0002C16.604 18.0002 18.802 16.3222 19.63 14.0002H42C43.106 14.0002 44 13.1062 44 12.0002C44 10.8942 43.106 10.0002 42 10.0002H19.63C18.802 7.67821 16.604 6.00021 14 6.00021C11.396 6.00021 9.198 7.67821 8.37 10.0002H6C4.894 10.0002 4 10.8942 4 12.0002C4 13.1062 4.894 14.0002 6 14.0002ZM38 18.0002C35.396 18.0002 33.198 19.6782 32.37 22.0002H6C4.894 22.0002 4 22.8942 4 24.0002C4 25.1062 4.894 26.0002 6 26.0002H32.37C33.198 28.3222 35.396 30.0002 38 30.0002C41.308 30.0002 44 27.3082 44 24.0002C44 20.6922 41.308 18.0002 38 18.0002ZM27.63 34.0002H42C43.106 34.0002 44 34.8942 44 36.0002C44 37.1062 43.106 38.0002 42 38.0002H27.63C26.802 40.3222 24.604 42.0002 22 42.0002C19.396 42.0002 17.198 40.3222 16.37 38.0002H6C4.894 38.0002 4 37.1062 4 36.0002C4 34.8942 4.894 34.0002 6 34.0002H16.37C17.198 31.6782 19.396 30.0002 22 30.0002C24.604 30.0002 26.802 31.6782 27.63 34.0002Z" fill="#ADB6BC" />
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="4" y="6" width="40" height="37">
          <path fillRule="evenodd" clipRule="evenodd" d="M6 14.0002H8.37C9.198 16.3222 11.396 18.0002 14 18.0002C16.604 18.0002 18.802 16.3222 19.63 14.0002H42C43.106 14.0002 44 13.1062 44 12.0002C44 10.8942 43.106 10.0002 42 10.0002H19.63C18.802 7.67821 16.604 6.00021 14 6.00021C11.396 6.00021 9.198 7.67821 8.37 10.0002H6C4.894 10.0002 4 10.8942 4 12.0002C4 13.1062 4.894 14.0002 6 14.0002ZM38 18.0002C35.396 18.0002 33.198 19.6782 32.37 22.0002H6C4.894 22.0002 4 22.8942 4 24.0002C4 25.1062 4.894 26.0002 6 26.0002H32.37C33.198 28.3222 35.396 30.0002 38 30.0002C41.308 30.0002 44 27.3082 44 24.0002C44 20.6922 41.308 18.0002 38 18.0002ZM27.63 34.0002H42C43.106 34.0002 44 34.8942 44 36.0002C44 37.1062 43.106 38.0002 42 38.0002H27.63C26.802 40.3222 24.604 42.0002 22 42.0002C19.396 42.0002 17.198 40.3222 16.37 38.0002H6C4.894 38.0002 4 37.1062 4 36.0002C4 34.8942 4.894 34.0002 6 34.0002H16.37C17.198 31.6782 19.396 30.0002 22 30.0002C24.604 30.0002 26.802 31.6782 27.63 34.0002Z" fill="white" />
        </mask>
        <g mask="url(#mask0)">
          {/* <rect width="48" height="48" fill="#ADB6BC" /> */}
          <rect width="48" height="48" fill="#000" />
        </g>
      </svg>
    )
  }
  else return null
}
export const Header = ({
  Icon = () => null,
  title = '',
  subtitle = '',
  className = '',
  children,
  showModal,
  fromDashboard = false,
  iconStyle = ''
}) => (
  <div className={`top-header ${className}`}>
    <span className="layout--title ellipsis">
      <Icon className={iconStyle} />
      <PreferencesIcon />
      <h3>{title && title} {subtitle && '/'} <span className="semi-bold">{subtitle}</span></h3>
    </span>
    {fromDashboard && (
      <button
        onClick={showModal}
        className="button--primary dash float-left flex"
      >
        <InfoIcon />
        {/* <MdAdd /> */}
        View Updates
      </button>
    )}
    {children}
  </div>
)
export const HeaderForHW = ({
  Icon = () => null,
  title = '',
  subtitle = '',
  className = '',
  children
}) => (
  <div className={`top-header ${className}`}>
    <span className="layout--title ellipsis">
      <Icon />
      <PreferencesIcon />
      <h3>{title && title} {subtitle && '/'} <span className="semi-bold">{subtitle}</span></h3>
    </span>
    {children}
  </div>
)

export const Body = ({
  children,
  className = ''
}) => (
  <div className={`layout--body ${className}`}>
    {children}
  </div>
)

export const Grid = ({
  children,
  className = '',
  transparent = false
}) => (
  <div className={`layout--grid ${className}${transparent ? ' transparent' : ''}`}>
    {children}
  </div>
)

export const GridLayout = ({
  children,
  grid = 'default',
  className = ''
}) => (
  <div className={`layout--grid-container grid-${grid} ${className}`}>
    {children}
  </div>
)

export default ({
  children,
  className = ''
}) => {
  return (
    <div className={`layout main-layout-set ${className} ${window.location.search.includes("search") ? "SarchResults" : ''}`}>
      {children}
    </div>
  )
}