import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './Content.css'

class Content extends Component {
  render() {
    return (
      <div className="home-content">
        <div className="home-content-title">
          <div className="home-content-title-heading">
            Hassle free inventory management.
            <br />
            Take control of your business.
          </div>
          <div className="home-content-desc">
            Sign up for early access to our Beta version
          </div>
          <Link to="/signup" className="home--btn">
            Get Started
          </Link>
        </div>
      </div>
    )
  }
}

export default Content
