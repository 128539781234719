import React, { Component } from 'react';

import AddressForm from '../../../../../Components/Form/AddressForm/AddressForm';

import './AddContactAddress.css';

class AddContactAddress extends Component {
  state = {
    addressType: 'billing',
    disabledAddressForms: [],
    billingAddress: {},
  };

  componentDidMount() {
    let contactAddresses = this.props.contactAddresses;
    let billing = contactAddresses.find(
      (address) => address.address_type === 'billing'
    );
    let shipping = contactAddresses.find(
      (address) => address.address_type === 'shipping'
    );

    if (!!billing) {
      this.setState({ billingAddress: billing });
    }
    if (!billing) {
      this.setState({ addressType: 'billing' });
    } else if (!shipping) {
      this.setState({ addressType: 'shipping' });
    } else {
      this.setState({ addressType: 'additional' });
    }
    this.state.disabledAddressForms.push(
      !!billing && 'billing',
      !!shipping && 'shipping'
    );
  }

  handleAddressForm = (event) => {
    let stateName = event.target.name;
    let value = event.target.value;
    this.setState({ [stateName]: value });
  };

  renderRadioButtons() {
    return (
      <div
        className="add-contact-address--radio radio-container"
        onChange={this.handleAddressForm}
      >
        <div className="radio-btn">
          <input
            readOnly
            type="radio"
            name="addressType"
            id="billing"
            value="billing"
            checked={this.state.addressType === 'billing'}
            disabled={this.state.disabledAddressForms.includes('billing')}
          />
          <label htmlFor="billing">Billing</label>
          <span className="radio-circle" />
        </div>

        <div className="radio-btn">
          <input
            readOnly
            type="radio"
            name="addressType"
            id="shipping"
            value="shipping"
            checked={this.state.addressType === 'shipping'}
            disabled={this.state.disabledAddressForms.includes('shipping')}
          />
          <label htmlFor="shipping">Shipping</label>
          <span className="radio-circle" />
        </div>

        <div className="radio-btn">
          <input
            readOnly
            type="radio"
            name="addressType"
            id="additional"
            value="additional"
            checked={this.state.addressType === 'additional'}
          />
          <label htmlFor="additional">Additional</label>
          <span className="radio-circle" />
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.renderRadioButtons()}
        <AddressForm
          type="add"
          contactId={this.props.contactId}
          addressType={this.state.addressType}
          onSubmit={this.props.onSubmit}
          onCancel={this.props.onCancel}
          handleToast={this.props.handleToast}
          billingAddress={this.state.billingAddress}
          handlePrompt={this.props.handlePrompt}
        />
      </>
    );
  }
}

export default AddContactAddress;
