import React, { useState, useEffect } from 'react';
import FAQIcon from '../../Assets/Dashboard/FAQIcon';
import HelpDocumentIcon from '../../Assets/Dashboard/HelpDocumentIcon';
import MigrationGuideIcon from '../../Assets/Dashboard/MigrationGuideIcon';
import ProductTourIcon from '../../Assets/Dashboard/ProductTourIcon';
import ChatIcon from '../../Assets/Dashboard/ChatIcon';
import EmailIcon from '../../Assets/Dashboard/EmailIcon';
import CrossIcon from '../../Assets/Dashboard/CrossIcon';
import SearchIconHelp from '../../Assets/Dashboard/SearchIconHelp';
import CallIcon from '../../Assets/Dashboard/CallIcon';
import { UserManual } from '../../Constants';
import './HelpSection.css';
import { seebizCloudUrl } from '../../Helpers/UrlHelper';

export default function HelpSection() {
  const seebizUrl = seebizCloudUrl();
  const [documentation, showDocumentation] = useState(false);
  const [Filter, setFilterSearch] = useState('');
  const [filterData, setFilterData] = useState(UserManual);

  const handleChange = (e) => {
    setFilterSearch(e.target.value);
  };

  useEffect(() => {
    const reuslt = UserManual.filter((data) => {
      return Object.keys(data).some((key) =>
        data['label'].toLowerCase().includes(Filter.trim().toLowerCase())
      );
    });
    setFilterData(reuslt);
  }, [Filter]);

  const resetValues = () => {
    showDocumentation((prev) => !prev);
    setFilterSearch('');
  };

  const showDocumentationDetails = () => {
    return (
      <>
        <div className="user_manual">
          <div className="float-left w-100 info">
            <span>User Manual</span>
            <button onClick={() => showDocumentation((prev) => !prev)}>
              <CrossIcon />
            </button>
          </div>
          <div className="search float-left w-100">
            <input
              type="text"
              value={Filter}
              id="gsearch"
              name="gsearch"
              onChange={(e) => handleChange(e)}
            />
            <SearchIconHelp />
          </div>
          <div className="options-- float-left w-100">
            {filterData.map((doc) => (
              <>
                <div className="user_manual_options">
                  <a
                    target="_blank"
                    href={`//${doc.url}`}
                    rel="noopener noreferrer"
                  >
                    <span>{doc.value}</span>

                    <span style={{ marginLeft: '10px' }}>{doc.label}</span>
                  </a>
                </div>
              </>
            ))}
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="helpSupport px-10">
        <div className="heading">
          <div className="heading_inner">
            <span>Help & Support</span>
          </div>
        </div>
        <div className="float-left w-100">
          <div
            className="helpsection_inner float-left w-50 cursor_pointer"
            onClick={() => resetValues()}
          >
            <div
              className="icon float-left w-100 text-center"
              style={{ marginBottom: '5px' }}
            >
              <HelpDocumentIcon />
            </div>
            <div className="desc float-left w-100 text-center">
              Help Documents
            </div>
          </div>

          <div className="helpsection_inner float-left w-50">
            <a
              target="_blank"
              href={`https://www.${seebizUrl}/inventory/contact-us/`}
              rel="noopener noreferrer"
            >
              <div
                className="icon float-left w-100 text-center"
                style={{ marginBottom: '5px' }}
              >
                <FAQIcon />
              </div>
              <div className="desc float-left w-100 text-center">FAQ</div>
            </a>
          </div>
          <div className="helpsection_inner float-left w-50">
            <a
              target="_blank"
              href={`https://www.${seebizUrl}/inventory/contact-us/`}
              rel="noopener noreferrer"
            >
              <div
                className="icon float-left w-100 text-center"
                style={{ marginBottom: '5px' }}
              >
                <MigrationGuideIcon />
              </div>
              <div className="desc float-left w-100 text-center">
                Migration Guide
              </div>
            </a>
          </div>
          <div className="helpsection_inner float-left w-50">
            <a
              target="_blank"
              href={`https://www.${seebizUrl}/inventory/user-manual/how-to-register-with-seebiz-inventory/   `}
              rel="noopener noreferrer"
            >
              <div
                className="icon float-left w-100 text-center"
                style={{ marginBottom: '5px' }}
              >
                <ProductTourIcon />
              </div>
              <div className="desc float-left w-100 text-center">
                Product Tour
              </div>
            </a>
          </div>
        </div>
      </div>
      {documentation && showDocumentationDetails()}
      <div className="need_assiatance px-10">
        <div className="heading">
          <div className="heading_inner">
            <span>Need Assistance?</span>
          </div>
        </div>
        <ul>
          <li>
            <a
              target="_blank"
              href={`https://www.${seebizUrl}/inventory/contact-us/ `}
              rel="noopener noreferrer"
            >
              <ChatIcon /> Chat With Our Experts
            </a>
          </li>
          <li>
            <a href="mailto:contact@seebiz-inventory.com">
              <EmailIcon /> Send Email
            </a>
          </li>
        </ul>
      </div>
      <div className="videotutorials px-10">
        <div className="heading">
          <div className="heading_inner">
            <span>Video Tutorials</span>
          </div>
        </div>
        <div className="float-left w-100 video_">
          <div className="float-left w-50 video_inner">
            <div className="image float-left w-100">
              <a
                target="_blank"
                href="https://www.youtube.com/watch?v=nzA65-Au0Uc"
                rel="noopener noreferrer"
              >
                <img
                  src={`${process.env.REACT_APP_AMAZON_S3}/static/media/help_section/thumb_item.png`}
                  alt="item-logo"
                />
              </a>
            </div>
          </div>
          <div className="float-left w-50 video_inner">
            <div className="image float-left w-100">
              <a
                target="_blank"
                href="https://www.youtube.com/watch?v=7fruK9bGEdI"
                rel="noopener noreferrer"
              >
                <img
                  src={`${process.env.REACT_APP_AMAZON_S3}/static/media/help_section/thumb_contact.png`}
                  alt="contact-logo"
                />
              </a>
            </div>
          </div>
          <div className="float-left w-50 video_inner">
            <div className="image float-left w-100">
              <a
                target="_blank"
                href="https://www.youtube.com/watch?v=-wl3Rt2UCTM"
                rel="noopener noreferrer"
              >
                <img
                  src={`${process.env.REACT_APP_AMAZON_S3}/static/media/help_section/thumb_saleorder.png`}
                  alt="so-logo"
                />
              </a>
            </div>
          </div>
          <div className="float-left w-50 video_inner">
            <div className="image float-left w-100">
              <a
                target="_blank"
                href="https://www.youtube.com/watch?v=VanjBMN14IE"
                rel="noopener noreferrer"
              >
                <img
                  src={`${process.env.REACT_APP_AMAZON_S3}/static/media/help_section/thumb_invoice.png`}
                  alt="invoice-logo"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="more_Vidoes">
          <a
            target="_blank"
            href="https://www.youtube.com/playlist?list=PLrNraRgjv2ipX3nY1Rr-3yGKSukDDj-Gw"
            rel="noopener noreferrer"
          >
            View More Videos
          </a>
        </div>
      </div>
      <div className="ring_us px-10">
        <div className="heading">
          <div className="heading_inner">
            <span>Ring Us</span>
          </div>
        </div>
        <div className="float-left w-100 anytime">
          <span>Anytime between Monday - Friday</span>
          <a href="tel:+1 213 986 9911">
            <CallIcon /> +1 213 986 9911
          </a>
        </div>
      </div>
    </div>
  );
}
