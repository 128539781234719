import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { restRequest, checkError } from '../../../Helpers';
import Loader from '../../Loader/Loader';
import EnhancedForm from './TaxFormEnhancer';
import './TaxForm.css';

class TaxForm extends Component {
  type = this.props.type; // "add" or "edit" form

  id = this.props.id || null;

  state = {
    tax: {
      name: '',
      rate: null,
      authority: '',
    },
    loading: true,
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.fetchData().then(() => this.setState({ loading: false }));
  }

  fetchData = async () => {
    if (this.type === 'edit') {
      await restRequest('get', `taxes/${this.id}/edit`)
        .then((res) => {
          let updatedTax = {
            name: res.name,
            rate: res.rate,
            authority: res.authority,
          };
          this.setState({ tax: updatedTax });
        })
        .catch((error) => {
          checkError(error, this.props.handleToast);
        });
    }
  };

  submitRequestType() {
    if (this.type === 'add') {
      return 'post';
    } else if (this.type === 'edit') {
      return 'put';
    }
  }

  submitRequestUrl() {
    if (this.type === 'add') {
      return 'taxes';
    } else if (this.type === 'edit') {
      return `taxes/${this.id}`;
    }
  }

  successMessage = () => {
    if (this.type === 'add') {
      this.props.handleToast('Tax created successfully', 'success');
    } else if (this.type === 'edit') {
      this.props.handleToast('Tax has been updated', 'success');
    }
  };

  taxContent() {
    const { tax } = this.state;
    const {
      title,
      history,
      inModal,
      closeModal,
      onSubmitModal,
      handleToast,
      totalRecords,
      modelSource = '',
      taxValueFetchAutomatically = () => null,
      // handleCrossAlert,
      handlePrompt = () => null,
    } = this.props;

    return (
      <EnhancedForm
        title={title}
        tax={{ ...tax }}
        requestType={this.submitRequestType()}
        requestUrl={this.submitRequestUrl()}
        onSubmitModal={onSubmitModal}
        taxValueFetchAutomatically={taxValueFetchAutomatically}
        successMessage={this.successMessage}
        handleToast={handleToast}
        history={history}
        inModal={inModal}
        closeModal={closeModal}
        totalRecords={totalRecords}
        modelSource={modelSource}
        handlePrompt={handlePrompt}
        // handleCrossAlert={handleCrossAlert}
      />
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return this.taxContent();
  }
}

export default withRouter(TaxForm);
