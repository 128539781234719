import { useEffect, useState } from 'react';

const ScreenSize = () => {
  const [size, setSize] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const handleResize = () => {
    setSize({ height: window.innerHeight, width: window.innerWidth });
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    // Clean up!
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { size };
};
export { ScreenSize };
