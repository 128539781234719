import {
  isAndroid,
  isTablet,
  isIOS,
  isWinPhone,
  isMobile,
} from 'mobile-device-detect';

const useMobileDetection = () => {
  const mobileDevice = isMobile || isAndroid || isTablet || isIOS || isWinPhone;

  return [mobileDevice];
};
export default useMobileDetection;
