import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import logo from './../../../Assets/Img/logo.png'

import './Header.css'

class Header extends Component {
  render() {
    return (
      <div className="home-header">
        <Link to="/" className="home-header-logo">
          <img src={logo} alt="seebiz-inventory" />
        </Link>

        <div className="home-header-nav-container">
          <div className="home-header-nav semi-bold">
            <Link to="/signup">Get Started</Link>
            <Link to="/login" className="home-header-nav--sign-in">Sign In</Link>
          </div>
        </div>
      </div>
    )
  }
} 

export default Header