import { restRequest } from "./RequestHelper";

export async function downloadAttachment(url, toast, type) {
  document.body.style.cursor = 'wait';
  await restRequest("get", `${url}`)
    .then((res) => {
      let file = res.file
      let encodedUri = encodeURI(file)
      let link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', `${res.name}.${res.type}`)
      document.body.appendChild(link)
      link.click()
      document.body.style.cursor = 'default';
      var att = (type === 'single') ? 'Attachment' : 'Attachments'
      toast(`${att} downloaded successfully`, 'success')
      return true
    })
    .catch((error) => {
      document.body.style.cursor = 'default';
      toast("Something went wrong", "error");
      return false
    });
}
