import React, { Component, Fragment } from 'react';

import { restRequest } from '../../../../Helpers/RequestHelper';
import getDate from '../../../../Helpers/Date/GetDate';
import { pdfDownlaod } from '../../../../Helpers/PdfDownload';
// import { SalesReturnReceiveIcon } from '../../../../Assets/Navigation/NavigationIcons'
import Table from '../../../../Components/Table/Table';
import Loader from '../../../../Components/Loader/Loader';
import PDFViewer from '../../../../Components/PDFViewer/PDFViewer';
import CustomModal from '../../../../Components/CustomModal/CustomModal';
import { MdAdd } from '../../../../Common/Icons';

import { NoItemsBox } from '../../../../Components/Layout/Box';
import ReturnReceive from './../../../../Components/Form/ReturnReceive/ReturnReceive';
import ReturnReceiveDetails from './../../../../Components/Form/ReturnReceive/ReturnReceiveDetails';
// import ShipmentCreate from './../../Packages/ShipmentCreate'
// import { LinkStatusColumn } from '../../../../Components/ListTable/ListTableColumnJoin'
import ListTableColumnJoin from '../../../../Components/ListTable/ListTableColumnJoin';
// import EditIcon from '../../../../Assets/General/EditIcon'
import DeleteIcon from '../../../../Assets/General/DeleteIcon';
import {
  Button,
  ActionMenu,
  ButtonLink,
} from '../../../../Components/CheckedMenu/CheckedMenu';
import AddRetrunReceive from '../../../../Assets/General/AddRetrunReceive';
import { withRouter } from 'react-router-dom';
import { getObjFromLS, setObjInLS } from '../../../../Helpers/LocalStorage';
import PdfIcon from '../../../../Assets/General/PdfIcon';
import EmailIcon from '../../../../Assets/General/EmailIcon';
import { checkError } from '../../../../Helpers/AuthHelper';

class Packages extends Component {
  state = {
    loading: true,
    packageList: [],
    showPackageCreateModal: false,
    showReturnReceiveViewModal: false,
    pdf: false,
    showPDF: false,
    showPackageEditModal: false,
    showPackageDeleteModal: false,
    currentPackageID: null,
    showManualShippingModal: false,
    showShipmentDeleteModal: false,
    saleReturnStatus: false,
    showPrompt: false,
  };

  tableRowOptions = [
    // {
    //   label: 'View',
    //   onClick: id => !this.props.hasPermissionCustom('returnreceive','Create') ? true : this.openViewRRModal(id),
    // },
    // {
    //   label: 'Edit',
    //   onClick: id => this.handleEditPackage(id),
    //   icon: EditIcon,
    //   disabled: id => !this.props.hasPermissionCustom('returnreceive','Edit') ? true : this.state.saleReturnStatus==="closed"
    // },
    {
      label: 'Print & PDF',
      icon: PdfIcon,
      onClick: (id) => this.openReturnReceivePdf(id),
      // onClick: id =>  pdfDownlaod(`returnreceives/${id}/pdf?download=true`,this.getCurrentReturnReceiveNo(id), this.props.handleToast)
    },
    {
      label: 'Email',
      icon: EmailIcon,
      onClick: (id) => this.props.history.push(`/returnreceives/email/${id}`),
    },
    {
      label: 'Delete',
      icon: DeleteIcon,
      onClick: (id) => this.openPackageDeleteModal(id),
      // disabled: id =>  this.getCurrentPackage(id).status !== 'not shipped'
      disabled: (id) =>
        !this.props.hasPermissionCustom('returnreceive', 'Delete')
          ? true
          : false,
    },
  ];

  componentDidMount() {
    this.fetchData();
  }

  handlePrompt = (status = true) => {
    this.setState({ showPrompt: status });
  };

  fetchData() {
    this.setState({ loading: true });
    restRequest(
      'get',
      `returnreceives/viewAll?sales_return_id=${this.props.salesReturnId}`
    )
      .then((list) => {
        this.setState({
          packageList: list.sales_return.return_receives,
          loading: false,
          saleReturnStatus: list.sales_return.sales_return_status,
        });
      })
      .catch((error) => checkError(error));
  }

  getCurrentPackage = (id) => {
    // return nul
    return this.state.packageList.find((obj) => obj.id === id);
  };

  handleEditPackage(id) {
    this.getCurrentPackage(id);

    this.openPackageEditModal(id);
    return;

    // this.props.handleToast('You cannot edit shipped or delivered package', 'error')
  }

  renderManualShippingModal() {
    // const currentPackage = this.getCurrentPackage(this.state.currentPackageID)
    // const slipID = currentPackage ? currentPackage.package_no : null
    return (
      <CustomModal
        showModal={this.state.showManualShippingModal}
        title="Create Shipment"
        renderActions={false}
        onClose={this.closeAllModals}
      >
        {/* <ShipmentCreate
          packageId={this.state.currentPackageID}
          packageNo={slipID}
          onClose={this.closeAllModals}
          onSubmit={this.handlePackageActionSubmit}
          handleToast={this.props.handleToast}
        /> */}
      </CustomModal>
    );
  }

  handleManualShipping = (packageId) => {
    this.setState({
      showManualShippingModal: true,
      currentPackageID: packageId,
    });
  };

  handleDeleteShipment = () => {
    this.closeAllModals();
    restRequest('delete', `shipments/${this.state.currentPackageID}`)
      .then(() => {
        this.handlePackageActionSubmit();
        this.props.handleToast('Shipment has been deleted', 'success');
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
      });
  };

  renderShipmentDeleteModal() {
    return (
      <CustomModal
        type="delete"
        showModal={this.state.showShipmentDeleteModal}
        title="Delete Shipment"
        onClose={this.closeAllModals}
        onSubmit={this.handleDeleteShipment}
      >
        Are you sure you want to delete this shipment?
      </CustomModal>
    );
  }

  openShipmentDeleteModal = (packageId) => {
    this.setState({
      showShipmentDeleteModal: true,
      currentPackageID: packageId,
    });
  };

  markPackageAsDelivered = (packageId) => {
    restRequest('put', `shipments/${packageId}/deliver`)
      .then(() => {
        this.handlePackageActionSubmit();
        this.props.handleToast('Package has been delivered', 'success');
      })
      .catch((error) => {
        //this.props.handleToast(error, "error")
        checkError(error, this.props.handleToast);
      });
  };

  markPackageAsUndelivered = (packageId) => {
    restRequest('put', `shipments/${packageId}/undeliver`)
      .then(() => {
        this.handlePackageActionSubmit();
        this.props.handleToast('Package has been undelivered', 'success');
      })
      .catch((error) => {
        //this.props.handleToast(error, "error")
        checkError(error, this.props.handleToast);
      });
  };

  // openPdf = id => {
  //   restRequest('get', `packages/${id}/pdf`).then(res => {
  //     this.setState({
  //       pdf: res,
  //       showPDF: true
  //     })
  //   })
  // }

  closeAllModals = () => {
    this.setState({
      showPackageCreateModal: false,
      showPDF: false,
      showPackageEditModal: false,
      showPackageDeleteModal: false,
      showManualShippingModal: false,
      showShipmentDeleteModal: false,
      showReturnReceiveViewModal: false,
    });
  };

  handleDeletePackage = () => {
    this.closeAllModals();
    restRequest('delete', `returnreceives/${this.state.currentPackageID}`)
      .then((res) => {
        let dataFromLS = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLS,
          queryParam: `${
            dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
          }returnReceive=true`,
        });
        this.props.handleToast(res.message, 'success');
        this.handlePackageActionSubmit();
      })
      .catch((error) => {
        //this.props.handleToast(error, "error")
        checkError(error, this.props.handleToast);
      });
  };

  renderPackageDeleteModal() {
    return (
      <CustomModal
        type="delete"
        showModal={this.state.showPackageDeleteModal}
        title="Confirm Return Receive Delete"
        onClose={this.closeAllModals}
        onSubmit={this.handleDeletePackage}
      >
        Are you sure you want to delete this Return Receive?
      </CustomModal>
    );
  }

  openPackageDeleteModal(id) {
    this.setState({
      showPackageDeleteModal: true,
      currentPackageID: id,
    });
  }

  renderPackageEditModal() {
    return (
      <CustomModal
        showModal={this.state.showPackageEditModal}
        title="Edit Return Receive"
        renderActions={false}
        size="large"
        onClose={this.closeAllModals}
        showPrompt={this.state.showPrompt}
      >
        <ReturnReceive
          inModal
          type="edit"
          id={this.state.currentPackageID}
          salesOrderId={this.props.salesOrderID}
          history={this.props.history}
          close={this.closeAllModals}
          onSubmit={this.handlePackageActionSubmit}
          handleToast={this.props.handleToast}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  openPackageEditModal(id) {
    this.setState({
      showPackageEditModal: true,
      showPrompt: false,
      currentPackageID: id,
    });
  }
  openViewRRModal(id) {
    this.setState({
      showReturnReceiveViewModal: true,
      currentPackageID: id,
    });
  }

  handlePackageActionSubmit = () => {
    this.fetchData();
    this.props.updateSalesOrderStatus();
    this.closeAllModals();
  };

  renderReturnReceiveActionMenus() {
    const id = this.state.currentPackageID;
    const [hasCreatePermission] = this.props.hasPermissionCustom(
      'returnreceive',
      'All'
    );
    return (
      <ActionMenu>
        <Button
          // handleClick={() => this.openReturnReceivePdf(id)}
          handleClick={() =>
            pdfDownlaod(
              `returnreceives/${this.state.currentPackageID}/pdf?download=true`,
              this.getCurrentReturnReceiveNo(this.state.currentPackageID),
              this.props.handleToast
            )
          }
          type="pdf"
        />
        <Button
          handleClick={() => this.openReturnReceivePdf(id)}
          type="print"
        />
        <ButtonLink
          enable={hasCreatePermission}
          to={`/returnreceives/email/${id}`}
          type="email"
        />
        {this.props.hasPermissionCustom('returnreceive', 'Delete') && (
          <Button
            handleClick={() =>
              /*(!this.props.hasPermissionCustom('returnreceive','Delete'))*/ this.handleDeleteModal(
                id
              )
            }
            type="delete"
          />
        )}
      </ActionMenu>
    );
  }

  handleDeleteModal = (id) => {
    this.openPackageDeleteModal(id);
  };

  openReturnReceivePdf = (id) => {
    this.setState({ showPDF: true });
    restRequest('get', `returnreceives/${id}/pdf`)
      .then((res) => {
        this.setState({ pdf: res });
      })
      .catch((error) => checkError(error));
  };

  renderPackageCreateModal() {
    return (
      <CustomModal
        showModal={this.state.showPackageCreateModal}
        title="Create Return Receive"
        className='popup-height-max'
        Icon={AddRetrunReceive}
        fill="#000000"
        renderActions={false}
        size="large"
        onClose={this.closeAllModals}
        showPrompt={this.state.showPrompt}
        mainContainerClass="dragDropPopupContainer"
      >
        <ReturnReceive
          inModal
          type="add"
          history={this.props.history}
          salesReturnId={this.props.salesReturnId}
          close={this.closeAllModals}
          onSubmit={this.handlePackageActionSubmit}
          handleToast={this.props.handleToast}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  renderReturnReceiveModal() {
    return (
      <CustomModal
        showModal={this.state.showReturnReceiveViewModal}
        title={
          'Return Receive | ' +
          this.getCurrentReturnReceiveNo(this.state.currentPackageID)
        }
        Icon={AddRetrunReceive}
        renderActions={false}
        size="medium"
        onClose={this.closeAllModals}
        renderReturnReceiveActionMenu={this.renderReturnReceiveActionMenus()}
        showActionInMenu={true} // falsse for make print pdf mail and delete option enable
        classNames="return-receive-popup"
      >
        <ReturnReceiveDetails
          inModal
          type="add"
          id={this.state.currentPackageID}
          salesOrderId={this.props.salesOrderID}
          close={this.closeAllModals}
          onSubmit={this.handlePackageActionSubmit}
          handleToast={this.props.handleToast}
        />
      </CustomModal>
    );
  }

  getCurrentReturnReceiveNo = (id) => {
    // return nul
    var name = '';
    this.state.packageList.forEach((obj) => {
      if (obj.id === id) {
        name = obj.return_receives_no;
        return;
      }
    });
    return name;
  };

  async createNewPackage() {
    const { handleToast } = this.props; //packageStatus, dropshipStatus, salesOrderID,
    // check for this case: package can be created or not

    try {
      await restRequest(
        'get',
        `returnreceives/create?sales_return_id=${this.props.salesReturnId}`
      );
    } catch (error) {
      checkError(error, handleToast);
      //handleToast(error, 'error')
      return;
    }

    this.setState({ showPackageCreateModal: true, showPrompt: false });
  }

  createReturnReceive() {
    this.props.history.push(`salesreturns/${this.props.salesReturnId}`);
  }

  preparePackageList(packages) {
    return packages.map((packageSlip) => {
      return {
        ...packageSlip,
        packageNo: this.props.inModal ? (
          `${packageSlip.return_receives_no}`
        ) : (
          <ListTableColumnJoin
            column1={
              <div
                className="payment_view"
                onClick={() => {
                  this.props.hasViewPermission &&
                    this.openViewRRModal(packageSlip.id);
                }}
                to={`/packages/${packageSlip.id}`}
                label={packageSlip.package_no}
                status={packageSlip.status}
              >
                {packageSlip.return_receives_no}
              </div>
            }
            // to={`/packages/${packageSlip.id}`} label={packageSlip.package_no} status={packageSlip.status}
          />
        ),
        packageDate: getDate(packageSlip.return_receive_date),
      };
    });
  }

  isPackageCreatable(packageStatus, orderStatus, dropshipStatus) {
    if (
      packageStatus === 'packed' ||
      ['onhold', 'void'].includes(orderStatus) ||
      dropshipStatus === 'dropshipped'
    )
      return false;

    return true;
  }

  renderPackagesListTable() {
    // const { packageStatus, salesOrderStatus, dropshipStatus } = this.props
    const tableListData = this.preparePackageList(this.state.packageList);
    const tableHeaders = ['Date', 'Return Receive No.'];
    const tableData = [
      'packageDate',
      'packageNo',
      this.props.inModal ? '' : 'options',
    ];
    return (
      <Fragment>
        <Table
          list={tableListData}
          tableHeaders={tableHeaders}
          tableData={tableData}
          fromTab={true}
          options={this.props.inModal ? [] : this.tableRowOptions}
          // linkTo={{ path: '/packages/', linkedColumn: 'return_receives_no' }}
          className={`box-table ${
            this.props.label ? this.props.label + '_menu' : ''
          }`}
          //RowIcon={SalesReturnReceiveIcon}
        />
        {this.props.hasPermissionCustom('returnreceive', 'Create') &&
          this.state.saleReturnStatus !== 'closed' &&
          // this.props.inModal ? "" : <button className="layout-box--button" onClick={() => this.props.inModal ? this.createReturnReceive(): this.createNewPackage()}>
          (this.props.inModal ? (
            ''
          ) : (
            <button
              className="layout-box--button btn-width"
              onClick={() => this.createNewPackage()}
            >
              <MdAdd className=" black-fill" />
              Add Return Receive
            </button>
          ))}
      </Fragment>
    );
  }

  renderModals() {
    return (
      <Fragment>
        <PDFViewer
          showPDF={this.state.showPDF}
          hidePDFModal={this.closeAllModals}
          pdf={this.state.pdf}
        />
        {this.renderManualShippingModal()}
        {this.renderShipmentDeleteModal()}
        {this.renderPackageCreateModal()}
        {this.renderPackageEditModal()}
        {this.renderReturnReceiveModal()}
        {this.renderPackageDeleteModal()}
      </Fragment>
    );
  }

  renderPackagesList() {
    const { salesOrderStatus } = this.props; //, dropshipStatus, packageStatus,
    return (
      <Fragment>
        {this.renderModals()}
        {!this.state.packageList.length ? (
          <NoItemsBox
            subtitle={
              salesOrderStatus === 'draft'
                ? 'Return receive not applicable yet!'
                : 'No return receive created yet.'
            }
            buttonText="Add Return Receive"
            showButton={
              this.props.hasPermissionCustom('returnreceive', 'Create') &&
              salesOrderStatus !== 'void' &&
              salesOrderStatus !== 'draft'
            }
            handleclick={() => this.createNewPackage()}
            // handleclick={() => this.props.inModal ? this.createReturnReceive(): this.createNewPackage()}
            Icon={AddRetrunReceive}
            inModal={this.props.inModal}
          />
        ) : (
          this.renderPackagesListTable()
        )}
      </Fragment>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return this.renderPackagesList();
  }
}

export default withRouter(Packages);
