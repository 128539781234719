import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { restRequest } from '../../../Helpers/RequestHelper';

import OverlayLoader from '../../../Components/Loader/OverlayLoader';
import Form from '../../../Components/Form/Form';
import Field from '../../../Components/Form/Field/Field';
import FormAction from '../../../Components/Form/FormAction';
import {
  getObjFromLS,
  // setObjInLS
} from '../../../Helpers/LocalStorage';

import './PurchaseOrderPreference.css';
import { checkError } from '../../../Helpers/AuthHelper';
import { isEqual } from 'lodash';

class PurchaseOrderPreference extends Component {
  state = {
    loading: true,
    showPrompt: false,
    staticVal: {},
    purchasePref: {
      bill: false,
      receive: false,
      both: false,
    },
    terms: '',
    isSubmitting: false,
  };

  componentDidMount() {
    localStorage.setItem('alert', false);
    this.fetchData();
    window.onbeforeunload = function () {
      return true;
    };
  }
  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { terms, purchasePref, staticVal } = this.state;
    const data = { ...purchasePref, terms };
    if (prevState.showPrompt !== this.state.showPrompt) {
      const checkEquality = Boolean(isEqual(data, staticVal));
      if (!checkEquality) localStorage.setItem('alert', true);
      else localStorage.setItem('alert', false);
    }
  }

  handlePrompt = () => {
    this.setState({
      showPrompt: !this.state.showPrompt,
    });
  };

  async fetchData() {
    this.setState({ loading: true });
    await restRequest('get', 'preferences/purchaseorder')
      .then((res) => {
        if (res.preferences.bill && res.preferences.receive) {
          this.setState({
            purchasePref: {
              bill: false,
              receive: false,
              both: true,
            },
            terms: res.terms,
            staticVal: {
              bill: false,
              receive: false,
              both: true,
              terms: res.terms,
            },
          });
        } else {
          this.setState({
            purchasePref: {
              bill: res.preferences.bill,
              receive: res.preferences.receive,
              both: false,
            },
            terms: res.terms,
            staticVal: {
              bill: res.preferences.bill,
              receive: res.preferences.receive,
              both: false,
              terms: res.terms,
            },
          });
        }
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  }

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
    this.handlePrompt();
  };

  updateOptions(obj, option) {
    let updatedObj = obj;
    for (let key in updatedObj) {
      if (key === option) updatedObj[key] = true;
      else updatedObj[key] = false;
    }
    return updatedObj;
  }

  handleRadioChange = (event) => {
    this.handlePrompt();
    let stateName = event.target.name;
    let option = event.target.id;
    let updatedOptions = this.updateOptions(this.state[stateName], option);
    this.setState((state) => ({
      [stateName]: {
        ...state[stateName],
        ...updatedOptions,
      },
    }));
  };
  handleRadioClick(radioOption, radiostateName) {
    this.handlePrompt();
    let stateName = radiostateName;
    let option = radioOption;
    let updatedOptions = this.updateOptions(this.state[stateName], option);
    this.setState((state) => ({
      [stateName]: {
        ...state[stateName],
        ...updatedOptions,
      },
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let { bill, receive, both } = this.state.purchasePref;
    this.setState({ isSubmitting: true });
    restRequest('put', 'preferences/purchaseorder', {
      status: {
        bill: bill || both,
        receive: receive || both,
      },
      terms: this.state.terms,
    })
      .then(() => {
        this.setState({ isSubmitting: false });
        this.props.handleToast(
          'Purchase Order preference updated successfully',
          'success'
        );
        this.props.setPrompt(false);
        this.fetchData();
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });
        checkError(error);
        this.props.handleToast(error, 'error');
      });
  };

  purchaseOrderStatus() {
    let { purchasePref } = this.state;
    return (
      <div className="float-left w-100">
        <h4 className="mb_5 float-left w-100 mt_0">
          When do you want your Purchase Orders to be closed?{' '}
        </h4>

        <div className="preference-label radio-btn float-left w-100">
          <input
            type="radio"
            checked={purchasePref.bill}
            name="purchasePref"
            onChange={(event) => this.handleRadioChange(event)}
            id="bill"
          />
          <label htmlFor="bill">When Purchase Order is fully Billed</label>
          <span
            onClick={() => this.handleRadioClick('bill', 'purchasePref')}
            className="radio-circle"
          />
        </div>

        <div className="preference-label radio-btn float-left w-100">
          <input
            type="radio"
            name="purchasePref"
            checked={purchasePref.receive}
            onChange={(event) => this.handleRadioChange(event)}
            id="receive"
          />
          <label htmlFor="receive">When Purchase Order is fully Received</label>
          <span
            onClick={() => this.handleRadioClick('receive', 'purchasePref')}
            className="radio-circle"
          />
        </div>

        <div className="preference-label radio-btn float-left w-100">
          <input
            type="radio"
            checked={purchasePref.both}
            onChange={(event) => this.handleRadioChange(event)}
            name="purchasePref"
            id="both"
          />
          <label htmlFor="both">
            When Purchase Order is both fully Received and Billed
          </label>
          <span
            onClick={() => this.handleRadioClick('both', 'purchasePref')}
            className="radio-circle"
          />
        </div>
      </div>
    );
  }

  purchaseOrderTermsContent() {
    return (
      <div className="float-left w-100">
        <Field
          name="Terms & Conditions"
          id="terms"
          type="textarea"
          value={this.state.terms}
          placeholder="Terms & Conditions"
          handleChange={this.handleChange}
        />
      </div>
    );
  }

  renderActionButtons() {
    // return (
    //   <FormAction disabled={this.state.isSubmitting} onCancel={() => this.props.history.push('/settings')} />
    // )
    let permissions = getObjFromLS('role').permissions;
    if (!permissions.superAccess) {
      if (permissions.PreferenceEdit) {
        return (
          <FormAction
            disabled={this.state.isSubmitting}
            onCancel={() => this.props.history.push('/settings')}
          />
        );
      }
    }
    if (permissions.superAccess) {
      return (
        <FormAction
          disabled={this.state.isSubmitting}
          onCancel={() => this.props.history.push('/settings')}
        />
      );
    }
  }

  renderPurchaseOrderPreference() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <div className="po-pref-component float-left w-100">
          <div className="float-left w-70 genral_tab_content general_tabs_set">
            {this.state.loading && <OverlayLoader />}
            <div>{this.purchaseOrderStatus()}</div>
            <hr className="pref--hr float-left w-100" />
            <div>{this.purchaseOrderTermsContent()}</div>
            {this.renderActionButtons()}
          </div>
        </div>
      </Form>
    );
  }

  render() {
    return this.renderPurchaseOrderPreference();
  }
}

export default withRouter(PurchaseOrderPreference);
