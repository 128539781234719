import React from 'react';

import Import from '../../Components/Import/Import';
import FormLayout from '../../Components/Layout/FormLayout';

import { NewContactIcon } from '../../Assets/Navigation/NavigationIcons';

const ContactImport = (props) => (
    <FormLayout
        title="Contacts"
        Icon={NewContactIcon}
        subtitle="Import"
        iconstyle="contact_general_module_icon"
    >
        <Import
            {...props}
            name="Contacts"
            fetchHeadersAPI="contacts/importheader"
            fetchSummaryAPI="contacts/importpreview"
            importAPI="contacts/import"
            sampleFileLink={`${process.env.REACT_APP_AMAZON_S3}/sample-files/contacts-sample-file.csv`}
        />
    </FormLayout>
);

export default ContactImport;
