import React from 'react'

export default ({
  className = 'checked-icon'
}) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.5795 8.00455L9.77283 13.0046C9.61616 13.2104 9.37366 13.3321 9.11533 13.3337H9.10949C8.85366 13.3337 8.61199 13.2154 8.45366 13.0137L6.42699 10.4246C6.14366 10.0629 6.20699 9.53872 6.56949 9.25539C6.93116 8.97122 7.45616 9.03455 7.73949 9.39789L9.10033 11.1362L12.2537 6.99539C12.532 6.62955 13.0545 6.55789 13.422 6.83705C13.7878 7.11622 13.8587 7.63872 13.5795 8.00455ZM10.0003 1.66705C5.39783 1.66705 1.66699 5.39789 1.66699 10.0004C1.66699 14.6021 5.39783 18.3337 10.0003 18.3337C14.6028 18.3337 18.3337 14.6021 18.3337 10.0004C18.3337 5.39789 14.6028 1.66705 10.0003 1.66705Z" fill="#231F20" />
    <mask id="maskCI" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="1" width="18" height="18">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.5795 8.00455L9.77283 13.0046C9.61616 13.2104 9.37366 13.3321 9.11533 13.3337H9.10949C8.85366 13.3337 8.61199 13.2154 8.45366 13.0137L6.42699 10.4246C6.14366 10.0629 6.20699 9.53872 6.56949 9.25539C6.93116 8.97122 7.45616 9.03455 7.73949 9.39789L9.10033 11.1362L12.2537 6.99539C12.532 6.62955 13.0545 6.55789 13.422 6.83705C13.7878 7.11622 13.8587 7.63872 13.5795 8.00455ZM10.0003 1.66705C5.39783 1.66705 1.66699 5.39789 1.66699 10.0004C1.66699 14.6021 5.39783 18.3337 10.0003 18.3337C14.6028 18.3337 18.3337 14.6021 18.3337 10.0004C18.3337 5.39789 14.6028 1.66705 10.0003 1.66705Z" fill="white" />
    </mask>
    <g mask="url(#maskCI)">
      <rect width="20" height="20" fill="#6FB879" />
    </g>
  </svg>
)