import React, { useState, useCallback } from 'react';
import getCroppedImg from './CroppedImages';
import Cropper from 'react-easy-crop';
import './ImageCropper.css';
import FormAction from '../../Components/Form/FormAction';
export default function ImageCropper(props) {
  // let skip = false;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [rotation, setRotation] = useState(0);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  function handleSkipCrop({ skip }) {
    // skip = true;
    showCroppedImage(skip);
  }
  const showCroppedImage = useCallback(
    async (skip = false) => {
      try {
        const croppedImage = await getCroppedImg(
          props.updatedFiles[props.updatedFiles.length - 1].preview,
          croppedAreaPixels,
          rotation
        );
        if (!skip) {
          setCroppedImage(croppedImage);
          props.onSubmitModal(props.updatedFiles, croppedImage);
        } else {
          var reader = new FileReader();
          reader.readAsDataURL(
            props.updatedFiles[props.updatedFiles.length - 1]
          );
          reader.onload = function () {
            props.onSubmitModal(props.updatedFiles, reader.result);
          };
          reader.onerror = function (error) {
            console.log('Error: ', error);
          };
        }
      } catch (e) {
        console.error(e);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [croppedAreaPixels, rotation]
  );
  return (
    <div className="image_modal_popup ">
      <div className="position-relative parent_container">
        <div className="crop-container">
          <Cropper
            image={props.updatedFiles[props.updatedFiles.length - 1].preview}
            crop={crop}
            zoom={zoom}
            aspect={4 / 4}
            rotation={rotation}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
      </div>
      <div className="controls">
        <div className="float-left w-50 justify_content_end">
          <label className="zoom_image">Zoom</label>
          <input
            id="typeinp"
            type="range"
            min="1"
            max="3"
            step="0.01"
            value={zoom}
            onChange={(e) => {
              setZoom(e.target.value);
            }}
          />
        </div>
        <div className="float-left w-50 position_relative">
          <label className="rotate_image">Rotate</label>
          <input
            id="typeinp"
            type="range"
            min="0"
            max="360"
            value={rotation}
            step="1"
            onChange={(e) => {
              setRotation(e.target.value);
            }}
          />
        </div>
      </div>
      <FormAction
        disabled={props.alreadyUploadInprogress}
        onSubmit={() => showCroppedImage()}
        onCancel={() => props.closeModal()}
        submitLabel="Save"
        marginFromRight={0}
        showSaveAndSendButton={true}
        saveAndSendField={{
          label: 'Save Original',
          onClick: (event) => handleSkipCrop({ skip: true }),
          disabled: props.alreadyUploadInprogress,
        }}
      />
      {!props.inModal && <img src={croppedImage} alt="cropped pic" />}
    </div>
  );
}
