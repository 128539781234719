import React from 'react'

export default ({
  className = ''
}) => (

<svg className={className} xmlns="http://www.w3.org/2000/svg" width="25.006" height="20.003" viewBox="0 0 25.006 20.003">
  <g id="Group_803" data-name="Group 803" transform="translate(-55.497 -10)">
    <path id="Icon_awesome-hands-helping" data-name="Icon awesome-hands-helping" d="M19.066,7.5H13.127V9.69a2.813,2.813,0,1,1-5.626,0V4.939L4.965,6.463A2.5,2.5,0,0,0,3.75,8.6v1.848L.624,12.257a1.247,1.247,0,0,0-.457,1.707L3.293,19.38A1.251,1.251,0,0,0,5,19.837L9.04,17.5h5.337a2.5,2.5,0,0,0,2.5-2.5H17.5a1.249,1.249,0,0,0,1.25-1.25v-2.5h.313A.935.935,0,0,0,20,10.316V8.44A.935.935,0,0,0,19.066,7.5Zm5.771-1.461L21.711.626A1.251,1.251,0,0,0,20,.169L15.964,2.5H11.971a2.519,2.519,0,0,0-1.325.379L9.337,3.7a1.242,1.242,0,0,0-.586,1.059V9.69a1.563,1.563,0,1,0,3.126,0V6.252h7.189A2.189,2.189,0,0,1,21.254,8.44V9.554L24.38,7.749a1.252,1.252,0,0,0,.457-1.707Z" transform="translate(55.499 9.998)" fill="#306ab4"/>
  </g>
</svg>
  )
