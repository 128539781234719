import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { restRequest } from '../../../Helpers/RequestHelper';

import OverlayLoader from '../../../Components/Loader/OverlayLoader';
import Form from '../../../Components/Form/Form';
import Field from '../../../Components/Form/Field/Field';
import { Checked } from '../../../Assets/List/ListIcons';
import FormAction from '../../../Components/Form/FormAction';
import { getObjFromLS } from '../../../Helpers/LocalStorage'; //, setObjInLS

import './BillPreference.css';
import { checkError } from '../../../Helpers/AuthHelper';
import { isEqual } from 'lodash';

class BillPreference extends Component {
  state = {
    loading: true,
    billPref: {
      displayStatus: false,
    },
    terms: '',
    isSubmitting: false,
    staticVal: {},
  };

  componentDidMount() {
    localStorage.setItem('alert', false);
    this.fetchData();
    window.onbeforeunload = function () {
      return true;
    };
  }
  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { staticVal } = this.state;
    const { terms } = staticVal;
    if (prevState.terms !== this.state.terms) {
      const checkEquality = Boolean(isEqual(this.state.terms, terms));
      if (!checkEquality) localStorage.setItem('alert', true);
      else localStorage.setItem('alert', false);
    }
  }

  async fetchData() {
    this.setState({ loading: true });
    await restRequest('get', 'preferences/bill')
      .then((res) => {
        this.setState({
          //billPref: {displayStatus: res.preferences.display_bill_status,},
          terms: res.terms,
          staticVal: {
            terms: res.terms,
          },
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    //let { displayStatus } = this.state.billPref
    let payload = {
      //status: {display_bill_status: displayStatus},
      terms: this.state.terms,
    };
    //console.log('payload', payload)
    this.setState({ isSubmitting: true });
    restRequest('put', 'preferences/bill', payload)
      .then(() => {
        this.setState({ isSubmitting: false });
        this.props.handleToast(
          'bill preference updated successfully',
          'success'
        );
        this.props.setPrompt(false);
        this.fetchData();
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });
        checkError(error);
        this.props.handleToast(error, 'error');
      });
  };

  togglebillDisplayStatus() {
    this.setState((state) => ({
      billPref: {
        ...state.billPref,
        displayStatus: !state.billPref.displayStatus,
      },
    }));
  }

  renderActionButtons() {
    // return (
    //   <FormAction disabled={this.state.isSubmitting} onCancel={() => this.props.history.push('/settings')} />
    // )
    let permissions = getObjFromLS('role').permissions;
    if (!permissions.superAccess) {
      if (permissions.PreferenceEdit) {
        return (
          <FormAction
            disabled={this.state.isSubmitting || !this.state.terms}
            onCancel={() => this.props.history.push('/settings')}
          />
        );
      }
    }
    if (permissions.superAccess) {
      return (
        <FormAction
          disabled={this.state.isSubmitting || !this.state.terms}
          onCancel={() => this.props.history.push('/settings')}
        />
      );
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  billTermsContent() {
    return (
      <div className="float-left w-100">
        <Field
          name="Terms & Conditions"
          id="terms"
          type="textarea"
          value={this.state.terms}
          placeholder="Terms & Conditions"
          handleChange={this.handleChange}
        />
      </div>
    );
  }

  billStampContent() {
    return (
      <label className="list-table--checkbox-lable pointer">
        <input
          className="float-left"
          checked={this.state.billPref.displayStatus}
          type="checkbox"
          onChange={() => this.togglebillDisplayStatus()}
        />
        <span className="float-left">
          <Checked />
        </span>
        <div className="bill-preference--label-text float-left">
          Display bill status stamp?
        </div>
      </label>
    );
  }

  renderStatusChangeInput() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <div className="bill-preference float-left w-100">
          <div className="float-left w-70 genral_tab_content general_tabs_set">
            {this.state.loading && <OverlayLoader />}
            {/*this.billStampContent()*/}
            {this.billTermsContent()}
            {this.renderActionButtons()}
          </div>
        </div>
      </Form>
    );
  }

  render() {
    return this.renderStatusChangeInput();
  }
}

export default withRouter(BillPreference);
