import React, { Component } from 'react';
import PackagesPlanForm from '../../Components/Form/PackagesPlanForm/PackagesPlanForm';
import FormLayout from '../../Components/Layout/FormLayout';
import { restRequest } from '../../Helpers/RequestHelper';
import {checkError, setDeploymentTime} from '../../Helpers/AuthHelper';

class PackagesPlan extends Component {
  componentDidMount() {
    document.title = 'Package Plans';
    this.fetchDeployment();
  }

  // renderTaxForm() {
  //   return (

  //   )
  // }

  async fetchDeployment() {
    await restRequest('get', `deploymentTime`)
      .then((res) => {
        setDeploymentTime(res);
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <FormLayout title="Package Plans" closeUrl="/dashboard">
        <PackagesPlanForm
          // title="Package Plans"
          type="add"
          handleToast={this.props.handleToast}
        />
      </FormLayout>
    );
  }
}

export default PackagesPlan;
