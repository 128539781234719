import React from 'react';
import proptypes from 'prop-types';
import './SubNavMenu.css';
// import { setValueInLS } from "../../Helpers/LocalStorage"
import { MdAdd } from '../../Common/Icons';
//import MdAdd from 'react-icons/lib/md/add';
import { confirmationAlert } from '../../Helpers';

const Tabs = ({
  tabs,
  currentTab,
  // history,
  showInvitedUsers = () => null,
  showUserRolesUsersAdd = () => null,
  isInventoryAccounts,
  showAccountGroupCreateModal = () => null,
  showAccountNameCreateModal = () => null,
  permission = true,
  confirmFlagForInviteForm = false,
  confirmFlagForRoleForm = false,
  handleConfirmFormInviteForm = () => null,
  handleConfirmForRoleForm = () => null,
}) => {
  function isCurrent(id) {
    return id === currentTab && 'active';
  }

  function renderTabContent() {
    let index = tabs.findIndex((tab) => tab.id === currentTab);
    return tabs[index].renderContent();
  }

  var border = isInventoryAccounts === true ? '' : 'border_blue';

  function run(i) {
    if (isInventoryAccounts === true) {
      if (i === 0) {
        showAccountGroupCreateModal();
      }
      if (i === 1) {
        showAccountNameCreateModal();
      }
    } else {
      if (i === 2) {
        let response = true;
        if (confirmFlagForRoleForm) {
          response = confirmationAlert();
        }
        if (response) {
          handleConfirmForRoleForm(false);
          showInvitedUsers();
        }
      }
      if (i === 0) {
        let response = true;
        if (confirmFlagForInviteForm) {
          response = confirmationAlert();
        }
        if (response) {
          handleConfirmFormInviteForm(false);
          showUserRolesUsersAdd();
        }
      }
    }
  }
  return (
    <div
      className={`navmenu-component--navmenu float-left w-100 ${border} invenoty_Tabs`}
    >
      <div className="navmenu-component--buttons">
        <ul className="nav_menus">
          {tabs.map((tab, index) => {
            return (
              <div
                key={index}
                className={`float-left w-100 position-relative parent_tab ${
                  isCurrent(tab.id) ? 'PARENT_ACTIVE ' : ''
                }`}
              >
                <li
                  key={index}
                  className={`${isCurrent(tab.id)}`}
                  onClick={() => tab.onClick()}
                >
                  <a>{tab.label}</a>
                </li>
                {(isInventoryAccounts || index !== 1) && (
                  <MdAdd
                    className={
                      permission === true ? 'material-icon' : 'hide-add-icon'
                    }
                    onClick={() => run(index)}
                  />
                )}
              </div>
            );
          })}
        </ul>
      </div>
      <div className="navmenu-component--content inventory_tabs_Table">
        {renderTabContent()}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  tabs: proptypes.arrayOf(proptypes.object).isRequired,
  currentTab: proptypes.string.isRequired,
};

export default Tabs;
