import React, { Component } from 'react';
import queryString from 'query-string';

import { NewItemAdjustmentIcon } from '../../../Assets/Navigation/NavigationIcons';
import { restRequest } from '../../../Helpers/RequestHelper';
import { routesPath } from '../../Navigation/NavigationUtils';
import ItemAdjustmentImage from '../../../Components/Onboarding/Diagrams/ItemAdjustmentImage';
import ItemAdjustmentFlowDiagram from '../../../Components/Onboarding/Diagrams/ItemAdjustmentFlowDiagram';

import HeaderWrapper from '../../../Components/HeaderWrapper/HeaderWrapper';
import Alert from '../../../Components/Alert/Alert';
import ListTable from '../../../Components/ListTable/ListTable';
import CheckedMenu, {
  Button,
} from '../../../Components/CheckedMenu/CheckedMenu';
import SearchResult from '../../../Components/SearchBar/SearchResult';
// import Onboarding from '../../../Components/Onboarding/Onboarding'   //old
import Onboarding from '../../../Components/Onboarding/InitialOnboardingScreen';
import {
  removeValueFromLS,
  getObjFromLS,
  setObjInLS,
  getStringValueFromLS,
} from '../../../Helpers/LocalStorage';
import AddAdjustments from '../../../Pages/Items/Adjustments/AddAdjustments';
import ItemAdjustmentDetails from '../../../Pages/Items/Adjustments/AdjustmentsDetails';
import DeleteIcon from '../../../Assets/General/DeleteIcon';
import { checkError } from '../../../Helpers/AuthHelper';
import AccessDenied from '../../../Components/AccessDenied/AccessDenied';
import './ItemAdjustment.css';

class ItemsAdjustment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataExpanded: [],
      currentItemId: null,
      currentItem: {},
      currentItemLoaded: false,
      currentItemImage: null,
      loading: true,
      itemAdjustmentId: null,
      totalPage: 1,
      currentPage: 1,
      viewPerPage: 20,
      totalRecords: 20,

      sortFilter: 'name',
      orderBy: 'asc',
      search: '',
      accessDenied: false,
    };
    this.getID = this.getID.bind(this);
  }

  sortOptions = [
    {
      id: 'adjustment_date',
      label: 'Adjustment Date',
      type: 'link',
      onClick: () =>
        this.generateRoute({
          ...this.prepareSortFilter('adjustment_date'),
        }),
    },
    {
      id: 'reason',
      label: 'Reason',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('reason') }),
    },
    {
      id: 'created_at',
      label: 'Created Time',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('created_at') }),
    },
    /*{
      id: 'updated_at',
      label: 'Last Modified Time',
      type: 'link',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('updated_at') })
    }*/
  ];

  componentDidMount() {
    document.title = 'View Adjustments';
    this.fetchDataWithQueryString();
  }

  componentDidUpdate() {
    this.handleOnUpdateQueryString();
  }

  handleOnUpdateQueryString() {
    const { page, sort, orderBy, view, search } = this.parseQueryString();
    if (sort !== this.state.sortFilter) {
      this.setState(
        {
          sortFilter: sort,
        },
        () => this.fetchData()
      );
    }
    if (orderBy !== this.state.orderBy) {
      this.setState(
        {
          orderBy: orderBy,
        },
        () => this.fetchData()
      );
    }
    if (page !== this.state.currentPage) {
      this.setState(
        {
          currentPage: parseInt(page),
        },
        () => this.fetchData()
      );
    }
    if (view !== this.state.viewPerPage) {
      this.setState(
        {
          viewPerPage: parseInt(view),
        },
        () => this.fetchData()
      );
    }
    if (search !== this.state.search) {
      this.setState({ search: search, bulkSelectedData: [] }, () =>
        this.fetchData()
      );
    }
  }

  fetchDataWithQueryString() {
    const { page, sort, orderBy, view, search } = this.parseQueryString();
    this.setState(
      {
        sortFilter: sort,
        currentPage: parseInt(page),
        orderBy: orderBy,
        viewPerPage: parseInt(view),
        search: search,
      },
      () => this.fetchData()
    );
  }

  parseQueryString() {
    const query = queryString.parse(this.props.location.search);
    let filter = '';
    let sort = '';
    switch (query.sort) {
      case 'adjustment_date':
      case 'reason':
      case 'created_at':
      case 'updated_at':
        sort = query.sort;
        break;
      default:
        sort = 'created_at';
    }

    let orderBy = '';
    switch (query.orderBy) {
      case 'asc':
      case 'desc':
        orderBy = query.orderBy;
        break;
      default:
        orderBy = 'desc';
    }

    let page = parseInt(query.page);
    if (!page) page = 1;
    let view = parseInt(query.view);
    if (!view) view = 20;

    let search = query.search;
    if (!search) search = '';

    return {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
    };
  }

  fetchData() {
    this.setState({ loading: true });
    restRequest(
      'get',
      `itemadjustments?page=${this.state.currentPage}&sort_by=${this.state.sortFilter}&order_by=${this.state.orderBy}&view=${this.state.viewPerPage}&search=${this.state.search}`
    )
      .then((res) => {
        let obj = {
          prevId: null,
          id: res.data[0] && res.data[0].id,
          nextId: res.data[1] && res.data[1].id,
          moduleName: 'item-adjustments',
          urlPath: window.location.pathname,
          queryParam: window.location.search,
        };
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS && dataFromLS.moduleName !== 'item-adjustments') {
          sessionStorage.setItem('fullscreen', true);
          sessionStorage.setItem('once', true);
          removeValueFromLS('module');
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
        }
        if (!res.data[0]) {
          removeValueFromLS('module');
        }
        if (!dataFromLS || dataFromLS.action === 'add') {
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
        } else if (dataFromLS.action === 'bulkdelete') {
          if (res.data[0]) {
            setObjInLS('module', { ...obj, lolo: 'no' });
          }
        } else if (
          dataFromLS &&
          dataFromLS.moduleName === 'item-adjustments' &&
          dataFromLS.queryParam !== window.location.search
        ) {
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
        }
        if (!res.data?.length) {
          sessionStorage.setItem('fullscreen', true);
          setObjInLS('module', obj);
        }
        this.setState(
          {
            dataExpanded: this.prepareDataForTable(res.data),
            data: this.prepareDataForTableCollapsed(res.data),
            totalPage: res.last_page,
            totalRecords: res.total,
          },
          () => {
            this.setState({ loading: false, mainLoading: false });
          }
        );
      })
      .catch((error) => {
        this.setState({
          accessDenied: checkError(error, this.props.handleToast),
        });
      });
  }

  prepareDataForTable(data) {
    return data.map((adjustment) => ({
      id: adjustment.id,
      Warehouse: {
        id: 'name',
        getValue: function () {
          return adjustment.warehouse[this.id];
        },
      },
      Date: {
        id: 'adjustment_date',
        sortable: true,
        getValue: function () {
          return adjustment[this.id];
        },
      },
      Reason: {
        id: 'reason',
        sortable: true,
        getValue: function () {
          return adjustment[this.id];
          // let str= adjustment[this.id]
          // if(str.length > 40){
          //   str = str.split('')
          //   let resultStr=[]
          //   str.map((s,i)=>{
          //     if(i < 38){
          //       resultStr.push(s)
          //     }
          //   })
          //   resultStr[38]='.'
          //   resultStr[39]='.'
          //   resultStr[40]='.'
          //   return resultStr.join('')
          // }else return str
        },
      },
      /*'Description': {
        id: 'description',
        getValue: function () { return adjustment[this.id] }
      },*/

      // 'Purchase Account': {
      //   id: 'title',
      //   getValue: function () { return adjustment.account[this.id] }
      // },
      Type: {
        id: 'type',
        getValue: function () {
          return (
            adjustment[this.id] &&
            adjustment[this.id].charAt(0).toUpperCase() +
              adjustment[this.id].substr(1)
          );
        },
      },
      'Adjusted By': {
        id: 'name',
        getValue: function () {
          return adjustment.item_adjustment_created_by[this.id];
        },
      },
      additional: {},
    }));
  }
  // const getShortString = (str)=> {

  // }

  prepareDataForTableCollapsed(data) {
    return data.map((adjustment) => ({
      id: adjustment.id,

      // 'Reason': {
      //   id: 'reason',
      //   sortable: true,
      //   getValue: function () { return adjustment[this.id] }
      // },
      Warehouse: {
        id: 'name',
        getValue: function () {
          return adjustment.warehouse[this.id];
        },
      },
      Date: {
        id: 'adjustment_date',
        sortable: true,
        getValue: function () {
          return adjustment[this.id];
        },
      },
      /*'Description': {
        id: 'description',
        getValue: function () { return adjustment[this.id] }
      },*/
      // 'Warehouse': {
      //   id: 'name',
      //   getValue: function () { return adjustment.warehouse[this.id] }
      // },
      // 'Purchase Account': {
      //   id: 'title',
      //   getValue: function () { return adjustment.account[this.id] }
      // },
      // 'Adjustment Type': {
      //   id: 'type',
      //   getValue: function () { return adjustment[this.id] }
      // },
      // 'Adjusted By': {
      //   id: 'name',
      //   getValue: function () { return adjustment.item_adjustment_created_by[this.id] }
      // },
      additional: {},
    }));
  }

  // render action menu for single selected data
  renderSingleActionMenu = () => {
    const checkedId = this.state.bulkSelectedData[0];
    const hasDeletePermission = this.hasPermission('itemadjustment', 'Delete');
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <Button
          enable={hasDeletePermission}
          handleClick={() => this.openDeleteModal(checkedId)}
          type="delete"
        />
      </CheckedMenu>
    );
  };

  // render action menu for multiple selected data
  renderMultipleActionMenu = () => {
    const hasDeletePermission = this.hasPermission('itemadjustment', 'Delete');
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <Button
          enable={hasDeletePermission}
          handleClick={this.openBulkDeleteModal}
          type="delete"
        />
      </CheckedMenu>
    );
  };

  getID(prevId, id, nextId) {
    let obj = {
      id,
      prevId: prevId ? prevId : null,
      nextId: nextId ? nextId : null,
      moduleName: 'item-adjustments',
      urlPath: window.location.pathname,
      queryParam: window.location.search,
    };
    setObjInLS('module', obj);
    this.setState({ itemAdjustmentId: id });
    // setValueInLS('id',id)
  }

  prepareRowOptions() {
    const rowOptions = [
      {
        label: 'Delete',
        icon: DeleteIcon,
        onClick: this.openDeleteModal,
        disabled: () => !this.hasPermission('itemadjustment', 'Delete'),
      },
    ];

    return rowOptions;
  }

  renderTable() {
    const {
      // loading,
      mainLoading,
      data,
      dataExpanded,
      bulkSelectedData,
      orderBy,
      sortFilter,
      accessDenied,
    } = this.state;
    if (!mainLoading) {
      if (this.checkIfOnboarding()) {
        return (
          // <Onboarding
          //   Image={ItemAdjustmentImage}
          //   FlowDiagram={ItemAdjustmentFlowDiagram}
          //   name="Item Adjustment"
          //   title="Item Adjustment"
          //   description="Adjust your item information after adding an item"
          //   buttonLabel="Create Adjustment"
          //   buttonLink="item-adjustments/add"
          // />
          <Onboarding
            Image={ItemAdjustmentImage}
            FlowDiagram={ItemAdjustmentFlowDiagram}
            videoFrame={
              <iframe
                width="700"
                height="400"
                src="https://www.youtube.com/embed/K1Yxkar-ApY"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            }
            DisplayIcon={NewItemAdjustmentIcon}
            headerTitle="Items Adjustment"
            name="Item Adjustment"
            title="Item Adjustment"
            description="Adjust your item information after adding an item"
            buttonLabel="Create Adjustment"
            buttonLink="item-adjustments/add"
          />
        );
      }
    }
    let fullscreen = getStringValueFromLS('fullscreen');
    if (accessDenied) {
      return <AccessDenied className="small" type="section" />;
    }
    return (
      <ListTable
        data={fullscreen === 'true' ? dataExpanded : data}
        selectedData={bulkSelectedData}
        rowOptions={this.prepareRowOptions()}
        generateRoute={this.generateRoute}
        orderBy={orderBy}
        sortFilter={sortFilter}
        className={`listtable ${
          getStringValueFromLS('fullscreen') === 'true'
            ? 'listable_item_adjustment'
            : ''
        } `}
        loading={mainLoading}
        handleCheckAll={this.handleCheckAll}
        handleCheckSingle={this.handleCheckSingle}
        linkTo="/item-adjustments"
        getID={this.getID}
        moduleName="item-adjustments"
        totalRecords={this.state.totalRecords}
      />
    );
  }

  renderSearchDetails() {
    const { search } = this.state;
    if (search) {
      return (
        <Alert
          className="full-width green"
          id="elasticSearch"
          title="Search Criteria"
          linkTo={routesPath.itemAdjustment}
        >
          <SearchResult
            criterias={[
              'Reason',
              'Description',
              'Warehouse',
              'Adjusted By',
              'Account',
            ]}
            searchKey={search.replace('%26', '&')}
          />
        </Alert>
      );
    }
    return null;
  }

  render() {
    return this.renderTable();
  }
}

export default HeaderWrapper(
  ItemsAdjustment,
  {
    name: 'Item Adjustments',
    Icon: NewItemAdjustmentIcon,
    tableIcon: 'general_module_icon',
    deleteName: 'Item Adjustment',
    baseUrl: 'itemadjustments',
    buttonUrl: '/item-adjustments/add',
    buttonLabel: 'New Adjustment',
    routeName: 'item-adjustments',
    showDetailsComp: true,
    bypassLoadingState: false,
    permissionName: 'itemadjustment',
  },
  ItemAdjustmentDetails,
  AddAdjustments
);
