import React from 'react'


const NotFound = () => {
  return (
    <div className="empty-data">
      No data found.
    </div>
  )
}

export default NotFound
