import React, { Fragment, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { withFormik, FieldArray } from 'formik';
import ListTableImageColumn from '../../ListTable/ListTableImageColumn';
import Quantity from '../../../Components/Fragments/Quantity/Quantity';

import DeleteIcon from '../../../Assets/General/DeleteIcon';
import { restRequest } from '../../../Helpers/RequestHelper';
import {
  ValidateRequired,
  required,
  ValidateNumber,
  ValidateMax50,
} from '../../../Helpers/Validator';
import { getError, validate, formSubmit } from '../FormHelper';

import RadioContainer from '../../Radio/RadioContainer';
import Radio from '../../Radio/Radio';
import Field from '../Field/Field';
import Form from '../Form';
import FormAction from '../FormAction';
import ErrorFocus from '../ErrorFocus';
import { getObjFromLS, setObjInLS } from '../../../Helpers/LocalStorage';
import { checkError } from '../../../Helpers/AuthHelper';
import { confirmationAlert } from '../../../Helpers';
import { validationNewReceiveForm } from '../../../Helpers/FormValidator';
import moment from 'moment';

const getYupValidationSchema = () => {
  return Yup.object().shape({
    type: Yup.string(),
    receiveNo: required(ValidateMax50),
    date: ValidateRequired.nullable(),
    unbilledItems: Yup.array().when('type', {
      is: 'unbilled',
      then: Yup.array()
        .of(
          Yup.object().shape({
            name: ValidateRequired,
            quantityToReceive: Yup.number()
              .required('Required')
              .moreThan(0, 'Should be greater than 0')
              .integer('Invalid Value'),
          })
        )
        .min(1, 'At least one item is required'),
      otherwise: Yup.array().notRequired(),
    }),
    billedItems: Yup.array().when('type', {
      is: 'billed',
      then: Yup.array()
        .of(
          Yup.object().shape({
            billId: required(ValidateNumber),
          })
        )
        .min(1, 'At least one item is required'),
      otherwise: Yup.array().notRequired(),
    }),
  });
};

const formEnhancer = withFormik({
  validate: validate(getYupValidationSchema),
  mapPropsToValues: ({ receive }) => ({
    ...receive,
  }),
  mapPropsToStatus: (props) => ({
    allUnbilledItems: props.allUnbilledItems,
    allBilledItems: props.allBilledItems,
    emptyUnbilledItem: props.emptyUnbilledItem,
    emptyBilledItem: props.emptyBilledItem,
  }),
  handleSubmit: (values, bag) => {
    handleFormSubmit(values, bag);
  },
});

const ReceiveForm = (props) => {
  const [staticVal, setStaticValues] = useState({});
  useEffect(() => {
    handleAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values, staticVal]);

  useEffect(() => {
    setStaticValues(props.values);
    localStorage.setItem('alert', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAlert = () => {
    let { checkout } = validationNewReceiveForm(props.values, staticVal);
    // if (!checkout) props.handleprompt();
    // else props.handleprompt(false);
    if (!checkout) localStorage.setItem('alert', true);
    else localStorage.setItem('alert', false);
  };

  const {
    values: {
      receiveNo,
      date,
      type,
      unbilledItems,
      billedItems,
      purchase_order_date,
    },
    // status: { emptyBilledItem, emptyUnbilledItem },
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    errors,
    touched,
    onClose,
  } = props;

  return (
    <Form
      onSubmit={(event) =>
        formSubmit(
          event,
          type === 'unbilled' ? unbilledItems : billedItems,
          setFieldValue,
          handleSubmit,
          type === 'unbilled' ? 'id' : 'billId',
          type === 'unbilled' ? 'unbilledItems' : 'billedItems'
        )
      }
    >
      <div className="form-fields-container d-flex flex-wrap  position-relative ">
        <Field
          className="flex-33"
          size="large"
          name="Receive No."
          autoFocus={true}
          id="receiveNo"
          value={receiveNo}
          required
          handleChange={handleChange}
          onBlur={handleBlur}
          error={touched.receiveNo && errors.receiveNo}
        />

        <Field
          className="flex-33"
          size="large"
          type="date"
          name="Receive Date"
          id="date"
          // value={date}
          value={typeof date == 'string' ? moment(date) : date}
          // minDate={purchase_order_date}
          minDate={
            typeof purchase_order_date == 'string'
              ? moment(purchase_order_date)
              : purchase_order_date
          }
          handleChange={(date) => {
            setFieldValue('date', date && date.format('YYYY-MM-DD 00:00:00'));
          }}
          onBlur={() => setFieldTouched('date', true)}
          required
          error={touched.date && errors.date}
        />
      </div>

      <div className="float-left w-100 receive-item-row d-flex align-center">
        <div className="float-left pr-label">
          <Field type="radio" name="Receive Items Type"></Field>
        </div>
        <div
          className="float-left"
          style={{ margin: '0.8rem 0 0.8rem 1.5rem' }}
        >
          <RadioContainer inline>
            <Radio
              id="unbilled"
              name="Unbilled Items"
              onChange={() => handleReceiveType('unbilled', setFieldValue)}
              checked={type === 'unbilled'}
            />
            <Radio
              id="billed"
              name="Billed Items"
              onChange={() => handleReceiveType('billed', setFieldValue)}
              checked={type === 'billed'}
            />
          </RadioContainer>
        </div>
      </div>

      {type === 'unbilled' && <Fragment>{renderUnbilledItems(props)}</Fragment>}
      {type === 'billed' && <Fragment>{renderBilledItems(props)}</Fragment>}

      <FormAction
        className="margin-top-md"
        disabled={isSubmitting}
        onCancel={() => {
          let response;
          let { checkout } = validationNewReceiveForm(props.values, staticVal);
          if (!checkout) response = confirmationAlert();
          if (response || checkout) onClose();
        }}
      />
      <ErrorFocus />
    </Form>
  );
};

const renderUnbilledItems = ({
  values: { unbilledItems },
  status,
  setFieldValue,
  setStatus,
  touched,
  errors,
  handleBlur,
  setFieldTouched,
}) => {
  const handleArrowsKey = (e, index, fieldname) => {
    if (e.key === 'ArrowDown' && index + 1 < unbilledItems?.length) {
      const element = document.getElementById(
        `unbilledItems[${index + 1}].${fieldname}`
      );
      setTimeout(() => {
        element.select();
        element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
      }, 100);
    }
  };

  const handleArrowUpKey = (e, index, fieldname) => {
    if (e.key === 'ArrowUp' && index - 1 >= 0) {
      const element = document.getElementById(
        `unbilledItems[${index - 1}].${fieldname}`
      );
      element.select();
      element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
    }
  };
  const { allUnbilledItems } = status;
  return (
    <FieldArray
      name="unbilledItems"
      render={() => {
        return (
          <div className="form-table--container recieve_popup">
            <table className="list-table form-table border margin-top-md">
              <thead>
                <tr>
                  <th>Items</th>
                  <th className="right-align">Ordered</th>
                  <th className="right-align">Received</th>
                  <th className="right-align">Cancelled</th>
                  <th className="right-align">Qty. To Received</th>
                </tr>
              </thead>
              <tbody>
                {unbilledItems.map((item, index) => {
                  return (
                    <tr key={index} className="table--row">
                      {item.id ? (
                        <td>
                          {
                            <ListTableImageColumn
                              name={item.name}
                              sku={item.sku}
                              images={[item.image]}
                            />
                          }
                        </td>
                      ) : (
                        <td className="no-padding">
                          <Field
                            type="dropdown"
                            value={item.id}
                            options={allUnbilledItems}
                            dropdownValue="id"
                            dropdownLabel="name"
                            placeholder="Select Item"
                            additionalSearchFilter="sku,upc,mpn,ean,isbn"
                            clearable={false}
                            handleChange={(option) =>
                              handleUnbilledSelectedItem(
                                option,
                                unbilledItems,
                                index,
                                setFieldValue,
                                status,
                                setStatus
                              )
                            }
                            error={getError(
                              errors,
                              touched,
                              `unbilledItems[${index}].name`
                            )}
                            onBlur={() =>
                              setFieldTouched(
                                `unbilledItems[${index}].name`,
                                true
                              )
                            }
                          />
                        </td>
                      )}

                      <td className="right-align">
                        <Quantity
                          quantity={item.orderedQuantity}
                          unit={item.unit}
                        />
                      </td>

                      <td className="right-align">
                        <Quantity
                          quantity={item.receivedQuantity}
                          unit={item.unit}
                        />
                      </td>

                      <td className="right-align">
                        <Quantity
                          quantity={item.cancelledQuantity}
                          unit={item.unit}
                        />
                      </td>

                      <td className="right-align no-padding with-close--btn">
                        <Field
                          size="small"
                          type="number"
                          className="align-qty-receive-right"
                          value={item.quantityToReceive}
                          decimalScale={6}
                          id={`unbilledItems[${index}].quantityToReceive`}
                          suffix={item.unit ? item.unit : ''}
                          allowNegative={false}
                          thousandSeparator=","
                          onValueChange={(value) =>
                            handleItemQuantityChange(
                              value,
                              index,
                              unbilledItems,
                              setFieldValue
                            )
                          }
                          error={getError(
                            errors,
                            touched,
                            `unbilledItems[${index}].quantityToReceive`
                          )}
                          onBlur={handleBlur}
                          onKeyDown={(e) =>
                            handleArrowsKey(e, index, 'quantityToReceive')
                          }
                          onKeyUp={(e) =>
                            handleArrowUpKey(e, index, 'quantityToReceive')
                          }
                        />

                        <button
                          type="button"
                          className="close-btn button"
                          disabled={unbilledItems.length === 1}
                          onClick={() =>
                            handleUnbilledRemoveItem(
                              index,
                              unbilledItems,
                              item,
                              setFieldValue,
                              status,
                              setStatus
                            )
                          }
                        >
                          {/* <MdClose /> */}
                          <DeleteIcon className="bin" />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      }}
    />
  );
};
const renderBilledItems = ({
  values: { billedItems },
  status,
  errors,
  touched,
  setFieldTouched,
  setStatus,
  setFieldValue,
}) => {
  const { allBilledItems } = status;
  return (
    <FieldArray
      name="billedItems"
      render={() => {
        return (
          <div className="float-left w-100">
            <div className="billed_popup">
              <table className="list-table form-table margin-top-md receive-unbilled-table new_reveive_set">
                <thead>
                  <tr>
                    <th>Bill No.</th>
                    <th>Item Name</th>
                    <th className="text-right">Qty. To Received</th>
                  </tr>
                </thead>
                <tbody>
                  {billedItems.map((bill, index) =>
                    bill.items.map((item, indexInner) => (
                      <tr key={index}>
                        {
                          //indexInner === 0 && (
                          bill.billId ? (
                            <td rowSpan={bill.items.length}>
                              <Link
                                target="_blank"
                                to={`/bills/${bill.billId}`}
                              >
                                {bill.billNo}
                              </Link>
                            </td>
                          ) : (
                            <td className="no-padding">
                              <Field
                                type="dropdown"
                                placeholder="Select Bill"
                                value={bill.billId}
                                options={allBilledItems}
                                dropdownValue="id"
                                dropdownLabel="billNo"
                                clearable={false}
                                handleChange={(option) =>
                                  handleBilledSelectedItem(
                                    option,
                                    billedItems,
                                    index,
                                    setFieldValue,
                                    status,
                                    setStatus
                                  )
                                }
                                error={getError(
                                  errors,
                                  touched,
                                  `billedItems[${index}].billId`
                                )}
                                onBlur={() =>
                                  setFieldTouched(
                                    `billedItems[${index}].billId`,
                                    true
                                  )
                                }
                              />
                            </td>
                          )
                          //)
                        }

                        <td>
                          <span className="receive_item_name_">
                            {item.name}
                          </span>
                        </td>

                        <td className="text-right with-close--btn">
                          <div>
                            <Quantity
                              quantity={item.quantity}
                              unit={item.unit}
                            />
                          </div>

                          {
                            //indexInner === 0 && (
                            <button
                              type="button"
                              disabled={billedItems.length === 1}
                              className="close-btn button right-align--margin"
                              onClick={() =>
                                handleBilledRemoveItem(
                                  index,
                                  billedItems,
                                  bill,
                                  setFieldValue,
                                  status,
                                  setStatus
                                )
                              }
                            >
                              <DeleteIcon className="bin" />
                            </button>
                            //)
                          }
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        );
      }}
    />
  );
};

const submitRequestPayload = (values) => {
  switch (values.type) {
    case 'billed':
      return {
        type: values.type,
        receive_no: values.receiveNo,
        receive_date: values.date && values.date,
        bill_ids: values.billedItems.map((bill) => bill.billId),
      };
    case 'unbilled':
    default:
      return {
        type: values.type,
        receive_no: values.receiveNo,
        receive_date: values.date && values.date,
        items: values.unbilledItems.map((item) => ({
          purchase_order_item_detail_id: item.purchaseOrderItemDetailId,
          quantity: item.quantityToReceive,
        })),
      };
  }
};

const handleFormSubmit = (
  values,
  { setSubmitting, props: { onSubmit, handleToast, purchaseOrderId, history } }
) => {
  restRequest(
    'post',
    `purchaseorders/${purchaseOrderId}/receives/store`,
    submitRequestPayload(values)
  )
    .then(() => {
      handleToast('Receives created successfully', 'success');
      let dataFromLS = getObjFromLS('module');
      const { moduleName } = dataFromLS;
      setObjInLS('module', {
        ...dataFromLS,
        queryParam: `${
          dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
        }receives=true`,
      });
      if (moduleName === 'invoices') history.go();
      history.push('/r');
      sessionStorage.setItem('fullscreen', false);
      return;
    })
    .catch((error) => {
      //console.log('error', error)
      setSubmitting(false);
      checkError(error);
      handleToast(error, 'error');
    });
};

const handleItemQuantityChange = (
  values,
  index,
  currentItems,
  setFieldValue
) => {
  let quantity = values.floatValue;
  let itemsList = [...currentItems];
  let currentItem = itemsList[index];
  itemsList[index] = {
    ...currentItem,
    quantityToReceive: quantity,
  };

  setFieldValue('unbilledItems', itemsList);
};

const handleUnbilledSelectedItem = (
  option,
  items,
  index,
  setFieldValue,
  status,
  setStatus
) => {
  let newItem = {
    ...option,
    quantityToReceive: items[index].quantityToReceive,
  };
  let updatedItems = [...Object.assign([...items], { [index]: newItem })];
  setFieldValue('unbilledItems', updatedItems);
  setStatus({
    ...status,
    allUnbilledItems: status.allUnbilledItems.filter(
      (item) => item.id !== option.id
    ),
  });
};

const handleBilledSelectedItem = (
  option,
  items,
  index,
  setFieldValue,
  status,
  setStatus
) => {
  let newItem = { ...option };
  let updatedItems = [...Object.assign([...items], { [index]: newItem })];
  setFieldValue('billedItems', updatedItems);
  setStatus({
    ...status,
    allBilledItems: status.allBilledItems.filter(
      (item) => item.billId !== option.billId
    ),
  });
};

const handleReceiveType = (value, setFieldValue) => {
  setFieldValue('type', value);
};

const handleUnbilledRemoveItem = (
  removeIndex,
  items,
  currentItem,
  setFieldValue,
  status,
  setStatus
) => {
  const updatedItems = items.filter((_, index) => index !== removeIndex);
  setFieldValue('unbilledItems', updatedItems);

  if (!currentItem.id) return;

  setStatus({
    ...status,
    allUnbilledItems: [...status.allUnbilledItems, currentItem],
  });
};

const handleBilledRemoveItem = (
  removeIndex,
  items,
  currentItem,
  setFieldValue,
  status,
  setStatus
) => {
  const updatedItems = items.filter((_, index) => index !== removeIndex);
  setFieldValue('billedItems', updatedItems);

  if (!currentItem.billId) return;

  setStatus({
    ...status,
    allBilledItems: [...status.allBilledItems, currentItem],
  });
};

export default withRouter(formEnhancer(ReceiveForm));
