import { restRequest } from './RequestHelper'

/**
 * Get the base currency name of the current organization.
 * 
 */
export function fetchCurrency() {
  let organizationID = localStorage.getItem('currentOrganization') !== null && JSON.parse(localStorage.getItem('currentOrganization')).id
  if (!!organizationID) {
    return restRequest( 'get', `organizations/${organizationID}` ).then( res => {
      return res.get_currency
    }).catch(error => {
      console.log(error)
    })
  }
}