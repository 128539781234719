import React, { Fragment } from 'react';
import getDate from '../../../../Helpers/Date/GetDate';
//import DropshipMultipleLink from '../../../../Helpers/DropshipMultipleLink';
import './PaymentmodeInfo.css';

const PaymentmodeInfo = ({
  info: {
    Paymentno,
    date,
    payment_mode,
    receive,
    currency,
    payment_type,
    total_amount,
    unused_amount,
    used_amount,
    reference,
  },
}) => {
  return (
    <div className="section-details--info marginfromtop">
      <div className="section-details--info-details details_column">
        {/* {Paymentno && (
          <Fragment>
            {' '}
            <div className="details_row">
              <div className="section-details--info-title semi-bold text_transform_lower">
                Payment No
              </div>
              <div>{Paymentno}</div>{' '}
            </div>
          </Fragment>
        )} */}

        {date && (
          <Fragment>
            {' '}
            <div className="details_row">
              <div className="section-details--info-title semi-bold text_transform_lower">
                Date
              </div>
              <div>{getDate(date)}</div>{' '}
            </div>
          </Fragment>
        )}

        {payment_mode && (
          <Fragment>
            {' '}
            <div className="details_row">
              <div className="section-details--info-title semi-bold text_transform_lower">
                payment Mode
              </div>
              <div>{payment_mode}</div>{' '}
            </div>
          </Fragment>
        )}

        {payment_type === 'advance' && total_amount >= 0 && (
          <Fragment>
            {' '}
            <div className="details_row">
              <div className="section-details--info-title semi-bold text_transform_lower">
                Total Amount
              </div>
              <div>
                {currency.symbol}
                {parseFloat(total_amount).toFixed(2)}
              </div>{' '}
            </div>
          </Fragment>
        )}
        {payment_type === 'advance' && used_amount >= 0 && (
          <Fragment>
            {' '}
            <div className="details_row">
              <div className="section-details--info-title semi-bold text_transform_lower">
                Used Amount
              </div>
              <div>
                {currency.symbol}
                {parseFloat(used_amount).toFixed(2)}
              </div>{' '}
            </div>
          </Fragment>
        )}
        {payment_type === 'advance' && unused_amount >= 0 && (
          <Fragment>
            {' '}
            <div className="details_row">
              <div className="section-details--info-title semi-bold text_transform_lower">
                Balance
              </div>
              <div className="semi-bold">
                {currency.symbol}
                {parseFloat(unused_amount).toFixed(2)}
              </div>{' '}
            </div>
          </Fragment>
        )}
        {payment_type !== 'advance' && receive && (
          <Fragment>
            {' '}
            <div className="details_row">
              <div className="section-details--info-title semi-bold text_transform_lower">
                Total Amount
              </div>
              <div className="reference">
                {currency.symbol}
                {parseFloat(receive).toFixed(2)}
              </div>{' '}
            </div>
            <div className="details_row">
              <div className="section-details--info-title semi-bold text_transform_lower">
                Used Amount
              </div>
              <div>
                {currency.symbol}
                {parseFloat(receive).toFixed(2)}{' '}
              </div>{' '}
            </div>
            <div className="details_row">
              <div className="section-details--info-title semi-bold text_transform_lower">
                Balance
              </div>
              <div>--</div>{' '}
            </div>
          </Fragment>
        )}
        {reference && (
          <Fragment>
            <div className="details_row">
              <div className="section-details--info-title semi-bold text_transform_lower">
                Reference
              </div>
              <div className="reference">{reference}</div>
            </div>
          </Fragment>
        )}

        <br />
      </div>
    </div>
  );
};

export default PaymentmodeInfo;
