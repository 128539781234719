import { restRequest } from './RequestHelper';
import Cookies from 'universal-cookie';
import { getObjFromLS, setObjInLS } from './LocalStorage';
import { ssosignoutPath } from "./UrlHelper";
const cookies = new Cookies();

export function validToken() {
  const token = cookies.get('token');
  if (token) return token;
  return false;
}

export function validUser() {
  const status = cookies.get('user_status');
  if (status) return status;
  return false;
}

export function setTokenLocal(token) {
  localStorage.setItem('_token', token);
}

// use cookie
export function setToken(token) {
  var date = new Date();
  var days = 120;
  date.setTime(+date + days * 86400 * 1000);
  //date.setTime(+ date + (days * 86400));
  cookies.set('token', token, {
    path: '/',
    domain: `${process.env.REACT_APP_COOKIEPARENT}`,
    expires: date,
  });
}

export function setRefreshToken(refreshToken) {
  cookies.set('refreshToken', refreshToken, {
    path: '/',
    domain: `${process.env.REACT_APP_COOKIEPARENT}`,
  });
}

export function setRememberMeToken(rememberToken) {
  cookies.set('rememberMe', rememberToken, {
    path: '/',
    domain: `${process.env.REACT_APP_COOKIEPARENT}`,
  });
}

export function setTokenRefreshToken(token, refreshToken, remember_me) {
  setToken(token);
  setRefreshToken(refreshToken);
  if (remember_me) {
    setRememberMeToken(refreshToken);
  }
  cookies.remove('user_status', {
    path: '/',
    domain: `${process.env.REACT_APP_COOKIEPARENT}`,
  });
}

export function setInvitationToken(token) {
  cookies.set('invitation_token', token, {
    path: '/',
    domain: `${process.env.REACT_APP_COOKIEPARENT}`,
  });
}

export function setOrganization(id) {
  localStorage.setItem('organization', id);
}

export function getCurrentOrganizaton() {
  const currentOrganization = localStorage.getItem('currentOrganization');
  return currentOrganization;
}

export function getOrganization() {
  const organization = localStorage.getItem('organization');
  return organization;
}

export function getRefreshToken() {
  const refreshToken = cookies.get('refreshToken');
  return refreshToken;
}

export function getRememberMeToken() {
  const rememberMe = cookies.get('rememberMe');
  return rememberMe;
}

export function logout(ssoflag = false) {
  // handle later
  const sso_connected = process.env.REACT_APP_SSO_ENABLED === 'false' ? false : true;
  //console.log('97', sso_connected)
  if (sso_connected) {
    window.location.href = ssosignoutPath();
  } else {
    restRequest('get', `auth/logout`);
    //console.log('clear cookies from logout url -----> 101')
    localStorage.setItem('logout-event', 'logout' + Math.random());
    localStorage.clear();
    sessionStorage.clear();
    cookies.remove('token', {
      path: '/',
      domain: `${process.env.REACT_APP_COOKIEPARENT}`,
    });
    cookies.remove('refreshToken', {
      path: '/',
      domain: `${process.env.REACT_APP_COOKIEPARENT}`,
    });
    cookies.remove('rememberMe', {
      path: '/',
      domain: `${process.env.REACT_APP_COOKIEPARENT}`,
    });
    window.location = "/";
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
  }
}

export function removeTokenCookies() {
  // handle later
  //await restRequest('get', `auth/logout`).then(res=>{}).catch(err=>{});
  //localStorage.setItem('logout-event', 'logout' + Math.random());
  localStorage.clear();
  sessionStorage.clear();
  let token = validToken();
  cookies.set('logout_token', token, {
    path: '/',
    domain: `${process.env.REACT_APP_COOKIEPARENT}`,
  });
  cookies.remove('token', {
    path: '/',
    domain: `${process.env.REACT_APP_COOKIEPARENT}`,
  });
  cookies.remove('refreshToken', {
    path: '/',
    domain: `${process.env.REACT_APP_COOKIEPARENT}`,
  });
  cookies.remove('rememberMe', {
    path: '/',
    domain: `${process.env.REACT_APP_COOKIEPARENT}`,
  });
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(function (names) {
      for (let name of names) caches.delete(name);
    });
  }
}

export function checkError(error, toast = null, err_msg = null) {
  if (error.response && error.response.status !== 401 && toast) {
    if (
      error.response.status === 403 &&
      error.response.data.status === 'access_denied'
    ) {
      toast(
        'Admin has changed the permissions. Forcefully Refreshing to load latest set permissions for you.',
        'success'
      );
    } else if (err_msg) {
      toast(err_msg, 'error');
    } else {
      toast(error.response.data.message, 'error');
    }
  }
  if (error.response && error.response.status === 401) {
    //alert('You have been forcefully logged out by admin')
    /*localStorage.setItem('logout-event', 'logout' + Math.random());
    localStorage.clear();
    sessionStorage.clear();
    cookies.remove('token', {
      path: '/',
      domain: `${process.env.REACT_APP_COOKIEPARENT}`,
    });
    cookies.remove('refreshToken', {
      path: '/',
      domain: `${process.env.REACT_APP_COOKIEPARENT}`,
    });
    cookies.remove('rememberMe', {
      path: '/',
      domain: `${process.env.REACT_APP_COOKIEPARENT}`,
    });*/
    //console.log('173')
    logout(true);
    localStorage.setItem('notFound', true);
    //window.location = '/login';
    return false;
  } else if (
    error.response &&
    error.response.status === 403 &&
    error.response.data.status === 'logout'
  ) {
    //console.log('183')
    setTimeout(function () {
      logout();
      //window.location.href = '/';
    }, 2000);
  } else if (
    error.response &&
    error.response.status === 403 &&
    error.response.data.status === 'access_denied'
  ) {
    // if permission changed by admin, permissions will also update in local storage
    if (
      window.location.pathname !== '/' &&
      window.location.pathname !== '/dashboard' //&&
      // window.location.pathname !== '/item-groups' &&
      // window.location.pathname !== '/useraccess' &&
      // window.location.pathname !== '/settings'
    ) {
      restRequest('get', 'currentuser/role').then((res) => {
        setObjInLS('role', res);
      });
      if (!error.response.data.status === 'access_denied')
        setTimeout(function () {
          window.location.reload();
        }, 2000);
    }
    return true;
  }
  return false;
}

export function checkUpdatedPermission(permission, toast = null) {
  let allPermissions = getObjFromLS('role').permissions;
  if (allPermissions.superAccess) {
    return true;
  } else {
    //console.log('permission name', permission)
    //console.log('permission', allPermissions[`${permission}`])
    if (allPermissions[`${permission}`] === false) {
      restRequest('get', 'currentuser/role').then((res) => {
        //console.log('after request permission', res)
        //console.log('after request permission', res.permissions[`${permission}`])
        if (res.permissions[`${permission}`] === true) {
          if (toast) {
            toast(
              'Admin has changed the permissions. Forcefully Refreshing to load latest set permissions for you.',
              'success'
            );
          }
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        }
      });
    } else {
      return true;
    }
  }
  return true;
}

export function setDeploymentTime(data) {
  //console.log('data', data)
  // check force logout here if force logout is true, then user will be logout automatically
  if (data.force_logout !== false) {
    //1. 
    restRequest('post', `deploymentTime`).then(() => { });
    setTimeout(function () {
      logout();
    }, 2000);
  }
  let storeDate = JSON.parse(localStorage.getItem('deploymentTime'));
  //console.log('storeDate ----------', storeDate);
  if (storeDate !== null) {
    storeDate = new Date(storeDate);
    let newDate = data.deploymentTime.created_at;
    newDate = new Date(newDate);
    //console.log('newDate', newDate, ' --------- storeDate', storeDate);
    if (newDate.getTime() > storeDate.getTime()) {
      restRequest('post', `deploymentTime`).then(() => {
        reload(data)
      }).catch(()=> {
        reload(data)
      });
    }
    else {
      localStorage.setItem(
        'deploymentTime',
        JSON.stringify(data.deploymentTime.created_at)
      );
    }
  }
  else {
    localStorage.setItem(
      'deploymentTime',
      JSON.stringify(data.deploymentTime.created_at)
    );
  }
}

function reload(data){
  localStorage.removeItem('deploymentTime');
  if (caches) {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }
  localStorage.setItem(
    'deploymentTime',
    JSON.stringify(data.deploymentTime.created_at)
  );
  window.location.reload(true);
}
