import { createStaticRanges } from 'react-date-range';

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  addYears,
} from 'date-fns';
import { getOrganizationDate } from '../../Helpers/Date/OrganizationDate';

export const ranges = () => {
  return {
    startOfWeek: startOfWeek(getOrganizationDate()),
    endOfWeek: endOfWeek(getOrganizationDate()),
    startOfLastWeek: startOfWeek(addDays(getOrganizationDate(), -7)),
    endOfLastWeek: endOfWeek(addDays(getOrganizationDate(), -7)),
    startOfToday: startOfDay(getOrganizationDate()),
    endOfToday: endOfDay(getOrganizationDate()),
    startOfYesterday: startOfDay(addDays(getOrganizationDate(), -1)),
    endOfYesterday: endOfDay(addDays(getOrganizationDate(), -1)),
    startOfMonth: startOfMonth(getOrganizationDate()),
    endOfMonth: endOfMonth(getOrganizationDate()),
    endOfNextMonth: endOfMonth(addMonths(getOrganizationDate(), 1)),
    startOfLastMonth: startOfMonth(addMonths(getOrganizationDate(), -1)),
    endOfLastMonth: endOfMonth(addMonths(getOrganizationDate(), -1)),
    startOfYear: startOfYear(getOrganizationDate()),
    endOfYear: endOfYear(getOrganizationDate()),
    startOfLastYear: startOfYear(addYears(getOrganizationDate(), -1)),
    endOfLastYear: endOfYear(addYears(getOrganizationDate(), -1)),
  };
};

export const staticRanges = createStaticRanges([
  {
    label: 'Today',
    range: () => ({
      startDate: ranges().startOfToday,
      endDate: ranges().endOfToday,
      key: 'static',
    }),
  },
  {
    label: 'This Week',
    range: () => ({
      startDate: ranges().startOfWeek,
      endDate: ranges().endOfWeek,
      key: 'static',
    }),
  },
  {
    label: 'This Month',
    range: () => ({
      startDate: ranges().startOfMonth,
      endDate: ranges().endOfMonth,
      key: 'static',
    }),
  },
  {
    label: 'This Year',
    range: () => ({
      startDate: ranges().startOfYear,
      endDate: ranges().endOfYear,
      key: 'static',
    }),
  },
  {
    label: 'Yesterday',
    range: () => ({
      startDate: ranges().startOfYesterday,
      endDate: ranges().endOfYesterday,
      key: 'static',
    }),
  },
  {
    label: 'Last Week',
    range: () => ({
      startDate: ranges().startOfLastWeek,
      endDate: ranges().endOfLastWeek,
      key: 'static',
    }),
  },
  {
    label: 'Last Month',
    range: () => ({
      startDate: ranges().startOfLastMonth,
      endDate: ranges().endOfLastMonth,
      key: 'static',
    }),
  },
  {
    label: 'Last Year',
    range: () => ({
      startDate: ranges().startOfLastYear,
      endDate: ranges().endOfLastYear,
      key: 'static',
    }),
  },
]);

export const customRanges = (startFrom = null) => {
  return {
    startOfWeek: startOfWeek(startFrom),
    endOfWeek: endOfWeek(startFrom),
    startOfLastWeek: startOfWeek(addDays(startFrom, -7)),
    endOfLastWeek: endOfWeek(addDays(startFrom, -7)),
    startOfToday: startOfDay(startFrom),
    endOfToday: endOfDay(startFrom),
    startOfYesterday: startOfDay(addDays(startFrom, -1)),
    endOfYesterday: endOfDay(addDays(startFrom, -1)),
    startOfMonth: startOfMonth(startFrom),
    endOfMonth: endOfMonth(startFrom),
    endOfNextMonth: endOfMonth(addMonths(startFrom, 1)),
    startOfLastMonth: startOfMonth(addMonths(startFrom, -1)),
    endOfLastMonth: endOfMonth(addMonths(startFrom, -1)),
    startOfYear: startOfYear(startFrom),
    endOfYear: endOfYear(startFrom),
    startOfLastYear: startOfYear(addYears(startFrom, -1)),
    endOfLastYear: endOfYear(addYears(startFrom, -1)),
  };
};

export const custom_ranges = createStaticRanges([
  {
    label: 'Today',
    range: () => ({
      startDate: customRanges().startOfToday,
      endDate: customRanges().endOfToday,
      key: 'static',
    }),
  },
  {
    label: 'This Week',
    range: () => ({
      startDate: customRanges().startOfWeek,
      endDate: customRanges().endOfWeek,
      key: 'static',
    }),
  },
  {
    label: 'This Month',
    range: () => ({
      startDate: customRanges().startOfMonth,
      endDate: customRanges().endOfMonth,
      key: 'static',
    }),
  },
  {
    label: 'This Year',
    range: () => ({
      startDate: customRanges().startOfYear,
      endDate: customRanges().endOfYear,
      key: 'static',
    }),
  },
  {
    label: 'Yesterday',
    range: () => ({
      startDate: customRanges().startOfYesterday,
      endDate: customRanges().endOfYesterday,
      key: 'static',
    }),
  },
  {
    label: 'Last Week',
    range: () => ({
      startDate: customRanges().startOfLastWeek,
      endDate: customRanges().endOfLastWeek,
      key: 'static',
    }),
  },
  {
    label: 'Last Month',
    range: () => ({
      startDate: customRanges().startOfLastMonth,
      endDate: customRanges().endOfLastMonth,
      key: 'static',
    }),
  },
  {
    label: 'Last Year',
    range: () => ({
      startDate: customRanges().startOfLastYear,
      endDate: customRanges().endOfLastYear,
      key: 'static',
    }),
  },
]);
