import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withFormik, FieldArray } from 'formik';
import moment from 'moment';
import DeleteIcon from '../../../Assets/General/DeleteIcon';
import {
  restRequest,
  pageIdentify,
  handleExtraDescription,
  getObjFromLS,
  setObjInLS,
  checkError,
  confirmationAlert,
  GetDateString,
  getOrganizationDate,
} from '../../../Helpers';
import {
  moreThanZero,
  required,
  ValidateDate,
  ValidateNumber,
  ValidateRequired,
  ValidateMax50,
  ValidateMax10Billion,
} from '../../../Helpers/Validator';
import { validateCreatePackageForm } from '../../../Helpers/FormValidator';
import { Field, Form, FormAction, AddItem, ErrorFocus } from '../';
import { getError, validate, formSubmit } from '../FormHelper';
import ListTableImageColumn from '../../ListTable/ListTableImageColumn';
import Quantity from '../../Fragments/Quantity/Quantity';
import { Loader } from '../../';
import './PackageForm.css';
import DragDropItems from '../../../Common/DragDrop';
import { Draggable } from 'react-beautiful-dnd';
import { MdDragIndicator } from 'react-icons/md';

let postWarehouses = [];

const getYupValidationSchema = (values) => {
  return Yup.object().shape({
    packageNo: required(ValidateMax50),
    salesOrderId: required(ValidateNumber),
    packageDate: required(ValidateDate),
    items: Yup.array()
      .of(
        Yup.lazy((item) => {
          return Yup.object().shape({
            name: ValidateRequired,
            quantity: Yup.number()
              .min(1, `Cannot be 0`)
              .test(
                'is-valid',
                `Package quantity of ${item?.name} shouldn't be greater then sale order quantity.`,
                (value) => {
                  if (value > item?.ordered - item.packed) return false;
                  return true;
                }
              ),
          });
        })
      )
      .min(1, 'At least one item is required'),
  });
};

const formEnhancer = withFormik({
  validate: validate(getYupValidationSchema),
  mapPropsToValues: ({ packageInfo }) => ({
    ...packageInfo,
  }),
  mapPropsToStatus: (props) => ({
    itemsList: props.itemsList,
    emptyItem: props.emptyItem,
  }),
  handleSubmit: (values, bag) => {
    handleFormSubmit(values, bag);
  },
});

const handleFormSubmit = (
  values,
  {
    setSubmitting,
    props: {
      submitRequestType,
      submitRequestUrl,
      successMessage,
      inModal,
      type,
      history,
      handleToast,
      setPrompt,
    },
  }
) => {
  let payload = {
    sales_order_id: values.salesOrderId,
    package_no: values.packageNo,
    package_date: values.packageDate ? values.packageDate : null,
    items: values.items.map((item, index) => ({
      id: item.id,
      sort_order: index,
      warehouse_id: postWarehouses[index],
      sales_order_item_detail_id: item.salesOrderItemDetailId,
      package_item_detail_id: item.packageItemDetailId,
      quantity: item.quantity || 0,
      extra_description: item.extra_description ? item.extra_description : '',
    })),
  };
  restRequest(submitRequestType(), submitRequestUrl(), payload)
    .then(() => {
      setPrompt(false);
      successMessage();
      let dataFromLS = getObjFromLS('module');
      if (dataFromLS === null) {
        let obj = {
          prevId: null,
          id: null,
          nextId: null,
          moduleName: 'packages',
          urlPath: '/packages',
          queryParam: window.location.search,
        };
        setObjInLS('module', obj);
        dataFromLS = getObjFromLS('module');
      }
      if (inModal && !pageIdentify(window.location.pathname)) {
        setObjInLS('module', {
          ...dataFromLS,
          queryParam: `${dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
            }packages=true`,
        });
        return history.push('/r');
      } else if (inModal) {
        setObjInLS('module', {
          ...dataFromLS,
          queryParam: `${dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
            }packages=true`,
        });
        return history.go();
      } else {
        if (type === 'add') {
          setObjInLS('module', {
            ...dataFromLS,
            urlPath: '/packages',
            moduleName: 'packages',
            action: type === 'add' ? 'add' : 'justedit',
            queryParam: '',
          });
        }
        if (type === 'clone') {
          setObjInLS('module', {
            ...dataFromLS,
            urlPath: '/packages',
            moduleName: 'packages',
            action: 'add',
            queryParam: '',
          });
        } else if (type === 'edit') {
          setObjInLS('module', {
            ...dataFromLS,
            urlPath: '/packages',
            moduleName: 'packages',
            action: type === 'add' ? 'add' : 'justedit',
            queryParam: dataFromLS.queryParam ? dataFromLS.queryParam : '',
          });
        }
        history.push('/r');
        sessionStorage.setItem('fullscreen', false);
      }
    })
    .catch((error) => {
      handleToast(error, 'error');
      checkError(error);
      setSubmitting(false);
    });
};

const PackageForm = ({
  // props from formik
  values,
  status,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
  setStatus,
  setValues,
  isSubmitting,
  submitCount,
  errors,
  touched,
  setFieldTouched,

  // custom props
  title,
  inModal,
  warehouseListRecieve,
  genericWarehouseId,
  warehouseResult,
  type,
  salesOrderList,
  history,
  close,
  className,
  ...props
}) => {
  const { itemsList, emptyItem } = status;
  postWarehouses = warehouseListRecieve;
  var packageFakeItem = false;
  const [flag, setflag] = useState(false);
  const [loader, showLoader] = useState(false);
  const [, setWarehouseChangeFlag] = useState(false);
  const [, setItemDeleteFlag] = useState(false);
  const [, setWarehouseNamesFlag] = useState(false);
  const [addItem, setAddItem] = useState(false);
  const [warehouseList, setWarehouseList] = useState({});
  const [warehouseNames, setWarehouseNames] = useState([]);
  const [yikes, setyikes] = useState([]);
  const [staticVal, setStaticValues] = useState({});

  useEffect(() => {
    handleAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, staticVal]);

  useEffect(() => {
    setStaticValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setWarehouseList(warehouseResult);
    setyikes(warehouseListRecieve);
    setflag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAlert = () => {
    let { checkout } = validateCreatePackageForm(values, staticVal);
    if (!checkout && !inModal) props.setPrompt();
    else if (!checkout && inModal) props.handlePrompt();
    else {
      if (inModal) props.handlePrompt(false);
      props.setPrompt(false);
    }
  };

  const handleSalesOrderChange = async (selected) => {
    showLoader(true);
    setWarehouseChangeFlag(true);
    try {
      const res = await restRequest(
        'get',
        `packages/create?sales_order_id=${selected.value}`
      );
      showLoader(false);

      let arraywareid = [];
      for (let i = 0; i < res.sales_order.sales_order_details.length; i++) {
        arraywareid.push(res.sales_order.sales_order_details[i].warehouse_id);
      }
      setyikes(arraywareid);

      if (arraywareid[0] !== null) {
        let arr = [];
        for (let i = 0; i < arraywareid.length; i++) {
          for (let j = 0; j < warehouseList.length; j++) {
            if (arraywareid[i] === warehouseList[j].id) {
              arr.push(warehouseList[j].name);
            }
          }
        }
        setWarehouseNames(arr);
      }
      const resItems = res.sales_order.sales_order_details.map(
        (item, index) => ({
          id: item.item_id,
          salesOrderItemDetailId: item.id,
          dragDropID: GetDateString(),
          name: item.items.name,
          images: item.items.images,
          sku: item.items.sku,
          unit: item.items.unit,
          ordered: item.quantity,
          warehouse_idd: res.sales_order.sales_order_details[index].warehouse_id
            ? res.sales_order.sales_order_details[index].warehouse_id
            : res.sales_order.warehouse_id,
          packed: item.packed,
          stocks:
            item.items.stocks.find(
              (stock) => stock.warehouse_id === res.sales_order.warehouse_id
            ) || {},
          quantity: item.remaining_quantity,
          extra_description: item.extra_description,
        })
      );
      setValues({
        ...values,
        salesOrderId: selected.value,
        orderDate: moment(res.sales_order.order_date),
        packageDate:
          res?.sales_order?.order_date &&
            res?.sales_order?.order_date <
            moment(getOrganizationDate()).format('YYYY-MM-DD 00:00:00')
            ? moment(getOrganizationDate()).format('YYYY-MM-DD 00:00:00')
            : res?.sales_order?.order_date,
        items: [...resItems],
      });
    } catch (error) {
      setValues({
        ...values,
        salesOrderId: selected.value,
        items: [],
      });
    }
  };

  const handleSelectedItem = (option, index) => {
    // const newItem = { ...option, quantity: values.items[index].quantity }
    const newItem = { ...option };
    const updatedItems = [
      ...Object.assign([...values.items], { [index]: newItem }),
    ];
    setFieldValue('items', updatedItems);
    setStatus({
      ...status,
      itemsList: status.itemsList.filter((item) => item.salesOrderItemDetailId !== option.salesOrderItemDetailId),
    });
  };

  const renderAddItem = () => {
    const { items } = values;
    items.forEach((item) => {
      if (item.id == null) {
        packageFakeItem = true;
      }
    });
    if (packageFakeItem) {
      return '';
    } else {
      return (
        <AddItem
          onClick={() => setFieldValue('items', [...values.items, emptyItem])}
        />
      );
    }
  };

  const handleItemQuantityChange = (quantityValue, index) => {
    let quantity = quantityValue.floatValue;
    if (quantity > 0) {
      let quantString = quantity.toString();

      // Price_Quantity_Limit

      // price and quantity limit from 5 digits to 10 digits
      if (quantString.length > 10) {
        quantString = quantString.slice(0, 10);
      }
      quantity = parseFloat(quantString);
    }
    setFieldValue('items[' + index + '].quantity', quantity);
    // setFieldValue('items[' + index + '].qunatity_update', true);
  };

  const onChangeValueHandler = (val, index) => {
    let newVal = handleExtraDescription(val, 254);
    setFieldValue('items[' + index + '].extra_description', newVal);
  };

  const getvals = () => {
    if (itemsList.length === 0) {
      setAddItem(false);
    }
    setWarehouseNames([]);
    if (yikes[0] !== null) {
      for (let i = 0; i < yikes.length; i++) {
        for (let j = 0; j < warehouseList.length; j++) {
          if (yikes[i] === warehouseList[j].id) {
            let arr = [];
            arr = warehouseNames;
            arr.push(warehouseList[j].name);
            setWarehouseNames(arr);
          }
        }
      }
    } else {
      for (let i = 0; i < values.items.length; i++) {
        for (let j = 0; j < warehouseList.length; j++) {
          if (genericWarehouseId === warehouseList[j].id) {
            let arr = [];
            arr = warehouseNames;
            arr.push(warehouseList[j].name);
            setWarehouseNames(arr);
          }
        }
      }
    }

    let arr = [];
    arr = warehouseNames;
    arr.splice(yikes.length);
    setWarehouseNames(arr);
    // setAddItem(false)
    if (type === 'edit' && itemsList.length > 0) {
      setAddItem(true);
    }
  };

  const handleRemoveItem = (removeIndex, currentItem) => {
    setItemDeleteFlag(true);
    setWarehouseNamesFlag(true);
    setAddItem(true);
    let updatedItems = values.items.filter((_, index) => index !== removeIndex);
    setFieldValue('items', updatedItems);
    packageFakeItem = false;
    if (!currentItem.id) return;

    setStatus({
      ...status,
      itemsList: [...status.itemsList, currentItem],
    });
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(values.items);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFieldValue('items', items);
  };
  const handleArrowsKey = (e, index, fieldname) => {
    if (e.key === 'ArrowDown' && index + 1 < values?.items?.length) {
      const element = document.getElementById(
        `items[${index + 1}].${fieldname}`
      );
      setTimeout(() => {
        element.select();
        element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
      }, 100);
    }
  };

  const handleArrowUpKey = (e, index, fieldname) => {
    if (e.key === 'ArrowUp' && index - 1 >= 0) {
      const element = document.getElementById(
        `items[${index - 1}].${fieldname}`
      );
      element.select();
      element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
    }
  };

  const renderSelectedItemsTable = () => {
    const { items } = values;
    if (warehouseList.length > 0) {
      for (let i = 0; i < items.length; i++) {
        for (let j = 0; j < warehouseList.length; j++) {
          if (items[i].warehouse_idd === warehouseList[j].id) {
            items[i].warehouse_idd = warehouseList[j].name;
          }
        }
      }
    }

    return (
      <div>
        {!flag ? <Loader /> : null}
        <table className="list-table form-table border horizontal-items-table create_package form-focus">
          <thead className="table-header">
            <tr className="table--row">
              <th>Items</th>
              <th className="">Warehouse</th>
              <th className="right-align">Ordered</th>
              <th className="right-align">Packed</th>
              <th className="right-align">Stock</th>
              <th className="right-align">Packed Qty.</th>
            </tr>
          </thead>
          {/* <tbody onLoad={getvals()}> */}
          <DragDropItems handleOnDragEnd={handleOnDragEnd} onLoad={getvals()}>
            {/* <DragDropItems> */}
            {items.map((item, index) => {
              return (
                <Draggable
                  key={item.dragDropID}
                  draggableId={item.dragDropID}
                  index={index}
                >
                  {(provided) => (
                    <tr
                      key={index}
                      className="table--row"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <td>
                        <div className="__dragDrop">
                          <div
                            {...provided.dragHandleProps}
                            className={
                              values.items.length >= 2
                                ? '__dragDrop_iconParent'
                                : '__dragDrop_iconParent notDisplay'
                            }
                            tabIndex="-1"
                          >
                            <MdDragIndicator />
                          </div>
                          <div className="__drag-righ">
                            {item.id ? (
                              <ListTableImageColumn
                                name={item.name}
                                images={item.images}
                                sku={item.sku}
                                id={item.id}
                                showModalUponClick={inModal ? false : true}
                                value={item.extra_description}
                                index={index}
                                showExtraDescription={true}
                                onChangeValue={onChangeValueHandler}
                              />
                            ) : (
                              <Field
                                type="dropdown"
                                className={className}
                                value={item.id}
                                options={itemsList}
                                dropdownValue="id"
                                dropdownLabel="name"
                                withImage={true}
                                placeholder="Select Item"
                                additionalSearchFilter="sku,upc,mpn,ean,isbn"
                                clearable={false}
                                handleChange={(option) =>
                                  handleSelectedItem(option, index)
                                }
                                error={getError(
                                  errors,
                                  touched,
                                  `items[${index}].name`
                                )}
                                onBlur={() =>
                                  setFieldTouched(`items[${index}].name`, true)
                                }
                              />
                            )}
                          </div>
                        </div>
                      </td>

                      <td className="right-align">
                        <Field
                          size="large"
                          id="warehouseId"
                          value={
                            typeof item.warehouse_idd === 'string'
                              ? item.warehouse_idd
                              : ''
                          }
                          disabled={true}
                          clearable={false}
                          required
                          error={touched.warehouseId && errors.warehouseId}
                        />
                      </td>
                      <td
                        className="right-align"
                        style={{ textAlign: 'right' }}
                      >
                        <Quantity quantity={item.ordered} unit={item.unit} />
                      </td>
                      <td
                        className="right-align"
                        style={{ textAlign: 'right' }}
                      >
                        <Quantity quantity={item.packed} unit={item.unit} />
                      </td>
                      <td
                        className="right-align"
                        style={{ textAlign: 'right' }}
                      >
                        <Quantity
                          quantity={item.stocks.physical_quantity || 0}
                          unit={item.unit}
                        />
                      </td>
                      <td className="right-align no-padding with-close--btn create_package_input">
                        <Field
                          size="small"
                          type="number"
                          value={item.quantity ? item.quantity : 0}
                          decimalScale={6}
                          id={`items[${index}].quantity`}
                          allowNegative={false}
                          thousandSeparator=","
                          onValueChange={(value) =>
                            handleItemQuantityChange(value, index)
                          }
                          error={
                            (touched.items || submitCount > 0) &&
                            getError(
                              errors,
                              touched,
                              `items[${index}].quantity`
                            )
                          }
                          onBlur={handleBlur}
                          onKeyDown={(e) =>
                            handleArrowsKey(e, index, 'quantity')
                          }
                          onKeyUp={(e) =>
                            handleArrowUpKey(e, index, 'quantity')
                          }
                        />
                        <button
                          type="button"
                          disabled={items.length === 1}
                          className="close-btn button"
                          onClick={() => handleRemoveItem(index, item)}
                        >
                          <DeleteIcon className="bin" />
                        </button>
                      </td>
                    </tr>
                  )}
                </Draggable>
              );
            })}
          </DragDropItems>

          {/* </tbody> */}
        </table>
      </div>
    );
  };

  return (
    <Form
      title={title}
      className="package-form package-width-enhancer"
      onSubmit={(event) =>
        formSubmit(event, values.items, setFieldValue, handleSubmit)
      }
    >
      <div className="form-fields-container d-flex flex-wrap so-fields-container position-relative">
        <Field
          name="Package No."
          id="packageNo"
          placeholder="Package No"
          value={values.packageNo}
          // disabled={type === 'edit'}
          disabled
          handleChange={handleChange}
          onBlur={handleBlur}
          required
          error={touched.packageNo && errors.packageNo}
          className="flex-25"
        />
        <Field
          className="flex-25"
          type="dropdown"
          name="Sales Order"
          autoFocus={true}
          id="salesOrderId"
          disabled={inModal || type === 'edit'}
          value={+values.salesOrderId}
          options={salesOrderList}
          dropdownValue="id"
          dropdownLabel="name"
          handleChange={(option) => handleSalesOrderChange(option)}
          clearable={false}
          onBlur={handleBlur}
          required
          error={touched.salesOrderId && errors.salesOrderId}
        />

        <Field
          className="flex-25"
          type="date"
          name="Package Date"
          id="packageDate"
          value={values.packageDate}
          minDate={values.orderDate}
          handleChange={(date) => {
            setFieldValue(
              'packageDate',
              date && date.format('YYYY-MM-DD 00:00:00')
            );
          }}
          onBlur={() => setFieldTouched('packageDate', true)}
          required
          error={touched.packageDate && errors.packageDate}
        />
      </div>
      <div
        className={`form-field ${inModal ? 'uiux_so_package_table' : 'form-table--container'
          }`}
      >
        <label className="form-label">Items Available to Pack</label>
        {loader && (
          <div style={{ marginLeft: '-15rem' }}>
            <Loader />
          </div>
        )}
        <FieldArray name="items" render={() => renderSelectedItemsTable()} />
        {addItem && <React.Fragment>{renderAddItem()} </React.Fragment>}
      </div>

      <FormAction
        disabled={isSubmitting}
        marginFromRight={0}
        onCancel={() => {
          let dataFromLS = getObjFromLS('module');
          let redirectUrl = '';
          if (dataFromLS && dataFromLS.moduleName === 'packages') {
            redirectUrl = dataFromLS.queryParam;
          }
          if (inModal) {
            let response;
            const { checkout } = validateCreatePackageForm(values, staticVal);
            if (!checkout) {
              response = confirmationAlert();
            }
            if (response || checkout) {
              close();
            }
          } else if (
            window.location.pathname.includes('add') ||
            window.location.pathname.includes('edit')
          ) {
            history.push('/packages' + redirectUrl);
          } else {
            setObjInLS('module', { ...dataFromLS, action: null });
            history.push('/r');
          }
        }}
      />
      <ErrorFocus />
    </Form>
  );
};

export default formEnhancer(PackageForm);
