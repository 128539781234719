import { isEqual } from 'lodash';

export const validatePurchaseOrderForm = (data = {}, defaultValues = {}) => {
  const checkEquality = Boolean(isEqual(data, defaultValues));
  let checkout = true;

  if (!checkEquality) {
    checkout = false;
  }
  return { checkout };
};

export const validationNewReceiveForm = (data = {}, defaultValues = {}) => {
  const checkEquality = Boolean(isEqual(data, defaultValues));
  let checkout = true;

  if (!checkEquality) {
    checkout = false;
  }
  return { checkout };
};
