import React, { Component } from 'react';
import { restRequest } from '../../../../../Helpers/RequestHelper';
import Loader from './../../../../../Components/Loader/Loader';
import Dropdown from './../../../../../Components/Dropdown/CustomDropdown';
import TransactionsTable from './TransactionsTable';
import './ItemTransaction.css';
import EmptyTable from '../../../../../Components/ListTable/EmptyTable';
import { checkError } from '../../../../../Helpers/AuthHelper';
import { SmallLoader } from '../../../../../Components';
class ItemTransactionTab extends Component {
  transactionTable = {
    sales_orders: {
      tableHeaders: [
        'Date',
        'Order No.',
        'Customer',
        'Qty.',
        'Total',
        'Status',
      ],
      tableData: [
        'order_date',
        'sales_order_no',
        'display_name',
        'quantity',
        'total',
        'status',
      ],
      rightAlignColumns: [4, 5, 6],
      linkTo: { path: '/salesorders/', linkedColumn: 'sales_order_no' },
    },
    invoices: {
      tableHeaders: [
        'Date',
        'Invoice No.',
        'Customer',
        'Qty.',
        'Amount',
        'Status',
      ],
      tableData: [
        'invoice_date',
        'invoice_no',
        'customer_name',
        'quantity',
        'total',
        'status',
      ],
      rightAlignColumns: [4, 5, 6],
      linkTo: { path: '/invoices/', linkedColumn: 'invoice_no' },
    },
    purchase_orders: {
      tableHeaders: ['Date', 'Order No.', 'Vendor', 'Qty.', 'Total', 'Status'],
      tableData: [
        'order_date',
        'purchase_order_no',
        'vendor_name',
        'quantity',
        'total',
        'status',
      ],
      rightAlignColumns: [4, 5, 6],
      linkTo: { path: '/purchaseorders/', linkedColumn: 'purchase_order_no' },
    },
    bills: {
      tableHeaders: ['Date', 'Bill No.', 'Vendor', 'Qty.', 'Amount', 'Status'],
      tableData: [
        'bill_date',
        'bill_no',
        'vendor_name',
        'quantity',
        'total',
        'status',
      ],
      rightAlignColumns: [4, 5, 6],
      linkTo: { path: '/bills/', linkedColumn: 'bill_no' },
    },
    credit_notes: {
      tableHeaders: [
        'Date',
        'Credit Note No.',
        'Customer Name',
        'Deduction',
        'Status',
      ],
      tableData: [
        'credit_note_date',
        'credit_note_no',
        'contact_name',
        'adjustment_amount',
        'status',
      ],
      rightAlignColumns: [4, 5],
      linkTo: { path: '/creditnotes/', linkedColumn: 'credit_note_no' },
    },
    sales_returns: {
      tableHeaders: [
        'Date',
        'Sales Return No.',
        'Customer Name',
        'Qty.',
        'Status',
      ],
      tableData: [
        'return_date',
        'sales_return_no',
        'contact_name',
        'return_quantity',
        'status',
      ],
      rightAlignColumns: [4, 5],
      linkTo: { path: '/salesreturns/', linkedColumn: 'sales_return_no' },
    },
  };
  filterOptions = []; // options available for current contact depending on item type
  filterStatusOptions = []; // options available for current contact depending on item type
  state = {
    loading: true,
    loadingList: true,
    loadingMore: false,
    activeTab: 'sales_orders',
    activeStatus: 'all',
    list: [],
    currency: {},
    pageNumber: 1,
    viewPerPage: 15,
  };

  componentDidMount() {
    this.getFilerOptions();
    // this.getStatusFilerOptions('sales_orders')
    this.setState({ loading: false });
  }

  getFilerOptions() {
    let options = [];
    this.updateFilter('sales_orders');
    options = [
      {
        id: 'sales_orders',
        label: 'Sales Orders',
        onClick: () => {
          this.updateFilter('sales_orders');
        },
      },
      {
        id: 'purchase_orders',
        label: 'Purchase Orders',
        onClick: () => {
          this.updateFilter('purchase_orders');
        },
      },
      {
        id: 'invoices',
        label: 'Invoices',
        onClick: () => {
          this.updateFilter('invoices');
        },
      },
      {
        id: 'bills',
        label: 'Bills',
        onClick: () => {
          this.updateFilter('bills');
        },
      },
      {
        id: 'sales_returns',
        label: 'Sales Return',
        onClick: () => {
          this.updateFilter('sales_returns');
        },
      },
      {
        id: 'credit_notes',
        label: 'Credit Notes',
        onClick: () => {
          this.updateFilter('credit_notes');
        },
      },
    ];
    this.filterOptions.push(...options);
  }

  getStatusFilerOptions(filter) {
    let Statusoptions = [];
    this.filterStatusOptions = [];
    // this.updateStatusFilter('all')
    // console.log('filter',filter)
    switch (filter) {
      case 'sales_orders':
        Statusoptions = [
          {
            id: 'all',
            label: 'All',
            onClick: () => {
              this.updateStatusFilter('all');
            },
          },
          /* {
             id: 'void',
             label: 'Void',
             onClick: () => { this.updateStatusFilter('void') }
           },
          {
            id: 'onhold',
            label: 'On Hold',
            onClick: () => {
              this.updateStatusFilter('onhold');
            },
          },
          {
            id: 'cancelled',
            label: 'Cancelled',
            onClick: () => {
              this.updateStatusFilter('cancelled');
            },
          },*/
          {
            id: 'draft',
            label: 'Draft',
            onClick: () => {
              this.updateStatusFilter('draft');
            },
          },
          {
            id: 'closed',
            label: 'Closed',
            onClick: () => {
              this.updateStatusFilter('closed');
            },
          },
          {
            id: 'confirmed',
            label: 'Confirmed',
            onClick: () => {
              this.updateStatusFilter('confirmed');
            },
          },
        ];
        break;
      case 'invoices':
        Statusoptions = [
          {
            id: 'all',
            label: 'All',
            onClick: () => {
              this.updateStatusFilter('all');
            },
          },
          {
            id: 'paid',
            label: 'Paid',
            onClick: () => {
              this.updateStatusFilter('paid');
            },
          },
          {
            id: 'overdue',
            label: 'Overdue',
            onClick: () => {
              this.updateStatusFilter('overdue');
            },
          },
          {
            id: 'draft',
            label: 'Draft',
            onClick: () => {
              this.updateStatusFilter('draft');
            },
          },
          {
            id: 'partially paid',
            label: 'PRTL Paid',
            onClick: () => {
              this.updateStatusFilter('partially paid');
            },
          },
          {
            id: 'sent',
            label: 'Sent',
            onClick: () => {
              this.updateStatusFilter('sent');
            },
          },
          /* {
             id: 'void',
             label: 'Void',
             onClick: () => { this.updateStatusFilter('void') }
           }*/
        ];
        break;
      case 'purchase_orders':
        Statusoptions = [
          {
            id: 'all',
            label: 'All',
            onClick: () => {
              this.updateStatusFilter('all');
            },
          },
          {
            id: 'issued',
            label: 'Issued',
            onClick: () => {
              this.updateStatusFilter('issued');
            },
          },
          {
            id: 'draft',
            label: 'Draft',
            onClick: () => {
              this.updateStatusFilter('draft');
            },
          },
          {
            id: 'cancelled',
            label: 'Cancelled',
            onClick: () => {
              this.updateStatusFilter('cancelled');
            },
          },
          {
            id: 'closed',
            label: 'Closed',
            onClick: () => {
              this.updateStatusFilter('closed');
            },
          },
          /*{
            id: 'void',
            label: 'Void',
            onClick: () => { this.updateStatusFilter('void') }
          }*/
        ];
        break;
      case 'bills':
        Statusoptions = [
          {
            id: 'all',
            label: 'All',
            onClick: () => {
              this.updateStatusFilter('all');
            },
          },
          {
            id: 'paid',
            label: 'Paid',
            onClick: () => {
              this.updateStatusFilter('paid');
            },
          },
          {
            id: 'draft',
            label: 'Draft',
            onClick: () => {
              this.updateStatusFilter('draft');
            },
          },
          {
            id: 'partially paid',
            label: 'PRTL Paid',
            onClick: () => {
              this.updateStatusFilter('partially paid');
            },
          },
          {
            id: 'open',
            label: 'Open',
            onClick: () => {
              this.updateStatusFilter('open');
            },
          },
          {
            id: 'overdue',
            label: 'Overdue',
            onClick: () => {
              this.updateStatusFilter('overdue');
            },
          },
          /* {
             id: 'void',
             label: 'Void',
             onClick: () => { this.updateStatusFilter('void') }
           }*/
        ];
        break;
      case 'credit_notes':
        Statusoptions = [
          {
            id: 'all',
            label: 'All',
            onClick: () => {
              this.updateStatusFilter('all');
            },
          },
          {
            id: 'draft',
            label: 'Draft',
            onClick: () => {
              this.updateStatusFilter('draft');
            },
          },
          {
            id: 'open',
            label: 'Open',
            onClick: () => {
              this.updateStatusFilter('open');
            },
          },
          {
            id: 'partially applied',
            label: 'PRTL Applied',
            onClick: () => {
              this.updateStatusFilter('partially applied');
            },
          },
          {
            id: 'consumed',
            label: 'Consumed',
            onClick: () => {
              this.updateStatusFilter('consumed');
            },
          },
        ];
        break;
      case 'sales_returns':
        Statusoptions = [
          {
            id: 'all',
            label: 'All',
            onClick: () => {
              this.updateStatusFilter('all');
            },
          },
          {
            id: 'draft',
            label: 'Draft',
            onClick: () => {
              this.updateStatusFilter('draft');
            },
          },
          {
            id: 'confirmed',
            label: 'Confirmed',
            onClick: () => {
              this.updateStatusFilter('confirmed');
            },
          },
          {
            id: 'receiving',
            label: 'PRTL Received',
            onClick: () => {
              this.updateStatusFilter('receiving');
            },
          },
          {
            id: 'closed',
            label: 'Received',
            onClick: () => {
              this.updateStatusFilter('closed');
            },
          },
        ];
        break;
      default:
        Statusoptions = [
          {
            id: 'all',
            label: 'All',
            onClick: () => {
              this.updateStatusFilter('all');
            },
          },
          /*{
            id: 'void',
            label: 'Void',
            onClick: () => { this.updateStatusFilter('void') }
          },*/
          {
            id: 'onhold',
            label: 'On Hold',
            onClick: () => {
              this.updateStatusFilter('onhold');
            },
          },
          {
            id: 'draft',
            label: 'Draft',
            onClick: () => {
              this.updateStatusFilter('Draft');
            },
          },
          {
            id: 'cancelled',
            label: 'Cancelled',
            onClick: () => {
              this.updateStatusFilter('cancelled');
            },
          },
          {
            id: 'closed',
            label: 'Closed',
            onClick: () => {
              this.updateStatusFilter('closed');
            },
          },
          {
            id: 'confirmed',
            label: 'Confirmed',
            onClick: () => {
              this.updateStatusFilter('confirmed');
            },
          },
        ];
        break;
    }
    this.filterStatusOptions.push(...Statusoptions);
    // console.log('this.filterStatusOptions',this.filterStatusOptions)
  }

  PrepareSsaleOrderTableData = (res) => {
    const transactionDetails = res.transactions?.data.map((item, index) => {
      let total = 0;
      let quantity = 0;
      let price = null;
      item.sales_order_details_for_item_transaction.forEach((element) => {
        total = total + element.amount;
        quantity = quantity + element.quantity;
        // price = price ? price + ',' + element.rate : element.rate;
      });
      return {
        order_date: item.order_date,
        display_name: item.display_name,
        sales_order_no: item.sales_order_no,
        status: item.status,
        total: total && Number(total).toFixed(2),
        quantity: quantity,
        id: item.id,
        // price: price,
      };
    });
    return transactionDetails;
  };

  PreparePOTableData = (res) => {
    const transactionDetails = res.transactions?.data.map((item, index) => {
      let total = 0;
      let quantity = 0;
      let price = null;
      item.purchase_order_item_details.forEach((element) => {
        total = total + element.amount;
        quantity = quantity + element.quantity;
        // price = price ? price + ',' + element.rate : element.rate;
      });
      return {
        order_date: item.order_date,
        vendor_name: item.item_transaction_contact.display_name,
        purchase_order_no: item.purchase_order_no,
        status: item.status,
        total: total,
        quantity: quantity,
        id: item.id,
        // price: price,
      };
    });
    return transactionDetails;
  };

  PrepareINVTableData = (res) => {
    const transactionDetails = res.transactions?.data.map((item, index) => {
      let total = 0;
      let quantity = 0;
      let price = null;
      item.invoice_details.forEach((element) => {
        total = total + element.amount;
        quantity = quantity + element.quantity;
        // price = price ? price + ',' + element.rate : element.rate;
      });
      return {
        invoice_date: item.invoice_date,
        customer_name: item.display_name,
        invoice_no: item.invoice_no,
        status: item.status,
        total: total,
        quantity: quantity,
        id: item.id,
        // price: price,
      };
    });
    return transactionDetails;
  };

  PrepareBillsTableData = (res) => {
    const transactionDetails = res.transactions?.data.map((item, index) => {
      let total = 0;
      let quantity = 0;
      let price = null;
      item.bill_item_details.forEach((element) => {
        total = total + element.amount;
        quantity = quantity + element.quantity;
        // price = price ? price + ',' + element.rate : element.rate;
      });
      return {
        bill_date: item.bill_date,
        vendor_name: item.item_transaction_contact.display_name,
        bill_no: item.bill_no,
        status: item.status,
        total: total,
        quantity: quantity,
        id: item.id,
        // price: price,
      };
    });
    return transactionDetails;
  };
  PrepareSRTableData = (res) => {
    const transactionDetails = res.transactions?.data.map((item, index) => {
      let quantity = 0;
      item.sales_return_details.forEach((element) => {
        quantity = quantity + element.quantity;
      });
      return {
        return_date: item.sales_return_date,
        contact_name: item.item_transaction_contact.display_name,
        sales_return_no: item.sales_return_no,
        status: item.status,
        return_quantity: quantity,
        id: item.id,
        // price: price,
      };
    });
    return transactionDetails;
  };

  PrepareCreditNotesTableData = (res) => {
    const transactionDetails = res.transactions?.data.map((item, index) => {
      let amount = 0;
      item.credit_note_details.forEach((element) => {
        amount = amount + Number(element.adjustment_amount);
      });
      return {
        credit_note_date: item.sales_credit_note_date,
        contact_name: item.item_transaction_contact.display_name,
        credit_note_no: item.credit_note_no,
        status: item.status,
        adjustment_amount: amount,
        id: item.id,
      };
    });
    return transactionDetails;
  };

  filterSwitch = (filter, res) => {
    switch (filter) {
      case 'sales_orders':
        return this.PrepareSsaleOrderTableData(res);
      case 'purchase_orders':
        return this.PreparePOTableData(res);
      case 'invoices':
        return this.PrepareINVTableData(res);
      case 'bills':
        return this.PrepareBillsTableData(res);
      case 'sales_returns':
        return this.PrepareSRTableData(res);
      case 'credit_notes':
        return this.PrepareCreditNotesTableData(res);
      default:
        return this.PrepareSsaleOrderTableData(res);
    }
  };

  fetchData(filter, status) {
    this.setState({ loadingList: true });
    const { pageNumber, viewPerPage } = this.state;
    restRequest(
      'get',
      `items/${this.props.itemId}/transaction?filter=${filter}&status=${status}&page=${pageNumber}&view=${viewPerPage}`
    )
      .then((res) => {
        this.setState({
          list: this.filterSwitch(filter, res),
          currency: res.base_currency,
          loadingList: false,
          last_page: res.transactions?.last_page,
        });
        var objDiv = document.querySelector('.detail_component_inner');
        if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
      });
  }

  fetchLoadMoreData = (filter, status) => {
    this.setState({ loadingMore: true });
    const { pageNumber, viewPerPage } = this.state;
    restRequest(
      'get',
      `items/${this.props.itemId}/transaction?filter=${filter}&status=${status}&page=${pageNumber}&view=${viewPerPage}`
    )
      .then((res) => {
        this.setState({
          list: [...this.state.list, ...this.filterSwitch(filter, res)],
          currency: res.base_currency,
          loadingMore: false,
          last_page: res.transactions.last_page,
        });
        var objDiv = document.querySelector('.detail_component_inner');
        if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  };

  // rehan
  // optimize code here
  // componentDidUpdate(){}

  updateFilter(filter) {
    this.setState({ activeTab: filter });
    this.setState({ activeStatus: 'all' }); //done by ahsan
    this.getStatusFilerOptions(filter);
    this.setState(
      {
        pageNumber: 1,
      },
      () => {
        this.fetchData(filter, 'all');
      }
    );
  }

  updateStatusFilter(status) {
    this.setState({ activeStatus: status });
    this.setState(
      {
        pageNumber: 1,
      },
      () => {
        this.fetchData(this.state.activeTab, status);
      }
    );
  }

  handlePageChange = () => {
    const { pageNumber, activeTab, activeStatus } = this.state;
    this.setState(
      {
        pageNumber: pageNumber + 1,
      },
      () => {
        this.fetchLoadMoreData(activeTab, activeStatus);
      }
    );
  };

  renderTransactions(tab) {
    const { loadingMore, loadingList, list, pageNumber, last_page } =
      this.state;
    if (loadingList) {
      return <Loader />;
    }
    if (list.length === 0) {
      return <EmptyTable inModal />;
    }
    return (
      <>
        <TransactionsTable
          list={list}
          tableHeaders={this.transactionTable[tab].tableHeaders}
          tableData={this.transactionTable[tab].tableData}
          rightAlignColumns={this.transactionTable[tab].rightAlignColumns}
          linkTo={this.transactionTable[tab].linkTo}
          className="list-table mt_0 items-transaction"
          currencySymbol={this.state.currency.symbol}
        />

        {pageNumber < last_page && (
          <button onClick={this.handlePageChange} className="btn-load-set">
            {loadingMore ? <SmallLoader /> : 'Load More'}
          </button>
        )}
      </>
    );
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }
    let currentTab = this.filterOptions.find(
      (obj) => obj.id === this.state.activeTab
    );
    let currentStatusTab = this.filterStatusOptions.find(
      (obj) => obj.id === this.state.activeStatus
    );
    // console.log(currentStatusTab);
    return (
      <div className="contact-transaction sub_tabs_table_transaction">
        <div className="contact-transaction--header padding--regular-horizontal">
          <span className="section-header--dropdown">
            {/* Because of New design */}
            {/* <span className="section-header--dropdown-title">Filter: </span> */}
            <Dropdown
              textLabel={currentTab.label}
              options={this.filterOptions}
            />
          </span>

          <div className="contact-transaction--filter">
            {/* <div className="contact-transaction--dropdown-title semi-bold">Status: </div> */}
            <Dropdown
              textLabel={'Status: ' + currentStatusTab.label}
              options={this.filterStatusOptions}
            />
          </div>
        </div>
        {this.renderTransactions(this.state.activeTab)}
      </div>
    );
  }
}

export default ItemTransactionTab;
