import React, { Component } from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import moment from 'moment';
import { restRequest } from '../../../Helpers/RequestHelper';
import { getOrganizationDate } from '../../../Helpers/Date/OrganizationDate';
import Loader from '../../Loader/Loader';
import NotFound from '../../NotFound/NotFound';
import { getObjFromLS } from '../../../Helpers/LocalStorage';
import PackageFormEnhancer from './PackageFormEnhancer';
import pageIdentify from '../../../Helpers/PageIdentify';
import './PackageForm.css';
import { checkError } from '../../../Helpers/AuthHelper';
import { GetDateString } from '../../../Helpers';

let warehouseResult = [];
class PackageForm extends Component {
  type = this.props.type; // "add" or "edit" form
  id = this.props.id || this.props.match.params.id || null;

  emptyItem = {
    id: null,
    salesOrderItemDetailId: null,
    name: '',
    image: '',
    sku: '',
    unit: 'unit',
    ordered: 0,
    packed: 0,
    stocks: 0,
    quantity: null,
    extra_description: '',
    dragDropID: GetDateString(),
  };

  state = {
    packageInfo: {
      packageNo: '',
      salesOrderId: null,
      packageDate: null,
      orderDate: null,
      items: [this.emptyItem],
    },
    // New Functionality
    warehouseList: [],
    genericWarehouseId: null,
    itemsList: [],
    salesOrderList: [],
    currency: {
      currency_code: '',
      name: '',
      symbol: '',
    },
    loading: true,
    notFound: false,
    showPrompt: false,
  };

  componentDidMount() {
    let module = getObjFromLS('module');
    this.id =
      !pageIdentify(window.location.pathname) &&
      window.location.pathname.includes('packages') &&
      module
        ? module.id
        : this.id;
    this.fetchData();
    sessionStorage.setItem('once', false);
    sessionStorage.setItem('fullscreen', false);
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  async fetchData() {
    if (this.type !== 'edit') {
      const salesOrderId = await this.setSalesOrderId();

      if (!salesOrderId) {
        this.props.handleToast(
          'No items left to be packaged. Create a sales order first.',
          'error'
        );
        this.setState({ loading: false });
        return;
      }
    }
    switch (this.type) {
      case 'edit':
        await this.fetchPackageEditData();
        break;
      case 'add':
      default:
        await this.fetchPackageCreateData(this.state.packageInfo.salesOrderId);
    }

    this.setState({ loading: false });
  }

  async setSalesOrderId() {
    if (this.props.inModal) {
      this.setState({
        packageInfo: {
          ...this.state.packageInfo,
          salesOrderId: this.props.salesOrderId,
        },
      });
      return this.props.salesOrderId;
    }

    const res = await restRequest('get', 'packages/create');
    if (res.sales_order_list.length) {
      const salesOrderList = res.sales_order_list.map((salesorder) => ({
        id: salesorder.id,
        name: salesorder.sales_order_no,
      }));
      this.setState({
        salesOrderList: salesOrderList,
        packageInfo: {
          ...this.state.packageInfo,
          salesOrderId: salesOrderList[0].id,
        },
      });
      return salesOrderList[0].id;
    }
  }

  prepareEditItemsList(
    itemsArray,
    salesOrderItemDetails,
    packageSalesOrder,
    genericWareId
  ) {
    // console.log('packageSalesOrderpackageSalesOrder' , salesOrderItemDetails)
    return itemsArray.map((item, index) => ({
      id: item.item_id,
      salesOrderItemDetailId: item.sales_order_item_detail_id,
      dragDropID: GetDateString(),
      packageItemDetailId: item.id,
      name: item.item.name,
      images: item.item.images,
      sku: item.item.sku,
      unit: item.item.unit,
      warehouse_idd: salesOrderItemDetails[index].warehouse_id
        ? salesOrderItemDetails[index].warehouse_id
        : genericWareId,
      ordered: salesOrderItemDetails.find(
        (salesorderItem) => item.item_id === salesorderItem.item_id
      ).quantity,
      packed:
        salesOrderItemDetails.find(
          (salesorderItem) => item.item_id === salesorderItem.item_id
        ).packed - item.quantity,
      stocks:
        item.item.stocks.find(
          (stock) => stock.warehouse_id === packageSalesOrder.warehouse_id
        ) || {},
      quantity: item.quantity,
      extra_description: item.extra_description ? item.extra_description : '',
    }));
  }

  prepareItemsList(itemsArray, packageSalesOrder) {
    return itemsArray.map((item) => ({
      id: item.item_id,
      salesOrderItemDetailId: item.id,
      name: item.items.name,
      images: item.items.images,
      sku: item.items.sku,
      unit: item.items.unit,
      ordered: item.quantity,
      packed: item.packed,
      stocks:
        item.items.stocks.find(
          (stock) => stock.warehouse_id === packageSalesOrder.warehouse_id
        ) || {},
      quantity: item.remaining_quantity,
      warehouse_idd: item.warehouse_id,
      extra_description: item.extra_description ? item.extra_description : '',
    }));
  }

  fetchPackageEditData() {
    return restRequest('get', `packages/${this.id}/edit`)
      .then((res) => {
        warehouseResult = res.warehouse_list;
        if (res.package.warehouse_id)
          this.setState({ genericWarehouseId: res.package.warehouse_id });

        let arr = [];
        for (let i = 0; i < res.sales_order_item_details.length; i++) {
          arr = this.state.warehouseList;
          arr.push(res.sales_order_item_details[i].warehouse_id);
          this.setState({ warehouseList: arr });
        }

        const packageInfo = {
          ...this.state.package,
          packageNo: res.package.package_no,
          salesOrderId: res.package.sales_order_id,
          packageDate: moment(res.package.package_date).format(
            'YYYY-MM-DD 00:00:00'
          ),
          orderDate: res?.package?.sales_order?.order_date
            ? moment(res.package.sales_order.order_date)
            : null,
          items: this.prepareEditItemsList(
            res.package.package_details,
            res.sales_order_item_details,
            res.package.sales_order,
            res.package.warehouse_id
          ),
        };

        const leftoverItems = this.prepareItemsList(
          res.left_over_items,
          res.package.sales_order
        );
        this.setState({
          packageInfo: {
            ...this.state.packageInfo,
            ...packageInfo,
            items: [...packageInfo.items],
          },
          itemsList: leftoverItems,
        });
        if (res.sales_order_list.length) {
          const salesOrderList = res.sales_order_list.map((salesorder) => ({
            id: salesorder.id,
            name: salesorder.sales_order_no,
          }));
          this.setState({
            salesOrderList: salesOrderList,
            packageInfo: {
              ...this.state.packageInfo,
              salesOrderId: res.package.sales_order.id,
            },
          });
        }
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
        if (this.props.inModal) {
          this.props.close();
        } else {
          this.setState({
            loading: false,
            notFound: true,
          });
        }
      });
  }

  prepareAddItemsList(arrayList, salesOrder) {
    return arrayList.map((item, index) => ({
      id: item.item_id,
      salesOrderItemDetailId: item.id,
      dragDropID: GetDateString(),
      name: item.items.name,
      images: item.items.images,
      sku: item.items.sku,
      unit: item.items.unit,
      warehouse_idd: salesOrder.sales_order_details[index].warehouse_id
        ? salesOrder.sales_order_details[index].warehouse_id
        : salesOrder.warehouse_id,
      ordered: item.quantity,
      packed: item.packed,
      stocks:
        item.items.stocks.find(
          (stock) => stock.warehouse_id === salesOrder.warehouse_id
        ) || {},
      quantity: item.remaining_quantity,
      extra_description: item.extra_description ? item.extra_description : '',
    }));
  }

  fetchPackageCreateData = async (salesOrderId) => {
    if (salesOrderId === null) {
      return;
    }
    return await restRequest(
      'get',
      `packages/create?sales_order_id=${salesOrderId}`
    )
      .then((res) => {
        warehouseResult = res.warehouse_list;
        if (res.sales_order.warehouse_id)
          this.setState({ genericWarehouseId: res.sales_order.warehouse_id });

        let arr = [];
        for (let i = 0; i < res.sales_order.sales_order_details.length; i++) {
          arr = this.state.warehouseList;
          arr.push(res.sales_order.sales_order_details[i].warehouse_id);
          this.setState({ warehouseList: arr });
        }

        const packageInfo = {
          ...this.state.packageInfo,
          packageNo: res.package_no,
          packageDate:
            res?.sales_order?.order_date &&
            res.sales_order.order_date <
              moment(getOrganizationDate()).format('YYYY-MM-DD 00:00:00')
              ? moment(getOrganizationDate()).format('YYYY-MM-DD 00:00:00')
              : res.sales_order.order_date,
          orderDate: res?.sales_order?.order_date
            ? moment(res.sales_order.order_date)
            : null,
          items: this.prepareAddItemsList(
            res.sales_order.sales_order_details,
            res.sales_order
          ),
        };
        this.setState({
          packageInfo: {
            ...packageInfo,
            items:
              packageInfo.items.length > 0
                ? [...packageInfo.items]
                : [this.emptyItem],
          },
          itemsList: [],
        });
        if (res.sales_order_list.length) {
          const salesOrderList = res.sales_order_list.map((salesorder) => ({
            id: salesorder.id,
            name: salesorder.sales_order_no,
          }));
          if (this.props.inModal) {
            this.setState({
              salesOrderList: salesOrderList,
              packageInfo: {
                ...this.state.packageInfo,
                salesOrderId: salesOrderList[0]?.id,
              },
            });
          } else {
            this.setState({
              packageInfo: {
                ...this.state.packageInfo,
                salesOrderId: salesOrderList[0]?.id,
              },
            });
          }
        }
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, 'error')
        if (this.props.inModal) {
          this.props.close();
        }
      });
  };

  submitRequestType = () => {
    switch (this.type) {
      case 'edit':
        return 'put';
      case 'add':
      default:
        return 'post';
    }
  };

  submitRequestUrl = () => {
    switch (this.type) {
      case 'edit':
        return `packages/${this.id}`;
      case 'add':
      default:
        return 'packages';
    }
  };

  successMessage = () => {
    if (this.type === 'add') {
      this.props.handleToast('Package created successfully', 'success');
    } else if (this.type === 'edit') {
      this.props.handleToast('Package has been updated', 'success');
    }
  };

  handlePrompt = (status = true) => this.setState({ showPrompt: status });

  renderPackageForm() {
    const { packageInfo, itemsList, salesOrderList, currency } = this.state;

    const { title, inModal, close, onSubmit, handleToast, history } =
      this.props;
    let warehouseListProp = this.state.warehouseList;
    let warehouseIdForNullCase = this.state.genericWarehouseId;
    return (
      <>
        <Prompt
          when={this.state.showPrompt}
          message="Are you sure you want to leave page while changes made?"
        />
        <div className="pad-mar-left-2r">
          <PackageFormEnhancer
            warehouseListRecieve={warehouseListProp}
            genericWarehouseId={warehouseIdForNullCase}
            title={title}
            type={this.type}
            warehouseResult={warehouseResult}
            packageInfo={packageInfo}
            inModal={inModal || null}
            salesOrderList={salesOrderList}
            itemsList={itemsList}
            emptyItem={this.emptyItem}
            id={this.id}
            currency={currency}
            submitRequestType={this.submitRequestType}
            submitRequestUrl={this.submitRequestUrl}
            successMessage={this.successMessage}
            close={close}
            onSubmit={onSubmit}
            history={history}
            handleToast={handleToast}
            setPrompt={this.handlePrompt}
            handlePrompt={this.handlePrompt}
            className={this.props.className}
          />
        </div>
      </>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    return this.renderPackageForm();
  }
}

export default withRouter(PackageForm);
