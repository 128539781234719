import React from 'react'

export default ({
  className = '',
  onClick
}) => (
<svg className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="12.5" height="15" viewBox="0 0 12.5 15">
  <path d="M9.375-15a1.217,1.217,0,0,0-.9.352,1.217,1.217,0,0,0-.352.9v.625H4.375v1.25H5v10A1.8,1.8,0,0,0,5.557-.557,1.8,1.8,0,0,0,6.875,0h7.5a1.8,1.8,0,0,0,1.318-.557,1.8,1.8,0,0,0,.557-1.318v-10h.625v-1.25h-3.75v-.625a1.217,1.217,0,0,0-.352-.9,1.217,1.217,0,0,0-.9-.352Zm0,1.25h2.5v.625h-2.5ZM6.25-11.875H15v10a.6.6,0,0,1-.186.439.6.6,0,0,1-.439.186h-7.5a.6.6,0,0,1-.439-.186.6.6,0,0,1-.186-.439ZM7.5-10v6.875H8.75V-10ZM10-10v6.875h1.25V-10Zm2.5,0v6.875h1.25V-10Z" transform="translate(-4.375 15)"/>
</svg>
  )
