import React, { Fragment, useState } from 'react';
import { Route, Switch, Redirect, withRouter, Prompt } from 'react-router-dom';
import Card from '../../Components/Card/Card';
import Nav from '../Nav/Nav';
import EcomNav from '../Nav/EcomNav';

const NavLayout = ({
  match,
  handleToast,
  routes = [], // path, title, component
  wareHouseData = [],
  wareHouseConnecHandler = () => {},
  crossBtnRoute = '/settings',
  cssprp = 'card-body',
  ecomModule = false,
}) => {
  const [showPrompt, setPrompt] = useState(false);

  const handlePrompt = (status = true) => setPrompt(status);

  return (
    <Fragment>
      <Prompt
        when={showPrompt}
        message="Are you sure you want to leave page while changes made?"
      />
      {ecomModule ? <EcomNav links={routes} /> : <Nav links={routes} />}
      <Switch>
        {routes.map((route, index) => (
          <Route
            key={index}
            exact
            path={route.path}
            render={(props) => (
              <Card
                showCrossBtn
                crossBtnRoute={crossBtnRoute}
                className={
                  route.title === 'Sales Return Policy'
                    ? 'sale_return_policy'
                    : ''
                }
                title={route.title}
                type={route.type}
                handleToast={handleToast}
                wareHouseData={wareHouseData}
                wareHouseConnecHandler={wareHouseConnecHandler}
                cssprp={cssprp}
              >
                <route.component
                  {...props}
                  handleToast={handleToast}
                  wareHouseData={wareHouseData}
                  wareHouseConnecHandler={wareHouseConnecHandler}
                  showPrompt={showPrompt}
                  setPrompt={handlePrompt}
                />
              </Card>
            )}
          />
        ))}
        <Redirect to={match.path} />
      </Switch>
    </Fragment>
  );
};

export default withRouter(NavLayout);
