import React, { Component, Fragment } from 'react';
import { MdExpandMore } from '../../Common/Icons';
import { restRequest } from '../../Helpers/RequestHelper';
import FormSection from '../Form/FormSection/FormSection';
import MatchingTable from '../Table/MatchingTable';
import FormAction from '../Form/FormAction';
import { removeValueFromLS } from '../../Helpers/LocalStorage';
import Loader from '../../Components/Loader/Loader';
import { checkError } from '../../Helpers/AuthHelper';
class ImportSummary extends Component {
  state = {
    collapseUnmappedFields: true,
    collapseWrongFields: true,
    disableNext: false,
    isLoading: false,
  };
  handleImportSummary = (event) => {
    event.preventDefault();
    let data = new FormData();
    data.append('file', this.props.csvFile.file);
    data.append('map', JSON.stringify(this.props.csvMap));
    data.append('duplicate', this.props.duplicate);
    data.append('importType', this.props.importType);
    this.setState({
      disableNext: true,
      isLoading: true,
    });
    //console.log("map", data);
    restRequest('post', `${this.props.importAPICall}`, data)
      .then((res) => {
        if (res.limitError && res.limitError !== null) {
          this.props.handleToast(res.limitError, 'error');
          if (window.location.pathname.includes('contacts')) {
            return this.props.history.push('/contacts');
          } else if (window.location.pathname.includes('items')) {
            return this.props.history.push('/items');
          }
        }
        let url = this.props.history.location.pathname;
        let returnURL = url.split('/')[url.split('/').length - 2];
        removeValueFromLS('module');
        this.props.handleToast(
          'Your file has been imported successfully',
          'success'
        );
        this.props.history.push(`/${returnURL}`);
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
        this.setState({
          isLoading: false,
        });
        let url = this.props.history.location.pathname;
        let returnURL = url.split('/')[url.split('/').length - 2];
        this.props.history.push(`/${returnURL}`);
      });
  };

  renderSuccessfulImports() {
    return (
      <section>
        <div className="subtitle">
          Ready to be imported as in csv file, Please correct mentioned records
          or we will import others according to our defined rules
        </div>
        <div>{this.props.success}</div>
      </section>
    );
  }

  renderTWrongfulImports() {
    return (
      <section>
        <div className="subtitle">Total wrong records</div>
        <div>{this.props.totalWrong}</div>
      </section>
    );
  }

  renderSkippedRows() {
    return (
      <section>
        <div className="subtitle">Skipped Rows</div>
        <MatchingTable
          headers={['CSV Row', 'Reason']}
          data={this.props.skipped.map((row) => {
            const data1 = row.csvRow;
            const data2 = (
              <div>
                {row.errors.map((error, index) => (
                  <div key={index}>{error}</div>
                ))}
              </div>
            );
            return { data1, data2 };
          })}
        />
      </section>
    );
  }

  renderUnmappedFields() {
    return (
      <section>
        <div
          className="subtitle inline pointer"
          onClick={() =>
            this.setState((state) => ({
              collapseUnmappedFields: !state.collapseUnmappedFields,
            }))
          }
        >
          <MdExpandMore className="material-icon no-margin" />
          <span>Unmapped Fields</span>
          <span className="">{` (${this.props.unmapped.length})`}</span>
        </div>

        {!this.state.collapseUnmappedFields &&
          this.props.unmapped.map((fieldName, index) => (
            <div
              className="padding--regular-horizontal margin-bottom-sm"
              key={index}
            >
              {fieldName}
            </div>
          ))}
      </section>
    );
  }

  renderWrongIndexes() {
    return (
      <section>
        <div
          className="subtitle inline pointer"
          onClick={() =>
            this.setState((state) => ({
              collapseWrongFields: !state.collapseWrongFields,
            }))
          }
        >
          <MdExpandMore className="material-icon no-margin" />
          <span>Wrong Indexes</span>
          <span className="">{` (${this.props.wrongRecord.length})`}</span>
        </div>
        {!this.state.collapseWrongFields &&
          /*this.props.wrongRecord.map((csvIndex, index) => (
                <div className='padding--regular-horizontal margin-bottom-sm' key={index}>{csvIndex}</div>
              ))*/
          this.props.wrongRecord.map((index) => (
            //console.log("this.state.collapseWrongFields", index)
            <div
              className="padding--regular-horizontal margin-bottom-sm"
              key={index.csvIndex}
            >
              {index.csvIndex}
            </div>
          ))}
      </section>
    );
  }

  render() {
    return this.state.isLoading ? (
      <Fragment>
        <div className="import_summary_loader">
          <Loader />{' '}
          <div>
            Please wait while{' '}
            {window.location.pathname.includes('contacts') && 'contacts'}{' '}
            {window.location.pathname.includes('items') && 'items'} are
            importing...
          </div>
        </div>
      </Fragment>
    ) : (
      <FormSection title="Import Summary">
        <form onSubmit={this.handleImportSummary}>
          {this.renderSuccessfulImports()}
          {this.props.skipped !== 0 && this.renderSkippedRows()}
          {this.renderUnmappedFields()}
          {this.props.totalWrong !== 0 && this.renderTWrongfulImports()}
          {this.props.wrongRecord.length !== 0 && this.renderWrongIndexes()}
          <FormAction
            submitLabel="Next"
            cancelLabel="Go Back"
            disabled={this.state.disableNext}
            onCancel={() => this.props.previousStep()}
          />
        </form>
      </FormSection>
    );
  }
}

export default ImportSummary;
