import React, { Component, Fragment } from 'react';
import General from './General';
import Layout, { Header } from '../../Components/Layout/Layout'; //, Body
import SettingsPageIcon from '../../Assets/General/SettingPageIcon';
import { restRequest, checkError, ssoMyProfilePath } from '../../Helpers';
import { Prompt } from 'react-router-dom';
import './MyAccount.css';
import { Loader } from '../../Components';
import {setDeploymentTime} from "../../Helpers/AuthHelper";

class MyAccount extends Component {
  state = {
    showPrompt: false,
    showAccountInfo: true,
    loading: false,
  };
  componentDidMount() {
    document.title = 'My Account';
    // this.handleSettingSSO();
    this.fetchDeployment();
    window.onbeforeunload = function () {
      return true;
    };
  }
  handleSettingSSO = () => {
    const sso_connected =
      process.env.REACT_APP_SSO_ENABLED === 'false' ? false : true;
    const { history } = this.props;
    this.setState({
      loading: true,
    });
    // restRequest('get', 'ssoSettings')
    //   .then((res) => {
    if (sso_connected) {
      window.open(ssoMyProfilePath());
      history.push('/');
      this.setState({
        showAccountInfo: false,
      });
    } else {
      this.setState({
        loading: false,
        showAccountInfo: true,
      });
    }
    // })
    // .catch((error) => {
    //   this.setState({
    //     loading: false,
    //     showAccountInfo:true
    //   });
    // });
  };
  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  fetchDeployment() {
    restRequest('get', `deploymentTime`)
      .then((res) => {
        setDeploymentTime(res);
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
  }

  handlePrompt = (check = true) => {
    this.setState({
      showPrompt: check,
    });
  };

  render() {
    const { handleToast, history } = this.props;
    const { showPrompt, showAccountInfo, loading } = this.state;
    if (loading) return <Loader />;
    return (
      <Fragment>
        <Prompt
          when={showPrompt}
          message="Are you sure you want to leave page while changes made?"
        />
        <Layout>
          <Header Icon={SettingsPageIcon} title="Settings - My Account" />
          <div className="float-left w-100">
            <div className="float-left w-100">
              {showAccountInfo && (
                <General
                  handleToast={handleToast}
                  className="float-left w-100"
                  history={history}
                  setPrompt={this.handlePrompt}
                />
              )}
            </div>
          </div>
        </Layout>
      </Fragment>
    );
  }
}

export default MyAccount;
