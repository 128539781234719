import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import ActionButton from '../../Components/ActionButton/ActionButton';
import { MdOutlineMoreVert } from '../../Common/Icons';
import { imagePathS3 } from '../../Helpers/RequestHelper';
import getStatusColor from '../../Helpers/GetStatusColor';
import CustomDropdown from '../Dropdown/CustomDropdown';
import getDate from '../../Helpers/Date/GetDate';
import { FaArrowUp, FaArrowDown } from '../../Common/Icons';

import './Table.css';

const Table = ({
  list = [],
  tableHeaders,
  tableData,
  bottomRow = {},
  // rightAlignColumns = [],
  className = false,
  hasImage = false,
  hasSKU = false,
  inModal = false,
  options,
  fromTab = false,
  linkTo, // format = { path: '/base/', linkedColumn: 'columnName' } notice it includes last slash in path,
  onClick, // send onClick prop function, format = { handlerFunction: (), linkedColumn: 'columnName'}
  history,
  handleSorting = false,
  RowIcon = false,
  orderBy = '',
  sortBy = '',
  rowColor = false,
  // paymentReport = false
}) => {
  const handleColumnClick = (id, data) => {
    if (linkTo && linkTo.linkedColumn === data)
      history.push(`${linkTo.path}${id}`);
    if (onClick && onClick.linkedColumn === data) onClick.handlerFunction(id);
    return;
  };

  const showImage = (images) => <img src={imagePathS3(images[0])} alt="" />;

  const renderCellData = (name, value, object) => {
    if (hasImage && (name === 'image' || name === 'images')) {
      return showImage(value);
    }
    if (hasSKU && (name === 'itemName' || name === 'productName')) {
      let SKU = object.sku || object.SKU || object.itemSKU;
      return (
        <Fragment>
          <div>{value}</div>
          <div className="table-data--baseline">{`SKU: ${SKU}`}</div>
        </Fragment>
      );
    }
    if (name === 'status') {
      if (
        tableHeaders[0] === 'Sales Return No.' ||
        tableHeaders[1] === 'Sales Return No.'
      ) {
        return (
          <span
            className={
              value === 'receiving'
                ? getStatusColor('partially received')
                : value === 'closed'
                ? getStatusColor('received')
                : getStatusColor(value)
            }
          >
            {value === 'receiving'
              ? 'PRTL Received'
              : value === 'closed'
              ? 'Received'
              : value}
          </span>
        );
      } else {
        return (
          <span className={getStatusColor(value)}>
            {value === 'partially paid'
              ? 'PRTL Paid'
              : value === 'partially applied'
              ? 'PRTL Applied'
              : value}
          </span>
        );
      }
    }
    if (name === 'created_on') {
      return getDate(value);
    }
    if (name === 'details')
      return <span className="word-break-word">{value}</span>;
    return value;
  };

  const handleSort = (name) => {
    if (!handleSorting) return;
    handleSorting(name);
  };

  return (
    <table
      className={`table sub_tabs_transaction_main  ${
        className ? className : ''
      }`}
    >
      <thead className="table-header hassan transaction_tab_table">
        <tr className="table--row">
          {tableHeaders.map((header, index) => {
            return (
              <th
                key={index}
                onClick={() =>
                  tableData[index] !== 2 && header !== 'Invoice No'
                    ? handleSort(tableData[index])
                    : ''
                }
                className={`
                ${header === 'Discount' ? 'invoice-discount-th' : ''}
                ${
                  tableData[index] !== 2 && header !== 'Invoice No'
                    ? 'pointer'
                    : ''
                }
                ${
                  tableData[index] === sortBy &&
                  (orderBy === 'asc' ? 'ascending' : 'descending')
                }
                `}
                /*${handleSorting ? 'pointer' : ''}*/
              >
                {header}
                {tableData[index] === sortBy &&
                  (orderBy === 'asc' ? <FaArrowUp /> : <FaArrowDown />)}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="">
        {list.map((obj, listIndex) => {
          if (Object.prototype.hasOwnProperty.call(obj, 'stock_on_hand')) {
            if (obj.stock_on_hand < 0) {
              return (
                <tr
                  key={listIndex}
                  className="table--row"
                  style={{ color: 'red' }}
                >
                  {RowIcon && (
                    <td className="table--row--icon">{<RowIcon />}</td>
                  )}
                  {tableData.map((data, index) => {
                    return (
                      <td
                        key={index}
                        data-heading={tableHeaders[index]}
                        className={classNames(
                          linkTo && data === linkTo.linkedColumn ? 'link' : '',
                          onClick && data === onClick.linkedColumn
                            ? 'pointer'
                            : '',
                          hasImage && (data === 'image' || data === 'images')
                            ? 'table--row-image'
                            : ''
                        )}
                        onClick={() => handleColumnClick(obj.id, data)}
                      >
                        {renderCellData(data, obj[data], obj)}
                        {options && data === 'options' && (
                          <CustomDropdown
                            icon={<MdOutlineMoreVert />}
                            options={options}
                            id={obj.id}
                            listIndex={listIndex}
                          />
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            }
          }
          return (
            <tr
              key={listIndex}
              className={`table--row ${
                rowColor && obj?.rowColor && 'unsyncItems'
              }`}
            >
              {RowIcon && <td className="table--row--icon">{<RowIcon />}</td>}
              {tableData.map((data, index) => {
                return (
                  <td
                    key={index}
                    data-heading={tableHeaders[index]}
                    className={classNames(
                      linkTo && data === linkTo.linkedColumn ? 'link' : '',
                      onClick && data === onClick.linkedColumn ? 'pointer' : '',
                      hasImage && (data === 'image' || data === 'images')
                        ? 'table--row-image'
                        : '',
                      inModal ? 'pointerOut' : 'pointerIn'
                    )}
                    onClick={() => handleColumnClick(obj.id, data)}
                  >
                    {renderCellData(data, obj[data], obj)}
                    {options && data === 'options' && (
                      <CustomDropdown
                        icon={
                          !fromTab ? <MdOutlineMoreVert /> : <ActionButton />
                        }
                        options={options}
                        id={obj.id}
                        listIndex={listIndex}
                      />
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
      {Object.values(bottomRow).length !== 0 && (
        <tfoot className="table--bottom-row bold">
          <tr>
            {/* {
              paymentReport ? <td className='payment-report-main-td' colSpan={tableData.length - Object.values(bottomRow).length}></td> : ''
            } */}
            <td
              colSpan={tableData.length - Object.values(bottomRow).length}
              style={{
                textAlign: 'right',
                paddingRight: '30px',
              }}
            >
              Total
            </td>
            {Object.values(bottomRow).map((col, index) => (
              <td key={index} className="right-align">
                {col}
              </td>
            ))}
          </tr>
        </tfoot>
      )}
    </table>
  );
};

export default withRouter(Table);
