import React, { useEffect } from 'react';
import SalesOrderForm from '../../Components/Form/SalesOrderForm/SalesOrderForm';
import FormLayout from '../../Components/Layout/FormLayout';
import { SalesOrderIcon } from '../../Assets/Navigation/NavigationIcons';
import { useLocation } from 'react-router-dom';
import { getObjFromLS } from '../../Helpers';

const AddSalesOrder = ({ handleToast }) => {
  const location = useLocation();
  useEffect(() => {
    document.title = 'Add Sales Orders';
  }, []);

  const handleRedirection = () => {
    const { search = '' } = location;
    let dataFromLS = getObjFromLS('module');
    if (dataFromLS.moduleName === 'contacts' && search) return '/contacts';
    else return '/salesorders';
  };

  return (
    <FormLayout
      title="Create Sales Orders"
      Icon={SalesOrderIcon}
      // closeUrl="/salesorders"
      closeUrl={handleRedirection()}
      iconstyle="general_module_icon"
      layoutBoxClass="uiux-form-redesign"
    >
      <SalesOrderForm type="add" handleToast={handleToast} />
    </FormLayout>
  );
};

export default AddSalesOrder;
