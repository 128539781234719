import React, { Component } from 'react';
import './DashboardReceivables.css';
import CountAndProgress from './../../../Components/CountAndProgress/CountAndProgress';
import { restRequest } from '../../../Helpers/RequestHelper';
import Loader from '../../../Components/Loader/Loader';
import { Link } from 'react-router-dom';
import AccessDenied from '../../../Components/AccessDenied/AccessDenied';
import DataError from '../../../Components/DashboardDataError/DB_DataError';
import { checkError } from '../../../Helpers/AuthHelper';
import {setDashboardAmount} from "../../../Helpers/setAmount";

export default class DashboardReceivables extends Component {
  constructor(props) {
    super();
    this.state = {
      data: {},
      loading: true,
      accessDenied: false,
      accessError: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  //   shouldComponentUpdate(nextProps) {
  //     return (this.props !== nextProps) ? false : true;
  // }

  // fetchData(currentDateRange) {

  dashboard_preferences = JSON.parse(
    localStorage.getItem('dashboardPreferences')
  );

  fetchData(
    currentDateRange = this.dashboard_preferences.receivables,
    startData,
    endDate
  ) {
    this.setState({ loading: true });
    restRequest(
      'get',
      `dashboard/receive_ables/${
        currentDateRange === 'custom'
          ? `custom?starting_date=${startData}&ending_date=${endDate}` //this part is for custom datepicker range
          : currentDateRange
          ? currentDateRange
          : this.props.currentDateRange
      }`
    )
      .then((res) => {
        this.setState({ data: res, loading: false });
        this.props.setReceivable(
          `${res.currency ? res.currency : '$'}${setDashboardAmount(res.receive_ables)}`
        );
      })
      .catch((err) => {
        checkError(err);
        if (err.response.status === 403) {
          this.setState({ accessDenied: true, loading: false });
        } else {
          this.setState({ accessError: true, loading: false });
        }
      });
  }

  // componentWillReceiveProps(nextProps) {
  //   this.fetchData(nextProps.currentDateRange)
  // }
  componentWillReceiveProps(nextProps) {
    // nextProps.currentDateRange !== this.props.currentDateRange &&  this.fetchData(nextProps.currentDateRange)
    (nextProps.currentDateRange !== this.props.currentDateRange ||
      nextProps.startData !== this.props.startData ||
      nextProps.endDate !== this.props.endDate) &&
      this.fetchData(
        nextProps.currentDateRange.receivables,
        nextProps.startData,
        nextProps.endDate
      );
  }
  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.accessDenied)
      return <AccessDenied className="small dashboard " type="Invoices" />;
    if (this.state.accessError)
      return <DataError className="small dashboard " type="Invoices" />;
    const { data } = this.state;
    const { due, over_due } = data;
    return (
      <div className="dashboard_receivables">
        {/*<Link className="dashboard_receivables_child" to="invoices?sort=created_at&page=1&orderBy=desc&view=20">*/}
        <Link
          className="dashboard_receivables_child"
          to={`invoices?filter=due&sort=created_at&page=1&orderBy=desc&view=20&start=${data.start_date}&end=${data.end_date}`}
        >
          <CountAndProgress
            title="Invoices"
            dueFlag
            color="#33A348"
            amount={due.amount}
            currency={data.currency}
            percentage={due.percent}
            count={due.invoices}
          />
        </Link>
        <Link
          className="dashboard_receivables_child"
          to={`invoices?filter=overdue&sort=created_at&page=1&orderBy=desc&view=20&start=${data.start_date}&end=${data.end_date}`}
        >
          {/*<Link className="dashboard_receivables_child" to="invoices?filter=overdue&sort=created_at&page=1&orderBy=desc&view=20">*/}
          <CountAndProgress
            title="Invoices"
            color="#D42F2F"
            currency={data.currency}
            amount={over_due.amount}
            percentage={over_due.percent}
            count={over_due.invoices}
          />
        </Link>
      </div>
    );
  }
}
