import React from 'react'

export default ({
  className = ''
}) => (
<svg  className={className} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 26.25 24.063">
  <path className="a"
        d="M16.406-25.156a3.123,3.123,0,0,0-1.9.615,3.285,3.285,0,0,0-1.179,1.572H4.375v2.188H13.33a3.285,3.285,0,0,0,1.179,1.572,3.123,3.123,0,0,0,1.9.615,3.123,3.123,0,0,0,1.9-.615,3.286,3.286,0,0,0,1.179-1.572H30.625v-2.187H19.482A3.286,3.286,0,0,0,18.3-24.541,3.123,3.123,0,0,0,16.406-25.156Zm0,2.188a1.065,1.065,0,0,1,.786.308,1.043,1.043,0,0,1,.308.769,1.1,1.1,0,0,1-.308.786,1.015,1.015,0,0,1-.769.325,1.071,1.071,0,0,1-.786-.325,1.071,1.071,0,0,1-.325-.786,1.015,1.015,0,0,1,.325-.769A1.081,1.081,0,0,1,16.406-22.969Zm7.656,6.563a3.123,3.123,0,0,0-1.9.615,3.285,3.285,0,0,0-1.179,1.572H4.375v2.188H20.986a3.285,3.285,0,0,0,1.179,1.572,3.123,3.123,0,0,0,1.9.615,3.123,3.123,0,0,0,1.9-.615,3.286,3.286,0,0,0,1.179-1.572h3.486v-2.187H27.139a3.286,3.286,0,0,0-1.179-1.572A3.123,3.123,0,0,0,24.063-16.406Zm0,2.188a1.065,1.065,0,0,1,.786.308,1.043,1.043,0,0,1,.308.769,1.1,1.1,0,0,1-.308.786,1.015,1.015,0,0,1-.769.325,1.071,1.071,0,0,1-.786-.325,1.071,1.071,0,0,1-.325-.786,1.015,1.015,0,0,1,.325-.769A1.081,1.081,0,0,1,24.063-14.219ZM12.031-7.656a3.123,3.123,0,0,0-1.9.615A3.285,3.285,0,0,0,8.955-5.469H4.375v2.188h4.58a3.285,3.285,0,0,0,1.179,1.572,3.123,3.123,0,0,0,1.9.615,3.123,3.123,0,0,0,1.9-.615,3.286,3.286,0,0,0,1.179-1.572H30.625V-5.469H15.107a3.286,3.286,0,0,0-1.179-1.572A3.123,3.123,0,0,0,12.031-7.656Zm0,2.188a1.065,1.065,0,0,1,.786.308,1.043,1.043,0,0,1,.308.769,1.1,1.1,0,0,1-.308.786,1.015,1.015,0,0,1-.769.325,1.071,1.071,0,0,1-.786-.325,1.071,1.071,0,0,1-.325-.786,1.015,1.015,0,0,1,.325-.769A1.081,1.081,0,0,1,12.031-5.469Z"
        transform="translate(-4.375 25.156)"/>
</svg>
   )
