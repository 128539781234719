import React, { Component, Fragment } from 'react';
// import { Link } from 'react-router-dom'
import { restRequest } from '../../../../../../Helpers/RequestHelper';
import getDate from '../../../../../../Helpers/Date/GetDate';
import { pdfDownlaod } from '../../../../../../Helpers/PdfDownload';
// import { NewBillIcon } from '../../../../../../Assets/Navigation/NavigationIcons'
import RecordPaymentForm from '../../../../../../Components/Form/RecordPaymentForm/RecordPaymentForm';
import Loader from '../../../../../../Components/Loader/Loader';
import Table from '../../../../../../Components/Table/Table';
import CustomModal from '../../../../../../Components/CustomModal/CustomModal';
import {
  Button,
  ButtonLink,
  ActionMenu,
} from '../../../../../../Components/CheckedMenu/CheckedMenu';
import RecordPaymentIcon from '../../../../../../Assets/General/RecordPaymentIcon';
import { NoItemsBox } from '../../../../../../Components/Layout/Box';
import {
  getObjFromLS,
  setObjInLS,
} from '../../../../../../Helpers/LocalStorage';
import ListTableColumnJoin from '../../../../../../Components/ListTable/ListTableColumnJoin';
import DeleteIcon from '../../../../../../Assets/General/DeleteIcon';
import { MdAdd } from '../../../../../../Common/Icons';
import PDFViewer from '../../../../../../Components/PDFViewer/PDFViewer';
import BillPaymentModalDetails from '../../../../../../Pages/PurchaseOrder/Bills/BillDetails/Tabs/Payments/BillPaymentModalDetails';
import { checkError } from '../../../../../../Helpers/AuthHelper';
class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      payments: [],
      showRecordPaymentModal: false,
      showDeletePaymentModal: false,
      showBillPaymentModal: false,
      showDeleteBillPaymentModal: false,
      pdf: null,
      paymentID: null,
      showPDF: false,
      showPrompt: false,
    };
  }

  tableRowOptions = [
    {
      label: 'Delete',
      icon: DeleteIcon,
      onClick: (id) => this.openDeletePaymentModal(id),
      disabled: (id) =>
        !this.props.hasPermissionCustom('payment', 'Delete') ? true : false,
    },
  ];

  handlePrompt = (status = true) => {
    this.setState({
      showPrompt: status,
    });
  };

  renderBillPaymentActionMenu(id) {
    return (
      <ActionMenu>
        {this.props.hasPermissionCustom('payment', 'Delete') && (
          <Button
            handleClick={() => this.openDeletePaymentModal(id)}
            type="delete"
          />
        )}
        <Button
          // handleClick={() => this.openPdf(id)}
          handleClick={() =>
            pdfDownlaod(
              `billpaymentrecords/${id}/pdf?download=true`,
              `Payment-${this.state.paymentID}`,
              this.props.handleToast
            )
          }
          type="pdf"
        />
        <Button handleClick={() => this.OpenPaymentPdf(id)} type="print" />
        <ButtonLink to={`bill_payments/email/${id}`} type="email" />
      </ActionMenu>
    );
  }

  OpenPaymentPdf = (id) => {
    this.setState({ showPDF: true });
    restRequest('get', `billpaymentrecords/${id}/pdf`)
      .then((res) => {
        this.setState({ pdf: res });
      })
      .catch((error) => checkError(error));
  };

  openPdf(id) {
    this.setState({ showPDF: true });
    restRequest('get', `billpaymentrecords/${id}/pdf`)
      .then((res) => {
        this.setState({ pdf: res });
      })
      .catch((error) => checkError(error));
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.fetchData().then(() => this.setState({ loading: false }));
  }

  openBillPaymentViewModal = (id) => {
    this.setState({
      showBillPaymentModal: true,
      paymentID: id,
    });
  };

  fetchData = () => {
    return restRequest('get', `bills/${this.props.billId}/paymentrecords`)
      .then((res) => {
        this.setState({
          payments: res,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error);
        this.setState({ loading: false });
      });
  };

  openNewRecordPaymentModal = () => {
    this.setState({ showRecordPaymentModal: true });
    // {this.props.handleLoad()}
  };

  openDeletePaymentModal = (id) => {
    this.setState({
      showDeletePaymentModal: true,
      clickedId: id,
    });
    // {this.props.handleLoad()}
  };

  closeAllModals = () => {
    this.setState({
      showRecordPaymentModal: false,
      showDeletePaymentModal: false,
      showBillPaymentModal: false,
      showDeleteBillPaymentModal: false,
      pdf: null,
      showPDF: false,
    });
    // {this.props.handleLoad()}
  };

  deletePaymentModal() {
    return (
      <CustomModal
        type="delete"
        showModal={this.state.showDeletePaymentModal}
        title="Confirm Payment Delete"
        onClose={this.closeAllModals}
        onSubmit={this.deletePayment}
      >
        Are you sure you want to delete this payment?
      </CustomModal>
    );
  }

  deletePayment = () => {
    this.closeAllModals();
    restRequest('delete', `paymentrecords/${this.state.clickedId}`)
      .then(() => {
        let dataFromLS = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLS,
          queryParam: `${dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
            }payments=true`,
        });
        this.fetchData();
        this.props.updateStatusOnPaymentChange();
        this.props.handleToast('Payment deleted successfully', 'success');
        this.props.handleLoad();
      })
      .catch((error) => {
        //this.props.handleToast(error, 'error')
        checkError(error, this.props.handleToast);
      });
  };

  handleRecordPaymentSubmit = () => {
    this.fetchData();
    this.props.updateStatusOnPaymentChange();
    this.closeAllModals();
    this.props.handleLoad();
  };

  newRecordPaymentModal() {
    return (
      <CustomModal
        showModal={this.state.showRecordPaymentModal}
        title="New Record Payment"
        Icon={RecordPaymentIcon}
        onClose={this.closeAllModals}
        renderActions={false}
        classNames="new-recieve-payment-popup bill-payment-popup"
        // classNames="new-recieve-payment-popup"
        showPrompt={this.state.showPrompt}
      >
        <RecordPaymentForm
          type="bill"
          id={this.props.billId}
          handleToast={this.props.handleToast}
          onSubmit={this.handleRecordPaymentSubmit}
          onClose={this.closeAllModals}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  renderModals() {
    return (
      <Fragment>
        {this.newRecordPaymentModal()}
        {this.deletePaymentModal()}
        {this.RenderBillViewPaymentModal()}
      </Fragment>
    );
  }

  preparePaymentsList(payments) {
    return payments.map((paymentSlip) => {
      return {
        ...paymentSlip,
        no: this.props.inModal ? (
          ` ${`Payment # ${paymentSlip.id}`}`
        ) : (
          <ListTableColumnJoin
            column1={
              <div
                className="payment_view"
                onClick={() => this.openBillPaymentViewModal(paymentSlip.id)}
                to={`/bills/${this.props.billId}/payments/${paymentSlip.id}`}
              >{`Payment # ${paymentSlip.id}`}</div>
            }
            column2={`${paymentSlip.payment_mode} - ${paymentSlip.currency.symbol}${paymentSlip.payment_made}`}
          />
        ),
        paymentDate: getDate(paymentSlip.payment_date),
        paymentMode: paymentSlip.payment_mode,
        //paymentMade:`${paymentSlip.currency.symbol} ${paymentSlip.payment_made}`,
        paymentMade: `${paymentSlip.currency.symbol} ${parseFloat(
          paymentSlip.payment_made
        ).toFixed(2)}`,
      };
    });
  }

  RenderBillViewPaymentModal() {
    return (
      <CustomModal
        Icon={RecordPaymentIcon}
        showModal={this.state.showBillPaymentModal}
        title={'Payment | Payment #' + this.state.paymentID}
        renderActions={false}
        modaltitle="bill_payment_modal_title_align"
        size="small"
        className="bill_payment_modal_box_align"
        renderBillPaymentActionMenu={this.renderBillPaymentActionMenu(
          this.state.paymentID
        )}
        onClose={this.closeAllModals}
        showActionInMenu={true}
      >
        <BillPaymentModalDetails id={this.state.paymentID} inModal={true} className="td-hover-unset" />
      </CustomModal>
    );
  }
  renderPaymentsListTable() {
    const { label } = this.props;
    const tableListData = this.preparePaymentsList(this.state.payments);
    const tableHeaders = [
      'Payment Date',
      'Payment No.',
      'Payment Mode',
      'Amount Paid',
    ];
    const tableData = [
      'paymentDate',
      'no',
      'paymentMode',
      'paymentMade',
      this.props.inModal ? '' : 'options',
    ];
    return (
      <Fragment>
        <Table
          list={tableListData}
          tableHeaders={tableHeaders}
          tableData={tableData}
          fromTab={true}
          options={this.props.inModal ? [] : this.tableRowOptions}
          className={`box-table ${label ? label + '_menu' : ''}`}
        />
        {this.props.hasPermissionCustom('payment', 'Create') &&
          this.props.billStatus !== 'paid' &&
          (this.props.inModal ? (
            ''
          ) : (
            <button
              className="layout-box--button btn-width"
              onClick={this.openNewRecordPaymentModal}
            >
              <MdAdd className=" black-fill" />
              Record Payment
            </button>
          ))}
      </Fragment>
    );
  }

  renderPaymentsList() {
    return (
      <Fragment>
        <PDFViewer
          showPDF={this.state.showPDF}
          hidePDFModal={this.closeAllModals}
          pdf={this.state.pdf}
        />
        {this.renderModals()}
        {!this.state.payments.length ? (
          <NoItemsBox
            subtitle="No payments made yet"
            buttonText="Make Payment"
            voidBill={this.props.billStatus === 'void'}
            showButton={
              this.props.hasPermissionCustom('payment', 'Create') &&
              this.props.billAmount !== 0
            }
            handleclick={this.openNewRecordPaymentModal}
            Icon={RecordPaymentIcon}
            inModal={this.props.inModal}
          />
        ) : (
          this.renderPaymentsListTable()
        )}
      </Fragment>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return this.renderPaymentsList();
  }
}

export default Payments;
