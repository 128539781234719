import React, { useEffect } from 'react';
import { withFormik } from 'formik';
import FileIcon from '../../Assets/Mixed/FileIcon';
import { MdClose } from '../../Common/Icons';
import { restRequest } from '../../Helpers/RequestHelper';
// import {required, ValidateRequired} from '../../Helpers/Validator'

import Field from '../Form/Field/Field';
import Form from '../Form/Form';
import EmailEditor from './../EmailEditor/EmailEditor';
import Conditions from './../Form/Conditions/Conditions';
import ErrorFocus from './../Form/ErrorFocus';
import FormAction from '../Form/FormAction';
import FormSection from '../Form/FormSection/FormSection';
import { checkError } from '../../Helpers/AuthHelper';
import { getObjFromLS } from '../../Helpers';

export const EmailContext = React.createContext();
const formEnhancer = withFormik({
  mapPropsToValues: ({ mail }) => ({
    ...mail,
    allAddresses: [],
  }),
  handleSubmit: (values, bag) => {
    handleFormSubmit(values, bag);
  },
});

function validateEmail(email) {
  var re =
    /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return re.test(email);
}

const handleFormSubmit = (
  values,
  {
    setSubmitting,
    props: { emailAPI, onCancelURL, history, handleToast, getBackTab = false },
  }
) => {
  const { allAddresses, subject, body, files, defaultFileStatus } = values;
  if (allAddresses.length === 0) {
    setSubmitting(false);
    return;
  }
  let data = new FormData();

  for (let [index, file] of files.entries()) {
    data.append(`files[${index}]`, file);
  }

  let addresses = allAddresses.map((add) => add.label);

  data.append('email', addresses.join(','));
  data.append('subject', subject);
  data.append('body', body);
  data.append('defaultFileStatus', defaultFileStatus);
  restRequest('post', emailAPI, data)
    .then(() => {
      handleToast('Email sent successfully', 'success');
      // history.push(onCancelURL)   before this was for all.
      if (getBackTab) {
        const dataFromLS = getObjFromLS('module');
        const { urlPath = onCancelURL, queryParam = '' } = dataFromLS;
        history.push(`${urlPath}${queryParam}`);
      } else {
        window.location.pathname.includes('invoice_payments') ||
        window.location.pathname.includes('bill_payments')
          ? history.push('/r')
          : history.push(onCancelURL);
      }
    })
    .catch((error) => {
      checkError(error, handleToast);
      //handleToast(error, 'error')
      setSubmitting(false);
    });
};

const EmailForm = ({
  // props from formik
  values,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
  isSubmitting,
  errors,
  submitCount,
  touched,

  // custom props
  name,
  onCancelURL,
  history,
  handleToast,
  getBackTab = false,
  mail,
}) => {
  const handleFiles = (event) => {
    let previousFiles = [...values.files];
    let selectedFiles = Array.from(event.target.files);
    if ([...previousFiles, ...selectedFiles].length > 10) {
      handleToast('Cannot add more than 10 files', 'error');
      return null;
    }
    let discardedFiles = [];
    let newFiles = selectedFiles.filter((file) => {
      const MAX_FILE_SIZE = 5; // max file size = 5MB
      let fileSize = file.size / 1000 / 1000; // converted from bytes to MB
      if (fileSize > MAX_FILE_SIZE) {
        discardedFiles.push(file);
      }
      return fileSize <= MAX_FILE_SIZE;
    });
    if (!!discardedFiles.length) {
      let errorMessage = discardedFiles.map((file) => {
        return `${file.name} exceeds the file size limit`;
      });
      handleToast(errorMessage, 'error');
    }

    setFieldValue('files', [...previousFiles, ...newFiles]);
  };

  const removeFile = (index) => {
    let mailFiles = [...values.files];
    mailFiles.splice(index, 1);
    setFieldValue('files', mailFiles);
  };

  const renderActionButtons = () => {
    return (
      <FormAction
        submitLabel="Send"
        disabled={isSubmitting}
        onCancel={() => {
          if (getBackTab) {
            const dataFromLS = getObjFromLS('module');
            const { urlPath = onCancelURL, queryParam = '' } = dataFromLS;
            history.push(`${urlPath}${queryParam}`);
          } else {
            history.push(onCancelURL);
          }
        }}
      />
    );
  };

  const handleEmailChange = (option, setFieldValue) => {
    //console.log('option', option)
    if (option === null) {
      setFieldValue('allAddresses', []);
    } else {
      setFieldValue('allAddresses', option);
    }
  };

  useEffect(() => {
    mail.selected.length === 1 &&
      // setFieldValue('allAddresses', [...mail.selected]);
      handleEmailChange([...mail.selected], setFieldValue);
  }, []);
  const removePdf = () => {
    setFieldValue('defaultFileStatus', false);
  };
  return (
    <div className="email">
      <Form onSubmit={handleSubmit}>
        <FormSection title={`Email ${name}`}>
          <EmailContext.Provider value={[...mail.selected]}>
            <Field
              size="large"
              type="badge"
              name="Email Address"
              className="full_width email_dropdown"
              autoFocus={true}
              options={values.contactPersons}
              value={values.allAddresses}
              id="allAddresses"
              handleChange={(option) => {
                handleEmailChange(option, setFieldValue);
              }}
              required
              error={
                (submitCount > 0 &&
                  values.allAddresses.length === 0 &&
                  'Required.') ||
                (values.allAddresses.length !== 0 &&
                  !values.allAddresses.every((al) => validateEmail(al.label)) &&
                  'Invalid Email')
              }
              onBlur={handleBlur}
            />
          </EmailContext.Provider>
          <Field
            size="large"
            name="Subject"
            className="full_width"
            value={values.subject}
            id="subject"
            handleChange={handleChange}
            error={touched.subject && errors.subject}
            onBlur={handleBlur}
          />

          <EmailEditor
            getEditorContent={(content) => setFieldValue('body', content)}
            bodyContent={values.body}
          />

          {/* {!!values.defaultFileName && (
            <div className="default-attached-file">
              <FileIcon />
              <span>{`${values.defaultFileName}.pdf`}</span>
            </div>
          )} */}
          {values.defaultFileStatus && (
            <div className="chips-container">
              <div key={0} className="chip">
                <FileIcon />
                <div className="chip-label">{`${values.defaultFileName}.pdf`}</div>
                <button
                  type="button"
                  className="chip-delete"
                  onClick={() => removePdf()}
                >
                  <MdClose />
                </button>
              </div>
            </div>
          )}

          {values.files.length > 0 && (
            <div className="chips-container">
              {values.files.map((file, index) => (
                <div key={index} className="chip">
                  <FileIcon />
                  <div className="chip-label">{file.name}</div>
                  <button
                    type="button"
                    className="chip-delete"
                    onClick={() => removeFile(index)}
                  >
                    <MdClose />
                  </button>
                </div>
              ))}
            </div>
          )}

          <div className="form-field">
            <input
              type="file"
              className="input-files"
              multiple
              id="mailFiles"
              accept=".txt, .doc, .docx, .pdf, .xls, .csv, .RTF, xml, epub, .png, .jpg, .jpeg, .bmp,"
              onChange={(e) => handleFiles(e)}
            />
            <label htmlFor="mailFiles" className="input-files--btn">
              Attach Files
            </label>
          </div>

          <Conditions>
            <div>File size cannot exceed 5MB, maximum 10 files</div>
          </Conditions>

          {renderActionButtons()}
          <ErrorFocus />
        </FormSection>
      </Form>
    </div>
  );
};

export default formEnhancer(EmailForm);
