import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../../../Components/Loader/Loader';
import { restRequest } from '../../../Helpers/RequestHelper';
import './DashboardPurchaseInfo.css';
import { formatNumber } from '../../../Helpers/FormatNumber';
import AccessDenied from '../../../Components/AccessDenied/AccessDenied';
import DataError from '../../../Components/DashboardDataError/DB_DataError';
import { checkError } from '../../../Helpers/AuthHelper';
import {setDashboardAmount} from "../../../Helpers/setAmount";

export default class DashboardPurchaseInfo extends Component {
  constructor(props) {
    super();
    this.state = {
      data: {},
      loading: true,
      accessDenied: false,
      accessError: false
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  dashboard_preferences = JSON.parse(localStorage.getItem("dashboardPreferences"))

  // fetchData(currentDateRange) {
  fetchData(currentDateRange = this.dashboard_preferences.purchase_information, startData, endDate) {
    this.setState({ loading: true });
    restRequest(
      'get',
      `dashboard/purchase_information/${currentDateRange === 'custom'
        ? `custom?starting_date=${startData}&ending_date=${endDate}`
        : currentDateRange
          ? currentDateRange
          : this.props.currentDateRange
      }`
    )
      .then((res) => {
        this.setState({ data: res, loading: false });
      })
      .catch((err) => {
        checkError(err);
        if (err.response.status === 403) {
          this.setState({ accessDenied: true, loading: false });
        } else {
          this.setState({ accessError: true, loading: false });

        }
      });
  }

  // componentWillReceiveProps(nextProps) {
  //   this.fetchData(nextProps.currentDateRange)
  // }
  componentWillReceiveProps(nextProps) {
    (nextProps.currentDateRange !== this.props.currentDateRange ||
      nextProps.startData !== this.props.startData ||
      nextProps.endDate !== this.props.endDate) &&
      this.fetchData(
        nextProps.currentDateRange.purchase_information,
        nextProps.startData,
        nextProps.endDate
      );
  }

  //   shouldComponentUpdate(nextProps) {
  //     return (this.props !== nextProps) ? false : true;
  // }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.accessDenied)
      return (
        <AccessDenied
          className="small dashboard "
          type="Purchase Information"
        />
      );
    if (this.state.accessError)
      return (
        <DataError
          className="small dashboard "
          type="Purchase Information"
        />
      );
    const { data } = this.state;
    const { currency } = data;

    return (
      <div className="dashboard_purchase_info float-left w-100 height_100 purchase-data-main ">
        <div className="float-left w-60 puprchase_data ">
          <div className="float-left w-100">
            {/*<Link className="float-left w-100" to="purchaseorders?filter=issued&sort=created_at&page=1&orderBy=desc&view=20">*/}
            {/*<Link
              className="float-left w-100"
              to={`purchaseorders?filter=issued&sort=created_at&page=1&orderBy=desc&view=20&start=${data.start_date}&end=${data.end_date}`}
            >
              <div className="mb_10px float-left w-100">
                <span className="float-left w-60">Quantity Ordered</span>
                <span className="semi-bold float-left w-40">
                  <span className="mr__10">: </span> {data.ordered}
                </span>
              </div>
            </Link>*/}
            <div className="mb_10px float-left w-100 d-flex align-center">
              <span className="float-left info-heading">Quantity Ordered</span>
              <span className="semi-bold float-left ">
                <span className="">: </span> {data.ordered}
              </span>
            </div>
            {/*<Link to="bills">*/}
            <Link
              className="float-left w-100"
              to={`bills?filter=all&sort=created_at&page=1&orderBy=desc&view=20&start=${data.start_date}&end=${data.end_date}`}
            >
              <div className="mb_10px float-left w-100 d-flex align-center">
                <span className="float-left info-heading">Total Cost</span>
                <span className="semi-bold float-left  UIUX-span-break">
                  <span className="">: </span>{' '}
                 <span>{`${currency}${setDashboardAmount(data.total_cost)}`}</span>
                </span>
              </div>
            </Link>
            <hr className="float-left w-100" />
          </div>

          <div className="float-left w-100">
            {/*<Link  className="float-left w-100" to="purchaseorders?filter=not fully received&sort=created_at&page=1&orderBy=desc&view=20">*/}
            <Link
              className="float-left w-100"
              to={`purchaseorders?filter=not fully received&sort=created_at&page=1&orderBy=desc&view=20&start=${data.start_date}&end=${data.end_date}`}
            >
              <div className="mb_10px mt_10px float-left w-100 d-flex">
                <span className="float-left info-heading ">Quantity to be received</span>
                <span className="semi-bold float-left ">
                  <span className="">: </span>{' '}
                  {data.quantity_to_be_received}
                </span>
              </div>
            </Link>
            {/*<Link className="float-left w-100" to="bills?filter=overdue&sort=created_at&page=1&orderBy=desc&view=20">*/}
            <Link
              className="float-left w-100"
              to={`bills?filter=open&sort=created_at&page=1&orderBy=desc&view=20&start=${data.start_date}&end=${data.end_date}`}
            >
              <div className="mb_10px float-left w-100 d-flex align-center">
                <span className="float-left info-heading">Bills to be paid</span>
                <span className="semi-bold float-left  UIUX-span-break">
                  <span className="">: </span>{' '}
                  <span>{`${currency}${setDashboardAmount(data.bills_to_be_paid)}`}</span>
                </span>
              </div>
            </Link>
            <hr className="float-left w-100" />
          </div>
          <Link
            className="float-left w-100"
            to="items?filter=lowstock&sort=created_at&page=1&orderBy=desc&view=20"
          >
            <div className="mt_10px float-left w-100">
              <span className="red_font float-left info-heading">Low stock Items</span>
              <span className="red_font semi-bold float-left ">
                <span className="">: </span> {data.low_stock_items}
              </span>
            </div>
          </Link>
        </div>
        <Link
          className="float-left w-40 purchase_bar"
          to="items?filter=active&sort=created_at&page=1&orderBy=desc&view=20"
        >
          <div className="dashboard_po_info_progress">
            <h3>
              <span>{data.active_items}</span>/{data.total_items}
            </h3>

            <div className="bar-container float-left w-100 mb_10">
              <div
                className="bar"
                style={{ width: `${data.items_percentage || 0}%` }}
              ></div>
            </div>
            <p className="float-left w-100" style={{ textAlign: 'right' }}>
              {data.items_percentage}%
            </p>
            <p className="float-left w-100 text-center">Active Items</p>
          </div>
        </Link>
      </div>
    );
  }
}
