import React from 'react';

export function Guideline(importType, path) {
  switch (path) {
    case '/items/import':
      return renderItemGuideline(importType);
    case '/contacts/import':
      return renderContactGuideline(importType);
    default:
      // do nothing;
      break;
    // return <div>Please select any from "Import From" to continue..</div>
  }
}

function renderItemGuideline(importType) {
  switch (importType) {
    case 'seebiz':
      return (
        <div>
          <strong>Step 1</strong> - Download this{' '}
          <a
            href={`${process.env.REACT_APP_AMAZON_S3}/sample-files/sample_SeeBiz_Items.csv`}
            rel="noopener noreferrer"
            target="_blank"
          >
            sample file.
          </a>
          <br />
          <strong>Step 2</strong> - Open the file in Excel, Click “
          <u>Save As</u>”, and save it in .CSV format. <br />
          <strong>Step 3</strong> - Edit & Fill data accordingly.
          <br />
          <strong>Step 4</strong> - Save the file & upload it on our system.
        </div>
      );

    case 'zoho':
      return (
        <div>
          <strong>Step 1</strong> - Open <b>Items Listing</b> from your Zoho
          account. <br />
          <strong>Step 2</strong> - Click the "<u>Export Button</u>". You can
          access it under the sandwich menu. <br />
          <strong>Step 3</strong> - Select Module “<u>Items</u>”, And select “
          <u>All Items</u>” (via radio button). <br />
          <strong>Step 4</strong> - Select “<u>CSV</u>“ as export format. <br />
          <strong>Step 5</strong> - Keep the "
          <u>Password Protection Disabled</u>". <br />
          <strong>Step 6</strong> - Click Export button of "<u>Export</u>" to
          download. <br />
          <strong>Step 7</strong> - Open the file in Excel, Click “
          <u>Save As</u>”, and save it in .CSV format. <br />
          <strong>Step 8</strong> - The exported file should look similar to
          this item{' '}
          <a
            href={`${process.env.REACT_APP_AMAZON_S3}/sample-files/sample_Zoho_Export_Items.csv`}
            rel="noopener noreferrer"
            target="_blank"
          >
            sample file
          </a>
          <br />
          <strong>Step 9</strong> - Now upload it on our system.
        </div>
      );

    case 'quickbookc':
      return (
        <div>
          <strong>Step 1</strong> - Open <b>Products & Services Listing</b> from
          your QuickBooks account. <br />
          <strong>Step 2</strong> - Click the “<u>Export To Excel</u>“ icon, at
          the top right of the listing. <br />
          <strong>Step 3</strong> - Open the file in Excel, Click “
          <u>Save As</u>”, and save it in .CSV format. <br />
          <strong>Step 4</strong> - The final converted file should look similar
          to this{' '}
          <a
            href={`${process.env.REACT_APP_AMAZON_S3}/sample-files/sample_QuickBook_Export_Product_Service_List.csv`}
            rel="noopener noreferrer"
            target="_blank"
          >
            products & services sample file.
          </a>
          <br />
          <strong>Step 5</strong> - Now upload it on our system.
        </div>
      );
    case 'quickbookr':
      return (
        <div>
          {' '}
          <strong>Step 1</strong> - Click on the <b>gear</b> icon, at the top
          right of your QuickBooks account. <br />
          <strong>Step 2</strong> - Select "<u>Export Data</u>" under the{' '}
          <u>Tools</u> section. <br />
          <strong>Step 3</strong> - Click on “<u>Visit the Reports Page</u>“
          link at the top right corner of the pop-up. <br />
          <strong>Step 4</strong> - Look for "<u>Product/Service List</u>"
          Option under the Sales and Customers Panel, and select it. Now click
          on the Customize button, and select all the columns available. <br />
          <strong>Step 5</strong> - Click Run Report, Now once the report is
          populated, click the Export button at top right of the results. (You
          must download in .xlsx format) <br />
          <strong>Step 6</strong> - Edit the file (a) Remove the first column;
          (b) Remove the top 4 rows, above the column headers; (c) Remove the
          bottom row having time stamp; (d) Remove any other section having
          extra notes or guidelines. <br />
          <strong>Step 7</strong> - Save the file as .CSV format. <br />
          <strong>Step 8</strong> - The final converted file should look similar
          to this{' '}
          <a
            href={`${process.env.REACT_APP_AMAZON_S3}/sample-files/sample_QuickBook_Report_ProductService_List.csv`}
            rel="noopener noreferrer"
            target="_blank"
          >
            products & services sample file.
          </a>{' '}
          <br />
          <strong>Step 9</strong> - Now upload it on our system.
        </div>
      );
    default:
      // do nothing;
      return <div>Please select any from "Import From" to continue..</div>;
  }
}

function renderContactGuideline(importType) {
  switch (importType) {
    case 'seebiz':
      return (
        <div>
          <strong>Step 1</strong> - Download this{' '}
          <a
            href={`${process.env.REACT_APP_AMAZON_S3}/sample-files/sample_SeeBiz_Contacts.csv`}
            rel="noopener noreferrer"
            target="_blank"
          >
            sample file.
          </a>
          <br />
          <strong>Step 2</strong> - Open the file in Excel, Click “
          <u>Save As</u>”, & save it in .CSV format. <br />
          <strong>Step 3</strong> - Edit & Fill data accordingly. Please add "
          <u>vendor</u>" or "<u>customer</u>" text under "contact type" column
          in the file to properly import data.
          <br />
          <strong>Step 4</strong> - If "contact type" is not available, contact
          will be save as a "<u>customer</u>".
          <br />
          <strong>Step 5</strong> - Save the file & upload it on our system.
        </div>
      );

    case 'zoho':
      return (
        <div>
          <strong>Step 1</strong> - Open <b>Customers/Vendors Listing</b> from
          your Zoho account. <br />
          <strong>Step 2</strong> - Click the "<u>Export Button</u>". You can
          access it under the sandwich menu. <br />
          <strong>Step 3</strong> - Select Module “<u>Customer</u>” or “
          <u>Vendors</u>“ and select only customers or vendors data for export
          (via radio buttons). <br />
          <strong>Step 4</strong> - If "contact type" is not available, contact
          will be save as a "<u>customer</u>".
          <br />
          <strong>Step 5</strong> - Select “<u>CSV</u>“ as export format. <br />
          <strong>Step 6</strong> - Keep the "
          <u>Password Protection Disabled</u>". <br />
          <strong>Step 7</strong> - Click Export button of "<u>Export</u>" to
          download. <br />
          <strong>Step 8</strong> - Open the file in Excel, Click “
          <u>Save As</u>”, and save it in .CSV format. <br />
          <strong>Step 9</strong> - The exported file should look similar to
          this{' '}
          <a
            href={`${process.env.REACT_APP_AMAZON_S3}/sample-files/sample_Zoho_Export_Customers.csv`}
            rel="noopener noreferrer"
            target="_blank"
          >
            customer sample file
          </a>{' '}
          OR{' '}
          <a
            href={`${process.env.REACT_APP_AMAZON_S3}/sample-files/sample_Zoho_Export_Vendors.csv`}
            rel="noopener noreferrer"
            target="_blank"
          >
            vendor sample file.
          </a>{' '}
          <br />
          <strong>Step 10</strong> - Now upload it on our system.
        </div>
      );

    case 'quickbookc':
      return (
        <div>
          <strong>Step 1</strong> - Open <b>Customers/Suppliers Listing</b> from
          your QuickBooks account. <br />
          <strong>Step 2</strong> - Click the “<u>Export To Excel</u>“ icon, at
          the top right of the listing. <br />
          <strong>Step 3</strong> - Open the file in Excel, Click “
          <u>Save As</u>”, & save it in .CSV format. <br />
          <strong>Step 4</strong> - The final converted file should look similar
          to this{' '}
          <a
            href={`${process.env.REACT_APP_AMAZON_S3}/sample-files/sample_QuickBook_Export_Customers.csv`}
            rel="noopener noreferrer"
            target="_blank"
          >
            customer sample file
          </a>{' '}
          OR{' '}
          <a
            href={`${process.env.REACT_APP_AMAZON_S3}/sample-files/sample_QuickBooks_Export_Supplier.csv`}
            rel="noopener noreferrer"
            target="_blank"
          >
            supplier sample file.
          </a>{' '}
          <br />
          <strong>Step 5</strong> - Now upload it on our system.
        </div>
      );
    case 'quickbookr':
      return (
        <div>
          {' '}
          <strong>Step 1</strong> - Click on the <b>gear</b> icon, at the top
          right of your QuickBooks account. <br />
          <strong>Step 2</strong> - Select "<u>Export Data</u>" under the{' '}
          <u>Tools</u> section. <br />
          <strong>Step 3</strong> - Go to "<u>Lists tab</u>" & select either "
          <u>Customers</u>" or "<u>Suppliers</u>". <br />
          <strong>Step 4</strong> - Click "<u>Export To Excel</u>" button <br />
          <strong>Step 5</strong> - Unzip the downloaded file, Open it & look
          for file named Customers.xlsx OR Suppliers.xlsx <br />
          <strong>Step 6</strong> - Edit the file (a) Remove the first column;
          (b) Remove the top 4 rows, above the column headers; (c) Remove the
          bottom row having time stamp; (d) Remove any other section having
          extra notes or guidelines. <br />
          <strong>Step 7</strong> - Save the file as "<u>.CSV</u>" format.{' '}
          <br />
          <strong>Step 8</strong> - The final converted file should look similar
          to this{' '}
          <a
            href={`${process.env.REACT_APP_AMAZON_S3}/sample-files/sample_QuickBook_Report_Customers.csv`}
            rel="noopener noreferrer"
            target="_blank"
          >
            customer sample file
          </a>{' '}
          OR{' '}
          <a
            href={`${process.env.REACT_APP_AMAZON_S3}/sample-files/sample_QuickBook_Report_Suppliers.csv`}
            rel="noopener noreferrer"
            target="_blank"
          >
            supplier sample file.
          </a>{' '}
          <br />
          <strong>Step 9</strong> - Now upload it on our system.
        </div>
      );
    default:
      // do nothing;
      // break;
      return <div>Please select any from "Import From" to continue..</div>;
  }
}
