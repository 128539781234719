const EMAIL_OPTIONS = [
  {
    id: 'once',
    name: 'Once',
  },
  {
    id: 'daily',
    name: 'Daily',
  },
  {
    id: 'weekly',
    name: 'Weekly',
  },
  {
    id: 'monthly',
    name: 'Monthly',
  },
  {
    id: 'yearly',
    name: 'Yearly',
  },
];

export default EMAIL_OPTIONS;
