import React, { Component } from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import moment from 'moment';
import {
  restRequest,
  checkError,
  getOrganizationDate,
  GetDateString,
} from '../../../Helpers';
import { Loader, NotFound } from '../../';
import PurchaseOrderEnhancedForm, {
  getTaxAmount,
} from './PurchaseOrderFormEnhancer';
import './PurchaseOrderForm.css';
import './../../Table/Table.css';

class PurchaseOrderForm extends Component {
  type = this.props.type; // "add", "edit", "clone", "dropship", "backorder"

  id =
    this.props.idFromHeaderWrapper ||
    this.props.id ||
    this.props.match.params.id ||
    null;
  vendorId = window.location.search.split('=')[1] || null;
  state = {
    loading: true,
    shippingAddress: {},
    purchaseOrder: {
      vendorID: null,
      contactObj: {},
      objectId: null,
      purchaseOrderNo: '',
      purchaseDate: moment(getOrganizationDate()).format('YYYY-MM-DD 00:00:00'),
      deliveryDate: moment(getOrganizationDate()).format('YYYY-MM-DD 00:00:00'),
      reference: '',
      warehouseID: null,
      items: [],
      transactionDiscountType: 'percent', // percent, amount
      transactionDiscount: 0,
      adjustment: 0,
      purchaseOrderType: this.type,
      hasTermsAndConditions: false,
      termsAndConditions: '',
    },
    vendorsFromDB: [],
    warehouseFromDB: [],
    itemsFromDB: [],
    // hasTermsAndConditions: false,
    // termsAndConditions: '',
    accountsFromDB: [],
    stockPreference: '',
    organizationCurrency: {},
    taxFromDB: [],
    notFound: false,
    previousPurchasePrices: [],
    cofirmState: false,
    showPrompt: false,
  };

  emptyItem = {
    id: null,
    name: '',
    accountID: null,
    images: [],
    sku: '',
    quantity: 1,
    stocks: [],
    unit: 'unit',
    dragDropID: GetDateString(),
    previousPurchasePrice: 0,
    unitPrice: null,
    tax: {
      id: null,
      name: '',
      rate: 0,
    },
    taxAmount: 0,
    total: '0.00',
    extra_description: '',
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.fetchData().then(() => this.setState({ loading: false }));
    sessionStorage.setItem('once', false);
    sessionStorage.setItem('fullscreen', false);
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  fetchData = async () => {
    this.setState({ loading: true });
    switch (this.type) {
      case 'add':
        await this.fetchPurchaseOrderCreateData();
        if (this.vendorId) {
          await this.fetchVendorDetail(this.vendorId);
        }
        break;
      case 'edit':
        await this.fetchPurchaseOrderEditData();
        break;
      case 'clone':
        await this.fetchPurchaseOrderCloneData();
        break;
      case 'dropship':
        await this.fetchPurchaseOrderDropshipBackorderData(
          this.props.dropshipItems
        );
        break;
      case 'backorder':
        await this.fetchPurchaseOrderDropshipBackorderData(
          this.props.backorderItems,
          this.props.warehouseID
        );
        break;
      default:
        break;
    }

    this.setState({ loading: false });
  };

  getWarehouseID(warehouseList) {
    return !!warehouseList.length
      ? warehouseList.find((warehouse) => warehouse.is_primary).id
      : null;
  }

  async fetchPurchaseOrderCreateData() {
    return restRequest('get', 'purchaseorders/create')
      .then((res) => {
        // Removes inactive warehouse from list
        res.warehouse_list = res.warehouse_list.filter(
          (list) => list.is_active === 1
        );
        this.setState({
          vendorsFromDB: res.vendor_list,
          taxFromDB: res.tax_list,
          warehouseFromDB: res.warehouse_list,
          itemsFromDB: res.item_list,
          accountsFromDB: res.purchase_account_group,
          stockPreference: res.stock_preference.preferences.physical
            ? 'physical'
            : 'accounting',
          organizationCurrency: res.base_currency,
          purchaseOrder: {
            ...this.state.purchaseOrder,
            purchaseOrderNo: res.purchase_order_no,
            warehouseID: this.getWarehouseID(res.warehouse_list),
            termsAndConditions: res.purchase_order_preference.terms,
            items: [this.emptyItem],
          },
        });
      })
      .catch((error) => checkError(error));
  }

  async fetchVendorDetail(vendorId) {
    return restRequest('get', `contacts/${vendorId}`).then((res) => {
      let purchaseOrder = {
        ...this.state.purchaseOrder,
        contactObj: {
          label: res.display_name,
          value: res.id,
        },
      };
      this.setState({
        purchaseOrder: purchaseOrder,
      });
    });
  }

  async fetchPurchaseOrderDropshipBackorderData(items, warehouseID = null) {
    return restRequest(
      'get',
      'purchaseorders/create?saleOrderId=' + items[0].saleOrderId
    )
      .then((res) => {
        let dropshipBackorderItems = items.map((dropshipBackorderItem) => {
          let foundItem = res.item_list.find(
            (item) => item.id === dropshipBackorderItem.itemId
          );
          return {
            ...foundItem,
            id: foundItem.id,
            dragDropID: GetDateString(),
            salesOrderItemDetailId: dropshipBackorderItem.id,
            name: foundItem.name,
            accountID: foundItem.purchase_account_id,
            images: foundItem.images,
            sku: foundItem.sku,
            quantity: dropshipBackorderItem.quantity,
            stocks: foundItem.stocks,
            unit: foundItem.unit,
            unitPrice: foundItem.purchase_unit_price,
            tax: {
              id: foundItem.tax ? foundItem.tax.id : null,
              rate: foundItem.tax ? foundItem.tax.rate : 0,
              name: foundItem.tax ? foundItem.tax.name : '',
            },
            taxAmount: foundItem.tax_id
              ? getTaxAmount(
                  dropshipBackorderItem.quantity,
                  foundItem.purchase_unit_price,
                  foundItem.tax.rate
                )
              : 0,
            total: foundItem.amount,
            extra_description: dropshipBackorderItem.extra_description
              ? dropshipBackorderItem.extra_description
              : '',
          };
        });
        this.setState({
          shippingAddress: res.shipping_address,
          vendorsFromDB: res.vendor_list,
          taxFromDB: res.tax_list,
          itemsFromDB: [],
          accountsFromDB: res.purchase_account_group,
          stockPreference:
            res.stock_preference === 1 ? 'physical' : 'accounting',
          organizationCurrency: res.base_currency,
          warehouseFromDB: res.warehouse_list,
          purchaseOrder: {
            ...this.state.purchaseOrder,
            warehouseID: warehouseID,
            termsAndConditions: res.purchase_order_preference.terms,
            purchaseOrderNo: res.purchase_order_no,
            items: dropshipBackorderItems,
            objectId: res.id,
          },
        });
      })
      .catch((error) => checkError(error));
  }

  async fetchPurchaseOrderCloneData() {
    return restRequest('get', `purchaseorders/${this.id}/clone`)
      .then((res) => {
        let itemsList =
          res.purchase_order_details.purchase_order_item_details.map(
            (item) => ({
              id: item.item.id,
              dragDropID: GetDateString(),
              name: item.item.name,
              accountID: item.account_id,
              images: item.item.images,
              sku: item.item.sku,
              quantity: item.quantity,
              stocks: item.item.stocks,
              unit: item.item.unit,
              unitPrice: item.rate,
              ean: item.item.ean,
              isbn: item.item.isbn,
              mpn: item.item.mpn,
              upc: item.item.upc,
              tax: {
                id: item.tax_id ? item.tax_id : null,
                rate: item.tax_id ? item.tax_rate : 0,
                name: item.tax_id ? item.tax_name : '',
              },
              taxAmount: item.tax_id ? item.tax_amount : 0,
              total: item.amount,
              extra_description: item.extra_description,
            })
          );
        let promises = [];
        itemsList.forEach((item, index) => {
          let POPURL = null;
          // if(res.purchase_order_details.id){
          //   POPURL = `purchaseorders/previouspurchaseprice?vendor_id=${res.purchase_order_details.vendor.id}&item_id=${item.id}&object=purchaseOrder&object_id=${res.purchase_order_details.id}`;
          // }else{
          POPURL = `purchaseorders/previouspurchaseprice?vendor_id=${res.purchase_order_details.vendor.id}&item_id=${item.id}`;
          // }
          promises.push(restRequest('get', POPURL));
        });
        Promise.all(promises).then((promisRes) => {
          this.setState({ previousPurchasePrices: promisRes });
        });
        this.setState({
          vendorsFromDB: res.vendor_list,
          taxFromDB: res.tax_list,
          warehouseFromDB: res.warehouse_list,
          itemsFromDB: res.item_list,
          accountsFromDB: res.purchase_account_group,
          stockPreference:
            res.stock_preference === 1 ? 'physical' : 'accounting',
          organizationCurrency: res.base_currency,
          purchaseOrder: {
            ...this.state.purchaseOrder,
            ...{
              purchaseOrderNo: res.new_purchase_order_no,
              vendorID: res.purchase_order_details.vendor.id,
              contactObj: {
                value: res.purchase_order_details.vendor.id,
                label: res.purchase_order_details.vendor.display_name,
              },
              purchaseDate: moment(
                res.purchase_order_details.order_date
              ).format('YYYY-MM-DD 00:00:00'),
              deliveryDate: moment(
                res.purchase_order_details.expected_delivery_date
              ).format('YYYY-MM-DD 00:00:00'),
              reference: res.purchase_order_details.reference,
              warehouseID: res.purchase_order_details.warehouse_id,
              transactionDiscountType:
                res.purchase_order_details.discount_type || 'percent',
              transactionDiscount:
                res.purchase_order_details.discount_transaction_level,
              adjustment: res.purchase_order_details.adjustment,
              hasTermsAndConditions:
                res.purchase_order_details.terms_and_conditions !== null &&
                res.purchase_order_details.terms_and_conditions.length > 0
                  ? true
                  : false,
              termsAndConditions: res.purchase_order_preference.terms,
              items: itemsList,
            },
          },
        });
      })
      .catch((error) => checkError(error));
  }

  buildItemList(items) {
    return items.map((item) => ({
      id: item.item.id,
      dragDropID: GetDateString(),
      purchaseOrderItemDetailId: item.id,
      name: item.item.name,
      accountID: item.account_id,
      images: item.item.images,
      sku: item.item.sku,
      quantity: item.quantity,
      stocks: item.item.stocks,
      unit: item.item.unit,
      ean: item.item.ean,
      isbn: item.item.isbn,
      mpn: item.item.mpn,
      upc: item.item.upc,
      unitPrice: item.rate,
      purchase_unit_price: item.item.purchase_unit_price,
      tax: {
        id: item.tax ? item.tax.id : null,
        rate: item.tax ? item.tax.rate : 0,
        name: item.tax ? item.tax.name : '',
      },
      taxAmount: item.tax_id ? item.tax_amount : 0,
      total: item.amount,
      extra_description: item.extra_description,
    }));
  }

  async fetchPurchaseOrderEditData() {
    return restRequest('get', `purchaseorders/${this.id}/edit`)
      .then((res) => {
        //let result = this.buildItemList(res.purchase_order_details.purchase_order_item_details)
        //let itemsList = res.item_list.filter(item =>  result.find(y => y.id === item.id) ? null : item)
        let itemsList = res.item_list;
        let purchaseOrderItems = this.buildItemList(
          res.purchase_order_details.purchase_order_item_details
        );
        // purchaseOrderItems.forEach((item, index) => {
        //   restRequest('get', `purchaseorders/previouspurchaseprice?vendor_id=${res.purchase_order_details.vendor_id}}&item_id=${item.id}`).then(previousPrice => {
        //     purchaseOrderItems[index] = { ...item, previousPurchasePrice: previousPrice }
        //   })
        // })
        let promises = [];
        purchaseOrderItems.forEach((item, index) => {
          let POPURL = null;
          if (res.purchase_order_details.id) {
            POPURL = `purchaseorders/previouspurchaseprice?vendor_id=${res.purchase_order_details.vendor_id}&item_id=${item.id}&object=purchaseOrder&object_id=${res.purchase_order_details.id}`;
          } else {
            POPURL = `purchaseorders/previouspurchaseprice?vendor_id=${res.purchase_order_details.vendor_id}&item_id=${item.id}`;
          }
          promises.push(restRequest('get', POPURL));
        });
        Promise.all(promises).then((promisRes) => {
          this.setState({ previousPurchasePrices: promisRes });
        });
        this.setState({
          vendorsFromDB: res.vendor_list,
          taxFromDB: res.tax_list,
          warehouseFromDB: res.warehouse_list,
          itemsFromDB: itemsList,
          accountsFromDB: res.purchase_account_group,
          stockPreference:
            res.stock_preference === 1 ? 'physical' : 'accounting',
          organizationCurrency: res.base_currency,
          purchaseOrder: {
            ...this.state.purchaseOrder,
            ...{
              purchaseOrderNo: res.purchase_order_details.purchase_order_no,
              vendorID: res.purchase_order_details.vendor.id,
              contactObj: {
                value: res.purchase_order_details.vendor.id,
                label: res.purchase_order_details.vendor.display_name,
              },
              purchaseDate: moment(
                res.purchase_order_details.order_date
              ).format('YYYY-MM-DD 00:00:00'),
              deliveryDate: moment(
                res.purchase_order_details.expected_delivery_date
              ).format('YYYY-MM-DD 00:00:00'),
              reference: res.purchase_order_details.reference,
              status: res.purchase_order_details.status,
              warehouseID: res.purchase_order_details.warehouse_id,
              transactionDiscountType:
                res.purchase_order_details.discount_type || 'percent',
              transactionDiscount:
                res.purchase_order_details.discount_transaction_level,
              adjustment: res.purchase_order_details.adjustment,
              hasTermsAndConditions:
                res.purchase_order_details.terms_and_conditions !== '' &&
                res.purchase_order_details.terms_and_conditions.length > 0
                  ? true
                  : false,
              termsAndConditions:
                res.purchase_order_details.terms_and_conditions !== '' &&
                res.purchase_order_details.terms_and_conditions.length > 0
                  ? res.purchase_order_details.terms_and_conditions
                  : res.purchase_order_preference.terms,
              items: purchaseOrderItems,
              objectId: this.id,
            },
          },
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, 'error')
        this.setState({ notFound: true });
      });
  }
  updateTaxList = (newTax) => {
    this.setState((state) => ({ taxFromDB: [newTax, ...state.taxFromDB] }));
  };
  updateVendorList = (newVendor) => {
    this.setState((state) => ({
      vendorsFromDB: [newVendor, ...state.vendorsFromDB],
    }));
  };

  submitRequestType() {
    switch (this.type) {
      case 'edit':
        return 'put';
      case 'add':
      case 'clone':
      case 'dropship':
      case 'backorder':
      default:
        return 'post';
    }
  }

  submitRequestUrl() {
    switch (this.type) {
      case 'edit':
        return `purchaseorders/${this.props.id}`;
      case 'add':
      case 'clone':
      case 'dropship':
      case 'backorder':
      default:
        return 'purchaseorders';
    }
  }

  successMessage = () => {
    switch (this.type) {
      case 'edit':
        this.props.handleToast('Purchase Order has been updated', 'success');
        break;
      case 'clone':
        this.props.handleToast('Purchase Order has been cloned', 'success');
        break;
      case 'dropship':
        this.props.handleToast('Dropship created successfully', 'success');
        break;
      case 'backorder':
        this.props.handleToast('Backorder created successfully', 'success');
        break;
      case 'add':
      default:
        this.props.handleToast(
          'Purchase Order created successfully',
          'success'
        );
        break;
    }
  };

  handleTotalCheckTrue = (check) => {
    this.setState({
      cofirmState: check,
    });
  };

  handleTotalCheckFalse = (check) => {
    this.setState({
      cofirmState: check,
    });
  };

  handlePrompt = (status = true) => this.setState({ showPrompt: status });

  renderPurchaseOrderForm() {
    const { title = '', history, handleToast } = this.props;
    const {
      shippingAddress,
      purchaseOrder,
      vendorsFromDB,
      warehouseFromDB,
      itemsFromDB,
      accountsFromDB,
      taxFromDB,
      stockPreference,
      organizationCurrency,
      previousPurchasePrices,
      //hasTermsAndConditions,
      cofirmState,
    } = this.state;
    return (
      <>
        <Prompt
          when={this.state.showPrompt}
          message="Are you sure you want to leave page while changes made?"
        />
        <PurchaseOrderEnhancedForm
          title={title}
          type={this.type}
          organizationCurrency={organizationCurrency}
          purchaseOrder={{ ...purchaseOrder }}
          emptyItem={this.emptyItem}
          vendorIdTransaction={this.vendorId}
          stockPreference={stockPreference}
          vendorsFromDB={vendorsFromDB}
          shippingAddress={shippingAddress}
          previousPurchasePrices={previousPurchasePrices}
          warehouseFromDB={warehouseFromDB}
          // hasTermsAndConditions={hasTermsAndConditions}
          itemsFromDB={itemsFromDB}
          accountsFromDB={accountsFromDB}
          taxFromDB={taxFromDB}
          id={this.id}
          updateVendorList={this.updateVendorList}
          requestUrl={this.submitRequestUrl()}
          requestType={this.submitRequestType()}
          successMessage={this.successMessage}
          updateTaxList={this.updateTaxList}
          history={history}
          handleToast={handleToast}
          cofirmState={cofirmState}
          handleTotalCheckTrue={this.handleTotalCheckTrue}
          handleTotalCheckFalse={this.handleTotalCheckFalse}
          setPrompt={this.handlePrompt}
          className="po-add-form"
        />
      </>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    return this.renderPurchaseOrderForm();
  }
}

export default withRouter(PurchaseOrderForm);
