export function getCurrentUserData() {
  // Store the token from localstorage
  const storage = localStorage.getItem( 'currentUser' )
  if(storage) return JSON.parse( storage )
  return false
}

export function getCurrentOrganization() {
  const storage = localStorage.getItem( 'currentOrganization' )
  if(storage) return JSON.parse( storage )
  return false
}

export function setCurrentOrganization(currentOrganization) {
  localStorage.setItem('currentOrganization', JSON.stringify(currentOrganization))
}

export const getCurrency = localStorage.getItem('currentOrganization') !== null && JSON.parse(localStorage.getItem('currentOrganization')).get_currency