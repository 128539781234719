import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { CustomModal, ContentLoader } from '../../../Components';
import { withRouter, useParams, useHistory } from 'react-router-dom';
import { restRequest } from '../../../Helpers';
import {
  woocommerceModalsContent,
  woocommerceSyncSettingTime,
  shopifyModalsContent,
} from '../../../static';
import { Button } from '../../../Common';
import { MenuItem, SplitButton } from 'react-bootstrap';

const EcomDetailNavigation = ({
  handleToast,
  wareHouseData,
  wareHouseConnecHandler = () => {},
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);
  const [syncTime, SetSyncTime] = useState('60 min');
  const [storeConnected, setStoreConnected] = useState('null');
  const [synMannuallyConnectivity, setSynMannuallyConnectivity] =
    useState('disconnected');
  const [time, setTime] = useState('60 min');
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    wareHouseConnecHandler(id);
  }, [id]);

  useEffect(() => {
    SetSyncTime(wareHouseData?.store_details?.auto_sync_interval);
    setTime(wareHouseData?.store_details?.auto_sync_interval);
    setStoreConnected(wareHouseData?.store_details?.connected);
    setSynMannuallyConnectivity(wareHouseData?.store_details?.status);
  }, [wareHouseData]);

  const integrationHandler = (val) => {
    setBtnLoader(true);
    let data = new FormData(),
      endPoint,
      method = 'post';
    if (val === 'disconnect') {
      data.append('store_connected_status', 'disconnected');
      endPoint = `my_stores/${id}/disconnect`;
    } else if (val === 'enable') {
      data.append('store_status', 'enabled');
      endPoint = `my_stores/${id}/enable`;
    } else if (val === 'disable') {
      data.append('store_status', 'disabled');
      endPoint = `my_stores/${id}/disable`;
    } else if (val === 'syncsave') {
      data.append('sync_interval', time);
      endPoint = `my_stores/${id}/settings`;
    }
    restRequest(method, endPoint, data)
      .then((responce) => {
        handleToast(responce.message ?? 'success', 'success');
        if (val === 'syncsave') SetSyncTime(time);
        setOpenModal(false);
        wareHouseConnecHandler(id);
        setBtnLoader(false);
        (val === 'disable' || val === 'disconnect') &&
          history.push('/integrations');
      })
      .catch((error) => {
        handleToast(error, 'error');
        setBtnLoader(false);
      });
  };

  const syncSettingContent = () => (
    <div className={classNames('sync-settings-popup')}>
      <div className={classNames('sync-interval-desc input-wrapper')}>
        <p className="cus-label">Synchronize After</p>
        <div className="input_box">
          <select
            className="cus-select"
            value={time}
            onChange={(e) => {
              setTime(e.target.value);
            }}
          >
            {woocommerceSyncSettingTime.map((item, i) => (
              <option key={i} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={classNames('interval-desc')}>
        <p>
          <b>Note:</b>
          Changes in sync preferences will be applied across all integrated sales channels
        </p>
      </div>
    </div>
  );

  const modalContentHandler = (index) => {
    const data =
      wareHouseData?.store_details?.slug === 'woo-commerce'
        ? woocommerceModalsContent[index]
        : shopifyModalsContent[index];
    return (
      <>
        <div className={classNames('disconnect-popup-main')}>
          {index === 0 ? (
            syncSettingContent()
          ) : (
            <div>
              <h3>{data.subHeading}</h3>
              <ul>
                {data.content.map((val) => (
                  <li key={val}>{val}</li>
                ))}
              </ul>
            </div>
          )}
          <div
            className={classNames(
              'action-btns',
              'popup-border-top',
              'd-flex',
              'flex-end'
            )}
          >
            <button
              className={classNames('btn-popup', 'mr_3', 'btn-gray')}
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </button>
            <Button
              btnText={data.btnText}
              className={classNames(
                'w-83px',
                !btnLoader
                  ? 'btn-green btn-popup btnloader'
                  : 'btn-green btn-popup'
              )}
              clickHandler={() => integrationHandler(data.btnHandler)}
              disabled={btnLoader}
              btnLoading={btnLoader}
            />
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <CustomModal
        size="medium"
        className={classNames('syncModal')}
        showModal={openModal}
        title={
          wareHouseData?.store_details?.slug === 'woo-commerce'
            ? woocommerceModalsContent[modalIndex].heading
            : shopifyModalsContent[modalIndex].heading
        }
        renderActions={false}
        onClose={() => {
          setOpenModal(false);
        }}
        classNames="shopify-popup-main-set cus-popup"
      >
        {modalContentHandler(modalIndex)}
      </CustomModal>
      {wareHouseData.length !== 0 && (
        <div className={classNames('ecom-header-main')}>
          <div className={classNames('ecom-header-inner')}>
            <div className={classNames('ecom-header-title')}>
              <p className={classNames('mb-0')}>
                {storeConnected === 'connected' &&
                synMannuallyConnectivity === 'enabled'
                  ? `Auto-sync occurs every ${syncTime}`
                  : null}
              </p>
            </div>
            <div className={classNames('ecom-header-sync-sec')}>
              {storeConnected === 'connected' ? (
                <button
                  className={classNames('btn btn-sync-setting')}
                  onClick={() => {
                    setModalIndex(0);
                    setOpenModal(true);
                  }}
                  disabled={
                    synMannuallyConnectivity === 'enabled' ? false : true
                  }
                >
                  Sync Settings
                </button>
              ) : null}
            </div>
            <div
              className={classNames('ecom-header-sync-sec', 'ecom-noborder')}
            >
              {storeConnected === 'connected' ? (
                <button
                  disabled={
                    synMannuallyConnectivity === 'enabled' ? false : true
                  }
                  className={classNames('btn btn-sync-setting')}
                  onClick={() => {
                    history.push(`/integrations/sync-history/${id}`);
                  }}
                >
                  Sync History
                </button>
              ) : null}
            </div>
            <div className="disable-integration-sec integration-btns">
              <SplitButton
                className=""
                bsSize="large"
                title={
                  wareHouseData.length === 0 ? (
                    <ContentLoader />
                  ) : wareHouseData?.store_details?.status !== 'enabled' ? (
                    'Enable'
                  ) : (
                    'Disabled Integration'
                  )
                }
                id="dropdown-size-large"
                onClick={() => {
                  wareHouseData?.store_details?.status === 'enabled'
                    ? setModalIndex(1)
                    : setModalIndex(2);
                  setOpenModal(true);
                }}
              >
                <MenuItem
                  eventKey="1"
                  onClick={() => {
                    setModalIndex(3);
                    setOpenModal(true);
                  }}
                >
                  Disconnect
                </MenuItem>
                <MenuItem eventKey="2"> Re-Authenticate</MenuItem>
              </SplitButton>
            </div>
            {/* <div className={classNames('disable-integration-sec')}>
              {storeConnected === 'connected' ? (
                <>
                  <button
                    className={classNames('btn-style', 'br-5')}
                    onClick={() => {
                      wareHouseData?.store_details?.status === 'enabled'
                        ? setModalIndex(1)
                        : setModalIndex(2);
                      setOpenModal(true);
                    }}
                  >
                    {wareHouseData.length === 0 ? (
                      <ContentLoader />
                    ) : wareHouseData?.store_details?.status !== 'enabled' ? (
                      'Enable'
                    ) : (
                      'Disabled Integration'
                    )}
                  </button>
                  <span
                    className={classNames('ecom-details-dropdown')}
                    onClick={() => {
                      setBtnToogle(!btnToogle);
                    }}
                  >
                    <i className="fas fa-chevron-down"></i>
                  </span>
                </>
              ) : null}
              <div></div>
              <ul
                className={`ecom-dropdown ${btnToogle ? 'd-block' : 'd-none'}`}
              >
                <li
                  onClick={() => {
                    setModalIndex(3);
                    setOpenModal(true);
                    setBtnToogle(false);
                  }}
                  className={classNames('ecom_detail_list')}
                >
                  Disconnect
                </li>
                <li
                  className={classNames(
                    'd-flex',
                    'space-between',
                    'align-center',
                    'ecom_detail_list'
                  )}
                >
                  Re-Authenticate
                  <i className={classNames('fas fa-check')}></i>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};
export default withRouter(EcomDetailNavigation);
