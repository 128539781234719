import React from 'react'
import { Formik } from 'formik'

class Wizard extends React.Component {
  static Page = ({ children, parentState }) => {
    return children(parentState)
  }

  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      values: props.initialValues,
    }
  }

  next = values =>
    this.setState(state => {
      const page = Math.min(state.page + 1, this.props.children.length - 1)
      const { handlePageChange } = this.props
      if(handlePageChange) {
        handlePageChange(page)
      }
      return {
        page,
        values,
      }
    })

  previous = () =>
    this.setState(state => {
      const page = Math.max(state.page - 1, 0)
      const { handlePageChange } = this.props
      if(handlePageChange) {
        handlePageChange(page)
      }
      return {
        page,
      }
    })

  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ]
    return activePage.props.validationSchema ? activePage.props.validationSchema(values) : {}
  }

  handleSubmit = (values, bag) => {
    const { children, onSubmit } = this.props
    const { page } = this.state
    const isLastPage = page === React.Children.count(children) - 1
    if (isLastPage) {
      return onSubmit(values, bag)
    } else {
      bag.setTouched({})
      bag.setSubmitting(false)
      this.next(values)
    }
  }

  render() {
    const { children } = this.props
    const { page, values } = this.state
    const activePage = React.Children.toArray(children)[page]
    const isLastPage = page === React.Children.count(children) - 1
    return (
      <Formik
        initialValues={values}
        enableReinitialize={false}
        validationSchema={this.validate}
        onSubmit={this.handleSubmit}
        render={props => (
          <form onSubmit={props.handleSubmit}>
            {React.cloneElement(activePage, { parentState: { ...props } })}
            <div className="buttons">

              {!isLastPage && <button type="submit" className="button--small-width">Next</button>}
              {isLastPage && (
                <button type="submit" className="button--small-width" disabled={props.isSubmitting}>
                  Submit
                </button>
              )}
              {page > 0 && (
                <button
                  type="button"
                  className="button--no-background button-gray-background"
                  onClick={this.previous}
                  disabled={props.isSubmitting}
                >
                  Go Back
                </button>
              )}
            </div>
          </form>
        )}
      />
    )
  }
}

export default Wizard