import React from 'react'

import DataError from './db_data_error.svg'
import './DB_DataError.css'

export default ({
  className = 'full',
  // type = 'page',
}) => {
  return (
    <div className={`data-error ${className}`}>
      <img className="data-error-style" src={DataError} alt="data-error" />
      <p>Someting went Wrong!</p>
    </div>
  )
}