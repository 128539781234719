import React, { Component, Fragment } from 'react';
//import { Link } from 'react-router-dom';
import getDate from '../../../Helpers/Date/GetDate';
import Table from '../../../Components/Table/Table';
import { NewInvoiceIcon } from '../../../Assets/Navigation/NavigationIcons';
import CustomModal from '../../../Components/CustomModal/CustomModal';
import InvoiceDetails from '../../SalesOrder/Invoices/InvoiceDetails';
import './InvoicePaymentmadeDetails.css';
class InvoicePaymentDetails extends Component {
  state = {
    showInvoiceModal: false,
    invoice_id: null,
  };

  viewInvoiceModal = () => {
    const { invoice_id } = this.state;
    return (
      <CustomModal
        Icon={NewInvoiceIcon}
        showModal={this.state.showInvoiceModal}
        title="View Invoice"
        className='popup-height'
        modaltitle="inventory_modal_title"
        renderActions={false}
        size="large"
        onClose={() => this.setState({ showInvoiceModal: false })}
        linkTo={`invoices/${invoice_id}`}
      >
        <InvoiceDetails id={invoice_id} inModal={true} />
      </CustomModal>
    );
  };

  renderLayoutView() {
    const {
      payment_type = '',
      invoice_payment = [],
      invoiceInfo = {},
      paymentInfo = {},
      currency = {},
    } = this.props;
    return (
      <Fragment>
        <Table
          list={
            payment_type === 'advance'
              ? invoice_payment
              : [
                  {
                    invoiceNo: (
                      <span
                        onClick={() =>
                          this.setState({
                            showInvoiceModal: true,
                            invoice_id: invoiceInfo.id,
                          })
                        }
                        style={{ color: '#306AB4', cursor: 'pointer' }}
                      >
                        {invoiceInfo.invoice_no}
                      </span>
                    ),
                    invoiceDate: getDate(invoiceInfo.invoice_date),
                    invoiceAmount: `${currency.symbol}${parseFloat(
                      invoiceInfo.total
                    ).toFixed(2)}`,
                    receivedAmount: `${currency.symbol}${parseFloat(
                      paymentInfo.paymentMade
                    ).toFixed(2)}`,
                  },
                ]
          }
          tableHeaders={
            payment_type === 'advance' && !invoice_payment.length
              ? []
              : [
                  'Invoice No.',
                  'Invoice Date',
                  'Invoice Amount',
                  'Payment Received',
                ]
          }
          tableData={[
            'invoiceNo',
            'invoiceDate',
            'invoiceAmount',
            'receivedAmount',
          ]}
          rightAlignColumns={[3, 4]} 
          className="details-table table_view_invoice paymentdetail-table"
        />
      </Fragment>
    );
  }

  render() {
    return (
      <Fragment>
        {this.viewInvoiceModal()}
        {this.renderLayoutView()}
      </Fragment>
    );
  }
}

export default InvoicePaymentDetails;
