import React from 'react';
import getDate from '../../../../../Helpers/Date/GetDate';
import { formatNumber } from '../../../../../Helpers/FormatNumber';
import './ShipmentInfoCard.css';

const ShipmentInfoCard = ({
  shipmentOrderNo,
  shipmentNote,
  shipmentDate,
  carrier,
  type = 'shipment',
  trackingNo,
  shippingCharge,
  closeViewRefundModal,
  currency,
  openShipmentDeleteModal,
  closeShipmentModal,
  packageStatus,
  hasPermissionCustom,
  selectedRefund,
}) => {
  return type === 'shipment' ? (
    <div className="shipment-info-card float-left w-100">
      <div className="shipment-info float-left w-100 shipment_padding">
        <div className="float-left w-100 inner_padding">
          <div className="section-details--info-title semi-bold float-left w-30">
            Shipment No.
          </div>
          <div
            className="word-break-all float-left w-70"
            style={{ textAlign: 'unset' }}
          >
            {shipmentOrderNo}
          </div>
        </div>
        <div className="float-left w-100 border_bottom_custom"></div>
        <div className="float-left w-100 inner_padding">
          <div className="section-details--info-title semi-bold float-left w-30">
            Shipped On
          </div>
          <div
            className="word-break-all float-left w-70"
            style={{ textAlign: 'unset' }}
          >
            {getDate(shipmentDate)}
          </div>
        </div>
        <div className="float-left w-100 border_bottom_custom"></div>
        <div className="float-left w-100 inner_padding">
          <div className="section-details--info-title semi-bold float-left w-30">
            Carrier
          </div>
          <div
            className="word-break-all float-left w-70"
            style={{ textAlign: 'unset' }}
          >
            {carrier}
          </div>
        </div>
        <div className="float-left w-100 border_bottom_custom"></div>
        <div className="float-left w-100 inner_padding">
          <div className="section-details--info-title semi-bold float-left w-30">
            Shipping & Handling
          </div>
          <div
            className="word-break-all float-left w-70"
            style={{ textAlign: 'unset' }}
          >
            {shippingCharge
              ? `${currency.symbol}${formatNumber(shippingCharge)}`
              : 'N/A'}
          </div>
        </div>
        <div className="float-left w-100 border_bottom_custom"></div>
        <div className="float-left w-100 inner_padding">
          <div className="section-details--info-title semi-bold float-left w-30">
            Tracking No.
          </div>
          {trackingNo ? (
            <a
              href={`https://www.google.com/search?q=${trackingNo}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {trackingNo}
            </a>
          ) : (
            <div
              className="word-break-all float-left w-70"
              style={{ textAlign: 'unset' }}
            >
              N/A
            </div>
          )}
        </div>
        {shipmentNote && (
          <div className="float-left w-100 border_bottom_custom"></div>
        )}
      </div>
      {shipmentNote && (
        <div className="shipment-info-card--note float-left w-100">
          <div
            className="section-details--info-title semi-bold"
            style={{ marginBottom: '5px' }}
          >
            Notes.
          </div>
          <div className="capitalize">{shipmentNote}</div>
        </div>
      )}
      {!shipmentNote && (
        <div className="shipment-info-card--note float-left w-100">
          <div
            className="section-details--info-title semi-bold"
            style={{ marginBottom: '5px' }}
          ></div>
          <div className="capitalize"></div>
        </div>
      )}
      {hasPermissionCustom('shipment', 'view') &&
        packageStatus !== 'delivered' && (
          <div className="float-left w-100 border_bottom_custom"></div>
        )}
      {hasPermissionCustom('shipment', 'view') &&
        packageStatus !== 'delivered' && (
          <div className="shipment-info-card--footer">
            <button
              className="button--secondary package_button package_ship_btn layout-box--button"
              onClick={closeShipmentModal}
            >
              Close
            </button>
          </div>
        )}
    </div>
  ) : (
    <div className="shipment-info-card float-left w-100">
      <div className="shipment-info float-left w-100 shipment_padding">
        <div className="float-left w-100 inner_padding">
          <div className="section-details--info-title semi-bold float-left w-30">
            Refund Date
          </div>
          <div
            className="word-break-all float-left w-70"
            style={{ textAlign: 'unset' }}
          >
            {getDate(selectedRefund.refund_date)}
          </div>
        </div>
        <div className="float-left w-100 border_bottom_custom"></div>
        <div className="float-left w-100 inner_padding">
          <div className="section-details--info-title semi-bold float-left w-30">
            Amount
          </div>
          <div
            className="word-break-all float-left w-70"
            style={{ textAlign: 'unset' }}
          >
            {currency.symbol}
            {selectedRefund.refund_credits}
          </div>
        </div>
        <div className="float-left w-100 border_bottom_custom"></div>
        <div className="float-left w-100 inner_padding">
          <div className="section-details--info-title semi-bold float-left w-30">
            Payment Mode
          </div>
          <div
            className="word-break-all float-left w-70"
            style={{ textAlign: 'unset' }}
          >
            {selectedRefund.refund_mode}
          </div>
        </div>
        <div className="float-left w-100 border_bottom_custom"></div>
        <div className="float-left w-100 inner_padding">
          <div className="section-details--info-title semi-bold float-left w-30">
            Reference
          </div>
          <div
            className="word-break-all float-left w-70"
            style={{ textAlign: 'unset' }}
          >
            {selectedRefund.reference ? `${selectedRefund.reference}` : 'N/A'}
          </div>
        </div>
        {selectedRefund.note && (
          <div className="float-left w-100 border_bottom_custom"></div>
        )}
      </div>
      {selectedRefund.note && (
        <div className="shipment-info-card--note float-left w-100">
          <div
            className="section-details--info-title semi-bold"
            style={{ marginBottom: '5px' }}
          >
            Description
          </div>
          <div className="capitalize">{selectedRefund.note}</div>
        </div>
      )}
      {!selectedRefund.note && (
        <div className="shipment-info-card--note float-left w-100">
          <div
            className="section-details--info-title semi-bold"
            style={{ marginBottom: '5px' }}
          ></div>
          <div className="capitalize"></div>
        </div>
      )}
      {!selectedRefund.note && (
        <div className="float-left w-100 border_bottom_custom"></div>
      )}
      {selectedRefund.note && (
        <div className="float-left w-100 border_bottom_custom"></div>
      )}
      <div className="shipment-info-card--footer">
        <button
          className="button--secondary package_button package_ship_btn layout-box--button"
          onClick={closeViewRefundModal}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ShipmentInfoCard;
