import React, { Component, Fragment } from 'react';

import { restRequest } from '../../../../../Helpers/RequestHelper';
import { PackageIcon } from '../../../../../Assets/Navigation/NavigationIcons';

import Loader from '../../../../../Components/Loader/Loader';
import CustomModal from '../../../../../Components/CustomModal/CustomModal';
import { NoItemsBox } from '../../../../../Components/Layout/Box';
import {
  getObjFromLS,
  setObjInLS,
  // setValueInLS,
} from '../../../../../Helpers/LocalStorage';

import ShipmentCreate from "./../../ShipmentCreate";
import ShipmentInfoCard from "./ShipmentInfoCard";
import { checkError } from "../../../../../Helpers/AuthHelper";

class ShipmentInfo extends Component {
  packageID = this.props.packageID;

  state = {
    loading: true,
    shipment: {},
    currency: {},
    showShipmentModal: false,
    showShipmentDeleteModal: false,
  };

  componentDidMount() {
    this.fetchShipmentInfo();
  }

  fetchShipmentInfo() {
    this.setState({ loading: true });
    restRequest('get', `shipments/${this.packageID}`)
      .then((res) => {
        this.setState({
          shipment: res.data.shipment || {},
          currency: res.data.base_currency,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error);
        this.setState({
          loading: false,
        });
      });
  }

  closeModal = () => {
    this.setState({
      showShipmentModal: false,
      showShipmentDeleteModal: false,
    });
  };

  handleShipmentCreateSubmit = () => {
    if (this.props.forceRedirectFlag) {
      this.props.forceRedirect();
    } else {
      this.props.fetchData();
    }
    this.closeModal();
  };

  handleDeleteShipment = () => {
    this.props.onClose();
    restRequest('delete', `shipments/${this.packageID}`)
      .then(() => {
        if (this.props.forceRedirectFlag) {
          this.props.forceRedirect();
        }
        this.props.fetchData();
        // this.closeModal()
        this.props.handleToast('Shipment has been deleted', 'success');
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error");
      });
  };

  renderShipmentDeleteModal() {
    return (
      <CustomModal
        type="delete"
        showModal={this.state.showShipmentDeleteModal}
        title="Delete Shipment"
        onClose={this.closeModal}
        onSubmit={this.handleDeleteShipment}
      >
        Are you sure you want to delete this Shipment?
      </CustomModal>
    );
  }

  openShipmentDeleteModal = () => {
    let obj = getObjFromLS('module');
    setObjInLS('module', { ...obj, action: 'ShipmentCreate' });
    this.setState({
      showShipmentDeleteModal: true,
    });
  };

  closeShipmentModal = () => {
    this.props.onClose();
    this.closeModal();
  };

  renderShippingModal() {
    return (
      <CustomModal
        showModal={this.state.showShipmentModal}
        title="Create Shipment"
        renderActions={false}
        size="small"
        onClose={this.closeModal}
      >
        <ShipmentCreate
          packageId={this.props.packageID}
          packageNo={this.props.packageNo}
          onClose={this.closeModal}
          onSubmit={this.handleShipmentCreateSubmit}
          handleToast={this.props.handleToast}
        />
      </CustomModal>
    );
  }

  renderShipmentInfo() {
    const { shipment, currency } = this.state;
    return (
      <Fragment>
        {this.renderShipmentDeleteModal()}
        <ShipmentInfoCard
          type="shipment"
          shipmentOrderNo={shipment.shipment_no}
          shipmentNote={shipment.note}
          shipmentDate={shipment.shipment_date}
          carrier={shipment.carrier}
          trackingNo={shipment.tracking_no}
          shippingCharge={shipment.shipping_charge}
          currency={currency}
          openShipmentDeleteModal={this.openShipmentDeleteModal}
          closeShipmentModal={this.closeShipmentModal}
          packageStatus={this.props.packageStatus}
          hasPermissionCustom={this.props.hasPermissionCustom}
        />
      </Fragment>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return (
      <Fragment>
        {this.renderShippingModal()}
        {!Object.keys(this.state.shipment).length ? (
          <NoItemsBox
            subtitle="Package not shipped yet."
            buttonText="Ship now?"
            showButton={this.props.hasPermissionCustom('shipment', 'Create')}
            handleclick={() => this.setState({ showShipmentModal: true })}
            Icon={PackageIcon}
          />
        ) : (
          this.renderShipmentInfo()
        )}
      </Fragment>
    );
  }
}

export default ShipmentInfo;
