import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import './PageNumber.css';
import getModuleName from '../../Helpers/getModuleName';

const PageNumber = ({
  totalPage = 1,
  currentPage,
  handleSelectedPage,
  nextPage,
  previousPage,
  // paginationLength = 5,
  history,
  totalRecords,
  listing_name,
}) => {
  function handleChange(e) {
    let value = e.target.value;
    if (value > totalPage) {
      value = totalPage;
    } else if (!value) {
      value = 0;
    }
    setPageValue(value);
  }

  const [pageValue, setPageValue] = useState(currentPage);
  return (
    <div className={classNames('page-number-container')}>
      <Link
        to={previousPage()}
        className={`button pointer arrows left_arrow ${
          pageValue < 2 ? 'disabled-link' : ''
        }`}
      >
        <svg
          viewBox="0 0 20 20"
          className={classNames('page-number-arrow--icon')}
        >
          <path d="M20 0 L10 10 L20 20" />
        </svg>
      </Link>

      <input
        type="number"
        className={classNames('pagination-input')}
        onChange={(e) => handleChange(e)}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            return history.push(handleSelectedPage(pageValue));
          }
        }}
        value={pageValue}
      />

      <Link
        to={nextPage()}
        className={`button pointer arrows right_arrow ml_3 ${
          pageValue === totalPage ? 'disabled-link' : ''
        }`}
      >
        <svg
          viewBox="0 0 20 20"
          className={classNames('page-number-arrow--icon')}
        >
          <path d="M0 0 L10 10 L0 20" />
        </svg>
      </Link>
      <div className={classNames('show_total')}>
        of {totalPage}
        <div
          className={`totalcount3 ${listing_name}`}
          /*UI_DEV : tooltip text color */
          style={{ color: 'white' }}
        >{`Total ${getModuleName(listing_name)} : ${totalRecords}`}</div>
      </div>
    </div>
  );
};

export default PageNumber;
