import React from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import SearchableDropdown from './../../SearchableDropdown/SearchableDropdown';
import MultiSelect from './../../MultiSelect/MultiSelect';
import AutoSuggest from '../../AutoSuggest/AutoSuggest';
import GroupDropdown from '../../GroupDropdown/GroupDropdown';
import FileUploader from '../../FileUploader/FileUploader';
import SingleDatePicker from '../../SingleDatePicker/SingleDatePicker';
import SelectPaginationField from '../../SelectPaginationField';
import SelectDynamicPaginationField from '../../SelectDynamicPaginationField';

import './Field.css';

const Field = ({
  newCust = {},
  editCust = {},
  name,
  value = '',
  defaultValue,
  id,
  placeholder = '',
  size = '', // small, medium, large
  type = 'input',
  required = false,
  handleChange,
  onInputChange,
  handleFocus,
  autoComplete = 'off',
  valueKey,
  labelKey,
  labelStyle = false,
  camIconflag = false,
  createButton = false,
  options = [],
  multi = false,
  removeSelected = false,
  dropdownValue,
  dropdownLabel,
  creatable = false,
  addButton = false,
  disableOption = '',
  disabled = false,
  withImage = false,
  itemsDropDown = false,
  className = '',
  searchable,
  clearable = true,
  children,
  leftData = null,
  rightData = null,
  itemsAdjustment = false,
  additionalSearchFilter = '',
  validationCheck = false,
  decimalScale = 2,
  thousandSeparator = ',',
  allowNegative = false,
  onValueChange,
  error = false,
  errorStringCheck = false, // check if error is a string or not
  onBlur = () => {},
  tableField = false,
  passwordToggleView = false,
  suggestions = [],
  onSuggestionsFetchRequested = () => {},
  onSuggestionsClearRequested = () => {},
  removeSuggestion = () => {},
  onKeyDown = () => {},
  inputProps = {},
  bottomText = false,
  format = '+1 (###) ###-####',
  minDate,
  suffix,
  prefix,
  isAddress,
  onDrop,
  multiple,
  accept,
  maxSize,
  noResultsText,
  displayType,
  autoFocus = false,
  reference = null,
  alphabetSort = false,
  isAllowed = () => true,
  removeValueOnClick = false,
  addValueOnBlur = 0,
  searchKeys = ['label'],
  noOptionFoundMessage = 'No Options Found',
  addType = '',
  api = '',
  rowid = 0,
  from = '',
  renderLoadOption = false,
  selecteditem = [],
  handlekeyPress = () => {},
  fromBulk = false,
  onKeyUp = () => null,
}) => {
  const updateValueOnConditionaly = React.useRef(null);

  function renderInput() {
    return (
      <div className="form-input-wrapper">
        <input
          className={classNames(
            'form-input',
            size === '' ? null : size,
            !!leftData && 'with-side-data--left',
            !!rightData && 'with-side-data--right',
            validationCheck && 'required'
          )}
          placeholder={placeholder}
          id={id}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          disabled={disabled}
          onFocus={handleFocus}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          type={
            type === 'password'
              ? 'password'
              : displayType === 'hidden'
              ? 'hidden'
              : 'text'
          }
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
        />
        {passwordToggleView && renderPasswordToggleView()}
      </div>
    );
  }

  function renderUncontrolledInput() {
    return (
      <div className="form-input-wrapper">
        <input
          className={classNames(
            'form-input',
            size,
            validationCheck && 'required'
          )}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={onBlur}
          disabled={disabled}
          onFocus={handleFocus}
          autoFocus={autoFocus}
          ref={reference}
          autoComplete={autoComplete}
          value={value}
          onKeyPress={(e) => {
            fromBulk
              ? handlekeyPress(e)
              : e.key === 'Enter' && e.preventDefault();
          }}
        />
      </div>
    );
  }

  function renderPasswordToggleView() {
    return (
      <span className="password-toggle-view" onClick={passwordToggleView}>
        {type !== 'password' ? (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 3.5C8.05723 3.50104 6.15976 4.08688 4.55456 5.18127C2.94936 6.27566 1.71077 7.82792 1 9.636C1.70973 11.4448 2.94805 12.9979 4.55347 14.0925C6.15889 15.1872 8.0569 15.7727 10 15.7727C11.9431 15.7727 13.8411 15.1872 15.4465 14.0925C17.0519 12.9979 18.2903 11.4448 19 9.636C18.2892 7.82792 17.0506 6.27566 15.4454 5.18127C13.8402 4.08688 11.9428 3.50104 10 3.5ZM10 13.727C9.19088 13.727 8.39992 13.4871 7.72716 13.0375C7.0544 12.588 6.53005 11.9491 6.22041 11.2016C5.91077 10.454 5.82976 9.63146 5.98761 8.83789C6.14546 8.04431 6.53509 7.31536 7.10723 6.74323C7.67936 6.17109 8.40831 5.78146 9.20189 5.62361C9.99546 5.46576 10.818 5.54677 11.5656 5.85641C12.3131 6.16605 12.952 6.6904 13.4015 7.36316C13.8511 8.03592 14.091 8.82688 14.091 9.636C14.0907 10.7209 13.6596 11.7613 12.8925 12.5285C12.1253 13.2956 11.0849 13.7267 10 13.727ZM10 7.182C9.51445 7.182 9.0398 7.32598 8.63608 7.59574C8.23235 7.8655 7.91769 8.24892 7.73188 8.69751C7.54606 9.14611 7.49745 9.63972 7.59217 10.1159C7.6869 10.5922 7.92071 11.0296 8.26405 11.3729C8.60739 11.7163 9.04483 11.9501 9.52105 12.0448C9.99728 12.1396 10.4909 12.0909 10.9395 11.9051C11.3881 11.7193 11.7715 11.4046 12.0413 11.0009C12.311 10.5972 12.455 10.1226 12.455 9.637C12.4555 9.31446 12.3924 8.99498 12.2692 8.69689C12.146 8.3988 11.9652 8.12795 11.7371 7.89988C11.509 7.6718 11.2382 7.49099 10.9401 7.3678C10.642 7.24461 10.3225 7.18147 10 7.182Z"
              fill="#ADB6BC"
            />
          </svg>
        ) : (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 5.27406C10.5381 5.27327 11.071 5.37855 11.5683 5.58388C12.0657 5.78921 12.5176 6.09055 12.8984 6.47069C13.2792 6.85082 13.5813 7.30229 13.7875 7.79928C13.9937 8.29626 14.0999 8.82901 14.1 9.36706C14.0997 9.88144 13.9995 10.3909 13.805 10.8671L16.195 13.2571C17.4417 12.2164 18.4064 10.8786 19 9.36706C18.2891 7.55881 17.0506 6.0063 15.4455 4.91144C13.8404 3.81658 11.943 3.23002 10 3.22806C8.88762 3.22693 7.78363 3.42052 6.738 3.80006L8.506 5.56906C8.98032 5.37531 9.48764 5.27514 10 5.27406ZM1.819 3.04006L3.685 4.90606L4.062 5.28306C2.69369 6.3453 1.63612 7.75586 1 9.36706C1.46604 10.5536 2.16193 11.6364 3.04769 12.5532C3.93345 13.47 4.99163 14.2028 6.16144 14.7094C7.33124 15.216 8.58962 15.4865 9.86427 15.5053C11.1389 15.5241 12.4047 15.2909 13.589 14.8191L13.933 15.1631L16.333 17.5531L17.373 16.5131L2.858 2.00006L1.819 3.04006ZM6.345 7.56606L7.614 8.83506C7.57176 9.00927 7.54994 9.18781 7.549 9.36706C7.54847 9.68931 7.61148 10.0085 7.7344 10.3064C7.85733 10.6043 8.03777 10.875 8.2654 11.1031C8.49304 11.3312 8.76341 11.5122 9.06104 11.6357C9.35868 11.7593 9.67775 11.8229 10 11.8231C10.1793 11.8221 10.3578 11.8003 10.532 11.7581L11.8 13.0261C11.0361 13.4106 10.1703 13.5445 9.32594 13.4086C8.48154 13.2728 7.70148 12.8741 7.09672 12.2693C6.49197 11.6646 6.0933 10.8845 5.95745 10.0401C5.82159 9.19573 5.95545 8.32999 6.34 7.56606H6.345ZM9.873 6.92806L12.451 9.50606L12.467 9.37506C12.4674 9.05256 12.4042 8.73314 12.2809 8.43511C12.1577 8.13707 11.9769 7.86628 11.7488 7.63823C11.5208 7.41019 11.25 7.22937 10.952 7.10613C10.6539 6.9829 10.3345 6.91967 10.012 6.92006L9.873 6.92806Z"
              fill="#ADB6BC"
            />
          </svg>
        )}
      </span>
    );
  }

  function renderInputWithLeftData() {
    return (
      <div className="inline">
        <div className="side-data--left">{leftData}</div>
        {renderInput()}
      </div>
    );
  }

  function renderInputWithRightData() {
    return (
      <div className="inline">
        {renderInput()}
        <div className="side-data--right">{rightData}</div>
      </div>
    );
  }

  function renderInputType() {
    if (!!leftData) return renderInputWithLeftData();
    else if (!!rightData) return renderInputWithRightData();
    else return renderInput();
  }

  function renderNumberInput() {
    const addValueOnHandlerClick = (e, value, addValueOnBlur) => {
      let val = +value;
      if (!val) {
        onValueChange({
          formattedValue: `$${addValueOnBlur}`,
          value: `${addValueOnBlur}`,
          floatValue: addValueOnBlur,
        });
      } else {
        onValueChange({
          formattedValue: `$${value}`,
          value: `${value}`,
          floatValue: value,
        });
        updateValueOnConditionaly.current.value = '$' + value;
      }
    };
    const removeValueOnHandlerClick = (e, value) => {
      let val = +value;
      if (!val) {
        //  updateValueOnConditionaly.current.value = '';
        onValueChange({
          formattedValue: ``,
          value: ``,
          floatValue: null,
        });
      }
    };
    return (
      <NumberFormat
        className={classNames(
          'form-input',
          'number-input',
          { className },
          size,
          !!leftData && 'with-side-data--left',
          !!rightData && 'with-side-data--right',
          validationCheck && 'required'
        )}
        placeholder={placeholder}
        id={id}
        value={value}
        onChange={handleChange}
        onBlur={(e) => {
          removeValueOnClick
            ? addValueOnHandlerClick(e, value, addValueOnBlur)
            : onBlur(e);
        }}
        onClick={(e) => {
          removeValueOnClick && removeValueOnHandlerClick(e, value);
        }}
        //onBlur={onBlur}
        disabled={disabled}
        decimalScale={decimalScale}
        thousandSeparator={thousandSeparator}
        allowNegative={allowNegative}
        onValueChange={onValueChange}
        suffix={suffix}
        prefix={prefix}
        getInputRef={updateValueOnConditionaly}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        // autoFocus={autoFocus}
      />
    );
  }

  function renderNumberInputItemAdjustment() {
    return (
      <NumberFormat
        className={classNames(
          'form-input',
          'number-input',
          { className },
          size,
          !!leftData && 'with-side-data--left',
          !!rightData && 'with-side-data--right',
          validationCheck && 'required'
        )}
        placeholder={placeholder}
        id={id}
        value={value}
        onChange={handleChange}
        onFocus={(e) => {
          e.target.value = parseInt(e.target.value) === 0 ? '' : e.target.value;
        }}
        onBlur={onBlur}
        disabled={disabled}
        decimalScale={decimalScale}
        thousandSeparator={thousandSeparator}
        allowNegative={allowNegative}
        onValueChange={onValueChange}
        suffix={suffix}
        prefix={prefix}
        isAllowed={isAllowed}
      />
    );
  }

  function renderNumberInputWithLeftData() {
    return (
      <div className="inline">
        <div className="side-data--left">{leftData}</div>
        {renderNumberInput()}
      </div>
    );
  }

  function renderNumberInputWithRightData() {
    return (
      <div>
        <span className="inline">
          {renderNumberInput()}
          <span className="side-data--right">{rightData}</span>
        </span>
      </div>
    );
  }

  function renderNumberInputType() {
    if (!!leftData) return renderNumberInputWithLeftData();
    else if (!!rightData) return renderNumberInputWithRightData();
    else if (itemsAdjustment) return renderNumberInputItemAdjustment();
    else return renderNumberInput();
  }

  function renderPhoneNumberInput() {
    return (
      <NumberFormat
        className={classNames(
          'form-input',
          size,
          { className },
          validationCheck && 'required'
        )}
        placeholder={placeholder}
        id={id}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        disabled={disabled}
        onValueChange={onValueChange}
        format={format} // "ex: +1 (###) ###-####
        mask="_"
      />
    );
  }

  function renderTextArea() {
    return (
      <textarea
        className={`form-input textarea ${
          validationCheck ? 'required' : ''
        } ${size}`}
        placeholder={placeholder}
        id={id}
        value={value}
        onChange={handleChange}
        autoFocus={autoFocus}
      />
    );
  }

  function renderDatePicker() {
    return (
      <SingleDatePicker
        className={`form-input ${size} ${validationCheck ? 'required' : ''}`}
        id={id}
        value={value}
        handleChange={handleChange}
        onBlur={onBlur}
        disabled={disabled}
        minDate={minDate}
      />
    );
  }

  function renderDropdown() {
    return (
      <SearchableDropdown
        id={id}
        autoFocus={autoFocus}
        state={value}
        options={options}
        multi={multi}
        removeSelected={removeSelected}
        value={dropdownValue}
        label={dropdownLabel}
        itemsDropDown={itemsDropDown}
        handleChange={handleChange}
        onInputChange={onInputChange}
        placeholder={placeholder}
        disabled={disabled}
        searchable={searchable}
        addButton={addButton}
        disableOption={disableOption}
        withImage={withImage}
        className={className}
        creatable={creatable}
        clearable={clearable}
        validationCheck={validationCheck}
        onBlur={onBlur}
        size={size}
        isAddress={isAddress}
        additionalSearchFilter={additionalSearchFilter}
        alphabetSort={alphabetSort}
        onKeyDown={onKeyDown}
      />
    );
  }

  function renderbadge() {
    return (
      <SearchableDropdown
        id={id}
        autoFocus={autoFocus}
        state={value}
        options={options}
        multi={multi}
        removeSelected={removeSelected}
        value={dropdownValue}
        badge
        label={dropdownLabel}
        handleChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
        searchable={searchable}
        addButton={addButton}
        disableOption={disableOption}
        withImage={withImage}
        className={className}
        creatable={creatable}
        clearable={clearable}
        validationCheck={validationCheck}
        onBlur={onBlur}
        size={size}
        isAddress={isAddress}
        addType={addType}
        additionalSearchFilter={additionalSearchFilter}
      />
    );
  }

  function renderGroupDropdown() {
    return (
      <GroupDropdown
        size={size}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        options={options}
        onBlur={onBlur}
        handleChange={handleChange}
        disabled={disabled}
      />
    );
  }

  function renderMultiSelect() {
    return (
      <MultiSelect
        id={id}
        value={value}
        valueKey={valueKey}
        labelKey={labelKey}
        options={options}
        handleChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
        searchable={searchable}
        removeSelected={removeSelected}
        clearable={clearable}
        className={className}
        additionalSearchFilter={additionalSearchFilter}
        validationCheck={validationCheck}
        onBlur={onBlur}
        size={size}
        noResultsText={noResultsText}
      />
    );
  }

  function renderImageUploader() {
    return (
      <FileUploader
        accept={accept}
        onDrop={onDrop}
        multiple={multiple}
        maxSize={maxSize}
        camIcon={camIconflag}
      />
    );
  }

  const PaginationDropdown = () => {
    return (
      <SelectPaginationField
        optionsList={options}
        value={value}
        handleChange={handleChange}
        error={error}
        dropdownValue={dropdownValue}
        dropdownLabel={dropdownLabel}
        addButton={addButton}
        alphabetSort={alphabetSort}
        searchKeys={searchKeys}
        disabled={disabled}
        placeholder={placeholder}
        onInputChange={onInputChange}
        clearable={clearable}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        withImage={withImage}
        noOptionFoundMessage={noOptionFoundMessage}
        autoFocus={autoFocus}
        fromSo
      />
    );
  };
  const DynamicPaginationDropdown = () => {
    return (
      <SelectDynamicPaginationField
        newCust={newCust}
        editCust={editCust}
        value={value}
        handleChange={handleChange}
        error={error}
        addButton={addButton}
        disabled={disabled}
        placeholder={placeholder}
        clearable={clearable}
        onBlur={onBlur}
        withImage={withImage}
        noOptionFoundMessage={noOptionFoundMessage}
        autoFocus={autoFocus}
        createButton={createButton}
        api={api}
        rowid={rowid}
        renderLoadOption={renderLoadOption}
        from={from}
        selecteditem={selecteditem}
      />
    );
  };

  function renderRadio() {
    return children;
  }

  function renderStaticBlock() {
    return <div>{value}</div>;
  }

  function renderError() {
    if (errorStringCheck) {
      if (typeof error === 'string')
        return (
          <div
            className={`input-feedback${tableField ? ' table-error--msg' : ''}`}
          >
            {error}
          </div>
        );
      return null;
    }
    if (error)
      return (
        <div
          className={`input-feedback${tableField ? ' table-error--msg' : ''}`}
        >
          {error}
        </div>
      );

    return null;
  }

  function renderAutoSuggestInput() {
    return (
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        inputProps={inputProps}
        removeSuggestion={removeSuggestion}
      />
    );
  }

  function switchInputs(type) {
    switch (type) {
      case 'image':
        return renderImageUploader();
      case 'PaginationDropdown':
        return PaginationDropdown();
      case 'DynamicPaginationDropdown':
        return DynamicPaginationDropdown();
      case 'radio':
        return renderRadio();
      case 'textarea':
        return renderTextArea();
      case 'date':
        return renderDatePicker();
      case 'dropdown':
        return renderDropdown();
      case 'badge':
        return renderbadge();
      case 'groupdropdown':
        return renderGroupDropdown();
      case 'multiselect':
        return renderMultiSelect();
      case 'static':
        return renderStaticBlock();
      case 'number':
        return renderNumberInputType();
      case 'autosuggest':
        return renderAutoSuggestInput();
      case 'phone':
        return renderPhoneNumberInput();
      case 'input-uncontrolled':
        return renderUncontrolledInput();
      case 'input':
      default:
        return renderInputType();
    }
  }

  function renderBottomText() {
    if (!bottomText) return null;
    return (
      <div className="ellipsis">
        <div title={bottomText} className="form-field--bottom-text">
          {bottomText}
        </div>
      </div>
    );
  }
  return (
    <div
      className={`form-field ${error ? ' error' : ''}${
        className ? ` ${className}` : ''
      }${tableField ? ' table-field' : ''}`}
    >
      {name && labelStyle === true && (
        <label className="form-label" htmlFor={id} style={{ marginTop: '1px' }}>
          {name}
          {required && <span className="red"> *</span>}
        </label>
      )}
      {name && labelStyle === false && (
        <label className="form-label" htmlFor={id}>
          {name}
          {required && <span className="red"> *</span>}
        </label>
      )}
      {switchInputs(type)}
      {renderError()}
      {renderBottomText()}
    </div>
  );
};

export default Field;
