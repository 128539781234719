import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
// import ContactIconListing from '../../Assets/General/ContactIconListing'
import ImportIcon from '../../Assets/General/ImportIcon';
import { Header } from '../Layout/Layout';

import './Onboarding.css';

const Onboarding = ({
  name = '',
  FlowDiagram = () => null,
  description = '',
  buttonLabel = '',
  buttonLink = '/',
  videoFrame = '',
  DisplayIcon = null,
  headerTitle = '',
  secondaryButtonLabel = '',
  secondaryButtonLink = '/',
}) => (
  <Fragment>
    <Header title={headerTitle} Icon={DisplayIcon}></Header>
    <div className="float-left w-100 intialscreen_innerpages">
      <div className="left">
        <div className="center_image">
          {videoFrame}
          <div className="our_goal">
            <h2>{description}</h2>
          </div>
          <div className="actions_buttons">
            <div className="float-left w-100">
              {buttonLabel && (
                <Link to={buttonLink} className="">
                  <DisplayIcon />
                  {buttonLabel}
                </Link>
              )}
            </div>
            <div className="float-left w-100">
              {secondaryButtonLabel && (
                <Link to={secondaryButtonLink} className="">
                  <ImportIcon />
                  {secondaryButtonLabel}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="right">
        <div className="float-left w-100 h-100 items_flow">
          <div className="center_image">
            <FlowDiagram />
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);

export default Onboarding;

// OLD HTML STRUCTURE
// <GridLayout grid="1-1" className="on-boarding">
//<Grid transparent>
// {videoFrame}
// <p className="on-boarding--description">
// {description}
// </p>
// {
// buttonLabel &&
// <Link to={buttonLink} className="button button--primary">
// <DisplayIcon />
// {buttonLabel}
// </Link>
// }
// {
// secondaryButtonLabel &&
// <Link to={secondaryButtonLink} className="button button--secondary">
// <ImportIcon />
// {secondaryButtonLabel}
// </Link>
// }
// </Grid>
// <Grid transparent>
// <div className="on-boarding--semi-title semi-bold">{name} Flow</div>
// <FlowDiagram  />
// </Grid>
// </GridLayout>
