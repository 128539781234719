import React, { useEffect, useState } from 'react';
import { restRequest } from '../../../Helpers';
import { Form, Field, FormAction } from '../../../Components/Form';
import { Loader } from '../../../Components';


const DashboardPrefrences = (props) => {

  let db_card_default = {
    sales_information: "total",
    package_and_shipment: "total",
    purchase_information: "total",
    receivables: "total",
    payable: "total",
    top_selling_items: "total",
    top_selling_items_filter2: "quantity",
    top_customers: "total",
    sales_vs_purchase: "month",
    sales_order_summary: "month"
  }
  const card_options = [
    {
      label: "Today",
      value: "today"
    },
    {
      label: "This Week",
      value: "week"
    },
    {
      label: "This Month",
      value: "month"
    },
    {
      label: "Last 30 Days",
      value: "thirty_days"
    },
    {
      label: "Last 6 Months",
      value: "six_months"
    },
    {
      label: "This Year",
      value: "year"
    },
    {
      label: "Last Year",
      value: "last_year"
    },
    {
      label: "Total",
      value: "total"
    },
  ]

  const card_options_sales_vs_purchase = [
    {
      label: "Today",
      value: "today"
    },
    {
      label: "This Week",
      value: "week"
    },
    {
      label: "This Month",
      value: "month"
    },
    {
      label: "Last 4 Weeks",
      value: "thirty_days"
    },
    {
      label: "Last 6 Months",
      value: "six_months"
    },
    {
      label: "This Year",
      value: "year"
    },
    {
      label: "Last Year",
      value: "last_year"
    },
  ]

  const [currPrefrences, SetCurrPrefrences] = useState()
  const [loading, setloading] = useState(false)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    setloading(true)
    restRequest('get', 'preferences/dashboard').
      then((res) => {
        SetCurrPrefrences(res.preferences)
        setloading(false)
      })
      .catch((err) => {
        console.log(err)
        SetCurrPrefrences(db_card_default)
        setloading(false)
      })
  }



  const handleSubmit = (event) => {
    event.preventDefault()
    let payload = {
      status: {
        currPrefrences
      },
    }

    restRequest('put', 'preferences/dashboard', payload).
      then((res) => {
        props.handleToast(
          res.message,
          'success'
        );
      })

  }


  const renderActionButtons = () => {
    return (
      <FormAction
        submitLabel="Save"
        // disabled={isSubmitting}
        // onSubmit={handleSubmit}
        onCancel={() => props.history.push('/settings')}
      />
    );
  };
  const handlePrefrenceChange = (module, value) => {
    let new_db_default = {}
    switch (module) {
      case 'Sales Information': {
        new_db_default = {
          ...currPrefrences,
          sales_information: value
        }
      }
        break
      case 'Package & Shipment': {
        new_db_default = {
          ...currPrefrences,
          package_and_shipment: value
        }
      }
        break
      case 'Purchase Information': {
        new_db_default = {
          ...currPrefrences,
          purchase_information: value
        }
      }
        break
      case 'Receivables': {
        new_db_default = {
          ...currPrefrences,
          receivables: value
        }
      }
        break
      case 'Payable': {
        new_db_default = {
          ...currPrefrences,
          payable: value
        }
      }
        break
      case 'Top Selling Items': {
        new_db_default = {
          ...currPrefrences,
          top_selling_items: value
        }
      }
        break

      case 'Top Customers': {
        new_db_default = {
          ...currPrefrences,
          top_customers: value
        }
      }
        break
      case 'Sales vs Purchase': {
        new_db_default = {
          ...currPrefrences,
          sales_vs_purchase: value
        }
      }
        break
      case 'SO Summary': {
        new_db_default = {
          ...currPrefrences,
          sales_order_summary: value
        }
      }
        break
      default:
        new_db_default = {
          ...db_card_default
        }
    }
    SetCurrPrefrences(new_db_default)
  }

  return (
    <div className="float-left w-100 dashboard-preferences">
      <h4 className="mb_5 float-left w-100 mt_0">
        Card Preference
      </h4>
      {!loading ? (
        <Form onSubmit={handleSubmit}>
          <div className="sale-return-preference float-left w-100  preference-sn">
            <div className="float-left w-70 genral_tab_content">
              {/* {this.state.loading && <OverlayLoader />} */}
              <div className='dashboard-preferences'>
                <label className="list-table--checkbox-lable float-left w-100 preferences-deduction">
                  <div className="sale-return-preference--label-text float-left w-auto">
                    Sales Information
                  </div>
                  <Field
                    className="float-left"
                    type="dropdown"
                    size="medium"
                    value={currPrefrences?.sales_information}
                    options={card_options}
                    dropdownValue="value"
                    dropdownLabel="label"
                    searchable={false}
                    clearable={false}
                    handleChange={(value) => {

                      handlePrefrenceChange('Sales Information', value.value)
                    }
                    }
                  />
                </label>

                <label className="list-table--checkbox-lable float-left w-100 preferences-deduction">
                  <div className="sale-return-preference--label-text float-left w-auto">
                    Package & Shipment
                  </div>
                  <Field
                    className="float-left"
                    type="dropdown"
                    size="medium"
                    value={currPrefrences?.package_and_shipment}
                    options={card_options}
                    dropdownValue="value"
                    dropdownLabel="label"
                    searchable={false}
                    clearable={false}
                    handleChange={(value) => {

                      handlePrefrenceChange('Package & Shipment', value.value)
                    }
                    }
                  />
                </label>

                <label className="list-table--checkbox-lable float-left w-100 preferences-deduction">
                  <div className="sale-return-preference--label-text float-left w-auto">
                    Purchase Information
                  </div>
                  <Field
                    className="float-left"
                    type="dropdown"
                    size="medium"
                    value={currPrefrences?.purchase_information}
                    options={card_options}
                    dropdownValue="value"
                    dropdownLabel="label"
                    searchable={false}
                    clearable={false}
                    handleChange={(value) => {

                      handlePrefrenceChange('Purchase Information', value.value)
                    }
                    }
                  />
                </label>

                <label className="list-table--checkbox-lable float-left w-100 preferences-deduction">
                  <div className="sale-return-preference--label-text float-left w-auto">
                    Receivables
                  </div>
                  <Field
                    className="float-left"
                    type="dropdown"
                    size="medium"
                    value={currPrefrences?.receivables}
                    options={card_options}
                    dropdownValue="value"
                    dropdownLabel="label"
                    searchable={false}
                    clearable={false}
                    handleChange={(value) => {

                      handlePrefrenceChange('Receivables', value.value)
                    }
                    }
                  />
                </label>

                <label className="list-table--checkbox-lable float-left w-100 preferences-deduction">
                  <div className="sale-return-preference--label-text float-left w-auto">
                    Payable
                  </div>
                  <Field
                    className="float-left"
                    type="dropdown"
                    size="medium"
                    value={currPrefrences?.payable}
                    options={card_options}
                    dropdownValue="value"
                    dropdownLabel="label"
                    searchable={false}
                    clearable={false}
                    handleChange={(value) => {

                      handlePrefrenceChange('Payable', value.value)
                    }
                    }
                  />
                </label>

                <label className="list-table--checkbox-lable float-left w-100 preferences-deduction">
                  <div className="sale-return-preference--label-text float-left w-auto">
                    Top Selling Items
                  </div>
                  <Field
                    className="float-left"
                    type="dropdown"
                    size="medium"
                    value={currPrefrences?.top_selling_items}
                    options={card_options}
                    dropdownValue="value"
                    dropdownLabel="label"
                    searchable={false}
                    clearable={false}
                    handleChange={(value) => {

                      handlePrefrenceChange('Top Selling Items', value.value)
                    }
                    }
                  />
                </label>

                <label className="list-table--checkbox-lable float-left w-100 preferences-deduction">
                  <div className="sale-return-preference--label-text float-left w-auto">
                    Top Customers
                  </div>
                  <Field
                    className="float-left"
                    type="dropdown"
                    size="medium"
                    value={currPrefrences?.top_customers}
                    options={card_options}
                    dropdownValue="value"
                    dropdownLabel="label"
                    searchable={false}
                    clearable={false}
                    handleChange={(value) => {

                      handlePrefrenceChange('Top Customers', value.value)
                    }
                    }
                  />
                </label>

                <label className="list-table--checkbox-lable float-left w-100 preferences-deduction">
                  <div className="sale-return-preference--label-text float-left w-auto">
                    Sales vs Purchase
                  </div>
                  <Field
                    className="float-left"
                    type="dropdown"
                    size="medium"
                    value={currPrefrences?.sales_vs_purchase}
                    options={card_options_sales_vs_purchase}
                    dropdownValue="value"
                    dropdownLabel="label"
                    searchable={false}
                    clearable={false}
                    handleChange={(value) => {

                      handlePrefrenceChange('Sales vs Purchase', value.value)
                    }
                    }
                  />
                </label>

                <label className="list-table--checkbox-lable float-left w-100 preferences-deduction">
                  <div className="sale-return-preference--label-text float-left w-auto">
                    Sales Order Summary
                  </div>
                  <Field
                    className="float-left"
                    type="dropdown"
                    size="medium"
                    value={currPrefrences?.sales_order_summary}
                    options={card_options}
                    dropdownValue="value"
                    dropdownLabel="label"
                    searchable={false}
                    clearable={false}
                    handleChange={(value) => {

                      handlePrefrenceChange('SO Summary', value.value)
                    }
                    }
                  />
                </label>
              </div>
              <div className='dashboard-actions'>
                {renderActionButtons()}
              </div>
            </div>
          </div>
        </Form>
      ) :
        <Loader />}
    </div>
  );

};

export default DashboardPrefrences;