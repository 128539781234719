import React, { Component } from 'react';
import './DashboardPackageAndShipment.css';

// import { MdFormatAlignJustify } from '../../../Common/Icons';
import { restRequest } from '../../../Helpers/RequestHelper';
import Loader from '../../../Components/Loader/Loader';
// import NoDashboardDataFound from "../../../Components/NotFound/NoDashboardDataFound";
import PackagingIcon from '../../../Assets/Dashboard/PackagingIcon';
import ShipmentIcon from '../../../Assets/Dashboard/ShipmentIcon';
import DeliveryIcon from '../../../Assets/Dashboard/DeliveryIcon';
import { Link } from 'react-router-dom';
import AccessDenied from '../../../Components/AccessDenied/AccessDenied';
import DataError from '../../../Components/DashboardDataError/DB_DataError';
import { checkError } from '../../../Helpers/AuthHelper';

// var foundData = true

export default class DashboardPackageAndShipment extends Component {
  constructor(props) {
    super();
    this.state = {
      package: {},
      shipment: {},
      delivery: {},
      loading: true,
      start: '',
      end: '',
      accessDenied: false,
      accessError: false
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  dashboard_preferences = JSON.parse(localStorage.getItem("dashboardPreferences"))

  // fetchData(currentDateRange) {
  fetchData(currentDateRange = this.dashboard_preferences.package_and_shipment, startData, endDate) {
    this.setState({ loading: true });
    restRequest(
      'get',
      `dashboard/package_information/${currentDateRange === 'custom'
        ? `custom?starting_date=${startData}&ending_date=${endDate}`
        : currentDateRange
          ? currentDateRange
          : this.props.currentDateRange
      }`
    )
      .then((res) => {
        // if(res.found_data === true){
        const { shipment, packages, delivery } = res;
        this.setState({
          delivery,
          packages,
          shipment,
          loading: false,
          start: res.start_date,
          end: res.end_date,
        });
        //   foundData = true
        // }else{
        //   foundData = false
        //   this.setState({ loading: false })
        // }
      })
      .catch((err) => {
        checkError(err);
        if (err.response.status === 403) {
          this.setState({ accessDenied: true, loading: false });
        }
        else {
          this.setState({ accessError: true, loading: false });
        }
        //console.log(err)
      });
  }

  // componentWillReceiveProps(nextProps) {
  //   this.fetchData(nextProps.currentDateRange)
  // }
  componentWillReceiveProps(nextProps) {

    (nextProps.currentDateRange !== this.props.currentDateRange ||
      nextProps.startData !== this.props.startData ||
      nextProps.endDate !== this.props.endDate) &&
      this.fetchData(
        nextProps.currentDateRange.package_and_shipment,
        nextProps.startData,
        nextProps.endDate
      );
  }
  render() {
    if (this.state.loading) return <Loader />;
    // if(foundData === false) return <NoDashboardDataFound message='No package created yet' />
    if (this.state.accessDenied)
      return (
        <AccessDenied className="small dashboard " type="Package & Shipment" />
      );
    if (this.state.accessError)
      return (
        <DataError className="small dashboard " type="Package & Shipment" />
      );
    const { shipment, packages, delivery, start, end } = this.state;
    return (
      <div className="float-left w-100 dashboard_pkg_shipment">
        <div className="dashborad_sales_info_invoices right_border">
          {/* <MdFormatAlignJustify className="material-icon fill-green" /> */}
          <PackagingIcon className="material-icon fill-green" />
          <h4 className="semi-bold">Packaging</h4>
          <div className="float-left w-100 ">
            <Link
              className="float-left w-100 mb_10"
              to={`salesorders?filter=not fully packed&sort=created_at&page=1&orderBy=desc&view=20&start=${start}&end=${end}`}
            >
              <span
                className="float-left w-50 text-left pl_3"
                style={{ color: '#000' }}
              >
                Required
              </span>
              <span
                className="semi-bold float-left w-50"
                style={{ color: '#000' }}
              >
                {packages.required}
              </span>
            </Link>
            <Link
              className="float-left w-100"
              to={`packages?filter=all&sort=created_at&page=1&orderBy=desc&view=20&start=${start}&end=${end}`}
            >
              <span
                className="float-left w-50 text-left pl_3"
                style={{ color: '#000' }}
              >
                Packed
              </span>
              <span
                className="semi-bold float-left w-50"
                style={{ color: '#000' }}
              >
                {packages.packages}
              </span>
            </Link>
          </div>
        </div>
        <div className="dashborad_sales_info_invoices right_border">
          {/* <MdFormatAlignJustify className="material-icon fill-green" /> */}
          <ShipmentIcon className="material-icon fill-green" />
          <h4 className="semi-bold">Shipment</h4>
          <div className="float-left w-100 mb_10">
            <Link
              className="float-left w-100 mb_10"
              to={`packages?filter=not shipped&sort=created_at&page=1&orderBy=desc&view=20&start=${start}&end=${end}`}
            >
              <span
                className="float-left w-50 text-left pl_3"
                style={{ color: '#000' }}
              >
                Required
              </span>
              <span
                className="semi-bold float-left w-50"
                style={{ color: '#000' }}
              >
                {shipment.required}
              </span>
            </Link>
            <Link
              className="float-left w-100"
              to={`packages?filter=required delivery&sort=created_at&page=1&orderBy=desc&view=20&start=${start}&end=${end}`}
            >
              <span
                className="float-left w-50 text-left pl_3"
                style={{ color: '#000' }}
              >
                Shipped
              </span>
              <span
                className="semi-bold float-left w-50"
                style={{ color: '#000' }}
              >
                {shipment.shipped}
              </span>
            </Link>
          </div>
        </div>
        <div className="dashborad_sales_info_invoices">
          {/* <MdFormatAlignJustify className="material-icon fill-green" /> */}
          <DeliveryIcon className="material-icon fill-green" />
          <h4 className="semi-bold">Delivery</h4>
          <div className="float-left w-100 mb_10">
            <Link
              className="float-left w-100 mb_10"
              to={`packages?filter=shipped&sort=created_at&page=1&orderBy=desc&view=20&start=${start}&end=${end}`}
            >
              <span
                className="float-left w-50 text-left pl_3"
                style={{ color: '#000' }}
              >
                Required
              </span>
              <span
                className="semi-bold float-left w-50"
                style={{ color: '#000' }}
              >
                {delivery.required}
              </span>
            </Link>
            <Link
              className="float-left w-100"
              to={`packages?filter=delivered&sort=created_at&page=1&orderBy=desc&view=20&start=${start}&end=${end}`}
            >
              <span
                className="float-left w-50 text-left pl_3"
                style={{ color: '#000' }}
              >
                Delivered
              </span>
              <span
                className="semi-bold float-left w-50"
                style={{ color: '#000' }}
              >
                {delivery.delivered}
              </span>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
