import React, { Component } from 'react';
import { imagePathS3, restRequest } from '../../../Helpers/RequestHelper';
import Loader from '../../Loader/Loader';
import './PlansTable.css';
import { getCurrentOrganization } from '../../../Helpers/CurrentSession';
import { MdClose } from '../../../Common/Icons';
import orgImage from '../../../Assets/Img/org-image.jpg';
import { checkError } from '../../../Helpers/AuthHelper';
import CustomModal from '../../CustomModal/CustomModal';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { setObjInLS } from '../../../Helpers/LocalStorage';
import { renderMonthlyPrice, renderSavePrice } from '../../../Helpers';
import moment from 'moment';

export default class PlansTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      packageplansYearly: [],
      packageplansMonthly: [],
      packagePlansThreeMonths: [],
      packageplans: [],
      loading: true,
      showPaymentReview: false,
      showpaymentModal: false,
      slug: '',
      packageId: null,
      accpet: false,
      basicMonthlyPackage: '',
      standardMonthlyPackage: '',
      professionalMonthlyPackage: '',
      threeMonths: 3,
      twelveMonths: 12,
    };
  }

  componentDidMount() {
    this.fetchData();
    if (
      sessionStorage.getItem('OrganizationReadOnly') &&
      sessionStorage.getItem('OrganizationReadOnly') === true
    ) {
      setTimeout(() => {
        this.props.handleToast('Upgrade Package Plan', 'error');
        sessionStorage.setItem('OrganizationReadOnly', false);
      }, 20);
    }
  }

  fetchData() {
    this.setState({
      loading: true,
    });
    restRequest('get', `package_plan/${getCurrentOrganization().id}`, {})
      .then((res) => {
        this.setState({
          allPackages: res.packagePlan,
          packageplansYearly: res.packagePlanYearly,
          packageplansMonthly: res.packagePlanMonthly,
          packagePlansThreeMonths: res.packagePlanThreeMonths,
          packageplans: res.package_duration.includes('1 Months')
            ? res.packagePlanMonthly
            : res.package_duration.includes('1 Years')
            ? res.packagePlanYearly
            : res.packagePlanThreeMonths,
          planDuration: res.package_duration.includes('1 Months')
            ? 'monthly'
            : res.package_duration.includes('1 Years')
            ? 'yearly'
            : 'three-months',
          showTrialButton: res.show_trial_button,
          is_Three_Months_coupon: res.is_Three_Months_coupon,
          active_three_months: res.active_three_months,
          loading: false,
          currentPackage: res.current_package_plan_id,
          packageExpiry: res.current_package_plan_expiry,
          basicMonthlyPackage: res.packagePlanMonthly.find(
            (plan) => plan.slug === 'basic-package-monthly'
          ),
          standardMonthlyPackage: res.packagePlanMonthly.find(
            (plan) => plan.slug === 'standard-package-monthly'
          ),
          professionalMonthlyPackage: res.packagePlanMonthly.find(
            (plan) => plan.slug === 'professional-package-monthly'
          ),
        });
      })
      .catch((error) => checkError(error));
  }

  selectPlan(plan, packageId) {
    this.setState({
      loading: true,
    });
    restRequest(
      'post',
      `package_plan/${getCurrentOrganization().id}/${packageId}`,
      {
        trial: plan === 'trial' ? 'trial_package' : 'regular',
      }
    )
      .then((res) => {
        this.setState({
          loading: false,
        });
        this.props.handleToast(
          'Congratulations, Your trial has been set',
          'success'
        );
        setTimeout(() => {
          window.location.href = '/';
        }, 1000);
      })
      .catch((error) => {
        this.setState({ loading: false });
        checkError(error, this.props.handleToast);
      });
  }
  // paypal request
  paypalSubscribe = () => {
    const { packageId, slug } = this.state;
    this.setState({ loading: true });
    restRequest('post', `payment/paypal_payment`, {
      packageId,
      slug,
    })
      .then((res) => {
        window.location.href = res.url;
      })
      .catch((error) => {
        this.setState({ loading: false });
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error");
      });
  };
  // End paypal request
  // Stripe request
  stripeSubscribe = () => {
    const { packageId, slug } = this.state;
    this.setState({ loading: true });
    restRequest('post', `payment/stripe_payment`, {
      packageId,
      slug,
    })
      .then((res) => {
        window.location.href = res.url;
        setObjInLS('se_token', res.system_token);
      })
      .catch((error) => {
        this.setState({ loading: false });
        checkError(error, this.props.handleToast);
      });
  };
  // End Stripe request
  handlePaymentModal = () => {
    this.setState({
      showpaymentModal: true,
    });
  };
  closeModal = () => {
    this.setState({
      showpaymentModal: false,
    });
  };
  renderPaymentModal() {
    const { showpaymentModal } = this.state;
    return (
      <>
        <CustomModal
          className="add_address_width stripe-payment"
          showModal={showpaymentModal}
          title="Select Payment Method"
          renderActions={false}
          onClose={this.closeModal}
          size="medium"
        >
          <div className={classNames('popup-stripe-main')}>
            <div className={classNames('popup-stripe-left')}>
              <Link onClick={this.stripeSubscribe}>
                <div className={classNames('stripe-imgbox')}>
                  {/* <img src="https://i.ibb.co/nQ5Rcwm/stripe.png" alt /> */}
                  <img
                    src="https://i.ibb.co/DWvT7n5/stripe-logo.png"
                    alt="stripe-logo"
                  />
                </div>
              </Link>
              <button
                className="action-buttons next-btn btn-stripe"
                style={{ marginTop: '15px', marginBottom: '0' }}
                onClick={this.stripeSubscribe}
              >
                Connect with <span>Stripe</span>
              </button>
            </div>
            <div className={classNames('popup-stripe-right')}>
              <Link onClick={this.paypalSubscribe}>
                <div className={classNames('stripe-imgbox')}>
                  {/* <img src="https://i.ibb.co/gtfDzJb/Pay-Pal-logo.png" /> */}
                  <img
                    src="https://i.ibb.co/nP0v6wn/Pay-Pal-logo-1.png"
                    alt="paypal-logo"
                  />
                </div>
              </Link>
              <button
                className="action-buttons next-btn btn-paypal"
                style={{ marginTop: '15px', marginBottom: '0' }}
                onClick={this.paypalSubscribe}
              >
                <svg
                  width="101px"
                  height="32"
                  viewBox="0 0 101 32"
                  preserveAspectRatio="xMinYMin meet"
                  xmlns="http:&#x2F;&#x2F;www.w3.org&#x2F;2000&#x2F;svg"
                >
                  <path
                    fill="#003087"
                    d="M 12.237 2.8 L 4.437 2.8 C 3.937 2.8 3.437 3.2 3.337 3.7 L 0.237 23.7 C 0.137 24.1 0.437 24.4 0.837 24.4 L 4.537 24.4 C 5.037 24.4 5.537 24 5.637 23.5 L 6.437 18.1 C 6.537 17.6 6.937 17.2 7.537 17.2 L 10.037 17.2 C 15.137 17.2 18.137 14.7 18.937 9.8 C 19.237 7.7 18.937 6 17.937 4.8 C 16.837 3.5 14.837 2.8 12.237 2.8 Z M 13.137 10.1 C 12.737 12.9 10.537 12.9 8.537 12.9 L 7.337 12.9 L 8.137 7.7 C 8.137 7.4 8.437 7.2 8.737 7.2 L 9.237 7.2 C 10.637 7.2 11.937 7.2 12.637 8 C 13.137 8.4 13.337 9.1 13.137 10.1 Z"
                  ></path>
                  <path
                    fill="#003087"
                    d="M 35.437 10 L 31.737 10 C 31.437 10 31.137 10.2 31.137 10.5 L 30.937 11.5 L 30.637 11.1 C 29.837 9.9 28.037 9.5 26.237 9.5 C 22.137 9.5 18.637 12.6 17.937 17 C 17.537 19.2 18.037 21.3 19.337 22.7 C 20.437 24 22.137 24.6 24.037 24.6 C 27.337 24.6 29.237 22.5 29.237 22.5 L 29.037 23.5 C 28.937 23.9 29.237 24.3 29.637 24.3 L 33.037 24.3 C 33.537 24.3 34.037 23.9 34.137 23.4 L 36.137 10.6 C 36.237 10.4 35.837 10 35.437 10 Z M 30.337 17.2 C 29.937 19.3 28.337 20.8 26.137 20.8 C 25.037 20.8 24.237 20.5 23.637 19.8 C 23.037 19.1 22.837 18.2 23.037 17.2 C 23.337 15.1 25.137 13.6 27.237 13.6 C 28.337 13.6 29.137 14 29.737 14.6 C 30.237 15.3 30.437 16.2 30.337 17.2 Z"
                  ></path>
                  <path
                    fill="#003087"
                    d="M 55.337 10 L 51.637 10 C 51.237 10 50.937 10.2 50.737 10.5 L 45.537 18.1 L 43.337 10.8 C 43.237 10.3 42.737 10 42.337 10 L 38.637 10 C 38.237 10 37.837 10.4 38.037 10.9 L 42.137 23 L 38.237 28.4 C 37.937 28.8 38.237 29.4 38.737 29.4 L 42.437 29.4 C 42.837 29.4 43.137 29.2 43.337 28.9 L 55.837 10.9 C 56.137 10.6 55.837 10 55.337 10 Z"
                  ></path>
                  <path
                    fill="#009cde"
                    d="M 67.737 2.8 L 59.937 2.8 C 59.437 2.8 58.937 3.2 58.837 3.7 L 55.737 23.6 C 55.637 24 55.937 24.3 56.337 24.3 L 60.337 24.3 C 60.737 24.3 61.037 24 61.037 23.7 L 61.937 18 C 62.037 17.5 62.437 17.1 63.037 17.1 L 65.537 17.1 C 70.637 17.1 73.637 14.6 74.437 9.7 C 74.737 7.6 74.437 5.9 73.437 4.7 C 72.237 3.5 70.337 2.8 67.737 2.8 Z M 68.637 10.1 C 68.237 12.9 66.037 12.9 64.037 12.9 L 62.837 12.9 L 63.637 7.7 C 63.637 7.4 63.937 7.2 64.237 7.2 L 64.737 7.2 C 66.137 7.2 67.437 7.2 68.137 8 C 68.637 8.4 68.737 9.1 68.637 10.1 Z"
                  ></path>
                  <path
                    fill="#009cde"
                    d="M 90.937 10 L 87.237 10 C 86.937 10 86.637 10.2 86.637 10.5 L 86.437 11.5 L 86.137 11.1 C 85.337 9.9 83.537 9.5 81.737 9.5 C 77.637 9.5 74.137 12.6 73.437 17 C 73.037 19.2 73.537 21.3 74.837 22.7 C 75.937 24 77.637 24.6 79.537 24.6 C 82.837 24.6 84.737 22.5 84.737 22.5 L 84.537 23.5 C 84.437 23.9 84.737 24.3 85.137 24.3 L 88.537 24.3 C 89.037 24.3 89.537 23.9 89.637 23.4 L 91.637 10.6 C 91.637 10.4 91.337 10 90.937 10 Z M 85.737 17.2 C 85.337 19.3 83.737 20.8 81.537 20.8 C 80.437 20.8 79.637 20.5 79.037 19.8 C 78.437 19.1 78.237 18.2 78.437 17.2 C 78.737 15.1 80.537 13.6 82.637 13.6 C 83.737 13.6 84.537 14 85.137 14.6 C 85.737 15.3 85.937 16.2 85.737 17.2 Z"
                  ></path>
                  <path
                    fill="#009cde"
                    d="M 95.337 3.3 L 92.137 23.6 C 92.037 24 92.337 24.3 92.737 24.3 L 95.937 24.3 C 96.437 24.3 96.937 23.9 97.037 23.4 L 100.237 3.5 C 100.337 3.1 100.037 2.8 99.637 2.8 L 96.037 2.8 C 95.637 2.8 95.437 3 95.337 3.3 Z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </CustomModal>
      </>
    );
  }
  handleThreeMonths = () => {
    const { packagePlansThreeMonths } = this.state;
    this.setState({
      packageplans: packagePlansThreeMonths,
      planDuration: 'three-months',
    });
  };
  handleLinkMontlhy = () => {
    const { packageplansMonthly } = this.state;
    this.setState({
      packageplans: packageplansMonthly,
      planDuration: 'monthly',
    });
  };
  handlePlanChange = (packageplans, planDuration) => {
    this.setState({
      packageplans,
      planDuration,
    });
  };
  // rehan
  // optimize code here
  // renderPackages(){

  // }
  renderPackages() {
    let dataFromLS = JSON.parse(localStorage.getItem('currentOrganization'));
    const {
      planDuration,
      packageplansYearly,
      packageplansMonthly,
      packageplans,
      currentPackage,
      showTrialButton,
      basicMonthlyPackage,
      standardMonthlyPackage,
      professionalMonthlyPackage,
      threeMonths,
      twelveMonths,
      packageExpiry,
      is_Three_Months_coupon,
      active_three_months,
    } = this.state;
    return (
      <div className="float-left w-100 py_3">
        <div className="float-left w-100 text-center subscription-title">
          Subscribe to SeeBiz Inventory
        </div>
        {/* Yearly and monthly */}
        <div className="float-left w-100 text-center position-relative mb-30">
          <div className="slect_duration">
            <div className="background">
              <p> Select Plan Duration : </p>
              <div className="radio_botton">
                <input
                  type="radio"
                  name="name"
                  id="Yearly"
                  value="yearly"
                  checked={planDuration === 'yearly'}
                  onClick={() =>
                    this.handlePlanChange(packageplansYearly, 'yearly')
                  }
                ></input>
                <label
                  htmlFor="Yearly"
                  onClick={() =>
                    this.handlePlanChange(packageplansYearly, 'yearly')
                  }
                >
                  Yearly
                </label>
                <input
                  type="radio"
                  name="name"
                  id="Monthly"
                  value="monthly"
                  checked={
                    planDuration === 'monthly' ||
                    planDuration === 'three-months'
                  }
                  onClick={() =>
                    this.handlePlanChange(packageplansMonthly, 'monthly')
                  }
                ></input>
                <label
                  htmlFor="Montly"
                  onClick={() =>
                    this.handlePlanChange(packageplansMonthly, 'monthly')
                  }
                >
                  Monthly
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="plan-main">
          {is_Three_Months_coupon && (
            <>
              {(planDuration === 'monthly' ||
                planDuration === 'three-months') && (
                <p className="plan-pay">Pay quarterly and get 50% off!</p>
              )}
              {planDuration === 'monthly' && (
                <>
                  <p className="plan-discount">
                    Click below to apply the discount coupon
                  </p>
                  <button
                    onClick={this.handleThreeMonths}
                    className="btn btn-coupon"
                  >
                    Apply Coupon
                  </button>
                </>
              )}
            </>
          )}
          {active_three_months && planDuration === 'three-months' && (
            <>
              <p className="coupon-success">Coupon Applied !</p>
            </>
          )}
        </div>
        <div
          className={`float-left w-100 packages packages-new-blocks ${
            planDuration === 'yearly' ? 'plans-yearly-set' : ''
          }`}
        >
          {packageplans &&
            packageplans.map((plan, index) => {
              if (plan && plan.slug !== 'free-package')
                return (
                  <>
                    <div
                      key={index}
                      className={`${
                        plan.slug === 'basic-package-three-months'
                          ? 'packages_plan package-basic'
                          : 'packages_plan'
                      } ${currentPackage === plan.id ? 'current_package' : ''}`}
                    >
                      <div className="hover-bar"></div>
                      <div
                        className={`border_custom ${
                          currentPackage === plan.id ? 'active_plans_table' : ''
                        }`}
                      >
                        <div className="float-left w-100 packages_plan_inner custom_height">
                          <div className="packages_name float-left w-100 text-center">
                            {plan.name.split(' ')[0]}
                          </div>
                          <div className="price float-left w-100 text-center">
                            {planDuration === 'monthly'
                              ? `$ ${plan.price}`
                              : plan.slug === 'basic-package-yearly'
                              ? `$${renderMonthlyPrice(
                                  basicMonthlyPackage.price,
                                  plan.discount,
                                  twelveMonths,
                                  plan.duration
                                )}`
                              : plan.slug === 'standard-package-yearly'
                              ? `$${renderMonthlyPrice(
                                  standardMonthlyPackage.price,
                                  plan.discount,
                                  twelveMonths,
                                  plan.duration
                                )}`
                              : plan.slug === 'professional-package-yearly'
                              ? `$${renderMonthlyPrice(
                                  professionalMonthlyPackage.price,
                                  plan.discount,
                                  twelveMonths,
                                  plan.duration
                                )}`
                              : plan.slug === 'basic-package-three-months'
                              ? `$${renderMonthlyPrice(
                                  basicMonthlyPackage.price,
                                  plan.discount,
                                  threeMonths,
                                  plan.duration
                                )}`
                              : plan.slug === 'standard-package-three-months'
                              ? `$${renderMonthlyPrice(
                                  standardMonthlyPackage.price,
                                  plan.discount,
                                  threeMonths,
                                  plan.duration
                                )}`
                              : plan.slug ===
                                'professional-package-three-months'
                              ? `$${renderMonthlyPrice(
                                  professionalMonthlyPackage.price,
                                  plan.discount,
                                  threeMonths,
                                  plan.duration
                                )}`
                              : ''}
                            <div className="plan-line-through">
                              {(plan.slug === 'basic-package-three-months' ||
                                plan.slug === 'basic-package-yearly') && (
                                <>
                                  <p>${basicMonthlyPackage.price}</p>
                                </>
                              )}
                            </div>
                            <div className="plan-line-through">
                              {(plan.slug === 'standard-package-three-months' ||
                                plan.slug === 'standard-package-yearly') && (
                                <>
                                  <p>${standardMonthlyPackage.price}</p>
                                </>
                              )}
                            </div>
                            <div className="plan-line-through">
                              {(plan.slug ===
                                'professional-package-three-months' ||
                                plan.slug ===
                                  'professional-package-yearly') && (
                                <>
                                  <p>${professionalMonthlyPackage.price}</p>
                                </>
                              )}
                            </div>
                            <span className="plans-bottom-border">
                              /
                              {planDuration === 'monthly' ||
                              planDuration === 'three-months' ||
                              planDuration === 'yearly'
                                ? 'month'
                                : 'year'}
                              /business
                            </span>
                          </div>
                          <div className="coupon-active-block">
                            {plan.slug === 'basic-package-three-months'
                              ? `Pay for 3 months & get $${renderSavePrice(
                                  basicMonthlyPackage.price,
                                  plan.discount,
                                  threeMonths,
                                  plan.duration
                                )} off`
                              : plan.slug === 'standard-package-three-months'
                              ? `Pay for 3 months & get $${renderSavePrice(
                                  standardMonthlyPackage.price,
                                  plan.discount,
                                  threeMonths,
                                  plan.duration
                                )} off`
                              : plan.slug ===
                                'professional-package-three-months'
                              ? `Pay for 3 months & get $${renderSavePrice(
                                  professionalMonthlyPackage.price,
                                  plan.discount,
                                  threeMonths,
                                  plan.duration
                                )} off`
                              : ''}
                          </div>
                          <div className="upgrade float-left w-100 text-left">
                            {showTrialButton && (
                              <button
                                className="action-buttons--submit next-btn"
                                disabled={
                                  (plan && plan.slug === 'free-packagse') ||
                                  dataFromLS.is_owner === false
                                }
                                onClick={() =>
                                  this.selectPlan('trial', index + 1)
                                }
                              >
                                Trial
                              </button>
                            )}
                            {dataFromLS.is_owner === true && (
                              <button
                                // className="action-buttons next-btn"
                                type="button"
                                className={`${
                                  currentPackage === plan.id
                                    ? 'greenbtn current-plan-block'
                                    : 'redbtn'
                                }`}
                                disabled={
                                  (plan && plan.slug === 'free-package') ||
                                  dataFromLS.is_owner === false ||
                                  ((plan.slug ===
                                    'basic-package-three-months' ||
                                    plan.slug ===
                                      'standard-package-three-months' ||
                                    plan.slug ===
                                      'professional-package-three-months') &&
                                    active_three_months)
                                }
                                onClick={() => {
                                  if (currentPackage !== plan.id)
                                    this.setState({
                                      slug: plan.slug,
                                      packageId: plan.id,
                                      showPaymentReview: true,
                                    });
                                }}
                              >
                                {currentPackage !== plan.id ? (
                                  <div className="plan-save-set">
                                    {plan.slug === 'basic-package-three-months'
                                      ? `save $${renderSavePrice(
                                          basicMonthlyPackage.price,
                                          plan.discount,
                                          threeMonths,
                                          plan.duration
                                        )}`
                                      : plan.slug ===
                                        'standard-package-three-months'
                                      ? `save $${renderSavePrice(
                                          standardMonthlyPackage.price,
                                          plan.discount,
                                          threeMonths,
                                          plan.duration
                                        )}`
                                      : plan.slug ===
                                        'professional-package-three-months'
                                      ? `save $${renderSavePrice(
                                          professionalMonthlyPackage.price,
                                          plan.discount,
                                          threeMonths,
                                          plan.duration
                                        )}`
                                      : plan.slug === 'basic-package-yearly'
                                      ? `save $${renderSavePrice(
                                          basicMonthlyPackage.price,
                                          plan.discount,
                                          twelveMonths,
                                          plan.duration
                                        )}`
                                      : plan.slug === 'standard-package-yearly'
                                      ? `save $${renderSavePrice(
                                          standardMonthlyPackage.price,
                                          plan.discount,
                                          twelveMonths,
                                          plan.duration
                                        )}`
                                      : plan.slug ===
                                        'professional-package-yearly'
                                      ? `save $${renderSavePrice(
                                          professionalMonthlyPackage.price,
                                          plan.discount,
                                          twelveMonths,
                                          plan.duration
                                        )}`
                                      : ''}
                                  </div>
                                ) : (
                                  ''
                                )}
                                <div className="button_plan_desc text-left">
                                  <div
                                    className={`subscribe ${
                                      currentPackage === plan.id
                                        ? 'current-plan'
                                        : ''
                                    }`}
                                  >
                                    {currentPackage === plan.id ? (
                                      'Current Plan'
                                    ) : planDuration === 'three-months' ? (
                                      <p>Subscribe for 3 months</p>
                                    ) : planDuration === 'monthly' ? (
                                      'Subscribe monthly'
                                    ) : (
                                      'Subscribe yearly'
                                    )}
                                  </div>
                                  <span className="info_plan">
                                    {currentPackage === plan.id
                                      ? `Auto Renew on  ${moment(
                                          packageExpiry
                                        ).format('MMMM D, YYYY')}`
                                      : 'Click here to activate plan'}
                                  </span>
                                </div>
                              </button>
                            )}
                          </div>
                          <div className="coupon-active-amount">
                            {plan.slug === 'basic-package-three-months'
                              ? `Total $${plan.price} for 3 months`
                              : plan.slug === 'standard-package-three-months'
                              ? `Total $${plan.price} for 3 months`
                              : plan.slug ===
                                'professional-package-three-months'
                              ? `Total $${plan.price}  for 3 months`
                              : plan.slug === 'basic-package-yearly'
                              ? `Total $${plan.price}  for 1 Year`
                              : plan.slug === 'standard-package-yearly'
                              ? `Total $${plan.price}  for 1 Year`
                              : plan.slug === 'professional-package-yearly'
                              ? `Total $${plan.price}  for 1 Year`
                              : ''}
                          </div>
                          <div className="plans-desc">
                            {plan.slug === 'basic-package-monthly' ||
                            plan.slug === 'basic-package-three-months' ||
                            plan.slug === 'basic-package-yearly'
                              ? 'Basic plan includes:'
                              : plan.slug === 'standard-package-monthly' ||
                                plan.slug === 'standard-package-three-months' ||
                                plan.slug === 'standard-package-yearly'
                              ? 'Standard plan includes:'
                              : plan.slug === 'professional-package-monthly' ||
                                plan.slug ===
                                  'professional-package-three-months' ||
                                plan.slug === 'professional-package-yearly'
                              ? 'Professional plan includes:'
                              : ''}
                          </div>
                          <ul className="float-left w-100 plan-desc-list">
                            <li className="float-left w-100 text-center">
                              {plan.invoices_limit} Invoices/Month
                            </li>
                            <li className="float-left w-100 text-center">
                              {plan.bills_limit} Bills/Month
                            </li>
                            <li className="float-left w-100 text-center">
                              {plan.items_limit === null
                                ? 'Unlimited'
                                : plan.items_limit}{' '}
                              Items
                            </li>
                            <li className="float-left w-100 text-center">
                              {plan.contacts_limit === null
                                ? 'Unlimited'
                                : plan.contacts_limit}{' '}
                              Contacts
                            </li>
                            <li className="float-left w-100 text-center">
                              {plan.warehouses_limit} Warehouses{' '}
                            </li>
                            <li className="float-left w-100 text-center">
                              {plan.organization_users_limit} Users
                            </li>
                            <li className="float-left w-100 text-center">
                              {index === 0 ? (
                                <div>
                                  <p>{'Chat & Phone Support'}</p>
                                  <span> (Monday - Friday 9AM - 7PM)</span>
                                </div>
                              ) : (
                                <div>
                                  <p>{'Chat & Phone Support'}</p>
                                  <span> (Monday - Friday 9AM - 7PM)</span>
                                </div>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                );
            })}
        </div>
        {planDuration === 'three-months' && (
          <p to="" className="link-monthly" onClick={this.handleLinkMontlhy}>
            Thanks, I'd like to pay monthly.
          </p>
        )}
      </div>
    );
  }

  renderPaymentReview() {
    const { loading, allPackages, accept, planDuration, packageId } =
      this.state;
    if (loading === true) return <Loader />;
    const planDetails =
      allPackages && allPackages.find((plan) => plan.id === packageId);
    const currentOrganization = JSON.parse(
      localStorage.getItem('currentOrganization')
    );
    return (
      <div className="package_plan_details">
        <div>
          <p>Plan Details</p>
          <div className={classNames('cross_icon')}>
            <MdClose
              onClick={() => {
                this.setState({
                  showPaymentReview: false,
                  accept: false,
                });
              }}
              style={{ cursor: 'pointer' }}
              className={classNames('material-icon  mt-11 animate-close-icon')}
            />
          </div>
        </div>
        <div className={classNames('purcahser_details')}>
          <div className={classNames('image')}>
            <img
              src={
                currentOrganization.logo
                  ? imagePathS3(currentOrganization.logo)
                  : orgImage
              }
              alt="organization-logo"
            />
          </div>
          <div className={classNames('desc')}>
            <div className={classNames('name')}>{currentOrganization.name}</div>
            <div className={classNames('email')}>
              {currentOrganization.primary_contact_email
                ? currentOrganization.primary_contact_email
                : ''}
            </div>
          </div>
        </div>
        <div className={classNames('package_plan_pricing')}>
          <div className={classNames('left_sec')}>
            <div className={classNames('pricing_total float-left w-100')}>
              <div
                className={classNames(
                  'name font_weight_medium float-left w-100'
                )}
              >
                Plan Name
              </div>
              <div className={classNames('price float-left w-100 fs_16')}>
                {planDetails.name}
              </div>
            </div>
            <div className={classNames('pricing_total float-left w-100')}>
              <div
                className={classNames(
                  'duration font_weight_medium float-left w-100'
                )}
              >
                Total Amount
              </div>
              <div className={classNames('price float-left w-100 fs_16')}>
                ${planDetails.price}
              </div>
            </div>
          </div>
          <div className={classNames('right_sec')}>
            <div className={classNames('pricing_total float-left')}>
              <div
                className={classNames(
                  'Limit font_weight_medium float-left w-100'
                )}
              >
                Invoices Limit
              </div>
              <div className={classNames('price float-left w-100 fs_16')}>
                {planDetails.invoices_limit}
              </div>
            </div>
            <div className={classNames('pricing_total float-left')}>
              <div
                className={classNames(
                  'Limit font_weight_medium float-left w-100'
                )}
              >
                Bills Limit
              </div>
              <div className={classNames('price float-left w-100 fs_16')}>
                {planDetails.bills_limit}
              </div>
            </div>
            <div className={classNames('pricing_total float-left')}>
              <div
                className={classNames(
                  'Limit font_weight_medium float-left w-100'
                )}
              >
                Items Limit
              </div>
              <div className={classNames('price float-left w-100 fs_16')}>
                {' '}
                {planDetails.items_limit === null
                  ? 'Unlimited'
                  : planDetails.items_limit}{' '}
              </div>
            </div>
            <div className={classNames('pricing_total float-left')}>
              <div
                className={classNames(
                  'Limit font_weight_medium float-left w-100'
                )}
              >
                Contacts Limit
              </div>
              <div className={classNames('price float-left w-100 fs_16')}>
                {' '}
                {planDetails.contacts_limit === null
                  ? 'Unlimited'
                  : planDetails.contacts_limit}{' '}
              </div>
            </div>
            <div className={classNames('pricing_total float-left')}>
              <div
                className={classNames(
                  'Limit font_weight_medium float-left w-100'
                )}
              >
                Warehouses Limit
              </div>
              <div className={classNames('price float-left w-100 fs_16')}>
                {planDetails.warehouses_limit}
              </div>
            </div>
            <div className="pricing_total float-left">
              <div className="Limit font_weight_medium float-left w-100">
                Users Limit
              </div>
              <div className="price float-left w-100 fs_16">
                {' '}
                {planDetails.organization_users_limit}{' '}
              </div>
            </div>
          </div>
        </div>
        <div className="note">
          <div className="about_sub">About Your Subscription</div>
          <p className="mb_10 float-left w-100">
            Your subscriptions will be automatically renewed on regular basis
            from your given payment method. You will be notified of such renewal
            the receipt. You have the option to upgrade, downgrade, or cancel
            the payment method at any time prior to such renewal. If you cancel
            your subscription, the refund and cancellation of access will be
            determined by the company following the Terms of Service. Prices are
            in US Dollars and can be changed at any time. Taxes and any other
            restrictions are subject to apply.
          </p>
          <input
            type="checkbox"
            name="terms_and_condition"
            style={{ cursor: 'pointer' }}
            id="View"
            onChange={() => this.setState({ accept: !accept })}
            checked={accept}
          />
          <label
            onClick={() => this.setState({ accept: !accept })}
            htmlFor="terms_and_condition"
            style={{ marginLeft: '5px', cursor: 'pointer' }}
          >
            {' '}
            I have read and accepted the Terms & Conditions
            {/*<Link className='bold' to=''>Terms & conditions</Link>*/}
          </label>
        </div>
        <div className="float-left w-100">
          <div className="float-left">
            <p className="float-left w-100 ft_16">Email</p>
            <span className="float-left w-100 ft_14">
              support@seebiz-inventory.com
            </span>
            <span className="float-left w-100 ft_14">
              For payments and billing Questions
            </span>
          </div>
          <span className="button-package-review">
            <div className="note-payment font-small text_dark">
              * Payment will be activated on {planDuration} basis
            </div>
            <div className="package-plans-actions">
              <button
                type="button"
                className="action-buttons--cancel cancel-btn"
                onClick={() =>
                  this.setState({
                    showPaymentReview: false,
                    accept: false,
                  })
                }
              >
                Cancel
              </button>
              <button
                disabled={!accept}
                className="action-buttons next-btn ml-15"
                style={{ marginTop: '0', marginBottom: '0' }}
                // Modal stripe or paypal
                // onClick={() =>
                //     this.state.accept
                //         ? this.handlePaymentModal()
                //         : false
                // }
                // only stripe
                onClick={() => (accept ? this.stripeSubscribe() : false)}
              >
                Confirm
              </button>
            </div>
          </span>
        </div>
      </div>
    );
  }
  renderPage() {
    const { showPaymentReview } = this.state;
    if (showPaymentReview === true) return this.renderPaymentReview();
    else return this.renderPackages();
  }

  render() {
    const { loading, showpaymentModal } = this.state;
    if (loading) return <Loader />;
    return (
      <>
        {showpaymentModal && this.renderPaymentModal()}
        {this.renderPage()}
      </>
    );
  }
}
