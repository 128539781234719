import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import getStatusColor from '../../Helpers/GetStatusColor'

export const ListTableColumnJoin = ({
  column1, column2, column3
}) => {
  if(column3 !== undefined && column3.length !== 0) {
    return(
      <Fragment>
        {column1}
        <div className="second-column">{column2}</div>
        <div className="third-column">{ 'Note : ' + column3}</div>
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        {column1}
        <div className="second-column">{column2}</div>
      </Fragment>
    )
  }
}

export const StatusColumn = ({ status }) => (
  <span className={`${getStatusColor(status)} status--transparent`}>{status}</span>
)

export const LinkColumn = ({
  to = '',
  label = ''
}) => <Link className="link--underline" to={to}>{label}</Link>

export const LinkStatusColumn = ({
  to = '',
  label = '',
  status = ''
}) => (
  <ListTableColumnJoin column1={<LinkColumn to={to} label={label} />} column2={<StatusColumn status={status} />}  />
)

export const HorizontalColumnJoin = ({
  Icons,
  labelClass = '',
  label = '',
  tooltips,
}) => (
  <span className="grid-1-1-fr-fr grid--center grid--column-gap-1">
    <span>
      <span className={labelClass}>{label}</span>
    </span>
    <span>
    {
      Icons.map((Icon, key) => {
        return (
            <span title={tooltips[key]} key={key}>
              <Icon/>
            </span>
          )
      })
    }
    </span>

  </span>
)

export const HorizontalIconColumnJoin = ({
  Icons,
  showStatus,
  labelClass = '',
  label = '',
  tooltips,
}) => (
  <span className="grid-1-1-fr-fr grid--center grid--column-gap-1">
    <span>
      <span className={labelClass}>{label}</span>
    </span>
    <div className="icons_sales_order">
    {
      Icons.map((Icon, key) => {
        return (
          showStatus[key] ?
            <div title={tooltips[key]} key={key}>
              <Icon/>
            </div> :
            <div key={key}></div>
          )
      })
    }
    </div>
  </span>
)

export const HorizontalColumnJoinICON = ({
   Icons,
   tooltips,
 }) => (
  <span className="grid--center grid--column-gap-1">
    <span>
    {
      Icons.map((Icon, key) => {
        return (
          <span title={tooltips[key]} key={key}>
              <Icon/>
            </span>
        )
      })
    }
    </span>

  </span>
)

export default ListTableColumnJoin