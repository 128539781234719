import { isEqual } from 'lodash';
export const validateContactForm = (data = {}, defaultValues = {}) => {
  let checkout = true;
  const checkEquality = Boolean(isEqual(data, defaultValues));
  const {
    // tab,
    salutation,
    // contactTypeId,
    department,
    displayName,
    designation,
    license,
    invoiceTermId = null,
    companyName,
    firstName,
    lastName,
    email,
    mobile,
    mobile_secondary,
    website,
    workPhone,
    workPhone_secondary,
    contactImage,
    billingAddress = {},
    shippingAddress = {},
    facebook,
    twitter,
    skype,
    instagram,
    notes,
    sameAsBillingAdress = false,
    customerTypeChange = false,
  } = data;
  const emailCheck = email?.toString();

  const {
    attention = '',
    city = '',
    countryId_updated = false,
    fax = '',
    phone = '',
    state = '',
    street = '',
    zipCode = '',
  } = billingAddress;

  if (
    // tab === 1 &&
    (salutation ||
      customerTypeChange ||
      companyName ||
      firstName ||
      lastName ||
      department ||
      displayName ||
      designation ||
      license ||
      invoiceTermId ||
      emailCheck ||
      mobile ||
      mobile_secondary ||
      website ||
      workPhone ||
      workPhone_secondary ||
      contactImage) &&
    !checkEquality
  ) {
    checkout = false;
  } else if (
    // tab === 2 &&
    (attention ||
      sameAsBillingAdress ||
      city ||
      fax ||
      countryId_updated ||
      phone ||
      state ||
      street ||
      zipCode ||
      shippingAddress.attention ||
      shippingAddress.city ||
      shippingAddress.countryId_updated ||
      shippingAddress.phone ||
      shippingAddress.state ||
      shippingAddress.street ||
      shippingAddress.fax ||
      shippingAddress.zipCode) &&
    !checkEquality
  ) {
    checkout = false;
  } else if (
    // tab === 3 &&
    (facebook || twitter || skype || instagram || notes) &&
    !checkEquality
  ) {
    checkout = false;
  }
  return { checkout };
};
