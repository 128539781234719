import React, { Component } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { MdCheck } from '../../../Common/Icons';
import { restRequest } from '../../../Helpers/RequestHelper';
import {
  required,
  ValidateMax50,
  ValidateMax20,
  // ValidateMax25,
  // ValidatePhoneNumber,
  // ValidateMax6,
  ValidateMax100,
  ValidateMax200,
} from '../../../Helpers/Validator';
import { confirmationAlert } from '../../../Helpers';
import { getError } from '../../Form/FormHelper';
import COUNTRY_STATES from '../../../Constants/CountryState';
import {
  handleStreet,
  handleCity,
  handlePhone,
  handleState,
} from '../../../Helpers/addressFieldsHandler';

import Field from '../Field/Field';
import Loader from '../../Loader/Loader';
import FormAction from '../FormAction';
import ErrorFocus from '../ErrorFocus';
// import { StateShortName, StateFullName } from '../../../Helpers/StateNameConvertion'

import './AddressForm.css';
import { getCurrentOrganizaton } from '../../../Helpers/AuthHelper';
import { validateAdressForm } from '../../../Helpers/FormValidator';

class AddressForm extends Component {
  type = this.props.type || 'add'; // add, edit

  state = {
    address: {
      id: this.props.addressId || null,
      attention: '',
      street: '',
      countryId: null,
      city: '',
      state: '',
      zipCode: '',
      fax: '',
      phone: '',
      type: '',
    },
    countryList: [],
    countryStates: [],
    loading: true,
    alertFlag: false,
    updateoverAll: false,
  };

  myRef = React.createRef();

  componentDidMount() {
    localStorage.setItem('alert', false);
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.addressType !== prevProps.addressType) {
      this.fetchData();
    }
    if (prevState.alertFlag !== this.state.alertFlag) {
      const { checkout } = validateAdressForm(
        this.myRef?.current?.state?.values
      );
      if (!checkout) localStorage.setItem('alert', true);
      else localStorage.setItem('alert', false);
    }
  }

  handlealertFlag = () => {
    this.setState({
      alertFlag: !this.state.alertFlag,
    });
  };

  fetchData = async () => {
    this.setState({ loading: true });

    switch (this.type) {
      case 'edit':
        await Promise.all([this.fetchAddress(), this.fetchCountryList()]);
        this.populateCountryStates(230);
        break;
      case 'add':
      default:
        await this.fetchCountryList();
    }
    this.populateCountryStates(230);
    this.setState({ loading: false });
  };

  fetchAddress() {
    return restRequest(
      'get',
      `contacts/${this.props.contactId}/addresses/${this.state.address.id}/edit`
    ).then((res) => {
      let address = res.contact_address;
      this.setState({
        address: {
          id: address.id,
          attention: address.attention,
          street: address.street,
          countryId: address.country_id,
          city: address.city,
          //state: StateShortName(address.state),
          state: address.state,
          zipCode: address.zip_code,
          fax: address.fax,
          phone: address.phone,
          type: address.address_type,
        },
      });
    });
  }

  fetchCountryList() {
    return restRequest('get', 'country').then((countryRes) => {
      this.setState((state) => ({
        countryList: countryRes,
      }));
    });
  }

  submitRequestType() {
    if (this.type === 'edit') {
      return 'put';
    }
    return 'post';
  }

  submitRequestUrl() {
    switch (this.type) {
      case 'edit':
        return `contacts/${this.props.contactId}/addresses/${this.state.address.id}`;
      case 'add':
      default:
        return `contacts/${this.props.contactId}/addresses`;
    }
  }

  successMessage() {
    switch (this.type) {
      case 'edit':
        this.props.handleToast('Address has been updated', 'success');
        break;
      case 'add':
      default:
        this.props.handleToast('Address added successfully', 'success');
    }
  }

  handleSubmit(values) {
    const type =
      this.type === 'add'
        ? this.props.addressType
        : this.state.address?.type || 'additional';
    restRequest(this.submitRequestType(), this.submitRequestUrl(), {
      attention: values.attention,
      street: values.street,
      city: values.city,
      //state: StateFullName(values.state),
      state: values.state,
      zip_code: values.zipCode,
      country_id: values.countryId,
      fax: values.fax,
      phone: values.phone,
      address_type: type,
      updateoverAll: this.state.updateoverAll,
    })
      .then((res) => {
        this.props.onSubmit(res.data);
        this.successMessage();
      })
      .catch((error) => {
        this.props.handleToast(error, 'error');
      });
  }

  populateCountryStates = (countryId) => {
    if (countryId) {
      const countryFromList = this.state.countryList.find(
        (country) => country.id === countryId
      );
      if (countryFromList) {
        const country = COUNTRY_STATES.find(
          (item) => item.country === countryFromList.country_name
        );
        if (country) {
          const states = country.states.map((state) => ({
            value: state,
            label: state,
          }));
          this.setState({ countryStates: states });
          return;
        }
      }
    }
    this.setState({ countryStates: [] });
  };

  handleCancel = () => {
    const { onCancel } = this.props;
    let response;
    const { checkout } = validateAdressForm(this.myRef?.current?.state?.values);
    if (!checkout) response = confirmationAlert();
    if (response || checkout) onCancel();
  };

  renderAddressForm() {
    return (
      <Formik
        ref={this.myRef}
        initialValues={{
          ...this.state.address,
          billingAddress: this.props.billingAddress,
          // countryId:230,
          countryId:
            this.type === 'add'
              ? JSON.parse(getCurrentOrganizaton()).country_id
              : this.state.address.countryId,
        }}
        validationSchema={() =>
          Yup.object().shape({
            attention: required(ValidateMax100),
            street: required(ValidateMax200),
            city: ValidateMax100,
            state: ValidateMax100,
            zipCode: ValidateMax50,
            phone: ValidateMax20,
            fax: ValidateMax20,
          })
        }
        onSubmit={(values) => {
          this.handleSubmit(values);
        }}
        render={({
          values,
          errors,
          isSubmitting,
          touched,
          handleBlur,
          handleSubmit,
          handleChange,
          setFieldValue,
          setValues,
        }) => {
          const handleCopyBillingAddress = (copyBillingAddress) => {
            let billingAddress = this.props.billingAddress;
            if (copyBillingAddress && !!this.props.billingAddress) {
              setValues({
                ...values,
                attention: billingAddress.attention,
                street: billingAddress.street,
                countryId: billingAddress.country_id,
                city: billingAddress.city,
                state: billingAddress.state,
                zipCode: billingAddress.zip_code,
                fax: billingAddress.fax,
                phone: billingAddress.phone,
              });
            }
            if (!copyBillingAddress) {
              setValues({
                ...values,
                attention: '',
                street: '',
                countryId: null,
                city: '',
                state: '',
                zipCode: '',
                fax: '',
                phone: '',
              });
            }
            this.populateCountryStates(billingAddress.country_id);
          };
          return (
            <form className="" onSubmit={handleSubmit}>
              {this.props.addressType === 'shipping' && (
                <label className="checkbox--option-label margin-vertical-sm">
                  <input
                    type="checkbox"
                    onChange={(event) =>
                      handleCopyBillingAddress(event.target.checked)
                    }
                  />
                  <span>
                    <MdCheck />
                  </span>
                  Same as Billing Address
                </label>
              )}
              <div className="form-fields-container d-flex flex-wrap">
                <Field
                  size=""
                  name="Attention"
                  className="flex-33"
                  value={values.attention}
                  id="attention"
                  placeholder="Name, Designation"
                  handleChange={(e) => {
                    handleChange(e);
                    this.handlealertFlag();
                  }}
                  required
                  onBlur={handleBlur}
                  error={touched.attention && errors.attention}
                />

                <Field
                  size=""
                  name="Street Address"
                  className="flex-33"
                  value={values.street}
                  id="street"
                  handleChange={(value) => {
                    setFieldValue('street', handleStreet(value, 200));
                    this.handlealertFlag();
                  }}
                  required
                  onBlur={handleBlur}
                  error={touched.street && errors.street}
                />

                <Field
                  className="flex-33"
                  name="City"
                  value={values.city}
                  size=""
                  id="city"
                  handleChange={(value) => {
                    setFieldValue('city', handleCity(value, 100));
                    this.handlealertFlag();
                  }}
                  onBlur={handleBlur}
                  error={getError(errors, touched, 'city')}
                />

                <Field
                  className="flex-33"
                  name="State"
                  value={values.state}
                  id="state"
                  size=""
                  handleChange={(value) => {
                    setFieldValue('state', handleState(value, 20));
                    this.handlealertFlag();
                  }}
                  onBlur={handleBlur}
                  error={getError(errors, touched, 'state')}
                />

                <Field
                  size=""
                  name="Zip Code"
                  value={values.zipCode}
                  className="flex-33"
                  id="zipCode"
                  handleChange={(handleChange) => {
                    let zip = handleChange.target.value;
                    if (zip.length > 0) {
                      let labelString = zip;
                      if (labelString.length > 15) {
                        labelString = labelString.slice(0, 15);
                      }
                      zip = labelString;
                    }
                    setFieldValue('zipCode', zip);
                    this.handlealertFlag();
                  }}
                  onBlur={handleBlur}
                  error={getError(errors, touched, 'zipCode')}
                />

                <Field
                  size=""
                  type="dropdown"
                  name="Country"
                  required
                  value={values.countryId}
                  className="flex-33"
                  options={this.state.countryList}
                  dropdownLabel="country_name"
                  dropdownValue="id"
                  handleChange={(option) => {
                    setFieldValue('countryId', option ? option.value : null);
                    setFieldValue('countryupdate', true);
                    this.handlealertFlag();
                  }}
                  clearable={false}
                />

                <Field
                  size=""
                  name="Phone"
                  value={values.phone}
                  className="flex-33"
                  id="phone"
                  handleChange={(value) => {
                    setFieldValue('phone', handlePhone(value, 20));
                    this.handlealertFlag();
                  }}
                  onBlur={handleBlur}
                  error={getError(errors, touched, 'phone')}
                />

                <Field
                  size=""
                  name="Fax"
                  value={values.fax}
                  className="flex-33"
                  id="fax"
                  handleChange={(value) => {
                    setFieldValue('fax', handlePhone(value, 20));
                    this.handlealertFlag();
                  }}
                  onBlur={handleBlur}
                  error={getError(errors, touched, 'fax')}
                />
                {/* <br />
                {this.props.addressType != 'additional' && (
                  <label className="checkbox--option-label">
                    <input
                      onChange={(event) =>
                        this.setState({ updateoverAll: event.target.checked })
                      }
                      checked={this.state.updateoverAll}
                      type="checkbox"
                      id="Click_checkbox_SameAsBilling"
                    />
                    <span>
                      <MdCheck />
                    </span>
                    If you want to save this information for previous records
                    then tick the checkbox.
                  </label>
                )} */}
              </div>
              <FormAction
                className="clearfix"
                disabled={isSubmitting}
                onCancel={this.handleCancel}
              />
              <ErrorFocus />
            </form>
          );
        }}
      />
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return this.renderAddressForm();
  }
}

export default AddressForm;
