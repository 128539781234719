import React from 'react';
import {
  // CellIcon,
  UserIcon,
  LocationIcon,
  // FaxIcon,
  PhoneIcon,
  // CurrencyIcon,
  CompanyOffice,
} from '../../Assets/Contact/OverviewContact';
import { getAddress } from '../../Pages/Organization/ChooseOrganization';
// import { StateShortName } from '../../Helpers/StateNameConvertion'
import './../../Assets/CSS/Details.css';

export default ({
  address: {
    type,
    attention,
    company,
    street,
    city,
    state,
    zip,
    country,
    phone,
    // fax,
  },
}) => {
  if (!type)
    return <div className="section-details--info margin-bottom-md"></div>;

  return (
    <div className="more_details float-left w-100">
      <span style={{ fontWeight: '500' }}>{`${
        type.charAt(0).toUpperCase() + type.slice(1)
      } Address`}</span>
      <div className="left_sec">
        <ul className="float-left w-100">
          {attention && (
            <li className="details_row">
              <UserIcon customWidth="20" customHeight="15" />{' '}
              <span className="details_data">{attention}</span>
            </li>
          )}
          {company && (
            <li className="details_row">
              <CompanyOffice customWidth="20" customHeight="15" />{' '}
              <span className="details_data">{company}</span>
            </li>
          )}
          <li className="details_row">
            <LocationIcon customWidth="20" customHeight="15" />
            <span className="details_data">
              {getAddress(street) && <span>{getAddress(street)}</span>}
              <span>
                {getAddress(city) && getAddress(city) + ', '}
                {getAddress(state) && getAddress(state) + ', '}
                {getAddress(zip) && getAddress(zip) + ', '}
                {getAddress(country) ? getAddress(country) : 'United States'}
              </span>
            </span>
          </li>

          {phone && (
            <li className="details_row">
              <PhoneIcon customWidth="20" customHeight="15" />{' '}
              <span className="details_data"> {phone}</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};
