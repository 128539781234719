import React from 'react';

export default () => (
  <svg
    className="packages_icon"
    xmlns="http://www.w3.org/2000/svg"
    width="14.873"
    height="10.411"
    viewBox="0 0 14.873 10.411"
  >
    <path
      d="M9.892-4.545a1.126,1.126,0,0,1-.962-.544L7.436-7.566,5.944-5.089a1.13,1.13,0,0,1-.964.546,1.069,1.069,0,0,1-.309-.044L1.487-5.5v4.136a.741.741,0,0,0,.562.72L7.074.616a1.511,1.511,0,0,0,.72,0L12.823-.641a.745.745,0,0,0,.562-.72V-5.5L10.2-4.589A1.068,1.068,0,0,1,9.892-4.545Zm4.94-2.607-1.2-2.389a.38.38,0,0,0-.388-.207l-5.812.741L9.567-5.472A.382.382,0,0,0,10-5.3l4.6-1.313A.383.383,0,0,0,14.833-7.152ZM1.236-9.541.04-7.152a.379.379,0,0,0,.235.534L4.873-5.3a.382.382,0,0,0,.43-.17L7.436-9.006,1.622-9.748A.38.38,0,0,0,1.236-9.541Z"
      transform="translate(0.001 9.751)"
    />
  </svg>
);
