import React, { useEffect } from 'react';
import { PurchaseOrderForm } from '../../Components/Form';
import { FormLayout } from '../../Components';
import { NewPurchaseOrderIcon } from '../../Assets/Navigation/NavigationIcons';

const EditPurchaseOrder = (props) => {
  useEffect(() => {
    document.title = 'Edit Purcahse Orders';
  }, []);

  return (
    <FormLayout
      title="Edit Purchase Order"
      Icon={NewPurchaseOrderIcon}
      // subtitle="Edit"
      iconstyle="general_module_icon"
      closeUrl="/purchaseorders"
      layoutBoxClass="uiux-form-redesign"
    >
      <PurchaseOrderForm
        type="edit"
        // title="Edit Purchase Order"
        id={
          props.idFromHeaderWrapper
            ? props.idFromHeaderWrapper
            : props.match.params.id
        }
        handleToast={props.handleToast}
      />
    </FormLayout>
  );
};

export default EditPurchaseOrder;
