import React, { Fragment, useEffect, useState } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
//import classNames from 'classnames';
import { restRequest } from '../../Helpers/RequestHelper';
import {
  ValidateName,
  ValidateRequired,
  required,
  ValidateMax10Billion,
} from '../../Helpers/Validator';

import Field from '../../Components/Form/Field/Field';
import Form from '../../Components/Form/Form';
import FormAction from '../../Components/Form/FormAction';
import CustomModal from '../../Components/CustomModal/CustomModal';
import ContactIcon from '../../Assets/General/ContactIconListing';

import NewAccount from './NewAccount';
import { checkError } from '../../Helpers/AuthHelper';
import { confirmationAlert } from '../../Helpers';
import { validateCreateAccountForm } from '../../Helpers/FormValidator';

const formEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    accGrpId: ValidateRequired.nullable(),
    accTitle: required(ValidateName).nullable(),
    openingBalance: required(ValidateMax10Billion),
    currentBalance: required(ValidateMax10Billion),
  }),
  mapPropsToValues: ({ account }) => ({
    ...account,
  }),
  mapPropsToStatus: (props) => ({
    showAccountGroupCreateModal: false,
    accountType: props.accountType,
    accountGroups: props.accountGroups,
    loadingAccountGroups: false,
  }),
  handleSubmit: (values, bag) => {
    handleFormSubmit(values, bag);
  },
});

const handleFormSubmit = (
  values,
  {
    setSubmitting,
    props: {
      // customProps
      inModal,
      handleToast,
      onSubmit,
      history,
    },
  }
) => {
  let accountCreatable = {
    account_title: values.accTitle,
    opening_balance: values.openingBalance,
    current_balance: values.currentBalance,
    account_group_id: values.accGrpId,
  };
  restRequest('post', 'accounts', accountCreatable)
    .then((res) => {
      if (inModal) {
        handleToast('Account created successfully', 'success');
        onSubmit(res.data);
        if (history?.location?.pathname.includes('/accounting/account')) {
          history.push('/accounting/account');
        }
      }
      if (!inModal) history.push('/settings');
      handleToast('Account created successfully', 'success');
      setSubmitting(false);
    })
    .catch((error) => {
      checkError(error, handleToast);
      setSubmitting(false);
    });
};

const AccountFormEnhancer = ({
  values,
  status,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
  setStatus,
  isSubmitting,
  errors,
  touched,
  setFieldTouched,

  // custom props
  accountType = 'all', // sales, purchase, all
  handleToast,
  inModal,
  onClose,
  history,
  handlePrompt,
  // ...props
}) => {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    handleAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleAlert = () => {
    let { checkout } = validateCreateAccountForm(values);
    handlePrompt(!checkout);
    return checkout;
  };

  const handleCancel = () => {
    let status = handleAlert();
    let response = true;
    if (!status) {
      response = confirmationAlert();
    }
    if (status || response) {
      if (history?.location?.pathname.includes('/accounting/account')) {
        history.push('/accounting/account');
      }
      inModal ? onClose() : history.goBack();
    }
  };

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };
    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  const handleAccountGroupChange = (option) => {
    if (option) {
      if (option.value === 'createNew') {
        setStatus({
          ...status,
          showAccountGroupCreateModal: true,
        });
      } else {
        setFieldValue('accGrpId', option.value);
      }
    } else {
      setFieldValue('accGrpId', null);
    }
  };

  const handleAccountGroupSubmit = (id) => {
    setStatus({
      ...status,
      loadingAccountGroups: true,
    });
    restRequest('get', `accounts/create?type=${accountType}`, {})
      .then((accountGroupsResponse) => {
        setStatus({
          ...status,
          accountGroups: accountGroupsResponse,
          loadingAccountGroups: false,
          showAccountGroupCreateModal: false,
        });
        setFieldValue('accGrpId', id);
      })
      .catch((error) => {
        checkError(error, handleToast);
        this.setState({ loadingAccountGroups: false });
      });
  };

  const handleShowalert = (status = true) => setShowAlert(status);

  const addAccountGroupModal = () => {
    return (
      <CustomModal
        classNames="warehouse_add_new"
        className="widthClass"
        showModal={status.showAccountGroupCreateModal}
        fill="#000000"
        Icon={ContactIcon}
        clas
        title="Add Group Account"
        renderActions={false}
        onClose={() =>
          setStatus({ ...status, showAccountGroupCreateModal: false })
        }
        showPrompt={showAlert}
      >
        <NewAccount
          inModal
          accountType={status.accountType}
          close={() =>
            setStatus({
              ...status,
              showAccountGroupCreateModal: false,
            })
          }
          onSubmit={(id) => handleAccountGroupSubmit(id)}
          handleToast={handleToast}
          handlePrompt={handleShowalert}
        />
      </CustomModal>
    );
  };

  return (
    <Fragment>
      {addAccountGroupModal()}
      <Form onSubmit={handleSubmit} className="account-form float-left w-100">
        <div className="form-fields-container d-flex flex-wrap  position-relative pb-20">
          <Field
            autoFocus={true}
            className="flex-33"
            name="Account Name"
            value={values.accTitle}
            id="accTitle"
            required
            handleChange={handleChange}
            onBlur={handleBlur}
            error={touched.accTitle && errors.accTitle}
          />
          <Field
            className="flex-33"
            id="accGrpId"
            name="Account Group"
            value={values.accGrpId}
            options={status.accountGroups}
            dropdownValue="id"
            dropdownLabel="name"
            addButton={true}
            handleChange={(option) =>
              handleAccountGroupChange(option, setFieldValue)
            }
            onBlur={() => setFieldTouched('accGrpId', true)}
            type="dropdown"
            required
            disabled={status.loadingAccountGroups}
            error={touched.accGrpId && errors.accGrpId}
          />
          <Field
            className="flex-33"
            type="number"
            name="Opening Balance"
            placeholder="$0"
            value={values.openingBalance}
            id="openingBalance"
            decimalScale={6}
            onValueChange={(value) =>
              setFieldValue('openingBalance', value.floatValue)
            }
            thousandSeparator=","
            required
            onBlur={handleBlur}
            error={touched.openingBalance && errors.openingBalance}
          />
          <Field
            className="flex-33"
            type="number"
            name="Current Balance"
            value={values.currentBalance}
            id="currentBalance"
            placeholder="$0"
            decimalScale={6}
            onValueChange={(value) =>
              setFieldValue('currentBalance', value.floatValue)
            }
            thousandSeparator=","
            required
            onBlur={handleBlur}
            error={touched.currentBalance && errors.currentBalance}
          />
        </div>
        <div
          className="float-left w-100"
          style={{ borderTop: '1px solid var(--border-light)' }}
        >
          <FormAction
            disabled={isSubmitting}
            hasHr={true}
            onCancel={handleCancel}
          />
        </div>
      </Form>
    </Fragment>
  );
};

export default formEnhancer(AccountFormEnhancer);
