import React, { Fragment } from 'react';

// import { formatNumber } from "../../../../Helpers/FormatNumber"

import Table from '../../../../Components/Table/Table';
//import { GridLayout } from '../../../../Components/Layout/Layout';
import ListTableImageColumn from '../../../../Components/ListTable/ListTableImageColumn';
import Quantity from '../../../../Components/Fragments/Quantity/Quantity';

export default ({ purchaseOrder, currency, overallTax, inModal }) => {
  const {
    adjustment,
    purchase_order_item_details: purchaseOrderItemDetails,
    total,
    discount_transaction_level,
    discount_type,
  } = purchaseOrder;
  const tableHeaders = ['Items', 'Account', 'Qty.', 'Price', 'Amount'];
  const tableData = ['itemName', 'account', 'quantity', 'rate', 'amount'];
  let rightAlignColumns = [3, 4, 5];
  let subtotal = 0;
  const items = purchaseOrderItemDetails.map((purchaseOrderMap) => {
    subtotal += purchaseOrderMap.quantity * purchaseOrderMap.rate;
    // console.log('Purchase Order Image', purchaseOrderMap.item.images)
    // console.log('Purchase Order Item Data', purchaseOrderMap.item)

    return {
      itemName: (
        <ListTableImageColumn
          name={purchaseOrderMap.item_name}
          sku={purchaseOrderMap.item.sku}
          id={purchaseOrderMap.item.id}
          showModalUponClick={inModal ? false : true}
          images={purchaseOrderMap.item.images} //check issue here
          value={purchaseOrderMap.extra_description}
          showExtraDisableDescription={!!purchaseOrderMap.extra_description}
        />
      ),
      account: purchaseOrderMap.account.title,
      quantity: (
        <Quantity
          quantity={purchaseOrderMap.quantity}
          unit={purchaseOrderMap.item.unit}
        />
      ),
      tax: parseFloat(purchaseOrderMap.tax_rate).toFixed(2),
      rate: currency.symbol + `${parseFloat(purchaseOrderMap.rate).toFixed(2)}`,
      //tax: formatNumber(purchaseOrderMap.tax_rate),
      //rate: currency.symbol+`${formatNumber(purchaseOrderMap.rate)}`,
      //amount: currency.symbol+`${formatNumber(purchaseOrderMap.quantity * purchaseOrderMap.rate)}`
      amount:
        currency.symbol +
        `${parseFloat(
          purchaseOrderMap.quantity * purchaseOrderMap.rate
        ).toFixed(2)}`,
    };
  });

  let transactionDiscount = 0;
  let DISCOUNT_LABEL = 'Discount';
  let DISCOUNT_AMOUNT = 0;
  if (discount_type !== null) {
    if (discount_type === 'amount') {
      transactionDiscount += discount_transaction_level;
      DISCOUNT_AMOUNT = parseFloat(discount_transaction_level).toFixed(2);
    } else if (discount_type === 'percent') {
      transactionDiscount = (discount_transaction_level * subtotal) / 100;
      DISCOUNT_LABEL = `${DISCOUNT_LABEL} (${discount_transaction_level}%)`;
      DISCOUNT_AMOUNT = parseFloat(transactionDiscount).toFixed(2);
    }
  }
  let ADJUSTMENT_AMPOUNT = parseFloat(adjustment).toFixed(2);
  function renderOverallTax() {
    return overallTax.map((tax) => (
      <div className="single-summary-rec mb-6" key={tax.id}>
        <span className="">{`${tax.name} (${tax.rate}%)`} </span>
        <span className="semi-bold">{`${currency.symbol}${parseFloat(
          tax.total
        ).toFixed(2)}`}</span>
      </div>
      // <div key={tax.id}>
      //   <span>{`${tax.name} (${tax.rate}%)`}</span>
      //   {/*<span>{`${currency.symbol}${formatNumber(tax.total)}`}</span>*/}
      //   <span>{`${currency.symbol}${parseFloat(tax.total).toFixed(2)}`}</span>
      // </div>
    ));
  }

  return (
    <Fragment>
      <div className="package-item-info package_details_table po-details-main">
        <Table
          list={items}
          tableHeaders={tableHeaders}
          tableData={tableData}
          rightAlignColumns={rightAlignColumns}
          className="details-table"
        />
        <div className="custom-padding">
          <div className="cust-table-summary">
            <div className="w-70 left-col">
              {/* <span>Applied Invoices:&nbsp;0</span> */}
            </div>
            <div className="w-30 right-col">
              <div className="single-summary-rec mb-6">
                <span className="">Sub Total </span>
                <span className="semi-bold">
                  {' '}
                  {currency.symbol}
                  {parseFloat(subtotal).toFixed(2)}{' '}
                </span>
              </div>
              {transactionDiscount !== 0 && (
                <div className="single-summary-rec mb-6">
                  <span>{DISCOUNT_LABEL}</span>
                  {parseFloat(DISCOUNT_AMOUNT) > 0 ? (
                    <span className="semi-bold" style={{ color: 'red' }}>
                      (-) {DISCOUNT_AMOUNT}
                    </span>
                  ) : (
                    <span className="semi-bold">
                      {currency.symbol}
                      {DISCOUNT_AMOUNT}
                    </span>
                  )}
                </div>
              )}
              {renderOverallTax()}
              <div className="single-summary-rec mb-6">
                <span className="">Adjustment </span>
                {parseFloat(adjustment) >= 0 ? (
                  <span className="semi-bold">
                    {currency.symbol}
                    {ADJUSTMENT_AMPOUNT}
                  </span>
                ) : (
                  <span className="semi-bold" style={{ color: 'red' }}>
                    (-) {ADJUSTMENT_AMPOUNT.substring(1)}
                  </span>
                )}
              </div>
            </div>
          </div>
          {/* previous code */}
          {/* <div className="section-details--footer-amount">
            <div className="">
              <span>Sub Total </span><span className="semi-bold"> {currency.symbol}{formatNumber(subtotal)} </span> // It needs to be commented by default
              <span>Sub Total </span>
              <span className="semi-bold">
                {' '}
                {currency.symbol}
                {parseFloat(subtotal).toFixed(2)}{' '}
              </span>
            </div>
            {transactionDiscount !== 0 && (
              <div>
                <span>{DISCOUNT_LABEL}</span><span className="semi-bold">{currency.symbol}{formatNumber(transactionDiscount)}</span> // It needs to be commented by default
                <span>{DISCOUNT_LABEL}</span>
                {parseInt(DISCOUNT_AMOUNT) > 0 ? (
                  <span className="semi-bold" style={{ color: 'red' }}>
                    (-) {DISCOUNT_AMOUNT}
                  </span>
                ) : (
                  <span className="semi-bold">
                    {currency.symbol}
                    {DISCOUNT_AMOUNT}
                  </span>
                )}
              </div>
            )}
            {renderOverallTax()}
            <div>
              <span>Adjustment</span><span className="semi-bold">{currency.symbol}{formatNumber(adjustment)}</span> // It needs to be commented by default
              <span>Adjustment</span>
              {parseInt(adjustment) >= 0 ? (
                <span className="semi-bold">
                  {currency.symbol}
                  {ADJUSTMENT_AMPOUNT}
                </span>
              ) : (
                <span className="semi-bold" style={{ color: 'red' }}>
                  (-) {ADJUSTMENT_AMPOUNT.substring(1)}
                </span>
              )}
            </div>
          </div> */}
        </div>
        <hr className="float-left w-100 divider" />
        <div className="cust-table-summary">
          <div className="w-70 left-col">
            {/* <span>Applied Invoices:&nbsp;0</span> */}
          </div>
          <div className="w-30 right-col">
            <div className="single-summary-rec mb-0">
              {/*<span className='semi-bold bottom_title'> Total </span><span className="semi-bold">{currency.symbol}{formatNumber(total)}</span>*/}
              <span className="semi-bold"> Total </span>
              <span className="semi-bold">
                {currency.symbol}
                {parseFloat(total).toFixed(2)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
