import React from 'react';

import { NewContactIcon } from '../../Assets/Navigation/NavigationIcons';

import Email from '../../Components/Email/Email';

const ContactEmail = (props) => (
    <Email
        {...props}
        Icon={NewContactIcon}
        name="Contacts"
        onCancelURL="/contacts"
        emailAPI={`contacts/${props.match.params.id}/mail`}
        fetchDataAPI={`contacts/${props.match.params.id}/mail`}
        getBackTab
    />
);

export default ContactEmail;
