import React, { Component } from 'react';

import { CreditNoteIcon } from '../../../Assets/Navigation/NavigationIcons';
import { checkError, logout, validToken } from '../../../Helpers/AuthHelper';
import { restRequest } from '../../../Helpers/RequestHelper';
import { pdfDownlaod } from '../../../Helpers/PdfDownload';
import HeaderWrapper from '../../../Components/HeaderWrapper/HeaderWrapper';
import Loader from '../../../Components/Loader/Loader';
import { DetailsHeader } from '../../../Components/Details/Details';
import Box from '../../../Components/Layout/Box';
import PackageInfo from './Info/PackageInfo';
import PackageItemInfo from './Info/PackageItemInfo';
import { ActionMenu } from '../../../Components/CheckedMenu/CheckedMenu';
import NotFound from '../../../Components/NotFound/NotFound';
import CustomModal from '../../../Components/CustomModal/CustomModal';
import pageIdentify from '../../../Helpers/PageIdentify';
import { getObjFromLS, setObjInLS } from '../../../Helpers/LocalStorage';
import { controllerNames } from '../../../Constants';
import PDFViewer from '../../../Components/PDFViewer/PDFViewer';
import MarkAsConfirmedIcon from '../../../Assets/General/MarkAsOpenIcon';
import CreditNoteContactInfo from './Info/CreditNoteContactInfo';
import Menu from '../../../Components/Menu/Menu';
import MenuData from '../../../Components/Menu/MenuData';
import History from '../../../Assets/General/History';
import getCurrentMenu from '../../../Helpers/getCurrentMenu';
import './CreditNoteDetails.css';
import BillIcon from '../../../Assets/General/BillIcon';
import RefundIcon from '../../../Assets/General/RefundIcon';
import { HeaderMenus } from '../../../Components';
import useMobileDetection from '../../../Hooks/MobileDetect';
class PackageDetails extends Component {
  id = this.props.id
    ? this.props.id
    : this.props.idFromHeaderWrapper
      ? this.props.idFromHeaderWrapper
      : this.props.match.params.id;
  state = {
    package: {},
    details: [],
    activityLog: [],
    pdf: null,
    status: '',
    showPDF: false,
    loading: true,
    loadingActivity: false,
    notFound: false,
    void: false,
    isSaleOrder: false,
    markAsVoidModal: false,
    markAsVoidId: '',
    detailsNo: null,
    currency: {},
    markAsDraftModal: false,
    currentMenu: getCurrentMenu(window.location.search),
  };

  componentDidMount() {
    document.title = 'Credit Note Details';
    if (validToken()) {
      this.fetchData();
    } else {
      logout();
      this.props.history.push('/login');
    }
  }

  fetchData = async () => {
    this.setState({ loading: true });
    await restRequest('get', `creditnotes/${this.id}`)
      .then((res) => {
        if (res.saleOrder.length === 0) {
          this.setState({
            isSaleOrder: false,
            package: res,
            // activityLog: res.activity_log,
            details: res.invoice_details,
            status: res.creditNote.status,
            detailsNo: res.creditNote.credit_note_no,
            currency: res.base_currency
          });
        } else {
          this.setState({
            isSaleOrder: true,
            package: res,
            // activityLog: res.activity_log,
            details:
              res.creditNote.sale_order !== null
                ? res.creditNote.sale_order.saleReturn[0].return_receives[0]
                  .returnReceivesDetails
                : null,
            status: res.creditNote.status,
            detailsNo: res.creditNote.credit_note_no,
            currency: res.base_currency
          });
        }
        let dataFromLS = getObjFromLS('module');
        if (
          dataFromLS &&
          dataFromLS.queryParam &&
          dataFromLS.queryParam.includes(this.state.currentMenu)
        ) {
          let query = dataFromLS.queryParam;
          query = query.replace(`&${this.state.currentMenu}=true`, '');
          query = query.replace(`?${this.state.currentMenu}=true`, '');
          setObjInLS('module', {
            ...dataFromLS,
            queryParam: query,
            presistDetailPage: true,
          });

          let newurl =
            window.location.protocol +
            '//' +
            window.location.host +
            window.location.pathname +
            query;
          window.history.pushState({ path: newurl }, '', newurl);
        } else {
          setObjInLS('module', {
            ...dataFromLS,
            presistDetailPage: true,
          });
        }
      })
      .catch((error) => {
        checkError(error);
        if (
          error.response &&
          error.response.data.message ===
          'Credit Note with void status can not be viewable.'
        ) {
          this.setState({ void: true });
        } else {
          // Did this because no redux is implemented and headerwrapper does not handle this well so hardcoded
          if (
            error.response &&
            error.response.data.message === 'Credit Note not found.'
          ) {
            this.setState({ notFound: true });
          } else {
            // this console is used for test CR on live. Kindly do not remove this.
            console.log({ error, err: error?.response, props: this.props });
            this.props.handleToast(error, 'error');
            this.setState({ notFound: true });
          }
        }
      });
    this.setState({ loading: false });
  };

  renderDetailsHeader() {
    if (this.state.loading) {
      return 'Loading...';
    } else {
      return `Package Details (${this.state.package.package_no})`;
    }
  }

  markSaleReturnAsConfirmed = (saleReturnId) => {
    this.setState({ processing: true });
    restRequest('put', `creditnotes/${saleReturnId}/confirmed`)
      .then(() => {
        this.setState({ processing: false });
        if (pageIdentify(window.location.pathname)) {
          this.fetchData();
          this.props.handleToast(
            'Credit Note has been marked as open.',
            'success'
          );
        } else {
          this.props.history.push('/r');
        }
      })
      .catch((error) => {
        this.setState({ processing: false });
        checkError(error, this.props.handleToast);
      });
  };

  markSaleReturnAsVoid = () => {
    restRequest('put', `creditnotes/${this.state.markAsVoidId}/void`)
      .then(() => {
        if (pageIdentify(window.location.pathname)) {
          this.fetchData();
          this.closeMarkAsVoidModal();
          this.props.handleToast(
            'Credit Note has been marked as void.',
            'success'
          );
        } else {
          this.props.history.push('/r');
        }
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.closeMarkAsVoidModal();
      });
  };

  markAsDraft = () => {
    restRequest('put', `creditnotes/${this.id}/draft`)
      .then(() => {
        if (pageIdentify(window.location.pathname)) {
          this.fetchData();
          this.closeConvertToDraftModal();
          this.props.handleToast(
            'Credit Note has been marked as draft.',
            'success'
          );
        } else {
          this.props.history.push('/r');
        }
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.closeConvertToDraftModal();
      });
  };

  openMarkAsVoidModal = (id) => {
    this.setState({
      markAsVoidModal: true,
      markAsVoidId: id,
    });
  };
  openConvertToDraftModal = () => {
    this.setState({
      markAsDraftModal: true,
    });
  };
  closeConvertToDraftModal = () => {
    this.setState({
      markAsDraftModal: false,
    });
  };
  hasPermissionCustom(name, type) {
    let permissions = getObjFromLS('role').permissions;
    if (permissions.superAccess) {
      if (type === 'All') return [true, true, true];
      return true;
    }

    if (!['Create', 'Edit', 'Delete', 'All'].includes(type))
      throw new Error('Type must be one of Create, Edit, Delete, All');
    if (!(name in controllerNames))
      throw new Error('Invalid name is receieved for permission check');
    if (type !== 'All') {
      return permissions[`${controllerNames[name]}${type}`];
    }
    return [
      permissions[`${controllerNames[name]}Create`],
      permissions[`${controllerNames[name]}Edit`],
      permissions[`${controllerNames[name]}Delete`],
    ];
  }

  renderMarkAsVoidModal() {
    return (
      <CustomModal
        showModal={this.state.markAsVoidModal}
        title="Confirm to Mark as Void"
        onClose={this.closeMarkAsVoidModal}
        onSubmit={() => this.markSaleReturnAsVoid()}
      >
        Are you sure you want to mark this credit note as void?
      </CustomModal>
    );
  }

  renderConvertToDraftModal() {
    return (
      <CustomModal
        showModal={this.state.markAsDraftModal}
        title="Confirm to Mark as Draft"
        onClose={this.closeConvertToDraftModal}
        onSubmit={() => this.markAsDraft()}
      >
        Are you sure you want to mark this Credit Note as draft?
      </CustomModal>
    );
  }

  closeMarkAsVoidModal = () => {
    this.setState({
      markAsVoidModal: false,
    });
  };

  openPdf = (id) => {
    const [hasMobile] = useMobileDetection();
    if (hasMobile) {
      window.open(`/print/${id}/creditnotes`, '_blank');
    } else {
      this.setState({ showPDF: true });
      restRequest('get', `creditnotes/${id}/pdf`)
        .then((res) => {
          this.setState({ pdf: res });
        })
        .catch((error) => {
          checkError(error);
        });
    }
  };

  closeAllModals = () => {
    this.setState({
      showPDF: false,
      pdf: null,
    });
  };

  populateConditionalOptions(id) {
    const hasEditPermission = this.hasPermissionCustom('creditnotes', 'Edit');
    const options = [];
    if (!hasEditPermission) return options;
    options.push({
      label: 'Mark As Open',
      onClick: () => this.markSaleReturnAsConfirmed(id),
      icon: MarkAsConfirmedIcon,
      disabled: () =>
        this.state.status === 'consumed' ||
        this.state.status === 'open' ||
        this.state.status === 'partially applied' ||
        this.state.status === 'void',
    });
    return options;
  }

  renderActionMenu() {
    const id = this.id;
    const options = this.populateConditionalOptions(id);

    const menusList = [
      {
        type: 'link',
        icon: 'edit',
        enable:
          (this.hasPermissionCustom('creditnotes', 'Edit') &&
            this.state.status === 'draft') ||
          this.state.status === 'open',
        to: `/creditnotes/edit/${id}`,
        tooTip: 'Edit',
        mobileLable: 'Edit',
        isMobile: true,
      },
      {
        type: 'button',
        icon: 'pdf',
        tooTip: 'Download PDF',
        isMobile: true,
        mobileLable: 'Download PDF',
        handleClick: () =>
          pdfDownlaod(
            `creditnotes/${id}/pdf?download=true`,
            this.state.detailsNo,
            this.props.handleToast
          ),
      },
      {
        type: 'button',
        icon: 'print',
        isMobile: true,
        mobileLable: 'Print',
        tooTip: 'Print',
        handleClick: () => this.openPdf(id),
      },
      {
        type: 'link',
        icon: 'email',
        tooTip: 'Email',
        isMobile: true,
        mobileLable: 'Email',
        enable: this.hasPermissionCustom('creditnotes', 'Create'),
        to: `/creditnotes/email/${id}`,
      },
      {
        type: 'button',
        icon: 'delete',
        tooTip: 'Delete',
        mobileLable: 'Delete',
        isMobile: true,
        handleClick: () =>
          this.openDeleteModal(
            id,
            this.props.forceRedirect,
            this.props.forceRedirectFlag
          ),
        enable:
          this.hasPermission('creditnotes', 'Delete') &&
          (this.state.status === 'draft' ||
            this.state.status === 'open' ||
            this.state.status === 'void'),
      },
      {
        type: 'options',
        options: options,
        className: 'options-dropdown',
        enable: !!options.length,
      },
    ];

    return (
      <ActionMenu>
        <HeaderMenus menusList={menusList} loading={this.state.loading} />
      </ActionMenu>
    );
  }

  getTotalQuantity(items) {
    return items.reduce((total, item) => item.credits + total, 0);
  }

  print() {
    this.setState({ loading: true });
    restRequest('get', `creditnotes/${this.id}/print`)
      .then((res) => {
        var w = window.open();
        w.document.write(res.body);
        w.document.close();
        setTimeout(function () {
          w.focus();
          w.print();
          w.close();
        }, 3000);

        this.setState({ loading: false });
      })
      .catch((error) => {
        checkError(error);
        this.setState({ loading: false });
      });
  }

  handleTabChange(tab) {
    this.setState({ currentMenu: tab });
  }

  menus() {
    return [
      {
        id: 'history',
        label: 'History',
        invoiceId: this.id,
        url: `creditnotes/${this.id}/activity-log`,
        icon: () => <History />,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('history');
        },
        renderContent: this.renderMenuForHistory,
      },
    ];
  }

  updatedMenus() {
    return [
      {
        id: 'history',
        label: 'History',
        invoiceId: this.id,
        url: `creditnotes/${this.id}/activity-log`,
        icon: () => <History />,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('history');
        },
        renderContent: this.renderMenuForHistory,
      },
      {
        id: 'appliedOnInvoices',
        label: 'Applied On Invoices',
        creditNoteNo: this.state.detailsNo,
        inModal: this.props.inModal,
        handleToast: this.props.handleToast,
        history: this.props.history,
        currency: this.state.currency,
        url: `creditnotes/${this.id}/activity-log`,
        updateCreditNote: this.updateCreditNote,
        totalAvailableCredits: this.state.package.creditNote.balance,
        creditNoteId: this.state.package.creditNote.id,
        hasPermissionCustom: this.hasPermissionCustom,
        hasViewPermission: this.hasViewPermission('creditnotes'),
        icon: () => <BillIcon />,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('appliedOnInvoices');
        },
        renderContent: this.renderMenuForAppliedOnInvoices,
      },
      {
        id: 'creditRefunds',
        label: 'Refunds',
        creditNoteNo: this.state.detailsNo,
        inModal: this.props.inModal,
        handleToast: this.props.handleToast,
        history: this.props.history,
        updateCreditNote: this.updateCreditNote,
        currency: this.state.currency,
        url: `creditnotes/${this.id}/activity-log`,
        totalAvailableCredits: this.state.package.creditNote.balance,
        creditNoteId: this.state.package.creditNote.id,
        creditNoteDate: this.state.package.creditNote.credit_note_date,
        hasPermissionCustom: this.hasPermissionCustom,
        hasViewPermission: this.hasViewPermission('creditnotes'),
        icon: () => <RefundIcon />,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('creditRefunds');
        },
        renderContent: this.renderMenuForCreditRefunds,
      },
    ];
  }

  renderMenuForHistory() {
    return <MenuData name="History" invoiceId={this.id} url={this.url} />;
  }

  renderMenuForCreditRefunds() {
    return (
      <MenuData
        name="creditRefunds"
        salesOrderID={this.salesOrderID}
        totalAvailableCredits={this.totalAvailableCredits}
        creditNoteId={this.creditNoteId}
        url={this.url}
        currency={this.currency}
        updateCreditNote={this.updateCreditNote}
        creditNoteNo={this.creditNoteNo}
        creditNoteDate={this.creditNoteDate}
        inModal={this.inModal}
        history={this.history}
        handleToast={this.handleToast}
        hasPermissionCustom={this.hasPermissionCustom}
        hasViewPermission={this.hasViewPermission}
      />
    );
  }

  renderMenuForAppliedOnInvoices() {
    return (
      <MenuData
        name="AppliedOnInvoices"
        salesOrderID={this.salesOrderID}
        totalAvailableCredits={this.totalAvailableCredits}
        creditNoteId={this.creditNoteId}
        url={this.url}
        currency={this.currency}
        updateCreditNote={this.updateCreditNote}
        creditNoteNo={this.creditNoteNo}
        inModal={this.inModal}
        history={this.history}
        handleToast={this.handleToast}
        hasPermissionCustom={this.hasPermissionCustom}
        hasViewPermission={this.hasViewPermission}
      />
    );
  }

  updateCreditNote = () => {
    if (!pageIdentify(window.location.pathname)) {
      this.props.history.push('/r');
    } else this.fetchData();
  };

  renderLayoutView() {
    const { package: packageInfo, currency, currentMenu } = this.state;
    // const { package_no: packageNo, status } = packageInfo;
    // const shippingAddress = packageInfo.shipping_address || {};
    const billingAddress =
      (typeof packageInfo.billing_address === 'string' &&
        JSON.parse(packageInfo.billing_address)) ||
      {};
    return (
      <>
        <PDFViewer
          showPDF={this.state.showPDF}
          hidePDFModal={this.closeAllModals}
          pdf={this.state.pdf}
        />
        <div className="float-left w-100 credit_note_details_menu sales-return-main">
          <Menu
            //menus={this.menus()}
            menus={
              this.state.package.creditNote.status === 'draft'
                ? this.menus()
                : this.updatedMenus()
            }
            currentMenu={currentMenu}
            history={this.props.history}
            inModal={this.props.inModal || false}
          />
        </div>
        <div className="credit_data_row">
          <DetailsHeader
            title="CREDIT NOTE"
            no={this.state.package.creditNote.credit_note_no}
            status={this.state.status}
            customer={this.state.package.customer.display_name}
            contactID={this.state.package.customer.id}
            inModal={this.props.inModal}
            utilizedCredits={this.state.package.creditNote.utilize_credits}
            refundCredits={this.state.package.creditNote.refund_credits}
          // status={this.state.package.sales_return_status}
          />

          <PackageInfo
            info={{
              history: this.props.history,
              creditNoteDate: this.state.package.creditNote.credit_note_date,
              salesOrderNo:
                this.state.isSaleOrder &&
                  this.state.package.creditNote.sale_order !== null
                  ? this.state.package.creditNote.sale_order.sales_order_no
                  : null,
              salesOrderID:
                this.state.isSaleOrder &&
                  this.state.package.creditNote.sale_order !== null
                  ? this.state.package.creditNote.sale_order.id
                  : null,
              InvoiceNo: this.state.package.invoice_no,
              InvoiceID: this.state.package.id,
              ReturnReceiveNo:
                this.state.isSaleOrder &&
                  this.state.package.creditNote.sale_order !== null
                  ? this.state.package.creditNote.sale_order.saleReturn[0]
                    .return_receives[0].return_receives_no
                  : null,
              ReturnReceiveID:
                this.state.isSaleOrder &&
                  this.state.package.creditNote.sale_order !== null
                  ? this.state.package.creditNote.sale_order.saleReturn[0]
                    .return_receives[0].id
                  : null,
              SaleReturnNo:
                this.state.isSaleOrder &&
                  this.state.package.creditNote.sale_order !== null
                  ? this.state.package.creditNote.sale_order.saleReturn[0]
                    .sales_return_no
                  : null,
              SaleReturnID:
                this.state.isSaleOrder &&
                  this.state.package.creditNote.sale_order !== null
                  ? this.state.package.creditNote.sale_order.saleReturn[0].id
                  : null,
              // backToStock: this.state.package.add_back_stock,
              customerName: this.state.package.customer.display_name,
              remaining_credits: this.state.package.remaining_credits,
              customerID: this.state.package.customer.id,
              totalInvoice: this.state.package.total,
              currency: currency,
              // totalQuantity: this.getTotalQuantity(this.state.package.sale_order.salesOrderDetails)
            }}
            inModal={this.props.inModal}
          />
        </div>
        <CreditNoteContactInfo
          customer={this.state.package.customer}
          billingAddress={billingAddress}
          inModal={this.props.inModal}
        />

        <PackageItemInfo
          itemList={
            packageInfo.creditNote.sale_order !== null
              ? packageInfo.creditNote.sale_order.saleReturn[0]
                .return_receives[0].returnReceivesDetails
              : packageInfo.creditNote.credit_note_details
          }
          isSaleOrder={this.state.isSaleOrder}
          totalCredits={this.state.package.creditNote.issued_credits}
          totalDeductions={this.state.package.creditNote.deductions}
          utilizedCredits={this.state.package.creditNote.utilize_credits}
          refundCredits={this.state.package.creditNote.refund_credits}
          creditNoteDetails={this.state.package.creditNote.credit_note_details}
          currency={currency}
          inModal={this.props.inModal}
          className="cn-summary"
        />
      </>
    );
  }
  updateSalesOrderStatus = () => {
    if (pageIdentify(window.location.pathname)) {
      this.setState({
        loadingActivity: true,
      });
      this.setState({ status: '. . .' });
      restRequest('get', `creditnotes/${this.state.package.id}`)
        .then((res) => {
          this.setState({
            status: res.sales_return[0].sales_return_status,
            activityLog: res.activity_log,
            loadingActivity: false,
          });
        })
        .catch((error) => checkError(error));
    } else {
      this.props.history.push('/r');
    }
  };

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    if (this.state.void)
      return (
        <div className="empty-data">Void Credit Notes cannot be viewed</div>
      );
    return (
      <>
        {this.renderMarkAsVoidModal()}
        {this.renderConvertToDraftModal()}
        <Box className="no-padding credit_note_details">
          {this.renderLayoutView()}
        </Box>
      </>
    );
  }
  c;
}

export default HeaderWrapper(PackageDetails, {
  name: 'Credit Notes',
  deleteName: 'Credit Note',
  // tableIcon: 'creditNote_table_icon_details',
  tableIcon: 'credit_general_module_icon',
  Icon: CreditNoteIcon,
  baseUrl: 'creditnotes',
  redirectUrl: '/creditnotes',
  onlyMenu: true,
  showName: true,
  permissionName: 'creditnotes',
});
