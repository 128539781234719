import React, { Component, Fragment } from 'react';
import { restRequest } from '../../Helpers/RequestHelper';
import DeliveryIcon from '../../Assets/General/DeliveryMethodIcon';

import ListTable from '../../Components/ListTable/ListTable';
import HeaderWrapper from '../../Components/HeaderWrapper/HeaderWrapper';
import CustomModal from '../../Components/CustomModal/CustomModal';
// import DeliveryModalIcon from '../../Assets/General/DeliveryModalIcon'

import './DeliveryMethodList.css';
import DeliveryMethodForm from '../../Components/Form/DeliveryForm/DeliveryMethodForm';
import EditIcon from '../../Assets/General/EditIcon';
import { setValueInLS } from '../../Helpers/LocalStorage';
import DeleteIcon from '../../Assets/General/DeleteIcon';
import { checkError } from '../../Helpers/AuthHelper';
class DeliveryMethodList extends Component {
  state = {
    data: [],
    loading: true,
    showCreateDMModal: window.location.search.slice(1).split('=')[0] === 'new',
    showEditDMModal: false,
    DeliveryID: null,
    totalRecords: 0,
    showPrompt: false,
  };

  componentDidMount() {
    setValueInLS('fullscreen', true);
    document.title = 'Delivery Method List';
    this.fetchData();
  }

  fetchData = () => {
    this.setState({ loading: true });
    restRequest('get', 'deliverymethods')
      .then((res) => {
        this.setState({
          data: this.prepareDataForTable(res),
          totalRecords: res && res.length ? res.length : 0,
          loading: false,
          showCreateDMModal: this.props?.location?.search.includes('new')
            ? true
            : !Boolean(res.length),
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  };

  prepareDataForTable(data) {
    return data.map((deliveryMethods) => ({
      id: deliveryMethods.id,
      Name: {
        id: 'name',
        getValue: function () {
          return deliveryMethods[this.id];
        },
      },
    }));
  }

  isOrderDeleteable() {
    const [, hasDeletePermission] = this.hasPermission('deliverymethod', 'All');

    return !hasDeletePermission;
  }

  isOrderEditable() {
    const [, hasEditPermission] = this.hasPermission('deliverymethod', 'All');
    return !hasEditPermission;
  }

  prepareRowOptions() {
    const rowOptions = [
      {
        label: 'Edit',
        // type: 'link',
        onClick: (id) => this.showEditModal(id),
        icon: EditIcon,
        disabled: () => this.isOrderEditable(),
      },
      {
        label: 'Delete',
        onClick: this.openDeleteModal,
        icon: DeleteIcon,
        disabled: () => this.isOrderDeleteable(),
      },
    ];

    return rowOptions;
  }
  showCreateModal() {
    return this.setState({ showCreateDMModal: true });
  }

  showEditModal(id) {
    return this.setState({ showEditDMModal: true, DeliveryID: id });
  }

  closeAndBack(totalRecords) {
    //console.log(totalRecords)
    this.setState({ showCreateDMModal: false });
    this.setState({ showEditDMModal: false });
    // this.props.history.push('/deliverymethods')
    if (totalRecords === undefined || totalRecords === 0) {
      //history.push('/settings')
      window.location.href = '/settings';
    }
  }

  renderCreateModal() {
    return (
      <CustomModal
        className="add_new_deleivery"
        showModal={this.state.showCreateDMModal}
        title="Create New Delivery Method"
        renderActions={false}
        Icon={DeliveryIcon}
        fill="#000000"
        modaltitle="delivery_modal_title"
        size="large"
        //onClose={() => this.setState({ showCreateDMModal: false })}
        onClose={() => {
          this.closeAndBack(this.state.totalRecords);
          this.props.history.push('/deliveryMethods');
        }}
        showPrompt={this.state.showPrompt}
        // onSubmit={() => this.setState({showCreateDMModal : false})}
      >
        <DeliveryMethodForm
          inModal={true}
          closeModal={() => this.setState({ showCreateDMModal: false })}
          onSubmitModal={() => {
            this.setState({ showCreateDMModal: false });
            this.fetchData();
          }}
          type="add"
          modelSource
          handleToast={this.props.handleToast}
          totalRecords={this.state.totalRecords}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  handlePrompt = (status = true) => {
    this.setState({ showPrompt: status });
  };

  renderEditModal() {
    return (
      <CustomModal
        className="add_new_deleivery edit-delivery-popup"
        showModal={this.state.showEditDMModal}
        title="Edit Delivery Method"
        renderActions={false}
        fill="#000000"
        modaltitle="edit_delivery_modal_title"
        size="large"
        Icon={DeliveryIcon}
        onClose={() => this.setState({ showEditDMModal: false })}
        // onSubmit={() => this.setState({showCreateDMModal : false})}
        showPrompt={this.state.showPrompt}
      >
        <DeliveryMethodForm
          inModal={true}
          closeModal={() => this.setState({ showEditDMModal: false })}
          onSubmitModal={() => {
            this.setState({ showEditDMModal: false });
            this.fetchData();
          }}
          type="edit"
          modelSource
          id={this.state.DeliveryID}
          handleToast={this.props.handleToast}
          totalRecords={this.state.totalRecords}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }
  renderTable() {
    return (
      <Fragment>
        {this.renderCreateModal()}
        {this.renderEditModal()}
        <ListTable
          className="system_settings"
          data={this.state.data}
          rowOptions={this.prepareRowOptions()}
          hasCheckbox={false}
          loading={this.state.loading}
          showSettingsIcon={false}
          totalRecords={this.state.totalRecords}
        />
      </Fragment>
    );
  }

  render() {
    return this.renderTable();
  }
}

export default HeaderWrapper(DeliveryMethodList, {
  // name: 'Delivery Methods',
  // Icon: DeliveryIcon,
  // baseUrl: 'deliveryMethods',
  // redirectUrl: '/deliverymethods',
  // buttonUrl: '/deliverymethods/add',
  // buttonLabel: 'Add Delivery Method',
  // sectionHeader: false,
  // permissionName:'deliverymethod',
  // createModal:true,
  // showAddTrue:true,
  name: 'Delivery Methods',
  Icon: DeliveryIcon,
  baseUrl: 'deliveryMethods',
  redirectUrl: '/deliveryMethods',
  routeName: 'deliveryMethods',
  buttonUrl: '/deliverymethods/add',
  createModal: true,
  buttonLabel: 'Add Delivery Method',
  pagination: false,
  permissionName: 'deliverymethod',
  tableIcon: 'deliverymethod_table_icon',
});
