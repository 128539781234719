import React from 'react'

export default ({
  className = ''
}) => (

  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="28" height="22.5" viewBox="0 0 28 22.5">
    <g transform="translate(-1061.5 -266.25)">
      <path fill="#33a348"
            d="M26.813-4.125h-.687V-8.77a2.063,2.063,0,0,0-.606-1.457l-4.293-4.293a2.063,2.063,0,0,0-1.457-.606H17.875v-2.062a2.063,2.063,0,0,0-2.062-2.062h-11A2.063,2.063,0,0,0,2.75-17.187v2.063H.344A.345.345,0,0,0,0-14.781v.688a.345.345,0,0,0,.344.344H12.031a.345.345,0,0,1,.344.344v.688a.345.345,0,0,1-.344.344H1.719a.345.345,0,0,0-.344.344v.688A.345.345,0,0,0,1.719-11h8.938a.345.345,0,0,1,.344.344v.688a.345.345,0,0,1-.344.344H.344A.345.345,0,0,0,0-9.281v.688a.345.345,0,0,0,.344.344H9.281a.345.345,0,0,1,.344.344v.688a.345.345,0,0,1-.344.344H2.75v5.5A4.126,4.126,0,0,0,6.875,2.75,4.126,4.126,0,0,0,11-1.375h5.5A4.126,4.126,0,0,0,20.625,2.75,4.126,4.126,0,0,0,24.75-1.375h2.063a.69.69,0,0,0,.688-.687V-3.437A.69.69,0,0,0,26.813-4.125ZM6.875.688A2.063,2.063,0,0,1,4.813-1.375,2.063,2.063,0,0,1,6.875-3.437,2.063,2.063,0,0,1,8.938-1.375,2.063,2.063,0,0,1,6.875.688Zm13.75,0a2.063,2.063,0,0,1-2.062-2.062,2.063,2.063,0,0,1,2.063-2.062,2.063,2.063,0,0,1,2.063,2.063A2.063,2.063,0,0,1,20.625.688ZM24.063-8.25H17.875v-4.812H19.77L24.063-8.77Z"
            transform="translate(1062 286)"/>
      <path fill="#306ab4"
            d="M6068.75,64v-.875h6.53a.344.344,0,0,0,.344-.344v-.687a.344.344,0,0,0-.344-.344h-8.937a.345.345,0,0,1-.344-.344v-.688a.345.345,0,0,1,.344-.343h10.313a.344.344,0,0,0,.342-.344v-.688a.344.344,0,0,0-.342-.343h-8.939a.346.346,0,0,1-.344-.344v-.688a.346.346,0,0,1,.344-.344h10.313a.344.344,0,0,0,.343-.343v-.688a.345.345,0,0,0-.343-.344h-11.688a.345.345,0,0,1-.344-.343v-.688a.345.345,0,0,1,.344-.344h2.407V52.813a2.063,2.063,0,0,1,2.062-2.063h11a2.063,2.063,0,0,1,2.063,2.063v2.061H6084v2.063h-.125V61.75H6084V64Z"
            transform="translate(-5004 216)"/>
    </g>
  </svg>
  )
