import React, { useEffect } from 'react';
import { PackageForm } from '../../../Components/Form';
import { FormLayout } from '../../../Components';
import { NewPackageIcon } from '../../../Assets/Navigation/NavigationIcons';

const AddPackage = ({ handleToast }) => {
  useEffect(() => {
    document.title = 'Add Package';
  }, []);

  return (
    <FormLayout
      title="Create New Package"
      Icon={NewPackageIcon}
      closeUrl="/packages"
      iconstyle="general_module_icon"
      layoutBoxClass="uiux-form-redesign"
    >
      <PackageForm
        // title="Add New Package"
        type="add"
        handleToast={handleToast}
      />
    </FormLayout>
  );
};

export default AddPackage;
