import React, { useMemo, useState } from 'react';
// import MdAdd from 'react-icons/lib/md/add';
import { MdAdd } from '../../Common/Icons';
import { AsyncPaginate } from 'react-select-async-paginate';
import { matchSorter } from 'match-sorter';
import { imagePathS3 } from '../../Helpers';

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });

const addNew = {
  value: 'createNew',
  label: (
    <div className="DDTopElement">
      <MdAdd /> &nbsp; Add New
    </div>
  ),
  className: 'add-new-option',
};

const noOptionFound = {
  value: '0',
  label: 'No Option Found',
  isdisabled: true,
  className: 'noOptionFoundPagination',
};

const notOptionsFoundResult = (addButton, noOptionFoundMessage) => [
  ...(addButton
    ? [{ ...addNew }, { ...noOptionFound, label: noOptionFoundMessage }]
    : [{ ...noOptionFound, label: noOptionFoundMessage }]),
];

const searchOptionsHandler = (options, searchWord, keys, alphabetSort) => {
  options = options.filter((option) => option.value !== 'createNew');
  const findedOptions = alphabetSort
    ? matchSorter(options, searchWord, { keys })
    : options.filter(({ label }) =>
        label.toLowerCase().includes(searchWord.toLowerCase())
      );
  return findedOptions;
};

const prepareOptionWithImage = (options) => {
  let modiFiedOptions = options.map((option) => {
    const image = typeof option.images !== 'undefined' ? option.images[0] : '';
    return {
      ...option,
      label: (
        <div className="option--with-image">
          <div className="image">
            <img
              src={imagePathS3(image)}
              role="presentation"
              alt="presentation"
            />
          </div>
          <div className="desc">
            {option?.label}
            <span className="small-td-info">{option?.sku}</span>
          </div>
        </div>
      ),
    };
  });
  return modiFiedOptions;
};

const SelectPaginationField = ({
  optionsList = [],
  value = null,
  handleChange = () => {},
  error = '',
  dropdownValue = 'id',
  dropdownLabel = 'name',
  addButton = false,
  alphabetSort = false,
  searchKeys = ['label'],
  disabled = false,
  placeholder = 'Select',
  onInputChange = () => {},
  clearable = true,
  onBlur = () => {},
  onKeyDown = () => {},
  withImage = false,
  noOptionFoundMessage = 'No Options Found',
  autoFocus = false,
}) => {
  const [customError] = useState(false);
  const [customValue, setCustomValue] = useState(null);

  let options = optionsList.map((item) => {
    return {
      ...item,
      value: item[dropdownValue],
      label: item[dropdownLabel],
    };
  });

  // let optionForSearch = [...options];
  options = withImage ? prepareOptionWithImage(options) : options;
  options = addButton ? [addNew, ...options] : options;

  const loadOptions = async (search, prevOptions) => {
    await sleep(1000);
    const filteredOptions = search
      ? searchOptionsHandler(options, search, searchKeys, alphabetSort)
      : options;
    const hasMore = filteredOptions.length > prevOptions.length + 30;
    let slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 30
    );

    slicedOptions =
      slicedOptions.length === 0
        ? notOptionsFoundResult(addButton, noOptionFoundMessage)
        : slicedOptions;

    return {
      options: slicedOptions,
      hasMore,
    };
  };

  useMemo(() => {
    let findItem = options.find((item) => item.value === value);
    findItem =
      findItem && 'value' in findItem
        ? { ...findItem, label: findItem.label, value: findItem.value }
        : null;
    setCustomValue(findItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <div
        className={`${
          !disabled && customError && !error && !value === true
            ? 'input-error-field asyn-paginate-custom'
            : 'asyn-paginate-custom'
        } ${error ? 'fieldError' : ''}`}
      >
        <AsyncPaginate
          // value={modifiedValue(value)}
          cacheUniqs={[optionsList.length]}
          value={customValue}
          loadOptions={loadOptions}
          onChange={(option) => {
            handleChange(option);
          }}
          isClearable={clearable}
          isOptionDisabled={(option) => option.isdisabled}
          className="asyncPaginateParent"
          isDisabled={disabled}
          onInputChange={onInputChange}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          autoFocus={autoFocus}
          onBlur={onBlur}
        />

        {!disabled && customError && !error && !value && (
          <div className="field-error">Required</div>
        )}
      </div>
    </>
  );
};

export default SelectPaginationField;
