export function getItemTotalAmount(item) {
  let quantity = item.quantity;
  // let unitPrice = item.unitPrice;
  let unitPrice = item.unitPrice || item.rate;

  let discountType = item.discountType || item.discount_type || 'percent';
  let discount = item.discount || item.discount_item_level || 0;

  let total = quantity * unitPrice;
  if (discountType === 'percent') {
    if (discount > 100) {
      return 0;
    }
    let totalDiscount = (total * discount) / 100;
    return parseFloat((total - totalDiscount).toFixed(2)) || 0;
  } else if (discountType === 'amount') {
    if (discount > total) {
      return 0;
    }
    return parseFloat((total - discount).toFixed(2)) || 0;
  } else return parseFloat(total.toFixed(2)) || 0;
}
