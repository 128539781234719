import React, { useCallback, useEffect, useState } from 'react';
import { PieChart, Pie, Sector } from 'recharts';
import './DashboardSalesInfo.css';
import InvoiceIcon from '../../../Assets/Dashboard/InvoiceIcon';
import NoSaleOrderIcon from '../../../Assets/Dashboard/NoSaleOrderIcon';
import { restRequest } from '../../../Helpers/RequestHelper';
import Loader from '../../../Components/Loader/Loader';
import AccessDenied from '../../../Components/AccessDenied/AccessDenied';
import DataError from '../../../Components/DashboardDataError/DB_DataError';
import { Link } from 'react-router-dom';
import { checkError } from '../../../Helpers/AuthHelper';

var foundData = true;
var accessDenied = false;
var accessError = false;
const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    //percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos <= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        Sales Order
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`${payload.name}`}
      </text>
    </g>
  );
};

export default function DashboardSalesInfo(props) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const pieData = [
    { name: 'Closed', value: (data.chart_data && data.chart_data.closed) || 0 },
    {
      name: 'Confirmed',
      value: (data.chart_data && data.chart_data.confirmed) || 0,
    },
    { name: 'Draft', value: (data.chart_data && data.chart_data.draft) || 0 },
  ];

  function fetchData(currentDateRange, startData, endDate) {
    setLoading(true);
    restRequest(
      'get',
      `dashboard/sales_information/${currentDateRange
        === 'custom'
        ? `custom?starting_date=${startData}&ending_date=${endDate}`
        : currentDateRange
      }`
    )
      .then((res) => {
        if (res.found_data === true) {
          setData(res);
          foundData = true;
        } else {
          setData({ invoiced: 0, required: 0 });
          foundData = false;
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          accessDenied = true;
        } else {
          accessError = true
        }
        checkError(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchData(props.currentDateRange.sales_information, props.startData, props.endDate);
  }, [props.currentDateRange, props.startData, props.endDate]);

  if (loading) return <Loader />;
  if (accessError)
    return <DataError className="small dashboard " />;
  if (accessDenied)
    return <AccessDenied className="small dashboard " />;
  return (
    <div className="float-left w-100">
      {foundData === false ? (
        <div className="float-left w-50 right_border no_sales_order_graph">
          <NoSaleOrderIcon />
        </div>
      ) : (
        <div className="float-left w-50 right_border">
          <PieChart width={300} height={226}>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={pieData}
              cx={180}
              cy={100}
              innerRadius={45}
              outerRadius={60}
              fill="#dfe1e5"
              dataKey="value"
              onMouseEnter={onPieEnter}
            />
          </PieChart>
        </div>
      )}
      <div className="dashborad_sales_info_invoices float-left w-50">
        <InvoiceIcon className="material-icon fill-green" />
        <h4 className="semi-bold">Invoices</h4>
        <div className="float-left w-100 mb_10">
          <Link
            to={`salesorders?filter=not fully invoiced&sort=created_at&page=1&orderBy=desc&view=20&start=${data.start_date}&end=${data.end_date}`}
          >
            <span className="float-left w-50 pl_6" style={{ color: '#000' }}>
              Required{' '}
            </span>
            <span
              className="semi-bold float-left w-50"
              style={{ color: '#000' }}
            >
              {data.required}
            </span>
          </Link>
        </div>
        <div className="float-left w-100">
          <Link
            to={`salesorders?filter=invoiced&sort=created_at&page=1&orderBy=desc&view=20&start=${data.start_date}&end=${data.end_date}`}
          >
            <span className="float-left w-50 pl_6" style={{ color: '#000' }}>
              Invoiced{' '}
            </span>
            <span
              className="semi-bold  float-left w-50"
              style={{ color: '#000' }}
            >
              {data.invoiced}
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}
