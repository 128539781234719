import OrganizationChoose from './OrganizationChoose';
import AuthContainer from '../../Auth/AuthContainer';
import OrganizationsList from './OrganizationsList';
import CenterContent from './CenterContent';
import { logout, restRequest, imagePathS3 } from '../../Helpers';
import { getOrganization } from '../../Helpers';
import Loader from '../Loader/Loader';

const orgStaticContent = {
  noAccessTitle: 'You have no more access to your default business',
  selectTitle: 'Select Business',
};

export default OrganizationChoose;
export {
  AuthContainer,
  OrganizationsList,
  CenterContent,
  logout,
  restRequest,
  imagePathS3,
  getOrganization,
  Loader,
  orgStaticContent,
};
