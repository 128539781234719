import { isEqual } from 'lodash';

export const validateBillsForm = (data = {}, defaultValues = {}) => {
  let checkout = true;
  if (data && defaultValues) {
    const { billDate = null, dueDate = null } = defaultValues;
    const actuabillDate = billDate && billDate.format('YYYY-MM-DD 00:00:00');
    const actualDueDate = dueDate && dueDate.format('YYYY-MM-DD 00:00:00');

    const checkoutEqualityBillDate = Boolean(
      isEqual(data?.billDate?.format('YYYY-MM-DD 00:00:00'), actuabillDate)
    );
    const checkoutEqualityDueeDate = Boolean(
      isEqual(data?.dueDate?.format('YYYY-MM-DD 00:00:00'), actualDueDate)
    );
    const updatedData = { ...data };
    const actualData = { ...defaultValues };
    delete updatedData.billDate;
    delete updatedData.dueDate;

    delete actualData.billDate;
    delete actualData.dueDate;

    const checkEquality = Boolean(isEqual(updatedData, actualData));

    if (
      !checkEquality ||
      !checkoutEqualityBillDate ||
      !checkoutEqualityDueeDate
    ) {
      checkout = false;
    }
  }
  return { checkout };
};
