import React, { Component } from 'react';
import moment from 'moment';
import { startOfDay, endOfDay } from 'date-fns';
import { Link } from 'react-router-dom';
import Loader from './../../../../../Components/Loader/Loader';
import CustomDatePicker from '../../../../../Components/CustomDatePicker/CustomDatePicker';
import Table from '../../../../../Components/Table/Table';
import EmailIcon from '../../../../../Assets/General/EmailIcon';
import { restRequest } from '../../../../../Helpers/RequestHelper';
import getDateFormat from '../../../../../Helpers/Date/GetDate';
import { getOrganizationDate } from '../../../../../Helpers/Date/OrganizationDate';
import { formatNumber } from '../../../../../Helpers/FormatNumber';

import './ContactStatement.css';
import { FaRegFilePdf, MdPrint } from '../../../../../Common/Icons';

import PDFViewer from '../../../../../Components/PDFViewer/PDFViewer';
import { checkError } from '../../../../../Helpers/AuthHelper';
import { pdfDownlaod } from '../../../../../Helpers/PdfDownload';
class ContactStatementTab extends Component {
  state = {
    loading: true,
    list: [],
    openingBalance: '',
    totalAmount: '',
    totalPaid: '',
    pdf: null,
    showPDF: false,
    totalDue: '',
    dateRangePicker: {
      selection: {
        startDate: moment(startOfDay(getOrganizationDate())),
        endDate: moment(endOfDay(getOrganizationDate())),
        key: 'selection',
      },
    },
    currency: {},
  };

  componentDidMount() {
    this.fetchData();
  }

  print() {
    this.setState({ loading: true });
    restRequest(
      'get',
      `contacts/${this.props.contactId
      }/print?starting_date=${this.state.dateRangePicker.selection.startDate.format(
        'YYYY-MM-DD HH:mm:ss'
      )}&ending_date=${this.state.dateRangePicker.selection.endDate.format(
        'YYYY-MM-DD HH:mm:ss'
      )}`
    )
      .then((res) => {
        var w = window.open();
        w.document.write(res.body);
        setTimeout(function () {
          w.focus();
          w.print();
          w.close();
        }, 3000);

        this.setState({ loading: false });
      })
      .catch((err) => {
        checkError(err, this.props.handleToast);
        this.setState({ loading: false });
      });
  }

  handleRangeChange = (range) => {
    this.setState(
      {
        dateRangePicker: {
          ...this.state.dateRangePicker,
          selection: {
            ...this.state.dateRangePicker.selection,
            startDate: moment(startOfDay(range.selection.startDate)),
            endDate: moment(endOfDay(range.selection.endDate)),
          },
        },
      },
      () => this.fetchData()
    );
  };

  fetchData() {
    this.setState({ loading: true });
    restRequest(
      'get',
      `contacts/${this.props.contactId
      }/statement?starting_date=${this.state.dateRangePicker.selection.startDate.format(
        'YYYY-MM-DD HH:mm:ss'
      )}&ending_date=${this.state.dateRangePicker.selection.endDate.format(
        'YYYY-MM-DD HH:mm:ss'
      )}`
    )
      .then((response) => {
        let statement = response.statement.map((statementItem) => {
          let details = '';
          if (statementItem.details.indexOf('due on')) {
            let slpitDetails = statementItem.details.split('on ');
            details = slpitDetails[0] + ' on ' + getDateFormat(slpitDetails[1]);
          }
          return {
            ...statementItem,
            details: details,
            date: getDateFormat(statementItem.date),
          };
        });
        this.setState({
          list: statement,
          openingBalance: response.openingBalance,
          totalAmount: response.totalAmount,
          totalPaid: response.totalPaid,
          totalDue: response.totalDue,
          currency: response.base_currency,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  }

  openPdf() {
    this.setState({ showPDF: true });
    restRequest(
      'get',
      `contacts/${this.props.contactId
      }/pdf?starting_date=${this.state.dateRangePicker.selection.startDate.format(
        'YYYY-MM-DD HH:mm:ss'
      )}&ending_date=${this.state.dateRangePicker.selection.endDate.format(
        'YYYY-MM-DD HH:mm:ss'
      )}`
    )
      .then((res) => {
        this.setState({ pdf: res });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  }

  close = () => {
    this.setState({
      showPDF: false,
      pdf: null,
    });
  };

  prepareList(list) {
    return list.map((data) => {
      return {
        ...data,
        total: `${this.state.currency.symbol}${formatNumber(data.total)}`,
        payments: `${this.state.currency.symbol}${formatNumber(data.payments)}`,
        balance: `${this.state.currency.symbol}${formatNumber(data.balance)}`,
        transaction: data?.payment_no ? `${data?.transaction} - ${data.payment_no}` : data?.invoice_payment_no ? `${data?.transaction} - ${data.invoice_payment_no}` : data?.transaction

      };
    });
  }

  renderStatement = () => {
    if (this.state.loading) return <Loader />;
    return (
      <>
        <div className="account-summary">
          <div className="account-summary--details">
            <div className="section-details--info-title bold account-summary--background-title">
              Account Summary
            </div>
            <div className="jaldikamkrbay float-left w-100 mb-3">
              <div className="float-left">Opening Balance</div>
              <div className="float-right">
                {this.state.currency.symbol}
                {formatNumber(this.state.openingBalance)}
              </div>
            </div>
            <div className="jaldikamkrbay float-left w-100 mb-3">
              <div className="float-left">Total Amount</div>
              <div className="float-right">
                {this.state.currency.symbol}
                {formatNumber(this.state.totalAmount)}
              </div>
            </div>
            <div className="jaldikamkrbay float-left w-100 mb-3">
              <div className="float-left">Total Paid</div>
              <div className="float-right">
                {this.state.currency.symbol}
                {formatNumber(this.state.totalPaid)}
              </div>
            </div>
            <hr className="float-left w-100 mb-3" />
            <div
              className="jaldikamkrbay float-left w-100"
              style={{ marginTop: '7px', marginBottom: '7px' }}
            >
              <div className="bold float-left">Total Due</div>
              <div className="bold float-right">
                {this.state.currency.symbol}
                {formatNumber(this.state.totalDue)}
              </div>
            </div>
            <hr className="float-left w-100 mb-1" />
            <hr className="float-left w-100" />
          </div>
        </div>
        <div className="statement_table scrollbar_style">
          <Table
            list={this.prepareList(this.state.list)}
            tableHeaders={[
              'Date',
              'Transaction',
              'Details',
              'Amount',
              'Payments',
              'Balance',
            ]}
            tableData={[
              'date',
              'transaction',
              'details',
              'total',
              'payments',
              'balance',
            ]}
            rightAlignColumns={[4, 5, 6]}
            className="list-table statement_thead"
          />
        </div>
      </>
    );
  };

  render() {
    return (
      <div className="padding-summarry">
        <PDFViewer
          showPDF={this.state.showPDF}
          hidePDFModal={this.close}
          pdf={this.state.pdf}
        />
        <CustomDatePicker
          type="range"
          ranges={this.state.dateRangePicker}
          months={1}
          onChange={this.handleRangeChange}
          inModal={this.props.inModal}
        />
        {!this.props.inModal && (
          <FaRegFilePdf
            onClick={() =>
              pdfDownlaod(
                `contacts/${this.props.contactId
                }/pdf?starting_date=${this.state.dateRangePicker.selection.startDate.format(
                  'YYYY-MM-DD HH:mm:ss'
                )}&ending_date=${this.state.dateRangePicker.selection.endDate.format(
                  'YYYY-MM-DD HH:mm:ss'
                )}&download=true`,
                `${this.props.contactName} Statement`,
                this.props.handleToast
              )
            }
            className="material-icon statement-icons"
            id="pdf"
          />
        )}
        {!this.props.inModal &&
          (!this.props.hasMobile ? (
            <MdPrint
              onClick={() => this.openPdf()}
              className="material-icon statement-icons"
              id="print"
            />
          ) : null)}
        {!this.props.inModal && (
          <Link
            to={`/contacts/statement/${this.props.contactId
              }/${this.state.dateRangePicker.selection.startDate.format(
                'YYYY-MM-DD HH:mm:ss'
              )}/${this.state.dateRangePicker.selection.endDate.format(
                'YYYY-MM-DD HH:mm:ss'
              )}`}
          >
            <EmailIcon className="material-icon statement-icons" id="email" />
          </Link>
        )}
        {this.renderStatement()}
      </div>
    );
  }
}

export default ContactStatementTab;
