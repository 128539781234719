import { COUNTRY_STATE } from '../Constants';

let CountryStates = COUNTRY_STATE.find(
  (element) => element.country === 'United States'
).statesCombination;

export function StateFullName(state) {
  let res = '';
  return CountryStates.find(function (as) {
    for (const stateKey in as) {
      if (as[stateKey] === state) {
        res = stateKey;
        return true;
      }
    }
    return res;
  });
}

export function StateShortName(state) {
  let res = '';
  return CountryStates.find(function (cs) {
    for (const stateKey in cs) {
      if (stateKey === state) {
        res = cs[stateKey];
        return true;
      }
    }
    return res;
  });
}
