import React, { Component } from 'react';
import proptypes from 'prop-types';
import { restRequest } from '../../../Helpers/RequestHelper';

import ListTableImageColumn from '../../../Components/ListTable/ListTableImageColumn';
import Loader from '../../../Components/Loader/Loader';
import FormAction from '../../../Components/Form/FormAction';
import Field from '../../../Components/Form/Field/Field';
import DeleteIcon from '../../../Assets/General/DeleteIcon';
import { checkError } from '../../../Helpers/AuthHelper';

class CancelItems extends Component {
  state = {
    loading: true,
    items: [],
    isSubmitting: false,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const id = this.props.id;
    restRequest('get', `purchaseorders/${id}/cancellable-items`)
      .then((res) => {
        const items = res.map((item) => ({
          id: item.item_id,
          name: item.item_name,
          sku: item.item_sku,
          image: item.item_images.length > 0 ? item.item_images : null,
          purchaseOrderItemDetailId: item.purchase_order_item_detail_id,
          quantityOrdered: item.quantity_ordered,
          receivedQuantity: item.received_quantity,
          billedReceivedQuantity: item.bill_receive_quantity,
          billedQuantity: item.billed_quantity,
          quantityToCancel: item.quantity_cancellable,
          maxQuantityToCancel: item.quantity_cancellable,
        }));
        this.setState({
          items,
          loading: false,
        });
      })
      .catch((error) => {
        this.props.closeModal();
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, "error")
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let stopRequest = this.state.items.some(
      (item) => item.quantityToCancel < 1 || item.quantityToCancel === undefined
    );
    if (!stopRequest) {
      this.setState({ isSubmitting: true });
      restRequest('put', `purchaseorders/${this.props.id}/cancel-items`, {
        items: this.state.items.map((item) => ({
          purchase_order_item_detail_id: item.purchaseOrderItemDetailId,
          quantity: item.quantityToCancel ? item.quantityToCancel : null,
        })),
      })
        .then(() => {
          this.props.handleToast('Item marked as Cancelled', 'success');
          this.props.updateCheckedState();
          this.props.closeModal();
          this.props.fetchData();
        })
        .catch((error) => {
          checkError(error, this.props.handleToast);
          //this.props.handleToast(error, 'error')
          this.setState({ isSubmitting: false });
        });
    }
  };

  handleChange = (value, id, index) => {
    const items = this.state.items.map((item, indx) => {
      if (item.id === id && index === indx) {
        return {
          ...item,
          quantityToCancel: value,
        };
      }
      return item;
    });

    this.setState({
      items,
    });
  };
  handleRemoveItem = (removeIndex, items) => {
    items = items.filter((_, index) => index !== removeIndex);
    this.setState({
      items,
    });
  };

  renderForm() {
    return (
      <form onSubmit={this.handleSubmit}>
        <h5 className="modal-section--title">Choose the items to cancel</h5>
        <div className="so-tbletabs">
          <table className="list-table form-table border cancel_items_popup ">
            <thead>
              <tr>
                <th>Item</th>
                <th className="text-right">Ordered</th>
                <th className="text-right">Received</th>
                <th className="text-right">Billed</th>
                <th className="text-right">Recieved & Billed</th>
                <th>Quantity to Cancel</th>
              </tr>
            </thead>
            <tbody>
              {this.state.items.map((item, index) => (
                <tr key={index}>
                  <td style={{ top: '5px' }}>
                    <ListTableImageColumn
                      name={item.name}
                      sku={item.sku}
                      images={item.image}
                    />
                  </td>

                  <td className="text-right">{item.quantityOrdered}</td>
                  <td className="text-right">{item.receivedQuantity}</td>
                  <td className="text-right">{item.billedQuantity}</td>
                  <td className="text-right">{item.billedReceivedQuantity}</td>
                  <td className="position-relative">
                    <span>
                      <Field
                        className="adjust_width"
                        type="number"
                        size="small"
                        value={item.quantityToCancel}
                        decimalScale={0}
                        allowNegative={false}
                        thousandSeparator=","
                        onValueChange={(value) =>
                          this.handleChange(value.floatValue, item.id, index)
                        }
                        error={
                          (item.quantityToCancel < 1 ||
                            item.quantityToCancel === undefined) &&
                          'Invalid Quantity'
                        }
                      />
                    </span>
                    <button
                      type="button"
                      disabled={this.state.items.length === 1}
                      className="close-btn button button_right"
                      onClick={() =>
                        this.handleRemoveItem(index, this.state.items)
                      }
                    >
                      <DeleteIcon className="bin" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <FormAction
          className="margin-top-md"
          disabled={this.state.isSubmitting}
          onCancel={this.props.closeModal}
        />
      </form>
    );
  }
  render() {
    return this.state.loading ? (
      // TODO: change to css file if reused
      <div className="loader-without-topbar">
        <Loader />
      </div>
    ) : (
      this.renderForm()
    );
  }
}
CancelItems.propTypes = {
  id: proptypes.number.isRequired,
  fetchData: proptypes.func.isRequired,
  closeModal: proptypes.func.isRequired,
  updateCheckedState: proptypes.func.isRequired,
  handleToast: proptypes.func.isRequired,
};

export default CancelItems;
