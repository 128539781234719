import React, { useEffect } from 'react';

import { ItemForm } from '../../../Components/Form';
import FormLayout from '../../../Components/Layout/FormLayout';
import { NewItemIcon } from '../../../Assets/Navigation/NavigationIcons';

const AddItemExistingGroup = ({
  handleToast,
  idFromHeaderWrapper,
  ...props
}) => {
  useEffect(() => {
    document.title = 'Add Item';
  }, []);

  return (
    <FormLayout
      title="Item Groups"
      Icon={NewItemIcon}
      subtitle="Add Item"
      closeUrl="/item-groups"
      iconstyle="general_module_icon"
      layoutBoxClass="uiux-form-redesign"
    >
      <ItemForm
        type="add"
        formType="itemGroup"
        id={idFromHeaderWrapper ? idFromHeaderWrapper : props.match.params.id}
        handleToast={handleToast}
      />
    </FormLayout>
  );
};

export default AddItemExistingGroup;
